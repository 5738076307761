import {Directive} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator} from '@angular/forms';
import {requiredTrimValidator} from './required-trim.validator';

@Directive({
    selector: '[appRequiredTrim]',
    providers: [{provide: NG_VALIDATORS, useExisting: RequiredTrimDirective, multi: true}],
})
export class RequiredTrimDirective implements Validator {
    public readonly validate = (control: AbstractControl): {[key: string]: any} | null =>
        requiredTrimValidator()(control);
}
