import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ClrDatagridFilterInterface} from '@clr/angular';
import {Moment} from 'moment';
import moment from 'moment';
import {Subject} from 'rxjs';
import {ISO_DATE_FORMAT, ISO_DATE_TIME_FORMAT} from '../../../../../defs/schema-static';
import {IFilterableEntity, ISerializableDatagridFilter} from '../../app-static';
import {MomentService} from '../moment/moment.service';
import {NestedProperty} from '../nested-properties';

export enum DATAGRID_FILTER_DATE_RANGE_TYPE {
    INTERVAL = 'INTERVAL',
    FROM = 'FROM',
}

export enum DATAGRID_FILTER_DATE_DATE_TYPE {
    DATE = 'DATE',
    DATE_TIME = 'DATE_TIME',
}

@Component({
    selector: 'shared-date-datagrid-filter',
    templateUrl: './date-datagrid-filter.component.html',
})
export class DateDatagridFilterComponent<T extends IFilterableEntity = any>
    implements ClrDatagridFilterInterface<T>, ISerializableDatagridFilter, OnInit, OnChanges {
    @Input() public filterKey: string;
    @Input() public filter = '';
    @Input() public rangeType = DATAGRID_FILTER_DATE_RANGE_TYPE.INTERVAL;
    @Input() public dateType = DATAGRID_FILTER_DATE_DATE_TYPE.DATE;

    @Input() public filterOpen = false;

    public filterDateStart: string;
    public filterDateEnd: string;
    public filterTimeStart: string;
    public filterTimeEnd: string;

    public changes = new Subject<any>();

    private nestedProp: NestedProperty<T>;

    public idIncrement = 0;

    public constructor(private readonly momentService: MomentService) {
        this.idIncrement = DateDatagridFilterComponent.ID_INCREMENT++;
    }

    public ngOnInit(): void {
        this.setFilterKey();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.filterKey) {
            this.setFilterKey();
        }
    }

    public setFilterKey() {
        this.nestedProp = this.filterKey ? new NestedProperty(this.filterKey) : undefined;
        this.changes.next(this.filterKey);
    }

    public updateFilter() {
        this.filter = this.serialize().join('');
        this.changes.next(this.filterKey);
    }

    public accepts(item: T): boolean {
        if (!this.filterKey) {
            // tslint:disable-next-line:no-console
            // console.warn('missing filterKey');

            return true;
        }

        const {filterValue} = this;

        if (!filterValue) {
            return true;
        }

        const itemValue = this.getItemValue(item);

        if (!itemValue) {
            return false;
        }

        const [from, until] = filterValue;
        if (this.rangeType === DATAGRID_FILTER_DATE_RANGE_TYPE.FROM) {
            return from && from.isAfter(itemValue);
        }

        return (!from || from.isBefore(itemValue)) && (!until || until.isAfter(itemValue));
    }

    public isActive(): boolean {
        const {filterValue} = this;

        return filterValue && !!filterValue.filter((date) => !!date).length;
    }

    public serialize(): string[] {
        const {filterValue} = this;

        if (!filterValue) {
            return [];
        }

        return [filterValue.map((date) => (date && date.format(ISO_DATE_TIME_FORMAT)) || 'null').join(',')];
    }

    public get filterValue(): [moment.Moment] | [moment.Moment, moment.Moment] {
        const serializeDate = (date: string | moment.Moment, time?: string): moment.Moment | null =>
            date
                ? moment(
                      `${moment(date).format(ISO_DATE_FORMAT)}T${
                          this.dateType === DATAGRID_FILTER_DATE_DATE_TYPE.DATE_TIME && time ? time : '00:00:00'
                      }Z`
                  )
                : null;

        if (this.rangeType === DATAGRID_FILTER_DATE_RANGE_TYPE.FROM) {
            return [serializeDate(this.filterDateStart, this.filterTimeStart)];
        }

        return [
            serializeDate(this.filterDateStart, this.filterTimeStart),
            serializeDate(this.filterDateEnd, this.filterTimeEnd),
        ];
    }

    public getItemValue(item: T): Moment {
        if (!this.nestedProp) {
            return null;
        }

        const value = this.nestedProp.getPropValue(item);

        return value && this.momentService.moment(value, null, null, true);
    }

    public resetFilter() {
        this.filterDateStart = '';
        this.filterDateEnd = '';

        this.filterTimeStart = '';
        this.filterTimeEnd = '';

        this.updateFilter();
    }

    public readonly DATAGRID_FILTER_DATE_TYPE = DATAGRID_FILTER_DATE_DATE_TYPE;
    public readonly DATAGRID_FILTER_DATE_RANGE_TYPE = DATAGRID_FILTER_DATE_RANGE_TYPE;

    public static ID_INCREMENT = 1;
}
