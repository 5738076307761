import {Component, Input} from '@angular/core';
import {IDashNumber} from '../app-static';

@Component({
    selector: 'app-dashboard-numbers',
    templateUrl: './dashboard-numbers.component.html',
    styleUrls: ['./dashboard-numbers.component.scss'],
})
export class DashboardNumbersComponent {
    @Input() public card: IDashNumber;
}
