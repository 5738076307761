var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ChangeDetectorRef, ElementRef, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { ClrForm } from '@clr/angular';
import { NgSelectComponent } from '@ng-select/ng-select';
import { FormControl, FormGroup } from 'ngx-strongly-typed-forms';
import { minutesToWorkingTime, overEstimatedTime } from '../../../../../defs/businessRules';
import { ApiRoutePlurality, HTTP_METHOD, ISO_DATE_FORMAT, PATTERN_TIME, RIGHTS } from '../../../../../defs/schema-static';
import { EMPLOYEE_SCHEMA_ROUTE } from '../../../../../defs/schema/public/Employees';
import { AuthService } from '../../auth/auth.service';
import { requiredTrimValidator } from '../../forms/validators/required-trim.validator';
import { ConfigService } from '../../shared/config/config.service';
import { HttpRestService } from '../../shared/http-rest/http-rest.service';
import { MomentService } from '../../shared/moment/moment.service';
import { TimerService } from '../../shared/timer/timer.service';
var TimeDetailsComponent = (function () {
    function TimeDetailsComponent(authService, httpRest, cdRef, configService, timerService, momentService) {
        var _this = this;
        this.authService = authService;
        this.httpRest = httpRest;
        this.cdRef = cdRef;
        this.configService = configService;
        this.timerService = timerService;
        this.momentService = momentService;
        this.form = new FormGroup({
            startDate: new FormControl(null, Validators.required),
            time: new FormControl(null, [requiredTrimValidator(), Validators.pattern(PATTERN_TIME)]),
            employeeId: new FormControl(null, Validators.required),
        });
        this.checkDates = function () {
            if (_this.beginDateInputElement && _this.beginDateInputElement.nativeElement.value === '') {
                _this.form.patchValue({ startDate: null });
                _this.beginDate = null;
            }
            else {
                _this.form.patchValue({
                    startDate: _this.beginDate ? _this.momentService.moment(_this.beginDate).format(ISO_DATE_FORMAT) : null,
                });
            }
            return _this.form.controls.startDate.valid;
        };
    }
    TimeDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (!this.employees || !this.employees.length) {
            (function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                return [2, this.getEmployees()];
            }); }); })();
        }
        else if (!this.authService.hasRight(RIGHTS.TIME_UPDATE)) {
            this.employees = this.employees.filter(function (e) { return e.id === _this.authService.user.employee.id; });
        }
    };
    TimeDetailsComponent.prototype.getEmployees = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4, this.httpRest
                                ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, EMPLOYEE_SCHEMA_ROUTE, 'list')
                                .toPromise()];
                    case 1:
                        _a.employees = (_b.sent()).sort(function (c1, c2) { return c1.user.name.localeCompare(c2.user.name); });
                        return [2];
                }
            });
        });
    };
    TimeDetailsComponent.prototype.onLoad = function (params) {
        var _this = this;
        if (params) {
            this.beginDate = params.startDate ? this.momentService.moment(params.startDate).toDate() : new Date();
            var time = '1m';
            if (this.timerService.timer.isStarted && !params.timeId) {
                var duration = this.momentService.moment.duration(this.timerService.timer.time);
                if (duration.asSeconds() < 60) {
                    duration = this.momentService.moment.duration(1, 'minute');
                }
                else if (duration.seconds() > 0) {
                    duration.add(1, 'minute');
                }
                time = minutesToWorkingTime(this.configService.config, Math.floor(duration.asMinutes()));
            }
            else if (params && params.time) {
                time = params.time;
            }
            this.form.patchValue({
                startDate: this.momentService.moment(this.beginDate).format(ISO_DATE_FORMAT),
                employeeId: params.employeeId ? params.employeeId : this.authService.user.employee.id,
                time: time,
            });
        }
        else {
            this.beginDate = new Date();
            this.form.patchValue({
                startDate: this.momentService.moment(this.beginDate).format(ISO_DATE_FORMAT),
            });
        }
        requestAnimationFrame(function () {
            _this.cdRef.markForCheck();
        });
        this.toggle();
    };
    TimeDetailsComponent.prototype.filterEmployee = function (id) {
        this.form.patchValue({
            employeeId: id || null,
        });
        this.setFocus();
    };
    TimeDetailsComponent.prototype.submit = function () {
        if (!this.form.valid) {
            return undefined;
        }
        return this.form;
    };
    TimeDetailsComponent.prototype.toggle = function () {
        var _this = this;
        this.form.controls.startDate.enable();
        this.form.controls.time.enable();
        this.form.controls.employeeId.enable();
        this.setFocus();
        setTimeout(function () {
            _this.cdRef.markForCheck();
        }, 200);
    };
    TimeDetailsComponent.prototype.setFocus = function () {
        var _this = this;
        requestAnimationFrame(function () {
            if (!_this.form.value.employeeId) {
                if (_this.ngSelectEmployee) {
                    _this.ngSelectEmployee.focus();
                    _this.ngSelectEmployee.open();
                }
            }
            else if (!_this.form.value.startDate) {
                if (_this.beginDateInputElement) {
                    _this.beginDateInputElement.nativeElement.focus();
                }
            }
            else {
                _this.setTimeInputSelectionRange();
            }
            _this.cdRef.markForCheck();
        });
    };
    TimeDetailsComponent.prototype.setTimeInputSelectionRange = function () {
        if (!this.timeInputElement) {
            return;
        }
        var $timeInput = this.timeInputElement.nativeElement;
        var time = this.form.value.time || '';
        var match = time.match(/(\d+)\w+\s*$/);
        if (match) {
            $timeInput.setSelectionRange(match.index, match.index + match[1].length, 'none');
            $timeInput.focus();
        }
    };
    TimeDetailsComponent.prototype.checkValidationTime = function () {
        if (overEstimatedTime(this.configService.config, this.form.value.time)) {
            this.form.controls.time.setErrors({ pattern: true });
        }
    };
    return TimeDetailsComponent;
}());
export { TimeDetailsComponent };
