import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
    ClrDatagrid,
    ClrDatagridComparatorInterface,
    ClrDatagridPagination,
    ClrDatagridSortOrder,
    ClrDatagridStateInterface,
} from '@clr/angular';
import {compare} from 'semver';
import {
    ApiRoutePlurality,
    HTTP_METHOD,
    MilestonesType,
    RIGHTS,
    TaskResolution,
    TaskStatusType,
    TaskType,
} from '../../../../../defs/schema-static';
import {IMilestone, MILESTONE_FIELD} from '../../../../../defs/schema/public/Milestones';
import {IProject} from '../../../../../defs/schema/public/Projects';
import {ITask, TASK_FIELD, TASK_SCHEMA_ROUTE} from '../../../../../defs/schema/public/Tasks';
import {TASK_SORT_FUNCTIONS} from '../../../../../defs/sorters';
import {
    DATAGRID_FILTER_TYPE,
    IDatagridColumn,
    IDatagridFilterMap,
    ISerializableDatagridFilter,
    PROJECT_TABS,
    TASK_STATUS_FILTER,
} from '../../app-static';
import {FormsAddTaskComponent} from '../../forms/add-task/add-task.component';
import {FormsAddTaskService} from '../../forms/add-task/add-task.service';
import {validate} from '../../forms/validators/form.validator';
import {HttpRestService} from '../../shared/http-rest/http-rest.service';

@Component({
    selector: 'app-project-tasks',
    templateUrl: './project-tasks.component.html',
    styleUrls: ['./project-tasks.component.scss'],
})
export class ProjectTasksComponent implements OnInit, OnChanges {
    @Input() public project: IProject;
    @Input() public tasks: Partial<ITask>[] = [];

    public pagesTasks: Partial<ITask>[][] = [];

    @ViewChild(ClrDatagrid) public datagrid: ClrDatagrid;
    @ViewChild(ClrDatagridPagination) public clrDgPagination: ClrDatagridPagination;
    @ViewChild(FormsAddTaskComponent) public addTaskComponent: FormsAddTaskComponent;

    public selectedTask: Partial<ITask>;
    public selectedTasks: Partial<ITask>[] = [];

    public showCreateModal = false;
    public showEditModal = false;
    public showDeleteModal = false;

    public taskCount: number;

    public pageSize = 10;

    private editQueryParams: number;
    private dgState: ClrDatagridStateInterface;

    public constructor(
        private readonly httpRest: HttpRestService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly formsAddTaskService: FormsAddTaskService
    ) {}

    public ngOnInit() {
        (async () => this.getTasks())();

        this.route.queryParams.subscribe(async (queryParams) => {
            this.editQueryParams = Number(queryParams.edit) || null;
            await this.selectedTaskChange();
        });
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.project) {
            this.tasks = [];
            (async () => this.getTasks())();

            if (this.editQueryParams) {
                (async () => this.selectedTaskChange())();
            }
        }
    }

    public async refresh(dgState: ClrDatagridStateInterface) {
        this.dgState = dgState;
        this.pagesTasks = [];

        this.selectedTasks = [];

        return this.getTasks();
    }

    public async closeEditModal() {
        return this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {edit: null},
            queryParamsHandling: 'merge',
        });
    }

    public async getTasks() {
        if (!this.project || !this.project.id) {
            return;
        }

        const {pageSize} = this.clrDgPagination;

        if (!pageSize) {
            return;
        }

        if (this.pageSize !== pageSize) {
            this.pageSize = pageSize;
            this.pagesTasks = [];
        }

        const currentPage = this.clrDgPagination.currentPage - 1;

        if (!this.pagesTasks[currentPage]) {
            const {sort = null} = this.dgState || {};

            const dgFilters = (this.dgState.filters || []) as (
                | {property: string; value: string}
                | (ISerializableDatagridFilter))[];

            const customFilters = dgFilters
                .filter((_filter: ISerializableDatagridFilter) => !!_filter.serialize)
                .reduce(
                    (_filters, _filter: ISerializableDatagridFilter) => {
                        _filters[_filter.filterKey] = _filter.serialize();

                        return _filters;
                    },
                    {} as {[key: string]: any[]}
                );

            const filters = dgFilters
                .filter((_filter: {property: string; value: string}) => !(_filter as any).serialize)
                .reduce((_filters, _filter: {property: string; value: string}) => {
                    const {property, value} = _filter;
                    _filters[property] = [value];

                    return _filters;
                }, customFilters);

            const order = [];
            if (sort) {
                order.push([sort.by, sort.reverse ? 'DESC' : 'ASC']);
            }

            const {tasks, count} = await this.httpRest
                ._request<{tasks: ITask[]; count: number}>(
                    HTTP_METHOD.POST,
                    ApiRoutePlurality.PLURAL,
                    TASK_SCHEMA_ROUTE,
                    `project/${this.project.id}`,
                    {
                        pageNumber: currentPage,
                        size: pageSize,
                        filters,
                        order,
                    }
                )
                .toPromise();

            this.taskCount = count;
            this.pagesTasks[currentPage] = tasks;
            this.mapReleases(tasks);
        }

        const pageTasks = this.pagesTasks[currentPage];

        const existingTaskIds = this.tasks.map((task) => task.id);
        const uniqueTasks = pageTasks.filter((task) => !existingTaskIds.includes(task.id));

        this.tasks.push(...uniqueTasks);
        /*console.log(
            this.datagrid.refresh.pipe(first()).subscribe(() => {
                console.log('refresh');
                setTimeout(() => this.datagrid.resize(), 200);
            })
        );*/
        // requestAnimationFrame(() => this.datagrid.resize());
    }

    public async selectedTaskChange() {
        if (!this.editQueryParams) {
            this.selectedTask = null;
            this.showEditModal = false;

            return;
        }

        let task: Partial<ITask> = (this.tasks || []).find((_task) => _task.id === this.editQueryParams);

        if (!task) {
            if (!this.project.id) {
                return;
            }

            const {tasks} = await this.httpRest
                ._request<{tasks: ITask[]; count: number}>(
                    HTTP_METHOD.POST,
                    ApiRoutePlurality.PLURAL,
                    TASK_SCHEMA_ROUTE,
                    `project/${this.project.id}`,
                    {
                        filters: {[TASK_FIELD.id]: [this.editQueryParams]},
                        pageNumber: 0,
                        size: 1,
                    }
                )
                .toPromise();

            [task] = tasks;
        }

        this.selectedTask = task;
        this.showEditModal = true;
    }

    public async updateFilter() {
        return this.getTasks();
    }

    public async deleteSelectedTasks() {
        const deletedTasksIds = this.selectedTasks.map((task) => task.id);

        try {
            await this.httpRest.deleteIds(TASK_SCHEMA_ROUTE, deletedTasksIds).toPromise();
        } catch (err) {
            this.showDeleteModal = false;

            return;
        }

        this.selectedTasks = [];
        this.tasks = this.tasks.filter((task) => !deletedTasksIds.includes(task.id));
        // FIXME this.updateFilter();

        this.showDeleteModal = false;

        this.pagesTasks = [];

        return this.getTasks();
    }

    public get releases(): Partial<IMilestone>[] {
        return (this.project.milestones || [])
            .filter((milestone) => milestone.type === MilestonesType.RELEASE)
            .sort((r1, r2) => compare(r2.version, r1.version));
    }

    public loadAddTask() {
        requestAnimationFrame(() => {
            this.addTaskComponent.load({projectId: this.project.id});
        });
    }
    public async addTask() {
        if (!(await this.formsAddTaskService.submit(this.addTaskComponent.form))) {
            return;
        }
        // this.addTaskComponent.alreadyInit = false;
        this.showCreateModal = false;
        this.pagesTasks = [];

        return this.getTasks();
    }

    public mapReleases(tasks = this.tasks) {
        const releases = this.releases;
        if (!tasks || !releases) {
            return;
        }

        tasks
            .filter((task) => !!task.targetRelease || !!task.targetReleaseId)
            .map(
                (task) =>
                    (task.targetRelease = {
                        ...task.targetRelease,
                        ...(releases.find(
                            (release) => release.id === (task.targetReleaseId || task.targetRelease.id)
                        ) || {}),
                    })
            );
    }

    public readonly RIGHTS = RIGHTS;
    public readonly TASK_FIELD = TASK_FIELD;
    public readonly PROJECT_TABS = PROJECT_TABS;
    public readonly DATAGRID_FILTER_TYPE = DATAGRID_FILTER_TYPE;

    public readonly TaskType = TaskType;
    public readonly TaskStatusType = TaskStatusType;

    public readonly TASKS_COLUMNS: IDatagridColumn[] = [
        {
            field: TASK_FIELD.code,
            translateKey: 'task_code',
            order: ClrDatagridSortOrder.ASC,
            width: '50px',
            hideable: false,
        },
        {field: `${TASK_FIELD.parent}.${TASK_FIELD.code}`, translateKey: 'task_parent', width: '150px', hidden: true},
        {field: TASK_FIELD.name, translateKey: 'task_name'},
        {
            field: TASK_FIELD.type,
            translateKey: 'task_type',
            sorter: {
                compare: TASK_SORT_FUNCTIONS[TASK_FIELD.type],
            } as ClrDatagridComparatorInterface<ITask>,
            width: '75px',
            hidden: true,
        },

        {field: TASK_FIELD.progress, translateKey: 'task_progress', width: '150px'},
        {
            field: `_metadata.${TASK_FIELD.beginDate}`,
            translateKey: 'date_begin',
            width: '150px',
            hidden: true,
            filterType: DATAGRID_FILTER_TYPE.DATE,
        },
        {
            field: `_metadata.${TASK_FIELD.endDate}`,
            translateKey: 'date_end',
            width: '150px',
            hidden: true,
            filterType: DATAGRID_FILTER_TYPE.DATE,
        },
        {
            field: `${TASK_FIELD.targetRelease}.${MILESTONE_FIELD.version}`,
            translateKey: 'version',
            sorter: {
                compare: TASK_SORT_FUNCTIONS[TASK_FIELD.targetReleaseId],
            } as ClrDatagridComparatorInterface<ITask>,
            width: '100px',
        },
        {
            field: TASK_FIELD.status,
            translateKey: 'task_status',
            sorter: {compare: TASK_SORT_FUNCTIONS[TASK_FIELD.status]} as ClrDatagridComparatorInterface<ITask>,
            width: '100px',
            filterType: DATAGRID_FILTER_TYPE.CUSTOM,
        },
        {
            field: TASK_FIELD.resolution,
            translateKey: 'resolution',
            sorter: {compare: TASK_SORT_FUNCTIONS[TASK_FIELD.resolution]} as ClrDatagridComparatorInterface<ITask>,
            width: '100px',
            hidden: true,
            filterType: DATAGRID_FILTER_TYPE.CUSTOM,
        },
    ];
    public filterOpen: {[key: string]: boolean} = {};

    public readonly TASK_RESOLUTION_FILTER: IDatagridFilterMap<TaskResolution> = {
        [TaskResolution.FIXED]: {
            translation: 'fixed',
            classList: 'text-success',
            icon: 'check',
        },
        [TaskResolution.DUPLICATE]: {
            translation: 'duplicate',
            classList: 'text-info',
            icon: 'copy',
        },
        [TaskResolution.WONT_FIX]: {
            translation: 'wont_fix',
            classList: 'text-error',
            icon: 'times',
        },
        [TaskResolution.CANT_REPRODUCE]: {
            translation: 'cant_reproduce',
            classList: 'text-warning',
            icon: 'unknown-status',
        },
    };

    public readonly validate = validate;
    public readonly TASK_STATUS_FILTER = TASK_STATUS_FILTER;
}
