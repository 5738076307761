import {Component, OnInit} from '@angular/core';
import {formatNumber} from 'libphonenumber-js';
import {ApiRoutePlurality, HTTP_METHOD, ReleaseStateType} from '../../../../../defs/schema-static';
import {CLIENT_SCHEMA_ROUTE} from '../../../../../defs/schema/public/Clients';
import {isColorDark} from '../../app-static';
import {AuthService} from '../../auth/auth.service';
import {HttpRestService} from '../../shared/http-rest/http-rest.service';

@Component({
    selector: 'app-contacts',
    templateUrl: './contacts.component.html',
    styleUrls: ['./contacts.component.scss'],
})
export class ContactsComponent implements OnInit {
    public portalData: any;

    public constructor(private readonly httpRest: HttpRestService, private readonly authService: AuthService) {}

    public ngOnInit() {
        this.httpRest
            ._request(
                HTTP_METHOD.GET,
                ApiRoutePlurality.SINGULAR,
                CLIENT_SCHEMA_ROUTE,
                `portaldata/contacts/${this.authService.user.client.id}`
            )
            .subscribe((data) => {
                this.portalData = data;
            });
    }

    protected mailTo = (mail: string) => {
        window.open(`mailto:${mail}`, '_blank');
    };

    protected readonly isColorDark = isColorDark;
    protected readonly ReleaseStateType = ReleaseStateType;
    protected readonly formatNumber = formatNumber;
}
