var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnDestroy, OnInit } from '@angular/core';
import { ClrDatagridSortOrder } from '@clr/angular';
import { TranslateService } from '@ngx-translate/core';
import { ApiRoutePlurality, COGNITO_USER_GROUPS, HTTP_METHOD, RIGHTS } from '../../../../defs/schema-static';
import { EMPLOYEE_FIELD, EMPLOYEE_SCHEMA_ROUTE } from '../../../../defs/schema/public/Employees';
import { USER_FIELD, USER_SCHEMA_ROUTE } from '../../../../defs/schema/public/Users';
import { getRandomColorHex } from '../app-static';
import { AuthService } from '../auth/auth.service';
import { validate } from '../forms/validators/form.validator';
import { HttpRestService } from '../shared/http-rest/http-rest.service';
import { LONG_AVATAR_TYPE } from '../shared/long-avatar/long-avatar.component';
import { SHORTCUT_CREATE, SHORTCUT_LOCAL, ShortcutHandlerService, } from '../shared/shortcut-handler/shortcut-handler.service';
import { TOAST_TYPE, ToastService } from '../shared/toast/toast.service';
var EmployeesComponent = (function () {
    function EmployeesComponent(httpRest, shortcutHandlerService, toastService, translate, authService) {
        this.httpRest = httpRest;
        this.shortcutHandlerService = shortcutHandlerService;
        this.toastService = toastService;
        this.translate = translate;
        this.authService = authService;
        this.showNewEmployeeModal = false;
        this.EMPLOYEES_COLUMNS = [
            {
                name: 'Name',
                field: EMPLOYEE_FIELD.user + "." + USER_FIELD.name,
                translateKey: 'table_name',
                order: ClrDatagridSortOrder.ASC,
            },
            { name: 'Price per day', field: EMPLOYEE_FIELD.dayPrice, translateKey: 'table_price_day', right: RIGHTS.MONEY },
        ];
        this.filterOpen = {};
        this.selectedEmployees = [];
        this.showCreateModal = false;
        this.showDeleteModal = false;
        this.validate = validate;
        this.RIGHTS = RIGHTS;
        this.LONG_AVATAR_TYPE = LONG_AVATAR_TYPE;
    }
    EmployeesComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (!this.employees) {
            (function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                return [2, this.getEmployees()];
            }); }); })();
        }
        this.shortcutHandlerService.register({
            name: SHORTCUT_CREATE.EMPLOYEE,
            shortcut: SHORTCUT_LOCAL.CREATE,
            callback: function () { return (_this.showCreateModal = true); },
            context: this,
        }, true);
    };
    EmployeesComponent.prototype.ngOnDestroy = function () { };
    EmployeesComponent.prototype.getEmployees = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4, this.httpRest
                                ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, EMPLOYEE_SCHEMA_ROUTE, 'employees')
                                .toPromise()];
                    case 1:
                        _a.employees = _b.sent();
                        return [2];
                }
            });
        });
    };
    EmployeesComponent.prototype.addNewEmployee = function (employee) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (this.authService.hasRight(RIGHTS.EMPLOYEE_FETCH_ALL)) {
                    this.employees.push(employee);
                }
                this.toastService.show({
                    type: TOAST_TYPE.SUCCESS,
                    text: 'success_insert_employee',
                });
                return [2];
            });
        });
    };
    EmployeesComponent.prototype.onSubmit = function (f) {
        var _this = this;
        if (!f.valid) {
            return;
        }
        var _a = f.value, empName = _a.empName, empCode = _a.empCode, email = _a.email, empPrice = _a.empPrice;
        var cognitoUsername = f.value.cognitoUsername;
        cognitoUsername = cognitoUsername || null;
        var putCognito = function () {
            return _this.httpRest._request(HTTP_METHOD.PUT, ApiRoutePlurality.SINGULAR, USER_SCHEMA_ROUTE, 'cognito', {
                email: email,
                username: cognitoUsername,
                groupName: COGNITO_USER_GROUPS.EMPLOYEE,
            });
        };
        var putUser = function () {
            return _this.httpRest.put(USER_SCHEMA_ROUTE, {
                cognitoUsername: cognitoUsername,
                email: email,
                name: empName,
                code: empCode,
                color: getRandomColorHex(),
            });
        };
        var putEmployee = function (user) {
            return _this.httpRest.put(EMPLOYEE_SCHEMA_ROUTE, {
                userId: user.id,
                dayPrice: empPrice,
            });
        };
        putCognito().subscribe(function () {
            return putUser().subscribe(function (user) {
                return putEmployee(user).subscribe(function (employee) {
                    _this.showNewEmployeeModal = false;
                    _this.employees.push(employee);
                });
            });
        });
    };
    EmployeesComponent.prototype.deleteSelectedEmployees = function () {
        return __awaiter(this, void 0, void 0, function () {
            var deletedEmployeesIds, err_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        deletedEmployeesIds = this.selectedEmployees.map(function (_a) {
                            var id = _a.id;
                            return id;
                        });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4, Promise.all(deletedEmployeesIds.map(function (employeeId) { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    return [2, this.httpRest
                                            ._request(HTTP_METHOD.DELETE, ApiRoutePlurality.SINGULAR, EMPLOYEE_SCHEMA_ROUTE, "/employee/withUser/" + employeeId)
                                            .toPromise()];
                                });
                            }); }))];
                    case 2:
                        _a.sent();
                        return [3, 5];
                    case 3:
                        err_1 = _a.sent();
                        return [2];
                    case 4:
                        this.showDeleteModal = false;
                        return [7];
                    case 5:
                        this.selectedEmployees = [];
                        this.employees = this.employees.filter(function (_a) {
                            var id = _a.id;
                            return !deletedEmployeesIds.includes(id);
                        });
                        this.toastService.show({
                            type: TOAST_TYPE.SUCCESS,
                            text: 'success_delete_employee',
                        });
                        return [2];
                }
            });
        });
    };
    return EmployeesComponent;
}());
export { EmployeesComponent };
