import {Injectable, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {ApiRoutePlurality, HTTP_METHOD} from '../../../../defs/schema-static';
import {INotification, NOTIFICATION_SCHEMA_ROUTE, NOTIFICATION_TYPE} from '../../../../defs/schema/public/Notification';
import {AuthService} from '../auth/auth.service';
import {HttpRestService} from './http-rest/http-rest.service';
import {MomentService} from './moment/moment.service';

@Injectable({
    providedIn: 'root',
})
export class NotificationService implements OnDestroy {
    private permission: NotificationPermission;
    public notifications: Partial<INotification>[];

    private checkInterval: number;
    private userChangeSubscription: Subscription;

    public constructor(
        private readonly httpRest: HttpRestService,
        private readonly authService: AuthService,
        private readonly momentService: MomentService
    ) {
        this.userChangeSubscription = this.authService.userChange.subscribe(async () => this.init());

        (async () => this.init())();
    }

    public ngOnDestroy() {
        clearInterval(this.checkInterval);

        if (this.userChangeSubscription) {
            this.userChangeSubscription.unsubscribe();
            this.userChangeSubscription = null;
        }
    }

    private async init() {
        clearInterval(this.checkInterval);

        if (!this.authService.user) {
            return;
        }

        this.notifications = sessionStorage.notifications ? JSON.parse(sessionStorage.notifications) : [];

        Notification.requestPermission().then(() => (this.permission = Notification.permission));

        (async () => this.fetchNotifications())();
        this.checkInterval = window.setInterval(async () => this.fetchNotifications(), 1000 * 60);
    }

    public get notificationsNotClosed(): Partial<INotification>[] {
        if (!this.authService.user || !this.notifications) {
            return [];
        }

        return this.notifications.filter(({closed}) => !closed);
    }

    private readonly onNotificationClicked = async (notification: Partial<INotification>): Promise<void> => {
        return this.markNotificationAsSeen(notification);
    };

    public async markNotificationAsSeen(notification: Partial<INotification>): Promise<void> {
        notification.seen = true;

        return this._updateNotification(notification);
    }

    public async markNotificationAsClosed(notification: Partial<INotification>): Promise<void> {
        notification.closed = true;

        return this._updateNotification(notification);
    }

    public getNotificationText(notification: Partial<INotification>): string {
        if (notification.type === NOTIFICATION_TYPE.EVENT_REMINDER) {
            return `${notification.milestone.obs} @ ${this.momentService
                .moment(notification.milestone.beginDate)
                .format('L LTS')}`;
        }

        if (notification.type === NOTIFICATION_TYPE.TASK_ASSIGNED) {
            return `${notification.task.project.code}-${notification.task.code} ${notification.task.name}`;
        }

        return 'unknown type';
    }

    private async _updateNotification(notification: Partial<INotification>): Promise<void> {
        await this.httpRest
            .post(NOTIFICATION_SCHEMA_ROUTE, notification as (Partial<INotification> & {id: number}))
            .toPromise();
    }

    public sendNotification(notification: Partial<INotification>) {
        if (this.permission === 'granted') {
            this._sendBrowserNotification(notification);
        } else if (this.permission !== 'denied') {
            Notification.requestPermission().then((permission) => {
                this.permission = permission;
                this._sendBrowserNotification(notification);
            });
        }
    }

    private _sendBrowserNotification(notification: Partial<INotification>) {
        const _notification = new Notification(this.getNotificationText(notification));

        _notification.onclose = _notification.onclick = async () => this.onNotificationClicked(notification);
    }

    private async fetchNotifications() {
        const notifications = await this.httpRest
            ._request<INotification[]>(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, NOTIFICATION_SCHEMA_ROUTE, '')
            .toPromise();
        {
            const existingNotificationIds = this.notifications.map(({id}) => id);
            const newNotifications = notifications.filter(({id}) => !existingNotificationIds.includes(id));

            this.notifications.push(...newNotifications);

            newNotifications
                .filter(({seen, closed}) => !seen && !closed)
                .map((notification) => this.sendNotification(notification));
        }

        {
            const newNotificationIds = notifications.map(({id}) => id);
            const removedNotifications = this.notifications.filter(({id}) => !newNotificationIds.includes(id));

            if (removedNotifications.length) {
                this.notifications = [...this.notifications.filter(({id}) => newNotificationIds.includes(id))];
            }
        }
    }
}
