<div class="login-wrapper">
    <form class="login" (submit)="submit()">
        <section class="title">
            <h3 class="welcome" translate="">welcome</h3>
            Project Manager
            <h5 class="hint" translate>login</h5>
        </section>
        <div class="login-group">
            <clr-input-container>
                <input
                    type="text"
                    name="username"
                    autocomplete="username"
                    clrInput
                    placeholder="{{ ('username' | translate) || 'username' }}"
                    [(ngModel)]="username"
                    sharedAutofocus
                />
            </clr-input-container>
            <clr-password-container>
                <input
                    type="password"
                    name="password"
                    autocomplete="current-password"
                    clrPassword
                    placeholder="{{ ('password' | translate) || 'password' }}"
                    [(ngModel)]="password"
                />
            </clr-password-container>
            <div class="error" [class.active]="error">{{ error | translate }}</div>

            <button [clrLoading]="loadingState" type="submit" class="btn btn-primary" [disabled]="!valid()">
                <span translate>login</span>
            </button>
            <h3 translate>need_help_question</h3>
            <a class="signup c-hand" (click)="forgotPassword()">
                <span translate>password_reset</span>
            </a>
            <!-- <a class="signup" routerLink="/auth/signup" [queryParams]="{username: username || null}">{{
                'signup' | translate
            }}</a> -->
        </div>
    </form>
</div>
