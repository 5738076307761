<form id="addTaskForm" clrForm clrLayout="vertical" #taskForm="ngForm" autocomplete="off">
    <div [formGroup]="form">
        <div class="recursive" *ngIf="noParent">
            <clr-checkbox-wrapper>
                <input
                    type="checkbox"
                    clrCheckbox
                    id="recursive"
                    [formControlName]="AddTaskFormField.recursive"
                    (change)="onRecursiveChange()"
                />
                <label for="recursive" class="clr-col-xs-12"> {{ 'recursive_add' | translate }} </label>
            </clr-checkbox-wrapper>
        </div>

        <clr-textarea-container class="mt-0 p-0">
            <label class="clr-col-xs-12 clr-col-md-3 p-0" translate>task_name</label>
            <textarea
                class="clr-col-12"
                clrTextarea
                type="text"
                id="name"
                [formControlName]="AddTaskFormField.name"
                sharedListAutoIndent
                rows="0"
                [minHeight]="'44'"
                [maxLength]="250"
                placeholder="{{ 'task_name' | translate }}"
                [sharedAutofocus]="show"
                [sharedEventBinder]="[SHORTCUT_MISC.TEXTAREA_SUBMIT, 'submit']"
                sharedAutosize
                #nameInput
            ></textarea>
            <clr-control-helper>
                <small class="float-right" [ngClass]="{'text-error': getInputNameLength() >= MAX_LENGTH_TASK_NAME}"
                    >{{ getInputNameLength() }}/{{ MAX_LENGTH_TASK_NAME }}</small
                >
            </clr-control-helper>
        </clr-textarea-container>

        <clr-textarea-container class="p-0">
            <label class="clr-col-xs-12 clr-col-md-3 p-0" translate>comment</label>
            <textarea
                clrTextarea
                class="clr-col-12"
                type="text"
                id="comment"
                [formControlName]="AddTaskFormField.comment"
                sharedListAutoIndent
                rows="1"
                [minHeight]="'44'"
                placeholder="{{ 'comment' | translate }}"
                sharedAutosize
                #commentInput
            ></textarea>
        </clr-textarea-container>

        <clr-checkbox-container clrInline>
            <clr-checkbox-wrapper>
                <input type="checkbox" clrCheckbox id="bug" [formControlName]="AddTaskFormField.bug" />
                <label for="bug" class="form-checkbox float-left">
                    <clr-icon shape="bug"></clr-icon> {{ 'task_isbug' | translate }}
                </label>
            </clr-checkbox-wrapper>
            <clr-checkbox-wrapper>
                <input type="checkbox" clrCheckbox id="urgent" [formControlName]="AddTaskFormField.urgent" />
                <label for="urgent" class="form-checkbox float-left">
                    <clr-icon shape="exclamation-triangle"></clr-icon>
                    {{ 'task_isurgent' | translate }}
                </label>
            </clr-checkbox-wrapper>
            <clr-checkbox-wrapper style="display: none;">
                <input type="checkbox" clrCheckbox id="tracked" [formControlName]="AddTaskFormField.tracked" />
                <label for="tracked" class="form-checkbox float-right">
                    <clr-icon shape="flame"></clr-icon>
                    {{ 'task_tracked' | translate }}
                </label>
            </clr-checkbox-wrapper>
        </clr-checkbox-container>

        <clr-input-container> <label translate>project</label> </clr-input-container>
        <ng-select
            class="less-pl p-0"
            [items]="activeProjects"
            bindLabel="obs"
            bindValue="id"
            [formControlName]="AddTaskFormField.project"
            groupBy="status"
            [clearable]="false"
            (change)="loadProject()"
        >
            <ng-template ng-optgroup-tmp let-item="item">
                {{ 'project_' + item.status | lowercase | translate }}
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index">
                <app-long-avatar [code]="item?.code" [bgColor]="item.color"> </app-long-avatar>
                {{ item.obs }}
            </ng-template>
            <ng-template ng-label-tmp let-item="item">
                <app-long-avatar [code]="item?.code" [bgColor]="item.color"> </app-long-avatar>
                {{ item.obs }}
            </ng-template>
        </ng-select>

        <div class="clr-row">
            <div class="clr-col-6" *ngIf="releases.length">
                <clr-input-container> <label translate>release</label> </clr-input-container>
                <ng-select
                    class="less-pl p-0"
                    [formControlName]="AddTaskFormField.releaseId"
                    [items]="releases"
                    dropdownPosition="top"
                    bindLabel="obs"
                    bindValue="id"
                    groupBy="releaseState"
                    (clear)="onReleaseIdChange()"
                    (change)="onReleaseIdChange($event)"
                >
                    <ng-template ng-optgroup-tmp let-item="item"> {{ item.releaseState | translate }} </ng-template>
                    <ng-template ng-label-tmp let-item="item">
                        <div>
                            <small class="label label-info"> {{ item.version }} </small>
                            <small class="text-ellipsis task-label"> {{ item.obs }} </small>
                            <span aria-hidden="true" class="ng-value-icon left">×</span>
                        </div></ng-template
                    >
                    <ng-template ng-option-tmp let-item="item" let-index="index">
                        <small class="label label-info vab"> {{ item.version }} </small>
                        <small class="text-ellipsis task-label"> {{ item.obs }} </small>
                    </ng-template>
                </ng-select>
            </div>
            <div class="clr-col-6" *ngIf="tasks.length">
                <clr-input-container> <label translate>task_parent</label> </clr-input-container>
                <ng-select
                    class="p-0"
                    [formControlName]="AddTaskFormField.parentTaskId"
                    [items]="tasksParent | async"
                    [typeahead]="tasksParent$"
                    [loading]="tasksParentLoading"
                    bindLabel="name"
                    dropdownPosition="top"
                    bindValue="id"
                    (clear)="onParentChange()"
                    (change)="onParentChange($event)"
                >
                    <ng-template ng-label-tmp let-item="item">
                        <div *ngIf="item.code">
                            <small class="label label-info"> {{ item.code }} </small>
                            <small class="text-ellipsis task-label"> {{ item.name }} </small>
                        </div>
                        <div *ngIf="!item.code">
                            <small class="label label-info"> {{ parentTask.code }} </small>
                            <small class="text-ellipsis task-label"> {{ parentTask.name }} </small>
                        </div>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-index="index">
                        <small class="label label-info vab"> {{ item.code }} </small>
                        <small class="text-ellipsis task-label"> {{ item.name }} </small>
                    </ng-template>
                </ng-select>
            </div>
        </div>

        <div class="clr-row">
            <div class="clr-col-4">
                <clr-input-container>
                    <label translate>estimated_time</label>
                    <input
                        clrInput
                        type="text"
                        [formControlName]="AddTaskFormField.time"
                        [placeholder]="'0m'"
                        sharedDebounce
                        (debounceFunction)="checkValidationTime()"
                    />
                    <clr-control-helper>10w 52d 10h 18m</clr-control-helper>

                    <clr-control-error [innerHTML]="'help_time' | translate"></clr-control-error>
                </clr-input-container>
            </div>
            <div class="clr-col-4">
                <clr-date-container>
                    <label translate
                        >date_begin
                        <small
                            *ngIf="currentRelease"
                            class="release-date tooltip-sm"
                            [attr.data-tooltip]="'release_begin_date' | translate"
                        >
                            ({{ currentRelease?.beginDate | momentFormat }})
                        </small></label
                    >
                    <input type="date" id="date" name="date" [(clrDate)]="beginDate" #beginDateInputElement />
                </clr-date-container>
            </div>
            <div class="clr-col-4">
                <clr-date-container>
                    <label translate
                        >date_end
                        <small
                            *ngIf="currentRelease"
                            class="release-date tooltip-sm"
                            [attr.data-tooltip]="'release_end_date' | translate"
                        >
                            ({{ currentRelease.endDate || currentRelease.target | momentFormat }})
                        </small></label
                    >
                    <input type="date" id="end" name="end" [(clrDate)]="endDate" #endDateInputElement />
                </clr-date-container>
            </div>
        </div>

        <ng-container *ngIf="form">
            <div class="clr-row mt-2" *ngIf="!checkDates()">
                <div class="clr-col-12">
                    <div class="alert alert-danger" role="alert">
                        <div class="alert-items">
                            <div class="alert-item static">
                                <div class="alert-icon-wrapper">
                                    <clr-icon class="alert-icon" shape="exclamation-circle"></clr-icon>
                                </div>
                                <span class="alert-text" [innerHTML]="'error_date' | translate"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

        <div class="clr-row">
            <div class="clr-col-6">
                <clr-input-container> <label translate>tasks_assignees</label> </clr-input-container>
                <ng-select
                    class="p-0"
                    [items]="employeesNotAssigned"
                    [hideSelected]="true"
                    [bindLabel]="EMPLOYEE_FIELD.user + '.' + USER_FIELD.name"
                    multiple="true"
                    dropdownPosition="top"
                    [formControlName]="'assigned'"
                >
                    <ng-template ng-option-tmp let-item="item" let-index="index">
                        <app-long-avatar
                            class="text-bold float-left mr-02"
                            [code]="item.user.code"
                            [bgColor]="item.user.color"
                        >
                        </app-long-avatar>

                        {{ item?.user?.name }}
                    </ng-template>
                    <ng-template ng-label-tmp let-item="item">
                        <shared-editable-employee-label
                            [member]="{employee: item}"
                            [removable]="true"
                            (remove)="removeAssigned($event.employee)"
                            [type]="EMPLOYEE_MEMBER_TYPE.CUSTOMER_CONTACT"
                        >
                        </shared-editable-employee-label>
                    </ng-template>
                </ng-select>
            </div>
            <div class="clr-col-6">
                <clr-input-container> <label translate>tags</label> </clr-input-container>
                <ng-select
                    class="p-0"
                    [items]="tags"
                    [hideSelected]="true"
                    bindValue="id"
                    bindLabel="text"
                    [addTag]="onTagSubmit"
                    multiple="true"
                    dropdownPosition="top"
                    [formControlName]="'tags'"
                    #selectTag
                >
                    <ng-template ng-label-tmp let-item="item">
                        <shared-task-tag
                            [tag]="item"
                            [updatable]="true"
                            [removable]="true"
                            [branchable]="false"
                            (update)="updateTag(null, $event)"
                            (remove)="removeTag($event)"
                        >
                        </shared-task-tag
                    ></ng-template>
                    <ng-template ng-tag-tmp let-search="searchTerm">
                        <b translate>new_tag</b>: {{ search }}
                    </ng-template>
                </ng-select>
            </div>
        </div>

        <ng-container *ngIf="tasks.length">
            <clr-input-container> <label translate>task_blockers</label> </clr-input-container>
            <ng-select
                class="p-0"
                [items]="addBlockers.tasksBlockers | async"
                [typeahead]="addBlockers.tasksBlockers$"
                [loading]="addBlockers.tasksBlockersLoading"
                bindLabel="name"
                dropdownPosition="top"
                bindValue="id"
                [hideSelected]="true"
                multiple="true"
                [formControlName]="'blockers'"
            >
                <ng-template ng-label-tmp let-item="item">
                    <div>
                        <small class="label label-info"> {{ item.code }} </small>
                        <small class="text-ellipsis task-label d-inlineb"> {{ item.name }} </small>
                        <span aria-hidden="true" class="ng-value-icon left" (click)="addBlockers.removeBlocker(item)"
                            >×</span
                        >
                    </div></ng-template
                >
                <ng-template ng-option-tmp let-item="item">
                    <small class="label label-info va-m"> {{ item.code }} </small>
                    <small class="text-ellipsis task-label "> {{ item.name }} </small>
                </ng-template>
            </ng-select>
        </ng-container>
    </div>
</form>
