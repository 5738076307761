import {Component, Input, OnInit} from '@angular/core';
import {ApiRoutePlurality, HTTP_METHOD} from '../../../../../defs/schema-static';
import {COGNITO_SCHEMA_ROUTE, ICognitoGroup} from '../../../../../defs/schema/public/Cognito';
import {IEmployee} from '../../../../../defs/schema/public/Employees';
import {IResettable} from '../../app-static';
import {HttpRestService} from '../../shared/http-rest/http-rest.service';

@Component({
    selector: 'app-employee-wizard-group-rights',
    templateUrl: './employee-wizard-group-rights.component.html',
})
export class EmployeeWizardGroupRightsComponent implements OnInit, IResettable {
    @Input() public employee: IEmployee;
    @Input() public groups: ICognitoGroup[];

    public selectedGroups: ICognitoGroup[] = [];

    public constructor(private readonly httpRest: HttpRestService) {}

    public ngOnInit() {
        if (!this.groups) {
            (async () => this.getGroups())();
        }
    }

    public async getGroups() {
        this.groups = await this.httpRest
            ._request<ICognitoGroup[]>(HTTP_METHOD.GET, ApiRoutePlurality.SINGULAR, COGNITO_SCHEMA_ROUTE, 'groups')
            .toPromise();
    }

    public async submit(): Promise<ICognitoGroup[]> {
        if (!this.employee || !this.employee.user || !this.employee.user.cognitoUsername) {
            throw new Error("missing employee cognitoUsername, can't submit");
        }

        if (!this.selectedGroups || !this.selectedGroups.length) {
            return undefined;
        }

        return Promise.all(
            (this.selectedGroups || []).map(async ({groupName}) =>
                this.httpRest
                    ._request<ICognitoGroup>(
                        HTTP_METHOD.PUT,
                        ApiRoutePlurality.SINGULAR,
                        COGNITO_SCHEMA_ROUTE,
                        'user',
                        {
                            username: this.employee.user.cognitoUsername,
                            groupName,
                        }
                    )
                    .toPromise()
            )
        );
    }

    public reset(): void {
        this.selectedGroups = [];
    }
}
