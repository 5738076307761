import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {HOURS_IN_DAY, MINUTES_IN_HOUR, notWorkingDays} from '../../../../defs/businessRules';
import {MILLISECONDS_IN_SECOND, SECONDS_IN_MINUTE, TaskStatusType} from '../../../../defs/schema-static';
import {IDashMilestone} from '../dashboard/dashboard.component';
import {ConfigService} from '../shared/config/config.service';
import {MomentService} from '../shared/moment/moment.service';

const MILLISECONDS_IN_DAY = MILLISECONDS_IN_SECOND * SECONDS_IN_MINUTE * MINUTES_IN_HOUR * HOURS_IN_DAY;

@Component({
    selector: 'app-dashboard-roadmap',
    templateUrl: './dashboard-roadmap.component.html',
    styleUrls: ['./dashboard-roadmap.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class DashboardRoadmapComponent implements OnInit {
    @Input()
    public roadmap: IDashMilestone;
    public total: number;
    public done: number;
    public doneRatio: string;
    public late: number;
    public taskLate: boolean;
    public possiblyLate: boolean;
    public distribTasks: string[];
    public stats: {label: string; value: number}[] = [];

    public labelLate: string;

    public constructor(
        private readonly router: Router,
        private readonly configService: ConfigService,
        private readonly translate: TranslateService,
        private readonly momentService: MomentService
    ) {}

    public ngOnInit() {
        this.done = this.roadmap.distribution[TaskStatusType.DONE];
        this.total = Object.values(this.roadmap.distribution).reduce((t, v) => t + v, 0);
        this.doneRatio = Math.floor((this.done / this.total) * 100).toString();

        const startDate = this.momentService.moment(this.roadmap.milestone.beginDate);
        const target = this.momentService.moment(this.roadmap.milestone.target);

        this.late = this.momentService
            .moment()
            .startOf('day')
            .diff(target.startOf('day'));
        this.taskLate = this.roadmap.time.lateTasks.length > 0;
        this.distribTasks = [];

        const predictedTime =
            target.diff(startDate) - notWorkingDays(this.configService.config, target, startDate, 'milliseconds');
        const now = this.momentService.moment();
        const remainingTime = target.diff(now) - notWorkingDays(this.configService.config, target, now, 'milliseconds');
        const timeRatio = 1 - remainingTime / predictedTime;
        this.labelLate = 'late';
        this.translate
            .get('late', {
                day: Math.abs(Math.floor(remainingTime / MILLISECONDS_IN_DAY)),
            })
            .subscribe((res) => (this.labelLate = res));

        let labelsPredicted = '';
        this.translate
            .get('left_predicted', {
                left: Math.floor(remainingTime / MILLISECONDS_IN_DAY),
                predicted: Math.floor(predictedTime / MILLISECONDS_IN_DAY),
            })
            .subscribe((res) => (labelsPredicted = res));

        let labelRelease = '';
        this.translate.get('please_release').subscribe((res) => (labelRelease = res));

        if (this.late > 0) {
            if (this.done === this.total) {
                this.distribTasks.push(labelRelease);
            }
        } else {
            this.distribTasks.push(labelsPredicted);
        }
        if (timeRatio > 0.5) {
            this.possiblyLate = this.roadmap.time.done / this.roadmap.time.total < timeRatio;
        }
        if (this.taskLate) {
            this.roadmap.time.lateTasks.map((task) => {
                const endDate = this.momentService.moment(task.endDate);
                const taskRemainingTime =
                    endDate.diff(now) - notWorkingDays(this.configService.config, endDate, now, 'milliseconds');
                let taskLate = '';
                this.translate
                    .get('task_late', {
                        code: task.code,
                        day: Math.abs(Math.floor(taskRemainingTime / MILLISECONDS_IN_DAY)),
                    })
                    .subscribe((res) => (taskLate = res));
                this.distribTasks.push(taskLate);
            });
        }

        this.stats = Object.keys(this.roadmap.distribution)
            .filter((f) => this.roadmap.distribution[f] > 0)
            .map((key) => {
                return {label: key, value: this.roadmap.distribution[key]};
            });
    }
}
