import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ApiRoutePlurality, HTTP_METHOD} from '../../../../../defs/schema-static';
import {ITag} from '../../../../../defs/schema/public/Tags';
import {TASK_TAG_SCHEMA_ROUTE} from '../../../../../defs/schema/public/TaskTags';
import {HttpRestService} from '../http-rest/http-rest.service';

@Component({
    selector: 'shared-task-tag',
    templateUrl: './task-tag.component.html',
    styleUrls: ['./task-tag.component.scss'],
})
export class TaskTagComponent {
    @Input()
    public tag: ITag;
    @Input()
    public updatable = false;
    @Input()
    public branchable = false;
    @Input()
    public removable = false;

    @Input()
    public deletable = false;

    @Output()
    public update = new EventEmitter<ITag>();
    @Output()
    public updateBranch = new EventEmitter<ITag>();
    @Output()
    public remove = new EventEmitter<ITag>();

    public showDeleteModal = false;
    public count = 0;

    public constructor(private readonly httpRest: HttpRestService) {}

    public delete() {
        this.showDeleteModal = true;

        this.httpRest
            ._request<{taskCount: number}>(
                HTTP_METHOD.GET,
                ApiRoutePlurality.SINGULAR,
                TASK_TAG_SCHEMA_ROUTE,
                `count/tasks/${this.tag.id}`
            )
            .subscribe((res) => {
                this.count = res.taskCount;
            });
    }

    public doDelete() {
        this.remove.emit(this.tag);
    }
}
