// tslint:disable:no-magic-numbers
// tslint:disable:prefer-function-over-method
import moment from 'moment';

export interface IMomentFerieMethods {
    easterDay(Y: number): moment.Moment;

    lundiDePaques(Y: number): moment.Moment;

    ascension(Y: number): moment.Moment;

    pentecote(Y: number): moment.Moment;

    jourDeLAn(Y: number): moment.Moment;

    feteDuTravail(Y: number): moment.Moment;

    victoireDeAllies(Y: number): moment.Moment;

    feteNationale(Y: number): moment.Moment;

    assomption(Y: number): moment.Moment;

    toussaint(Y: number): moment.Moment;

    armistice(Y: number): moment.Moment;

    noel(Y: number): moment.Moment;

    getFerieList(Y: number): IferieList[];

    getFerie(): string;

    isFerie(): boolean;

    // isWeekEnd(): boolean;
    //
    // isWorkingDay(): boolean;
}

export interface IferieList {
    name: string;
    date: moment.Moment;
}

export class DateFerie implements IMomentFerieMethods {
    public date: moment.Moment;

    public listeFerie: any = {
        "Jour de l'an": this.jourDeLAn,
        'Fête du travail': this.feteDuTravail,
        'Victoire des alliés': this.victoireDeAllies,
        'Fête Nationale': this.feteNationale,
        Assomption: this.assomption,
        Toussaint: this.toussaint,
        Armistice: this.armistice,
        Noël: this.noel,
        Pâques: this.easterDay,
        'Lundi de Pâques': this.lundiDePaques,
        Ascension: this.ascension,
        Pentecôte: this.pentecote,
    };

    public constructor(date: moment.Moment) {
        this.date = date;
    }

    public easterDay(Y: number): moment.Moment {
        if (Y === undefined) {
            Y = moment().year();
        }
        const a = Y % 19;
        const b = Math.floor(Y / 100);
        const c = Y % 100;
        const d = Math.floor(b / 4);
        const e = b % 4;
        const f = Math.floor((b + 8) / 25);
        const g = Math.floor((b - f + 1) / 3);
        const h = (19 * a + b - d - g + 15) % 30;
        const i = Math.floor(c / 4);
        const k = c % 4;
        const l = (32 + 2 * e + 2 * i - h - k) % 7;
        const m = Math.floor((a + 11 * h + 22 * l) / 451);
        const n0 = h + l + 7 * m + 114;
        const n = Math.floor(n0 / 31) - 1;
        const p = (n0 % 31) + 1;
        const date = new Date(Y, n, p);

        return moment(date);
    }

    public lundiDePaques(Y: number): moment.Moment {
        if (Y === undefined) {
            Y = moment().year();
        }

        return this.easterDay(Y).add(1, 'days');
    }

    public ascension(Y: number): moment.Moment {
        if (Y === undefined) {
            Y = moment().year();
        }

        return this.easterDay(Y).add(39, 'days');
    }

    public pentecote(Y: number): moment.Moment {
        if (Y === undefined) {
            Y = moment().year();
        }

        return this.easterDay(Y).add(50, 'days');
    }

    public jourDeLAn(Y: number): moment.Moment {
        if (Y === undefined) {
            Y = moment().year();
        }

        return moment(`1-1-${Y}`, 'DD-MM-YYYY');
    }

    public feteDuTravail(Y: number): moment.Moment {
        if (Y === undefined) {
            Y = moment().year();
        }

        return moment(`1-5-${Y}`, 'DD-MM-YYYY');
    }

    public victoireDeAllies(Y: number): moment.Moment {
        if (Y === undefined) {
            Y = moment().year();
        }

        return moment(`8-5-${Y}`, 'DD-MM-YYYY');
    }

    public feteNationale(Y: number): moment.Moment {
        if (Y === undefined) {
            Y = moment().year();
        }

        return moment(`14-7-${Y}`, 'DD-MM-YYYY');
    }

    public assomption(Y: number): moment.Moment {
        if (Y === undefined) {
            Y = moment().year();
        }

        return moment(`15-8-${Y}`, 'DD-MM-YYYY');
    }

    public toussaint(Y: number): moment.Moment {
        if (Y === undefined) {
            Y = moment().year();
        }

        return moment(`1-11-${Y}`, 'DD-MM-YYYY');
    }

    public armistice(Y: number): moment.Moment {
        if (Y === undefined) {
            Y = moment().year();
        }

        return moment(`11-11-${Y}`, 'DD-MM-YYYY');
    }

    public noel(Y: number): moment.Moment {
        if (Y === undefined) {
            Y = moment().year();
        }

        return moment(`25-12-${Y}`, 'DD-MM-YYYY');
    }

    public getFerieList(Y: number): IferieList[] {
        if (Y === undefined) {
            Y = moment().year();
        }

        const res: IferieList[] = [];
        for (const key in this.listeFerie) {
            if (this.listeFerie.hasOwnProperty(key)) {
                res.push({name: key, date: this.listeFerie[key](Y)});
            }
        }

        return res;
    }

    public getFerie(): string {
        for (const key in this.listeFerie) {
            if (this.listeFerie.hasOwnProperty(key)) {
                if (this.date.isSame(this.listeFerie[key].call(this), 'days')) {
                    return key;
                }
            }
        }

        return null; // tslint:disable-line
    }

    public isFerie(): boolean {
        return this.getFerie() !== null;
    }

    // working days are now alterable from the admin panel
    // public isWeekEnd(): boolean {
    //     return (this.date.isoWeekday() === 6 || this.date.isoWeekday() === 7);
    // }
    //
    // public isWorkingDay(): boolean {
    //     return (!this.isWeekEnd() && !this.isFerie());
    // }
}
