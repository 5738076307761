import {Component, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {FormControl, FormGroup} from 'ngx-strongly-typed-forms';
import {ApiRoutePlurality, DECIMAL_RADIX, HTTP_METHOD} from '../../../../defs/schema-static';
import {IIncident, INCIDENT_SCHEMA_ROUTE} from '../../../../defs/schema/public/Incidents';
import {IProject, PROJECT_SCHEMA_ROUTE} from '../../../../defs/schema/public/Projects';
import {sortDates, sortNumbers, sortStrings} from '../app-static';
import {AuthService} from '../auth/auth.service';
import {HttpRestService} from '../shared/http-rest/http-rest.service';

interface IIncidentFormValues {
    title: string;
    description: string;
    project: number;
}
enum INCIDENT_FORM_KEYS {
    title = 'title',
    description = 'description',
    project = 'project',
}
@Component({
    selector: 'app-incident',
    templateUrl: './incident.component.html',
    styleUrls: ['./incident.component.scss'],
})
export class IncidentComponent {
    public readonly form = new FormGroup<Partial<IIncidentFormValues>>({
        title: new FormControl<string>(),
        description: new FormControl<string>(),
        project: new FormControl<number>(),
    });

    private incidents: IIncident[] = [];
    // private displayIncidents: IIncident[] = [];
    public showNewIncidentModal = false;
    public pleaseWait = true;
    private projects: IProject[] = [];
    private clientId: number;

    public constructor(private readonly httpRest: HttpRestService, private readonly route: ActivatedRoute) {
        this.route.params.subscribe((params) => {
            this.clientId = parseInt(params.id, DECIMAL_RADIX);
        });
        this.httpRest
            ._request<IIncident[]>(
                HTTP_METHOD.GET,
                ApiRoutePlurality.PLURAL,
                INCIDENT_SCHEMA_ROUTE,
                `client/${this.clientId}`
            )
            .subscribe((incidents) => {
                this.incidents = incidents;
                this.pleaseWait = false;
                // this.displayIncidents = [...this.incidents];
            });

        this.httpRest
            ._request<IProject[]>(
                HTTP_METHOD.GET,
                ApiRoutePlurality.PLURAL,
                PROJECT_SCHEMA_ROUTE,
                `clientLight/${this.clientId}`
            )
            .subscribe((projects) => (this.projects = projects));
    }

    protected getProject(projectId: number): IProject {
        return this.projects.find((p) => p.id === projectId);
    }

    public updateFilter = (event: Event) => {
        const target = event.target as HTMLInputElement;

        const val = target.value.toLowerCase();

        // this.displayIncidents = this.incidents.filter((d) => {
        //     return (
        //         d.title.toLowerCase().indexOf(val) !== -1 ||
        //         d.description.toLowerCase().indexOf(val) !== -1 ||
        //         d.status.toLowerCase().indexOf(val) !== -1 ||
        //         (this.getProject(d.projectId) &&
        //             this.getProject(d.projectId)
        //                 .obs.toLowerCase()
        //                 .indexOf(val) !== -1) ||
        //         !val
        //     );
        // });
    };

    protected onSubmit(f: NgForm) {
        if (!f.valid) {
            return;
        }

        this.httpRest
            .put<IIncident>(INCIDENT_SCHEMA_ROUTE, {
                title: f.value.title,
                description: f.value.description,
                clientId: this.clientId,
                projectId: f.value.project,
            })
            .subscribe((newRequest) => {
                this.incidents.push(newRequest);
                // this.displayIncidents = [...this.incidents];
            });
        this.showNewIncidentModal = false;
    }

    public readonly INCIDENT_FORM_KEYS = INCIDENT_FORM_KEYS;
    protected readonly sortStrings = sortStrings;
    protected readonly sortNumbers = sortNumbers;
    protected readonly sortDates = sortDates;
}
