import {Component, OnDestroy, OnInit} from '@angular/core';
import {ClrDatagridSortOrder} from '@clr/angular';
import {TranslateService} from '@ngx-translate/core';
import {ApiRoutePlurality, HTTP_METHOD, RIGHTS} from '../../../../defs/schema-static';
import {CLIENT_FIELD, CLIENT_SCHEMA_ROUTE, IClient} from '../../../../defs/schema/public/Clients';
import {IEmployee} from '../../../../defs/schema/public/Employees';
import {IProject} from '../../../../defs/schema/public/Projects';
import {USER_FIELD} from '../../../../defs/schema/public/Users';
import {EMPLOYEE_SORT_FUNCTION, PROJECT_SORT_FUNCTION} from '../../../../defs/sorters';
import {IDatagridColumns, SelectFilterType, sortFnArrayWrapper} from '../app-static';
import {validate} from '../forms/validators/form.validator';
import {HttpRestService} from '../shared/http-rest/http-rest.service';
import {LONG_AVATAR_TYPE} from '../shared/long-avatar/long-avatar.component';
import {
    SHORTCUT_CREATE,
    SHORTCUT_LOCAL,
    ShortcutHandlerService,
} from '../shared/shortcut-handler/shortcut-handler.service';
import {TOAST_TYPE, ToastService} from '../shared/toast/toast.service';

@Component({
    selector: 'app-clients',
    templateUrl: './clients.component.html',
    styleUrls: ['./clients.component.scss'],
})
export class ClientsComponent implements OnInit, OnDestroy {
    public clients: IClient[];
    public employees: IEmployee[];
    public projects: Partial<IProject>[];
    protected pleaseWait = true;

    public selectedClients: IClient[] = [];
    public showCreateModal = false;
    public showDeleteModal = false;

    public constructor(
        private readonly httpRest: HttpRestService,
        private readonly shortcutHandlerService: ShortcutHandlerService,
        private readonly translate: TranslateService,
        private readonly toastService: ToastService
    ) {}

    public ngOnInit() {
        if (!this.clients) {
            (async () => this.getClients())();
        }

        this.shortcutHandlerService.register(
            {
                name: SHORTCUT_CREATE.CLIENT,
                shortcut: SHORTCUT_LOCAL.CREATE,
                callback: () => (this.showCreateModal = true),
                context: this,
            },
            true
        );
    }

    // tslint:disable-next-line:prefer-function-over-method no-empty
    public ngOnDestroy() {}

    public async getClients() {
        const {clients, employees} = await this.httpRest
            ._request<{clients: IClient[]; employees: IEmployee[]}>(
                HTTP_METHOD.GET,
                ApiRoutePlurality.PLURAL,
                CLIENT_SCHEMA_ROUTE,
                'listComplete'
            )
            .toPromise();

        this.employees = employees.sort((a, b) => a.user.name.localeCompare(b.user.name));
        this.projects = [];
        clients.map((c) => {
            this.projects = this.projects.concat([...(c.projects || [])]).sort(PROJECT_SORT_FUNCTION);
        });

        this.clients = clients.map(
            (client) =>
                ({
                    ...client,
                    projects: (client.projects || []).sort(PROJECT_SORT_FUNCTION),
                    contacts: (client.contacts || [])
                        .map((contact) => employees.find(({id}) => id === contact.id))
                        .filter((contact) => !!contact)
                        .sort(EMPLOYEE_SORT_FUNCTION),
                } as IClient)
        );

        this.pleaseWait = false;
    }

    public async addNewClient(client: IClient) {
        this.clients.push({
            ...client,
            projects: client.projects || [],
        } as IClient);
        this.toastService.show({
            type: TOAST_TYPE.SUCCESS,
            text: 'success_create_client',
        });
    }

    public async deleteSelectedClients() {
        const deletedClientsIds = this.selectedClients.map(({id}) => id);

        try {
            deletedClientsIds.map(async (clientId) =>
                this.httpRest
                    ._request(
                        HTTP_METHOD.DELETE,
                        ApiRoutePlurality.SINGULAR,
                        CLIENT_SCHEMA_ROUTE,
                        `withUser/${clientId}`
                    )
                    .toPromise()
            );
        } catch (err) {
            return;
        } finally {
            this.showDeleteModal = false;
        }

        this.selectedClients = [];
        this.clients = this.clients.filter(({id}) => !deletedClientsIds.includes(id));

        this.toastService.show({
            type: TOAST_TYPE.SUCCESS,
            text: 'success_delete_client',
        });
    }

    public readonly validate = validate;

    public readonly RIGHTS = RIGHTS;
    public readonly LONG_AVATAR_TYPE = LONG_AVATAR_TYPE;
    public readonly SelectFilterType = SelectFilterType;

    public readonly filterOpen: {[key: string]: boolean} = {};
    public readonly CLIENTS_COLUMNS: IDatagridColumns<IClient> = [
        {
            field: `${CLIENT_FIELD.user}.${USER_FIELD.name}`,
            translateKey: 'table_name',
            order: ClrDatagridSortOrder.ASC,
        },
        // FIXME force width?
        {field: CLIENT_FIELD.obs, translateKey: 'table_observation' /*width: '200px'*/},
        {
            field: 'contacts',
            translateKey: 'customer_contacts',
            propertyGetter: ({contacts}: IClient) =>
                (contacts || []).map(({user}) => (user && user.name) || '').join(' '),
            sorter: {compare: (c1, c2) => sortFnArrayWrapper(EMPLOYEE_SORT_FUNCTION)(c1.contacts, c2.contacts)},
        },
        {
            field: 'projects',
            translateKey: 'projects',
            propertyGetter: ({projects}: IClient) => (projects || []).map(({obs}) => obs || '').join(' '),
            sorter: {compare: (c1, c2) => sortFnArrayWrapper(PROJECT_SORT_FUNCTION)(c1.projects, c2.projects)},
        },
    ];
}
