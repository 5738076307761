var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Router } from '@angular/router';
import { COGNITO_USER_GROUPS } from '../../../../defs/schema-static';
import { AuthGuard } from './auth.guard';
import { AuthService } from './auth.service';
var ClientAuthGuard = (function (_super) {
    __extends(ClientAuthGuard, _super);
    function ClientAuthGuard(authService, router) {
        var _this = _super.call(this, authService, router) || this;
        _this.authService = authService;
        _this.router = router;
        _this.requiredGroup = COGNITO_USER_GROUPS.CLIENT;
        return _this;
    }
    return ClientAuthGuard;
}(AuthGuard));
export { ClientAuthGuard };
