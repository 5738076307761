import { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import fuzzysort from 'fuzzysort';
import { Subject } from 'rxjs';
import { stripAccents } from '../../../../../defs/schema-static';
import { NestedProperty } from '../nested-properties';
var FuzzyDatagridFilterComponent = (function () {
    function FuzzyDatagridFilterComponent() {
        this.filter = '';
        this.filterOpen = false;
        this.changes = new Subject();
    }
    FuzzyDatagridFilterComponent.prototype.ngOnInit = function () {
        this.setFilter();
    };
    FuzzyDatagridFilterComponent.prototype.ngOnChanges = function (changes) {
        if (changes.filterKey || changes.propertyGetter) {
            this.setFilter();
        }
    };
    FuzzyDatagridFilterComponent.prototype.setFilter = function () {
        this.nestedProp = this.filterKey ? new NestedProperty(this.filterKey) : undefined;
        this.changes.next(this.propertyGetter || this.filterKey);
    };
    FuzzyDatagridFilterComponent.prototype.accepts = function (item) {
        if (!this.propertyGetter && !this.filterKey) {
            console.warn('missing propertyGetter/filterKey');
            return true;
        }
        var filterValue = this.filterValue;
        if (!filterValue.length) {
            return true;
        }
        var result = fuzzysort.single(filterValue, this.getItemValue(item));
        return result && result.score >= FuzzyDatagridFilterComponent.FUZZYSORT_THRESHOLD;
    };
    FuzzyDatagridFilterComponent.prototype.isActive = function () {
        return this.filterValue.length > 0;
    };
    FuzzyDatagridFilterComponent.prototype.serialize = function () {
        var filterValue = this.filterValue;
        if (!filterValue) {
            return [];
        }
        return [filterValue];
    };
    Object.defineProperty(FuzzyDatagridFilterComponent.prototype, "filterValue", {
        get: function () {
            return stripAccents(this.filter.trim()) || '';
        },
        enumerable: true,
        configurable: true
    });
    FuzzyDatagridFilterComponent.prototype.getItemValue = function (item) {
        if (!this.propertyGetter && !this.nestedProp) {
            return '';
        }
        var prop = this.propertyGetter ? this.propertyGetter(item) : this.nestedProp.getPropValue(item);
        return stripAccents(FuzzyDatagridFilterComponent.castToString(prop));
    };
    FuzzyDatagridFilterComponent.castToString = function (value) {
        if (typeof value === 'undefined' || value === null) {
            return '';
        }
        return value.toString();
    };
    FuzzyDatagridFilterComponent.FUZZYSORT_THRESHOLD = -Infinity;
    return FuzzyDatagridFilterComponent;
}());
export { FuzzyDatagridFilterComponent };
