var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ClrForm } from '@clr/angular';
import { FormControl, FormGroup } from 'ngx-strongly-typed-forms';
import { ApiRoutePlurality, HTTP_METHOD } from '../../../../../defs/schema-static';
import { CUSTOMER_CONTACT_FIELD, CUSTOMER_CONTACT_SCHEMA_ROUTE, } from '../../../../../defs/schema/public/CustomerContacts';
import { EMPLOYEE_FIELD, EMPLOYEE_SCHEMA_ROUTE } from '../../../../../defs/schema/public/Employees';
import { USER_FIELD } from '../../../../../defs/schema/public/Users';
import { CUSTOMER_CONTACT_SORT_FUNCTION, EMPLOYEE_SORT_FUNCTION } from '../../../../../defs/sorters';
import { EMPLOYEE_MEMBER_TYPE } from '../../shared/editable-employee-label/editable-employee-label.component';
import { HttpRestService } from '../../shared/http-rest/http-rest.service';
var CUSTOMER_CONTACTS_FORM_KEYS;
(function (CUSTOMER_CONTACTS_FORM_KEYS) {
    CUSTOMER_CONTACTS_FORM_KEYS["contacts"] = "contacts";
})(CUSTOMER_CONTACTS_FORM_KEYS || (CUSTOMER_CONTACTS_FORM_KEYS = {}));
var ClientWizardCustomerContactsComponent = (function () {
    function ClientWizardCustomerContactsComponent(httpRest) {
        var _a;
        this.httpRest = httpRest;
        this.form = new FormGroup((_a = {},
            _a[CUSTOMER_CONTACTS_FORM_KEYS.contacts] = new FormControl(),
            _a));
        this.employees = [];
        this.dropdownPosition = 'bottom';
        this.CUSTOMER_CONTACTS_FORM_KEYS = CUSTOMER_CONTACTS_FORM_KEYS;
        this.EMPLOYEE_MEMBER_TYPE = EMPLOYEE_MEMBER_TYPE;
        this.CUSTOMER_CONTACT_FIELD = CUSTOMER_CONTACT_FIELD;
        this.EMPLOYEE_FIELD = EMPLOYEE_FIELD;
        this.USER_FIELD = USER_FIELD;
    }
    ClientWizardCustomerContactsComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.contacts) {
            this.form.patchValue({ contacts: this.contacts });
        }
        if (!this.employees || !this.employees.length) {
            (function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                return [2, this.getEmployees()];
            }); }); })();
        }
        else {
            this.setContacts();
        }
    };
    ClientWizardCustomerContactsComponent.prototype.ngOnChanges = function (changes) {
        if (changes.contacts) {
            this.form.patchValue({ contacts: this.contacts });
            this.setContacts();
        }
    };
    ClientWizardCustomerContactsComponent.prototype.getEmployees = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4, this.httpRest
                                ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, EMPLOYEE_SCHEMA_ROUTE, 'project')
                                .toPromise()];
                    case 1:
                        _a.employees = (_b.sent()).sort(EMPLOYEE_SORT_FUNCTION);
                        this.setContacts();
                        return [2];
                }
            });
        });
    };
    ClientWizardCustomerContactsComponent.prototype.setContacts = function () {
        var existingIds = (this.form.value.contacts || []).map(function (contact) { return contact.employee.id; });
        this.contacts = (this.form.value.contacts || []).concat(this.employees
            .filter(function (employee) { return !existingIds.includes(employee.id); })
            .map(function (employee) {
            return ({
                employee: employee,
                employeeId: employee.id,
            });
        }));
    };
    ClientWizardCustomerContactsComponent.prototype.removeContact = function (contact) {
        var contacts = this.form.value.contacts;
        contacts.splice(contacts.indexOf(contact), 1);
        this.form.patchValue({ contacts: contacts });
    };
    ClientWizardCustomerContactsComponent.prototype.updateContact = function () {
        var contacts = this.form.value.contacts;
        this.form.patchValue({ contacts: contacts });
    };
    ClientWizardCustomerContactsComponent.prototype.submit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var contacts, customerContacts;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.client || !this.client.id) {
                            throw new Error("missing client id, can't submit ");
                        }
                        if (!this.form.valid) {
                            this.clrForm.markAsDirty();
                            return [2, undefined];
                        }
                        contacts = this.form.value.contacts;
                        return [4, Promise.all((contacts || []).map(function (contact) { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    return [2, this.httpRest
                                            .put(CUSTOMER_CONTACT_SCHEMA_ROUTE, {
                                            clientId: this.client.id,
                                            employeeId: contact.employee.id,
                                        })
                                            .toPromise()];
                                });
                            }); }))];
                    case 1:
                        customerContacts = _a.sent();
                        return [2, customerContacts
                                .map(function (contact) { return (__assign({}, contact, { employee: _this.employees.find(function (_a) {
                                    var id = _a.id;
                                    return contact.employeeId === id;
                                }) })); })
                                .sort(CUSTOMER_CONTACT_SORT_FUNCTION)];
                }
            });
        });
    };
    return ClientWizardCustomerContactsComponent;
}());
export { ClientWizardCustomerContactsComponent };
