var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { EventEmitter, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { ClrForm } from '@clr/angular';
import { NgSelectComponent } from '@ng-select/ng-select';
import { FormControl, FormGroup } from 'ngx-strongly-typed-forms';
import { concat, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { ApiRoutePlurality, HTTP_METHOD } from '../../../../../defs/schema-static';
import { PROJECT_SCHEMA_ROUTE } from '../../../../../defs/schema/public/Projects';
import { TASK_SCHEMA_ROUTE } from '../../../../../defs/schema/public/Tasks';
import { TaskTarget } from '../../forms/add-time.service';
import { HttpRestService } from '../../shared/http-rest/http-rest.service';
var TimeExistingComponent = (function () {
    function TimeExistingComponent(httpRest) {
        this.httpRest = httpRest;
        this.form = new FormGroup({
            assignedTask: new FormControl(null, Validators.required),
            project: new FormControl(null, Validators.required),
            taskId: new FormControl(null, Validators.required),
        });
        this.tasksProgress = [];
        this.projects = [];
        this.projectChange = new EventEmitter();
        this.tasksLoading = false;
        this.tasksTH$ = new Subject();
        this.TASK_TARGET = TaskTarget;
    }
    TimeExistingComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (!this.tasksProgress || !this.tasksProgress.length || !this.projects || !this.projects.length) {
            (function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                return [2, this.getDatas()];
            }); }); })();
        }
    };
    TimeExistingComponent.prototype.getCodeProject = function (id) {
        if (!this.projects) {
            return '';
        }
        return this.projects.find(function (f) { return f.id === id; }).code;
    };
    TimeExistingComponent.prototype.getDatas = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.httpRest
                    ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, PROJECT_SCHEMA_ROUTE, 'withTasks')
                    .subscribe(function (projects) {
                    _this.projects = projects.sort(function (a, b) { return a.obs.localeCompare(b.obs); });
                    if (projects.length > 0) {
                        _this.httpRest
                            ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, TASK_SCHEMA_ROUTE, 'inProgress')
                            .subscribe(function (tasks) {
                            _this.tasksProgress = tasks.map(function (t) {
                                return __assign({}, t, { displayName: _this.getCodeProject(t.projectId) + "-" + t.code + " : " + t.name });
                            });
                        });
                        _this.loadTasks();
                    }
                });
                return [2];
            });
        });
    };
    TimeExistingComponent.prototype.onLoad = function (params) {
        if (params) {
            if (params.taskTarget) {
                this.taskTarget = params.taskTarget;
            }
            this.toggle();
        }
    };
    TimeExistingComponent.prototype.filterProject = function (projectId) {
        this.form.patchValue({
            project: projectId || null,
            taskId: null,
            assignedTask: null,
        });
        this.projectChange.emit((this.projects || []).find(function (_a) {
            var id = _a.id;
            return id === projectId;
        }));
        this.loadTasks();
        this.setFocus();
    };
    TimeExistingComponent.prototype.filterTask = function (id) {
        this.form.patchValue({
            taskId: id || null,
        });
    };
    TimeExistingComponent.prototype.filterTaskProgress = function (taskId) {
        this.form.patchValue({
            taskId: null,
            project: null,
            assignedTask: taskId || null,
        });
        var task = this.tasksProgress.find(function (_a) {
            var id = _a.id;
            return id === taskId;
        });
        if (!task) {
            this.projectChange.emit(undefined);
            return;
        }
        this.projectChange.emit((this.projects || []).find(function (_a) {
            var id = _a.id;
            return id === task.projectId;
        }));
    };
    TimeExistingComponent.prototype.submit = function () {
        if (!this.form.valid) {
            return undefined;
        }
        return this.form;
    };
    TimeExistingComponent.prototype.toggle = function () {
        if (this.taskTarget === TaskTarget.ASSIGNED) {
            this.form.controls.assignedTask.enable();
            this.form.controls.project.disable();
            this.form.controls.taskId.disable();
        }
        else {
            this.form.controls.assignedTask.disable();
            this.form.controls.project.enable();
            this.form.controls.taskId.enable();
        }
        this.setFocus();
    };
    TimeExistingComponent.prototype.setFocus = function () {
        var _this = this;
        requestAnimationFrame(function () {
            if (_this.taskTarget === TaskTarget.ASSIGNED) {
                if (_this.ngSelectTaskProgress && !_this.form.value.assignedTask) {
                    _this.ngSelectTaskProgress.focus();
                    _this.ngSelectTaskProgress.open();
                }
            }
            else if (_this.taskTarget === TaskTarget.EXISTING && _this.form.value.project && !_this.form.value.taskId) {
                _this.ngSelectTask.focus();
                _this.ngSelectTask.open();
            }
            else if (!_this.form.value.project) {
                if (_this.ngSelectProject) {
                    _this.ngSelectProject.focus();
                    _this.ngSelectProject.open();
                }
            }
        });
    };
    TimeExistingComponent.prototype.getTasks = function (term) {
        return this.httpRest._request(HTTP_METHOD.POST, ApiRoutePlurality.PLURAL, TASK_SCHEMA_ROUTE, 'searchTasks', {
            search: term,
            projectId: this.form.value.project,
        });
    };
    TimeExistingComponent.prototype.loadTasks = function () {
        var _this = this;
        this.tasks = concat(of([]), this.tasksTH$.pipe(debounceTime(200), distinctUntilChanged(), tap(function () { return (_this.tasksLoading = true); }), switchMap(function (term) {
            if (!term || term === '') {
                _this.tasksLoading = false;
                return of([]);
            }
            return _this.getTasks(term).pipe(catchError(function () { return of([]); }), tap(function () { return (_this.tasksLoading = false); }));
        })));
    };
    return TimeExistingComponent;
}());
export { TimeExistingComponent };
