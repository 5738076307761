import {Component} from '@angular/core';
import {ToastService} from '../../shared/toast/toast.service';
import {AuthService} from '../auth.service';

@Component({
    selector: 'app-log-out',
    templateUrl: './log-out.component.html',
    styleUrls: ['./log-out.component.scss'],
})
export class LogOutComponent {
    public constructor(private readonly authService: AuthService, private readonly toastService: ToastService) {
        this.toastService.hideAll();
        this.authService.signOut();
    }
}
