import {Component, Input, OnInit} from '@angular/core';
import {ApiRoutePlurality, HTTP_METHOD, ProjectMemberType} from '../../../../../defs/schema-static';
import {CLIENT_FIELD, CLIENT_SCHEMA_ROUTE, IClient} from '../../../../../defs/schema/public/Clients';
import {IEmployee} from '../../../../../defs/schema/public/Employees';
import {IProjectMember, PROJECT_MEMBER_SCHEMA_ROUTE} from '../../../../../defs/schema/public/ProjectMembers';
import {IProject, PROJECT_FIELD, PROJECT_SCHEMA_ROUTE} from '../../../../../defs/schema/public/Projects';
import {USER_FIELD} from '../../../../../defs/schema/public/Users';
import {IDatagridColumn, IResettable} from '../../app-static';
import {PROJECT_LIST_LAYOUT, ProjectsListComponent} from '../../projects/list/projects-list.component';
import {HttpRestService} from '../../shared/http-rest/http-rest.service';

@Component({
    selector: 'app-employee-wizard-projects',
    templateUrl: './employee-wizard-projects.component.html',
})
export class EmployeeWizardProjectsComponent implements OnInit, IResettable {
    public readonly columns: IDatagridColumn[] = ProjectsListComponent.DEFAULT_PROJECTS_COLUMNS.map((column) => ({
        ...column,
    }));

    @Input() public projects: Partial<IProject>[];
    @Input() public employee: IEmployee;

    public selectedProjects: Partial<IProject>[] = [];

    public constructor(private readonly httpRest: HttpRestService) {
        this.columns
            .filter(({field}) => !EmployeeWizardProjectsComponent.COLUMNS_FIELDS.includes(field))
            .map((column) => (column.hidden = true));
    }

    public ngOnInit() {
        if (!this.projects) {
            (async () => this.getProjects())();
        }
    }

    public async getProjects() {
        this.projects = (await this.httpRest
            ._request<IProject[]>(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, PROJECT_SCHEMA_ROUTE, 'light_manager')
            .toPromise()).sort((p1, p2) => p1.code.localeCompare(p2.code));

        const clients = await this.httpRest
            ._request<IClient[]>(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, CLIENT_SCHEMA_ROUTE, 'light')
            .toPromise();

        this.projects.map((project) => (project.client = clients.find(({id}) => id === project.clientId)));

        this.projects = [...this.projects];
    }

    public async submit(): Promise<IProjectMember[]> {
        if (!this.employee || !this.employee.id) {
            throw new Error("missing employee id, can't submit ");
        }

        if (!this.selectedProjects || !this.selectedProjects.length) {
            return undefined;
        }

        return this.httpRest
            ._request<IProjectMember[]>(
                HTTP_METHOD.PUT,
                ApiRoutePlurality.PLURAL,
                PROJECT_MEMBER_SCHEMA_ROUTE,
                '',
                (this.selectedProjects || []).map((project) => ({
                    projectId: project.id,
                    type: ProjectMemberType.MEMBER,
                    employeeId: this.employee.id,
                }))
            )
            .toPromise();
    }

    public reset(): void {
        this.selectedProjects = [];
    }

    public readonly PROJECT_LIST_LAYOUT = PROJECT_LIST_LAYOUT;

    public static readonly COLUMNS_FIELDS = [
        PROJECT_FIELD.obs,
        `${PROJECT_FIELD.client}.${CLIENT_FIELD.user}.${USER_FIELD.name}`,
        'state',
    ];
}
