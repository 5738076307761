<!-- FIXME workaround for https://github.com/vmware/clarity/issues/2977 -->
<clr-datagrid
    appDatagridToggle
    [clrDgSelected]="proxiedSelectedUserGroups"
    (clrDgSelectedChange)="selectedUserGroupsChange.emit((selectedUserGroups = $event))"
    [clrDgRowSelection]="true"
    [clrDgLoading]="!groups"
>
    <clr-dg-column [clrDgField]="'groupName'" [clrDgSortOrder]="ClrDatagridSortOrder.ASC"
        >{{ 'group_name' | translate }}
        <clr-dg-filter [clrDgFilter]="fuzzyFilter" [(clrDgFilterOpen)]="filterOpen.displayName">
            <shared-fuzzy-datagrid-filter
                [filterKey]="'displayName'"
                [filterOpen]="filterOpen.displayName"
                #fuzzyFilter
            ></shared-fuzzy-datagrid-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'description'">
        {{ 'table_description' | translate }}

        <clr-dg-filter [clrDgFilter]="fuzzyFilter" [(clrDgFilterOpen)]="filterOpen.description">
            <shared-fuzzy-datagrid-filter
                [filterKey]="'description'"
                [filterOpen]="filterOpen.description"
                #fuzzyFilter
            ></shared-fuzzy-datagrid-filter>
        </clr-dg-filter>
    </clr-dg-column>

    <clr-dg-row *clrDgItems="let group of groups" [clrDgItem]="group">
        <clr-dg-cell>{{ getGroupDisplayName(group.groupName) }}</clr-dg-cell>
        <clr-dg-cell>{{ group.description }}</clr-dg-cell>
    </clr-dg-row>

    <clr-dg-footer
        ><span *ngIf="groups">{{ groups.length }} {{ 'groups' | translate }}</span></clr-dg-footer
    >
</clr-datagrid>
