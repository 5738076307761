import moment from 'moment';
import {ISchemaRoute} from '../../schema-static';
import {IEmployee} from './Employees';

export enum TASK_COMMENT_FIELD {
    id = 'id',
    createdAt = 'createdAt',
    updatedAt = 'updatedAt',
    comment = 'comment',
    employeeId = 'employeeId',
    taskId = 'taskId',
}

export interface ITaskCommentsBare {
    id?: number;
    createdAt?: moment.Moment;
    updatedAt?: moment.Moment;
    comment?: string;
    employeeId: number;
    taskId: number;
}

export interface ITaskCommentsBelong {
    employee?: Partial<IEmployee>;
}

export interface ITaskComments extends ITaskCommentsBare, ITaskCommentsBelong {}

export interface ITaskCommentsDiff extends ITaskCommentsBare, ITaskCommentsBelong {
    diff?: string;
}

const TASK_COMMENT_SCHEMA_ROUTE: ISchemaRoute = {
    tableName: 'taskcomments',
    singularRoute: 'taskcomment',
    pluralRoute: 'taskcomments',
};

export {TASK_COMMENT_SCHEMA_ROUTE};
