<div class="column-container py-02">
    <div class="clr-row flex-1-set">
        <div class="clr-col-12">
            <clr-datagrid
                class="h-100"
                appDatagridToggle
                [(clrDgSelected)]="selectedTasks"
                (clrDgRefresh)="refresh($event)"
                [clrDgLoading]="!pagesTasks[clrDgPagination.currentPage - 1]"
            >
                <clr-dg-action-bar>
                    <div class="btn-group">
                        <button
                            class="btn btn-sm"
                            (click)="showCreateModal = true"
                            *sharedAccessControl="RIGHTS.TASK_CREATE"
                        >
                            {{ 'add_task' | translate }}
                        </button>
                    </div>

                    <div class="btn-group">
                        <button
                            class="btn btn-sm btn-danger"
                            (click)="showDeleteModal = true"
                            *sharedAccessControl="RIGHTS.TASK_DELETE"
                            [disabled]="!selectedTasks.length"
                        >
                            {{ (selectedTasks.length > 1 ? 'delete_tasks' : 'delete_task') | translate }}
                        </button>
                    </div>
                </clr-dg-action-bar>

                <clr-dg-column
                    *ngFor="let column of TASKS_COLUMNS"
                    [clrDgField]="column.field"
                    [clrDgSortOrder]="column.order"
                    [style.width]="column.width"
                >
                    <ng-container *ngIf="column.hideable === false; else hideableColumn">
                        {{ column.translateKey | translate }}
                    </ng-container>
                    <ng-template #hideableColumn>
                        <ng-container *clrDgHideableColumn="{hidden: column.hidden === true}">
                            {{ column.translateKey | translate }}
                        </ng-container>
                    </ng-template>

                    <clr-dg-filter
                        *ngIf="!column.filterType || column.filterType === DATAGRID_FILTER_TYPE.FUZZY"
                        [clrDgFilter]="fuzzyFilter"
                        [(clrDgFilterOpen)]="filterOpen[column.field]"
                    >
                        <shared-fuzzy-datagrid-filter
                            [filterKey]="column.field"
                            [filterOpen]="filterOpen[column.field]"
                            #fuzzyFilter
                        ></shared-fuzzy-datagrid-filter>
                    </clr-dg-filter>

                    <clr-dg-filter
                        *ngIf="column.filterType === DATAGRID_FILTER_TYPE.DATE"
                        [clrDgFilter]="dateFilter"
                        [(clrDgFilterOpen)]="filterOpen[column.field]"
                    >
                        <shared-date-datagrid-filter
                            [filterKey]="column.field"
                            [filterOpen]="filterOpen[column.field]"
                            #dateFilter
                        ></shared-date-datagrid-filter>
                    </clr-dg-filter>

                    <clr-dg-filter *ngIf="column.field === TASK_FIELD.status" [clrDgFilter]="statusFilter">
                        <shared-generic-datagrid-filter
                            [filter]="TASK_STATUS_FILTER"
                            [filterKey]="column.field"
                            #statusFilter
                        ></shared-generic-datagrid-filter>
                    </clr-dg-filter>

                    <clr-dg-filter *ngIf="column.field === TASK_FIELD.resolution" [clrDgFilter]="resolutionFilter">
                        <shared-generic-datagrid-filter
                            [filter]="TASK_RESOLUTION_FILTER"
                            [filterKey]="column.field"
                            #resolutionFilter
                        ></shared-generic-datagrid-filter>
                    </clr-dg-filter>
                </clr-dg-column>

                <clr-dg-row *ngFor="let task of pagesTasks[clrDgPagination.currentPage - 1]" [clrDgItem]="task">
                    <clr-dg-cell class="monospace">
                        <ng-container *sharedAccessControl="RIGHTS.TASK_UPDATE; else codeWithoutLink">
                            <a [routerLink]="['.']" [queryParams]="{edit: task.id}" [queryParamsHandling]="'merge'">
                                {{ task.code }}</a
                            >
                        </ng-container>
                        <ng-template #codeWithoutLink> {{ task.code }} </ng-template>
                    </clr-dg-cell>
                    <clr-dg-cell>
                        <a
                            *ngIf="task.parent"
                            [routerLink]="['.']"
                            [queryParams]="{edit: task.parent.id}"
                            [queryParamsHandling]="'merge'"
                            >{{ task.parent.code }}</a
                        >
                    </clr-dg-cell>
                    <clr-dg-cell
                        ><span style="word-break: break-all;">{{ task.name }} </span>
                    </clr-dg-cell>
                    <clr-dg-cell>
                        <span *ngIf="task.type === TaskType.BUG" class="text-warning">
                            <clr-icon shape="bug"></clr-icon> {{ 'bug' | translate }}
                        </span>
                    </clr-dg-cell>
                    <clr-dg-cell>
                        <span
                            [class.text-progress-0]="task.progress >= 0 && task.progress < 10"
                            [class.text-progress-10]="task.progress >= 10 && task.progress < 40"
                            [class.text-progress-40]="task.progress >= 40 && task.progress < 80"
                            [class.text-progress-80]="task.progress >= 80 && task.progress < 100"
                            [class.text-progress-overtime]="task.progress >= 100"
                            >{{ task.progress }}%</span
                        >
                    </clr-dg-cell>

                    <clr-dg-cell class="monospace-date" [title]="task._metadata.beginDate | momentDuration">
                        {{ task._metadata.beginDate | momentFormat: 'L' }}
                    </clr-dg-cell>

                    <clr-dg-cell class="monospace-date" [title]="task._metadata.endDate | momentDuration">
                        {{ task._metadata.endDate | momentFormat: 'L' }}
                    </clr-dg-cell>
                    <clr-dg-cell>
                        <a
                            *ngIf="task.targetRelease"
                            [routerLink]="['.']"
                            [queryParams]="{tab: PROJECT_TABS.RELEASES, edit: task.targetRelease.id}"
                            [title]="task.targetRelease.obs"
                        >
                            <code>{{ task.targetRelease.version }}</code>
                        </a>
                    </clr-dg-cell>

                    <clr-dg-cell>
                        <span [classList]="TASK_STATUS_FILTER[task.status].classList">
                            <clr-icon [attr.shape]="TASK_STATUS_FILTER[task.status].icon"></clr-icon>
                            {{ TASK_STATUS_FILTER[task.status].translation | translate }}
                        </span>
                    </clr-dg-cell>

                    <clr-dg-cell>
                        <span *ngIf="task.resolution" [classList]="TASK_RESOLUTION_FILTER[task.resolution].classList">
                            <clr-icon [attr.shape]="TASK_RESOLUTION_FILTER[task.resolution].icon"></clr-icon>
                            {{ TASK_RESOLUTION_FILTER[task.resolution].translation | translate }}
                        </span>
                    </clr-dg-cell>

                    <!-- FIXME add task detail? -->
                    <clr-dg-row-detail *clrIfExpanded
                        ><app-report-task-detail [task]="task"></app-report-task-detail
                    ></clr-dg-row-detail>
                </clr-dg-row>

                <clr-dg-footer>
                    <clr-dg-column-toggle>
                        <clr-dg-column-toggle-title>{{ 'tasks_columns' | translate }}</clr-dg-column-toggle-title>
                        <clr-dg-column-toggle-button>{{ 'show_all_columns' | translate }}</clr-dg-column-toggle-button>
                    </clr-dg-column-toggle>

                    <clr-dg-pagination [clrDgTotalItems]="taskCount" [clrDgPageSize]="pageSize">
                        <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]" translate
                            >tasks_per_page</clr-dg-page-size
                        >
                        <ng-container *ngIf="taskCount"
                            >{{
                                'pagination_count'
                                    | translate
                                        : {
                                              first: clrDgPagination.firstItem + 1,
                                              last: clrDgPagination.lastItem + 1,
                                              count: taskCount,
                                              what: 'tasks' | translate | lowercase
                                          }
                            }}
                        </ng-container>
                    </clr-dg-pagination>
                </clr-dg-footer>
            </clr-datagrid>
        </div>
    </div>
</div>

<app-edit-task
    [(show)]="showEditModal"
    [releases]="releases"
    [tasks]="tasks"
    [taskToEdit]="selectedTask"
    (hidden)="closeEditModal()"
>
</app-edit-task>

<app-modal
    [(show)]="showCreateModal"
    [appModalSize]="'lg'"
    [title]="'add_task' | translate"
    (shown)="loadAddTask()"
    (hidden)="addTaskComponent.alreadyInit = false"
>
    <ng-template appModalBody> <app-forms-add-task [(show)]="showCreateModal"></app-forms-add-task> </ng-template>
    <ng-template appModalFooter>
        <button type="button" class="btn btn-secondary btn-footer-left" (click)="showMultiple = true" translate>
            multiple_add
        </button>
        <button
            *ngIf="addTaskComponent"
            [disabled]="!validate(addTaskComponent.form, false)"
            (click)="addTask()"
            class="btn"
            translate
        >
            add_task
        </button>
    </ng-template>
</app-modal>

<app-modal [(show)]="showDeleteModal" [title]="'task_delete_modal_title' | translate">
    <ng-template appModalBody>
        <p>
            {{
                'swal_delete_attch'
                    | translate
                        : {
                              what:
                                  selectedTasks.length > 1
                                      ? ('count_tasks' | translate: {count: selectedTasks.length})
                                      : selectedTasks[0].code
                          }
            }}
        </p>
    </ng-template>
    <ng-template appModalFooter>
        <button
            class="btn btn-secondary"
            (click)="showDeleteModal = false"
            [sharedAutofocus]="showDeleteModal"
            translate
        >
            form_cancel
        </button>
        <button class="btn btn-danger" (click)="deleteSelectedTasks()">
            {{ (selectedTasks.length > 1 ? 'delete_tasks' : 'delete_task') | translate }}
        </button>
    </ng-template>
</app-modal>
