import moment from 'moment';
import {IIdea} from './schema/ideaboard/Ideas';
import {IWikiPage, WIKI_PAGE_FIELD} from './schema/public/WikiPage';

export enum HTTP_METHOD {
    GET = 'GET',
    PUT = 'PUT',
    POST = 'POST',
    DELETE = 'DELETE',
}

export enum ApiRoutePlurality {
    SINGULAR = 'SINGULAR',
    PLURAL = 'PLURAL',
}

export enum BudgetStatus {
    ESTIMATED = 'ESTIMATED',
    BILLED = 'BILLED',
    CANCELLED = 'CANCELLED',
}

export enum RightCategory {
    NAVIGATION = 'navigation',
    CLIENTS = 'clients',
    PROJECTS = 'projects',
    EMPLOYEES = 'employees',
    TASKS = 'tasks',
    COMMENT = 'comment',
    TIMES = 'times',
    MILESTONES = 'milestones',
    WIKI = 'wiki-pages',
    ATTACHMENTS = 'attachments',
}

export enum NAVIGATE_RIGHTS {
    NAVIGATE_FIRON = 'NAVIGATE_FIRON',
    NAVIGATE_GANTT = 'NAVIGATE_GANTT',
    NAVIGATE_DASHBOARD = 'NAVIGATE_DASHBOARD',
    NAVIGATE_TODO = 'NAVIGATE_TODO',
    NAVIGATE_WIKI = 'NAVIGATE_WIKI',
    NAVIGATE_REPORTS = 'NAVIGATE_REPORTS',
    NAVIGATE_CALENDAR = 'NAVIGATE_CALENDAR',
    NAVIGATE_CLIENTS = 'NAVIGATE_CLIENTS',
    NAVIGATE_CLIENT = 'NAVIGATE_CLIENT',
    NAVIGATE_PROJECTS = 'NAVIGATE_PROJECTS',
    NAVIGATE_PROJECT = 'NAVIGATE_PROJECT',
    NAVIGATE_EMPLOYEES = 'NAVIGATE_EMPLOYEES',
    NAVIGATE_EMPLOYEE = 'NAVIGATE_EMPLOYEE',
    NAVIGATE_ADMIN = 'NAVIGATE_ADMIN',
    NAVIGATE_IDEABOARD = 'NAVIGATE_IDEABOARD',
    NAVIGATE_WORKLOADTOOLS = 'NAVIGATE_WORKLOADTOOLS',
}

export enum RIGHTS {
    PROJECT_TAB_MS = 'PROJECT_TAB_MS',
    PROJECT_TAB_BG = 'PROJECT_TAB_BG',
    PROJECT_TAB_TK = 'PROJECT_TAB_TK',
    PROJECT_TAB_TI = 'PROJECT_TAB_TI',
    PROJECT_TAB_RL = 'PROJECT_TAB_RL',
    PROJECT_TAB_BK = 'PROJECT_TAB_BK',
    PROJECT_TAB_PR = 'PROJECT_TAB_PR',

    PROJECT_STICKY_READ = 'PROJECT_STICKY_READ',
    PROJECT_STICKY_UPDATE = 'PROJECT_STICKY_UPDATE',
    PROJECT_DELETE = 'PROJECT_DELETE',
    PROJECT_CREATE = 'PROJECT_CREATE',

    EMPLOYEE_DELETE = 'EMPLOYEE_DELETE',
    EMPLOYEE_UPDATE = 'EMPLOYEE_UPDATE',
    EMPLOYEE_CREATE = 'EMPLOYEE_CREATE',

    MONEY = 'MONEY',

    CLIENT_DELETE = 'CLIENT_DELETE',
    CLIENT_UPDATE = 'CLIENT_UPDATE',
    CLIENT_CREATE = 'CLIENT_CREATE',
    CLIENT_TAB_MS = 'CLIENT_TAB_MS',
    CLIENT_TAB_PJ = 'CLIENT_TAB_PJ',
    CLIENT_TAB_RQ = 'CLIENT_TAB_RQ',
    CLIENT_TAB_IN = 'CLIENT_TAB_IN',
    CLIENT_TAB_CH = 'CLIENT_TAB_CH',
    CLIENT_TAB_BK = 'CLIENT_TAB_BK',
    CLIENT_BK_DL = 'CLIENT_BK_DL',
    CLIENT_CONTACTS = 'CLIENT_CONTACTS',
    CLIENT_STICKY_READ = 'CLIENT_STICKY_READ',
    CLIENT_STICKY_UPDATE = 'CLIENT_STICKY_UPDATE',

    TASK_CREATE = 'TASK_CREATE',
    TASK_UPDATE = 'TASK_UPDATE',
    TASK_DELETE = 'TASK_DELETE',

    COMMENT_UPDATE = 'COMMENT_UPDATE',
    COMMENT_DELETE = 'COMMENT_DELETE',

    TIME_UPDATE = 'TIME_UPDATE',
    TIME_DELETE = 'TIME_DELETE',

    WIKI_UPDATE = 'WIKI_UPDATE',
    WIKI_DELETE = 'WIKI_DELETE',

    PJ_CREATE = 'PJ_CREATE',
    PJ_DELETE = 'PJ_DELETE',

    TAG_CREATE = 'TAG_CREATE',
    ASSIGN_OTHER = 'ASSIGN_OTHER',

    MS_UPDATE = 'MS_UPDATE',
    MS_DELETE = 'MS_DELETE',

    CLIENT_FETCH_ALL = 'CLIENT_FETCH_ALL',
    PROJECT_FETCH_ALL = 'PROJECT_FETCH_ALL',
    PROJECT_ADMIN_ALL = 'PROJECT_ADMIN_ALL',
    EMPLOYEE_FETCH_ALL = 'EMPLOYEE_FETCH_ALL',
    MS_RELEASE = 'MS_RELEASE',
}

// https://www.youtube.com/watch?v=8qMtsir0l9k
export type ALL_RIGHTS = RIGHTS | NAVIGATE_RIGHTS;

export const RIGHTS_INFO: {[right in ALL_RIGHTS]: {category: RightCategory; dependency?: ALL_RIGHTS}} = {
    [NAVIGATE_RIGHTS.NAVIGATE_FIRON]: {category: RightCategory.NAVIGATION},
    [NAVIGATE_RIGHTS.NAVIGATE_GANTT]: {category: RightCategory.NAVIGATION},
    [NAVIGATE_RIGHTS.NAVIGATE_TODO]: {category: RightCategory.NAVIGATION},
    [NAVIGATE_RIGHTS.NAVIGATE_WIKI]: {category: RightCategory.NAVIGATION},
    [NAVIGATE_RIGHTS.NAVIGATE_REPORTS]: {category: RightCategory.NAVIGATION},
    [NAVIGATE_RIGHTS.NAVIGATE_CALENDAR]: {category: RightCategory.NAVIGATION},
    [NAVIGATE_RIGHTS.NAVIGATE_CLIENTS]: {category: RightCategory.NAVIGATION},
    [NAVIGATE_RIGHTS.NAVIGATE_CLIENT]: {category: RightCategory.NAVIGATION},
    [NAVIGATE_RIGHTS.NAVIGATE_PROJECTS]: {category: RightCategory.NAVIGATION},
    [NAVIGATE_RIGHTS.NAVIGATE_PROJECT]: {category: RightCategory.NAVIGATION},
    [NAVIGATE_RIGHTS.NAVIGATE_EMPLOYEES]: {category: RightCategory.NAVIGATION},
    [NAVIGATE_RIGHTS.NAVIGATE_EMPLOYEE]: {category: RightCategory.NAVIGATION},
    [NAVIGATE_RIGHTS.NAVIGATE_ADMIN]: {category: RightCategory.NAVIGATION},
    [NAVIGATE_RIGHTS.NAVIGATE_DASHBOARD]: {category: RightCategory.NAVIGATION},
    [NAVIGATE_RIGHTS.NAVIGATE_WORKLOADTOOLS]: {category: RightCategory.NAVIGATION},
    [RIGHTS.MS_RELEASE]: {category: RightCategory.MILESTONES},
    [RIGHTS.MS_DELETE]: {category: RightCategory.MILESTONES},
    [RIGHTS.MS_UPDATE]: {category: RightCategory.MILESTONES},
    [RIGHTS.WIKI_UPDATE]: {dependency: NAVIGATE_RIGHTS.NAVIGATE_WIKI, category: RightCategory.WIKI},
    [RIGHTS.WIKI_DELETE]: {dependency: NAVIGATE_RIGHTS.NAVIGATE_WIKI, category: RightCategory.WIKI},
    [RIGHTS.PJ_CREATE]: {category: RightCategory.ATTACHMENTS},
    [RIGHTS.PJ_DELETE]: {category: RightCategory.ATTACHMENTS},
    [RIGHTS.TIME_UPDATE]: {category: RightCategory.TIMES},
    [RIGHTS.TIME_DELETE]: {category: RightCategory.TIMES},
    [RIGHTS.ASSIGN_OTHER]: {dependency: NAVIGATE_RIGHTS.NAVIGATE_FIRON, category: RightCategory.TASKS},
    [RIGHTS.TASK_CREATE]: {category: RightCategory.TASKS},
    [RIGHTS.TASK_UPDATE]: {category: RightCategory.TASKS},
    [RIGHTS.TASK_DELETE]: {category: RightCategory.TASKS},
    [RIGHTS.COMMENT_UPDATE]: {dependency: NAVIGATE_RIGHTS.NAVIGATE_FIRON, category: RightCategory.COMMENT},
    [RIGHTS.COMMENT_DELETE]: {dependency: NAVIGATE_RIGHTS.NAVIGATE_FIRON, category: RightCategory.COMMENT},
    [RIGHTS.TAG_CREATE]: {category: RightCategory.PROJECTS},
    [RIGHTS.PROJECT_TAB_MS]: {dependency: NAVIGATE_RIGHTS.NAVIGATE_PROJECT, category: RightCategory.PROJECTS},
    [RIGHTS.PROJECT_TAB_BG]: {dependency: NAVIGATE_RIGHTS.NAVIGATE_PROJECT, category: RightCategory.PROJECTS},
    [RIGHTS.PROJECT_TAB_TK]: {dependency: NAVIGATE_RIGHTS.NAVIGATE_PROJECT, category: RightCategory.PROJECTS},
    [RIGHTS.PROJECT_TAB_TI]: {dependency: NAVIGATE_RIGHTS.NAVIGATE_PROJECT, category: RightCategory.PROJECTS},
    [RIGHTS.PROJECT_TAB_RL]: {dependency: NAVIGATE_RIGHTS.NAVIGATE_PROJECT, category: RightCategory.PROJECTS},
    [RIGHTS.PROJECT_TAB_BK]: {dependency: NAVIGATE_RIGHTS.NAVIGATE_PROJECT, category: RightCategory.PROJECTS},
    [RIGHTS.PROJECT_TAB_PR]: {dependency: NAVIGATE_RIGHTS.NAVIGATE_PROJECT, category: RightCategory.PROJECTS},
    [RIGHTS.PROJECT_STICKY_READ]: {dependency: NAVIGATE_RIGHTS.NAVIGATE_PROJECT, category: RightCategory.PROJECTS},
    [RIGHTS.PROJECT_STICKY_UPDATE]: {dependency: RIGHTS.PROJECT_STICKY_READ, category: RightCategory.PROJECTS},
    [RIGHTS.PROJECT_DELETE]: {dependency: NAVIGATE_RIGHTS.NAVIGATE_PROJECTS, category: RightCategory.PROJECTS},
    [RIGHTS.PROJECT_CREATE]: {dependency: NAVIGATE_RIGHTS.NAVIGATE_PROJECTS, category: RightCategory.PROJECTS},
    [RIGHTS.MONEY]: {category: RightCategory.EMPLOYEES},
    [RIGHTS.EMPLOYEE_FETCH_ALL]: {category: RightCategory.EMPLOYEES},
    [RIGHTS.EMPLOYEE_DELETE]: {dependency: NAVIGATE_RIGHTS.NAVIGATE_EMPLOYEES, category: RightCategory.EMPLOYEES},
    [RIGHTS.EMPLOYEE_CREATE]: {dependency: NAVIGATE_RIGHTS.NAVIGATE_EMPLOYEES, category: RightCategory.EMPLOYEES},
    [RIGHTS.EMPLOYEE_UPDATE]: {dependency: NAVIGATE_RIGHTS.NAVIGATE_EMPLOYEES, category: RightCategory.EMPLOYEES},
    [RIGHTS.CLIENT_FETCH_ALL]: {category: RightCategory.CLIENTS},
    [RIGHTS.CLIENT_UPDATE]: {dependency: NAVIGATE_RIGHTS.NAVIGATE_CLIENTS, category: RightCategory.CLIENTS},
    [RIGHTS.CLIENT_CONTACTS]: {dependency: RIGHTS.CLIENT_UPDATE, category: RightCategory.CLIENTS},
    [RIGHTS.CLIENT_STICKY_READ]: {dependency: NAVIGATE_RIGHTS.NAVIGATE_CLIENT, category: RightCategory.CLIENTS},
    [RIGHTS.CLIENT_DELETE]: {dependency: NAVIGATE_RIGHTS.NAVIGATE_CLIENTS, category: RightCategory.CLIENTS},
    [RIGHTS.CLIENT_CREATE]: {dependency: NAVIGATE_RIGHTS.NAVIGATE_CLIENTS, category: RightCategory.CLIENTS},
    [RIGHTS.CLIENT_TAB_MS]: {dependency: NAVIGATE_RIGHTS.NAVIGATE_CLIENT, category: RightCategory.CLIENTS},
    [RIGHTS.CLIENT_TAB_PJ]: {dependency: NAVIGATE_RIGHTS.NAVIGATE_CLIENT, category: RightCategory.CLIENTS},
    [RIGHTS.CLIENT_TAB_RQ]: {dependency: NAVIGATE_RIGHTS.NAVIGATE_CLIENT, category: RightCategory.CLIENTS},
    [RIGHTS.CLIENT_TAB_IN]: {dependency: NAVIGATE_RIGHTS.NAVIGATE_CLIENT, category: RightCategory.CLIENTS},
    [RIGHTS.CLIENT_TAB_CH]: {dependency: NAVIGATE_RIGHTS.NAVIGATE_CLIENT, category: RightCategory.CLIENTS},
    [RIGHTS.CLIENT_TAB_BK]: {dependency: NAVIGATE_RIGHTS.NAVIGATE_CLIENT, category: RightCategory.CLIENTS},
    [RIGHTS.CLIENT_BK_DL]: {dependency: RIGHTS.CLIENT_TAB_BK, category: RightCategory.CLIENTS},
    [NAVIGATE_RIGHTS.NAVIGATE_IDEABOARD]: {category: RightCategory.NAVIGATION},
    [RIGHTS.PROJECT_FETCH_ALL]: {category: RightCategory.PROJECTS},
    [RIGHTS.PROJECT_ADMIN_ALL]: {dependency: RIGHTS.PROJECT_FETCH_ALL, category: RightCategory.PROJECTS},
    [RIGHTS.CLIENT_STICKY_UPDATE]: {dependency: RIGHTS.CLIENT_STICKY_READ, category: RightCategory.CLIENTS},
};

export enum MilestonesType {
    MEETING = 'MEETING',
    MEETING_NO_NOTE = 'MEETING_NO_NOTE',
    DEADLINE = 'DEADLINE',
    REMINDER = 'REMINDER',
    RELEASE = 'RELEASE',
    CALL = 'CALL',
}

export const MILESTONE_TYPE_RIGHTS: {[key in MilestonesType]: boolean} = {
    [MilestonesType.MEETING]: false,
    [MilestonesType.MEETING_NO_NOTE]: false,
    [MilestonesType.DEADLINE]: true,
    [MilestonesType.REMINDER]: false,
    [MilestonesType.RELEASE]: true,
    [MilestonesType.CALL]: false,
};

export enum ReleaseStateType {
    IN_DEVELOPMENT = 'IN_DEVELOPMENT',
    STAGED = 'STAGED',
    RELEASED = 'RELEASED',
}

export const RELEASE_TYPE_LABEL: {[key in ReleaseStateType]: string} = {
    [ReleaseStateType.IN_DEVELOPMENT]: 'In development',
    [ReleaseStateType.STAGED]: 'Staged',
    [ReleaseStateType.RELEASED]: 'Released',
};

export const MILESTONE_TYPE_LABEL: {[key in MilestonesType]: string} = {
    [MilestonesType.MEETING]: 'Meeting',
    [MilestonesType.MEETING_NO_NOTE]: 'Meeting without note',
    [MilestonesType.DEADLINE]: 'Deadline',
    [MilestonesType.REMINDER]: 'Reminder',
    [MilestonesType.RELEASE]: 'Release',
    [MilestonesType.CALL]: 'Call',
};

export const MILESTONE_TYPE_ICON: {[key in MilestonesType]: string} = {
    [MilestonesType.MEETING]: 'users',
    [MilestonesType.MEETING_NO_NOTE]: 'users',
    [MilestonesType.DEADLINE]: 'step-forward-2',
    [MilestonesType.REMINDER]: 'bell',
    [MilestonesType.RELEASE]: 'checkbox-list',
    [MilestonesType.CALL]: 'phone-handset',
};

export enum CustomerRequestStatus {
    NEW = 'NEW',
    OPEN = 'OPEN',
    DEFINED = 'DEFINED',
    AGREED = 'AGREED',
}

export enum IncidentStatus {
    NEW = 'NEW',
    OPEN = 'OPEN',
    SOLVED = 'SOLVED',
}

export enum TaskType {
    BUG = 'BUG',
    TASK = 'TASK',
}

export enum TaskStatusType {
    BACKLOG = 'BACKLOG',
    TODO = 'TODO',
    INPROGRESS = 'INPROGRESS',
    REVIEW = 'REVIEW',
    REVIEWING = 'REVIEWING',
    DONE = 'DONE',
}

export enum AssignedEmployeeType {
    DEVELOPER = 'DEVELOPER',
    REVIEWER = 'REVIEWER',
}

export enum ProjectMemberType {
    MANAGER = 'MANAGER',
    MEMBER = 'MEMBER',
}

export enum TaskResolution {
    WONT_FIX = 'WONT_FIX',
    FIXED = 'FIXED',
    CANT_REPRODUCE = 'CANT_REPRODUCE',
    DUPLICATE = 'DUPLICATE',
}

export const TASK_RESOLUTION_LABEL: {[key in TaskResolution]: string} = {
    [TaskResolution.WONT_FIX]: "Won't fix",
    [TaskResolution.FIXED]: 'Fixed',
    [TaskResolution.CANT_REPRODUCE]: "Can't reproduce",
    [TaskResolution.DUPLICATE]: 'Duplicate',
};

export const TASK_STATUS_LABEL: {[key in TaskStatusType]: string} = {
    [TaskStatusType.BACKLOG]: 'Backlog',
    [TaskStatusType.TODO]: 'TODO',
    [TaskStatusType.INPROGRESS]: 'In Progress',
    [TaskStatusType.REVIEW]: 'Review',
    [TaskStatusType.REVIEWING]: 'In Review',
    [TaskStatusType.DONE]: 'GGWP',
};

export const TASK_STATUS_SHORT_LABEL: {[key in TaskStatusType]: string} = {
    [TaskStatusType.BACKLOG]: 'BL',
    [TaskStatusType.TODO]: 'TD',
    [TaskStatusType.INPROGRESS]: 'IP',
    [TaskStatusType.REVIEW]: 'R',
    [TaskStatusType.REVIEWING]: 'IR',
    [TaskStatusType.DONE]: 'GG',
};

export const TASK_STATUS_CLASS: {[key in TaskStatusType]: string} = {
    [TaskStatusType.BACKLOG]: 'inactive',
    [TaskStatusType.TODO]: 'inactive',
    [TaskStatusType.INPROGRESS]: 'default',
    [TaskStatusType.REVIEW]: 'default',
    [TaskStatusType.REVIEWING]: 'default',
    [TaskStatusType.DONE]: 'success',
};

export enum TodoStatusType {
    CANCELED = 'CANCELED',
    TODO = 'TODO',
    DONE = 'DONE',
}

export enum ProjectStatusType {
    OPEN = 'OPEN',
    WAITING = 'WAITING',
    CLOSE = 'CLOSE',
}

export enum FailedReviewReasons {
    UX = 'FAILED_REVIEW_REASONS_UX',
    UI = 'FAILED_REVIEW_REASONS_UI',
    BROWSER_COMPATIBILITY = 'FAILED_REVIEW_REASONS_BROWSER_COMPATIBILITY',
    BREAKING = 'FAILED_REVIEW_REASONS_BREAKING',
    REGRESSION = 'FAILED_REVIEW_REASONS_REGRESSION',
    DETAIL = 'FAILED_REVIEW_REASONS_DETAIL',
    OTHER = 'FAILED_REVIEW_REASONS_OTHER',
}

export enum FileStateType {
    WAITING = 'WAITING',
    PENDING = 'PENDING',
    UPLOADING = 'UPLOADING',
    PAUSED = 'PAUSED',
    DONE = 'DONE',
    CANCELLED = 'CANCELLED',
    ERROR = 'ERROR',
    REMOVED = 'REMOVED',
}

export enum WikiPageType {
    PAGE = 'PAGE',
    MEETING_NOTE = 'MEETING_NOTE',
    TECHNICAL_NOTE = 'TECHNICAL_NOTE',
    CHANGELOG = 'CHANGELOG',
}

export enum DATABASE_SCHEMA {
    PUBLIC = 'public',
    BI = 'bi',
    STORAGE = 'storage',
    META = 'meta',
    IDEABOARD = 'ideaboard',
}

export interface ISchemaRoute {
    tableName?: string;
    singularRoute: string;
    pluralRoute: string;
    schema?: DATABASE_SCHEMA; // defaults to public
}

export enum STORED_TYPE {
    WIKI_PAGE = 'STORED_TYPE_WIKI_PAGE',
    WIKI_ATTACHMENT = 'STORED_TYPE_WIKI_ATTACHMENT',
    CUSTOMER_REQUEST_FILE = 'STORED_TYPE_CUSTOMER_REQUEST_FILE',
    TASK_FILE = 'STORED_TYPE_TASK_FILE',
    IDEA_FILE = 'STORED_TYPE_IDEA_FILE',
}

export const WIKI_PAGE_SORT_FUNCTIONS: {[field in WIKI_PAGE_FIELD]?: (p1: IWikiPage, p2: IWikiPage) => number} = {
    [WIKI_PAGE_FIELD.updatedAt]: (p1: IWikiPage, p2: IWikiPage) =>
        moment(p1.updatedAt).isBefore(p2.updatedAt) ? 1 : -1,
    [WIKI_PAGE_FIELD.title]: (p1: IWikiPage, p2: IWikiPage) => p1.title.localeCompare(p2.title),
};

export enum STORAGE_RIGHT {
    ADMIN = 'STORAGE_RIGHT_ADMIN',
    EMPLOYEE = 'STORAGE_RIGHT_EMPLOYEE',
    CLIENT = 'STORAGE_RIGHT_CLIENT',
    PUBLIC = 'STORAGE_RIGHT_PUBLIC',
}

export enum MimeTypes {
    HTML = 'text/html',
    MARKDOWN = 'text/markdown',
    JPG = 'image/jpg',
    JPEG = 'image/jpeg',
    PNG = 'image/png',
    BMP = 'image/bitmap',
    PDF = 'application/pdf',
    ZIP = 'application/x-zip-compressed',
    DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    PPTX = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    XML = 'application/xml',
    XMLTEXT = 'text/xml',
    RTF = 'application/rtf',
    XLS = 'application/vnd.ms-excel',
    CSV = 'text/csv',
    CSVEXCEL = 'application/vnd.ms-excel',
    DOC = 'application/msword',
    SVG = 'image/svg+xml',
    PLAIN = 'text/plain',
}

export enum MimeTypesExtensions {
    HTML = '.html',
    MARKDOWN = '.md',
    JPG = '.jpg',
    JPEG = '.jpeg',
    PNG = '.png',
    BMP = '.bmp',
    PDF = '.pdf',
    ZIP = '.zip',
    DOCX = '.docx',
    PPTX = '.pptx',
    XLSX = '.xlsx',
    XLS = '.xls',
    XML = '.xml',
    XMLTEXT = '.xml',
    RTF = '.rtf',
    CSV = '.csv',
    CSVEXCEL = '.csv',
    DOC = '.doc',
    SVG = '.svg',
    PLAIN = '.txt',
}

export enum MimeTypesPandocReader {
    HTML = 'html',
    MARKDOWN = 'markdown',
    PDF = 'pdf',
    DOCX = 'docx',
}

export enum EXPORT_FORMATS {
    PDF = MimeTypes.PDF,
    MARKDOWN = MimeTypes.MARKDOWN,
    HTML = MimeTypes.HTML,
    DOCX = MimeTypes.DOCX,
}

export enum IMPORT_FORMATS {
    HTML = MimeTypes.HTML,
    MARKDOWN = MimeTypes.MARKDOWN,
    DOCX = MimeTypes.DOCX,
}

export const PREVIEW_FORMATS = [MimeTypes.PNG, MimeTypes.JPG, MimeTypes.JPEG, MimeTypes.BMP, MimeTypes.PDF];

export enum COGNITO_USER_GROUPS {
    ADMIN = 'admin',
    EMPLOYEE = 'employee',
    BOT = 'bot',
    CLIENT = 'client',

    _AUTHED = '_AUTHED',
    _UNAUTHED = '_UNAUTHED',
}

export const COGNITO_USER_GROUPS_PRECEDENCE: {[group in COGNITO_USER_GROUPS]: number} = {
    [COGNITO_USER_GROUPS.ADMIN]: 3,
    [COGNITO_USER_GROUPS.EMPLOYEE]: 5,
    [COGNITO_USER_GROUPS.BOT]: 7,
    [COGNITO_USER_GROUPS.CLIENT]: 10,
    [COGNITO_USER_GROUPS._AUTHED]: 999,
    [COGNITO_USER_GROUPS._UNAUTHED]: 1000,
};

export interface IDecodedCognitoToken {
    sub: string;
    event_id: string;
    token_use: string;
    scope: string;
    auth_time: number;
    iss: string;
    exp: number;
    iat: number;
    jti: string;
    client_id: string;
    username: string;
    'cognito:groups': COGNITO_USER_GROUPS[];
}

export interface IIdeaComplete extends IIdea {
    totalUpvotes: number;
    totalDownvotes: number;
}

export enum GIT_LOG_KEY {
    HASH = 'hash',
    SHORT_HASH = 'shortHash',
    TREE = 'tree',
    PARENT = 'parent',
    AUTHOR = 'author',
    COMMITTER = 'committer',
    MESSAGE = 'message',
    RAW_DIFFS = 'rawDiffs',
    DIFFS = 'diffs',
    DATE = 'date',
    DIFF = 'diff',
}

export type GitLogData = {[key in GIT_LOG_KEY]?: string};

export const DEFAULT_LIMIT = 100;
export const DECIMAL_RADIX = 10;

export const ISO_DATE_FORMAT = 'YYYY-MM-DD';
export const ISO_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ssZZ';
export const ISO_DATE_TIME_WITHOUT_TZ_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const ISO_MONTH_FORMAT = 'YYYY-MM';

export const PERCENTAGE = 100;
export const PERCENTAGE_PRECISION = 2;

export const SECONDS_IN_MINUTE = 60;
export const MILLISECONDS_IN_SECOND = 1000;

export const MD5_DIGEST_BYTES_LENGTH = 16;
export const MD5_DIGEST_HEX_LENGTH = 32;

export const GUID_LENGTH = 36;

export const MAX_LENGTH_TASK_NAME = 250;
export const MAX_LENGTH_DEFAULT = 255;
export const MAX_LENGTH_COGNITO_USERNAME = 128;
export const MAX_LENGTH_COGNITO_GROUP_NAME = 128;
// _ prefixed
export const MAX_LENGTH_COGNITO_CUSTOM_GROUP_NAME = MAX_LENGTH_COGNITO_GROUP_NAME - 1;
export const MAX_LENGTH_COGNITO_GROUP_DESCRIPTION = 2048;
export const MAX_LENGTH_DISCORD_SNOWFLAKE = 20;

export const PATTERN_NUMBER = /^\d*$/;
export const PATTERN_CAPITAL_NUMERIC_SPACE = /^[A-Z 0-9]*$/;
export const PATTERN_TIME = /^(\d+[wdhm]\s*)*$/;
export const PATTERN_COGNITO_USERNAME = /^[a-z0-9._\-]+$/;

export interface IMetadata {
    _metadata?: any;
}

export enum BACKUP_FIELD {
    key = 'key',
    size = 'size',
    date = 'date',
}

export interface IBackup {
    key: string;
    size: number;
    date: string;
}

export interface IStorageFile {
    key: string;
    size: number;
    date: string;
}

export enum APP_HTTP_HEADERS {
    WWW_VERSION = 'x-www-version',
}

export enum API_ERROR_TYPE {
    VERSION_MISMATCH = 'VERSION_MISMATCH',
}

export const stripAccents = (str = ''): string => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

export const TIME_COMPONENTS: {[key in moment.unitOfTime.Base]?: string} = {
    years: 'Y',
    months: 'M',
    weeks: 'w',
    days: 'd',
    hours: 'h',
    minutes: 'm',
    seconds: 's',
};

export enum ComponentType {
    TEXT = 'TEXT',
    IDEA = 'IDEA',
    CIRCLE = 'CIRCLE',
    CIRCLE_EMPTY = 'CIRCLE_EMPTY',
    TRIANGLE = 'TRIANGLE',
    LINE = 'LINE',
    ARROW = 'ARROW',
    DOUBLE_ARROW = 'DOUBLE_ARROW',
    RECTANGLE = 'RECTANGLE',
    RECTANGLE_EMPTY = 'RECTANGLE_EMPTY',
    FOLDER = 'FOLDER',
}

export enum CollaboratorType {
    READ = 'READ',
    WRITE = 'WRITE',
    MODERATOR = 'MODERATOR',
}

export enum IdeaStatus {
    OPEN = 'OPEN',
    CLOSE = 'CLOSE',
}

export enum BoardStatus {
    OPEN = 'OPEN',
    CLOSE = 'CLOSE',
}

export enum GANTT_WORKLOAD_TYPE {
    PROJECT = 'PROJECT',
    MEETING = 'MEETING',
    OVERTIME = 'OVERTIME',
}

export interface IGanttWorkload {
    employeeId: number;
    day: string;
    stuff: IGanttWorkloadDetail[];
    realstuff?: IGanttWorkloadDetail[];
}

export interface IGanttWorkloadDetail {
    workload: number;
    type: GANTT_WORKLOAD_TYPE;
    projectId: number;
}
export interface IReleaseWorkload {
    employeeId: number;
    day: string;
    stuff: IReleaseWorkloadDetail[];
}

export interface IReleaseWorkloadDetail {
    progress: number;
    projectId: number;
    releaseId: number;
}
