import { AfterViewInit, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
var AutofocusDirective = (function () {
    function AutofocusDirective(element) {
        this.element = element;
        this.afSelect = false;
        this._autofocus = true;
    }
    AutofocusDirective.prototype.ngOnChanges = function (changes) {
        if (changes.sharedAutofocus && changes.sharedAutofocus.currentValue !== changes.sharedAutofocus.previousValue) {
            this.triggerAutofocus();
        }
    };
    AutofocusDirective.prototype.ngAfterViewInit = function () {
        this.triggerAutofocus();
    };
    AutofocusDirective.prototype.triggerAutofocus = function () {
        var _this = this;
        if (!this._autofocus) {
            return;
        }
        window.setTimeout(function () { return _this.element.nativeElement.focus(); }, 0);
        if (this.afSelect) {
            window.setTimeout(function () { return _this.element.nativeElement.select(); }, 0);
        }
    };
    Object.defineProperty(AutofocusDirective.prototype, "sharedAutofocus", {
        set: function (autofocus) {
            this._autofocus = autofocus;
        },
        enumerable: true,
        configurable: true
    });
    return AutofocusDirective;
}());
export { AutofocusDirective };
