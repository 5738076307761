import * as i0 from "@angular/core";
export var TOAST_POSITION;
(function (TOAST_POSITION) {
    TOAST_POSITION["TOP_LEFT"] = "top-left";
    TOAST_POSITION["TOP"] = "top";
    TOAST_POSITION["TOP_RIGHT"] = "top-right";
    TOAST_POSITION["RIGHT"] = "right";
    TOAST_POSITION["BOTTOM_RIGHT"] = "bottom-right";
    TOAST_POSITION["BOTTOM"] = "bottom";
    TOAST_POSITION["BOTTOM_LEFT"] = "bottom-left";
    TOAST_POSITION["LEFT"] = "left";
})(TOAST_POSITION || (TOAST_POSITION = {}));
export var TOAST_TYPE;
(function (TOAST_TYPE) {
    TOAST_TYPE["INFO"] = "info";
    TOAST_TYPE["SUCCESS"] = "success";
    TOAST_TYPE["WARNING"] = "warning";
    TOAST_TYPE["ERROR"] = "danger";
})(TOAST_TYPE || (TOAST_TYPE = {}));
var ToastService = (function () {
    function ToastService() {
        this.toasts = [];
        this.appLevelToasts = [];
    }
    ToastService.prototype.show = function (params) {
        if (!params.appLevel) {
            params.closed = false;
            this.toasts.push(params);
            window.setTimeout(function () {
                params.closed = true;
            }, 6000);
            return;
        }
        this.appLevelToasts.push(params);
    };
    ToastService.prototype.hideAll = function () {
        this.appLevelToasts.map(function (toast) { return (toast.closed = true); });
        this.appLevelToasts.length = 0;
    };
    ToastService.ngInjectableDef = i0.defineInjectable({ factory: function ToastService_Factory() { return new ToastService(); }, token: ToastService, providedIn: "root" });
    return ToastService;
}());
export { ToastService };
