import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'prepareMarkdown',
})
export class PrepareMarkdownPipe implements PipeTransform {
    public transform = (value: string): string => {
        return (value.trim() || '').replace(/\n/g, '\n\n');
    };
}
