import {Component} from '@angular/core';
import {PATTERN_NUMBER} from '../../../../defs/schema-static';
import {AuthService} from './auth.service';

@Component({
    selector: 'app-auth',
    templateUrl: './auth.component.html',
})
export class AuthComponent {
    public constructor(public authService: AuthService) {}

    public readonly PATTERN_NUMBER = PATTERN_NUMBER;
    public readonly PASSWORD_SPECIFICATIONS: {i18n: string; params?: {[key: string]: string | number}}[] = [
        {i18n: 'password_specification_length', params: {length: 8}},
        {i18n: 'password_specification_complexity'},
    ];
}
