import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {ClrWizard} from '@clr/angular';
import {ICognitoGroup} from '../../../../defs/schema/public/Cognito';
import {IEmployee} from '../../../../defs/schema/public/Employees';
import {IProject} from '../../../../defs/schema/public/Projects';
import {IResettable} from '../app-static';
import {SHORTCUT_WIZARD, ShortcutHandlerService} from '../shared/shortcut-handler/shortcut-handler.service';
import {EmployeeWizardEmployeeComponent} from './employee/employee-wizard-employee.component';
import {EmployeeWizardGroupRightsComponent} from './group-rights/employee-wizard-group-rights.component';
import {EmployeeWizardProjectsComponent} from './projects/employee-wizard-projects.component';

@Component({
    selector: 'app-employee-wizard',
    templateUrl: './employee-wizard.component.html',
})
export class EmployeeWizardComponent implements IResettable, OnChanges, OnInit {
    @ViewChild(ClrWizard) public wizard: ClrWizard;
    @ViewChild(EmployeeWizardEmployeeComponent) public employeeComponent: EmployeeWizardEmployeeComponent;
    @ViewChild(EmployeeWizardProjectsComponent) public projectsComponent: EmployeeWizardProjectsComponent;
    @ViewChild(EmployeeWizardGroupRightsComponent) public rightsComponent: EmployeeWizardGroupRightsComponent;

    @Input() public clrWizardOpen = false;
    @Output() public clrWizardOpenChange = new EventEmitter<boolean>();

    @Output() public finish = new EventEmitter<IEmployee>();

    @Input() public projects: IProject[];
    @Input() public groups: ICognitoGroup[];

    @Input() public edit = false;

    public submitting = false;

    public constructor(private readonly shortcutHandlerService: ShortcutHandlerService) {}

    public ngOnInit() {
        this.shortcutHandlerService.register({
            shortcut: SHORTCUT_WIZARD.NEXT,
            callback: (e) => {
                if (!this.wizard.currentPage.nextStepDisabled && (e.target as HTMLElement).tagName !== 'TEXTAREA') {
                    if (this.wizard.isLast) {
                        this.triggerFinish();
                    } else {
                        this.wizard.next();
                    }
                }
            },
            context: this,
            forceListen: true,
        });
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.clrWizardOpen && changes.clrWizardOpen.currentValue) {
            this.employeeComponent.onLoad();
        }
    }

    public async triggerFinish() {
        this.submitting = true;
        try {
            const employee = await this.employeeComponent.submit();

            if (!employee) {
                return;
            }

            {
                this.projectsComponent.employee = employee;
                await this.projectsComponent.submit();
            }

            {
                this.rightsComponent.employee = employee;
                await this.rightsComponent.submit();
            }

            this.finish.emit(employee);
        } catch (err) {
            return;
        } finally {
            this.submitting = false;
        }

        this.triggerCancel();
    }

    public triggerCancel() {
        this.reset();
        this.wizard.forceFinish();
    }

    public reset(): void {
        this.wizard.reset();

        [this.employeeComponent, this.projectsComponent, this.rightsComponent].map((component) => component.reset());
    }
}
