import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ClrDatagridComparatorInterface, ClrDatagridSortOrder} from '@clr/angular';
import {compare} from 'semver';
import {
    ApiRoutePlurality,
    HTTP_METHOD,
    ISO_DATE_FORMAT,
    MilestonesType,
    ReleaseStateType,
    RIGHTS,
    WikiPageType,
} from '../../../../../defs/schema-static';
import {IMilestone, MILESTONE_FIELD, MILESTONE_SCHEMA_ROUTE} from '../../../../../defs/schema/public/Milestones';
import {IProject} from '../../../../../defs/schema/public/Projects';
import {TASK_SCHEMA_ROUTE} from '../../../../../defs/schema/public/Tasks';
import {IWikiPage, WIKI_PAGE_SCHEMA_ROUTE} from '../../../../../defs/schema/public/WikiPage';
import {DATAGRID_FILTER_TYPE, ICount, IDatagridColumn, RELEASE_STATE_FILTER} from '../../app-static';
import {AuthService} from '../../auth/auth.service';
import {FormsAddMilestoneService, IMilestoneFormValues, MilestoneTarget} from '../../forms/add-milestone.service';
import {HttpRestService} from '../../shared/http-rest/http-rest.service';
import {MomentService} from '../../shared/moment/moment.service';
import {TOAST_TYPE, ToastService} from '../../shared/toast/toast.service';

@Component({
    selector: 'app-project-releases',
    templateUrl: './project-releases.component.html',
})
export class ProjectReleasesComponent implements OnInit, OnChanges {
    @Input() public project: IProject;
    @Input() public releases: Partial<IMilestone>[];

    public selectedRelease: Partial<IMilestone>;

    public editionRelease: IMilestoneFormValues;

    public showCreateModal = false;
    public showEditModal = false;
    public showReleaseModal = false;
    public showMoveTasksModal = false;
    public showCreateChangelogModal = false;
    public showDeleteModal = false;

    public modalLoading = false;

    public undoneTasksCount = 0;
    public otherUnreleased: Partial<IMilestone>[] = [];
    public targetRelease?: Partial<IMilestone> = undefined;

    private editQueryParams: number;

    public constructor(
        private readonly httpRest: HttpRestService,
        private readonly formsAddMilestoneService: FormsAddMilestoneService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly authService: AuthService,
        private readonly momentService: MomentService,
        private readonly toastService: ToastService
    ) {}

    public ngOnInit() {
        this.getReleases();

        this.route.queryParams.subscribe((queryParams) => {
            this.editQueryParams = Number(queryParams.edit) || undefined;
            this.selectedReleaseChange();
        });
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.project) {
            this.releases = undefined;
            this.getReleases();
        }
    }

    public getReleases() {
        if (!this.project || !this.project.milestones) {
            return;
        }

        this.releases =
            this.releases || this.project.milestones.filter((milestone) => milestone.type === MilestonesType.RELEASE);
        this.selectedReleaseChange();
    }

    public selectedReleaseChange() {
        if (!this.editQueryParams) {
            this.selectedRelease = undefined;
            this.showEditModal = false;

            return;
        }

        this.selectedRelease = (this.releases || []).find((_release) => _release.id === this.editQueryParams);

        if (this.selectedRelease) {
            this.editRelease();
        }
    }

    public editRelease() {
        const release = this.selectedRelease;

        this.editionRelease = {
            id: release.id,
            author: release.employeeId,
            description: release.description,
            milestone: release.obs,
            participants: release.participants,
            project: this.project.id,
            startDate: this.momentService.moment(release.beginDate).format(ISO_DATE_FORMAT),
            startTime: this.momentService
                .moment(release.beginDate)
                .format(FormsAddMilestoneService.HOUR_MINUTE_TIME_FORMAT),
            endDate: this.momentService.moment(release.endDate).format(ISO_DATE_FORMAT),
            endTime: this.momentService
                .moment(release.endDate)
                .format(FormsAddMilestoneService.HOUR_MINUTE_TIME_FORMAT),
            target: this.momentService.moment(release.target).format(ISO_DATE_FORMAT),
            targetType: MilestoneTarget.PROJECT,
            type: release.type,
            version: release.version,
        };

        this.showEditModal = true;
    }

    public async closeEditModal() {
        return this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {edit: null},
            queryParamsHandling: 'merge',
        });
    }

    public async getUndoneTasksCount() {
        this.modalLoading = true;

        const {count} = await this.httpRest
            ._request<ICount>(
                HTTP_METHOD.GET,
                ApiRoutePlurality.PLURAL,
                TASK_SCHEMA_ROUTE,
                `countReleaseNotDone/${this.selectedRelease.id}`
            )
            .toPromise();

        this.undoneTasksCount = count;

        if (!this.undoneTasksCount) {
            await this.updateReleaseState();
            this.modalLoading = false;
            this.showReleaseModal = false;

            return;
        }

        [this.targetRelease] = this.otherUnreleased = this.getOtherUnreleased();
        this.modalLoading = false;
        this.showReleaseModal = false;
        this.showMoveTasksModal = true;
    }

    public async getChangelog() {
        if (!this.selectedRelease) {
            return;
        }

        const page = await this.httpRest
            ._request<IWikiPage>(
                HTTP_METHOD.GET,
                ApiRoutePlurality.SINGULAR,
                WIKI_PAGE_SCHEMA_ROUTE,
                `getByRelease/${this.selectedRelease.id}`
            )
            .toPromise();

        if (!page) {
            this.showCreateChangelogModal = true;

            return;
        }

        window.open(`/wiki/page/${page.hash}`);
    }

    public async updateReleaseState(targetState = ReleaseStateType.RELEASED) {
        this.modalLoading = true;
        await this.httpRest
            ._request(HTTP_METHOD.PUT, ApiRoutePlurality.PLURAL, MILESTONE_SCHEMA_ROUTE, 'release', {
                targetState,
                id: this.selectedRelease.id,
                targetReleaseId: this.targetRelease && this.targetRelease.id,
            })
            .toPromise();

        this.selectedRelease.releaseState = targetState;

        this.selectedRelease = undefined;
        this.targetRelease = undefined;
        this.otherUnreleased = [];

        this.modalLoading = false;
        this.showMoveTasksModal = false;

        this.toastService.show({
            type: TOAST_TYPE.SUCCESS,
            text: 'success_update_release',
        });
    }

    private getOtherUnreleased(): Partial<IMilestone>[] {
        const releaseId = this.selectedRelease.id;

        return (this.releases || [])
            .filter((release) => release.id !== releaseId && release.releaseState !== ReleaseStateType.RELEASED)
            .sort(ProjectReleasesComponent.SORT_MILESTONES_VERSION)
            .reverse();
    }

    public async addNewRelease(releaseForm: IMilestoneFormValues) {
        const release = await this.formsAddMilestoneService.submit(releaseForm);
        if (!release) {
            return undefined;
        }

        this.releases.push(release);

        return this.closeEditModal();
    }

    public async editedRelease(releaseForm: IMilestoneFormValues) {
        const release = await this.formsAddMilestoneService.edit({
            ...releaseForm,
            id: this.selectedRelease.id,
            type: this.selectedRelease.type,
        });

        Object.assign(this.selectedRelease, release);

        this.selectedRelease = undefined;

        return this.closeEditModal();
    }

    public async deleteSelectedRelease() {
        const releaseId = this.selectedRelease.id;
        try {
            await this.httpRest.deleteId(MILESTONE_SCHEMA_ROUTE, releaseId).toPromise();
        } catch (err) {
            this.showDeleteModal = false;

            return;
        }
        this.selectedRelease = undefined;
        this.releases = this.releases.filter((release) => releaseId !== release.id);

        this.showDeleteModal = false;
    }

    public readonly RIGHTS = RIGHTS;
    public readonly MILESTONE_FIELD = MILESTONE_FIELD;
    public readonly DATAGRID_FILTER_TYPE = DATAGRID_FILTER_TYPE;

    public readonly WikiPageType = WikiPageType;
    public readonly MilestonesType = MilestonesType;
    public readonly ReleaseStateType = ReleaseStateType;

    public readonly RELEASES_COLUMNS: IDatagridColumn[] = [
        {
            field: MILESTONE_FIELD.version,
            translateKey: 'version',
            sorter: {
                compare: ProjectReleasesComponent.SORT_MILESTONES_VERSION,
            } as ClrDatagridComparatorInterface<IMilestone>,
            order: ClrDatagridSortOrder.ASC,
            hideable: false,
        },
        {
            field: MILESTONE_FIELD.obs,
            translateKey: 'table_name',
        },
        {
            field: MILESTONE_FIELD.description,
            translateKey: 'table_description',
        },
        {
            field: MILESTONE_FIELD.target,
            translateKey: 'table_target',
            filterType: DATAGRID_FILTER_TYPE.DATE,
        },
        {
            field: MILESTONE_FIELD.target,
            translateKey: 'table_target_time',
            filterType: DATAGRID_FILTER_TYPE.DATE,
            hidden: true,
        },
        {
            field: MILESTONE_FIELD.beginDate,
            translateKey: 'begin_date',
            filterType: DATAGRID_FILTER_TYPE.DATE,
        },
        {
            field: MILESTONE_FIELD.beginDate,
            translateKey: 'table_begin_time',
            filterType: DATAGRID_FILTER_TYPE.DATE,
            hidden: true,
        },
        {
            field: MILESTONE_FIELD.endDate,
            translateKey: 'end_date',
            filterType: DATAGRID_FILTER_TYPE.DATE,
        },
        {
            field: MILESTONE_FIELD.endDate,
            translateKey: 'table_end_time',
            filterType: DATAGRID_FILTER_TYPE.DATE,
            hidden: true,
        },
        {
            field: MILESTONE_FIELD.releaseState,
            translateKey: 'table_status',
            filterType: DATAGRID_FILTER_TYPE.CUSTOM,
        },
    ];
    public filterOpen: {[key: string]: boolean} = {};

    public readonly RELEASE_STATE_FILTER = RELEASE_STATE_FILTER;

    public static readonly SORT_MILESTONES_VERSION = (r1: IMilestone, r2: IMilestone) =>
        r2.version ? (r1.version ? compare(r2.version, r1.version) : 1) : -1;

    public readonly isManagerOf: (projectId: number) => boolean = this.authService.isManagerOf.bind(this.authService);
    public readonly hasRight: (right: string) => boolean = this.authService.hasRight.bind(this.authService);
}
