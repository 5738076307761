import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ClrLoadingState} from '@clr/angular';
import {NgSelectComponent} from '@ng-select/ng-select';
import {TranslateService} from '@ngx-translate/core';
import {
    ApiRoutePlurality,
    COGNITO_USER_GROUPS,
    HTTP_METHOD,
    MAX_LENGTH_COGNITO_USERNAME,
    MAX_LENGTH_DEFAULT,
    PATTERN_COGNITO_USERNAME,
} from '../../../../../defs/schema-static';
import {CLIENT_SCHEMA_ROUTE, IClient} from '../../../../../defs/schema/public/Clients';
import {IProject, PROJECT_SCHEMA_ROUTE} from '../../../../../defs/schema/public/Projects';
import {IUser, USER_SCHEMA_ROUTE} from '../../../../../defs/schema/public/Users';
import {isColorDark} from '../../app-static';
import {HttpRestService} from '../../shared/http-rest/http-rest.service';
import {TOAST_TYPE, ToastService} from '../../shared/toast/toast.service';

@Component({
    selector: 'app-admin-clients',
    templateUrl: './admin-clients.component.html',
    styleUrls: ['./admin-clients.component.scss'],
})
export class AdminClientsComponent implements OnInit {
    @Input()
    public clients: IClient[] = [];
    public currentClient: IClient;

    public projects: IProject[] = [];
    public currentProject: IProject;

    public clientValidateBtnState: ClrLoadingState = ClrLoadingState.DEFAULT;
    public projectValidateBtnState: ClrLoadingState = ClrLoadingState.DEFAULT;

    @ViewChild('clientsNgSelect')
    public clientsSelect: NgSelectComponent;

    @ViewChild('projectsNgSelect')
    public projectsSelect: NgSelectComponent;

    public constructor(
        private readonly httpRest: HttpRestService,
        private readonly toastService: ToastService,
        private readonly translateService: TranslateService
    ) {}

    public ngOnInit() {
        this.httpRest
            ._request<IClient[]>(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, CLIENT_SCHEMA_ROUTE, 'light/memberOf')
            .subscribe((clients) => {
                this.clients = clients;
                this.clientsSelect.focus();
                this.clientsSelect.open();
            });
    }

    public clientChange(client: IClient) {
        this.currentClient = null;
        this.currentProject = null;
        if (client) {
            this.httpRest
                ._request<IClient>(HTTP_METHOD.GET, ApiRoutePlurality.SINGULAR, CLIENT_SCHEMA_ROUTE, `${client.id}`)
                .subscribe((c) => {
                    this.currentClient = c;
                    this.httpRest
                        ._request<IProject[]>(
                            HTTP_METHOD.GET,
                            ApiRoutePlurality.PLURAL,
                            PROJECT_SCHEMA_ROUTE,
                            `admin/${client.id}`
                        )
                        .subscribe((projects) => {
                            this.projects = projects;
                            if (this.projects.length) {
                                this.projectsSelect.focus();
                                this.projectsSelect.open();
                            }
                        });
                });
        }
    }

    public async saveClient() {
        if (!this.currentClient) {
            return;
        }

        this.clientValidateBtnState = ClrLoadingState.LOADING;

        const {backupPrefix} = this.currentClient;
        const {email} = this.currentClient.user;
        let {cognitoUsername} = this.currentClient.user;

        cognitoUsername = cognitoUsername || null;

        const oldClient = this.clients[this.clients.map((m) => m.id).indexOf(this.currentClient.id)];
        const cognitoUsernameBeforeSave = oldClient.user.cognitoUsername;

        const putCognito = async () =>
            this.httpRest
                ._request(HTTP_METHOD.PUT, ApiRoutePlurality.SINGULAR, USER_SCHEMA_ROUTE, 'cognito', {
                    username: cognitoUsername,
                    email,
                    groupName: COGNITO_USER_GROUPS.CLIENT,
                })
                .toPromise();

        const _postClient = async () =>
            this.httpRest
                .post<IClient>(CLIENT_SCHEMA_ROUTE, {
                    id: this.currentClient.id,
                    backupPrefix,
                })
                .toPromise();

        const _postUser = async () =>
            this.httpRest
                .post<IUser>(USER_SCHEMA_ROUTE, {
                    id: this.currentClient.user.id,
                    email,
                    cognitoUsername,
                })
                .toPromise();

        const postUser = async () => {
            await _postUser();
            await _postClient();
        };

        try {
            if (!cognitoUsernameBeforeSave && cognitoUsername) {
                try {
                    await putCognito();
                } catch (err) {
                    this.toastService.show({
                        type: TOAST_TYPE.ERROR,
                        text: 'generic_error',
                    });
                    throw err;
                }
            }
            await postUser();
        } catch (err) {
            this.clientValidateBtnState = ClrLoadingState.DEFAULT;

            return;
        }
        this.clientValidateBtnState = ClrLoadingState.SUCCESS;
    }

    public projectChange(project: IProject) {
        this.currentProject = null;
        if (project) {
            this.httpRest
                ._request<IProject>(HTTP_METHOD.GET, ApiRoutePlurality.SINGULAR, PROJECT_SCHEMA_ROUTE, `${project.id}`)
                .subscribe((p) => {
                    this.currentProject = p;
                });
        }
    }

    public async saveProject() {
        if (!this.currentProject) {
            return;
        }

        this.projectValidateBtnState = ClrLoadingState.LOADING;
        try {
            this.currentProject = await this.httpRest
                .post<IProject>(PROJECT_SCHEMA_ROUTE, {
                    id: this.currentProject.id,
                    backupPrefix: this.currentProject.backupPrefix,
                    discordPath: this.currentProject.discordPath,
                    repositoryUrl: this.currentProject.repositoryUrl,
                })
                .toPromise();
        } catch (err) {
            this.projectValidateBtnState = ClrLoadingState.DEFAULT;

            return;
        }
        this.projectValidateBtnState = ClrLoadingState.SUCCESS;
    }

    public readonly isColorDark = isColorDark;

    public readonly MAX_LENGTH_DEFAULT = MAX_LENGTH_DEFAULT;
    public readonly MAX_LENGTH_COGNITO_USERNAME = MAX_LENGTH_COGNITO_USERNAME;
    public readonly PATTERN_COGNITO_USERNAME = PATTERN_COGNITO_USERNAME;
}
