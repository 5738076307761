import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ClrDatagridSortOrder} from '@clr/angular';
import {ICognitoGroup} from '../../../../../../defs/schema/public/Cognito';
import {getGroupDisplayName} from '../../../app-static';

@Component({
    selector: 'app-admin-access-users-list',
    templateUrl: './admin-access-users-list.component.html',
})
export class AdminAccessUsersListComponent {
    @Input() public selectedUserGroups: ICognitoGroup[];
    @Output() public selectedUserGroupsChange = new EventEmitter<ICognitoGroup[]>();

    @Input() public groups: ICognitoGroup[];

    public filterOpen: {[key: string]: boolean} = {};

    public readonly getGroupDisplayName = getGroupDisplayName;

    public get proxiedSelectedUserGroups(): ICognitoGroup[] {
        return [...this.selectedUserGroups];
    }

    public readonly ClrDatagridSortOrder = ClrDatagridSortOrder;
}
