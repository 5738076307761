var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { ClrLoadingState } from '@clr/angular';
import { NgSelectComponent } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';
import { ApiRoutePlurality, COGNITO_USER_GROUPS, HTTP_METHOD, MAX_LENGTH_COGNITO_USERNAME, MAX_LENGTH_DEFAULT, PATTERN_COGNITO_USERNAME, } from '../../../../../defs/schema-static';
import { CLIENT_SCHEMA_ROUTE } from '../../../../../defs/schema/public/Clients';
import { PROJECT_SCHEMA_ROUTE } from '../../../../../defs/schema/public/Projects';
import { USER_SCHEMA_ROUTE } from '../../../../../defs/schema/public/Users';
import { isColorDark } from '../../app-static';
import { HttpRestService } from '../../shared/http-rest/http-rest.service';
import { TOAST_TYPE, ToastService } from '../../shared/toast/toast.service';
var AdminClientsComponent = (function () {
    function AdminClientsComponent(httpRest, toastService, translateService) {
        this.httpRest = httpRest;
        this.toastService = toastService;
        this.translateService = translateService;
        this.clients = [];
        this.projects = [];
        this.clientValidateBtnState = ClrLoadingState.DEFAULT;
        this.projectValidateBtnState = ClrLoadingState.DEFAULT;
        this.isColorDark = isColorDark;
        this.MAX_LENGTH_DEFAULT = MAX_LENGTH_DEFAULT;
        this.MAX_LENGTH_COGNITO_USERNAME = MAX_LENGTH_COGNITO_USERNAME;
        this.PATTERN_COGNITO_USERNAME = PATTERN_COGNITO_USERNAME;
    }
    AdminClientsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.httpRest
            ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, CLIENT_SCHEMA_ROUTE, 'light/memberOf')
            .subscribe(function (clients) {
            _this.clients = clients;
            _this.clientsSelect.focus();
            _this.clientsSelect.open();
        });
    };
    AdminClientsComponent.prototype.clientChange = function (client) {
        var _this = this;
        this.currentClient = null;
        this.currentProject = null;
        if (client) {
            this.httpRest
                ._request(HTTP_METHOD.GET, ApiRoutePlurality.SINGULAR, CLIENT_SCHEMA_ROUTE, "" + client.id)
                .subscribe(function (c) {
                _this.currentClient = c;
                _this.httpRest
                    ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, PROJECT_SCHEMA_ROUTE, "admin/" + client.id)
                    .subscribe(function (projects) {
                    _this.projects = projects;
                    if (_this.projects.length) {
                        _this.projectsSelect.focus();
                        _this.projectsSelect.open();
                    }
                });
            });
        }
    };
    AdminClientsComponent.prototype.saveClient = function () {
        return __awaiter(this, void 0, void 0, function () {
            var backupPrefix, email, cognitoUsername, oldClient, cognitoUsernameBeforeSave, putCognito, _postClient, _postUser, postUser, err_1, err_2;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.currentClient) {
                            return [2];
                        }
                        this.clientValidateBtnState = ClrLoadingState.LOADING;
                        backupPrefix = this.currentClient.backupPrefix;
                        email = this.currentClient.user.email;
                        cognitoUsername = this.currentClient.user.cognitoUsername;
                        cognitoUsername = cognitoUsername || null;
                        oldClient = this.clients[this.clients.map(function (m) { return m.id; }).indexOf(this.currentClient.id)];
                        cognitoUsernameBeforeSave = oldClient.user.cognitoUsername;
                        putCognito = function () { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                return [2, this.httpRest
                                        ._request(HTTP_METHOD.PUT, ApiRoutePlurality.SINGULAR, USER_SCHEMA_ROUTE, 'cognito', {
                                        username: cognitoUsername,
                                        email: email,
                                        groupName: COGNITO_USER_GROUPS.CLIENT,
                                    })
                                        .toPromise()];
                            });
                        }); };
                        _postClient = function () { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                return [2, this.httpRest
                                        .post(CLIENT_SCHEMA_ROUTE, {
                                        id: this.currentClient.id,
                                        backupPrefix: backupPrefix,
                                    })
                                        .toPromise()];
                            });
                        }); };
                        _postUser = function () { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                return [2, this.httpRest
                                        .post(USER_SCHEMA_ROUTE, {
                                        id: this.currentClient.user.id,
                                        email: email,
                                        cognitoUsername: cognitoUsername,
                                    })
                                        .toPromise()];
                            });
                        }); };
                        postUser = function () { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4, _postUser()];
                                    case 1:
                                        _a.sent();
                                        return [4, _postClient()];
                                    case 2:
                                        _a.sent();
                                        return [2];
                                }
                            });
                        }); };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 7, , 8]);
                        if (!(!cognitoUsernameBeforeSave && cognitoUsername)) return [3, 5];
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4, putCognito()];
                    case 3:
                        _a.sent();
                        return [3, 5];
                    case 4:
                        err_1 = _a.sent();
                        this.toastService.show({
                            type: TOAST_TYPE.ERROR,
                            text: 'generic_error',
                        });
                        throw err_1;
                    case 5: return [4, postUser()];
                    case 6:
                        _a.sent();
                        return [3, 8];
                    case 7:
                        err_2 = _a.sent();
                        this.clientValidateBtnState = ClrLoadingState.DEFAULT;
                        return [2];
                    case 8:
                        this.clientValidateBtnState = ClrLoadingState.SUCCESS;
                        return [2];
                }
            });
        });
    };
    AdminClientsComponent.prototype.projectChange = function (project) {
        var _this = this;
        this.currentProject = null;
        if (project) {
            this.httpRest
                ._request(HTTP_METHOD.GET, ApiRoutePlurality.SINGULAR, PROJECT_SCHEMA_ROUTE, "" + project.id)
                .subscribe(function (p) {
                _this.currentProject = p;
            });
        }
    };
    AdminClientsComponent.prototype.saveProject = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, err_3;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.currentProject) {
                            return [2];
                        }
                        this.projectValidateBtnState = ClrLoadingState.LOADING;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        _a = this;
                        return [4, this.httpRest
                                .post(PROJECT_SCHEMA_ROUTE, {
                                id: this.currentProject.id,
                                backupPrefix: this.currentProject.backupPrefix,
                                discordPath: this.currentProject.discordPath,
                                repositoryUrl: this.currentProject.repositoryUrl,
                            })
                                .toPromise()];
                    case 2:
                        _a.currentProject = _b.sent();
                        return [3, 4];
                    case 3:
                        err_3 = _b.sent();
                        this.projectValidateBtnState = ClrLoadingState.DEFAULT;
                        return [2];
                    case 4:
                        this.projectValidateBtnState = ClrLoadingState.SUCCESS;
                        return [2];
                }
            });
        });
    };
    return AdminClientsComponent;
}());
export { AdminClientsComponent };
