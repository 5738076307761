var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Validators } from '@angular/forms';
import { ClrForm } from '@clr/angular';
import { FormControl, FormGroup } from 'ngx-strongly-typed-forms';
import { ApiRoutePlurality, HTTP_METHOD, MAX_LENGTH_DEFAULT, PATTERN_CAPITAL_NUMERIC_SPACE, ProjectStatusType, } from '../../../../../defs/schema-static';
import { CLIENT_SCHEMA_ROUTE } from '../../../../../defs/schema/public/Clients';
import { PROJECT_SCHEMA_ROUTE } from '../../../../../defs/schema/public/Projects';
import { CLIENT_SORT_FUNCTION } from '../../../../../defs/sorters';
import { getRandomColorHex, MAX_LENGTH_PROJECT_CODE, PROJECT_STATUS_FILTER } from '../../app-static';
import { requiredTrimValidator } from '../../forms/validators/required-trim.validator';
import { HttpRestService } from '../../shared/http-rest/http-rest.service';
var PROJECT_FORM_KEYS;
(function (PROJECT_FORM_KEYS) {
    PROJECT_FORM_KEYS["obs"] = "obs";
    PROJECT_FORM_KEYS["code"] = "code";
    PROJECT_FORM_KEYS["color"] = "color";
    PROJECT_FORM_KEYS["clientId"] = "clientId";
    PROJECT_FORM_KEYS["status"] = "status";
})(PROJECT_FORM_KEYS || (PROJECT_FORM_KEYS = {}));
var ProjectWizardProjectComponent = (function () {
    function ProjectWizardProjectComponent(httpRest) {
        var _a;
        this.httpRest = httpRest;
        this.clients = [];
        this.formLayout = 'horizontal';
        this.clrWizardOpen = false;
        this.form = new FormGroup((_a = {},
            _a[PROJECT_FORM_KEYS.obs] = new FormControl(null, [
                requiredTrimValidator(),
                Validators.maxLength(MAX_LENGTH_DEFAULT),
            ]),
            _a[PROJECT_FORM_KEYS.code] = new FormControl(null, [
                requiredTrimValidator(),
                Validators.maxLength(MAX_LENGTH_PROJECT_CODE),
                Validators.pattern(PATTERN_CAPITAL_NUMERIC_SPACE),
            ]),
            _a[PROJECT_FORM_KEYS.color] = new FormControl(getRandomColorHex(), requiredTrimValidator()),
            _a[PROJECT_FORM_KEYS.clientId] = new FormControl(this.clientId, Validators.required),
            _a[PROJECT_FORM_KEYS.status] = new FormControl(ProjectStatusType.OPEN, Validators.required),
            _a));
        this.PROJECT_FORM_KEYS = PROJECT_FORM_KEYS;
        this.MAX_LENGTH_DEFAULT = MAX_LENGTH_DEFAULT;
        this.MAX_LENGTH_PROJECT_CODE = MAX_LENGTH_PROJECT_CODE;
        this.PROJECT_STATUS_VALUES = Object.values(ProjectStatusType);
        this.PROJECT_STATUS_FILTER = PROJECT_STATUS_FILTER;
    }
    ProjectWizardProjectComponent.prototype.ngOnInit = function () {
        this.setProject();
    };
    ProjectWizardProjectComponent.prototype.ngOnChanges = function (changes) {
        if (changes.clientId && !this.form.value.clientId) {
            this.form.patchValue({ clientId: this.clientId });
        }
        if (changes.project) {
            this.setProject();
        }
    };
    ProjectWizardProjectComponent.prototype.filterClient = function (id) {
        var _a;
        this.form.patchValue((_a = {},
            _a[PROJECT_FORM_KEYS.clientId] = id || null,
            _a));
    };
    ProjectWizardProjectComponent.prototype.setProject = function () {
        if (!this.project) {
            return this.reset();
        }
        this.form.reset(this.project);
    };
    ProjectWizardProjectComponent.prototype.getClients = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4, this.httpRest
                                ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, CLIENT_SCHEMA_ROUTE, 'list')
                                .toPromise()];
                    case 1:
                        _a.clients = (_b.sent()).sort(CLIENT_SORT_FUNCTION);
                        return [2];
                }
            });
        });
    };
    ProjectWizardProjectComponent.prototype.submit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var clientId, client, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.form.valid) {
                            this.clrForm.markAsDirty();
                            return [2, undefined];
                        }
                        clientId = this.form.value.clientId;
                        client = this.clients.find(function (_client) { return _client.id === clientId; });
                        _a = [{}];
                        return [4, this.httpRest
                                .put(PROJECT_SCHEMA_ROUTE, __assign({}, this.form.value))
                                .toPromise()];
                    case 1: return [2, __assign.apply(void 0, _a.concat([(_b.sent()), { client: client }]))];
                }
            });
        });
    };
    ProjectWizardProjectComponent.prototype.reset = function () {
        var _a;
        this.form.reset((_a = {},
            _a[PROJECT_FORM_KEYS.color] = getRandomColorHex(),
            _a[PROJECT_FORM_KEYS.clientId] = this.clientId,
            _a[PROJECT_FORM_KEYS.status] = ProjectStatusType.OPEN,
            _a));
    };
    return ProjectWizardProjectComponent;
}());
export { ProjectWizardProjectComponent };
