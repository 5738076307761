import {Component} from '@angular/core';
import {ToastService} from './toast.service';

@Component({
    selector: 'shared-toast',
    templateUrl: './toast.component.html',
    styleUrls: ['./toast.component.scss'],
})
export class ToastComponent {
    public constructor(public toastService: ToastService) {}
}
