<form id="milestoneParticipantsForm" clrForm [formGroup]="form">
    <ng-select
        [items]="members"
        [hideSelected]="true"
        [bindLabel]="'employee' + '.' + EMPLOYEE_FIELD.user + '.' + USER_FIELD.name"
        multiple="true"
        [formControlName]="'participants'"
    >
        <ng-template ng-label-tmp let-item="item">
            <shared-editable-employee-label
                [member]="item"
                [removable]="true"
                (remove)="removeMember($event)"
                (update)="updateMember($event)"
            >
            </shared-editable-employee-label>
        </ng-template>
    </ng-select>
</form>
