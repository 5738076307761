var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { EventEmitter, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ClrWizard } from '@clr/angular';
import { ApiRoutePlurality, HTTP_METHOD, RIGHTS } from '../../../../defs/schema-static';
import { EMPLOYEE_SCHEMA_ROUTE } from '../../../../defs/schema/public/Employees';
import { AuthService } from '../auth/auth.service';
import { FormsAddTaskComponent } from '../forms/add-task/add-task.component';
import { FormsAddTimeService, TaskTarget } from '../forms/add-time.service';
import { HttpRestService } from '../shared/http-rest/http-rest.service';
import { SHORTCUT_WIZARD, ShortcutHandlerService } from '../shared/shortcut-handler/shortcut-handler.service';
import { TimeDetailsComponent } from './time-details/time-details.component';
import { TimeExistingComponent } from './time-existing/time-existing.component';
import { TimeTypeComponent } from './time-type/time-type.component';
var TimeWizardComponent = (function () {
    function TimeWizardComponent(authService, httpRest, shortcutHandlerService) {
        this.authService = authService;
        this.httpRest = httpRest;
        this.shortcutHandlerService = shortcutHandlerService;
        this.clrWizardOpen = false;
        this.clrWizardOpenChange = new EventEmitter();
        this.finish = new EventEmitter();
        this.form = FormsAddTimeService.getFormGroup();
        this.submitting = false;
        this.TASK_TARGET = TaskTarget;
    }
    TimeWizardComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (!this.employees) {
            (function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                return [2, this.getEmployees()];
            }); }); })();
        }
        this.shortcutHandlerService.register({
            shortcut: SHORTCUT_WIZARD.NEXT,
            callback: function (e) {
                if (!_this.wizard.currentPage.nextStepDisabled && e.target.tagName !== 'TEXTAREA') {
                    if (_this.wizard.isLast) {
                        _this.wizard.finish();
                    }
                    else {
                        _this.wizard.next();
                    }
                }
            },
            context: this,
            forceListen: true,
        });
    };
    TimeWizardComponent.prototype.ngOnChanges = function (changes) {
        if (changes.clrWizardOpen && changes.clrWizardOpen.currentValue) {
            this.form.patchValue({
                employeeId: this.authService.user.employee.id,
            });
            this.load();
        }
        if (changes.project || changes.project) {
            this._employeeMembers = undefined;
        }
    };
    TimeWizardComponent.prototype.getEmployees = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4, this.httpRest
                                ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, EMPLOYEE_SCHEMA_ROUTE, 'list')
                                .toPromise()];
                    case 1:
                        _a.employees = _b.sent();
                        return [2];
                }
            });
        });
    };
    TimeWizardComponent.prototype.load = function () {
        if (this.params) {
            this.form.reset({
                employeeId: this.params.employeeId || this.form.value.employeeId,
            });
            this.typeComponent.onLoad(this.form.value);
        }
    };
    TimeWizardComponent.prototype.onLoad = function (page) {
        switch (page) {
            case 1:
                this.typeComponent.onLoad();
                break;
            case 2:
                this.existingComponent.onLoad(this.form.value);
                break;
            case 3:
                this.taskComponent.load();
                break;
            case 4:
                this.detailsComponent.onLoad(this.form.value);
                break;
            default:
                return;
        }
    };
    TimeWizardComponent.prototype.onCommit = function (page) {
        switch (page) {
            case 1:
                this.form.patchValue({
                    taskTarget: this.typeComponent.form.value.taskTarget,
                });
                break;
            default:
                return;
        }
    };
    TimeWizardComponent.prototype.triggerFinish = function () {
        return __awaiter(this, void 0, void 0, function () {
            var item;
            return __generator(this, function (_a) {
                item = {};
                try {
                    item.time = __assign({}, this.typeComponent.submit().value);
                    if (this.existingComponent) {
                        item.time = __assign({}, item.time, this.existingComponent.submit().value);
                    }
                    if (this.detailsComponent) {
                        item.time = __assign({}, item.time, this.detailsComponent.submit().value);
                    }
                    if (this.taskComponent) {
                        item.task = __assign({}, this.taskComponent.form);
                    }
                    if (!item) {
                        return [2];
                    }
                }
                catch (err) {
                    return [2];
                }
                finally {
                    this.submitting = false;
                }
                this.finish.emit(item);
                this.triggerCancel();
                return [2];
            });
        });
    };
    TimeWizardComponent.prototype.triggerCancel = function () {
        this.reset();
        this.wizard.forceFinish();
    };
    TimeWizardComponent.prototype.reset = function () {
        this.wizard.reset();
        [this, this.typeComponent, this.existingComponent, this.taskComponent, this.detailsComponent]
            .filter(function (component) { return component && component.form; })
            .map(function (_a) {
            var form = _a.form;
            return form.reset();
        });
    };
    Object.defineProperty(TimeWizardComponent.prototype, "employeeMembers", {
        get: function () {
            var _this = this;
            if (!this.project) {
                return [];
            }
            if (!this._employeeMembers) {
                var employeeIds_1 = (this.project.projectMembers || []).map(function (_a) {
                    var employeeId = _a.employeeId;
                    return employeeId;
                });
                this._employeeMembers = (this.employees || []).filter(function (_a) {
                    var id = _a.id;
                    return employeeIds_1.includes(id);
                });
                if (!this.authService.hasRight(RIGHTS.TIME_UPDATE)) {
                    this._employeeMembers = this._employeeMembers.filter(function (e) { return e.id === _this.authService.user.employee.id; });
                }
            }
            return this._employeeMembers;
        },
        enumerable: true,
        configurable: true
    });
    TimeWizardComponent.prototype.projectChange = function (project) {
        this.project = project;
        this._employeeMembers = undefined;
    };
    return TimeWizardComponent;
}());
export { TimeWizardComponent };
