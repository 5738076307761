import {Pipe, PipeTransform} from '@angular/core';
import slugify from 'slugify';

@Pipe({
    name: 'slugify',
})
export class SlugifyPipe implements PipeTransform {
    public transform = (value: string, args: any[]): string => {
        return slugify(value || '').toLowerCase();
    };
}
