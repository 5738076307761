var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { EventEmitter } from '@angular/core';
import { Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, FormGroup } from 'ngx-strongly-typed-forms';
import { TIME_SCHEMA_ROUTE } from '../../../../defs/schema/public/Times';
import { AuthService } from '../auth/auth.service';
import { HttpRestService } from '../shared/http-rest/http-rest.service';
import { MomentService } from '../shared/moment/moment.service';
import { TimerService } from '../shared/timer/timer.service';
import { TOAST_TYPE, ToastService } from '../shared/toast/toast.service';
import { FormsAddTaskService } from './add-task/add-task.service';
import { requiredTrimValidator } from './validators/required-trim.validator';
import * as i0 from "@angular/core";
import * as i1 from "../shared/http-rest/http-rest.service";
import * as i2 from "../shared/toast/toast.service";
import * as i3 from "../auth/auth.service";
import * as i4 from "./add-task/add-task.service";
import * as i5 from "@ngx-translate/core";
import * as i6 from "../shared/timer/timer.service";
import * as i7 from "../shared/moment/moment.service";
export var TaskTarget;
(function (TaskTarget) {
    TaskTarget["ASSIGNED"] = "assigned";
    TaskTarget["EXISTING"] = "existing";
    TaskTarget["NEW"] = "new";
})(TaskTarget || (TaskTarget = {}));
export var AddTimeFormField;
(function (AddTimeFormField) {
    AddTimeFormField["taskTarget"] = "taskTarget";
    AddTimeFormField["assignedTask"] = "assignedTask";
    AddTimeFormField["project"] = "project";
    AddTimeFormField["task"] = "task";
    AddTimeFormField["taskId"] = "taskId";
    AddTimeFormField["startDate"] = "startDate";
    AddTimeFormField["endDate"] = "endDate";
    AddTimeFormField["time"] = "time";
    AddTimeFormField["employeeId"] = "employeeId";
})(AddTimeFormField || (AddTimeFormField = {}));
var FormsAddTimeService = (function () {
    function FormsAddTimeService(httpRest, toastService, authService, formsAddTaskService, translate, timerService, momentService) {
        this.httpRest = httpRest;
        this.toastService = toastService;
        this.authService = authService;
        this.formsAddTaskService = formsAddTaskService;
        this.translate = translate;
        this.timerService = timerService;
        this.momentService = momentService;
        this.onSubmit = new EventEmitter();
    }
    FormsAddTimeService.getFormGroup = function () {
        return new FormGroup({
            taskTarget: new FormControl(null, Validators.required),
            assignedTask: new FormControl(null, Validators.required),
            project: new FormControl(null, Validators.required),
            task: new FormControl(),
            timeId: new FormControl(),
            taskId: new FormControl(null, Validators.required),
            startDate: new FormControl(null, Validators.required),
            endDate: new FormControl(null, Validators.required),
            time: new FormControl(null, requiredTrimValidator()),
            employeeId: new FormControl(null, Validators.required),
        });
    };
    FormsAddTimeService.prototype.submitTask = function (taskForm) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2, new Promise(function (resolve, reject) {
                        _this.formsAddTaskService.submit(taskForm, function (task) {
                            if (!task) {
                                return reject(0);
                            }
                            return resolve(task.id);
                        });
                    })];
            });
        });
    };
    FormsAddTimeService.prototype.submit = function (f, taskForm, callback) {
        return __awaiter(this, void 0, void 0, function () {
            var taskId;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!f.taskTarget) {
                            return [2, false];
                        }
                        if (!(f.taskTarget === TaskTarget.NEW)) return [3, 2];
                        return [4, this.submitTask(taskForm)];
                    case 1:
                        taskId = _a.sent();
                        if (!taskId || taskId <= 0) {
                            return [2, false];
                        }
                        return [3, 3];
                    case 2:
                        taskId = TaskTarget.ASSIGNED === f.taskTarget ? f.assignedTask : f.taskId;
                        if (!taskId) {
                            this.toastService.show({
                                type: TOAST_TYPE.WARNING,
                                text: 'please_select_task',
                            });
                            return [2, false];
                        }
                        _a.label = 3;
                    case 3: return [4, this.doSubmit(f, taskId, callback)];
                    case 4:
                        _a.sent();
                        this.timerService.timer.stop();
                        return [2, true];
                }
            });
        });
    };
    FormsAddTimeService.reset = function (f) {
        f.reset();
    };
    FormsAddTimeService.prototype.doSubmit = function (f, taskId, callback) {
        return __awaiter(this, void 0, void 0, function () {
            var time;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!f.timeId) return [3, 2];
                        return [4, this.httpRest
                                .put(TIME_SCHEMA_ROUTE, {
                                employeeId: f.employeeId,
                                taskId: taskId,
                                time: f.time,
                                beginDate: this.momentService.moment(f.startDate),
                                endDate: this.momentService.moment(f.startDate),
                            })
                                .toPromise()];
                    case 1:
                        time = _a.sent();
                        {
                            this.toastService.show({
                                type: TOAST_TYPE.SUCCESS,
                                text: 'success_insert_time',
                            });
                            if (callback) {
                                callback(time);
                            }
                            this.onSubmit.emit(time);
                        }
                        return [2];
                    case 2: return [4, this.httpRest
                            .post(TIME_SCHEMA_ROUTE, {
                            id: f.timeId,
                            employeeId: f.employeeId,
                            time: f.time,
                            beginDate: this.momentService.moment(f.startDate),
                        })
                            .toPromise()];
                    case 3:
                        time = _a.sent();
                        {
                            this.toastService.show({
                                type: TOAST_TYPE.SUCCESS,
                                text: 'success_update_time',
                            });
                            if (callback) {
                                callback(time);
                            }
                            this.onSubmit.emit(time);
                        }
                        return [2];
                }
            });
        });
    };
    FormsAddTimeService.ngInjectableDef = i0.defineInjectable({ factory: function FormsAddTimeService_Factory() { return new FormsAddTimeService(i0.inject(i1.HttpRestService), i0.inject(i2.ToastService), i0.inject(i3.AuthService), i0.inject(i4.FormsAddTaskService), i0.inject(i5.TranslateService), i0.inject(i6.TimerService), i0.inject(i7.MomentService)); }, token: FormsAddTimeService, providedIn: "root" });
    return FormsAddTimeService;
}());
export { FormsAddTimeService };
