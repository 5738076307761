import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {FormGroup} from 'ngx-strongly-typed-forms';
import {concat, forkJoin, Observable, of, Subject} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, switchMap, tap} from 'rxjs/operators';
import * as SemVer from 'semver';
import {overEstimatedTime} from '../../../../defs/businessRules';
import {
    ApiRoutePlurality,
    HTTP_METHOD,
    ISO_DATE_FORMAT,
    MAX_LENGTH_TASK_NAME,
    PATTERN_TIME,
    ReleaseStateType,
    RIGHTS,
    TaskType,
} from '../../../../defs/schema-static';
import {IMilestone} from '../../../../defs/schema/public/Milestones';
import {IDisplayTag, ITag, TAG_SCHEMA_ROUTE} from '../../../../defs/schema/public/Tags';
import {ITaskBlocker, TASK_BLOCKER_SCHEMA_ROUTE} from '../../../../defs/schema/public/TaskBlockers';
import {ITask, TASK_SCHEMA_ROUTE} from '../../../../defs/schema/public/Tasks';
import {ITaskTag, TASK_TAG_SCHEMA_ROUTE} from '../../../../defs/schema/public/TaskTags';
import {AddBlockersService} from '../add-blockers-modal/add-blockers.service';
import {getRandomColorHex} from '../app-static';
import {AuthService} from '../auth/auth.service';
import {EditTaskFormField, FormsAddTaskService, IEditTaskFormModal} from '../forms/add-task/add-task.service';
import {validate} from '../forms/validators/form.validator';
import {ModalComponent} from '../modal/modal.component';
import {ConfigService} from '../shared/config/config.service';
import {ControlFlowService} from '../shared/control-flow/control-flow.service';
import {HttpRestService, IDeleteCount} from '../shared/http-rest/http-rest.service';
import {MomentService} from '../shared/moment/moment.service';
import {SHORTCUT_MISC, ShortcutHandlerService} from '../shared/shortcut-handler/shortcut-handler.service';
import {TOAST_TYPE, ToastService} from '../shared/toast/toast.service';

@Component({
    selector: 'app-edit-task',
    templateUrl: './edit-task.component.html',
    styleUrls: ['./edit-task.component.scss'],
})
export class EditTaskComponent extends ModalComponent implements OnChanges, OnInit {
    @Input()
    public taskToEdit: ITask;

    @Input()
    public releases: IMilestone[];
    public releasesStatic: IMilestone[]; // static version of "releases"
    // @ViewChild('taskEditForm')
    // public form: NgForm;

    public form: FormGroup<IEditTaskFormModal> = FormsAddTaskService.getEditFormGroup();

    @Input()
    public tasks: ITask[];

    @Output()
    public triggerCdRef = new EventEmitter();

    public tags: IDisplayTag[];
    public _tags: IDisplayTag[];
    public parentTask: ITask;

    @ViewChild('nameInput')
    public nameInput: ElementRef;

    @ViewChild('beginDateInputElement') public beginDateInputElement: ElementRef;
    @ViewChild('endDateInputElement') public endDateInputElement: ElementRef;

    public beginDate: Date;
    public endDate: Date;

    public otherTasks = false;

    private saving = false;

    // private horseyTags: typeof horsey;
    // private horseyTasks: typeof horsey;

    public constructor(
        public shortcutHandlerService: ShortcutHandlerService,
        public cdRef: ChangeDetectorRef,
        private readonly toastService: ToastService,
        private readonly translate: TranslateService,
        public addBlockers: AddBlockersService,
        private readonly httpRest: HttpRestService,
        public authService: AuthService,
        private readonly formsAddTaskService: FormsAddTaskService,
        controlFlowService: ControlFlowService,
        private readonly momentService: MomentService,
        private readonly configService: ConfigService
    ) {
        super(shortcutHandlerService, controlFlowService, cdRef);
    }

    public ngOnChanges(changes: SimpleChanges) {
        super.ngOnChanges(changes);
        if (changes.show && changes.show.currentValue === false && this.form) {
            this.reset();
        }
        if (changes.show && changes.show.currentValue === true && this.form) {
            this.initValues();
        }
        if (changes.taskToEdit) {
            this.initValues();
        }
        if (changes.releases && !this.releasesStatic) {
            requestAnimationFrame(() => {
                this.releasesStatic = this.releases.sort((a, b) => {
                    if (
                        a.releaseState === ReleaseStateType.IN_DEVELOPMENT &&
                        b.releaseState === ReleaseStateType.IN_DEVELOPMENT
                    ) {
                        return SemVer.compare(a.version, b.version);
                    } else if (
                        a.releaseState === ReleaseStateType.IN_DEVELOPMENT &&
                        b.releaseState !== ReleaseStateType.IN_DEVELOPMENT
                    ) {
                        return -1;
                    }

                    return SemVer.compare(b.version, a.version);
                });
            });
        }
    }

    public ngOnInit() {
        this.initValues();
    }

    public getInputNameLength() {
        if (!this.nameInput) {
            return;
        }

        return this.nameInput.nativeElement.value.length;
    }

    public reset() {
        this.form.reset({
            name: this.taskToEdit && this.taskToEdit.name,
            progress: this.taskToEdit && this.taskToEdit.progress,
        });
        this.parentTask = null;
    }

    private initValues() {
        if (!this.taskToEdit) {
            this.reset();

            return;
        }

        this.otherTasks =
            this.tasks.filter((t) => t.projectId === this.taskToEdit.projectId && t.id !== this.taskToEdit.id).length >
            0;

        this.parentTask = null;
        this.loadParentTasks();
        if (this.taskToEdit.parentId) {
            const ptask = this.tasks.find((t) => t.id === this.taskToEdit.parentId);

            if (ptask) {
                this.parentTask = {} as ITask;
                this.parentTask.name = ptask.name;
                this.parentTask.id = ptask.id;
                this.parentTask.code = ptask.code;
            }
        }

        let blockers: any[] = [];
        if (this.taskToEdit.taskBlockers && this.taskToEdit.taskBlockers.length > 0) {
            blockers = this.taskToEdit.taskBlockers.map((b) => {
                return {
                    name: this.getBlockerName(b),
                    code: this.getBlockerCode(b),
                    blockerId: b.blockerId,
                    id: b.id,
                    active: b.active,
                };
            });
        }

        const startDate = this.taskToEdit.beginDate
            ? this.momentService.moment(this.taskToEdit.beginDate).format(ISO_DATE_FORMAT)
            : null;
        const endDate = this.taskToEdit.endDate
            ? this.momentService.moment(this.taskToEdit.endDate).format(ISO_DATE_FORMAT)
            : null;

        requestAnimationFrame(() => {
            this.form.patchValue({
                parentTaskId: this.parentTask ? this.parentTask : null,
                releaseId: this.taskToEdit.targetReleaseId,
                project: this.taskToEdit.projectId,
                estimated: this.taskToEdit.estimatedTime,
                name: this.taskToEdit.name,
                progress: this.taskToEdit.progress,
                bug: this.taskToEdit.type === TaskType.BUG,
                urgent: this.taskToEdit.urgent,
                blockers,
                tags: this.taskToEdit.tasktags,
                startDate,
                endDate,
            });

            const pattern = Validators.pattern(PATTERN_TIME);

            this.form.controls.estimated.setValidators([pattern]);

            this.beginDate = this.taskToEdit.beginDate
                ? this.momentService.moment(this.taskToEdit.beginDate).toDate()
                : null;
            this.endDate = this.taskToEdit.endDate ? this.momentService.moment(this.taskToEdit.endDate).toDate() : null;

            this.toggleRelease(false);
        });

        this.httpRest
            ._request<ITag[]>(
                HTTP_METHOD.GET,
                ApiRoutePlurality.PLURAL,
                TAG_SCHEMA_ROUTE,
                `project/${this.taskToEdit.projectId}`
            )
            .subscribe((tags) => {
                this._tags = tags;
                this.tags = this._tags.filter((t) => this.form.value.tags.findIndex((_t) => t.id === _t.tagId) === -1);
                // this.tags = tags.map((tag) => ({
                //     ...tag,
                //     selected: !!this.taskToEdit.tasktags.find((tasktag) => tasktag.tagId === tag.id),
                // }));
            });

        this.addBlockers.init(true, this.form, this.taskToEdit, blockers);
    }

    public onParentChange($event: ITask = null) {
        this.form.patchValue({parentTaskId: $event ? $event : null});
    }

    public onReleaseIdChange($event: Partial<IMilestone> = null) {
        const nowStr = this.momentService.moment();
        const currentValueStart = this.form.value.startDate || nowStr.format(ISO_DATE_FORMAT);
        const currentValueEnd = this.form.value.endDate || nowStr.format(ISO_DATE_FORMAT);

        this.form.patchValue({
            releaseId: $event ? $event.id : null,
            startDate: $event ? null : currentValueStart,
            endDate: $event ? null : currentValueEnd,
        });

        if ($event && $event.id) {
            this.beginDate = null;
            this.beginDateInputElement.nativeElement.value = '';
            this.endDate = null;
            this.endDateInputElement.nativeElement.value = '';
        } else {
            this.beginDate = nowStr.toDate();
            this.endDate = nowStr.toDate();
        }

        this.toggleRelease();
    }

    public toggleRelease(reset = true) {
        if (this.form.value.releaseId) {
            // this.form.controls.startDate.disable();
            this.form.controls.startDate.clearValidators();
            // this.form.controls.endDate.disable();
            this.form.controls.endDate.clearValidators();
            if (reset) {
                this.form.patchValue({
                    startDate: null,
                    endDate: null,
                });
                this.beginDate = null;
                this.endDate = null;
            }
        } else {
            // this.form.controls.startDate.enable();
            this.form.controls.startDate.setValidators([Validators.required]);
            // this.form.controls.endDate.enable();
            this.form.controls.endDate.setValidators([Validators.required]);
            const nowStr: string = this.momentService.moment().format(ISO_DATE_FORMAT);
            this.form.patchValue({
                startDate: this.form.value.startDate || nowStr,
                endDate: this.form.value.endDate || nowStr,
            });
        }
        this.form.controls.startDate.updateValueAndValidity();
        this.form.controls.endDate.updateValueAndValidity();
    }

    public toggle() {
        this.clearParentTask();
    }

    public tasksParent: Observable<any[] | ITask[]>;
    public tasksParentLoading = false;
    public tasksParent$ = new Subject<string>();

    public loadTasks(term: string) {
        return this.httpRest._request<ITask[]>(
            HTTP_METHOD.POST,
            ApiRoutePlurality.PLURAL,
            TASK_SCHEMA_ROUTE,
            'searchTasks',
            {
                search: term,
                projectId: this.form.value.project,
                exclude: [this.taskToEdit.id],
            }
        );
    }

    private loadParentTasks() {
        this.tasksParent = concat(
            of([]), // default items
            this.tasksParent$.pipe(
                debounceTime(200),
                distinctUntilChanged(),
                tap(() => (this.tasksParentLoading = true)),
                switchMap((term) =>
                    this.loadTasks(term).pipe(
                        catchError(() => of([])), // empty list on error
                        tap(() => (this.tasksParentLoading = false))
                    )
                )
            )
        );
    }

    public clearParentTask() {
        Object.assign(this.parentTask, null);
        this.form.value.parentTaskId = null;
        const el = document.getElementById('parentTaskId');
        if (el) {
            window.setTimeout(() => {
                el.focus();
            }, 200);
        }
    }

    public getSelectedRelease() {
        if (!this.releases) {
            return undefined;
        }

        return this.releases.find((m) => this.form.value.releaseId === m.id);
    }

    public selectedTags() {
        if (!this.tags) {
            return null;
        }

        return this.tags.filter((tag) => tag.selected);
    }

    public removeTag($event: ITaskTag) {
        const idToRemove = $event.tagId ? $event.tagId : $event.id;

        this.form.patchValue({
            tags: this.form.value.tags.filter((_tag) =>
                _tag.tagId ? _tag.tagId !== idToRemove : _tag.id !== idToRemove
            ),
        });
    }
    public onTagSubmit() {
        const el = document.getElementById('tag') as HTMLInputElement;
        if (!el) {
            return;
        }

        const value = el.value.trim();
        if (!value) {
            return;
        }

        const tag: IDisplayTag = {
            text: value,
            color: getRandomColorHex(),
            branch: false,
            projectId: this.taskToEdit.projectId,
        };
        this.updateTag(el, tag);
    }

    public updateTag(el: HTMLInputElement, tag: IDisplayTag) {
        const {...clone} = tag;
        this.httpRest.put<ITag>(TAG_SCHEMA_ROUTE, clone).subscribe((_tag) => {
            if (el) {
                this.tags.push({..._tag, selected: true});
                el.value = '';
                el.focus();
            }
        });
    }

    public refreshTagList() {
        this.tags = this._tags.filter((t) => this.form.value.tags.findIndex((_t) => t.id === _t.tagId) === -1);
    }

    public getBlockerName(blocker: Partial<ITaskBlocker>) {
        const task = this.tasks[this.tasks.map((t) => t.id).indexOf(blocker.blockerId)];
        if (task) {
            return task.name;
        }

        return '';
    }
    public getBlockerCode(blocker: Partial<ITaskBlocker>) {
        const task = this.tasks[this.tasks.map((t) => t.id).indexOf(blocker.blockerId)];
        if (task) {
            return task.code;
        }

        return '';
    }

    public doAddBlocker(task: ITask) {
        this.addBlockers.blockersNew.push(task.id);
    }

    public doRemoveBlocker(blocker: ITaskBlocker) {
        if (blocker.blockerId) {
            this.addBlockers.blockersDeleted.push(blocker.id);

            this.form.patchValue({
                blockers: this.form.value.blockers.filter((b) => b.blockerId !== blocker.blockerId),
            });
        } else {
            this.addBlockers.blockersNew.splice(this.addBlockers.blockersNew.findIndex((b) => b === blocker.id));

            this.form.patchValue({
                blockers: this.form.value.blockers.filter((b) => b.id !== blocker.id),
            });
        }
    }

    public checkDates = () => {
        if (!this.form) {
            return false;
        }
        let beginDate: string;
        let endDate: string;
        let error = false;
        if (!this.beginDate && this.beginDateInputElement && this.beginDateInputElement.nativeElement.value !== '') {
            beginDate = this.beginDateInputElement.nativeElement.value;
            if (!this.momentService.moment(beginDate, ISO_DATE_FORMAT, true).isValid()) {
                error = true;
            }
        }
        if (!this.endDate && this.endDateInputElement && this.endDateInputElement.nativeElement.value !== '') {
            endDate = this.endDateInputElement.nativeElement.value;
            if (!this.momentService.moment(endDate, ISO_DATE_FORMAT, true).isValid()) {
                error = true;
            }
        }

        this.form.patchValue({
            startDate: this.beginDate ? this.momentService.moment(this.beginDate).toISOString() : null,
            endDate: this.endDate ? this.momentService.moment(this.endDate).toISOString() : null,
        });

        if ((!this.beginDate || !this.endDate) && !this.form.value.releaseId) {
            return false;
        } else if (
            this.momentService
                .moment(this.beginDate)
                .startOf('day')
                .isAfter(this.momentService.moment(this.endDate).endOf('day'))
        ) {
            return false;
        } else if (error) {
            return false;
        } else {
            return true;
        }
    };

    public getReleased() {
        return this.releases.filter((r) => r.releaseState === ReleaseStateType.RELEASED);
    }

    public getStaged() {
        return this.releases.filter((r) => r.releaseState === ReleaseStateType.STAGED);
    }

    public getUnreleased() {
        return this.releases.filter((r) => r.releaseState === ReleaseStateType.IN_DEVELOPMENT);
    }

    public saveTask(form: FormGroup<IEditTaskFormModal>) {
        if (!validate(form) || this.saving) {
            return undefined;
        }
        this.saving = true;

        if (`${form.value.release}` === 'null') {
            form.value.release = null;
        }

        if (form.value.startDate && form.value.endDate) {
            if (
                this.momentService.moment(form.value.startDate).isAfter(this.momentService.moment(form.value.endDate))
            ) {
                this.toastService.show({
                    type: TOAST_TYPE.ERROR,
                    text: 'error_milestones',
                });

                return false;
            }
        }

        const release = this.releases.find((r) => r.id === form.value.releaseId);
        const type = form.value.bug ? TaskType.BUG : TaskType.TASK;

        this.httpRest
            .post<ITask>(TASK_SCHEMA_ROUTE, {
                id: this.taskToEdit.id,
                name: form.value.name,
                progress: form.value.progress,
                targetReleaseId: form.value.releaseId,
                estimatedTime: form.value.estimated,
                type: TaskType[type],
                urgent: form.value.urgent,
                beginDate: form.value.startDate ? this.momentService.moment(form.value.startDate) : null,
                endDate: form.value.endDate ? this.momentService.moment(form.value.endDate) : null,
                parentId: form.value.parentTaskId ? form.value.parentTaskId.id : null,
            })
            .subscribe((task) => {
                const obss: Observable<IDeleteCount | ITaskTag[] | ITaskBlocker[]>[] = [];

                const deletedBlockers = this.addBlockers.blockersDeleted;
                let deletedBlockersObs: Observable<IDeleteCount>;
                if (deletedBlockers && deletedBlockers.length > 0) {
                    deletedBlockersObs = this.httpRest.deleteIds(TASK_BLOCKER_SCHEMA_ROUTE, deletedBlockers);
                    deletedBlockersObs.subscribe(() => {
                        deletedBlockers.map((d) => {
                            const idx = this.taskToEdit.taskBlockers.map((t) => t.id).indexOf(d);
                            if (idx !== -1) {
                                this.taskToEdit.taskBlockers.splice(idx, 1);
                            }
                        });
                    });
                    obss.push(deletedBlockersObs);
                }
                const newBlockers = this.addBlockers.blockersNew;
                let newBlockersObs: Observable<ITaskBlocker[]>;
                if (newBlockers && newBlockers.length) {
                    newBlockersObs = this.httpRest.putEntities<ITaskBlocker>(
                        TASK_BLOCKER_SCHEMA_ROUTE,
                        newBlockers.map((blocker) => ({
                            taskId: task.id,
                            blockerId: blocker,
                        }))
                    );

                    newBlockersObs.subscribe((blockers) => {
                        blockers.map((blocker) => {
                            this.taskToEdit.taskBlockers.push({
                                taskId: task.id,
                                blockerId: blocker.blockerId,
                                id: blocker.id,
                                active: blocker.active,
                            });
                        });
                    });
                    obss.push(newBlockersObs);
                }
                this.addBlockers.reset();

                const deletedTags = this.taskToEdit.tasktags.filter(
                    (tt) => !this.form.value.tags.find((t) => t.tagId === tt.tagId)
                );

                const newTags = this.form.value.tags.filter(
                    (tt) => !this.taskToEdit.tasktags.find((t) => t.tagId === tt.tagId)
                );

                let deletedTagsObs: Observable<IDeleteCount>;
                if (deletedTags.length) {
                    deletedTagsObs = this.httpRest.deleteIds(
                        TASK_TAG_SCHEMA_ROUTE,
                        deletedTags.map((deleted) => deleted.id)
                    );
                    deletedTagsObs.subscribe(() => {
                        deletedTags.map((deleted) => {
                            this.taskToEdit.tasktags.splice(
                                this.taskToEdit.tasktags.findIndex((tt) => tt.tagId === deleted.tagId),
                                1
                            );
                        });
                    });
                    obss.push(deletedTagsObs);
                }
                let newTagsObs: Observable<ITaskTag[]>;
                if (newTags.length) {
                    newTagsObs = this.httpRest.putEntities<ITaskTag>(
                        TASK_TAG_SCHEMA_ROUTE,
                        newTags.map((t) => ({
                            tagId: t.id,
                            taskId: this.taskToEdit.id,
                        }))
                    );
                    newTagsObs.subscribe((tasktags) => {
                        tasktags.map(async (tasktag) => {
                            const tag = await this.httpRest
                                ._request(
                                    HTTP_METHOD.GET,
                                    ApiRoutePlurality.SINGULAR,
                                    TAG_SCHEMA_ROUTE,
                                    `${tasktag.tagId}`
                                )
                                .toPromise();

                            tasktag.tag = tag;
                            this.taskToEdit.tasktags.push(tasktag);
                        });
                    });
                    obss.push(newTagsObs);
                }

                const done = () => {
                    this.saving = false;

                    this.taskToEdit.name = task.name;
                    this.taskToEdit._metadata = task._metadata;
                    this.taskToEdit.targetReleaseId = task.targetReleaseId;
                    this.taskToEdit.progress = task.progress;
                    this.taskToEdit.estimatedTime = task.estimatedTime;
                    this.taskToEdit.beginDate = task.beginDate;
                    this.taskToEdit.endDate = task.endDate;
                    this.taskToEdit.parentId = task.parentId;
                    if (release) {
                        this.taskToEdit.targetRelease = release;
                    }
                    if (type) {
                        this.taskToEdit.type = TaskType[type];
                    }
                    this.taskToEdit.urgent = task.urgent;
                    this.taskToEdit.tracked = task.tracked;
                    this.taskToEdit.createdAt = task.createdAt;
                    this.taskToEdit.updatedAt = task.updatedAt;

                    this.triggerCdRef.emit();

                    this.form.removeControl('blockers');
                    this.toastService.show({
                        type: TOAST_TYPE.SUCCESS,
                        text: 'toast_updated_task',
                    });
                    this.showChange.emit((this.show = false));
                };

                if (obss.length) {
                    forkJoin(obss).subscribe(done);
                } else {
                    done();
                }
            });
    }

    public isTimeInvalid() {
        if (!this.form || !this.form.controls.estimated) {
            return false;
        }

        return this.form.controls.estimated.status === 'INVALID';
    }

    public checkValidationTime() {
        if (overEstimatedTime(this.configService.config, this.form.value.estimated)) {
            this.form.controls.estimated.setErrors({over53Weeks: true});
        }
    }

    public readonly validate = validate;

    public readonly MAX_LENGTH_TASK_NAME = MAX_LENGTH_TASK_NAME;

    public readonly EditTaskFormField = EditTaskFormField;

    public readonly RIGHTS = RIGHTS;
    public readonly SHORTCUT_MISC = SHORTCUT_MISC;
}
