import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {ClrWizard} from '@clr/angular';
import {Moment} from 'moment';
import {FormGroup} from 'ngx-strongly-typed-forms';
import {getWorkingHours} from '../../../../defs/businessRules';
import {ApiRoutePlurality, HTTP_METHOD, ISO_DATE_FORMAT, MilestonesType, RIGHTS} from '../../../../defs/schema-static';
import {CLIENT_SCHEMA_ROUTE, IClient} from '../../../../defs/schema/public/Clients';
import {IProject, PROJECT_SCHEMA_ROUTE} from '../../../../defs/schema/public/Projects';
import {AuthService} from '../auth/auth.service';
import {FormsAddMilestoneService, IMilestoneFormValues, MilestoneTarget} from '../forms/add-milestone.service';
import {ConfigService} from '../shared/config/config.service';
import {HttpRestService} from '../shared/http-rest/http-rest.service';
import {MomentService} from '../shared/moment/moment.service';
import {SHORTCUT_WIZARD, ShortcutHandlerService} from '../shared/shortcut-handler/shortcut-handler.service';
import {MilestoneDetailsComponent} from './milestone-details/milestone-details.component';
import {MilestoneParticipantsComponent} from './milestone-participants/milestone-participants.component';
import {MilestoneTargetComponent} from './milestone-target/milestone-target.component';
import {MilestoneTypeComponent} from './milestone-type/milestone-type.component';

interface IMilestoneWizardParams {
    start: Moment;
    end: Moment;
    clientId: number;
    projectId: number;
    type: MilestonesType;
    description: string;
    version: string;
    milestone: string;
    targetType: MilestoneTarget;
}

@Component({
    selector: 'app-milestone-wizard',
    templateUrl: './milestone-wizard.component.html',
})
export class MilestoneWizardComponent implements OnInit, OnChanges {
    @ViewChild(ClrWizard) public wizard: ClrWizard;

    @ViewChild(MilestoneTypeComponent) public typeComponent: MilestoneTypeComponent;
    @ViewChild(MilestoneTargetComponent) public targetComponent: MilestoneTargetComponent;
    @ViewChild(MilestoneParticipantsComponent) public participantsComponent: MilestoneParticipantsComponent;
    @ViewChild(MilestoneDetailsComponent) public detailsComponent: MilestoneDetailsComponent;

    @Input() public params: Partial<IMilestoneWizardParams>;
    @Input() public clrWizardOpen = false;
    @Output() public clrWizardOpenChange = new EventEmitter<boolean>();

    @Output() public finish = new EventEmitter<IMilestoneFormValues>();
    @Output() public cancel = new EventEmitter();

    @Input() public clients: Partial<IClient>[] = [];
    @Input() public projects: Partial<IProject>[] = [];

    public filteredTypes: MilestonesType[] = Object.values(MilestonesType).filter(
        (m) => m !== MilestonesType.MEETING_NO_NOTE
    );

    public form: FormGroup<IMilestoneFormValues> = FormsAddMilestoneService.getFormGroup();

    public submitting = false;

    public constructor(
        private readonly httpRest: HttpRestService,
        private readonly configService: ConfigService,
        private readonly authService: AuthService,
        private readonly shortcutHandlerService: ShortcutHandlerService,
        private readonly momentService: MomentService
    ) {}

    public ngOnInit() {
        if (!this.clients || !this.clients.length) {
            (async () => this.getClients())();
        }
        if (!this.projects || !this.projects.length) {
            (async () => this.getProjects())();
        }
        requestAnimationFrame(() => {
            this.load();
        });
        this.shortcutHandlerService.register({
            shortcut: SHORTCUT_WIZARD.NEXT,
            callback: (e) => {
                if (!this.wizard.currentPage.nextStepDisabled && (e.target as HTMLElement).tagName !== 'TEXTAREA') {
                    if (this.wizard.isLast) {
                        this.triggerFinish();
                    } else {
                        this.wizard.next();
                    }
                }
            },
            context: this,
            forceListen: true,
        });
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.clrWizardOpen && changes.clrWizardOpen.currentValue) {
            this.load();
        }
    }

    public async getClients(): Promise<void> {
        this.clients = (await this.httpRest
            ._request<IClient[]>(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, CLIENT_SCHEMA_ROUTE, 'light')
            .toPromise()).sort((c1, c2) => c1.user.name.localeCompare(c2.user.name));
    }

    public async getProjects(): Promise<void> {
        this.projects = (await this.httpRest
            ._request<IProject[]>(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, PROJECT_SCHEMA_ROUTE, 'light/true')
            .toPromise()).sort((c1, c2) => c1.obs.localeCompare(c2.obs));
    }

    // tslint:disable-next-line: cyclomatic-complexity
    public load() {
        this.wizard.reset();
        const managerOf: number[] = this.authService.getProjectsManagerOf();

        if (!this.params) {
            if (!(managerOf.length > 0) && !this.authService.hasRight(RIGHTS.PROJECT_ADMIN_ALL)) {
                if (!this.authService.hasRight(RIGHTS.MS_RELEASE)) {
                    this.filteredTypes = Object.values(MilestonesType).filter(
                        (f) => f !== MilestonesType.DEADLINE && f !== MilestonesType.RELEASE
                    );
                }
                if (!this.authService.hasRight(RIGHTS.MS_UPDATE)) {
                    this.filteredTypes = this.filteredTypes.filter(
                        (f) => f !== MilestonesType.MEETING && f !== MilestonesType.MEETING_NO_NOTE
                    );
                }
            }

            requestAnimationFrame(() => {
                this.typeComponent.onLoad(this.form.value);
            });

            return;
        }

        const _now = this.momentService.moment();
        const workingHours = getWorkingHours(this.configService.config);
        const configStartTime = workingHours.start.split(':').map(Number);
        const configEndTime = workingHours.end.split(':').map(Number);
        let target = _now.format(ISO_DATE_FORMAT);
        let startDate = _now.format(ISO_DATE_FORMAT);
        let startTime = _now
            .set({
                hour: configStartTime[0],
                minute: configStartTime[1],
            })
            .format(MilestoneWizardComponent.HOUR_MINUTE_TIME_FORMAT);
        let endDate = _now.format(ISO_DATE_FORMAT);
        let endTime = _now
            .set({
                hour: configEndTime[0],
                minute: configEndTime[1],
            })
            .format(MilestoneWizardComponent.HOUR_MINUTE_TIME_FORMAT);

        if (this.params.start && this.params.end) {
            target = this.params.start.format(ISO_DATE_FORMAT);
            startDate = this.params.start.format(ISO_DATE_FORMAT);
            startTime = this.params.start.format(MilestoneWizardComponent.HOUR_MINUTE_TIME_FORMAT);
            endDate = this.params.end.format(ISO_DATE_FORMAT);
            endTime = this.params.end.format(MilestoneWizardComponent.HOUR_MINUTE_TIME_FORMAT);
        }

        const targetType = this.params.clientId ? MilestoneTarget.CLIENT : MilestoneTarget.PROJECT;

        this.form.reset({
            targetType,
            target,
            startDate,
            startTime,
            endDate,
            endTime,
            client: this.params.clientId || null,
            project: this.params.projectId || null,
            type: this.params.type || null,
            description: this.params.description || null,
            milestone: this.params.milestone || null,
            version: this.params.version || null,
        });

        if (
            (this.params && this.params.clientId && targetType === MilestoneTarget.CLIENT) ||
            !this.authService.hasRight(RIGHTS.MS_RELEASE)
        ) {
            if (!(managerOf.length > 0) && !this.authService.hasRight(RIGHTS.PROJECT_ADMIN_ALL)) {
                this.filteredTypes = Object.values(MilestonesType).filter(
                    (f) => f !== MilestonesType.DEADLINE && f !== MilestonesType.RELEASE
                );
            }
        }

        if (!this.params.type) {
            requestAnimationFrame(() => {
                this.typeComponent.onLoad(this.form.value);
            });
        }
    }

    public onLoad(page: number) {
        switch (page) {
            case 1:
                this.typeComponent.onLoad();
                break;
            case 2:
                this.targetComponent.onLoad(this.form.value);
                break;
            case 3:
                this.detailsComponent.onLoad(this.form.value);
                break;
            default:
                return;
        }
    }

    public onCommit(page: number) {
        switch (page) {
            case 1:
                this.form.patchValue({
                    type: this.typeComponent.form.value.type,
                });
                break;
            case 2:
                this.form.patchValue({
                    targetType: this.targetComponent.form.value.targetType,
                    client: this.targetComponent.form.value.client,
                    project: this.targetComponent.form.value.project,
                });
                break;
            default:
                return;
        }
    }

    public async triggerFinish() {
        this.submitting = true;
        let milestone: IMilestoneFormValues;
        try {
            milestone = {
                ...this.detailsComponent.submit().value,
            } as IMilestoneFormValues;

            if (this.typeComponent) {
                Object.assign(milestone, this.typeComponent.submit().value);
            } else {
                milestone.type = this.params.type;
            }

            if (this.targetComponent) {
                Object.assign(milestone, this.targetComponent.submit().value);
            } else {
                if (this.params.clientId) {
                    milestone.targetType = MilestoneTarget.CLIENT;
                    milestone.client = this.params.clientId;
                } else {
                    milestone.targetType = MilestoneTarget.PROJECT;
                    milestone.project = this.params.projectId;
                }
            }

            if (this.participantsComponent) {
                Object.assign(milestone, this.participantsComponent.submit().value);
            }

            if (!milestone) {
                return;
            }
        } catch (err) {
            return;
        } finally {
            this.submitting = false;
        }

        this.finish.emit(milestone);
        this.triggerCancel();
    }

    public triggerCancel(onPage?: boolean) {
        this.reset();
        this.wizard.forceFinish();
        if (onPage) {
            this.cancel.emit();
        }
    }

    public reset(): void {
        this.wizard.reset();

        [this, this.typeComponent, this.targetComponent, this.participantsComponent, this.detailsComponent]
            .filter((component: {form: FormGroup<any>}) => component && component.form)
            .map(({form}: {form: FormGroup<any>}) => form.reset());
    }

    public readonly MILESTONE_TYPE = MilestonesType;
    private static readonly HOUR_MINUTE_TIME_FORMAT = 'HH:mm';
}
