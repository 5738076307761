<div class="container">
    <div class="clr-row">
        <h2 translate>requests_list</h2>
        <div class="column clr-col-12 mt-1">
            <button class="btn float-right" (click)="showNewRequestModal = true">
                <i class="fa fa-plus mr-2"></i>{{ 'add_request' | translate }}
            </button>
        </div>
        <div class="column clr-col-12">
            <div class="spinner-bg-c-small" style="margin-top: 15em;" *ngIf="pleaseWait">
                <div class="spinner-c"></div>
            </div>
            <!-- <input
                class="d-block filter-input"
                type="text"
                [placeholder]="'placeholder_filter' | translate"
                (keyup)="updateFilter($event)"
            /> -->
            <!--
                <ngx-datatable
                    *ngIf="!pleaseWait"
                    class="c-hand"
                    [rows]="displayRequest"
                    [columnMode]="'force'"
                    [headerHeight]="50"
                    [rowHeight]="65"
                    [scrollbarV]="true"
                    [messages]="{emptyMessage: 'no_data' | translate, totalMessage: 'total'}"
                    (select)="displayDescription($event)"
                    [selectionType]="'single'"
                    [columns]="requestColumns"
                >
                    <ngx-datatable-column [name]="'table_title' | translate">
                        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                            {{ row.title }}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column [name]="'table_description' | translate">
                        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                            {{ row.description }}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column [name]="'request' | translate">
                        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                            {{ row.request }}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column [name]="'project' | translate">
                        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                            {{ getProject(row.projectId)?.obs }}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column [name]="'created_at' | translate">
                        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                            {{ row.createdAt | momentFormat: 'L LT' }}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column [name]="'updated_at' | translate">
                        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                            {{ row.updatedAt | momentFormat: 'L LT' }}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column [name]="'table_status' | translate">
                        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                            <span class="{{ row.status.toLowerCase() }}">{{
                                'request_' + row.status | lowercase | translate
                            }}</span>
                        </ng-template>
                    </ngx-datatable-column>
                </ngx-datatable>
            -->
        </div>
    </div>
</div>
<!--
    A refaire avec un formgroup (déclarer le form dans le ts etc comment firon-detail-comment)
    <app-modal [(show)]="showNewRequestModal" title="{{ 'add_request' | translate }}">
            <ng-template appModalBody>
        <form [formGroup]="form" novalidate autocomplete="off">
                <label class="form-label my-2" for="title" translate>table_title</label>
                <input class="d-block" id="title" name="title" ngModel appRequiredTrim #first="ngModel" />
                <label class="form-label my-2" for="description" translate>short_description</label>
                <input class="d-block" id="description" name="description" ngModel required />
                <label class="form-label my-2" for="request" translate>request_detail</label>
                <textarea
                    rows="5"
                    class="d-block"
                    type="text"
                    id="request"
                    name="request"
                    ngModel
                    appRequiredTrim
                ></textarea>

                <label class="form-label my-2" for="project" translate>project</label>
                <select id="project" name="project" [ngModel]="projects[0]?.id" required>
                    <option *ngFor="let project of (projects | sharedArraySort: 'obs')" [value]="project.id">
                        {{ project.obs }}
                    </option>
                </select>
                <br />
                <label class="form-label my-2" translate>attachments</label>
                <app-file-manager-drop-zone
                    [storedType]="STORED_TYPE.CUSTOMER_REQUEST_FILE"
                    [entity]="getEntity"
                    [acceptedTypes]="ACCEPTED_TYPES"
                    [uploadOnAdd]="false"
                    #fileInput
                ></app-file-manager-drop-zone>
        </form>
            </ng-template>

            <ng-template appModalFooter>
                <button class="btn btn-primary" (click)="onSubmit(form)" translate>form_save</button>
            </ng-template>
    </app-modal>
-->
<app-modal [(show)]="displayModal" [title]="selectedRequest?.title">
    <ng-template appModalBody>
        <div class="clr-row">{{ selectedRequest?.description }}</div>
        <div class="divider"></div>
        <div class="clr-row" [innerHTML]="selectedRequest?.request | convertNewLines"></div>
    </ng-template>
</app-modal>
