<div class="modal">
    <div
        class="modal-dialog"
        [class.modal-sm]="size === 'sm'"
        [class.modal-lg]="size === 'lg'"
        [class.modal-xl]="size === 'xl'"
        role="dialog"
        aria-hidden="true"
        appMovable
    >
        <div class="modal-content">
            <div class="modal-header movable-handle" *ngIf="!!title">
                <button aria-label="Close" class="close" type="button" (click)="dismiss()">
                    <clr-icon aria-hidden="true" shape="close"></clr-icon>
                </button>
                <h3 class="modal-title">{{ title | translate }}</h3>
            </div>

            <div class="modal-body" *ngIf="!!contentString || !!contentI18n">
                <ng-container *ngIf="!!contentString">
                    <div [innerHTML]="contentString"></div>
                </ng-container>
                <ng-container *ngIf="!!contentI18n">
                    <p>{{ contentI18n | translate: contentObj }}</p>
                </ng-container>
            </div>

            <div class="modal-footer">
                <button (click)="close()" [clrLoading]="cancelBtnState" class="btn {{ ko.class }}">
                    {{ ko.i18n | translate }}
                </button>
                <button (click)="validate()" [clrLoading]="validateBtnState" class="btn {{ ok.class }}">
                    {{ ok.i18n | translate }}
                </button>
            </div>
        </div>
    </div>
    <div class="modal-backdrop" aria-hidden="true" (click)="dismiss()"></div>
</div>
