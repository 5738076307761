import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {ClrForm} from '@clr/angular';
import {FormControl, FormGroup} from 'ngx-strongly-typed-forms';
import {ApiRoutePlurality, HTTP_METHOD} from '../../../../../defs/schema-static';
import {IClient} from '../../../../../defs/schema/public/Clients';
import {EMPLOYEE_FIELD, EMPLOYEE_SCHEMA_ROUTE, IEmployee} from '../../../../../defs/schema/public/Employees';
import {IMeetingParticipant} from '../../../../../defs/schema/public/MeetingParticipants';
import {IProjectMember} from '../../../../../defs/schema/public/ProjectMembers';
import {IProject} from '../../../../../defs/schema/public/Projects';
import {USER_FIELD} from '../../../../../defs/schema/public/Users';
import {AuthService} from '../../auth/auth.service';
import {MilestoneTarget} from '../../forms/add-milestone.service';
import {HttpRestService} from '../../shared/http-rest/http-rest.service';

@Component({
    selector: 'app-milestone-participants',
    templateUrl: './milestone-participants.component.html',
    styleUrls: ['./milestone-participants.component.scss'],
})
export class MilestoneParticipantsComponent implements OnInit, OnChanges {
    public readonly form = new FormGroup<{participants: Partial<IMeetingParticipant>[]}>({
        participants: new FormControl<Partial<IMeetingParticipant>[]>(),
    });

    @ViewChild(ClrForm) public clrForm: ClrForm;

    @Input() public clients: Partial<IClient>[] = [];
    @Input() public projects: Partial<IProject>[] = [];
    @Input() public targetType: MilestoneTarget;
    @Input() public employees: Partial<IEmployee>[] = [];
    @Input() public id: number;

    public members: Partial<IMeetingParticipant>[];

    public constructor(private readonly httpRest: HttpRestService, private readonly authService: AuthService) {}

    public ngOnInit() {
        if (!this.employees || !this.employees.length) {
            (async () => this.getEmployees())();
        } else {
            this.setMembers();
        }

        this.form.patchValue({
            participants: [],
        });
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.clients || changes.projects || changes.id) {
            this.setMembers();
        }
    }

    public async getEmployees(): Promise<void> {
        this.employees = (await this.httpRest
            ._request<IEmployee[]>(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, EMPLOYEE_SCHEMA_ROUTE, 'project')
            .toPromise()).sort((e1, e2) => e1.user.name.localeCompare(e2.user.name));

        this.setMembers();
    }

    public setMembers() {
        const existingIds = (this.form.value.participants || []).map((participant) => participant.employee.id);

        let validEmployees: Partial<IEmployee>[] = [];
        if (this.targetType === MilestoneTarget.PROJECT && this.projects && this.id) {
            validEmployees = this.employees.filter(
                (e) =>
                    this.projects
                        .find((p) => p.id === Number(this.id))
                        .projectMembers.findIndex((_e) => _e.employeeId === e.id) !== -1 &&
                    this.form.value.participants.findIndex((_e) => _e.employeeId === e.id) === -1
            );
        } else if (this.targetType === MilestoneTarget.CLIENT && this.clients && this.id) {
            validEmployees = this.employees.filter(
                (e) =>
                    this.projects
                        .filter((p) => p.clientId === Number(this.id))
                        .reduce((pm, p) => pm.concat(p.projectMembers), [] as Partial<IProjectMember>[])
                        .findIndex((_e) => _e.employeeId === e.id) !== -1 &&
                    this.form.value.participants.findIndex((_e) => _e.employeeId === e.id) === -1
            );
        }

        // auto add myself if not in
        if (
            !existingIds.includes(this.authService.user.employee.id) &&
            validEmployees.map((m) => m.id).includes(this.authService.user.employee.id)
        ) {
            const me = validEmployees.find((f) => f.id === this.authService.user.employee.id);
            this.form.patchValue({
                participants: [...this.form.value.participants, {employee: me}],
            });
        }

        this.members = [
            ...validEmployees
                .filter((employee) => !existingIds.includes(employee.id))
                .map(
                    (employee) =>
                        ({
                            employee,
                        } as Partial<IMeetingParticipant>)
                ),
        ];
    }

    public removeMember(participant: IMeetingParticipant) {
        const {participants} = this.form.value;
        participants.splice(participants.indexOf(participant), 1);
        this.form.patchValue({participants});
    }

    public updateMember() {
        const {participants} = this.form.value;
        this.form.patchValue({participants});
    }

    public submit() {
        if (!this.form.valid) {
            this.clrForm.markAsDirty();

            return undefined;
        }

        return this.form;
    }

    public readonly EMPLOYEE_FIELD = EMPLOYEE_FIELD;
    public readonly USER_FIELD = USER_FIELD;
}
