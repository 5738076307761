import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {Validators} from '@angular/forms';
import {ClrForm} from '@clr/angular';
import {FormControl, FormGroup} from 'ngx-strongly-typed-forms';
import {
    ApiRoutePlurality,
    HTTP_METHOD,
    MAX_LENGTH_DEFAULT,
    PATTERN_CAPITAL_NUMERIC_SPACE,
    ProjectStatusType,
} from '../../../../../defs/schema-static';
import {CLIENT_SCHEMA_ROUTE, IClient} from '../../../../../defs/schema/public/Clients';
import {IProject, PROJECT_SCHEMA_ROUTE} from '../../../../../defs/schema/public/Projects';
import {CLIENT_SORT_FUNCTION} from '../../../../../defs/sorters';
import {getRandomColorHex, IResettable, MAX_LENGTH_PROJECT_CODE, PROJECT_STATUS_FILTER} from '../../app-static';
import {requiredTrimValidator} from '../../forms/validators/required-trim.validator';
import {HttpRestService} from '../../shared/http-rest/http-rest.service';

enum PROJECT_FORM_KEYS {
    obs = 'obs',
    code = 'code',
    color = 'color',
    clientId = 'clientId',
    status = 'status',
}

interface IProjectFormValues {
    obs: string;
    code: string;
    color: string;
    clientId: number;
    status: ProjectStatusType;
}

@Component({
    selector: 'app-project-wizard-project',
    templateUrl: './project-wizard-project.component.html',
    styleUrls: ['./project-wizard-project.component.scss'],
})
export class ProjectWizardProjectComponent implements OnInit, OnChanges, IResettable {
    @ViewChild(ClrForm) private readonly clrForm: ClrForm;

    @Input() public clients: Partial<IClient>[] = [];
    @Input() public clientId: number;

    @Input() public project: IProject;

    @Input() public formLayout = 'horizontal';

    @Input() public clrWizardOpen = false;

    public readonly form = new FormGroup<IProjectFormValues>({
        [PROJECT_FORM_KEYS.obs]: new FormControl<string>(null, [
            requiredTrimValidator(),
            Validators.maxLength(MAX_LENGTH_DEFAULT),
        ]),
        [PROJECT_FORM_KEYS.code]: new FormControl<string>(null, [
            requiredTrimValidator(),
            Validators.maxLength(MAX_LENGTH_PROJECT_CODE),
            Validators.pattern(PATTERN_CAPITAL_NUMERIC_SPACE),
        ]),
        [PROJECT_FORM_KEYS.color]: new FormControl<string>(getRandomColorHex(), requiredTrimValidator()),
        [PROJECT_FORM_KEYS.clientId]: new FormControl<number>(this.clientId, Validators.required),
        [PROJECT_FORM_KEYS.status]: new FormControl<ProjectStatusType>(ProjectStatusType.OPEN, Validators.required),
    });

    public constructor(private readonly httpRest: HttpRestService) {}

    public ngOnInit() {
        this.setProject();

        // expected from app-projects
        /*if (!this.clients || !this.clients.length) {
            (async () => this.getClients())();
        }*/
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.clientId && !this.form.value.clientId) {
            this.form.patchValue({clientId: this.clientId});
        }

        if (changes.project) {
            this.setProject();
        }
    }

    public filterClient(id?: number) {
        this.form.patchValue({
            [PROJECT_FORM_KEYS.clientId]: id || null,
        });
    }

    public setProject(): void {
        if (!this.project) {
            return this.reset();
        }
        this.form.reset(this.project);
    }

    public async getClients(): Promise<void> {
        this.clients = (await this.httpRest
            ._request<IClient[]>(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, CLIENT_SCHEMA_ROUTE, 'list')
            .toPromise()).sort(CLIENT_SORT_FUNCTION);
    }

    public async submit(): Promise<IProject> {
        if (!this.form.valid) {
            this.clrForm.markAsDirty();

            return undefined;
        }

        const {clientId} = this.form.value;
        const client = this.clients.find((_client) => _client.id === clientId);

        return {
            ...(await this.httpRest
                .put<IProject>(PROJECT_SCHEMA_ROUTE, {
                    ...this.form.value,
                })
                .toPromise()),
            client,
        };
    }

    public reset(): void {
        this.form.reset({
            [PROJECT_FORM_KEYS.color]: getRandomColorHex(),
            [PROJECT_FORM_KEYS.clientId]: this.clientId,
            [PROJECT_FORM_KEYS.status]: ProjectStatusType.OPEN,
        });
    }

    public readonly PROJECT_FORM_KEYS = PROJECT_FORM_KEYS;
    public readonly MAX_LENGTH_DEFAULT = MAX_LENGTH_DEFAULT;
    public readonly MAX_LENGTH_PROJECT_CODE = MAX_LENGTH_PROJECT_CODE;
    public readonly PROJECT_STATUS_VALUES = Object.values(ProjectStatusType);
    public readonly PROJECT_STATUS_FILTER = PROJECT_STATUS_FILTER;
}
