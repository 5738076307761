var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ElementRef, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, FormGroup } from 'ngx-strongly-typed-forms';
import { ApiRoutePlurality, HTTP_METHOD, ISO_DATE_FORMAT, MAX_LENGTH_DEFAULT, MilestonesType, RIGHTS, } from '../../../../defs/schema-static';
import { CLIENT_SCHEMA_ROUTE } from '../../../../defs/schema/public/Clients';
import { EMPLOYEE_SCHEMA_ROUTE } from '../../../../defs/schema/public/Employees';
import { MILESTONE_SCHEMA_ROUTE } from '../../../../defs/schema/public/Milestones';
import { PROJECT_SCHEMA_ROUTE } from '../../../../defs/schema/public/Projects';
import { AuthService } from '../auth/auth.service';
import { FormsAddMilestoneService, MILESTONE_FORM_KEYS, MilestoneTarget, } from '../forms/add-milestone.service';
import { requiredTrimValidator } from '../forms/validators/required-trim.validator';
import { HttpRestService } from '../shared/http-rest/http-rest.service';
import { MomentService } from '../shared/moment/moment.service';
var EditMilestoneComponent = (function () {
    function EditMilestoneComponent(translate, httpRest, authService, momentService, formsAddMilestoneService) {
        var _this = this;
        this.translate = translate;
        this.httpRest = httpRest;
        this.authService = authService;
        this.momentService = momentService;
        this.formsAddMilestoneService = formsAddMilestoneService;
        this.form = new FormGroup({
            description: new FormControl(),
            milestone: new FormControl(null, [requiredTrimValidator(), Validators.maxLength(MAX_LENGTH_DEFAULT)]),
            target: new FormControl(null, requiredTrimValidator()),
            startDate: new FormControl(null, Validators.required),
            startTime: new FormControl(null, Validators.required),
            endDate: new FormControl(null, Validators.required),
            endTime: new FormControl(null, Validators.required),
            version: new FormControl(),
            participants: new FormControl(),
        });
        this.showModal = false;
        this.showModalChange = new EventEmitter();
        this.hidden = new EventEmitter();
        this.saved = new EventEmitter();
        this.removed = new EventEmitter();
        this.deletable = true;
        this.errorsDates = ['error_date_begin_only', 'error_date'];
        this.errorDates = 0;
        this.showDeleteModal = false;
        this.checkDates = function () {
            if (_this.beginDateInputElement && _this.beginDateInputElement.nativeElement.value === '') {
                _this.form.patchValue({ startDate: null });
                _this.beginDate = null;
            }
            if (_this.endDateInputElement && _this.endDateInputElement.nativeElement.value === '') {
                _this.form.patchValue({ endDate: null });
                _this.endDate = null;
            }
            if (_this.targetDateInputElement && _this.targetDateInputElement.nativeElement.value === '') {
                _this.form.patchValue({ target: null });
                _this.targetDate = null;
            }
            if (_this.selectedEvent.client === -1 && _this.selectedEvent.project === -1) {
                return true;
            }
            return _this.formsAddMilestoneService.checkDates(_this.form, _this.beginDate, _this.endDate, _this.targetDate, _this.selectedEvent.type);
        };
        this.MILESTONE_FORM_KEYS = MILESTONE_FORM_KEYS;
        this.MILESTONE_TYPES = MilestonesType;
        this.MAX_LENGTH_DEFAULT = MAX_LENGTH_DEFAULT;
        this.RIGHTS = RIGHTS;
        (function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
            return [2, this.getData()];
        }); }); })();
    }
    EditMilestoneComponent.prototype.getData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4, Promise.all([
                            this.httpRest
                                ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, EMPLOYEE_SCHEMA_ROUTE, 'calendar')
                                .toPromise(),
                            this.httpRest
                                ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, PROJECT_SCHEMA_ROUTE, 'light')
                                .toPromise(),
                            this.httpRest
                                ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, CLIENT_SCHEMA_ROUTE, 'light')
                                .toPromise(),
                        ])];
                    case 1:
                        _a = _b.sent(), this.employees = _a[0], this.projects = _a[1], this.clients = _a[2];
                        this.setMembers();
                        this.setEmp();
                        this.setClient();
                        this.setProject();
                        return [2];
                }
            });
        });
    };
    EditMilestoneComponent.prototype.ngOnChanges = function (changes) {
        if (changes.selectedEvent && this.selectedEvent) {
            this.form.reset();
            this.initEditMilestone();
            this.setMembers();
            this.setEmp();
            this.setClient();
            this.setProject();
            if (!(this.authService.hasRight(RIGHTS.MS_UPDATE) ||
                this.selectedEvent.author === this.authService.user.employee.id)) {
                this.form.disable();
            }
        }
    };
    Object.defineProperty(EditMilestoneComponent.prototype, "canEdit", {
        get: function () {
            return (this.authService.hasRight(RIGHTS.MS_UPDATE) ||
                this.selectedEvent.author === this.authService.user.employee.id);
        },
        enumerable: true,
        configurable: true
    });
    EditMilestoneComponent.prototype.hideModale = function () {
        this.hidden.emit();
    };
    EditMilestoneComponent.prototype.deleteMilestone = function () {
        var _this = this;
        this.httpRest.deleteId(MILESTONE_SCHEMA_ROUTE, this.selectedEvent.id).subscribe(function (res) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (res.count > 0) {
                    this.removed.emit();
                    this.showModal = false;
                    this.showDeleteModal = false;
                }
                return [2];
            });
        }); });
    };
    EditMilestoneComponent.prototype.setClient = function () {
        var _this = this;
        if (!this.selectedEvent || !(this.selectedEvent.client && this.selectedEvent.client > 0) || !this.clients) {
            return '';
        }
        this.selectedClient = this.clients.find(function (f) { return f.id === _this.selectedEvent.client; });
    };
    EditMilestoneComponent.prototype.setProject = function () {
        var _this = this;
        if (!this.selectedEvent || !(this.selectedEvent.project && this.selectedEvent.project > 0) || !this.projects) {
            return '';
        }
        this.selectedProject = this.projects.find(function (f) { return f.id === _this.selectedEvent.project; });
    };
    EditMilestoneComponent.prototype.setEmp = function () {
        var _this = this;
        if (!this.selectedEvent || !this.employees || this.employees.length === 0 || !this.selectedEvent.author) {
            return;
        }
        this.selectedEmployee = this.employees.find(function (f) { return f.id === _this.selectedEvent.author; });
    };
    EditMilestoneComponent.prototype.initEditMilestone = function () {
        var _this = this;
        if (!this.selectedEvent.participants) {
            this.selectedEvent.participants = [];
        }
        this.beginDate = this.momentService.moment(this.selectedEvent.startDate).toDate();
        this.endDate = this.momentService.moment(this.selectedEvent.endDate).toDate();
        this.targetDate = this.momentService.moment(this.selectedEvent.target).toDate();
        this.toggle(this.selectedEvent.type);
        this.form.patchValue({
            milestone: this.selectedEvent.milestone,
            description: this.selectedEvent.description,
            version: this.selectedEvent.version,
            participants: this.selectedEvent.participants.slice(),
            targetType: this.selectedEvent.client ? MilestoneTarget.CLIENT : MilestoneTarget.PROJECT,
            type: this.selectedEvent.type,
            startDate: this.beginDate ? this.momentService.moment(this.beginDate).format(ISO_DATE_FORMAT) : null,
            endDate: this.endDate ? this.momentService.moment(this.endDate).format(ISO_DATE_FORMAT) : null,
            target: this.targetDate ? this.momentService.moment(this.targetDate).format(ISO_DATE_FORMAT) : null,
        });
        setTimeout(function () {
            _this.form.patchValue({
                startTime: _this.selectedEvent.startTime,
                endTime: _this.selectedEvent.endTime,
            });
        }, 50);
    };
    EditMilestoneComponent.prototype.toggle = function (type) {
        var _type = this.form.value.type || type;
        this.errorDates = 1;
        if (MilestonesType.REMINDER === _type) {
            this.errorDates = 0;
        }
        FormsAddMilestoneService.toggle(this.form, type);
    };
    EditMilestoneComponent.prototype.saveMilestone = function () {
        if (!this.form.valid) {
            return;
        }
        return this.saved.emit(__assign({}, this.selectedEvent, this.form.value));
    };
    EditMilestoneComponent.prototype.setMembers = function () {
        var _this = this;
        if (!this.selectedEvent ||
            (this.selectedEvent.type !== MilestonesType.MEETING &&
                this.selectedEvent.type !== MilestonesType.MEETING_NO_NOTE)) {
            return;
        }
        var existingIds = (this.form.value.participants || []).map(function (participant) { return participant.employee.id; });
        var validEmployees = [];
        if (this.selectedEvent.targetType === MilestoneTarget.PROJECT && this.projects && this.selectedEvent.project) {
            validEmployees = this.employees.filter(function (e) {
                var proj = _this.projects.length <= 0
                    ? undefined
                    : _this.projects.find(function (p) { return p.id === Number(_this.selectedEvent.project); });
                return !proj
                    ? false
                    : proj.projectMembers.findIndex(function (_e) { return _e.employeeId === e.id; }) !== -1 &&
                        (_this.form.value.participants || []).findIndex(function (_e) { return _e.employeeId === e.id; }) === -1;
            });
        }
        else if (this.selectedEvent.targetType === MilestoneTarget.CLIENT &&
            this.clients &&
            this.selectedEvent.client) {
            validEmployees = this.employees.filter(function (e) {
                var proj = _this.projects.length <= 0
                    ? undefined
                    : _this.projects.filter(function (p) { return p.clientId === Number(_this.selectedEvent.client); });
                return !proj
                    ? false
                    : proj
                        .reduce(function (pm, p) { return pm.concat(p.projectMembers); }, [])
                        .findIndex(function (_e) { return _e.employeeId === e.id; }) !== -1 &&
                        (_this.form.value.participants || []).findIndex(function (_e) { return _e.employeeId === e.id; }) === -1;
            });
        }
        this.members = (this.form.value.participants ? this.form.value.participants : []).concat(validEmployees
            .filter(function (employee) { return !existingIds.includes(employee.id); })
            .map(function (employee) {
            return ({
                employee: employee,
            });
        }));
    };
    EditMilestoneComponent.prototype.removeMember = function (participant) {
        var participants = this.form.value.participants;
        participants.splice(participants.indexOf(participant), 1);
        this.form.patchValue({ participants: participants });
    };
    EditMilestoneComponent.prototype.updateMember = function () {
        var participants = this.form.value.participants;
        this.form.patchValue({ participants: participants });
    };
    EditMilestoneComponent.HOUR_MINUTE_TIME_FORMAT = 'HH:mm';
    return EditMilestoneComponent;
}());
export { EditMilestoneComponent };
