<ng-container>
    <ng-container
        *ngIf="
            file.state === FileStateType.PENDING ||
            file.state === FileStateType.UPLOADING ||
            file.state === FileStateType.PAUSED ||
            file.state === FileStateType.DONE
        "
    >
        {{ ((file.progress || 0) * 100).toFixed() }}%

        <span *ngIf="file.transferStats?.secondsLeft > 0">
            ({{ formatRemainingSeconds(file.transferStats?.secondsLeft) }})
        </span>
    </ng-container>

    <ng-container *ngIf="file.state === FileStateType.WAITING"> {{ 'pending' | translate }} </ng-container>
</ng-container>
