<form clrForm [formGroup]="form" [clrLayout]="formLayout">
    <clr-input-container class="mt-0">
        <label translate>project_name</label>
        <input
            clrInput
            [formControlName]="PROJECT_FORM_KEYS.obs"
            type="text"
            [sharedAutofocus]="clrWizardOpen"
            maxlength="{{ MAX_LENGTH_DEFAULT }}"
        />

        <clr-control-error *clrIfError="'requiredTrim'" translate>error_field_empty</clr-control-error>
        <clr-control-error *clrIfError="'maxLength'" translate>error_field_text_overflow</clr-control-error>
    </clr-input-container>

    <clr-input-container>
        <label translate>placeholder_code</label>
        <input
            clrInput
            [formControlName]="PROJECT_FORM_KEYS.code"
            type="text"
            maxlength="{{ MAX_LENGTH_PROJECT_CODE }}"
            appUppercaseInput
        />
        <clr-control-helper>{{ MAX_LENGTH_PROJECT_CODE }} {{ 'max_length_code' | translate }}</clr-control-helper>

        <clr-control-error *clrIfError="'requiredTrim'" translate>error_field_empty</clr-control-error>
        <clr-control-error *clrIfError="'maxLength'" translate>error_field_text_overflow</clr-control-error>
        <clr-control-error *clrIfError="'pattern'" translate>error_field_pattern_capital_numeric</clr-control-error>
    </clr-input-container>

    <clr-input-container>
        <label translate>badge_color</label> <input clrInput [formControlName]="PROJECT_FORM_KEYS.color" type="color" />
        <clr-control-error translate>error_field_empty</clr-control-error>
    </clr-input-container>

    <div class="clr-row" *ngIf="!project || !project.id">
        <div class="clr-col-xs-12 clr-col-md-2"><label class="fake-label" translate>client</label></div>
        <div class="clr-col-md-10 clr-col-xs-12">
            <ng-select
                class="less-pl p-0"
                [items]="clients"
                bindLabel="user.name"
                bindValue="id"
                (clear)="filterClient()"
                (change)="filterClient($event?.id)"
                [formControlName]="PROJECT_FORM_KEYS.clientId"
            >
                <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index">
                    <app-long-avatar [code]="item?.user?.code" [bgColor]="item?.user?.color"> </app-long-avatar>
                    {{ item?.user?.name }}
                </ng-template>
            </ng-select>
        </div>
    </div>

    <clr-select-container>
        <label translate>table_status</label>
        <select clrSelect [formControlName]="PROJECT_FORM_KEYS.status">
            <option *ngFor="let status of PROJECT_STATUS_VALUES" [ngValue]="status">
                {{ PROJECT_STATUS_FILTER[status].translation | translate }}
            </option>
        </select>
    </clr-select-container>
</form>
