<clr-wizard
    [clrWizardOpen]="clrWizardOpen"
    (clrWizardOpenChange)="clrWizardOpenChange.emit((clrWizardOpen = $event))"
    (clrWizardOnCancel)="triggerCancel()"
    clrWizardSize="xl"
>
    <clr-wizard-title translate>add_time</clr-wizard-title>

    <clr-wizard-button [clrWizardButtonDisabled]="submitting" [type]="'cancel'">{{
        'cancel' | translate
    }}</clr-wizard-button>
    <clr-wizard-button [clrWizardButtonDisabled]="submitting" [type]="'previous'">{{
        'back' | translate
    }}</clr-wizard-button>
    <clr-wizard-button [clrWizardButtonDisabled]="submitting" [type]="'next'">{{
        'next' | translate
    }}</clr-wizard-button>
    <clr-wizard-button [clrWizardButtonDisabled]="submitting" [type]="'finish'">{{
        'create_time' | translate
    }}</clr-wizard-button>

    <clr-wizard-page [clrWizardPageNextDisabled]="!typeComponent?.form.valid" (clrWizardPageOnLoad)="onLoad(1)">
        <ng-template clrPageTitle>{{ 'type' | translate }}</ng-template>
        <app-time-type (switchType)="onCommit(1)"></app-time-type>
    </clr-wizard-page>

    <clr-wizard-page
        (clrWizardPageOnLoad)="onLoad(2)"
        *ngIf="typeComponent.form.value.taskTarget !== TASK_TARGET.NEW"
        [clrWizardPageNextDisabled]="!existingComponent?.form.valid"
    >
        <ng-template clrPageTitle>{{ 'task_assign' | translate }}</ng-template>
        <app-time-existing (projectChange)="projectChange($event)"></app-time-existing>
    </clr-wizard-page>

    <clr-wizard-page
        (clrWizardPageOnLoad)="onLoad(3)"
        *ngIf="typeComponent.form.value.taskTarget === TASK_TARGET.NEW"
        [clrWizardPageNextDisabled]="!taskComponent?.form.valid"
    >
        <ng-template clrPageTitle>{{ 'task_creation' | translate }}</ng-template>
        <app-forms-add-task [employees]="employees"></app-forms-add-task>
    </clr-wizard-page>

    <clr-wizard-page
        (clrWizardPageOnLoad)="onLoad(4)"
        [clrWizardPageNextDisabled]="!detailsComponent?.form.valid"
        clrWizardPagePreventDefault="true"
        (clrWizardPageOnCancel)="triggerCancel()"
        (clrWizardPagePrevious)="wizard.previous()"
        (clrWizardPageOnCommit)="triggerFinish()"
    >
        <ng-template clrPageTitle>{{ 'details' | translate }}</ng-template>
        <app-time-details [employees]="employeeMembers"></app-time-details>
    </clr-wizard-page>
</clr-wizard>
