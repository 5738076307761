var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ClrForm } from '@clr/angular';
import { FormControl, FormGroup } from 'ngx-strongly-typed-forms';
import { ApiRoutePlurality, HTTP_METHOD } from '../../../../../defs/schema-static';
import { EMPLOYEE_FIELD, EMPLOYEE_SCHEMA_ROUTE } from '../../../../../defs/schema/public/Employees';
import { USER_FIELD } from '../../../../../defs/schema/public/Users';
import { AuthService } from '../../auth/auth.service';
import { MilestoneTarget } from '../../forms/add-milestone.service';
import { HttpRestService } from '../../shared/http-rest/http-rest.service';
var MilestoneParticipantsComponent = (function () {
    function MilestoneParticipantsComponent(httpRest, authService) {
        this.httpRest = httpRest;
        this.authService = authService;
        this.form = new FormGroup({
            participants: new FormControl(),
        });
        this.clients = [];
        this.projects = [];
        this.employees = [];
        this.EMPLOYEE_FIELD = EMPLOYEE_FIELD;
        this.USER_FIELD = USER_FIELD;
    }
    MilestoneParticipantsComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (!this.employees || !this.employees.length) {
            (function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                return [2, this.getEmployees()];
            }); }); })();
        }
        else {
            this.setMembers();
        }
        this.form.patchValue({
            participants: [],
        });
    };
    MilestoneParticipantsComponent.prototype.ngOnChanges = function (changes) {
        if (changes.clients || changes.projects || changes.id) {
            this.setMembers();
        }
    };
    MilestoneParticipantsComponent.prototype.getEmployees = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4, this.httpRest
                                ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, EMPLOYEE_SCHEMA_ROUTE, 'project')
                                .toPromise()];
                    case 1:
                        _a.employees = (_b.sent()).sort(function (e1, e2) { return e1.user.name.localeCompare(e2.user.name); });
                        this.setMembers();
                        return [2];
                }
            });
        });
    };
    MilestoneParticipantsComponent.prototype.setMembers = function () {
        var _this = this;
        var existingIds = (this.form.value.participants || []).map(function (participant) { return participant.employee.id; });
        var validEmployees = [];
        if (this.targetType === MilestoneTarget.PROJECT && this.projects && this.id) {
            validEmployees = this.employees.filter(function (e) {
                return _this.projects
                    .find(function (p) { return p.id === Number(_this.id); })
                    .projectMembers.findIndex(function (_e) { return _e.employeeId === e.id; }) !== -1 &&
                    _this.form.value.participants.findIndex(function (_e) { return _e.employeeId === e.id; }) === -1;
            });
        }
        else if (this.targetType === MilestoneTarget.CLIENT && this.clients && this.id) {
            validEmployees = this.employees.filter(function (e) {
                return _this.projects
                    .filter(function (p) { return p.clientId === Number(_this.id); })
                    .reduce(function (pm, p) { return pm.concat(p.projectMembers); }, [])
                    .findIndex(function (_e) { return _e.employeeId === e.id; }) !== -1 &&
                    _this.form.value.participants.findIndex(function (_e) { return _e.employeeId === e.id; }) === -1;
            });
        }
        if (!existingIds.includes(this.authService.user.employee.id) &&
            validEmployees.map(function (m) { return m.id; }).includes(this.authService.user.employee.id)) {
            var me = validEmployees.find(function (f) { return f.id === _this.authService.user.employee.id; });
            this.form.patchValue({
                participants: this.form.value.participants.concat([{ employee: me }]),
            });
        }
        this.members = validEmployees
            .filter(function (employee) { return !existingIds.includes(employee.id); })
            .map(function (employee) {
            return ({
                employee: employee,
            });
        }).slice();
    };
    MilestoneParticipantsComponent.prototype.removeMember = function (participant) {
        var participants = this.form.value.participants;
        participants.splice(participants.indexOf(participant), 1);
        this.form.patchValue({ participants: participants });
    };
    MilestoneParticipantsComponent.prototype.updateMember = function () {
        var participants = this.form.value.participants;
        this.form.patchValue({ participants: participants });
    };
    MilestoneParticipantsComponent.prototype.submit = function () {
        if (!this.form.valid) {
            this.clrForm.markAsDirty();
            return undefined;
        }
        return this.form;
    };
    return MilestoneParticipantsComponent;
}());
export { MilestoneParticipantsComponent };
