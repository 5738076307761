import moment from 'moment';
import {IMetadata, ISchemaRoute, TaskResolution, TaskStatusType, TaskType} from '../../schema-static';
import {ITaskFile} from '../storage/TaskFiles';
import {IAssignedTask} from './AssignedTasks';
import {IEmployee} from './Employees';
import {IFailedReview} from './FailedReviews';
import {IMilestone} from './Milestones';
import {IProject} from './Projects';
import {ITaskBlocker} from './TaskBlockers';
import {ITaskComments} from './TaskComments';
import {ITaskHistory} from './TaskHistory';
import {ITaskMail} from './TaskMails';
import {ITaskPage} from './TaskPages';
import {ITaskTag} from './TaskTags';
import {ITime} from './Times';

export enum TASK_FIELD {
    id = 'id',
    createdAt = 'createdAt',
    updatedAt = 'updatedAt',
    beginDate = 'beginDate',
    endDate = 'endDate',
    name = 'name',
    progress = 'progress',
    status = 'status',
    type = 'type',
    tracked = 'tracked',
    parentId = 'parentId',
    projectId = 'projectId',
    estimatedTime = 'estimatedTime',
    targetReleaseId = 'targetReleaseId',
    failedReviewCount = 'failedReviewCount',
    authorId = 'authorId',
    parent = 'parent',
    author = 'author',
    project = 'project',
    targetRelease = 'targetRelease',
    children = 'children',
    times = 'times',
    employees = 'employees',
    taskComments = 'taskComments',
    code = 'code',
    urgent = 'urgent',
    resolution = 'resolution',
    needHelp = 'needHelp',
}

export enum TASK_JQL {
    id = 'Id',
    createdAt = 'Created at',
    updatedAt = 'Updated at',
    beginDate = 'Begin date',
    endDate = 'End date',
    code = 'Code',
    name = 'Name',
    estimatedTime = 'Estimated time',
    progress = 'Progress',
    status = 'Status',
    type = 'Type',
    urgent = 'Urgent',
    // needHelp = 'Need Help',
    tracked = 'Tracked',
    projectId = 'Project',
    authorId = 'Author',
    targetReleaseId = 'Target Release',
    failedReviewCount = 'Failed review count',
    resolution = 'Resolution',
    tags = 'Tags',
}

export enum TASK_JQL_TRANSLATE {
    id = 'Id',
    createdAt = 'created_at',
    updatedAt = 'updated_at',
    beginDate = 'date_begin',
    endDate = 'date_end',
    code = 'task_code',
    name = 'task_name',
    estimatedTime = 'est_time',
    progress = 'task_progress',
    status = 'task_status',
    type = 'task_type',
    urgent = 'task_urgent',
    // needHelp = 'need_help',
    tracked = 'task_tracked',
    projectId = 'project',
    authorId = 'author',
    targetReleaseId = 'target_release',
    failedReviewCount = 'failed_review_count',
    resolution = 'resolution',
    tags = 'tags',
}

export interface ITaskBare extends IMetadata {
    id?: number;
    createdAt?: moment.Moment;
    updatedAt?: moment.Moment;
    beginDate?: moment.Moment;
    endDate?: moment.Moment;
    name?: string;
    progress: number;
    status: TaskStatusType;
    type: TaskType;
    urgent?: boolean;
    tracked?: boolean;
    needHelp: boolean;
    parentId?: number;
    projectId: number;
    estimatedTime?: string;
    targetReleaseId?: number;
    failedReviewCount: number;
    authorId: number;
    code: string;
    assigned: Partial<IAssignedTask>[];
    resolution?: TaskResolution;
}

export interface ITaskBelong {
    parent?: Partial<ITask>;
    author?: Partial<IEmployee>;
    project: Partial<IProject>;
    targetRelease?: Partial<IMilestone>;
}

export interface ITaskMany {
    children: Partial<ITask>[];
    times?: Partial<ITime>[];
    employees?: Partial<IEmployee>[];
    taskComments?: Partial<ITaskComments>[];
    taskMails?: Partial<ITaskMail>[];
    taskBlockers?: Partial<ITaskBlocker>[];
    taskPages?: Partial<ITaskPage>[];
    taskFiles?: Partial<ITaskFile>[];
    tasktags?: Partial<ITaskTag>[];
    taskHistories?: Partial<ITaskHistory>[];
    failedReviews?: Partial<IFailedReview>[];
}

export interface ITask extends ITaskBare, ITaskBelong, ITaskMany {}

const TASK_SCHEMA_ROUTE: ISchemaRoute = {
    tableName: 'tasks',
    singularRoute: 'task',
    pluralRoute: 'tasks',
};

export {TASK_SCHEMA_ROUTE};
