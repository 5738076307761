var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a, _b, _c;
import { AfterViewInit, ChangeDetectorRef, ElementRef, OnDestroy, OnInit, } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import fuzzysort from 'fuzzysort';
import { horsey } from 'horsey';
import { ApiRoutePlurality, HTTP_METHOD, NAVIGATE_RIGHTS, stripAccents, TaskStatusType, } from '../../../../defs/schema-static';
import { CLIENT_SCHEMA_ROUTE } from '../../../../defs/schema/public/Clients';
import { EMPLOYEE_SCHEMA_ROUTE } from '../../../../defs/schema/public/Employees';
import { PROJECT_SCHEMA_ROUTE } from '../../../../defs/schema/public/Projects';
import { TASK_SCHEMA_ROUTE } from '../../../../defs/schema/public/Tasks';
import { SETTING_LANGUAGE_VALUES } from '../../../../defs/schema/public/Users';
import { WIKI_PAGE_SCHEMA_ROUTE } from '../../../../defs/schema/public/WikiPage';
import { CLIENT_TABS_LABELS, CLIENT_TABS_RIGHTS, PROJECT_TABS_LABELS, PROJECT_TABS_RIGHTS, TASK_STATUS_FILTER, } from '../app-static';
import { AuthService } from '../auth/auth.service';
import { ModalComponent } from '../modal/modal.component';
import { ControlFlowService } from '../shared/control-flow/control-flow.service';
import { HttpRestService } from '../shared/http-rest/http-rest.service';
import { SHORTCUT_COMBO, SHORTCUT_LABEL, SHORTCUT_RIGHTS, ShortcutHandlerService, } from '../shared/shortcut-handler/shortcut-handler.service';
var AutocompleteCategory;
(function (AutocompleteCategory) {
    AutocompleteCategory["GLOBAL"] = "Global";
    AutocompleteCategory["ACTION"] = "Actions";
    AutocompleteCategory["PROJECT"] = "Projects";
    AutocompleteCategory["PROJECT_SUBMENU"] = "PROJECT_SUBMENU";
    AutocompleteCategory["CLIENT"] = "Clients";
    AutocompleteCategory["CLIENT_SUBMENU"] = "CLIENT_SUBMENU";
    AutocompleteCategory["TASKS"] = "Tasks";
    AutocompleteCategory["EMPLOYEE"] = "Employees";
    AutocompleteCategory["WIKI_PAGE"] = "Wiki Pages";
})(AutocompleteCategory || (AutocompleteCategory = {}));
var AUTOCOMPLETE_CATEGORY_TRANSLATE_KEY = (_a = {},
    _a[AutocompleteCategory.ACTION] = 'actions',
    _a[AutocompleteCategory.PROJECT] = 'projects',
    _a[AutocompleteCategory.CLIENT] = 'clients',
    _a[AutocompleteCategory.TASKS] = 'tasks',
    _a[AutocompleteCategory.EMPLOYEE] = 'employees',
    _a[AutocompleteCategory.WIKI_PAGE] = 'wiki-pages',
    _a);
var GlobalSearchModalComponent = (function (_super) {
    __extends(GlobalSearchModalComponent, _super);
    function GlobalSearchModalComponent(shortcutHandlerService, httpRest, router, changeDetectorRef, translate, controlFlowService, authService) {
        var _a;
        var _this = _super.call(this, shortcutHandlerService, controlFlowService, changeDetectorRef) || this;
        _this.shortcutHandlerService = shortcutHandlerService;
        _this.httpRest = httpRest;
        _this.router = router;
        _this.changeDetectorRef = changeDetectorRef;
        _this.translate = translate;
        _this.authService = authService;
        _this.title = 'global_search';
        _this.query = '';
        _this.displayInline = false;
        _this.isCollapsed = false;
        _this.autocompleteCategoryMethods = (_a = {},
            _a[AutocompleteCategory.PROJECT] = { get: _this.getProjects, map: _this.mapProjects },
            _a[AutocompleteCategory.CLIENT] = { get: _this.getClients, map: _this.mapClients },
            _a[AutocompleteCategory.TASKS] = { get: _this.getTasks, map: GlobalSearchModalComponent.mapTasks },
            _a[AutocompleteCategory.EMPLOYEE] = { get: _this.getEmployees, map: GlobalSearchModalComponent.mapEmployees },
            _a[AutocompleteCategory.WIKI_PAGE] = { get: _this.getWikiPages, map: GlobalSearchModalComponent.mapWikiPages },
            _a);
        _this._searchScope = AutocompleteCategory.GLOBAL;
        _this.horseySources = [];
        _this.GET_ACTION_CATEGORY_SOURCE = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.authService;
                        return [4, this.authService.getRightsPromise];
                    case 1:
                        _a.rights = _b.sent();
                        return [2, GlobalSearchModalComponent.generateSource(AutocompleteCategory.ACTION, Object.keys(SHORTCUT_LABEL), function (shortcutKey) { return __awaiter(_this, void 0, void 0, function () {
                                var _a, _b;
                                return __generator(this, function (_c) {
                                    switch (_c.label) {
                                        case 0:
                                            _a = (!SHORTCUT_RIGHTS[shortcutKey] ||
                                                this.authService.rights.find(function (right) { return right.code === SHORTCUT_RIGHTS[shortcutKey]; }));
                                            if (!_a) return [3, 2];
                                            _b = {
                                                category: AutocompleteCategory.ACTION,
                                                value: shortcutKey
                                            };
                                            return [4, this.translate.get(SHORTCUT_LABEL[shortcutKey]).toPromise()];
                                        case 1:
                                            _a = (_b.text = _c.sent(),
                                                _b.shortcut = shortcutKey || undefined,
                                                _b);
                                            _c.label = 2;
                                        case 2: return [2, _a];
                                    }
                                });
                            }); })];
                }
            });
        }); };
        _this.AutocompleteCategory = AutocompleteCategory;
        _this.CATEGORY_KEY = GlobalSearchModalComponent.CATEGORY_KEY;
        _this.CATEGORY_KEY_KEYS = Object.keys(GlobalSearchModalComponent.CATEGORY_KEY);
        _this.CATEGORY_RIGHTS = GlobalSearchModalComponent.CATEGORY_RIGHTS;
        return _this;
    }
    GlobalSearchModalComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        if (this.displayInline) {
            this.controlFlowService.isCollapsed.subscribe(function (collapsed) {
                if (collapsed) {
                    _this.query = '';
                }
            });
        }
    };
    GlobalSearchModalComponent.prototype.load = function () { };
    GlobalSearchModalComponent.prototype.modalShown = function () {
        var _this = this;
        (function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
            return [2, this.initHorsey()];
        }); }); })();
        this.query = '';
        this._searchScope = AutocompleteCategory.GLOBAL;
    };
    GlobalSearchModalComponent.prototype.modalHidden = function () {
        if (this.horsey) {
            this.horsey.destroy();
            this.horsey = null;
        }
    };
    GlobalSearchModalComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        if (this.displayInline) {
            (function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                return [2, this.initHorsey()];
            }); }); })();
            this.query = '';
            this._searchScope = AutocompleteCategory.GLOBAL;
        }
    };
    GlobalSearchModalComponent.prototype.focusInput = function () {
        if (this.autocompleteInput) {
            this.autocompleteInput.nativeElement.focus();
        }
    };
    GlobalSearchModalComponent.prototype.initHorsey = function () {
        return __awaiter(this, void 0, void 0, function () {
            var browserLang, lang, _a, no_matches_ellipsis, loading_ellipsis, count_more_ellipsis;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (this.horsey) {
                            this.horsey.destroy();
                            this.autocompleteInput.nativeElement.focus();
                        }
                        if (!!this.translate.currentLang) return [3, 2];
                        browserLang = this.translate.getBrowserLang().toUpperCase();
                        lang = Object.values(SETTING_LANGUAGE_VALUES).includes(browserLang)
                            ? browserLang
                            : SETTING_LANGUAGE_VALUES.EN;
                        this.translate.use(lang);
                        return [4, new Promise(function (resolve, reject) { return _this.translate.onLangChange.subscribe(resolve, reject); })];
                    case 1:
                        _b.sent();
                        _b.label = 2;
                    case 2: return [4, this.translate
                            .get(['no_matches_ellipsis', 'loading_ellipsis', 'count_more_ellipsis'])
                            .toPromise()];
                    case 3:
                        _a = _b.sent(), no_matches_ellipsis = _a.no_matches_ellipsis, loading_ellipsis = _a.loading_ellipsis, count_more_ellipsis = _a.count_more_ellipsis;
                        this.horsey = horsey(this.autocompleteInput.nativeElement, {
                            source: this.getHorseySources.bind(this),
                            getText: function (suggestion) { return suggestion.text; },
                            fuzzyKeys: GlobalSearchModalComponent.HORSEY_FUZZY_KEYS,
                            fuzzyThreshold: GlobalSearchModalComponent.HORSEY_FUZZYSORT_THRESHOLD,
                            limit: GlobalSearchModalComponent.HORSEY_CATEGORY_LIMIT,
                            overflowIncrement: GlobalSearchModalComponent.HORSEY_CATEGORY_OVERFLOW_LIMIT_INCREMENT,
                            i18n: {
                                noMatches: no_matches_ellipsis,
                                loadingSources: loading_ellipsis,
                                more: count_more_ellipsis,
                            },
                            filterCategories: function (query, category) {
                                var categoryIndex = Object.values(GlobalSearchModalComponent.CATEGORY_KEY).findIndex(function (_key) { return query.indexOf(_key) === 0; });
                                var categoryKey = categoryIndex !== -1 && Object.keys(GlobalSearchModalComponent.CATEGORY_KEY)[categoryIndex];
                                return (_this.categoriesScope.indexOf(category.id) !== -1 && (!categoryKey || categoryKey === category.id));
                            },
                            filterSuggestions: function (query, category) {
                                var categoryKey = GlobalSearchModalComponent.CATEGORY_KEY[GlobalSearchModalComponent.getCategory(_this.query)];
                                var needle = _this.query.toLowerCase();
                                if (categoryKey && needle.indexOf(categoryKey) === 0) {
                                    needle = needle.substr(categoryKey.length);
                                }
                                needle = needle.trim();
                                var limit = category.limit || GlobalSearchModalComponent.HORSEY_CATEGORY_LIMIT;
                                if (!needle) {
                                    return [
                                        category.list.slice(0, limit).map(function (suggestion) { return ({ obj: suggestion }); }),
                                        category.list.length,
                                    ];
                                }
                                var normalizedList = (category.list || []).map(function (suggestion) {
                                    return GlobalSearchModalComponent.HORSEY_FUZZY_KEYS.reduce(function (_normalized, key) {
                                        _normalized[key] = stripAccents("" + suggestion[key]);
                                        return _normalized;
                                    }, { __original: suggestion });
                                });
                                var result = fuzzysort
                                    .go(stripAccents(needle), normalizedList, {
                                    keys: GlobalSearchModalComponent.HORSEY_FUZZY_KEYS,
                                    threshold: GlobalSearchModalComponent.HORSEY_FUZZYSORT_THRESHOLD,
                                })
                                    .map(function (line) { return line.obj && line.obj.__original; })
                                    .filter(function (res) { return !!res; });
                                return [result.slice(0, limit), result.length];
                            },
                            set: this.getHorseySet.bind(this),
                            highlightCompleteWords: false,
                            renderItem: GlobalSearchModalComponent.renderItem,
                            renderCategory: this.renderCategory.bind(this),
                            fixPosition: true,
                        });
                        if (this.query) {
                            window.setTimeout(function () { return _this.horsey.show(); }, 0);
                        }
                        return [2];
                }
            });
        });
    };
    GlobalSearchModalComponent.prototype.getHorseySources = function (data, done, partial) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, Promise.all(this.categoriesScope.map(function (category) { return __awaiter(_this, void 0, void 0, function () {
                            var _a, _b, _c, entities, _d, _e;
                            return __generator(this, function (_f) {
                                switch (_f.label) {
                                    case 0:
                                        if (this.horseySources.find(function (_source) { return _source.id === category; })) {
                                            return [2];
                                        }
                                        _a = category;
                                        switch (_a) {
                                            case AutocompleteCategory.ACTION: return [3, 1];
                                        }
                                        return [3, 3];
                                    case 1:
                                        _c = (_b = this.horseySources).push;
                                        return [4, this.GET_ACTION_CATEGORY_SOURCE()];
                                    case 2:
                                        _c.apply(_b, [_f.sent()]);
                                        partial(null, this.filteredHorseySources);
                                        return [3, 6];
                                    case 3:
                                        if (!this.autocompleteCategoryMethods[category]) {
                                            return [2];
                                        }
                                        return [4, this.autocompleteCategoryMethods[category].get.call(this)];
                                    case 4:
                                        entities = _f.sent();
                                        _e = (_d = this.horseySources).push;
                                        return [4, this.autocompleteCategoryMethods[category].map.call(this, entities)];
                                    case 5:
                                        _e.apply(_d, [_f.sent()]);
                                        this.mapEntities();
                                        partial(null, this.filteredHorseySources);
                                        return [3, 6];
                                    case 6: return [2];
                                }
                            });
                        }); }))];
                    case 1:
                        _a.sent();
                        this.mapEntities();
                        done(null, this.filteredHorseySources);
                        return [2];
                }
            });
        });
    };
    Object.defineProperty(GlobalSearchModalComponent.prototype, "filteredHorseySources", {
        get: function () {
            var _this = this;
            return this.horseySources.filter(function (source) { return _this.categoriesScope.indexOf(source.id) !== -1; });
        },
        enumerable: true,
        configurable: true
    });
    GlobalSearchModalComponent.prototype.getHorseySet = function (text, suggestion) {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.query = '';
                        _a = suggestion.category;
                        switch (_a) {
                            case AutocompleteCategory.TASKS: return [3, 1];
                            case AutocompleteCategory.PROJECT: return [3, 2];
                            case AutocompleteCategory.PROJECT_SUBMENU: return [3, 3];
                            case AutocompleteCategory.CLIENT: return [3, 4];
                            case AutocompleteCategory.CLIENT_SUBMENU: return [3, 5];
                            case AutocompleteCategory.EMPLOYEE: return [3, 6];
                            case AutocompleteCategory.WIKI_PAGE: return [3, 7];
                            case AutocompleteCategory.ACTION: return [3, 8];
                        }
                        return [3, 10];
                    case 1:
                        (function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                            return [2, this.router.navigate(['/firon'], { queryParams: { taskId: suggestion.id } })];
                        }); }); })();
                        return [3, 11];
                    case 2:
                        (function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                            return [2, this.router.navigate(["/project/" + suggestion.id])];
                        }); }); })();
                        return [3, 11];
                    case 3:
                        (function () { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                return [2, this.router.navigate(["/project/" + suggestion.parent.id + "/"], {
                                        queryParams: { tab: suggestion.value },
                                    })];
                            });
                        }); })();
                        return [3, 11];
                    case 4:
                        (function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                            return [2, this.router.navigate(["/client/" + suggestion.id])];
                        }); }); })();
                        return [3, 11];
                    case 5:
                        (function () { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                return [2, this.router.navigate(["/client/" + suggestion.parent.id + "/"], {
                                        queryParams: { tab: suggestion.value },
                                    })];
                            });
                        }); })();
                        return [3, 11];
                    case 6:
                        (function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                            return [2, this.router.navigate(["/employee/" + suggestion.id])];
                        }); }); })();
                        return [3, 11];
                    case 7:
                        (function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                            return [2, this.router.navigate(["/wiki/page/" + suggestion.value])];
                        }); }); })();
                        return [3, 11];
                    case 8: return [4, this.shortcutHandlerService.execute(suggestion.value)];
                    case 9:
                        _b.sent();
                        return [3, 11];
                    case 10: throw new Error("Unknown AutocompleteCategory: " + suggestion.category);
                    case 11:
                        this.showChange.emit((this.show = false));
                        return [2];
                }
            });
        });
    };
    GlobalSearchModalComponent.renderItem = function ($li, suggestion) {
        $li.innerHTML = "<span class=\"sey-item-text\">" + suggestion.text + "</span>";
        var avatar;
        var icon;
        var classes = '';
        var styles = '';
        var kbd;
        switch (suggestion.category) {
            case AutocompleteCategory.PROJECT:
                avatar = (suggestion.entity.tasks || []).filter(GlobalSearchModalComponent.taskFilter)
                    .length;
                break;
            case AutocompleteCategory.EMPLOYEE:
                var employee = suggestion.entity;
                avatar = employee.user.code;
                if (employee.user.color) {
                    styles += "background-color: " + employee.user.color;
                }
                break;
            case AutocompleteCategory.TASKS:
                var status_1 = suggestion.entity.status;
                var statusFilter = TASK_STATUS_FILTER[status_1];
                if (statusFilter) {
                    icon = statusFilter.icon;
                    classes = Array.isArray(statusFilter.classList)
                        ? statusFilter.classList.join(' ')
                        : statusFilter.classList;
                }
                break;
            case AutocompleteCategory.CLIENT:
                avatar = (suggestion.entity.projects || []).reduce(function (sum, project) { return sum + (project.tasks || []).filter(GlobalSearchModalComponent.taskFilter).length; }, 0);
                break;
            case AutocompleteCategory.ACTION:
                if (suggestion.shortcut && SHORTCUT_COMBO[suggestion.shortcut]) {
                    kbd = Array.isArray(SHORTCUT_COMBO[suggestion.shortcut])
                        ? SHORTCUT_COMBO[suggestion.shortcut].join('+')
                        : SHORTCUT_COMBO[suggestion.shortcut];
                }
                break;
            default:
                break;
        }
        if (avatar) {
            var classList = "avatar float-right " + classes;
            $li.innerHTML += "<figure class=\"" + classList + "\" style=\"" + styles + "\" data-initial=\"" + avatar + "\"></figure>";
        }
        else if (kbd) {
            $li.innerHTML += "<kbd class=\"float-right " + classes + "\" style=\"white-space: pre; " + styles + "\">" + kbd + "</kbd>";
        }
        else if (icon) {
            $li.innerHTML += "<clr-icon shape=\"" + icon + "\" class=\"float-right " + classes + "\" style=\"" + styles + "\"></clr-icon>";
        }
    };
    GlobalSearchModalComponent.prototype.renderCategory = function ($div, category) {
        var _this = this;
        if (category.id === 'default') {
            return;
        }
        var $categoryId = document.createElement('div');
        $categoryId.classList.add('sey-category-id');
        $div.appendChild($categoryId);
        if (!AUTOCOMPLETE_CATEGORY_TRANSLATE_KEY[category.id]) {
            $categoryId.innerText = category.id;
            return;
        }
        (function () { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = $categoryId;
                        return [4, this.translate
                                .get(AUTOCOMPLETE_CATEGORY_TRANSLATE_KEY[category.id])
                                .toPromise()];
                    case 1:
                        _a.innerText = _b.sent();
                        return [2];
                }
            });
        }); })();
    };
    GlobalSearchModalComponent.taskFilter = function (task) {
        return task.status !== TaskStatusType.DONE;
    };
    GlobalSearchModalComponent.prototype.getClients = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.authService;
                        return [4, this.authService.getRightsPromise];
                    case 1:
                        _a.rights = _b.sent();
                        if (!this.authService.rights.find(function (right) { return right.code === GlobalSearchModalComponent.CATEGORY_RIGHTS[AutocompleteCategory.CLIENT]; })) {
                            return [2, []];
                        }
                        return [2, this.httpRest
                                ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, CLIENT_SCHEMA_ROUTE, 'list')
                                .toPromise()];
                }
            });
        });
    };
    GlobalSearchModalComponent.prototype.mapClients = function (clients) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b, _c;
            var _this = this;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _a = this.authService;
                        return [4, this.authService.getRightsPromise];
                    case 1:
                        _a.rights = _d.sent();
                        _b = [{}];
                        return [4, GlobalSearchModalComponent.generateSource(AutocompleteCategory.CLIENT, clients, function (client) { return ({
                                category: AutocompleteCategory.CLIENT,
                                id: client.id,
                                value: client.obs,
                                text: client.user.name,
                                entity: client,
                            }); })];
                    case 2:
                        _b = _b.concat([(_d.sent())]);
                        _c = {};
                        return [4, Promise.all(Object.keys(CLIENT_TABS_LABELS)
                                .filter(function (tab) {
                                return _this.authService.rights.find(function (right) { return right.code === CLIENT_TABS_RIGHTS[tab]; });
                            })
                                .map(function (tab) { return __awaiter(_this, void 0, void 0, function () {
                                var _a;
                                return __generator(this, function (_b) {
                                    switch (_b.label) {
                                        case 0:
                                            _a = {
                                                category: AutocompleteCategory.CLIENT_SUBMENU,
                                                value: tab
                                            };
                                            return [4, this.translate.get(tab).toPromise()];
                                        case 1: return [2, (_a.text = _b.sent(),
                                                _a)];
                                    }
                                });
                            }); }))];
                    case 3: return [2, __assign.apply(void 0, _b.concat([(_c.submenu = _d.sent(), _c)]))];
                }
            });
        });
    };
    GlobalSearchModalComponent.prototype.getProjects = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.authService;
                        return [4, this.authService.getRightsPromise];
                    case 1:
                        _a.rights = _b.sent();
                        if (!this.authService.rights.find(function (right) { return right.code === GlobalSearchModalComponent.CATEGORY_RIGHTS[AutocompleteCategory.PROJECT]; })) {
                            return [2, []];
                        }
                        return [2, this.httpRest
                                ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, PROJECT_SCHEMA_ROUTE, 'globalSearchList')
                                .toPromise()];
                }
            });
        });
    };
    GlobalSearchModalComponent.prototype.mapProjects = function (projects) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = [{}];
                        return [4, GlobalSearchModalComponent.generateSource(AutocompleteCategory.PROJECT, projects, function (project) { return ({
                                category: AutocompleteCategory.PROJECT,
                                id: project.id,
                                value: project.code,
                                text: project.obs,
                                entity: project,
                            }); })];
                    case 1:
                        _a = _a.concat([(_c.sent())]);
                        _b = {};
                        return [4, Promise.all(Object.keys(PROJECT_TABS_LABELS)
                                .filter(function (tab) {
                                return _this.authService.rights.find(function (right) { return right.code === PROJECT_TABS_RIGHTS[tab]; });
                            })
                                .map(function (tab) { return __awaiter(_this, void 0, void 0, function () {
                                var _a;
                                return __generator(this, function (_b) {
                                    switch (_b.label) {
                                        case 0:
                                            _a = {
                                                category: AutocompleteCategory.PROJECT_SUBMENU,
                                                value: tab
                                            };
                                            return [4, this.translate.get(tab).toPromise()];
                                        case 1: return [2, (_a.text = _b.sent(),
                                                _a)];
                                    }
                                });
                            }); }))];
                    case 2: return [2, __assign.apply(void 0, _a.concat([(_b.submenu = _c.sent(), _b)]))];
                }
            });
        });
    };
    GlobalSearchModalComponent.prototype.getEmployees = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.authService;
                        return [4, this.authService.getRightsPromise];
                    case 1:
                        _a.rights = _b.sent();
                        if (!this.authService.rights.find(function (right) { return right.code === GlobalSearchModalComponent.CATEGORY_RIGHTS[AutocompleteCategory.EMPLOYEE]; })) {
                            return [2, []];
                        }
                        return [2, this.httpRest
                                ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, EMPLOYEE_SCHEMA_ROUTE, 'globalSearch')
                                .toPromise()];
                }
            });
        });
    };
    GlobalSearchModalComponent.mapEmployees = function (employees) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, GlobalSearchModalComponent.generateSource(AutocompleteCategory.EMPLOYEE, employees, function (employee) { return ({
                        category: AutocompleteCategory.EMPLOYEE,
                        id: employee.id,
                        value: employee.user.code,
                        text: employee.user.name,
                        entity: employee,
                    }); })];
            });
        });
    };
    GlobalSearchModalComponent.prototype.getTasks = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.authService;
                        return [4, this.authService.getRightsPromise];
                    case 1:
                        _a.rights = _b.sent();
                        if (!this.authService.rights.find(function (right) { return right.code === GlobalSearchModalComponent.CATEGORY_RIGHTS[AutocompleteCategory.TASKS]; })) {
                            return [2, []];
                        }
                        return [2, this.httpRest
                                ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, TASK_SCHEMA_ROUTE, 'global')
                                .toPromise()];
                }
            });
        });
    };
    GlobalSearchModalComponent.mapTasks = function (tasks) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, GlobalSearchModalComponent.generateSource(AutocompleteCategory.TASKS, tasks, function (task) { return ({
                        category: AutocompleteCategory.TASKS,
                        id: task.id,
                        value: (task.project && task.project.code) + "-" + task.id,
                        text: task.name,
                        entity: task,
                    }); })];
            });
        });
    };
    GlobalSearchModalComponent.prototype.getWikiPages = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.authService;
                        return [4, this.authService.getRightsPromise];
                    case 1:
                        _a.rights = _b.sent();
                        if (!this.authService.rights.find(function (right) { return right.code === GlobalSearchModalComponent.CATEGORY_RIGHTS[AutocompleteCategory.WIKI_PAGE]; })) {
                            return [2, []];
                        }
                        return [2, this.httpRest
                                ._request(HTTP_METHOD.POST, ApiRoutePlurality.PLURAL, WIKI_PAGE_SCHEMA_ROUTE, 'search')
                                .toPromise()];
                }
            });
        });
    };
    GlobalSearchModalComponent.mapWikiPages = function (wikiPages) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, GlobalSearchModalComponent.generateSource(AutocompleteCategory.WIKI_PAGE, wikiPages, function (wikiPage) { return ({
                        category: AutocompleteCategory.WIKI_PAGE,
                        id: wikiPage.id,
                        value: wikiPage.hash,
                        text: wikiPage.title,
                        entity: wikiPage,
                    }); })];
            });
        });
    };
    GlobalSearchModalComponent.generateSource = function (category, entities, mapFn) {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = {
                            id: category,
                            limit: GlobalSearchModalComponent.HORSEY_CATEGORY_LIMIT
                        };
                        return [4, Promise.all(entities.map(mapFn))];
                    case 1: return [2, (_a.list = (_b.sent()).filter(function (suggestion) { return !!suggestion; }),
                            _a)];
                }
            });
        });
    };
    Object.defineProperty(GlobalSearchModalComponent.prototype, "searchScope", {
        get: function () {
            return this._searchScope;
        },
        set: function (searchScope) {
            var _this = this;
            this._searchScope = searchScope;
            this.title = searchScope + "_search";
            (function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                return [2, this.initHorsey()];
            }); }); })();
        },
        enumerable: true,
        configurable: true
    });
    GlobalSearchModalComponent.prototype.mapEntities = function () {
        var taskSource = this.horseySources.find(function (_source) { return _source.id === AutocompleteCategory.TASKS; });
        var projectSource = this.horseySources.find(function (_source) { return _source.id === AutocompleteCategory.PROJECT; });
        var clientSource = this.horseySources.find(function (_source) { return _source.id === AutocompleteCategory.CLIENT; });
        if (taskSource && projectSource) {
            var tasks_1 = taskSource.list.map(function (task) { return task.entity; });
            var projects_1 = projectSource.list.map(function (project) { return project.entity; });
            tasks_1.map(function (task) { return (task.project = projects_1.find(function (project) { return project.id === task.projectId; })); });
            projects_1.map(function (project) { return (project.tasks = tasks_1.filter(function (task) { return task.projectId === project.id; })); });
        }
        if (projectSource && clientSource) {
            var projects_2 = projectSource.list.map(function (project) { return project.entity; });
            var clients_1 = clientSource.list.map(function (client) { return client.entity; });
            projects_2.map(function (project) { return (project.client = clients_1.find(function (client) { return client.id === project.clientId; })); });
            clients_1.map(function (client) { return (client.projects = projects_2.filter(function (project) { return project.clientId === client.id; })); });
        }
    };
    GlobalSearchModalComponent.getCategory = function (query) {
        var categoryIndex = Object.values(GlobalSearchModalComponent.CATEGORY_KEY).findIndex(function (_key) { return query.indexOf(_key) === 0; });
        return (categoryIndex !== -1 &&
            Object.keys(GlobalSearchModalComponent.CATEGORY_KEY)[categoryIndex]);
    };
    Object.defineProperty(GlobalSearchModalComponent.prototype, "categoriesScope", {
        get: function () {
            var category = GlobalSearchModalComponent.getCategory(this.query);
            if (category) {
                return [category];
            }
            return this.searchScope !== AutocompleteCategory.GLOBAL
                ? [this.searchScope]
                : Object.values(AutocompleteCategory).filter(function (_category) { return _category !== AutocompleteCategory.GLOBAL; });
        },
        enumerable: true,
        configurable: true
    });
    GlobalSearchModalComponent.HORSEY_CATEGORY_LIMIT = 5;
    GlobalSearchModalComponent.HORSEY_CATEGORY_OVERFLOW_LIMIT_INCREMENT = 10;
    GlobalSearchModalComponent.HORSEY_FUZZYSORT_THRESHOLD = -Infinity;
    GlobalSearchModalComponent.HORSEY_FUZZY_KEYS = ['value', 'text'];
    GlobalSearchModalComponent.CATEGORY_KEY = (_b = {},
        _b[AutocompleteCategory.ACTION] = '!',
        _b[AutocompleteCategory.PROJECT] = '#',
        _b[AutocompleteCategory.CLIENT] = '~',
        _b[AutocompleteCategory.EMPLOYEE] = '@',
        _b[AutocompleteCategory.WIKI_PAGE] = '?',
        _b[AutocompleteCategory.TASKS] = "'",
        _b);
    GlobalSearchModalComponent.CATEGORY_RIGHTS = (_c = {},
        _c[AutocompleteCategory.PROJECT] = NAVIGATE_RIGHTS.NAVIGATE_PROJECT,
        _c[AutocompleteCategory.CLIENT] = NAVIGATE_RIGHTS.NAVIGATE_CLIENT,
        _c[AutocompleteCategory.EMPLOYEE] = NAVIGATE_RIGHTS.NAVIGATE_EMPLOYEE,
        _c[AutocompleteCategory.WIKI_PAGE] = NAVIGATE_RIGHTS.NAVIGATE_WIKI,
        _c[AutocompleteCategory.TASKS] = NAVIGATE_RIGHTS.NAVIGATE_FIRON,
        _c);
    return GlobalSearchModalComponent;
}(ModalComponent));
export { GlobalSearchModalComponent };
