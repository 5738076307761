import moment from 'moment';
import {ISchemaRoute, MilestonesType, ReleaseStateType} from '../../schema-static';
import {IClient} from './Clients';
import {IEmployee} from './Employees';
import {IMeetingParticipant} from './MeetingParticipants';
import {IProject} from './Projects';
import {ITask} from './Tasks';

export enum MILESTONE_FIELD {
    id = 'id',
    target = 'target',
    beginDate = 'beginDate',
    endDate = 'endDate',
    type = 'type',
    obs = 'obs',
    clientId = 'clientId',
    projectId = 'projectId',
    employeeId = 'employeeId',
    client = 'client',
    project = 'project',
    description = 'description',
    version = 'version',
    releaseState = 'releaseState',
    tasks = 'tasks',
    employees = 'employees',
    createdAt = 'createdAt',
    updatedAt = 'updatedAt',
}

export interface IMilestoneBare {
    id?: number;
    createdAt?: moment.Moment;
    updatedAt?: moment.Moment;
    target?: moment.Moment;
    beginDate: moment.Moment;
    endDate?: moment.Moment;
    type: MilestonesType;
    obs: string;
    clientId?: number;
    projectId?: number;
    description?: string;
    releaseState?: ReleaseStateType;
    version?: string;
    employeeId: number;
    participants?: IMeetingParticipant[];
}

export interface IMilestoneBelong {
    client?: Partial<IClient>;
    project?: Partial<IProject>;
    employee?: Partial<IEmployee>;
}

export interface IMilestoneMany {
    tasks: Partial<ITask>[];
    employees: Partial<IEmployee>[];
}

export interface IMilestone extends IMilestoneBare, IMilestoneBelong, IMilestoneMany {}

const MILESTONE_SCHEMA_ROUTE: ISchemaRoute = {
    tableName: 'milestones',
    singularRoute: 'milestone',
    pluralRoute: 'milestones',
};

export {MILESTONE_SCHEMA_ROUTE};
