import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {CognitoUserSession} from 'amazon-cognito-identity-js';
import {UNAUTHORIZED} from 'http-status-codes';
// tslint:disable-next-line import-blacklist
import {from, Observable} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {ApiHttpError} from '../../../../defs/api-error';
import {MomentService} from '../shared/moment/moment.service';
import {TOAST_TYPE, ToastService} from '../shared/toast/toast.service';
import {AuthService} from './auth.service';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private refreshSessionPromise: Promise<CognitoUserSession>;

    public constructor(
        private readonly authService: AuthService,
        private readonly translate: TranslateService,
        private readonly toastService: ToastService,
        private readonly momentService: MomentService
    ) {}

    public intercept(
        request: HttpRequest<any>,
        next: HttpHandler,
        force = false,
        retries = 1
    ): Observable<HttpEvent<any>> {
        return this.refreshBearer(request, next, force).pipe(
            catchError((err: Error, caught) => {
                if (
                    err instanceof HttpErrorResponse &&
                    err.status === UNAUTHORIZED &&
                    err.error === ApiHttpError.ForceLogoutError
                ) {
                    (async () => {
                        this.toastService.show({
                            type: TOAST_TYPE.INFO,
                            text: 'force_logout_error',
                            appLevel: true,
                        });
                    })();

                    this.authService.signOut();

                    return undefined;
                }

                if (
                    retries < AuthInterceptor.MAX_REFRESH_RETRIES &&
                    err instanceof HttpErrorResponse &&
                    err.status === UNAUTHORIZED &&
                    (!err.error || err.error.name !== 'InsufficientAuthorization')
                ) {
                    if (this.authService.accessToken) {
                        // tslint:disable-next-line: no-console
                        console.error(
                            `was supposed to expire ${this.momentService
                                .moment(this.authService.accessToken.getExpiration() * 1000)
                                .format()}`
                        );
                    }

                    return this.intercept(request.clone(), next, true, retries + 1);
                }

                throw err;

                // @ts-ignore forces correct typing
                return next.handle(this.setAuthorizationHeader(request));
            })
        );
    }

    private setAuthorizationHeader(request: HttpRequest<any>): HttpRequest<any> {
        if (!this.authService.accessJWTToken) {
            return request;
        }

        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${this.authService.accessJWTToken}`,
            },
        });
    }

    private refreshBearer(request: HttpRequest<any>, next: HttpHandler, force = false): Observable<HttpEvent<any>> {
        return from(
            (async () => {
                if (!force && this.authService.isSessionValid) {
                    return next.handle(this.setAuthorizationHeader(request));
                }

                if (!this.refreshSessionPromise) {
                    this.refreshSessionPromise = this.authService.refreshSession(force);
                }

                await this.refreshSessionPromise;
                this.refreshSessionPromise = null;
            })()
        ).pipe(switchMap(() => next.handle(this.setAuthorizationHeader(request))));
    }

    private static readonly MAX_REFRESH_RETRIES = 3;
}
