import * as i0 from "@angular/core";
import * as i1 from "../../projects/list/projects-list.component.ngfactory";
import * as i2 from "../../projects/list/projects-list.component";
import * as i3 from "../../shared/http-rest/http-rest.service";
import * as i4 from "../../shared/shortcut-handler/shortcut-handler.service";
import * as i5 from "../../auth/auth.service";
import * as i6 from "@ngx-translate/core";
import * as i7 from "../../shared/toast/toast.service";
import * as i8 from "./employee-wizard-projects.component";
var styles_EmployeeWizardProjectsComponent = [];
var RenderType_EmployeeWizardProjectsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EmployeeWizardProjectsComponent, data: {} });
export { RenderType_EmployeeWizardProjectsComponent as RenderType_EmployeeWizardProjectsComponent };
export function View_EmployeeWizardProjectsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-projects-list", [], null, [[null, "selectedProjectsChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedProjectsChange" === en)) {
        var pd_0 = ((_co.selectedProjects = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ProjectsListComponent_0, i1.RenderType_ProjectsListComponent)), i0.ɵdid(1, 770048, null, 0, i2.ProjectsListComponent, [i3.HttpRestService, i4.ShortcutHandlerService, i5.AuthService, i6.TranslateService, i7.ToastService], { projects: [0, "projects"], columns: [1, "columns"], layout: [2, "layout"], selectedProjects: [3, "selectedProjects"], clrDgRowSelection: [4, "clrDgRowSelection"] }, { selectedProjectsChange: "selectedProjectsChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.projects; var currVal_1 = _co.columns; var currVal_2 = _co.PROJECT_LIST_LAYOUT.SELECT; var currVal_3 = _co.selectedProjects; var currVal_4 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_EmployeeWizardProjectsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-employee-wizard-projects", [], null, null, null, View_EmployeeWizardProjectsComponent_0, RenderType_EmployeeWizardProjectsComponent)), i0.ɵdid(1, 114688, null, 0, i8.EmployeeWizardProjectsComponent, [i3.HttpRestService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EmployeeWizardProjectsComponentNgFactory = i0.ɵccf("app-employee-wizard-projects", i8.EmployeeWizardProjectsComponent, View_EmployeeWizardProjectsComponent_Host_0, { projects: "projects", employee: "employee" }, {}, []);
export { EmployeeWizardProjectsComponentNgFactory as EmployeeWizardProjectsComponentNgFactory };
