import {Injectable} from '@angular/core';
import {MomentService} from '../moment/moment.service';
import {Timer} from './timer.class';

@Injectable({
    providedIn: 'root',
})
export class TimerService {
    public timer: Timer;

    public constructor(private readonly momentService: MomentService) {
        this.timer = new Timer(this.momentService);
    }
}
