import moment from 'moment';
import {ISchemaRoute} from '../../schema-static';
import {IEmployee} from './Employees';
import {IMilestone} from './Milestones';
import {ITask} from './Tasks';

export enum NOTIFICATION_TYPE {
    EVENT_REMINDER = 'EVENT_REMINDER',
    TASK_ASSIGNED = 'TASK_ASSIGNED',
}

export enum NOTIFICATION_FIELD {
    id = 'id',
    createdAt = 'createdAt',
    updatedAt = 'updatedAt',
    employeeId = 'employeeId',
    type = 'type',
    milestoneId = 'milestoneId',
    taskId = 'taskId',
    seen = 'seen',
    closed = 'closed',
}

export interface INotificationBare {
    id?: number;
    createdAt?: moment.Moment;
    updatedAt?: moment.Moment;
    employeeId: number;
    type: NOTIFICATION_TYPE;
    milestoneId?: number;
    taskId?: number;
    seen: boolean;
    closed: boolean;
}

export interface INotificationBelong {
    employee: Partial<IEmployee>;
    milestone?: Partial<IMilestone>;
    task?: Partial<ITask>;
}

export interface INotification extends INotificationBare, INotificationBelong {}

const NOTIFICATION_SCHEMA_ROUTE: ISchemaRoute = {
    tableName: 'notifications',
    singularRoute: 'notification',
    pluralRoute: 'notifications',
};

export {NOTIFICATION_SCHEMA_ROUTE};
