import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {Validators} from '@angular/forms';
import {ClrForm} from '@clr/angular';
import {NgSelectComponent} from '@ng-select/ng-select';
import {FormControl, FormGroup} from 'ngx-strongly-typed-forms';
import {MilestonesType, RIGHTS} from '../../../../../defs/schema-static';
import {IClient} from '../../../../../defs/schema/public/Clients';
import {IProject} from '../../../../../defs/schema/public/Projects';
import {AuthService} from '../../auth/auth.service';
import {IMilestoneFormValues, MilestoneTarget} from '../../forms/add-milestone.service';

interface IMSTarget {
    targetType: MilestoneTarget;
    project: number;
    client: number;
}

@Component({
    selector: 'app-milestone-target',
    templateUrl: './milestone-target.component.html',
    styleUrls: ['./milestone-target.component.scss'],
})
export class MilestoneTargetComponent implements OnChanges {
    public readonly form = new FormGroup<IMSTarget>({
        targetType: new FormControl<MilestoneTarget>(null, Validators.required),
        project: new FormControl<number>(null, Validators.required),
        client: new FormControl<number>(null, Validators.required),
    });

    @ViewChild(ClrForm) public clrForm: ClrForm;
    @ViewChild('ngSelectClient') public ngSelectClient: NgSelectComponent;
    @ViewChild('ngSelectProject') public ngSelectProject: NgSelectComponent;

    @Output() public switchType = new EventEmitter();

    @Input() public clients: Partial<IClient>[] = [];
    @Input() public projects: Partial<IProject>[] = [];

    public projectsFiltered: Partial<IProject>[] = [];

    public projectOnly = false;

    public constructor(private readonly authService: AuthService) {}

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.projects) {
            this.filterSelect({type: this.form.value.targetType} as any);
        }
    }

    public onLoad(params: IMilestoneFormValues) {
        if (params) {
            this.projectOnly = params.type === MilestonesType.DEADLINE || params.type === MilestonesType.RELEASE;

            if (!this.form.value.targetType && params.targetType) {
                // loading first time
                this.form.patchValue({
                    targetType: params.targetType,
                    project: params.project,
                    client: params.client,
                });
            }

            if (this.projectOnly) {
                this.form.patchValue({
                    targetType: MilestoneTarget.PROJECT,
                });
                if (this.form.value.client) {
                    this.form.patchValue({
                        client: null,
                    });
                }
            }
            this.toggle();
            this.filterSelect(params);
        }
    }

    public filterSelect(params: Partial<IMilestoneFormValues>) {
        const managerOf: number[] = this.authService.getProjectsManagerOf();
        this.projectsFiltered = this.projects.filter(
            (p) =>
                managerOf.includes(p.id) ||
                this.authService.hasRight(RIGHTS.PROJECT_ADMIN_ALL) ||
                (this.authService.hasRight(RIGHTS.MS_RELEASE) &&
                    (params.type === MilestonesType.DEADLINE || params.type === MilestonesType.RELEASE)) ||
                (this.authService.hasRight(RIGHTS.MS_UPDATE) &&
                    (params.type === MilestonesType.MEETING || params.type === MilestonesType.MEETING_NO_NOTE)) ||
                params.type === MilestonesType.CALL ||
                params.type === MilestonesType.REMINDER
        );
    }

    public filterProject(id?: number) {
        this.form.patchValue({
            project: id || null,
            client: null,
        });
        if (id) {
            this.switchType.emit();
        }
    }

    public filterClient(id?: number) {
        this.form.patchValue({
            client: id || null,
            project: null,
        });
        if (id) {
            this.switchType.emit();
        }
    }

    public submit() {
        if (!this.form.valid) {
            return undefined;
        }

        return this.form;
    }

    public setTargetType(targetType: MilestoneTarget) {
        this.form.patchValue({targetType, client: null, project: null});
        this.toggle();
    }

    private toggle() {
        if (this.form.value.targetType === MilestoneTarget.PROJECT) {
            this.form.controls.project.enable();
            this.form.controls.client.disable();
        } else {
            this.form.controls.project.disable();
            this.form.controls.client.enable();
        }
        this.setFocus();
    }

    private setFocus() {
        requestAnimationFrame(() => {
            if (this.form.value.targetType === MilestoneTarget.CLIENT) {
                if (this.ngSelectClient && !this.form.value.client) {
                    this.ngSelectClient.focus();
                    this.ngSelectClient.open();
                }
            } else {
                if (this.ngSelectProject && !this.form.value.project) {
                    this.ngSelectProject.focus();
                    this.ngSelectProject.open();
                }
            }
        });
    }

    public readonly MILESTONE_TARGET = MilestoneTarget;
}
