import moment from 'moment';
import {ISchemaRoute} from '../../schema-static';
import {IClient} from './Clients';
import {IProject} from './Projects';

export enum STICKY_NOTE_FIELD {
    id = 'id',
    clientId = 'clientId',
    projectId = 'projectId',
    title = 'title',
    text = 'text',
    color = 'color',
    createdAt = 'createdAt',
}

export interface IStickyNoteBare {
    id?: number;
    createdAt?: moment.Moment;
    updatedAt?: moment.Moment;
    title?: string;
    text?: string;
    color: string;
    clientId?: number;
    projectId?: number;
}

export interface IStickyNoteBelong {
    client?: Partial<IClient>;
    project?: Partial<IProject>;
}

export interface IStickyNote extends IStickyNoteBare, IStickyNoteBelong {}

const STICKY_NOTE_SCHEMA_ROUTE: ISchemaRoute = {
    tableName: 'stickynote',
    singularRoute: 'stickynote',
    pluralRoute: 'stickynotes',
};

export {STICKY_NOTE_SCHEMA_ROUTE};
