<div class="column-container py-02">
    <div class="clr-row">
        <div class="clr-col-12"><h1 class="mt-0" translate>title_clients</h1></div>
    </div>

    <div class="clr-row flex-1">
        <div class="column-container clr-col-12">
            <clr-datagrid
                appDatagridToggle
                class="flex-1-set"
                [(clrDgSelected)]="selectedClients"
                [clrDgLoading]="!clients"
            >
                <clr-dg-action-bar>
                    <div class="btn-group">
                        <button
                            [title]="('shortcut_' | translate) + ' C'"
                            class="btn btn-primary"
                            (click)="showCreateModal = true"
                            *sharedAccessControl="RIGHTS.CLIENT_CREATE"
                        >
                            {{ 'add_client' | translate }}
                        </button>
                    </div>

                    <div class="btn-group">
                        <button
                            class="btn btn-danger"
                            (click)="showDeleteModal = true"
                            *sharedAccessControl="RIGHTS.CLIENT_DELETE"
                            [disabled]="!selectedClients.length"
                        >
                            {{ (selectedClients.length > 1 ? 'delete_clients' : 'delete_client') | translate }}
                        </button>
                    </div>
                </clr-dg-action-bar>

                <clr-dg-column
                    *ngFor="let column of CLIENTS_COLUMNS"
                    [clrDgField]="column.field"
                    [clrDgSortOrder]="column.order"
                    [style.width]="column.width"
                    [clrDgSortBy]="column.sorter"
                >
                    {{ column.translateKey | translate }}
                    <clr-dg-filter
                        [clrDgFilter]="fuzzyFilter"
                        [(clrDgFilterOpen)]="filterOpen[column.field]"
                        *ngIf="column.field !== 'contacts' && column.field !== 'projects'"
                    >
                        <shared-fuzzy-datagrid-filter
                            [filterKey]="column.field"
                            [propertyGetter]="column.propertyGetter"
                            [filterOpen]="filterOpen[column.field]"
                            #fuzzyFilter
                        ></shared-fuzzy-datagrid-filter>
                    </clr-dg-filter>
                    <clr-dg-filter
                        [clrDgFilter]="selectFilter"
                        [(clrDgFilterOpen)]="filterOpen[column.field]"
                        *ngIf="column.field === 'contacts'"
                    >
                        <shared-select-datagrid-filter
                            [emps]="employees"
                            [filterKey]="column.field"
                            [type]="SelectFilterType.CONTACT"
                            [propertyGetter]="column.propertyGetter"
                            [filterOpen]="filterOpen[column.field]"
                            #selectFilter
                        ></shared-select-datagrid-filter>
                    </clr-dg-filter>
                    <clr-dg-filter
                        [clrDgFilter]="selectFilter"
                        [(clrDgFilterOpen)]="filterOpen[column.field]"
                        *ngIf="column.field === 'projects'"
                    >
                        <shared-select-datagrid-filter
                            [projects]="projects"
                            [type]="SelectFilterType.PROJECT"
                            [filterKey]="column.field"
                            [propertyGetter]="column.propertyGetter"
                            [filterOpen]="filterOpen[column.field]"
                            #selectFilter
                        ></shared-select-datagrid-filter>
                    </clr-dg-filter>
                </clr-dg-column>

                <clr-dg-row *clrDgItems="let client of clients" [clrDgItem]="client">
                    <clr-dg-cell>
                        <a [routerLink]="['/client', client.id]"> {{ client.user?.name }} </a>
                        <app-long-avatar
                            [code]="client.user?.code"
                            [bgColor]="client.user?.color"
                            [type]="LONG_AVATAR_TYPE.BADGE_100"
                        ></app-long-avatar>
                    </clr-dg-cell>
                    <clr-dg-cell class="text-ellipsis" title="{{ client.obs }}"> {{ client.obs }}</clr-dg-cell>

                    <clr-dg-cell>
                        <ng-container *ngIf="client.contacts && client.contacts.length > 1; else contactList">
                            {{ 'customer_contacts_count' | translate: {count: client.contacts.length} }}
                            <clr-signpost>
                                <clr-signpost-content [clrPosition]="'bottom-left'" *clrIfOpen>
                                    <h3 class="mt-0" translate>customer_contacts</h3>
                                    <ul class="list mt-05">
                                        <ng-container *ngIf="false; else contactList"></ng-container>
                                    </ul>
                                </clr-signpost-content>
                            </clr-signpost>
                        </ng-container>

                        <ng-template #contactList>
                            <li *ngFor="let contact of client.contacts">
                                <ng-container *ngIf="contact && contact.user">
                                    <a [routerLink]="['/employee', contact.id]">{{ contact.user.name }}</a>
                                    <app-long-avatar
                                        class="ml-02"
                                        [type]="LONG_AVATAR_TYPE.BADGE_100"
                                        [code]="contact.user.code"
                                        [bgColor]="contact.user.color"
                                    ></app-long-avatar>
                                </ng-container>
                            </li>
                        </ng-template>
                    </clr-dg-cell>

                    <clr-dg-cell>
                        <ng-container *ngIf="client.projects && client.projects.length > 1; else projectList">
                            {{ 'projects_count' | translate: {count: client.projects.length} }}
                            <clr-signpost>
                                <clr-signpost-content [clrPosition]="'bottom-left'" *clrIfOpen>
                                    <h3 class="mt-0" translate>projects</h3>
                                    <ul class="list mt-05">
                                        <ng-container *ngIf="false; else projectList"></ng-container>
                                    </ul>
                                </clr-signpost-content>
                            </clr-signpost>
                        </ng-container>

                        <ng-template #projectList>
                            <li *ngFor="let project of client.projects">
                                <ng-container *ngIf="project">
                                    <a [routerLink]="['/project', project.id]">{{ project.obs }}</a>
                                    <app-long-avatar
                                        class="ml-02"
                                        [type]="LONG_AVATAR_TYPE.BADGE_100"
                                        [code]="project.code"
                                        [bgColor]="project.color"
                                    ></app-long-avatar>
                                </ng-container>
                            </li>
                        </ng-template>
                    </clr-dg-cell>
                </clr-dg-row>

                <clr-dg-footer>
                    <span *ngIf="clients">{{ 'count_clients' | translate: {count: clients.length} }}</span>
                </clr-dg-footer>
            </clr-datagrid>
        </div>
    </div>
</div>

<app-modal [(show)]="showDeleteModal" [title]="'client_delete_modal_title' | translate">
    <ng-template appModalBody>
        <p>
            {{
                'swal_delete_attch'
                    | translate
                        : {
                              what:
                                  selectedClients.length > 1
                                      ? ('count_clients' | translate: {count: selectedClients.length})
                                      : selectedClients[0].user?.name
                          }
            }}
        </p>
    </ng-template>

    <ng-template appModalFooter>
        <button
            class="btn btn-secondary"
            (click)="showDeleteModal = false"
            [sharedAutofocus]="showDeleteModal"
            translate
        >
            form_cancel
        </button>
        <button class="btn btn-danger" (click)="deleteSelectedClients()">
            {{ (selectedClients.length > 1 ? 'delete_clients' : 'delete_client') | translate }}
        </button>
    </ng-template>
</app-modal>

<app-client-wizard
    [(clrWizardOpen)]="showCreateModal"
    *sharedAccessControl="RIGHTS.CLIENT_CREATE"
    (finish)="addNewClient($event)"
></app-client-wizard>
