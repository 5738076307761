import {EventEmitter, Injectable} from '@angular/core';
import {Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {FormControl, FormGroup} from 'ngx-strongly-typed-forms';
import {forkJoin, Observable} from 'rxjs';
import {getWorkingHours} from '../../../../../defs/businessRules';
import {AssignedEmployeeType, DECIMAL_RADIX, TaskStatusType, TaskType} from '../../../../../defs/schema-static';
import {ASSIGNED_TASK_SCHEMA_ROUTE, IAssignedTask} from '../../../../../defs/schema/public/AssignedTasks';
import {IEmployee} from '../../../../../defs/schema/public/Employees';
import {ITag, TAG_SCHEMA_ROUTE} from '../../../../../defs/schema/public/Tags';
import {ITaskBlocker, TASK_BLOCKER_SCHEMA_ROUTE} from '../../../../../defs/schema/public/TaskBlockers';
import {ITaskComments, TASK_COMMENT_SCHEMA_ROUTE} from '../../../../../defs/schema/public/TaskComments';
import {ITask, TASK_SCHEMA_ROUTE} from '../../../../../defs/schema/public/Tasks';
import {ITaskTag, TASK_TAG_SCHEMA_ROUTE} from '../../../../../defs/schema/public/TaskTags';
import {noop} from '../../app-static';
import {AuthService} from '../../auth/auth.service';
import {ConfigService} from '../../shared/config/config.service';
import {HttpRestService, IDeleteCount} from '../../shared/http-rest/http-rest.service';
import {MomentService} from '../../shared/moment/moment.service';
import {TOAST_TYPE, ToastService} from '../../shared/toast/toast.service';
import {validate} from '../validators/form.validator';
import {requiredTrimValidator} from '../validators/required-trim.validator';

export enum AddTaskFormField {
    tracked = 'tracked',
    startDate = 'startDate',
    endDate = 'endDate',
    parentTaskId = 'parentTaskId',
    releaseId = 'releaseId',
    project = 'project',
    parentTask = 'parentTask',
    release = 'release',
    recursive = 'recursive',
    name = 'name',
    comment = 'comment',
    bug = 'bug',
    time = 'time',
    urgent = 'urgent',
    assigned = 'assigned',
}

export enum EditTaskFormField {
    tracked = 'tracked',
    startDate = 'startDate',
    endDate = 'endDate',
    parentTaskId = 'parentTaskId',
    releaseId = 'releaseId',
    project = 'project',
    parentTask = 'parentTask',
    release = 'release',
    recursive = 'recursive',
    name = 'name',
    comment = 'comment',
    blockers = 'blockers',
    bug = 'bug',
    estimated = 'estimated',
    progress = 'progress',
    urgent = 'urgent',
    assigned = 'assigned',
}

export interface IAddTaskFormModal {
    tracked: boolean;
    startDate: string;
    endDate: string;
    parentTaskId: number;
    releaseId: number;
    project: number;
    parentTask: boolean;
    release: boolean;
    recursive: boolean;
    name: string;
    bug: boolean;
    time: string;
    blockers: number[];
    tags: number[];
    urgent: boolean;
    comment?: string;
    assigned?: IEmployee[];
}

export interface IEditTaskFormModal {
    tracked: boolean;
    startDate: string;
    endDate: string;
    parentTaskId: ITask;
    releaseId: number;
    progress: number;
    parentTask: boolean;
    release: boolean;
    name: string;
    project: number;
    bug: boolean;
    estimated: string;
    blockers: any[];
    tags: Partial<ITaskTag>[];
    urgent: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class FormsAddTaskService {
    public pendingTags: ITag[];
    public selectedTags: any[];

    public onSubmit: EventEmitter<ITask> = new EventEmitter();

    public constructor(
        private readonly httpRest: HttpRestService,
        private readonly toastService: ToastService,
        private readonly translate: TranslateService,
        private readonly authService: AuthService,
        private readonly configService: ConfigService,
        private readonly momentService: MomentService
    ) {}

    public static getFormGroup(): FormGroup<IAddTaskFormModal> {
        return new FormGroup<IAddTaskFormModal>({
            project: new FormControl<number>(null, Validators.required),
            parentTask: new FormControl<boolean>(),
            parentTaskId: new FormControl<number>({value: null, disabled: false}),
            release: new FormControl<boolean>(),
            releaseId: new FormControl<number>({value: null, disabled: false}),
            name: new FormControl<string>(null, requiredTrimValidator()),
            bug: new FormControl<boolean>(),
            tracked: new FormControl<boolean>(),
            time: new FormControl<string>(),
            startDate: new FormControl<string>(null, Validators.required),
            endDate: new FormControl<string>(null, Validators.required),
            recursive: new FormControl<boolean>(),
            blockers: new FormControl<number[]>(),
            tags: new FormControl<number[]>(),
            urgent: new FormControl<boolean>(),
            comment: new FormControl<string>(),
            assigned: new FormControl(),
        });
    }

    public static getEditFormGroup(): FormGroup<IEditTaskFormModal> {
        return new FormGroup<IEditTaskFormModal>({
            parentTask: new FormControl<boolean>(),
            parentTaskId: new FormControl<ITask>({value: null, disabled: false}),
            release: new FormControl<boolean>(),
            releaseId: new FormControl<number>({value: null, disabled: false}),
            progress: new FormControl<number>(),
            name: new FormControl<string>(null, requiredTrimValidator()),
            bug: new FormControl<boolean>(),
            tracked: new FormControl<boolean>(),
            estimated: new FormControl<string>(),
            startDate: new FormControl<string>(null, Validators.required),
            endDate: new FormControl<string>(null, Validators.required),
            project: new FormControl<number>(null, Validators.required),
            blockers: new FormControl<number[]>(),
            tags: new FormControl<any[]>(),
            urgent: new FormControl<boolean>(),
        });
    }

    public async submit(f: FormGroup<IAddTaskFormModal>, callback: (task: ITask) => void = noop) {
        // tslint:disable-next-line

        const assigned = [...f.value.assigned];

        if (this.selectedTags && this.selectedTags.length > 0) {
            f.patchValue({
                tags: this.selectedTags,
            });
        }

        if (!validate(f)) {
            return false;
        }

        const employeeId = this.authService.user.employee.id;
        const {
            recursive,
            blockers,
            time,
            tracked,
            startDate,
            endDate,
            urgent,
            bug,
            release,
            parentTask,
            project,
            releaseId,
            parentTaskId,
        } = f.value;

        let {tags} = f.value;

        const name = f.value.name.trim();
        let comment = f.value.comment;
        if (comment) {
            comment = comment.trim();
        }

        if (!name) {
            this.toastService.show({text: 'error_noname_tasks'});

            return false;
        }

        const status = tracked
            ? assigned.length > 0
                ? TaskStatusType.TODO
                : TaskStatusType.BACKLOG
            : TaskStatusType.DONE;

        const _task = {
            beginDate: startDate ? this.momentService.moment(startDate) : undefined,
            endDate: endDate ? this.momentService.moment(endDate) : undefined,
            name,
            type: TaskType[bug ? TaskType.BUG : TaskType.TASK],
            urgent,
            tracked,
            status,
            progress: tracked ? 0 : 100,
            estimatedTime: time,
            projectId: project,
            parentId: parentTaskId || null,
            targetReleaseId: releaseId || null,
            authorId: employeeId,
        };

        if (_task.beginDate && _task.endDate && _task.endDate.isBefore(_task.beginDate)) {
            f.controls.endDate.setErrors({endDateBeforeBeginDate: ''});

            this.toastService.show({text: 'error_insert_tasks'});

            return false;
        }
        const workingHours = getWorkingHours(this.configService.config);
        const startTime = workingHours.start.split(':').map((e) => parseInt(e, DECIMAL_RADIX));
        const endTime = workingHours.end.split(':').map((e) => parseInt(e, DECIMAL_RADIX));
        if (_task.beginDate && _task.endDate && !_task.targetReleaseId) {
            if (_task.beginDate.isSame(_task.endDate)) {
                _task.beginDate.set({
                    hour: startTime[0],
                    minute: startTime[1],
                });
                _task.endDate.set({hour: endTime[0], minute: endTime[1]});
            } else {
                _task.beginDate.set({
                    hour: startTime[0],
                    minute: startTime[1],
                });
                _task.endDate.set({hour: startTime[0], minute: startTime[1]});
            }
        }

        const _putTask = () => this.httpRest.put<ITask>(TASK_SCHEMA_ROUTE, _task);
        const _putBlockers = (task: ITask) =>
            blockers.map((blocker) =>
                this.httpRest.put<ITaskBlocker>(TASK_BLOCKER_SCHEMA_ROUTE, {
                    taskId: task.id,
                    blockerId: blocker,
                })
            );

        if (this.pendingTags.length) {
            const newTags = await this.httpRest
                .putEntities<ITag>(
                    TAG_SCHEMA_ROUTE,
                    this.pendingTags.map((p: any) => {
                        if (project) {
                            p.projectId = project;
                        }
                        delete p.selected;

                        return p;
                    })
                )
                .toPromise();
            newTags.map((_tag) => {
                if (!tags) {
                    tags = [];
                }
                tags.push(_tag.id);
            });
        }

        const _putTags = (task: ITask) =>
            this.httpRest.putEntities(
                TASK_TAG_SCHEMA_ROUTE,
                tags
                    .filter((_t) => !!_t)
                    .map((tag) => ({
                        taskId: task.id,
                        tagId: tag,
                    }))
            );
        const _putComment = (task: ITask) =>
            this.httpRest.put<ITaskComments>(TASK_COMMENT_SCHEMA_ROUTE, {
                comment,
                employeeId,
                taskId: task.id,
            });

        const _done = async (task: ITask) => {
            this.toastService.show({
                type: TOAST_TYPE.SUCCESS,
                text: 'success_insert_task',
            });
            this.pendingTags = [];
            callback(task);
            this.onSubmit.emit(task);
        };

        const t = await _putTask().toPromise();

        {
            const observables: Observable<
                ITaskBlocker | ITaskTag[] | ITaskComments | IDeleteCount | IAssignedTask
            >[] = [];

            if (blockers && blockers.length) {
                observables.push(..._putBlockers(t));
            }
            if (tags && tags.length) {
                observables.push(_putTags(t));
            }
            if (comment) {
                observables.push(_putComment(t));
            }

            if (assigned.length > 0) {
                assigned.map((emp) => {
                    observables.push(
                        this.httpRest.put<IAssignedTask>(ASSIGNED_TASK_SCHEMA_ROUTE, {
                            employeeId: emp.id,
                            assignerId: this.authService.user.employee.id,
                            taskId: t.id,
                            type: AssignedEmployeeType.DEVELOPER,
                        })
                    );
                });
            }

            if (!observables.length) {
                _done(t);
            }

            forkJoin(observables).subscribe(async () => _done(t));
        }

        if (recursive) {
            f.controls.name.reset();
            f.controls.comment.reset();
            f.controls.time.reset();
            const el = document.getElementById('name');
            if (el) {
                el.focus();
            }
        } else {
            f.reset();
        }

        return !recursive;
    }
}
