import {Component} from '@angular/core';
import {HttpRestService} from './http-rest.service';

@Component({
    selector: 'shared-http-rest',
    templateUrl: './http-rest.component.html',
    styleUrls: ['./http-rest.component.scss'],
})
export class HttpRestComponent {
    public constructor(public httpRest: HttpRestService) {}
}
