var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClrDatagridSortOrder } from '@clr/angular';
import { compare } from 'semver';
import { ApiRoutePlurality, HTTP_METHOD, ISO_DATE_FORMAT, MilestonesType, ReleaseStateType, RIGHTS, WikiPageType, } from '../../../../../defs/schema-static';
import { MILESTONE_FIELD, MILESTONE_SCHEMA_ROUTE } from '../../../../../defs/schema/public/Milestones';
import { TASK_SCHEMA_ROUTE } from '../../../../../defs/schema/public/Tasks';
import { WIKI_PAGE_SCHEMA_ROUTE } from '../../../../../defs/schema/public/WikiPage';
import { DATAGRID_FILTER_TYPE, RELEASE_STATE_FILTER } from '../../app-static';
import { AuthService } from '../../auth/auth.service';
import { FormsAddMilestoneService, MilestoneTarget } from '../../forms/add-milestone.service';
import { HttpRestService } from '../../shared/http-rest/http-rest.service';
import { MomentService } from '../../shared/moment/moment.service';
import { TOAST_TYPE, ToastService } from '../../shared/toast/toast.service';
var ProjectReleasesComponent = (function () {
    function ProjectReleasesComponent(httpRest, formsAddMilestoneService, route, router, authService, momentService, toastService) {
        this.httpRest = httpRest;
        this.formsAddMilestoneService = formsAddMilestoneService;
        this.route = route;
        this.router = router;
        this.authService = authService;
        this.momentService = momentService;
        this.toastService = toastService;
        this.showCreateModal = false;
        this.showEditModal = false;
        this.showReleaseModal = false;
        this.showMoveTasksModal = false;
        this.showCreateChangelogModal = false;
        this.showDeleteModal = false;
        this.modalLoading = false;
        this.undoneTasksCount = 0;
        this.otherUnreleased = [];
        this.targetRelease = undefined;
        this.RIGHTS = RIGHTS;
        this.MILESTONE_FIELD = MILESTONE_FIELD;
        this.DATAGRID_FILTER_TYPE = DATAGRID_FILTER_TYPE;
        this.WikiPageType = WikiPageType;
        this.MilestonesType = MilestonesType;
        this.ReleaseStateType = ReleaseStateType;
        this.RELEASES_COLUMNS = [
            {
                field: MILESTONE_FIELD.version,
                translateKey: 'version',
                sorter: {
                    compare: ProjectReleasesComponent.SORT_MILESTONES_VERSION,
                },
                order: ClrDatagridSortOrder.ASC,
                hideable: false,
            },
            {
                field: MILESTONE_FIELD.obs,
                translateKey: 'table_name',
            },
            {
                field: MILESTONE_FIELD.description,
                translateKey: 'table_description',
            },
            {
                field: MILESTONE_FIELD.target,
                translateKey: 'table_target',
                filterType: DATAGRID_FILTER_TYPE.DATE,
            },
            {
                field: MILESTONE_FIELD.target,
                translateKey: 'table_target_time',
                filterType: DATAGRID_FILTER_TYPE.DATE,
                hidden: true,
            },
            {
                field: MILESTONE_FIELD.beginDate,
                translateKey: 'begin_date',
                filterType: DATAGRID_FILTER_TYPE.DATE,
            },
            {
                field: MILESTONE_FIELD.beginDate,
                translateKey: 'table_begin_time',
                filterType: DATAGRID_FILTER_TYPE.DATE,
                hidden: true,
            },
            {
                field: MILESTONE_FIELD.endDate,
                translateKey: 'end_date',
                filterType: DATAGRID_FILTER_TYPE.DATE,
            },
            {
                field: MILESTONE_FIELD.endDate,
                translateKey: 'table_end_time',
                filterType: DATAGRID_FILTER_TYPE.DATE,
                hidden: true,
            },
            {
                field: MILESTONE_FIELD.releaseState,
                translateKey: 'table_status',
                filterType: DATAGRID_FILTER_TYPE.CUSTOM,
            },
        ];
        this.filterOpen = {};
        this.RELEASE_STATE_FILTER = RELEASE_STATE_FILTER;
        this.isManagerOf = this.authService.isManagerOf.bind(this.authService);
        this.hasRight = this.authService.hasRight.bind(this.authService);
    }
    ProjectReleasesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.getReleases();
        this.route.queryParams.subscribe(function (queryParams) {
            _this.editQueryParams = Number(queryParams.edit) || undefined;
            _this.selectedReleaseChange();
        });
    };
    ProjectReleasesComponent.prototype.ngOnChanges = function (changes) {
        if (changes.project) {
            this.releases = undefined;
            this.getReleases();
        }
    };
    ProjectReleasesComponent.prototype.getReleases = function () {
        if (!this.project || !this.project.milestones) {
            return;
        }
        this.releases =
            this.releases || this.project.milestones.filter(function (milestone) { return milestone.type === MilestonesType.RELEASE; });
        this.selectedReleaseChange();
    };
    ProjectReleasesComponent.prototype.selectedReleaseChange = function () {
        var _this = this;
        if (!this.editQueryParams) {
            this.selectedRelease = undefined;
            this.showEditModal = false;
            return;
        }
        this.selectedRelease = (this.releases || []).find(function (_release) { return _release.id === _this.editQueryParams; });
        if (this.selectedRelease) {
            this.editRelease();
        }
    };
    ProjectReleasesComponent.prototype.editRelease = function () {
        var release = this.selectedRelease;
        this.editionRelease = {
            id: release.id,
            author: release.employeeId,
            description: release.description,
            milestone: release.obs,
            participants: release.participants,
            project: this.project.id,
            startDate: this.momentService.moment(release.beginDate).format(ISO_DATE_FORMAT),
            startTime: this.momentService
                .moment(release.beginDate)
                .format(FormsAddMilestoneService.HOUR_MINUTE_TIME_FORMAT),
            endDate: this.momentService.moment(release.endDate).format(ISO_DATE_FORMAT),
            endTime: this.momentService
                .moment(release.endDate)
                .format(FormsAddMilestoneService.HOUR_MINUTE_TIME_FORMAT),
            target: this.momentService.moment(release.target).format(ISO_DATE_FORMAT),
            targetType: MilestoneTarget.PROJECT,
            type: release.type,
            version: release.version,
        };
        this.showEditModal = true;
    };
    ProjectReleasesComponent.prototype.closeEditModal = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.router.navigate([], {
                        relativeTo: this.route,
                        queryParams: { edit: null },
                        queryParamsHandling: 'merge',
                    })];
            });
        });
    };
    ProjectReleasesComponent.prototype.getUndoneTasksCount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var count;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.modalLoading = true;
                        return [4, this.httpRest
                                ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, TASK_SCHEMA_ROUTE, "countReleaseNotDone/" + this.selectedRelease.id)
                                .toPromise()];
                    case 1:
                        count = (_a.sent()).count;
                        this.undoneTasksCount = count;
                        if (!!this.undoneTasksCount) return [3, 3];
                        return [4, this.updateReleaseState()];
                    case 2:
                        _a.sent();
                        this.modalLoading = false;
                        this.showReleaseModal = false;
                        return [2];
                    case 3:
                        this.targetRelease = (this.otherUnreleased = this.getOtherUnreleased())[0];
                        this.modalLoading = false;
                        this.showReleaseModal = false;
                        this.showMoveTasksModal = true;
                        return [2];
                }
            });
        });
    };
    ProjectReleasesComponent.prototype.getChangelog = function () {
        return __awaiter(this, void 0, void 0, function () {
            var page;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.selectedRelease) {
                            return [2];
                        }
                        return [4, this.httpRest
                                ._request(HTTP_METHOD.GET, ApiRoutePlurality.SINGULAR, WIKI_PAGE_SCHEMA_ROUTE, "getByRelease/" + this.selectedRelease.id)
                                .toPromise()];
                    case 1:
                        page = _a.sent();
                        if (!page) {
                            this.showCreateChangelogModal = true;
                            return [2];
                        }
                        window.open("/wiki/page/" + page.hash);
                        return [2];
                }
            });
        });
    };
    ProjectReleasesComponent.prototype.updateReleaseState = function (targetState) {
        if (targetState === void 0) { targetState = ReleaseStateType.RELEASED; }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.modalLoading = true;
                        return [4, this.httpRest
                                ._request(HTTP_METHOD.PUT, ApiRoutePlurality.PLURAL, MILESTONE_SCHEMA_ROUTE, 'release', {
                                targetState: targetState,
                                id: this.selectedRelease.id,
                                targetReleaseId: this.targetRelease && this.targetRelease.id,
                            })
                                .toPromise()];
                    case 1:
                        _a.sent();
                        this.selectedRelease.releaseState = targetState;
                        this.selectedRelease = undefined;
                        this.targetRelease = undefined;
                        this.otherUnreleased = [];
                        this.modalLoading = false;
                        this.showMoveTasksModal = false;
                        this.toastService.show({
                            type: TOAST_TYPE.SUCCESS,
                            text: 'success_update_release',
                        });
                        return [2];
                }
            });
        });
    };
    ProjectReleasesComponent.prototype.getOtherUnreleased = function () {
        var releaseId = this.selectedRelease.id;
        return (this.releases || [])
            .filter(function (release) { return release.id !== releaseId && release.releaseState !== ReleaseStateType.RELEASED; })
            .sort(ProjectReleasesComponent.SORT_MILESTONES_VERSION)
            .reverse();
    };
    ProjectReleasesComponent.prototype.addNewRelease = function (releaseForm) {
        return __awaiter(this, void 0, void 0, function () {
            var release;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.formsAddMilestoneService.submit(releaseForm)];
                    case 1:
                        release = _a.sent();
                        if (!release) {
                            return [2, undefined];
                        }
                        this.releases.push(release);
                        return [2, this.closeEditModal()];
                }
            });
        });
    };
    ProjectReleasesComponent.prototype.editedRelease = function (releaseForm) {
        return __awaiter(this, void 0, void 0, function () {
            var release;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.formsAddMilestoneService.edit(__assign({}, releaseForm, { id: this.selectedRelease.id, type: this.selectedRelease.type }))];
                    case 1:
                        release = _a.sent();
                        Object.assign(this.selectedRelease, release);
                        this.selectedRelease = undefined;
                        return [2, this.closeEditModal()];
                }
            });
        });
    };
    ProjectReleasesComponent.prototype.deleteSelectedRelease = function () {
        return __awaiter(this, void 0, void 0, function () {
            var releaseId, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        releaseId = this.selectedRelease.id;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4, this.httpRest.deleteId(MILESTONE_SCHEMA_ROUTE, releaseId).toPromise()];
                    case 2:
                        _a.sent();
                        return [3, 4];
                    case 3:
                        err_1 = _a.sent();
                        this.showDeleteModal = false;
                        return [2];
                    case 4:
                        this.selectedRelease = undefined;
                        this.releases = this.releases.filter(function (release) { return releaseId !== release.id; });
                        this.showDeleteModal = false;
                        return [2];
                }
            });
        });
    };
    ProjectReleasesComponent.SORT_MILESTONES_VERSION = function (r1, r2) {
        return r2.version ? (r1.version ? compare(r2.version, r1.version) : 1) : -1;
    };
    return ProjectReleasesComponent;
}());
export { ProjectReleasesComponent };
