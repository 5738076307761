import { EventEmitter } from '@angular/core';
import { ClrDatagridSortOrder } from '@clr/angular';
import { getGroupDisplayName } from '../../../app-static';
var AdminAccessUsersListComponent = (function () {
    function AdminAccessUsersListComponent() {
        this.selectedUserGroupsChange = new EventEmitter();
        this.filterOpen = {};
        this.getGroupDisplayName = getGroupDisplayName;
        this.ClrDatagridSortOrder = ClrDatagridSortOrder;
    }
    Object.defineProperty(AdminAccessUsersListComponent.prototype, "proxiedSelectedUserGroups", {
        get: function () {
            return this.selectedUserGroups.slice();
        },
        enumerable: true,
        configurable: true
    });
    return AdminAccessUsersListComponent;
}());
export { AdminAccessUsersListComponent };
