import { ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { ShortcutHandlerService } from '../shortcut-handler/shortcut-handler.service';
var EventBinderDirective = (function () {
    function EventBinderDirective(element, shortcutHandlerService) {
        this.element = element;
        this.shortcutHandlerService = shortcutHandlerService;
    }
    EventBinderDirective.prototype.ngOnChanges = function (changes) {
        if (changes && changes.ebShortcut) {
            if (this.isFocused) {
                this.registerHandler();
            }
            else {
                this.unregisterHandler();
            }
        }
    };
    EventBinderDirective.prototype.registerHandler = function () {
        var _this = this;
        if (this.handler) {
            if (this.handler.shortcut === this.ebShortcut) {
                return;
            }
            this.unregisterHandler();
        }
        this.handler = this.shortcutHandlerService.register({
            shortcut: this.ebShortcut,
            callback: function () { return _this.triggerEvent(); },
            forceListen: true,
        });
    };
    EventBinderDirective.prototype.unregisterHandler = function () {
        if (!this.handler) {
            return;
        }
        this.shortcutHandlerService.unregisterHandlers(this.handler);
        this.handler = undefined;
    };
    EventBinderDirective.prototype.triggerEvent = function () {
        if (!this.element) {
            return;
        }
        var $element = this.element.nativeElement;
        var $srcElement;
        switch (this.ebEventType) {
            case 'submit':
                $srcElement = $element.closest('form');
                break;
            default:
                $srcElement = $element;
                break;
        }
        if (!$srcElement) {
            return;
        }
        $srcElement.dispatchEvent(new Event(this.ebEventType, {
            bubbles: true,
            cancelable: true,
        }));
    };
    Object.defineProperty(EventBinderDirective.prototype, "sharedEventBinder", {
        set: function (args) {
            if (args.length !== 2) {
                throw new Error("Expected 2 arguments, got " + args + " [ebShortcut, ebEventType]");
            }
            this.ebShortcut = args[0], this.ebEventType = args[1];
            if (this.isFocused) {
                this.registerHandler();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventBinderDirective.prototype, "isFocused", {
        get: function () {
            return (this.element &&
                document.activeElement &&
                this.element.nativeElement === document.activeElement);
        },
        enumerable: true,
        configurable: true
    });
    return EventBinderDirective;
}());
export { EventBinderDirective };
