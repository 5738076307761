import {ISchemaRoute} from '../../schema-static';
import {IProject} from './Projects';
import {ITaskTag} from './TaskTags';

export enum TAG_FIELD {
    id = 'id',
    text = 'text',
    color = 'color',
    branch = 'branch',
    projectId = 'projectId',
}

export interface ITagBare {
    id?: number;
    text: string;
    color: string;
    branch: boolean;
    projectId: number;
}

export interface ITagBelong {
    project?: Partial<IProject>;
}

export interface ITagMany {
    taskTags?: Partial<ITaskTag>[];
}

export interface ITag extends ITagBare, ITagBelong, ITagMany {}

export interface IDisplayTag extends ITag {
    selected?: boolean;
}

export interface ITagWithTaskCount extends ITag {
    taskTagsCount: {
        notDone: number;
        done: number;
    };
}

const TAG_SCHEMA_ROUTE: ISchemaRoute = {
    tableName: 'tags',
    singularRoute: 'tag',
    pluralRoute: 'tags',
};

export {TAG_SCHEMA_ROUTE};
