<div class="clr-row">
    <div class="clr-col" style="margin: 2px;">
        <div
            class="clr-row item-choice c-hand"
            (click)="setTargetType(MILESTONE_TARGET.PROJECT)"
            [ngClass]="{active: MILESTONE_TARGET.PROJECT === form.value.targetType}"
        >
            <div class="clr-col-3"><clr-icon shape="folder"></clr-icon></div>
            <div class="clr-icon-9" translate>project</div>
        </div>
    </div>
    <div class="clr-col" style="margin: 2px;" *ngIf="!projectOnly">
        <div
            class="clr-row item-choice c-hand"
            (click)="setTargetType(MILESTONE_TARGET.CLIENT)"
            [ngClass]="{active: MILESTONE_TARGET.CLIENT === form.value.targetType}"
        >
            <div class="clr-col-3"><clr-icon shape="user"></clr-icon></div>
            <div class="clr-icon-9" translate>client</div>
        </div>
    </div>
</div>

<div class="clr-row mt-2" *ngIf="MILESTONE_TARGET.CLIENT === form.value.targetType">
    <div class="clr-col-12">
        <ng-select
            class="less-pl"
            [items]="clients"
            bindLabel="user.name"
            bindValue="id"
            [placeholder]="'client_select' | translate"
            (clear)="filterClient()"
            (change)="filterClient($event?.id)"
            #ngSelectClient
            [ngModel]="form.value.client"
        >
            <ng-template ng-option-tmp let-item="item" let-index="index">
                <app-long-avatar [code]="item?.user.code" [bgColor]="item.user.color"> </app-long-avatar>
                {{ item.user.name }}
            </ng-template>
            <ng-template ng-label-tmp let-item="item">
                <app-long-avatar [code]="item?.user.code" [bgColor]="item.user.color"> </app-long-avatar>
                {{ item.user.name }}
            </ng-template>
        </ng-select>
    </div>
</div>

<div class="clr-row mt-2" *ngIf="MILESTONE_TARGET.PROJECT === form.value.targetType">
    <div class="clr-col-12">
        <ng-select
            class="less-pl"
            [items]="projectsFiltered"
            bindLabel="obs"
            bindValue="id"
            [placeholder]="'project_select' | translate"
            (clear)="filterProject()"
            (change)="filterProject($event?.id)"
            #ngSelectProject
            [ngModel]="form.value.project"
        >
            <ng-template ng-option-tmp let-item="item" let-index="index">
                <app-long-avatar [code]="item?.code" [bgColor]="item.color"> </app-long-avatar>
                {{ item.obs }}
            </ng-template>
            <ng-template ng-label-tmp let-item="item">
                <app-long-avatar [code]="item?.code" [bgColor]="item.color"> </app-long-avatar>
                {{ item.obs }}
            </ng-template>
        </ng-select>
    </div>
</div>
