<app-modal
    title="{{ 'edit_task' | translate }}"
    [(show)]="show"
    (showChange)="showChange.emit($event)"
    appModalSize="lg"
>
    <ng-template appModalBody>
        <ng-container *ngIf="taskToEdit">
            <form id="editTaskForm" #taskEditForm="ngForm" clrForm [clrLayout]="'vertical'" autocomplete="off">
                <div [formGroup]="form">
                    <clr-textarea-container class="mt-0 p-0">
                        <label class="clr-col-12 clr-col-md-3 p-0" for="taskName" translate>task_name</label>
                        <textarea
                            class="clr-col-12"
                            clrTextarea
                            type="text"
                            id="name"
                            [formControlName]="EditTaskFormField.name"
                            sharedListAutoIndent
                            rows="0"
                            [minHeight]="'44'"
                            [maxLength]="250"
                            placeholder="{{ 'task_name' | translate }}"
                            [sharedAutofocus]="show"
                            [sharedEventBinder]="[SHORTCUT_MISC.TEXTAREA_SUBMIT, 'submit']"
                            sharedAutosize
                            #nameInput
                        ></textarea>
                    </clr-textarea-container>
                    <small class="float-right" [ngClass]="{'text-error': getInputNameLength() >= MAX_LENGTH_TASK_NAME}"
                        >{{ getInputNameLength() }}/{{ MAX_LENGTH_TASK_NAME }}</small
                    >

                    <clr-checkbox-container clrInline>
                        <clr-checkbox-wrapper>
                            <input type="checkbox" clrCheckbox id="bug" [formControlName]="EditTaskFormField.bug" />
                            <label for="bug" class="form-checkbox float-left">
                                <clr-icon shape="bug"></clr-icon> {{ 'task_isbug' | translate }}
                            </label>
                        </clr-checkbox-wrapper>
                        <clr-checkbox-wrapper>
                            <input
                                type="checkbox"
                                clrCheckbox
                                id="urgent"
                                [formControlName]="EditTaskFormField.urgent"
                            />
                            <label for="urgent" class="form-checkbox float-left">
                                <clr-icon shape="exclamation-triangle"></clr-icon>
                                {{ 'task_isurgent' | translate }}
                            </label>
                        </clr-checkbox-wrapper>
                        <clr-checkbox-wrapper style="display: none;">
                            <input
                                type="checkbox"
                                clrCheckbox
                                id="tracked"
                                [formControlName]="EditTaskFormField.tracked"
                            />
                            <label for="tracked" class="form-checkbox float-right">
                                <clr-icon shape="flame"></clr-icon>
                                {{ 'task_tracked' | translate }}
                            </label>
                        </clr-checkbox-wrapper>
                    </clr-checkbox-container>

                    <div class="clr-row">
                        <div class="clr-col-6" *ngIf="releasesStatic && releasesStatic.length">
                            <clr-input-container> <label translate>release</label> </clr-input-container>
                            <ng-select
                                class="less-pl p-0"
                                [formControlName]="EditTaskFormField.releaseId"
                                [items]="releasesStatic"
                                dropdownPosition="bottom"
                                bindLabel="obs"
                                bindValue="id"
                                groupBy="releaseState"
                                (clear)="onReleaseIdChange()"
                                (change)="onReleaseIdChange($event)"
                            >
                                <ng-template ng-optgroup-tmp let-item="item">
                                    {{ item.releaseState | translate }}
                                </ng-template>
                                <ng-template ng-label-tmp let-item="item">
                                    <div>
                                        <small class="label label-info"> {{ item.version }} </small>
                                        <small class="text-ellipsis task-label"> {{ item.obs }} </small>
                                        <!-- <span aria-hidden="true" class="ng-value-icon left">×</span> -->
                                    </div></ng-template
                                >
                                <ng-template ng-option-tmp let-item="item" let-index="index">
                                    <small class="label label-info vab"> {{ item.version }} </small>
                                    <small class="text-ellipsis task-label"> {{ item.obs }} </small>
                                </ng-template>
                            </ng-select>
                        </div>
                        <div class="clr-col-6" *ngIf="otherTasks">
                            <clr-input-container> <label translate>task_parent</label> </clr-input-container>
                            <ng-select
                                class="less-pl p-0"
                                [formControlName]="EditTaskFormField.parentTaskId"
                                [items]="tasksParent | async"
                                [typeahead]="tasksParent$"
                                [loading]="tasksParentLoading"
                                dropdownPosition="bottom"
                                (clear)="onParentChange()"
                                (change)="onParentChange($event)"
                            >
                                <ng-template ng-label-tmp let-item="item">
                                    <div *ngIf="item">
                                        <small class="label label-info"> {{ item.code }} </small>
                                        <small class="text-ellipsis task-label"> {{ item.name }} </small>
                                    </div></ng-template
                                >
                                <ng-template ng-option-tmp let-item="item" let-index="index">
                                    <small class="label label-info vab"> {{ item.code }} </small>
                                    <small class="text-ellipsis task-label"> {{ item.name }} </small>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>

                    <div class="clr-row">
                        <div class="clr-col-4">
                            <clr-input-container>
                                <label translate>task_progress </label>
                                <input clrInput type="number" [formControlName]="EditTaskFormField.progress" />

                                <clr-control-error [innerHTML]="'help_time' | translate"></clr-control-error>
                            </clr-input-container>
                        </div>
                    </div>

                    <div class="clr-row">
                        <div class="clr-col-4">
                            <clr-input-container>
                                <label translate>estimated_time</label>
                                <input
                                    clrInput
                                    type="text"
                                    [formControlName]="EditTaskFormField.estimated"
                                    placeholder="0m"
                                    sharedDebounce
                                    (debounceFunction)="checkValidationTime()"
                                />
                                <clr-control-helper>0w 52d 10h 18m</clr-control-helper>
                                <clr-control-error [innerHTML]="'help_time' | translate"></clr-control-error>
                            </clr-input-container>
                        </div>
                        <div class="clr-col-4">
                            <clr-date-container>
                                <label translate
                                    >date_begin
                                    <small
                                        *ngIf="getSelectedRelease()"
                                        class="release-date tooltip-sm"
                                        [attr.data-tooltip]="'release_begin_date' | translate"
                                    >
                                        ({{ getSelectedRelease()?.beginDate | momentFormat }})
                                    </small></label
                                >
                                <input
                                    type="date"
                                    id="date"
                                    name="date"
                                    [(clrDate)]="beginDate"
                                    #beginDateInputElement
                                />
                            </clr-date-container>
                        </div>
                        <div class="clr-col-4">
                            <clr-date-container>
                                <label translate
                                    >date_end
                                    <small
                                        *ngIf="getSelectedRelease()"
                                        class="release-date tooltip-sm"
                                        [attr.data-tooltip]="'release_end_date' | translate"
                                    >
                                        ({{
                                            getSelectedRelease().endDate || getSelectedRelease().target | momentFormat
                                        }})
                                    </small></label
                                >
                                <input type="date" id="end" name="end" [(clrDate)]="endDate" #endDateInputElement />
                            </clr-date-container>
                        </div>
                    </div>
                    <div class="clr-row mt-1" *ngIf="form && !checkDates()">
                        <div class="clr-col-12">
                            <div class="alert alert-danger" role="alert">
                                <div class="alert-items">
                                    <div class="alert-item static">
                                        <div class="alert-icon-wrapper">
                                            <clr-icon class="alert-icon" shape="exclamation-circle"></clr-icon>
                                        </div>
                                        <span class="alert-text" [innerHTML]="'error_date' | translate"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="clr-row">
                        <div class="clr-col-12">
                            <clr-input-container> <label translate>tags</label> </clr-input-container>
                            <ng-select
                                class="p-0"
                                [items]="tags"
                                [hideSelected]="true"
                                dropdownPosition="top"
                                bindLabel="text"
                                [addTag]="onTagSubmit"
                                multiple="true"
                                (change)="refreshTagList()"
                                [formControlName]="'tags'"
                            >
                                <ng-template ng-label-tmp let-item="item">
                                    <shared-task-tag
                                        [tag]="item?.tag ? item.tag : item"
                                        [updatable]="true"
                                        [removable]="true"
                                        [branchable]="false"
                                        (update)="updateTag(null, $event)"
                                        (remove)="removeTag($event)"
                                    >
                                    </shared-task-tag
                                ></ng-template>
                                <ng-template ng-tag-tmp let-search="searchTerm">
                                    <b>Add new tag</b>: {{ search }}
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>

                    <div class="clr-row" *ngIf="otherTasks">
                        <div class="clr-col-12">
                            <clr-input-container> <label translate>task_blockers</label> </clr-input-container>
                            <ng-select
                                class="p-0"
                                [items]="addBlockers.tasksBlockers | async"
                                [typeahead]="addBlockers.tasksBlockers$"
                                [loading]="addBlockers.tasksBlockersLoading"
                                dropdownPosition="top"
                                [hideSelected]="true"
                                [clearable]="false"
                                multiple="true"
                                [formControlName]="EditTaskFormField.blockers"
                                (add)="doAddBlocker($event)"
                            >
                                <ng-template ng-label-tmp let-item="item">
                                    <div>
                                        <small class="label label-info"> {{ item.code }} </small>
                                        <small class="text-ellipsis task-label d-inlineb"> {{ item.name }} </small>
                                        <span class="left c-hand" (click)="doRemoveBlocker(item)">×</span>
                                    </div></ng-template
                                >
                                <ng-template ng-option-tmp let-item="item">
                                    <small class="label label-info  va-m"> {{ item.code }} </small>
                                    <small class="text-ellipsis task-label"> {{ item.name }} </small>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                </div>
            </form>
        </ng-container>
    </ng-template>
    <ng-template appModalFooter>
        <button (click)="show = false" class="btn btn-secondary" translate>cancel</button>
        <ng-container *ngIf="!!taskToEdit && !!form">
            <button
                form="editTaskForm"
                [disabled]="!validate(form, false) || !checkDates()"
                (click)="saveTask(form)"
                class="btn btn-success"
                translate
            >
                form_save
            </button>
        </ng-container>
    </ng-template>
</app-modal>
