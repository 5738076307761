import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {COGNITO_USER_GROUPS, COGNITO_USER_GROUPS_PRECEDENCE, NAVIGATE_RIGHTS} from '../../../../defs/schema-static';
import {APP_MODULE_ROUTE_PATH, AUTH_MODULE_ROUTE_PATH, PORTAL_MODULE_ROUTE_PATH} from '../app-static';
import {AuthService} from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
    public readonly requiredGroup: COGNITO_USER_GROUPS = COGNITO_USER_GROUPS._UNAUTHED;

    protected targetRouteUrl = '';
    protected static restored = false;
    private static restoring = false;

    public constructor(protected authService: AuthService, protected router: Router) {
        if (!AuthGuard.restoring) {
            AuthGuard.restoring = true;
            this.authService.restoreSession((err) => {
                if (err) {
                    this.authService.signOut();

                    return;
                }

                AuthGuard.restored = true;
            });

            this.authService.isAuthenticatedChange.subscribe(() => {
                if (this.isAuthenticated) {
                    if (this.targetRouteUrl) {
                        (async () => this.router.navigateByUrl(this.targetRouteUrl))();
                        this.targetRouteUrl = '';

                        return;
                    }
                    (async () =>
                        this.router.navigateByUrl(
                            !router.url.includes('login') ? router.url.replace('/', '') : await this.getDefaultRoute(),
                            {
                                replaceUrl: true,
                            }
                        ))();

                    return;
                }

                if (AuthGuard.restored) {
                    (async () => this.router.navigate([AuthGuard.LOGIN_ROUTE], {replaceUrl: true}))();
                }
            });
        }
    }

    public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const {url} = state;
        if (url.indexOf(`/${AUTH_MODULE_ROUTE_PATH.AUTH}`) === 0) {
            if (!this.isAuthenticated) {
                return true;
            }
        }

        if (!this.isAuthenticated) {
            if (!this.targetRouteUrl) {
                this.targetRouteUrl = url;
            }

            if (AuthGuard.restored) {
                (async () => this.router.navigate([AuthGuard.LOGIN_ROUTE], {replaceUrl: true}))();
            }

            return false;
        }

        if (!this.isAuthorized || (url === AuthGuard.LOGIN_ROUTE && !this.targetRouteUrl)) {
            (async () => this.router.navigate([await this.getDefaultRoute()], {replaceUrl: true}))();

            return this.isAuthorized;
        }

        if (this.targetRouteUrl) {
            (async () => this.router.navigateByUrl(this.targetRouteUrl))();
            this.targetRouteUrl = '';
        }

        return true;
    }

    public get isAuthenticated(): boolean {
        return this.authService.isAuthenticated;
    }

    public get isAuthorized(): boolean {
        return (
            this.isAuthenticated &&
            !!this.authService.cognitoGroups.find(
                (group) => COGNITO_USER_GROUPS_PRECEDENCE[this.requiredGroup] >= COGNITO_USER_GROUPS_PRECEDENCE[group]
            )
        );
    }

    public async getDefaultRoute(): Promise<string> {
        switch (this.authService.precedenceGroup) {
            case COGNITO_USER_GROUPS.ADMIN:
            case COGNITO_USER_GROUPS.EMPLOYEE:
                this.authService.rights = await this.authService.getRightsPromise;
                if (this.authService.rights.find((right) => right.code === NAVIGATE_RIGHTS.NAVIGATE_DASHBOARD)) {
                    return `/${APP_MODULE_ROUTE_PATH.DASHBOARD}`;
                }
                if (this.authService.rights.find((right) => right.code === NAVIGATE_RIGHTS.NAVIGATE_FIRON)) {
                    return `/${APP_MODULE_ROUTE_PATH.FIRON}`;
                }
                if (this.authService.rights.find((right) => right.code === NAVIGATE_RIGHTS.NAVIGATE_CALENDAR)) {
                    return `/${APP_MODULE_ROUTE_PATH.CALENDAR}`;
                }
                if (this.authService.rights.find((right) => right.code === NAVIGATE_RIGHTS.NAVIGATE_GANTT)) {
                    return `/${APP_MODULE_ROUTE_PATH.GANTT}`;
                }
                if (this.authService.rights.find((right) => right.code === NAVIGATE_RIGHTS.NAVIGATE_REPORTS)) {
                    return `/${APP_MODULE_ROUTE_PATH.REPORTS}`;
                }

                return `/${AUTH_MODULE_ROUTE_PATH.AUTH}/${AUTH_MODULE_ROUTE_PATH.UNAUTHORIZED}`;
            case COGNITO_USER_GROUPS.CLIENT:
                return `/${PORTAL_MODULE_ROUTE_PATH.DASHBOARD}`;
            default:
                return `/${AUTH_MODULE_ROUTE_PATH.AUTH}/${AUTH_MODULE_ROUTE_PATH.UNAUTHORIZED}`;
        }
    }

    public static readonly LOGIN_ROUTE = `/${AUTH_MODULE_ROUTE_PATH.AUTH}/${AUTH_MODULE_ROUTE_PATH.LOG_IN}`;
}
