import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../auth/auth.service';

@Injectable({
    providedIn: 'root',
})
export class AccessGuard implements CanActivate {
    public constructor(private readonly authService: AuthService, private readonly router: Router) {}

    public canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        if (!this.authService.user) {
            return false;
        }
        const {expectedRight} = next.data;

        if (!expectedRight) {
            return true;
        }

        if (this.authService.rights) {
            return this.authService.rights && this.authService.hasRight(expectedRight);
        }

        return new Promise((resolve) => {
            this.authService.getRightsPromise
                .then((r) => {
                    this.authService.rights = r;
                    if (this.authService.rights && this.authService.hasRight(expectedRight)) {
                        resolve(true);

                        return undefined;
                    }
                    (async () => this.router.navigate(['/']))();
                    resolve(false);
                })
                .catch((err) => {
                    resolve(true);
                });
        });
    }
}
