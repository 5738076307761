<div class="column-container py-02 pt-2 oy-a">
    <div class="flex-1-set clr-col">
        <ng-container *sharedAccessControl="RIGHTS.PROJECT_TAB_PR">
            <div class="clr-row"><h3 class="mt-0" translate>team_members</h3></div>

            <div class="clr-row">
                <app-project-wizard-project-members
                    class="clr-col-xl-6 clr-col-12"
                    [disabled]="!isManager"
                    [project]="project"
                    [employees]="employees"
                    [(members)]="project.projectMembers"
                    [autoSubmit]="true"
                ></app-project-wizard-project-members>
            </div>
        </ng-container>
        <ng-container *sharedAccessControl="RIGHTS.TAG_CREATE">
            <div class="clr-row"><h3 class="mt-0" translate>tags</h3></div>

            <div class="clr-row">
                <form class="clr-col-xl-6 clr-col-12" clrForm [clrLayout]="'vertical'" (submit)="addTag()">
                    <clr-input-container>
                        <label translate>new_tag</label>
                        <input clrInput name="tagInputText" [(ngModel)]="tagInputText" appRequiredTrim />
                    </clr-input-container>
                    <clr-input-container>
                        <label translate>tag_color</label>
                        <input clrInput name="tagInputColor" [(ngModel)]="tagInputColor" type="color" />
                    </clr-input-container>
                    <clr-checkbox-container clrInline>
                        <clr-checkbox-wrapper>
                            <label translate>tag_branch</label>
                            <input clrCheckbox type="checkbox" name="tagInputBranch" [(ngModel)]="tagInputBranch" />
                        </clr-checkbox-wrapper>
                    </clr-checkbox-container>

                    <button type="submit" class="btn btn-success" [disabled]="!tagInputText" translate>add_tag</button>
                </form>
            </div>
            <ng-container *ngFor="let _tags of [notDoneTags, doneTags, inactiveTags]">
                <ng-container *ngIf="_tags && _tags.length">
                    <div class="clr-row">
                        <div class="clr-col-12">
                            <h4 *ngIf="_tags === notDoneTags" translate>not_done_tags</h4>
                            <h4 *ngIf="_tags === doneTags" translate>done_tags</h4>
                            <h4 *ngIf="_tags === inactiveTags" translate>inactive_tags</h4>
                        </div>
                    </div>
                    <div class="clr-row">
                        <div class="clr-col-xl-6 clr-col-12" style="margin-top: 1rem;">
                            <shared-task-tag
                                *ngFor="let tag of _tags"
                                [tag]="tag"
                                [updatable]="true"
                                [branchable]="true"
                                [removable]="true"
                                (update)="updateTag($event)"
                                (updateBranch)="toggleTagBranch($event)"
                                (remove)="removeTag($event)"
                            ></shared-task-tag>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>
</div>

<app-modal [(show)]="showDeleteModal" [title]="'tag_delete_modal_title' | translate">
    <ng-template appModalBody>
        <p>
            {{
                'tag_delete_modal_confirm_has_tasks'
                    | translate
                        : {
                              what: selectedTag.text,
                              count: selectedTag.taskTagsCount.done + selectedTag.taskTagsCount.notDone
                          }
            }}
            <span *ngIf="selectedTag.taskTagsCount.notDone">
                {{ 'tag_delete_modal_including_not_done' | translate: {count: selectedTag.taskTagsCount.notDone} }}
            </span>
        </p>
    </ng-template>
    <ng-template appModalFooter>
        <button class="btn btn-secondary" (click)="showDeleteModal = false" translate>form_cancel</button>
        <button class="btn btn-danger" (click)="removeTag(selectedTag, true)">{{ 'delete_tag' | translate }}</button>
    </ng-template>
</app-modal>
