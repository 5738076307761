<span
    [title]="title"
    [class.text-dark]="isColorDark(bgColor || user?.color)"
    [class.text-light]="!isColorDark(bgColor || user?.color)"
    [class.badge-100]="type === LONG_AVATAR_TYPE.BADGE_100"
    [class.label-round]="type === LONG_AVATAR_TYPE.LABEL_ROUND"
    [class.d-inline]="type !== LONG_AVATAR_TYPE.LABEL_ROUND"
    [class.d-inlineb]="code === '*'"
    [class.stroke-black]="strokedBlack"
    [class.stroke-white]="strokedWhite"
    [class.va-m]="verticalAlign"
    [ngClass]="{
        'label label-rounded': [LONG_AVATAR_TYPE.LABEL, LONG_AVATAR_TYPE.LABEL_ROUND].includes(type),
        'badge badge-rounded': [LONG_AVATAR_TYPE.BADGE, LONG_AVATAR_TYPE.BADGE_100].includes(type)
    }"
    [ngStyle]="{'background-color': bgColor || user?.color, visibility: hidden ? 'hidden' : null}"
    >{{ code || user?.code?.toUpperCase() || '' }}<ng-content></ng-content
></span>
