import * as i0 from "./toast.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../../../node_modules/@clr/angular/clr-angular.ngfactory";
import * as i4 from "@clr/angular";
import * as i5 from "@angular/common";
import * as i6 from "./toast.component";
import * as i7 from "./toast.service";
var styles_ToastComponent = [i0.styles];
var RenderType_ToastComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ToastComponent, data: {} });
export { RenderType_ToastComponent as RenderType_ToastComponent };
function View_ToastComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "alert-actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "button", [["class", "btn alert-action"], ["translate", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (_v.parent.context.$implicit.callback() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(3, null, ["", ""]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit.callbackText; _ck(_v, 3, 0, currVal_1); }); }
function View_ToastComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "clr-alert", [["class", "mb-02"]], null, [[null, "clrAlertClosedChange"]], function (_v, en, $event) { var ad = true; if (("clrAlertClosedChange" === en)) {
        var pd_0 = ((_v.context.$implicit.closed = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ClrAlert_0, i3.RenderType_ClrAlert)), i1.ɵprd(512, null, i4.ɵo, i4.ɵo, [i4.ClrCommonStrings]), i1.ɵdid(2, 49152, null, 0, i4.ClrAlert, [i4.ɵo, i1.ChangeDetectorRef, [2, i4.ɵp], i4.ClrCommonStrings], { isAppLevel: [0, "isAppLevel"], _closed: [1, "_closed"], alertType: [2, "alertType"] }, { _closedChanged: "clrAlertClosedChange" }), (_l()(), i1.ɵeld(3, 0, null, 0, 6, "clr-alert-item", [["class", "alert-item"]], null, null, null, i3.View_ClrAlertItem_0, i3.RenderType_ClrAlertItem)), i1.ɵdid(4, 49152, null, 0, i4.ClrAlertItem, [i4.ɵo], null, null), (_l()(), i1.ɵeld(5, 0, null, 0, 2, "span", [["class", "alert-text"], ["translate", ""]], null, null, null, null, null)), i1.ɵdid(6, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ToastComponent_2)), i1.ɵdid(9, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = true; var currVal_1 = _v.context.$implicit.closed; var currVal_2 = _v.context.$implicit.type; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = ""; _ck(_v, 6, 0, currVal_3); var currVal_5 = _v.context.$implicit.callback; _ck(_v, 9, 0, currVal_5); }, function (_ck, _v) { var currVal_4 = _v.context.$implicit.text; _ck(_v, 7, 0, currVal_4); }); }
function View_ToastComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "alert-actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "button", [["class", "btn alert-action"], ["translate", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (_v.parent.context.$implicit.callback() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(3, null, ["", ""]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit.callbackText; _ck(_v, 3, 0, currVal_1); }); }
function View_ToastComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "clr-alert", [], null, null, null, i3.View_ClrAlert_0, i3.RenderType_ClrAlert)), i1.ɵprd(512, null, i4.ɵo, i4.ɵo, [i4.ClrCommonStrings]), i1.ɵdid(2, 49152, null, 0, i4.ClrAlert, [i4.ɵo, i1.ChangeDetectorRef, [2, i4.ɵp], i4.ClrCommonStrings], { isAppLevel: [0, "isAppLevel"], alertType: [1, "alertType"] }, null), (_l()(), i1.ɵeld(3, 0, null, 0, 6, "clr-alert-item", [["class", "alert-item"]], null, null, null, i3.View_ClrAlertItem_0, i3.RenderType_ClrAlertItem)), i1.ɵdid(4, 49152, null, 0, i4.ClrAlertItem, [i4.ɵo], null, null), (_l()(), i1.ɵeld(5, 0, null, 0, 2, "span", [["class", "alert-text"], ["translate", ""]], null, null, null, null, null)), i1.ɵdid(6, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ToastComponent_4)), i1.ɵdid(9, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = true; var currVal_1 = _v.context.$implicit.type; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = ""; _ck(_v, 6, 0, currVal_2); var currVal_4 = _v.context.$implicit.callback; _ck(_v, 9, 0, currVal_4); }, function (_ck, _v) { var currVal_3 = _v.context.$implicit.text; _ck(_v, 7, 0, currVal_3); }); }
export function View_ToastComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "toaster"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ToastComponent_1)), i1.ɵdid(2, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "top-toaster"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ToastComponent_3)), i1.ɵdid(5, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.toastService.toasts; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.toastService.appLevelToasts; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_ToastComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "shared-toast", [], null, null, null, View_ToastComponent_0, RenderType_ToastComponent)), i1.ɵdid(1, 49152, null, 0, i6.ToastComponent, [i7.ToastService], null, null)], null, null); }
var ToastComponentNgFactory = i1.ɵccf("shared-toast", i6.ToastComponent, View_ToastComponent_Host_0, {}, {}, []);
export { ToastComponentNgFactory as ToastComponentNgFactory };
