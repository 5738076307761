import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-gantt-task-employee',
    templateUrl: './gantt-task-employee.component.html',
    styleUrls: ['./gantt-task-employee.component.scss'],
})
export class GanttTaskEmployeeComponent {
    @Input()
    public color: string;

    @Input()
    public code: string;
}
