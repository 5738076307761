import {
    ChangeDetectorRef,
    Component,
    ContentChild,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import {ControlFlowService} from '../shared/control-flow/control-flow.service';
import {SHORTCUT_LOCAL, ShortcutHandlerService} from '../shared/shortcut-handler/shortcut-handler.service';
import {ModalBodyDirective, ModalFooterDirective, ModalHeaderDirective} from './modal.directives';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit, OnChanges, OnDestroy {
    private static readonly BASE_Z_INDEX = 1050;
    private static readonly openModals: ModalComponent[] = [];

    @Input()
    public show = false;
    @Input()
    public title = '';
    @Input()
    public class = 'modal-dialog';
    @Input()
    public preventDismiss = false;
    @Input()
    public baseZIndex = ModalComponent.BASE_Z_INDEX;
    // tslint:disable-next-line:no-input-rename
    @Input('appModalSize')
    public size: 'sm' | 'lg' | 'xl'; // medium is default

    protected zIndex = this.baseZIndex;

    @Output()
    public showChange = new EventEmitter<boolean>();
    @Output()
    public shown = new EventEmitter<boolean>();
    @Output()
    public hidden = new EventEmitter<boolean>();
    @Output()
    public dismiss = new EventEmitter<boolean>();

    @ContentChild(ModalHeaderDirective, {read: TemplateRef})
    public modalHeader: TemplateRef<ModalHeaderDirective>;
    @ContentChild(ModalBodyDirective, {read: TemplateRef})
    public modalBody: TemplateRef<ModalBodyDirective>;
    @ContentChild(ModalFooterDirective, {read: TemplateRef})
    public modalFooter: TemplateRef<ModalFooterDirective>;

    @ViewChild('modalFooterRef')
    public modalFooterRef: ElementRef;

    public constructor(
        protected shortcutHandlerService: ShortcutHandlerService,
        protected controlFlowService: ControlFlowService,
        protected cdRef?: ChangeDetectorRef
    ) {}

    public ngOnInit() {
        this.show ? this.openModal(false) : this.closeModal(false, true);
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.show) {
            const emit = typeof changes.show.previousValue !== 'undefined' || changes.show.currentValue;

            this.show ? this.openModal(emit) : this.closeModal(emit, true);
        }
    }

    public ngOnDestroy() {
        this.cdRef.detach();
        this.cdRef = null;

        const bodyElement = document.querySelector('body');
        if (bodyElement.classList.contains('overflow-hidden')) {
            bodyElement.classList.remove('overflow-hidden');
        }
    }

    private openModal(emit = true) {
        this.show = true;
        const bodyElement = document.querySelector('body');
        if (!bodyElement.classList.contains('overflow-hidden')) {
            bodyElement.classList.add('overflow-hidden');
        }

        ModalComponent.openModals.push(this);
        this.zIndex = this.baseZIndex + ModalComponent.openModals.length;

        if (!emit) {
            return;
        }

        this.showChange.emit(this.show);
        this.shown.emit(this.show);

        if (!this.preventDismiss) {
            this.shortcutHandlerService.register({
                shortcut: SHORTCUT_LOCAL.CANCEL,
                callback: () => {
                    if (this.show && ModalComponent.openModals[ModalComponent.openModals.length - 1] === this) {
                        this.closeModal(true, true);
                        this.shortcutHandlerService.unregister(this, SHORTCUT_LOCAL.CANCEL);
                    }
                },
                context: this,
                forceListen: true,
            });
        }
        this.shortcutHandlerService.register({
            shortcut: SHORTCUT_LOCAL.CONFIRM,
            callback: (e) => {
                if (!this.modalFooterRef || (e.target as HTMLElement).tagName === 'TEXTAREA') {
                    return;
                }
                const el = this.modalFooterRef.nativeElement as HTMLDivElement;

                const buttons = el.querySelectorAll('.btn-primary, .btn-success');

                if (buttons.length === 1) {
                    (buttons[0] as HTMLButtonElement).click();
                }
            },
            context: this,
            forceListen: true,
        });
    }

    public closeModal(emit: boolean, dismiss: boolean, event?: Event) {
        if (!!event) {
            event.stopPropagation();
        }

        if (!!event && this.preventDismiss) {
            return;
        }

        this.show = false;

        const index = ModalComponent.openModals.findIndex((_modalComponent) => _modalComponent === this);

        if (index !== -1) {
            ModalComponent.openModals
                .map((_modalComponent, _index) => (_modalComponent === this || !_modalComponent.show ? _index : null))
                .filter((_index) => _index !== null)
                .map((_index) => ModalComponent.openModals.splice(_index, 1));

            ModalComponent.openModals.map((_modalComponent, _index) => {
                _modalComponent.zIndex = _modalComponent.baseZIndex + _index;
                if (_modalComponent.cdRef) {
                    _modalComponent.cdRef.detectChanges();
                }
            });
        }

        const bodyElement = document.querySelector('body');
        requestAnimationFrame(() => {
            if (bodyElement.classList.contains('overflow-hidden') && ModalComponent.openModals.length === 0) {
                bodyElement.classList.remove('overflow-hidden');
            }
        });

        if (!emit) {
            return;
        }

        this.shortcutHandlerService.unregister(this, SHORTCUT_LOCAL.CANCEL);
        this.shortcutHandlerService.unregister(this, SHORTCUT_LOCAL.CONFIRM);
        this.showChange.emit(this.show);
        this.hidden.emit(this.show);
        if (dismiss) {
            this.dismiss.emit(this.show);
        }

        if (this.cdRef) {
            this.cdRef.detectChanges();
        }
    }
}
