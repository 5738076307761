import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({
    name: 'convertCheckboxes',
})
export class ConvertCheckboxesPipe implements PipeTransform {
    public static readonly CHECKBOX_REGEX = /(\[\s*([xvXV])?\s*])\s*([^\n]+)/g;
    private static convertId = 0;

    public constructor(private readonly sanitizer: DomSanitizer) {}

    public transform(value: string, clickDisabled = false) {
        const id = ConvertCheckboxesPipe.convertId++;
        let index = 0;

        return this.sanitizer.bypassSecurityTrustHtml(
            value.replace(
                ConvertCheckboxesPipe.CHECKBOX_REGEX,
                (_match: string, checkbox: string, checked: string, label: string) =>
                    [
                        '<div class="clr-checkbox-container">',
                        '   <div class="clr-checkbox-wrapper">',
                        `       <input id="checkbox${id}_${index}" type="checkbox" class="clr-checkbox" ${
                            clickDisabled ? 'disabled="true"' : ''
                        }" ${checked ? 'checked' : ''} data-index="${index}">`,
                        `       <label for="checkbox${id}_${index++}">${label}</label>`,
                        '   </div>',
                        '</div>',
                    ].join('')
            )
        );
    }
}
