<form clrForm [formGroup]="form">
    <ng-select
        [items]="memberList"
        [hideSelected]="true"
        [bindLabel]="PROJECT_MEMBER_FIELD.employee + '.' + EMPLOYEE_FIELD.user + '.' + USER_FIELD.name"
        multiple="true"
        [formControlName]="PROJECT_MEMBERS_FORM_KEYS.members"
        (add)="addMember($event)"
        [clearable]="false"
    >
        <ng-template ng-label-tmp let-item="item">
            <shared-editable-employee-label
                [member]="item"
                [updatable]="!disabled"
                [removable]="!disabled && form.value.members?.length > 1"
                (remove)="removeMember($event)"
                (update)="updateMember($event)"
                [type]="EMPLOYEE_MEMBER_TYPE.PROJECT_MEMBER"
            >
            </shared-editable-employee-label>
        </ng-template>
        <ng-template ng-option-tmp let-item="item">
            <app-long-avatar [code]="item.employee.user.code" [bgColor]="item.employee.user.color"> </app-long-avatar>
            {{ item.employee.user.name }}
        </ng-template>
    </ng-select>
</form>

<app-modal [(show)]="showConfirmSudokuModal" [title]="'project_manager_delete_modal_title' | translate">
    <ng-template appModalBody>
        <p translate>project_manager_self_delete_confirm</p>

        <div class="alert alert-warning">
            <div class="alert-items">
                <div class="alert-item static">
                    <div class="alert-icon-wrapper">
                        <clr-icon class="alert-icon" shape="exclamation-circle"></clr-icon>
                    </div>
                    <span class="alert-text" translate>project_manager_self_delete_consequence</span>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template appModalFooter>
        <button class="btn btn-secondary" (click)="cancelSudoku()" [sharedAutofocus]="showConfirmSudokuModal" translate>
            form_cancel
        </button>
        <button class="btn btn-danger" (click)="confirmSudoku()" translate>remove_self</button>
    </ng-template>
</app-modal>
