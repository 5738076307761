<li [title]="authService.user?.code">
    <div class="sidebar-container" [ngClass]="isCollapsed ? 'is-collapsed' : ''">
        <a class="clr-col-6 c-hand user-view-button" (click)="showSettings = true">
            <clr-icon shape="cog" class="is-solid"></clr-icon>
            <span class="menu-title">{{ 'settings' | translate }}</span>
        </a>

        <a class="clr-col-6 c-hand user-view-button active" (click)="logout()">
            <clr-icon shape="logout" class="is-solid" [ngStyle]="{color: authService.user?.color}"></clr-icon>
            <span class="menu-title">{{ authService.user?.code }}</span>
        </a>
    </div>
</li>

<app-settings [(show)]="showSettings" *ngIf="authService.user"></app-settings>
