import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {ClrDatagridFilterInterface} from '@clr/angular';
import {IClient, IClientBelong} from '../../../../../defs/schema/public/Clients';
import {IEmployee} from '../../../../../defs/schema/public/Employees';
import {IProjectMember} from '../../../../../defs/schema/public/ProjectMembers';
import {IProject} from '../../../../../defs/schema/public/Projects';
import {SelectFilterType} from '../../app-static';

@Component({
    selector: 'shared-select-datagrid-filter',
    templateUrl: './select-datagrid-filter.component.html',
    styleUrls: ['./select-datagrid-filter.component.scss'],
})
export class SelectDatagridFilterComponent implements ClrDatagridFilterInterface<any> {
    @Input()
    public emps: IEmployee[] = null;
    public selected: any[] = null;

    @Input()
    public projects: IProject[] = null;
    public selectedProjects: IProject[] = null;

    @Input()
    public type: SelectFilterType;

    public changes: EventEmitter<any> = new EventEmitter<any>(false);

    public toggleEmp(item: any) {
        this.changes.emit(true);
    }

    public toggleProject(item: any) {
        this.changes.emit(true);
    }

    public accepts(item: any) {
        if (!this.selected) {
            return true;
        }

        if (this.selected && this.selected.length <= 0) {
            return true;
        }

        // tslint:disable-next-line: prefer-switch
        if (this.type === SelectFilterType.CONTACT) {
            const selectedIds = this.selected.map((s) => s.user.id);

            return item && item.contacts.findIndex((c: IClient) => selectedIds.includes(c.user.id)) > -1;
        } else if (this.type === SelectFilterType.CLIENT) {
            const selectedIds = this.selected.map((s) => s.id);

            return item && selectedIds.includes(item.client.id);
            // tslint:disable-next-line: prefer-switch
        } else if (this.type === SelectFilterType.PROJECT) {
            const selectedIds = this.selected.map((s) => s.id);

            return item && item.projects.findIndex((p: IProject) => selectedIds.includes(p.id)) > -1;
        } else if (this.type === SelectFilterType.MANAGER) {
            const selectedIds = this.selected.map((s) => s.id);

            return item && item.managers.findIndex((p: IProjectMember) => selectedIds.includes(p.employee.id)) > -1;
        } else if (this.type === SelectFilterType.EMPLOYEE) {
            const selectedIds = this.selected.map((s) => s.employee.id);

            return (
                item && item.projectMembers.findIndex((p: IProjectMember) => selectedIds.includes(p.employee.id)) > -1
            );
        }
    }

    public isActive(): boolean {
        return this.selected && this.selected.length > 0;
    }

    public readonly SelectFilterType = SelectFilterType;
}
