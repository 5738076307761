import {ContentChild, ContentChildren, Directive, HostListener, QueryList} from '@angular/core';
import {ClrDatagridColumnToggle, ClrDatagridFilter} from '@clr/angular';

@Directive({
    selector: 'clr-datagrid[appDatagridToggle]',
})
export class DatagridToggleDirective {
    @ContentChild(ClrDatagridColumnToggle) public clrColumnToggle: ClrDatagridColumnToggle;
    @ContentChildren(ClrDatagridFilter, {descendants: true}) public clrColumnFilter: QueryList<ClrDatagridFilter>;

    @HostListener('document:click', ['$event'])
    public onDocumentClick($event: MouseEvent) {
        const $target = $event.target as HTMLElement;
        if (this.clrColumnToggle && this.clrColumnToggle.open) {
            const selectors = ['.column-switch', '.column-switch-wrapper'];
            const findTargetOnSelectors = selectors.find((selector) => !!$target.closest(selector));
            if (!findTargetOnSelectors) {
                this.clrColumnToggle.open = false;
            }
        }
        if (this.clrColumnFilter && this.clrColumnFilter.length > 0) {
            const oneOpened = this.clrColumnFilter.some((s) => s.open);
            if (oneOpened) {
                const selectors = ['.datagrid-filter', '.datagrid-filter-toggle'];
                const findTargetOnSelectors = selectors.find((selector) => !!$target.closest(selector));
                if (!findTargetOnSelectors) {
                    this.clrColumnFilter.map((s) => (s.open = false));
                }
            }
        }
    }
}
