import {Component, EventEmitter, Output, ViewChild} from '@angular/core';
import {Validators} from '@angular/forms';
import {ClrForm} from '@clr/angular';
import {FormControl, FormGroup} from 'ngx-strongly-typed-forms';
import {RIGHTS} from '../../../../../defs/schema-static';
import {AuthService} from '../../auth/auth.service';
import {IAddTimeFormModal, TaskTarget} from '../../forms/add-time.service';

@Component({
    selector: 'app-time-type',
    templateUrl: './time-type.component.html',
    styleUrls: ['./time-type.component.scss'],
})
export class TimeTypeComponent {
    public readonly form = new FormGroup<{taskTarget: TaskTarget}>({
        taskTarget: new FormControl<TaskTarget>(null, Validators.required),
    });

    public taskTarget = Object.values(TaskTarget);
    public i18nTarget = {
        [TaskTarget.ASSIGNED]: 'assigned_task_inprogress',
        [TaskTarget.EXISTING]: 'existing_task',
        [TaskTarget.NEW]: 'new_task',
    };
    public iconTarget = {
        [TaskTarget.ASSIGNED]: 'assign-user',
        [TaskTarget.EXISTING]: 'check',
        [TaskTarget.NEW]: 'plus',
    };

    @ViewChild(ClrForm) public clrForm: ClrForm;
    @Output() public switchType = new EventEmitter();

    public constructor(private readonly authService: AuthService) {}

    public onLoad(params?: IAddTimeFormModal) {
        this.taskTarget = Object.values(TaskTarget);

        if (!this.authService.hasRight(RIGHTS.TASK_CREATE)) {
            this.taskTarget = this.taskTarget.filter((t) => t !== TaskTarget.NEW);
        }

        if (params && params.taskTarget && !this.form.value.taskTarget) {
            this.setType(params.taskTarget);
        } else if (this.taskTarget.length > 0 && !this.form.value.taskTarget) {
            this.setType(this.taskTarget[0]);
        }

        // focus on item selected to avoid enter key for next step
        requestAnimationFrame(() => {
            const idxElement = this.taskTarget.findIndex((f) => f === this.form.value.taskTarget);
            const el: HTMLElement = document.querySelector(`.item-choice[tabindex="${idxElement}"]`);
            if (el) {
                el.focus();
            }
        });
    }

    public setType(taskTarget: TaskTarget) {
        this.form.patchValue({
            taskTarget,
        });
        this.switchType.emit();
    }

    public submit() {
        if (!this.form.valid) {
            this.clrForm.markAsDirty();

            return undefined;
        }

        return this.form;
    }
}
