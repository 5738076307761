import {ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild, ViewChildren} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Moment} from 'moment';
import {forkJoin} from 'rxjs';
import * as SemVer from 'semver';
import {overEstimatedTime} from '../../../../defs/businessRules';
import {
    ApiRoutePlurality,
    HTTP_METHOD,
    PATTERN_TIME,
    ProjectStatusType,
    ReleaseStateType,
    TaskType,
} from '../../../../defs/schema-static';
import {IMilestone, MILESTONE_SCHEMA_ROUTE} from '../../../../defs/schema/public/Milestones';
import {IProject, PROJECT_SCHEMA_ROUTE} from '../../../../defs/schema/public/Projects';
import {ITaskBlocker, TASK_BLOCKER_SCHEMA_ROUTE} from '../../../../defs/schema/public/TaskBlockers';
import {ITask, TASK_SCHEMA_ROUTE} from '../../../../defs/schema/public/Tasks';
import {AuthService} from '../auth/auth.service';
import {FormsAddTaskComponent} from '../forms/add-task/add-task.component';
import {ModalComponent} from '../modal/modal.component';
import {ConfigService} from '../shared/config/config.service';
import {ControlFlowService} from '../shared/control-flow/control-flow.service';
import {HttpRestService} from '../shared/http-rest/http-rest.service';
import {MomentService} from '../shared/moment/moment.service';
import {ShortcutHandlerService, SHORTCUT_MULTIPLE_ADD} from '../shared/shortcut-handler/shortcut-handler.service';
import {ToastService, TOAST_TYPE} from '../shared/toast/toast.service';

export interface ITempTask {
    id?: number;
    beginDate: Moment;
    endDate: Moment;
    parentTaskId: number;
    targetReleaseId: number;
    estimatedTime: string;
    projectId: number;
    bug: boolean;
    urgent: boolean;
    name: string;
}

@Component({
    selector: 'app-add-multiple-task',
    templateUrl: './add-multiple-task.component.html',
    styleUrls: ['./add-multiple-task.component.scss'],
})
export class AddMultipleTaskComponent extends ModalComponent implements OnInit {
    @Input()
    public addTaskComponent: FormsAddTaskComponent;

    private readonly DEFAULT_TASK: ITempTask = {
        beginDate: this.momentService.moment(),
        endDate: this.momentService.moment(),
        name: '',
        parentTaskId: 0,
        estimatedTime: '',
        projectId: null,
        targetReleaseId: null,
        bug: false,
        urgent: false,
    };

    public tempTasks: Partial<ITempTask>[] = [];
    public parentTask: Partial<ITempTask> = {...this.DEFAULT_TASK};
    public blockerTask: Partial<ITempTask> = {...this.DEFAULT_TASK};

    public inactiveProjects: IProject[] = [];
    public activeProjects: IProject[] = [];
    public error = '';

    public released: IMilestone[];
    public staged: IMilestone[];
    public releases: IMilestone[];
    public unreleased: IMilestone[];

    public constructor(
        public shortcutHandlerService: ShortcutHandlerService,
        public changeDetectorRef: ChangeDetectorRef,
        private readonly authService: AuthService,
        private readonly httpRest: HttpRestService,
        controlFlowService: ControlFlowService,
        private readonly translate: TranslateService,
        private readonly toastService: ToastService,
        private readonly momentService: MomentService,
        private readonly configService: ConfigService
    ) {
        super(shortcutHandlerService, controlFlowService, changeDetectorRef);
    }

    @ViewChild('blockerEstimated')
    public blockerEstimated: ElementRef;

    @ViewChildren('childEstTime')
    public childEstTime: ElementRef[];

    public ngOnInit() {
        super.ngOnInit();
    }

    public getBlockerEstimatedValid() {
        if (!this.blockerEstimated) {
            return false;
        }

        return (
            this.blockerEstimated.nativeElement.validity.valid &&
            (this.blockerTask.estimatedTime === '' || this.isUnderLimitWeeks(this.blockerTask.estimatedTime))
        );
    }

    public getChildrenEstimatedValid() {
        if (!this.childEstTime) {
            return false;
        }

        let oneTaskFail = false;
        this.tempTasks.map((child) => {
            if (child.estimatedTime && child.estimatedTime !== '' && !this.isUnderLimitWeeks(child.estimatedTime)) {
                oneTaskFail = true;
            }
        });
        if (oneTaskFail) {
            return true;
        }

        return (
            this.childEstTime.filter((e) => {
                return !e.nativeElement.validity.valid;
            }).length === 1
        );
    }

    public isUnderLimitWeeks(estimatedTime: string) {
        return !overEstimatedTime(this.configService.config, estimatedTime);
    }

    public load() {
        const projectId = this.addTaskComponent.form.controls.project.value;
        const releaseId = this.addTaskComponent.form.controls.releaseId.value;

        this.shortcutHandlerService.register({
            shortcut: SHORTCUT_MULTIPLE_ADD.ADD_TEMP,
            callback: () => {
                this.newTempTask();
            },
            context: this,
            forceListen: true,
        });
        this.parentTask = {
            ...this.DEFAULT_TASK,
            projectId,
        };
        this.blockerTask = {...this.DEFAULT_TASK};
        this.tempTasks = [];
        this.newTempTask(true);
        this.httpRest
            ._request<IProject[]>(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, PROJECT_SCHEMA_ROUTE, 'light')
            .subscribe(async (projects) => {
                // this.activeProjects = projects.filter((project) => project.status !== ProjectStatusType.CLOSE);
                this.activeProjects = projects
                    .filter((p) => p.status === ProjectStatusType.OPEN)
                    .sort((a, b) => {
                        return a.obs.localeCompare(b.obs);
                    })
                    .concat(
                        projects
                            .filter((p) => p.status === ProjectStatusType.WAITING)
                            .sort((a, b) => {
                                return a.obs.localeCompare(b.obs);
                            })
                    )
                    .concat(
                        projects
                            .filter((p) => p.status === ProjectStatusType.CLOSE)
                            .sort((a, b) => {
                                return a.obs.localeCompare(b.obs);
                            })
                    );

                this.inactiveProjects = projects.filter((project) => project.status === ProjectStatusType.CLOSE);

                this.getReleasesByProject();
                this.parentTask.targetReleaseId = releaseId;
            });
    }

    public getRelease(releaseId: number) {
        if (!this.releases) {
            return null;
        }

        return this.releases.find((r) => r.id === releaseId);
    }

    public getReleasesByProject(clear = false) {
        if (clear) {
            this.parentTask.projectId = null;
            this.parentTask.targetReleaseId = null;
            this.releases = [];

            return;
        }
        if (!this.parentTask.projectId) {
            return;
        }
        this.parentTask.targetReleaseId = null;
        this.httpRest
            ._request<IMilestone[]>(
                HTTP_METHOD.GET,
                ApiRoutePlurality.PLURAL,
                MILESTONE_SCHEMA_ROUTE,
                `release/project/${this.parentTask.projectId}`
            )
            .subscribe((releases) => {
                this.releases = releases.sort((a, b) => {
                    if (
                        a.releaseState === ReleaseStateType.IN_DEVELOPMENT &&
                        b.releaseState === ReleaseStateType.IN_DEVELOPMENT
                    ) {
                        return SemVer.compare(a.version, b.version);
                    } else if (
                        a.releaseState === ReleaseStateType.IN_DEVELOPMENT &&
                        b.releaseState !== ReleaseStateType.IN_DEVELOPMENT
                    ) {
                        return -1;
                    }

                    return SemVer.compare(b.version, a.version);
                });
            });
    }

    public newTempTask(force = false) {
        if (!this.show) {
            return;
        }
        const tempTask = {
            name: '',
            estimatedTime: '',
            beginDate: this.parentTask.beginDate,
            endDate: this.parentTask.endDate,
            targetReleaseId: this.parentTask.targetReleaseId,
            projectId: this.parentTask.projectId,
            bug: this.parentTask.bug,
            urgent: this.parentTask.urgent,
        };
        this.tempTasks.push({...tempTask});
        window.setTimeout(() => {
            if (this.show && !force) {
                const taskNodes = Array.from(document.querySelectorAll('.ml-10'));
                (taskNodes[taskNodes.length - 1] as HTMLInputElement).focus();
            }
            this.changeDetectorRef.markForCheck();
        }, 200);
    }

    public submitTempTasks() {
        this.error = '';
        if (
            this.parentTask.name &&
            this.parentTask.projectId &&
            this.tempTasks.filter((task) => task.name).length > 0
        ) {
            this.httpRest
                .put<ITask>(TASK_SCHEMA_ROUTE, {
                    authorId: this.authService.user.employee.id,
                    beginDate: this.parentTask.beginDate,
                    endDate: this.parentTask.endDate,
                    estimatedTime: this.parentTask.estimatedTime,
                    name: this.parentTask.name,
                    parentId: null,
                    projectId: this.parentTask.projectId,
                    targetReleaseId: this.parentTask.targetReleaseId || null,
                    tracked: true,
                    type: TaskType[this.parentTask.bug ? 'BUG' : 'TASK'],
                    urgent: this.parentTask.urgent,
                })
                .subscribe((t) => {
                    this.parentTask.id = t.id;
                    this.saveBlocker();
                });
        } else {
            this.error = 'task_multiple_parent_error';
        }
    }

    public saveBlocker() {
        if (this.blockerTask.name) {
            this.httpRest
                .put<ITask>(TASK_SCHEMA_ROUTE, {
                    authorId: this.authService.user.employee.id,
                    beginDate: this.blockerTask.beginDate,
                    endDate: this.blockerTask.endDate,
                    estimatedTime: this.blockerTask.estimatedTime,
                    name: this.blockerTask.name,
                    parentId: this.parentTask.id,
                    projectId: this.parentTask.projectId,
                    targetReleaseId: this.parentTask.targetReleaseId || null,
                    tracked: true,
                    type: TaskType[this.blockerTask.bug ? 'BUG' : 'TASK'],
                    urgent: this.blockerTask.urgent,
                })
                .subscribe((t) => {
                    this.blockerTask.id = t.id;
                    this.saveTasks();
                });
        } else {
            this.saveTasks();
        }
    }

    public deleteTask(task: ITempTask, index: number) {
        this.tempTasks.splice(index, 1);
    }

    public clearBlocker() {
        this.blockerTask = {...this.DEFAULT_TASK};
    }

    public clearParent() {
        this.parentTask = {...this.DEFAULT_TASK};
    }
    public saveTasks() {
        forkJoin(
            this.tempTasks
                .filter((task) => task.name)
                .map((task) => {
                    const obs = this.httpRest.put<ITask>(TASK_SCHEMA_ROUTE, {
                        authorId: this.authService.user.employee.id,
                        beginDate: task.beginDate,
                        endDate: task.endDate,
                        estimatedTime: task.estimatedTime,
                        name: task.name,
                        parentId: this.parentTask.id || null,
                        projectId: this.parentTask.projectId,
                        targetReleaseId: this.parentTask.targetReleaseId || null,
                        tracked: true,
                        type: TaskType[task.bug ? 'BUG' : 'TASK'],
                        urgent: task.urgent,
                    });
                    obs.subscribe(async (t) => {
                        if (this.blockerTask.id) {
                            this.httpRest.put<ITaskBlocker>(TASK_BLOCKER_SCHEMA_ROUTE, {
                                taskId: t.id,
                                blockerId: this.blockerTask.id,
                            });
                        }
                    });

                    return obs;
                })
        ).subscribe(async (e) => {
            this.showChange.emit((this.show = false));
            this.toastService.show({
                type: TOAST_TYPE.SUCCESS,
                text: 'success_insert_mult_task',
            });
        });
    }

    public readonly PATTERN_TIME = PATTERN_TIME;
}
