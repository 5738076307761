var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiRoutePlurality, BudgetStatus, HTTP_METHOD, ProjectMemberType, RIGHTS } from '../../../../defs/schema-static';
import { PROJECT_SCHEMA_ROUTE } from '../../../../defs/schema/public/Projects';
import { CLIENT_SORT_FUNCTION } from '../../../../defs/sorters';
import { AuthService } from '../auth/auth.service';
import { validate } from '../forms/validators/form.validator';
import { HttpRestService } from '../shared/http-rest/http-rest.service';
var ProjectsComponent = (function () {
    function ProjectsComponent(httpRest, route, router, authService) {
        var _this = this;
        this.httpRest = httpRest;
        this.route = route;
        this.router = router;
        this.authService = authService;
        this.clients = [];
        this.showCreateModal = false;
        this.validate = validate;
        this.RIGHTS = RIGHTS;
        this.httpRest
            ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, PROJECT_SCHEMA_ROUTE, 'withBudgets')
            .subscribe(function (_a) {
            var projects = _a.projects, clients = _a.clients, employees = _a.employees;
            _this.clients = clients.sort(CLIENT_SORT_FUNCTION);
            projects.map(function (project) {
                return project.projectMembers.map(function (projectMember) {
                    return (projectMember.employee = employees.find(function (_a) {
                        var id = _a.id;
                        return id === projectMember.employeeId;
                    }));
                });
            });
            _this.projects = projects.map(function (project) { return (__assign({ client: clients.find(function (_a) {
                    var id = _a.id;
                    return id === project.clientId;
                }), roleType: project.projectMembers
                    .filter(function (projectMember) { return projectMember.employeeId === _this.authService.user.employee.id; })
                    .map(function (projectMember) { return projectMember.type; })[0] || undefined, managers: (project.projectMembers || []).filter(function (projectMember) { return projectMember.type === ProjectMemberType.MANAGER; }), budgetSum: project.budgets
                    .filter(function (budget) { return budget.status !== BudgetStatus.CANCELLED; })
                    .reduce(function (sum, budget) { return sum + budget.price; }, 0) || 0 }, project)); });
        });
        this.route.queryParams.subscribe(function (queryParams) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                if (queryParams.action === 'create' && queryParams.clientId) {
                    this.queryClientId = Number(queryParams.clientId);
                    this.showCreateModal = true;
                }
                if (queryParams.clientId || queryParams.action) {
                    requestAnimationFrame(function () { return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            return [2, this.router.navigate([], {
                                    relativeTo: this.route,
                                    queryParams: { action: null, clientId: null },
                                    queryParamsHandling: 'merge',
                                })];
                        });
                    }); });
                }
                return [2];
            });
        }); });
    }
    return ProjectsComponent;
}());
export { ProjectsComponent };
