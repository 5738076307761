<div class="spinner-bg-c" style="margin-left: auto;margin-right: auto;display: contents;" *ngIf="loading">
    <div class="spinner-c"></div>
</div>

<div *ngIf="!loading">
    <div class="clr-row">
        <div class="clr-col-2 btn-group p-0" style="min-width:185px;">
            <button
                type="button"
                class="btn"
                [ngClass]="{active: currentView === GANTT_VIEW_VALUES.DAY}"
                (click)="changeViewMode(GANTT_VIEW_VALUES.DAY)"
            >
                {{ 'date_day' | translate }}
            </button>
            <button
                type="button"
                class="btn"
                [ngClass]="{active: currentView === GANTT_VIEW_VALUES.WEEK}"
                (click)="changeViewMode(GANTT_VIEW_VALUES.WEEK)"
            >
                {{ 'date_week' | translate }}
            </button>
            <button
                *ngIf="currentMode === GANTT_MODE_VALUES.PROJECTS"
                type="button"
                class="btn"
                [ngClass]="{active: currentView === GANTT_VIEW_VALUES.MONTH}"
                (click)="changeViewMode(GANTT_VIEW_VALUES.MONTH)"
            >
                {{ 'date_month' | translate }}
            </button>
        </div>

        <div class="clr-col-9" style="margin-top: -1rem;">
            <ng-select
                *ngIf="!loading"
                #projectSelect
                class="less-pl pb-0"
                [items]="projectList"
                bindValue="id"
                bindLabel="obs"
                multiple="true"
                [closeOnSelect]="false"
                [hideSelected]="true"
                [placeholder]="'project_select' | translate"
                (clear)="filterProject()"
                (change)="filterProject($event)"
            >
                <ng-template ng-option-tmp let-item="item" let-index="index">
                    <app-long-avatar class="text-bold float-left mr-02" [code]="item?.code" [bgColor]="item?.color">
                    </app-long-avatar>
                    {{ item?.obs }}
                </ng-template>
                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                    <app-long-avatar
                        class="text-bold float-left mr-02 smaller"
                        [code]="item?.code"
                        [bgColor]="item?.color"
                    >
                    </app-long-avatar>
                    {{ item?.obs }}
                    <span class="ng-value-icon right ml-01" (click)="clear(item)" aria-hidden="true">×</span>
                </ng-template>
            </ng-select>
        </div>
        <!-- <div class="clr-col-4">
            <label class="clr-control-label" for="projectstatus">{{ 'filter_status_project' | translate }}</label>
            <select
                *ngIf="this.projects"
                id="projectstatus"
                name="projectstatus"
                clrSelect
                [ngModel]="projectStatusFilter"
                style="margin-top: -0.4em;"
                (change)="filterProjectStatus($event.target.value)"
            >
                <option [value]="null" translate>select_all </option>
                <option *ngFor="let status of (PROJECT_STATUS_TYPE | sharedArraySort)" [value]="status">
                    {{ 'project_' + status | lowercase | translate }}
                </option>
            </select>
        </div> -->
    </div>

    <app-gantt-projects
        [projects]="selectedProjects()"
        [timespan]="timespan"
        [maxDate]="maxDate"
        [minDate]="minDate"
        [projectColorList]="projectColorList"
        [msColorList]="msColorList"
        [taskColorList]="taskColorList"
        [fetching]="fetching"
        [tdWidth]="tdWidth"
        [MIN_WIDTH]="MIN_WIDTH"
        [currentView]="currentView"
        [projectStatusFilter]="projectStatusFilter"
        [clientFilter]="clientFilter"
        *ngIf="currentMode === GANTT_MODE_VALUES.PROJECTS"
    ></app-gantt-projects>
</div>
