var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnDestroy, OnInit } from '@angular/core';
import { ClrDatagridSortOrder } from '@clr/angular';
import { TranslateService } from '@ngx-translate/core';
import { ApiRoutePlurality, HTTP_METHOD, RIGHTS } from '../../../../defs/schema-static';
import { CLIENT_FIELD, CLIENT_SCHEMA_ROUTE } from '../../../../defs/schema/public/Clients';
import { USER_FIELD } from '../../../../defs/schema/public/Users';
import { EMPLOYEE_SORT_FUNCTION, PROJECT_SORT_FUNCTION } from '../../../../defs/sorters';
import { SelectFilterType, sortFnArrayWrapper } from '../app-static';
import { validate } from '../forms/validators/form.validator';
import { HttpRestService } from '../shared/http-rest/http-rest.service';
import { LONG_AVATAR_TYPE } from '../shared/long-avatar/long-avatar.component';
import { SHORTCUT_CREATE, SHORTCUT_LOCAL, ShortcutHandlerService, } from '../shared/shortcut-handler/shortcut-handler.service';
import { TOAST_TYPE, ToastService } from '../shared/toast/toast.service';
var ClientsComponent = (function () {
    function ClientsComponent(httpRest, shortcutHandlerService, translate, toastService) {
        this.httpRest = httpRest;
        this.shortcutHandlerService = shortcutHandlerService;
        this.translate = translate;
        this.toastService = toastService;
        this.pleaseWait = true;
        this.selectedClients = [];
        this.showCreateModal = false;
        this.showDeleteModal = false;
        this.validate = validate;
        this.RIGHTS = RIGHTS;
        this.LONG_AVATAR_TYPE = LONG_AVATAR_TYPE;
        this.SelectFilterType = SelectFilterType;
        this.filterOpen = {};
        this.CLIENTS_COLUMNS = [
            {
                field: CLIENT_FIELD.user + "." + USER_FIELD.name,
                translateKey: 'table_name',
                order: ClrDatagridSortOrder.ASC,
            },
            { field: CLIENT_FIELD.obs, translateKey: 'table_observation' },
            {
                field: 'contacts',
                translateKey: 'customer_contacts',
                propertyGetter: function (_a) {
                    var contacts = _a.contacts;
                    return (contacts || []).map(function (_a) {
                        var user = _a.user;
                        return (user && user.name) || '';
                    }).join(' ');
                },
                sorter: { compare: function (c1, c2) { return sortFnArrayWrapper(EMPLOYEE_SORT_FUNCTION)(c1.contacts, c2.contacts); } },
            },
            {
                field: 'projects',
                translateKey: 'projects',
                propertyGetter: function (_a) {
                    var projects = _a.projects;
                    return (projects || []).map(function (_a) {
                        var obs = _a.obs;
                        return obs || '';
                    }).join(' ');
                },
                sorter: { compare: function (c1, c2) { return sortFnArrayWrapper(PROJECT_SORT_FUNCTION)(c1.projects, c2.projects); } },
            },
        ];
    }
    ClientsComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (!this.clients) {
            (function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                return [2, this.getClients()];
            }); }); })();
        }
        this.shortcutHandlerService.register({
            name: SHORTCUT_CREATE.CLIENT,
            shortcut: SHORTCUT_LOCAL.CREATE,
            callback: function () { return (_this.showCreateModal = true); },
            context: this,
        }, true);
    };
    ClientsComponent.prototype.ngOnDestroy = function () { };
    ClientsComponent.prototype.getClients = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, clients, employees;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4, this.httpRest
                            ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, CLIENT_SCHEMA_ROUTE, 'listComplete')
                            .toPromise()];
                    case 1:
                        _a = _b.sent(), clients = _a.clients, employees = _a.employees;
                        this.employees = employees.sort(function (a, b) { return a.user.name.localeCompare(b.user.name); });
                        this.projects = [];
                        clients.map(function (c) {
                            _this.projects = _this.projects.concat((c.projects || []).slice()).sort(PROJECT_SORT_FUNCTION);
                        });
                        this.clients = clients.map(function (client) {
                            return (__assign({}, client, { projects: (client.projects || []).sort(PROJECT_SORT_FUNCTION), contacts: (client.contacts || [])
                                    .map(function (contact) { return employees.find(function (_a) {
                                    var id = _a.id;
                                    return id === contact.id;
                                }); })
                                    .filter(function (contact) { return !!contact; })
                                    .sort(EMPLOYEE_SORT_FUNCTION) }));
                        });
                        this.pleaseWait = false;
                        return [2];
                }
            });
        });
    };
    ClientsComponent.prototype.addNewClient = function (client) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.clients.push(__assign({}, client, { projects: client.projects || [] }));
                this.toastService.show({
                    type: TOAST_TYPE.SUCCESS,
                    text: 'success_create_client',
                });
                return [2];
            });
        });
    };
    ClientsComponent.prototype.deleteSelectedClients = function () {
        return __awaiter(this, void 0, void 0, function () {
            var deletedClientsIds;
            var _this = this;
            return __generator(this, function (_a) {
                deletedClientsIds = this.selectedClients.map(function (_a) {
                    var id = _a.id;
                    return id;
                });
                try {
                    deletedClientsIds.map(function (clientId) { return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            return [2, this.httpRest
                                    ._request(HTTP_METHOD.DELETE, ApiRoutePlurality.SINGULAR, CLIENT_SCHEMA_ROUTE, "withUser/" + clientId)
                                    .toPromise()];
                        });
                    }); });
                }
                catch (err) {
                    return [2];
                }
                finally {
                    this.showDeleteModal = false;
                }
                this.selectedClients = [];
                this.clients = this.clients.filter(function (_a) {
                    var id = _a.id;
                    return !deletedClientsIds.includes(id);
                });
                this.toastService.show({
                    type: TOAST_TYPE.SUCCESS,
                    text: 'success_delete_client',
                });
                return [2];
            });
        });
    };
    return ClientsComponent;
}());
export { ClientsComponent };
