import moment from 'moment';
import {ISchemaRoute, ProjectStatusType} from '../../schema-static';
import {IBudget} from './Budget';
import {IClient} from './Clients';
import {IMilestone} from './Milestones';
import {IProjectMember} from './ProjectMembers';
import {ITask} from './Tasks';

export enum PROJECT_FIELD {
    id = 'id',
    createdAt = 'createdAt',
    updatedAt = 'updatedAt',
    obs = 'obs',
    status = 'status',
    code = 'code',
    discordPath = 'discordPath',
    backupPrefix = 'backupPrefix',
    clientId = 'clientId',
    color = 'color',
    endDate = 'endDate',
    repositoryUrl = 'repositoryUrl',
    projectMembers = 'projectMembers',
    client = 'client',
    tasks = 'tasks',
    milestones = 'milestones',
    budgets = 'budgets',
}

export interface IProjectBare {
    id?: number;
    createdAt?: moment.Moment;
    updatedAt?: moment.Moment;
    obs: string;
    status: ProjectStatusType;
    code?: string;
    discordPath?: string;
    backupPrefix?: string;
    clientId: number;
    color?: string;
    endDate?: moment.Moment;
    repositoryUrl?: string;
    projectMembers?: Partial<IProjectMember>[];
}

export interface IProjectBelong {
    client?: Partial<IClient>;
}

export interface IProjectMany {
    tasks?: Partial<ITask>[];
    milestones?: Partial<IMilestone>[];
    budgets?: Partial<IBudget>[];
}

export interface IProject extends IProjectBare, IProjectBelong, IProjectMany {}

const PROJECT_SCHEMA_ROUTE: ISchemaRoute = {
    tableName: 'projects',
    singularRoute: 'project',
    pluralRoute: 'projects',
};

export {PROJECT_SCHEMA_ROUTE};
