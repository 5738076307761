import {NgModule} from '@angular/core';
import {Route, RouterModule} from '@angular/router';
import {AUTH_MODULE_ROUTE_PATH} from '../app-static';
import {LogInComponent} from './log-in/log-in.component';
import {LogOutComponent} from './log-out/log-out.component';
import {SignUpComponent} from './sign-up/sign-up.component';
import {UnauthorizedComponent} from './unauthorized/unauthorized.component';

export const authModuleRoutes: Route[] = [
    {path: AUTH_MODULE_ROUTE_PATH.LOG_IN, component: LogInComponent},
    {path: AUTH_MODULE_ROUTE_PATH.LOG_OUT, component: LogOutComponent},
    // {path: AUTH_MODULE_ROUTE_PATH.SIGN_UP, component: SignUpComponent},
    {path: AUTH_MODULE_ROUTE_PATH.UNAUTHORIZED, component: UnauthorizedComponent},
];

export const authRoutes: Route[] = [
    {path: '', redirectTo: AUTH_MODULE_ROUTE_PATH.LOG_IN, pathMatch: 'full'},
    {
        path: '',
        children: [...authModuleRoutes],
    },
];

@NgModule({
    imports: [RouterModule.forRoot([...authRoutes])],
    exports: [RouterModule],
})
export class AuthRoutingModule {
    // . Dynamic routes, prevent AOT errors
    /*public constructor(router: Router) {
    authRoutes.push(...dynamicRoutes);

    router.config.push(...dynamicRoutes);
    router.resetConfig(router.config);
  }*/
}
