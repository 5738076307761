import { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import moment from 'moment';
import { Subject } from 'rxjs';
import { ISO_DATE_FORMAT, ISO_DATE_TIME_FORMAT } from '../../../../../defs/schema-static';
import { MomentService } from '../moment/moment.service';
import { NestedProperty } from '../nested-properties';
export var DATAGRID_FILTER_DATE_RANGE_TYPE;
(function (DATAGRID_FILTER_DATE_RANGE_TYPE) {
    DATAGRID_FILTER_DATE_RANGE_TYPE["INTERVAL"] = "INTERVAL";
    DATAGRID_FILTER_DATE_RANGE_TYPE["FROM"] = "FROM";
})(DATAGRID_FILTER_DATE_RANGE_TYPE || (DATAGRID_FILTER_DATE_RANGE_TYPE = {}));
export var DATAGRID_FILTER_DATE_DATE_TYPE;
(function (DATAGRID_FILTER_DATE_DATE_TYPE) {
    DATAGRID_FILTER_DATE_DATE_TYPE["DATE"] = "DATE";
    DATAGRID_FILTER_DATE_DATE_TYPE["DATE_TIME"] = "DATE_TIME";
})(DATAGRID_FILTER_DATE_DATE_TYPE || (DATAGRID_FILTER_DATE_DATE_TYPE = {}));
var DateDatagridFilterComponent = (function () {
    function DateDatagridFilterComponent(momentService) {
        this.momentService = momentService;
        this.filter = '';
        this.rangeType = DATAGRID_FILTER_DATE_RANGE_TYPE.INTERVAL;
        this.dateType = DATAGRID_FILTER_DATE_DATE_TYPE.DATE;
        this.filterOpen = false;
        this.changes = new Subject();
        this.idIncrement = 0;
        this.DATAGRID_FILTER_DATE_TYPE = DATAGRID_FILTER_DATE_DATE_TYPE;
        this.DATAGRID_FILTER_DATE_RANGE_TYPE = DATAGRID_FILTER_DATE_RANGE_TYPE;
        this.idIncrement = DateDatagridFilterComponent.ID_INCREMENT++;
    }
    DateDatagridFilterComponent.prototype.ngOnInit = function () {
        this.setFilterKey();
    };
    DateDatagridFilterComponent.prototype.ngOnChanges = function (changes) {
        if (changes.filterKey) {
            this.setFilterKey();
        }
    };
    DateDatagridFilterComponent.prototype.setFilterKey = function () {
        this.nestedProp = this.filterKey ? new NestedProperty(this.filterKey) : undefined;
        this.changes.next(this.filterKey);
    };
    DateDatagridFilterComponent.prototype.updateFilter = function () {
        this.filter = this.serialize().join('');
        this.changes.next(this.filterKey);
    };
    DateDatagridFilterComponent.prototype.accepts = function (item) {
        if (!this.filterKey) {
            return true;
        }
        var filterValue = this.filterValue;
        if (!filterValue) {
            return true;
        }
        var itemValue = this.getItemValue(item);
        if (!itemValue) {
            return false;
        }
        var from = filterValue[0], until = filterValue[1];
        if (this.rangeType === DATAGRID_FILTER_DATE_RANGE_TYPE.FROM) {
            return from && from.isAfter(itemValue);
        }
        return (!from || from.isBefore(itemValue)) && (!until || until.isAfter(itemValue));
    };
    DateDatagridFilterComponent.prototype.isActive = function () {
        var filterValue = this.filterValue;
        return filterValue && !!filterValue.filter(function (date) { return !!date; }).length;
    };
    DateDatagridFilterComponent.prototype.serialize = function () {
        var filterValue = this.filterValue;
        if (!filterValue) {
            return [];
        }
        return [filterValue.map(function (date) { return (date && date.format(ISO_DATE_TIME_FORMAT)) || 'null'; }).join(',')];
    };
    Object.defineProperty(DateDatagridFilterComponent.prototype, "filterValue", {
        get: function () {
            var _this = this;
            var serializeDate = function (date, time) {
                return date
                    ? moment(moment(date).format(ISO_DATE_FORMAT) + "T" + (_this.dateType === DATAGRID_FILTER_DATE_DATE_TYPE.DATE_TIME && time ? time : '00:00:00') + "Z")
                    : null;
            };
            if (this.rangeType === DATAGRID_FILTER_DATE_RANGE_TYPE.FROM) {
                return [serializeDate(this.filterDateStart, this.filterTimeStart)];
            }
            return [
                serializeDate(this.filterDateStart, this.filterTimeStart),
                serializeDate(this.filterDateEnd, this.filterTimeEnd),
            ];
        },
        enumerable: true,
        configurable: true
    });
    DateDatagridFilterComponent.prototype.getItemValue = function (item) {
        if (!this.nestedProp) {
            return null;
        }
        var value = this.nestedProp.getPropValue(item);
        return value && this.momentService.moment(value, null, null, true);
    };
    DateDatagridFilterComponent.prototype.resetFilter = function () {
        this.filterDateStart = '';
        this.filterDateEnd = '';
        this.filterTimeStart = '';
        this.filterTimeEnd = '';
        this.updateFilter();
    };
    DateDatagridFilterComponent.ID_INCREMENT = 1;
    return DateDatagridFilterComponent;
}());
export { DateDatagridFilterComponent };
