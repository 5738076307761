import * as i0 from "./task-tag.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../long-avatar/long-avatar.component.ngfactory";
import * as i3 from "@angular/common";
import * as i4 from "ngx-color-picker";
import * as i5 from "../long-avatar/long-avatar.component";
import * as i6 from "@clr/angular";
import * as i7 from "@ngx-translate/core";
import * as i8 from "../../modal/modal.component.ngfactory";
import * as i9 from "../../modal/modal.component";
import * as i10 from "../shortcut-handler/shortcut-handler.service";
import * as i11 from "../control-flow/control-flow.service";
import * as i12 from "../../modal/modal.directives";
import * as i13 from "./task-tag.component";
import * as i14 from "../http-rest/http-rest.service";
var styles_TaskTagComponent = [i0.styles];
var RenderType_TaskTagComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TaskTagComponent, data: {} });
export { RenderType_TaskTagComponent as RenderType_TaskTagComponent };
function View_TaskTagComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 4, "app-long-avatar", [["class", "avatar"]], null, [[null, "colorPickerChange"], [null, "colorPickerClose"], [null, "click"], [null, "focus"], [null, "input"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).handleClick() !== false);
        ad = (pd_0 && ad);
    } if (("focus" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).handleFocus() !== false);
        ad = (pd_1 && ad);
    } if (("input" === en)) {
        var pd_2 = (i1.ɵnov(_v, 3).handleInput($event) !== false);
        ad = (pd_2 && ad);
    } if (("colorPickerChange" === en)) {
        var pd_3 = ((_co.tag.color = $event) !== false);
        ad = (pd_3 && ad);
    } if (("colorPickerClose" === en)) {
        var pd_4 = (_co.update.emit(_co.tag) !== false);
        ad = (pd_4 && ad);
    } return ad; }, i2.View_LongAvatarComponent_0, i2.RenderType_LongAvatarComponent)), i1.ɵdid(1, 278528, null, 0, i3.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(2, { "background-color": 0 }), i1.ɵdid(3, 671744, null, 0, i4.ColorPickerDirective, [i1.Injector, i1.ComponentFactoryResolver, i1.ApplicationRef, i1.ViewContainerRef, i1.ElementRef, i4.ColorPickerService], { colorPicker: [0, "colorPicker"], cpOutputFormat: [1, "cpOutputFormat"], cpOKButton: [2, "cpOKButton"], cpCancelButton: [3, "cpCancelButton"] }, { colorPickerClose: "colorPickerClose", colorPickerChange: "colorPickerChange" }), i1.ɵdid(4, 49152, null, 0, i5.LongAvatarComponent, [], { bgColor: [0, "bgColor"], hidden: [1, "hidden"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, _co.tag.color); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.tag.color; var currVal_2 = "hex"; var currVal_3 = true; var currVal_4 = true; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = _co.tag.color; var currVal_6 = true; _ck(_v, 4, 0, currVal_5, currVal_6); }, null); }
function View_TaskTagComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-long-avatar", [["class", "avatar"]], null, null, null, i2.View_LongAvatarComponent_0, i2.RenderType_LongAvatarComponent)), i1.ɵdid(1, 278528, null, 0, i3.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(2, { "background-color": 0 }), i1.ɵdid(3, 49152, null, 0, i5.LongAvatarComponent, [], { bgColor: [0, "bgColor"], hidden: [1, "hidden"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, _co.tag.color); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.tag.color; var currVal_2 = true; _ck(_v, 3, 0, currVal_1, currVal_2); }, null); }
function View_TaskTagComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "clr-icon", [["class", "c-hand"], ["shape", "tree"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.updateBranch.emit(_co.tag) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i3.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(2, { opacity: 0 }), i1.ɵdid(3, 16384, null, 0, i6.ClrIconCustomTag, [], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, (_co.tag.branch ? 1 : 0.3)); _ck(_v, 1, 0, currVal_0); }, null); }
function View_TaskTagComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "clr-icon", [["shape", "tree"]], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i6.ClrIconCustomTag, [], null, null)], null, null); }
function View_TaskTagComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "clr-icon", [["class", "c-hand"], ["shape", "times"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.doDelete() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i6.ClrIconCustomTag, [], null, null)], null, null); }
function View_TaskTagComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "clr-icon", [["class", "c-hand"], ["shape", "trash"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.delete() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i6.ClrIconCustomTag, [], null, null)], null, null); }
function View_TaskTagComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpod(2, { count: 0 }), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 3).transform("tag_delete_confirm_count", _ck(_v, 2, 0, (_co.count || 0)))); _ck(_v, 1, 0, currVal_0); }); }
function View_TaskTagComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, [" ", " "])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(2, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TaskTagComponent_8)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_1 = !!_co.count; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 1).transform("tag_delete_confirm")); _ck(_v, 0, 0, currVal_0); }); }
function View_TaskTagComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-secondary"], ["translate", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.showDeleteModal = false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 8536064, null, 0, i7.TranslateDirective, [i7.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["form_cancel"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["class", "btn btn-danger"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.doDelete() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("form_validate")); _ck(_v, 4, 0, currVal_1); }); }
export function View_TaskTagComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "span", [["class", "label label-gray"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TaskTagComponent_1)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TaskTagComponent_2)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TaskTagComponent_3)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TaskTagComponent_4)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["class", "span-tag px-02"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TaskTagComponent_5)), i1.ɵdid(12, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TaskTagComponent_6)), i1.ɵdid(14, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 9, "app-modal", [], null, [[null, "showChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("showChange" === en)) {
        var pd_0 = ((_co.showDeleteModal = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_ModalComponent_0, i8.RenderType_ModalComponent)), i1.ɵdid(16, 770048, null, 3, i9.ModalComponent, [i10.ShortcutHandlerService, i11.ControlFlowService, i1.ChangeDetectorRef], { show: [0, "show"], title: [1, "title"] }, { showChange: "showChange" }), i1.ɵqud(335544320, 1, { modalHeader: 0 }), i1.ɵqud(335544320, 2, { modalBody: 0 }), i1.ɵqud(335544320, 3, { modalFooter: 0 }), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, [[2, 2]], null, 1, null, View_TaskTagComponent_7)), i1.ɵdid(22, 16384, null, 0, i12.ModalBodyDirective, [i1.TemplateRef], null, null), (_l()(), i1.ɵand(0, [[3, 2]], null, 1, null, View_TaskTagComponent_9)), i1.ɵdid(24, 16384, null, 0, i12.ModalFooterDirective, [i1.TemplateRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.updatable; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.updatable; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.branchable; _ck(_v, 6, 0, currVal_2); var currVal_3 = (!_co.branchable && _co.tag.branch); _ck(_v, 8, 0, currVal_3); var currVal_5 = _co.removable; _ck(_v, 12, 0, currVal_5); var currVal_6 = _co.deletable; _ck(_v, 14, 0, currVal_6); var currVal_7 = _co.showDeleteModal; var currVal_8 = i1.ɵunv(_v, 16, 1, i1.ɵnov(_v, 20).transform("delete_tag")); _ck(_v, 16, 0, currVal_7, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.tag.text; _ck(_v, 10, 0, currVal_4); }); }
export function View_TaskTagComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "shared-task-tag", [], null, null, null, View_TaskTagComponent_0, RenderType_TaskTagComponent)), i1.ɵdid(1, 49152, null, 0, i13.TaskTagComponent, [i14.HttpRestService], null, null)], null, null); }
var TaskTagComponentNgFactory = i1.ɵccf("shared-task-tag", i13.TaskTagComponent, View_TaskTagComponent_Host_0, { tag: "tag", updatable: "updatable", branchable: "branchable", removable: "removable", deletable: "deletable" }, { update: "update", updateBranch: "updateBranch", remove: "remove" }, []);
export { TaskTagComponentNgFactory as TaskTagComponentNgFactory };
