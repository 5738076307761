var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiRoutePlurality, AssignedEmployeeType, HTTP_METHOD, MilestonesType, TaskStatusType, } from '../../../../defs/schema-static';
import { MILESTONE_SCHEMA_ROUTE } from '../../../../defs/schema/public/Milestones';
import { PROJECT_SCHEMA_ROUTE } from '../../../../defs/schema/public/Projects';
import { TASK_MAIL_SCHEMA_ROUTE } from '../../../../defs/schema/public/TaskMails';
import { TASK_SCHEMA_ROUTE } from '../../../../defs/schema/public/Tasks';
import { SETTINGS, SETTINGS_FILTER_ROADMAP_VALUES } from '../../../../defs/schema/public/Users';
import { chartMainColor } from '../app-static';
import { AuthService } from '../auth/auth.service';
import { SettingsService } from '../settings/settings.service';
import { HttpRestService } from '../shared/http-rest/http-rest.service';
import { MomentService } from '../shared/moment/moment.service';
var DashboardComponent = (function () {
    function DashboardComponent(httpRest, auth, settingsService, translate, momentService) {
        this.httpRest = httpRest;
        this.auth = auth;
        this.settingsService = settingsService;
        this.translate = translate;
        this.momentService = momentService;
        this.pieTasksShow = false;
        this.pieBugsShow = false;
        this.pieTimeSheetShow = false;
        this.pieCompletionShow = false;
        this.pleaseWaitActivities = true;
        this.activities = [];
        this.projectsStateShow = false;
        this.projectsDivisionShow = false;
        this.pleaseWaitRoad = true;
        this.pleaseWaitTask = true;
        this.pleaseWaitMail = true;
        this.pleaseWaitMeeting = true;
        this.colors = chartMainColor;
        this.rodmapSelectOptions = [
            {
                i18n: 'show_all_releases',
                value: SETTINGS_FILTER_ROADMAP_VALUES.ALL,
            },
            {
                i18n: 'only_my_releases',
                value: SETTINGS_FILTER_ROADMAP_VALUES.MY,
            },
        ];
        this.isDark = function () { return document.querySelector('.Wrapper').classList.contains('theme-dark'); };
        this.getCompletion = function (project) {
            if (!project) {
                return 0;
            }
            return parseFloat(((project.completed / project.total) * 100).toFixed(0));
        };
        this.getDevVSTest = function (project) {
            return project.dev + " / " + project.test;
        };
        this.getFailedReviewRate = function (project) {
            if (project.dev === 0) {
                return (0).toFixed(2);
            }
            return ((project.failedReview / project.dev) * 100).toFixed(2);
        };
    }
    DashboardComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.getPieTasks();
        this.getPieBugs();
        this.getOverallCompletion();
        this.getTimeSheet();
        this.getProjectsDivision();
        this.getProjectsState();
        this.getRoadMap().subscribe(function (milestones) {
            _this.roadmapTmp = JSON.parse(JSON.stringify(milestones));
            _this.roadmapFilter =
                _this.settingsService.settings[SETTINGS.ROADMAP_FILTER] || SETTINGS_FILTER_ROADMAP_VALUES.ALL;
            _this.changeRoadmapFilter();
            _this.pleaseWaitRoad = false;
        });
        this.getCalendarEvents().subscribe(function (milestones) {
            _this.events = [];
            milestones.map(function (milestone) {
                if (milestone.type === MilestonesType.MEETING) {
                    if (milestone.participants.findIndex(function (p) { return p.employeeId === _this.auth.user.employee.id; }) > -1) {
                        _this.events.push({
                            avatar: DashboardComponent.getMileStoneAvatar(milestone),
                            id: milestone.id,
                            subtitle: milestone.obs,
                            type: milestone.type,
                            color: DashboardComponent.getMilestoneColor(milestone),
                            title: DashboardComponent.getMilestoneTitle(milestone),
                            begin: _this.momentService.moment(milestone.beginDate),
                            end: _this.getEndDate(milestone),
                        });
                    }
                    return undefined;
                }
                _this.events.push({
                    avatar: DashboardComponent.getMileStoneAvatar(milestone),
                    id: milestone.id,
                    subtitle: milestone.obs,
                    type: milestone.type,
                    color: DashboardComponent.getMilestoneColor(milestone),
                    title: DashboardComponent.getMilestoneTitle(milestone),
                    begin: _this.momentService.moment(milestone.beginDate),
                    end: _this.getEndDate(milestone),
                });
            });
        });
        this.getAssignedTasks();
        this.getPastMeetings();
        this.getMails();
    };
    DashboardComponent.prototype.getProjectsState = function () {
        var _this = this;
        this.httpRest
            ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, PROJECT_SCHEMA_ROUTE, 'dashboard/state')
            .subscribe(function (data) {
            _this.projectsState = data;
            _this.projectsStateShow = true;
        });
    };
    DashboardComponent.prototype.getProjectsDivision = function () {
        var _this = this;
        this.httpRest
            ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, PROJECT_SCHEMA_ROUTE, 'dashboard/division')
            .subscribe(function (data) {
            _this.projectsDivision = data;
            _this.projectsDivisionShow = true;
        });
    };
    DashboardComponent.prototype.getTimeSheet = function () {
        var _this = this;
        this.httpRest
            ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, TASK_SCHEMA_ROUTE, 'dashboard/timesheet')
            .subscribe(function (data) { return __awaiter(_this, void 0, void 0, function () {
            var _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _c = this;
                        return [4, this.translateSerie(data)];
                    case 1:
                        _c.pieTimeSheet = _d.sent();
                        this.pieTimeSheetShow = true;
                        return [2];
                }
            });
        }); });
    };
    DashboardComponent.prototype.getPieTasks = function () {
        var _this = this;
        this.httpRest
            ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, TASK_SCHEMA_ROUTE, 'dashboard/division')
            .subscribe(function (data) { return __awaiter(_this, void 0, void 0, function () {
            var _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _c = this;
                        return [4, this.translateSerie(data)];
                    case 1:
                        _c.pieTasks = _d.sent();
                        this.pieTasksShow = true;
                        return [2];
                }
            });
        }); });
    };
    DashboardComponent.prototype.getPieBugs = function () {
        var _this = this;
        this.httpRest
            ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, TASK_SCHEMA_ROUTE, 'dashboard/bugs')
            .subscribe(function (data) { return __awaiter(_this, void 0, void 0, function () {
            var _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _c = this;
                        return [4, this.translateSerie(data, 'project_')];
                    case 1:
                        _c.pieBugs = _d.sent();
                        this.pieBugsShow = true;
                        return [2];
                }
            });
        }); });
    };
    DashboardComponent.prototype.getOverallCompletion = function () {
        var _this = this;
        this.httpRest
            ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, PROJECT_SCHEMA_ROUTE, 'dashboard/completion')
            .subscribe(function (data) { return __awaiter(_this, void 0, void 0, function () {
            var _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _c = this;
                        return [4, this.translateSerie(data, 'project_')];
                    case 1:
                        _c.pieCompletion = _d.sent();
                        this.pieCompletionShow = true;
                        return [2];
                }
            });
        }); });
    };
    DashboardComponent.prototype.changeRoadmapFilter = function () {
        var _this = this;
        if (this.roadmapFilter === SETTINGS_FILTER_ROADMAP_VALUES.ALL) {
            this.roadmap = this.roadmapTmp;
        }
        else {
            this.roadmap = this.roadmapTmp.filter(function (f) { return f.devs.indexOf(_this.auth.user.employee.id) !== -1; });
        }
        if (this.settingsService.settings[SETTINGS.ROADMAP_FILTER] !== this.roadmapFilter) {
            this.settingsService.settings[SETTINGS.ROADMAP_FILTER] = this.roadmapFilter;
            (function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_c) {
                return [2, this.settingsService.save()];
            }); }); })();
        }
    };
    DashboardComponent.getMileStoneAvatar = function (milestone) {
        if (milestone.client) {
            return milestone.client.user.code;
        }
        else {
            return milestone.project.code;
        }
    };
    DashboardComponent.getMilestoneColor = function (milestone) {
        if (milestone.client) {
            return milestone.client.user.color;
        }
        else {
            return milestone.project.color;
        }
    };
    DashboardComponent.prototype.translateSerie = function (serie, prefix) {
        if (prefix === void 0) { prefix = ''; }
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_c) {
                return [2, Promise.all(serie.map(function (m) { return __awaiter(_this, void 0, void 0, function () {
                        var _c;
                        return __generator(this, function (_d) {
                            switch (_d.label) {
                                case 0:
                                    _c = {
                                        value: m.value
                                    };
                                    return [4, this.translate.get(prefix + m.name.toLowerCase()).toPromise()];
                                case 1: return [2, (_c.name = _d.sent(),
                                        _c)];
                            }
                        });
                    }); }))];
            });
        });
    };
    DashboardComponent.getMilestoneTitle = function (milestone) {
        var base = milestone.type;
        if (milestone.client) {
            return milestone.client ? milestone.client.user.name + "(" + milestone.client.user.code + ")" : 'Unknown';
        }
        else {
            return milestone.project ? milestone.project.obs + "(" + milestone.project.code + ")" : 'Unknown';
        }
    };
    DashboardComponent.prototype.getEndDate = function (milestone) {
        if (milestone.type === MilestonesType.RELEASE) {
            return this.momentService.moment(milestone.endDate || milestone.target);
        }
        return null;
    };
    DashboardComponent.prototype.getCalendarEvents = function () {
        return this.httpRest._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, MILESTONE_SCHEMA_ROUTE, 'calendar/events');
    };
    DashboardComponent.prototype.dismissTaskMail = function (mail) {
        this.httpRest
            .post(TASK_MAIL_SCHEMA_ROUTE, {
            id: mail.id,
            dismissed: true,
        })
            .subscribe(function (_mail) {
            mail.dismissed = _mail.dismissed;
        });
    };
    DashboardComponent.prototype.getMails = function () {
        var _this = this;
        this.httpRest
            ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, TASK_MAIL_SCHEMA_ROUTE, 'employee')
            .subscribe(function (mails) {
            _this.mails = mails;
            _this.pleaseWaitMail = false;
        });
    };
    DashboardComponent.prototype.getPastMeetings = function () {
        var _this = this;
        this.httpRest
            ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, MILESTONE_SCHEMA_ROUTE, 'meeting')
            .subscribe(function (meetings) {
            _this.meetings = meetings
                .filter(function (meeting) {
                return (meeting.participants.length === 0 ||
                    meeting.participants.findIndex(function (p) { return p.employeeId === _this.auth.user.employee.id; }) > -1);
            })
                .sort(function (a, b) {
                var _a = _this.momentService.moment(a.beginDate);
                var _b = _this.momentService.moment(b.beginDate);
                return _a.isBefore(_b) ? 1 : -1;
            });
            _this.pleaseWaitMeeting = false;
        });
    };
    DashboardComponent.prototype.getRoadMap = function () {
        return this.httpRest._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, MILESTONE_SCHEMA_ROUTE, 'roadmap');
    };
    DashboardComponent.prototype.getAssignedTasks = function () {
        var _this = this;
        this.httpRest
            ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, TASK_SCHEMA_ROUTE, 'assigned')
            .subscribe(function (data) {
            _this.tasks = data;
            _this.tasks = _this.tasks.filter(function (t) {
                if (t.assigned[0].type === AssignedEmployeeType.DEVELOPER) {
                    return true;
                }
                else {
                    if (t.status === TaskStatusType.REVIEWING || t.status === TaskStatusType.REVIEW) {
                        return true;
                    }
                }
                return false;
            });
            _this.pleaseWaitTask = false;
        });
    };
    return DashboardComponent;
}());
export { DashboardComponent };
