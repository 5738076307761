import {Component, Input} from '@angular/core';
import {MILESTONE_TYPE_ICON, MilestonesType} from '../../../../defs/schema-static';
import {ITask} from '../../../../defs/schema/public/Tasks';
import {isColorDark} from '../app-static';
import {IGanttMilestone, IGanttProject} from '../gantt/gantt.component';

@Component({
    selector: 'app-gantt-popover',
    templateUrl: './gantt-popover.component.html',
    styleUrls: ['./gantt-popover.component.scss'],
})
export class GanttPopoverComponent {
    @Input()
    public project: IGanttProject;

    @Input()
    public milestone: IGanttMilestone;

    @Input()
    public task: ITask;

    public readonly MilestonesType = MilestonesType;
    public readonly MILESTONE_TYPE_ICON = MILESTONE_TYPE_ICON;
    public readonly isColorDark = isColorDark;
}
