import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ClrLoadingState} from '@clr/angular';
import {TranslateService} from '@ngx-translate/core';
import {noop, Observable} from 'rxjs';
import {IProjectDash} from '../../../../defs/businessRules';
import publicConfig from '../../../../defs/config/config.json';
import {
    ApiRoutePlurality,
    BudgetStatus,
    DECIMAL_RADIX,
    HTTP_METHOD,
    ProjectMemberType,
    ProjectStatusType,
    RIGHTS,
} from '../../../../defs/schema-static';
import {CLIENT_SCHEMA_ROUTE, IClient} from '../../../../defs/schema/public/Clients';
import {CUSTOMER_CONTACT_SCHEMA_ROUTE, ICustomerContact} from '../../../../defs/schema/public/CustomerContacts';
import {EMPLOYEE_SCHEMA_ROUTE, IEmployee} from '../../../../defs/schema/public/Employees';
import {IMilestone, MILESTONE_SCHEMA_ROUTE} from '../../../../defs/schema/public/Milestones';
import {PROJECT_SCHEMA_ROUTE} from '../../../../defs/schema/public/Projects';
import {IStickyNote, STICKY_NOTE_SCHEMA_ROUTE} from '../../../../defs/schema/public/StickyNote';
import {IUser, USER_SCHEMA_ROUTE} from '../../../../defs/schema/public/Users';
import {CUSTOMER_CONTACT_SORT_FUNCTION, PROJECT_STATUS_SORT_FUNCTION} from '../../../../defs/sorters';
import {
    CLIENT_TABS,
    CLIENT_TABS_LABELS,
    CLIENT_TABS_RIGHTS,
    IDatagridColumn,
    isColorDark,
    PROJECT_STATUS_FILTER,
} from '../app-static';
import {AuthService} from '../auth/auth.service';
import {ClientWizardClientComponent} from '../client-wizard/client/client-wizard-client.component';
import {ClientWizardCustomerContactsComponent} from '../client-wizard/customer-contacts/client-wizard-customer-contacts.component';
import {validate} from '../forms/validators/form.validator';
import {ModalSimpleComponent} from '../modal-simple/modal-simple.component';
import {ModalSimpleService} from '../modal-simple/modal-simple.service';
import {ProjectsListComponent} from '../projects/list/projects-list.component';
import {HttpRestService} from '../shared/http-rest/http-rest.service';
import {LONG_AVATAR_TYPE} from '../shared/long-avatar/long-avatar.component';
import {
    SHORTCUT_CREATE,
    SHORTCUT_LOCAL,
    ShortcutHandlerService,
} from '../shared/shortcut-handler/shortcut-handler.service';
import {TOAST_TYPE, ToastService} from '../shared/toast/toast.service';

enum LIST_TABLE_COLUMN {
    PAGE = 'PAGE',
    CLIENT = 'CLIENT',
    PROJECT = 'PROJECT',
    AUTHOR = 'AUTHOR',
    MODIFICATION_DATE = 'MODIFICATION_DATE',
}

const LIST_TABLE_COLUMN_NAME: {[column in LIST_TABLE_COLUMN]: string} = {
    [LIST_TABLE_COLUMN.PAGE]: 'Page',
    [LIST_TABLE_COLUMN.CLIENT]: 'Client',
    [LIST_TABLE_COLUMN.PROJECT]: 'Project',
    [LIST_TABLE_COLUMN.AUTHOR]: 'Author',
    [LIST_TABLE_COLUMN.MODIFICATION_DATE]: 'Modification date',
};

@Component({
    selector: 'app-client',
    templateUrl: './client.component.html',
    styleUrls: ['./client.component.scss'],
})
export class ClientComponent implements OnInit, OnDestroy {
    @ViewChild(ClientWizardClientComponent) public clientEditComponent: ClientWizardClientComponent;
    @ViewChild(ClientWizardCustomerContactsComponent)
    public contactsEditComponent: ClientWizardCustomerContactsComponent;

    public client: IClient = {
        obs: '',
        userId: 0,
        user: {
            code: '',
            name: '',
        },
        contacts: [],
    };

    public showEditModal = false;

    public projects: IProjectDash[];
    public milestones: IMilestone[];

    public readonly projectsColumns: IDatagridColumn[] = ProjectsListComponent.DEFAULT_PROJECTS_COLUMNS.map(
        (column) => ({
            ...column,
        })
    );

    public contacts: ICustomerContact[];
    public employees: IEmployee[];
    public stickyNotes: IStickyNote[] = [];

    public validateBtnState = ClrLoadingState.DEFAULT;

    public constructor(
        private readonly httpRest: HttpRestService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        public authService: AuthService,
        private readonly shortcutHandlerService: ShortcutHandlerService,
        private readonly translate: TranslateService,
        private readonly toastService: ToastService,
        private readonly modalSimpleService: ModalSimpleService
    ) {
        this.projectsColumns.find(({translateKey}) => translateKey === 'table_client').hidden = true;
    }

    public ngOnInit() {
        this.shortcutHandlerService.register(
            {
                name: SHORTCUT_CREATE.CLIENT,
                shortcut: SHORTCUT_LOCAL.CANCEL,
                callback: () => {
                    (async () => this.router.navigate(['/clients']))();
                },
                context: this,
            },
            true
        );

        this.route.params.subscribe(async (params) => {
            const clientId = parseInt(params.id, DECIMAL_RADIX);

            const getClient = async () =>
                (this.client = await this.httpRest
                    ._request<IClient>(HTTP_METHOD.GET, ApiRoutePlurality.SINGULAR, CLIENT_SCHEMA_ROUTE, `${clientId}`)
                    .toPromise());

            const mapProjectMembers = () => {
                if (!this.projects || !this.employees) {
                    return;
                }

                this.projects.map((project) =>
                    project.projectMembers.map(
                        (projectMember) =>
                            (projectMember.employee = this.employees.find(({id}) => id === projectMember.employeeId))
                    )
                );
            };

            const getEmployees = async () => {
                this.employees = await this.httpRest
                    ._request<IEmployee[]>(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, EMPLOYEE_SCHEMA_ROUTE, 'client')
                    .toPromise();

                const contacts = await this.httpRest
                    ._request<ICustomerContact[]>(
                        HTTP_METHOD.GET,
                        ApiRoutePlurality.PLURAL,
                        CUSTOMER_CONTACT_SCHEMA_ROUTE,
                        `client/${clientId}`
                    )
                    .toPromise();

                this.contacts = contacts
                    .map((contact) => ({
                        ...contact,
                        employee: this.employees.find(({id}) => id === contact.employeeId),
                    }))
                    .sort(CUSTOMER_CONTACT_SORT_FUNCTION);

                this.client.contacts = this.contacts.map(({employee}) => employee);

                mapProjectMembers();
            };

            const getProjects = async () => {
                const projects = await this.httpRest
                    ._request<IProjectDash[]>(
                        HTTP_METHOD.GET,
                        ApiRoutePlurality.PLURAL,
                        PROJECT_SCHEMA_ROUTE,
                        `client/${clientId}`
                    )
                    .toPromise();

                this.client.projects = this.projects = projects
                    .map((project) => ({
                        client: this.client,
                        roleType:
                            project.projectMembers
                                .filter(
                                    (projectMember) => projectMember.employeeId === this.authService.user.employee.id
                                )
                                .map((projectMember) => projectMember.type)[0] || undefined,
                        managers: (project.projectMembers || []).filter(
                            (projectMember) => projectMember.type === ProjectMemberType.MANAGER
                        ),
                        budgetSum:
                            project.budgets
                                .filter((budget) => budget.status !== BudgetStatus.CANCELLED)
                                .reduce((sum, budget) => sum + budget.price, 0) || 0,
                        ...project,
                    }))
                    .sort(PROJECT_STATUS_SORT_FUNCTION);

                mapProjectMembers();
            };

            const getMilestones = async () =>
                (this.milestones = await this.httpRest
                    ._request<IMilestone[]>(
                        HTTP_METHOD.GET,
                        ApiRoutePlurality.PLURAL,
                        MILESTONE_SCHEMA_ROUTE,
                        `client/${clientId}`
                    )
                    .toPromise());

            const getStickyNotes = async () =>
                (this.stickyNotes = await this.httpRest
                    ._request<IStickyNote[]>(
                        HTTP_METHOD.GET,
                        ApiRoutePlurality.PLURAL,
                        STICKY_NOTE_SCHEMA_ROUTE,
                        `client/${clientId}`
                    )
                    .toPromise());

            await getClient();

            return Promise.all([getEmployees(), getProjects(), getMilestones(), getStickyNotes()]);
        });
    }

    // tslint:disable-next-line: prefer-function-over-method no-empty
    public ngOnDestroy() {} // for shortcut handler

    public async saveClient() {
        if (!this.clientEditComponent.form || !this.clientEditComponent.form.valid) {
            return;
        }

        this.validateBtnState = ClrLoadingState.LOADING;

        const {obs = '', ...user} = this.clientEditComponent.form.getRawValue();
        const {contacts} = this.contactsEditComponent.form.getRawValue();

        const contactsToAdd = contacts.filter(
            (contact) => !this.contacts.find(({employeeId}) => contact.employeeId === employeeId)
        );
        const contactsToDelete = this.contacts.filter(
            (contact) => !contacts.find(({employeeId}) => contact.employeeId === employeeId)
        );

        try {
            const [_client, _user, _deletedConctactsCount, ...addedContacts] = await Promise.all(
                [
                    this.httpRest.post<IClient>(CLIENT_SCHEMA_ROUTE, {
                        id: this.client.id,
                        obs,
                    }),
                    this.httpRest.post<IUser>(USER_SCHEMA_ROUTE, {
                        id: this.client.user.id,
                        ...user,
                    }),
                    this.httpRest.deleteIds(CUSTOMER_CONTACT_SCHEMA_ROUTE, contactsToDelete.map(({id}) => id)),
                    ...contactsToAdd.map((contact) =>
                        this.httpRest.put<ICustomerContact>(CUSTOMER_CONTACT_SCHEMA_ROUTE, {
                            clientId: this.client.id,
                            employeeId: contact.employee.id,
                        })
                    ),
                ].map(async (observable: Observable<any>) => observable.toPromise())
            );

            contacts.map((contact) => {
                const addedContact = (addedContacts as ICustomerContact[]).find(
                    ({employeeId}) => employeeId === contact.employeeId
                );

                contact.id = (addedContact && addedContact.id) || contact.id;
            });
        } catch (err) {
            this.showEditModal = false;

            return;
        }

        this.validateBtnState = ClrLoadingState.SUCCESS;
        Object.assign(this.client, {obs});
        Object.assign(this.client.user, user);

        this.toastService.show({
            type: TOAST_TYPE.SUCCESS,
            text: 'success_update_client',
        });

        this.contacts = contacts as ICustomerContact[];
        this.client.contacts = this.contacts.map(({employee}) => employee);

        this.showEditModal = false;
    }

    public deleteClientModal() {
        this.modalSimpleService
            .open(ModalSimpleComponent, {
                title: 'client_delete_modal_title',
                contentI18n: 'swal_delete_attch',
                contentObj: {what: this.client.obs},
                ok: {
                    i18n: 'delete_client',
                    class: 'btn-danger',
                },
            })
            .subscribe((closed) => {
                if (closed.result) {
                    this.deleteClient();
                }
            }, noop);
    }

    public async deleteClient() {
        try {
            await this.httpRest
                ._request(
                    HTTP_METHOD.DELETE,
                    ApiRoutePlurality.SINGULAR,
                    CLIENT_SCHEMA_ROUTE,
                    `withUser/${this.client.id}`
                )
                .toPromise();
        } catch (err) {
            return undefined;
        }

        this.toastService.show({
            type: TOAST_TYPE.SUCCESS,
            text: 'success_delete_client',
        });

        return this.router.navigate(['/clients']);
    }

    public readonly CLIENT_TABS = CLIENT_TABS;
    public readonly CLIENT_TABS_LABELS = CLIENT_TABS_LABELS;
    public readonly validate = validate;
    public readonly isColorDark = isColorDark;

    public readonly publicConfig = publicConfig;

    public readonly RIGHTS = RIGHTS;
    public readonly CLIENT_TABS_RIGHTS = CLIENT_TABS_RIGHTS;
    public readonly LONG_AVATAR_TYPE = LONG_AVATAR_TYPE;
    public readonly PROJECT_STATUS_FILTER = PROJECT_STATUS_FILTER;

    public readonly ProjectStatusType = ProjectStatusType;
}
