<div style="position: relative">
    <input
        name="search"
        type="text"
        [(ngModel)]="filter"
        (input)="changes.next(filterKey)"
        [sharedAutofocus]="filterOpen"
    />
    <clr-icon
        *ngIf="filter.length"
        style="position: absolute; right: 0;"
        class="c-hand"
        shape="eraser"
        (click)="filter = ''; setFilter()"
    ></clr-icon>
</div>
