var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClrLoadingState } from '@clr/angular';
import { TranslateService } from '@ngx-translate/core';
import { noop } from 'rxjs';
import { ApiRoutePlurality, HTTP_METHOD, RIGHTS } from '../../../../defs/schema-static';
import { EMPLOYEE_SCHEMA_ROUTE } from '../../../../defs/schema/public/Employees';
import { EmployeeWizardEmployeeComponent } from '../employee-wizard/employee/employee-wizard-employee.component';
import { validate } from '../forms/validators/form.validator';
import { ModalSimpleComponent } from '../modal-simple/modal-simple.component';
import { ModalSimpleService } from '../modal-simple/modal-simple.service';
import { HttpRestService } from '../shared/http-rest/http-rest.service';
import { LONG_AVATAR_TYPE } from '../shared/long-avatar/long-avatar.component';
import { TOAST_TYPE, ToastService } from '../shared/toast/toast.service';
var EmployeeComponent = (function () {
    function EmployeeComponent(httpRest, route, toastService, translate, router, modalSimpleService) {
        this.httpRest = httpRest;
        this.route = route;
        this.toastService = toastService;
        this.translate = translate;
        this.router = router;
        this.modalSimpleService = modalSimpleService;
        this.validateBtnState = ClrLoadingState.DEFAULT;
        this.validate = validate;
        this.RIGHTS = RIGHTS;
        this.LONG_AVATAR_TYPE = LONG_AVATAR_TYPE;
    }
    EmployeeComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.params.subscribe(function (_a) {
            var id = _a.id;
            return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4, this.getEmployee(Number(id))];
                        case 1:
                            _b.sent();
                            return [2];
                    }
                });
            });
        });
    };
    EmployeeComponent.prototype.canDeactivate = function (component, currentRoute, currentState, nextState) {
        return !this.editComponent.form.dirty;
    };
    EmployeeComponent.prototype.confirmUnloadChanges = function ($event) {
        if (this.editComponent.form.dirty) {
            $event.preventDefault();
            return ($event.returnValue = true);
        }
    };
    EmployeeComponent.prototype.getEmployee = function (employeeId) {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!employeeId) {
                            this.employee = undefined;
                            return [2];
                        }
                        if (this.employee && employeeId === this.employee.id) {
                            return [2];
                        }
                        _a = this;
                        return [4, this.httpRest
                                ._request(HTTP_METHOD.GET, ApiRoutePlurality.SINGULAR, EMPLOYEE_SCHEMA_ROUTE, "employee/" + employeeId)
                                .toPromise()];
                    case 1:
                        _a.employee = _b.sent();
                        return [2];
                }
            });
        });
    };
    EmployeeComponent.prototype.saveEmployee = function () {
        var _this = this;
        var form = this.editComponent.form;
        if (!form.valid) {
            return;
        }
        this.validateBtnState = ClrLoadingState.LOADING;
        var _a = form.getRawValue(), name = _a.name, code = _a.code, color = _a.color, dayPrice = _a.dayPrice, email = _a.email, discordSnowflake = _a.discordSnowflake, phoneNumber = _a.phoneNumber, mobileNumber = _a.mobileNumber;
        var employee = {
            dayPrice: dayPrice,
            phoneNumber: phoneNumber,
            mobileNumber: mobileNumber,
            discordSnowflake: discordSnowflake,
        };
        var user = {
            color: color,
            name: name,
            code: code,
            email: email,
        };
        var postEmployee = function () {
            return _this.httpRest
                ._request(HTTP_METHOD.POST, ApiRoutePlurality.SINGULAR, EMPLOYEE_SCHEMA_ROUTE, '/employee/withUser', {
                userValues: __assign({}, user, { id: _this.employee.user.id }),
                employeeValues: __assign({}, employee, { id: _this.employee.id }),
            })
                .subscribe(function () { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    this.validateBtnState = ClrLoadingState.SUCCESS;
                    Object.assign(this.employee, employee);
                    Object.assign(this.employee.user, user);
                    this.toastService.show({
                        type: TOAST_TYPE.SUCCESS,
                        text: 'success_update_employee',
                    });
                    form.markAsPristine();
                    return [2];
                });
            }); }, function () {
                _this.validateBtnState = ClrLoadingState.DEFAULT;
            });
        };
        postEmployee();
    };
    EmployeeComponent.prototype.deleteEmployeeModal = function () {
        var _this = this;
        this.modalSimpleService
            .open(ModalSimpleComponent, {
            title: 'employee_delete_modal_title',
            contentI18n: 'swal_delete_attch',
            contentObj: { what: this.employee.user.name },
            ok: {
                i18n: 'delete_employee',
                class: 'btn-danger',
            },
        })
            .subscribe(function (closed) {
            if (closed.result) {
                _this.deleteEmployee();
            }
        }, noop);
    };
    EmployeeComponent.prototype.deleteEmployee = function () {
        return __awaiter(this, void 0, void 0, function () {
            var err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4, this.httpRest
                                ._request(HTTP_METHOD.DELETE, ApiRoutePlurality.SINGULAR, EMPLOYEE_SCHEMA_ROUTE, "/employee/withUser/" + this.employee.id)
                                .toPromise()];
                    case 1:
                        _a.sent();
                        return [3, 3];
                    case 2:
                        err_1 = _a.sent();
                        return [2, undefined];
                    case 3:
                        this.toastService.show({
                            type: TOAST_TYPE.SUCCESS,
                            text: 'success_delete_employee',
                        });
                        return [2, this.router.navigate(['/employees'])];
                }
            });
        });
    };
    return EmployeeComponent;
}());
export { EmployeeComponent };
