import {Component, Input, OnInit} from '@angular/core';
import {noop} from 'rxjs';
import {IProject} from '../../../../../defs/schema/public/Projects';
import {ITask} from '../../../../../defs/schema/public/Tasks';
import {AuthService} from '../../auth/auth.service';
import {FormsAddTimeService} from '../../forms/add-time.service';
import {ModalSimpleComponent} from '../../modal-simple/modal-simple.component';
import {ModalSimpleService} from '../../modal-simple/modal-simple.service';
import {ITimeWizardParams} from '../../time-wizard/time-wizard.component';
import {MomentService} from '../moment/moment.service';
import {SHORTCUT_MISC, ShortcutHandlerService} from '../shortcut-handler/shortcut-handler.service';
import {TimerService} from './timer.service';

@Component({
    selector: 'app-timer',
    templateUrl: './timer.component.html',
    styleUrls: ['./timer.component.scss'],
})
export class TimerComponent implements OnInit {
    public projects: IProject[];
    public assignedTasks: ITask[];
    public tasks: ITask[];

    public taskStartDate: string;
    public taskEndDate: string;

    @Input() public isCollapsed: boolean;

    public showCollapsedDrawer = false;

    public showTimerModal = false;
    public paramsTimerModal: ITimeWizardParams;

    public constructor(
        public timerService: TimerService,
        public authService: AuthService,
        private readonly formsAddTimeService: FormsAddTimeService,
        private readonly shortcutHandlerService: ShortcutHandlerService,
        private readonly momentService: MomentService,
        private readonly modalSimpleService: ModalSimpleService
    ) {}

    public ngOnInit() {
        this.shortcutHandlerService.register({
            name: SHORTCUT_MISC.TOGGLE_TIMER,
            shortcut: SHORTCUT_MISC.TOGGLE_TIMER,
            callback: () => {
                this.timerService.timer.toggle();
            },
            context: this,
        });
    }

    public openModal() {
        this.stopTimer();
        this.showTimerModal = true;
    }

    public stopTimer() {
        if (this.timerService.timer.isRunning) {
            this.timerService.timer.toggle();
        }

        const now = this.momentService.moment();
        const nowStr: string = now.format('YYYY-MM-DD');

        this.paramsTimerModal = {
            startDate: this.timerService.timer.startDate.format('YYYY-MM-DD'),
            endDate: nowStr,
        };
    }

    public cancel() {
        this.showTimerModal = false;
    }

    public reset() {
        this.modalSimpleService
            .open(ModalSimpleComponent, {
                title: 'modal_title_cancel_timer',
                contentI18n: 'swal_are_you_sure',
                ok: {
                    i18n: 'cancel_timer',
                    class: 'btn-danger',
                },
            })
            .subscribe((closed) => {
                if (closed.result) {
                    this.timerService.timer.stop();
                }
            }, noop);
    }

    public async assign(item: any) {
        if (await this.formsAddTimeService.submit(item.time, item.task)) {
            this.cancel();
        }
    }
}
