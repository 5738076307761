import {Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {Validators} from '@angular/forms';
import {ClrForm} from '@clr/angular';
import {FormControl, FormGroup} from 'ngx-strongly-typed-forms';
import {
    ApiRoutePlurality,
    COGNITO_USER_GROUPS,
    HTTP_METHOD,
    MAX_LENGTH_COGNITO_USERNAME,
    MAX_LENGTH_DEFAULT,
    MAX_LENGTH_DISCORD_SNOWFLAKE,
    PATTERN_CAPITAL_NUMERIC_SPACE,
    PATTERN_COGNITO_USERNAME,
    PATTERN_NUMBER,
    RIGHTS,
} from '../../../../../defs/schema-static';
import {EMPLOYEE_SCHEMA_ROUTE, IEmployee} from '../../../../../defs/schema/public/Employees';
import {IUser, USER_SCHEMA_ROUTE} from '../../../../../defs/schema/public/Users';
import {getRandomColorHex, IResettable, MAX_LENGTH_EMP_CODE} from '../../app-static';
import {AuthService} from '../../auth/auth.service';
import {phoneNumberValidator} from '../../forms/validators/phone-number.validator';
import {requiredTrimValidator} from '../../forms/validators/required-trim.validator';
import {HttpRestService} from '../../shared/http-rest/http-rest.service';

enum EMPLOYEE_FORM_KEYS {
    name = 'name',
    code = 'code',
    color = 'color',
    dayPrice = 'dayPrice',
    email = 'email',
    cognitoUsername = 'cognitoUsername',
    discordSnowflake = 'discordSnowflake',
    phoneNumber = 'phoneNumber',
    mobileNumber = 'mobileNumber',
}

interface IEmployeeFormValues {
    name: string;
    code: string;
    color: string;
    dayPrice: number;
    email: string;
    cognitoUsername: string;
    discordSnowflake?: string;
    phoneNumber?: string;
    mobileNumber?: string;
}

@Component({
    selector: 'app-employee-wizard-employee',
    templateUrl: './employee-wizard-employee.component.html',
    styleUrls: ['./employee-wizard-employee.component.scss'],
})
export class EmployeeWizardEmployeeComponent implements OnInit, OnChanges, IResettable {
    @ViewChild(ClrForm) private readonly clrForm: ClrForm;

    @ViewChild('nameElement') public nameElement: ElementRef;

    @Input() public employee: IEmployee;
    @Input() public formLayout = 'horizontal';

    @Input() public edit = false;

    public readonly form = new FormGroup<IEmployeeFormValues>({
        [EMPLOYEE_FORM_KEYS.name]: new FormControl<string>(null, [
            requiredTrimValidator(),
            Validators.maxLength(MAX_LENGTH_DEFAULT),
        ]),
        [EMPLOYEE_FORM_KEYS.code]: new FormControl<string>(null, [
            requiredTrimValidator(),
            Validators.maxLength(MAX_LENGTH_EMP_CODE),
            Validators.pattern(PATTERN_CAPITAL_NUMERIC_SPACE),
        ]),
        [EMPLOYEE_FORM_KEYS.color]: new FormControl<string>(getRandomColorHex(), requiredTrimValidator()),
        [EMPLOYEE_FORM_KEYS.dayPrice]: new FormControl<number>(0, [
            Validators.required,
            Validators.min(0),
            Validators.max(Number.MAX_SAFE_INTEGER),
        ]),
        [EMPLOYEE_FORM_KEYS.email]: new FormControl<string>(null, [
            requiredTrimValidator(),
            Validators.email,
            Validators.maxLength(MAX_LENGTH_DEFAULT),
        ]),
        [EMPLOYEE_FORM_KEYS.cognitoUsername]: new FormControl<string>(null, [
            requiredTrimValidator(),
            Validators.maxLength(MAX_LENGTH_COGNITO_USERNAME),
            Validators.pattern(PATTERN_COGNITO_USERNAME),
        ]),
        [EMPLOYEE_FORM_KEYS.discordSnowflake]: new FormControl<string>(null, [
            Validators.maxLength(MAX_LENGTH_DISCORD_SNOWFLAKE),
            Validators.pattern(PATTERN_NUMBER),
        ]),
        [EMPLOYEE_FORM_KEYS.phoneNumber]: new FormControl<string>(null, [
            phoneNumberValidator(),
            Validators.maxLength(MAX_LENGTH_DEFAULT),
        ]),
        [EMPLOYEE_FORM_KEYS.mobileNumber]: new FormControl<string>(null, [
            phoneNumberValidator(),
            Validators.maxLength(MAX_LENGTH_DEFAULT),
        ]),
    });

    public constructor(private readonly httpRest: HttpRestService, private readonly authService: AuthService) {}

    public ngOnInit() {
        this.setEmployee();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.employee) {
            this.setEmployee();
        }
        if (changes.edit) {
            this.toggleFormValidators();
        }
    }

    private toggleFormValidators(): void {
        if (this.edit) {
            this.form.get(EMPLOYEE_FORM_KEYS.cognitoUsername).disable();
        } else {
            this.form.get(EMPLOYEE_FORM_KEYS.cognitoUsername).enable();
        }
    }

    public onLoad() {
        requestAnimationFrame(() => {
            if (this.nameElement) {
                this.nameElement.nativeElement.focus();
            }
        });
    }

    public setEmployee(): void {
        if (!this.employee) {
            return this.reset();
        }

        this.form.reset({
            [EMPLOYEE_FORM_KEYS.dayPrice]: this.employee.dayPrice,
            [EMPLOYEE_FORM_KEYS.discordSnowflake]: this.employee.discordSnowflake,
            [EMPLOYEE_FORM_KEYS.mobileNumber]: this.employee.mobileNumber,
            [EMPLOYEE_FORM_KEYS.phoneNumber]: this.employee.phoneNumber,
            ...(((this.employee && this.employee.user) || {}) as IEmployeeFormValues),
        });

        if (!this.authService.hasRight(RIGHTS.EMPLOYEE_UPDATE)) {
            this.form.disable();
        } else {
            this.form.controls.cognitoUsername.disable();
        }
    }

    public async submit(): Promise<IEmployee> {
        if (!this.form.valid) {
            this.clrForm.markAsDirty();

            return undefined;
        }

        const {
            name,
            code,
            color,
            dayPrice,
            email,
            cognitoUsername,
            discordSnowflake,
            mobileNumber,
            phoneNumber,
        } = this.form.value;

        if (cognitoUsername) {
            const cognitoValues = {
                email,
                username: cognitoUsername,
                groupName: COGNITO_USER_GROUPS.EMPLOYEE,
            };

            await this.httpRest
                ._request(HTTP_METHOD.PUT, ApiRoutePlurality.SINGULAR, USER_SCHEMA_ROUTE, 'cognito', cognitoValues)
                .toPromise();
        }

        const userValues: Partial<IUser> = {
            name,
            code,
            color,
            email,
        };

        if (cognitoUsername) {
            userValues.cognitoUsername = cognitoUsername;
        }

        const employeeValues: Partial<IEmployee> = {
            dayPrice,
            discordSnowflake,
            mobileNumber,
            phoneNumber,
        };

        const {employee, user} = await this.httpRest
            ._request<{employee: IEmployee; user: IUser}>(
                HTTP_METHOD.PUT,
                ApiRoutePlurality.SINGULAR,
                EMPLOYEE_SCHEMA_ROUTE,
                '/employee/withUser',
                {
                    userValues,
                    employeeValues,
                }
            )
            .toPromise();
        employee.user = user;

        return employee;
    }

    public reset(): void {
        this.form.reset({[EMPLOYEE_FORM_KEYS.dayPrice]: 0, [EMPLOYEE_FORM_KEYS.color]: getRandomColorHex()});
    }

    public readonly EMPLOYEE_FORM_KEYS = EMPLOYEE_FORM_KEYS;
    public readonly MAX_LENGTH_DEFAULT = MAX_LENGTH_DEFAULT;
    public readonly MAX_LENGTH_EMP_CODE = MAX_LENGTH_EMP_CODE;
    public readonly MAX_LENGTH_COGNITO_USERNAME = MAX_LENGTH_COGNITO_USERNAME;
    public readonly MAX_LENGTH_DISCORD_SNOWFLAKE = MAX_LENGTH_DISCORD_SNOWFLAKE;
    public readonly PATTERN_CAPITAL_NUMERIC_SPACE = PATTERN_CAPITAL_NUMERIC_SPACE;
}
