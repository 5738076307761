import {AfterViewInit, Directive, ElementRef, Input, OnChanges, SimpleChanges} from '@angular/core';

@Directive({
    selector: '[sharedAutofocus]',
})
export class AutofocusDirective implements OnChanges, AfterViewInit {
    @Input() public afSelect = false;

    private _autofocus = true;

    public constructor(private readonly element: ElementRef) {}

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.sharedAutofocus && changes.sharedAutofocus.currentValue !== changes.sharedAutofocus.previousValue) {
            this.triggerAutofocus();
        }
    }

    public ngAfterViewInit() {
        this.triggerAutofocus();
    }

    public triggerAutofocus() {
        if (!this._autofocus) {
            return;
        }

        window.setTimeout(() => (this.element.nativeElement as HTMLInputElement).focus(), 0);
        if (this.afSelect) {
            window.setTimeout(() => (this.element.nativeElement as HTMLInputElement).select(), 0);
        }
    }

    @Input()
    public set sharedAutofocus(autofocus: boolean) {
        this._autofocus = autofocus;
    }
}
