var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ApiRoutePlurality, HTTP_METHOD } from '../../../../../defs/schema-static';
import { CONFIG_SCHEMA_ROUTE } from '../../../../../defs/schema/meta/Config';
import { AuthService } from '../../auth/auth.service';
import { HttpRestService } from '../http-rest/http-rest.service';
import * as i0 from "@angular/core";
import * as i1 from "../http-rest/http-rest.service";
import * as i2 from "../../auth/auth.service";
var ConfigService = (function () {
    function ConfigService(httpRest, authService) {
        var _this = this;
        this.httpRest = httpRest;
        this.authService = authService;
        if (this.authService.user) {
            (function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                return [2, this.reload()];
            }); }); })();
        }
        this.authService.userChange.subscribe(function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
            return [2, this.reload()];
        }); }); });
    }
    ConfigService.prototype.get = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.config || (this.configObservable && this.configObservable.toPromise())];
            });
        });
    };
    ConfigService.prototype.set = function (config) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.config = null;
                this._configObservable = this.httpRest._request(HTTP_METHOD.POST, ApiRoutePlurality.SINGULAR, CONFIG_SCHEMA_ROUTE, 'businessRules', config);
                return [2];
            });
        });
    };
    ConfigService.prototype.reload = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.configObservable &&
                    this.configObservable.toPromise().then(function (_config) {
                        _this.config = _config;
                    });
                return [2];
            });
        });
    };
    Object.defineProperty(ConfigService.prototype, "configObservable", {
        get: function () {
            if (!this.authService.user) {
                return null;
            }
            if (this._configObservable) {
                return this._configObservable;
            }
            return (this._configObservable = this.httpRest._request(HTTP_METHOD.GET, ApiRoutePlurality.SINGULAR, CONFIG_SCHEMA_ROUTE, 'businessRules'));
        },
        enumerable: true,
        configurable: true
    });
    ConfigService.ngInjectableDef = i0.defineInjectable({ factory: function ConfigService_Factory() { return new ConfigService(i0.inject(i1.HttpRestService), i0.inject(i2.AuthService)); }, token: ConfigService, providedIn: "root" });
    return ConfigService;
}());
export { ConfigService };
