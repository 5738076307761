var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationDetails, CognitoUser, CognitoUserAttribute, CognitoUserPool, } from 'amazon-cognito-identity-js';
import { CognitoIdentityCredentials } from 'aws-sdk';
import publicConfig from '../../../../defs/config/config.json';
import { ApiRoutePlurality, COGNITO_USER_GROUPS, COGNITO_USER_GROUPS_PRECEDENCE, HTTP_METHOD, RIGHTS, } from '../../../../defs/schema-static';
import { COGNITO_PUBLIC_SCHEMA_ROUTE } from '../../../../defs/schema/public/CognitoPublic';
import { PROJECT_SCHEMA_ROUTE } from '../../../../defs/schema/public/Projects';
import { RIGHT_SCHEMA_ROUTE } from '../../../../defs/schema/public/Rights';
import { USER_SCHEMA_ROUTE } from '../../../../defs/schema/public/Users';
import { noop } from '../app-static';
import { HttpRestService } from '../shared/http-rest/http-rest.service';
import { MomentService } from '../shared/moment/moment.service.js';
import * as i0 from "@angular/core";
import * as i1 from "../shared/http-rest/http-rest.service";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../shared/moment/moment.service";
var AuthService = (function () {
    function AuthService(httpRest, translate, momentService) {
        this.httpRest = httpRest;
        this.translate = translate;
        this.momentService = momentService;
        this.modalConfirmNumber = {
            show: false,
            cancelable: false,
        };
        this.modalAlert = {
            show: false,
            callback: undefined,
        };
        this.modalUpdate = {
            show: false,
            cancelable: false,
        };
        this.modalConfirmPass = {
            show: false,
            cancelable: true,
        };
        this.isAuthenticatedChange = new EventEmitter(true);
        this.isAuthenticated = false;
        this.cognitoUsernameChange = new EventEmitter(true);
        this.userChange = new EventEmitter();
        this.cognitoGroups = [COGNITO_USER_GROUPS._UNAUTHED];
        this.storage = window.localStorage;
        this.userPool = new CognitoUserPool({
            UserPoolId: publicConfig.cognito.userPoolId,
            ClientId: publicConfig.cognito.clientId,
            Storage: this.storage,
        });
        this.managerOf = [];
        this.COGNITO_AUTH_REFRESH_THRESHOLD = this.momentService.moment.duration(1, 'minute').as('seconds');
        this.COGNITO_RESOURCE_URL = "cognito-idp." + publicConfig.cognito.region + ".amazonaws.com/" + publicConfig.cognito.userPoolId;
        this.LOCAL_STORAGE_COGNITO_USERNAME_KEY = 'cognitoUsername';
        this.LOCAL_STORAGE_COGNITO_SUB_KEY = 'cognitoSub';
    }
    Object.defineProperty(AuthService.prototype, "accessToken", {
        get: function () {
            return this.cognitoSession && this.cognitoSession.getAccessToken();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "idToken", {
        get: function () {
            return this.cognitoSession && this.cognitoSession.getIdToken();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "accessJWTToken", {
        get: function () {
            return this.accessToken && this.accessToken.getJwtToken();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "idJWTToken", {
        get: function () {
            return this.idToken && this.idToken.getJwtToken();
        },
        enumerable: true,
        configurable: true
    });
    AuthService.prototype.getAwsCredentials = function (loop) {
        if (loop === void 0) { loop = 0; }
        return __awaiter(this, void 0, void 0, function () {
            var err_1, cognitoIdentityGuid;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.idJWTToken) {
                            return [2, null];
                        }
                        if (!(!this.credentials || this.credentials.expired)) return [3, 5];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        if (!this.credentialsGetPromise) {
                            this.credentialsGetPromise = new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
                                var _a, err_2;
                                return __generator(this, function (_b) {
                                    switch (_b.label) {
                                        case 0:
                                            _b.trys.push([0, 3, , 4]);
                                            return [4, this.refreshSession(true)];
                                        case 1:
                                            _b.sent();
                                            this.credentials = new CognitoIdentityCredentials({
                                                IdentityPoolId: publicConfig.cognito.identityPoolId,
                                                Logins: (_a = {},
                                                    _a[this.COGNITO_RESOURCE_URL] = this.idJWTToken,
                                                    _a),
                                            }, { region: publicConfig.cognito.region });
                                            return [4, this.credentials.getPromise()];
                                        case 2:
                                            _b.sent();
                                            resolve();
                                            return [3, 4];
                                        case 3:
                                            err_2 = _b.sent();
                                            reject(err_2);
                                            return [3, 4];
                                        case 4: return [2];
                                    }
                                });
                            }); });
                        }
                        return [4, this.credentialsGetPromise];
                    case 2:
                        _a.sent();
                        return [3, 4];
                    case 3:
                        err_1 = _a.sent();
                        console.error(err_1);
                        if (loop >= 2) {
                            console.error('giving up');
                            return [2, undefined];
                        }
                        this.credentialsGetPromise = null;
                        return [2, new Promise(function (resolve, reject) {
                                _this.restoreSession(function (err) { return __awaiter(_this, void 0, void 0, function () {
                                    var credentials, err_3;
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0:
                                                if (err) {
                                                    reject(err);
                                                    return [2];
                                                }
                                                _a.label = 1;
                                            case 1:
                                                _a.trys.push([1, 3, , 4]);
                                                return [4, this.getAwsCredentials(loop + 1)];
                                            case 2:
                                                credentials = _a.sent();
                                                resolve(credentials);
                                                return [3, 4];
                                            case 3:
                                                err_3 = _a.sent();
                                                reject(err_3);
                                                return [3, 4];
                                            case 4: return [2];
                                        }
                                    });
                                }); });
                            })];
                    case 4:
                        this.credentialsGetPromise = null;
                        _a.label = 5;
                    case 5:
                        if (!this.credentials.needsRefresh()) return [3, 7];
                        if (!this.credentialsRefreshPromise) {
                            this.credentialsRefreshPromise = new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
                                var err_4;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            _a.trys.push([0, 3, , 4]);
                                            return [4, this.refreshSession(true)];
                                        case 1:
                                            _a.sent();
                                            return [4, this.credentials.refreshPromise()];
                                        case 2:
                                            _a.sent();
                                            resolve();
                                            return [3, 4];
                                        case 3:
                                            err_4 = _a.sent();
                                            reject(err_4);
                                            return [3, 4];
                                        case 4: return [2];
                                    }
                                });
                            }); });
                        }
                        return [4, this.credentialsRefreshPromise];
                    case 6:
                        _a.sent();
                        this.credentialsRefreshPromise = null;
                        _a.label = 7;
                    case 7:
                        cognitoIdentityGuid = (this.credentials.data
                            .IdentityId || '')
                            .split(':')[1];
                        if (!(cognitoIdentityGuid && cognitoIdentityGuid !== this.user.cognitoIdentityGuid)) return [3, 9];
                        this.user.cognitoIdentityGuid = cognitoIdentityGuid;
                        return [4, this.httpRest
                                ._request(HTTP_METHOD.POST, ApiRoutePlurality.SINGULAR, USER_SCHEMA_ROUTE, 'cognito/identityGuid', {
                                cognitoIdentityGuid: cognitoIdentityGuid,
                            })
                                .toPromise()];
                    case 8:
                        _a.sent();
                        _a.label = 9;
                    case 9: return [2, this.credentials];
                }
            });
        });
    };
    Object.defineProperty(AuthService.prototype, "precedenceGroup", {
        get: function () {
            return this.cognitoGroups.sort(function (g1, g2) {
                return (COGNITO_USER_GROUPS_PRECEDENCE[g1] || Number.MAX_SAFE_INTEGER) -
                    (COGNITO_USER_GROUPS_PRECEDENCE[g2] || Number.MAX_SAFE_INTEGER);
            })[0];
        },
        enumerable: true,
        configurable: true
    });
    AuthService.prototype.signUp = function (username, email, password, callback) {
        var _this = this;
        if (callback === void 0) { callback = noop; }
        this.userPool.signUp(username, password, [new CognitoUserAttribute({ Name: 'email', Value: email })], null, function (err, result) { return __awaiter(_this, void 0, void 0, function () {
            var cognitoUser, text;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (err) {
                            if (err.code !== 'UsernameExistsException') {
                                this.errorHandler(err);
                            }
                            return [2, callback(err)];
                        }
                        cognitoUser = result.user;
                        return [4, this.translate.get('auth_mail_sent', { email: email }).toPromise()];
                    case 1:
                        text = _a.sent();
                        Object.assign(this.modalConfirmNumber, {
                            show: true,
                            title: 'Registration confirmation',
                            content: text,
                            callback: function (value) {
                                cognitoUser.confirmRegistration(value.toString(), true, function (_err) {
                                    if (_err) {
                                        _this.errorHandler(_err);
                                        _this.modalConfirmNumber.show = false;
                                        return callback(_err);
                                    }
                                    _this.authenticate(username, password, callback);
                                    _this.modalConfirmNumber.show = false;
                                });
                            },
                            callbackLabel: 'form_validate',
                            cancel: undefined,
                        });
                        return [2];
                }
            });
        }); });
    };
    AuthService.prototype.signOut = function () {
        if (this.cognitoUser) {
            this.cognitoUser.signOut();
        }
        this.cognitoUser = null;
        this.cognitoSession = null;
        this.storage.clear();
        this.rights = undefined;
        this.getRightsPromise = undefined;
        this.managerOf = [];
        if (this.credentials) {
            this.credentials.clearCachedId();
            this.credentials = undefined;
        }
        localStorage[this.LOCAL_STORAGE_COGNITO_USERNAME_KEY] = '';
        this.cognitoUsernameChange.emit((this.cognitoUsername = ''));
        this.userChange.emit((this.user = undefined));
        this.isAuthenticatedChange.emit((this.isAuthenticated = false));
    };
    AuthService.parsePasswordError = function (err) {
        if (err.message.includes('Password not long enough') || err.message.includes('must have length greater than')) {
            return 'password_error_not_long_enough';
        }
        else if (err.message.includes('Password must have uppercase characters')) {
            return 'password_error_missing_uppercase';
        }
        else if (err.message.includes('Password must have lowercase characters')) {
            return 'password_error_missing_lowercase';
        }
        else if (err.message.includes('Password must have numeric characters')) {
            return 'password_error_missing_numeric';
        }
        else if (err.message.includes('Password must have symbol characters')) {
            return 'password_error_missing_symbol';
        }
        return err.message;
    };
    AuthService.prototype.authenticate = function (username, password, callback) {
        var _this = this;
        if (callback === void 0) { callback = noop; }
        var authenticationData = {
            Username: username,
            Password: password,
        };
        var authenticationDetails = new AuthenticationDetails(authenticationData);
        var cognitoUser = (this.cognitoUser = new CognitoUser({
            Username: username,
            Pool: this.userPool,
            Storage: this.storage,
        }));
        var _t = this.translate;
        var mfaRequired = function (codeDeliveryDetails) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                Object.assign(this.modalConfirmNumber, {
                    show: true,
                    title: 'Please input verification code',
                    content: '',
                    callback: function (value) {
                        cognitoUser.sendMFACode(value, _this);
                        _this.modalConfirmNumber.show = false;
                    },
                    callbackLabel: 'form_validate',
                    cancel: function () {
                        _this.modalConfirmNumber.show = false;
                        return callback(new Error('cancelled'));
                    },
                });
                return [2];
            });
        }); };
        var done = function (session) {
            _this.cognitoSession = session;
            _this.cognitoGroups = AuthService.getCognitoGroupsFromSession(_this.cognitoSession);
            localStorage[_this.LOCAL_STORAGE_COGNITO_USERNAME_KEY] = username;
            _this.getUser(callback);
        };
        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: function (session) {
                done(session);
            },
            onFailure: function (err) {
                callback(err);
            },
            newPasswordRequired: function (userAttributes, requiredAttributes) { return __awaiter(_this, void 0, void 0, function () {
                var title;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            delete userAttributes.email_verified;
                            return [4, _t.get('must_update_password').toPromise()];
                        case 1:
                            title = _a.sent();
                            Object.assign(this.modalUpdate, {
                                show: true,
                                title: title,
                                callback: function (value) {
                                    cognitoUser.completeNewPasswordChallenge(value, userAttributes, {
                                        onFailure: function (err) {
                                            _this.modalUpdate.error = AuthService.parsePasswordError(err);
                                        },
                                        onSuccess: function (session) {
                                            _this.modalUpdate.error = undefined;
                                            _this.modalUpdate.show = false;
                                            done(session);
                                        },
                                        mfaRequired: mfaRequired,
                                    });
                                },
                                callbackLabel: 'form_validate',
                                cancel: function () {
                                    _this.modalUpdate.show = false;
                                    return callback(new Error('cancelled'));
                                },
                            });
                            return [2];
                    }
                });
            }); },
            mfaRequired: mfaRequired,
        });
    };
    AuthService.prototype.resetPassword = function (username, callback) {
        var _this = this;
        if (callback === void 0) { callback = noop; }
        var cognitoUser = (this.cognitoUser = new CognitoUser({
            Username: username,
            Pool: this.userPool,
            Storage: this.storage,
        }));
        var _t = this.translate;
        this.modalConfirmPass.value = undefined;
        this.modalConfirmPass.valueBis = undefined;
        cognitoUser.forgotPassword({
            onSuccess: function (data) { return callback(); },
            onFailure: function (err) { return __awaiter(_this, void 0, void 0, function () {
                var err_5;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!(err.code === 'NotAuthorizedException' &&
                                err.message.includes('User password cannot be reset in the current state'))) return [3, 5];
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 3, , 4]);
                            return [4, this.resendVerification(username)];
                        case 2:
                            _a.sent();
                            return [3, 4];
                        case 3:
                            err_5 = _a.sent();
                            return [2, callback(err_5)];
                        case 4: return [2, callback(null, { resend: true })];
                        case 5:
                            callback(err);
                            return [2];
                    }
                });
            }); },
            inputVerificationCode: function (data) { return __awaiter(_this, void 0, void 0, function () {
                var title, detail, plsDetail;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4, _t.get('password_reset').toPromise()];
                        case 1:
                            title = _a.sent();
                            return [4, _t
                                    .get('auth_detail_sent', {
                                    smth: data.CodeDeliveryDetails.AttributeName,
                                    dest: data.CodeDeliveryDetails.Destination,
                                })
                                    .toPromise()];
                        case 2:
                            detail = _a.sent();
                            return [4, _t.get('please_enter_confirmation').toPromise()];
                        case 3:
                            plsDetail = _a.sent();
                            Object.assign(this.modalConfirmPass, {
                                show: true,
                                title: title,
                                content: "<p>" + detail + "</p><br><p>" + plsDetail + "</p>",
                                callback: function (value, valueBis) {
                                    cognitoUser.confirmPassword(value.toString(), valueBis, {
                                        onSuccess: function () {
                                            _this.modalConfirmPass.error = undefined;
                                            _this.modalConfirmPass.show = false;
                                            callback(null, { password: true });
                                        },
                                        onFailure: function (err) {
                                            _this.modalConfirmPass.error = AuthService.parsePasswordError(err);
                                        },
                                    });
                                },
                                callbackLabel: 'form_validate',
                                cancel: function () {
                                    return callback(new Error('cancelled'));
                                },
                            });
                            return [2];
                    }
                });
            }); },
        });
    };
    AuthService.prototype.resendVerification = function (username) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (!username) {
                    return [2, undefined];
                }
                return [2, this.httpRest
                        ._request(HTTP_METHOD.GET, ApiRoutePlurality.SINGULAR, COGNITO_PUBLIC_SCHEMA_ROUTE, "user/resend/" + username)
                        .toPromise()];
            });
        });
    };
    AuthService.prototype.restoreSession = function (callback) {
        var _this = this;
        if (callback === void 0) { callback = noop; }
        if (!localStorage[this.LOCAL_STORAGE_COGNITO_USERNAME_KEY]) {
            return callback();
        }
        var userData = {
            Username: localStorage[this.LOCAL_STORAGE_COGNITO_USERNAME_KEY],
            Pool: this.userPool,
            Storage: this.storage,
        };
        this.cognitoUser = new CognitoUser(userData);
        this.cognitoUser.getSession(function (err, session) {
            if (!session) {
                return callback(err);
            }
            _this.cognitoSession = session;
            _this.cognitoGroups = AuthService.getCognitoGroupsFromSession(_this.cognitoSession);
            _this.getUser(function (_err) { return callback(_err, session); });
        });
    };
    Object.defineProperty(AuthService.prototype, "isSessionValid", {
        get: function () {
            if (!this.cognitoSession || !this.cognitoSession.getAccessToken()) {
                return false;
            }
            var timeExpiration = 1000;
            var expiration = this.momentService.moment(this.cognitoSession.getAccessToken().getExpiration() * timeExpiration);
            var remainingSeconds = this.momentService.moment
                .duration(expiration.diff(this.momentService.moment()))
                .as('seconds');
            return this.cognitoSession.isValid() && remainingSeconds > this.COGNITO_AUTH_REFRESH_THRESHOLD;
        },
        enumerable: true,
        configurable: true
    });
    AuthService.prototype.refreshSession = function (force) {
        if (force === void 0) { force = false; }
        return __awaiter(this, void 0, void 0, function () {
            var userData, _a;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!!this.cognitoSession) return [3, 2];
                        return [4, new Promise(function (resolve, reject) { return _this.restoreSession(function (err) { return (err ? reject(err) : resolve()); }); })];
                    case 1:
                        _b.sent();
                        return [2, undefined];
                    case 2:
                        if (!force && this.cognitoSession.isValid()) {
                            return [2, undefined];
                        }
                        if (!force && !this.isSessionValid) {
                            return [2, undefined];
                        }
                        userData = {
                            Username: localStorage[this.LOCAL_STORAGE_COGNITO_USERNAME_KEY],
                            Pool: this.userPool,
                            Storage: this.storage,
                        };
                        this.cognitoUser = new CognitoUser(userData);
                        if (!this.sessionRefreshPromise) {
                            this.sessionRefreshPromise = new Promise(function (resolve, reject) {
                                return _this.cognitoUser.refreshSession(_this.cognitoSession.getRefreshToken(), function (err, _session) { return (err ? reject(err) : resolve(_session)); });
                            });
                        }
                        _a = this;
                        return [4, this.sessionRefreshPromise];
                    case 3:
                        _a.cognitoSession = _b.sent();
                        this.sessionRefreshPromise = null;
                        this.cognitoGroups = AuthService.getCognitoGroupsFromSession(this.cognitoSession);
                        return [2];
                }
            });
        });
    };
    AuthService.prototype.hasRight = function (right) {
        if (!this.rights) {
            return false;
        }
        return this.rights.findIndex(function (r) { return r.code === right; }) > -1;
    };
    AuthService.prototype.isManagerOf = function (projectId) {
        return this.hasRight(RIGHTS.PROJECT_ADMIN_ALL) || (!!this.managerOf && this.managerOf.includes(projectId));
    };
    AuthService.prototype.addManagerOf = function (projectId) {
        if (this.managerOf.indexOf(projectId) === -1) {
            this.managerOf.push(projectId);
            return true;
        }
        return false;
    };
    AuthService.prototype.getProjectsManagerOf = function () {
        return this.managerOf;
    };
    AuthService.prototype.getManagerOf = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4, this.httpRest
                                ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, PROJECT_SCHEMA_ROUTE, 'managerOf')
                                .toPromise()];
                    case 1:
                        _a.managerOf = _b.sent();
                        return [2];
                }
            });
        });
    };
    AuthService.prototype.getUser = function (callback) {
        var _this = this;
        if (callback === void 0) { callback = noop; }
        (function () { return __awaiter(_this, void 0, void 0, function () {
            var user, err_6, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.getUserPromise) {
                            this.getUserPromise = this.httpRest
                                ._request(HTTP_METHOD.GET, ApiRoutePlurality.SINGULAR, USER_SCHEMA_ROUTE, "cognito/" + (this.cognitoUsername || localStorage.getItem('cognitoUsername')))
                                .toPromise();
                        }
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, 4, 5]);
                        return [4, this.getUserPromise];
                    case 2:
                        user = _b.sent();
                        return [3, 5];
                    case 3:
                        err_6 = _b.sent();
                        return [2, callback(err_6)];
                    case 4:
                        this.getUserPromise = null;
                        return [7];
                    case 5:
                        if (!user) {
                            this.cognitoUsernameChange.emit((this.cognitoUsername = ''));
                            this.isAuthenticatedChange.emit((this.isAuthenticated = false));
                            this.userChange.emit((this.user = undefined));
                            return [2, callback(new Error('Missing user'))];
                        }
                        localStorage[this.LOCAL_STORAGE_COGNITO_USERNAME_KEY] = user.cognitoUsername;
                        if (this.cognitoUsername !== localStorage[this.LOCAL_STORAGE_COGNITO_USERNAME_KEY]) {
                            this.cognitoUsernameChange.emit((this.cognitoUsername = localStorage[this.LOCAL_STORAGE_COGNITO_USERNAME_KEY]));
                        }
                        if (!this.isAuthenticated) {
                            this.isAuthenticatedChange.emit((this.isAuthenticated = true));
                        }
                        if (this.user !== user) {
                            this.userChange.emit((this.user = user));
                        }
                        _a = this;
                        return [4, this.getRights()];
                    case 6:
                        _a.rights = _b.sent();
                        return [4, this.getManagerOf()];
                    case 7:
                        _b.sent();
                        callback();
                        return [2];
                }
            });
        }); })();
    };
    AuthService.prototype.getRights = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, (this.getRightsPromise = this.httpRest
                        ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, RIGHT_SCHEMA_ROUTE, '')
                        .toPromise())];
            });
        });
    };
    AuthService.getCognitoGroupsFromSession = function (session) {
        if (!session.isValid()) {
            return [COGNITO_USER_GROUPS._UNAUTHED];
        }
        return [COGNITO_USER_GROUPS._AUTHED].concat(((session.getIdToken().decodePayload() || {})['cognito:groups'] || []));
    };
    AuthService.prototype.errorHandler = function (err) {
        return __awaiter(this, void 0, void 0, function () {
            var title;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.translate.get('login_error').toPromise()];
                    case 1:
                        title = _a.sent();
                        (function () { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                Object.assign(this.modalAlert, {
                                    showInline: true,
                                    title: title,
                                    content: "<div style=\"overflow: auto; max-height: 30vh\"><code>" + JSON.stringify(err) + "</code></div>",
                                });
                                return [2];
                            });
                        }); })();
                        return [2];
                }
            });
        });
    };
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject(i1.HttpRestService), i0.inject(i2.TranslateService), i0.inject(i3.MomentService)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
