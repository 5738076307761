var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { EventEmitter, OnChanges, OnInit, SimpleChanges, } from '@angular/core';
import { Validators } from '@angular/forms';
import { ClrForm } from '@clr/angular';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, FormGroup } from 'ngx-strongly-typed-forms';
import semVer from 'semver';
import { BudgetStatus, MAX_LENGTH_DEFAULT } from '../../../../defs/schema-static';
import { BUDGET_SCHEMA_ROUTE } from '../../../../defs/schema/public/Budget';
import { BUDGET_STATUS_FILTER, PROJECT_TABS } from '../app-static';
import { requiredTrimValidator } from '../forms/validators/required-trim.validator';
import { HttpRestService } from '../shared/http-rest/http-rest.service';
import { TOAST_TYPE, ToastService } from '../shared/toast/toast.service';
var BUDGET_FORM_KEYS;
(function (BUDGET_FORM_KEYS) {
    BUDGET_FORM_KEYS["description"] = "description";
    BUDGET_FORM_KEYS["price"] = "price";
    BUDGET_FORM_KEYS["status"] = "status";
    BUDGET_FORM_KEYS["releaseId"] = "releaseId";
    BUDGET_FORM_KEYS["projectId"] = "projectId";
})(BUDGET_FORM_KEYS || (BUDGET_FORM_KEYS = {}));
var EditBudgetComponent = (function () {
    function EditBudgetComponent(httpRest, toastService, translate) {
        var _a;
        this.httpRest = httpRest;
        this.toastService = toastService;
        this.translate = translate;
        this.show = false;
        this.showChange = new EventEmitter();
        this.releasesList = [];
        this.hidden = new EventEmitter();
        this.budgetChange = new EventEmitter();
        this.form = new FormGroup((_a = {},
            _a[BUDGET_FORM_KEYS.description] = new FormControl(null, [
                requiredTrimValidator(),
                Validators.maxLength(MAX_LENGTH_DEFAULT),
            ]),
            _a[BUDGET_FORM_KEYS.price] = new FormControl(null, Validators.required),
            _a[BUDGET_FORM_KEYS.status] = new FormControl(BudgetStatus.ESTIMATED, Validators.required),
            _a[BUDGET_FORM_KEYS.releaseId] = new FormControl(),
            _a[BUDGET_FORM_KEYS.projectId] = new FormControl(this.projectId, Validators.required),
            _a));
        this.BUDGET_STATUS_VALUES = Object.values(BudgetStatus);
        this.BUDGET_FORM_KEYS = BUDGET_FORM_KEYS;
        this.BUDGET_STATUS_FILTER = BUDGET_STATUS_FILTER;
        this.PROJECT_TABS = PROJECT_TABS;
        this.MAX_LENGTH_DEFAULT = MAX_LENGTH_DEFAULT;
    }
    EditBudgetComponent.prototype.ngOnInit = function () {
        this.formReset();
    };
    EditBudgetComponent.prototype.ngOnChanges = function (changes) {
        if (changes.projectId) {
            this.form.patchValue({
                projectId: this.projectId,
            });
        }
        if (changes.budget) {
            this.formReset();
        }
        if (changes.releases && (!this.releasesList || this.releasesList.length === 0)) {
            this.releasesList = this.releases.sort(function (a, b) { return semVer.compare(b.version, a.version); });
        }
    };
    EditBudgetComponent.prototype.hideModal = function () {
        this.budget = undefined;
        this.formReset();
        this.showChange.emit((this.show = false));
        this.hidden.emit();
    };
    EditBudgetComponent.prototype.formReset = function () {
        this.form.reset({
            status: BudgetStatus.ESTIMATED,
            projectId: this.projectId,
        });
        if (this.budget && this.budget.release) {
            this.budget.releaseId = this.budget.release.id;
        }
        this.form.patchValue(this.budget || {});
    };
    EditBudgetComponent.prototype.saveBudget = function () {
        return __awaiter(this, void 0, void 0, function () {
            var creation, budget;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.form.valid) {
                            this.clrForm.markAsDirty();
                            return [2];
                        }
                        creation = !this.budget || !this.budget.id;
                        return [4, (creation
                                ? this.httpRest.put(BUDGET_SCHEMA_ROUTE, __assign({}, this.form.value))
                                : this.httpRest.post(BUDGET_SCHEMA_ROUTE, __assign({ id: this.budget.id }, this.form.value))).toPromise()];
                    case 1:
                        budget = _a.sent();
                        this.budget = __assign({}, (this.budget || {}), budget);
                        this.budget.release = this.releases.find(function (release) { return release.id === budget.releaseId; });
                        this.budgetChange.emit(this.budget);
                        this.toastService.show({
                            type: TOAST_TYPE.SUCCESS,
                            text: creation ? 'toast_added_budget' : 'toast_updated_budget',
                        });
                        this.hideModal();
                        return [2];
                }
            });
        });
    };
    return EditBudgetComponent;
}());
export { EditBudgetComponent };
