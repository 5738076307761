import {HttpClient} from '@angular/common/http';
import {Component, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {ClrLoadingState} from '@clr/angular';
import {TranslateService} from '@ngx-translate/core';
import {Duration} from 'moment';
import {getConfigKeys} from '../../../../../defs/businessRules';
import {DECIMAL_RADIX} from '../../../../../defs/schema-static';
import {IRules} from '../../../../../defs/schema/meta/Config';
import {getTimeString} from '../../../../../defs/schema/public/Times';
import {SETTING_THEME_VALUES} from '../../../../../defs/schema/public/Users';
// @ts-ignore
import {version} from '../../../../package.json';
import {API_HTTP_ROOT, API_VERSION} from '../../app-static';
import {AuthService} from '../../auth/auth.service';
import {validate} from '../../forms/validators/form.validator';
import {ConfigService} from '../../shared/config/config.service';
import {MomentService} from '../../shared/moment/moment.service';
import {TOAST_TYPE, ToastService} from '../../shared/toast/toast.service';
import {VersionInterceptor} from '../../version/version.interceptor';

interface IDay {
    start: string;
    end: string;
    duration?: Duration;
    durationStr?: string;
    error?: boolean;
}

@Component({
    selector: 'app-admin-main',
    templateUrl: './admin-main.component.html',
    styleUrls: ['./admin-main.component.scss'],
})
export class AdminMainComponent implements OnInit {
    public days: {initial: string; num: number; enabled: boolean; old: boolean}[];
    public workingHours: IDay;
    public lunchHours: IDay;
    public calendarHours: IDay;
    public avgEmployeePrice: number;

    public appName: string;
    public mailDomain: string;
    public defaultTheme: SETTING_THEME_VALUES;

    public link = [API_HTTP_ROOT, API_VERSION, ''].join('/');

    public ready = false;

    public validateBtnState: ClrLoadingState = ClrLoadingState.DEFAULT;

    @ViewChild('f')
    public form: NgForm;

    public constructor(
        private readonly translate: TranslateService,
        private readonly toastService: ToastService,
        private readonly configService: ConfigService,
        private readonly httpClient: HttpClient,
        public readonly authService: AuthService,
        private readonly momentService: MomentService
    ) {}

    public async ngOnInit() {
        const cfg = await this.configService.get();

        const rules: (keyof IRules)[] = [
            'appName',
            'mailDomain',
            'workingDays',
            'workingHours',
            'lunchHours',
            'defaultTheme',
            'calendarHours',
        ];

        const rulesObj = getConfigKeys(cfg, rules);

        const {appName, mailDomain, workingDays, workingHours, lunchHours, defaultTheme, calendarHours} = rulesObj;

        this.appName = appName;
        this.mailDomain = mailDomain;

        this.days = this.momentService.moment.weekdays(true).map((day) => {
            const dayNum = this.dayNumber(day);

            return {
                initial: day,
                num: dayNum,
                enabled: workingDays.indexOf(dayNum) !== -1,
                old: workingDays.indexOf(dayNum) !== -1,
            };
        });

        this.workingHours = workingHours;
        this.lunchHours = lunchHours;
        this.calendarHours = calendarHours;

        this.updateDuration(this.lunchHours, true);
        // working day duration is updated on lunch update

        this.defaultTheme = defaultTheme;

        this.ready = true;
    }

    public dayNumber = (short: string) => {
        return this.momentService
            .moment()
            .day(short)
            .isoWeekday();
    };

    public workingDaysTouched(): boolean {
        return this.days.filter((f) => f.enabled !== f.old).length > 0;
    }

    public updateDuration(day: IDay, isLunch?: boolean) {
        if (!day) {
            return;
        }

        if (!day.start || !day.end) {
            day.error = true;

            return;
        }

        const startTime = day.start.split(':').map((t) => parseInt(t, DECIMAL_RADIX));
        const start = this.momentService
            .moment()
            .hours(startTime[0])
            .minutes(startTime[1])
            .seconds(0)
            .milliseconds(0);

        const endTime = day.end.split(':').map((t) => parseInt(t, DECIMAL_RADIX));
        const end = this.momentService
            .moment()
            .hours(endTime[0])
            .minutes(endTime[1])
            .seconds(0)
            .milliseconds(0);

        day.duration = this.momentService.moment.duration(end.diff(start));
        if (!isLunch && this.lunchHours.duration.asMilliseconds() > 0) {
            day.duration = day.duration.subtract(this.lunchHours.duration);
        }

        const ms = day.duration.asMilliseconds();
        day.error = ms < 0;
        if (!day.error) {
            day.error = !isLunch && ms === 0;
        }

        day.durationStr = getTimeString(day.duration, Number.MAX_SAFE_INTEGER, false);

        if (ms < 0) {
            day.durationStr = `-${day.durationStr}`;
        }

        if (isLunch) {
            this.updateDuration(this.workingHours);
        }
    }

    public checkStartEnd(day: IDay) {
        if (!day.start || !day.end) {
            day.error = true;

            return;
        }

        const startTime = day.start.split(':').map((t) => parseInt(t, DECIMAL_RADIX));
        const start = this.momentService
            .moment()
            .hours(startTime[0])
            .minutes(startTime[1])
            .seconds(0)
            .milliseconds(0);

        const endTime = day.end.split(':').map((t) => parseInt(t, DECIMAL_RADIX));
        const end = this.momentService
            .moment()
            .hours(endTime[0])
            .minutes(endTime[1])
            .seconds(0)
            .milliseconds(0);

        day.error = start.isAfter(end);
    }

    public save() {
        this.validateBtnState = ClrLoadingState.LOADING;
        this.configService
            .set({
                appName: this.appName,
                mailDomain: this.mailDomain,
                workingDays: this.days.filter((day) => day.enabled).map((day) => day.num),
                workingHours: {
                    start: this.workingHours.start,
                    end: this.workingHours.end,
                },
                lunchHours: {start: this.lunchHours.start, end: this.lunchHours.end},
                defaultTheme: this.defaultTheme,
                calendarHours: this.calendarHours,
            })
            .then(() => {
                this.configService.configObservable.subscribe((c) => {
                    this.configService.config = c;

                    this.toastService.show({
                        type: TOAST_TYPE.INFO,
                        text: 'toast_refresh_config',
                        appLevel: true,
                        callbackText: 'refresh',
                        callback: () => {
                            // tslint:disable-next-line: deprecation
                            window.location.reload(true);
                        },
                    });
                    this.validateBtnState = ClrLoadingState.SUCCESS;
                    this.form.form.markAsPristine();

                    this.days.map((m) => (m.old = m.enabled));
                });
            })
            .catch(() => {
                this.validateBtnState = ClrLoadingState.DEFAULT;
            });
    }

    public async openSignedLink() {
        let uri = this.link;

        uri = `${uri}?v=${VersionInterceptor.WWW_VERSION}`;

        const {bewit} = await this.httpClient
            .get<{bewit: string}>([API_HTTP_ROOT, API_VERSION, 'sign', encodeURIComponent(uri)].join('/'))
            .toPromise();

        window.open(`${uri}&bewit=${bewit}`, '_blank');
    }

    public readonly validate = validate;
    public readonly SETTING_THEME_VALUES_VALUES = Object.values(SETTING_THEME_VALUES);
    public readonly WWW_VERSION = version;
}
