import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {ALL_RIGHTS} from '../../../../../defs/schema-static';
import {AuthService} from '../../auth/auth.service';

@Directive({
    selector: '[sharedAccessControl]',
})
export class AccessControlDirective implements OnInit {
    private _requiredRights: ALL_RIGHTS | ALL_RIGHTS[] | false;
    @Input()
    public set sharedAccessControl(requiredRights: ALL_RIGHTS | ALL_RIGHTS[] | false) {
        this._requiredRights = requiredRights;
    }

    private elseTemplateRef: TemplateRef<any>;
    @Input()
    public set sharedAccessControlElse(elseTemplateRef: TemplateRef<any>) {
        this.elseTemplateRef = elseTemplateRef;
    }

    public isVisible = false;
    public constructor(
        private readonly viewContainerRef: ViewContainerRef,
        private readonly templateRef: TemplateRef<any>,
        private readonly authService: AuthService
    ) {}

    public async ngOnInit() {
        if (typeof this._requiredRights === 'boolean' || !this._requiredRights) {
            if (this._requiredRights === false) {
                this.hideContents();
            } else {
                // ignore directive if right is falsy (null, undefined)
                this.showContents();
            }

            return;
        }

        this.authService.rights = await this.authService.getRightsPromise;
        if (!this.authService.rights) {
            return this.hideContents();
        }

        const rights = Array.isArray(this._requiredRights) ? this._requiredRights : [this._requiredRights];
        const hasAtLeastOneRight = !!rights.find(
            (right) => !!this.authService.rights.find((_right) => _right.code === right)
        );

        // if the user has the role needed to
        // render this component we can add it
        if (hasAtLeastOneRight) {
            // if it is already visible (which can happen if
            // his roles changed) we do not need to add it a second time
            if (!this.isVisible) {
                // we update the `isVisible` property and add the
                // templateRef to the view using the
                // 'createEmbeddedView' method of the viewContainerRef
                this.showContents();
            }
        } else {
            // if the user does not have the role,
            // we update the `isVisible` property and clear
            // the contents of the viewContainerRef
            this.hideContents();
        }
    }

    private hideContents() {
        this.isVisible = false;
        if (this.elseTemplateRef) {
            this.viewContainerRef.createEmbeddedView(this.elseTemplateRef);
        } else {
            this.viewContainerRef.clear();
        }
    }

    private showContents() {
        this.isVisible = true;
        this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
}
