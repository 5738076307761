import * as i0 from "./changelog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./changelog.component";
var styles_ChangelogComponent = [i0.styles];
var RenderType_ChangelogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChangelogComponent, data: {} });
export { RenderType_ChangelogComponent as RenderType_ChangelogComponent };
export function View_ChangelogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "clr-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["changelog works!"]))], null, null); }
export function View_ChangelogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-changelog", [], null, null, null, View_ChangelogComponent_0, RenderType_ChangelogComponent)), i1.ɵdid(1, 49152, null, 0, i2.ChangelogComponent, [], null, null)], null, null); }
var ChangelogComponentNgFactory = i1.ɵccf("app-changelog", i2.ChangelogComponent, View_ChangelogComponent_Host_0, {}, {}, []);
export { ChangelogComponentNgFactory as ChangelogComponentNgFactory };
