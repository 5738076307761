import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy} from '@angular/router';

interface IRouteConfigData {
    reuse: boolean;
}

interface ICachedRoute {
    handle: DetachedRouteHandle;
    data: IRouteConfigData;
}

// stolen from https://github.com/angular/angular/issues/13869#issuecomment-354421009
@Injectable()
export class CustomReuseStrategy implements RouteReuseStrategy {
    private readonly routeCache = new Map<string, ICachedRoute>();

    public shouldReuseRoute = (future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean => {
        return future.routeConfig === curr.routeConfig;
    };

    public shouldDetach = (route: ActivatedRouteSnapshot): boolean => {
        const data = CustomReuseStrategy.getRouteData(route);

        return data && data.reuse;
    };

    public store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
        const url = this.getFullRouteUrl(route);
        const data = CustomReuseStrategy.getRouteData(route);
        this.routeCache.set(url, {handle, data});
        this.addRedirectsRecursively(route);
    }

    public shouldAttach(route: ActivatedRouteSnapshot): boolean {
        const url = this.getFullRouteUrl(route);

        return this.routeCache.has(url);
    }

    public retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
        const url = this.getFullRouteUrl(route);
        const data = CustomReuseStrategy.getRouteData(route);

        return data && data.reuse && this.routeCache.has(url) ? this.routeCache.get(url).handle : null;
    }

    private readonly addRedirectsRecursively = (route: ActivatedRouteSnapshot): void => {
        const config = route.routeConfig;
        if (config) {
            if (!config.loadChildren) {
                const routeFirstChild = route.firstChild;
                const routeFirstChildUrl = routeFirstChild
                    ? CustomReuseStrategy.getRouteUrlPaths(routeFirstChild).join('/')
                    : '';
                const childConfigs = config.children;
                if (childConfigs) {
                    const childConfigWithRedirect = childConfigs.find((c) => c.path === '' && !!c.redirectTo);
                    if (childConfigWithRedirect) {
                        childConfigWithRedirect.redirectTo = routeFirstChildUrl;
                    }
                }
            }
            route.children.forEach((childRoute) => this.addRedirectsRecursively(childRoute));
        }
    };

    private getFullRouteUrl(route: ActivatedRouteSnapshot): string {
        return this.getFullRouteUrlPaths(route)
            .filter(Boolean)
            .join('/');
    }

    private readonly getFullRouteUrlPaths = (route: ActivatedRouteSnapshot): string[] => {
        const paths = CustomReuseStrategy.getRouteUrlPaths(route);

        return route.parent ? [...this.getFullRouteUrlPaths(route.parent), ...paths] : paths;
    };

    private static getRouteUrlPaths(route: ActivatedRouteSnapshot): string[] {
        return route.url.map((urlSegment) => urlSegment.path);
    }

    private static getRouteData(route: ActivatedRouteSnapshot): IRouteConfigData {
        return route.routeConfig && (route.routeConfig.data as IRouteConfigData);
    }
}
