import * as i0 from "./wander.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./wander.component";
var styles_WanderComponent = [i0.styles];
var RenderType_WanderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WanderComponent, data: {} });
export { RenderType_WanderComponent as RenderType_WanderComponent };
export function View_WanderComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { bobby: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "monster"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setRandomColor() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(3, 0, [[1, 0], ["bobby", 1]], null, 0, "span", [["class", "body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "span", [["class", "eyes"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.rainbowMode() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "monster"; var currVal_1 = (_co.isCollapsed ? "is-collapsed" : ""); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_WanderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-wander", [], null, null, null, View_WanderComponent_0, RenderType_WanderComponent)), i1.ɵdid(1, 114688, null, 0, i3.WanderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WanderComponentNgFactory = i1.ɵccf("app-wander", i3.WanderComponent, View_WanderComponent_Host_0, { isCollapsed: "isCollapsed" }, {}, []);
export { WanderComponentNgFactory as WanderComponentNgFactory };
