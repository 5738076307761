<div [hidden]="notificationService.notificationsNotClosed.length === 0">
    <div class="list-item-notification" *ngFor="let notification of notificationService.notificationsNotClosed">
        <div style="display: flex">
            <div class="flex-0-1 pr-02">
                <clr-icon
                    *ngIf="notification.type === NOTIFICATION_TYPE.EVENT_REMINDER"
                    class="text-milestone-{{ notification.milestone.type | lowercase }}"
                    [attr.shape]="MILESTONE_TYPE_ICON[notification.milestone.type]"
                ></clr-icon>
                <clr-icon *ngIf="notification.type === NOTIFICATION_TYPE.TASK_ASSIGNED" shape="flame"></clr-icon>
            </div>

            <div class="flex-1">
                <a
                    *ngIf="notification.type === NOTIFICATION_TYPE.EVENT_REMINDER"
                    [routerLink]="'/calendar'"
                    [queryParams]="{date: notification.milestone.beginDate, milestoneId: notification.milestone.id}"
                    >{{ getNotificationText(notification) }}</a
                >
                <a
                    *ngIf="notification.type === NOTIFICATION_TYPE.TASK_ASSIGNED"
                    [routerLink]="'/firon'"
                    [queryParams]="{taskId: notification.taskId, displayRelated: 1}"
                >
                    {{ 'task_was_assigned' | translate }}
                    {{ getNotificationText(notification) }}
                </a>
            </div>
            <div
                *ngIf="notification.type === NOTIFICATION_TYPE.EVENT_REMINDER"
                class="flex-0-1 ml-05"
                [ngClass]="{late: isLate(notification.milestone.beginDate)}"
            >
                {{ notification.milestone.beginDate | momentDuration }}
            </div>
            <div class="flex-0-1 ws-nw pos-r" style="top: -0.2rem;">
                <span class="btn btn-link m-0 seen" style="min-width: unset" *ngIf="notification.seen">
                    <clr-icon shape="eye" style="opacity: 0.4"></clr-icon>
                </span>
                <button
                    class="btn btn-link m-0"
                    style="min-width: unset"
                    (click)="notificationService.markNotificationAsClosed(notification)"
                >
                    <clr-icon shape="times-circle" style="color: red"></clr-icon>
                </button>
            </div>
        </div>
    </div>
</div>
<p class="mt-0" [hidden]="notificationService.notificationsNotClosed.length > 0" translate>no_data</p>
