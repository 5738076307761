import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {ClrWizard} from '@clr/angular';
import {FormGroup} from 'ngx-strongly-typed-forms';
import {ApiRoutePlurality, HTTP_METHOD, RIGHTS} from '../../../../defs/schema-static';
import {EMPLOYEE_SCHEMA_ROUTE, IEmployee} from '../../../../defs/schema/public/Employees';
import {IProject} from '../../../../defs/schema/public/Projects';
import {AuthService} from '../auth/auth.service';
import {FormsAddTaskComponent} from '../forms/add-task/add-task.component';
import {FormsAddTimeService, IAddTimeFormModal, TaskTarget} from '../forms/add-time.service';
import {HttpRestService} from '../shared/http-rest/http-rest.service';
import {SHORTCUT_WIZARD, ShortcutHandlerService} from '../shared/shortcut-handler/shortcut-handler.service';
import {TimeDetailsComponent} from './time-details/time-details.component';
import {TimeExistingComponent} from './time-existing/time-existing.component';
import {TimeTypeComponent} from './time-type/time-type.component';

export interface ITimeWizardParams {
    startDate: string;
    endDate: string;
    employeeId?: number;
}

@Component({
    selector: 'app-time-wizard',
    templateUrl: './time-wizard.component.html',
})
export class TimeWizardComponent implements OnInit, OnChanges {
    @ViewChild(ClrWizard) public wizard: ClrWizard;

    @ViewChild(TimeTypeComponent) public typeComponent: TimeTypeComponent;
    @ViewChild(TimeExistingComponent) public existingComponent: TimeExistingComponent;
    @ViewChild(FormsAddTaskComponent) public taskComponent: FormsAddTaskComponent;
    @ViewChild(TimeDetailsComponent) public detailsComponent: TimeDetailsComponent;

    @Input() public params: Partial<ITimeWizardParams>;
    @Input() public clrWizardOpen = false;
    @Output() public clrWizardOpenChange = new EventEmitter<boolean>();

    @Output() public finish = new EventEmitter<FormGroup<IAddTimeFormModal>>();

    @Input() public project: Partial<IProject>;
    @Input() public employees: Partial<IEmployee>[];

    public form: FormGroup<IAddTimeFormModal> = FormsAddTimeService.getFormGroup();

    public submitting = false;

    public constructor(
        private readonly authService: AuthService,
        private readonly httpRest: HttpRestService,
        private readonly shortcutHandlerService: ShortcutHandlerService
    ) {}

    public ngOnInit() {
        if (!this.employees) {
            (async () => this.getEmployees())();
        }
        this.shortcutHandlerService.register({
            shortcut: SHORTCUT_WIZARD.NEXT,
            callback: (e) => {
                if (!this.wizard.currentPage.nextStepDisabled && (e.target as HTMLElement).tagName !== 'TEXTAREA') {
                    if (this.wizard.isLast) {
                        this.wizard.finish();
                    } else {
                        this.wizard.next();
                    }
                }
            },
            context: this,
            forceListen: true,
        });
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.clrWizardOpen && changes.clrWizardOpen.currentValue) {
            this.form.patchValue({
                employeeId: this.authService.user.employee.id,
            });
            this.load();
        }

        if (changes.project || changes.project) {
            this._employeeMembers = undefined;
        }
    }

    public async getEmployees() {
        this.employees = await this.httpRest
            ._request<IEmployee[]>(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, EMPLOYEE_SCHEMA_ROUTE, 'list')
            .toPromise();
    }

    public load() {
        if (this.params) {
            this.form.reset({
                employeeId: this.params.employeeId || this.form.value.employeeId,
            });
            this.typeComponent.onLoad(this.form.value);
        }
    }

    public onLoad(page: number) {
        switch (page) {
            case 1:
                this.typeComponent.onLoad();
                break;
            case 2:
                this.existingComponent.onLoad(this.form.value);
                break;
            case 3:
                this.taskComponent.load();
                break;
            case 4:
                this.detailsComponent.onLoad(this.form.value);
                break;
            default:
                return;
        }
    }

    public onCommit(page: number) {
        switch (page) {
            case 1:
                this.form.patchValue({
                    taskTarget: this.typeComponent.form.value.taskTarget,
                });
                break;
            default:
                return;
        }
    }

    public async triggerFinish() {
        const item: any = {};
        try {
            item.time = {
                ...this.typeComponent.submit().value,
            };

            if (this.existingComponent) {
                item.time = {
                    ...item.time,
                    ...this.existingComponent.submit().value,
                };
            }

            if (this.detailsComponent) {
                item.time = {
                    ...item.time,
                    ...this.detailsComponent.submit().value,
                };
            }

            if (this.taskComponent) {
                item.task = {
                    ...this.taskComponent.form,
                };
            }

            if (!item) {
                return;
            }
        } catch (err) {
            return;
        } finally {
            this.submitting = false;
        }

        this.finish.emit(item);
        this.triggerCancel();
    }

    public triggerCancel() {
        this.reset();
        this.wizard.forceFinish();
    }

    public reset(): void {
        this.wizard.reset();

        [this, this.typeComponent, this.existingComponent, this.taskComponent, this.detailsComponent]
            .filter((component: {form: FormGroup<any>}) => component && component.form)
            .map(({form}: {form: FormGroup<any>}) => form.reset());
    }

    private _employeeMembers: Partial<IEmployee>[];
    public get employeeMembers(): Partial<IEmployee>[] {
        if (!this.project) {
            return [];
        }

        if (!this._employeeMembers) {
            const employeeIds = (this.project.projectMembers || []).map(({employeeId}) => employeeId);
            this._employeeMembers = (this.employees || []).filter(({id}) => employeeIds.includes(id));
            if (!this.authService.hasRight(RIGHTS.TIME_UPDATE)) {
                this._employeeMembers = this._employeeMembers.filter((e) => e.id === this.authService.user.employee.id);
            }
        }

        return this._employeeMembers;
    }

    public readonly TASK_TARGET = TaskTarget;

    public projectChange(project: Partial<IProject>) {
        this.project = project;
        this._employeeMembers = undefined;
    }
}
