import * as i0 from "./progress-circle.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./progress-circle.component";
var styles_ProgressCircleComponent = [i0.styles];
var RenderType_ProgressCircleComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProgressCircleComponent, data: {} });
export { RenderType_ProgressCircleComponent as RenderType_ProgressCircleComponent };
function View_ProgressCircleComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "small", [["class", "ml-2 text-clip"], ["style", "font-size: 9px!important"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " / ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.part; var currVal_1 = _co.total; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_ProgressCircleComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "small", [["class", "ml-2 text-clip"], ["style", "font-size: 9px!important"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", "%"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.progressPercent; _ck(_v, 1, 0, currVal_0); }); }
export function View_ProgressCircleComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "pie"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "left-side half-circle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "right-side half-circle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "shadow"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProgressCircleComponent_1)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProgressCircleComponent_2)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.part && _co.total); _ck(_v, 6, 0, currVal_1); var currVal_2 = (!(_co.part && _co.total) && _co.progressPercent); _ck(_v, 8, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "pie-wrapper progress-", _co.progressPercent, " style-2 mr-1"); _ck(_v, 0, 0, currVal_0); }); }
export function View_ProgressCircleComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-progress-circle", [], null, null, null, View_ProgressCircleComponent_0, RenderType_ProgressCircleComponent)), i1.ɵdid(1, 49152, null, 0, i3.ProgressCircleComponent, [], null, null)], null, null); }
var ProgressCircleComponentNgFactory = i1.ɵccf("app-progress-circle", i3.ProgressCircleComponent, View_ProgressCircleComponent_Host_0, { progressPercent: "progressPercent", total: "total", part: "part" }, {}, []);
export { ProgressCircleComponentNgFactory as ProgressCircleComponentNgFactory };
