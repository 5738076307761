import { EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { concat, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { ApiRoutePlurality, HTTP_METHOD } from '../../../../defs/schema-static';
import { TASK_SCHEMA_ROUTE } from '../../../../defs/schema/public/Tasks';
import { HttpRestService } from '../shared/http-rest/http-rest.service';
import * as i0 from "@angular/core";
import * as i1 from "../shared/http-rest/http-rest.service";
import * as i2 from "@ngx-translate/core";
var AddBlockersService = (function () {
    function AddBlockersService(httpRest, translate) {
        this.httpRest = httpRest;
        this.translate = translate;
        this.callback = new EventEmitter();
        this.showBlockersModal = false;
        this.blockers = [];
        this.deletedBlockers = [];
        this.newBlockers = [];
        this.selectedBlockers = [];
        this.tasksBlockersLoading = false;
        this.tasksBlockers$ = new Subject();
        this.loadBlockerTasks();
    }
    AddBlockersService.prototype.loadTasks = function (term) {
        return this.httpRest._request(HTTP_METHOD.POST, ApiRoutePlurality.PLURAL, TASK_SCHEMA_ROUTE, 'searchTasks', {
            search: term,
            projectId: this.form.value.project,
        });
    };
    AddBlockersService.prototype.loadBlockerTasks = function () {
        var _this = this;
        this.tasksBlockers = concat(of([]), this.tasksBlockers$.pipe(debounceTime(200), distinctUntilChanged(), tap(function () { return (_this.tasksBlockersLoading = true); }), switchMap(function (term) {
            if (!term || term === '') {
                _this.tasksBlockersLoading = false;
                return of([]);
            }
            return _this.loadTasks(term).pipe(map(function (res) {
                return res.filter(function (r) {
                    return _this.newBlockers.findIndex(function (t) { return t === r.id; }) === -1;
                });
            }), catchError(function () { return of([]); }), tap(function () { return (_this.tasksBlockersLoading = false); }));
        })));
    };
    AddBlockersService.prototype.reset = function () {
        this.blockers = [];
        this.deletedBlockers = [];
        this.newBlockers = [];
        this.selectedBlockers = [];
    };
    AddBlockersService.prototype.init = function (isNgForm, form, taskEdit, blockers) {
        if (isNgForm === void 0) { isNgForm = false; }
        if (blockers === void 0) { blockers = []; }
        this.reset();
        this.form = form;
        this.taskToEdit = taskEdit;
        this.blockers = blockers;
    };
    Object.defineProperty(AddBlockersService.prototype, "blockersDeleted", {
        get: function () {
            return this.deletedBlockers;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddBlockersService.prototype, "blockersNew", {
        get: function () {
            return this.newBlockers;
        },
        enumerable: true,
        configurable: true
    });
    AddBlockersService.prototype.removeBlocker = function (i) {
        var _this = this;
        requestAnimationFrame(function () {
            _this.form.patchValue({
                blockers: _this.form.value.blockers.filter(function (blocker) { return blocker !== i.id; }),
            });
        });
    };
    AddBlockersService.prototype.updateBlockers = function () {
        var _this = this;
        requestAnimationFrame(function () {
            _this.form.patchValue({
                blockers: _this.blockers.filter(function (blocker) { return blocker && blocker !== 0; }).map(function (blocker) { return blocker; }),
            });
        });
    };
    AddBlockersService.prototype.doShowBlockersModal = function () {
        this.showBlockersModal = true;
    };
    AddBlockersService.prototype.closeBlockersModal = function () {
        this.blockers = this.blockers.filter(function (blocker) { return blocker && blocker !== 0; });
        this.showBlockersModal = false;
        if (this.callback) {
            this.callback.emit();
        }
    };
    AddBlockersService.ngInjectableDef = i0.defineInjectable({ factory: function AddBlockersService_Factory() { return new AddBlockersService(i0.inject(i1.HttpRestService), i0.inject(i2.TranslateService)); }, token: AddBlockersService, providedIn: "root" });
    return AddBlockersService;
}());
export { AddBlockersService };
