import { AfterViewInit, ElementRef, EventEmitter, OnChanges, SimpleChanges, } from '@angular/core';
var AutoresizeDirective = (function () {
    function AutoresizeDirective(element) {
        this.element = element;
        this.ngModelChange = new EventEmitter();
        this.el = element.nativeElement;
        this._clientWidth = this.el.clientWidth;
    }
    Object.defineProperty(AutoresizeDirective.prototype, "minHeight", {
        get: function () {
            return this._minHeight;
        },
        set: function (val) {
            var _this = this;
            this._minHeight = val;
            requestAnimationFrame(function () {
                _this.updateMinHeight();
            });
        },
        enumerable: true,
        configurable: true
    });
    AutoresizeDirective.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes) {
            requestAnimationFrame(function () {
                _this.adjust();
            });
        }
    };
    Object.defineProperty(AutoresizeDirective.prototype, "maxHeight", {
        get: function () {
            return this._maxHeight;
        },
        set: function (val) {
            this._maxHeight = val;
            this.updateMaxHeight();
        },
        enumerable: true,
        configurable: true
    });
    AutoresizeDirective.prototype.onResize = function (textArea) {
        if (this.el.clientWidth === this._clientWidth) {
            return;
        }
        this._clientWidth = this.element.nativeElement.clientWidth;
        this.adjust();
    };
    AutoresizeDirective.prototype.ngAfterViewInit = function () {
        var _this = this;
        var style = window.getComputedStyle(this.el, null);
        if (style.resize === 'both') {
            this.el.style.resize = 'horizontal';
        }
        else if (style.resize === 'vertical') {
            this.el.style.resize = 'none';
        }
        requestAnimationFrame(function () {
            _this.adjust();
        });
    };
    AutoresizeDirective.prototype.onInput = function (textArea) {
        this.adjust();
    };
    AutoresizeDirective.prototype.adjust = function () {
        if (this.el.scrollHeight === 0) {
            return;
        }
        if (this.el.style.height === this.element.nativeElement.scrollHeight + "px") {
            return;
        }
        this.el.style.overflow = 'hidden';
        this.el.style.height = 'auto';
        this.el.style.height = this.el.scrollHeight + "px";
    };
    AutoresizeDirective.prototype.updateMinHeight = function () {
        this.el.style.minHeight = this._minHeight + "px";
        this.el.style.overflow = 'hidden';
    };
    AutoresizeDirective.prototype.updateMaxHeight = function () {
        this.el.style.maxHeight = this._maxHeight + "px";
    };
    return AutoresizeDirective;
}());
export { AutoresizeDirective };
