<app-modal
    [(show)]="showModal"
    (hidden)="hideModale()"
    [title]="('milestone_' + selectedEvent?.type | lowercase | translate) + ': ' + selectedEvent?.milestone"
>
    <ng-template appModalBody>
        <form id="editMilestoneForm" clrForm [formGroup]="form" *ngIf="!!selectedEvent">
            <div class="clr-row" style="margin-top: 0;" *ngIf="selectedEmployee">
                <label class="clr-col-md-3 label-custom" translate>author</label>
                <div class="clr-col-md-9">
                    <app-long-avatar [code]="selectedEmployee?.user.code" [bgColor]="selectedEmployee?.user.color">
                    </app-long-avatar>
                    {{ selectedEmployee?.user.name }}
                </div>
            </div>
            <div class="clr-row mt-04" *ngIf="selectedEvent.client && selectedEvent.client > 0">
                <label class="clr-col-md-3 label-custom" translate>client</label>
                <div class="clr-col-md-9">
                    <app-long-avatar [code]="selectedClient?.user.code" [bgColor]="selectedClient?.user.color">
                    </app-long-avatar>
                    {{ selectedClient?.user.name }}
                </div>
            </div>
            <div class="clr-row mt-04" *ngIf="selectedEvent.project && selectedEvent.project > 0">
                <label class="clr-col-md-3 label-custom" translate>project</label>
                <div class="clr-col-md-9">
                    <app-long-avatar [code]="selectedProject?.code" [bgColor]="selectedProject?.color">
                    </app-long-avatar>
                    {{ selectedProject?.obs }}
                </div>
            </div>
            <clr-input-container>
                <label class="clr-col-xs-12 clr-col-md-3" translate>{{
                    selectedEvent?.type === MILESTONE_TYPES.RELEASE ? 'release_name' : 'milestone_name'
                }}</label>
                <input
                    class="clr-col-xs-12 clr-col-md-9"
                    clrInput
                    type="text"
                    [formControlName]="MILESTONE_FORM_KEYS.milestone"
                    name="milestone"
                    maxlength="{{ MAX_LENGTH_DEFAULT }}"
                />

                <clr-control-error *clrIfError="'requiredTrim'" translate>error_field_empty</clr-control-error>
                <clr-control-error *clrIfError="'maxLength'" translate>error_field_text_overflow</clr-control-error>
            </clr-input-container>
            <clr-input-container *ngIf="selectedEvent.type === MILESTONE_TYPES.RELEASE">
                <label class="clr-col-xs-12 clr-col-md-3" translate>milestone_version</label>
                <input
                    [placeholder]="'sermver_placeholder' | translate"
                    class="clr-col-xs-12 clr-col-md-9"
                    clrInput
                    type="text"
                    [formControlName]="MILESTONE_FORM_KEYS.version"
                    maxlength="{{ MAX_LENGTH_DEFAULT }}"
                    name="example"
                />
                <clr-control-error
                    *clrIfError="'validSemVer'"
                    class="lh-1"
                    [innerHTML]="'help_version' | translate"
                ></clr-control-error>
                <clr-control-error *clrIfError="'maxLength'" translate>error_field_text_overflow</clr-control-error>
            </clr-input-container>
            <clr-textarea-container>
                <label class="clr-col-xs-12 clr-col-md-3" translate>{{
                    selectedEvent?.type === MILESTONE_TYPES.RELEASE ? 'release_description' : 'milestone_description'
                }}</label>
                <textarea
                    class="clr-col-xs-12 clr-col-md-9"
                    clrTextarea
                    [formControlName]="MILESTONE_FORM_KEYS.description"
                    name="description"
                ></textarea>
            </clr-textarea-container>

            <div class="clr-row">
                <div
                    class="clr-col-8"
                    *ngIf="
                        selectedEvent.type !== MILESTONE_TYPES.RELEASE &&
                        selectedEvent.type !== MILESTONE_TYPES.DEADLINE
                    "
                >
                    <clr-date-container class="clr-row">
                        <label class="clr-col-12 clr-col-md-5" translate>date_begin</label>
                        <input
                            class="clr-col-12 clr-col-md-7"
                            type="date"
                            id="date"
                            [disabled]="!canEdit"
                            name="date"
                            [(clrDate)]="beginDate"
                            #beginDateInputElement
                        />
                    </clr-date-container>
                </div>
                <div
                    class="clr-col"
                    *ngIf="
                        selectedEvent.type === MILESTONE_TYPES.RELEASE ||
                        selectedEvent.type === MILESTONE_TYPES.DEADLINE
                    "
                >
                    <clr-date-container class="clr-row">
                        <label class="clr-col-12 clr-col-md-3" translate>date_begin</label>
                        <input
                            class="clr-col-12 clr-col-md-9"
                            type="date"
                            id="date"
                            [disabled]="!canEdit"
                            name="date"
                            [(clrDate)]="beginDate"
                            #beginDateInputElement
                        />
                    </clr-date-container>
                </div>
                <div
                    style="margin-top: 1rem;"
                    class="clr-col-4"
                    *ngIf="
                        selectedEvent.type !== MILESTONE_TYPES.RELEASE &&
                        selectedEvent.type !== MILESTONE_TYPES.DEADLINE
                    "
                >
                    <input type="time" [formControlName]="MILESTONE_FORM_KEYS.startTime" />
                </div>
            </div>

            <clr-date-container class="clr-row" *ngIf="selectedEvent.type === MILESTONE_TYPES.RELEASE">
                <label class="clr-col-12 clr-col-md-3" translate>table_target</label>
                <input
                    class="clr-col-12 clr-col-md-9"
                    type="date"
                    id="date"
                    [disabled]="!canEdit"
                    name="date"
                    [(clrDate)]="targetDate"
                    #targetDateInputElement
                />
            </clr-date-container>

            <div
                class="clr-row"
                *ngIf="
                    selectedEvent.type === MILESTONE_TYPES.MEETING ||
                    selectedEvent.type === MILESTONE_TYPES.MEETING_NO_NOTE
                "
            >
                <div class="clr-col-8">
                    <clr-date-container class="clr-row">
                        <label class="clr-col-12 clr-col-md-5" translate>date_end</label>
                        <input
                            class="clr-col-12 clr-col-md-7"
                            type="date"
                            id="date"
                            name="date"
                            [disabled]="!canEdit"
                            [(clrDate)]="endDate"
                            #endDateInputElement
                        />
                    </clr-date-container>
                </div>
                <div style="margin-top: 1rem;" class="clr-col-4">
                    <input type="time" [formControlName]="MILESTONE_FORM_KEYS.endTime" />
                </div>
            </div>

            <div class="clr-row mt-2" *ngIf="!checkDates()">
                <div class="clr-col-12">
                    <div class="alert alert-danger" role="alert">
                        <div class="alert-items">
                            <div class="alert-item static">
                                <div class="alert-icon-wrapper">
                                    <clr-icon class="alert-icon" shape="exclamation-circle"></clr-icon>
                                </div>
                                <span class="alert-text" [innerHTML]="errorsDates[errorDates] | translate"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="clr-row"
                *ngIf="
                    selectedEvent.type === MILESTONE_TYPES.MEETING ||
                    selectedEvent.type === MILESTONE_TYPES.MEETING_NO_NOTE
                "
            >
                <clr-input-container class="clr-col-3">
                    <label translate>meeting_participant</label>
                </clr-input-container>
            </div>
            <ng-select
                *ngIf="
                    selectedEvent.type === MILESTONE_TYPES.MEETING ||
                    selectedEvent.type === MILESTONE_TYPES.MEETING_NO_NOTE
                "
                [items]="members"
                [hideSelected]="true"
                [bindLabel]="'employee.user.name'"
                multiple="true"
                [formControlName]="'participants'"
                [dropdownPosition]="'top'"
            >
                <ng-template ng-label-tmp let-item="item">
                    <shared-editable-employee-label
                        [member]="item"
                        [removable]="canEdit"
                        (remove)="removeMember($event)"
                        (update)="updateMember($event)"
                    >
                    </shared-editable-employee-label>
                </ng-template>
            </ng-select>
        </form>
    </ng-template>
    <ng-template appModalFooter>
        <button
            *ngIf="
                deletable &&
                (authService.hasRight(RIGHTS.MS_DELETE) || selectedEvent?.author === authService.user.employee.id)
            "
            class="btn btn-danger btn-footer-left"
            (click)="showDeleteModal = true"
        >
            {{ (selectedEvent?.type === MILESTONE_TYPES.RELEASE ? 'delete_release' : 'delete_milestone') | translate }}
        </button>
        <button class="btn btn-secondary" (click)="hideModale()" translate>form_cancel</button>
        <button
            form="editMilestoneForm"
            class="btn btn-success"
            (click)="saveMilestone()"
            [disabled]="!form.valid"
            translate
        >
            form_save
        </button>
    </ng-template>
</app-modal>

<app-modal
    [(show)]="showDeleteModal"
    (showChange)="showModalChange.emit($event)"
    [title]="
        (selectedEvent?.type === MILESTONE_TYPES.RELEASE
            ? 'release_delete_modal_title'
            : 'milestone_delete_modal_title') | translate
    "
>
    <ng-template appModalBody>
        <p>{{ 'swal_delete_attch' | translate: {what: selectedEvent?.milestone} }}</p>
    </ng-template>
    <ng-template appModalFooter>
        <button
            class="btn btn-secondary"
            (click)="showDeleteModal = false"
            [sharedAutofocus]="showDeleteModal"
            translate
        >
            form_cancel
        </button>
        <button class="btn btn-danger" (click)="deleteMilestone()" translate>
            {{ (selectedEvent?.type === MILESTONE_TYPES.RELEASE ? 'delete_release' : 'delete_milestone') | translate }}
        </button>
    </ng-template>
</app-modal>
