var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
var AccessControlDirective = (function () {
    function AccessControlDirective(viewContainerRef, templateRef, authService) {
        this.viewContainerRef = viewContainerRef;
        this.templateRef = templateRef;
        this.authService = authService;
        this.isVisible = false;
    }
    Object.defineProperty(AccessControlDirective.prototype, "sharedAccessControl", {
        set: function (requiredRights) {
            this._requiredRights = requiredRights;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccessControlDirective.prototype, "sharedAccessControlElse", {
        set: function (elseTemplateRef) {
            this.elseTemplateRef = elseTemplateRef;
        },
        enumerable: true,
        configurable: true
    });
    AccessControlDirective.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, rights, hasAtLeastOneRight;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (typeof this._requiredRights === 'boolean' || !this._requiredRights) {
                            if (this._requiredRights === false) {
                                this.hideContents();
                            }
                            else {
                                this.showContents();
                            }
                            return [2];
                        }
                        _a = this.authService;
                        return [4, this.authService.getRightsPromise];
                    case 1:
                        _a.rights = _b.sent();
                        if (!this.authService.rights) {
                            return [2, this.hideContents()];
                        }
                        rights = Array.isArray(this._requiredRights) ? this._requiredRights : [this._requiredRights];
                        hasAtLeastOneRight = !!rights.find(function (right) { return !!_this.authService.rights.find(function (_right) { return _right.code === right; }); });
                        if (hasAtLeastOneRight) {
                            if (!this.isVisible) {
                                this.showContents();
                            }
                        }
                        else {
                            this.hideContents();
                        }
                        return [2];
                }
            });
        });
    };
    AccessControlDirective.prototype.hideContents = function () {
        this.isVisible = false;
        if (this.elseTemplateRef) {
            this.viewContainerRef.createEmbeddedView(this.elseTemplateRef);
        }
        else {
            this.viewContainerRef.clear();
        }
    };
    AccessControlDirective.prototype.showContents = function () {
        this.isVisible = true;
        this.viewContainerRef.createEmbeddedView(this.templateRef);
    };
    return AccessControlDirective;
}());
export { AccessControlDirective };
