<div class="tabs-container">
    <div class="clr-row" [class.sublime]="sublime">
        <div class="clr-row tabs" #tabsContainer>
            <div
                class="tab c-hand
                "
                [class.sublime]="sublime"
                [class.active]="tab.isActive"
                [style.background]="tab.bgColor"
                *ngFor="let tab of _tabs; let i = index"
                [attr.data-tab]="tab.name"
                (click)="clicked.emit(tab.justAButton)"
            >
                <input
                    class="d-none"
                    [id]="'tab' + i"
                    type="radio"
                    [checked]="tab.isActive"
                    [disabled]="tab.disabled"
                    (change)="selectTab(tab)"
                />
                <label
                    [for]="'tab' + i"
                    [class.text-dark]="tab.bgColor ? isColorDark(tab.bgColor) : false"
                    [class.text-light]="tab.bgColor ? !isColorDark(tab.bgColor) : false"
                    >{{ tab.label || labels[tab.name] || tab.name }}</label
                >
            </div>
        </div>

        <ng-template [ngTemplateOutlet]="tabsHeader"></ng-template>
    </div>
    <div class="tab-container"><ng-content></ng-content></div>
</div>
