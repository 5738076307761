<div class="popover-container">
    <ng-container *ngIf="project">
        <div class="signpost-flex-wrap">
            <div class="signpost-content-header">
                <p class="mt-04 clr-row">
                    <b class="clr-col-3">{{ 'project' | translate }}:</b>
                    <span class="clr-col-9" style="padding-left:0.3rem">{{ project?.project.obs }}</span>
                </p>
                <p class="mt-04 clr-row">
                    <b class="clr-col-3">{{ 'client' | translate }}:</b>
                    <span class="clr-col-9 " style="padding-left:0.3rem">{{
                        project?.project?.client?.user?.name
                    }}</span>
                </p>
            </div>
            <div class="signpost-content-body">
                <p class="mt-04 clr-row">
                    <b class="clr-col-3">{{ 'start' | translate }}: </b>
                    <span class="clr-col-9">{{ project?.beginDate | momentFormat }}</span>
                </p>
                <p class="mt-04 clr-row">
                    <b class="clr-col-3">{{ 'end' | translate }}: </b>
                    <span class="clr-col-9">{{ project?.endDate | momentFormat }}</span>
                </p>
                <p class="mt-04 clr-row clr-col-12" *ngIf="project.who"><b translate>team_members</b></p>
                <div class="mt-04 clr-row clr-col-12">
                    <app-long-avatar *ngFor="let emp of project.who" [code]="emp?.user.code" [bgColor]="emp.user.color">
                    </app-long-avatar>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="milestone">
        <div class="signpost-flex-wrap">
            <div class="signpost-content-body">
                <p class="mt-04 clr-row">
                    <b class="clr-col-4 ws-nw">
                        <clr-icon
                            class="text-milestone-{{ milestone.milestone?.type | lowercase }}"
                            [attr.shape]="MILESTONE_TYPE_ICON[(milestone.milestone?.type)]"
                        ></clr-icon
                        >{{ 'milestone_' + milestone.milestone?.type | lowercase | translate }}:</b
                    >
                    <span class="clr-col-8">{{ milestone.milestone?.obs }}</span>
                </p>
                <p class="mt-04 clr-row">
                    <b class="clr-col-4">{{ 'table_description' | translate }}:</b>
                    <span class="clr-col-8">{{ milestone.milestone?.description }}</span>
                </p>
                <p class="mt-04 clr-row">
                    <b class="clr-col-4">{{ 'start' | translate }}:</b>
                    <span class="clr-col-8">{{ milestone.milestone?.beginDate | momentFormat }}</span>
                </p>
                <p class="mt-04 clr-row" *ngIf="milestone.milestone?.target || milestone.milestone?.endDate">
                    <b class="clr-col-4">{{ 'end' | translate }}:</b>
                    <span class="clr-col-8">
                        {{
                            (milestone.milestone?.target | momentFormat) ||
                                (milestone.milestone?.endDate | momentFormat)
                        }}</span
                    >
                </p>

                <ng-container
                    *ngIf="
                        milestone?.milestone?.type === MilestonesType.MEETING ||
                        milestone?.milestone?.type === MilestonesType.MEETING_NO_NOTE
                    "
                >
                    <p class="mt-04 clr-row clr-col-12" *ngIf="milestone?.milestone?.participants?.length > 0">
                        <b translate>meeting_participant</b>
                    </p>
                    <div class="mt-04 clr-row clr-col-12">
                        <app-long-avatar
                            *ngFor="let emp of milestone?.milestone?.participants"
                            [code]="emp?.employee?.user.code"
                            [bgColor]="emp?.employee?.user.color"
                        >
                        </app-long-avatar>
                    </div>
                </ng-container>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="task">
        <div class="signpost-flex-wrap">
            <div class="signpost-content-header">
                <h3 style="font-weight:bold;">
                    {{ task?.name }}
                </h3>
            </div>
            <div class="signpost-content-body">
                <p class="mt-04 clr-row">
                    <b class="clr-col-6">{{ 'task_code' | translate }}: </b>{{ task?.code }}
                </p>

                <p class="mt-04 clr-row">
                    <b class="clr-col-6">{{ 'date_begin' | translate }}: </b>
                    {{ task?._metadata.beginDate | momentFormat }}
                </p>
                <p class="mt-04 clr-row">
                    <b class="clr-col-6">{{ 'date_end' | translate }}: </b>{{ task?._metadata.endDate | momentFormat }}
                </p>
                <p class="mt-04 clr-row">
                    <b class="clr-col-6">{{ 'task_progress' | translate }}: </b>{{ task.progress }}%
                </p>
                <p class="mt-04 clr-row">
                    <b class="clr-col-6">{{ 'time_dev' | translate }} & {{ 'time_review' | translate }}: </b>
                    <span class="clr-col-6 pl-0">
                        <app-long-avatar
                            *ngFor="let assign of task.assigned"
                            [code]="assign.employee?.user.code"
                            [bgColor]="assign.employee?.user.color"
                        >
                        </app-long-avatar>
                    </span>
                </p>
            </div>
        </div>
    </ng-container>
</div>
