import {Component, Inject, Input} from '@angular/core';
import {MomentInput} from 'moment';
import {WikiPageType} from '../../../../defs/schema-static';
import {IMilestone, MILESTONE_SCHEMA_ROUTE} from '../../../../defs/schema/public/Milestones';
import {HttpRestService} from '../shared/http-rest/http-rest.service';
import {MomentService} from '../shared/moment/moment.service';
import {WINDOW} from '../shared/windowProvider';

@Component({
    selector: 'app-dashboard-reminder',
    templateUrl: './dashboard-reminder.component.html',
    styleUrls: ['./dashboard-reminder.component.scss'],
})
export class DashboardReminderComponent {
    @Input()
    public meeting: IMilestone;
    public showModal: boolean;
    public startTime: MomentInput;
    public endTime: MomentInput;

    public constructor(
        private readonly httpRest: HttpRestService,
        private readonly momentService: MomentService,
        @Inject(WINDOW) private readonly window: Window
    ) {
        this.showModal = false;
    }

    public show() {
        this.showModal = true;

        setTimeout(() => {
            this.startTime = this.momentService.moment(this.meeting.beginDate).format('HH:mm');
            this.endTime = this.momentService.moment(this.meeting.endDate).format('HH:mm');
        }, 50);
    }

    protected valid() {
        const begin = this.momentService.moment(this.meeting.beginDate).format('YYYY-MM-DD');
        const end = this.momentService.moment(this.meeting.endDate).format('YYYY-MM-DD');
        this.meeting.beginDate = this.momentService.moment(`${begin}T${this.startTime}`);
        this.meeting.endDate = this.momentService.moment(`${end}T${this.endTime}`);
        this.httpRest
            .post(MILESTONE_SCHEMA_ROUTE, {
                id: this.meeting.id,
                beginDate: this.meeting.beginDate && this.momentService.moment(this.meeting.beginDate).utc(),
                endDate: this.meeting.endDate && this.momentService.moment(this.meeting.endDate).utc(),
            })
            .subscribe(() => {
                this.goTo();
                this.showModal = false;
            });
    }

    protected validate() {
        return !(
            !this.momentService.moment(this.startTime, 'HH:mm', true).isValid() ||
            !this.momentService.moment(this.endTime, 'HH:mm', true).isValid()
        );
    }

    protected async goTo() {
        let args = `/wiki/edit?meetingId=${this.meeting.id}&type=${WikiPageType.MEETING_NOTE}`;
        args += this.meeting.clientId ? `&client=${this.meeting.clientId}` : '';
        args += this.meeting.projectId ? `&project=${this.meeting.projectId}` : '';
        this.window.open(args);
    }

    protected readonly WikiPageType = WikiPageType;
}
