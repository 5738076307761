<div class="container">
    <div class="clr-row piechart-line">
        <div class="clr-col-3">
            <div class="card">
                <div class="card-header"><h3 class="card-title-h3" translate>task_division</h3></div>
                <div class="card-block">
                    <div class="card-text">
                        <div class="spinner-bg-c-small" *ngIf="!pieTasksShow"><div class="spinner-c"></div></div>
                        <div class="nothing-pie" *ngIf="pieTasksShow && (!pieTasks || pieTasks.length === 0)" translate>
                            no_data
                        </div>
                        <div class="canvas-container">
                            <ngx-charts-pie-chart
                                *ngIf="pieTasksShow"
                                [results]="pieTasks"
                                [labels]="true"
                                [animations]="true"
                                [scheme]="colors"
                                emptyColor="#1e222e"
                                [textColor]="isDark() ? '#ffffff' : '#000000'"
                            ></ngx-charts-pie-chart>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="clr-col-3">
            <div class="card">
                <div class="card-header"><h3 class="card-title-h3" translate>bugs_current</h3></div>
                <div class="card-block">
                    <div class="card-text">
                        <div class="spinner-bg-c-small" *ngIf="!pieBugsShow"><div class="spinner-c"></div></div>
                        <div class="nothing-pie" *ngIf="pieBugsShow && (!pieBugs || pieBugs.length === 0)" translate>
                            no_data
                        </div>
                        <div class="canvas-container">
                            <ngx-charts-pie-chart
                                *ngIf="pieBugsShow"
                                [results]="pieBugs"
                                [labels]="true"
                                [animations]="true"
                                [scheme]="colors"
                                emptyColor="#1e222e"
                                textColor="#ffffff"
                            >
                            </ngx-charts-pie-chart>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="clr-col-3">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title-h3"><span translate>overdue_summary</span> (min)</h3>
                </div>
                <div class="card-block">
                    <div class="card-text">
                        <div class="spinner-bg-c-small" *ngIf="!pieTimeSheetShow"><div class="spinner-c"></div></div>
                        <div
                            class="nothing-pie"
                            *ngIf="pieTimeSheetShow && (!pieTimeSheet || pieTimeSheet.length === 0)"
                            translate
                        >
                            no_data
                        </div>
                        <div class="canvas-container">
                            <ngx-charts-bar-horizontal
                                *ngIf="pieTimeSheetShow"
                                [results]="pieTimeSheet"
                                [animations]="true"
                                [xAxis]="true"
                                [yAxis]="true"
                                [legend]="false"
                                [showGridLines]="true"
                                [roundEdges]="true"
                                [scheme]="colors"
                                emptyColor="#1e222e"
                                textColor="#ffffff"
                            >
                            </ngx-charts-bar-horizontal>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="clr-col-3">
            <div class="card">
                <div class="card-header"><h3 class="card-title-h3" translate>global_completion</h3></div>
                <div class="card-block">
                    <div class="card-text">
                        <div class="spinner-bg-c-small" *ngIf="!pieCompletionShow"><div class="spinner-c"></div></div>
                        <div
                            class="nothing-pie"
                            *ngIf="pieCompletionShow && (!pieCompletion || pieCompletion.length === 0)"
                            translate
                        >
                            no_data
                        </div>
                        <div class="canvas-container">
                            <ngx-charts-pie-chart
                                *ngIf="pieCompletionShow"
                                [results]="pieCompletion"
                                [labels]="true"
                                [animations]="true"
                                [scheme]="colors"
                                emptyColor="#1e222e"
                                textColor="#ffffff"
                            >
                            </ngx-charts-pie-chart>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="clr-row pb-1">
        <div class="clr-col-3">
            <div class="clr-row">
                <!-- Calendar -->
                <div class="clr-col-12"><app-dashboard-calendar [events]="events"></app-dashboard-calendar></div>
                <!-- Mails and reminders -->
                <div
                    class="clr-col-12"
                    *ngIf="
                        pleaseWaitMeeting ||
                        pleaseWaitMail ||
                        (mails && mails.length > 0) ||
                        (meetings && meetings.length > 0)
                    "
                >
                    <div class="card">
                        <div class="card-block"><h3 class="card-title" translate>reminders</h3></div>
                        <ul class="list-group list-group-flush">
                            <div class="spinner-bg-c-small" *ngIf="pleaseWaitMeeting">
                                <div class="spinner-c"></div>
                            </div>
                            <ng-container *ngIf="!pleaseWaitMeeting">
                                <ng-container *ngFor="let meeting of meetings">
                                    <app-dashboard-reminder [meeting]="meeting"></app-dashboard-reminder>
                                </ng-container>
                            </ng-container>

                            <div class="spinner-bg-c-small" *ngIf="pleaseWaitMail"><div class="spinner-c"></div></div>
                            <ng-container *ngIf="!pleaseWaitMail">
                                <li class="list-group-item" *ngFor="let mail of mails">
                                    <div class="item">
                                        <span class="event-name"> {{ mail.task.name }}</span>
                                        <div class="event-subtitle">
                                            <span>{{ mail.subject }}</span>
                                        </div>
                                        <div class="event-detail" [title]="mail.updatedAt | momentDuration">
                                            <i>{{ mail.updatedAt | momentFormat }}</i>
                                        </div>
                                    </div>
                                    <div class="action">
                                        <a
                                            [href]="mail.weblink"
                                            target="_blank"
                                            class="c-hand"
                                            style="margin-right: 10px;"
                                        >
                                            <clr-icon size="24" shape="envelope"></clr-icon>
                                        </a>
                                        <a (click)="dismissTaskMail(mail)" class="c-hand">
                                            <clr-icon size="24" shape="times-circle"></clr-icon>
                                        </a>
                                    </div>
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="clr-col-4">
            <div class="clr-row">
                <div class="clr-col-12">
                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title-h3" translate>reports_projects_division</h3>
                        </div>
                        <div class="card-block">
                            <div class="card-text">
                                <div class="spinner-bg-c-small" *ngIf="!projectsDivisionShow">
                                    <div class="spinner-c"></div>
                                </div>
                                <div
                                    class="nothing-pie"
                                    *ngIf="projectsDivisionShow && (!projectsDivision || projectsDivision.length === 0)"
                                    translate
                                >
                                    no_data
                                </div>
                                <div
                                    class="canvas-container"
                                    *ngIf="projectsDivisionShow && projectsDivision && projectsDivision.length > 0"
                                >
                                    <ngx-charts-pie-chart
                                        [results]="projectsDivision"
                                        [legendTitle]="''"
                                        [tooltipDisabled]="true"
                                        [legend]="true"
                                        [animations]="true"
                                        [scheme]="colors"
                                        emptyColor="#1e222e"
                                        textColor="#ffffff"
                                    ></ngx-charts-pie-chart>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="clr-row">
                <div class="clr-col-12">
                    <div class="card">
                        <div class="card-block"><h4 class="card-title" translate>projects</h4></div>
                        <ul class="list-group list-group-flush">
                            <div class="spinner-bg-c-small" *ngIf="!projectsStateShow">
                                <div class="spinner-c"></div>
                            </div>
                            <ng-container *ngIf="projectsStateShow">
                                <li class="list-group-item project-line" *ngFor="let project of projectsState">
                                    <div class="clr-row" style="width: 100%;">
                                        <div class="clr-col-12">
                                            <div class="clr-row  progress-block">
                                                <label class="clr-col-5">
                                                    <a [routerLink]="'/project/' + project.id">
                                                        {{ project.code }}-{{ project.name }}
                                                    </a>
                                                </label>
                                                <div
                                                    class="clr-col-7 progress-static labeled"
                                                    [ngStyle]="{color: project.color}"
                                                >
                                                    <div
                                                        class="progress-meter"
                                                        [attr.data-value]="getCompletion(project)"
                                                        max="100"
                                                    ></div>
                                                    <span>{{ getCompletion(project) + '%' }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="clr-col-4 text-right">
                                            <div>{{ project.assigned }}</div>
                                            <small translate>assigned_task_to_me</small>
                                        </div>
                                        <div class="clr-col-4 text-right">
                                            <div>{{ getFailedReviewRate(project) }} %</div>
                                            <small translate>failed_review</small>
                                        </div>
                                        <div class="clr-col-4 text-right">
                                            <div>{{ getDevVSTest(project) }}</div>
                                            <small>dev VS test</small>
                                        </div>
                                    </div>
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="clr-col-5">
            <div class="clr-row">
                <div class="clr-col-12">
                    <!-- Roadmap -->
                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title-h3" translate>project_roadmap</h3>
                            <div class="roadmap-select">
                                <select
                                    clrSelect
                                    name="roadmapFilter"
                                    (change)="changeRoadmapFilter()"
                                    [(ngModel)]="roadmapFilter"
                                >
                                    <option [value]="item.value" *ngFor="let item of rodmapSelectOptions" translate>{{
                                        item.i18n
                                    }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="card-block">
                            <ul class="list-group list-group-flush">
                                <div class="spinner-bg-c-small" *ngIf="pleaseWaitRoad">
                                    <div class="spinner-c"></div>
                                </div>
                                <div
                                    class="nothing"
                                    *ngIf="!pleaseWaitRoad && (roadmap && roadmap.length === 0)"
                                    translate
                                >
                                    no_data
                                </div>
                                <ng-container *ngIf="!pleaseWaitRoad">
                                    <li class="list-group-item roadmap-line" *ngFor="let road of roadmap">
                                        <app-dashboard-roadmap
                                            style="width: 100%;"
                                            [roadmap]="road"
                                        ></app-dashboard-roadmap>
                                    </li>
                                </ng-container>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Assigned to me -->
            <div class="clr-row">
                <div class="clr-col-12">
                    <div class="card">
                        <div class="card-header"><h3 class="card-title-h3" translate>assigned_task_to_me</h3></div>
                        <div class="card-block list-height">
                            <div class="spinner-bg-c-small" *ngIf="pleaseWaitTask"><div class="spinner-c"></div></div>
                            <div class="nothing" *ngIf="!pleaseWaitTask && (tasks && tasks.length === 0)" translate>
                                no_data
                            </div>
                            <ng-container *ngIf="!pleaseWaitTask">
                                <table class="table table-noborder mt-0">
                                    <tbody>
                                        <tr *ngFor="let task of tasks">
                                            <app-dashboard-task [task]="task"></app-dashboard-task>
                                        </tr>
                                    </tbody>
                                </table>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
