import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {Observable} from 'rxjs';
import {APP_HTTP_HEADERS} from '../../../../defs/schema-static';
import {environment} from '../../environments/environment';

@Injectable()
export class VersionInterceptor implements HttpInterceptor {
    public intercept = (request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> => {
        return next.handle(VersionInterceptor.setVersionHeader(request));
    };

    private static setVersionHeader(request: HttpRequest<any>): HttpRequest<any> {
        return request.clone({
            setHeaders: {
                [APP_HTTP_HEADERS.WWW_VERSION]: `${VersionInterceptor.WWW_VERSION}`,
            },
        });
    }

    public static readonly WWW_VERSION = environment.version;
}
