<div class="clr-row roadmap">
    <div class="clr-row" style="width: 100%;">
        <div class="clr-col-12">
            <div class="clr-row progress-block">
                <label class="clr-col-4">
                    <a [routerLink]="'/project/' + roadmap.project.id"> {{ roadmap.project.obs }} </a>
                </label>
                <div class="clr-col-8 progress-static success labeled">
                    <div class="progress-meter" [attr.data-value]="doneRatio" max="100"></div>
                    <clr-icon
                        size="13"
                        shape="circle"
                        class="is-solid"
                        [ngClass]="{
                            'led-green': late <= 0 && !taskLate && !possiblyLate,
                            'led-yellow': late <= 0 && !taskLate && possiblyLate,
                            'led-red': late > 0 || taskLate
                        }"
                    ></clr-icon>
                </div>
                <div class="roadmap-info">
                    <clr-tooltip>
                        <clr-icon clrTooltipTrigger shape="info-circle" size="24"></clr-icon>
                        <clr-tooltip-content clrPosition="left" clrSize="md" *clrIfOpen>
                            <div class="clr-row" *ngIf="stats.length > 0">
                                <div class="clr-col-12" translate>task_division</div>
                            </div>
                            <div class="clr-row">
                                <div class="clr-col-12">
                                    <ng-container *ngFor="let stat of stats">
                                        <div class="clr-row">
                                            <div class="clr-col-8" translate>{{ stat.label | lowercase }}</div>
                                            <div class="clr-col-4">{{ stat.value }}</div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="clr-row mt-1" *ngIf="distribTasks.length > 0">
                                <div class="clr-col-12">{{ labelLate }}</div>
                            </div>
                            <div class="clr-row">
                                <div class="clr-col-12">
                                    <ng-container *ngFor="let dist of distribTasks">
                                        <div class="clr-row">
                                            <div class="clr-col-12">{{ dist }}</div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </clr-tooltip-content>
                    </clr-tooltip>
                </div>
            </div>
        </div>
    </div>
    <div class="clr-row" style="width: 100%;">
        <div class="clr-col-5">
            {{ roadmap.milestone.obs }} ({{ roadmap.milestone.version }}) <br />
            <a [routerLink]="'/client/' + roadmap.client.id">
                <small>{{ roadmap.client.user.name }}</small>
            </a>
        </div>
        <div
            class="clr-col-3"
            [ngClass]="{late: late > 0, today: late === 0}"
            [title]="roadmap.milestone.target | momentDuration"
        >
            {{ roadmap.milestone.target | momentFormat }}
        </div>
        <div class="clr-col-4 text-right" tooltip-custom>
            <small translate [translateParams]="{done: done, total: total}"> roadmap_issues_detail </small>
        </div>
    </div>
</div>
