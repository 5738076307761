var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { HttpClient } from '@angular/common/http';
import { OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ClrLoadingState } from '@clr/angular';
import { TranslateService } from '@ngx-translate/core';
import { getConfigKeys } from '../../../../../defs/businessRules';
import { DECIMAL_RADIX } from '../../../../../defs/schema-static';
import { getTimeString } from '../../../../../defs/schema/public/Times';
import { SETTING_THEME_VALUES } from '../../../../../defs/schema/public/Users';
import { version } from '../../../../package.json';
import { API_HTTP_ROOT, API_VERSION } from '../../app-static';
import { AuthService } from '../../auth/auth.service';
import { validate } from '../../forms/validators/form.validator';
import { ConfigService } from '../../shared/config/config.service';
import { MomentService } from '../../shared/moment/moment.service';
import { TOAST_TYPE, ToastService } from '../../shared/toast/toast.service';
import { VersionInterceptor } from '../../version/version.interceptor';
var AdminMainComponent = (function () {
    function AdminMainComponent(translate, toastService, configService, httpClient, authService, momentService) {
        var _this = this;
        this.translate = translate;
        this.toastService = toastService;
        this.configService = configService;
        this.httpClient = httpClient;
        this.authService = authService;
        this.momentService = momentService;
        this.link = [API_HTTP_ROOT, API_VERSION, ''].join('/');
        this.ready = false;
        this.validateBtnState = ClrLoadingState.DEFAULT;
        this.dayNumber = function (short) {
            return _this.momentService
                .moment()
                .day(short)
                .isoWeekday();
        };
        this.validate = validate;
        this.SETTING_THEME_VALUES_VALUES = Object.values(SETTING_THEME_VALUES);
        this.WWW_VERSION = version;
    }
    AdminMainComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var cfg, rules, rulesObj, appName, mailDomain, workingDays, workingHours, lunchHours, defaultTheme, calendarHours;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.configService.get()];
                    case 1:
                        cfg = _a.sent();
                        rules = [
                            'appName',
                            'mailDomain',
                            'workingDays',
                            'workingHours',
                            'lunchHours',
                            'defaultTheme',
                            'calendarHours',
                        ];
                        rulesObj = getConfigKeys(cfg, rules);
                        appName = rulesObj.appName, mailDomain = rulesObj.mailDomain, workingDays = rulesObj.workingDays, workingHours = rulesObj.workingHours, lunchHours = rulesObj.lunchHours, defaultTheme = rulesObj.defaultTheme, calendarHours = rulesObj.calendarHours;
                        this.appName = appName;
                        this.mailDomain = mailDomain;
                        this.days = this.momentService.moment.weekdays(true).map(function (day) {
                            var dayNum = _this.dayNumber(day);
                            return {
                                initial: day,
                                num: dayNum,
                                enabled: workingDays.indexOf(dayNum) !== -1,
                                old: workingDays.indexOf(dayNum) !== -1,
                            };
                        });
                        this.workingHours = workingHours;
                        this.lunchHours = lunchHours;
                        this.calendarHours = calendarHours;
                        this.updateDuration(this.lunchHours, true);
                        this.defaultTheme = defaultTheme;
                        this.ready = true;
                        return [2];
                }
            });
        });
    };
    AdminMainComponent.prototype.workingDaysTouched = function () {
        return this.days.filter(function (f) { return f.enabled !== f.old; }).length > 0;
    };
    AdminMainComponent.prototype.updateDuration = function (day, isLunch) {
        if (!day) {
            return;
        }
        if (!day.start || !day.end) {
            day.error = true;
            return;
        }
        var startTime = day.start.split(':').map(function (t) { return parseInt(t, DECIMAL_RADIX); });
        var start = this.momentService
            .moment()
            .hours(startTime[0])
            .minutes(startTime[1])
            .seconds(0)
            .milliseconds(0);
        var endTime = day.end.split(':').map(function (t) { return parseInt(t, DECIMAL_RADIX); });
        var end = this.momentService
            .moment()
            .hours(endTime[0])
            .minutes(endTime[1])
            .seconds(0)
            .milliseconds(0);
        day.duration = this.momentService.moment.duration(end.diff(start));
        if (!isLunch && this.lunchHours.duration.asMilliseconds() > 0) {
            day.duration = day.duration.subtract(this.lunchHours.duration);
        }
        var ms = day.duration.asMilliseconds();
        day.error = ms < 0;
        if (!day.error) {
            day.error = !isLunch && ms === 0;
        }
        day.durationStr = getTimeString(day.duration, Number.MAX_SAFE_INTEGER, false);
        if (ms < 0) {
            day.durationStr = "-" + day.durationStr;
        }
        if (isLunch) {
            this.updateDuration(this.workingHours);
        }
    };
    AdminMainComponent.prototype.checkStartEnd = function (day) {
        if (!day.start || !day.end) {
            day.error = true;
            return;
        }
        var startTime = day.start.split(':').map(function (t) { return parseInt(t, DECIMAL_RADIX); });
        var start = this.momentService
            .moment()
            .hours(startTime[0])
            .minutes(startTime[1])
            .seconds(0)
            .milliseconds(0);
        var endTime = day.end.split(':').map(function (t) { return parseInt(t, DECIMAL_RADIX); });
        var end = this.momentService
            .moment()
            .hours(endTime[0])
            .minutes(endTime[1])
            .seconds(0)
            .milliseconds(0);
        day.error = start.isAfter(end);
    };
    AdminMainComponent.prototype.save = function () {
        var _this = this;
        this.validateBtnState = ClrLoadingState.LOADING;
        this.configService
            .set({
            appName: this.appName,
            mailDomain: this.mailDomain,
            workingDays: this.days.filter(function (day) { return day.enabled; }).map(function (day) { return day.num; }),
            workingHours: {
                start: this.workingHours.start,
                end: this.workingHours.end,
            },
            lunchHours: { start: this.lunchHours.start, end: this.lunchHours.end },
            defaultTheme: this.defaultTheme,
            calendarHours: this.calendarHours,
        })
            .then(function () {
            _this.configService.configObservable.subscribe(function (c) {
                _this.configService.config = c;
                _this.toastService.show({
                    type: TOAST_TYPE.INFO,
                    text: 'toast_refresh_config',
                    appLevel: true,
                    callbackText: 'refresh',
                    callback: function () {
                        window.location.reload(true);
                    },
                });
                _this.validateBtnState = ClrLoadingState.SUCCESS;
                _this.form.form.markAsPristine();
                _this.days.map(function (m) { return (m.old = m.enabled); });
            });
        })
            .catch(function () {
            _this.validateBtnState = ClrLoadingState.DEFAULT;
        });
    };
    AdminMainComponent.prototype.openSignedLink = function () {
        return __awaiter(this, void 0, void 0, function () {
            var uri, bewit;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        uri = this.link;
                        uri = uri + "?v=" + VersionInterceptor.WWW_VERSION;
                        return [4, this.httpClient
                                .get([API_HTTP_ROOT, API_VERSION, 'sign', encodeURIComponent(uri)].join('/'))
                                .toPromise()];
                    case 1:
                        bewit = (_a.sent()).bewit;
                        window.open(uri + "&bewit=" + bewit, '_blank');
                        return [2];
                }
            });
        });
    };
    return AdminMainComponent;
}());
export { AdminMainComponent };
