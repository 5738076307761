import {Component, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {IProjectDash} from '../../../../defs/businessRules';
import {ApiRoutePlurality, BudgetStatus, HTTP_METHOD, ProjectMemberType, RIGHTS} from '../../../../defs/schema-static';
import {IClient} from '../../../../defs/schema/public/Clients';
import {IEmployee} from '../../../../defs/schema/public/Employees';
import {IProject, PROJECT_SCHEMA_ROUTE} from '../../../../defs/schema/public/Projects';
import {CLIENT_SORT_FUNCTION} from '../../../../defs/sorters';
import {AuthService} from '../auth/auth.service';
import {validate} from '../forms/validators/form.validator';
import {HttpRestService} from '../shared/http-rest/http-rest.service';

@Component({
    selector: 'app-projects',
    templateUrl: './projects.component.html',
})
export class ProjectsComponent {
    public projects: IProjectDash[];
    public clients: Partial<IClient>[] = [];
    // private pleaseWait = true;
    @ViewChild('f')
    public form: NgForm;

    public queryClientId: number;

    public showCreateModal = false;

    public constructor(
        private readonly httpRest: HttpRestService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly authService: AuthService
    ) {
        this.httpRest
            ._request<{projects: IProject[]; clients: IClient[]; employees: IEmployee[]}>(
                HTTP_METHOD.GET,
                ApiRoutePlurality.PLURAL,
                PROJECT_SCHEMA_ROUTE,
                'withBudgets'
            )
            .subscribe(({projects, clients, employees}) => {
                this.clients = clients.sort(CLIENT_SORT_FUNCTION);

                projects.map((project) =>
                    project.projectMembers.map(
                        (projectMember) =>
                            (projectMember.employee = employees.find(({id}) => id === projectMember.employeeId))
                    )
                );

                this.projects = projects.map((project) => ({
                    client: clients.find(({id}) => id === project.clientId),
                    roleType:
                        project.projectMembers
                            .filter((projectMember) => projectMember.employeeId === this.authService.user.employee.id)
                            .map((projectMember) => projectMember.type)[0] || undefined,
                    managers: (project.projectMembers || []).filter(
                        (projectMember) => projectMember.type === ProjectMemberType.MANAGER
                    ),
                    budgetSum:
                        project.budgets
                            .filter((budget) => budget.status !== BudgetStatus.CANCELLED)
                            .reduce((sum, budget) => sum + budget.price, 0) || 0,
                    ...project,
                }));

                // this.pleaseWait = false;
            });

        this.route.queryParams.subscribe(async (queryParams) => {
            if (queryParams.action === 'create' && queryParams.clientId) {
                this.queryClientId = Number(queryParams.clientId);
                this.showCreateModal = true;
            }

            if (queryParams.clientId || queryParams.action) {
                requestAnimationFrame(async () =>
                    this.router.navigate([], {
                        relativeTo: this.route,
                        queryParams: {action: null, clientId: null},
                        queryParamsHandling: 'merge',
                    })
                );
            }
        });
    }

    public readonly validate = validate;
    public readonly RIGHTS = RIGHTS;
}
