<form clrForm [formGroup]="form" [clrLayout]="formLayout">
    <clr-input-container>
        <label translate>placeholder_name</label>
        <input
            clrInput
            [formControlName]="CLIENT_FORM_KEYS.name"
            #nameElement
            [sharedAutofocus]="clrWizardOpen"
            maxlength="{{ MAX_LENGTH_DEFAULT }}"
        />

        <clr-control-error *clrIfError="'requiredTrim'" translate>error_field_empty</clr-control-error>
        <clr-control-error *clrIfError="'maxLength'" translate>error_field_text_overflow</clr-control-error>
    </clr-input-container>

    <clr-input-container>
        <label translate>placeholder_code</label>
        <input
            clrInput
            [formControlName]="CLIENT_FORM_KEYS.code"
            maxlength="{{ MAX_LENGTH_CLIENT_CODE }}"
            appUppercaseInput
        />
        <clr-control-helper>{{ MAX_LENGTH_CLIENT_CODE }} {{ 'max_length_code' | translate }}</clr-control-helper>

        <clr-control-error *clrIfError="'requiredTrim'" translate>error_field_empty</clr-control-error>
        <clr-control-error *clrIfError="'maxLength'" translate>error_field_text_overflow</clr-control-error>
        <clr-control-error *clrIfError="'pattern'" translate>error_field_pattern_capital_numeric</clr-control-error>
    </clr-input-container>

    <clr-textarea-container>
        <label translate>placeholder_observation</label>
        <textarea
            class="w-100"
            clrTextarea
            [formControlName]="CLIENT_FORM_KEYS.obs"
            maxlength="{{ MAX_LENGTH_DEFAULT }}"
        ></textarea>
        <clr-control-helper translate>helper_field_optional</clr-control-helper>

        <clr-control-error *clrIfError="'maxLength'" translate>error_field_text_overflow</clr-control-error>
    </clr-textarea-container>

    <clr-input-container>
        <label translate>badge_color</label> <input clrInput [formControlName]="CLIENT_FORM_KEYS.color" type="color" />
        <clr-control-error translate>error_field_empty</clr-control-error>
    </clr-input-container>
</form>
