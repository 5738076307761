import {Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import 'fullcalendar';
import 'fullcalendar/dist/locale/fr.js';
import $ from 'jquery';
import {forkJoin, Subscription} from 'rxjs';
import {getConfigKeys, getWorkingHours} from '../../../../defs/businessRules';
import {
    ApiRoutePlurality,
    DECIMAL_RADIX,
    HTTP_METHOD,
    ISO_DATE_FORMAT,
    MILESTONE_TYPE_ICON,
    MilestonesType,
    RIGHTS,
} from '../../../../defs/schema-static';
import {CLIENT_SCHEMA_ROUTE, IClient} from '../../../../defs/schema/public/Clients';
import {EMPLOYEE_SCHEMA_ROUTE, IEmployee} from '../../../../defs/schema/public/Employees';
import {IMeetingParticipant} from '../../../../defs/schema/public/MeetingParticipants';
import {IMilestone, MILESTONE_SCHEMA_ROUTE} from '../../../../defs/schema/public/Milestones';
import {IProject, PROJECT_SCHEMA_ROUTE} from '../../../../defs/schema/public/Projects';
import {isColorDark} from '../app-static';
import {AuthService} from '../auth/auth.service';
import {
    FormsAddMilestoneService,
    IMilestoneFormValues,
    MILESTONE_FORM_KEYS,
    MilestoneTarget,
} from '../forms/add-milestone.service';
import {validate} from '../forms/validators/form.validator';
import {ConfigService} from '../shared/config/config.service';
import {HttpRestService} from '../shared/http-rest/http-rest.service';
import {MomentService} from '../shared/moment/moment.service';
import {SHORTCUT_CREATE, ShortcutHandlerService} from '../shared/shortcut-handler/shortcut-handler.service';
import {ToastService} from '../shared/toast/toast.service';
import {WINDOW} from '../shared/windowProvider';

@Component({
    selector: 'app-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CalendarComponent implements OnInit, OnDestroy {
    public selectedEvent: any;
    private events: any[];
    public showCalendarModal = false;

    public clients: IClient[] = [];
    public employees: IEmployee[] = [];
    public projects: IProject[] = [];

    public filteredProjects: IProject[] = [];
    public filteredEmployees: IEmployee[] = [];

    public employeeFilter: number = null;
    public projectFilter: number = null;
    public clientFilter: number = null;

    public projectModel: IProject;
    public employeeModel: IEmployee;

    public typeFilter: string;
    public submitSubscriber: Subscription;

    public editTask: Partial<IMilestoneFormValues>; // use for editing the selected one

    @ViewChild('participantSelect')
    public participantSelect: ElementRef;

    @ViewChild('calendar')
    public calendarElement: ElementRef;

    public loading = true;
    public initialized = false;

    private gotoDate: string;
    private gotoMilestoneId: number;

    private calendarEvents: any[] = [];

    public constructor(
        @Inject(WINDOW) private readonly window: Window,
        private readonly httpRest: HttpRestService,
        private readonly formsAddMilestoneService: FormsAddMilestoneService,
        private readonly translate: TranslateService,
        private readonly authService: AuthService,
        private readonly toastService: ToastService,
        public shortcutHandlerService: ShortcutHandlerService,
        private readonly configService: ConfigService,
        private readonly momentService: MomentService,
        private readonly route: ActivatedRoute,
        private readonly router: Router
    ) {}

    public ngOnDestroy() {
        $(this.calendarElement.nativeElement).fullCalendar('destroy');
    }

    public ngOnInit() {
        forkJoin(
            this.httpRest._request<IEmployee[]>(
                HTTP_METHOD.GET,
                ApiRoutePlurality.PLURAL,
                EMPLOYEE_SCHEMA_ROUTE,
                'calendar'
            ),
            this.httpRest._request<IClient[]>(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, CLIENT_SCHEMA_ROUTE, 'light'),
            this.httpRest._request<IProject[]>(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, PROJECT_SCHEMA_ROUTE, 'light')
        ).subscribe((result) => {
            [this.employees, this.clients, this.projects] = result;
            this.filteredEmployees = this.employees.sort((a, b) => a.user.name.localeCompare(b.user.name));
            this.filteredProjects = this.projects;
            this.init();
        });

        this.route.queryParams.subscribe((queryParams) => {
            if (queryParams.date) {
                if (!this.initialized) {
                    this.gotoDate = queryParams.date;
                } else {
                    $(this.calendarElement.nativeElement).fullCalendar('gotoDate', queryParams.date);
                }
            }
            if (queryParams.milestoneId) {
                const milestoneId = Number(queryParams.milestoneId);

                const event = this.calendarEvents.find(({id}) => id === milestoneId);

                if (event) {
                    this.editMilestone(event);
                } else {
                    this.gotoMilestoneId = milestoneId;
                }
            }

            this.router.navigate([], {relativeTo: this.route, queryParams: {}});
        });
    }

    private getMinMaxTime() {
        const calendarTime = getConfigKeys(this.configService.config, ['calendarHours']);
        if (calendarTime && calendarTime.calendarHours) {
            const confStart: string[] = calendarTime.calendarHours.start.split(':');
            const start = this.momentService
                .moment()
                .hours(parseInt(confStart[0], DECIMAL_RADIX))
                .minutes(parseInt(confStart[1], DECIMAL_RADIX));
            const confEnd: string[] = calendarTime.calendarHours.end.split(':');
            const end = this.momentService
                .moment()
                .hours(parseInt(confEnd[0], DECIMAL_RADIX))
                .minutes(parseInt(confEnd[1], DECIMAL_RADIX));

            return {start: start.format('HH:mm:00'), end: end.format('HH:mm:00')};
        }

        return {start: '08:00:00', end: '18:00:00'};
    }

    private init() {
        const workingHours = this.getMinMaxTime();
        $(this.calendarElement.nativeElement).fullCalendar({
            weekends: true,
            header: {
                left: 'title',
                right: 'month,agendaWeek,agendaDay today prev,next',
            },
            locale: this.translate.currentLang.toLowerCase(),
            defaultView: 'agendaWeek',
            minTime: workingHours.start, // '08:00:00',
            maxTime: workingHours.end, // '19:00:00',
            height: this.window.innerHeight - 80,
            slotDuration: '00:15:00',
            slotLabelFormat: 'HH:mm',
            allDaySlot: true,
            slotEventOverlap: false,
            handleWindowResize: true,
            windowResizeDelay: 100,
            displayEventEnd: true,
            unselectAuto: true,
            nowIndicator: true,
            selectable: true,
            droppable: true,
            weekNumbers: true,
            weekNumbersWithinDays: true,
            eventDurationEditable: false,
            events: (start, end, timezone, callback) => {
                this.initialized = true;

                if (this.gotoDate) {
                    const date = this.gotoDate;
                    this.gotoDate = null;

                    $(this.calendarElement.nativeElement).fullCalendar('gotoDate', date);

                    return;
                }

                this.loading = true;
                this.httpRest
                    ._request<IMilestone[]>(
                        HTTP_METHOD.GET,
                        ApiRoutePlurality.PLURAL,
                        MILESTONE_SCHEMA_ROUTE,
                        `calendar/${[start, end].map((date) => date.format()).join('/')}`
                    )
                    .subscribe((milestones) => {
                        this.events = milestones;
                        this.calendarEvents = milestones.map((ms) => {
                            const options = this.fillEvent(ms);
                            $(this.calendarElement.nativeElement).fullCalendar('renderEvent', options, false);
                            callback(this.events);

                            if (this.gotoMilestoneId && ms.id === this.gotoMilestoneId) {
                                this.gotoMilestoneId = null;
                                this.editMilestone(options);
                            }

                            return options;
                        });

                        this.loading = false;
                    });
            },
            themeSystem: 'bootstrap4',
            themeButtonIcons: {
                prev: 'circle-triangle-w',
                next: 'circle-triangle-e',
                prevYear: 'seek-prev',
                nextYear: 'seek-next',
            },
            eventClick: (calEvent, jsEvent, view) => {
                this.editMilestone(calEvent);
            },
            // tslint:disable-next-line: cyclomatic-complexity
            eventRender: (event, element, view) => {
                element.addClass(`milestone-${event.type.toLowerCase()}`);
                if (event.icon) {
                    element.find('.fc-title').prepend(`<clr-icon shape="${event.icon}"></clr-icon> `);
                }
                if (event.hidden) {
                    element.addClass('fc-event-transparent');
                }
                const ntoday = new Date().getTime();
                const eventEnd = this.momentService.moment(event.end).valueOf();
                const eventStart = this.momentService.moment(event.start).valueOf();
                if (!event.end) {
                    if (eventStart < ntoday) {
                        element.addClass('past-event');
                        element.children().addClass('past-event');
                    }
                } else {
                    if (eventEnd < ntoday) {
                        element.addClass('past-event');
                        element.children().addClass('past-event');
                    }
                }

                return (
                    (!this.projectFilter || event.projectId === this.projectFilter) &&
                    (event.type === this.MilestonesType.REMINDER
                        ? event.employeeId === this.authService.user.employee.id
                        : true) &&
                    (!this.clientFilter ||
                        event.clientId === this.clientFilter ||
                        (event.projectId && this.getProject(event.projectId).clientId === this.clientFilter)) &&
                    (!this.typeFilter || event.type === this.typeFilter) &&
                    (this.employeeFilter
                        ? (event.participants.findIndex(
                              (e: IMeetingParticipant) => e.employeeId === this.employeeFilter
                          ) > -1 &&
                              (event.type === MilestonesType.MEETING ||
                                  event.type === MilestonesType.MEETING_NO_NOTE)) ||
                          (event.employeeId === this.employeeFilter &&
                              event.type !== MilestonesType.MEETING &&
                              event.type !== MilestonesType.MEETING_NO_NOTE)
                        : true)
                );
            },
            select: (start, end, event, calendar) => {
                if (!this.submitSubscriber) {
                    this.submitSubscriber = this.formsAddMilestoneService.onSubmit.subscribe(() => {
                        $(this.calendarElement.nativeElement).fullCalendar('refetchEvents');
                        this.submitSubscriber.unsubscribe();
                        delete this.submitSubscriber;
                    });
                }
                const _workingHours = getWorkingHours(this.configService.config);
                const configStartTime = _workingHours.start.split(':').map((e) => parseInt(e, DECIMAL_RADIX));
                const configEndTime = _workingHours.end.split(':').map((e) => parseInt(e, DECIMAL_RADIX));
                if (start.hours() === 0) {
                    start.hours(configStartTime[0]);
                    start.minutes(configEndTime[1]);
                }
                if (end.hours() === 0) {
                    end.subtract(1, 'day');
                    end.hours(configStartTime[0]);
                    end.minutes(configEndTime[1]);
                }

                this.shortcutHandlerService.execute(SHORTCUT_CREATE.MILESTONE, {start, end});
            },
            windowResize: () => {
                $(this.calendarElement.nativeElement).fullCalendar('option', 'height', this.window.innerHeight - 80);
            },
        });
    }

    public fillEvent(ms: Partial<IMilestone>) {
        return {
            id: ms.id,
            author: ms.employee,
            title: ms.obs,
            description: ms.description,
            type: ms.type,
            targetType: ms.client ? MilestoneTarget.CLIENT : MilestoneTarget.PROJECT,
            clientId: ms.client ? ms.client.id : -1,
            projectId: ms.project ? ms.project.id : -1,
            employeeId: ms.employeeId,
            version: ms.version,
            beginDate: ms.beginDate,
            endDate: ms.endDate,
            target: ms.target,
            name: this.getEventName(ms),
            participants: ms.participants,
            ...this.getCalendarDates(ms),
            hidden: !!(!ms.project && !ms.client && ms.employeeId !== this.authService.user.employee.id),
        };
    }

    public editMilestone(calEvent: any) {
        this.selectedEvent = calEvent;
        this.editTask = {
            id: this.selectedEvent.id,
            client: this.selectedEvent.clientId,
            project: this.selectedEvent.projectId,
            milestone: this.selectedEvent.title,
            description: this.selectedEvent.description,
            startDate: this.momentService.moment(this.selectedEvent.beginDate).format(ISO_DATE_FORMAT),
            startTime: this.momentService
                .moment(this.selectedEvent.beginDate)
                .format(CalendarComponent.HOUR_MINUTE_TIME_FORMAT),
            endDate: this.momentService.moment(this.selectedEvent.endDate).format(ISO_DATE_FORMAT),
            endTime: this.momentService
                .moment(this.selectedEvent.endDate)
                .format(CalendarComponent.HOUR_MINUTE_TIME_FORMAT),
            version: this.selectedEvent.version,
            target: this.momentService.moment(this.selectedEvent.target).format(ISO_DATE_FORMAT),
            targetType: this.selectedEvent.targetType,
            type: this.selectedEvent.type,
            author: this.selectedEvent.author.id,
            participants: this.selectedEvent.participants,
        };
        this.showCalendarModal = true;
    }

    public async saveMilestone(milestone: IMilestoneFormValues) {
        const participants = milestone.participants ? [...milestone.participants] : [];
        const delParticipants = this.selectedEvent.participants
            .filter((f: IMeetingParticipant) => !participants.map((p) => p.employeeId).includes(f.employeeId))
            .map((m: IMeetingParticipant) => m.employeeId);

        const addedParticipants = participants
            .filter(
                (f: IMeetingParticipant) =>
                    !this.selectedEvent.participants
                        .map((p: IMeetingParticipant) => p.employeeId)
                        .includes(f.employeeId)
            )
            .map((m) => m.employee.id);

        this.submitSubscriber = this.formsAddMilestoneService.onSubmit.subscribe(() => {
            this.showCalendarModal = false;
            $(this.calendarElement.nativeElement).fullCalendar('refetchEvents');
            this.submitSubscriber.unsubscribe();
            delete this.submitSubscriber;
        });

        this.formsAddMilestoneService.edit(
            {...milestone, id: this.selectedEvent.id, type: this.selectedEvent.type},
            addedParticipants,
            delParticipants
        );

        this.showCalendarModal = false;
    }

    public deleteMilestone() {
        // $(this.calendarElement.nativeElement).fullCalendar('refetchEvents');
        $(this.calendarElement.nativeElement).fullCalendar('prev');
        $(this.calendarElement.nativeElement).fullCalendar('next');
    }

    public getClient(id: number) {
        return this.clients.find((cl) => cl.id === id);
    }

    public getProject(id: number) {
        return this.projects.find((p) => p.id === id);
    }

    public getProjectName(id: number) {
        if (!id) {
            return undefined;
        }

        return this.projects.find((p) => p.id === id);
    }

    public getCalendarDates(ms: Partial<IMilestone>) {
        const options: any = {};
        switch (ms.type) {
            case MilestonesType.DEADLINE:
                options.start = this.momentService.moment(ms.beginDate);
                options.allDay = true;
                options.icon = 'step-forward-2';
                break;
            case MilestonesType.MEETING:
            case MilestonesType.MEETING_NO_NOTE:
                options.start = this.momentService.moment(ms.beginDate);
                options.end = this.momentService.moment(ms.endDate);
                options.icon = 'users';
                break;
            case MilestonesType.CALL:
                options.start = this.momentService.moment(ms.beginDate);
                options.end = this.momentService.moment(ms.beginDate).add(15, 'minutes');
                options.icon = 'phone-handset';
                break;
            case MilestonesType.REMINDER:
                options.start = this.momentService.moment(ms.beginDate);
                options.end = this.momentService.moment(ms.beginDate).add(15, 'minutes');
                options.icon = 'bell';
                break;
            case MilestonesType.RELEASE:
                options.start = ms.endDate
                    ? this.momentService.moment(ms.endDate)
                    : this.momentService.moment(ms.target);
                options.allDay = true;
                options.icon = 'checkbox-list';
                break;
            default:
                break;
        }

        return options;
    }

    public getEventName(ms: Partial<IMilestone>) {
        if (!ms.client && !ms.project) {
            return '';
        }

        if (ms.clientId) {
            return ms.client && `${ms.client.user.name} (${ms.client.user.code})`;
        } else {
            return `${ms.project.obs} (${ms.project.code}) - ${this.getClient(ms.project.clientId).user.name} (${
                this.getClient(ms.project.clientId).user.code
            })`;
        }
    }

    public filterEmployee(filterBy?: string) {
        if (!filterBy) {
            this.employeeFilter = null;
        } else {
            this.employeeFilter = parseInt(filterBy, 10);
        }
        $(this.calendarElement.nativeElement).fullCalendar('rerenderEvents');
    }

    public filterProject(filterBy?: string) {
        if (!filterBy) {
            this.projectFilter = null;
            this.filteredEmployees = this.employees;
        } else {
            this.projectFilter = parseInt(filterBy, 10);
            this.filteredEmployees = this.employees.filter((f) => {
                return this.filteredProjects
                    .find((s) => s.id === this.projectFilter)
                    .projectMembers.map((m) => m.employeeId)
                    .includes(f.id);
            });

            // if select another project and the selected employee doesn't fit in it
            if (!this.filteredEmployees.map((m) => m.id).includes(this.employeeFilter)) {
                this.employeeFilter = null;
                this.employeeModel = null;
            }
        }
        $(this.calendarElement.nativeElement).fullCalendar('rerenderEvents');
    }

    public filterClient(filterBy?: string) {
        if (!filterBy) {
            this.clientFilter = null;
            this.filteredProjects = this.projects;
        } else {
            this.clientFilter = parseInt(filterBy, 10);
            this.filteredProjects = this.projects.filter((f) => f.clientId === this.clientFilter);

            // if select another client and the selected project doesn't fit in it
            if (!this.filteredProjects.map((m) => m.id).includes(this.projectFilter)) {
                this.projectFilter = null;
                this.projectModel = null;
                this.filteredEmployees = this.employees;
            }
        }
        $(this.calendarElement.nativeElement).fullCalendar('rerenderEvents');
    }

    public filterType(filterBy?: string) {
        if (!filterBy) {
            this.typeFilter = null;
        } else {
            this.typeFilter = filterBy;
        }
        $(this.calendarElement.nativeElement).fullCalendar('rerenderEvents');
    }

    public readonly msTypes = Object.keys(MilestonesType);
    public readonly MILESTONE_TYPE_ICON = MILESTONE_TYPE_ICON;
    public readonly MilestonesType = MilestonesType;
    private static readonly HOUR_MINUTE_TIME_FORMAT = 'HH:mm';
    public readonly isColorDark = isColorDark;
    public readonly RIGHTS = RIGHTS;

    public readonly MILESTONE_FORM_KEYS = MILESTONE_FORM_KEYS;
    public readonly validate = validate;
}
