import {Directive, ElementRef, HostListener, Input, OnChanges, SimpleChanges} from '@angular/core';
import {IShortcutHandler, SHORTCUT_NAME, ShortcutHandlerService} from '../shortcut-handler/shortcut-handler.service';

@Directive({
    selector: '[sharedEventBinder]',
})
export class EventBinderDirective implements OnChanges {
    @Input() public ebShortcut: SHORTCUT_NAME;
    @Input() public ebEventType: string;

    private handler: IShortcutHandler;

    public constructor(
        private readonly element: ElementRef,
        private readonly shortcutHandlerService: ShortcutHandlerService
    ) {}

    public ngOnChanges(changes: SimpleChanges) {
        if (changes && changes.ebShortcut) {
            if (this.isFocused) {
                this.registerHandler();
            } else {
                this.unregisterHandler();
            }
        }
    }

    @HostListener('focus', ['$event'])
    public registerHandler() {
        if (this.handler) {
            if (this.handler.shortcut === this.ebShortcut) {
                return;
            }

            this.unregisterHandler();
        }

        this.handler = this.shortcutHandlerService.register({
            shortcut: this.ebShortcut,
            callback: () => this.triggerEvent(),
            forceListen: true,
        });
    }

    @HostListener('blur', ['$event'])
    public unregisterHandler() {
        if (!this.handler) {
            return;
        }

        this.shortcutHandlerService.unregisterHandlers(this.handler);
        this.handler = undefined;
    }

    public triggerEvent() {
        if (!this.element) {
            return;
        }

        const $element = this.element.nativeElement as HTMLElement;
        let $srcElement: HTMLElement;
        switch (this.ebEventType) {
            case 'submit':
                $srcElement = $element.closest('form') as HTMLElement;
                break;
            default:
                $srcElement = $element;
                break;
        }

        if (!$srcElement) {
            return;
        }

        $srcElement.dispatchEvent(
            new Event(this.ebEventType, {
                bubbles: true,
                cancelable: true,
            })
        );
    }

    @Input()
    public set sharedEventBinder(args: [SHORTCUT_NAME, string]) {
        if (args.length !== 2) {
            throw new Error(`Expected 2 arguments, got ${args} [ebShortcut, ebEventType]`);
        }

        [this.ebShortcut, this.ebEventType] = args;
        if (this.isFocused) {
            this.registerHandler();
        }
    }

    public get isFocused(): boolean {
        return (
            this.element &&
            document.activeElement &&
            (this.element.nativeElement as HTMLElement) === document.activeElement
        );
    }
}
