import { ApplicationRef, ComponentFactoryResolver, ComponentRef, EmbeddedViewRef, Injector, Type, } from '@angular/core';
import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var ModalSimpleService = (function () {
    function ModalSimpleService(componentFactoryResolver, appRef, injector) {
        this.componentFactoryResolver = componentFactoryResolver;
        this.appRef = appRef;
        this.injector = injector;
    }
    ModalSimpleService.prototype.open = function (component, modalOptions) {
        this.removeModal();
        var factory = this.componentFactoryResolver.resolveComponentFactory(component);
        this.componentRef = factory.create(this.injector);
        this.appRef.attachView(this.componentRef.hostView);
        this.componentRef.instance.onInjectInputs(modalOptions);
        var domElem = this.componentRef.hostView.rootNodes[0];
        document.body.appendChild(domElem);
        return this.onResult();
    };
    ModalSimpleService.prototype.removeModal = function () {
        if (this.componentRef) {
            this.appRef.detachView(this.componentRef.hostView);
            this.componentRef.destroy();
        }
    };
    ModalSimpleService.prototype.onResult = function () {
        this.result$ = new Subject();
        return this.result$.asObservable();
    };
    ModalSimpleService.prototype.close = function () {
        this.result$.next({ result: false });
        this.result$.complete();
        this.removeModal();
    };
    ModalSimpleService.prototype.dismiss = function () {
        this.result$.error('dismissed');
        this.removeModal();
    };
    ModalSimpleService.prototype.validate = function () {
        this.result$.next({ result: true });
        this.result$.complete();
        this.removeModal();
    };
    ModalSimpleService.ngInjectableDef = i0.defineInjectable({ factory: function ModalSimpleService_Factory() { return new ModalSimpleService(i0.inject(i0.ComponentFactoryResolver), i0.inject(i0.ApplicationRef), i0.inject(i0.INJECTOR)); }, token: ModalSimpleService, providedIn: "root" });
    return ModalSimpleService;
}());
export { ModalSimpleService };
