<div class="spinner-bg-c-small" *ngIf="!card.subtitle"><div class="spinner-c"></div></div>
<div class="fade-transition number {{ card.color }}" *ngIf="card.subtitle">
    <div class="media">
        <div
            class="media-body
                text-{{ card.textAlign !== 'right' ? 'left' : 'right' }}"
        >
            <h5 class="{{ card.size }}" translate>{{ card.title }}</h5>
            <span translate>{{ card.subtitle }}</span>
        </div>
        <div class="media-icon" *ngIf="card.icon">
            <i class="fas fa-{{ card.icon }} float-{{ card.textAlign !== 'right' ? 'right' : 'left' }}"></i>
        </div>
        <div class="media-progression" *ngIf="card.progress">
            <div class="progress">
                <div
                    role="progressbar"
                    class="progress-bar {{ card.color }}"
                    [ngStyle]="{width: card.progress + '%'}"
                ></div>
            </div>
        </div>
    </div>
</div>
