<li
    class="Sidebar-navItem"
    (click)="showMenuModal = true"
    title="{{ 'route_quickaction' | translate }} ({{ 'key_shift' | translate }} + C)"
>
    <a>
        <clr-icon shape="bolt" class="Sidebar-menuIcon"></clr-icon>
        <span translate *ngIf="!isCollapsed">route_quickaction</span>
    </a>
</li>

<app-modal [(show)]="showMenuModal" (shown)="registerShortcuts()" (hidden)="unregisterShortcuts()">
    <ng-template appModalHeader>
        {{ 'route_quickaction' | translate }} <kbd><span translate>key_shift</span> + C</kbd>
    </ng-template>
    <ng-template appModalBody>
        <div class="clr-row">
            <ng-container *ngFor="let action of objectValues(actions)">
                <ng-container *sharedAccessControl="action.requiredRight">
                    <div
                        class="column clr-col-3 clr-col-mx-auto text-center c-hand qa-item"
                        *ngIf="!action.hide || !action.hide(action)"
                        (click)="showAction(action)"
                        style="padding: 10px;"
                    >
                        <div>
                            <a [ngClass]="action.class"> <clr-icon [attr.shape]="action.icon" size="40"></clr-icon> </a>
                        </div>
                        <div [ngClass]="action.class" translate>{{ action.label }}</div>
                        <kbd
                            *ngIf="action.quickShortcut && SHORTCUT_COMOBO[action.quickShortcut]"
                            title="Quick shortcut"
                        >
                            {{ SHORTCUT_COMOBO[action.quickShortcut] }}
                        </kbd>
                        <kbd *ngIf="action.shortcut && SHORTCUT_COMOBO[action.shortcut]" title="Global shortcut">
                            {{ SHORTCUT_COMOBO[action.shortcut] }}
                        </kbd>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </ng-template>
</app-modal>

<!--
    <app-modal
        [(show)]="authService.hasRight(RIGHTS.TASK_CREATE) && actions[IActionName.TASK].showModal"
        [title]="'add_task' | translate"
        (shown)="openModal(IActionName.TASK)"
    >
-->
<app-modal
    *ngIf="actions[IActionName.TASK].showModal"
    [(show)]="authService.hasRight(RIGHTS.TASK_CREATE) && actions[IActionName.TASK].showModal"
    [appModalSize]="'lg'"
    [title]="'add_task' | translate"
    (shown)="loadAddTask()"
    (hidden)="addTaskComponent.alreadyInit = false; resetParams(IActionName.TASK)"
>
    <ng-template appModalBody>
        <app-forms-add-task [(show)]="actions[IActionName.TASK].showModal" [noParent]="true"></app-forms-add-task>
    </ng-template>
    <ng-template appModalFooter>
        <button
            type="button"
            class="btn btn-secondary btn-footer-left"
            (click)="showMultiple = true; closeModal(IActionName.TASK)"
            translate
        >
            multiple_add
        </button>
        <button
            *ngIf="addTaskComponent"
            (click)="closeModal(IActionName.TASK)"
            class="btn btn-primary-outline"
            translate
        >
            cancel
        </button>
        <button
            *ngIf="addTaskComponent"
            [disabled]="!validate(addTaskComponent.form, false) || !addTaskComponent.checkDates()"
            (click)="addTask()"
            class="btn btn-success"
            translate
        >
            add_task
        </button>
    </ng-template>
</app-modal>

<app-add-multiple-task [(show)]="showMultiple" [addTaskComponent]="addTaskComponent"> </app-add-multiple-task>

<app-milestone-wizard
    *ngIf="actions[IActionName.MILESTONE].showModal"
    [(clrWizardOpen)]="actions[IActionName.MILESTONE].showModal"
    (finish)="addNewMilestone($event)"
    [params]="actions[IActionName.MILESTONE].params"
    (clrWizardOpenChange)="openModal(IActionName.MILESTONE)"
    (cancel)="cancelMilestoneCreation()"
></app-milestone-wizard>

<app-time-wizard
    [(clrWizardOpen)]="actions[IActionName.TIME].showModal"
    *ngIf="actions[IActionName.TIME].showModal"
    (finish)="addTime($event)"
    [params]="actions[IActionName.TIME].params"
    (clrWizardOpenChange)="openModal(IActionName.MILESTONE)"
></app-time-wizard>

<app-modal [(show)]="actions[IActionName.COMMAND].showModal" title="Run a command">
    <ng-template appModalBody>
        <form class="column clr-col-12 form form-horizontal" (ngSubmit)="run(command)" autocomplete="off">
            <div class="form-group">
                <div class="column clr-col-8"><input class="input-lg" name="command" [(ngModel)]="command" /></div>
                <div class="column clr-col-4">
                    <button class="btn btn-success float-right" style="width: 100%">
                        <i class="fas fa-terminal"></i> Run
                    </button>
                </div>
            </div>
        </form>
    </ng-template>
</app-modal>

<app-global-search [(show)]="actions[IActionName.SEARCH].showModal"></app-global-search>
