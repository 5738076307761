<clr-icon
    shape="users"
    *ngIf="
        ganttms.milestone.type === MilestonesType.MEETING || ganttms.milestone.type === MilestonesType.MEETING_NO_NOTE
    "
    class="gantt-ms is-solid popover"
    [class.popover-bottom]="popBottom"
    [class.popover-top]="!popBottom"
>
    <app-gantt-popover [milestone]="ganttms"></app-gantt-popover
></clr-icon>

<clr-icon
    shape="calendar"
    *ngIf="ganttms.milestone.type === MilestonesType.DEADLINE"
    class="gantt-ms is-solid popover"
    [class.popover-bottom]="popBottom"
    [class.popover-top]="!popBottom"
>
    <app-gantt-popover [milestone]="ganttms"></app-gantt-popover
></clr-icon>

<clr-icon
    shape="clock"
    *ngIf="ganttms.milestone.type === MilestonesType.REMINDER"
    class="gantt-ms is-solid popover"
    [class.popover-bottom]="popBottom"
    [class.popover-top]="!popBottom"
>
    <app-gantt-popover [milestone]="ganttms"></app-gantt-popover
></clr-icon>

<clr-icon
    shape="phone-handset"
    *ngIf="ganttms.milestone.type === MilestonesType.CALL"
    class="gantt-ms is-solid popover"
    [class.popover-bottom]="popBottom"
    [class.popover-top]="!popBottom"
>
    <app-gantt-popover [milestone]="ganttms"></app-gantt-popover
></clr-icon>
