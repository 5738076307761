<button class="btn btn-sm pos-a" style="top: 5px;" (click)="resetFilter()"><clr-icon shape="eraser"></clr-icon></button>

<div [style.max-width]="dateType === DATAGRID_FILTER_DATE_TYPE.DATE ? '10rem' : '17rem'">
    <div class="clr-row">
        <div class="clr-col-12"><label translate for="dateStart-{{ idIncrement }}">date_filter_after</label></div>
        <div
            [class.clr-col-12]="dateType === DATAGRID_FILTER_DATE_TYPE.DATE"
            [class.clr-col-8]="dateType === DATAGRID_FILTER_DATE_TYPE.DATE_TIME"
        >
            <clr-date-container class="clr-row" (click)="$event.stopPropagation()">
                <input
                    class="clr-col-12"
                    type="date"
                    name="dateStart"
                    id="dateStart-{{ idIncrement }}"
                    [(clrDate)]="filterDateStart"
                    (clrDateChange)="updateFilter()"
                />
            </clr-date-container>
        </div>
        <div class="clr-col-4 mt-02" *ngIf="dateType === DATAGRID_FILTER_DATE_TYPE.DATE_TIME">
            <input type="time" [(ngModel)]="filterTimeStart" (change)="updateFilter()" />
        </div>
    </div>

    <div class="clr-row" *ngIf="rangeType === DATAGRID_FILTER_DATE_RANGE_TYPE.INTERVAL">
        <div class="clr-col-12"><label translate for="dateEnd-{{ idIncrement }}">date_filter_before</label></div>
        <div
            [class.clr-col-12]="dateType === DATAGRID_FILTER_DATE_TYPE.DATE"
            [class.clr-col-8]="dateType === DATAGRID_FILTER_DATE_TYPE.DATE_TIME"
        >
            <clr-date-container class="clr-row" (click)="$event.stopPropagation()">
                <input
                    class="clr-col-12"
                    type="date"
                    name="dateEnd"
                    id="dateEnd-{{ idIncrement }}"
                    [(clrDate)]="filterDateEnd"
                    (clrDateChange)="updateFilter()"
                />
            </clr-date-container>
        </div>
        <div class="clr-col-4 mt-02" *ngIf="dateType === DATAGRID_FILTER_DATE_TYPE.DATE_TIME">
            <input type="time" [(ngModel)]="filterTimeEnd" (change)="updateFilter()" />
        </div>
    </div>
</div>
