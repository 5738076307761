<div class="card">
    <div class="card-block overlay-dark-bg" [ngStyle]="{'background-color': authService.user?.color}">
        <div class="text-center">
            <h1
                class="font-size-65 text-light"
                [ngClass]="{
                    'text-dark': isColorDark(authService.user?.color),
                    'text-light': !isColorDark(authService.user?.color)
                }"
            >
                {{ today | momentFormat: 'Do' }}
            </h1>
            <h2
                class="mt-04"
                [ngClass]="{
                    'text-dark': isColorDark(authService.user?.color),
                    'text-light': !isColorDark(authService.user?.color)
                }"
            >
                {{ today | momentFormat: 'MMMM' }}
            </h2>
            <h4
                class="card-title"
                translate
                [ngClass]="{
                    'text-dark': isColorDark(authService.user?.color),
                    'text-light': !isColorDark(authService.user?.color)
                }"
            >
                calendar_events
            </h4>
        </div>
    </div>
    <ul class="list-group list-group-flush">
        <div class="spinner-bg-c-small" style="height: 4em;" *ngIf="!display"><div class="spinner-c"></div></div>
        <div style="margin-top: 1rem;margin-left: 1rem;" *ngIf="display && (!events || events.length === 0)" translate>
            no_data
        </div>
        <div *ngIf="display">
            <li class="list-group-item" *ngFor="let event of events; trackBy: trackByIndex">
                <app-long-avatar [code]="event.avatar" [bgColor]="event.color"></app-long-avatar>
                <span [ngClass]="{'text-grayer': isPastEvent(event)}" class="event-name"> {{ event.title }}</span>
                <div class="event-subtitle" [ngClass]="{'text-grayer': isPastEvent(event)}">
                    <span>{{ event.subtitle }}</span>
                </div>
                <div class="event-detail" [ngClass]="{'text-grayer': isPastEvent(event)}">
                    <i
                        >{{ event.begin | momentFormat: 'L LT' }}
                        {{ event.end ? ' - ' + (event.end | momentFormat: 'L LT') : '' }}</i
                    >
                </div>
            </li>
        </div>
    </ul>
</div>
