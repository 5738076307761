import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Validators} from '@angular/forms';
import {ClrForm} from '@clr/angular';
import {NgSelectComponent} from '@ng-select/ng-select';
import {FormControl, FormGroup} from 'ngx-strongly-typed-forms';
import {concat, Observable, of, Subject} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, switchMap, tap} from 'rxjs/operators';
import {ApiRoutePlurality, HTTP_METHOD} from '../../../../../defs/schema-static';
import {IProject, PROJECT_SCHEMA_ROUTE} from '../../../../../defs/schema/public/Projects';
import {ITask, TASK_SCHEMA_ROUTE} from '../../../../../defs/schema/public/Tasks';
import {IAddTimeFormModal, TaskTarget} from '../../forms/add-time.service';
import {HttpRestService} from '../../shared/http-rest/http-rest.service';

interface ITaskExtendDisplay extends ITask {
    displayName?: string;
}

@Component({
    selector: 'app-time-existing',
    templateUrl: './time-existing.component.html',
    styleUrls: ['./time-existing.component.scss'],
})
export class TimeExistingComponent implements OnInit {
    public readonly form = new FormGroup<Partial<IAddTimeFormModal>>({
        assignedTask: new FormControl<number>(null, Validators.required),
        project: new FormControl<number>(null, Validators.required),
        taskId: new FormControl<number>(null, Validators.required),
    });

    @ViewChild(ClrForm) public clrForm: ClrForm;
    @ViewChild('ngSelectTaskProgress') public ngSelectTaskProgress: NgSelectComponent;
    @ViewChild('ngSelectProject') public ngSelectProject: NgSelectComponent;
    @ViewChild('ngSelectTask') public ngSelectTask: NgSelectComponent;

    @Input() public tasksProgress: Partial<ITaskExtendDisplay>[] = [];
    @Input() public projects: Partial<IProject>[] = [];

    @Output() public projectChange = new EventEmitter<Partial<IProject>>();

    public taskTarget: TaskTarget;

    public tasks: Observable<ITask[]>;
    public tasksLoading = false;
    public tasksTH$ = new Subject<string>();

    public constructor(private readonly httpRest: HttpRestService) {}

    public ngOnInit() {
        if (!this.tasksProgress || !this.tasksProgress.length || !this.projects || !this.projects.length) {
            (async () => this.getDatas())();
        }
    }

    public getCodeProject(id: number) {
        if (!this.projects) {
            return '';
        }

        return this.projects.find((f) => f.id === id).code;
    }

    public async getDatas(): Promise<void> {
        this.httpRest
            ._request<IProject[]>(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, PROJECT_SCHEMA_ROUTE, 'withTasks')
            .subscribe((projects) => {
                this.projects = projects.sort((a, b) => a.obs.localeCompare(b.obs));
                if (projects.length > 0) {
                    this.httpRest
                        ._request<ITask[]>(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, TASK_SCHEMA_ROUTE, 'inProgress')
                        .subscribe((tasks) => {
                            this.tasksProgress = tasks.map((t) => {
                                return {...t, displayName: `${this.getCodeProject(t.projectId)}-${t.code} : ${t.name}`};
                            });
                        });
                    this.loadTasks();
                }
            });
    }

    public onLoad(params: IAddTimeFormModal) {
        if (params) {
            if (params.taskTarget) {
                this.taskTarget = params.taskTarget;
            }
            // this.projectOnly = params.type === MilestonesType.DEADLINE || params.type === MilestonesType.RELEASE;

            // if (!this.form.value.targetType && params.targetType) {
            //     // loading first time
            //     this.form.patchValue({
            //         targetType: params.targetType,
            //         project: params.project,
            //         client: params.client,
            //     });
            // }

            // if (this.projectOnly) {
            //     this.form.patchValue({
            //         targetType: MilestoneTarget.PROJECT,
            //     });
            //     if (this.form.value.client) {
            //         this.form.patchValue({
            //             client: null,
            //         });
            //     }
            // }
            this.toggle();
        }
    }

    public filterProject(projectId?: number) {
        this.form.patchValue({
            project: projectId || null,
            taskId: null,
            assignedTask: null,
        });

        this.projectChange.emit((this.projects || []).find(({id}) => id === projectId));

        this.loadTasks();

        this.setFocus();
    }

    public filterTask(id?: number) {
        this.form.patchValue({
            taskId: id || null,
        });
    }

    public filterTaskProgress(taskId?: number) {
        this.form.patchValue({
            taskId: null,
            project: null,
            assignedTask: taskId || null,
        });

        const task = this.tasksProgress.find(({id}) => id === taskId);

        if (!task) {
            this.projectChange.emit(undefined);

            return;
        }

        this.projectChange.emit((this.projects || []).find(({id}) => id === task.projectId));
    }

    public submit() {
        if (!this.form.valid) {
            return undefined;
        }

        return this.form;
    }

    private toggle() {
        if (this.taskTarget === TaskTarget.ASSIGNED) {
            this.form.controls.assignedTask.enable();
            this.form.controls.project.disable();
            this.form.controls.taskId.disable();
        } else {
            this.form.controls.assignedTask.disable();
            this.form.controls.project.enable();
            this.form.controls.taskId.enable();
        }
        this.setFocus();
    }

    private setFocus() {
        requestAnimationFrame(() => {
            if (this.taskTarget === TaskTarget.ASSIGNED) {
                if (this.ngSelectTaskProgress && !this.form.value.assignedTask) {
                    this.ngSelectTaskProgress.focus();
                    this.ngSelectTaskProgress.open();
                }
            } else if (this.taskTarget === TaskTarget.EXISTING && this.form.value.project && !this.form.value.taskId) {
                this.ngSelectTask.focus();
                this.ngSelectTask.open();
            } else if (!this.form.value.project) {
                if (this.ngSelectProject) {
                    this.ngSelectProject.focus();
                    this.ngSelectProject.open();
                }
            }
        });
    }

    public getTasks(term: string) {
        return this.httpRest._request<ITask[]>(
            HTTP_METHOD.POST,
            ApiRoutePlurality.PLURAL,
            TASK_SCHEMA_ROUTE,
            'searchTasks',
            {
                search: term,
                projectId: this.form.value.project,
            }
        );
    }

    private loadTasks() {
        this.tasks = concat(
            of([]), // default items
            this.tasksTH$.pipe(
                debounceTime(200),
                distinctUntilChanged(),
                tap(() => (this.tasksLoading = true)),
                switchMap((term) => {
                    if (!term || term === '') {
                        this.tasksLoading = false;

                        return of([]);
                    }

                    return this.getTasks(term).pipe(
                        catchError(() => of([])), // empty list on error
                        tap(() => (this.tasksLoading = false))
                    );
                })
            )
        );
    }

    public readonly TASK_TARGET = TaskTarget;
}
