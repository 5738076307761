var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { EventEmitter, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ClrDatagridSortOrder } from '@clr/angular';
import { TranslateService } from '@ngx-translate/core';
import { ProjectMemberType, RIGHTS } from '../../../../../defs/schema-static';
import { CLIENT_FIELD } from '../../../../../defs/schema/public/Clients';
import { PROJECT_FIELD, PROJECT_SCHEMA_ROUTE } from '../../../../../defs/schema/public/Projects';
import { USER_FIELD } from '../../../../../defs/schema/public/Users';
import { CLIENT_SORT_FUNCTION, PROJECT_MEMBER_SORT_FUNCTION } from '../../../../../defs/sorters';
import { DATAGRID_FILTER_TYPE, PROJECT_STATUS_FILTER, SelectFilterType, sortFnArrayWrapper, } from '../../app-static';
import { AuthService } from '../../auth/auth.service';
import { HttpRestService } from '../../shared/http-rest/http-rest.service';
import { LONG_AVATAR_TYPE } from '../../shared/long-avatar/long-avatar.component';
import { SHORTCUT_CREATE, SHORTCUT_LOCAL, ShortcutHandlerService, } from '../../shared/shortcut-handler/shortcut-handler.service';
import { TOAST_TYPE, ToastService } from '../../shared/toast/toast.service';
export var PROJECT_LIST_LAYOUT;
(function (PROJECT_LIST_LAYOUT) {
    PROJECT_LIST_LAYOUT["ADD_DELETE"] = "ADD_DELETE";
    PROJECT_LIST_LAYOUT["SELECT"] = "SELECT";
})(PROJECT_LIST_LAYOUT || (PROJECT_LIST_LAYOUT = {}));
var ProjectsListComponent = (function () {
    function ProjectsListComponent(httpRest, shortcutHandlerService, authService, translate, toastService) {
        var _a;
        var _this = this;
        this.httpRest = httpRest;
        this.shortcutHandlerService = shortcutHandlerService;
        this.authService = authService;
        this.translate = translate;
        this.toastService = toastService;
        this.columns = ProjectsListComponent.DEFAULT_PROJECTS_COLUMNS;
        this.actionBarButtonClassList = 'btn-sm';
        this.addButtonClassList = '';
        this.layout = PROJECT_LIST_LAYOUT.ADD_DELETE;
        this.selectedProjects = [];
        this.selectedProjectsChange = new EventEmitter();
        this.showDeleteModal = false;
        this.clients = null;
        this.managers = null;
        this.showCreateModal = false;
        this.showCreateModalChange = new EventEmitter();
        this.clrDgRowSelection = false;
        this.PROJECT_FIELD = PROJECT_FIELD;
        this.PROJECT_STATUS_FILTER = PROJECT_STATUS_FILTER;
        this.PROJECT_ROLE_TYPE_FILTER = (_a = {},
            _a[ProjectMemberType.MEMBER] = {
                translation: 'team_member',
            },
            _a[ProjectMemberType.MANAGER] = {
                classList: 'text-warning',
                translation: 'project_manager',
            },
            _a);
        this.RIGHTS = RIGHTS;
        this.LONG_AVATAR_TYPE = LONG_AVATAR_TYPE;
        this.ProjectMemberType = ProjectMemberType;
        this.CLIENT_FIELD = CLIENT_FIELD;
        this.USER_FIELD = USER_FIELD;
        this.DATAGRID_FILTER_TYPE = DATAGRID_FILTER_TYPE;
        this.PROJECT_LIST_LAYOUT = PROJECT_LIST_LAYOUT;
        this.SelectFilterType = SelectFilterType;
        this.filterOpen = {};
        this.shortcutHandlerService.register({
            name: SHORTCUT_CREATE.PROJECT,
            shortcut: SHORTCUT_LOCAL.CREATE,
            callback: function () { return _this.showCreateModalChange.emit((_this.showCreateModal = true)); },
            context: this,
        }, true);
    }
    ProjectsListComponent.prototype.ngOnInit = function () { };
    ProjectsListComponent.prototype.ngOnChanges = function (changes) {
        if (changes && changes.projects && this.projects) {
            this.refreshClients();
            this.refreshManagers();
        }
    };
    ProjectsListComponent.prototype.refreshClients = function () {
        var _this = this;
        this.clients = [];
        this.projects.map(function (p) {
            var clientFound = _this.clients.find(function (c) { return c && c.id === p.client.id; });
            if (!clientFound) {
                _this.clients.push(p.client);
            }
        });
        this.clients.sort(CLIENT_SORT_FUNCTION);
    };
    ProjectsListComponent.prototype.refreshManagers = function () {
        var _this = this;
        this.managers = [];
        this.projects.map(function (p) {
            if (p.managers) {
                p.managers.map(function (m) {
                    var managerFound = _this.managers.find(function (c) { return m.employee.id === c.id; });
                    if (!managerFound) {
                        _this.managers.push(m.employee);
                    }
                });
            }
        });
        this.managers.sort(function (a, b) { return a.user.name.localeCompare(b.user.name); });
    };
    ProjectsListComponent.prototype.ngOnDestroy = function () { };
    ProjectsListComponent.prototype.addNewProject = function (project) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.projects.push(__assign({ budgetSum: 0 }, project, { roleType: (project.projectMembers || [])
                        .filter(function (projectMember) { return projectMember.employeeId === _this.authService.user.employee.id; })
                        .map(function (projectMember) { return projectMember.type; })[0] || undefined, managers: (project.projectMembers || []).filter(function (projectMember) { return projectMember.type === ProjectMemberType.MANAGER; }) }));
                this.toastService.show({
                    type: TOAST_TYPE.SUCCESS,
                    text: 'success_insert_project',
                });
                return [2];
            });
        });
    };
    ProjectsListComponent.prototype.deleteSelectedProjects = function () {
        return __awaiter(this, void 0, void 0, function () {
            var deletedProjectIds, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        deletedProjectIds = this.selectedProjects.map(function (_a) {
                            var id = _a.id;
                            return id;
                        });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4, this.httpRest.deleteIds(PROJECT_SCHEMA_ROUTE, deletedProjectIds).toPromise()];
                    case 2:
                        _a.sent();
                        return [3, 5];
                    case 3:
                        err_1 = _a.sent();
                        return [2];
                    case 4:
                        this.showDeleteModal = false;
                        return [7];
                    case 5:
                        this.selectedProjects = [];
                        this.projects = this.projects.filter(function (_a) {
                            var id = _a.id;
                            return !deletedProjectIds.includes(id);
                        });
                        this.toastService.show({
                            type: TOAST_TYPE.SUCCESS,
                            text: 'success_delete_project',
                        });
                        return [2];
                }
            });
        });
    };
    ProjectsListComponent.DEFAULT_PROJECTS_COLUMNS = [
        {
            field: PROJECT_FIELD.obs,
            translateKey: 'table_name',
            hideable: false,
            order: ClrDatagridSortOrder.ASC,
        },
        {
            field: PROJECT_FIELD.client + "." + CLIENT_FIELD.user + "." + USER_FIELD.name,
            translateKey: 'table_client',
            filterType: DATAGRID_FILTER_TYPE.SELECT,
        },
        { field: 'budgetSum', translateKey: 'budget' },
        { field: PROJECT_FIELD.status, translateKey: 'table_status', filterType: DATAGRID_FILTER_TYPE.CUSTOM },
        {
            field: 'managers',
            filterType: DATAGRID_FILTER_TYPE.SELECT,
            translateKey: 'project_manager',
            propertyGetter: function (_a) {
                var managers = _a.managers;
                return (managers || []).map(function (_a) {
                    var employee = _a.employee;
                    return (employee && employee.user && employee.user.name) || '';
                }).join(' ');
            },
            sorter: { compare: function (p1, p2) { return sortFnArrayWrapper(PROJECT_MEMBER_SORT_FUNCTION)(p1.managers, p2.managers); } },
        },
        { field: 'roleType', translateKey: 'my_role', filterType: DATAGRID_FILTER_TYPE.CUSTOM, hidden: true },
    ];
    return ProjectsListComponent;
}());
export { ProjectsListComponent };
