<clr-datagrid
    appDatagridToggle
    class="clr-col-12 flex-1-set"
    [(clrDgSelected)]="selectedMilestones"
    [clrDgLoading]="!milestones"
>
    <clr-dg-action-bar>
        <div class="btn-group">
            <button class="btn btn-sm" (click)="showCreateModal = true" *sharedAccessControl="RIGHTS.MS_UPDATE">
                {{ 'add_milestone' | translate }}
            </button>
        </div>

        <div class="btn-group">
            <button
                class="btn btn-sm btn-danger"
                (click)="showDeleteModal = true"
                *sharedAccessControl="RIGHTS.MS_DELETE"
                [disabled]="!selectedMilestones.length"
            >
                {{ (selectedMilestones.length > 1 ? 'delete_milestones' : 'delete_milestone') | translate }}
            </button>
        </div>
    </clr-dg-action-bar>

    <clr-dg-column
        *ngFor="let column of MILESTONES_COLUMNS"
        [clrDgField]="column.field"
        [clrDgSortOrder]="column.order"
    >
        <ng-container *ngIf="column.hideable === false; else hideableColumn">
            {{ column.translateKey | translate }}
        </ng-container>
        <ng-template #hideableColumn>
            <ng-container *clrDgHideableColumn="{hidden: column.hidden === true}">
                {{ column.translateKey | translate }}
            </ng-container>
        </ng-template>

        <clr-dg-filter
            *ngIf="!column.filterType || column.filterType === DATAGRID_FILTER_TYPE.FUZZY"
            [clrDgFilter]="fuzzyFilter"
            [(clrDgFilterOpen)]="filterOpen[column.field]"
        >
            <shared-fuzzy-datagrid-filter
                [filterKey]="column.field"
                [filterOpen]="filterOpen[column.field]"
                #fuzzyFilter
            ></shared-fuzzy-datagrid-filter>
        </clr-dg-filter>

        <clr-dg-filter
            *ngIf="column.filterType === DATAGRID_FILTER_TYPE.DATE"
            [clrDgFilter]="dateFilter"
            [(clrDgFilterOpen)]="filterOpen[column.field]"
        >
            <shared-date-datagrid-filter
                [filterKey]="column.field"
                [filterOpen]="filterOpen[column.field]"
                #dateFilter
            ></shared-date-datagrid-filter>
        </clr-dg-filter>

        <clr-dg-filter *ngIf="column.field === MILESTONE_FIELD.type" [clrDgFilter]="typeFilter">
            <shared-generic-datagrid-filter
                [filter]="MILESTONE_TYPE_FILTER"
                [filterKey]="column.field"
                [unfilteredEntities]="displayMilestones"
                [state]="MILESTONE_TYPE_FILTER_STATE_WITHOUT_RELEASES"
                #typeFilter
            ></shared-generic-datagrid-filter>
        </clr-dg-filter>
    </clr-dg-column>

    <clr-dg-row *clrDgItems="let milestone of displayMilestones" [clrDgItem]="milestone">
        <clr-dg-cell>
            <ng-container *sharedAccessControl="RIGHTS.MS_UPDATE; else obsWithoutLink">
                <a [routerLink]="'.'" [queryParams]="{edit: milestone.id}" [queryParamsHandling]="'merge'">{{
                    milestone.obs
                }}</a>
            </ng-container>
            <ng-template #obsWithoutLink> {{ milestone.obs }} </ng-template>
        </clr-dg-cell>
        <clr-dg-cell> {{ milestone.description }} </clr-dg-cell>
        <clr-dg-cell class="monospace-date" [title]="milestone.target | momentDuration">
            {{ milestone.target | momentFormat: 'L LT' }}
        </clr-dg-cell>
        <clr-dg-cell class="monospace-date" [title]="milestone.beginDate | momentDuration">
            {{ milestone.beginDate | momentFormat: 'L LT' }}
        </clr-dg-cell>
        <clr-dg-cell class="monospace-date" [title]="milestone.endDate | momentDuration">
            {{ milestone.endDate | momentFormat: 'L LT' }}
        </clr-dg-cell>
        <clr-dg-cell>
            <span [classList]="MILESTONE_TYPE_FILTER[milestone.type].classList">
                <clr-icon [attr.shape]="MILESTONE_TYPE_FILTER[milestone.type].icon"></clr-icon>
                {{ MILESTONE_TYPE_FILTER[milestone.type].translation | translate }}

                <clr-icon
                    *ngIf="
                        milestone.type === MilestonesType.RELEASE &&
                        milestone.releaseState === ReleaseStateType.RELEASED
                    "
                    shape="truck"
                ></clr-icon>
            </span>
        </clr-dg-cell>
    </clr-dg-row>

    <clr-dg-footer>
        <clr-dg-column-toggle>
            <clr-dg-column-toggle-title>{{ 'milestones_columns' | translate }}</clr-dg-column-toggle-title>
            <clr-dg-column-toggle-button>{{ 'show_all_columns' | translate }}</clr-dg-column-toggle-button>
        </clr-dg-column-toggle>

        <span *ngIf="milestones">{{ 'count_milestones' | translate: {count: milestones?.length} }}</span>
    </clr-dg-footer>
</clr-datagrid>

<!-- (clrWizardOpenChange)="openModal(IActionName.MILESTONE)" -->
<app-milestone-wizard
    *sharedAccessControl="RIGHTS.MS_UPDATE"
    [(clrWizardOpen)]="showCreateModal"
    (finish)="addNewMilestone($event)"
    [params]="{clientId: client?.id, projectId: project?.id}"
></app-milestone-wizard>

<app-edit-milestone
    [selectedEvent]="editionMilestone"
    [showModal]="showEditModal"
    (hidden)="closeEditModal()"
    (saved)="editedMilestone($event)"
    (removed)="deleteSelectedMilestone()"
    [deletable]="false"
>
</app-edit-milestone>

<app-modal [(show)]="showDeleteModal" [title]="'milestone_delete_modal_title' | translate">
    <ng-template appModalBody>
        <p>
            {{
                'swal_delete_attch'
                    | translate
                        : {
                              what:
                                  selectedMilestones.length > 1
                                      ? ('count_milestones' | translate: {count: selectedMilestones.length})
                                      : selectedMilestones[0].obs
                          }
            }}
        </p>
    </ng-template>
    <ng-template appModalFooter>
        <button
            class="btn btn-secondary"
            (click)="showDeleteModal = false"
            [sharedAutofocus]="showDeleteModal"
            translate
        >
            form_cancel
        </button>
        <button class="btn btn-danger" (click)="deleteSelectedMilestones()">
            {{ (selectedMilestones.length > 1 ? 'delete_milestones' : 'delete_milestone') | translate }}
        </button>
    </ng-template>
</app-modal>
