import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ClrDatagridSortOrder} from '@clr/angular';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {ISO_DATE_FORMAT, MilestonesType, ReleaseStateType, RIGHTS} from '../../../../defs/schema-static';
import {IClient} from '../../../../defs/schema/public/Clients';
import {IMeetingParticipant} from '../../../../defs/schema/public/MeetingParticipants';
import {IMilestone, MILESTONE_FIELD, MILESTONE_SCHEMA_ROUTE} from '../../../../defs/schema/public/Milestones';
import {IProject} from '../../../../defs/schema/public/Projects';
import {DATAGRID_FILTER_TYPE, IDatagridColumn, MILESTONE_TYPE_FILTER} from '../app-static';
import {FormsAddMilestoneService, IMilestoneFormValues, MilestoneTarget} from '../forms/add-milestone.service';
import {HttpRestService} from '../shared/http-rest/http-rest.service';
import {MomentService} from '../shared/moment/moment.service';
import {TOAST_TYPE, ToastService} from '../shared/toast/toast.service';

@Component({
    selector: 'app-milestones-list',
    templateUrl: './milestones-list.component.html',
})
export class MilestonesListComponent implements OnInit, OnChanges {
    @Input() public client: IClient;
    @Input() public project: IProject;
    @Input() public milestones: Partial<IMilestone>[];

    public displayMilestones: Partial<IMilestone>[] = [];

    public filter = '';
    public selectedMilestone: Partial<IMilestone>;
    public selectedMilestones: Partial<IMilestone>[] = [];

    public editionMilestone: IMilestoneFormValues;

    public showCreateModal = false;
    public showEditModal = false;
    public showDeleteModal = false;

    private editQueryParams: number;
    public submitSubscriber: Subscription;

    public constructor(
        private readonly httpRest: HttpRestService,
        private readonly formsAddMilestoneService: FormsAddMilestoneService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly toastService: ToastService,
        private readonly translate: TranslateService,
        private readonly momentService: MomentService
    ) {}

    public ngOnInit() {
        this.route.queryParams.subscribe((queryParams) => {
            this.editQueryParams = Number(queryParams.edit) || null;
            this.selectedMilestoneChange();
        });

        this.getMilestones();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.milestones && this.milestones) {
            this.milestones = [...this.milestones];

            this.getMilestones();
        }
    }

    public selectedMilestoneChange() {
        if (!this.editQueryParams) {
            this.selectedMilestone = null;
            this.showEditModal = false;

            return;
        }

        this.selectedMilestone = (this.milestones || []).find((_milestone) => _milestone.id === this.editQueryParams);

        if (this.selectedMilestone) {
            this.editMilestone();
        }
    }

    public async closeEditModal() {
        return this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {edit: null},
            queryParamsHandling: 'merge',
        });
    }

    public editMilestone() {
        const milestone = this.selectedMilestone;

        this.editionMilestone = {
            id: milestone.id,
            author: milestone.employeeId,
            description: milestone.description,
            milestone: milestone.obs,
            participants: milestone.participants,
            client: this.client && this.client.id,
            project: this.project && this.project.id,
            startDate: this.momentService.moment(milestone.beginDate).format(ISO_DATE_FORMAT),
            startTime: this.momentService
                .moment(milestone.beginDate)
                .format(FormsAddMilestoneService.HOUR_MINUTE_TIME_FORMAT),
            endDate: this.momentService.moment(milestone.endDate).format(ISO_DATE_FORMAT),
            endTime: this.momentService
                .moment(milestone.endDate)
                .format(FormsAddMilestoneService.HOUR_MINUTE_TIME_FORMAT),
            target: milestone.target ? this.momentService.moment(milestone.target).format(ISO_DATE_FORMAT) : null,
            targetType: this.client ? MilestoneTarget.CLIENT : MilestoneTarget.PROJECT,
            type: milestone.type,
            version: milestone.version,
        };
        this.showEditModal = true;
    }

    public getMilestones() {
        if (!this.milestones) {
            return;
        }

        this.updateFilter();
        this.selectedMilestoneChange();
    }

    public updateFilter() {
        const val = this.filter.toLowerCase();
        this.displayMilestones = (this.milestones || []).filter(
            (milestone) =>
                (milestone.obs || '').toLowerCase().includes(val) ||
                (milestone.type || '').toLowerCase().includes(val) ||
                (milestone.description || '').toLowerCase().includes(val) ||
                (milestone.version || '').toLowerCase().includes(val) ||
                (milestone.releaseState || '').toLowerCase().includes(val) ||
                !val
        );
    }

    public async addNewMilestone(milestone: IMilestoneFormValues) {
        const _milestone = await this.formsAddMilestoneService.submit(milestone);
        if (!_milestone) {
            return undefined;
        }

        this.milestones.push(_milestone);
        this.updateFilter();

        return this.closeEditModal();
    }

    public async editedMilestone(milestone: IMilestoneFormValues) {
        const participants = milestone.participants ? [...milestone.participants] : [];
        const delParticipants = this.selectedMilestone.participants
            .filter((f: IMeetingParticipant) => !participants.map((p) => p.employeeId).includes(f.employeeId))
            .map((m: IMeetingParticipant) => m.employeeId);

        const addedParticipants = participants
            .filter(
                (f: IMeetingParticipant) =>
                    !this.selectedMilestone.participants
                        .map((p: IMeetingParticipant) => p.employeeId)
                        .includes(f.employeeId)
            )
            .map((m) => m.employee.id);

        this.submitSubscriber = this.formsAddMilestoneService.onSubmit.subscribe(async () => {
            this.showEditModal = false;
            this.submitSubscriber.unsubscribe();
            delete this.submitSubscriber;

            return this.closeEditModal();
        });

        await this.formsAddMilestoneService.edit(
            {...milestone, id: this.selectedMilestone.id, type: this.selectedMilestone.type},
            addedParticipants,
            delParticipants
        );

        this.selectedMilestone = undefined;

        const existingMilestone = this.milestones.find(({id}) => id === milestone.id);
        if (!existingMilestone) {
            return;
        }

        if (!milestone.target || milestone.target === 'Invalid date') {
            delete milestone.target;
        }

        Object.assign(existingMilestone, {
            ...milestone,
            obs: milestone.milestone,
            beginDate: this.momentService.moment(`${milestone.startDate}T${milestone.startTime}`),
            endDate: this.momentService.moment(`${milestone.endDate}T${milestone.endTime}`),
            target: milestone.target ? this.momentService.moment(`${milestone.target}`) : undefined,
        } as Partial<IMilestone>);

        this.updateFilter();
    }

    public async deleteSelectedMilestones() {
        const deletedMilestoneIds = this.selectedMilestones.map((milestone) => milestone.id);
        try {
            await this.httpRest.deleteIds(MILESTONE_SCHEMA_ROUTE, deletedMilestoneIds).toPromise();
        } catch (err) {
            this.showDeleteModal = false;

            return;
        }
        this.selectedMilestones = [];
        this.milestones = this.milestones.filter((milestone) => !deletedMilestoneIds.includes(milestone.id));
        this.updateFilter();

        this.toastService.show({
            type: TOAST_TYPE.SUCCESS,
            text: 'success_delete_milestone',
        });

        this.showDeleteModal = false;
    }

    public async deleteSelectedMilestone() {
        this.milestones = this.milestones.filter((milestone) => milestone.id !== this.selectedMilestone.id);
        this.selectedMilestones = [];
        this.editionMilestone = null;
        this.updateFilter();

        this.toastService.show({
            type: TOAST_TYPE.SUCCESS,
            text: 'success_delete_milestone',
        });

        this.showEditModal = false;
    }

    public readonly RIGHTS = RIGHTS;
    public readonly MILESTONE_FIELD = MILESTONE_FIELD;
    public readonly DATAGRID_FILTER_TYPE = DATAGRID_FILTER_TYPE;

    public readonly MilestonesType = MilestonesType;
    public readonly ReleaseStateType = ReleaseStateType;

    public readonly MILESTONE_TYPE_FILTER = MILESTONE_TYPE_FILTER;
    public readonly MILESTONE_TYPE_FILTER_STATE_WITHOUT_RELEASES = Object.keys(MILESTONE_TYPE_FILTER).reduce(
        (_state, filterKey: keyof typeof MILESTONE_TYPE_FILTER) => {
            _state[filterKey] = filterKey !== MilestonesType.RELEASE;

            return _state;
        },
        {} as {[key in keyof typeof MILESTONE_TYPE_FILTER]: boolean}
    );

    public readonly MILESTONES_COLUMNS: IDatagridColumn[] = [
        {
            field: MILESTONE_FIELD.obs,
            translateKey: 'table_name',
            hideable: false,
        },
        {
            name: 'Description',
            field: MILESTONE_FIELD.description,
            translateKey: 'table_description',
        },
        {
            field: MILESTONE_FIELD.target,
            translateKey: 'table_target',
            order: ClrDatagridSortOrder.ASC,
            filterType: DATAGRID_FILTER_TYPE.DATE,
            hidden: true,
        },
        {
            field: MILESTONE_FIELD.beginDate,
            translateKey: 'begin_date',
            filterType: DATAGRID_FILTER_TYPE.DATE,
        },
        {
            field: MILESTONE_FIELD.endDate,
            translateKey: 'end_date',
            filterType: DATAGRID_FILTER_TYPE.DATE,
        },
        {
            field: MILESTONE_FIELD.type,
            translateKey: 'table_type',
            filterType: DATAGRID_FILTER_TYPE.CUSTOM,
        },
    ];

    public filterOpen: {[key: string]: boolean} = {};
}
