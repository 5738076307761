var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Router } from '@angular/router';
import { COGNITO_USER_GROUPS, COGNITO_USER_GROUPS_PRECEDENCE, NAVIGATE_RIGHTS } from '../../../../defs/schema-static';
import { APP_MODULE_ROUTE_PATH, AUTH_MODULE_ROUTE_PATH, PORTAL_MODULE_ROUTE_PATH } from '../app-static';
import { AuthService } from './auth.service';
var AuthGuard = (function () {
    function AuthGuard(authService, router) {
        var _this = this;
        this.authService = authService;
        this.router = router;
        this.requiredGroup = COGNITO_USER_GROUPS._UNAUTHED;
        this.targetRouteUrl = '';
        if (!AuthGuard.restoring) {
            AuthGuard.restoring = true;
            this.authService.restoreSession(function (err) {
                if (err) {
                    _this.authService.signOut();
                    return;
                }
                AuthGuard.restored = true;
            });
            this.authService.isAuthenticatedChange.subscribe(function () {
                if (_this.isAuthenticated) {
                    if (_this.targetRouteUrl) {
                        (function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                            return [2, this.router.navigateByUrl(this.targetRouteUrl)];
                        }); }); })();
                        _this.targetRouteUrl = '';
                        return;
                    }
                    (function () { return __awaiter(_this, void 0, void 0, function () {
                        var _a, _b, _c;
                        return __generator(this, function (_d) {
                            switch (_d.label) {
                                case 0:
                                    _b = (_a = this.router).navigateByUrl;
                                    if (!!router.url.includes('login')) return [3, 1];
                                    _c = router.url.replace('/', '');
                                    return [3, 3];
                                case 1: return [4, this.getDefaultRoute()];
                                case 2:
                                    _c = _d.sent();
                                    _d.label = 3;
                                case 3: return [2, _b.apply(_a, [_c, {
                                            replaceUrl: true,
                                        }])];
                            }
                        });
                    }); })();
                    return;
                }
                if (AuthGuard.restored) {
                    (function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                        return [2, this.router.navigate([AuthGuard.LOGIN_ROUTE], { replaceUrl: true })];
                    }); }); })();
                }
            });
        }
    }
    AuthGuard.prototype.canActivate = function (next, state) {
        var _this = this;
        var url = state.url;
        if (url.indexOf("/" + AUTH_MODULE_ROUTE_PATH.AUTH) === 0) {
            if (!this.isAuthenticated) {
                return true;
            }
        }
        if (!this.isAuthenticated) {
            if (!this.targetRouteUrl) {
                this.targetRouteUrl = url;
            }
            if (AuthGuard.restored) {
                (function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                    return [2, this.router.navigate([AuthGuard.LOGIN_ROUTE], { replaceUrl: true })];
                }); }); })();
            }
            return false;
        }
        if (!this.isAuthorized || (url === AuthGuard.LOGIN_ROUTE && !this.targetRouteUrl)) {
            (function () { return __awaiter(_this, void 0, void 0, function () { var _a, _b; return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _b = (_a = this.router).navigate;
                        return [4, this.getDefaultRoute()];
                    case 1: return [2, _b.apply(_a, [[_c.sent()], { replaceUrl: true }])];
                }
            }); }); })();
            return this.isAuthorized;
        }
        if (this.targetRouteUrl) {
            (function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                return [2, this.router.navigateByUrl(this.targetRouteUrl)];
            }); }); })();
            this.targetRouteUrl = '';
        }
        return true;
    };
    Object.defineProperty(AuthGuard.prototype, "isAuthenticated", {
        get: function () {
            return this.authService.isAuthenticated;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthGuard.prototype, "isAuthorized", {
        get: function () {
            var _this = this;
            return (this.isAuthenticated &&
                !!this.authService.cognitoGroups.find(function (group) { return COGNITO_USER_GROUPS_PRECEDENCE[_this.requiredGroup] >= COGNITO_USER_GROUPS_PRECEDENCE[group]; }));
        },
        enumerable: true,
        configurable: true
    });
    AuthGuard.prototype.getDefaultRoute = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = this.authService.precedenceGroup;
                        switch (_a) {
                            case COGNITO_USER_GROUPS.ADMIN: return [3, 1];
                            case COGNITO_USER_GROUPS.EMPLOYEE: return [3, 1];
                            case COGNITO_USER_GROUPS.CLIENT: return [3, 3];
                        }
                        return [3, 4];
                    case 1:
                        _b = this.authService;
                        return [4, this.authService.getRightsPromise];
                    case 2:
                        _b.rights = _c.sent();
                        if (this.authService.rights.find(function (right) { return right.code === NAVIGATE_RIGHTS.NAVIGATE_DASHBOARD; })) {
                            return [2, "/" + APP_MODULE_ROUTE_PATH.DASHBOARD];
                        }
                        if (this.authService.rights.find(function (right) { return right.code === NAVIGATE_RIGHTS.NAVIGATE_FIRON; })) {
                            return [2, "/" + APP_MODULE_ROUTE_PATH.FIRON];
                        }
                        if (this.authService.rights.find(function (right) { return right.code === NAVIGATE_RIGHTS.NAVIGATE_CALENDAR; })) {
                            return [2, "/" + APP_MODULE_ROUTE_PATH.CALENDAR];
                        }
                        if (this.authService.rights.find(function (right) { return right.code === NAVIGATE_RIGHTS.NAVIGATE_GANTT; })) {
                            return [2, "/" + APP_MODULE_ROUTE_PATH.GANTT];
                        }
                        if (this.authService.rights.find(function (right) { return right.code === NAVIGATE_RIGHTS.NAVIGATE_REPORTS; })) {
                            return [2, "/" + APP_MODULE_ROUTE_PATH.REPORTS];
                        }
                        return [2, "/" + AUTH_MODULE_ROUTE_PATH.AUTH + "/" + AUTH_MODULE_ROUTE_PATH.UNAUTHORIZED];
                    case 3: return [2, "/" + PORTAL_MODULE_ROUTE_PATH.DASHBOARD];
                    case 4: return [2, "/" + AUTH_MODULE_ROUTE_PATH.AUTH + "/" + AUTH_MODULE_ROUTE_PATH.UNAUTHORIZED];
                }
            });
        });
    };
    AuthGuard.restored = false;
    AuthGuard.restoring = false;
    AuthGuard.LOGIN_ROUTE = "/" + AUTH_MODULE_ROUTE_PATH.AUTH + "/" + AUTH_MODULE_ROUTE_PATH.LOG_IN;
    return AuthGuard;
}());
export { AuthGuard };
