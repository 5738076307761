var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import 'fullcalendar';
import 'fullcalendar/dist/locale/fr.js';
import $ from 'jquery';
import { forkJoin } from 'rxjs';
import { getConfigKeys, getWorkingHours } from '../../../../defs/businessRules';
import { ApiRoutePlurality, DECIMAL_RADIX, HTTP_METHOD, ISO_DATE_FORMAT, MILESTONE_TYPE_ICON, MilestonesType, RIGHTS, } from '../../../../defs/schema-static';
import { CLIENT_SCHEMA_ROUTE } from '../../../../defs/schema/public/Clients';
import { EMPLOYEE_SCHEMA_ROUTE } from '../../../../defs/schema/public/Employees';
import { MILESTONE_SCHEMA_ROUTE } from '../../../../defs/schema/public/Milestones';
import { PROJECT_SCHEMA_ROUTE } from '../../../../defs/schema/public/Projects';
import { isColorDark } from '../app-static';
import { AuthService } from '../auth/auth.service';
import { FormsAddMilestoneService, MILESTONE_FORM_KEYS, MilestoneTarget, } from '../forms/add-milestone.service';
import { validate } from '../forms/validators/form.validator';
import { ConfigService } from '../shared/config/config.service';
import { HttpRestService } from '../shared/http-rest/http-rest.service';
import { MomentService } from '../shared/moment/moment.service';
import { SHORTCUT_CREATE, ShortcutHandlerService } from '../shared/shortcut-handler/shortcut-handler.service';
import { ToastService } from '../shared/toast/toast.service';
var CalendarComponent = (function () {
    function CalendarComponent(window, httpRest, formsAddMilestoneService, translate, authService, toastService, shortcutHandlerService, configService, momentService, route, router) {
        this.window = window;
        this.httpRest = httpRest;
        this.formsAddMilestoneService = formsAddMilestoneService;
        this.translate = translate;
        this.authService = authService;
        this.toastService = toastService;
        this.shortcutHandlerService = shortcutHandlerService;
        this.configService = configService;
        this.momentService = momentService;
        this.route = route;
        this.router = router;
        this.showCalendarModal = false;
        this.clients = [];
        this.employees = [];
        this.projects = [];
        this.filteredProjects = [];
        this.filteredEmployees = [];
        this.employeeFilter = null;
        this.projectFilter = null;
        this.clientFilter = null;
        this.loading = true;
        this.initialized = false;
        this.calendarEvents = [];
        this.msTypes = Object.keys(MilestonesType);
        this.MILESTONE_TYPE_ICON = MILESTONE_TYPE_ICON;
        this.MilestonesType = MilestonesType;
        this.isColorDark = isColorDark;
        this.RIGHTS = RIGHTS;
        this.MILESTONE_FORM_KEYS = MILESTONE_FORM_KEYS;
        this.validate = validate;
    }
    CalendarComponent.prototype.ngOnDestroy = function () {
        $(this.calendarElement.nativeElement).fullCalendar('destroy');
    };
    CalendarComponent.prototype.ngOnInit = function () {
        var _this = this;
        forkJoin(this.httpRest._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, EMPLOYEE_SCHEMA_ROUTE, 'calendar'), this.httpRest._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, CLIENT_SCHEMA_ROUTE, 'light'), this.httpRest._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, PROJECT_SCHEMA_ROUTE, 'light')).subscribe(function (result) {
            _this.employees = result[0], _this.clients = result[1], _this.projects = result[2];
            _this.filteredEmployees = _this.employees.sort(function (a, b) { return a.user.name.localeCompare(b.user.name); });
            _this.filteredProjects = _this.projects;
            _this.init();
        });
        this.route.queryParams.subscribe(function (queryParams) {
            if (queryParams.date) {
                if (!_this.initialized) {
                    _this.gotoDate = queryParams.date;
                }
                else {
                    $(_this.calendarElement.nativeElement).fullCalendar('gotoDate', queryParams.date);
                }
            }
            if (queryParams.milestoneId) {
                var milestoneId_1 = Number(queryParams.milestoneId);
                var event_1 = _this.calendarEvents.find(function (_a) {
                    var id = _a.id;
                    return id === milestoneId_1;
                });
                if (event_1) {
                    _this.editMilestone(event_1);
                }
                else {
                    _this.gotoMilestoneId = milestoneId_1;
                }
            }
            _this.router.navigate([], { relativeTo: _this.route, queryParams: {} });
        });
    };
    CalendarComponent.prototype.getMinMaxTime = function () {
        var calendarTime = getConfigKeys(this.configService.config, ['calendarHours']);
        if (calendarTime && calendarTime.calendarHours) {
            var confStart = calendarTime.calendarHours.start.split(':');
            var start = this.momentService
                .moment()
                .hours(parseInt(confStart[0], DECIMAL_RADIX))
                .minutes(parseInt(confStart[1], DECIMAL_RADIX));
            var confEnd = calendarTime.calendarHours.end.split(':');
            var end = this.momentService
                .moment()
                .hours(parseInt(confEnd[0], DECIMAL_RADIX))
                .minutes(parseInt(confEnd[1], DECIMAL_RADIX));
            return { start: start.format('HH:mm:00'), end: end.format('HH:mm:00') };
        }
        return { start: '08:00:00', end: '18:00:00' };
    };
    CalendarComponent.prototype.init = function () {
        var _this = this;
        var workingHours = this.getMinMaxTime();
        $(this.calendarElement.nativeElement).fullCalendar({
            weekends: true,
            header: {
                left: 'title',
                right: 'month,agendaWeek,agendaDay today prev,next',
            },
            locale: this.translate.currentLang.toLowerCase(),
            defaultView: 'agendaWeek',
            minTime: workingHours.start,
            maxTime: workingHours.end,
            height: this.window.innerHeight - 80,
            slotDuration: '00:15:00',
            slotLabelFormat: 'HH:mm',
            allDaySlot: true,
            slotEventOverlap: false,
            handleWindowResize: true,
            windowResizeDelay: 100,
            displayEventEnd: true,
            unselectAuto: true,
            nowIndicator: true,
            selectable: true,
            droppable: true,
            weekNumbers: true,
            weekNumbersWithinDays: true,
            eventDurationEditable: false,
            events: function (start, end, timezone, callback) {
                _this.initialized = true;
                if (_this.gotoDate) {
                    var date = _this.gotoDate;
                    _this.gotoDate = null;
                    $(_this.calendarElement.nativeElement).fullCalendar('gotoDate', date);
                    return;
                }
                _this.loading = true;
                _this.httpRest
                    ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, MILESTONE_SCHEMA_ROUTE, "calendar/" + [start, end].map(function (date) { return date.format(); }).join('/'))
                    .subscribe(function (milestones) {
                    _this.events = milestones;
                    _this.calendarEvents = milestones.map(function (ms) {
                        var options = _this.fillEvent(ms);
                        $(_this.calendarElement.nativeElement).fullCalendar('renderEvent', options, false);
                        callback(_this.events);
                        if (_this.gotoMilestoneId && ms.id === _this.gotoMilestoneId) {
                            _this.gotoMilestoneId = null;
                            _this.editMilestone(options);
                        }
                        return options;
                    });
                    _this.loading = false;
                });
            },
            themeSystem: 'bootstrap4',
            themeButtonIcons: {
                prev: 'circle-triangle-w',
                next: 'circle-triangle-e',
                prevYear: 'seek-prev',
                nextYear: 'seek-next',
            },
            eventClick: function (calEvent, jsEvent, view) {
                _this.editMilestone(calEvent);
            },
            eventRender: function (event, element, view) {
                element.addClass("milestone-" + event.type.toLowerCase());
                if (event.icon) {
                    element.find('.fc-title').prepend("<clr-icon shape=\"" + event.icon + "\"></clr-icon> ");
                }
                if (event.hidden) {
                    element.addClass('fc-event-transparent');
                }
                var ntoday = new Date().getTime();
                var eventEnd = _this.momentService.moment(event.end).valueOf();
                var eventStart = _this.momentService.moment(event.start).valueOf();
                if (!event.end) {
                    if (eventStart < ntoday) {
                        element.addClass('past-event');
                        element.children().addClass('past-event');
                    }
                }
                else {
                    if (eventEnd < ntoday) {
                        element.addClass('past-event');
                        element.children().addClass('past-event');
                    }
                }
                return ((!_this.projectFilter || event.projectId === _this.projectFilter) &&
                    (event.type === _this.MilestonesType.REMINDER
                        ? event.employeeId === _this.authService.user.employee.id
                        : true) &&
                    (!_this.clientFilter ||
                        event.clientId === _this.clientFilter ||
                        (event.projectId && _this.getProject(event.projectId).clientId === _this.clientFilter)) &&
                    (!_this.typeFilter || event.type === _this.typeFilter) &&
                    (_this.employeeFilter
                        ? (event.participants.findIndex(function (e) { return e.employeeId === _this.employeeFilter; }) > -1 &&
                            (event.type === MilestonesType.MEETING ||
                                event.type === MilestonesType.MEETING_NO_NOTE)) ||
                            (event.employeeId === _this.employeeFilter &&
                                event.type !== MilestonesType.MEETING &&
                                event.type !== MilestonesType.MEETING_NO_NOTE)
                        : true));
            },
            select: function (start, end, event, calendar) {
                if (!_this.submitSubscriber) {
                    _this.submitSubscriber = _this.formsAddMilestoneService.onSubmit.subscribe(function () {
                        $(_this.calendarElement.nativeElement).fullCalendar('refetchEvents');
                        _this.submitSubscriber.unsubscribe();
                        delete _this.submitSubscriber;
                    });
                }
                var _workingHours = getWorkingHours(_this.configService.config);
                var configStartTime = _workingHours.start.split(':').map(function (e) { return parseInt(e, DECIMAL_RADIX); });
                var configEndTime = _workingHours.end.split(':').map(function (e) { return parseInt(e, DECIMAL_RADIX); });
                if (start.hours() === 0) {
                    start.hours(configStartTime[0]);
                    start.minutes(configEndTime[1]);
                }
                if (end.hours() === 0) {
                    end.subtract(1, 'day');
                    end.hours(configStartTime[0]);
                    end.minutes(configEndTime[1]);
                }
                _this.shortcutHandlerService.execute(SHORTCUT_CREATE.MILESTONE, { start: start, end: end });
            },
            windowResize: function () {
                $(_this.calendarElement.nativeElement).fullCalendar('option', 'height', _this.window.innerHeight - 80);
            },
        });
    };
    CalendarComponent.prototype.fillEvent = function (ms) {
        return __assign({ id: ms.id, author: ms.employee, title: ms.obs, description: ms.description, type: ms.type, targetType: ms.client ? MilestoneTarget.CLIENT : MilestoneTarget.PROJECT, clientId: ms.client ? ms.client.id : -1, projectId: ms.project ? ms.project.id : -1, employeeId: ms.employeeId, version: ms.version, beginDate: ms.beginDate, endDate: ms.endDate, target: ms.target, name: this.getEventName(ms), participants: ms.participants }, this.getCalendarDates(ms), { hidden: !!(!ms.project && !ms.client && ms.employeeId !== this.authService.user.employee.id) });
    };
    CalendarComponent.prototype.editMilestone = function (calEvent) {
        this.selectedEvent = calEvent;
        this.editTask = {
            id: this.selectedEvent.id,
            client: this.selectedEvent.clientId,
            project: this.selectedEvent.projectId,
            milestone: this.selectedEvent.title,
            description: this.selectedEvent.description,
            startDate: this.momentService.moment(this.selectedEvent.beginDate).format(ISO_DATE_FORMAT),
            startTime: this.momentService
                .moment(this.selectedEvent.beginDate)
                .format(CalendarComponent.HOUR_MINUTE_TIME_FORMAT),
            endDate: this.momentService.moment(this.selectedEvent.endDate).format(ISO_DATE_FORMAT),
            endTime: this.momentService
                .moment(this.selectedEvent.endDate)
                .format(CalendarComponent.HOUR_MINUTE_TIME_FORMAT),
            version: this.selectedEvent.version,
            target: this.momentService.moment(this.selectedEvent.target).format(ISO_DATE_FORMAT),
            targetType: this.selectedEvent.targetType,
            type: this.selectedEvent.type,
            author: this.selectedEvent.author.id,
            participants: this.selectedEvent.participants,
        };
        this.showCalendarModal = true;
    };
    CalendarComponent.prototype.saveMilestone = function (milestone) {
        return __awaiter(this, void 0, void 0, function () {
            var participants, delParticipants, addedParticipants;
            var _this = this;
            return __generator(this, function (_a) {
                participants = milestone.participants ? milestone.participants.slice() : [];
                delParticipants = this.selectedEvent.participants
                    .filter(function (f) { return !participants.map(function (p) { return p.employeeId; }).includes(f.employeeId); })
                    .map(function (m) { return m.employeeId; });
                addedParticipants = participants
                    .filter(function (f) {
                    return !_this.selectedEvent.participants
                        .map(function (p) { return p.employeeId; })
                        .includes(f.employeeId);
                })
                    .map(function (m) { return m.employee.id; });
                this.submitSubscriber = this.formsAddMilestoneService.onSubmit.subscribe(function () {
                    _this.showCalendarModal = false;
                    $(_this.calendarElement.nativeElement).fullCalendar('refetchEvents');
                    _this.submitSubscriber.unsubscribe();
                    delete _this.submitSubscriber;
                });
                this.formsAddMilestoneService.edit(__assign({}, milestone, { id: this.selectedEvent.id, type: this.selectedEvent.type }), addedParticipants, delParticipants);
                this.showCalendarModal = false;
                return [2];
            });
        });
    };
    CalendarComponent.prototype.deleteMilestone = function () {
        $(this.calendarElement.nativeElement).fullCalendar('prev');
        $(this.calendarElement.nativeElement).fullCalendar('next');
    };
    CalendarComponent.prototype.getClient = function (id) {
        return this.clients.find(function (cl) { return cl.id === id; });
    };
    CalendarComponent.prototype.getProject = function (id) {
        return this.projects.find(function (p) { return p.id === id; });
    };
    CalendarComponent.prototype.getProjectName = function (id) {
        if (!id) {
            return undefined;
        }
        return this.projects.find(function (p) { return p.id === id; });
    };
    CalendarComponent.prototype.getCalendarDates = function (ms) {
        var options = {};
        switch (ms.type) {
            case MilestonesType.DEADLINE:
                options.start = this.momentService.moment(ms.beginDate);
                options.allDay = true;
                options.icon = 'step-forward-2';
                break;
            case MilestonesType.MEETING:
            case MilestonesType.MEETING_NO_NOTE:
                options.start = this.momentService.moment(ms.beginDate);
                options.end = this.momentService.moment(ms.endDate);
                options.icon = 'users';
                break;
            case MilestonesType.CALL:
                options.start = this.momentService.moment(ms.beginDate);
                options.end = this.momentService.moment(ms.beginDate).add(15, 'minutes');
                options.icon = 'phone-handset';
                break;
            case MilestonesType.REMINDER:
                options.start = this.momentService.moment(ms.beginDate);
                options.end = this.momentService.moment(ms.beginDate).add(15, 'minutes');
                options.icon = 'bell';
                break;
            case MilestonesType.RELEASE:
                options.start = ms.endDate
                    ? this.momentService.moment(ms.endDate)
                    : this.momentService.moment(ms.target);
                options.allDay = true;
                options.icon = 'checkbox-list';
                break;
            default:
                break;
        }
        return options;
    };
    CalendarComponent.prototype.getEventName = function (ms) {
        if (!ms.client && !ms.project) {
            return '';
        }
        if (ms.clientId) {
            return ms.client && ms.client.user.name + " (" + ms.client.user.code + ")";
        }
        else {
            return ms.project.obs + " (" + ms.project.code + ") - " + this.getClient(ms.project.clientId).user.name + " (" + this.getClient(ms.project.clientId).user.code + ")";
        }
    };
    CalendarComponent.prototype.filterEmployee = function (filterBy) {
        if (!filterBy) {
            this.employeeFilter = null;
        }
        else {
            this.employeeFilter = parseInt(filterBy, 10);
        }
        $(this.calendarElement.nativeElement).fullCalendar('rerenderEvents');
    };
    CalendarComponent.prototype.filterProject = function (filterBy) {
        var _this = this;
        if (!filterBy) {
            this.projectFilter = null;
            this.filteredEmployees = this.employees;
        }
        else {
            this.projectFilter = parseInt(filterBy, 10);
            this.filteredEmployees = this.employees.filter(function (f) {
                return _this.filteredProjects
                    .find(function (s) { return s.id === _this.projectFilter; })
                    .projectMembers.map(function (m) { return m.employeeId; })
                    .includes(f.id);
            });
            if (!this.filteredEmployees.map(function (m) { return m.id; }).includes(this.employeeFilter)) {
                this.employeeFilter = null;
                this.employeeModel = null;
            }
        }
        $(this.calendarElement.nativeElement).fullCalendar('rerenderEvents');
    };
    CalendarComponent.prototype.filterClient = function (filterBy) {
        var _this = this;
        if (!filterBy) {
            this.clientFilter = null;
            this.filteredProjects = this.projects;
        }
        else {
            this.clientFilter = parseInt(filterBy, 10);
            this.filteredProjects = this.projects.filter(function (f) { return f.clientId === _this.clientFilter; });
            if (!this.filteredProjects.map(function (m) { return m.id; }).includes(this.projectFilter)) {
                this.projectFilter = null;
                this.projectModel = null;
                this.filteredEmployees = this.employees;
            }
        }
        $(this.calendarElement.nativeElement).fullCalendar('rerenderEvents');
    };
    CalendarComponent.prototype.filterType = function (filterBy) {
        if (!filterBy) {
            this.typeFilter = null;
        }
        else {
            this.typeFilter = filterBy;
        }
        $(this.calendarElement.nativeElement).fullCalendar('rerenderEvents');
    };
    CalendarComponent.HOUR_MINUTE_TIME_FORMAT = 'HH:mm';
    return CalendarComponent;
}());
export { CalendarComponent };
