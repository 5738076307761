var _a;
export var USER_FIELD;
(function (USER_FIELD) {
    USER_FIELD["id"] = "id";
    USER_FIELD["code"] = "code";
    USER_FIELD["name"] = "name";
    USER_FIELD["color"] = "color";
    USER_FIELD["settings"] = "settings";
    USER_FIELD["email"] = "email";
    USER_FIELD["cognitoUsername"] = "cognitoUsername";
    USER_FIELD["cognitoIdentityGuid"] = "cognitoIdentityGuid";
    USER_FIELD["createdAt"] = "createdAt";
    USER_FIELD["updatedAt"] = "updatedAt";
})(USER_FIELD || (USER_FIELD = {}));
export var USER_SCHEMA_ROUTE = {
    tableName: 'users',
    singularRoute: 'user',
    pluralRoute: 'users',
};
export var SETTINGS;
(function (SETTINGS) {
    SETTINGS["THEME"] = "THEME";
    SETTINGS["LANGUAGE"] = "LANGUAGE";
    SETTINGS["SIDEBAR_COLLAPSED"] = "SIDEBAR_COLLAPSED";
    SETTINGS["FIRON_VIEW"] = "FIRON_VIEW";
    SETTINGS["FIRON_DETAIL_DISPLAY"] = "FIRON_DETAIL_DISPLAY";
    SETTINGS["ROADMAP_FILTER"] = "ROADMAP_FILTER";
    SETTINGS["EMAIL_NOTIFICATIONS"] = "EMAIL_NOTIFICATIONS";
})(SETTINGS || (SETTINGS = {}));
export var SETTING_THEME_VALUES;
(function (SETTING_THEME_VALUES) {
    SETTING_THEME_VALUES["DARK"] = "dark";
    SETTING_THEME_VALUES["LIGHT"] = "light";
})(SETTING_THEME_VALUES || (SETTING_THEME_VALUES = {}));
export var SETTING_LANGUAGE_VALUES;
(function (SETTING_LANGUAGE_VALUES) {
    SETTING_LANGUAGE_VALUES["FR"] = "FR";
    SETTING_LANGUAGE_VALUES["EN"] = "EN";
})(SETTING_LANGUAGE_VALUES || (SETTING_LANGUAGE_VALUES = {}));
export var SETTING_FIRON_VIEW_VALUES;
(function (SETTING_FIRON_VIEW_VALUES) {
    SETTING_FIRON_VIEW_VALUES["ALL"] = "all";
    SETTING_FIRON_VIEW_VALUES["DEV"] = "dev";
    SETTING_FIRON_VIEW_VALUES["REVIEW"] = "review";
})(SETTING_FIRON_VIEW_VALUES || (SETTING_FIRON_VIEW_VALUES = {}));
export var SETTING_FIRON_DETAIL_DISPLAY_VALUES;
(function (SETTING_FIRON_DETAIL_DISPLAY_VALUES) {
    SETTING_FIRON_DETAIL_DISPLAY_VALUES["COLUMN"] = "col";
    SETTING_FIRON_DETAIL_DISPLAY_VALUES["MODAL"] = "modal";
})(SETTING_FIRON_DETAIL_DISPLAY_VALUES || (SETTING_FIRON_DETAIL_DISPLAY_VALUES = {}));
export var SETTINGS_FILTER_ROADMAP_VALUES;
(function (SETTINGS_FILTER_ROADMAP_VALUES) {
    SETTINGS_FILTER_ROADMAP_VALUES["ALL"] = "all";
    SETTINGS_FILTER_ROADMAP_VALUES["MY"] = "my";
})(SETTINGS_FILTER_ROADMAP_VALUES || (SETTINGS_FILTER_ROADMAP_VALUES = {}));
export var DEFAULT_SETTINGS = (_a = {},
    _a[SETTINGS.THEME] = SETTING_THEME_VALUES.DARK,
    _a[SETTINGS.SIDEBAR_COLLAPSED] = false,
    _a[SETTINGS.FIRON_VIEW] = SETTING_FIRON_VIEW_VALUES.ALL,
    _a[SETTINGS.FIRON_DETAIL_DISPLAY] = SETTING_FIRON_DETAIL_DISPLAY_VALUES.MODAL,
    _a[SETTINGS.EMAIL_NOTIFICATIONS] = false,
    _a);
