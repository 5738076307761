import moment from 'moment';
import {CustomerRequestStatus, ISchemaRoute} from '../../schema-static';
import {ICustomerRequestFile} from '../storage/CustomerRequestFiles';
import {IEmployee} from './Employees';
import {IProject} from './Projects';

export enum CUSTOMER_REQUEST_FIELD {
    id = 'id',
    clientId = 'clientId',
    title = 'title',
    request = 'request',
    description = 'description',
    status = 'status',
    createdAt = 'createdAt',
    updatedAt = 'updatedAt',
    projectId = 'projectId',
    employeeId = 'employeeId',
}

export interface ICustomerRequestBare {
    id?: number;
    clientId: number;
    title?: string;
    request?: string;
    description?: string;
    status: CustomerRequestStatus;
    createdAt?: moment.Moment;
    updatedAt?: moment.Moment;
    projectId: number;
    employeeId: number;
}

export interface ICustomerRequestBelong {
    project: Partial<IProject>;
    employee: Partial<IEmployee>;
}

export interface ICustomerRequestMany {
    customerRequestFiles: Partial<ICustomerRequestFile>[];
}

export interface ICustomerRequest extends ICustomerRequestBare, ICustomerRequestBelong, ICustomerRequestMany {}

const CUSTOMER_REQUEST_SCHEMA_ROUTE: ISchemaRoute = {
    tableName: 'customerrequests',
    singularRoute: 'customerrequest',
    pluralRoute: 'customerrequests',
};

export {CUSTOMER_REQUEST_SCHEMA_ROUTE};
