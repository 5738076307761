import {HttpClient} from '@angular/common/http';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {ClrLoadingState} from '@clr/angular';
import {TranslateService} from '@ngx-translate/core';
import {forkJoin, Observable} from 'rxjs';
import {getConfigKeys} from '../../../../../defs/businessRules';
import {ApiRoutePlurality, HTTP_METHOD} from '../../../../../defs/schema-static';
import {ITranslations, TRANSLATIONS_SCHEMA_ROUTE} from '../../../../../defs/schema/meta/Translations';
import {SETTING_LANGUAGE_VALUES} from '../../../../../defs/schema/public/Users';
import {AppComponent} from '../../app.component';
import {ConfigService} from '../../shared/config/config.service';
import {HttpRestService} from '../../shared/http-rest/http-rest.service';
import {TOAST_TYPE, ToastService} from '../../shared/toast/toast.service';

interface Ii18nFile {
    name: SETTING_LANGUAGE_VALUES;
    enabled: boolean;
}

interface Ii18nObject {
    [key: string]: string;
}

const prepareCustomFile = (file: Ii18nObject, customTranslations: ITranslations[]) => {
    const custom: Ii18nObject = {};

    Object.keys(file).map((key) => {
        const customtr = customTranslations.find((tr) => tr.key === key);
        if (customtr) {
            custom[key] = customtr.value;
        } else {
            custom[key] = '';
        }
    });

    return custom;
};

@Component({
    selector: 'app-admin-i18n',
    templateUrl: './admin-i18n.component.html',
    styleUrls: ['./admin-i18n.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminI18nComponent implements OnInit {
    public files: Ii18nFile[] = [];
    public activeFiles: Ii18nFile[] = [];
    public inactiveFiles: Ii18nFile[] = [];

    public currentFile: Ii18nFile = null;
    public currentJSON: Ii18nObject;
    public filteredJSON: string[];

    public customJSON: Ii18nObject;
    public beforeEdit: Ii18nObject;

    public enabledI18n: SETTING_LANGUAGE_VALUES[];

    public validateBtnState: ClrLoadingState = ClrLoadingState.DEFAULT;

    public textFilter: string;
    public customFilter: boolean;

    private translations: ITranslations[];

    @ViewChild('f')
    public form: NgForm;

    public constructor(
        private readonly httpRest: HttpRestService,
        private readonly http: HttpClient,
        private readonly configService: ConfigService,
        private readonly toastService: ToastService,
        private readonly translate: TranslateService,
        public readonly cdRef: ChangeDetectorRef
    ) {}

    public async ngOnInit() {
        const config = await this.configService.get();

        const {enabledI18n} = getConfigKeys(config, ['enabledI18n']);
        this.enabledI18n = enabledI18n;

        const i18n = Object.values(SETTING_LANGUAGE_VALUES) as SETTING_LANGUAGE_VALUES[];

        this.files = i18n.map((name) => ({
            name,
            enabled: enabledI18n.includes(name),
        }));
        this.activeFiles = this.files.filter((f) => f.enabled);
        this.inactiveFiles = this.files.filter((f) => !f.enabled);

        this.cdRef.detectChanges();
    }

    public async fileChange() {
        if (!this.currentFile) {
            this.currentJSON = null;
            this.customJSON = null;

            return;
        }

        const {name} = this.currentFile;
        this.currentJSON = (await this.http
            .get([AppComponent.TRANSLATION_PREFIX, name, AppComponent.TRANSLATION_SUFFIX].join(''), {
                responseType: 'json',
            })
            .toPromise()) as Ii18nObject;

        // get custom translations
        this.translations = await this.httpRest
            ._request<ITranslations[]>(
                HTTP_METHOD.GET,
                ApiRoutePlurality.PLURAL,
                TRANSLATIONS_SCHEMA_ROUTE,
                name.toLowerCase()
            )
            .toPromise();

        this.customJSON = prepareCustomFile(this.currentJSON, this.translations);
        this.beforeEdit = prepareCustomFile(this.currentJSON, this.translations);

        if (this.form && this.form.dirty) {
            this.form.form.markAsPristine();
        }

        this.filterJSON();
    }

    public refresh() {
        this.cdRef.detectChanges();
    }

    protected saveFile() {
        this.validateBtnState = ClrLoadingState.LOADING;

        const i18nCode = this.currentFile.name;

        const obs: Observable<any>[] = [];

        // deleting values
        const deletedIds: number[] = [];
        Object.keys(this.beforeEdit)
            .filter((key) => this.beforeEdit[key] !== '')
            .map((key) => {
                if (!this.customJSON[key]) {
                    const translation = this.translations.find((t) => t.key === key);
                    if (!!translation) {
                        deletedIds.push(translation.id);
                    }
                }
            });
        if (deletedIds.length) {
            obs.push(this.httpRest.deleteIds(TRANSLATIONS_SCHEMA_ROUTE, deletedIds));
        }

        // updating values
        const inserted: ITranslations[] = [];
        const updated: Partial<ITranslations>[] = [];
        Object.keys(this.customJSON).map((key) => {
            this.customJSON[key] = this.customJSON[key].trim();
            if (this.customJSON[key] && this.customJSON[key] !== this.beforeEdit[key]) {
                const translation = this.translations.find((t) => t.key === key);
                if (!!translation) {
                    updated.push({
                        id: translation.id,
                        value: this.customJSON[key],
                    });
                } else {
                    inserted.push({
                        key,
                        language: i18nCode.toLowerCase(),
                        value: this.customJSON[key],
                    });
                }
            }
        });
        if (inserted.length) {
            obs.push(this.httpRest.putEntities(TRANSLATIONS_SCHEMA_ROUTE, inserted));
        }
        if (updated.length) {
            // @ts-ignore
            obs.push(this.httpRest.postEntities(TRANSLATIONS_SCHEMA_ROUTE, updated));
        }

        if (obs.length) {
            this.toastService.show({
                type: TOAST_TYPE.INFO,
                text: 'toast_refresh_translation',
                appLevel: true,
                callbackText: 'refresh',
                callback: () => {
                    // tslint:disable-next-line: deprecation
                    window.location.reload(true);
                },
            });
            forkJoin(obs).subscribe(() => {
                this.validateBtnState = ClrLoadingState.SUCCESS;
                this.form.form.markAsPristine();
                this.cdRef.markForCheck();
            });
            this.fileChange();
        } else {
            this.validateBtnState = ClrLoadingState.SUCCESS;
            this.toastService.show({
                type: TOAST_TYPE.INFO,
                text: 'toast_refresh_translation',
                appLevel: true,
                callbackText: 'refresh',
                callback: () => {
                    // tslint:disable-next-line: deprecation
                    window.location.reload(true);
                },
            });
            this.form.form.markAsPristine();
            this.cdRef.markForCheck();
        }

        const i18nIdx = this.enabledI18n.indexOf(i18nCode);
        if (this.currentFile.enabled !== (i18nIdx !== -1)) {
            if (this.currentFile.enabled) {
                this.enabledI18n.push(i18nCode);
            } else {
                this.enabledI18n.splice(i18nIdx, 1);
            }
            (async () => {
                this.configService.set({enabledI18n: this.enabledI18n});

                this.configService.configObservable.subscribe(() => {
                    this.toastService.show({
                        type: TOAST_TYPE.INFO,
                        text: 'toast_refresh_translation',
                        appLevel: true,
                        callbackText: 'refresh',
                        callback: () => {
                            // tslint:disable-next-line: deprecation
                            window.location.reload(true);
                        },
                    });
                });
            })();
        }
    }

    public filterJSON() {
        this.filteredJSON = Object.keys(this.currentJSON).filter(
            (k) =>
                (!this.customFilter || !!this.customJSON[k]) &&
                (!this.textFilter ||
                    k.indexOf(this.textFilter) !== -1 ||
                    (!!this.currentJSON[k] && this.currentJSON[k].indexOf(this.textFilter) !== -1) ||
                    (!!this.customJSON[k] && this.customJSON[k].indexOf(this.textFilter) !== -1))
        );

        this.cdRef.markForCheck();
    }

    public variableRemainCount(key: string) {
        const currentTranslation = this.currentJSON[key];
        const newTranslation = this.customJSON[key];

        const variablesNeeded = currentTranslation.match(/{{[a-zA-Z]*}}/g) || [];
        let variableRemaningCount = variablesNeeded.length;

        variablesNeeded.map((v) => {
            if (newTranslation.includes(v)) {
                variableRemaningCount -= 1;
            }
        });

        return variableRemaningCount;
    }

    public variableRemainList(key: string) {
        const currentTranslation = this.currentJSON[key];
        const newTranslation = this.customJSON[key];

        const variablesNeeded = currentTranslation.match(/{{[a-zA-Z]*}}/g) || [];
        const list: string[] = [];

        variablesNeeded.map((v) => {
            if (!newTranslation.includes(v)) {
                list.push(v);
            }
        });

        return list.join(',');
    }

    public hasVariableMissingError = () => {
        const els = document.getElementsByClassName('alert-variable');

        return els && els.length > 0;
    };
}
