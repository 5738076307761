import {ChangeDetectorRef, Component, Input} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Moment} from 'moment';
import {DECIMAL_RADIX, MilestonesType, ProjectStatusType, ReleaseStateType} from '../../../../defs/schema-static';
import {MILESTONE_SCHEMA_ROUTE} from '../../../../defs/schema/public/Milestones';
import {IProject} from '../../../../defs/schema/public/Projects';
import {ITask, TASK_SCHEMA_ROUTE} from '../../../../defs/schema/public/Tasks';
import {isColorDark} from '../app-static';
import {AuthService} from '../auth/auth.service';
import {GANTT_MODE_VALUES, GANTT_VIEW_VALUES, IGanttMilestone, IGanttProject} from '../gantt/gantt.component';
import {HttpRestService} from '../shared/http-rest/http-rest.service';
import {MomentService} from '../shared/moment/moment.service';
import {ResizeEvent} from '../shared/resizable-element/interfaces/resize-event.interface';

@Component({
    selector: 'app-gantt-projects',
    templateUrl: './gantt-projects.component.html',
    styleUrls: ['./gantt-projects.component.scss'],
})
export class GanttProjectsComponent {
    @Input()
    public projectStatusFilter: string;

    @Input()
    public clientFilter: number;

    @Input()
    public projects: IGanttProject[];

    @Input()
    public timespan: string[];

    @Input()
    public fetching: boolean;

    @Input()
    public projectColorList: string[];

    @Input()
    public msColorList: string[];

    @Input()
    public taskColorList: string[];

    @Input()
    public tdWidth: number;

    @Input()
    public MIN_WIDTH: number;

    @Input()
    public minDate: Moment;

    @Input()
    public maxDate: Moment;

    @Input()
    public currentView: GANTT_VIEW_VALUES;

    public y = 0;

    public arrowChecker = false;

    public constructor(
        private readonly httpRest: HttpRestService,
        public cdRef: ChangeDetectorRef,
        private readonly translate: TranslateService,
        public readonly authService: AuthService,
        private readonly momentService: MomentService
    ) {}

    public projectsFiltered() {
        if (!this.clientFilter && !this.projectStatusFilter) {
            return this.projects;
        }

        return this.projects;

        // return this.projects.filter((f) => {
        //     return (
        //         (this.clientFilter ? f.project.client.id === this.clientFilter : true) &&
        //         (this.projectStatusFilter ? f.project.status === this.projectStatusFilter : true)
        //     );
        // });
    }

    public goalDate: Moment;
    public resizingMS(objectMoved: IGanttMilestone, $event: ResizeEvent) {
        const event = $event;
        if (event.edges.right) {
            const c = this.convertMovementToDays(event.edges.right as number);
            this.goalDate = this.momentService
                .moment(objectMoved.milestone.target)
                .clone()
                .add(c, 'day');
        } else if (event.edges.left) {
            const c = this.convertMovementToDays(event.edges.left as number);
            this.goalDate = this.momentService
                .moment(objectMoved.milestone.beginDate)
                .clone()
                .add(c, 'day');
        }
    }

    public getReleases = (msList: IGanttMilestone[]) => {
        return msList.filter((ms) => ms.milestone.type === MilestonesType.RELEASE);
    };

    public getOtherMS = (msList: IGanttMilestone[]) => {
        return msList.filter((ms) => ms.milestone.type !== MilestonesType.RELEASE);
    };

    public popBottom() {
        return window.innerHeight / 2 > this.y;
    }

    public onResizeEndProject(objectMoved: IGanttProject, $event: ResizeEvent) {
        this.goalDate = null;
        const event = $event;
        if (event.edges.right) {
            // adjust end date accordingly
            const c = this.convertMovementToDays(event.edges.right as number);
            objectMoved.endDate.add(c, 'day');
        } else if (event.edges.left) {
            // adjust begin date accordingly
            const c = this.convertMovementToDays(event.edges.left as number);
            objectMoved.beginDate.add(c, 'day');
        }
    }

    public resizingTask(objectMoved: ITask, $event: ResizeEvent) {
        const event = $event;
        if (event.edges.right) {
            const c = this.convertMovementToDays(event.edges.right as number);

            this.goalDate = this.momentService
                .moment(objectMoved._metadata.endDate)
                .clone()
                .add(c, 'day');
        } else if (event.edges.left) {
            const c = this.convertMovementToDays(event.edges.left as number);
            this.goalDate = this.momentService
                .moment(objectMoved._metadata.beginDate)
                .clone()
                .add(c, 'day');
        }
    }

    private convertMovementToDays(nb: number): number {
        const monthDisplay = (): number => {
            return nb / 5;
        };

        const weekDisplay = (): number => {
            const coef = 140 / 7;

            return nb / coef;
        };

        const dayDisplay = (): number => {
            return nb / this.tdWidth;
        };

        if (this.currentView === GANTT_VIEW_VALUES.MONTH) {
            return monthDisplay();
        } else if (this.currentView === GANTT_VIEW_VALUES.WEEK) {
            return weekDisplay();
        } else {
            return dayDisplay();
        }
    }

    public onResizeEndTask(objectMoved: ITask, $event: ResizeEvent, project: IGanttProject, ms: IGanttMilestone) {
        const event = $event;
        this.goalDate = null;
        if (event.edges.right) {
            // adjust end date accordingly
            const c = this.convertMovementToDays(event.edges.right as number);

            objectMoved._metadata.endDate = this.momentService.moment(objectMoved._metadata.endDate).add(c, 'day');
            this.httpRest.post(TASK_SCHEMA_ROUTE, {id: objectMoved.id, endDate: objectMoved._metadata.endDate});
            project.project.tasks.map((t) => {
                if (t.id === objectMoved.id) {
                    objectMoved._metadata.endDate = objectMoved._metadata.endDate.clone();
                }
            });

            ms.tasks.map((t) => {
                if (t.id === objectMoved.id) {
                    objectMoved._metadata.endDate = objectMoved._metadata.endDate.clone();
                }
            });
        } else if (event.edges.left) {
            // adjust begin date accordingly
            const c = this.convertMovementToDays(event.edges.left as number);
            objectMoved._metadata.beginDate = this.momentService.moment(objectMoved._metadata.beginDate).add(c, 'day');
            this.httpRest.post(TASK_SCHEMA_ROUTE, {id: objectMoved.id, beginDate: objectMoved._metadata.beginDate});
            project.project.tasks.map((t) => {
                if (t.id === objectMoved.id) {
                    objectMoved._metadata.beginDate = objectMoved._metadata.beginDate.clone();
                }
            });

            ms.tasks.map((t) => {
                if (t.id === objectMoved.id) {
                    objectMoved._metadata.beginDate = objectMoved._metadata.beginDate.clone();
                }
            });
        }
        this.updateProjectDate(project);
        this.redrawArrow();
    }

    public redrawArrow() {
        this.arrowChecker = !this.arrowChecker;
    }

    public displayContent(elementId: string): boolean {
        const el = document.getElementById(elementId) as HTMLElement;
        if (!el) {
            return true;
        }

        return parseInt(el.style.width.replace('px', ''), DECIMAL_RADIX) > this.MIN_WIDTH;
    }

    public onResizeEndMS(objectMoved: IGanttMilestone, $event: ResizeEvent, project: IGanttProject) {
        this.goalDate = null;
        const event = $event;
        if (event.edges.right) {
            // adjust end date accordingly
            const c = this.convertMovementToDays(event.edges.right as number);
            if (!this.momentService.moment.isMoment(objectMoved.milestone.target)) {
                objectMoved.milestone.target = this.momentService.moment(objectMoved.milestone.target);
            }
            objectMoved.milestone.target = objectMoved.milestone.target.add(c, 'day').clone();
            this.httpRest.post(MILESTONE_SCHEMA_ROUTE, {
                id: objectMoved.milestone.id,
                target: objectMoved.milestone.target,
            });
            objectMoved.tasks.map((t) => {
                if (!t.beginDate && !t.endDate && t.targetReleaseId === objectMoved.milestone.id) {
                    t._metadata.endDate = objectMoved.milestone.target.clone();
                }
            });
            project.project.tasks.map((t) => {
                if (!t.beginDate && !t.endDate && t.targetReleaseId === objectMoved.milestone.id) {
                    t._metadata.endDate = objectMoved.milestone.target.clone();
                }
            });

            project.project.milestones.map((ms) => {
                if (ms.id === objectMoved.milestone.id) {
                    ms.target = objectMoved.milestone.target.clone();
                }
            });

            this.updateProjectDate(project);
        } else if (event.edges.left) {
            // adjust begin date accordingly
            const c = this.convertMovementToDays(event.edges.left as number);
            if (!this.momentService.moment.isMoment(objectMoved.milestone.beginDate)) {
                objectMoved.milestone.beginDate = this.momentService.moment(objectMoved.milestone.beginDate);
            }
            objectMoved.milestone.beginDate = objectMoved.milestone.beginDate.add(c, 'day');
            this.httpRest.post(MILESTONE_SCHEMA_ROUTE, {
                id: objectMoved.milestone.id,
                beginDate: objectMoved.milestone.beginDate,
            });
            objectMoved.tasks.map((t) => {
                if (!t.beginDate && !t.endDate && t.targetReleaseId === objectMoved.milestone.id) {
                    t._metadata.beginDate = objectMoved.milestone.beginDate.clone();
                }
            });
            project.project.tasks.map((t) => {
                if (!t.beginDate && !t.endDate && t.targetReleaseId === objectMoved.milestone.id) {
                    t._metadata.beginDate = objectMoved.milestone.beginDate.clone();
                }
            });
            const projectMs = project.project.milestones.find((ms) => ms.id === objectMoved.milestone.id);
            if (projectMs) {
                projectMs.beginDate = objectMoved.milestone.beginDate.clone();
            }
            this.updateProjectDate(project);
        }

        this.redrawArrow();
    }

    public convertIntervalToWidth(beginDate: Moment, endDate: Moment): number {
        const monthDisplay = (): number => {
            const duration = endDate.diff(beginDate, 'days');

            return duration * 5;
        };

        const weekDisplay = (): number => {
            const duration = endDate
                .clone()
                .startOf('isoWeek')
                .diff(beginDate.clone().startOf('isoWeek'), 'week');
            const dayLeftover =
                Math.abs(
                    endDate
                        .clone()
                        .startOf('isoWeek')
                        .diff(endDate, 'day')
                ) || 0;
            const coef = 140 / 7;

            return duration * this.tdWidth + dayLeftover * coef;
        };

        const dayDisplay = (): number => {
            const duration = endDate.diff(beginDate, 'days');

            return Math.max(duration * this.tdWidth, this.tdWidth / 7);
        };

        if (!(endDate && beginDate)) {
            return undefined;
        }

        if (!this.momentService.moment.isMoment(endDate)) {
            endDate = this.momentService.moment(endDate);
        }

        if (!this.momentService.moment.isMoment(beginDate)) {
            beginDate = this.momentService.moment(beginDate);
        }

        if (this.currentView === GANTT_VIEW_VALUES.MONTH) {
            return monthDisplay();
        } else if (this.currentView === GANTT_VIEW_VALUES.WEEK) {
            return weekDisplay();
        } else {
            return dayDisplay();
        }
    }

    public startProjectToLeftPosition(beginDate: Moment) {
        if (!this.minDate) {
            return 1;
        }
        const monthDisplay = (): number => {
            const beginMonth = Math.abs(
                this.minDate
                    .clone()
                    .startOf('month')
                    .diff(beginDate.clone().startOf('month'), 'month')
            );

            const days =
                Math.abs(
                    beginDate
                        .clone()
                        .startOf('month')
                        .diff(beginDate, 'day')
                ) || 0;
            const coef = 140 / 30;

            return beginMonth * this.tdWidth + days * coef;
        };

        const weekDisplay = (): number => {
            const weekDiff = this.minDate
                .clone()
                .startOf('isoWeek')
                .diff(beginDate.clone().startOf('isoWeek'), 'week');
            const dayLeftover =
                Math.abs(
                    beginDate
                        .clone()
                        .startOf('isoWeek')
                        .diff(beginDate, 'day')
                ) || 0;
            const coef = 140 / 7;

            return Math.abs(weekDiff * this.tdWidth) + coef * dayLeftover;
        };

        const dayDisplay = (): number => {
            const dayDiff = this.minDate.diff(beginDate, 'day');

            return Math.abs(dayDiff * this.tdWidth);
        };

        if (!beginDate) {
            return undefined;
        }

        if (!this.momentService.moment.isMoment(beginDate)) {
            beginDate = this.momentService.moment(beginDate);
        }

        if (this.currentView === GANTT_VIEW_VALUES.MONTH) {
            return monthDisplay();
        } else if (this.currentView === GANTT_VIEW_VALUES.WEEK) {
            return weekDisplay();
        } else {
            return dayDisplay();
        }
    }

    private getExtremeDates() {
        if (!this.projects) {
            return;
        }

        this.projects.map((gproject) => {
            if (!gproject.beginDate && !gproject.endDate) {
                return;
            }

            if (!this.minDate) {
                this.minDate = gproject.beginDate.clone();
                this.maxDate = gproject.beginDate.clone();
            }
            if (this.minDate.isAfter(gproject.beginDate)) {
                this.minDate = gproject.beginDate.clone();
            }

            if (this.maxDate.isBefore(gproject.endDate)) {
                this.maxDate = gproject.endDate.clone();
            }

            gproject.project.milestones.map((ms) => {
                if (this.minDate.isAfter(this.momentService.moment(ms.beginDate))) {
                    this.minDate = this.momentService.moment(ms.beginDate).clone();
                }

                if (this.maxDate.isBefore(this.momentService.moment(ms.endDate))) {
                    this.maxDate = this.momentService.moment(ms.endDate).clone();
                }
            });
        });

        this.minDate.add(-1, 'month');
        this.maxDate.add(1, 'month');

        this.fetching = false;
    }

    private updateProjectDate(project: IGanttProject) {
        project.beginDate = this.getMinDateProject(project.project);
        project.endDate = this.getMaxDateProject(project.project);
    }

    private readonly getMaxDateProject = (project: IProject) => {
        if (!project.tasks || !project.tasks[0]) {
            return this.momentService.moment();
        }

        let max = this.momentService.moment(project.tasks[0]._metadata.endDate);
        project.tasks.map((t) => {
            if (this.momentService.moment(t._metadata.endDate).isAfter(max)) {
                max = this.momentService.moment(t._metadata.endDate);
            }
        });

        project.milestones.map((ms) => {
            if (this.momentService.moment(ms.target).isAfter(max)) {
                max = this.momentService.moment(ms.target);
            }
        });

        return this.momentService.moment(max);
    };

    private readonly getMinDateProject = (project: IProject) => {
        if (!project.tasks || !project.tasks[0]) {
            return this.momentService.moment();
        }
        let min = this.momentService.moment(project.tasks[0]._metadata.beginDate);

        project.tasks.map((t) => {
            if (this.momentService.moment(t._metadata.beginDate).isBefore(min)) {
                min = this.momentService.moment(t._metadata.beginDate);
            }
        });

        project.milestones.map((ms) => {
            if (ms.type !== MilestonesType.RELEASE) {
                return;
            }
            if (this.momentService.moment(ms.beginDate).isBefore(min)) {
                min = this.momentService.moment(ms.beginDate);
            }
        });

        return this.momentService.moment(min);
    };

    public isFirefox = () => {
        return navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    };

    public getDuration = (target: Moment, beginDate: Moment) => {
        this.momentService.moment(target).diff(this.momentService.moment(beginDate), 'day');
    };

    public readonly isColorDark = isColorDark;
    public readonly GANTT_VIEW_VALUES = GANTT_VIEW_VALUES;
    public readonly GANTT_MODE_VALUES = GANTT_MODE_VALUES;

    public readonly MilestonesType = MilestonesType;
    public readonly ReleaseStateType = ReleaseStateType;
    public readonly PROJECT_STATUS_TYPE = Object.values(ProjectStatusType);
}
