import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {API_HTTP_ROOT, API_VERSION} from '../../app-static';
import {AuthService} from '../../auth/auth.service';
import {VersionInterceptor} from '../../version/version.interceptor';
import {FileManagerService} from '../file-manager/file-manager.service';

@Injectable({
    providedIn: 'root',
})
export class FileDownloaderService {
    public constructor(
        private readonly authService: AuthService,
        private readonly httpClient: HttpClient,
        private readonly fileManager: FileManagerService
    ) {}

    public async downloadAPI(uri: string) {
        if (!uri.startsWith(API_HTTP_ROOT)) {
            uri = [API_HTTP_ROOT, API_VERSION, uri].join('/');
        }

        uri = `${uri}?v=${VersionInterceptor.WWW_VERSION}`;

        const {bewit} = await this.httpClient
            .get<{bewit: string}>([API_HTTP_ROOT, API_VERSION, 'sign', encodeURIComponent(uri)].join('/'))
            .toPromise();

        FileDownloaderService.openInNewTab(`${uri}&bewit=${bewit}`);
    }

    public async downloadS3(file: {path: string; version?: string}, attachment = false) {
        const [signedFile] = await this.fileManager.signFiles(file);
        const {getObject, getObjectAttachment} = signedFile.signed;

        FileDownloaderService.openInNewTab(attachment ? getObjectAttachment : getObject);
    }

    public static openInNewTab(url: string) {
        window.open(url, '_blank');
    }
}
