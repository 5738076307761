<div class="spinner-bg-c" *ngIf="!project"><div class="spinner-c"></div></div>
<div class="column-container" *ngIf="project && project.id">
    <div class="clr-row">
        <div class="clr-col-6">
            <ul class="breadcrumb" style="font-size: .7rem;">
                <li class="breadcrumb-item"><a [routerLink]="['/projects']" translate>projects</a></li>
                <li class="breadcrumb-item" *ngIf="project">
                    {{ project.obs }}
                    <app-long-avatar
                        [type]="LONG_AVATAR_TYPE.BADGE_100"
                        [code]="project.code"
                        [bgColor]="project.color"
                    ></app-long-avatar>
                </li>
            </ul>
        </div>
        <div class="clr-col-6 text-right">
            <button class="btn btn-primary" *ngIf="authService.isManagerOf(project.id)" (click)="showEditModal = true">
                {{ 'edit_project_info' | translate }}
            </button>
        </div>
    </div>

    <div class="clr-row">
        <div class="clr-col-12">
            <table class="info-table mb-07">
                <tbody>
                    <tr>
                        <td>
                            <app-long-avatar
                                [code]="project.code"
                                [bgColor]="project.color"
                                style="font-size: 2em; line-height: 2em"
                            ></app-long-avatar>
                        </td>
                        <td>
                            <h3 class="mt-0">
                                <strong>{{ project.obs }}</strong>
                            </h3>
                            <div class="mt-2" [attr.class]="PROJECT_STATUS_FILTER[project.status].classList">
                                <clr-icon
                                    style="margin-top: -3px;"
                                    [attr.shape]="PROJECT_STATUS_FILTER[project.status].icon"
                                ></clr-icon>
                                {{ PROJECT_STATUS_FILTER[project.status].translation | translate }}
                            </div>
                        </td>
                        <td *ngIf="project.client && project.client.user">
                            <p class="small-label" translate>client</p>

                            <a [routerLink]="['/client', project.clientId]">
                                {{ project.client.user.name }}

                                <app-long-avatar
                                    [type]="LONG_AVATAR_TYPE.BADGE_100"
                                    [code]="project.client.user.code"
                                    [bgColor]="project.client.user.color"
                                ></app-long-avatar>
                            </a>
                        </td>
                        <td>
                            <div
                                class="mt-02"
                                *ngIf="project.projectMembers && project.projectMembers.length > 1; else memberList"
                            >
                                {{ 'team_members_count' | translate: {count: project.projectMembers.length} }}
                                <clr-signpost>
                                    <clr-signpost-content [clrPosition]="'bottom-right'" *clrIfOpen>
                                        <h3 class="mt-0" translate>team_members</h3>
                                        <ul class="list mt-05">
                                            <ng-container *ngIf="false; else memberList"></ng-container>
                                        </ul>
                                    </clr-signpost-content>
                                </clr-signpost>
                            </div>

                            <ng-template #memberList>
                                <p class="small-label" translate *ngIf="project.projectMembers?.length === 1">
                                    team_member
                                </p>

                                <li *ngFor="let member of project.projectMembers">
                                    <ng-container *ngIf="member.employee && member.employee.user">
                                        <a [routerLink]="['/employee', member.employee.id]">{{
                                            member.employee.user.name
                                        }}</a>
                                        <app-long-avatar
                                            class="ml-02"
                                            [type]="LONG_AVATAR_TYPE.BADGE_100"
                                            [code]="member.employee.user.code"
                                            [bgColor]="member.employee.user.color"
                                        ></app-long-avatar>
                                        <clr-icon
                                            class="mr-02 is-solid"
                                            shape="star"
                                            [class.d-invisible]="member.type !== ProjectMemberType.MANAGER"
                                        ></clr-icon>
                                    </ng-container>
                                </li>
                            </ng-template>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="clr-row flex-1">
        <app-tabs class="w-100" queryParamKey="tab" [labels]="PROJECT_TABS_LABELS">
            <app-tab
                [name]="PROJECT_TABS.MILESTONES"
                [label]="PROJECT_TABS.MILESTONES | translate"
                *ngIf="
                    authService.hasRight(PROJECT_TABS_RIGHTS[PROJECT_TABS.MILESTONES]) ||
                    authService.isManagerOf(project.id)
                "
            >
                <ng-template appTabContent>
                    <div class="column-container py-02">
                        <div class="clr-row flex-1">
                            <app-milestones-list
                                class="column-container w-100"
                                [project]="project"
                                [milestones]="project.milestones"
                            ></app-milestones-list>
                        </div>
                    </div>
                </ng-template>
            </app-tab>
            <app-tab
                [name]="PROJECT_TABS.BUDGETS"
                [label]="PROJECT_TABS.BUDGETS | translate"
                *ngIf="
                    authService.hasRight(PROJECT_TABS_RIGHTS[PROJECT_TABS.BUDGETS]) ||
                    authService.isManagerOf(project.id)
                "
            >
                <ng-template appTabContent>
                    <app-project-budgets [project]="project"></app-project-budgets>
                </ng-template>
            </app-tab>
            <app-tab
                [name]="PROJECT_TABS.TASKS"
                [label]="PROJECT_TABS.TASKS | translate"
                *ngIf="
                    authService.hasRight(PROJECT_TABS_RIGHTS[PROJECT_TABS.TASKS]) || authService.isManagerOf(project.id)
                "
            >
                <ng-template appTabContent> <app-project-tasks [project]="project"></app-project-tasks> </ng-template>
            </app-tab>
            <app-tab
                [name]="PROJECT_TABS.RELEASES"
                [label]="PROJECT_TABS.RELEASES | translate"
                *ngIf="
                    authService.hasRight(PROJECT_TABS_RIGHTS[PROJECT_TABS.RELEASES]) ||
                    authService.isManagerOf(project.id)
                "
            >
                <ng-template appTabContent>
                    <app-project-releases [project]="project"></app-project-releases>
                </ng-template>
            </app-tab>
            <ng-container
                *ngIf="
                    authService.hasRight(PROJECT_TABS_RIGHTS[PROJECT_TABS.BACKUPS]) ||
                    authService.isManagerOf(project.id)
                "
            >
                <app-tab
                    [name]="PROJECT_TABS.BACKUPS"
                    [label]="PROJECT_TABS.BACKUPS | translate"
                    [disabled]="!!(project.id && !project.backupPrefix)"
                    *ngIf="publicConfig.appParams.module_backups"
                >
                    <ng-template appTabContent> <app-backups-list [project]="project"></app-backups-list> </ng-template>
                </app-tab>
            </ng-container>
            <app-tab
                [name]="PROJECT_TABS.PARAMS"
                [label]="PROJECT_TABS.PARAMS | translate"
                *ngIf="
                    authService.hasRight(PROJECT_TABS_RIGHTS[PROJECT_TABS.PARAMS]) ||
                    authService.isManagerOf(project.id)
                "
            >
                <ng-template appTabContent>
                    <app-project-params [project]="project" [employees]="employees"></app-project-params>
                </ng-template>
            </app-tab>

            <ng-container
                *ngIf="authService.hasRight(RIGHTS.PROJECT_STICKY_READ) || authService.isManagerOf(project.id)"
            >
                <app-tab [name]="PROJECT_TABS.NOTES" [label]="PROJECT_TABS.NOTES | translate">
                    <ng-template appTabContent>
                        <app-sticky-notes
                            [stickyNotes]="stickyNotes"
                            [projectId]="project?.id"
                            [editAllowed]="
                                authService.hasRight(RIGHTS.PROJECT_STICKY_UPDATE) ||
                                authService.isManagerOf(project.id)
                            "
                        ></app-sticky-notes>
                    </ng-template>
                </app-tab>
            </ng-container>
        </app-tabs>
    </div>
</div>

<app-modal [(show)]="showEditModal" [title]="'project' | translate" [appModalSize]="'lg'">
    <ng-template appModalBody>
        <app-project-wizard-project [project]="project" [formLayout]="'vertical'"></app-project-wizard-project>
    </ng-template>
    <ng-template appModalFooter>
        <div class="mr-a">
            <button class="btn btn-danger" *sharedAccessControl="RIGHTS.PJ_DELETE" (click)="deleteProjectModal()">
                {{ 'delete_project' | translate }}
            </button>
        </div>
        <div>
            <button class="btn btn-secondary" (click)="showEditModal = false">{{ 'form_cancel' | translate }}</button>
            <button
                class="btn btn-success"
                (click)="saveProject()"
                [disabled]="!editComponent?.form.valid"
                [clrLoading]="validateBtnState"
            >
                {{ 'save_project' | translate }}
            </button>
        </div>
    </ng-template>
</app-modal>
