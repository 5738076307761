import moment from 'moment';
import {IncidentStatus, ISchemaRoute} from '../../schema-static';
import {IClient} from './Clients';
import {IEmployee} from './Employees';
import {IProject} from './Projects';

export enum INCIDENT_FIELD {
    id = 'id',
    clientId = 'clientId',
    title = 'title',
    description = 'description',
    status = 'status',
    createdAt = 'createdAt',
    updatedAt = 'updatedAt',
    projectId = 'projectId',
    employeeId = 'employeeId',
}

export interface IIncidentBare {
    id?: number;
    clientId: number;
    title?: string;
    description?: string;
    status: IncidentStatus;
    createdAt?: moment.Moment;
    updatedAt?: moment.Moment;
    projectId: number;
    employeeId: number;
}

export interface IIncidentBelong {
    employee: Partial<IEmployee>;
    client: Partial<IClient>;
    project: Partial<IProject>;
}

export interface IIncident extends IIncidentBare, IIncidentBelong {}

const INCIDENT_SCHEMA_ROUTE: ISchemaRoute = {
    tableName: 'incidents',
    singularRoute: 'incident',
    pluralRoute: 'incidents',
};

export {INCIDENT_SCHEMA_ROUTE};
