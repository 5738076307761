import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {ClrLoadingState} from '@clr/angular';
import {NgSelectComponent} from '@ng-select/ng-select';
import {TranslateService} from '@ngx-translate/core';
import {forkJoin, noop} from 'rxjs';
import {
    ApiRoutePlurality,
    HTTP_METHOD,
    MAX_LENGTH_COGNITO_CUSTOM_GROUP_NAME,
    MAX_LENGTH_COGNITO_GROUP_DESCRIPTION,
    RightCategory,
} from '../../../../../defs/schema-static';
import {COGNITO_SCHEMA_ROUTE, ICognitoGroup} from '../../../../../defs/schema/public/Cognito';
import {GROUP_RIGHTS_SCHEMA_ROUTE, IGroupRights} from '../../../../../defs/schema/public/GroupRights';
import {IRight, RIGHT_SCHEMA_ROUTE} from '../../../../../defs/schema/public/Rights';
import {IUserExtended} from '../../../../../defs/schema/public/Users';
import {getGroupDisplayName, isColorDark} from '../../app-static';
import {AuthService} from '../../auth/auth.service';
import {ModalSimpleComponent} from '../../modal-simple/modal-simple.component';
import {ModalSimpleService} from '../../modal-simple/modal-simple.service';
import {HttpRestService} from '../../shared/http-rest/http-rest.service';
import {TOAST_TYPE, ToastService} from '../../shared/toast/toast.service';

export enum ACCESS_CONTROL_LEVELS {
    GROUPS = 'GROUPS',
    USERS = 'USERS',
}

interface IRightExtended extends IRight {
    active?: boolean;
    accessible?: boolean;
    tip?: string;
    highlight?: boolean;
}

interface IItem {
    cognitoId: string;
    displayName: string;
    level: ACCESS_CONTROL_LEVELS;
}

interface ICategoryRights {
    name: string;
    children: IRightExtended[];
    expanded: boolean;
}

interface IGroupEdit {
    groupName: string;
    description: string;
    isNew: boolean;
    copyFrom?: string;
    invalid?: boolean;
}

const createRightTree = (rights: IRight[]) => {
    const categories: ICategoryRights[] = Object.values(RightCategory).map((cat) => ({
        name: cat,
        children: [],
        expanded: true,
    }));

    rights.map((r) => {
        const idx = categories.map((cat) => cat.name).indexOf(r.category);
        if (idx !== -1) {
            categories[idx].children.push(r);
        }
    });

    categories.map((cat) => {
        cat.children = cat.children.sort((a, b) => {
            if (a.dependency === b.dependency) {
                return a.code.localeCompare(b.code);
            }

            if (!a.dependency) {
                return -1;
            } else if (!b.dependency) {
                return 1;
            }

            return a.dependency - b.dependency;
        });
    });

    return categories;
};

@Component({
    selector: 'app-admin-access',
    templateUrl: './admin-access.component.html',
    styleUrls: ['./admin-access.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminAccessComponent implements OnInit {
    public groups: ICognitoGroup[] = [];
    public users: IUserExtended[] = [];

    public rights: IRightExtended[] = [];
    public groupRights: IGroupRights[] = [];
    public treeRights: ICategoryRights[] = [];

    public selectedLevel: ACCESS_CONTROL_LEVELS = ACCESS_CONTROL_LEVELS.GROUPS;

    public selectItems: IItem[] = [];
    public selectedItem: IItem;
    public selectedGroup: ICognitoGroup;
    public selectedUser: IUserExtended;

    public dependsOnStr: string;

    public selectUserGroups: ICognitoGroup[] = [];
    public initialSelectUserGroups: ICognitoGroup[] = [];
    public selectUserGroup: string;
    public selectUserGroupsChanged = false;
    public saveUserGroupsBtnState: ClrLoadingState = ClrLoadingState.DEFAULT;

    public deleteGroupBtnState = ClrLoadingState.DEFAULT;
    public showEditModal = false;
    public editGroupBtnState = ClrLoadingState.DEFAULT;
    public showConfirmSudokuModal = false;

    public currentGroupEdit: IGroupEdit;

    @ViewChild('itemsNgSelect')
    public itemsSelect: NgSelectComponent;

    public constructor(
        private readonly httpRest: HttpRestService,
        private readonly toastService: ToastService,
        private readonly translate: TranslateService,
        private readonly cdRef: ChangeDetectorRef,
        private readonly authService: AuthService,
        private readonly modalSimpleService: ModalSimpleService
    ) {
        this.selectUserGroup = null;
    }

    public ngOnInit() {
        forkJoin(this.getGroupList(), this.getUsersList(), this.getRightsList()).subscribe(() => {
            this.itemsSelect.focus();
            this.itemsSelect.open();
        });
        this.translate.get('depends_on').subscribe((translation) => {
            this.dependsOnStr = translation;
        });
    }

    public getGroupList() {
        const obs = this.httpRest._request<ICognitoGroup[]>(
            HTTP_METHOD.GET,
            ApiRoutePlurality.SINGULAR,
            COGNITO_SCHEMA_ROUTE,
            'groups'
        );
        obs.subscribe((groups) => {
            this.groups = groups.map((g) => {
                if (!!g.description) {
                    g.description = g.description.trim();
                }

                return g;
            });

            this.selectItems = this.selectItems.concat(
                this.groups
                    .filter((g) => g.groupName !== 'admin')
                    .map((g) => ({
                        cognitoId: g.groupName,
                        displayName: getGroupDisplayName(g.groupName),
                        level: ACCESS_CONTROL_LEVELS.GROUPS,
                    }))
            );

            this.cdRef.markForCheck();
        });

        return obs;
    }

    public allExpanded = true;
    public expandAll() {
        this.allExpanded = !this.allExpanded;
        this.treeRights.map((tr) => (tr.expanded = this.allExpanded));
    }
    public getUsersList() {
        const obs = this.httpRest._request<IUserExtended[]>(
            HTTP_METHOD.GET,
            ApiRoutePlurality.SINGULAR,
            COGNITO_SCHEMA_ROUTE,
            'users'
        );
        obs.subscribe((users) => {
            this.selectItems = this.selectItems.concat(
                (this.users = users.map((u) => ({
                    cognitoId: u.cognitoUsername,
                    cognitoUsername: u.cognitoUsername,
                    displayName: u.name,
                    level: ACCESS_CONTROL_LEVELS.USERS,
                    user: {
                        code: u.code,
                        color: u.color,
                    },
                    groups: u.groups.map((g) => {
                        g.displayName = getGroupDisplayName(g.groupName);

                        return {...g, cognitoId: g.groupName};
                    }),
                })))
            );

            this.cdRef.markForCheck();
        });

        return obs;
    }

    public getRightsList() {
        const obs = this.httpRest._request<IRight[]>(
            HTTP_METHOD.GET,
            ApiRoutePlurality.PLURAL,
            RIGHT_SCHEMA_ROUTE,
            'list'
        );
        obs.subscribe((rights) => {
            this.rights = rights;
            this.treeRights = createRightTree(rights);
        });

        return obs;
    }

    public selectItem(item: IItem) {
        this.selectedUser = null;
        this.selectedGroup = null;
        if (!item) {
            this.selectedLevel = null;

            return;
        }
        this.selectedLevel = item.level;
        if (this.selectedLevel === ACCESS_CONTROL_LEVELS.USERS) {
            this.selectedUser = this.users.find((u) => u.cognitoUsername === item.cognitoId);
            this.setGroupSelectMenu();
        } else if (this.selectedLevel === ACCESS_CONTROL_LEVELS.GROUPS) {
            this.selectedGroup = this.groups.find((g) => g.groupName === item.cognitoId);
            this.getGroupRightsList();
        }
    }

    public getAccessibility(right: IRightExtended) {
        delete right.tip;
        if (!right.dependency) {
            return true;
        }
        const dependency = this.rights.find((r) => r.id === right.dependency);
        if (dependency && !dependency.active) {
            right.tip = `${this.dependsOnStr} ${dependency.code}`;

            return false;
        }

        return true;
    }

    public resetRightsList() {
        this.rights.map((r) => {
            r.active = false;
            r.accessible = this.getAccessibility(r);
        });
        this.rights = [...this.rights];
        this.cdRef.markForCheck();
    }

    public getGroupRightsList() {
        this.resetRightsList();
        this.httpRest
            ._request<IGroupRights[]>(
                HTTP_METHOD.GET,
                ApiRoutePlurality.PLURAL,
                GROUP_RIGHTS_SCHEMA_ROUTE,
                `${this.selectedGroup.groupName}`
            )
            .subscribe((groupRights) => {
                this.groupRights = groupRights;
                if (this.groupRights.length > 0 && this.groupRights[0].groupName === this.selectedGroup.groupName) {
                    this.groupRights.map((gr) => {
                        const right = this.rights.find((r) => r.id === gr.rightId);
                        if (right) {
                            right.active = true;
                        }
                    });
                }
                this.rights.map((right) => {
                    right.accessible = this.getAccessibility(right);
                });
                this.rights = [...this.rights];
                this.cdRef.markForCheck();
            });
    }

    public resolveAccessibility(right: IRightExtended) {
        this.rights
            .filter((r) => r.dependency === right.id)
            .map((r) => {
                r.accessible = right.active;
                if (!right.active) {
                    r.active = false;

                    const groupRightIdx = this.groupRights.findIndex(
                        (f) => f.groupName === this.selectedGroup.groupName && f.rightId === r.id
                    );
                    if (groupRightIdx !== -1) {
                        this.httpRest
                            .deleteId(GROUP_RIGHTS_SCHEMA_ROUTE, this.groupRights[groupRightIdx].id)
                            .subscribe(() => {
                                this.groupRights.splice(groupRightIdx, 1);

                                this.cdRef.markForCheck();
                            });
                    }
                }
            });
        this.rights = [...this.rights];
        this.cdRef.markForCheck();
    }

    public switchRight(right: IRightExtended) {
        this.resolveAccessibility(right);
        if (right.active) {
            this.httpRest
                .put<IGroupRights>(GROUP_RIGHTS_SCHEMA_ROUTE, {
                    rightId: right.id,
                    groupName: this.selectedGroup.groupName,
                })
                .subscribe((groupRight) => {
                    this.groupRights.push(groupRight);

                    this.cdRef.markForCheck();
                });
        } else {
            const groupRightIdx = this.groupRights.findIndex(
                (f) => f.groupName === this.selectedGroup.groupName && f.rightId === right.id
            );
            if (groupRightIdx !== -1) {
                this.httpRest.deleteId(GROUP_RIGHTS_SCHEMA_ROUTE, this.groupRights[groupRightIdx].id).subscribe(() => {
                    this.groupRights.splice(groupRightIdx, 1);

                    this.cdRef.markForCheck();
                });
            }
        }
    }

    public deleteGroupModal() {
        this.modalSimpleService
            .open(ModalSimpleComponent, {
                title: 'group_delete_modal_title',
                contentI18n: 'swal_delete_attch',
                contentObj: {what: this.selectedItem.displayName},
                ok: {
                    i18n: 'delete_group',
                    class: 'btn-danger',
                },
            })
            .subscribe((closed) => {
                if (closed.result) {
                    this.deleteSelectedGroup();
                }
            }, noop);
    }

    public deleteSelectedGroup() {
        if (!this.selectedGroup) {
            return;
        }

        const groupName = this.selectedGroup.groupName;

        if (!groupName) {
            return;
        }

        this.httpRest
            ._request<ICognitoGroup>(
                HTTP_METHOD.DELETE,
                ApiRoutePlurality.SINGULAR,
                COGNITO_SCHEMA_ROUTE,
                `group/${groupName}`
            )
            .subscribe((el) => {
                const groupIdx = this.groups.findIndex((g) => g.groupName === el.groupName);
                this.groups.splice(groupIdx, 1);

                const itemIdx = this.selectItems.findIndex((i) => i.cognitoId === el.groupName);
                this.selectItems.splice(itemIdx, 1);
                this.selectItems = [...this.selectItems];

                this.users.map((user: IUserExtended) => {
                    const userGroupIdx = user.groups.findIndex((g) => g.groupName === el.groupName);
                    if (userGroupIdx !== -1) {
                        user.groups.splice(userGroupIdx, 1);
                    }
                });

                this.selectedItem = null;
                this.selectedGroup = null;
                this.cdRef.markForCheck();
            });
    }

    public setGroupSelectMenu() {
        this.selectUserGroupsChanged = false;
        const user = this.users.find((u) => u.cognitoUsername === this.selectedUser.cognitoUsername);
        if (!user) {
            this.selectUserGroups = [];
            this.initialSelectUserGroups = [];

            return;
        }

        const groupNames = (user.groups || []).map(({groupName}) => groupName);
        this.selectUserGroups = this.groups.filter(({groupName}) => groupNames.includes(groupName));
        this.initialSelectUserGroups = [...this.selectUserGroups];

        this.cdRef.detectChanges();
    }

    public checkSelectUserGroupsChanged() {
        const [selectedSerialized, initialSerialized] = [this.selectUserGroups, this.initialSelectUserGroups].map(
            (groups) => groups.map(({groupName}) => groupName).sort()
        );

        this.selectUserGroupsChanged = selectedSerialized.toString() !== initialSerialized.toString();
    }

    public saveUserGroups() {
        this.saveUserGroupsBtnState = ClrLoadingState.LOADING;

        const [selectedGroupNames, initialGroupNames] = [this.selectUserGroups, this.initialSelectUserGroups].map(
            (groups) => groups.map(({groupName}) => groupName)
        );

        const groupsToAdd = this.selectUserGroups.filter(({groupName}) => !initialGroupNames.includes(groupName));
        const groupsToDelete = this.initialSelectUserGroups.filter(
            ({groupName}) => !selectedGroupNames.includes(groupName)
        );

        forkJoin([
            ...groupsToAdd.map(({groupName}) => this.addGroupToUser(groupName)),
            ...groupsToDelete.map(({groupName}) => this.removeGroupFromUser(groupName)),
        ]).subscribe(
            () => {
                this.initialSelectUserGroups = [...this.selectUserGroups];
                this.selectUserGroupsChanged = false;
                this.saveUserGroupsBtnState = ClrLoadingState.DEFAULT;

                this.cdRef.markForCheck();
            },
            () => {
                this.saveUserGroupsBtnState = ClrLoadingState.DEFAULT;

                this.cdRef.markForCheck();
            }
        );

        if (this.showConfirmSudokuModal) {
            this.showConfirmSudokuModal = false;
            this.cdRef.markForCheck();
        }
    }

    public addGroupToUser(groupName: string) {
        return this.httpRest._request<ICognitoGroup>(
            HTTP_METHOD.PUT,
            ApiRoutePlurality.SINGULAR,
            COGNITO_SCHEMA_ROUTE,
            'user',
            {
                username: this.selectedUser.cognitoUsername,
                groupName,
            }
        );
    }

    public removeGroupFromUser(groupName: string) {
        if (groupName === 'admin') {
            const admins = this.users.filter(
                (u) =>
                    u.cognitoUsername !== this.selectedUser.cognitoUsername &&
                    u.groups.find((g) => g.groupName === groupName)
            ).length;

            if (!admins) {
                this.toastService.show({
                    type: TOAST_TYPE.ERROR,
                    text: 'cognito_minimum_admin',
                });

                return undefined;
            }
        }

        return this.httpRest._request<ICognitoGroup>(
            HTTP_METHOD.DELETE,
            ApiRoutePlurality.SINGULAR,
            COGNITO_SCHEMA_ROUTE,
            `user/${this.selectedUser.cognitoUsername}/${groupName}`
        );
    }

    public addGroup() {
        this.currentGroupEdit = {
            groupName: '',
            description: '',
            isNew: true,
            invalid: true,
        };
        this.showEditModal = true;
    }

    public editGroup() {
        this.currentGroupEdit = {
            groupName: this.selectedItem.displayName,
            description: this.selectedGroup.description,
            isNew: false,
        };
        this.showEditModal = true;
    }

    public duplicateGroup() {
        this.currentGroupEdit = {
            groupName: this.selectedItem.displayName,
            description: this.selectedGroup.description,
            isNew: true,
            copyFrom: this.selectedGroup.groupName,
            invalid: true,
        };
        this.showEditModal = true;
    }

    public isValid() {
        this.currentGroupEdit.invalid = !!(
            !this.currentGroupEdit.groupName ||
            this.currentGroupEdit.groupName.indexOf(' ') !== -1 ||
            (this.currentGroupEdit.copyFrom &&
                getGroupDisplayName(this.currentGroupEdit.copyFrom) === this.currentGroupEdit.groupName)
        );
    }

    public saveGroup() {
        this.currentGroupEdit.description = (this.currentGroupEdit.description || '').trim();

        this.editGroupBtnState = ClrLoadingState.LOADING;
        if (this.currentGroupEdit.isNew) {
            const from = this.currentGroupEdit.copyFrom;

            this.httpRest
                ._request<ICognitoGroup>(
                    HTTP_METHOD.PUT,
                    ApiRoutePlurality.SINGULAR,
                    COGNITO_SCHEMA_ROUTE,
                    'group',
                    this.currentGroupEdit
                )
                .subscribe(
                    (el: ICognitoGroup) => {
                        if (from !== '') {
                            this.httpRest
                                ._request<IGroupRights>(
                                    HTTP_METHOD.POST,
                                    ApiRoutePlurality.PLURAL,
                                    GROUP_RIGHTS_SCHEMA_ROUTE,
                                    'copy',
                                    {
                                        from,
                                        to: `_${this.currentGroupEdit.groupName}`,
                                    }
                                )
                                .subscribe(
                                    () => {
                                        this.groups.push(el);
                                        this.selectItems.push({
                                            cognitoId: el.groupName,
                                            displayName: getGroupDisplayName(el.groupName),
                                            level: ACCESS_CONTROL_LEVELS.GROUPS,
                                        });
                                        this.selectItems = [...this.selectItems];

                                        this.selectedItem = this.selectItems[this.selectItems.length - 1];
                                        this.selectItem(this.selectedItem);
                                        this.showEditModal = false;

                                        this.editGroupBtnState = ClrLoadingState.SUCCESS;
                                        this.cdRef.markForCheck();
                                    },
                                    () => (this.editGroupBtnState = ClrLoadingState.ERROR)
                                );
                        } else {
                            this.groups.push(el);
                            this.selectItems.push({
                                cognitoId: el.groupName,
                                displayName: getGroupDisplayName(el.groupName),
                                level: ACCESS_CONTROL_LEVELS.GROUPS,
                            });
                            this.selectItems = [...this.selectItems];

                            this.selectedItem = this.selectItems[this.selectItems.length - 1];
                            this.selectItem(this.selectedItem);
                            this.showEditModal = false;

                            this.editGroupBtnState = ClrLoadingState.SUCCESS;
                            this.cdRef.markForCheck();
                        }
                    },
                    (err) => {
                        if (err && ['GroupExistsException'].includes(err.error.err)) {
                            this.toastService.show({
                                type: TOAST_TYPE.ERROR,
                                text: 'group_name_exists',
                            });
                        }
                        if (err && ['InvalidParameterException'].includes(err.error.err)) {
                            this.toastService.show({
                                type: TOAST_TYPE.ERROR,
                                text: 'group_name_no_spaces',
                            });
                        }

                        this.editGroupBtnState = ClrLoadingState.ERROR;
                    }
                );
        } else {
            this.httpRest
                ._request<ICognitoGroup>(
                    HTTP_METHOD.POST,
                    ApiRoutePlurality.SINGULAR,
                    COGNITO_SCHEMA_ROUTE,
                    'group',
                    this.currentGroupEdit
                )
                .subscribe(
                    (el) => {
                        el.description = el.description.trim();
                        this.groups.find((m) => m.groupName === el.groupName).description = el.description;
                        this.selectedGroup.description = el.description;
                        this.showEditModal = false;

                        this.editGroupBtnState = ClrLoadingState.SUCCESS;
                        this.cdRef.markForCheck();
                    },
                    () => (this.editGroupBtnState = ClrLoadingState.ERROR)
                );
        }
    }

    public saveGroupsChanges() {
        if (this.selectedUser.id === this.authService.user.id) {
            this.showConfirmSudokuModal = true;
        } else {
            this.saveUserGroups();
        }
    }

    public enter(right: IRightExtended) {
        if (!right.dependency) {
            return;
        }

        const dependency = this.rights.find((r) => r.id === right.dependency);
        dependency.highlight = true;
    }

    public leave(right: IRightExtended) {
        if (!right.dependency) {
            return;
        }

        const dependency = this.rights.find((r) => r.id === right.dependency);
        dependency.highlight = false;
    }

    public readonly isColorDark = isColorDark;
    public readonly ACCESS_CONTROL_LEVELS = ACCESS_CONTROL_LEVELS;

    public readonly MAX_LENGTH_COGNITO_CUSTOM_GROUP_NAME = MAX_LENGTH_COGNITO_CUSTOM_GROUP_NAME;
    public readonly MAX_LENGTH_COGNITO_GROUP_DESCRIPTION = MAX_LENGTH_COGNITO_GROUP_DESCRIPTION;
}
