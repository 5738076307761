var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, noop } from 'rxjs';
import { ApiRoutePlurality, HTTP_METHOD, MAX_LENGTH_DEFAULT, TodoStatusType } from '../../../../defs/schema-static';
import { TODO_CATEGORY_SCHEMA_ROUTE } from '../../../../defs/schema/public/TodoCategory';
import { TODO_SCHEMA_ROUTE } from '../../../../defs/schema/public/Todos';
import { AuthService } from '../auth/auth.service';
import { ModalSimpleComponent } from '../modal-simple/modal-simple.component';
import { ModalSimpleService } from '../modal-simple/modal-simple.service';
import { DEBOUNCE_EVENT_TYPE } from '../shared/debounce/debounce.directive';
import { HttpRestService } from '../shared/http-rest/http-rest.service';
import { MomentService } from '../shared/moment/moment.service';
import { SHORTCUT_TODO, ShortcutHandlerService } from '../shared/shortcut-handler/shortcut-handler.service';
var TodoComponent = (function () {
    function TodoComponent(httpRest, authService, translate, keyService, router, momentService, modalSimpleService) {
        var _this = this;
        this.httpRest = httpRest;
        this.authService = authService;
        this.translate = translate;
        this.keyService = keyService;
        this.router = router;
        this.momentService = momentService;
        this.modalSimpleService = modalSimpleService;
        this.alreadyDefault = false;
        this.BARE_TODO = {
            id: -1,
            text: '',
            status: TodoStatusType.TODO,
            employeeId: null,
            todoCategoryId: null,
            dateAction: null,
        };
        this.BARE_CATEGORY = {
            text: '',
            employeeId: null,
            defaultCategory: false,
            orderTodos: [],
        };
        this.onDragStart = function ($event, todoMoved) { };
        this.onDragCanceled = function ($event) { };
        this.changeCollapsed = function (el) {
            el.collapsed = !el.collapsed;
            localStorage.setItem("category" + el.category.id, el.collapsed ? 'true' : 'false');
        };
        this.mapOrder = function (array, order, key) {
            array.sort(function (a, b) {
                var A = a[key];
                var B = b[key];
                if (order) {
                    return order.indexOf(A) - order.indexOf(B);
                }
            });
            return array;
        };
        this.displayDate = function (date) {
            return _this.momentService.moment(date).format('L LTS');
        };
        this.TodoStatusType = TodoStatusType;
        this.DEBOUNCE_EVENT_TYPE = DEBOUNCE_EVENT_TYPE;
        this.MAX_LENGTH_DEFAULT = MAX_LENGTH_DEFAULT;
        this.setupKeyEvent();
        this.userChangeSubscriber = this.authService.userChange.subscribe(function () {
            _this.refreshComponent();
        });
        if (localStorage.getItem('orderCategory')) {
            this.orderCategory = localStorage
                .getItem('orderCategory')
                .split(',')
                .map(function (e) { return parseInt(e, 10); });
        }
        this.refreshComponent();
    }
    TodoComponent.prototype.refreshComponent = function () {
        var _this = this;
        this.currentUser = this.authService.user.employee;
        this.BARE_CATEGORY.employeeId = this.currentUser.id;
        this.BARE_TODO.employeeId = this.currentUser.id;
        if (this.currentUser.id) {
            this.httpRest
                ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, TODO_CATEGORY_SCHEMA_ROUTE, 'list')
                .subscribe(function (todoCats) {
                _this.todoCategories = todoCats;
                _this.httpRest
                    ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, TODO_SCHEMA_ROUTE, 'list')
                    .subscribe(function (todos) {
                    if (todoCats.length === 0 && !_this.alreadyDefault) {
                        var DEFAULT_CATEGORY = __assign({}, _this.BARE_CATEGORY);
                        DEFAULT_CATEGORY.defaultCategory = true;
                        _this.alreadyDefault = true;
                        DEFAULT_CATEGORY.text = 'MISC.';
                        _this.httpRest
                            .put(TODO_CATEGORY_SCHEMA_ROUTE, __assign({}, DEFAULT_CATEGORY))
                            .subscribe(function (todoCat) {
                            _this.todoCategories = [];
                            _this.todoCategories.push(todoCat);
                            _this.selectedCategory = todoCat;
                            _this.todos = [];
                            _this.addTodo();
                        });
                    }
                    else if (todos.length > 0) {
                        _this.todos = todos;
                    }
                    else {
                        _this.todos = [];
                        _this.selectedCategory = todoCats[0];
                        _this.addTodo();
                    }
                    _this.setDisplayCategory();
                    window.setTimeout(function () {
                        var el = document.querySelector('.todo');
                        if (el) {
                            el.focus();
                        }
                    }, 150);
                });
            });
        }
    };
    TodoComponent.prototype.deleteTodo = function (todo) {
        var _this = this;
        var obs = this.httpRest.deleteId(TODO_SCHEMA_ROUTE, todo.id);
        obs.subscribe(function () {
            var idx = _this.todos.findIndex(function (t) { return t.id === todo.id; });
            _this.todos.splice(idx, 1);
            _this.setDisplayCategory();
        });
        return obs;
    };
    TodoComponent.prototype.deleteCategory = function (category) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.selectedCategory = category;
                this.modalSimpleService
                    .open(ModalSimpleComponent, {
                    title: 'swal_are_you_sure',
                    contentI18n: 'swal_cant_revert',
                    ok: {
                        i18n: 'form_validate',
                        class: 'btn-danger',
                    },
                })
                    .subscribe(function (closed) {
                    if (closed.result) {
                        _this.doDeleteCategory();
                    }
                }, noop);
                return [2];
            });
        });
    };
    TodoComponent.prototype.doDeleteCategory = function () {
        var _this = this;
        var todos = this.getTodoOfCategory(this.selectedCategory);
        var del = function () {
            _this.httpRest.deleteId(TODO_CATEGORY_SCHEMA_ROUTE, _this.selectedCategory.id).subscribe(function () {
                var idx = _this.todoCategories.findIndex(function (t) { return t.id === _this.selectedCategory.id; });
                _this.todoCategories.splice(idx, 1);
                _this.setDisplayCategory();
            });
        };
        if (todos.length) {
            forkJoin(todos.map(function (todo) { return _this.deleteTodo(todo); })).subscribe(del);
        }
        else {
            del();
        }
    };
    TodoComponent.prototype.setupKeyEvent = function () {
        var _this = this;
        this.keyService.register({
            shortcut: SHORTCUT_TODO.MOVE_TODO_DOWN,
            callback: function () {
                _this.moveTodo(1);
            },
            context: this,
            forceListen: true,
        });
        this.keyService.register({
            shortcut: SHORTCUT_TODO.DELETE_TODO,
            callback: function () {
                _this.deleteTodo(_this.selectedTodo);
            },
            context: this,
            forceListen: true,
        });
        this.keyService.register({
            shortcut: SHORTCUT_TODO.MOVE_TODO_UP,
            callback: function () {
                _this.moveTodo(-1);
            },
            context: this,
            forceListen: true,
        });
        this.keyService.register({
            shortcut: SHORTCUT_TODO.CANCEL_TODO,
            callback: function () {
                _this.cancelTodo(_this.selectedTodo);
            },
            context: this,
            forceListen: true,
        });
        this.keyService.register({
            shortcut: SHORTCUT_TODO.DONE_TODO,
            callback: function () {
                _this.doneTodo(_this.selectedTodo);
            },
            context: this,
            forceListen: true,
        });
        this.keyService.register({
            shortcut: SHORTCUT_TODO.ADD_CATEGORY,
            callback: function () {
                _this.addCategory();
            },
            context: this,
            forceListen: true,
        });
        this.keyService.register({
            shortcut: SHORTCUT_TODO.SAVE_TODO,
            callback: function () {
                _this.saveTodo(_this.selectedTodo, true);
            },
            context: this,
            forceListen: true,
        });
    };
    TodoComponent.prototype.ngOnDestroy = function () {
        this.userChangeSubscriber.unsubscribe();
    };
    TodoComponent.prototype.changeTodoStatus = function (todo) {
        switch (todo.status) {
            case this.TodoStatusType.TODO:
                todo.status = this.TodoStatusType.DONE;
                break;
            case this.TodoStatusType.DONE:
                todo.status = this.TodoStatusType.CANCELED;
                break;
            case this.TodoStatusType.CANCELED:
                todo.status = this.TodoStatusType.TODO;
                break;
            default:
                break;
        }
        this.updateTodo(todo);
    };
    TodoComponent.prototype.setDisplayCategory = function () {
        var _this = this;
        this.displayCategory = [];
        this.todoCategories.sort(function (a, b) {
            return a.defaultCategory === b.defaultCategory ? 0 : b.defaultCategory ? -1 : 1;
        });
        if (!this.orderCategory || this.orderCategory.length === 0) {
            this.orderCategory = this.todoCategories.map(function (cat) { return cat.id; });
            localStorage.setItem('orderCategory', this.orderCategory.toString());
        }
        this.mapOrder(this.todoCategories, this.orderCategory, 'id');
        this.todoCategories.map(function (category) {
            if (category.defaultCategory) {
                _this.defaultCategory = category;
            }
            var todosOf = _this.getTodoOfCategory(category);
            _this.displayCategory.push({
                category: category,
                todos: todosOf,
                collapsed: localStorage.getItem("category" + category.id)
                    ? localStorage.getItem("category" + category.id) === 'true'
                    : true,
            });
        });
    };
    TodoComponent.prototype.selectTodo = function (todo) {
        this.selectedTodo = todo;
    };
    TodoComponent.prototype.selectCategory = function (category) {
        this.selectedCategory = category;
    };
    TodoComponent.prototype.addTodo = function () {
        var _this = this;
        var i = this.todos.findIndex(function (todo) { return todo.text.trim() === ''; });
        if (i > -1) {
            this.todos.splice(i, 1);
        }
        var todoToAdd = __assign({}, this.BARE_TODO);
        todoToAdd.id = -1;
        todoToAdd.dateAction = this.momentService.moment();
        if (this.selectedCategory) {
            todoToAdd.todoCategoryId = this.selectedCategory.id;
            if (this.selectedTodo) {
                if (this.selectedCategory.orderTodos) {
                    var todoIdx = this.selectedCategory.orderTodos.findIndex(function (idx) { return idx === _this.selectedTodo.id; });
                    this.selectedCategory.orderTodos.splice(todoIdx + 1, 0, -1);
                }
            }
        }
        else {
            todoToAdd.todoCategoryId = this.defaultCategory.id;
        }
        this.todos.push(todoToAdd);
        this.setDisplayCategory();
        window.setTimeout(function () {
            var el = document.getElementById("todo" + todoToAdd.id.toString());
            if (el) {
                el.focus();
                _this.selectedTodo = todoToAdd;
            }
        }, 10);
    };
    TodoComponent.prototype.saveTodo = function (todoToAdd, createNewTodo) {
        var _this = this;
        if (!todoToAdd) {
            if (createNewTodo) {
                this.addTodo();
            }
            return;
        }
        if (todoToAdd.id > -1 && todoToAdd.text.trim() === '') {
            this.httpRest.deleteId(TODO_SCHEMA_ROUTE, todoToAdd.id);
            this.todos.splice(this.todos.indexOf(todoToAdd), 1);
            this.setDisplayCategory();
            return;
        }
        todoToAdd.dateAction = this.momentService.moment();
        if (this.selectedCategory && !todoToAdd.todoCategoryId) {
            todoToAdd.todoCategoryId = this.selectedCategory.id;
        }
        if (todoToAdd.text.trim() === '') {
            var i = this.todos.findIndex(function (todo) { return todo.text.trim() === ''; });
            if (i > -1) {
                this.todos.splice(i, 1);
                this.setDisplayCategory();
            }
            if (createNewTodo) {
                this.addTodo();
            }
            return;
        }
        if (todoToAdd.id > -1) {
            this.updateTodo(todoToAdd, createNewTodo);
        }
        else {
            delete todoToAdd.id;
            this.httpRest.put(TODO_SCHEMA_ROUTE, todoToAdd).subscribe(function (ntodo) {
                todoToAdd.id = ntodo.id;
                if (_this.selectedCategory) {
                    _this.selectedCategory.orderTodos.splice(_this.selectedCategory.orderTodos.indexOf(-1), 1, todoToAdd.id);
                    _this.updateTodoCategory(_this.selectedCategory, null);
                }
                _this.setDisplayCategory();
                window.setTimeout(function () {
                    var el = document.getElementById("todo" + todoToAdd.id.toString());
                    if (el) {
                        el.focus();
                    }
                }, 10);
                if (createNewTodo) {
                    _this.addTodo();
                }
            });
        }
    };
    TodoComponent.prototype.moveTodo = function (relative) {
        var _this = this;
        if (!this.selectedTodo) {
            return;
        }
        var oldIdx = this.selectedCategory.orderTodos.indexOf(this.selectedTodo.id);
        var newIdx = 0;
        if (oldIdx > -1) {
            if (oldIdx + relative >= 0) {
                newIdx = oldIdx + relative;
            }
            this.selectedCategory.orderTodos.splice(oldIdx, 1);
        }
        this.selectedCategory.orderTodos.splice(newIdx, 0, this.selectedTodo.id);
        this.httpRest
            .post(TODO_CATEGORY_SCHEMA_ROUTE, {
            id: this.selectedCategory.id,
            orderTodos: this.selectedCategory.orderTodos || [],
        })
            .subscribe(function () {
            _this.setDisplayCategory();
        });
    };
    TodoComponent.prototype.onDrop = function (event, category) {
        var _this = this;
        if (event.data.orderTodos) {
            var idx = this.orderCategory.indexOf(event.data.id);
            var newidx = event.index;
            if (event.index > 0) {
                newidx = this.orderCategory.indexOf(category.id);
            }
            if (newidx >= this.orderCategory.length - 1) {
                return;
            }
            if (event.index === 0) {
                if (event.event.target.clientTop !== 0) {
                    this.orderCategory.splice(idx, 1);
                    this.orderCategory.splice(idx - 1, 0, event.data.id);
                    localStorage.setItem('orderCategory', this.orderCategory.toString());
                    this.setDisplayCategory();
                    return;
                }
            }
            this.orderCategory.splice(idx, 1);
            this.orderCategory.splice(newidx, 0, event.data.id);
            localStorage.setItem('orderCategory', this.orderCategory.toString());
            this.setDisplayCategory();
            return;
        }
        var newIdx = event.index - 1;
        var todoMoved = event.data;
        var oldIdx = category.orderTodos.indexOf(event.data.id);
        if (oldIdx > -1) {
            category.orderTodos.splice(oldIdx, 1);
        }
        category.orderTodos.splice(newIdx, 0, todoMoved.id);
        this.httpRest
            .post(TODO_CATEGORY_SCHEMA_ROUTE, {
            id: category.id,
            orderTodos: category.orderTodos,
        })
            .subscribe(function () {
            _this.setDisplayCategory();
        });
    };
    TodoComponent.prototype.addCategory = function () {
        var _this = this;
        this.httpRest.put(TODO_CATEGORY_SCHEMA_ROUTE, this.BARE_CATEGORY).subscribe(function (ncategory) {
            _this.todoCategories.push(ncategory);
            _this.selectedCategory = ncategory;
            window.setTimeout(function () {
                _this.setDisplayCategory();
            }, 100);
            window.setTimeout(function () {
                var el = document.getElementById("category" + ncategory.id.toString());
                if (el) {
                    el.focus();
                }
            }, 250);
        });
    };
    TodoComponent.prototype.cancelTodo = function (todo) {
        todo.status = TodoStatusType.CANCELED;
        todo.dateAction = this.momentService.moment();
        this.updateTodo(todo);
    };
    TodoComponent.prototype.doneTodo = function (todo) {
        todo.status = TodoStatusType.DONE;
        todo.dateAction = this.momentService.moment();
        this.updateTodo(todo);
    };
    TodoComponent.prototype.updateTodoCategory = function (category, todoList) {
        var _this = this;
        if (category.text.trim() === '') {
            this.httpRest.deleteId(TODO_CATEGORY_SCHEMA_ROUTE, category.id).subscribe(function (res) {
                _this.todoCategories.splice(_this.todoCategories.indexOf(category), 1);
                _this.setDisplayCategory();
            });
            return;
        }
        if (todoList) {
            category.orderTodos = todoList.map(function (todo) { return todo.id; });
        }
        this.httpRest.post(TODO_CATEGORY_SCHEMA_ROUTE, {
            id: category.id,
            text: category.text,
            orderTodos: category.orderTodos,
        });
    };
    TodoComponent.prototype.updateTodo = function (todo, createNewTodo) {
        if (todo.id === -1) {
            if (todo.text.trim() === '') {
                return;
            }
            this.saveTodo(todo, createNewTodo);
        }
        if (todo.text.trim() === '') {
            this.httpRest.deleteId(TODO_SCHEMA_ROUTE, todo.id);
            this.todos.splice(this.todos.indexOf(todo), 1);
            this.setDisplayCategory();
            return;
        }
        this.httpRest.post(TODO_SCHEMA_ROUTE, { id: todo.id, text: todo.text, status: todo.status });
        if (createNewTodo) {
            this.addTodo();
        }
    };
    TodoComponent.prototype.getTodoOfCategory = function (category) {
        var todos = this.todos.filter(function (todo) { return todo.todoCategoryId === category.id; });
        return this.mapOrder(todos, category.orderTodos, 'id');
    };
    TodoComponent.prototype.getTodoWithoutCategory = function () {
        return this.todos.filter(function (todo) { return !todo.todoCategoryId; });
    };
    return TodoComponent;
}());
export { TodoComponent };
