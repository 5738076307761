import * as i0 from "./customer-request.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/convert-new-lines/convert-new-lines.pipe";
import * as i3 from "@ngx-translate/core";
import * as i4 from "@angular/common";
import * as i5 from "../../modal/modal.component.ngfactory";
import * as i6 from "../../modal/modal.component";
import * as i7 from "../../shared/shortcut-handler/shortcut-handler.service";
import * as i8 from "../../shared/control-flow/control-flow.service";
import * as i9 from "../../modal/modal.directives";
import * as i10 from "./customer-request.component";
import * as i11 from "../../shared/http-rest/http-rest.service";
import * as i12 from "../../auth/auth.service";
var styles_CustomerRequestComponent = [i0.styles];
var RenderType_CustomerRequestComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_CustomerRequestComponent, data: {} });
export { RenderType_CustomerRequestComponent as RenderType_CustomerRequestComponent };
function View_CustomerRequestComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "spinner-bg-c-small"], ["style", "margin-top: 15em;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "spinner-c"]], null, null, null, null, null))], null, null); }
function View_CustomerRequestComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "clr-row"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "divider"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "clr-row"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(4, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.selectedRequest == null) ? null : _co.selectedRequest.description); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent, 0), ((_co.selectedRequest == null) ? null : _co.selectedRequest.request))); _ck(_v, 3, 0, currVal_1); }); }
export function View_CustomerRequestComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.ConvertNewLinesPipe, []), i1.ɵqud(402653184, 1, { fileInput: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 12, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 11, "div", [["class", "clr-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "h2", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(5, 8536064, null, 0, i3.TranslateDirective, [i3.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["requests_list"])), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "column clr-col-12 mt-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 3, "button", [["class", "btn float-right"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.showNewRequestModal = true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "i", [["class", "fa fa-plus mr-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "column clr-col-12"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerRequestComponent_1)), i1.ɵdid(14, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 6, "app-modal", [], null, [[null, "showChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("showChange" === en)) {
        var pd_0 = ((_co.displayModal = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_ModalComponent_0, i5.RenderType_ModalComponent)), i1.ɵdid(16, 770048, null, 3, i6.ModalComponent, [i7.ShortcutHandlerService, i8.ControlFlowService, i1.ChangeDetectorRef], { show: [0, "show"], title: [1, "title"] }, { showChange: "showChange" }), i1.ɵqud(335544320, 2, { modalHeader: 0 }), i1.ɵqud(335544320, 3, { modalBody: 0 }), i1.ɵqud(335544320, 4, { modalFooter: 0 }), (_l()(), i1.ɵand(0, [[3, 2]], null, 1, null, View_CustomerRequestComponent_2)), i1.ɵdid(21, 16384, null, 0, i9.ModalBodyDirective, [i1.TemplateRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ""; _ck(_v, 5, 0, currVal_0); var currVal_2 = _co.pleaseWait; _ck(_v, 14, 0, currVal_2); var currVal_3 = _co.displayModal; var currVal_4 = ((_co.selectedRequest == null) ? null : _co.selectedRequest.title); _ck(_v, 16, 0, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_1 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform("add_request")); _ck(_v, 10, 0, currVal_1); }); }
export function View_CustomerRequestComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-customer-request", [], null, null, null, View_CustomerRequestComponent_0, RenderType_CustomerRequestComponent)), i1.ɵdid(1, 49152, null, 0, i10.CustomerRequestComponent, [i11.HttpRestService, i12.AuthService], null, null)], null, null); }
var CustomerRequestComponentNgFactory = i1.ɵccf("app-customer-request", i10.CustomerRequestComponent, View_CustomerRequestComponent_Host_0, {}, {}, []);
export { CustomerRequestComponentNgFactory as CustomerRequestComponentNgFactory };
