import {ISchemaRoute} from '../../schema-static';

export enum GROUP_RIGHTS_FIELD {
    id = 'id',
    groupName = 'groupName',
    rightId = 'rightId',
}

export interface IGroupRights {
    id?: number;
    groupName: string;
    rightId: number;
}

const GROUP_RIGHTS_SCHEMA_ROUTE: ISchemaRoute = {
    tableName: 'groupRights',
    singularRoute: 'groupRight',
    pluralRoute: 'groupRights',
};

export {GROUP_RIGHTS_SCHEMA_ROUTE};
