import * as i0 from "./control-flow.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@clr/angular/clr-angular.ngfactory";
import * as i3 from "@clr/angular";
import * as i4 from "../../notifications/notifications.component.ngfactory";
import * as i5 from "../../notifications/notifications.component";
import * as i6 from "../notification.service";
import * as i7 from "../moment/moment.service";
import * as i8 from "../../global-search-modal/global-search-modal.component.ngfactory";
import * as i9 from "../../global-search-modal/global-search-modal.component";
import * as i10 from "../shortcut-handler/shortcut-handler.service";
import * as i11 from "../http-rest/http-rest.service";
import * as i12 from "@angular/router";
import * as i13 from "@ngx-translate/core";
import * as i14 from "./control-flow.service";
import * as i15 from "../../auth/auth.service";
import * as i16 from "../../user-view/user-view.component.ngfactory";
import * as i17 from "../../user-view/user-view.component";
import * as i18 from "../../modal-simple/modal-simple.service";
import * as i19 from "@angular/common";
import * as i20 from "../access-control/access-control.directive";
import * as i21 from "../quick-action/quick-action.component.ngfactory";
import * as i22 from "../quick-action/quick-action.component";
import * as i23 from "../../forms/add-task/add-task.service";
import * as i24 from "../../forms/add-milestone.service";
import * as i25 from "../../forms/add-time.service";
import * as i26 from "../timer/timer.component.ngfactory";
import * as i27 from "../timer/timer.component";
import * as i28 from "../timer/timer.service";
import * as i29 from "../../wander/wander.component.ngfactory";
import * as i30 from "../../wander/wander.component";
import * as i31 from "../../file-manager/file-manager.component.ngfactory";
import * as i32 from "../../file-manager/file-manager.component";
import * as i33 from "../file-manager/file-manager.service";
import * as i34 from "../file-downloader/file-downloader.service";
import * as i35 from "./control-flow.component";
var styles_ControlFlowComponent = [i0.styles];
var RenderType_ControlFlowComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ControlFlowComponent, data: {} });
export { RenderType_ControlFlowComponent as RenderType_ControlFlowComponent };
function View_ControlFlowComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["src", "../../../assets/logo_lg.png"]], null, null, null, null, null))], null, null); }
function View_ControlFlowComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "clr-signpost-content", [["style", "min-width:17rem;"]], [[2, "signpost-content", null], [2, "is-off-screen", null]], null, null, i2.View_ClrSignpostContent_0, i2.RenderType_ClrSignpostContent)), i1.ɵdid(1, 8568832, null, 0, i3.ClrSignpostContent, [i1.Injector, [2, i3.ɵh], i3.ClrCommonStrings], { position: [0, "position"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "app-notifications", [], null, null, null, i4.View_NotificationsComponent_0, i4.RenderType_NotificationsComponent)), i1.ɵdid(3, 49152, null, 0, i5.NotificationsComponent, [i6.NotificationService, i7.MomentService], null, null)], function (_ck, _v) { var currVal_2 = "bottom-right"; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = true; var currVal_1 = i1.ɵnov(_v, 1).isOffScreen; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_ControlFlowComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "app-global-search", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.isCollapsed = false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_GlobalSearchModalComponent_0, i8.RenderType_GlobalSearchModalComponent)), i1.ɵdid(1, 4964352, null, 3, i9.GlobalSearchModalComponent, [i10.ShortcutHandlerService, i11.HttpRestService, i12.Router, i1.ChangeDetectorRef, i13.TranslateService, i14.ControlFlowService, i15.AuthService], { displayInline: [0, "displayInline"], isCollapsed: [1, "isCollapsed"] }, null), i1.ɵqud(335544320, 2, { modalHeader: 0 }), i1.ɵqud(335544320, 3, { modalBody: 0 }), i1.ɵqud(335544320, 4, { modalFooter: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = _co.isCollapsed; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ControlFlowComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-user-view", [], [[2, "mt-04", null]], null, null, i16.View_UserViewComponent_0, i16.RenderType_UserViewComponent)), i1.ɵdid(2, 49152, null, 0, i17.UserViewComponent, [i18.ModalSimpleService, i15.AuthService, i12.Router], { isCollapsed: [0, "isCollapsed"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ControlFlowComponent_4)), i1.ɵdid(4, 16384, null, 0, i19.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.isCollapsed; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.authService.user; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isCollapsed; _ck(_v, 1, 0, currVal_0); }); }
function View_ControlFlowComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "span", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i19.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(2, { color: 0 }), i1.ɵdid(3, 8536064, null, 0, i13.TranslateDirective, [i13.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(4, null, ["route_", ""])), i1.ɵppd(5, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.parent.parent.parent.context.$implicit.active && _ck(_v, 2, 0, ((_co.authService.user == null) ? null : _co.authService.user.color))); _ck(_v, 1, 0, currVal_0); var currVal_1 = ""; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent.parent.parent.parent.parent, 0), _v.parent.parent.parent.context.$implicit.label)); _ck(_v, 4, 0, currVal_2); }); }
function View_ControlFlowComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "li", [["class", "Sidebar-navItem"]], [[8, "title", 0]], null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i19.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { active: 0, "has-success": 1, "has-error": 2 }), i1.ɵppd(3, 1), i1.ɵpid(131072, i13.TranslatePipe, [i13.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 7, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 671744, null, 0, i12.RouterLinkWithHref, [i12.Router, i12.ActivatedRoute, i19.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 3, "clr-icon", [["class", "Sidebar-menuIcon"]], [[1, "shape", 0]], null, null, null, null)), i1.ɵdid(8, 278528, null, 0, i19.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(9, { color: 0 }), i1.ɵdid(10, 16384, null, 0, i3.ClrIconCustomTag, [], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ControlFlowComponent_9)), i1.ɵdid(12, 16384, null, 0, i19.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "Sidebar-navItem"; var currVal_2 = _ck(_v, 2, 0, _v.parent.parent.context.$implicit.active, _v.parent.parent.context.$implicit.valid, (_v.parent.parent.context.$implicit.valid === false)); _ck(_v, 1, 0, currVal_1, currVal_2); var currVal_5 = (!_v.parent.parent.context.$implicit.disabled ? _co.getRouteUrl(_v.parent.parent.context.$implicit) : null); _ck(_v, 6, 0, currVal_5); var currVal_7 = (_v.parent.parent.context.$implicit.active && _ck(_v, 9, 0, ((_co.authService.user == null) ? null : _co.authService.user.color))); _ck(_v, 8, 0, currVal_7); var currVal_8 = !_co.isCollapsed; _ck(_v, 12, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 4).transform(i1.ɵunv(_v, 0, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent.parent.parent, 0), ("route_" + _v.parent.parent.context.$implicit.label))))); _ck(_v, 0, 0, currVal_0); var currVal_3 = i1.ɵnov(_v, 6).target; var currVal_4 = i1.ɵnov(_v, 6).href; _ck(_v, 5, 0, currVal_3, currVal_4); var currVal_6 = _v.parent.parent.context.$implicit.icon; _ck(_v, 7, 0, currVal_6); }); }
function View_ControlFlowComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ControlFlowComponent_8)), i1.ɵdid(2, 81920, null, 0, i20.AccessControlDirective, [i1.ViewContainerRef, i1.TemplateRef, i15.AuthService], { sharedAccessControl: [0, "sharedAccessControl"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = ("NAVIGATE_" + _v.parent.context.$implicit.label.toUpperCase()); _ck(_v, 2, 0, currVal_0); }, null); }
function View_ControlFlowComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ControlFlowComponent_7)), i1.ɵdid(2, 16384, null, 0, i19.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((((_co.displayedDepths[0].index == 0) || (_v.context.$implicit.parentRoute && _v.context.$implicit.parentRoute.active)) && (_v.context.$implicit.icon != null)) && _co.publicConfig.appParams[("module_" + _v.context.$implicit.label.toLowerCase())]); _ck(_v, 2, 0, currVal_0); }, null); }
function View_ControlFlowComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-quick-action", [], null, null, null, i21.View_QuickActionComponent_0, i21.RenderType_QuickActionComponent)), i1.ɵdid(1, 114688, null, 0, i22.QuickActionComponent, [i23.FormsAddTaskService, i24.FormsAddMilestoneService, i25.FormsAddTimeService, i10.ShortcutHandlerService, i15.AuthService], { isCollapsed: [0, "isCollapsed"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isCollapsed; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ControlFlowComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-timer", [["class", "pos-r"]], null, null, null, i26.View_TimerComponent_0, i26.RenderType_TimerComponent)), i1.ɵdid(1, 114688, null, 0, i27.TimerComponent, [i28.TimerService, i15.AuthService, i25.FormsAddTimeService, i10.ShortcutHandlerService, i7.MomentService, i18.ModalSimpleService], { isCollapsed: [0, "isCollapsed"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isCollapsed; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ControlFlowComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ControlFlowComponent_11)), i1.ɵdid(2, 16384, null, 0, i19.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ControlFlowComponent_12)), i1.ɵdid(4, 16384, null, 0, i19.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.publicConfig.appParams.quickaction; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.publicConfig.appParams.timer; _ck(_v, 4, 0, currVal_1); }, null); }
function View_ControlFlowComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "ul", [["class", "oy-a ox-h"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ControlFlowComponent_6)), i1.ɵdid(3, 278528, null, 0, i19.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ControlFlowComponent_10)), i1.ɵdid(5, 16384, null, 0, i19.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.displayedDepths[0].routes; _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.COGNITO_USER_GROUPS_PRECEDENCE[_co.requiredGroup] <= _co.COGNITO_USER_GROUPS_PRECEDENCE[_co.COGNITO_USER_GROUPS.EMPLOYEE]); _ck(_v, 5, 0, currVal_1); }, null); }
function View_ControlFlowComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-wander", [], null, null, null, i29.View_WanderComponent_0, i29.RenderType_WanderComponent)), i1.ɵdid(1, 114688, null, 0, i30.WanderComponent, [], { isCollapsed: [0, "isCollapsed"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isCollapsed; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ControlFlowComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i19.LowerCasePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 43, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 35, "div", [["class", "column-container Sidebar"]], [[2, "is-collapsed", null], [2, "d-invisible", null], [2, "d-none", null]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 19, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "Sidebar-logo"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ControlFlowComponent_1)), i1.ɵdid(6, 16384, null, 0, i19.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 15, "div", [["class", "notif-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 14, "div", [["class", "signpost-triggers-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 13, "div", [["class", "signpost-trigger-demo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 12, "div", [["class", "signpost-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 11, "clr-signpost", [], [[2, "signpost", null]], null, null, i2.View_ClrSignpost_0, i2.RenderType_ClrSignpost)), i1.ɵprd(6144, null, i3.ɵh, null, [i1.ElementRef]), i1.ɵdid(13, 49152, null, 1, i3.ClrSignpost, [i3.ClrCommonStrings], null, null), i1.ɵqud(335544320, 1, { customTrigger: 0 }), i1.ɵprd(512, null, i3.ɵd, i3.ɵd, []), (_l()(), i1.ɵeld(16, 0, null, 0, 4, "clr-icon", [["class", "signpost-trigger"], ["clrSignpostTrigger", ""], ["shape", "bell"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 20).onSignpostTriggerClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(17, 278528, null, 0, i19.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(18, { "has-badge-info": 0 }), i1.ɵdid(19, 16384, null, 0, i3.ClrIconCustomTag, [], null, null), i1.ɵdid(20, 147456, [[1, 4]], 0, i3.ClrSignpostTrigger, [i3.ɵd, i1.Renderer2, i1.ElementRef], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ControlFlowComponent_2)), i1.ɵdid(22, 147456, null, 0, i3.ClrIfOpen, [i3.ɵd, i1.TemplateRef, i1.ViewContainerRef], { open: [0, "open"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ControlFlowComponent_3)), i1.ɵdid(24, 16384, null, 0, i19.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(25, 0, null, null, 2, "nav", [["class", "flex-1 column-container oy-a"], ["role", "navigation"]], [[2, "mt-1-5e", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ControlFlowComponent_5)), i1.ɵdid(27, 16384, null, 0, i19.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ControlFlowComponent_13)), i1.ɵdid(29, 16384, null, 0, i19.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(30, 0, null, null, 7, "footer", [["class", "Sidebar-footer"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.switchSidebar() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(31, 0, null, null, 3, "clr-icon", [["class", "Sidebar-toggleArrow Sidebar-menuIcon"], ["shape", "arrow"]], null, null, null, null, null)), i1.ɵdid(32, 278528, null, 0, i19.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(33, { transform: 0 }), i1.ɵdid(34, 16384, null, 0, i3.ClrIconCustomTag, [], null, null), (_l()(), i1.ɵeld(35, 0, null, null, 2, "span", [["class", "Sidebar-toggleText"], ["style", "font-size:0.8rem"], ["translate", ""]], null, null, null, null, null)), i1.ɵdid(36, 8536064, null, 0, i13.TranslateDirective, [i13.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["menu_close"])), (_l()(), i1.ɵeld(38, 0, null, null, 4, "div", [["class", "Main"]], null, null, null, null, null)), i1.ɵdid(39, 278528, null, 0, i19.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(40, { "is-collapsed": 0, "is-without-sidebar": 1 }), (_l()(), i1.ɵeld(41, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(42, 212992, null, 0, i12.RouterOutlet, [i12.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(43, 0, null, null, 1, "app-file-manager", [], null, [["window", "beforeunload"]], function (_v, en, $event) { var ad = true; if (("window:beforeunload" === en)) {
        var pd_0 = (i1.ɵnov(_v, 44).confirmUnloadChanges($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i31.View_FileManagerComponent_0, i31.RenderType_FileManagerComponent)), i1.ɵdid(44, 245760, null, 0, i32.FileManagerComponent, [i33.FileManagerService, i1.ChangeDetectorRef, i34.FileDownloaderService], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = !_co.isCollapsed; _ck(_v, 6, 0, currVal_4); var currVal_6 = _ck(_v, 18, 0, ((_co.notificationService == null) ? null : _co.notificationService.notificationsNotClosed.length)); _ck(_v, 17, 0, currVal_6); var currVal_7 = null; _ck(_v, 22, 0, currVal_7); var currVal_8 = (!_co.activeRoute || ((_co.activeRoute.depth >= (_co.displayedDepths[0].index + 1)) && (_co.activeRoute.fullPath !== ""))); _ck(_v, 24, 0, currVal_8); var currVal_10 = (!_co.activeRoute || ((_co.activeRoute.depth >= (_co.displayedDepths[0].index + 1)) && (_co.activeRoute.fullPath !== ""))); _ck(_v, 27, 0, currVal_10); var currVal_11 = _co.publicConfig.appParams.bobby; _ck(_v, 29, 0, currVal_11); var currVal_12 = _ck(_v, 33, 0, (_co.isCollapsed ? "rotate(90deg)" : "rotate(270deg)")); _ck(_v, 32, 0, currVal_12); var currVal_13 = ""; _ck(_v, 36, 0, currVal_13); var currVal_14 = "Main"; var currVal_15 = _ck(_v, 40, 0, _co.isCollapsed, _co.hidden); _ck(_v, 39, 0, currVal_14, currVal_15); _ck(_v, 42, 0); _ck(_v, 44, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "Wrapper ", _co.class, ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.isCollapsed; var currVal_2 = _co.hidden; var currVal_3 = _co.controlFlowService.isNotFound; _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3); var currVal_5 = true; _ck(_v, 11, 0, currVal_5); var currVal_9 = _co.isCollapsed; _ck(_v, 25, 0, currVal_9); }); }
export function View_ControlFlowComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "shared-control-flow", [], null, null, null, View_ControlFlowComponent_0, RenderType_ControlFlowComponent)), i1.ɵdid(1, 770048, null, 0, i35.ControlFlowComponent, [i12.Router, i12.ActivatedRoute, i14.ControlFlowService, i1.ChangeDetectorRef, i15.AuthService, i6.NotificationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ControlFlowComponentNgFactory = i1.ɵccf("shared-control-flow", i35.ControlFlowComponent, View_ControlFlowComponent_Host_0, { rootPath: "rootPath", routes: "routes", routesControlFlow: "routesControlFlow", maxDepth: "maxDepth", maxDisplayedDepth: "maxDisplayedDepth", hidden: "hidden", requiredGroup: "requiredGroup", class: "class" }, {}, []);
export { ControlFlowComponentNgFactory as ControlFlowComponentNgFactory };
