// tslint:disable ban-types
import {EventEmitter, Injectable, OnDestroy, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
// @ts-ignore
import {AWSError, CognitoIdentityCredentials, util} from 'aws-sdk';
import {GetCredentialsForIdentityResponse} from 'aws-sdk/clients/cognitoidentity';
import S3, {GetObjectRequest, HeadObjectOutput, Object, ObjectVersionList} from 'aws-sdk/clients/s3';
import {camelCase, paramCase} from 'change-case';
// @ts-ignore
import Evaporate from 'evaporate';
import PQueue from 'p-queue';
import {Subscription} from 'rxjs';
import slugify from 'slugify';
import publicConfig from '../../../../../defs/config/config.json';
import {
    ApiRoutePlurality,
    FileStateType,
    HTTP_METHOD,
    STORAGE_RIGHT,
    STORED_TYPE,
} from '../../../../../defs/schema-static';
import {IUser} from '../../../../../defs/schema/public/Users';
import {IWikiPage} from '../../../../../defs/schema/public/WikiPage';
import {IBoardAttachment} from '../../../../../defs/schema/storage/BoardAttachments.js';
import {ICustomerRequestFile} from '../../../../../defs/schema/storage/CustomerRequestFiles';
import {FILE_FIELD, FILE_SCHEMA_ROUTE, FileEntity, IFile, IFileEntity} from '../../../../../defs/schema/storage/Files';
import {ITaskFile} from '../../../../../defs/schema/storage/TaskFiles';
import {IWikiAttachment} from '../../../../../defs/schema/storage/WikiAttachments';
import {noop} from '../../app-static';
import {AuthService} from '../../auth/auth.service';
import {HttpRestService} from '../http-rest/http-rest.service';
import {MomentService} from '../moment/moment.service.js';
import {TOAST_TYPE, ToastService} from '../toast/toast.service';

export type Gettable<T> = T | (() => T);

interface IMetadata {
    [key: string]: string;
}

interface IExtendedMetadata {
    [key: string]: string | number;
}

export interface ISignedRequests {
    getObject: string;
    getObjectAttachment: string;
}

export type HeadedObject = Object & HeadObjectOutput;
export type AuthoredObject = HeadedObject & {user?: Partial<IUser>};
export type SignedGetObject = Object & {signed: ISignedRequests};

export interface IFileSigned extends IFile {
    signed: ISignedRequests;
}

export interface IUploadingFile extends Partial<IFile> {
    file: File;
    progress: number;
    transferStats: Evaporate.TransferStats;
    signed?: ISignedRequests;
    storedType: STORED_TYPE;
    entity?: Gettable<FileEntity>;
    quiet?: boolean;
    fileId?: number;

    cancel(): Promise<void>;
    pause(): Promise<void>;
    resume(): Promise<void>;
    resend(): Promise<void>;
}

@Injectable({
    providedIn: 'root',
})
export class FileManagerService implements OnInit, OnDestroy {
    private readonly uploadQueue = new PQueue({concurrency: FileManagerService.MAX_CONCURRENT_UPLOADS});
    private readonly _uploadingFiles: IUploadingFile[] = [];
    @Output()
    public uploadingFileState = new EventEmitter<IUploadingFile>();
    @Output()
    public uploadingFileProgress = new EventEmitter<IUploadingFile>();

    public static readonly REGION = publicConfig.aws.storage.region || publicConfig.aws.region;
    public static readonly BUCKET = publicConfig.aws.storage.bucket;
    public static readonly PREFIX = publicConfig.aws.storage.prefix;

    private readonly s3 = new S3({
        signatureVersion: 'v4',
        region: FileManagerService.REGION,
        computeChecksums: true,
        httpOptions: {
            timeout: 0,
        },
    });

    private evaporate: Evaporate;

    private authSubscription: Subscription;

    public constructor(
        private readonly authService: AuthService,
        private readonly httpRest: HttpRestService,
        private readonly toastService: ToastService,
        private readonly translate: TranslateService,
        private readonly momentService: MomentService
    ) {}

    public ngOnInit() {
        this.authSubscription = this.authService.isAuthenticatedChange.subscribe(async () => this.updateCredentials());

        if (this.authService.idJWTToken) {
            (async () => this.updateCredentials())();
        }
    }

    public ngOnDestroy() {
        if (this.authSubscription) {
            this.authSubscription.unsubscribe();
            this.authSubscription = null;
        }
    }

    public async updateCredentials() {
        if (this.s3.config.credentials && !(this.s3.config.credentials as CognitoIdentityCredentials).expired) {
            if (!this.authService.isAuthenticated) {
                (this.s3.config.credentials as CognitoIdentityCredentials).clearCachedId();
                this.s3.config.update({credentials: new CognitoIdentityCredentials()});
            }
        } else {
            this.s3.config.update({credentials: await this.authService.getAwsCredentials()});
        }

        const {accessKeyId, secretAccessKey, sessionToken} = await this.authService.getAwsCredentials();

        if (!this.evaporate) {
            this.evaporate = (await Evaporate.create({
                bucket: FileManagerService.BUCKET,
                aws_key: accessKeyId,
                awsRegion: FileManagerService.REGION,
                logging: false,
                sendCanonicalRequestToSignerUrl: true,

                maxConcurrentParts: 1,
                partSize: 5 * 1024 * 1024,

                computeContentMd5: true,
                cryptoMd5Method: (data: any) => util.crypto.md5(data, 'base64'),
                cryptoHexEncodedHash256: (data: any) => util.crypto.sha256(data, 'hex'),
                customAuthMethod: async (
                    _signParams: any,
                    _signHeaders: any,
                    stringToSign: any,
                    _signatureDateTime: any,
                    canonicalRequest: any
                ) => {
                    const stringToSignDecoded = decodeURIComponent(stringToSign);
                    const splitStringToSignDecoded = stringToSignDecoded.split('\n');
                    const requestScope = splitStringToSignDecoded[2];
                    const [date, region, service, signatureType] = requestScope.split('/');

                    const dateRound = util.crypto.hmac(`AWS4${secretAccessKey}`, date, 'buffer');
                    const regionRound = util.crypto.hmac(dateRound, region, 'buffer');
                    const serviceRound = util.crypto.hmac(regionRound, service, 'buffer');
                    const signatureTypeRound = util.crypto.hmac(serviceRound, signatureType, 'buffer');

                    const fixedCanonicalRequest = canonicalRequest.replace(
                        /(x-amz-date:)[\dTZ]+/,
                        `$1${this.momentService
                            .moment()
                            .utc()
                            .format('YYYYMMDDTHHmmss\\Z')}`
                    );

                    const fixedCanonicalHash = util.crypto.sha256(fixedCanonicalRequest, 'hex');
                    if (splitStringToSignDecoded[3] !== fixedCanonicalHash) {
                        // tslint:disable-next-line:no-console
                        console.warn('wrong x-amz-date, fu evaporate', splitStringToSignDecoded[3], fixedCanonicalHash);
                        splitStringToSignDecoded[3] = fixedCanonicalHash;
                    }

                    const fixedStringToSignDecoded = splitStringToSignDecoded.join('\n');

                    return util.crypto.hmac(signatureTypeRound, fixedStringToSignDecoded, 'hex');
                },
            })) as Evaporate;
        }

        const sessionHeaders = {'x-amz-security-token': sessionToken};
        this.evaporate.config.aws_key = accessKeyId;
        this.evaporate.filesInProcess.forEach((fileInProcess: any) => {
            Object.assign(fileInProcess.xAmzHeadersCommon, sessionHeaders);
            Object.assign(fileInProcess.xAmzHeadersAtInitiate, sessionHeaders);
            // amend request in every PutPart instance - all requests are prepared before processing the parts!
            if (fileInProcess.s3Parts && fileInProcess.s3Parts.length > 0) {
                fileInProcess.s3Parts.forEach((part: any) => {
                    part.awsRequest.con.aws_key = accessKeyId;
                    Object.assign(part.awsRequest.request.x_amz_headers, sessionHeaders);
                });
            }
        });
    }

    public async getFileList(storedType?: STORED_TYPE, entity?: Gettable<FileEntity>): Promise<IFile[]> {
        const route = ['fileEntity'];
        if (storedType) {
            route.push(storedType);
            if (entity) {
                route.push(FileManagerService.STORED_TYPE_ENTITY_ID[storedType](entity).toString());
            }
        }

        return this.httpRest
            ._request<IFile[]>(HTTP_METHOD.GET, ApiRoutePlurality.SINGULAR, FILE_SCHEMA_ROUTE, route.join('/'))
            .toPromise();
    }

    public async getFileVersionList(
        storedType: STORED_TYPE,
        entity: Gettable<FileEntity>,
        filename?: string
    ): Promise<ObjectVersionList> {
        const prefix = FileManagerService.buildPath(await this.getStoragePrefix(storedType, entity), filename);

        await this.updateCredentials();
        const {Versions} = await this.s3
            .listObjectVersions({
                Bucket: FileManagerService.BUCKET,
                Prefix: prefix,
            })
            .promise();

        return Versions;
    }

    public async signFiles<T extends Partial<IFile>>(...files: T[]): Promise<(T & IFileSigned)[]> {
        return Promise.all(
            files.map(
                async (file: T & IFileSigned) =>
                    ({
                        ...(file as IFile),
                        signed: {
                            getObject: (await this.getSignedGetObjectUrls({}, file))[0],
                            getObjectAttachment: (await this.getSignedGetObjectUrls(
                                {
                                    ResponseContentDisposition: `attachment; filename=${slugify(
                                        FileManagerService.getFilename(file)
                                    )}`,
                                },
                                file
                            ))[0],
                        },
                    } as T & IFileSigned)
            )
        );
    }

    public async getSignedGetObjectUrls(
        params: Partial<GetObjectRequest>,
        ...files: {path: string; version?: string}[]
    ): Promise<string[]> {
        return this.getSignedUrls('getObject', params, ...files);
    }

    public async getSignedUrls(
        operation: string,
        params: Partial<GetObjectRequest>,
        ...files: {path: string; version?: string}[]
    ): Promise<string[]> {
        await this.updateCredentials();

        return files.map((file) =>
            this.s3.getSignedUrl(operation, {
                ...params,
                Bucket: FileManagerService.BUCKET,
                Key: file.path,
                VersionId: file.version,
            })
        );
    }

    public async deleteFiles(...files: {path: string}[]) {
        return Promise.all(
            files.map(async (file) =>
                this.httpRest
                    ._request<IFileEntity>(
                        HTTP_METHOD.DELETE,
                        ApiRoutePlurality.SINGULAR,
                        FILE_SCHEMA_ROUTE,
                        `fileEntity/${file.path}`
                    )
                    .toPromise()
            )
        );
    }

    public async uploadFiles(
        storedType: STORED_TYPE,
        entity?: Gettable<FileEntity>,
        ...files: File[]
    ): Promise<IUploadingFile[]> {
        return this._uploadFiles(storedType, entity, false, ...files);
    }

    public async uploadFilesQuiet(
        storedType: STORED_TYPE,
        entity?: Gettable<FileEntity>,
        ...files: File[]
    ): Promise<IUploadingFile[]> {
        return this._uploadFiles(storedType, entity, true, ...files);
    }

    private async _uploadFiles(
        storedType: STORED_TYPE,
        entity?: Gettable<FileEntity>,
        quiet = false,
        ...files: File[]
    ): Promise<IUploadingFile[]> {
        const uploadingFiles = await Promise.all(
            files.map(
                async (file: File): Promise<IUploadingFile> => {
                    const path = await this.getUploadFilename(storedType, entity, file);

                    const [uploadingFile] = (await this.signFiles({
                        file,
                        state: FileStateType.WAITING,
                        path,
                        md5: util.crypto.md5(file, 'hex'),
                        size: file.size,
                        mimeType: file.type,
                        progress: 0,
                        storedType,
                        entity,
                        quiet,
                    })) as Partial<IUploadingFile>[];

                    uploadingFile.cancel = async () => this.cancelFiles(uploadingFile as IUploadingFile);
                    uploadingFile.pause = async () => this.pauseFiles(uploadingFile as IUploadingFile);
                    uploadingFile.resume = async () => this.resumeFiles(uploadingFile as IUploadingFile);
                    uploadingFile.resend = async () => this.resendFiles(uploadingFile as IUploadingFile);

                    return uploadingFile as IUploadingFile;
                }
            )
        );

        this._uploadingFiles.push(...uploadingFiles);

        return Promise.all(
            uploadingFiles.map(
                async (uploadingFile) =>
                    this.uploadQueue.add(
                        async (): Promise<IUploadingFile> => this.uploadFile(uploadingFile)
                    ) as Promise<IUploadingFile>
            )
        );
    }

    private async getUploadFilename(
        storedType: STORED_TYPE,
        entity: Gettable<FileEntity>,
        file: File
    ): Promise<string> {
        const prefix = await this.getStoragePrefix(storedType, entity);

        if (FileManagerService.STORED_TYPE_ENTITY_UNIQUE_FILENAME[storedType]) {
            return prefix;
        }

        const expectedFilename = slugify(file.name);
        let path = [prefix, expectedFilename].join('/');

        const {filename} = await this.httpRest
            ._request<{filename: string}>(
                HTTP_METHOD.GET,
                ApiRoutePlurality.SINGULAR,
                FILE_SCHEMA_ROUTE,
                ['uniqueFilename', path].join('/')
            )
            .toPromise();

        if (expectedFilename !== filename) {
            path = [await this.getStoragePrefix(storedType, entity), filename].join('/');
            this.toastService.show({
                type: TOAST_TYPE.WARNING,
                text: await this.translate
                    .get('duplicate_filename', {
                        renamed: filename,
                    })
                    .toPromise(),
            });
        }

        return path;
    }

    private async uploadFile(uploadingFile: IUploadingFile) {
        if (uploadingFile.state === FileStateType.CANCELLED) {
            return undefined;
        }

        uploadingFile.state = FileStateType.PENDING;
        this.uploadingFileState.emit(uploadingFile);
        await this.updateCredentials();

        // @ts-ignore
        if (uploadingFile.state === FileStateType.CANCELLED) {
            return undefined;
        }

        const {file, storedType, entity, ...fileEntity} = uploadingFile;

        const storageFile = await this.httpRest
            ._request<IFileEntity>(HTTP_METHOD.PUT, ApiRoutePlurality.SINGULAR, FILE_SCHEMA_ROUTE, 'fileEntity', {
                storedType,
                entity: typeof entity === 'function' ? entity() : entity,
                ...fileEntity,
            })
            .toPromise();

        uploadingFile.fileId = storageFile.id;

        // @ts-ignore
        if (uploadingFile.state === FileStateType.CANCELLED) {
            return undefined;
        }

        const {state} = uploadingFile;
        uploadingFile.state = FileStateType.UPLOADING;
        this.uploadingFileState.emit(uploadingFile);

        const {sessionToken} = this.s3.config.credentials;
        const xAmzHeadersCommon = {'x-amz-security-token': sessionToken};

        const upload = this.evaporate.add({
            xAmzHeadersCommon,
            xAmzHeadersAtInitiate: {
                ...xAmzHeadersCommon,
                ...FileManagerService.formatMetadataParams({
                    userId: this.authService.user.id.toString(),
                    ...((FileManagerService.STORED_TYPE_METADATA[storedType] &&
                        FileManagerService.STORED_TYPE_METADATA[storedType](entity)) ||
                        {}),
                }),
            },
            file,
            name: fileEntity.path,
            contentType: file.type,
            progress: (progress: number, transferStats: Evaporate.TransferStats) => {
                uploadingFile.progress = progress;
                uploadingFile.transferStats = transferStats;
                this.uploadingFileProgress.emit(uploadingFile);
            },
        } as Evaporate.AddConfig & Partial<Evaporate.CreateConfig>);

        // @ts-ignore
        if (state === FileStateType.PAUSED) {
            await this.evaporate.pause(upload, {force: true});
        }

        let err: AWSError | string = null;
        await upload.catch((_err: AWSError | string) => (err = _err));

        if (err === 'User aborted the upload') {
            await this.cancelFiles(uploadingFile);

            return uploadingFile;
        }

        uploadingFile.state = storageFile.state = err ? FileStateType.ERROR : FileStateType.DONE;
        this.uploadingFileState.emit(uploadingFile);

        await this.httpRest
            .post<IFile>(FILE_SCHEMA_ROUTE, {
                id: storageFile.id,
                state: storageFile.state,
            })
            .toPromise();

        if (err) {
            throw err;
        }

        return uploadingFile;
    }

    public async headFile(
        storedType: STORED_TYPE,
        entity: Gettable<FileEntity>,
        filename?: string,
        versionId?: string
    ): Promise<HeadObjectOutput> {
        const key = FileManagerService.buildPath(await this.getStoragePrefix(storedType, entity), filename);

        await this.updateCredentials();

        return this.s3
            .headObject({
                Bucket: FileManagerService.BUCKET,
                Key: key,
                VersionId: versionId || undefined,
            })
            .promise();
    }

    public async getFile(
        storedType: STORED_TYPE,
        entity: Gettable<FileEntity>,
        filename?: string,
        versionId?: string
    ): Promise<string> {
        const key = FileManagerService.buildPath(await this.getStoragePrefix(storedType, entity), filename);

        await this.updateCredentials();

        const {Body} = await this.s3
            .getObject({
                Bucket: FileManagerService.BUCKET,
                Key: key,
                VersionId: versionId || undefined,
            })
            .promise();

        return (Body as Buffer).toString('utf8');
    }

    public async pauseFiles(...uploadingFiles: IUploadingFile[]): Promise<void> {
        await Promise.all(
            uploadingFiles.map(async (uploadingFile) => {
                await this.evaporate
                    .pause([FileManagerService.BUCKET, uploadingFile.path].join('/'), {
                        force: true,
                    })
                    .catch(noop);

                if (uploadingFile.progress < 1) {
                    uploadingFile.state = FileStateType.PAUSED;
                    this.uploadingFileState.emit(uploadingFile);
                }
            })
        );
    }

    public async resumeFiles(...uploadingFiles: IUploadingFile[]): Promise<void> {
        await Promise.all(
            uploadingFiles.map(async (uploadingFile) => {
                await this.evaporate.resume([FileManagerService.BUCKET, uploadingFile.path].join('/')).catch(noop);

                uploadingFile.state = uploadingFile.progress < 1 ? FileStateType.UPLOADING : FileStateType.DONE;
                this.uploadingFileState.emit(uploadingFile);
            })
        );
    }

    public async cancelFiles(...uploadingFiles: IUploadingFile[]): Promise<void> {
        await Promise.all(
            uploadingFiles.map(async (uploadingFile) => {
                if (uploadingFile.state === FileStateType.DONE) {
                    await this.deleteFiles(uploadingFile as IFile);
                    uploadingFile.state = FileStateType.REMOVED;
                    this.uploadingFileState.emit(uploadingFile);

                    return undefined;
                }

                await this.evaporate.cancel([FileManagerService.BUCKET, uploadingFile.path].join('/')).catch(noop);
                uploadingFile.state = FileStateType.CANCELLED;
                this.uploadingFileState.emit(uploadingFile);
            })
        );

        const fileIds = uploadingFiles.filter(({fileId}) => fileId).map(({fileId}) => fileId);
        if (fileIds.length) {
            await this.httpRest.deleteIds(FILE_SCHEMA_ROUTE, fileIds).toPromise();
        }
    }

    public async resendFiles(...uploadingFiles: IUploadingFile[]): Promise<void> {
        await Promise.all(
            uploadingFiles.map(async (uploadingFile) => {
                uploadingFile.state = FileStateType.PENDING;
                this.uploadingFileState.emit(uploadingFile);

                return this.uploadQueue.add(
                    async (): Promise<IUploadingFile> => this.uploadFile(uploadingFile)
                ) as Promise<IUploadingFile>;
            })
        );
    }

    private async userBucketPrefix(storedType: STORED_TYPE): Promise<string> {
        switch (FileManagerService.STORED_TYPE_RIGHTS[storedType]) {
            case STORAGE_RIGHT.EMPLOYEE:
                return FileManagerService.buildPath(
                    FileManagerService.PREFIX,
                    FileManagerService.STORED_TYPE_PREFIX[storedType]
                );

            case STORAGE_RIGHT.CLIENT:
                const credentials: CognitoIdentityCredentials = await this.authService.getAwsCredentials();

                return FileManagerService.buildPath(
                    FileManagerService.PREFIX,
                    (credentials.data as GetCredentialsForIdentityResponse).IdentityId,
                    FileManagerService.STORED_TYPE_PREFIX[storedType]
                );

            case STORAGE_RIGHT.PUBLIC:
                return FileManagerService.buildPath(
                    FileManagerService.PREFIX,
                    'public',
                    FileManagerService.STORED_TYPE_PREFIX[storedType]
                );

            default:
                throw new Error(`Unkown stored type ${storedType}`);
        }
    }

    public async getStoragePrefix(storedType: STORED_TYPE, entity?: Gettable<FileEntity>) {
        if (typeof entity === 'function') {
            entity = entity();
        }

        if (FileManagerService.STORED_TYPE_ENTITY_PREFIX[storedType] && !entity) {
            throw new Error(`Expected entity for ${storedType} storage type, got ${entity}`);
        }

        return FileManagerService.buildPath(
            await this.userBucketPrefix(storedType),
            FileManagerService.STORED_TYPE_ENTITY_PREFIX[storedType](entity),
            (FileManagerService.STORED_TYPE_ENTITY_UNIQUE_FILENAME[storedType] &&
                FileManagerService.STORED_TYPE_ENTITY_UNIQUE_FILENAME[storedType](entity)) ||
                ''
        );
    }

    public static buildPath(...components: string[]): string {
        return components.filter((prefix) => !!prefix).join('/');
    }

    public static getFilename(file: IFile | Object | string) {
        return ((file as IFile).path || (file as Object).Key || (file as string)).split('/').reverse()[0];
    }

    public static formatMetadataParams(metadata: IExtendedMetadata): IMetadata {
        return Object.keys(metadata || {}).reduce(
            (_metadata, key) => {
                _metadata[`x-amz-meta-${paramCase(key)}`] = metadata[key].toString();

                return _metadata;
            },
            {} as any
        );
    }

    public static parseMetadataParams(metadata: IMetadata): IExtendedMetadata {
        return Object.keys(metadata || {}).reduce(
            (_metadata, key) => {
                const value = metadata[key];
                const valueNumber = Number(value);

                _metadata[camelCase(key.replace(/^x-amz-meta-/, ''))] = isNaN(valueNumber) ? value : valueNumber;

                return _metadata;
            },
            {} as any
        );
    }

    public get uploadingFiles(): IUploadingFile[] {
        return this._uploadingFiles.filter((file) => !file.quiet);
    }

    public get uploadingFilesRunning(): IUploadingFile[] {
        return this.uploadingFiles.filter((file) => FileManagerService.FILE_STATE_RUNNING.includes(file.state));
    }

    public get uploadingFilesOver(): IUploadingFile[] {
        return this.uploadingFiles.filter((file) => FileManagerService.FILE_STATE_OVER.includes(file.state));
    }

    public clearFilesOver(): number {
        const files = this.uploadingFilesRunning;
        const clearedFilesCount = this._uploadingFiles.length - files.length;

        this._uploadingFiles.length = 0;
        this._uploadingFiles.push(...files);

        if (clearedFilesCount) {
            this.uploadingFileState.emit();
        }

        return clearedFilesCount;
    }

    private static readonly STORED_TYPE_ENTITY_ID: {[type in STORED_TYPE]: (entity: Gettable<FileEntity>) => number} = {
        [STORED_TYPE.WIKI_PAGE]: (wikiPage: Gettable<IWikiPage>) =>
            (typeof wikiPage === 'function' ? wikiPage() : wikiPage).id,
        [STORED_TYPE.WIKI_ATTACHMENT]: (wikiAttachment: Gettable<IWikiAttachment>) =>
            (typeof wikiAttachment === 'function' ? wikiAttachment() : wikiAttachment).wikiPageId,

        [STORED_TYPE.CUSTOMER_REQUEST_FILE]: (customerRequestFile: Gettable<ICustomerRequestFile>) =>
            (typeof customerRequestFile === 'function' ? customerRequestFile() : customerRequestFile).customerRequestId,

        [STORED_TYPE.TASK_FILE]: (taskFile: Gettable<ITaskFile>) =>
            (typeof taskFile === 'function' ? taskFile() : taskFile).taskId,

        [STORED_TYPE.IDEA_FILE]: (ideaFile: Gettable<IBoardAttachment>) =>
            (typeof ideaFile === 'function' ? ideaFile() : ideaFile).ideaId,
    };

    private static readonly STORED_TYPE_ENTITY_PREFIX: {
        [type in STORED_TYPE]?: (entity: Gettable<FileEntity>) => string
    } = {
        [STORED_TYPE.WIKI_PAGE]: (wikiPage: Gettable<IWikiPage>) =>
            (typeof wikiPage === 'function' ? wikiPage() : wikiPage).hash,

        [STORED_TYPE.WIKI_ATTACHMENT]: (wikiAttachment: Gettable<IWikiAttachment>) =>
            (typeof wikiAttachment === 'function' ? wikiAttachment() : wikiAttachment).wikiPage.hash,

        [STORED_TYPE.CUSTOMER_REQUEST_FILE]: (customerRequestFile: Gettable<ICustomerRequestFile>) =>
            (typeof customerRequestFile === 'function'
                ? customerRequestFile()
                : customerRequestFile
            ).customerRequestId.toString(),

        [STORED_TYPE.TASK_FILE]: (taskFile: Gettable<ITaskFile>) =>
            (typeof taskFile === 'function' ? taskFile() : taskFile).task.code,

        [STORED_TYPE.IDEA_FILE]: (ideaFile: Gettable<IBoardAttachment>) =>
            (typeof ideaFile === 'function' ? ideaFile() : ideaFile).ideaId.toString(),
    };

    private static readonly STORED_TYPE_ENTITY_UNIQUE_FILENAME: {
        [type in STORED_TYPE]?: (entity: Gettable<FileEntity>) => string
    } = {
        [STORED_TYPE.WIKI_PAGE]: (wikiPage: Gettable<IWikiPage>) => 'content.html',
    };

    private static readonly STORED_TYPE_METADATA: {
        [type in STORED_TYPE]?: (entity: Gettable<FileEntity>) => IExtendedMetadata
    } = {
        [STORED_TYPE.WIKI_ATTACHMENT]: (wikiAttachment: Gettable<IWikiAttachment>) => ({
            wikiPageId: (typeof wikiAttachment === 'function' ? wikiAttachment() : wikiAttachment).wikiPageId,
        }),

        [STORED_TYPE.CUSTOMER_REQUEST_FILE]: (customerRequestFile: Gettable<ICustomerRequestFile>) => ({
            customerRequestId: (typeof customerRequestFile === 'function' ? customerRequestFile() : customerRequestFile)
                .customerRequestId,
        }),

        [STORED_TYPE.TASK_FILE]: (taskFile: Gettable<ITaskFile>) => {
            const task = (typeof taskFile === 'function' ? taskFile() : taskFile).task;

            return {taskId: task.id, clientId: task.project.clientId};
        },
    };

    private static readonly STORED_TYPE_PREFIX: {[type in STORED_TYPE]: string} = {
        [STORED_TYPE.WIKI_PAGE]: 'wiki_page',
        [STORED_TYPE.WIKI_ATTACHMENT]: 'wiki_attachment',
        [STORED_TYPE.CUSTOMER_REQUEST_FILE]: 'customer_request',
        [STORED_TYPE.TASK_FILE]: 'task',
        [STORED_TYPE.IDEA_FILE]: 'idea',
    };

    private static readonly STORED_TYPE_RIGHTS: {[type in STORED_TYPE]: STORAGE_RIGHT} = {
        [STORED_TYPE.WIKI_PAGE]: STORAGE_RIGHT.EMPLOYEE,
        [STORED_TYPE.WIKI_ATTACHMENT]: STORAGE_RIGHT.EMPLOYEE,
        [STORED_TYPE.CUSTOMER_REQUEST_FILE]: STORAGE_RIGHT.CLIENT,
        [STORED_TYPE.TASK_FILE]: STORAGE_RIGHT.EMPLOYEE,
        [STORED_TYPE.IDEA_FILE]: STORAGE_RIGHT.EMPLOYEE,
    };

    public static readonly MAX_CONCURRENT_UPLOADS = 3;
    public static readonly FILE_STATE_RUNNING = [
        FileStateType.WAITING,
        FileStateType.PENDING,
        FileStateType.UPLOADING,
        FileStateType.PAUSED,
    ];
    public static readonly FILE_STATE_OVER = [
        FileStateType.DONE,
        FileStateType.CANCELLED,
        FileStateType.ERROR,
        FileStateType.REMOVED,
    ];
}
