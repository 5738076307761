var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClrDatagrid, ClrDatagridPagination, ClrDatagridSortOrder, } from '@clr/angular';
import { compare } from 'semver';
import { ApiRoutePlurality, HTTP_METHOD, MilestonesType, RIGHTS, TaskResolution, TaskStatusType, TaskType, } from '../../../../../defs/schema-static';
import { MILESTONE_FIELD } from '../../../../../defs/schema/public/Milestones';
import { TASK_FIELD, TASK_SCHEMA_ROUTE } from '../../../../../defs/schema/public/Tasks';
import { TASK_SORT_FUNCTIONS } from '../../../../../defs/sorters';
import { DATAGRID_FILTER_TYPE, PROJECT_TABS, TASK_STATUS_FILTER, } from '../../app-static';
import { FormsAddTaskComponent } from '../../forms/add-task/add-task.component';
import { FormsAddTaskService } from '../../forms/add-task/add-task.service';
import { validate } from '../../forms/validators/form.validator';
import { HttpRestService } from '../../shared/http-rest/http-rest.service';
var ProjectTasksComponent = (function () {
    function ProjectTasksComponent(httpRest, route, router, formsAddTaskService) {
        var _a;
        this.httpRest = httpRest;
        this.route = route;
        this.router = router;
        this.formsAddTaskService = formsAddTaskService;
        this.tasks = [];
        this.pagesTasks = [];
        this.selectedTasks = [];
        this.showCreateModal = false;
        this.showEditModal = false;
        this.showDeleteModal = false;
        this.pageSize = 10;
        this.RIGHTS = RIGHTS;
        this.TASK_FIELD = TASK_FIELD;
        this.PROJECT_TABS = PROJECT_TABS;
        this.DATAGRID_FILTER_TYPE = DATAGRID_FILTER_TYPE;
        this.TaskType = TaskType;
        this.TaskStatusType = TaskStatusType;
        this.TASKS_COLUMNS = [
            {
                field: TASK_FIELD.code,
                translateKey: 'task_code',
                order: ClrDatagridSortOrder.ASC,
                width: '50px',
                hideable: false,
            },
            { field: TASK_FIELD.parent + "." + TASK_FIELD.code, translateKey: 'task_parent', width: '150px', hidden: true },
            { field: TASK_FIELD.name, translateKey: 'task_name' },
            {
                field: TASK_FIELD.type,
                translateKey: 'task_type',
                sorter: {
                    compare: TASK_SORT_FUNCTIONS[TASK_FIELD.type],
                },
                width: '75px',
                hidden: true,
            },
            { field: TASK_FIELD.progress, translateKey: 'task_progress', width: '150px' },
            {
                field: "_metadata." + TASK_FIELD.beginDate,
                translateKey: 'date_begin',
                width: '150px',
                hidden: true,
                filterType: DATAGRID_FILTER_TYPE.DATE,
            },
            {
                field: "_metadata." + TASK_FIELD.endDate,
                translateKey: 'date_end',
                width: '150px',
                hidden: true,
                filterType: DATAGRID_FILTER_TYPE.DATE,
            },
            {
                field: TASK_FIELD.targetRelease + "." + MILESTONE_FIELD.version,
                translateKey: 'version',
                sorter: {
                    compare: TASK_SORT_FUNCTIONS[TASK_FIELD.targetReleaseId],
                },
                width: '100px',
            },
            {
                field: TASK_FIELD.status,
                translateKey: 'task_status',
                sorter: { compare: TASK_SORT_FUNCTIONS[TASK_FIELD.status] },
                width: '100px',
                filterType: DATAGRID_FILTER_TYPE.CUSTOM,
            },
            {
                field: TASK_FIELD.resolution,
                translateKey: 'resolution',
                sorter: { compare: TASK_SORT_FUNCTIONS[TASK_FIELD.resolution] },
                width: '100px',
                hidden: true,
                filterType: DATAGRID_FILTER_TYPE.CUSTOM,
            },
        ];
        this.filterOpen = {};
        this.TASK_RESOLUTION_FILTER = (_a = {},
            _a[TaskResolution.FIXED] = {
                translation: 'fixed',
                classList: 'text-success',
                icon: 'check',
            },
            _a[TaskResolution.DUPLICATE] = {
                translation: 'duplicate',
                classList: 'text-info',
                icon: 'copy',
            },
            _a[TaskResolution.WONT_FIX] = {
                translation: 'wont_fix',
                classList: 'text-error',
                icon: 'times',
            },
            _a[TaskResolution.CANT_REPRODUCE] = {
                translation: 'cant_reproduce',
                classList: 'text-warning',
                icon: 'unknown-status',
            },
            _a);
        this.validate = validate;
        this.TASK_STATUS_FILTER = TASK_STATUS_FILTER;
    }
    ProjectTasksComponent.prototype.ngOnInit = function () {
        var _this = this;
        (function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
            return [2, this.getTasks()];
        }); }); })();
        this.route.queryParams.subscribe(function (queryParams) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.editQueryParams = Number(queryParams.edit) || null;
                        return [4, this.selectedTaskChange()];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        }); });
    };
    ProjectTasksComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes.project) {
            this.tasks = [];
            (function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                return [2, this.getTasks()];
            }); }); })();
            if (this.editQueryParams) {
                (function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                    return [2, this.selectedTaskChange()];
                }); }); })();
            }
        }
    };
    ProjectTasksComponent.prototype.refresh = function (dgState) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.dgState = dgState;
                this.pagesTasks = [];
                this.selectedTasks = [];
                return [2, this.getTasks()];
            });
        });
    };
    ProjectTasksComponent.prototype.closeEditModal = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.router.navigate([], {
                        relativeTo: this.route,
                        queryParams: { edit: null },
                        queryParamsHandling: 'merge',
                    })];
            });
        });
    };
    ProjectTasksComponent.prototype.getTasks = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, pageSize, currentPage, _b, sort, dgFilters, customFilters, filters, order, _c, tasks, count, pageTasks, existingTaskIds, uniqueTasks;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        if (!this.project || !this.project.id) {
                            return [2];
                        }
                        pageSize = this.clrDgPagination.pageSize;
                        if (!pageSize) {
                            return [2];
                        }
                        if (this.pageSize !== pageSize) {
                            this.pageSize = pageSize;
                            this.pagesTasks = [];
                        }
                        currentPage = this.clrDgPagination.currentPage - 1;
                        if (!!this.pagesTasks[currentPage]) return [3, 2];
                        _b = (this.dgState || {}).sort, sort = _b === void 0 ? null : _b;
                        dgFilters = (this.dgState.filters || []);
                        customFilters = dgFilters
                            .filter(function (_filter) { return !!_filter.serialize; })
                            .reduce(function (_filters, _filter) {
                            _filters[_filter.filterKey] = _filter.serialize();
                            return _filters;
                        }, {});
                        filters = dgFilters
                            .filter(function (_filter) { return !_filter.serialize; })
                            .reduce(function (_filters, _filter) {
                            var property = _filter.property, value = _filter.value;
                            _filters[property] = [value];
                            return _filters;
                        }, customFilters);
                        order = [];
                        if (sort) {
                            order.push([sort.by, sort.reverse ? 'DESC' : 'ASC']);
                        }
                        return [4, this.httpRest
                                ._request(HTTP_METHOD.POST, ApiRoutePlurality.PLURAL, TASK_SCHEMA_ROUTE, "project/" + this.project.id, {
                                pageNumber: currentPage,
                                size: pageSize,
                                filters: filters,
                                order: order,
                            })
                                .toPromise()];
                    case 1:
                        _c = _d.sent(), tasks = _c.tasks, count = _c.count;
                        this.taskCount = count;
                        this.pagesTasks[currentPage] = tasks;
                        this.mapReleases(tasks);
                        _d.label = 2;
                    case 2:
                        pageTasks = this.pagesTasks[currentPage];
                        existingTaskIds = this.tasks.map(function (task) { return task.id; });
                        uniqueTasks = pageTasks.filter(function (task) { return !existingTaskIds.includes(task.id); });
                        (_a = this.tasks).push.apply(_a, uniqueTasks);
                        return [2];
                }
            });
        });
    };
    ProjectTasksComponent.prototype.selectedTaskChange = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, task, tasks;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.editQueryParams) {
                            this.selectedTask = null;
                            this.showEditModal = false;
                            return [2];
                        }
                        task = (this.tasks || []).find(function (_task) { return _task.id === _this.editQueryParams; });
                        if (!!task) return [3, 2];
                        if (!this.project.id) {
                            return [2];
                        }
                        return [4, this.httpRest
                                ._request(HTTP_METHOD.POST, ApiRoutePlurality.PLURAL, TASK_SCHEMA_ROUTE, "project/" + this.project.id, {
                                filters: (_a = {}, _a[TASK_FIELD.id] = [this.editQueryParams], _a),
                                pageNumber: 0,
                                size: 1,
                            })
                                .toPromise()];
                    case 1:
                        tasks = (_b.sent()).tasks;
                        task = tasks[0];
                        _b.label = 2;
                    case 2:
                        this.selectedTask = task;
                        this.showEditModal = true;
                        return [2];
                }
            });
        });
    };
    ProjectTasksComponent.prototype.updateFilter = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.getTasks()];
            });
        });
    };
    ProjectTasksComponent.prototype.deleteSelectedTasks = function () {
        return __awaiter(this, void 0, void 0, function () {
            var deletedTasksIds, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        deletedTasksIds = this.selectedTasks.map(function (task) { return task.id; });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4, this.httpRest.deleteIds(TASK_SCHEMA_ROUTE, deletedTasksIds).toPromise()];
                    case 2:
                        _a.sent();
                        return [3, 4];
                    case 3:
                        err_1 = _a.sent();
                        this.showDeleteModal = false;
                        return [2];
                    case 4:
                        this.selectedTasks = [];
                        this.tasks = this.tasks.filter(function (task) { return !deletedTasksIds.includes(task.id); });
                        this.showDeleteModal = false;
                        this.pagesTasks = [];
                        return [2, this.getTasks()];
                }
            });
        });
    };
    Object.defineProperty(ProjectTasksComponent.prototype, "releases", {
        get: function () {
            return (this.project.milestones || [])
                .filter(function (milestone) { return milestone.type === MilestonesType.RELEASE; })
                .sort(function (r1, r2) { return compare(r2.version, r1.version); });
        },
        enumerable: true,
        configurable: true
    });
    ProjectTasksComponent.prototype.loadAddTask = function () {
        var _this = this;
        requestAnimationFrame(function () {
            _this.addTaskComponent.load({ projectId: _this.project.id });
        });
    };
    ProjectTasksComponent.prototype.addTask = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.formsAddTaskService.submit(this.addTaskComponent.form)];
                    case 1:
                        if (!(_a.sent())) {
                            return [2];
                        }
                        this.showCreateModal = false;
                        this.pagesTasks = [];
                        return [2, this.getTasks()];
                }
            });
        });
    };
    ProjectTasksComponent.prototype.mapReleases = function (tasks) {
        if (tasks === void 0) { tasks = this.tasks; }
        var releases = this.releases;
        if (!tasks || !releases) {
            return;
        }
        tasks
            .filter(function (task) { return !!task.targetRelease || !!task.targetReleaseId; })
            .map(function (task) {
            return (task.targetRelease = __assign({}, task.targetRelease, (releases.find(function (release) { return release.id === (task.targetReleaseId || task.targetRelease.id); }) || {})));
        });
    };
    return ProjectTasksComponent;
}());
export { ProjectTasksComponent };
