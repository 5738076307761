import {Directive, EventEmitter, HostListener, Input, Output} from '@angular/core';

@Directive({
    selector: 'textarea[sharedListAutoIndent]',
})
export class ListAutoIndentDirective {
    @Input() public laiBullets: RegExp[] = [/^\s*(\*)\s*/, /^\s*((\d+)\.)\s*/, /^\s*(\[(\s*[xvXV]?\s*)])\s*/];
    @Output() public ngModelChange: EventEmitter<string> = new EventEmitter();

    @Input() public ngModel: string;

    // FIXME kinda fugly, would be better to replace with $event.inputType === 'insert%'
    // https://developer.mozilla.org/en-US/docs/Web/API/InputEvent/inputType
    public previousValue: string;

    @HostListener('input', ['$event'])
    public onInput($event: Event): void {
        const $textarea = $event.target as HTMLTextAreaElement;
        const {value, selectionStart} = $textarea;
        const lines = value.split(/\n/);

        // if inputType === 'delete%'
        // allows deletion of inserted bullet
        const previousValue = this.previousValue || this.ngModel;
        this.previousValue = value;

        // if inputType === 'delete%'
        // allows deletion of inserted bullet
        if (typeof previousValue !== 'undefined' && previousValue.length > value.length) {
            return;
        }

        if (lines.length < 2) {
            return;
        }

        const linesLengthSum = ListAutoIndentDirective.getLinesLengthsSum(lines);
        const currentLineIndex =
            linesLengthSum.findIndex((lengthSum) => lengthSum > selectionStart) || lines.length - 1;
        const beforeCurrentLineIndex = currentLineIndex - 1;

        if (beforeCurrentLineIndex < 0) {
            return;
        }

        const [currentLine, beforeCurrentLine] = [currentLineIndex, beforeCurrentLineIndex].map(
            (index) => lines[index]
        );

        // check if carret at the beginning of the line
        if (!currentLine.match(/^\s*$/) && linesLengthSum[beforeCurrentLineIndex] !== selectionStart) {
            return;
        }

        const [bulletMatch, currentBulletMatch] = [beforeCurrentLine, currentLine].map((line) =>
            this.laiBullets.map((bulletRegex) => line.match(bulletRegex)).find((match) => !!match)
        );

        if (!bulletMatch) {
            return;
        }

        const [fullMatch, _, num] = bulletMatch;
        // tslint:disable-next-line prefer-const
        let [__, bullet] = bulletMatch;

        if (num) {
            if (isNaN(parseInt(num, 10))) {
                bullet = bullet.replace(num, '');
            } else {
                bullet = bullet.replace(num, (parseInt(num, 10) + 1).toString());
            }
        }

        let selection = linesLengthSum[beforeCurrentLineIndex];

        // empty bullet, so we delete it
        if (fullMatch.length === beforeCurrentLine.length) {
            selection -= lines[beforeCurrentLineIndex].length;
            delete lines[beforeCurrentLineIndex];
        } else if (currentBulletMatch) {
            selection += currentBulletMatch[0].length;
        } else {
            lines[currentLineIndex] = `${bullet} ${lines[currentLineIndex]}`;
            selection += `${bullet} `.length;
        }

        this.previousValue = $textarea.value = lines.join('\n');
        if (this.ngModelChange) {
            this.ngModelChange.emit(this.previousValue);
        }

        $textarea.setSelectionRange(selection, selection);
    }

    public static getLinesLengthsSum(lines: string[]): number[] {
        return lines.reduce(
            (_lengthSums, line, index) =>
                _lengthSums.push(line.length + 1 + (_lengthSums[index - 1] || 0)) && _lengthSums,
            [] as number[]
        );
    }
}
