import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {getMilestoneIcon, IDashEvent, isColorDark, trackByIndex} from '../app-static';
import {AuthService} from '../auth/auth.service';
import {MomentService} from '../shared/moment/moment.service';

@Component({
    selector: 'app-dashboard-calendar',
    templateUrl: './dashboard-calendar.component.html',
    styleUrls: ['./dashboard-calendar.component.scss'],
})
export class DashboardCalendarComponent implements OnChanges {
    @Input() public events: IDashEvent[];
    public today = this.momentService.moment();
    public display = false;

    public constructor(public authService: AuthService, private readonly momentService: MomentService) {}

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.events && changes.events.currentValue) {
            this.display = true;
        }
    }

    public isPastEvent = (event: IDashEvent) => {
        if (event.end) {
            return this.momentService.moment(event.end, 'DD/MM/YYYY HH:mm').isBefore(this.momentService.moment());
        }

        return this.momentService.moment(event.begin, 'DD/MM/YYYY HH:mm').isBefore(this.momentService.moment());
    };

    protected readonly getMilestoneIcon = getMilestoneIcon;
    public readonly isColorDark = isColorDark;
    protected readonly trackByIndex = trackByIndex;
}
