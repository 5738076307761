<div class="clr-row">
    <div class="clr-col-6">
        <ng-select
            #itemsNgSelect
            class="ng-select-admin-access-control"
            [items]="selectItems"
            [placeholder]="'select_item' | translate"
            bindLabel="displayName"
            [dropdownPosition]="'bottom'"
            groupBy="level"
            [(ngModel)]="selectedItem"
            (change)="selectItem($event)"
        >
            <ng-template ng-optgroup-tmp let-item="item"> {{ item.level | translate }} </ng-template>
            <ng-template ng-option-tmp ng-label-tmp let-item="item">
                <ng-container *ngIf="item.level === ACCESS_CONTROL_LEVELS.USERS">
                    <app-long-avatar [code]="item.user.code" [bgColor]="item.user.color"> </app-long-avatar>
                </ng-container>
                {{ item.displayName }}
            </ng-template>
        </ng-select>
    </div>
    <div class="clr-col-2 clr-align-self-center btn-add-group-container" *ngIf="groups.length < 60">
        <button class="btn btn-secondary" (click)="addGroup()">
            <clr-icon shape="users"></clr-icon> {{ 'new_group' | translate }}
        </button>
    </div>
</div>

<div class="container">
    <ng-container *ngIf="selectedLevel === ACCESS_CONTROL_LEVELS.GROUPS && selectedGroup">
        <div class="clr-row">
            <div class="clr-col">
                <h1>{{ selectedItem.displayName }}</h1>
                <clr-icon class="c-hand icon-edit" shape="pencil" (click)="editGroup()"></clr-icon>
                <blockquote *ngIf="selectedGroup.description?.length > 0 && selectedGroup.description !== ' '">
                    {{ selectedGroup.description }}
                </blockquote>
                <blockquote class="text-italic" *ngIf="!selectedGroup.description">
                    {{ 'no_description' | translate }}
                </blockquote>
                <button class="btn btn-icon btn-primary" (click)="duplicateGroup()">
                    <clr-icon shape="copy"></clr-icon> {{ 'duplicate_group' | translate }}
                </button>
                <button class="btn btn-icon btn-danger" (click)="deleteGroupModal()">
                    <clr-icon shape="trash"></clr-icon> {{ 'delete_group' | translate }}
                </button>
                <button class="btn btn-icon btn-secondary" (click)="expandAll()">
                    <ng-container *ngIf="!allExpanded">
                        <clr-icon shape="angle-double" dir="down"></clr-icon>
                        {{ 'expand_all' | translate }}
                    </ng-container>
                    <ng-container *ngIf="allExpanded"
                        ><clr-icon shape="angle-double" dir="right"></clr-icon> {{ 'close_all' | translate }}
                    </ng-container>
                </button>
            </div>
        </div>

        <div class="clr-row">
            <div class="clr-col"><div class="divider"></div></div>
        </div>

        <div class="clr-row">
            <div class="clr-col">
                <clr-tree>
                    <clr-tree-node *ngFor="let category of treeRights" [clrExpanded]="category.expanded">
                        <span class="c-hand" (click)="category.expanded = !category.expanded">
                            {{ category.name | translate }}
                        </span>
                        <clr-tree-node *ngFor="let right of category.children">
                            <clr-checkbox-container
                                class="tooltip-right"
                                [class.tooltip-sm]="!right.accessible"
                                [attr.data-tooltip]="right.tip"
                                [class.highlight]="right.highlight"
                                (mouseenter)="enter(right)"
                                (mouseleave)="leave(right)"
                            >
                                <clr-checkbox-wrapper>
                                    <input
                                        type="checkbox"
                                        clrCheckbox
                                        [disabled]="!right.accessible"
                                        [ngModel]="right.active"
                                        (ngModelChange)="right.active = $event; switchRight(right)"
                                    />
                                    <label>{{ right.code }}</label>
                                </clr-checkbox-wrapper>
                                <clr-control-helper class="right-helper">{{
                                    right.code | lowercase | translate
                                }}</clr-control-helper>
                            </clr-checkbox-container>
                        </clr-tree-node>
                    </clr-tree-node>
                </clr-tree>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="selectedLevel === ACCESS_CONTROL_LEVELS.USERS && selectedUser">
        <div class="clr-row">
            <div class="clr-col">
                <h1>{{ selectedUser.name }}</h1>
            </div>
        </div>
        <div class="clr-row">
            <div class="clr-col">
                <div class="clr-row" *ngIf="groups.length > 0; else noGroups">
                    <div class="clr-col">
                        <button
                            class="btn btn-success"
                            [disabled]="!selectUserGroupsChanged"
                            [clrLoading]="saveUserGroupsBtnState"
                            translate
                            (click)="saveGroupsChanges()"
                        >
                            {{ 'save_groups_changes' | translate }}
                        </button>

                        <app-admin-access-users-list
                            [groups]="groups"
                            [(selectedUserGroups)]="selectUserGroups"
                            (selectedUserGroupsChange)="checkSelectUserGroupsChanged()"
                        ></app-admin-access-users-list>
                    </div>
                </div>
                <ng-template #noGroups
                    ><p>{{ 'error_no_groups' | translate }}</p></ng-template
                >
            </div>
        </div>
    </ng-container>
</div>

<app-modal
    [(show)]="showEditModal"
    [appModalSize]="'lg'"
    [title]="currentGroupEdit?.isNew ? ('new_group' | translate) : selectedItem?.displayName"
>
    <ng-template appModalBody>
        <form id="adminAccessForm" clrForm *ngIf="currentGroupEdit">
            <clr-input-container [class.clr-error]="currentGroupEdit.invalid">
                <label class="clr-col-12 clr-col-md-3" translate>group_name</label>
                <input
                    clrInput
                    class="clr-col-12 clr-col-md-9"
                    type="text"
                    [(ngModel)]="currentGroupEdit.groupName"
                    (ngModelChange)="isValid()"
                    [sharedAutofocus]="currentGroupEdit.isNew"
                    name="groupName"
                    required
                    [disabled]="!currentGroupEdit.isNew"
                    maxlength="{{ MAX_LENGTH_COGNITO_CUSTOM_GROUP_NAME }}"
                />
            </clr-input-container>

            <clr-textarea-container>
                <label class="clr-col-12 clr-col-md-3" translate>comment</label>
                <textarea
                    clrTextarea
                    class="clr-col-12 clr-col-md-9"
                    sharedListAutoIndent
                    sharedAutosize
                    [sharedAutofocus]="!currentGroupEdit.isNew"
                    [(ngModel)]="currentGroupEdit.description"
                    name="description"
                    maxlength="{{ MAX_LENGTH_COGNITO_GROUP_DESCRIPTION }}"
                ></textarea>
            </clr-textarea-container>
        </form>
    </ng-template>

    <ng-template appModalFooter>
        <button class="btn btn-secondary" (click)="showEditModal = false" translate>form_cancel</button>
        <button
            form="adminAccessForm"
            class="btn btn-success"
            [disabled]="currentGroupEdit?.invalid"
            [clrLoading]="editGroupBtnState"
            (click)="saveGroup()"
        >
            {{
                (!!currentGroupEdit?.copyFrom
                    ? 'duplicate_group'
                    : currentGroupEdit?.isNew
                    ? 'create_group'
                    : 'save_group') | translate
            }}
        </button>
    </ng-template>
</app-modal>

<app-modal [(show)]="showConfirmSudokuModal" [title]="'groups_edit_modal_title' | translate">
    <ng-template appModalBody>
        <p translate>groups_edit_self_confirm</p>

        <div class="alert alert-warning">
            <div class="alert-items">
                <div class="alert-item static">
                    <div class="alert-icon-wrapper">
                        <clr-icon class="alert-icon" shape="exclamation-circle"></clr-icon>
                    </div>
                    <span class="alert-text" translate>groups_edit_self_consequence</span>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template appModalFooter>
        <button
            class="btn btn-secondary"
            (click)="showConfirmSudokuModal = false"
            [sharedAutofocus]="showConfirmSudokuModal"
            translate
        >
            form_cancel
        </button>
        <button class="btn btn-danger" [clrLoading]="saveUserGroupsBtnState" (click)="saveUserGroups()" translate>
            save_groups_changes
        </button>
    </ng-template>
</app-modal>
