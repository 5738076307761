var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { HttpClient } from '@angular/common/http';
import { API_HTTP_ROOT, API_VERSION } from '../../app-static';
import { AuthService } from '../../auth/auth.service';
import { VersionInterceptor } from '../../version/version.interceptor';
import { FileManagerService } from '../file-manager/file-manager.service';
import * as i0 from "@angular/core";
import * as i1 from "../../auth/auth.service";
import * as i2 from "@angular/common/http";
import * as i3 from "../file-manager/file-manager.service";
var FileDownloaderService = (function () {
    function FileDownloaderService(authService, httpClient, fileManager) {
        this.authService = authService;
        this.httpClient = httpClient;
        this.fileManager = fileManager;
    }
    FileDownloaderService.prototype.downloadAPI = function (uri) {
        return __awaiter(this, void 0, void 0, function () {
            var bewit;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!uri.startsWith(API_HTTP_ROOT)) {
                            uri = [API_HTTP_ROOT, API_VERSION, uri].join('/');
                        }
                        uri = uri + "?v=" + VersionInterceptor.WWW_VERSION;
                        return [4, this.httpClient
                                .get([API_HTTP_ROOT, API_VERSION, 'sign', encodeURIComponent(uri)].join('/'))
                                .toPromise()];
                    case 1:
                        bewit = (_a.sent()).bewit;
                        FileDownloaderService.openInNewTab(uri + "&bewit=" + bewit);
                        return [2];
                }
            });
        });
    };
    FileDownloaderService.prototype.downloadS3 = function (file, attachment) {
        if (attachment === void 0) { attachment = false; }
        return __awaiter(this, void 0, void 0, function () {
            var signedFile, _a, getObject, getObjectAttachment;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4, this.fileManager.signFiles(file)];
                    case 1:
                        signedFile = (_b.sent())[0];
                        _a = signedFile.signed, getObject = _a.getObject, getObjectAttachment = _a.getObjectAttachment;
                        FileDownloaderService.openInNewTab(attachment ? getObjectAttachment : getObject);
                        return [2];
                }
            });
        });
    };
    FileDownloaderService.openInNewTab = function (url) {
        window.open(url, '_blank');
    };
    FileDownloaderService.ngInjectableDef = i0.defineInjectable({ factory: function FileDownloaderService_Factory() { return new FileDownloaderService(i0.inject(i1.AuthService), i0.inject(i2.HttpClient), i0.inject(i3.FileManagerService)); }, token: FileDownloaderService, providedIn: "root" });
    return FileDownloaderService;
}());
export { FileDownloaderService };
