import {EventEmitter, Injectable} from '@angular/core';
import {Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {FormControl, FormGroup} from 'ngx-strongly-typed-forms';
import {ISO_DATE_FORMAT, MAX_LENGTH_DEFAULT, MilestonesType} from '../../../../defs/schema-static';
import {
    IMeetingParticipant,
    MEETING_PARTICIPANT_SCHEMA_ROUTE,
} from '../../../../defs/schema/public/MeetingParticipants';
import {IMilestone, MILESTONE_SCHEMA_ROUTE} from '../../../../defs/schema/public/Milestones';
import {noop} from '../app-static';
import {AuthService} from '../auth/auth.service';
import {HttpRestService} from '../shared/http-rest/http-rest.service';
import {MomentService} from '../shared/moment/moment.service';
import {TOAST_TYPE, ToastService} from '../shared/toast/toast.service';
import {requiredTrimValidator} from './validators/required-trim.validator';
import {semVerValidator} from './validators/semver.validator';

export enum MilestoneTarget {
    CLIENT = 'client',
    PROJECT = 'project',
}

export interface IMilestoneFormValues {
    id?: number;
    type: MilestonesType;
    targetType: MilestoneTarget;
    project?: number;
    client?: number;
    milestone: string;
    description: string;
    target: string;
    startDate: string;
    startTime: string;
    endDate: string;
    endTime: string;
    version: string;
    participants?: Partial<IMeetingParticipant>[];
    author?: number;
}

export enum MILESTONE_FORM_KEYS {
    type = 'type',
    targetType = 'targetType',
    project = 'project',
    client = 'client',
    milestone = 'milestone',
    description = 'description',
    target = 'target',
    startDate = 'startDate',
    startTime = 'startTime',
    endDate = 'endDate',
    endTime = 'endTime',
    recursive = 'recursive',
    version = 'version',
    participants = 'participants',
}

@Injectable({
    providedIn: 'root',
})
export class FormsAddMilestoneService {
    public onSubmit: EventEmitter<IMilestone> = new EventEmitter();
    public onAbort: EventEmitter<boolean> = new EventEmitter();

    public constructor(
        private readonly httpRest: HttpRestService,
        private readonly toastService: ToastService,
        private readonly translate: TranslateService,
        private readonly authService: AuthService,
        private readonly momentService: MomentService
    ) {}

    public static getFormGroup(): FormGroup<IMilestoneFormValues> {
        return new FormGroup<IMilestoneFormValues>({
            type: new FormControl<MilestonesType>(null, Validators.required),
            targetType: new FormControl<MilestoneTarget>(null, Validators.required),
            project: new FormControl<number>(),
            client: new FormControl<number>(),
            description: new FormControl<string>(),
            milestone: new FormControl<string>(null, requiredTrimValidator()),
            target: new FormControl<string>(null, requiredTrimValidator()),
            startDate: new FormControl<string>(null, Validators.required),
            startTime: new FormControl<string>(),
            endDate: new FormControl<string>(null, Validators.required),
            endTime: new FormControl<string>(),
            version: new FormControl<string>(),
            participants: new FormControl(),
        });
    }

    private getEndDate(milestone: IMilestoneFormValues) {
        switch (milestone.type) {
            case MilestonesType.DEADLINE:
            case MilestonesType.RELEASE:
                return null;
            case MilestonesType.MEETING:
            case MilestonesType.MEETING_NO_NOTE:
                return this.momentService.moment(`${milestone.endDate}T${milestone.endTime}`);
            case MilestonesType.CALL:
            case MilestonesType.REMINDER:
                return this.momentService.moment(`${milestone.startDate}T${milestone.startTime}`).add(15, 'minutes');
            default:
                return undefined;
        }
    }

    private getEndDateOnEdit(milestone: IMilestoneFormValues) {
        switch (milestone.type) {
            case MilestonesType.DEADLINE:
            case MilestonesType.RELEASE:
                return this.momentService.moment(`${milestone.endDate}T${milestone.endTime}`).isValid()
                    ? this.momentService.moment(`${milestone.endDate}T${milestone.endTime}`)
                    : null;
            case MilestonesType.MEETING:
            case MilestonesType.MEETING_NO_NOTE:
                return this.momentService.moment(`${milestone.endDate}T${milestone.endTime}`);
            case MilestonesType.CALL:
            case MilestonesType.REMINDER:
                return this.momentService.moment(`${milestone.startDate}T${milestone.startTime}`).add(15, 'minutes');
            default:
                return undefined;
        }
    }

    public getDate(milestone: IMilestoneFormValues) {
        let beginDate = milestone.startDate;
        if (milestone.startTime && milestone.startTime !== 'Invalid date') {
            beginDate += `T${milestone.startTime}`;
        }

        return {
            target:
                milestone.type === MilestonesType.RELEASE
                    ? this.momentService
                          .moment(`${milestone.target}T17:00:00Z`)
                          .add(-this.momentService.moment().utcOffset(), 'm')
                    : null,
            beginDate: this.momentService.moment(beginDate),
            endDate: this.getEndDateOnEdit(milestone),
        };
    }

    public async edit(
        f: IMilestoneFormValues,
        added: number[] = [],
        removed: number[] = [],
        callback: (milestone: IMilestone) => void = noop
    ): Promise<IMilestone> {
        const milestoneDates = this.getDate(f);

        if (f.type !== MilestonesType.MEETING && f.type !== MilestonesType.MEETING_NO_NOTE) {
            milestoneDates.endDate = null;
        }
        if (f.type !== MilestonesType.RELEASE) {
            milestoneDates.target = null;
        }

        const milestone = await this.httpRest
            .post<IMilestone>(MILESTONE_SCHEMA_ROUTE, {
                id: f.id,
                obs: f.milestone,
                description: f.description,
                version: f.version,
                beginDate: milestoneDates.beginDate,
                endDate: milestoneDates.endDate,
                target: milestoneDates.target,
            })
            .toPromise();

        {
            if (removed.length > 0) {
                await Promise.all(
                    removed.map(async (id) => {
                        await this.httpRest
                            .deleteQuery(MEETING_PARTICIPANT_SCHEMA_ROUTE, {
                                employeeId: id,
                                milestoneId: milestone.id,
                            })
                            .toPromise();
                    })
                );
            }

            if (added.length > 0) {
                await Promise.all(
                    added.map(async (emp) => {
                        await this.httpRest
                            .put(MEETING_PARTICIPANT_SCHEMA_ROUTE, {
                                employeeId: emp,
                                milestoneId: milestone.id,
                            })
                            .toPromise();
                    })
                );
            }

            this.toastService.show({
                type: TOAST_TYPE.SUCCESS,
                text: 'toast_updated_milestone',
            });

            if (callback) {
                callback(milestone);
            }
            this.onSubmit.emit(milestone);
        }

        return milestone;
    }

    public async submit(
        f: IMilestoneFormValues,
        callback: (milestone: IMilestone) => void = noop
    ): Promise<IMilestone> {
        const participants = f.participants ? [...f.participants] : [];
        const milestoneDates = this.getDate(f);

        if (f.type !== MilestonesType.MEETING && f.type !== MilestonesType.MEETING_NO_NOTE) {
            milestoneDates.endDate = null;
        }
        if (f.type !== MilestonesType.RELEASE) {
            milestoneDates.target = null;
        }

        const milestone = await this.httpRest
            .put<IMilestone>(MILESTONE_SCHEMA_ROUTE, {
                target: milestoneDates.target,
                beginDate: milestoneDates.beginDate,
                endDate: milestoneDates.endDate,
                version: f.version,
                type: f.type,
                description: f.description,
                obs: f.milestone,
                clientId: (f.targetType === MilestoneTarget.CLIENT && f.client) || null,
                projectId: (f.targetType === MilestoneTarget.PROJECT && f.project) || null,
                employeeId: this.authService.user.employee.id,
            })
            .toPromise();

        {
            if (participants.length > 0) {
                await this.httpRest
                    .putEntities(
                        MEETING_PARTICIPANT_SCHEMA_ROUTE,
                        participants.map((emp) => ({
                            employeeId: emp.employee.id,
                            milestoneId: milestone.id,
                        }))
                    )
                    .toPromise();
            }

            this.toastService.show({
                type: TOAST_TYPE.SUCCESS,
                text: 'toast_added_milestone',
            });

            Object.assign(milestone, {
                participants: participants.map((p) => {
                    return {
                        employeeId: p.employee.id,
                        employee: p.employee,
                        milestoneId: milestone.id,
                    };
                }),
            });

            if (callback) {
                callback(milestone);
            }
            this.onSubmit.emit(milestone);
        }

        return milestone;
    }

    public abort() {
        this.onAbort.emit();
    }

    public static toggle(form: FormGroup<Partial<IMilestoneFormValues>>, type?: MilestonesType) {
        const {controls, value} = form;
        const _type = value.type || type;
        controls.endTime.disable();
        controls.startTime.disable();
        switch (_type) {
            case MilestonesType.CALL:
            case MilestonesType.REMINDER:
                controls.version.clearValidators();
                controls.version.updateValueAndValidity();
                controls.target.disable();
                controls.endDate.disable();
                controls.startTime.enable();
                break;
            case MilestonesType.DEADLINE:
                controls.version.clearValidators();
                controls.version.updateValueAndValidity();
                controls.target.disable();
                controls.endDate.disable();
                break;
            case MilestonesType.MEETING:
            case MilestonesType.MEETING_NO_NOTE:
                requestAnimationFrame(() => {
                    controls.version.clearValidators();
                    controls.version.updateValueAndValidity();
                    controls.target.disable();
                    controls.endDate.enable();
                    controls.endTime.enable();
                    controls.startTime.enable();
                });
                break;
            case MilestonesType.RELEASE:
                controls.endDate.disable();
                controls.version.setValidators([semVerValidator(), Validators.maxLength(MAX_LENGTH_DEFAULT)]);
                controls.target.enable();
                break;
            default:
                return;
        }
    }

    public beginEndDates = (b: string, bt: string, e: string, et: string) => {
        const beginDate = this.momentService.moment(b);
        const endDate = this.momentService.moment(e);
        if (bt && et && this.momentService.moment(beginDate).isSame(this.momentService.moment(endDate))) {
            return bt <= et;
        }

        return this.momentService.moment(beginDate).isSameOrBefore(this.momentService.moment(endDate));
    };

    public checkDates = (
        form: FormGroup<Partial<IMilestoneFormValues>>,
        begin: Date,
        end: Date,
        target: Date,
        type: MilestonesType
    ): boolean => {
        form.patchValue({
            startDate: begin ? this.momentService.moment(begin).format(ISO_DATE_FORMAT) : null,
            endDate: end ? this.momentService.moment(end).format(ISO_DATE_FORMAT) : null,
            target: target ? this.momentService.moment(target).format(ISO_DATE_FORMAT) : null,
        });

        let result = false;
        switch (type) {
            case MilestonesType.CALL:
            case MilestonesType.REMINDER:
                result = form.controls.startDate.valid && Boolean(form.value.startTime);
                break;
            case MilestonesType.DEADLINE:
                result = form.controls.startDate.valid;
                break;
            case MilestonesType.MEETING:
            case MilestonesType.MEETING_NO_NOTE:
                result =
                    form.controls.startDate.valid &&
                    Boolean(form.value.startTime) &&
                    form.controls.endDate.valid &&
                    Boolean(form.value.endTime) &&
                    this.beginEndDates(
                        form.value.startDate,
                        form.value.startTime,
                        form.value.endDate,
                        form.value.endTime
                    );
                break;
            case MilestonesType.RELEASE:
                result =
                    form.controls.startDate.valid &&
                    form.controls.target.valid &&
                    this.beginEndDates(form.value.startDate, null, form.value.target, null);
                break;
            default:
                break;
        }

        if (!result) {
            form.controls.startDate.setErrors({validDate: {value: null}});
        }

        return result;
    };

    public static readonly HOUR_MINUTE_TIME_FORMAT = 'HH:mm';
}
