var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
var _a;
import { OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClrLoadingState } from '@clr/angular';
import { TranslateService } from '@ngx-translate/core';
import { noop } from 'rxjs';
import publicConfig from '../../../../defs/config/config.json';
import { ApiRoutePlurality, BudgetStatus, DECIMAL_RADIX, HTTP_METHOD, ProjectMemberType, ProjectStatusType, RIGHTS, } from '../../../../defs/schema-static';
import { CLIENT_SCHEMA_ROUTE } from '../../../../defs/schema/public/Clients';
import { CUSTOMER_CONTACT_SCHEMA_ROUTE } from '../../../../defs/schema/public/CustomerContacts';
import { EMPLOYEE_SCHEMA_ROUTE } from '../../../../defs/schema/public/Employees';
import { MILESTONE_SCHEMA_ROUTE } from '../../../../defs/schema/public/Milestones';
import { PROJECT_SCHEMA_ROUTE } from '../../../../defs/schema/public/Projects';
import { STICKY_NOTE_SCHEMA_ROUTE } from '../../../../defs/schema/public/StickyNote';
import { USER_SCHEMA_ROUTE } from '../../../../defs/schema/public/Users';
import { CUSTOMER_CONTACT_SORT_FUNCTION, PROJECT_STATUS_SORT_FUNCTION } from '../../../../defs/sorters';
import { CLIENT_TABS, CLIENT_TABS_LABELS, CLIENT_TABS_RIGHTS, isColorDark, PROJECT_STATUS_FILTER, } from '../app-static';
import { AuthService } from '../auth/auth.service';
import { ClientWizardClientComponent } from '../client-wizard/client/client-wizard-client.component';
import { ClientWizardCustomerContactsComponent } from '../client-wizard/customer-contacts/client-wizard-customer-contacts.component';
import { validate } from '../forms/validators/form.validator';
import { ModalSimpleComponent } from '../modal-simple/modal-simple.component';
import { ModalSimpleService } from '../modal-simple/modal-simple.service';
import { ProjectsListComponent } from '../projects/list/projects-list.component';
import { HttpRestService } from '../shared/http-rest/http-rest.service';
import { LONG_AVATAR_TYPE } from '../shared/long-avatar/long-avatar.component';
import { SHORTCUT_CREATE, SHORTCUT_LOCAL, ShortcutHandlerService, } from '../shared/shortcut-handler/shortcut-handler.service';
import { TOAST_TYPE, ToastService } from '../shared/toast/toast.service';
var LIST_TABLE_COLUMN;
(function (LIST_TABLE_COLUMN) {
    LIST_TABLE_COLUMN["PAGE"] = "PAGE";
    LIST_TABLE_COLUMN["CLIENT"] = "CLIENT";
    LIST_TABLE_COLUMN["PROJECT"] = "PROJECT";
    LIST_TABLE_COLUMN["AUTHOR"] = "AUTHOR";
    LIST_TABLE_COLUMN["MODIFICATION_DATE"] = "MODIFICATION_DATE";
})(LIST_TABLE_COLUMN || (LIST_TABLE_COLUMN = {}));
var LIST_TABLE_COLUMN_NAME = (_a = {},
    _a[LIST_TABLE_COLUMN.PAGE] = 'Page',
    _a[LIST_TABLE_COLUMN.CLIENT] = 'Client',
    _a[LIST_TABLE_COLUMN.PROJECT] = 'Project',
    _a[LIST_TABLE_COLUMN.AUTHOR] = 'Author',
    _a[LIST_TABLE_COLUMN.MODIFICATION_DATE] = 'Modification date',
    _a);
var ClientComponent = (function () {
    function ClientComponent(httpRest, route, router, authService, shortcutHandlerService, translate, toastService, modalSimpleService) {
        this.httpRest = httpRest;
        this.route = route;
        this.router = router;
        this.authService = authService;
        this.shortcutHandlerService = shortcutHandlerService;
        this.translate = translate;
        this.toastService = toastService;
        this.modalSimpleService = modalSimpleService;
        this.client = {
            obs: '',
            userId: 0,
            user: {
                code: '',
                name: '',
            },
            contacts: [],
        };
        this.showEditModal = false;
        this.projectsColumns = ProjectsListComponent.DEFAULT_PROJECTS_COLUMNS.map(function (column) { return (__assign({}, column)); });
        this.stickyNotes = [];
        this.validateBtnState = ClrLoadingState.DEFAULT;
        this.CLIENT_TABS = CLIENT_TABS;
        this.CLIENT_TABS_LABELS = CLIENT_TABS_LABELS;
        this.validate = validate;
        this.isColorDark = isColorDark;
        this.publicConfig = publicConfig;
        this.RIGHTS = RIGHTS;
        this.CLIENT_TABS_RIGHTS = CLIENT_TABS_RIGHTS;
        this.LONG_AVATAR_TYPE = LONG_AVATAR_TYPE;
        this.PROJECT_STATUS_FILTER = PROJECT_STATUS_FILTER;
        this.ProjectStatusType = ProjectStatusType;
        this.projectsColumns.find(function (_a) {
            var translateKey = _a.translateKey;
            return translateKey === 'table_client';
        }).hidden = true;
    }
    ClientComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.shortcutHandlerService.register({
            name: SHORTCUT_CREATE.CLIENT,
            shortcut: SHORTCUT_LOCAL.CANCEL,
            callback: function () {
                (function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                    return [2, this.router.navigate(['/clients'])];
                }); }); })();
            },
            context: this,
        }, true);
        this.route.params.subscribe(function (params) { return __awaiter(_this, void 0, void 0, function () {
            var clientId, getClient, mapProjectMembers, getEmployees, getProjects, getMilestones, getStickyNotes;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        clientId = parseInt(params.id, DECIMAL_RADIX);
                        getClient = function () { return __awaiter(_this, void 0, void 0, function () {
                            var _a;
                            return __generator(this, function (_b) {
                                switch (_b.label) {
                                    case 0:
                                        _a = this;
                                        return [4, this.httpRest
                                                ._request(HTTP_METHOD.GET, ApiRoutePlurality.SINGULAR, CLIENT_SCHEMA_ROUTE, "" + clientId)
                                                .toPromise()];
                                    case 1: return [2, (_a.client = _b.sent())];
                                }
                            });
                        }); };
                        mapProjectMembers = function () {
                            if (!_this.projects || !_this.employees) {
                                return;
                            }
                            _this.projects.map(function (project) {
                                return project.projectMembers.map(function (projectMember) {
                                    return (projectMember.employee = _this.employees.find(function (_a) {
                                        var id = _a.id;
                                        return id === projectMember.employeeId;
                                    }));
                                });
                            });
                        };
                        getEmployees = function () { return __awaiter(_this, void 0, void 0, function () {
                            var _a, contacts;
                            var _this = this;
                            return __generator(this, function (_b) {
                                switch (_b.label) {
                                    case 0:
                                        _a = this;
                                        return [4, this.httpRest
                                                ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, EMPLOYEE_SCHEMA_ROUTE, 'client')
                                                .toPromise()];
                                    case 1:
                                        _a.employees = _b.sent();
                                        return [4, this.httpRest
                                                ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, CUSTOMER_CONTACT_SCHEMA_ROUTE, "client/" + clientId)
                                                .toPromise()];
                                    case 2:
                                        contacts = _b.sent();
                                        this.contacts = contacts
                                            .map(function (contact) { return (__assign({}, contact, { employee: _this.employees.find(function (_a) {
                                                var id = _a.id;
                                                return id === contact.employeeId;
                                            }) })); })
                                            .sort(CUSTOMER_CONTACT_SORT_FUNCTION);
                                        this.client.contacts = this.contacts.map(function (_a) {
                                            var employee = _a.employee;
                                            return employee;
                                        });
                                        mapProjectMembers();
                                        return [2];
                                }
                            });
                        }); };
                        getProjects = function () { return __awaiter(_this, void 0, void 0, function () {
                            var projects;
                            var _this = this;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4, this.httpRest
                                            ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, PROJECT_SCHEMA_ROUTE, "client/" + clientId)
                                            .toPromise()];
                                    case 1:
                                        projects = _a.sent();
                                        this.client.projects = this.projects = projects
                                            .map(function (project) { return (__assign({ client: _this.client, roleType: project.projectMembers
                                                .filter(function (projectMember) { return projectMember.employeeId === _this.authService.user.employee.id; })
                                                .map(function (projectMember) { return projectMember.type; })[0] || undefined, managers: (project.projectMembers || []).filter(function (projectMember) { return projectMember.type === ProjectMemberType.MANAGER; }), budgetSum: project.budgets
                                                .filter(function (budget) { return budget.status !== BudgetStatus.CANCELLED; })
                                                .reduce(function (sum, budget) { return sum + budget.price; }, 0) || 0 }, project)); })
                                            .sort(PROJECT_STATUS_SORT_FUNCTION);
                                        mapProjectMembers();
                                        return [2];
                                }
                            });
                        }); };
                        getMilestones = function () { return __awaiter(_this, void 0, void 0, function () {
                            var _a;
                            return __generator(this, function (_b) {
                                switch (_b.label) {
                                    case 0:
                                        _a = this;
                                        return [4, this.httpRest
                                                ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, MILESTONE_SCHEMA_ROUTE, "client/" + clientId)
                                                .toPromise()];
                                    case 1: return [2, (_a.milestones = _b.sent())];
                                }
                            });
                        }); };
                        getStickyNotes = function () { return __awaiter(_this, void 0, void 0, function () {
                            var _a;
                            return __generator(this, function (_b) {
                                switch (_b.label) {
                                    case 0:
                                        _a = this;
                                        return [4, this.httpRest
                                                ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, STICKY_NOTE_SCHEMA_ROUTE, "client/" + clientId)
                                                .toPromise()];
                                    case 1: return [2, (_a.stickyNotes = _b.sent())];
                                }
                            });
                        }); };
                        return [4, getClient()];
                    case 1:
                        _a.sent();
                        return [2, Promise.all([getEmployees(), getProjects(), getMilestones(), getStickyNotes()])];
                }
            });
        }); });
    };
    ClientComponent.prototype.ngOnDestroy = function () { };
    ClientComponent.prototype.saveClient = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b, obs, user, contacts, contactsToAdd, contactsToDelete, _c, _client, _user, _deletedConctactsCount, addedContacts_1, err_1;
            var _this = this;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        if (!this.clientEditComponent.form || !this.clientEditComponent.form.valid) {
                            return [2];
                        }
                        this.validateBtnState = ClrLoadingState.LOADING;
                        _a = this.clientEditComponent.form.getRawValue(), _b = _a.obs, obs = _b === void 0 ? '' : _b, user = __rest(_a, ["obs"]);
                        contacts = this.contactsEditComponent.form.getRawValue().contacts;
                        contactsToAdd = contacts.filter(function (contact) { return !_this.contacts.find(function (_a) {
                            var employeeId = _a.employeeId;
                            return contact.employeeId === employeeId;
                        }); });
                        contactsToDelete = this.contacts.filter(function (contact) { return !contacts.find(function (_a) {
                            var employeeId = _a.employeeId;
                            return contact.employeeId === employeeId;
                        }); });
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 3, , 4]);
                        return [4, Promise.all([
                                this.httpRest.post(CLIENT_SCHEMA_ROUTE, {
                                    id: this.client.id,
                                    obs: obs,
                                }),
                                this.httpRest.post(USER_SCHEMA_ROUTE, __assign({ id: this.client.user.id }, user)),
                                this.httpRest.deleteIds(CUSTOMER_CONTACT_SCHEMA_ROUTE, contactsToDelete.map(function (_a) {
                                    var id = _a.id;
                                    return id;
                                }))
                            ].concat(contactsToAdd.map(function (contact) {
                                return _this.httpRest.put(CUSTOMER_CONTACT_SCHEMA_ROUTE, {
                                    clientId: _this.client.id,
                                    employeeId: contact.employee.id,
                                });
                            })).map(function (observable) { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                                return [2, observable.toPromise()];
                            }); }); }))];
                    case 2:
                        _c = _d.sent(), _client = _c[0], _user = _c[1], _deletedConctactsCount = _c[2], addedContacts_1 = _c.slice(3);
                        contacts.map(function (contact) {
                            var addedContact = addedContacts_1.find(function (_a) {
                                var employeeId = _a.employeeId;
                                return employeeId === contact.employeeId;
                            });
                            contact.id = (addedContact && addedContact.id) || contact.id;
                        });
                        return [3, 4];
                    case 3:
                        err_1 = _d.sent();
                        this.showEditModal = false;
                        return [2];
                    case 4:
                        this.validateBtnState = ClrLoadingState.SUCCESS;
                        Object.assign(this.client, { obs: obs });
                        Object.assign(this.client.user, user);
                        this.toastService.show({
                            type: TOAST_TYPE.SUCCESS,
                            text: 'success_update_client',
                        });
                        this.contacts = contacts;
                        this.client.contacts = this.contacts.map(function (_a) {
                            var employee = _a.employee;
                            return employee;
                        });
                        this.showEditModal = false;
                        return [2];
                }
            });
        });
    };
    ClientComponent.prototype.deleteClientModal = function () {
        var _this = this;
        this.modalSimpleService
            .open(ModalSimpleComponent, {
            title: 'client_delete_modal_title',
            contentI18n: 'swal_delete_attch',
            contentObj: { what: this.client.obs },
            ok: {
                i18n: 'delete_client',
                class: 'btn-danger',
            },
        })
            .subscribe(function (closed) {
            if (closed.result) {
                _this.deleteClient();
            }
        }, noop);
    };
    ClientComponent.prototype.deleteClient = function () {
        return __awaiter(this, void 0, void 0, function () {
            var err_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4, this.httpRest
                                ._request(HTTP_METHOD.DELETE, ApiRoutePlurality.SINGULAR, CLIENT_SCHEMA_ROUTE, "withUser/" + this.client.id)
                                .toPromise()];
                    case 1:
                        _a.sent();
                        return [3, 3];
                    case 2:
                        err_2 = _a.sent();
                        return [2, undefined];
                    case 3:
                        this.toastService.show({
                            type: TOAST_TYPE.SUCCESS,
                            text: 'success_delete_client',
                        });
                        return [2, this.router.navigate(['/clients'])];
                }
            });
        });
    };
    return ClientComponent;
}());
export { ClientComponent };
