<app-modal [(show)]="httpRest.modal.show" [title]="httpRest.modal.title | translate">
    <ng-template appModalBody> <div [innerHTML]="httpRest.modal.content"></div> </ng-template>
    <ng-template appModalFooter>
        <button *ngIf="!httpRest.modal.callback" class="btn btn-primary" (click)="httpRest.modal.show = false">
            {{ 'form_close' | translate }}
        </button>
        <button *ngIf="!!httpRest.modal.callback" class="btn btn-primary" (click)="httpRest.modal.callback()">
            {{ httpRest.modal.callbackLabel | translate }}
        </button>
    </ng-template>
</app-modal>
