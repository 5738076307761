import * as i0 from "./gantt-milestone.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@clr/angular";
import * as i3 from "../gantt-popover/gantt-popover.component.ngfactory";
import * as i4 from "../gantt-popover/gantt-popover.component";
import * as i5 from "@angular/common";
import * as i6 from "./gantt-milestone.component";
var styles_GanttMilestoneComponent = [i0.styles];
var RenderType_GanttMilestoneComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GanttMilestoneComponent, data: {} });
export { RenderType_GanttMilestoneComponent as RenderType_GanttMilestoneComponent };
function View_GanttMilestoneComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "clr-icon", [["class", "gantt-ms is-solid popover"], ["shape", "users"]], [[2, "popover-bottom", null], [2, "popover-top", null]], null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.ClrIconCustomTag, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-gantt-popover", [], null, null, null, i3.View_GanttPopoverComponent_0, i3.RenderType_GanttPopoverComponent)), i1.ɵdid(3, 49152, null, 0, i4.GanttPopoverComponent, [], { milestone: [0, "milestone"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.ganttms; _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.popBottom; var currVal_1 = !_co.popBottom; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_GanttMilestoneComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "clr-icon", [["class", "gantt-ms is-solid popover"], ["shape", "calendar"]], [[2, "popover-bottom", null], [2, "popover-top", null]], null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.ClrIconCustomTag, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-gantt-popover", [], null, null, null, i3.View_GanttPopoverComponent_0, i3.RenderType_GanttPopoverComponent)), i1.ɵdid(3, 49152, null, 0, i4.GanttPopoverComponent, [], { milestone: [0, "milestone"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.ganttms; _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.popBottom; var currVal_1 = !_co.popBottom; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_GanttMilestoneComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "clr-icon", [["class", "gantt-ms is-solid popover"], ["shape", "clock"]], [[2, "popover-bottom", null], [2, "popover-top", null]], null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.ClrIconCustomTag, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-gantt-popover", [], null, null, null, i3.View_GanttPopoverComponent_0, i3.RenderType_GanttPopoverComponent)), i1.ɵdid(3, 49152, null, 0, i4.GanttPopoverComponent, [], { milestone: [0, "milestone"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.ganttms; _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.popBottom; var currVal_1 = !_co.popBottom; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_GanttMilestoneComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "clr-icon", [["class", "gantt-ms is-solid popover"], ["shape", "phone-handset"]], [[2, "popover-bottom", null], [2, "popover-top", null]], null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.ClrIconCustomTag, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-gantt-popover", [], null, null, null, i3.View_GanttPopoverComponent_0, i3.RenderType_GanttPopoverComponent)), i1.ɵdid(3, 49152, null, 0, i4.GanttPopoverComponent, [], { milestone: [0, "milestone"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.ganttms; _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.popBottom; var currVal_1 = !_co.popBottom; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_GanttMilestoneComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_GanttMilestoneComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GanttMilestoneComponent_2)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GanttMilestoneComponent_3)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GanttMilestoneComponent_4)), i1.ɵdid(7, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.ganttms.milestone.type === _co.MilestonesType.MEETING) || (_co.ganttms.milestone.type === _co.MilestonesType.MEETING_NO_NOTE)); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.ganttms.milestone.type === _co.MilestonesType.DEADLINE); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.ganttms.milestone.type === _co.MilestonesType.REMINDER); _ck(_v, 5, 0, currVal_2); var currVal_3 = (_co.ganttms.milestone.type === _co.MilestonesType.CALL); _ck(_v, 7, 0, currVal_3); }, null); }
export function View_GanttMilestoneComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-gantt-milestone", [], null, null, null, View_GanttMilestoneComponent_0, RenderType_GanttMilestoneComponent)), i1.ɵdid(1, 49152, null, 0, i6.GanttMilestoneComponent, [], null, null)], null, null); }
var GanttMilestoneComponentNgFactory = i1.ɵccf("app-gantt-milestone", i6.GanttMilestoneComponent, View_GanttMilestoneComponent_Host_0, { ganttms: "ganttms", popBottom: "popBottom" }, {}, []);
export { GanttMilestoneComponentNgFactory as GanttMilestoneComponentNgFactory };
