import * as i0 from "./file-manager-progress.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "./file-manager-progress.component";
import * as i5 from "../file-manager/file-manager.service";
var styles_FileManagerProgressComponent = [i0.styles];
var RenderType_FileManagerProgressComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FileManagerProgressComponent, data: {} });
export { RenderType_FileManagerProgressComponent as RenderType_FileManagerProgressComponent };
function View_FileManagerProgressComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" (", ") "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.formatRemainingSeconds(((_co.file.transferStats == null) ? null : _co.file.transferStats.secondsLeft)); _ck(_v, 1, 0, currVal_0); }); }
function View_FileManagerProgressComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", "% "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileManagerProgressComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_1 = (((_co.file.transferStats == null) ? null : _co.file.transferStats.secondsLeft) > 0); _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.file.progress || 0) * 100).toFixed(); _ck(_v, 1, 0, currVal_0); }); }
function View_FileManagerProgressComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("pending")); _ck(_v, 1, 0, currVal_0); }); }
export function View_FileManagerProgressComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileManagerProgressComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileManagerProgressComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((((_co.file.state === _co.FileStateType.PENDING) || (_co.file.state === _co.FileStateType.UPLOADING)) || (_co.file.state === _co.FileStateType.PAUSED)) || (_co.file.state === _co.FileStateType.DONE)); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.file.state === _co.FileStateType.WAITING); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_FileManagerProgressComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-file-manager-progress", [], null, null, null, View_FileManagerProgressComponent_0, RenderType_FileManagerProgressComponent)), i1.ɵdid(1, 245760, null, 0, i4.FileManagerProgressComponent, [i5.FileManagerService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FileManagerProgressComponentNgFactory = i1.ɵccf("app-file-manager-progress", i4.FileManagerProgressComponent, View_FileManagerProgressComponent_Host_0, { file: "file" }, {}, []);
export { FileManagerProgressComponentNgFactory as FileManagerProgressComponentNgFactory };
