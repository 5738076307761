<div class="calendar">
    <div class="clr-row" style="margin-top: -10px; height:60px;">
        <div class="clr-col-3">
            <ng-select
                class="less-pl"
                [items]="clients"
                bindLabel="user.name"
                bindValue="id"
                [placeholder]="'client_select' | translate"
                (clear)="filterClient()"
                (change)="filterClient($event?.id)"
            >
                <ng-template ng-option-tmp let-item="item" let-index="index">
                    <app-long-avatar [code]="item?.user.code" [bgColor]="item.user.color"> </app-long-avatar>
                    {{ item.user.name }}
                </ng-template>
                <ng-template ng-label-tmp let-item="item">
                    <app-long-avatar [code]="item?.user.code" [bgColor]="item.user.color"> </app-long-avatar>
                    {{ item.user.name }}
                </ng-template>
            </ng-select>
        </div>
        <div class="clr-col-3">
            <ng-select
                class="less-pl"
                [items]="filteredProjects"
                bindLabel="obs"
                bindValue="id"
                [placeholder]="'project_select' | translate"
                (clear)="filterProject()"
                (change)="filterProject($event?.id)"
                [(ngModel)]="projectModel"
            >
                <ng-template ng-option-tmp let-item="item" let-index="index">
                    <app-long-avatar [code]="item?.code" [bgColor]="item.color"> </app-long-avatar>
                    {{ item.obs }}
                </ng-template>
                <ng-template ng-label-tmp let-item="item">
                    <app-long-avatar [code]="item?.code" [bgColor]="item.color"> </app-long-avatar>
                    {{ item.obs }}
                </ng-template>
            </ng-select>
        </div>
        <div class="clr-col-3">
            <ng-select
                class="less-pl"
                [items]="filteredEmployees"
                bindLabel="user.name"
                bindValue="id"
                [placeholder]="'employee_select' | translate"
                (clear)="filterEmployee()"
                (change)="filterEmployee($event?.id)"
                [(ngModel)]="employeeModel"
            >
                <ng-template ng-option-tmp let-item="item" let-index="index">
                    <app-long-avatar [code]="item?.user.code" [bgColor]="item.user.color"> </app-long-avatar>
                    {{ item.user.name }}
                </ng-template>
                <ng-template ng-label-tmp let-item="item">
                    <app-long-avatar [code]="item?.user.code" [bgColor]="item.user.color"> </app-long-avatar>
                    {{ item.user.name }}
                </ng-template>
            </ng-select>
        </div>
        <div class="clr-col-3">
            <ng-select
                class="less-pl"
                [items]="msTypes"
                bindLabel="type"
                bindValue="type"
                [placeholder]="'milestone_type_select' | translate"
                (clear)="filterType()"
                (change)="filterType($event)"
            >
                <ng-template ng-option-tmp let-item="item" let-index="index">
                    <clr-icon
                        [attr.shape]="MILESTONE_TYPE_ICON[item]"
                        class="text-milestone-{{ item | lowercase }}"
                    ></clr-icon>
                    {{ 'milestone_' + item | lowercase | translate }}
                </ng-template>
                <ng-template ng-label-tmp let-item="item">
                    <clr-icon
                        [attr.shape]="MILESTONE_TYPE_ICON[item]"
                        class="text-milestone-{{ item | lowercase }}"
                    ></clr-icon>
                    {{ 'milestone_' + item | lowercase | translate }}
                </ng-template>
            </ng-select>
        </div>
    </div>
    <div class="clr-row">
        <div *ngIf="loading" class="spinner-bg-c"><div class="spinner-c"></div></div>
        <div class="clr-col-12"><div class="calendar-container" #calendar></div></div>
    </div>
</div>

<app-edit-milestone
    [selectedEvent]="editTask"
    [showModal]="showCalendarModal"
    (hidden)="showCalendarModal = false"
    (saved)="saveMilestone($event)"
    (removed)="deleteMilestone()"
>
</app-edit-milestone>
