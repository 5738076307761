var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ChangeDetectorRef, ElementRef, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import * as SemVer from 'semver';
import { overEstimatedTime } from '../../../../defs/businessRules';
import { ApiRoutePlurality, HTTP_METHOD, PATTERN_TIME, ProjectStatusType, ReleaseStateType, TaskType, } from '../../../../defs/schema-static';
import { MILESTONE_SCHEMA_ROUTE } from '../../../../defs/schema/public/Milestones';
import { PROJECT_SCHEMA_ROUTE } from '../../../../defs/schema/public/Projects';
import { TASK_BLOCKER_SCHEMA_ROUTE } from '../../../../defs/schema/public/TaskBlockers';
import { TASK_SCHEMA_ROUTE } from '../../../../defs/schema/public/Tasks';
import { AuthService } from '../auth/auth.service';
import { FormsAddTaskComponent } from '../forms/add-task/add-task.component';
import { ModalComponent } from '../modal/modal.component';
import { ConfigService } from '../shared/config/config.service';
import { ControlFlowService } from '../shared/control-flow/control-flow.service';
import { HttpRestService } from '../shared/http-rest/http-rest.service';
import { MomentService } from '../shared/moment/moment.service';
import { ShortcutHandlerService, SHORTCUT_MULTIPLE_ADD } from '../shared/shortcut-handler/shortcut-handler.service';
import { ToastService, TOAST_TYPE } from '../shared/toast/toast.service';
var AddMultipleTaskComponent = (function (_super) {
    __extends(AddMultipleTaskComponent, _super);
    function AddMultipleTaskComponent(shortcutHandlerService, changeDetectorRef, authService, httpRest, controlFlowService, translate, toastService, momentService, configService) {
        var _this = _super.call(this, shortcutHandlerService, controlFlowService, changeDetectorRef) || this;
        _this.shortcutHandlerService = shortcutHandlerService;
        _this.changeDetectorRef = changeDetectorRef;
        _this.authService = authService;
        _this.httpRest = httpRest;
        _this.translate = translate;
        _this.toastService = toastService;
        _this.momentService = momentService;
        _this.configService = configService;
        _this.DEFAULT_TASK = {
            beginDate: _this.momentService.moment(),
            endDate: _this.momentService.moment(),
            name: '',
            parentTaskId: 0,
            estimatedTime: '',
            projectId: null,
            targetReleaseId: null,
            bug: false,
            urgent: false,
        };
        _this.tempTasks = [];
        _this.parentTask = __assign({}, _this.DEFAULT_TASK);
        _this.blockerTask = __assign({}, _this.DEFAULT_TASK);
        _this.inactiveProjects = [];
        _this.activeProjects = [];
        _this.error = '';
        _this.PATTERN_TIME = PATTERN_TIME;
        return _this;
    }
    AddMultipleTaskComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
    };
    AddMultipleTaskComponent.prototype.getBlockerEstimatedValid = function () {
        if (!this.blockerEstimated) {
            return false;
        }
        return (this.blockerEstimated.nativeElement.validity.valid &&
            (this.blockerTask.estimatedTime === '' || this.isUnderLimitWeeks(this.blockerTask.estimatedTime)));
    };
    AddMultipleTaskComponent.prototype.getChildrenEstimatedValid = function () {
        var _this = this;
        if (!this.childEstTime) {
            return false;
        }
        var oneTaskFail = false;
        this.tempTasks.map(function (child) {
            if (child.estimatedTime && child.estimatedTime !== '' && !_this.isUnderLimitWeeks(child.estimatedTime)) {
                oneTaskFail = true;
            }
        });
        if (oneTaskFail) {
            return true;
        }
        return (this.childEstTime.filter(function (e) {
            return !e.nativeElement.validity.valid;
        }).length === 1);
    };
    AddMultipleTaskComponent.prototype.isUnderLimitWeeks = function (estimatedTime) {
        return !overEstimatedTime(this.configService.config, estimatedTime);
    };
    AddMultipleTaskComponent.prototype.load = function () {
        var _this = this;
        var projectId = this.addTaskComponent.form.controls.project.value;
        var releaseId = this.addTaskComponent.form.controls.releaseId.value;
        this.shortcutHandlerService.register({
            shortcut: SHORTCUT_MULTIPLE_ADD.ADD_TEMP,
            callback: function () {
                _this.newTempTask();
            },
            context: this,
            forceListen: true,
        });
        this.parentTask = __assign({}, this.DEFAULT_TASK, { projectId: projectId });
        this.blockerTask = __assign({}, this.DEFAULT_TASK);
        this.tempTasks = [];
        this.newTempTask(true);
        this.httpRest
            ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, PROJECT_SCHEMA_ROUTE, 'light')
            .subscribe(function (projects) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.activeProjects = projects
                    .filter(function (p) { return p.status === ProjectStatusType.OPEN; })
                    .sort(function (a, b) {
                    return a.obs.localeCompare(b.obs);
                })
                    .concat(projects
                    .filter(function (p) { return p.status === ProjectStatusType.WAITING; })
                    .sort(function (a, b) {
                    return a.obs.localeCompare(b.obs);
                }))
                    .concat(projects
                    .filter(function (p) { return p.status === ProjectStatusType.CLOSE; })
                    .sort(function (a, b) {
                    return a.obs.localeCompare(b.obs);
                }));
                this.inactiveProjects = projects.filter(function (project) { return project.status === ProjectStatusType.CLOSE; });
                this.getReleasesByProject();
                this.parentTask.targetReleaseId = releaseId;
                return [2];
            });
        }); });
    };
    AddMultipleTaskComponent.prototype.getRelease = function (releaseId) {
        if (!this.releases) {
            return null;
        }
        return this.releases.find(function (r) { return r.id === releaseId; });
    };
    AddMultipleTaskComponent.prototype.getReleasesByProject = function (clear) {
        var _this = this;
        if (clear === void 0) { clear = false; }
        if (clear) {
            this.parentTask.projectId = null;
            this.parentTask.targetReleaseId = null;
            this.releases = [];
            return;
        }
        if (!this.parentTask.projectId) {
            return;
        }
        this.parentTask.targetReleaseId = null;
        this.httpRest
            ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, MILESTONE_SCHEMA_ROUTE, "release/project/" + this.parentTask.projectId)
            .subscribe(function (releases) {
            _this.releases = releases.sort(function (a, b) {
                if (a.releaseState === ReleaseStateType.IN_DEVELOPMENT &&
                    b.releaseState === ReleaseStateType.IN_DEVELOPMENT) {
                    return SemVer.compare(a.version, b.version);
                }
                else if (a.releaseState === ReleaseStateType.IN_DEVELOPMENT &&
                    b.releaseState !== ReleaseStateType.IN_DEVELOPMENT) {
                    return -1;
                }
                return SemVer.compare(b.version, a.version);
            });
        });
    };
    AddMultipleTaskComponent.prototype.newTempTask = function (force) {
        var _this = this;
        if (force === void 0) { force = false; }
        if (!this.show) {
            return;
        }
        var tempTask = {
            name: '',
            estimatedTime: '',
            beginDate: this.parentTask.beginDate,
            endDate: this.parentTask.endDate,
            targetReleaseId: this.parentTask.targetReleaseId,
            projectId: this.parentTask.projectId,
            bug: this.parentTask.bug,
            urgent: this.parentTask.urgent,
        };
        this.tempTasks.push(__assign({}, tempTask));
        window.setTimeout(function () {
            if (_this.show && !force) {
                var taskNodes = Array.from(document.querySelectorAll('.ml-10'));
                taskNodes[taskNodes.length - 1].focus();
            }
            _this.changeDetectorRef.markForCheck();
        }, 200);
    };
    AddMultipleTaskComponent.prototype.submitTempTasks = function () {
        var _this = this;
        this.error = '';
        if (this.parentTask.name &&
            this.parentTask.projectId &&
            this.tempTasks.filter(function (task) { return task.name; }).length > 0) {
            this.httpRest
                .put(TASK_SCHEMA_ROUTE, {
                authorId: this.authService.user.employee.id,
                beginDate: this.parentTask.beginDate,
                endDate: this.parentTask.endDate,
                estimatedTime: this.parentTask.estimatedTime,
                name: this.parentTask.name,
                parentId: null,
                projectId: this.parentTask.projectId,
                targetReleaseId: this.parentTask.targetReleaseId || null,
                tracked: true,
                type: TaskType[this.parentTask.bug ? 'BUG' : 'TASK'],
                urgent: this.parentTask.urgent,
            })
                .subscribe(function (t) {
                _this.parentTask.id = t.id;
                _this.saveBlocker();
            });
        }
        else {
            this.error = 'task_multiple_parent_error';
        }
    };
    AddMultipleTaskComponent.prototype.saveBlocker = function () {
        var _this = this;
        if (this.blockerTask.name) {
            this.httpRest
                .put(TASK_SCHEMA_ROUTE, {
                authorId: this.authService.user.employee.id,
                beginDate: this.blockerTask.beginDate,
                endDate: this.blockerTask.endDate,
                estimatedTime: this.blockerTask.estimatedTime,
                name: this.blockerTask.name,
                parentId: this.parentTask.id,
                projectId: this.parentTask.projectId,
                targetReleaseId: this.parentTask.targetReleaseId || null,
                tracked: true,
                type: TaskType[this.blockerTask.bug ? 'BUG' : 'TASK'],
                urgent: this.blockerTask.urgent,
            })
                .subscribe(function (t) {
                _this.blockerTask.id = t.id;
                _this.saveTasks();
            });
        }
        else {
            this.saveTasks();
        }
    };
    AddMultipleTaskComponent.prototype.deleteTask = function (task, index) {
        this.tempTasks.splice(index, 1);
    };
    AddMultipleTaskComponent.prototype.clearBlocker = function () {
        this.blockerTask = __assign({}, this.DEFAULT_TASK);
    };
    AddMultipleTaskComponent.prototype.clearParent = function () {
        this.parentTask = __assign({}, this.DEFAULT_TASK);
    };
    AddMultipleTaskComponent.prototype.saveTasks = function () {
        var _this = this;
        forkJoin(this.tempTasks
            .filter(function (task) { return task.name; })
            .map(function (task) {
            var obs = _this.httpRest.put(TASK_SCHEMA_ROUTE, {
                authorId: _this.authService.user.employee.id,
                beginDate: task.beginDate,
                endDate: task.endDate,
                estimatedTime: task.estimatedTime,
                name: task.name,
                parentId: _this.parentTask.id || null,
                projectId: _this.parentTask.projectId,
                targetReleaseId: _this.parentTask.targetReleaseId || null,
                tracked: true,
                type: TaskType[task.bug ? 'BUG' : 'TASK'],
                urgent: task.urgent,
            });
            obs.subscribe(function (t) { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    if (this.blockerTask.id) {
                        this.httpRest.put(TASK_BLOCKER_SCHEMA_ROUTE, {
                            taskId: t.id,
                            blockerId: this.blockerTask.id,
                        });
                    }
                    return [2];
                });
            }); });
            return obs;
        })).subscribe(function (e) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.showChange.emit((this.show = false));
                this.toastService.show({
                    type: TOAST_TYPE.SUCCESS,
                    text: 'success_insert_mult_task',
                });
                return [2];
            });
        }); });
    };
    return AddMultipleTaskComponent;
}(ModalComponent));
export { AddMultipleTaskComponent };
