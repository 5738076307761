import moment from 'moment';
import semVer from 'semver';
import {ProjectMemberType, ProjectStatusType, TaskResolution, TaskStatusType, TaskType} from './schema-static';
import {IClient} from './schema/public/Clients';
import {ICustomerContact} from './schema/public/CustomerContacts';
import {IEmployee} from './schema/public/Employees';
import {IProjectMember} from './schema/public/ProjectMembers';
import {IProject} from './schema/public/Projects';
import {ITask, TASK_FIELD} from './schema/public/Tasks';

export const PROJECT_STATUS_SORT_ORDER = [ProjectStatusType.OPEN, ProjectStatusType.WAITING, ProjectStatusType.CLOSE];

export const PROJECT_STATUS_SORT_FUNCTION = (p1: Partial<IProject>, p2: Partial<IProject>) => {
    if (p1.status !== p2.status) {
        return PROJECT_STATUS_SORT_ORDER.indexOf(p1.status) - PROJECT_STATUS_SORT_ORDER.indexOf(p2.status);
    }

    return p1.obs.localeCompare(p2.obs);
};

export const PROJECT_SORT_FUNCTION = (p1: Partial<IProject>, p2: Partial<IProject>) => {
    return p1.obs.localeCompare(p2.obs);
};

export const CLIENT_SORT_FUNCTION = (c1: IClient, c2: IClient) => {
    const [u1, u2] = [c1, c2].map((client) => (client.user && client.user.name) || '');

    return u1.localeCompare(u2);
};

export const EMPLOYEE_SORT_FUNCTION = (e1: Partial<IEmployee>, e2: Partial<IEmployee>) => {
    const [u1, u2] = [e1, e2].map((employee) => (employee.user && employee.user.name) || '');

    return u1.localeCompare(u2);
};

export const PROJECT_MEMBER_SORT_FUNCTION = (m1: Partial<IProjectMember>, m2: Partial<IProjectMember>) => {
    if (m1.type !== m2.type) {
        return m1.type === ProjectMemberType.MANAGER ? -1 : 1;
    }

    const [e1, e2] = [m1, m2].map(({employee}) => employee);

    return EMPLOYEE_SORT_FUNCTION(e1, e2);
};

export const CUSTOMER_CONTACT_SORT_FUNCTION = (c1: ICustomerContact, C2: ICustomerContact) => {
    const [e1, e2] = [c1, C2].map(({employee}) => employee);

    return EMPLOYEE_SORT_FUNCTION(e1, e2);
};

export const TASK_TYPE_SORT_ORDER = [TaskType.BUG, TaskType.TASK];

export const TASK_STATUS_SORT_ORDER = [
    TaskStatusType.INPROGRESS,
    TaskStatusType.TODO,
    TaskStatusType.BACKLOG,
    TaskStatusType.REVIEWING,
    TaskStatusType.REVIEW,
];

export const TASK_RESOLUTION_SORT_ORDER = [
    TaskResolution.FIXED,
    TaskResolution.DUPLICATE,
    TaskResolution.CANT_REPRODUCE,
    TaskResolution.WONT_FIX,
];

export const TASK_SORT_FUNCTIONS: {[field in TASK_FIELD]?: (t1: ITask, t2: ITask) => number} = {
    [TASK_FIELD.urgent]: (t1: ITask, t2: ITask) => (t1.urgent !== t2.urgent ? +t2.urgent - +t1.urgent : 0),

    [TASK_FIELD.type]: (t1: ITask, t2: ITask) =>
        TASK_TYPE_SORT_ORDER.indexOf(t1.type) - TASK_TYPE_SORT_ORDER.indexOf(t2.type),

    [TASK_FIELD.status]: (t1: ITask, t2: ITask) =>
        TASK_STATUS_SORT_ORDER.indexOf(t1.status) - TASK_STATUS_SORT_ORDER.indexOf(t2.status),

    [TASK_FIELD.resolution]: (t1: ITask, t2: ITask) =>
        TASK_RESOLUTION_SORT_ORDER.indexOf(t1.resolution) - TASK_RESOLUTION_SORT_ORDER.indexOf(t2.resolution),

    [TASK_FIELD.endDate]: (t1: ITask, t2: ITask) =>
        moment(t1._metadata.endDate).isAfter(t2._metadata.endDate) ? 1 : -1,
    [TASK_FIELD.createdAt]: (t1: ITask, t2: ITask) => (moment(t1.createdAt).isAfter(t2.createdAt) ? 1 : -1),

    [TASK_FIELD.code]: (t1: ITask, t2: ITask) => t1.code.localeCompare(t2.code),

    [TASK_FIELD.targetReleaseId]: (t1: ITask, t2: ITask) => {
        if (t1.targetRelease && !t2.targetRelease) {
            return -1;
        } else if (!t1.targetRelease && t2.targetRelease) {
            return 1;
        } else if (!t1.targetRelease && !t2.targetRelease) {
            return 0;
        } else {
            return semVer.compare(t2.targetRelease.version, t1.targetRelease.version);
        }
    },
};
