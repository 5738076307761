var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClrLoadingState } from '@clr/angular';
import { TranslateService } from '@ngx-translate/core';
import { SETTING_LANGUAGE_VALUES } from '../../../../../defs/schema/public/Users';
import { TOAST_TYPE, ToastService } from '../../shared/toast/toast.service';
import { AuthService } from '../auth.service';
var LogInComponent = (function () {
    function LogInComponent(authService, route, translate, toastService) {
        var _this = this;
        this.authService = authService;
        this.route = route;
        this.translate = translate;
        this.toastService = toastService;
        this.username = '';
        this.password = '';
        this.loadingState = ClrLoadingState.DEFAULT;
        this.ClrLoadingState = ClrLoadingState;
        this.translate.use(this.translate.getBrowserLang().toUpperCase()).subscribe(function () {
            _this.translate.setDefaultLang(SETTING_LANGUAGE_VALUES.FR);
        });
    }
    LogInComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.authService.restoreSession();
        this.route.queryParams.subscribe(function (queryParams) {
            if (queryParams.username) {
                _this.username = queryParams.username;
            }
        });
    };
    LogInComponent.prototype.submit = function () {
        var _this = this;
        this.loadingState = ClrLoadingState.LOADING;
        this.authService.authenticate(this.username.toLowerCase(), this.password, function (err) {
            if (err) {
                if (err.message === 'cancelled') {
                    _this.loadingState = ClrLoadingState.DEFAULT;
                    return;
                }
                switch (err.code) {
                    default:
                        _this.error = 'login_error';
                        break;
                }
                _this.loadingState = ClrLoadingState.DEFAULT;
                return;
            }
            _this.loadingState = ClrLoadingState.SUCCESS;
            _this.error = undefined;
        });
    };
    LogInComponent.prototype.forgotPassword = function () {
        var _this = this;
        if (!this.username) {
            this.error = 'forgot_password_missing_email_error';
            return;
        }
        this.error = undefined;
        this.authService.resetPassword(this.username.toLowerCase(), function (err, result) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (err) {
                    switch (err.code) {
                        case 'UserNotFoundException':
                            this.error = 'forgot_password_unknown_email_error';
                            break;
                        default:
                            this.error = 'forgot_password_unknown_error';
                            break;
                    }
                    return [2];
                }
                if (result) {
                    if (result.resend) {
                        this.toastService.show({
                            type: TOAST_TYPE.INFO,
                            text: 'resend_email_sent',
                        });
                    }
                    else if (result.password) {
                        this.toastService.show({
                            type: TOAST_TYPE.SUCCESS,
                            text: 'password_successfully_updated',
                        });
                    }
                }
                return [2];
            });
        }); });
    };
    LogInComponent.prototype.valid = function () {
        return this.username && this.password;
    };
    return LogInComponent;
}());
export { LogInComponent };
