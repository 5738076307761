import {
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewEncapsulation,
} from '@angular/core';
import {ActivatedRoute, convertToParamMap, NavigationEnd, Route, Router, UrlSegment} from '@angular/router';
import {Params} from '@angular/router/src/shared';
import {filter, map} from 'rxjs/operators';

import {Subscription} from 'rxjs';
import publicConfig from '../../../../../defs/config/config.json';
import {COGNITO_USER_GROUPS, COGNITO_USER_GROUPS_PRECEDENCE} from '../../../../../defs/schema-static';
import {AuthService} from '../../auth/auth.service';
import {NotificationService} from '../notification.service';
import {ControlFlowService} from './control-flow.service';

export interface IControlFlowParameterRoute {
    label?: string;
    active: boolean;
    parameters: {[key: string]: string};
}

interface ILabel {
    label: string;
    description?: boolean;
}

export interface IControlFlowRoute extends Route, ILabel {
    fullPath: string;
    disabled: boolean;
    active: boolean;
    required?: boolean;
    valid?: boolean;
    depth: number;
    icon?: string;
    aliases?: string[];
    fullPathAliases?: string[];
    parentRoute?: IControlFlowRoute;
    flowParameters?: IControlFlowParameterRoute[];
}

interface IControlFlowDepth {
    index: number;
    routes: IControlFlowRoute[];
}

@Component({
    selector: 'shared-control-flow',
    templateUrl: './control-flow.component.html',
    styleUrls: ['./control-flow.component.scss'],
    // encapsulation: ViewEncapsulation.None,
})
export class ControlFlowComponent implements OnInit, OnChanges, OnDestroy {
    @Input()
    public rootPath = '';
    @Input()
    public routes: IControlFlowRoute[];
    @Input()
    public routesControlFlow: {[path: string]: Partial<IControlFlowRoute>} = {};
    @Input()
    public maxDepth = 1;
    @Input()
    public maxDisplayedDepth = 3;
    @Input()
    public hidden = false;
    @Input()
    public requiredGroup = COGNITO_USER_GROUPS._UNAUTHED;
    @Input()
    public class = '';

    public activeRoute?: IControlFlowRoute;
    // private nextRoute?: IControlFlowRoute;
    // private previousRoute?: IControlFlowRoute;

    private activeFlowParameter?: IControlFlowParameterRoute;

    private validSubscription: Subscription;
    private collapsedSubscription: Subscription;
    private routerSubscription: Subscription;

    private depths: IControlFlowDepth[];

    public isCollapsed = this.controlFlowService.isCollapsed.value;

    public constructor(
        private readonly router: Router,
        private readonly activatedRoute: ActivatedRoute,
        public controlFlowService: ControlFlowService,
        private readonly changeDetectorRef: ChangeDetectorRef,
        public authService: AuthService,
        public notificationService: NotificationService
    ) {}

    public switchSidebar() {
        this.controlFlowService.toggleSidebar();
    }

    public ngOnInit(): void {
        this.initRoutes();
        if (this.rootPath && this.rootPath.substr(-1) !== '/') {
            this.rootPath += '/';
        }

        const rootUrlSegment: UrlSegment = {
            path: this.router.url.replace(`/${this.rootPath}`, ''),
            parameters: {},
            parameterMap: convertToParamMap({}),
        } as UrlSegment;

        this.updateCurrentRoute(rootUrlSegment);

        this.routerSubscription = this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map(() => this.activatedRoute),
                map((route: ActivatedRoute) => {
                    while (route.firstChild) {
                        route = route.firstChild;
                    }

                    return route;
                }),
                filter((route: ActivatedRoute) => route.outlet === 'primary')
            )
            /*.mergeMap((route: ActivatedRoute) => route.url)*/
            .subscribe((route: ActivatedRoute) => {
                let parentRoute = route.parent;
                let parentRoutePath = '';
                while (parentRoute.parent) {
                    if (parentRoute.routeConfig && parentRoute.routeConfig.path) {
                        parentRoutePath = `${parentRoute.routeConfig.path}/${parentRoutePath}`;
                    }

                    parentRoute = parentRoute.parent;
                }

                route.url.subscribe((event: UrlSegment[]) => {
                    this.updateCurrentRoute(event[0], parentRoutePath);
                });
            });

        this.validSubscription = this.controlFlowService.valid$.subscribe((valid) => this.setFormValidation(valid));
        this.collapsedSubscription = this.controlFlowService.isCollapsed$.subscribe(
            (isCollapsed) => (this.isCollapsed = isCollapsed)
        );
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.routes || changes.routesControlFlow || changes.requiredGroup) {
            this.initRoutes();
        }
    }

    public ngOnDestroy(): void {
        [this.routerSubscription, this.validSubscription, this.collapsedSubscription]
            .filter((subscription) => typeof subscription !== 'undefined')
            .map((subscription) => subscription.unsubscribe());
    }

    public initRoutes() {
        this.routes = this.getRoutes(this.routes);
        if (this.routesControlFlow) {
            const routesControlFlowKeys = Object.keys(this.routesControlFlow);
            this.routes = this.routes.sort(
                (r1, r2) => routesControlFlowKeys.indexOf(r1.path) - routesControlFlowKeys.indexOf(r2.path)
            );
        }

        this.depths = new Array(this.maxDepth).fill(0).map((_, i) => {
            return {
                index: i,
                routes: this.routes.filter((route) => route.depth === i + 1),
            };
        });

        if (this.activeRoute) {
            this.updateCurrentRoute(new UrlSegment(this.activeRoute.fullPath.replace(/\/:.*/, ''), {}));
        }
    }

    public getRoutes(routes: Route[], depth = 1, parentRoute?: IControlFlowRoute): IControlFlowRoute[] {
        return routes.reduce(
            (_routes, _route) => {
                const controlFlow = this.routesControlFlow[_route.path];

                const rootPath = (parentRoute && `${parentRoute.fullPath}/`) || '';

                const route = {
                    ..._route,
                    ...(this.routesControlFlow[_route.path] || {}),
                    active: false,
                    disabled: false,
                    label: (controlFlow && controlFlow.label) || _route.path,
                    depth,
                    fullPath: `${rootPath}${_route.path}`.replace(/\/{2,}/g, '/'),
                    parentRoute,
                    fullPathAliases: ((controlFlow && controlFlow.aliases) || []).map((alias) =>
                        `${rootPath}${alias}`.replace(/\/{2,}/g, '/')
                    ),
                };

                _routes.push(route);

                if (depth >= this.maxDepth || !route.children || !route.children.length) {
                    return _routes;
                }

                _routes.push(...this.getRoutes(route.children, depth + 1, route));

                return _routes;
            },
            [] as IControlFlowRoute[]
        );
    }

    private updateCurrentRoute(urlSegment?: UrlSegment, parentRoute = '', retry = true) {
        const path = urlSegment ? `${parentRoute}${urlSegment.path}` : parentRoute.replace(/\/$/, '');

        this.updateDisabledState();

        const activeRoutes = [].concat(
            ...this.depths
                .map((depth) =>
                    depth.routes.filter(
                        (route) =>
                            route.fullPath.replace(/\/:.*/, '') === path ||
                            !!route.fullPathAliases.find((alias) => alias.replace(/\/:.*/, '') === path)
                    )
                )
                .filter((route) => !!route.length)
        ) as IControlFlowRoute[];

        // FIXME find better selection criteria?
        const activeRoute = [...activeRoutes].sort((r1, r2) => (r2.icon || '').length - (r1.icon || '').length)[0];

        if (!activeRoute || activeRoute.disabled) {
            const requiredRoutes = this.routes.filter((route: IControlFlowRoute) => route.required && !route.valid);

            if (requiredRoutes.length) {
                (async () => this.router.navigate([`/${this.rootPath}${requiredRoutes.pop().path}`]))();

                return;
            }
        }

        if (!activeRoute) {
            if (retry) {
                this.updateCurrentRoute(null, parentRoute, false);
            }

            return;
        }

        this.setActiveRoute(activeRoute, (urlSegment || ({} as UrlSegment)).parameters);
        // this.nextRoute = this.getNextRoute();
        // this.previousRoute = this.getPreviousRoute();
    }

    public setFormValidation(valid: boolean) {
        const activeRoute = this.getActiveRoute();
        if (!activeRoute) {
            return;
        }

        if (activeRoute.valid === valid) {
            return;
        }

        activeRoute.valid = valid;

        if (activeRoute.required) {
            this.updateDisabledState();
        }

        // prevents ExpressionChangedAfterItHasBeenCheckedError
        this.changeDetectorRef.detectChanges();
    }

    protected updateDisabledState() {
        let invalidRequired = false;
        this.routes.map((route) => {
            route.disabled = invalidRequired;

            if (!route.valid && route.required) {
                invalidRequired = true;
            }
        });
    }

    public setActiveRoute(route: IControlFlowRoute, params?: Params): void {
        const activeRoute = this.getActiveRoute();
        if (activeRoute) {
            activeRoute.active = false;

            {
                let parentRoute = activeRoute;
                // tslint:disable-next-line:no-conditional-assignment
                while ((parentRoute = parentRoute.parentRoute)) {
                    parentRoute.active = false;
                }
            }

            if (activeRoute.flowParameters) {
                activeRoute.flowParameters.map((flowParam) => (flowParam.active = false));
                this.activeFlowParameter = null;
            }
        }

        route.active = true;
        {
            let parentRoute = route;
            // tslint:disable-next-line:no-conditional-assignment
            while ((parentRoute = parentRoute.parentRoute)) {
                parentRoute.active = true;
            }
        }

        if (route.flowParameters && params) {
            route.flowParameters.map((flowParam) => (flowParam.active = false));

            const activeFlowParam = route.flowParameters.find(
                (flowParam) =>
                    Object.keys(params).length === Object.keys(flowParam.parameters).length &&
                    Object.keys(params)
                        .map(
                            (filterParamKey) =>
                                flowParam.parameters[filterParamKey] &&
                                flowParam.parameters[filterParamKey] === params[filterParamKey]
                        )
                        .find((bool) => !bool)
            );

            if (activeFlowParam) {
                activeFlowParam.active = true;
                this.activeFlowParameter = activeFlowParam;
            }
        }

        this.activeRoute = route;

        if (activeRoute) {
            // prevents ExpressionChangedAfterItHasBeenCheckedError error
            this.changeDetectorRef.detectChanges();
        }
    }

    private getRelativeRoute(relative: number): IControlFlowRoute | undefined {
        if (typeof this.activeRoute === 'undefined') {
            return undefined;
        }

        let index = this.routes.indexOf(this.activeRoute);
        if (index === -1) {
            index = this.routes.findIndex((route) => route.fullPath === this.activeRoute.fullPath);
        }

        if (index === -1) {
            return undefined;
        }

        const relativeIndex = index + relative;

        if (relativeIndex >= this.routes.length || relativeIndex < 0) {
            return undefined;
        }

        return this.routes[relativeIndex];
    }

    public getNextRoute(): IControlFlowRoute | undefined {
        return this.getRelativeRoute(1);
    }

    public getActiveRoute(): IControlFlowRoute | undefined {
        return this.getRelativeRoute(0);
    }

    public getPreviousRoute(): IControlFlowRoute | undefined {
        return this.getRelativeRoute(-1);
    }

    public get displayedDepths(): IControlFlowDepth[] {
        return this.depths.slice(0, this.maxDisplayedDepth);
    }

    public getRouteUrl(route: IControlFlowRoute) {
        return [
            `/${this.rootPath}${route.fullPath}`,
            route.flowParameters &&
            (this.activeRoute || {flowParameters: undefined}).flowParameters === route.flowParameters
                ? (this.activeFlowParameter || {parameters: undefined}).parameters || {}
                : {},
        ];
    }

    public readonly COGNITO_USER_GROUPS = COGNITO_USER_GROUPS;
    public readonly COGNITO_USER_GROUPS_PRECEDENCE = COGNITO_USER_GROUPS_PRECEDENCE;
    public readonly publicConfig = publicConfig;
}
