<div class="todo-container default" [ngClass]="authService.user.code">
    <ng-container *ngIf="this.displayCategory">
        <div
            dndDropzone
            [dndDisableIf]="el.category.defaultCategory"
            [dndDraggable]="el.category"
            (dndDrop)="onDrop($event, el.category)"
            *ngFor="let el of displayCategory"
        >
            <div class="clr-row todo-category h4">
                <clr-icon
                    dndHandle
                    class="c-move mb-0 drag-handle"
                    shape="ellipsis-vertical"
                    *ngIf="!el.category.defaultCategory"
                ></clr-icon>
                <clr-icon
                    class="c-hand"
                    shape="angle"
                    style="transform: rotate(180deg);margin-top: 0.2rem;"
                    *ngIf="el.collapsed"
                    (click)="changeCollapsed(el)"
                ></clr-icon>
                <clr-icon
                    class="c-hand"
                    shape="angle"
                    style="transform: rotate(90deg);margin-top: 0.2rem;"
                    *ngIf="!el.collapsed"
                    (click)="changeCollapsed(el)"
                ></clr-icon
                >&nbsp;
                <div class="correct-mt clr-col">
                    <input
                        clrInput
                        sharedDebounce
                        [ngClass]="{'default-category': el.category.defaultCategory}"
                        [disabled]="el.category.defaultCategory"
                        [id]="'category' + el.category.id"
                        class="todo-category-input"
                        [delay]="200"
                        (debounceFunction)="updateTodoCategory(el.category, el.todos)"
                        (focus)="selectCategory(el.category)"
                        (click)="selectCategory(el.category)"
                        [(ngModel)]="el.category.text"
                        maxlength="{{ MAX_LENGTH_DEFAULT }}"
                    />
                </div>
                <small class="todo-category-counter">
                    ({{ el.todos.length }})
                    <clr-icon
                        class="c-hand"
                        shape="times"
                        (click)="deleteCategory(el.category)"
                        *ngIf="!el.category.defaultCategory"
                    ></clr-icon>
                </small>
            </div>
            <div dndPlaceholderRef class="dndPlaceholder"></div>
            <div
                class="todo-list m-1"
                *ngFor="let todo of el.todos"
                [hidden]="!el.collapsed"
                [dndDraggable]="todo"
                [dndDisableIf]="el.category.defaultCategory"
                (dndStart)="onDragStart($event, todo)"
                (click)="selectTodo(todo); selectCategory(el.category)"
                (focus)="selectTodo(todo); selectCategory(el.category)"
            >
                <clr-icon
                    dndHandle
                    class="c-move mr-1 drag-handle"
                    shape="ellipsis-vertical"
                    *ngIf="!el.category.defaultCategory"
                ></clr-icon>
                <span
                    (click)="changeTodoStatus(todo)"
                    class="c-hand bullet-pending mr-2"
                    *ngIf="todo.status === TodoStatusType.TODO"
                    >☐</span
                >
                <span
                    (click)="changeTodoStatus(todo)"
                    class=" c-hand bullet-cancelled mr-2"
                    *ngIf="todo.status === TodoStatusType.CANCELED"
                    >✘</span
                >
                <span
                    (click)="changeTodoStatus(todo)"
                    class="c-hand bullet-done mr-2"
                    *ngIf="todo.status === TodoStatusType.DONE"
                    >✔</span
                >
                <input
                    [id]="'todo' + todo.id"
                    class="todo m-0 p-0"
                    type="text"
                    sharedDebounce
                    (debounceFunction)="saveTodo(todo, false)"
                    [(ngModel)]="todo.text"
                    (click)="selectTodo(todo)"
                    maxlength="{{ MAX_LENGTH_DEFAULT }}"
                />
                <small class="detail text-right"
                    >{{ displayDate(todo.dateAction) }}
                    <clr-icon class="c-hand" shape="times" (click)="deleteTodo(todo)" *ngIf="todo.id > -1"></clr-icon
                ></small>
            </div>
        </div>
    </ng-container>
</div>
