var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { EventEmitter, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ClrWizard } from '@clr/angular';
import { ApiRoutePlurality, HTTP_METHOD, ProjectMemberType } from '../../../../defs/schema-static';
import { CLIENT_SCHEMA_ROUTE } from '../../../../defs/schema/public/Clients';
import { AuthService } from '../auth/auth.service';
import { HttpRestService } from '../shared/http-rest/http-rest.service';
import { SHORTCUT_WIZARD, ShortcutHandlerService } from '../shared/shortcut-handler/shortcut-handler.service';
import { ProjectWizardProjectMembersComponent } from './project-members/project-wizard-project-members.component';
import { ProjectWizardProjectComponent } from './project/project-wizard-project.component';
var ProjectWizardComponent = (function () {
    function ProjectWizardComponent(httpRest, authService, shortcutHandlerService) {
        this.httpRest = httpRest;
        this.authService = authService;
        this.shortcutHandlerService = shortcutHandlerService;
        this.clrWizardOpen = false;
        this.clrWizardOpenChange = new EventEmitter();
        this.finish = new EventEmitter();
        this.submitting = false;
        this.projectMembers = [];
        this.defaultProjectMembers = [
            {
                employee: this.authService.user.employee,
                type: ProjectMemberType.MANAGER,
            },
        ];
        this.projectMembers = this.defaultProjectMembers.slice();
    }
    ProjectWizardComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes.clrWizardOpen && changes.clrWizardOpen.currentValue && !this.clients) {
            (function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                return [2, this.getClients()];
            }); }); })();
        }
    };
    ProjectWizardComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.shortcutHandlerService.register({
            shortcut: SHORTCUT_WIZARD.NEXT,
            callback: function (e) {
                if (!_this.wizard.currentPage.nextStepDisabled && e.target.tagName !== 'TEXTAREA') {
                    if (_this.wizard.isLast) {
                        _this.triggerFinish();
                    }
                    else {
                        _this.wizard.next();
                    }
                }
            },
            context: this,
            forceListen: true,
        });
    };
    ProjectWizardComponent.prototype.getClients = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4, this.httpRest
                                ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, CLIENT_SCHEMA_ROUTE, 'light')
                                .toPromise()];
                    case 1:
                        _a.clients = (_b.sent()).sort(function (c1, c2) { return c1.user.name.localeCompare(c2.user.name); });
                        return [2];
                }
            });
        });
    };
    ProjectWizardComponent.prototype.triggerFinish = function () {
        return __awaiter(this, void 0, void 0, function () {
            var project, _a, err_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.submitting = true;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 4, 5, 6]);
                        return [4, this.projectComponent.submit()];
                    case 2:
                        project = _b.sent();
                        if (!project) {
                            return [2];
                        }
                        this.membersComponent.project = project;
                        _a = project;
                        return [4, this.membersComponent.submit()];
                    case 3:
                        _a.projectMembers = _b.sent();
                        this.finish.emit(project);
                        return [3, 6];
                    case 4:
                        err_1 = _b.sent();
                        return [2];
                    case 5:
                        this.submitting = false;
                        return [7];
                    case 6:
                        this.triggerCancel();
                        return [2];
                }
            });
        });
    };
    ProjectWizardComponent.prototype.triggerCancel = function () {
        this.reset();
        this.wizard.forceFinish();
    };
    ProjectWizardComponent.prototype.reset = function () {
        this.wizard.reset();
        [this.projectComponent].map(function (component) { return component.reset(); });
        [this.membersComponent].map(function (_a) {
            var form = _a.form;
            return form.reset();
        });
        this.projectMembers = this.defaultProjectMembers.slice();
    };
    return ProjectWizardComponent;
}());
export { ProjectWizardComponent };
