import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ApiRoutePlurality, HTTP_METHOD, TaskType} from '../../../../defs/schema-static';
import {EMPLOYEE_SCHEMA_ROUTE, IEmployee} from '../../../../defs/schema/public/Employees';
import {ITask, TASK_SCHEMA_ROUTE} from '../../../../defs/schema/public/Tasks';
import {HttpRestService} from '../shared/http-rest/http-rest.service';

@Component({
    selector: 'app-report-task-detail',
    templateUrl: './report-task-detail.component.html',
    styleUrls: ['./report-task-detail.component.scss'],
})
export class ReportTaskDetailComponent implements OnInit, OnChanges {
    @Input() public task: ITask;
    private employees: IEmployee[];
    public taskDetailled: ITask;

    public constructor(private readonly httpRest: HttpRestService) {}

    public ngOnInit() {
        this.httpRest
            ._request<IEmployee[]>(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, EMPLOYEE_SCHEMA_ROUTE, 'employees')
            .subscribe((employees) => {
                this.employees = employees;
            });
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.task && changes.task.currentValue) {
            this.httpRest
                ._request<ITask>(
                    HTTP_METHOD.GET,
                    ApiRoutePlurality.SINGULAR,
                    TASK_SCHEMA_ROUTE,
                    `complete/${this.task.id}`
                )
                .subscribe((task) => {
                    this.taskDetailled = task;
                });
        }
    }

    public getEmployee(employeeId: number) {
        if (!this.employees) {
            return null;
        }

        return this.employees.find((e) => e.id === employeeId);
    }

    public TASK_TYPE = TaskType;
}
