var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ElementRef, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Validators } from '@angular/forms';
import { ClrForm } from '@clr/angular';
import { FormControl, FormGroup } from 'ngx-strongly-typed-forms';
import { ApiRoutePlurality, COGNITO_USER_GROUPS, HTTP_METHOD, MAX_LENGTH_COGNITO_USERNAME, MAX_LENGTH_DEFAULT, MAX_LENGTH_DISCORD_SNOWFLAKE, PATTERN_CAPITAL_NUMERIC_SPACE, PATTERN_COGNITO_USERNAME, PATTERN_NUMBER, RIGHTS, } from '../../../../../defs/schema-static';
import { EMPLOYEE_SCHEMA_ROUTE } from '../../../../../defs/schema/public/Employees';
import { USER_SCHEMA_ROUTE } from '../../../../../defs/schema/public/Users';
import { getRandomColorHex, MAX_LENGTH_EMP_CODE } from '../../app-static';
import { AuthService } from '../../auth/auth.service';
import { phoneNumberValidator } from '../../forms/validators/phone-number.validator';
import { requiredTrimValidator } from '../../forms/validators/required-trim.validator';
import { HttpRestService } from '../../shared/http-rest/http-rest.service';
var EMPLOYEE_FORM_KEYS;
(function (EMPLOYEE_FORM_KEYS) {
    EMPLOYEE_FORM_KEYS["name"] = "name";
    EMPLOYEE_FORM_KEYS["code"] = "code";
    EMPLOYEE_FORM_KEYS["color"] = "color";
    EMPLOYEE_FORM_KEYS["dayPrice"] = "dayPrice";
    EMPLOYEE_FORM_KEYS["email"] = "email";
    EMPLOYEE_FORM_KEYS["cognitoUsername"] = "cognitoUsername";
    EMPLOYEE_FORM_KEYS["discordSnowflake"] = "discordSnowflake";
    EMPLOYEE_FORM_KEYS["phoneNumber"] = "phoneNumber";
    EMPLOYEE_FORM_KEYS["mobileNumber"] = "mobileNumber";
})(EMPLOYEE_FORM_KEYS || (EMPLOYEE_FORM_KEYS = {}));
var EmployeeWizardEmployeeComponent = (function () {
    function EmployeeWizardEmployeeComponent(httpRest, authService) {
        var _a;
        this.httpRest = httpRest;
        this.authService = authService;
        this.formLayout = 'horizontal';
        this.edit = false;
        this.form = new FormGroup((_a = {},
            _a[EMPLOYEE_FORM_KEYS.name] = new FormControl(null, [
                requiredTrimValidator(),
                Validators.maxLength(MAX_LENGTH_DEFAULT),
            ]),
            _a[EMPLOYEE_FORM_KEYS.code] = new FormControl(null, [
                requiredTrimValidator(),
                Validators.maxLength(MAX_LENGTH_EMP_CODE),
                Validators.pattern(PATTERN_CAPITAL_NUMERIC_SPACE),
            ]),
            _a[EMPLOYEE_FORM_KEYS.color] = new FormControl(getRandomColorHex(), requiredTrimValidator()),
            _a[EMPLOYEE_FORM_KEYS.dayPrice] = new FormControl(0, [
                Validators.required,
                Validators.min(0),
                Validators.max(Number.MAX_SAFE_INTEGER),
            ]),
            _a[EMPLOYEE_FORM_KEYS.email] = new FormControl(null, [
                requiredTrimValidator(),
                Validators.email,
                Validators.maxLength(MAX_LENGTH_DEFAULT),
            ]),
            _a[EMPLOYEE_FORM_KEYS.cognitoUsername] = new FormControl(null, [
                requiredTrimValidator(),
                Validators.maxLength(MAX_LENGTH_COGNITO_USERNAME),
                Validators.pattern(PATTERN_COGNITO_USERNAME),
            ]),
            _a[EMPLOYEE_FORM_KEYS.discordSnowflake] = new FormControl(null, [
                Validators.maxLength(MAX_LENGTH_DISCORD_SNOWFLAKE),
                Validators.pattern(PATTERN_NUMBER),
            ]),
            _a[EMPLOYEE_FORM_KEYS.phoneNumber] = new FormControl(null, [
                phoneNumberValidator(),
                Validators.maxLength(MAX_LENGTH_DEFAULT),
            ]),
            _a[EMPLOYEE_FORM_KEYS.mobileNumber] = new FormControl(null, [
                phoneNumberValidator(),
                Validators.maxLength(MAX_LENGTH_DEFAULT),
            ]),
            _a));
        this.EMPLOYEE_FORM_KEYS = EMPLOYEE_FORM_KEYS;
        this.MAX_LENGTH_DEFAULT = MAX_LENGTH_DEFAULT;
        this.MAX_LENGTH_EMP_CODE = MAX_LENGTH_EMP_CODE;
        this.MAX_LENGTH_COGNITO_USERNAME = MAX_LENGTH_COGNITO_USERNAME;
        this.MAX_LENGTH_DISCORD_SNOWFLAKE = MAX_LENGTH_DISCORD_SNOWFLAKE;
        this.PATTERN_CAPITAL_NUMERIC_SPACE = PATTERN_CAPITAL_NUMERIC_SPACE;
    }
    EmployeeWizardEmployeeComponent.prototype.ngOnInit = function () {
        this.setEmployee();
    };
    EmployeeWizardEmployeeComponent.prototype.ngOnChanges = function (changes) {
        if (changes.employee) {
            this.setEmployee();
        }
        if (changes.edit) {
            this.toggleFormValidators();
        }
    };
    EmployeeWizardEmployeeComponent.prototype.toggleFormValidators = function () {
        if (this.edit) {
            this.form.get(EMPLOYEE_FORM_KEYS.cognitoUsername).disable();
        }
        else {
            this.form.get(EMPLOYEE_FORM_KEYS.cognitoUsername).enable();
        }
    };
    EmployeeWizardEmployeeComponent.prototype.onLoad = function () {
        var _this = this;
        requestAnimationFrame(function () {
            if (_this.nameElement) {
                _this.nameElement.nativeElement.focus();
            }
        });
    };
    EmployeeWizardEmployeeComponent.prototype.setEmployee = function () {
        var _a;
        if (!this.employee) {
            return this.reset();
        }
        this.form.reset(__assign((_a = {}, _a[EMPLOYEE_FORM_KEYS.dayPrice] = this.employee.dayPrice, _a[EMPLOYEE_FORM_KEYS.discordSnowflake] = this.employee.discordSnowflake, _a[EMPLOYEE_FORM_KEYS.mobileNumber] = this.employee.mobileNumber, _a[EMPLOYEE_FORM_KEYS.phoneNumber] = this.employee.phoneNumber, _a), ((this.employee && this.employee.user) || {})));
        if (!this.authService.hasRight(RIGHTS.EMPLOYEE_UPDATE)) {
            this.form.disable();
        }
        else {
            this.form.controls.cognitoUsername.disable();
        }
    };
    EmployeeWizardEmployeeComponent.prototype.submit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, name, code, color, dayPrice, email, cognitoUsername, discordSnowflake, mobileNumber, phoneNumber, cognitoValues, userValues, employeeValues, _b, employee, user;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!this.form.valid) {
                            this.clrForm.markAsDirty();
                            return [2, undefined];
                        }
                        _a = this.form.value, name = _a.name, code = _a.code, color = _a.color, dayPrice = _a.dayPrice, email = _a.email, cognitoUsername = _a.cognitoUsername, discordSnowflake = _a.discordSnowflake, mobileNumber = _a.mobileNumber, phoneNumber = _a.phoneNumber;
                        if (!cognitoUsername) return [3, 2];
                        cognitoValues = {
                            email: email,
                            username: cognitoUsername,
                            groupName: COGNITO_USER_GROUPS.EMPLOYEE,
                        };
                        return [4, this.httpRest
                                ._request(HTTP_METHOD.PUT, ApiRoutePlurality.SINGULAR, USER_SCHEMA_ROUTE, 'cognito', cognitoValues)
                                .toPromise()];
                    case 1:
                        _c.sent();
                        _c.label = 2;
                    case 2:
                        userValues = {
                            name: name,
                            code: code,
                            color: color,
                            email: email,
                        };
                        if (cognitoUsername) {
                            userValues.cognitoUsername = cognitoUsername;
                        }
                        employeeValues = {
                            dayPrice: dayPrice,
                            discordSnowflake: discordSnowflake,
                            mobileNumber: mobileNumber,
                            phoneNumber: phoneNumber,
                        };
                        return [4, this.httpRest
                                ._request(HTTP_METHOD.PUT, ApiRoutePlurality.SINGULAR, EMPLOYEE_SCHEMA_ROUTE, '/employee/withUser', {
                                userValues: userValues,
                                employeeValues: employeeValues,
                            })
                                .toPromise()];
                    case 3:
                        _b = _c.sent(), employee = _b.employee, user = _b.user;
                        employee.user = user;
                        return [2, employee];
                }
            });
        });
    };
    EmployeeWizardEmployeeComponent.prototype.reset = function () {
        var _a;
        this.form.reset((_a = {}, _a[EMPLOYEE_FORM_KEYS.dayPrice] = 0, _a[EMPLOYEE_FORM_KEYS.color] = getRandomColorHex(), _a));
    };
    return EmployeeWizardEmployeeComponent;
}());
export { EmployeeWizardEmployeeComponent };
