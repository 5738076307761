import {
    AfterContentChecked,
    Component,
    ContentChild,
    EmbeddedViewRef,
    Input,
    OnChanges,
    SimpleChanges,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import {TabContentDirective} from '../tab-content/tab-content.directive';

@Component({
    selector: 'app-tab',
    templateUrl: './tab.component.html',
    styleUrls: ['./tab.component.scss'],
})
export class TabComponent implements OnChanges, AfterContentChecked {
    @ContentChild(TabContentDirective, {read: TemplateRef})
    public _lazyContent: TemplateRef<any>;
    @ViewChild('viewOutlet', {read: ViewContainerRef})
    public viewOutlet: ViewContainerRef;

    @Input() public name = '';
    @Input() public bgColor: string;
    @Input() public label = '';
    @Input() public disabled = false;
    @Input() public justAButton = false;

    @Input() public queryParams: {[key: string]: string | number} = {};

    public isActive = false;
    private view: EmbeddedViewRef<ViewContainerRef>;

    public ngOnChanges(changes: SimpleChanges) {
        if (changes && changes.disabled && this.isActive) {
            this.isActive = false;
        }
    }

    public ngAfterContentChecked() {
        if (this._lazyContent && this.isActive && !this.view) {
            this.view = this.viewOutlet.createEmbeddedView(this._lazyContent, this);
        }
    }
}
