import {Component, HostListener, Input, OnChanges, SimpleChanges} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {fromEvent, noop} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {DECIMAL_RADIX, RIGHTS} from '../../../../defs/schema-static';
import {IStickyNote, STICKY_NOTE_SCHEMA_ROUTE} from '../../../../defs/schema/public/StickyNote';
import {AuthService} from '../auth/auth.service';
import {ModalSimpleComponent} from '../modal-simple/modal-simple.component';
import {ModalSimpleService} from '../modal-simple/modal-simple.service';
import {ConvertCheckboxesPipe} from '../shared/convert-checkboxes/convert-checkboxes.pipe';
import {HttpRestService} from '../shared/http-rest/http-rest.service';
import {MomentService} from '../shared/moment/moment.service';

export enum StickyNotesColor {
    yellow = '#FFE860',
    green = '#AADB1E',
    purple = '#B7BDE7',
    cyan = '#6DDBEB',
    blue = '#A6D8E7',
    red = '#FF9A69',
    teal = '#6FEAD9',
}

type StickNotesColorKey = keyof typeof StickyNotesColor;

export interface IColorStickyNote {
    key: StickNotesColorKey;
    color: StickyNotesColor;
}

export interface IStickyNoteExt extends Partial<IStickyNote> {
    edit?: boolean;
}

@Component({
    selector: 'app-sticky-notes',
    templateUrl: './sticky-notes.component.html',
    styleUrls: ['./sticky-notes.component.scss'],
})
export class StickyNotesComponent implements OnChanges {
    @Input() public stickyNotes: IStickyNoteExt[];
    @Input() public projectId?: number;
    @Input() public clientId?: number;
    @Input() public editAllowed: boolean;

    protected colorsAvailable: IColorStickyNote[];
    public selectedStickyNote: IStickyNoteExt;

    public constructor(
        private readonly translate: TranslateService,
        private readonly httpRest: HttpRestService,
        public authService: AuthService,
        private readonly momentService: MomentService,
        private readonly modalSimpleService: ModalSimpleService
    ) {
        const keys = Object.keys(StickyNotesColor) as StickNotesColorKey[];
        this.colorsAvailable = keys.map((key) => ({
            key,
            color: StickyNotesColor[key],
        }));
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.stickyNotes && changes.stickyNotes.currentValue.length > 0) {
            window.setTimeout(() => {
                this.stickyNotes
                    .sort((a, b) => {
                        const _a = this.momentService.moment(a.createdAt);
                        const _b = this.momentService.moment(b.createdAt);

                        return _a.isBefore(_b) ? -1 : 1;
                    })
                    .map((s, index) => {
                        this.listenElementTitle(index);
                    });
            }, 200);
        }
    }

    protected getRandomColor(): StickNotesColorKey {
        return this.colorsAvailable[Math.floor(Math.random() * this.colorsAvailable.length)].key;
    }

    protected addNote() {
        const note: Partial<IStickyNote> = {
            title: '',
            text: '',
            color: this.getRandomColor(),
        };
        if (this.projectId) {
            note.projectId = this.projectId;
        }
        if (this.clientId) {
            note.clientId = this.clientId;
        }
        this.stickyNotes.push(note);
        window.setTimeout(() => {
            const el = document.getElementById(`sn-title-${this.stickyNotes.length - 1}`);
            this.listenElementTitle(this.stickyNotes.length - 1);
            this.listenElementText(this.stickyNotes.length - 1);
            el.focus();
        }, 200);
    }

    protected saveNote(note: Partial<IStickyNote>) {
        if (!note) {
            return;
        }

        if (note.id) {
            this.httpRest.post<IStickyNote>(STICKY_NOTE_SCHEMA_ROUTE, note as Partial<IStickyNote> & {id: number});
        } else {
            this.httpRest.put<IStickyNote>(STICKY_NOTE_SCHEMA_ROUTE, note).subscribe((nt) => (note.id = nt.id));
        }
    }

    protected listenElementTitle(i: number) {
        const el = document.getElementById(`sn-title-${i}`);
        if (el) {
            fromEvent(el, 'keyup')
                .pipe(debounceTime(800))
                .subscribe((event: Event) => {
                    const note = this.stickyNotes[Number((event.target as HTMLTextAreaElement).dataset.index)];
                    note.title = (event.target as HTMLTextAreaElement).value;
                    this.saveNote(note);
                });
        }
    }

    protected listenElementText(i: number) {
        const el = document.getElementById(`sn-text-${i}`);
        if (el) {
            fromEvent(el, 'keyup')
                .pipe(debounceTime(800))
                .subscribe((event: Event) => {
                    const note = this.stickyNotes[Number((event.target as HTMLTextAreaElement).dataset.index)];
                    note.text = (event.target as HTMLTextAreaElement).value;
                    this.saveNote(note);
                });
        }
    }

    protected changeColor(sticky: number, color: number) {
        const note = this.stickyNotes[sticky];
        note.color = this.colorsAvailable[color].key;
        this.saveNote(note);
    }

    protected delete(stickyNote: IStickyNoteExt) {
        this.selectedStickyNote = stickyNote;
        if (!this.selectedStickyNote.id) {
            this.doDelete();

            return;
        }

        this.modalSimpleService
            .open(ModalSimpleComponent, {
                title: 'note_delete_modal_title',
                contentI18n: 'swal_cant_revert',
                ok: {
                    i18n: 'delete_note',
                    class: 'btn-danger',
                },
            })
            .subscribe((closed) => {
                if (closed.result) {
                    this.doDelete();
                }
            }, noop);
    }

    public doDelete() {
        if (this.selectedStickyNote.id) {
            try {
                this.httpRest.deleteId(STICKY_NOTE_SCHEMA_ROUTE, this.selectedStickyNote.id);
            } catch (err) {
                return;
            }
        }

        this.stickyNotes.splice(this.stickyNotes.indexOf(this.selectedStickyNote), 1);
    }

    protected edit(i: number, event: MouseEvent) {
        const target = event.target as HTMLElement;
        if (!target.closest('.clr-checkbox-container')) {
            this.stickyNotes[i].edit = true;
            window.setTimeout(() => {
                this.listenElementText(i);
                document.getElementById(`sn-text-${i}`).focus();
            }, 200);
        }
        event.stopPropagation();
    }

    @HostListener('document:click', ['$event'])
    public onClick(event: MouseEvent) {
        const editedElement = this.stickyNotes.map((f) => f.edit).indexOf(true);
        if (editedElement !== -1) {
            const element = document.getElementById(`sn-text-${editedElement}`);
            if (element) {
                if (!element.contains(event.target as HTMLElement)) {
                    this.stickyNotes[editedElement].edit = false;
                }
            }
        }
    }

    @HostListener('change', ['$event'])
    public checkboxChange($event: Event) {
        const $target = $event.target as HTMLInputElement;
        if (!$target.classList.contains('converted-checkbox')) {
            return;
        }

        const noteElement = $target.closest('.sticky-note') as HTMLElement;
        const noteIndex = parseInt(noteElement.dataset.index, DECIMAL_RADIX);
        const note = this.stickyNotes[noteIndex];
        const indexCheckbox = parseInt($target.dataset.index, 10);

        let _index = 0;
        note.text = note.text.replace(ConvertCheckboxesPipe.CHECKBOX_REGEX, (_match: string, checkbox: string) => {
            if (_index++ !== indexCheckbox) {
                return _match;
            }

            return _match.replace(checkbox, `[${$target.checked ? 'x' : ' '}]`);
        });
        this.saveNote(note);
    }

    protected readonly colors = StickyNotesColor;
    protected readonly RIGHTS = RIGHTS;
}
