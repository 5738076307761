var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClrLoadingState } from '@clr/angular';
import { TranslateService } from '@ngx-translate/core';
import publicConfig from '../../../../defs/config/config.json';
import { ApiRoutePlurality, HTTP_METHOD, MilestonesType, ProjectMemberType, ProjectStatusType, ReleaseStateType, RIGHTS, TaskStatusType, } from '../../../../defs/schema-static';
import { EMPLOYEE_SCHEMA_ROUTE } from '../../../../defs/schema/public/Employees';
import { PROJECT_SCHEMA_ROUTE } from '../../../../defs/schema/public/Projects';
import { STICKY_NOTE_SCHEMA_ROUTE } from '../../../../defs/schema/public/StickyNote';
import { EMPLOYEE_SORT_FUNCTION, PROJECT_MEMBER_SORT_FUNCTION } from '../../../../defs/sorters';
import { isColorDark, MAX_LENGTH_PROJECT_CODE, noop, PROJECT_STATUS_FILTER, PROJECT_TABS, PROJECT_TABS_LABELS, PROJECT_TABS_RIGHTS, } from '../app-static';
import { AuthService } from '../auth/auth.service';
import { validate } from '../forms/validators/form.validator';
import { ModalSimpleComponent } from '../modal-simple/modal-simple.component';
import { ModalSimpleService } from '../modal-simple/modal-simple.service';
import { ProjectWizardProjectComponent } from '../project-wizard/project/project-wizard-project.component';
import { HttpRestService } from '../shared/http-rest/http-rest.service';
import { LONG_AVATAR_TYPE } from '../shared/long-avatar/long-avatar.component';
import { SHORTCUT_LOCAL, ShortcutHandlerService } from '../shared/shortcut-handler/shortcut-handler.service';
import { TOAST_TYPE, ToastService } from '../shared/toast/toast.service';
var ProjectComponent = (function () {
    function ProjectComponent(httpRest, route, authService, router, shortcutHandlerService, toastService, translate, modalSimpleService) {
        this.httpRest = httpRest;
        this.route = route;
        this.authService = authService;
        this.router = router;
        this.shortcutHandlerService = shortcutHandlerService;
        this.toastService = toastService;
        this.translate = translate;
        this.modalSimpleService = modalSimpleService;
        this.project = {
            code: '',
            obs: '',
            status: ProjectStatusType.OPEN,
            clientId: 0,
            tasks: [],
        };
        this.displayProject = __assign({}, this.project);
        this.showEditModal = false;
        this.employees = [];
        this.stickyNotes = [];
        this.validateBtnState = ClrLoadingState.DEFAULT;
        this.MilestonesType = MilestonesType;
        this.ReleaseStateType = ReleaseStateType;
        this.TaskStatusType = TaskStatusType;
        this.PROJECT_TABS = PROJECT_TABS;
        this.PROJECT_TABS_LABELS = PROJECT_TABS_LABELS;
        this.PROJECT_STATUS_FILTER = PROJECT_STATUS_FILTER;
        this.validate = validate;
        this.LONG_AVATAR_TYPE = LONG_AVATAR_TYPE;
        this.isColorDark = isColorDark;
        this.ProjectMemberType = ProjectMemberType;
        this.publicConfig = publicConfig;
        this.RIGHTS = RIGHTS;
        this.PROJECT_TABS_RIGHTS = PROJECT_TABS_RIGHTS;
        this.statusList = Object.keys(ProjectStatusType);
        this.MAX_LENGTH_PROJECT_CODE = MAX_LENGTH_PROJECT_CODE;
        this.fetchData();
    }
    ProjectComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.shortcutHandlerService.register({
            shortcut: SHORTCUT_LOCAL.CANCEL,
            callback: function () {
                (function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                    return [2, this.router.navigate(['/projects'])];
                }); }); })();
            },
            context: this,
        }, true);
    };
    ProjectComponent.prototype.ngOnDestroy = function () { };
    ProjectComponent.prototype.fetchData = function () {
        var _this = this;
        this.route.params.subscribe(function (_a) {
            var id = _a.id;
            return __awaiter(_this, void 0, void 0, function () {
                var _this = this;
                return __generator(this, function (_b) {
                    this.httpRest
                        ._request(HTTP_METHOD.GET, ApiRoutePlurality.SINGULAR, PROJECT_SCHEMA_ROUTE, "complete/" + id)
                        .subscribe(function (project) {
                        _this.project = __assign({}, project, { tasks: [], projectMembers: (project.projectMembers || []).sort(PROJECT_MEMBER_SORT_FUNCTION) });
                        _this.displayProject = JSON.parse(JSON.stringify(_this.project));
                    });
                    this.httpRest
                        ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, STICKY_NOTE_SCHEMA_ROUTE, "project/" + id)
                        .subscribe(function (stickyNotes) { return (_this.stickyNotes = stickyNotes); });
                    this.httpRest
                        ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, EMPLOYEE_SCHEMA_ROUTE, 'project')
                        .subscribe(function (employees) { return (_this.employees = employees.sort(EMPLOYEE_SORT_FUNCTION)); });
                    return [2];
                });
            });
        });
    };
    ProjectComponent.prototype.saveProject = function () {
        return __awaiter(this, void 0, void 0, function () {
            var project, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.editComponent.form || !this.editComponent.form.valid) {
                            return [2];
                        }
                        this.validateBtnState = ClrLoadingState.LOADING;
                        project = this.editComponent.form.getRawValue();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4, this.httpRest
                                .post(PROJECT_SCHEMA_ROUTE, __assign({ id: this.project.id }, project))
                                .toPromise()];
                    case 2:
                        _a.sent();
                        return [3, 5];
                    case 3:
                        err_1 = _a.sent();
                        return [2];
                    case 4:
                        this.showEditModal = false;
                        return [7];
                    case 5:
                        this.validateBtnState = ClrLoadingState.SUCCESS;
                        Object.assign(this.project, project);
                        this.toastService.show({
                            type: TOAST_TYPE.SUCCESS,
                            text: 'success_update_project',
                        });
                        return [2];
                }
            });
        });
    };
    ProjectComponent.prototype.deleteProjectModal = function () {
        var _this = this;
        this.modalSimpleService
            .open(ModalSimpleComponent, {
            title: 'project_delete_modal_title',
            contentI18n: 'swal_delete_attch',
            contentObj: { what: this.project.obs },
            ok: {
                i18n: 'delete_project',
                class: 'btn-danger',
            },
        })
            .subscribe(function (closed) {
            if (closed.result) {
                _this.deleteProject();
            }
        }, noop);
    };
    ProjectComponent.prototype.deleteProject = function () {
        return __awaiter(this, void 0, void 0, function () {
            var err_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4, this.httpRest.deleteId(PROJECT_SCHEMA_ROUTE, this.project.id).toPromise()];
                    case 1:
                        _a.sent();
                        return [3, 3];
                    case 2:
                        err_2 = _a.sent();
                        return [2, undefined];
                    case 3:
                        this.toastService.show({
                            type: TOAST_TYPE.SUCCESS,
                            text: 'success_delete_project',
                        });
                        return [2, this.router.navigate(['/projects'])];
                }
            });
        });
    };
    return ProjectComponent;
}());
export { ProjectComponent };
