<app-modal [title]="'settings' | translate" [(show)]="show" (showChange)="showChange.emit($event)" (dismiss)="cancel()">
    <ng-template appModalBody>
        <form id="settingsForm" clrForm>
            <clr-select-container>
                <label class="clr-col-xs-12 clr-col-md-6">
                    <clr-icon shape="paint-roller"></clr-icon>
                    {{ SETTINGS.THEME | lowercase | translate }}
                </label>

                <select
                    class="clr-col-xs-12 clr-col-md-6"
                    clrSelect
                    [name]="SETTINGS.THEME"
                    [(ngModel)]="settings[SETTINGS.THEME]"
                >
                    <option *ngFor="let theme of SETTING_THEME_VALUES_VALUES" [ngValue]="theme">
                        {{ 'theme_' + theme | lowercase | translate }}
                    </option>
                </select>
            </clr-select-container>

            <clr-select-container>
                <label class="clr-col-xs-12 clr-col-md-6">
                    <clr-icon shape="language"></clr-icon>
                    {{ SETTINGS.LANGUAGE | lowercase | translate }}
                </label>

                <select
                    class="clr-col-xs-12 clr-col-md-6"
                    clrSelect
                    [name]="SETTINGS.LANGUAGE"
                    [(ngModel)]="settings[SETTINGS.LANGUAGE]"
                >
                    <option *ngFor="let language of getAvailableLanguages()" [ngValue]="language">
                        {{ SETTINGS_LANGUAGE_LABELS[language] }}
                    </option>
                </select>
            </clr-select-container>

            <clr-checkbox-container>
                <label
                    class="clr-col-xs-12 clr-col-md-6"
                    [for]="SETTINGS.EMAIL_NOTIFICATIONS"
                    style="line-height: 1rem;"
                >
                    <clr-icon shape="envelope"></clr-icon>
                    {{ SETTINGS.EMAIL_NOTIFICATIONS | lowercase | translate }}
                </label>

                <clr-checkbox-wrapper>
                    <label></label>
                    <input
                        clrCheckbox
                        [name]="SETTINGS.EMAIL_NOTIFICATIONS"
                        [id]="SETTINGS.EMAIL_NOTIFICATIONS"
                        type="checkbox"
                        [(ngModel)]="settings[SETTINGS.EMAIL_NOTIFICATIONS]"
                    />
                </clr-checkbox-wrapper>
            </clr-checkbox-container>
        </form>
    </ng-template>

    <ng-template appModalFooter>
        <button class="btn btn-secondary" (click)="cancel()">{{ 'form_cancel' | translate }}</button>
        <button form="settingsForm" class="btn btn-success" (click)="confirm()">
            {{ 'save_settings' | translate }}
        </button>
    </ng-template>
</app-modal>
