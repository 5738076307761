import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {ClrWizard} from '@clr/angular';
import {FormGroup} from 'ngx-strongly-typed-forms';
import {ApiRoutePlurality, HTTP_METHOD, ProjectMemberType} from '../../../../defs/schema-static';
import {CLIENT_SCHEMA_ROUTE, IClient} from '../../../../defs/schema/public/Clients';
import {IProjectMember} from '../../../../defs/schema/public/ProjectMembers';
import {IProject} from '../../../../defs/schema/public/Projects';
import {AuthService} from '../auth/auth.service';
import {HttpRestService} from '../shared/http-rest/http-rest.service';
import {SHORTCUT_WIZARD, ShortcutHandlerService} from '../shared/shortcut-handler/shortcut-handler.service';
import {ProjectWizardProjectMembersComponent} from './project-members/project-wizard-project-members.component';
import {ProjectWizardProjectComponent} from './project/project-wizard-project.component';

@Component({
    selector: 'app-project-wizard',
    templateUrl: './project-wizard.component.html',
})
export class ProjectWizardComponent implements OnInit, OnChanges {
    @ViewChild(ClrWizard) public wizard: ClrWizard;
    @ViewChild(ProjectWizardProjectComponent) public projectComponent: ProjectWizardProjectComponent;
    @ViewChild(ProjectWizardProjectMembersComponent) public membersComponent: ProjectWizardProjectMembersComponent;

    @Input() public clrWizardOpen = false;
    @Output() public clrWizardOpenChange = new EventEmitter<boolean>();

    @Output() public finish = new EventEmitter<IProject>();

    @Input() public clients: IClient[];
    @Input() public clientId: number;

    public submitting = false;

    public projectMembers: Partial<IProjectMember>[] = [];

    public readonly defaultProjectMembers: Partial<IProjectMember>[] = [
        {
            employee: this.authService.user.employee,
            type: ProjectMemberType.MANAGER,
        },
    ];

    public constructor(
        private readonly httpRest: HttpRestService,
        private readonly authService: AuthService,
        private readonly shortcutHandlerService: ShortcutHandlerService
    ) {
        this.projectMembers = [...this.defaultProjectMembers];
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.clrWizardOpen && changes.clrWizardOpen.currentValue && !this.clients) {
            (async () => this.getClients())();
        }
    }

    public ngOnInit() {
        this.shortcutHandlerService.register({
            shortcut: SHORTCUT_WIZARD.NEXT,
            callback: (e) => {
                if (!this.wizard.currentPage.nextStepDisabled && (e.target as HTMLElement).tagName !== 'TEXTAREA') {
                    if (this.wizard.isLast) {
                        this.triggerFinish();
                    } else {
                        this.wizard.next();
                    }
                }
            },
            context: this,
            forceListen: true,
        });
    }

    public async getClients(): Promise<void> {
        this.clients = (await this.httpRest
            ._request<IClient[]>(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, CLIENT_SCHEMA_ROUTE, 'light')
            .toPromise()).sort((c1, c2) => c1.user.name.localeCompare(c2.user.name));
    }

    public async triggerFinish() {
        this.submitting = true;
        try {
            const project = await this.projectComponent.submit();

            if (!project) {
                return;
            }

            this.membersComponent.project = project;
            project.projectMembers = await this.membersComponent.submit();

            this.finish.emit(project);
        } catch (err) {
            return;
        } finally {
            this.submitting = false;
        }

        this.triggerCancel();
    }

    public triggerCancel() {
        this.reset();
        this.wizard.forceFinish();
    }

    public reset(): void {
        this.wizard.reset();
        [this.projectComponent].map((component) => component.reset());
        [this.membersComponent].map(({form}: {form: FormGroup<any>}) => form.reset());
        this.projectMembers = [...this.defaultProjectMembers];
    }
}
