import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'sharedArraySort',
})
export class SharedArraySortPipe implements PipeTransform {
    public value: string[] = [];

    public static _orderByComparator(a: any, b: any): number {
        if (a === null || typeof a === 'undefined') {
            a = 0;
        }
        if (b === null || typeof b === 'undefined') {
            b = 0;
        }

        if (isNaN(parseFloat(a)) || !isFinite(a) || (isNaN(parseFloat(b)) || !isFinite(b))) {
            if (a.toLowerCase() < b.toLowerCase()) {
                return -1;
            }
            if (a.toLowerCase() > b.toLowerCase()) {
                return 1;
            }
        } else {
            if (parseFloat(a) < parseFloat(b)) {
                return -1;
            }
            if (parseFloat(a) > parseFloat(b)) {
                return 1;
            }
        }

        return 0;
    }

    public transform(input: any, config = '+'): any {
        if (!input) {
            return input;
        }

        this.value = [...input];
        const value = this.value;

        if (!Array.isArray(value)) {
            return value;
        }

        if (!Array.isArray(config) || (Array.isArray(config) && config.length === 1)) {
            const propertyToCheck: string = !Array.isArray(config) ? config : config[0];
            const desc = propertyToCheck.substr(0, 1) === '-';

            if (!propertyToCheck || propertyToCheck === '-' || propertyToCheck === '+') {
                return !desc ? value.sort() : value.sort().reverse();
            } else {
                const property: string =
                    propertyToCheck.substr(0, 1) === '+' || propertyToCheck.substr(0, 1) === '-'
                        ? propertyToCheck.substr(1)
                        : propertyToCheck;

                return value.sort((a: any, b: any) => {
                    let aValue = a[property];
                    let bValue = b[property];

                    const propertySplit = property.split('.');

                    if (typeof aValue === 'undefined' && typeof bValue === 'undefined' && propertySplit.length > 1) {
                        aValue = a;
                        bValue = b;
                        propertySplit.map((p) => {
                            aValue = aValue[p];
                            bValue = bValue[p];
                        });
                    }

                    return !desc
                        ? SharedArraySortPipe._orderByComparator(aValue, bValue)
                        : -SharedArraySortPipe._orderByComparator(aValue, bValue);
                });
            }
        } else {
            return value.sort((a: any, b: any) => {
                config.map((c) => {
                    const desc = c.substr(0, 1) === '-';
                    const property = c.substr(0, 1) === '+' || c.substr(0, 1) === '-' ? c.substr(1) : c;

                    let aValue = a[property];
                    let bValue = b[property];

                    const propertySplit = property.split('.');

                    if (typeof aValue === 'undefined' && typeof bValue === 'undefined' && propertySplit.length > 1) {
                        aValue = a;
                        bValue = b;
                        propertySplit.map((p: any) => {
                            aValue = aValue[p];
                            bValue = bValue[p];
                        });
                    }

                    const comparison = !desc
                        ? SharedArraySortPipe._orderByComparator(aValue, bValue)
                        : -SharedArraySortPipe._orderByComparator(aValue, bValue);

                    if (comparison !== 0) {
                        return comparison;
                    }
                });

                return 0;
            });
        }
    }
}
