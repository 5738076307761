<div class="column-container py-02">
    <div class="clr-row flex-1-set">
        <div class="clr-col-12">
            <clr-datagrid appDatagridToggle class="h-100" [(clrDgSelected)]="selectedBudgets" [clrDgLoading]="!budgets">
                <clr-dg-action-bar>
                    <div class="btn-group">
                        <button class="btn btn-sm" (click)="showEditModal = true" *ngIf="isManagerOf(project.id)">
                            {{ 'add_budget' | translate }}
                        </button>
                    </div>

                    <div class="btn-group">
                        <button
                            class="btn btn-sm btn-danger"
                            (click)="showDeleteModal = true"
                            *ngIf="isManagerOf(project.id)"
                            [disabled]="!selectedBudgets.length"
                        >
                            {{ (selectedBudgets.length > 1 ? 'delete_budgets' : 'delete_budget') | translate }}
                        </button>
                    </div>
                </clr-dg-action-bar>

                <clr-dg-column
                    *ngFor="let column of BUDGETS_COLUMNS"
                    [clrDgField]="column.field"
                    [clrDgSortOrder]="column.order"
                    [clrDgSortBy]="column.sorter"
                >
                    {{ column.translateKey | translate }}

                    <clr-dg-filter
                        *ngIf="!column.filterType || column.filterType === DATAGRID_FILTER_TYPE.FUZZY"
                        [clrDgFilter]="fuzzyFilter"
                        [(clrDgFilterOpen)]="filterOpen[column.field]"
                    >
                        <shared-fuzzy-datagrid-filter
                            [filterKey]="column.field"
                            [filterOpen]="filterOpen[column.field]"
                            #fuzzyFilter
                        ></shared-fuzzy-datagrid-filter>
                    </clr-dg-filter>

                    <clr-dg-filter
                        *ngIf="column.filterType === DATAGRID_FILTER_TYPE.DATE"
                        [clrDgFilter]="dateFilter"
                        [(clrDgFilterOpen)]="filterOpen[column.field]"
                    >
                        <shared-date-datagrid-filter
                            [filterKey]="column.field"
                            [filterOpen]="filterOpen[column.field]"
                            #dateFilter
                        ></shared-date-datagrid-filter>
                    </clr-dg-filter>

                    <clr-dg-filter *ngIf="column.field === BUDGET_FIELD.status" [clrDgFilter]="statusFilter">
                        <shared-generic-datagrid-filter
                            [filter]="BUDGET_STATUS_FILTER"
                            [filterKey]="column.field"
                            [unfilteredEntities]="budgets"
                            #statusFilter
                        ></shared-generic-datagrid-filter>
                    </clr-dg-filter>
                </clr-dg-column>

                <clr-dg-row *clrDgItems="let budget of budgets" [clrDgItem]="budget">
                    <clr-dg-cell>
                        <ng-container *ngIf="isManagerOf(project.id); else descriptionWithoutLink">
                            <a [routerLink]="['.']" [queryParams]="{edit: budget.id}" [queryParamsHandling]="'merge'">{{
                                budget.description
                            }}</a>
                        </ng-container>
                        <ng-template #descriptionWithoutLink> {{ budget.description }} </ng-template>
                    </clr-dg-cell>
                    <clr-dg-cell>{{ budget.price | currency: 'EUR' }}</clr-dg-cell>
                    <clr-dg-cell>
                        <a
                            *ngIf="budget.release"
                            [routerLink]="['.']"
                            [queryParams]="{tab: PROJECT_TABS.RELEASES, edit: budget.release.id}"
                        >
                            <code>{{ budget.release.version }}</code> - {{ budget.release.obs }}
                        </a>
                    </clr-dg-cell>
                    <clr-dg-cell class="monospace-date" [title]="budget.createdAt | momentDuration">{{
                        budget.createdAt | momentFormat: 'L LT'
                    }}</clr-dg-cell>
                    <clr-dg-cell>
                        <span [classList]="BUDGET_STATUS_FILTER[budget.status].classList">
                            <clr-icon [attr.shape]="BUDGET_STATUS_FILTER[budget.status].icon"></clr-icon>
                            {{ BUDGET_STATUS_FILTER[budget.status].translation | translate }}
                        </span>
                    </clr-dg-cell>
                </clr-dg-row>

                <clr-dg-footer>
                    <span *ngIf="budgets">{{ 'count_budgets' | translate: {count: budgets.length} }}</span>
                </clr-dg-footer>
            </clr-datagrid>
        </div>
    </div>
</div>

<app-edit-budget
    [budget]="selectedBudget"
    [projectId]="project.id"
    [releases]="releases"
    [(show)]="showEditModal"
    (hidden)="closeEditModal()"
    (budgetChange)="budgetChange($event)"
>
</app-edit-budget>

<app-modal [(show)]="showDeleteModal" [title]="'budget_delete_modal_title' | translate">
    <ng-template appModalBody>
        <p>
            {{
                'swal_delete_attch'
                    | translate
                        : {
                              what:
                                  selectedBudgets.length > 1
                                      ? ('count_budgets' | translate: {count: selectedBudgets.length})
                                      : selectedBudgets[0].description
                          }
            }}
        </p>
    </ng-template>
    <ng-template appModalFooter>
        <button
            class="btn btn-secondary"
            (click)="showDeleteModal = false"
            [sharedAutofocus]="showDeleteModal"
            translate
        >
            form_cancel
        </button>
        <button class="btn btn-danger" (click)="deleteSelectedBudgets()">
            {{ (selectedBudgets.length > 1 ? 'delete_budgets' : 'delete_budget') | translate }}
        </button>
    </ng-template>
</app-modal>
