import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';

import {ClrDatagridSortOrder} from '@clr/angular';
import {TranslateService} from '@ngx-translate/core';
import {ApiRoutePlurality, COGNITO_USER_GROUPS, HTTP_METHOD, RIGHTS} from '../../../../defs/schema-static';
import {CUSTOMER_CONTACT_FIELD, CUSTOMER_CONTACT_SCHEMA_ROUTE} from '../../../../defs/schema/public/CustomerContacts';
import {EMPLOYEE_FIELD, EMPLOYEE_SCHEMA_ROUTE, IEmployee} from '../../../../defs/schema/public/Employees';
import {PROJECT_MEMBER_FIELD, PROJECT_MEMBER_SCHEMA_ROUTE} from '../../../../defs/schema/public/ProjectMembers';
import {IUser, USER_FIELD, USER_SCHEMA_ROUTE} from '../../../../defs/schema/public/Users';
import {getRandomColorHex, IDatagridColumn} from '../app-static';
import {AuthService} from '../auth/auth.service';
import {validate} from '../forms/validators/form.validator';
import {HttpRestService} from '../shared/http-rest/http-rest.service';
import {LONG_AVATAR_TYPE} from '../shared/long-avatar/long-avatar.component';
import {
    SHORTCUT_CREATE,
    SHORTCUT_LOCAL,
    ShortcutHandlerService,
} from '../shared/shortcut-handler/shortcut-handler.service';
import {TOAST_TYPE, ToastService} from '../shared/toast/toast.service';

@Component({
    selector: 'app-employees',
    templateUrl: './employees.component.html',
    styleUrls: ['./employees.component.scss'],
})
export class EmployeesComponent implements OnInit, OnDestroy {
    public employees: IEmployee[];

    public showNewEmployeeModal = false;

    public readonly EMPLOYEES_COLUMNS: IDatagridColumn[] = [
        {
            name: 'Name',
            field: `${EMPLOYEE_FIELD.user}.${USER_FIELD.name}`,
            translateKey: 'table_name',
            order: ClrDatagridSortOrder.ASC,
        },
        {name: 'Price per day', field: EMPLOYEE_FIELD.dayPrice, translateKey: 'table_price_day', right: RIGHTS.MONEY},
    ];
    public filterOpen: {[key: string]: boolean} = {};

    public selectedEmployees: IEmployee[] = [];
    public showCreateModal = false;
    public showDeleteModal = false;

    public constructor(
        private readonly httpRest: HttpRestService,
        private readonly shortcutHandlerService: ShortcutHandlerService,
        private readonly toastService: ToastService,
        private readonly translate: TranslateService,
        private readonly authService: AuthService
    ) {}

    public ngOnInit() {
        if (!this.employees) {
            (async () => this.getEmployees())();
        }

        this.shortcutHandlerService.register(
            {
                name: SHORTCUT_CREATE.EMPLOYEE,
                shortcut: SHORTCUT_LOCAL.CREATE,
                callback: () => (this.showCreateModal = true),
                context: this,
            },
            true
        );
    }

    // tslint:disable-next-line:prefer-function-over-method no-empty
    public ngOnDestroy() {}

    public async getEmployees() {
        this.employees = await this.httpRest
            ._request<IEmployee[]>(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, EMPLOYEE_SCHEMA_ROUTE, 'employees')
            .toPromise();
    }

    public async addNewEmployee(employee: IEmployee) {
        if (this.authService.hasRight(RIGHTS.EMPLOYEE_FETCH_ALL)) {
            this.employees.push(employee);
        }
        this.toastService.show({
            type: TOAST_TYPE.SUCCESS,
            text: 'success_insert_employee',
        });
    }

    protected onSubmit(f: NgForm) {
        if (!f.valid) {
            return;
        }

        const {empName, empCode, email, empPrice} = f.value;
        let {cognitoUsername} = f.value;
        cognitoUsername = cognitoUsername || null;

        const putCognito = () =>
            this.httpRest._request(HTTP_METHOD.PUT, ApiRoutePlurality.SINGULAR, USER_SCHEMA_ROUTE, 'cognito', {
                email,
                username: cognitoUsername,
                groupName: COGNITO_USER_GROUPS.EMPLOYEE,
            });

        const putUser = () =>
            this.httpRest.put<IUser>(USER_SCHEMA_ROUTE, {
                cognitoUsername,
                email,
                name: empName,
                code: empCode,
                color: getRandomColorHex(),
            });

        const putEmployee = (user: IUser) =>
            this.httpRest.put<IEmployee>(EMPLOYEE_SCHEMA_ROUTE, {
                userId: user.id,
                dayPrice: empPrice,
            });

        putCognito().subscribe(() =>
            putUser().subscribe((user) =>
                putEmployee(user).subscribe((employee: IEmployee) => {
                    this.showNewEmployeeModal = false;

                    this.employees.push(employee);
                })
            )
        );
    }

    public async deleteSelectedEmployees() {
        const deletedEmployeesIds = this.selectedEmployees.map(({id}) => id);

        try {
            await Promise.all(
                deletedEmployeesIds.map(async (employeeId) =>
                    this.httpRest
                        ._request(
                            HTTP_METHOD.DELETE,
                            ApiRoutePlurality.SINGULAR,
                            EMPLOYEE_SCHEMA_ROUTE,
                            `/employee/withUser/${employeeId}`
                        )
                        .toPromise()
                )
            );
        } catch (err) {
            return;
        } finally {
            this.showDeleteModal = false;
        }

        this.selectedEmployees = [];
        this.employees = this.employees.filter(({id}) => !deletedEmployeesIds.includes(id));

        this.toastService.show({
            type: TOAST_TYPE.SUCCESS,
            text: 'success_delete_employee',
        });
    }

    public readonly validate = validate;
    public readonly RIGHTS = RIGHTS;
    public readonly LONG_AVATAR_TYPE = LONG_AVATAR_TYPE;
}
