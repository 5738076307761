import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ISettings, SETTINGS} from '../../../../../defs/schema/public/Users';
import {SettingsService} from '../../settings/settings.service';

@Injectable({
    providedIn: 'root',
})
export class ControlFlowService {
    public valid = new BehaviorSubject<boolean>(null); // false
    public isCollapsed = new BehaviorSubject<boolean>(this.settingsService.settings[SETTINGS.SIDEBAR_COLLAPSED]);
    public leftOffset = new BehaviorSubject<string>(ControlFlowService.LEFT_SIDEBAR_OFFSET[+this.isCollapsed.value]);
    public rightOffset = new BehaviorSubject<string>('0');

    public valid$ = this.valid.asObservable();
    public isCollapsed$ = this.isCollapsed.asObservable();
    public leftOffset$ = this.leftOffset.asObservable();
    public rightOffset$ = this.rightOffset.asObservable();

    public isNotFound: boolean;
    public constructor(private readonly settingsService: SettingsService) {
        this.settingsService.settingsRestored.subscribe((settings: ISettings) => {
            if (this.isCollapsed.value !== settings[SETTINGS.SIDEBAR_COLLAPSED]) {
                this.toggleSidebar();
            }
        });
    }

    public validate(valid: boolean) {
        this.valid.next(valid);
    }

    public toggleSidebar() {
        const isCollapsed = !this.isCollapsed.value;
        this.isCollapsed.next((this.settingsService.settings[SETTINGS.SIDEBAR_COLLAPSED] = isCollapsed));
        (async () => this.settingsService.save())();

        this.leftOffset.next(ControlFlowService.LEFT_SIDEBAR_OFFSET[+isCollapsed]);
    }

    public static readonly LEFT_SIDEBAR_OFFSET: [string, string] = ['240px', '50px'];
}
