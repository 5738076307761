var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import { ChangeDetectorRef, ElementRef, EventEmitter, OnChanges, OnInit, SimpleChanges, } from '@angular/core';
import { Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { concat, forkJoin, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import * as SemVer from 'semver';
import { overEstimatedTime } from '../../../../defs/businessRules';
import { ApiRoutePlurality, HTTP_METHOD, ISO_DATE_FORMAT, MAX_LENGTH_TASK_NAME, PATTERN_TIME, ReleaseStateType, RIGHTS, TaskType, } from '../../../../defs/schema-static';
import { TAG_SCHEMA_ROUTE } from '../../../../defs/schema/public/Tags';
import { TASK_BLOCKER_SCHEMA_ROUTE } from '../../../../defs/schema/public/TaskBlockers';
import { TASK_SCHEMA_ROUTE } from '../../../../defs/schema/public/Tasks';
import { TASK_TAG_SCHEMA_ROUTE } from '../../../../defs/schema/public/TaskTags';
import { AddBlockersService } from '../add-blockers-modal/add-blockers.service';
import { getRandomColorHex } from '../app-static';
import { AuthService } from '../auth/auth.service';
import { EditTaskFormField, FormsAddTaskService } from '../forms/add-task/add-task.service';
import { validate } from '../forms/validators/form.validator';
import { ModalComponent } from '../modal/modal.component';
import { ConfigService } from '../shared/config/config.service';
import { ControlFlowService } from '../shared/control-flow/control-flow.service';
import { HttpRestService } from '../shared/http-rest/http-rest.service';
import { MomentService } from '../shared/moment/moment.service';
import { SHORTCUT_MISC, ShortcutHandlerService } from '../shared/shortcut-handler/shortcut-handler.service';
import { TOAST_TYPE, ToastService } from '../shared/toast/toast.service';
var EditTaskComponent = (function (_super) {
    __extends(EditTaskComponent, _super);
    function EditTaskComponent(shortcutHandlerService, cdRef, toastService, translate, addBlockers, httpRest, authService, formsAddTaskService, controlFlowService, momentService, configService) {
        var _this = _super.call(this, shortcutHandlerService, controlFlowService, cdRef) || this;
        _this.shortcutHandlerService = shortcutHandlerService;
        _this.cdRef = cdRef;
        _this.toastService = toastService;
        _this.translate = translate;
        _this.addBlockers = addBlockers;
        _this.httpRest = httpRest;
        _this.authService = authService;
        _this.formsAddTaskService = formsAddTaskService;
        _this.momentService = momentService;
        _this.configService = configService;
        _this.form = FormsAddTaskService.getEditFormGroup();
        _this.triggerCdRef = new EventEmitter();
        _this.otherTasks = false;
        _this.saving = false;
        _this.tasksParentLoading = false;
        _this.tasksParent$ = new Subject();
        _this.checkDates = function () {
            if (!_this.form) {
                return false;
            }
            var beginDate;
            var endDate;
            var error = false;
            if (!_this.beginDate && _this.beginDateInputElement && _this.beginDateInputElement.nativeElement.value !== '') {
                beginDate = _this.beginDateInputElement.nativeElement.value;
                if (!_this.momentService.moment(beginDate, ISO_DATE_FORMAT, true).isValid()) {
                    error = true;
                }
            }
            if (!_this.endDate && _this.endDateInputElement && _this.endDateInputElement.nativeElement.value !== '') {
                endDate = _this.endDateInputElement.nativeElement.value;
                if (!_this.momentService.moment(endDate, ISO_DATE_FORMAT, true).isValid()) {
                    error = true;
                }
            }
            _this.form.patchValue({
                startDate: _this.beginDate ? _this.momentService.moment(_this.beginDate).toISOString() : null,
                endDate: _this.endDate ? _this.momentService.moment(_this.endDate).toISOString() : null,
            });
            if ((!_this.beginDate || !_this.endDate) && !_this.form.value.releaseId) {
                return false;
            }
            else if (_this.momentService
                .moment(_this.beginDate)
                .startOf('day')
                .isAfter(_this.momentService.moment(_this.endDate).endOf('day'))) {
                return false;
            }
            else if (error) {
                return false;
            }
            else {
                return true;
            }
        };
        _this.validate = validate;
        _this.MAX_LENGTH_TASK_NAME = MAX_LENGTH_TASK_NAME;
        _this.EditTaskFormField = EditTaskFormField;
        _this.RIGHTS = RIGHTS;
        _this.SHORTCUT_MISC = SHORTCUT_MISC;
        return _this;
    }
    EditTaskComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        _super.prototype.ngOnChanges.call(this, changes);
        if (changes.show && changes.show.currentValue === false && this.form) {
            this.reset();
        }
        if (changes.show && changes.show.currentValue === true && this.form) {
            this.initValues();
        }
        if (changes.taskToEdit) {
            this.initValues();
        }
        if (changes.releases && !this.releasesStatic) {
            requestAnimationFrame(function () {
                _this.releasesStatic = _this.releases.sort(function (a, b) {
                    if (a.releaseState === ReleaseStateType.IN_DEVELOPMENT &&
                        b.releaseState === ReleaseStateType.IN_DEVELOPMENT) {
                        return SemVer.compare(a.version, b.version);
                    }
                    else if (a.releaseState === ReleaseStateType.IN_DEVELOPMENT &&
                        b.releaseState !== ReleaseStateType.IN_DEVELOPMENT) {
                        return -1;
                    }
                    return SemVer.compare(b.version, a.version);
                });
            });
        }
    };
    EditTaskComponent.prototype.ngOnInit = function () {
        this.initValues();
    };
    EditTaskComponent.prototype.getInputNameLength = function () {
        if (!this.nameInput) {
            return;
        }
        return this.nameInput.nativeElement.value.length;
    };
    EditTaskComponent.prototype.reset = function () {
        this.form.reset({
            name: this.taskToEdit && this.taskToEdit.name,
            progress: this.taskToEdit && this.taskToEdit.progress,
        });
        this.parentTask = null;
    };
    EditTaskComponent.prototype.initValues = function () {
        var _this = this;
        if (!this.taskToEdit) {
            this.reset();
            return;
        }
        this.otherTasks =
            this.tasks.filter(function (t) { return t.projectId === _this.taskToEdit.projectId && t.id !== _this.taskToEdit.id; }).length >
                0;
        this.parentTask = null;
        this.loadParentTasks();
        if (this.taskToEdit.parentId) {
            var ptask = this.tasks.find(function (t) { return t.id === _this.taskToEdit.parentId; });
            if (ptask) {
                this.parentTask = {};
                this.parentTask.name = ptask.name;
                this.parentTask.id = ptask.id;
                this.parentTask.code = ptask.code;
            }
        }
        var blockers = [];
        if (this.taskToEdit.taskBlockers && this.taskToEdit.taskBlockers.length > 0) {
            blockers = this.taskToEdit.taskBlockers.map(function (b) {
                return {
                    name: _this.getBlockerName(b),
                    code: _this.getBlockerCode(b),
                    blockerId: b.blockerId,
                    id: b.id,
                    active: b.active,
                };
            });
        }
        var startDate = this.taskToEdit.beginDate
            ? this.momentService.moment(this.taskToEdit.beginDate).format(ISO_DATE_FORMAT)
            : null;
        var endDate = this.taskToEdit.endDate
            ? this.momentService.moment(this.taskToEdit.endDate).format(ISO_DATE_FORMAT)
            : null;
        requestAnimationFrame(function () {
            _this.form.patchValue({
                parentTaskId: _this.parentTask ? _this.parentTask : null,
                releaseId: _this.taskToEdit.targetReleaseId,
                project: _this.taskToEdit.projectId,
                estimated: _this.taskToEdit.estimatedTime,
                name: _this.taskToEdit.name,
                progress: _this.taskToEdit.progress,
                bug: _this.taskToEdit.type === TaskType.BUG,
                urgent: _this.taskToEdit.urgent,
                blockers: blockers,
                tags: _this.taskToEdit.tasktags,
                startDate: startDate,
                endDate: endDate,
            });
            var pattern = Validators.pattern(PATTERN_TIME);
            _this.form.controls.estimated.setValidators([pattern]);
            _this.beginDate = _this.taskToEdit.beginDate
                ? _this.momentService.moment(_this.taskToEdit.beginDate).toDate()
                : null;
            _this.endDate = _this.taskToEdit.endDate ? _this.momentService.moment(_this.taskToEdit.endDate).toDate() : null;
            _this.toggleRelease(false);
        });
        this.httpRest
            ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, TAG_SCHEMA_ROUTE, "project/" + this.taskToEdit.projectId)
            .subscribe(function (tags) {
            _this._tags = tags;
            _this.tags = _this._tags.filter(function (t) { return _this.form.value.tags.findIndex(function (_t) { return t.id === _t.tagId; }) === -1; });
        });
        this.addBlockers.init(true, this.form, this.taskToEdit, blockers);
    };
    EditTaskComponent.prototype.onParentChange = function ($event) {
        if ($event === void 0) { $event = null; }
        this.form.patchValue({ parentTaskId: $event ? $event : null });
    };
    EditTaskComponent.prototype.onReleaseIdChange = function ($event) {
        if ($event === void 0) { $event = null; }
        var nowStr = this.momentService.moment();
        var currentValueStart = this.form.value.startDate || nowStr.format(ISO_DATE_FORMAT);
        var currentValueEnd = this.form.value.endDate || nowStr.format(ISO_DATE_FORMAT);
        this.form.patchValue({
            releaseId: $event ? $event.id : null,
            startDate: $event ? null : currentValueStart,
            endDate: $event ? null : currentValueEnd,
        });
        if ($event && $event.id) {
            this.beginDate = null;
            this.beginDateInputElement.nativeElement.value = '';
            this.endDate = null;
            this.endDateInputElement.nativeElement.value = '';
        }
        else {
            this.beginDate = nowStr.toDate();
            this.endDate = nowStr.toDate();
        }
        this.toggleRelease();
    };
    EditTaskComponent.prototype.toggleRelease = function (reset) {
        if (reset === void 0) { reset = true; }
        if (this.form.value.releaseId) {
            this.form.controls.startDate.clearValidators();
            this.form.controls.endDate.clearValidators();
            if (reset) {
                this.form.patchValue({
                    startDate: null,
                    endDate: null,
                });
                this.beginDate = null;
                this.endDate = null;
            }
        }
        else {
            this.form.controls.startDate.setValidators([Validators.required]);
            this.form.controls.endDate.setValidators([Validators.required]);
            var nowStr = this.momentService.moment().format(ISO_DATE_FORMAT);
            this.form.patchValue({
                startDate: this.form.value.startDate || nowStr,
                endDate: this.form.value.endDate || nowStr,
            });
        }
        this.form.controls.startDate.updateValueAndValidity();
        this.form.controls.endDate.updateValueAndValidity();
    };
    EditTaskComponent.prototype.toggle = function () {
        this.clearParentTask();
    };
    EditTaskComponent.prototype.loadTasks = function (term) {
        return this.httpRest._request(HTTP_METHOD.POST, ApiRoutePlurality.PLURAL, TASK_SCHEMA_ROUTE, 'searchTasks', {
            search: term,
            projectId: this.form.value.project,
            exclude: [this.taskToEdit.id],
        });
    };
    EditTaskComponent.prototype.loadParentTasks = function () {
        var _this = this;
        this.tasksParent = concat(of([]), this.tasksParent$.pipe(debounceTime(200), distinctUntilChanged(), tap(function () { return (_this.tasksParentLoading = true); }), switchMap(function (term) {
            return _this.loadTasks(term).pipe(catchError(function () { return of([]); }), tap(function () { return (_this.tasksParentLoading = false); }));
        })));
    };
    EditTaskComponent.prototype.clearParentTask = function () {
        Object.assign(this.parentTask, null);
        this.form.value.parentTaskId = null;
        var el = document.getElementById('parentTaskId');
        if (el) {
            window.setTimeout(function () {
                el.focus();
            }, 200);
        }
    };
    EditTaskComponent.prototype.getSelectedRelease = function () {
        var _this = this;
        if (!this.releases) {
            return undefined;
        }
        return this.releases.find(function (m) { return _this.form.value.releaseId === m.id; });
    };
    EditTaskComponent.prototype.selectedTags = function () {
        if (!this.tags) {
            return null;
        }
        return this.tags.filter(function (tag) { return tag.selected; });
    };
    EditTaskComponent.prototype.removeTag = function ($event) {
        var idToRemove = $event.tagId ? $event.tagId : $event.id;
        this.form.patchValue({
            tags: this.form.value.tags.filter(function (_tag) {
                return _tag.tagId ? _tag.tagId !== idToRemove : _tag.id !== idToRemove;
            }),
        });
    };
    EditTaskComponent.prototype.onTagSubmit = function () {
        var el = document.getElementById('tag');
        if (!el) {
            return;
        }
        var value = el.value.trim();
        if (!value) {
            return;
        }
        var tag = {
            text: value,
            color: getRandomColorHex(),
            branch: false,
            projectId: this.taskToEdit.projectId,
        };
        this.updateTag(el, tag);
    };
    EditTaskComponent.prototype.updateTag = function (el, tag) {
        var _this = this;
        var clone = __rest(tag, []);
        this.httpRest.put(TAG_SCHEMA_ROUTE, clone).subscribe(function (_tag) {
            if (el) {
                _this.tags.push(__assign({}, _tag, { selected: true }));
                el.value = '';
                el.focus();
            }
        });
    };
    EditTaskComponent.prototype.refreshTagList = function () {
        var _this = this;
        this.tags = this._tags.filter(function (t) { return _this.form.value.tags.findIndex(function (_t) { return t.id === _t.tagId; }) === -1; });
    };
    EditTaskComponent.prototype.getBlockerName = function (blocker) {
        var task = this.tasks[this.tasks.map(function (t) { return t.id; }).indexOf(blocker.blockerId)];
        if (task) {
            return task.name;
        }
        return '';
    };
    EditTaskComponent.prototype.getBlockerCode = function (blocker) {
        var task = this.tasks[this.tasks.map(function (t) { return t.id; }).indexOf(blocker.blockerId)];
        if (task) {
            return task.code;
        }
        return '';
    };
    EditTaskComponent.prototype.doAddBlocker = function (task) {
        this.addBlockers.blockersNew.push(task.id);
    };
    EditTaskComponent.prototype.doRemoveBlocker = function (blocker) {
        if (blocker.blockerId) {
            this.addBlockers.blockersDeleted.push(blocker.id);
            this.form.patchValue({
                blockers: this.form.value.blockers.filter(function (b) { return b.blockerId !== blocker.blockerId; }),
            });
        }
        else {
            this.addBlockers.blockersNew.splice(this.addBlockers.blockersNew.findIndex(function (b) { return b === blocker.id; }));
            this.form.patchValue({
                blockers: this.form.value.blockers.filter(function (b) { return b.id !== blocker.id; }),
            });
        }
    };
    EditTaskComponent.prototype.getReleased = function () {
        return this.releases.filter(function (r) { return r.releaseState === ReleaseStateType.RELEASED; });
    };
    EditTaskComponent.prototype.getStaged = function () {
        return this.releases.filter(function (r) { return r.releaseState === ReleaseStateType.STAGED; });
    };
    EditTaskComponent.prototype.getUnreleased = function () {
        return this.releases.filter(function (r) { return r.releaseState === ReleaseStateType.IN_DEVELOPMENT; });
    };
    EditTaskComponent.prototype.saveTask = function (form) {
        var _this = this;
        if (!validate(form) || this.saving) {
            return undefined;
        }
        this.saving = true;
        if ("" + form.value.release === 'null') {
            form.value.release = null;
        }
        if (form.value.startDate && form.value.endDate) {
            if (this.momentService.moment(form.value.startDate).isAfter(this.momentService.moment(form.value.endDate))) {
                this.toastService.show({
                    type: TOAST_TYPE.ERROR,
                    text: 'error_milestones',
                });
                return false;
            }
        }
        var release = this.releases.find(function (r) { return r.id === form.value.releaseId; });
        var type = form.value.bug ? TaskType.BUG : TaskType.TASK;
        this.httpRest
            .post(TASK_SCHEMA_ROUTE, {
            id: this.taskToEdit.id,
            name: form.value.name,
            progress: form.value.progress,
            targetReleaseId: form.value.releaseId,
            estimatedTime: form.value.estimated,
            type: TaskType[type],
            urgent: form.value.urgent,
            beginDate: form.value.startDate ? this.momentService.moment(form.value.startDate) : null,
            endDate: form.value.endDate ? this.momentService.moment(form.value.endDate) : null,
            parentId: form.value.parentTaskId ? form.value.parentTaskId.id : null,
        })
            .subscribe(function (task) {
            var obss = [];
            var deletedBlockers = _this.addBlockers.blockersDeleted;
            var deletedBlockersObs;
            if (deletedBlockers && deletedBlockers.length > 0) {
                deletedBlockersObs = _this.httpRest.deleteIds(TASK_BLOCKER_SCHEMA_ROUTE, deletedBlockers);
                deletedBlockersObs.subscribe(function () {
                    deletedBlockers.map(function (d) {
                        var idx = _this.taskToEdit.taskBlockers.map(function (t) { return t.id; }).indexOf(d);
                        if (idx !== -1) {
                            _this.taskToEdit.taskBlockers.splice(idx, 1);
                        }
                    });
                });
                obss.push(deletedBlockersObs);
            }
            var newBlockers = _this.addBlockers.blockersNew;
            var newBlockersObs;
            if (newBlockers && newBlockers.length) {
                newBlockersObs = _this.httpRest.putEntities(TASK_BLOCKER_SCHEMA_ROUTE, newBlockers.map(function (blocker) { return ({
                    taskId: task.id,
                    blockerId: blocker,
                }); }));
                newBlockersObs.subscribe(function (blockers) {
                    blockers.map(function (blocker) {
                        _this.taskToEdit.taskBlockers.push({
                            taskId: task.id,
                            blockerId: blocker.blockerId,
                            id: blocker.id,
                            active: blocker.active,
                        });
                    });
                });
                obss.push(newBlockersObs);
            }
            _this.addBlockers.reset();
            var deletedTags = _this.taskToEdit.tasktags.filter(function (tt) { return !_this.form.value.tags.find(function (t) { return t.tagId === tt.tagId; }); });
            var newTags = _this.form.value.tags.filter(function (tt) { return !_this.taskToEdit.tasktags.find(function (t) { return t.tagId === tt.tagId; }); });
            var deletedTagsObs;
            if (deletedTags.length) {
                deletedTagsObs = _this.httpRest.deleteIds(TASK_TAG_SCHEMA_ROUTE, deletedTags.map(function (deleted) { return deleted.id; }));
                deletedTagsObs.subscribe(function () {
                    deletedTags.map(function (deleted) {
                        _this.taskToEdit.tasktags.splice(_this.taskToEdit.tasktags.findIndex(function (tt) { return tt.tagId === deleted.tagId; }), 1);
                    });
                });
                obss.push(deletedTagsObs);
            }
            var newTagsObs;
            if (newTags.length) {
                newTagsObs = _this.httpRest.putEntities(TASK_TAG_SCHEMA_ROUTE, newTags.map(function (t) { return ({
                    tagId: t.id,
                    taskId: _this.taskToEdit.id,
                }); }));
                newTagsObs.subscribe(function (tasktags) {
                    tasktags.map(function (tasktag) { return __awaiter(_this, void 0, void 0, function () {
                        var tag;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4, this.httpRest
                                        ._request(HTTP_METHOD.GET, ApiRoutePlurality.SINGULAR, TAG_SCHEMA_ROUTE, "" + tasktag.tagId)
                                        .toPromise()];
                                case 1:
                                    tag = _a.sent();
                                    tasktag.tag = tag;
                                    this.taskToEdit.tasktags.push(tasktag);
                                    return [2];
                            }
                        });
                    }); });
                });
                obss.push(newTagsObs);
            }
            var done = function () {
                _this.saving = false;
                _this.taskToEdit.name = task.name;
                _this.taskToEdit._metadata = task._metadata;
                _this.taskToEdit.targetReleaseId = task.targetReleaseId;
                _this.taskToEdit.progress = task.progress;
                _this.taskToEdit.estimatedTime = task.estimatedTime;
                _this.taskToEdit.beginDate = task.beginDate;
                _this.taskToEdit.endDate = task.endDate;
                _this.taskToEdit.parentId = task.parentId;
                if (release) {
                    _this.taskToEdit.targetRelease = release;
                }
                if (type) {
                    _this.taskToEdit.type = TaskType[type];
                }
                _this.taskToEdit.urgent = task.urgent;
                _this.taskToEdit.tracked = task.tracked;
                _this.taskToEdit.createdAt = task.createdAt;
                _this.taskToEdit.updatedAt = task.updatedAt;
                _this.triggerCdRef.emit();
                _this.form.removeControl('blockers');
                _this.toastService.show({
                    type: TOAST_TYPE.SUCCESS,
                    text: 'toast_updated_task',
                });
                _this.showChange.emit((_this.show = false));
            };
            if (obss.length) {
                forkJoin(obss).subscribe(done);
            }
            else {
                done();
            }
        });
    };
    EditTaskComponent.prototype.isTimeInvalid = function () {
        if (!this.form || !this.form.controls.estimated) {
            return false;
        }
        return this.form.controls.estimated.status === 'INVALID';
    };
    EditTaskComponent.prototype.checkValidationTime = function () {
        if (overEstimatedTime(this.configService.config, this.form.value.estimated)) {
            this.form.controls.estimated.setErrors({ over53Weeks: true });
        }
    };
    return EditTaskComponent;
}(ModalComponent));
export { EditTaskComponent };
