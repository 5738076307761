import {CommonModule} from '@angular/common';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {ClarityModule} from '@clr/angular';
import {NgSelectModule} from '@ng-select/ng-select';
import {TranslateModule} from '@ngx-translate/core';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {ColorPickerModule} from 'ngx-color-picker';
import {DndModule} from 'ngx-drag-drop';
import {MarkdownModule} from 'ngx-markdown';
import {AddMultipleTaskComponent} from '../add-multiple-task/add-multiple-task.component';
import {AttachmentCardComponent} from '../attachment-card/attachment-card.component';
import {CardComponent} from '../card/card.component';
import {
    CardBodyDirective,
    CardFooterDirective,
    CardFooterRightDirective,
    CardHeaderRightDirective,
} from '../card/card.directives';
import {DashboardNumbersComponent} from '../dashboard-numbers/dashboard-numbers.component';
import {EditBudgetComponent} from '../edit-budget/edit-budget.component';
import {EditMilestoneComponent} from '../edit-milestone/edit-milestone.component';
import {EditTaskComponent} from '../edit-task/edit-task.component';
import {EditTimeComponent} from '../edit-time/edit-time.component';
import {FailedReviewComponent} from '../failed-review/failed-review.component';
import {MaxDirective} from '../forms/validators/max.directive';
import {MinDirective} from '../forms/validators/min.directive';
import {PhoneNumberDirective} from '../forms/validators/phone-number.directive';
import {RequiredTrimDirective} from '../forms/validators/required-trim.directive';
import {ModalSimpleComponent} from '../modal-simple/modal-simple.component';
import {ModalComponent} from '../modal/modal.component';
import {ModalBodyDirective, ModalFooterDirective, ModalHeaderDirective} from '../modal/modal.directives';
import {ProjectWizardProjectMembersComponent} from '../project-wizard/project-members/project-wizard-project-members.component';
import {ProjectWizardComponent} from '../project-wizard/project-wizard.component';
import {ProjectWizardProjectComponent} from '../project-wizard/project/project-wizard-project.component';
import {ReportTaskDetailComponent} from '../report-task-detail/report-task-detail.component';
import {ReportBulkModifyComponent} from '../report/report-bulk/report-bulk-modify/report-bulk-modify.component';
import {ReportBulkSelectionComponent} from '../report/report-bulk/report-bulk-selection/report-bulk-selection.component';
import {ReportBulkSummaryComponent} from '../report/report-bulk/report-bulk-summary/report-bulk-summary.component';
import {ReportBulkComponent} from '../report/report-bulk/report-bulk.component';
import {RtFilterDateComponent} from '../report/report-tasks/rt-filter-date/rt-filter-date.component';
import {RtFilterNumberComponent} from '../report/report-tasks/rt-filter-number/rt-filter-number.component';
import {RtFilterComponent} from '../report/report-tasks/rt-filter/rt-filter.component';
import {DebounceDirective} from '../shared/debounce/debounce.directive';
import {TabContentDirective} from '../tabs/tab-content/tab-content.directive';
import {TabComponent} from '../tabs/tab/tab.component';
import {TabsHeaderDirective} from '../tabs/tabs-header.directive';
import {TabsComponent} from '../tabs/tabs.component';
import {TimeDetailsComponent} from '../time-wizard/time-details/time-details.component';
import {TimeExistingComponent} from '../time-wizard/time-existing/time-existing.component';
import {TimeTypeComponent} from '../time-wizard/time-type/time-type.component';
import {TimeWizardComponent} from '../time-wizard/time-wizard.component';
import {AccessControlDirective} from './access-control/access-control.directive';
import {AutofocusDirective} from './autofocus/autofocus.directive';
import {AutoresizeDirective} from './autoresize/autoresize.directive';
import {ClipboardDirective} from './clipboard/clipboard.directive';
import {ConvertCheckboxesPipe} from './convert-checkboxes/convert-checkboxes.pipe';
import {ConvertNewLinesPipe} from './convert-new-lines/convert-new-lines.pipe';
import {DatagridToggleDirective} from './datagrid-toggle/datagrid-toggle.directive';
import {DateDatagridFilterComponent} from './date-datagrid-filter/date-datagrid-filter.component';
import {EditableEmployeeLabelComponent} from './editable-employee-label/editable-employee-label.component';
import {EventBinderDirective} from './event-binder/event-binder.directive';
import {FileManagerDropZoneComponent} from './file-manager-drop-zone/file-manager-drop-zone.component';
import {FileSizePipe} from './file-size/file-size.pipe';
import {FullscreenDirective} from './fullscreen/fullscreen.directive';
import {FuzzyDatagridFilterComponent} from './fuzzy-datagrid-filter/fuzzy-datagrid-filter.component';
import {GenericDatagridFilterComponent} from './generic-datagrid-filter/generic-datagrid-filter.component';
import {HighlightDirective} from './highlight/highlight.directive';
import {HttpRestComponent} from './http-rest/http-rest.component';
import {ListAutoIndentDirective} from './list-auto-indent/list-auto-indent.directive';
import {LongAvatarComponent} from './long-avatar/long-avatar.component';
import {LowercaseInputDirective} from './lowercase-input/lower-input.directive';
import {MomentDurationPipe} from './moment-duration/moment-duration.pipe';
import {MomentFormatPipe} from './moment-format/moment-format.pipe';
import {MovableDirective} from './movable/movable.directive';
import {PrepareMarkdownPipe} from './prepare-markdown/prepare-markdown.pipe';
import {ResizableDirective} from './resizable-element/resizable.directive';
import {ResizeHandleDirective} from './resizable-element/resize-handle.directive';
import {RightSidebarComponent} from './right-sidebar/right-sidebar.component';
import {SelectDatagridFilterComponent} from './select-datagrid-filter/select-datagrid-filter.component';
import {SharedArraySortPipe} from './sort-array/shared-array-sort.pipe';
import {TaskCodeLinkPipe} from './task-code-link/task-code-link.pipe';
import {TaskTagComponent} from './task-tag/task-tag.component';
import {ToastComponent} from './toast/toast.component';
import {UppercaseInputDirective} from './uppercase-input/uppercase-input.directive';
@NgModule({
    imports: [
        MarkdownModule.forRoot(),
        CommonModule,
        TranslateModule,
        NgSelectModule,
        FormsModule,
        ReactiveFormsModule,
        NgxChartsModule,
        DndModule,
        ColorPickerModule,
        ClarityModule,
        RouterModule,
    ],
    declarations: [
        FailedReviewComponent,
        ProjectWizardComponent,
        ProjectWizardProjectComponent,
        ProjectWizardProjectMembersComponent,
        ConvertCheckboxesPipe,
        TabsHeaderDirective,
        DebounceDirective,
        TabContentDirective,
        TabsComponent,
        TabComponent,
        ModalComponent,
        RightSidebarComponent,
        AutofocusDirective,
        MomentFormatPipe,
        MomentDurationPipe,
        ConvertNewLinesPipe,
        PrepareMarkdownPipe,
        TaskCodeLinkPipe,
        FileSizePipe,
        LongAvatarComponent,
        TaskTagComponent,
        ListAutoIndentDirective,
        AutoresizeDirective,
        EventBinderDirective,
        DashboardNumbersComponent,
        HttpRestComponent,
        RequiredTrimDirective,
        SharedArraySortPipe,
        MovableDirective,
        FileManagerDropZoneComponent,
        AccessControlDirective,
        EditableEmployeeLabelComponent,
        GenericDatagridFilterComponent,
        ModalHeaderDirective,
        ModalBodyDirective,
        ModalFooterDirective,
        CardComponent,
        CardFooterRightDirective,
        CardBodyDirective,
        CardFooterDirective,
        CardHeaderRightDirective,
        FuzzyDatagridFilterComponent,
        ToastComponent,
        PhoneNumberDirective,
        MinDirective,
        MaxDirective,
        UppercaseInputDirective,
        LowercaseInputDirective,
        DateDatagridFilterComponent,
        AttachmentCardComponent,
        HighlightDirective,
        FullscreenDirective,
        ResizableDirective,
        ResizeHandleDirective,
        DatagridToggleDirective,
        ClipboardDirective,
        EditTaskComponent,
        EditMilestoneComponent,
        EditBudgetComponent,
        TimeWizardComponent,
        TimeTypeComponent,
        TimeDetailsComponent,
        EditTimeComponent,
        TimeExistingComponent,
        SelectDatagridFilterComponent,
        ReportTaskDetailComponent,
        ReportBulkComponent,
        ReportBulkSelectionComponent,
        ReportBulkModifyComponent,
        ReportBulkSummaryComponent,
        ModalSimpleComponent,
        RtFilterComponent,
        AddMultipleTaskComponent,
        RtFilterNumberComponent,
        RtFilterDateComponent,
    ],
    exports: [
        EditTaskComponent,
        FailedReviewComponent,
        TimeWizardComponent,
        TimeTypeComponent,
        TimeDetailsComponent,
        TimeExistingComponent,
        EditMilestoneComponent,
        EditBudgetComponent,
        EditTimeComponent,
        ProjectWizardComponent,
        ProjectWizardProjectComponent,
        ProjectWizardProjectMembersComponent,
        ResizableDirective,
        ResizeHandleDirective,
        MarkdownModule,
        ConvertCheckboxesPipe,
        TabsHeaderDirective,
        DebounceDirective,
        TabContentDirective,
        TabsComponent,
        TabComponent,
        TranslateModule,
        ModalComponent,
        AutofocusDirective,
        MomentFormatPipe,
        MomentDurationPipe,
        ConvertNewLinesPipe,
        PrepareMarkdownPipe,
        TaskCodeLinkPipe,
        FileSizePipe,
        NgSelectModule,
        NgxChartsModule,
        ListAutoIndentDirective,
        AutoresizeDirective,
        EventBinderDirective,
        LongAvatarComponent,
        TaskTagComponent,
        DashboardNumbersComponent,
        HttpRestComponent,
        RequiredTrimDirective,
        SharedArraySortPipe,
        MovableDirective,
        AccessControlDirective,
        SharedArraySortPipe,
        FileManagerDropZoneComponent,
        ColorPickerModule,
        EditableEmployeeLabelComponent,
        GenericDatagridFilterComponent,
        ModalHeaderDirective,
        ModalBodyDirective,
        ModalFooterDirective,
        CardComponent,
        CardFooterRightDirective,
        CardBodyDirective,
        CardFooterDirective,
        CardHeaderRightDirective,
        ToastComponent,
        FuzzyDatagridFilterComponent,
        PhoneNumberDirective,
        MinDirective,
        MaxDirective,
        UppercaseInputDirective,
        LowercaseInputDirective,
        DateDatagridFilterComponent,
        AttachmentCardComponent,
        HighlightDirective,
        DndModule,
        RightSidebarComponent,
        FullscreenDirective,
        DatagridToggleDirective,
        ClipboardDirective,
        SelectDatagridFilterComponent,
        ReportTaskDetailComponent,
        ReportBulkComponent,
        ReportBulkSelectionComponent,
        ReportBulkModifyComponent,
        ModalSimpleComponent,
        ReportBulkSummaryComponent,
        RtFilterComponent,
        AddMultipleTaskComponent,
        RtFilterNumberComponent,
        RtFilterDateComponent,
    ],
    providers: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
