import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {ClrForm} from '@clr/angular';
import {FormControl, FormGroup} from 'ngx-strongly-typed-forms';
import {ApiRoutePlurality, HTTP_METHOD, ProjectMemberType} from '../../../../../defs/schema-static';
import {EMPLOYEE_FIELD, EMPLOYEE_SCHEMA_ROUTE, IEmployee} from '../../../../../defs/schema/public/Employees';
import {
    IProjectMember,
    PROJECT_MEMBER_FIELD,
    PROJECT_MEMBER_SCHEMA_ROUTE,
} from '../../../../../defs/schema/public/ProjectMembers';
import {IProject} from '../../../../../defs/schema/public/Projects';
import {USER_FIELD} from '../../../../../defs/schema/public/Users';
import {PROJECT_MEMBER_SORT_FUNCTION} from '../../../../../defs/sorters';
import {AuthService} from '../../auth/auth.service';
import {requiredProjectMembers} from '../../forms/validators/required-project-members.validator';
import {EMPLOYEE_MEMBER_TYPE} from '../../shared/editable-employee-label/editable-employee-label.component';
import {HttpRestService} from '../../shared/http-rest/http-rest.service';

enum PROJECT_MEMBERS_FORM_KEYS {
    members = 'members',
}

interface IProjectMembersFormValues {
    members: Partial<IProjectMember>[];
}

enum CONFIRM_ACTION {
    DOWNGRADE = 'DOWNGRADE',
    DELETE = 'DELETE',
}

@Component({
    selector: 'app-project-wizard-project-members',
    templateUrl: './project-wizard-project-members.component.html',
})
export class ProjectWizardProjectMembersComponent implements OnInit, OnChanges, OnDestroy {
    public readonly form = new FormGroup<IProjectMembersFormValues>({
        [PROJECT_MEMBERS_FORM_KEYS.members]: new FormControl<Partial<IProjectMember>[]>(null, requiredProjectMembers()),
    });

    @ViewChild(ClrForm) private readonly clrForm: ClrForm;

    @Input() public employees: Partial<IEmployee>[] = [];
    @Input() public project: Partial<IProject>;

    @Input() public members: Partial<IProjectMember>[];
    @Output() public membersChange = new EventEmitter<Partial<IProjectMember>[]>();

    @Input() public autoSubmit = false;
    @Input() public disabled = false;

    public memberList: Partial<IProjectMember>[] = [];

    public showConfirmSudokuModal = false;
    public confirmMember: IProjectMember;
    public confirmAction: CONFIRM_ACTION;

    public constructor(private readonly httpRest: HttpRestService, private readonly authService: AuthService) {}

    public ngOnInit() {
        if (!this.employees || !this.employees.length) {
            (async () => this.getEmployees())();

            return;
        }

        this.setMembers();
        this.setMemberList();

        if (this.disabled) {
            this.form.controls[PROJECT_MEMBERS_FORM_KEYS.members].disable();
        }
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.members) {
            this.setMembers();
        }

        if (changes.members || changes.employees) {
            this.setMemberList();
        }

        if (changes.disabled) {
            const control = this.form.controls[PROJECT_MEMBERS_FORM_KEYS.members];
            this.disabled ? control.disable() : control.enable();
        }
    }

    public ngOnDestroy(): void {
        this.form.reset();
    }

    public async getEmployees(): Promise<void> {
        this.employees = (await this.httpRest
            ._request<IEmployee[]>(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, EMPLOYEE_SCHEMA_ROUTE, 'project')
            .toPromise()).sort((e1, e2) => e1.user.name.localeCompare(e2.user.name));

        this.setMemberList();
    }

    private setMembers() {
        this.form.patchValue({
            members: this.members,
        });
    }

    private setMemberList() {
        const existingEmployeeIds = (this.members || []).map(({employee}) => employee.id);

        this.memberList = [
            ...(this.members || []),
            ...this.employees
                .filter((employee) => !existingEmployeeIds.includes(employee.id))
                .map(
                    (employee) =>
                        ({
                            employee,
                            employeeId: employee.id,
                        } as Partial<IProjectMember>)
                ),
        ];
    }

    public emitChanges() {
        this.membersChange.emit((this.members = this.form.value.members));
    }

    public async addMember(member: IProjectMember) {
        if (this.autoSubmit) {
            const _member = await this.httpRest
                .put<IProjectMember>(PROJECT_MEMBER_SCHEMA_ROUTE, {
                    projectId: this.project.id,
                    type: member.type || ProjectMemberType.MEMBER,
                    employeeId: member.employee.id,
                })
                .toPromise();

            member.id = _member.id;
        }

        this.emitChanges();
    }

    public async updateMember(member: IProjectMember, force = false) {
        if (
            !force &&
            member.employeeId === this.authService.user.employee.id &&
            member.type !== ProjectMemberType.MANAGER
        ) {
            this.confirmMember = member;
            this.confirmAction = CONFIRM_ACTION.DOWNGRADE;
            this.showConfirmSudokuModal = true;

            return;
        }

        const {members} = this.form.value;
        this.form.patchValue({members});

        if (this.autoSubmit) {
            await this.httpRest
                .post<IProjectMember>(PROJECT_MEMBER_SCHEMA_ROUTE, {
                    id: member.id,
                    type: member.type || ProjectMemberType.MEMBER,
                })
                .toPromise();
        }

        this.emitChanges();
    }
    public async removeMember(member: IProjectMember, force = false) {
        if (
            !force &&
            member.employeeId === this.authService.user.employee.id &&
            member.type === ProjectMemberType.MANAGER
        ) {
            this.confirmMember = member;
            this.confirmAction = CONFIRM_ACTION.DELETE;
            this.showConfirmSudokuModal = true;

            return;
        }

        const {members} = this.form.value;
        members.splice(members.indexOf(member), 1);
        this.form.patchValue({members});

        if (this.autoSubmit) {
            await this.httpRest
                .deleteQuery(PROJECT_MEMBER_SCHEMA_ROUTE, {
                    projectId: this.project.id,
                    employeeId: member.employee.id,
                })
                .toPromise();
        }

        this.emitChanges();
    }

    public async submit(): Promise<IProjectMember[]> {
        if (!this.project || !this.project.id) {
            throw new Error("missing project id, can't submit ");
        }

        if (!this.form.valid) {
            this.clrForm.markAsDirty();

            return undefined;
        }

        const {members} = this.form.value;

        const projectMembers = await this.httpRest
            .putEntities<IProjectMember>(
                PROJECT_MEMBER_SCHEMA_ROUTE,
                members.map((member) => ({
                    projectId: this.project.id,
                    type: member.type || ProjectMemberType.MEMBER,
                    employeeId: member.employee.id,
                }))
            )
            .toPromise();

        this.emitChanges();

        return projectMembers
            .map((member) => ({
                ...member,
                employee: this.employees.find(({id}) => id === member.employeeId),
            }))
            .sort(PROJECT_MEMBER_SORT_FUNCTION);
    }

    public closeSudokuModal() {
        this.confirmMember = undefined;
        this.showConfirmSudokuModal = false;
        this.confirmAction = undefined;
    }

    public async cancelSudoku() {
        switch (this.confirmAction) {
            case CONFIRM_ACTION.DOWNGRADE:
                this.confirmMember.type = ProjectMemberType.MANAGER;
                break;
            case CONFIRM_ACTION.DELETE:
                break;
            default:
                throw new Error(`unknown action: ${this.confirmAction}`);
        }

        this.closeSudokuModal();
    }

    public async confirmSudoku() {
        switch (this.confirmAction) {
            case CONFIRM_ACTION.DOWNGRADE:
                await this.updateMember(this.confirmMember, true);
                break;
            case CONFIRM_ACTION.DELETE:
                await this.removeMember(this.confirmMember, true);
                break;
            default:
                throw new Error(`unknown action: ${this.confirmAction}`);
        }

        this.closeSudokuModal();
    }

    public readonly PROJECT_MEMBERS_FORM_KEYS = PROJECT_MEMBERS_FORM_KEYS;

    public readonly PROJECT_MEMBER_FIELD = PROJECT_MEMBER_FIELD;
    public readonly EMPLOYEE_MEMBER_TYPE = EMPLOYEE_MEMBER_TYPE;
    public readonly EMPLOYEE_FIELD = EMPLOYEE_FIELD;
    public readonly USER_FIELD = USER_FIELD;
}
