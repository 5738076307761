var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
import moment from 'moment';
import { WIKI_PAGE_FIELD } from './schema/public/WikiPage';
export var HTTP_METHOD;
(function (HTTP_METHOD) {
    HTTP_METHOD["GET"] = "GET";
    HTTP_METHOD["PUT"] = "PUT";
    HTTP_METHOD["POST"] = "POST";
    HTTP_METHOD["DELETE"] = "DELETE";
})(HTTP_METHOD || (HTTP_METHOD = {}));
export var ApiRoutePlurality;
(function (ApiRoutePlurality) {
    ApiRoutePlurality["SINGULAR"] = "SINGULAR";
    ApiRoutePlurality["PLURAL"] = "PLURAL";
})(ApiRoutePlurality || (ApiRoutePlurality = {}));
export var BudgetStatus;
(function (BudgetStatus) {
    BudgetStatus["ESTIMATED"] = "ESTIMATED";
    BudgetStatus["BILLED"] = "BILLED";
    BudgetStatus["CANCELLED"] = "CANCELLED";
})(BudgetStatus || (BudgetStatus = {}));
export var RightCategory;
(function (RightCategory) {
    RightCategory["NAVIGATION"] = "navigation";
    RightCategory["CLIENTS"] = "clients";
    RightCategory["PROJECTS"] = "projects";
    RightCategory["EMPLOYEES"] = "employees";
    RightCategory["TASKS"] = "tasks";
    RightCategory["COMMENT"] = "comment";
    RightCategory["TIMES"] = "times";
    RightCategory["MILESTONES"] = "milestones";
    RightCategory["WIKI"] = "wiki-pages";
    RightCategory["ATTACHMENTS"] = "attachments";
})(RightCategory || (RightCategory = {}));
export var NAVIGATE_RIGHTS;
(function (NAVIGATE_RIGHTS) {
    NAVIGATE_RIGHTS["NAVIGATE_FIRON"] = "NAVIGATE_FIRON";
    NAVIGATE_RIGHTS["NAVIGATE_GANTT"] = "NAVIGATE_GANTT";
    NAVIGATE_RIGHTS["NAVIGATE_DASHBOARD"] = "NAVIGATE_DASHBOARD";
    NAVIGATE_RIGHTS["NAVIGATE_TODO"] = "NAVIGATE_TODO";
    NAVIGATE_RIGHTS["NAVIGATE_WIKI"] = "NAVIGATE_WIKI";
    NAVIGATE_RIGHTS["NAVIGATE_REPORTS"] = "NAVIGATE_REPORTS";
    NAVIGATE_RIGHTS["NAVIGATE_CALENDAR"] = "NAVIGATE_CALENDAR";
    NAVIGATE_RIGHTS["NAVIGATE_CLIENTS"] = "NAVIGATE_CLIENTS";
    NAVIGATE_RIGHTS["NAVIGATE_CLIENT"] = "NAVIGATE_CLIENT";
    NAVIGATE_RIGHTS["NAVIGATE_PROJECTS"] = "NAVIGATE_PROJECTS";
    NAVIGATE_RIGHTS["NAVIGATE_PROJECT"] = "NAVIGATE_PROJECT";
    NAVIGATE_RIGHTS["NAVIGATE_EMPLOYEES"] = "NAVIGATE_EMPLOYEES";
    NAVIGATE_RIGHTS["NAVIGATE_EMPLOYEE"] = "NAVIGATE_EMPLOYEE";
    NAVIGATE_RIGHTS["NAVIGATE_ADMIN"] = "NAVIGATE_ADMIN";
    NAVIGATE_RIGHTS["NAVIGATE_IDEABOARD"] = "NAVIGATE_IDEABOARD";
    NAVIGATE_RIGHTS["NAVIGATE_WORKLOADTOOLS"] = "NAVIGATE_WORKLOADTOOLS";
})(NAVIGATE_RIGHTS || (NAVIGATE_RIGHTS = {}));
export var RIGHTS;
(function (RIGHTS) {
    RIGHTS["PROJECT_TAB_MS"] = "PROJECT_TAB_MS";
    RIGHTS["PROJECT_TAB_BG"] = "PROJECT_TAB_BG";
    RIGHTS["PROJECT_TAB_TK"] = "PROJECT_TAB_TK";
    RIGHTS["PROJECT_TAB_TI"] = "PROJECT_TAB_TI";
    RIGHTS["PROJECT_TAB_RL"] = "PROJECT_TAB_RL";
    RIGHTS["PROJECT_TAB_BK"] = "PROJECT_TAB_BK";
    RIGHTS["PROJECT_TAB_PR"] = "PROJECT_TAB_PR";
    RIGHTS["PROJECT_STICKY_READ"] = "PROJECT_STICKY_READ";
    RIGHTS["PROJECT_STICKY_UPDATE"] = "PROJECT_STICKY_UPDATE";
    RIGHTS["PROJECT_DELETE"] = "PROJECT_DELETE";
    RIGHTS["PROJECT_CREATE"] = "PROJECT_CREATE";
    RIGHTS["EMPLOYEE_DELETE"] = "EMPLOYEE_DELETE";
    RIGHTS["EMPLOYEE_UPDATE"] = "EMPLOYEE_UPDATE";
    RIGHTS["EMPLOYEE_CREATE"] = "EMPLOYEE_CREATE";
    RIGHTS["MONEY"] = "MONEY";
    RIGHTS["CLIENT_DELETE"] = "CLIENT_DELETE";
    RIGHTS["CLIENT_UPDATE"] = "CLIENT_UPDATE";
    RIGHTS["CLIENT_CREATE"] = "CLIENT_CREATE";
    RIGHTS["CLIENT_TAB_MS"] = "CLIENT_TAB_MS";
    RIGHTS["CLIENT_TAB_PJ"] = "CLIENT_TAB_PJ";
    RIGHTS["CLIENT_TAB_RQ"] = "CLIENT_TAB_RQ";
    RIGHTS["CLIENT_TAB_IN"] = "CLIENT_TAB_IN";
    RIGHTS["CLIENT_TAB_CH"] = "CLIENT_TAB_CH";
    RIGHTS["CLIENT_TAB_BK"] = "CLIENT_TAB_BK";
    RIGHTS["CLIENT_BK_DL"] = "CLIENT_BK_DL";
    RIGHTS["CLIENT_CONTACTS"] = "CLIENT_CONTACTS";
    RIGHTS["CLIENT_STICKY_READ"] = "CLIENT_STICKY_READ";
    RIGHTS["CLIENT_STICKY_UPDATE"] = "CLIENT_STICKY_UPDATE";
    RIGHTS["TASK_CREATE"] = "TASK_CREATE";
    RIGHTS["TASK_UPDATE"] = "TASK_UPDATE";
    RIGHTS["TASK_DELETE"] = "TASK_DELETE";
    RIGHTS["COMMENT_UPDATE"] = "COMMENT_UPDATE";
    RIGHTS["COMMENT_DELETE"] = "COMMENT_DELETE";
    RIGHTS["TIME_UPDATE"] = "TIME_UPDATE";
    RIGHTS["TIME_DELETE"] = "TIME_DELETE";
    RIGHTS["WIKI_UPDATE"] = "WIKI_UPDATE";
    RIGHTS["WIKI_DELETE"] = "WIKI_DELETE";
    RIGHTS["PJ_CREATE"] = "PJ_CREATE";
    RIGHTS["PJ_DELETE"] = "PJ_DELETE";
    RIGHTS["TAG_CREATE"] = "TAG_CREATE";
    RIGHTS["ASSIGN_OTHER"] = "ASSIGN_OTHER";
    RIGHTS["MS_UPDATE"] = "MS_UPDATE";
    RIGHTS["MS_DELETE"] = "MS_DELETE";
    RIGHTS["CLIENT_FETCH_ALL"] = "CLIENT_FETCH_ALL";
    RIGHTS["PROJECT_FETCH_ALL"] = "PROJECT_FETCH_ALL";
    RIGHTS["PROJECT_ADMIN_ALL"] = "PROJECT_ADMIN_ALL";
    RIGHTS["EMPLOYEE_FETCH_ALL"] = "EMPLOYEE_FETCH_ALL";
    RIGHTS["MS_RELEASE"] = "MS_RELEASE";
})(RIGHTS || (RIGHTS = {}));
export var RIGHTS_INFO = (_a = {},
    _a[NAVIGATE_RIGHTS.NAVIGATE_FIRON] = { category: RightCategory.NAVIGATION },
    _a[NAVIGATE_RIGHTS.NAVIGATE_GANTT] = { category: RightCategory.NAVIGATION },
    _a[NAVIGATE_RIGHTS.NAVIGATE_TODO] = { category: RightCategory.NAVIGATION },
    _a[NAVIGATE_RIGHTS.NAVIGATE_WIKI] = { category: RightCategory.NAVIGATION },
    _a[NAVIGATE_RIGHTS.NAVIGATE_REPORTS] = { category: RightCategory.NAVIGATION },
    _a[NAVIGATE_RIGHTS.NAVIGATE_CALENDAR] = { category: RightCategory.NAVIGATION },
    _a[NAVIGATE_RIGHTS.NAVIGATE_CLIENTS] = { category: RightCategory.NAVIGATION },
    _a[NAVIGATE_RIGHTS.NAVIGATE_CLIENT] = { category: RightCategory.NAVIGATION },
    _a[NAVIGATE_RIGHTS.NAVIGATE_PROJECTS] = { category: RightCategory.NAVIGATION },
    _a[NAVIGATE_RIGHTS.NAVIGATE_PROJECT] = { category: RightCategory.NAVIGATION },
    _a[NAVIGATE_RIGHTS.NAVIGATE_EMPLOYEES] = { category: RightCategory.NAVIGATION },
    _a[NAVIGATE_RIGHTS.NAVIGATE_EMPLOYEE] = { category: RightCategory.NAVIGATION },
    _a[NAVIGATE_RIGHTS.NAVIGATE_ADMIN] = { category: RightCategory.NAVIGATION },
    _a[NAVIGATE_RIGHTS.NAVIGATE_DASHBOARD] = { category: RightCategory.NAVIGATION },
    _a[NAVIGATE_RIGHTS.NAVIGATE_WORKLOADTOOLS] = { category: RightCategory.NAVIGATION },
    _a[RIGHTS.MS_RELEASE] = { category: RightCategory.MILESTONES },
    _a[RIGHTS.MS_DELETE] = { category: RightCategory.MILESTONES },
    _a[RIGHTS.MS_UPDATE] = { category: RightCategory.MILESTONES },
    _a[RIGHTS.WIKI_UPDATE] = { dependency: NAVIGATE_RIGHTS.NAVIGATE_WIKI, category: RightCategory.WIKI },
    _a[RIGHTS.WIKI_DELETE] = { dependency: NAVIGATE_RIGHTS.NAVIGATE_WIKI, category: RightCategory.WIKI },
    _a[RIGHTS.PJ_CREATE] = { category: RightCategory.ATTACHMENTS },
    _a[RIGHTS.PJ_DELETE] = { category: RightCategory.ATTACHMENTS },
    _a[RIGHTS.TIME_UPDATE] = { category: RightCategory.TIMES },
    _a[RIGHTS.TIME_DELETE] = { category: RightCategory.TIMES },
    _a[RIGHTS.ASSIGN_OTHER] = { dependency: NAVIGATE_RIGHTS.NAVIGATE_FIRON, category: RightCategory.TASKS },
    _a[RIGHTS.TASK_CREATE] = { category: RightCategory.TASKS },
    _a[RIGHTS.TASK_UPDATE] = { category: RightCategory.TASKS },
    _a[RIGHTS.TASK_DELETE] = { category: RightCategory.TASKS },
    _a[RIGHTS.COMMENT_UPDATE] = { dependency: NAVIGATE_RIGHTS.NAVIGATE_FIRON, category: RightCategory.COMMENT },
    _a[RIGHTS.COMMENT_DELETE] = { dependency: NAVIGATE_RIGHTS.NAVIGATE_FIRON, category: RightCategory.COMMENT },
    _a[RIGHTS.TAG_CREATE] = { category: RightCategory.PROJECTS },
    _a[RIGHTS.PROJECT_TAB_MS] = { dependency: NAVIGATE_RIGHTS.NAVIGATE_PROJECT, category: RightCategory.PROJECTS },
    _a[RIGHTS.PROJECT_TAB_BG] = { dependency: NAVIGATE_RIGHTS.NAVIGATE_PROJECT, category: RightCategory.PROJECTS },
    _a[RIGHTS.PROJECT_TAB_TK] = { dependency: NAVIGATE_RIGHTS.NAVIGATE_PROJECT, category: RightCategory.PROJECTS },
    _a[RIGHTS.PROJECT_TAB_TI] = { dependency: NAVIGATE_RIGHTS.NAVIGATE_PROJECT, category: RightCategory.PROJECTS },
    _a[RIGHTS.PROJECT_TAB_RL] = { dependency: NAVIGATE_RIGHTS.NAVIGATE_PROJECT, category: RightCategory.PROJECTS },
    _a[RIGHTS.PROJECT_TAB_BK] = { dependency: NAVIGATE_RIGHTS.NAVIGATE_PROJECT, category: RightCategory.PROJECTS },
    _a[RIGHTS.PROJECT_TAB_PR] = { dependency: NAVIGATE_RIGHTS.NAVIGATE_PROJECT, category: RightCategory.PROJECTS },
    _a[RIGHTS.PROJECT_STICKY_READ] = { dependency: NAVIGATE_RIGHTS.NAVIGATE_PROJECT, category: RightCategory.PROJECTS },
    _a[RIGHTS.PROJECT_STICKY_UPDATE] = { dependency: RIGHTS.PROJECT_STICKY_READ, category: RightCategory.PROJECTS },
    _a[RIGHTS.PROJECT_DELETE] = { dependency: NAVIGATE_RIGHTS.NAVIGATE_PROJECTS, category: RightCategory.PROJECTS },
    _a[RIGHTS.PROJECT_CREATE] = { dependency: NAVIGATE_RIGHTS.NAVIGATE_PROJECTS, category: RightCategory.PROJECTS },
    _a[RIGHTS.MONEY] = { category: RightCategory.EMPLOYEES },
    _a[RIGHTS.EMPLOYEE_FETCH_ALL] = { category: RightCategory.EMPLOYEES },
    _a[RIGHTS.EMPLOYEE_DELETE] = { dependency: NAVIGATE_RIGHTS.NAVIGATE_EMPLOYEES, category: RightCategory.EMPLOYEES },
    _a[RIGHTS.EMPLOYEE_CREATE] = { dependency: NAVIGATE_RIGHTS.NAVIGATE_EMPLOYEES, category: RightCategory.EMPLOYEES },
    _a[RIGHTS.EMPLOYEE_UPDATE] = { dependency: NAVIGATE_RIGHTS.NAVIGATE_EMPLOYEES, category: RightCategory.EMPLOYEES },
    _a[RIGHTS.CLIENT_FETCH_ALL] = { category: RightCategory.CLIENTS },
    _a[RIGHTS.CLIENT_UPDATE] = { dependency: NAVIGATE_RIGHTS.NAVIGATE_CLIENTS, category: RightCategory.CLIENTS },
    _a[RIGHTS.CLIENT_CONTACTS] = { dependency: RIGHTS.CLIENT_UPDATE, category: RightCategory.CLIENTS },
    _a[RIGHTS.CLIENT_STICKY_READ] = { dependency: NAVIGATE_RIGHTS.NAVIGATE_CLIENT, category: RightCategory.CLIENTS },
    _a[RIGHTS.CLIENT_DELETE] = { dependency: NAVIGATE_RIGHTS.NAVIGATE_CLIENTS, category: RightCategory.CLIENTS },
    _a[RIGHTS.CLIENT_CREATE] = { dependency: NAVIGATE_RIGHTS.NAVIGATE_CLIENTS, category: RightCategory.CLIENTS },
    _a[RIGHTS.CLIENT_TAB_MS] = { dependency: NAVIGATE_RIGHTS.NAVIGATE_CLIENT, category: RightCategory.CLIENTS },
    _a[RIGHTS.CLIENT_TAB_PJ] = { dependency: NAVIGATE_RIGHTS.NAVIGATE_CLIENT, category: RightCategory.CLIENTS },
    _a[RIGHTS.CLIENT_TAB_RQ] = { dependency: NAVIGATE_RIGHTS.NAVIGATE_CLIENT, category: RightCategory.CLIENTS },
    _a[RIGHTS.CLIENT_TAB_IN] = { dependency: NAVIGATE_RIGHTS.NAVIGATE_CLIENT, category: RightCategory.CLIENTS },
    _a[RIGHTS.CLIENT_TAB_CH] = { dependency: NAVIGATE_RIGHTS.NAVIGATE_CLIENT, category: RightCategory.CLIENTS },
    _a[RIGHTS.CLIENT_TAB_BK] = { dependency: NAVIGATE_RIGHTS.NAVIGATE_CLIENT, category: RightCategory.CLIENTS },
    _a[RIGHTS.CLIENT_BK_DL] = { dependency: RIGHTS.CLIENT_TAB_BK, category: RightCategory.CLIENTS },
    _a[NAVIGATE_RIGHTS.NAVIGATE_IDEABOARD] = { category: RightCategory.NAVIGATION },
    _a[RIGHTS.PROJECT_FETCH_ALL] = { category: RightCategory.PROJECTS },
    _a[RIGHTS.PROJECT_ADMIN_ALL] = { dependency: RIGHTS.PROJECT_FETCH_ALL, category: RightCategory.PROJECTS },
    _a[RIGHTS.CLIENT_STICKY_UPDATE] = { dependency: RIGHTS.CLIENT_STICKY_READ, category: RightCategory.CLIENTS },
    _a);
export var MilestonesType;
(function (MilestonesType) {
    MilestonesType["MEETING"] = "MEETING";
    MilestonesType["MEETING_NO_NOTE"] = "MEETING_NO_NOTE";
    MilestonesType["DEADLINE"] = "DEADLINE";
    MilestonesType["REMINDER"] = "REMINDER";
    MilestonesType["RELEASE"] = "RELEASE";
    MilestonesType["CALL"] = "CALL";
})(MilestonesType || (MilestonesType = {}));
export var MILESTONE_TYPE_RIGHTS = (_b = {},
    _b[MilestonesType.MEETING] = false,
    _b[MilestonesType.MEETING_NO_NOTE] = false,
    _b[MilestonesType.DEADLINE] = true,
    _b[MilestonesType.REMINDER] = false,
    _b[MilestonesType.RELEASE] = true,
    _b[MilestonesType.CALL] = false,
    _b);
export var ReleaseStateType;
(function (ReleaseStateType) {
    ReleaseStateType["IN_DEVELOPMENT"] = "IN_DEVELOPMENT";
    ReleaseStateType["STAGED"] = "STAGED";
    ReleaseStateType["RELEASED"] = "RELEASED";
})(ReleaseStateType || (ReleaseStateType = {}));
export var RELEASE_TYPE_LABEL = (_c = {},
    _c[ReleaseStateType.IN_DEVELOPMENT] = 'In development',
    _c[ReleaseStateType.STAGED] = 'Staged',
    _c[ReleaseStateType.RELEASED] = 'Released',
    _c);
export var MILESTONE_TYPE_LABEL = (_d = {},
    _d[MilestonesType.MEETING] = 'Meeting',
    _d[MilestonesType.MEETING_NO_NOTE] = 'Meeting without note',
    _d[MilestonesType.DEADLINE] = 'Deadline',
    _d[MilestonesType.REMINDER] = 'Reminder',
    _d[MilestonesType.RELEASE] = 'Release',
    _d[MilestonesType.CALL] = 'Call',
    _d);
export var MILESTONE_TYPE_ICON = (_e = {},
    _e[MilestonesType.MEETING] = 'users',
    _e[MilestonesType.MEETING_NO_NOTE] = 'users',
    _e[MilestonesType.DEADLINE] = 'step-forward-2',
    _e[MilestonesType.REMINDER] = 'bell',
    _e[MilestonesType.RELEASE] = 'checkbox-list',
    _e[MilestonesType.CALL] = 'phone-handset',
    _e);
export var CustomerRequestStatus;
(function (CustomerRequestStatus) {
    CustomerRequestStatus["NEW"] = "NEW";
    CustomerRequestStatus["OPEN"] = "OPEN";
    CustomerRequestStatus["DEFINED"] = "DEFINED";
    CustomerRequestStatus["AGREED"] = "AGREED";
})(CustomerRequestStatus || (CustomerRequestStatus = {}));
export var IncidentStatus;
(function (IncidentStatus) {
    IncidentStatus["NEW"] = "NEW";
    IncidentStatus["OPEN"] = "OPEN";
    IncidentStatus["SOLVED"] = "SOLVED";
})(IncidentStatus || (IncidentStatus = {}));
export var TaskType;
(function (TaskType) {
    TaskType["BUG"] = "BUG";
    TaskType["TASK"] = "TASK";
})(TaskType || (TaskType = {}));
export var TaskStatusType;
(function (TaskStatusType) {
    TaskStatusType["BACKLOG"] = "BACKLOG";
    TaskStatusType["TODO"] = "TODO";
    TaskStatusType["INPROGRESS"] = "INPROGRESS";
    TaskStatusType["REVIEW"] = "REVIEW";
    TaskStatusType["REVIEWING"] = "REVIEWING";
    TaskStatusType["DONE"] = "DONE";
})(TaskStatusType || (TaskStatusType = {}));
export var AssignedEmployeeType;
(function (AssignedEmployeeType) {
    AssignedEmployeeType["DEVELOPER"] = "DEVELOPER";
    AssignedEmployeeType["REVIEWER"] = "REVIEWER";
})(AssignedEmployeeType || (AssignedEmployeeType = {}));
export var ProjectMemberType;
(function (ProjectMemberType) {
    ProjectMemberType["MANAGER"] = "MANAGER";
    ProjectMemberType["MEMBER"] = "MEMBER";
})(ProjectMemberType || (ProjectMemberType = {}));
export var TaskResolution;
(function (TaskResolution) {
    TaskResolution["WONT_FIX"] = "WONT_FIX";
    TaskResolution["FIXED"] = "FIXED";
    TaskResolution["CANT_REPRODUCE"] = "CANT_REPRODUCE";
    TaskResolution["DUPLICATE"] = "DUPLICATE";
})(TaskResolution || (TaskResolution = {}));
export var TASK_RESOLUTION_LABEL = (_f = {},
    _f[TaskResolution.WONT_FIX] = "Won't fix",
    _f[TaskResolution.FIXED] = 'Fixed',
    _f[TaskResolution.CANT_REPRODUCE] = "Can't reproduce",
    _f[TaskResolution.DUPLICATE] = 'Duplicate',
    _f);
export var TASK_STATUS_LABEL = (_g = {},
    _g[TaskStatusType.BACKLOG] = 'Backlog',
    _g[TaskStatusType.TODO] = 'TODO',
    _g[TaskStatusType.INPROGRESS] = 'In Progress',
    _g[TaskStatusType.REVIEW] = 'Review',
    _g[TaskStatusType.REVIEWING] = 'In Review',
    _g[TaskStatusType.DONE] = 'GGWP',
    _g);
export var TASK_STATUS_SHORT_LABEL = (_h = {},
    _h[TaskStatusType.BACKLOG] = 'BL',
    _h[TaskStatusType.TODO] = 'TD',
    _h[TaskStatusType.INPROGRESS] = 'IP',
    _h[TaskStatusType.REVIEW] = 'R',
    _h[TaskStatusType.REVIEWING] = 'IR',
    _h[TaskStatusType.DONE] = 'GG',
    _h);
export var TASK_STATUS_CLASS = (_j = {},
    _j[TaskStatusType.BACKLOG] = 'inactive',
    _j[TaskStatusType.TODO] = 'inactive',
    _j[TaskStatusType.INPROGRESS] = 'default',
    _j[TaskStatusType.REVIEW] = 'default',
    _j[TaskStatusType.REVIEWING] = 'default',
    _j[TaskStatusType.DONE] = 'success',
    _j);
export var TodoStatusType;
(function (TodoStatusType) {
    TodoStatusType["CANCELED"] = "CANCELED";
    TodoStatusType["TODO"] = "TODO";
    TodoStatusType["DONE"] = "DONE";
})(TodoStatusType || (TodoStatusType = {}));
export var ProjectStatusType;
(function (ProjectStatusType) {
    ProjectStatusType["OPEN"] = "OPEN";
    ProjectStatusType["WAITING"] = "WAITING";
    ProjectStatusType["CLOSE"] = "CLOSE";
})(ProjectStatusType || (ProjectStatusType = {}));
export var FailedReviewReasons;
(function (FailedReviewReasons) {
    FailedReviewReasons["UX"] = "FAILED_REVIEW_REASONS_UX";
    FailedReviewReasons["UI"] = "FAILED_REVIEW_REASONS_UI";
    FailedReviewReasons["BROWSER_COMPATIBILITY"] = "FAILED_REVIEW_REASONS_BROWSER_COMPATIBILITY";
    FailedReviewReasons["BREAKING"] = "FAILED_REVIEW_REASONS_BREAKING";
    FailedReviewReasons["REGRESSION"] = "FAILED_REVIEW_REASONS_REGRESSION";
    FailedReviewReasons["DETAIL"] = "FAILED_REVIEW_REASONS_DETAIL";
    FailedReviewReasons["OTHER"] = "FAILED_REVIEW_REASONS_OTHER";
})(FailedReviewReasons || (FailedReviewReasons = {}));
export var FileStateType;
(function (FileStateType) {
    FileStateType["WAITING"] = "WAITING";
    FileStateType["PENDING"] = "PENDING";
    FileStateType["UPLOADING"] = "UPLOADING";
    FileStateType["PAUSED"] = "PAUSED";
    FileStateType["DONE"] = "DONE";
    FileStateType["CANCELLED"] = "CANCELLED";
    FileStateType["ERROR"] = "ERROR";
    FileStateType["REMOVED"] = "REMOVED";
})(FileStateType || (FileStateType = {}));
export var WikiPageType;
(function (WikiPageType) {
    WikiPageType["PAGE"] = "PAGE";
    WikiPageType["MEETING_NOTE"] = "MEETING_NOTE";
    WikiPageType["TECHNICAL_NOTE"] = "TECHNICAL_NOTE";
    WikiPageType["CHANGELOG"] = "CHANGELOG";
})(WikiPageType || (WikiPageType = {}));
export var DATABASE_SCHEMA;
(function (DATABASE_SCHEMA) {
    DATABASE_SCHEMA["PUBLIC"] = "public";
    DATABASE_SCHEMA["BI"] = "bi";
    DATABASE_SCHEMA["STORAGE"] = "storage";
    DATABASE_SCHEMA["META"] = "meta";
    DATABASE_SCHEMA["IDEABOARD"] = "ideaboard";
})(DATABASE_SCHEMA || (DATABASE_SCHEMA = {}));
export var STORED_TYPE;
(function (STORED_TYPE) {
    STORED_TYPE["WIKI_PAGE"] = "STORED_TYPE_WIKI_PAGE";
    STORED_TYPE["WIKI_ATTACHMENT"] = "STORED_TYPE_WIKI_ATTACHMENT";
    STORED_TYPE["CUSTOMER_REQUEST_FILE"] = "STORED_TYPE_CUSTOMER_REQUEST_FILE";
    STORED_TYPE["TASK_FILE"] = "STORED_TYPE_TASK_FILE";
    STORED_TYPE["IDEA_FILE"] = "STORED_TYPE_IDEA_FILE";
})(STORED_TYPE || (STORED_TYPE = {}));
export var WIKI_PAGE_SORT_FUNCTIONS = (_k = {},
    _k[WIKI_PAGE_FIELD.updatedAt] = function (p1, p2) {
        return moment(p1.updatedAt).isBefore(p2.updatedAt) ? 1 : -1;
    },
    _k[WIKI_PAGE_FIELD.title] = function (p1, p2) { return p1.title.localeCompare(p2.title); },
    _k);
export var STORAGE_RIGHT;
(function (STORAGE_RIGHT) {
    STORAGE_RIGHT["ADMIN"] = "STORAGE_RIGHT_ADMIN";
    STORAGE_RIGHT["EMPLOYEE"] = "STORAGE_RIGHT_EMPLOYEE";
    STORAGE_RIGHT["CLIENT"] = "STORAGE_RIGHT_CLIENT";
    STORAGE_RIGHT["PUBLIC"] = "STORAGE_RIGHT_PUBLIC";
})(STORAGE_RIGHT || (STORAGE_RIGHT = {}));
export var MimeTypes;
(function (MimeTypes) {
    MimeTypes["HTML"] = "text/html";
    MimeTypes["MARKDOWN"] = "text/markdown";
    MimeTypes["JPG"] = "image/jpg";
    MimeTypes["JPEG"] = "image/jpeg";
    MimeTypes["PNG"] = "image/png";
    MimeTypes["BMP"] = "image/bitmap";
    MimeTypes["PDF"] = "application/pdf";
    MimeTypes["ZIP"] = "application/x-zip-compressed";
    MimeTypes["DOCX"] = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    MimeTypes["PPTX"] = "application/vnd.openxmlformats-officedocument.presentationml.presentation";
    MimeTypes["XLSX"] = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    MimeTypes["XML"] = "application/xml";
    MimeTypes["XMLTEXT"] = "text/xml";
    MimeTypes["RTF"] = "application/rtf";
    MimeTypes["XLS"] = "application/vnd.ms-excel";
    MimeTypes["CSV"] = "text/csv";
    MimeTypes["CSVEXCEL"] = "application/vnd.ms-excel";
    MimeTypes["DOC"] = "application/msword";
    MimeTypes["SVG"] = "image/svg+xml";
    MimeTypes["PLAIN"] = "text/plain";
})(MimeTypes || (MimeTypes = {}));
export var MimeTypesExtensions;
(function (MimeTypesExtensions) {
    MimeTypesExtensions["HTML"] = ".html";
    MimeTypesExtensions["MARKDOWN"] = ".md";
    MimeTypesExtensions["JPG"] = ".jpg";
    MimeTypesExtensions["JPEG"] = ".jpeg";
    MimeTypesExtensions["PNG"] = ".png";
    MimeTypesExtensions["BMP"] = ".bmp";
    MimeTypesExtensions["PDF"] = ".pdf";
    MimeTypesExtensions["ZIP"] = ".zip";
    MimeTypesExtensions["DOCX"] = ".docx";
    MimeTypesExtensions["PPTX"] = ".pptx";
    MimeTypesExtensions["XLSX"] = ".xlsx";
    MimeTypesExtensions["XLS"] = ".xls";
    MimeTypesExtensions["XML"] = ".xml";
    MimeTypesExtensions["XMLTEXT"] = ".xml";
    MimeTypesExtensions["RTF"] = ".rtf";
    MimeTypesExtensions["CSV"] = ".csv";
    MimeTypesExtensions["CSVEXCEL"] = ".csv";
    MimeTypesExtensions["DOC"] = ".doc";
    MimeTypesExtensions["SVG"] = ".svg";
    MimeTypesExtensions["PLAIN"] = ".txt";
})(MimeTypesExtensions || (MimeTypesExtensions = {}));
export var MimeTypesPandocReader;
(function (MimeTypesPandocReader) {
    MimeTypesPandocReader["HTML"] = "html";
    MimeTypesPandocReader["MARKDOWN"] = "markdown";
    MimeTypesPandocReader["PDF"] = "pdf";
    MimeTypesPandocReader["DOCX"] = "docx";
})(MimeTypesPandocReader || (MimeTypesPandocReader = {}));
export var EXPORT_FORMATS;
(function (EXPORT_FORMATS) {
    EXPORT_FORMATS["PDF"] = "application/pdf";
    EXPORT_FORMATS["MARKDOWN"] = "text/markdown";
    EXPORT_FORMATS["HTML"] = "text/html";
    EXPORT_FORMATS["DOCX"] = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
})(EXPORT_FORMATS || (EXPORT_FORMATS = {}));
export var IMPORT_FORMATS;
(function (IMPORT_FORMATS) {
    IMPORT_FORMATS["HTML"] = "text/html";
    IMPORT_FORMATS["MARKDOWN"] = "text/markdown";
    IMPORT_FORMATS["DOCX"] = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
})(IMPORT_FORMATS || (IMPORT_FORMATS = {}));
export var PREVIEW_FORMATS = [MimeTypes.PNG, MimeTypes.JPG, MimeTypes.JPEG, MimeTypes.BMP, MimeTypes.PDF];
export var COGNITO_USER_GROUPS;
(function (COGNITO_USER_GROUPS) {
    COGNITO_USER_GROUPS["ADMIN"] = "admin";
    COGNITO_USER_GROUPS["EMPLOYEE"] = "employee";
    COGNITO_USER_GROUPS["BOT"] = "bot";
    COGNITO_USER_GROUPS["CLIENT"] = "client";
    COGNITO_USER_GROUPS["_AUTHED"] = "_AUTHED";
    COGNITO_USER_GROUPS["_UNAUTHED"] = "_UNAUTHED";
})(COGNITO_USER_GROUPS || (COGNITO_USER_GROUPS = {}));
export var COGNITO_USER_GROUPS_PRECEDENCE = (_l = {},
    _l[COGNITO_USER_GROUPS.ADMIN] = 3,
    _l[COGNITO_USER_GROUPS.EMPLOYEE] = 5,
    _l[COGNITO_USER_GROUPS.BOT] = 7,
    _l[COGNITO_USER_GROUPS.CLIENT] = 10,
    _l[COGNITO_USER_GROUPS._AUTHED] = 999,
    _l[COGNITO_USER_GROUPS._UNAUTHED] = 1000,
    _l);
export var GIT_LOG_KEY;
(function (GIT_LOG_KEY) {
    GIT_LOG_KEY["HASH"] = "hash";
    GIT_LOG_KEY["SHORT_HASH"] = "shortHash";
    GIT_LOG_KEY["TREE"] = "tree";
    GIT_LOG_KEY["PARENT"] = "parent";
    GIT_LOG_KEY["AUTHOR"] = "author";
    GIT_LOG_KEY["COMMITTER"] = "committer";
    GIT_LOG_KEY["MESSAGE"] = "message";
    GIT_LOG_KEY["RAW_DIFFS"] = "rawDiffs";
    GIT_LOG_KEY["DIFFS"] = "diffs";
    GIT_LOG_KEY["DATE"] = "date";
    GIT_LOG_KEY["DIFF"] = "diff";
})(GIT_LOG_KEY || (GIT_LOG_KEY = {}));
export var DEFAULT_LIMIT = 100;
export var DECIMAL_RADIX = 10;
export var ISO_DATE_FORMAT = 'YYYY-MM-DD';
export var ISO_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ssZZ';
export var ISO_DATE_TIME_WITHOUT_TZ_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export var ISO_MONTH_FORMAT = 'YYYY-MM';
export var PERCENTAGE = 100;
export var PERCENTAGE_PRECISION = 2;
export var SECONDS_IN_MINUTE = 60;
export var MILLISECONDS_IN_SECOND = 1000;
export var MD5_DIGEST_BYTES_LENGTH = 16;
export var MD5_DIGEST_HEX_LENGTH = 32;
export var GUID_LENGTH = 36;
export var MAX_LENGTH_TASK_NAME = 250;
export var MAX_LENGTH_DEFAULT = 255;
export var MAX_LENGTH_COGNITO_USERNAME = 128;
export var MAX_LENGTH_COGNITO_GROUP_NAME = 128;
export var MAX_LENGTH_COGNITO_CUSTOM_GROUP_NAME = MAX_LENGTH_COGNITO_GROUP_NAME - 1;
export var MAX_LENGTH_COGNITO_GROUP_DESCRIPTION = 2048;
export var MAX_LENGTH_DISCORD_SNOWFLAKE = 20;
export var PATTERN_NUMBER = /^\d*$/;
export var PATTERN_CAPITAL_NUMERIC_SPACE = /^[A-Z 0-9]*$/;
export var PATTERN_TIME = /^(\d+[wdhm]\s*)*$/;
export var PATTERN_COGNITO_USERNAME = /^[a-z0-9._\-]+$/;
export var BACKUP_FIELD;
(function (BACKUP_FIELD) {
    BACKUP_FIELD["key"] = "key";
    BACKUP_FIELD["size"] = "size";
    BACKUP_FIELD["date"] = "date";
})(BACKUP_FIELD || (BACKUP_FIELD = {}));
export var APP_HTTP_HEADERS;
(function (APP_HTTP_HEADERS) {
    APP_HTTP_HEADERS["WWW_VERSION"] = "x-www-version";
})(APP_HTTP_HEADERS || (APP_HTTP_HEADERS = {}));
export var API_ERROR_TYPE;
(function (API_ERROR_TYPE) {
    API_ERROR_TYPE["VERSION_MISMATCH"] = "VERSION_MISMATCH";
})(API_ERROR_TYPE || (API_ERROR_TYPE = {}));
export var stripAccents = function (str) {
    if (str === void 0) { str = ''; }
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};
export var TIME_COMPONENTS = {
    years: 'Y',
    months: 'M',
    weeks: 'w',
    days: 'd',
    hours: 'h',
    minutes: 'm',
    seconds: 's',
};
export var ComponentType;
(function (ComponentType) {
    ComponentType["TEXT"] = "TEXT";
    ComponentType["IDEA"] = "IDEA";
    ComponentType["CIRCLE"] = "CIRCLE";
    ComponentType["CIRCLE_EMPTY"] = "CIRCLE_EMPTY";
    ComponentType["TRIANGLE"] = "TRIANGLE";
    ComponentType["LINE"] = "LINE";
    ComponentType["ARROW"] = "ARROW";
    ComponentType["DOUBLE_ARROW"] = "DOUBLE_ARROW";
    ComponentType["RECTANGLE"] = "RECTANGLE";
    ComponentType["RECTANGLE_EMPTY"] = "RECTANGLE_EMPTY";
    ComponentType["FOLDER"] = "FOLDER";
})(ComponentType || (ComponentType = {}));
export var CollaboratorType;
(function (CollaboratorType) {
    CollaboratorType["READ"] = "READ";
    CollaboratorType["WRITE"] = "WRITE";
    CollaboratorType["MODERATOR"] = "MODERATOR";
})(CollaboratorType || (CollaboratorType = {}));
export var IdeaStatus;
(function (IdeaStatus) {
    IdeaStatus["OPEN"] = "OPEN";
    IdeaStatus["CLOSE"] = "CLOSE";
})(IdeaStatus || (IdeaStatus = {}));
export var BoardStatus;
(function (BoardStatus) {
    BoardStatus["OPEN"] = "OPEN";
    BoardStatus["CLOSE"] = "CLOSE";
})(BoardStatus || (BoardStatus = {}));
export var GANTT_WORKLOAD_TYPE;
(function (GANTT_WORKLOAD_TYPE) {
    GANTT_WORKLOAD_TYPE["PROJECT"] = "PROJECT";
    GANTT_WORKLOAD_TYPE["MEETING"] = "MEETING";
    GANTT_WORKLOAD_TYPE["OVERTIME"] = "OVERTIME";
})(GANTT_WORKLOAD_TYPE || (GANTT_WORKLOAD_TYPE = {}));
