import moment from 'moment';
import {ISchemaRoute} from '../../schema-static';
import {IClient} from './Clients';
import {ICognitoGroup} from './Cognito';
import {IEmployee} from './Employees';

export enum USER_FIELD {
    id = 'id',
    code = 'code',
    name = 'name',
    color = 'color',
    settings = 'settings',
    email = 'email',
    cognitoUsername = 'cognitoUsername',
    cognitoIdentityGuid = 'cognitoIdentityGuid',

    createdAt = 'createdAt',
    updatedAt = 'updatedAt',
}

export interface IUserBare {
    id?: number;
    createdAt?: moment.Moment;
    updatedAt?: moment.Moment;
    code: string;
    name: string;
    color: string;
    settings: ISettings;
    email?: string;
    cognitoUsername?: string;
    cognitoIdentityGuid?: string;
}

export interface IUserBelong {
    employee?: Partial<IEmployee>;
    client?: Partial<IClient>;
}

export interface IUser extends IUserBare, IUserBelong {}

export const USER_SCHEMA_ROUTE: ISchemaRoute = {
    tableName: 'users',
    singularRoute: 'user',
    pluralRoute: 'users',
};

export enum SETTINGS {
    THEME = 'THEME',
    LANGUAGE = 'LANGUAGE',
    SIDEBAR_COLLAPSED = 'SIDEBAR_COLLAPSED',
    FIRON_VIEW = 'FIRON_VIEW',
    FIRON_DETAIL_DISPLAY = 'FIRON_DETAIL_DISPLAY',
    ROADMAP_FILTER = 'ROADMAP_FILTER',
    EMAIL_NOTIFICATIONS = 'EMAIL_NOTIFICATIONS',
}

export enum SETTING_THEME_VALUES {
    DARK = 'dark',
    LIGHT = 'light',
}

export enum SETTING_LANGUAGE_VALUES {
    FR = 'FR',
    EN = 'EN',
}

export enum SETTING_FIRON_VIEW_VALUES {
    ALL = 'all',
    DEV = 'dev',
    REVIEW = 'review',
}

export enum SETTING_FIRON_DETAIL_DISPLAY_VALUES {
    COLUMN = 'col',
    MODAL = 'modal',
}

export enum SETTINGS_FILTER_ROADMAP_VALUES {
    ALL = 'all',
    MY = 'my',
}

export interface ISettings {
    LANGUAGE?: SETTING_LANGUAGE_VALUES;
    THEME: SETTING_THEME_VALUES;
    SIDEBAR_COLLAPSED: boolean;
    FIRON_VIEW: SETTING_FIRON_VIEW_VALUES;
    FIRON_DETAIL_DISPLAY: SETTING_FIRON_DETAIL_DISPLAY_VALUES;
    super_admin?: boolean;
    ROADMAP_FILTER?: string;
    EMAIL_NOTIFICATIONS?: boolean;
}

export const DEFAULT_SETTINGS: ISettings = {
    [SETTINGS.THEME]: SETTING_THEME_VALUES.DARK,
    [SETTINGS.SIDEBAR_COLLAPSED]: false,
    [SETTINGS.FIRON_VIEW]: SETTING_FIRON_VIEW_VALUES.ALL,
    [SETTINGS.FIRON_DETAIL_DISPLAY]: SETTING_FIRON_DETAIL_DISPLAY_VALUES.MODAL,
    [SETTINGS.EMAIL_NOTIFICATIONS]: false,
};

export interface IUserExtended extends Partial<IUser> {
    displayName?: string;
    groups?: ICognitoGroup[];
}
