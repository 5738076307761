<shared-toast></shared-toast>
<shared-control-flow
    [hidden]="!isAuthenticated"
    [rootPath]=""
    [routes]="routes"
    [routesControlFlow]="routesControlFlow"
    [requiredGroup]="requiredGroup"
    [maxDepth]="3"
    [maxDisplayedDepth]="1"
></shared-control-flow>
<shared-http-rest></shared-http-rest>
