import {Injectable, NgZone, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {fromEvent} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {RIGHTS} from '../../../../../defs/schema-static';
import {APP_MODULE_ROUTE_PATH, WIKI_MODULE_ROUTE_PATH} from '../../app-static';

export type ComponentContext = OnInit | OnDestroy;

export enum SHORTCUT_CREATE {
    CLIENT = 'CREATE_CLIENT',
    PROJECT = 'CREATE_PROJECT',
    EMPLOYEE = 'CREATE_EMPLOYEE',
    TASK = 'CREATE_TASK',
    MILESTONE = 'CREATE_MILESTONE',
    TIME = 'CREATE_TIME',
    WIKI_PAGE = 'CREATE_WIKI_PAGE',
}

export enum SHORTCUT_LOCAL {
    CREATE = 'LOCAL_CREATE',
    CANCEL = 'LOCAL_CANCEL',
    CONFIRM = 'LOCAL_CONFIRM',
}

export enum SHORTCUT_TODO {
    CANCEL_TODO = 'CANCEL_TODO_TODO',
    DONE_TODO = 'DONE_TODO_TODO',
    SAVE_TODO = 'SAVE_TODO_TODO',
    ADD_CATEGORY = 'ADD_CATEGORY_TODO',
    MOVE_TODO_UP = 'MOVE_TODO_UP',
    MOVE_TODO_DOWN = 'MOVE_TODO_DOWN',
    DELETE_TODO = 'DELETE_TODO',
}

export enum SHORTCUT_MULTIPLE_ADD {
    ADD_TEMP = 'ADD_TEMP',
}

export enum SHORTCUT_WIZARD {
    NEXT = 'NEXT',
}

export enum SHORTCUT_QUICK_ACTION {
    CREATE_TASK = 'CREATE_TASK_QUICK_ACTION',
    CREATE_MILESTONE = 'CREATE_MILESTONE_QUICK_ACTION',
    CREATE_TIME = 'CREATE_TIME_QUICK_ACTION',
}

export enum SHORTCUT_MISC {
    SIDEBAR = 'MISC_SIDEBAR',
    QUICK_ACTION = 'MISC_QUICK_ACTION',
    GLOBAL_SEARCH = 'MISC_GLOBAL_SEARCH',
    TOGGLE_TIMER = 'MISC_TOGGLE_TIMER',
    TEXTAREA_SUBMIT = 'MISC_TEXTAREA_SUBMIT',
}

export type SHORTCUT_NAME =
    | SHORTCUT_CREATE
    | SHORTCUT_TODO
    | SHORTCUT_QUICK_ACTION
    | SHORTCUT_LOCAL
    | SHORTCUT_MISC
    | SHORTCUT_MULTIPLE_ADD
    | SHORTCUT_WIZARD;

export const SHORTCUT_NUMBER: string[][] = [
    ['²', '~', '@'],
    ['1', '&'],
    ['2', 'é'],
    ['3', '"'],
    ['4', "'"],
    ['5', '('],
    ['6', '-', '§'],
    ['7', 'è'],
    ['8', '_', '!'],
    ['9', 'ç'],
    ['0', 'à'],
    ['°', ')'],
];

export const SHORTCUT_COMBO: {[shortcut in SHORTCUT_NAME]?: string | string[]} = {
    [SHORTCUT_LOCAL.CREATE]: 'c',
    [SHORTCUT_LOCAL.CANCEL]: 'Esc',
    [SHORTCUT_LOCAL.CONFIRM]: 'Enter',

    [SHORTCUT_MISC.GLOBAL_SEARCH]: ['/', ':'],
    [SHORTCUT_MISC.QUICK_ACTION]: 'shiftKey+C',
    [SHORTCUT_MISC.TOGGLE_TIMER]: 'ctrlKey+ ',
    [SHORTCUT_MISC.TEXTAREA_SUBMIT]: 'ctrlKey+Enter',

    [SHORTCUT_TODO.CANCEL_TODO]: 'ctrlKey+altKey+F',
    [SHORTCUT_TODO.MOVE_TODO_UP]: 'ctrlKey+shiftKey+ArrowUp',
    [SHORTCUT_TODO.MOVE_TODO_DOWN]: 'ctrlKey+shiftKey+ArrowDown',
    [SHORTCUT_TODO.DELETE_TODO]: 'ctrlKey+shiftKey+K',
    [SHORTCUT_TODO.DONE_TODO]: 'ctrlKey+shiftKey+D',
    [SHORTCUT_TODO.SAVE_TODO]: 'Enter',
    [SHORTCUT_TODO.ADD_CATEGORY]: 'shiftKey+Enter',

    [SHORTCUT_MULTIPLE_ADD.ADD_TEMP]: 'Enter',
    [SHORTCUT_WIZARD.NEXT]: 'Enter',

    [SHORTCUT_QUICK_ACTION.CREATE_TASK]: SHORTCUT_NUMBER[1],
    [SHORTCUT_QUICK_ACTION.CREATE_MILESTONE]: SHORTCUT_NUMBER[2],
    [SHORTCUT_QUICK_ACTION.CREATE_TIME]: SHORTCUT_NUMBER[3],
};

export const SHORTCUT_ROUTE: {[shortcut in SHORTCUT_NAME]?: string} = {
    [SHORTCUT_CREATE.CLIENT]: `/${APP_MODULE_ROUTE_PATH.CLIENTS}`,
    [SHORTCUT_CREATE.PROJECT]: `/${APP_MODULE_ROUTE_PATH.PROJECTS}`,
    [SHORTCUT_CREATE.EMPLOYEE]: `/${APP_MODULE_ROUTE_PATH.EMPLOYEES}`,

    [SHORTCUT_CREATE.WIKI_PAGE]: `/${WIKI_MODULE_ROUTE_PATH.WIKI}/${WIKI_MODULE_ROUTE_PATH.LIST}`,
};

export const SHORTCUT_LABEL: {[shortcut in SHORTCUT_NAME]?: string} = {
    [SHORTCUT_CREATE.CLIENT]: 'create_new_client',
    [SHORTCUT_CREATE.PROJECT]: 'create_new_project',
    [SHORTCUT_CREATE.EMPLOYEE]: 'create_new_employee',
    [SHORTCUT_CREATE.TASK]: 'create_new_task',
    [SHORTCUT_CREATE.MILESTONE]: 'create_new_milestone',
    [SHORTCUT_CREATE.TIME]: 'create_new_time',
    [SHORTCUT_CREATE.WIKI_PAGE]: 'create_new_wiki_page',

    [SHORTCUT_MISC.QUICK_ACTION]: 'open_quick_action',
    [SHORTCUT_MISC.TOGGLE_TIMER]: 'toggle_timer',
};

export const SHORTCUT_RIGHTS: {[shortcut in SHORTCUT_NAME]?: RIGHTS} = {
    [SHORTCUT_CREATE.CLIENT]: RIGHTS.CLIENT_CREATE,
    [SHORTCUT_CREATE.PROJECT]: RIGHTS.PROJECT_CREATE,
    [SHORTCUT_CREATE.EMPLOYEE]: RIGHTS.EMPLOYEE_CREATE,
    [SHORTCUT_CREATE.TASK]: RIGHTS.TASK_CREATE,
    [SHORTCUT_CREATE.MILESTONE]: RIGHTS.MS_UPDATE,
    [SHORTCUT_CREATE.TIME]: RIGHTS.TIME_UPDATE,
    [SHORTCUT_CREATE.WIKI_PAGE]: RIGHTS.WIKI_UPDATE,

    [SHORTCUT_MISC.TOGGLE_TIMER]: RIGHTS.TIME_UPDATE,
};

export interface IShortcutHandler {
    name?: SHORTCUT_NAME;
    shortcut?: SHORTCUT_NAME;
    shortcutFn?(event: KeyboardEvent): boolean;
    callback(event?: KeyboardEvent): void;
    context?: ComponentContext;
    forceListen?: boolean;
}

const ESCAPE_KEYS = ['Escape', 'Esc'];
const ENTER_KEYS = ['Enter', 'Return'];
const ARROW_KEYS = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'];

const SPECIAL_KEYS = [...ESCAPE_KEYS, ...ENTER_KEYS, ...ARROW_KEYS];

const MODIFIER_KEYS = ['ctrlKey', 'shiftKey', 'altKey', 'metaKey'];

@Injectable({
    providedIn: 'root',
})
export class ShortcutHandlerService {
    public handlers: IShortcutHandler[] = [];
    private readonly isChrome = /chrome/i.test(navigator.userAgent);

    public constructor(private readonly router: Router, private readonly zone: NgZone) {
        const events = {
            keydown: (e: KeyboardEvent) => this.isKeydown(e),
            keypress: (e: KeyboardEvent) => !this.isKeydown(e),
        };
        this.zone.runOutsideAngular(() => {
            Object.keys(events).map((event) => {
                fromEvent(window, event)
                    .pipe(debounceTime(50))
                    .subscribe((e: KeyboardEvent) => {
                        this.zone.run(() => events[event as 'keydown' | 'keypress'](e) && this.trigger(e));
                    });
            });
        });
    }

    private isKeydown(event: KeyboardEvent): boolean {
        // temp fix for firefox 64 (and maybe later)
        return this.isChrome || SPECIAL_KEYS.indexOf(event.key) !== -1 || event.ctrlKey;
    }

    private trigger(event: KeyboardEvent) {
        if (!event.key) {
            return;
        }
        const handler = [...this.handlers].reverse().find((_handler) => _handler.shortcutFn(event));
        if (!handler || ((event.target as HTMLElement).tagName !== 'BODY' && !handler.forceListen)) {
            return;
        }

        event.preventDefault();
        event.stopPropagation();

        handler.callback(event);
    }

    public async execute(shortcut: SHORTCUT_NAME, params?: any): Promise<void> {
        const handler = this.handlers.find((_handler) => _handler.name === shortcut);

        if (handler) {
            handler.callback(params);
        }

        if (SHORTCUT_ROUTE[shortcut] && this.router.url !== SHORTCUT_ROUTE[shortcut]) {
            // prevents redirect loop if route is guarded off
            if (await this.router.navigate([SHORTCUT_ROUTE[shortcut]])) {
                return this.execute(shortcut);
            }
        }
    }

    public register(params: IShortcutHandler, unregisterOnDestroy?: boolean): IShortcutHandler {
        if (!params.shortcut && !params.name) {
            throw new Error('undefined shortcut');
        }

        const shortcuts = (typeof SHORTCUT_COMBO[params.shortcut] === 'string'
            ? [SHORTCUT_COMBO[params.shortcut]]
            : SHORTCUT_COMBO[params.shortcut]) as string[];

        if (
            shortcuts &&
            this.handlers.find(
                (_handler) =>
                    shortcuts.indexOf(_handler.shortcut) !== -1 &&
                    (!params.context || _handler.context === params.context) &&
                    (!params.name || _handler.name === params.name)
            )
        ) {
            // tslint:disable-next-line no-console
            console.warn(`Duplicate shortcut: ${shortcuts} (${params.shortcut})`);

            return undefined;
        }

        if (params.context && unregisterOnDestroy) {
            // TODO is component
            if (!(params.context as OnDestroy).ngOnDestroy) {
                throw new Error("unregisterOnDestroy, but component doesn't implement OnDestroy");
            }

            const context = params.context as OnDestroy;
            const onDestroy = context.ngOnDestroy.bind(context);
            context.ngOnDestroy = () => {
                onDestroy();
                this.unregister(context);
            };
        }

        const handler: IShortcutHandler = {
            name: params.name,
            shortcut: params.shortcut,
            shortcutFn: (event: KeyboardEvent) =>
                shortcuts &&
                !!shortcuts.find(
                    (shortcut) =>
                        !shortcut
                            .split('+')
                            .find(
                                (token: keyof KeyboardEvent) =>
                                    !!MODIFIER_KEYS.find(
                                        (mod: keyof KeyboardEvent) => !shortcut.includes(mod) && !!event[mod]
                                    ) ||
                                    (ESCAPE_KEYS.includes(token) && !ESCAPE_KEYS.includes(event.key)) ||
                                    (ENTER_KEYS.includes(token) && !ENTER_KEYS.includes(event.key)) ||
                                    ((ARROW_KEYS.includes(token) || token.length === 1) &&
                                        event.key.toLowerCase() !== token.toLowerCase()) ||
                                    (!SPECIAL_KEYS.includes(token) && token.length > 1 && !event[token])
                            )
                ),
            callback: params.callback,
            context: params.context,
            forceListen: params.forceListen,
        };

        this.handlers.push(handler);

        return handler;
    }

    public unregisterHandlers(...handlers: IShortcutHandler[]) {
        this.handlers = this.handlers.filter((handler) => !handlers.includes(handler));
    }

    public unregister(context: ComponentContext, ...shortcuts: SHORTCUT_NAME[]) {
        this.handlers = this.handlers.filter(
            (handler) => handler.context !== context || (shortcuts.length && shortcuts.indexOf(handler.shortcut) === -1)
        );
    }
}
