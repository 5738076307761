<div class="clr-row">
    <div class="column clr-col-8">
        <ul class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/employees']" translate>employees</a></li>
            <li class="breadcrumb-item" *ngIf="employee && employee.user">
                {{ employee?.user.name }}
                <app-long-avatar
                    [type]="LONG_AVATAR_TYPE.BADGE_100"
                    [code]="employee?.user?.code"
                    [bgColor]="employee?.user?.color"
                ></app-long-avatar>
            </li>
        </ul>
    </div>
    <div class="column clr-col-4 text-right">
        <button
            class="btn btn-danger"
            (click)="deleteEmployeeModal()"
            *sharedAccessControl="RIGHTS.EMPLOYEE_DELETE"
            translate
        >
            delete_employee
        </button>
    </div>
</div>

<div class="clr-row">
    <div class="clr-col">
        <div class="avatar-container">
            <app-long-avatar [code]="employee?.user?.code" [bgColor]="employee?.user?.color"> </app-long-avatar>
        </div>
    </div>
</div>

<app-employee-wizard-employee
    [employee]="employee"
    [formLayout]="'vertical'"
    [edit]="true"
></app-employee-wizard-employee>

<button
    class="btn btn-success mt-2"
    [clrLoading]="validateBtnState"
    [disabled]="!editComponent.form.dirty || !editComponent.form.valid"
    (click)="saveEmployee()"
>
    {{ 'form_save' | translate }}
</button>
