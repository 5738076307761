var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ElementRef, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiRoutePlurality, HTTP_METHOD, ProjectMemberType, RIGHTS } from '../../../../../defs/schema-static';
import { TAG_SCHEMA_ROUTE } from '../../../../../defs/schema/public/Tags';
import { getRandomColorHex } from '../../app-static';
import { AuthService } from '../../auth/auth.service';
import { ProjectWizardProjectMembersComponent } from '../../project-wizard/project-members/project-wizard-project-members.component';
import { HttpRestService } from '../../shared/http-rest/http-rest.service';
import { TOAST_TYPE, ToastService } from '../../shared/toast/toast.service';
var ProjectParamsComponent = (function () {
    function ProjectParamsComponent(authService, httpRest, translate, toastService) {
        this.authService = authService;
        this.httpRest = httpRest;
        this.translate = translate;
        this.toastService = toastService;
        this.showDeleteModal = false;
        this.tagInputText = '';
        this.tagInputColor = getRandomColorHex();
        this.tagInputBranch = false;
        this.RIGHTS = RIGHTS;
    }
    ProjectParamsComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (!this.tags) {
            (function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                return [2, this.getTags()];
            }); }); })();
        }
        else {
            this.setActiveTags();
        }
    };
    ProjectParamsComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes.project) {
            (function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                return [2, this.getTags()];
            }); }); })();
        }
    };
    Object.defineProperty(ProjectParamsComponent.prototype, "isManager", {
        get: function () {
            var _this = this;
            return (!!(this.project.projectMembers || []).find(function (member) {
                return member.employeeId === _this.authService.user.employee.id && member.type === ProjectMemberType.MANAGER;
            }) || this.authService.hasRight(RIGHTS.PROJECT_ADMIN_ALL));
        },
        enumerable: true,
        configurable: true
    });
    ProjectParamsComponent.prototype.getTags = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.project || !this.project.id) {
                            return [2];
                        }
                        _a = this;
                        return [4, this.httpRest
                                ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, TAG_SCHEMA_ROUTE, "project/taskCount/" + this.project.id)
                                .toPromise()];
                    case 1:
                        _a.tags = _b.sent();
                        this.setActiveTags();
                        return [2];
                }
            });
        });
    };
    ProjectParamsComponent.prototype.setActiveTags = function () {
        this.notDoneTags = this.tags.filter(function (_a) {
            var taskTagsCount = _a.taskTagsCount;
            return taskTagsCount.notDone;
        });
        this.doneTags = this.tags.filter(function (_a) {
            var taskTagsCount = _a.taskTagsCount;
            return taskTagsCount.done && !taskTagsCount.notDone;
        });
        this.inactiveTags = this.tags.filter(function (_a) {
            var taskTagsCount = _a.taskTagsCount;
            return !taskTagsCount.done && !taskTagsCount.notDone;
        });
    };
    ProjectParamsComponent.prototype.addTag = function () {
        return __awaiter(this, void 0, void 0, function () {
            var text, tag;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        text = this.tagInputText.trim();
                        if (!text) {
                            return [2];
                        }
                        if (this.tags.find(function (_tag) { return _tag.text === text; })) {
                            this.resetTagInput();
                            this.toastService.show({
                                type: TOAST_TYPE.WARNING,
                                text: 'warning_duplicate_tag',
                            });
                            return [2];
                        }
                        return [4, this.updateTag({
                                text: text,
                                color: this.tagInputColor,
                                branch: this.tagInputBranch,
                                projectId: this.project.id,
                            })];
                    case 1:
                        tag = _a.sent();
                        this.tags.push(__assign({}, tag, { taskTagsCount: { done: 0, notDone: 0 } }));
                        this.toastService.show({
                            type: TOAST_TYPE.SUCCESS,
                            text: 'success_insert_tag',
                        });
                        this.setActiveTags();
                        this.resetTagInput();
                        return [2];
                }
            });
        });
    };
    ProjectParamsComponent.prototype.toggleTagBranch = function (tag) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                tag.branch = !tag.branch;
                return [2, this.updateTag(tag)];
            });
        });
    };
    ProjectParamsComponent.prototype.updateTag = function (tag) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (tag.id) {
                    return [2, this.httpRest.post(TAG_SCHEMA_ROUTE, __assign({}, tag)).toPromise()];
                }
                else {
                    return [2, this.httpRest.put(TAG_SCHEMA_ROUTE, __assign({}, tag)).toPromise()];
                }
                return [2];
            });
        });
    };
    ProjectParamsComponent.prototype.removeTag = function (tag, force) {
        if (force === void 0) { force = false; }
        return __awaiter(this, void 0, void 0, function () {
            var err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!force && (tag.taskTagsCount.notDone || tag.taskTagsCount.done)) {
                            this.selectedTag = tag;
                            this.showDeleteModal = true;
                            return [2];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4, this.httpRest.deleteId(TAG_SCHEMA_ROUTE, tag.id).toPromise()];
                    case 2:
                        _a.sent();
                        return [3, 5];
                    case 3:
                        err_1 = _a.sent();
                        return [2];
                    case 4:
                        this.showDeleteModal = false;
                        this.selectedTag = undefined;
                        return [7];
                    case 5:
                        this.tags.splice(this.tags.indexOf(tag), 1);
                        this.setActiveTags();
                        return [2];
                }
            });
        });
    };
    ProjectParamsComponent.prototype.resetTagInput = function () {
        this.tagInputText = '';
        this.tagInputColor = getRandomColorHex();
        var $tagInput = this.tagInput && this.tagInput.nativeElement;
        if ($tagInput) {
            $tagInput.focus();
        }
    };
    return ProjectParamsComponent;
}());
export { ProjectParamsComponent };
