import {Directive, ElementRef, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output} from '@angular/core';
import {NgModel} from '@angular/forms';
import {fromEvent, Subscription} from 'rxjs';
import {debounceTime, filter, map} from 'rxjs/operators';

export enum DEBOUNCE_EVENT_TYPE {
    CLICK = 'click',
    KEYUP = 'keyup',
    BLUR = 'blur',
}

@Directive({selector: '[sharedDebounce]'})
export class DebounceDirective implements OnInit, OnDestroy {
    @Input() public readonly delay = 700;
    @Input() public readonly debounceEventType = DEBOUNCE_EVENT_TYPE.KEYUP;

    @Output() public debounceFunction: EventEmitter<any> = new EventEmitter(true);

    private active = false;
    private readonly keyIgnored = [
        'ShiftLeft',
        'Tab',
        'ShiftRight',
        'Escape',
        'NumpadEnter',
        'Insert',
        'PageDown',
        'PageUp',
        'Home',
        'ControlLeft',
        'ControlRight',
    ];

    private subscription: Subscription;

    public constructor(
        private readonly elementRef: ElementRef,
        private readonly model: NgModel,
        private readonly zone: NgZone
    ) {}

    public isKeyIgnored(keycode: string) {
        return this.keyIgnored.includes(keycode);
    }

    public ngOnInit(): void {
        this.zone.runOutsideAngular(() => {
            const eventStream = fromEvent(this.elementRef.nativeElement, this.debounceEventType)
                .pipe(
                    filter((event: KeyboardEvent) => {
                        return !this.isKeyIgnored(event.code);
                    })
                )
                .pipe(
                    map(() => {
                        return this.model.value;
                    })
                );

            eventStream.subscribe(() => {
                this.active = true;
            });

            this.subscription = eventStream.pipe(debounceTime(this.delay)).subscribe((input) => {
                this.zone.run(() => {
                    this.debounceFunction.emit(input);

                    this.active = false;
                });
            });
        });
    }

    public ngOnDestroy() {
        if (this.subscription) {
            if (this.active) {
                this.debounceFunction.emit();
            }

            this.subscription.unsubscribe();
        }
    }
}
