import moment from 'moment';
import {ISchemaRoute} from '../../schema-static';
import {IEmployee} from './Employees';
import {IMilestone} from './Milestones';
import {IProject} from './Projects';
import {IUser} from './Users';

export enum CLIENT_FIELD {
    id = 'id',
    createdAt = 'createdAt',
    updatedAt = 'updatedAt',
    obs = 'obs',
    backupPrefix = 'backupPrefix',
    userId = 'userId',
    user = 'user',
    projects = 'projects',
    milestones = 'milestones',
}

export interface IClientBare {
    id?: number;
    createdAt?: moment.Moment;
    updatedAt?: moment.Moment;
    obs: string;
    backupPrefix?: string;
    userId: number;
}

export interface IClientBelong {
    user: Partial<IUser>;
    contacts: Partial<IEmployee>[];
}

export interface IClientMany {
    projects?: Partial<IProject>[];
    milestones?: Partial<IMilestone>[];
}

export interface IClient extends IClientBare, IClientBelong, IClientMany {}

const CLIENT_SCHEMA_ROUTE: ISchemaRoute = {
    tableName: 'clients',
    singularRoute: 'client',
    pluralRoute: 'clients',
};

export {CLIENT_SCHEMA_ROUTE};
