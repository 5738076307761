import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {Validators} from '@angular/forms';
import {ClrForm} from '@clr/angular';
import {TranslateService} from '@ngx-translate/core';
import {FormControl, FormGroup} from 'ngx-strongly-typed-forms';
import semVer from 'semver';
import {BudgetStatus, MAX_LENGTH_DEFAULT} from '../../../../defs/schema-static';
import {BUDGET_SCHEMA_ROUTE, IBudget} from '../../../../defs/schema/public/Budget';
import {IMilestone} from '../../../../defs/schema/public/Milestones';
import {BUDGET_STATUS_FILTER, PROJECT_TABS} from '../app-static';
import {requiredTrimValidator} from '../forms/validators/required-trim.validator';
import {HttpRestService} from '../shared/http-rest/http-rest.service';
import {TOAST_TYPE, ToastService} from '../shared/toast/toast.service';

enum BUDGET_FORM_KEYS {
    description = 'description',
    price = 'price',
    status = 'status',
    releaseId = 'releaseId',
    projectId = 'projectId',
}

interface IAddBudgetFormModal {
    description: string;
    status: BudgetStatus;
    price: number;
    releaseId?: number;
    projectId: number;
}

@Component({
    selector: 'app-edit-budget',
    templateUrl: './edit-budget.component.html',
    styleUrls: ['./edit-budget.component.scss'],
})
export class EditBudgetComponent implements OnInit, OnChanges {
    @Input() public budget: Partial<IBudget>;

    @Input() public show = false;
    @Output() public showChange = new EventEmitter<boolean>();

    @Input() public projectId: number;
    @Input() public releases: Partial<IMilestone>[];
    public releasesList: Partial<IMilestone>[] = [];

    @Output() public hidden = new EventEmitter();
    @Output() public budgetChange = new EventEmitter<IBudget>();

    @ViewChild(ClrForm) public clrForm: ClrForm;

    public readonly form = new FormGroup<IAddBudgetFormModal>({
        [BUDGET_FORM_KEYS.description]: new FormControl<string>(null, [
            requiredTrimValidator(),
            Validators.maxLength(MAX_LENGTH_DEFAULT),
        ]),
        [BUDGET_FORM_KEYS.price]: new FormControl<number>(null, Validators.required),
        [BUDGET_FORM_KEYS.status]: new FormControl<BudgetStatus>(BudgetStatus.ESTIMATED, Validators.required),
        [BUDGET_FORM_KEYS.releaseId]: new FormControl<number>(),
        [BUDGET_FORM_KEYS.projectId]: new FormControl<number>(this.projectId, Validators.required),
    });

    public constructor(
        private readonly httpRest: HttpRestService,
        private readonly toastService: ToastService,
        private readonly translate: TranslateService
    ) {}

    public ngOnInit(): void {
        this.formReset();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.projectId) {
            this.form.patchValue({
                projectId: this.projectId,
            });
        }

        if (changes.budget) {
            this.formReset();
        }

        if (changes.releases && (!this.releasesList || this.releasesList.length === 0)) {
            this.releasesList = this.releases.sort((a, b) => semVer.compare(b.version, a.version));
        }
    }

    public hideModal() {
        this.budget = undefined;
        this.formReset();
        this.showChange.emit((this.show = false));
        this.hidden.emit();
    }

    private formReset() {
        this.form.reset({
            status: BudgetStatus.ESTIMATED,
            projectId: this.projectId,
        });

        if (this.budget && this.budget.release) {
            this.budget.releaseId = this.budget.release.id;
        }

        this.form.patchValue(this.budget || {});
    }

    public async saveBudget() {
        if (!this.form.valid) {
            this.clrForm.markAsDirty();

            return;
        }

        const creation = !this.budget || !this.budget.id;
        const budget = await (creation
            ? this.httpRest.put<IBudget>(BUDGET_SCHEMA_ROUTE, {
                  ...this.form.value,
              })
            : this.httpRest.post<IBudget>(BUDGET_SCHEMA_ROUTE, {
                  id: this.budget.id,
                  ...this.form.value,
              })
        ).toPromise();

        this.budget = {...(this.budget || {}), ...budget};
        this.budget.release = this.releases.find((release) => release.id === budget.releaseId);

        this.budgetChange.emit(this.budget as IBudget);

        this.toastService.show({
            type: TOAST_TYPE.SUCCESS,
            text: creation ? 'toast_added_budget' : 'toast_updated_budget',
        });

        this.hideModal();
    }

    public readonly BUDGET_STATUS_VALUES = Object.values(BudgetStatus);
    public readonly BUDGET_FORM_KEYS = BUDGET_FORM_KEYS;
    public readonly BUDGET_STATUS_FILTER = BUDGET_STATUS_FILTER;
    public readonly PROJECT_TABS = PROJECT_TABS;
    public readonly MAX_LENGTH_DEFAULT = MAX_LENGTH_DEFAULT;
}
