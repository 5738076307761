import {EventEmitter, Injectable, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {FormGroup} from 'ngx-strongly-typed-forms';
import {concat, Observable, of, Subject} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, map, switchMap, tap} from 'rxjs/operators';
import {ApiRoutePlurality, HTTP_METHOD} from '../../../../defs/schema-static';
import {ITaskBlocker} from '../../../../defs/schema/public/TaskBlockers';
import {ITask, TASK_SCHEMA_ROUTE} from '../../../../defs/schema/public/Tasks';
import {IAddTaskFormModal, IEditTaskFormModal} from '../forms/add-task/add-task.service';
import {HttpRestService} from '../shared/http-rest/http-rest.service';

interface IShittyTaskBlocker extends Partial<ITaskBlocker> {
    name?: string;
}

@Injectable({
    providedIn: 'root',
})
export class AddBlockersService {
    @Output() public callback = new EventEmitter();
    private form: FormGroup<any>;

    public showBlockersModal = false;
    public blockers: any[] = [];
    private deletedBlockers: number[] = [];
    private newBlockers: number[] = [];
    private taskToEdit: ITask;

    public selectedBlockers: ITaskBlocker[] = [];

    // private horseyBlockers: typeof horsey;

    public tasksBlockers: Observable<any[] | ITask[]>;
    public tasksBlockersLoading = false;
    public tasksBlockers$ = new Subject<string>();

    public constructor(private readonly httpRest: HttpRestService, private readonly translate: TranslateService) {
        this.loadBlockerTasks();
    }

    public loadTasks(term: string) {
        return this.httpRest._request<ITask[]>(
            HTTP_METHOD.POST,
            ApiRoutePlurality.PLURAL,
            TASK_SCHEMA_ROUTE,
            'searchTasks',
            {
                search: term,
                projectId: this.form.value.project,
            }
        );
    }

    private loadBlockerTasks() {
        // filter out selected blockers
        this.tasksBlockers = concat(
            of([]), // default items
            this.tasksBlockers$.pipe(
                debounceTime(200),
                distinctUntilChanged(),
                tap(() => (this.tasksBlockersLoading = true)),
                switchMap((term) => {
                    if (!term || term === '') {
                        this.tasksBlockersLoading = false;

                        return of([]);
                    }

                    return this.loadTasks(term).pipe(
                        map((res) => {
                            return res.filter((r) => {
                                return this.newBlockers.findIndex((t) => t === r.id) === -1;
                            });
                        }),
                        catchError(() => of([])), // empty list on error
                        tap(() => (this.tasksBlockersLoading = false))
                    );
                })
            )
        );
    }

    public reset() {
        this.blockers = [];
        this.deletedBlockers = [];
        this.newBlockers = [];
        this.selectedBlockers = [];
    }

    public init(
        isNgForm = false,
        form: FormGroup<IAddTaskFormModal> | FormGroup<IEditTaskFormModal>,
        taskEdit?: ITask,
        blockers: number[] = []
    ) {
        this.reset();

        this.form = form as FormGroup<any>;

        this.taskToEdit = taskEdit;
        this.blockers = blockers;
    }

    public get blockersDeleted() {
        return this.deletedBlockers;
    }

    public get blockersNew() {
        return this.newBlockers;
    }

    public removeBlocker(i: ITaskBlocker) {
        // if (this.blockers.includes(i.id)) {
        //     this.deletedBlockers.push(this.blockers[i.id]);
        // } else {
        //     this.newBlockers.splice(this.newBlockers.indexOf(i.id), 1);
        // }

        requestAnimationFrame(() => {
            this.form.patchValue({
                blockers: this.form.value.blockers.filter((blocker: number) => blocker !== i.id),
            });
        });

        // this.updateBlockers();
    }

    public updateBlockers() {
        requestAnimationFrame(() => {
            this.form.patchValue({
                blockers: this.blockers.filter((blocker) => blocker && blocker !== 0).map((blocker) => blocker),
            });
        });
    }

    public doShowBlockersModal() {
        // if (!this.blockers.length) {
        //     this.addBlocker();
        // }
        this.showBlockersModal = true;
    }

    public closeBlockersModal() {
        this.blockers = this.blockers.filter((blocker) => blocker && blocker !== 0);
        this.showBlockersModal = false;
        if (this.callback) {
            this.callback.emit();
        }
    }
}
