import {Directive, HostListener} from '@angular/core';
import {NgControl} from '@angular/forms';

@Directive({
    selector: '[appLowercaseInput]',
})
export class LowercaseInputDirective {
    public constructor(private readonly control: NgControl) {}

    @HostListener('input', ['$event'])
    public onInputChange($event: Event) {
        const target = $event.target as HTMLInputElement;
        const value = target.value.toLowerCase();
        if (value !== target.value) {
            this.control.control.setValue(value);
        }
    }
}
