<form clrForm>
    <clr-select-container>
        <label><clr-icon shape="language"></clr-icon> {{ 'file_select' | translate }}</label>
        <select
            clrSelect
            name="file"
            [(ngModel)]="currentFile"
            (ngModelChange)="fileChange()"
            [disabled]="files.length === 0"
        >
            <option [ngValue]="null" translate>file_select</option>
            <optgroup *ngIf="activeFiles.length" [attr.label]="'active' | translate">
                <option *ngFor="let file of (activeFiles | sharedArraySort)" [ngValue]="file"> {{ file.name }} </option>
            </optgroup>
            <optgroup *ngIf="inactiveFiles.length" [attr.label]="'inactive' | translate">
                <option *ngFor="let file of (inactiveFiles | sharedArraySort)" [ngValue]="file">
                    {{ file.name }}
                </option>
            </optgroup>
        </select>
    </clr-select-container>
</form>

<div class="spinner-bg-c-small" *ngIf="currentFile && !currentJSON"><div class="spinner-c"></div></div>

<ng-container *ngIf="currentFile && currentJSON && customJSON">
    <form clrForm #f="ngForm">
        <div class="i18n-actions-bar">
            <div style="flex: 0 1">
                <div class="toggle-switch right-label">
                    <input
                        type="checkbox"
                        id="toggle_lang"
                        name="toggle_lang"
                        [(ngModel)]="currentFile.enabled"
                        [disabled]="currentFile.enabled && enabledI18n.length === 1"
                    />
                    <label for="toggle_lang" translate> active </label>
                </div>
            </div>
            <div style="flex: 1 1;"></div>
            <div style="flex: 1 1 100%; max-width: 300px;">
                <input
                    type="text"
                    sharedDebounce
                    [placeholder]="'placeholder_filter' | translate"
                    [(ngModel)]="textFilter"
                    [ngModelOptions]="{standalone: true}"
                    [delay]="200"
                    (debounceFunction)="filterJSON()"
                />
            </div>
            <div style="flex: 1 1;"></div>
            <div style="flex: 0 1">
                <div class="toggle-switch right-label">
                    <input
                        type="checkbox"
                        id="toggle_custom"
                        name="toggle_custom"
                        [(ngModel)]="customFilter"
                        [ngModelOptions]="{standalone: true}"
                        (ngModelChange)="filterJSON()"
                    />
                    <label for="toggle_custom" style="white-space: nowrap">
                        {{ 'lang_custom_only' | translate }}
                    </label>
                </div>
            </div>
        </div>

        <div class="clr-row">
            <div class="clr-col"><div class="divider"></div></div>
        </div>

        <div class="clr-col translations" [ngStyle]="{opacity: currentFile.enabled ? 1 : 0.6}">
            <ng-container *ngFor="let key of filteredJSON; let i = index; trackBy: key">
                <clr-input-container>
                    <label [class.custom]="!!customJSON[key]" class="text-ellipsis" [title]="key">{{ key }}</label>
                    <input
                        clrInput
                        type="text"
                        [(ngModel)]="customJSON[key]"
                        [placeholder]="currentJSON[key]"
                        (keyup)="refresh()"
                        name="i18n_{{ i }}"
                    />
                </clr-input-container>
                <div
                    class="alert alert-danger alert-variable"
                    role="alert"
                    *ngIf="customJSON[key] && variableRemainCount(key)"
                >
                    <div class="alert-items">
                        <div class="alert-item static">
                            <div class="alert-icon-wrapper">
                                <clr-icon class="alert-icon" shape="exclamation-circle"></clr-icon>
                            </div>
                            <span class="alert-text">{{
                                'variable_remaining' | translate: {list: variableRemainList(key)}
                            }}</span>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>

        <div class="clr-row">
            <div class="clr-col"><div class="divider"></div></div>
        </div>

        <div class="clr-row">
            <div class="clr-col-12">
                <button
                    [clrLoading]="validateBtnState"
                    class="btn btn-success"
                    [disabled]="!f.dirty || hasVariableMissingError()"
                    (click)="saveFile()"
                >
                    {{ 'save_lang' | translate }}
                </button>
            </div>
        </div>
    </form>
</ng-container>
