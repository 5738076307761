<app-modal
    [(show)]="show"
    (showChange)="showChange.emit($event)"
    (hidden)="hideModal()"
    [title]="
        budget && budget.description
            ? ('budget' | translate) + ': ' + budget.description
            : ('budget_create_modal_title' | translate)
    "
>
    <ng-template appModalBody>
        <form id="editBudgetForm" clrForm [clrLayout]="'vertical'" [formGroup]="form">
            <clr-input-container>
                <label translate>table_description</label>
                <input
                    clrInput
                    [formControlName]="BUDGET_FORM_KEYS.description"
                    sharedAutofocus
                    maxlength="{{ MAX_LENGTH_DEFAULT }}"
                />

                <clr-control-error *clrIfError="'requiredTrim'" translate>error_field_empty</clr-control-error>
                <clr-control-error *clrIfError="'maxLength'" translate>error_field_text_overflow</clr-control-error>
            </clr-input-container>
            <clr-input-container>
                <label translate>table_price</label>
                <input clrInput [formControlName]="BUDGET_FORM_KEYS.price" type="number" />
            </clr-input-container>
            <clr-select-container>
                <label translate>table_status</label>
                <select clrSelect [formControlName]="BUDGET_FORM_KEYS.status">
                    <option *ngFor="let status of BUDGET_STATUS_VALUES" [ngValue]="status" translate
                        >budget_{{ status | lowercase }}
                    </option>
                </select>
            </clr-select-container>

            <ng-select
                class="less-pl mt-1"
                [items]="releasesList"
                bindValue="id"
                [placeholder]="'milestone_release' | translate"
                [formControlName]="BUDGET_FORM_KEYS.releaseId"
                appendTo=".modal-body"
            >
                <ng-template ng-option-tmp let-item="item" let-index="index">
                    <small class="label label-info"> {{ item?.version }} </small> <span>{{ item?.obs }}</span>
                </ng-template>
                <ng-template ng-label-tmp let-item="item">
                    <small class="label label-info"> {{ item?.version }} </small> <span>{{ item?.obs }}</span>
                </ng-template>
            </ng-select>
        </form>
    </ng-template>

    <ng-template appModalFooter>
        <div class="clr-row">
            <div class="column clr-col-12">
                <button class="btn btn-secondary" (click)="hideModal()" translate>cancel</button>
                <button form="editBudgetForm" class="btn btn-success" (click)="saveBudget()" [disabled]="!form.valid">
                    {{ (budget?.id > 0 ? 'save_budget' : 'create_budget') | translate }}
                </button>
            </div>
        </div>
    </ng-template>
</app-modal>
