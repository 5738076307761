var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ChangeDetectorRef, OnChanges, OnDestroy, OnInit, SimpleChanges, } from '@angular/core';
import { ActivatedRoute, convertToParamMap, NavigationEnd, Router, UrlSegment } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import publicConfig from '../../../../../defs/config/config.json';
import { COGNITO_USER_GROUPS, COGNITO_USER_GROUPS_PRECEDENCE } from '../../../../../defs/schema-static';
import { AuthService } from '../../auth/auth.service';
import { NotificationService } from '../notification.service';
import { ControlFlowService } from './control-flow.service';
var ControlFlowComponent = (function () {
    function ControlFlowComponent(router, activatedRoute, controlFlowService, changeDetectorRef, authService, notificationService) {
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.controlFlowService = controlFlowService;
        this.changeDetectorRef = changeDetectorRef;
        this.authService = authService;
        this.notificationService = notificationService;
        this.rootPath = '';
        this.routesControlFlow = {};
        this.maxDepth = 1;
        this.maxDisplayedDepth = 3;
        this.hidden = false;
        this.requiredGroup = COGNITO_USER_GROUPS._UNAUTHED;
        this.class = '';
        this.isCollapsed = this.controlFlowService.isCollapsed.value;
        this.COGNITO_USER_GROUPS = COGNITO_USER_GROUPS;
        this.COGNITO_USER_GROUPS_PRECEDENCE = COGNITO_USER_GROUPS_PRECEDENCE;
        this.publicConfig = publicConfig;
    }
    ControlFlowComponent.prototype.switchSidebar = function () {
        this.controlFlowService.toggleSidebar();
    };
    ControlFlowComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.initRoutes();
        if (this.rootPath && this.rootPath.substr(-1) !== '/') {
            this.rootPath += '/';
        }
        var rootUrlSegment = {
            path: this.router.url.replace("/" + this.rootPath, ''),
            parameters: {},
            parameterMap: convertToParamMap({}),
        };
        this.updateCurrentRoute(rootUrlSegment);
        this.routerSubscription = this.router.events
            .pipe(filter(function (event) { return event instanceof NavigationEnd; }), map(function () { return _this.activatedRoute; }), map(function (route) {
            while (route.firstChild) {
                route = route.firstChild;
            }
            return route;
        }), filter(function (route) { return route.outlet === 'primary'; }))
            .subscribe(function (route) {
            var parentRoute = route.parent;
            var parentRoutePath = '';
            while (parentRoute.parent) {
                if (parentRoute.routeConfig && parentRoute.routeConfig.path) {
                    parentRoutePath = parentRoute.routeConfig.path + "/" + parentRoutePath;
                }
                parentRoute = parentRoute.parent;
            }
            route.url.subscribe(function (event) {
                _this.updateCurrentRoute(event[0], parentRoutePath);
            });
        });
        this.validSubscription = this.controlFlowService.valid$.subscribe(function (valid) { return _this.setFormValidation(valid); });
        this.collapsedSubscription = this.controlFlowService.isCollapsed$.subscribe(function (isCollapsed) { return (_this.isCollapsed = isCollapsed); });
    };
    ControlFlowComponent.prototype.ngOnChanges = function (changes) {
        if (changes.routes || changes.routesControlFlow || changes.requiredGroup) {
            this.initRoutes();
        }
    };
    ControlFlowComponent.prototype.ngOnDestroy = function () {
        [this.routerSubscription, this.validSubscription, this.collapsedSubscription]
            .filter(function (subscription) { return typeof subscription !== 'undefined'; })
            .map(function (subscription) { return subscription.unsubscribe(); });
    };
    ControlFlowComponent.prototype.initRoutes = function () {
        var _this = this;
        this.routes = this.getRoutes(this.routes);
        if (this.routesControlFlow) {
            var routesControlFlowKeys_1 = Object.keys(this.routesControlFlow);
            this.routes = this.routes.sort(function (r1, r2) { return routesControlFlowKeys_1.indexOf(r1.path) - routesControlFlowKeys_1.indexOf(r2.path); });
        }
        this.depths = new Array(this.maxDepth).fill(0).map(function (_, i) {
            return {
                index: i,
                routes: _this.routes.filter(function (route) { return route.depth === i + 1; }),
            };
        });
        if (this.activeRoute) {
            this.updateCurrentRoute(new UrlSegment(this.activeRoute.fullPath.replace(/\/:.*/, ''), {}));
        }
    };
    ControlFlowComponent.prototype.getRoutes = function (routes, depth, parentRoute) {
        var _this = this;
        if (depth === void 0) { depth = 1; }
        return routes.reduce(function (_routes, _route) {
            var controlFlow = _this.routesControlFlow[_route.path];
            var rootPath = (parentRoute && parentRoute.fullPath + "/") || '';
            var route = __assign({}, _route, (_this.routesControlFlow[_route.path] || {}), { active: false, disabled: false, label: (controlFlow && controlFlow.label) || _route.path, depth: depth, fullPath: ("" + rootPath + _route.path).replace(/\/{2,}/g, '/'), parentRoute: parentRoute, fullPathAliases: ((controlFlow && controlFlow.aliases) || []).map(function (alias) {
                    return ("" + rootPath + alias).replace(/\/{2,}/g, '/');
                }) });
            _routes.push(route);
            if (depth >= _this.maxDepth || !route.children || !route.children.length) {
                return _routes;
            }
            _routes.push.apply(_routes, _this.getRoutes(route.children, depth + 1, route));
            return _routes;
        }, []);
    };
    ControlFlowComponent.prototype.updateCurrentRoute = function (urlSegment, parentRoute, retry) {
        var _this = this;
        if (parentRoute === void 0) { parentRoute = ''; }
        if (retry === void 0) { retry = true; }
        var path = urlSegment ? "" + parentRoute + urlSegment.path : parentRoute.replace(/\/$/, '');
        this.updateDisabledState();
        var activeRoutes = [].concat.apply([], this.depths
            .map(function (depth) {
            return depth.routes.filter(function (route) {
                return route.fullPath.replace(/\/:.*/, '') === path ||
                    !!route.fullPathAliases.find(function (alias) { return alias.replace(/\/:.*/, '') === path; });
            });
        })
            .filter(function (route) { return !!route.length; }));
        var activeRoute = activeRoutes.slice().sort(function (r1, r2) { return (r2.icon || '').length - (r1.icon || '').length; })[0];
        if (!activeRoute || activeRoute.disabled) {
            var requiredRoutes_1 = this.routes.filter(function (route) { return route.required && !route.valid; });
            if (requiredRoutes_1.length) {
                (function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                    return [2, this.router.navigate(["/" + this.rootPath + requiredRoutes_1.pop().path])];
                }); }); })();
                return;
            }
        }
        if (!activeRoute) {
            if (retry) {
                this.updateCurrentRoute(null, parentRoute, false);
            }
            return;
        }
        this.setActiveRoute(activeRoute, (urlSegment || {}).parameters);
    };
    ControlFlowComponent.prototype.setFormValidation = function (valid) {
        var activeRoute = this.getActiveRoute();
        if (!activeRoute) {
            return;
        }
        if (activeRoute.valid === valid) {
            return;
        }
        activeRoute.valid = valid;
        if (activeRoute.required) {
            this.updateDisabledState();
        }
        this.changeDetectorRef.detectChanges();
    };
    ControlFlowComponent.prototype.updateDisabledState = function () {
        var invalidRequired = false;
        this.routes.map(function (route) {
            route.disabled = invalidRequired;
            if (!route.valid && route.required) {
                invalidRequired = true;
            }
        });
    };
    ControlFlowComponent.prototype.setActiveRoute = function (route, params) {
        var activeRoute = this.getActiveRoute();
        if (activeRoute) {
            activeRoute.active = false;
            {
                var parentRoute = activeRoute;
                while ((parentRoute = parentRoute.parentRoute)) {
                    parentRoute.active = false;
                }
            }
            if (activeRoute.flowParameters) {
                activeRoute.flowParameters.map(function (flowParam) { return (flowParam.active = false); });
                this.activeFlowParameter = null;
            }
        }
        route.active = true;
        {
            var parentRoute = route;
            while ((parentRoute = parentRoute.parentRoute)) {
                parentRoute.active = true;
            }
        }
        if (route.flowParameters && params) {
            route.flowParameters.map(function (flowParam) { return (flowParam.active = false); });
            var activeFlowParam = route.flowParameters.find(function (flowParam) {
                return Object.keys(params).length === Object.keys(flowParam.parameters).length &&
                    Object.keys(params)
                        .map(function (filterParamKey) {
                        return flowParam.parameters[filterParamKey] &&
                            flowParam.parameters[filterParamKey] === params[filterParamKey];
                    })
                        .find(function (bool) { return !bool; });
            });
            if (activeFlowParam) {
                activeFlowParam.active = true;
                this.activeFlowParameter = activeFlowParam;
            }
        }
        this.activeRoute = route;
        if (activeRoute) {
            this.changeDetectorRef.detectChanges();
        }
    };
    ControlFlowComponent.prototype.getRelativeRoute = function (relative) {
        var _this = this;
        if (typeof this.activeRoute === 'undefined') {
            return undefined;
        }
        var index = this.routes.indexOf(this.activeRoute);
        if (index === -1) {
            index = this.routes.findIndex(function (route) { return route.fullPath === _this.activeRoute.fullPath; });
        }
        if (index === -1) {
            return undefined;
        }
        var relativeIndex = index + relative;
        if (relativeIndex >= this.routes.length || relativeIndex < 0) {
            return undefined;
        }
        return this.routes[relativeIndex];
    };
    ControlFlowComponent.prototype.getNextRoute = function () {
        return this.getRelativeRoute(1);
    };
    ControlFlowComponent.prototype.getActiveRoute = function () {
        return this.getRelativeRoute(0);
    };
    ControlFlowComponent.prototype.getPreviousRoute = function () {
        return this.getRelativeRoute(-1);
    };
    Object.defineProperty(ControlFlowComponent.prototype, "displayedDepths", {
        get: function () {
            return this.depths.slice(0, this.maxDisplayedDepth);
        },
        enumerable: true,
        configurable: true
    });
    ControlFlowComponent.prototype.getRouteUrl = function (route) {
        return [
            "/" + this.rootPath + route.fullPath,
            route.flowParameters &&
                (this.activeRoute || { flowParameters: undefined }).flowParameters === route.flowParameters
                ? (this.activeFlowParameter || { parameters: undefined }).parameters || {}
                : {},
        ];
    };
    return ControlFlowComponent;
}());
export { ControlFlowComponent };
