import moment from 'moment';
import {DATABASE_SCHEMA, FileStateType, ISchemaRoute, STORED_TYPE} from '../../schema-static';
import {IUser} from '../public/Users';
import {IWikiPage} from '../public/WikiPage';
import {IBoardAttachment} from './BoardAttachments';
import {ICustomerRequestFile} from './CustomerRequestFiles';
import {ITaskFile} from './TaskFiles';
import {IWikiAttachment} from './WikiAttachments';

export type FileEntity = (Partial<IWikiPage | IWikiAttachment | ICustomerRequestFile | ITaskFile>) & {
    fileId?: number;
    file?: Partial<IFile>;
};

export enum FILE_FIELD {
    id = 'id',
    createdAt = 'createdAt',
    updatedAt = 'updatedAt',
    path = 'path',
    mimeType = 'mimeType',
    md5 = 'md5',
    size = 'size',
    state = 'state',
    userId = 'userId',
    user = 'user',
}

export interface IFileBare {
    id?: number;
    createdAt?: moment.Moment;
    updatedAt?: moment.Moment;
    path: string;
    mimeType: string;
    size: number;
    md5: string;
    state: FileStateType;
    userId: number;
}

export interface IFileBelong {
    user: Partial<IUser>;
}

export interface IFileMany {
    wikiAttachments: Partial<IWikiAttachment>[];
    customerRequestFiles: Partial<ICustomerRequestFile>[];
    taskFiles: Partial<ITaskFile>[];
    ideaFiles: Partial<IBoardAttachment>[];
}

export interface IFile extends IFileBare, IFileBelong, IFileMany {}

export interface IFileEntity extends IFile {
    storedType: STORED_TYPE;
    entity: FileEntity;
}

const FILE_SCHEMA_ROUTE: ISchemaRoute = {
    tableName: 'files',
    singularRoute: 'file',
    pluralRoute: 'files',
    schema: DATABASE_SCHEMA.STORAGE,
};

export {FILE_SCHEMA_ROUTE};
