import * as i0 from "./tab.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./tab.component";
var styles_TabComponent = [i0.styles];
var RenderType_TabComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TabComponent, data: {} });
export { RenderType_TabComponent as RenderType_TabComponent };
function View_TabComponent_1(_l) { return i1.ɵvid(0, [i1.ɵncd(null, 0), (_l()(), i1.ɵand(0, null, null, 0))], null, null); }
export function View_TabComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { viewOutlet: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 5, "section", [["class", "tab-content clr-col-12"]], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "d-none": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TabComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 16777216, [[1, 3], ["viewOutlet", 1]], null, 0, null, null, null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "tab-content clr-col-12"; var currVal_1 = _ck(_v, 3, 0, ((!_co.isActive || _co.disabled) || _co.justAButton)); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = !_co._lazyContent; _ck(_v, 5, 0, currVal_2); }, null); }
export function View_TabComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-tab", [], null, null, null, View_TabComponent_0, RenderType_TabComponent)), i1.ɵdid(1, 2670592, null, 1, i3.TabComponent, [], null, null), i1.ɵqud(335544320, 1, { _lazyContent: 0 })], null, null); }
var TabComponentNgFactory = i1.ɵccf("app-tab", i3.TabComponent, View_TabComponent_Host_0, { name: "name", bgColor: "bgColor", label: "label", disabled: "disabled", justAButton: "justAButton", queryParams: "queryParams" }, {}, ["*"]);
export { TabComponentNgFactory as TabComponentNgFactory };
