<div class="card-columns">
    <div class="card" *ngFor="let request of requests">
        <div class="card-header">
            <div class="card-title-container">
                <span class="card-title"> {{ request.title }} </span>
                <span class="card-date tooltip-sm" [attr.data-tooltip]="request.createdAt | momentFormat: 'L LTS'">
                    {{ request.createdAt | momentDuration }}
                </span>
            </div>
            <div class="card-description-container">
                <p class="card-description">{{ request.description }}</p>
                <span class="card-status" [ngClass]="request.status | lowercase"> {{ request.status }} </span>
            </div>
        </div>
        <ul class="list-group list-group-flush" *ngIf="attachments[request.id]?.length > 0">
            <li class="list-group-item" *ngFor="let attachment of attachments[request.id]">
                <a [routerLink]="" (click)="downloadS3(attachment)" [title]="attachment.path">
                    {{ getFilename(attachment.path) }}
                </a>
                <small>({{ attachment.size | fileSize }})</small>
            </li>
        </ul>
        <div
            class="card-footer"
            *ngIf="request.status === CustomerRequestStatus.NEW || request.status === CustomerRequestStatus.OPEN"
        >
            <button
                class="btn btn-sm btn-link"
                *ngIf="request.status === CustomerRequestStatus.NEW"
                (click)="changeRequestStatus(request, CustomerRequestStatus.OPEN)"
            >
                {{ 'mark_opened' | translate }}
            </button>
            <button
                class="btn btn-sm btn-link"
                *ngIf="request.status === CustomerRequestStatus.OPEN"
                (click)="changeRequestStatus(request, CustomerRequestStatus.DEFINED)"
            >
                {{ 'mark_defined' | translate }}
            </button>
        </div>
    </div>
</div>
