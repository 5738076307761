import {Component, OnDestroy} from '@angular/core';
import {ClrLoadingState} from '@clr/angular';
import {SHORTCUT_LOCAL, ShortcutHandlerService} from '../shared/shortcut-handler/shortcut-handler.service';
import {IModalSimpleOptions, ModalSimpleService} from './modal-simple.service';

@Component({
    selector: 'app-modal-simple',
    templateUrl: './modal-simple.component.html',
    styleUrls: ['./modal-simple.component.scss'],
})
export class ModalSimpleComponent implements OnDestroy {
    public size = 'md';
    public title: string;
    public ok = {
        i18n: 'form_validate',
        class: 'btn-success',
    };
    public ko = {
        i18n: 'form_cancel',
        class: 'btn-secondary',
    };

    public contentString: string;
    public contentI18n: string;
    public contentObj: any;

    public cancelBtnState: ClrLoadingState = ClrLoadingState.DEFAULT;
    public validateBtnState: ClrLoadingState = ClrLoadingState.DEFAULT;

    public constructor(
        protected shortcutHandlerService: ShortcutHandlerService,
        private readonly modalSimpleService: ModalSimpleService
    ) {}

    // tslint:disable-next-line:prefer-function-over-method no-empty
    public ngOnDestroy() {}

    public onInjectInputs(options?: IModalSimpleOptions): void {
        this.title = options.title;
        this.size = options.size || 'md';
        this.contentString = options.contentString;
        this.contentI18n = options.contentI18n;
        this.contentObj = options.contentObj;

        if (options.ok) {
            this.ok = {...this.ok, ...options.ok};
        }
        if (options.ko) {
            this.ko = {...this.ko, ...options.ko};
        }

        this.shortcutHandlerService.register({
            shortcut: SHORTCUT_LOCAL.CANCEL,
            callback: () => {
                this.dismiss();
            },
            context: this,
            forceListen: true,
        });

        this.shortcutHandlerService.register({
            shortcut: SHORTCUT_LOCAL.CONFIRM,
            callback: (e) => {
                this.validate();
            },
            context: this,
            forceListen: true,
        });
    }

    public dismiss() {
        this.modalSimpleService.dismiss();
        this.unregister();
    }

    public close() {
        this.cancelBtnState = ClrLoadingState.LOADING;
        this.modalSimpleService.close();
        this.unregister();
    }

    public validate() {
        this.validateBtnState = ClrLoadingState.LOADING;
        this.modalSimpleService.validate();
        this.unregister();
    }

    public unregister() {
        this.shortcutHandlerService.unregister(this, SHORTCUT_LOCAL.CANCEL);
        this.shortcutHandlerService.unregister(this, SHORTCUT_LOCAL.CONFIRM);
    }
}
