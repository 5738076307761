<form id="timeForm" clrForm [formGroup]="form" [clrLayout]="'horizontal'">
    <div class="clr-row">
        <div class="clr-col-12">
            <ng-select
                class="less-pl"
                [items]="employees"
                bindLabel="user.name"
                bindValue="id"
                [placeholder]="'please_select_employee' | translate"
                (clear)="filterEmployee()"
                (change)="filterEmployee($event?.id)"
                #ngSelectEmployee
                formControlName="employeeId"
            >
                <ng-template ng-option-tmp let-item="item" let-index="index">
                    <app-long-avatar [code]="item?.user?.code" [bgColor]="item?.user?.color"> </app-long-avatar>
                    {{ item?.user?.name }}
                </ng-template>
                <ng-template ng-label-tmp let-item="item">
                    <app-long-avatar [code]="item?.user?.code" [bgColor]="item?.user?.color"> </app-long-avatar>
                    {{ item?.user?.name }}
                </ng-template>
            </ng-select>
        </div>
    </div>

    <clr-date-container class="clr-row">
        <label class="clr-col-12 clr-col-md-3" translate>date_begin</label>
        <input
            class="clr-col-12 clr-col-md-9"
            type="date"
            id="startDate"
            name="startDate"
            [(clrDate)]="beginDate"
            #beginDateInputElement
        />
    </clr-date-container>

    <div class="clr-row mt-1" *ngIf="!checkDates()">
        <div class="clr-col-12">
            <div class="alert alert-danger" role="alert">
                <div class="alert-items">
                    <div class="alert-item static">
                        <div class="alert-icon-wrapper">
                            <clr-icon class="alert-icon" shape="exclamation-circle"></clr-icon>
                        </div>
                        <span class="alert-text" [innerHTML]="'error_date_begin_only' | translate"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <clr-input-container class="mt-2">
        <label class="clr-col-12 clr-col-md-3" translate>time_tracking</label>
        <input
            class="clr-col-12 clr-col-md-9"
            clrInput
            type="text"
            placeholder="0m"
            formControlName="time"
            name="time"
            sharedDebounce
            [delay]="100"
            (debounceFunction)="checkValidationTime()"
            #timeInputElement
        />
        <clr-control-helper>0w 0d 0h 0m</clr-control-helper>
        <clr-control-error *clrIfError="'pattern'" [innerHTML]="'help_time' | translate"></clr-control-error>
    </clr-input-container>

    <ng-container *ngIf="times && times.length > 0">
        <h3 translate>previous_times</h3>
        <app-card
            *ngFor="let time of times"
            [user]="time.employee.user"
            [createdAt]="time.createdAt"
            [updatedAt]="time.updatedAt"
            [tile]="true"
        >
            <ng-template appCardBody>{{ time.time }}</ng-template>
        </app-card>
    </ng-container>
</form>
