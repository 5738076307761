import * as i0 from "./time-type.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@clr/angular";
import * as i4 from "@ngx-translate/core";
import * as i5 from "./time-type.component";
import * as i6 from "../../auth/auth.service";
var styles_TimeTypeComponent = [i0.styles];
var RenderType_TimeTypeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TimeTypeComponent, data: {} });
export { RenderType_TimeTypeComponent as RenderType_TimeTypeComponent };
function View_TimeTypeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "clr-col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "clr-row item-choice c-hand"]], [[8, "tabIndex", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setType(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { active: 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "clr-col-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "clr-icon", [], [[1, "shape", 0]], null, null, null, null)), i1.ɵdid(6, 16384, null, 0, i3.ClrIconCustomTag, [], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "clr-icon-9"], ["translate", ""]], null, null, null, null, null)), i1.ɵdid(8, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(9, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "clr-row item-choice c-hand"; var currVal_2 = _ck(_v, 3, 0, (_v.context.$implicit === _co.form.value.taskTarget)); _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_4 = ""; _ck(_v, 8, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.context.index, ""); _ck(_v, 1, 0, currVal_0); var currVal_3 = _co.iconTarget[_v.context.$implicit]; _ck(_v, 5, 0, currVal_3); var currVal_5 = _co.i18nTarget[_v.context.$implicit]; _ck(_v, 9, 0, currVal_5); }); }
export function View_TimeTypeComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { clrForm: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "clr-col-row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TimeTypeComponent_1)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.taskTarget; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_TimeTypeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-time-type", [], null, null, null, View_TimeTypeComponent_0, RenderType_TimeTypeComponent)), i1.ɵdid(1, 49152, null, 0, i5.TimeTypeComponent, [i6.AuthService], null, null)], null, null); }
var TimeTypeComponentNgFactory = i1.ɵccf("app-time-type", i5.TimeTypeComponent, View_TimeTypeComponent_Host_0, {}, { switchType: "switchType" }, []);
export { TimeTypeComponentNgFactory as TimeTypeComponentNgFactory };
