import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {ClrDatagridSortOrder} from '@clr/angular';
import {TranslateService} from '@ngx-translate/core';
import {IProjectDash} from '../../../../../defs/businessRules';
import {ProjectMemberType, RIGHTS} from '../../../../../defs/schema-static';
import {CLIENT_FIELD, IClient} from '../../../../../defs/schema/public/Clients';
import {IEmployee} from '../../../../../defs/schema/public/Employees';
import {IProject, PROJECT_FIELD, PROJECT_SCHEMA_ROUTE} from '../../../../../defs/schema/public/Projects';
import {USER_FIELD} from '../../../../../defs/schema/public/Users';
import {CLIENT_SORT_FUNCTION, PROJECT_MEMBER_SORT_FUNCTION} from '../../../../../defs/sorters';
import {
    DATAGRID_FILTER_TYPE,
    IDatagridColumns,
    IDatagridFilterMap,
    PROJECT_STATUS_FILTER,
    SelectFilterType,
    sortFnArrayWrapper,
} from '../../app-static';
import {AuthService} from '../../auth/auth.service';
import {HttpRestService} from '../../shared/http-rest/http-rest.service';
import {LONG_AVATAR_TYPE} from '../../shared/long-avatar/long-avatar.component';
import {
    SHORTCUT_CREATE,
    SHORTCUT_LOCAL,
    ShortcutHandlerService,
} from '../../shared/shortcut-handler/shortcut-handler.service';
import {TOAST_TYPE, ToastService} from '../../shared/toast/toast.service';

export enum PROJECT_LIST_LAYOUT {
    ADD_DELETE = 'ADD_DELETE',
    SELECT = 'SELECT',
}

@Component({
    selector: 'app-projects-list',
    templateUrl: './projects-list.component.html',
})
export class ProjectsListComponent implements OnInit, OnDestroy, OnChanges {
    @Input() public projects: IProjectDash[];
    @Input() public columns = ProjectsListComponent.DEFAULT_PROJECTS_COLUMNS;
    @Input() public actionBarButtonClassList = 'btn-sm';
    @Input() public addButtonClassList = '';
    @Input() public layout: PROJECT_LIST_LAYOUT = PROJECT_LIST_LAYOUT.ADD_DELETE;

    @Input() public wizardClients: IClient[];
    @Input() public wizardClientId: number;

    @Input() public selectedProjects: IProjectDash[] = [];
    @Output() public selectedProjectsChange = new EventEmitter<IProjectDash[]>();

    public showDeleteModal = false;
    public clients: Partial<IClient>[] = null;
    public managers: Partial<IEmployee>[] = null;

    @Input() public showCreateModal = false;
    @Output() public showCreateModalChange = new EventEmitter<boolean>();

    @Input() public clrDgRowSelection = false;

    public constructor(
        private readonly httpRest: HttpRestService,
        private readonly shortcutHandlerService: ShortcutHandlerService,
        private readonly authService: AuthService,
        private readonly translate: TranslateService,
        private readonly toastService: ToastService
    ) {
        this.shortcutHandlerService.register(
            {
                name: SHORTCUT_CREATE.PROJECT,
                shortcut: SHORTCUT_LOCAL.CREATE,
                callback: () => this.showCreateModalChange.emit((this.showCreateModal = true)),
                context: this,
            },
            true
        );
    }

    // tslint:disable-next-line:prefer-function-over-method no-empty
    public ngOnInit() {}

    public ngOnChanges(changes: SimpleChanges) {
        if (changes && changes.projects && this.projects) {
            this.refreshClients();
            this.refreshManagers();
        }
    }

    public refreshClients() {
        this.clients = [];
        this.projects.map((p) => {
            const clientFound = this.clients.find((c) => c && c.id === p.client.id);
            if (!clientFound) {
                this.clients.push(p.client);
            }
        });
        this.clients.sort(CLIENT_SORT_FUNCTION);
    }
    public refreshManagers() {
        this.managers = [];
        this.projects.map((p) => {
            if (p.managers) {
                p.managers.map((m) => {
                    const managerFound = this.managers.find((c) => m.employee.id === c.id);
                    if (!managerFound) {
                        this.managers.push(m.employee);
                    }
                });
            }
        });
        this.managers.sort((a, b) => a.user.name.localeCompare(b.user.name));
    }

    // tslint:disable-next-line:prefer-function-over-method no-empty
    public ngOnDestroy() {}

    public async addNewProject(project: IProject) {
        this.projects.push({
            budgetSum: 0,
            ...project,
            roleType:
                (project.projectMembers || [])
                    .filter((projectMember) => projectMember.employeeId === this.authService.user.employee.id)
                    .map((projectMember) => projectMember.type)[0] || undefined,
            managers: (project.projectMembers || []).filter(
                (projectMember) => projectMember.type === ProjectMemberType.MANAGER
            ),
        });

        this.toastService.show({
            type: TOAST_TYPE.SUCCESS,
            text: 'success_insert_project',
        });
    }

    public async deleteSelectedProjects() {
        const deletedProjectIds = this.selectedProjects.map(({id}) => id);

        try {
            await this.httpRest.deleteIds(PROJECT_SCHEMA_ROUTE, deletedProjectIds).toPromise();
        } catch (err) {
            return;
        } finally {
            this.showDeleteModal = false;
        }

        this.selectedProjects = [];
        this.projects = this.projects.filter(({id}) => !deletedProjectIds.includes(id));

        this.toastService.show({
            type: TOAST_TYPE.SUCCESS,
            text: 'success_delete_project',
        });
    }

    public readonly PROJECT_FIELD = PROJECT_FIELD;

    public readonly PROJECT_STATUS_FILTER = PROJECT_STATUS_FILTER;

    public readonly PROJECT_ROLE_TYPE_FILTER: IDatagridFilterMap<ProjectMemberType> = {
        [ProjectMemberType.MEMBER]: {
            translation: 'team_member',
        },
        [ProjectMemberType.MANAGER]: {
            classList: 'text-warning',
            translation: 'project_manager',
        },
    };

    public readonly RIGHTS = RIGHTS;
    public readonly LONG_AVATAR_TYPE = LONG_AVATAR_TYPE;
    public readonly ProjectMemberType = ProjectMemberType;
    public readonly CLIENT_FIELD = CLIENT_FIELD;
    public readonly USER_FIELD = USER_FIELD;
    public readonly DATAGRID_FILTER_TYPE = DATAGRID_FILTER_TYPE;
    public readonly PROJECT_LIST_LAYOUT = PROJECT_LIST_LAYOUT;
    public readonly SelectFilterType = SelectFilterType;

    public filterOpen: {[key: string]: boolean} = {};
    public static readonly DEFAULT_PROJECTS_COLUMNS: IDatagridColumns<IProjectDash> = [
        {
            field: PROJECT_FIELD.obs,
            translateKey: 'table_name',
            hideable: false,
            order: ClrDatagridSortOrder.ASC,
        },
        {
            field: `${PROJECT_FIELD.client}.${CLIENT_FIELD.user}.${USER_FIELD.name}`,
            translateKey: 'table_client',
            filterType: DATAGRID_FILTER_TYPE.SELECT,
        },
        {field: 'budgetSum', translateKey: 'budget'},
        {field: PROJECT_FIELD.status, translateKey: 'table_status', filterType: DATAGRID_FILTER_TYPE.CUSTOM},
        {
            field: 'managers',
            filterType: DATAGRID_FILTER_TYPE.SELECT,
            translateKey: 'project_manager',
            propertyGetter: ({managers}: IProjectDash) =>
                (managers || []).map(({employee}) => (employee && employee.user && employee.user.name) || '').join(' '),
            sorter: {compare: (p1, p2) => sortFnArrayWrapper(PROJECT_MEMBER_SORT_FUNCTION)(p1.managers, p2.managers)},
        },
        {field: 'roleType', translateKey: 'my_role', filterType: DATAGRID_FILTER_TYPE.CUSTOM, hidden: true},
    ];
}
