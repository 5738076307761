<div class="container">
    <h4>Contacts</h4>
    <div class="clr-row clr-col-12">
        <div class="column clr-col-3 pt-2 pb-2" *ngFor="let contact of portalData?.data?.contacts">
            <div class="panel">
                <div class="panel-header text-center">
                    <button class="btn float-right" (click)="mailTo(contact.employee?.user.email)">
                        <i class="far fa-envelope"></i>
                    </button>

                    <app-long-avatar [code]="contact.employee?.user.code" [bgColor]="contact.employee?.user.color">
                    </app-long-avatar>
                    <div class="panel-title h5">{{ contact.employee.user.name }}</div>
                    <div class="card-body text-center">
                        <p class="mt-0 mb-0 text-ellipsis">
                            <a target="_blank" [href]="'mailto:' + contact.employee?.user.email">{{
                                contact.employee?.user.email || '&nbsp;'
                            }}</a>
                        </p>
                        <p class="mt-0 mb-0 ">
                            <a
                                class="mt-0 mb-0 "
                                *ngIf="contact.employee?.phoneNumber"
                                target="_blank"
                                href="tel:{{
                                    formatNumber(contact.employee?.phoneNumber.substr(1), 'FR', 'International')
                                }}"
                                >{{ formatNumber(contact.employee?.phoneNumber.substr(1), 'FR', 'International') }}</a
                            >
                        </p>
                        <p class="mt-0 mb-0 ">
                            <a
                                class="mt-0 mb-0 "
                                target="_blank"
                                href="tel:{{
                                    formatNumber(contact.employee?.mobileNumber.substr(1), 'FR', 'International')
                                }}"
                                *ngIf="contact.employee?.mobileNumber"
                            >
                                {{ formatNumber(contact.employee?.mobileNumber.substr(1), 'FR', 'International') }}</a
                            >
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
