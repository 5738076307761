import {Component, Input} from '@angular/core';
import {IUser} from '../../../../../defs/schema/public/Users';
import {isColorDark} from '../../app-static';

export enum LONG_AVATAR_TYPE {
    LABEL = 'LABEL',
    BADGE = 'BADGE',
    BADGE_100 = 'BADGE_100',
    LABEL_ROUND = 'LABEL_ROUND',
}

@Component({
    selector: 'app-long-avatar',
    templateUrl: './long-avatar.component.html',
    styleUrls: ['./long-avatar.component.scss'],
})
export class LongAvatarComponent {
    @Input() public type: LONG_AVATAR_TYPE = LONG_AVATAR_TYPE.LABEL;

    @Input() public code: string;
    @Input() public verticalAlign = false;
    @Input() public bgColor: string;
    @Input() public user: Partial<IUser>;
    @Input() public hidden: boolean;
    @Input() public strokedWhite = false;
    @Input() public strokedBlack = false;

    @Input() public title = '';

    public readonly isColorDark = isColorDark;
    public readonly LONG_AVATAR_TYPE = LONG_AVATAR_TYPE;
}
