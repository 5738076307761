import {Injectable} from '@angular/core';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import moment from 'moment';

@Injectable({
    providedIn: 'root',
})
export class MomentService {
    public constructor(private readonly translate: TranslateService) {
        moment.locale(this.translate.currentLang);
        this.translate.onLangChange.subscribe(async (event: LangChangeEvent) => {
            moment.locale(event.lang);
        });
    }

    public moment = moment;
}
