<clr-datagrid
    appDatagridToggle
    class="flex-1"
    [clrDgSelected]="selectedProjects"
    (clrDgSelectedChange)="selectedProjectsChange.emit((selectedProjects = $event))"
    [clrDgLoading]="!projects"
    [clrDgRowSelection]="clrDgRowSelection"
>
    <clr-dg-action-bar *ngIf="layout === PROJECT_LIST_LAYOUT.ADD_DELETE">
        <div class="btn-group">
            <button
                [title]="('shortcut_' | translate) + ' C'"
                class="btn {{ actionBarButtonClassList }} {{ addButtonClassList }}"
                (click)="showCreateModal = true"
                *sharedAccessControl="RIGHTS.PJ_CREATE"
            >
                {{ 'add_project' | translate }}
            </button>
        </div>

        <div class="btn-group">
            <button
                class="btn btn-danger {{ actionBarButtonClassList }}"
                (click)="showDeleteModal = true"
                *sharedAccessControl="RIGHTS.PJ_DELETE"
                [disabled]="!selectedProjects.length"
            >
                {{ (selectedProjects.length > 1 ? 'delete_projects' : 'delete_project') | translate }}
            </button>
        </div>
    </clr-dg-action-bar>

    <clr-dg-column
        *ngFor="let column of columns"
        [clrDgField]="column.field"
        [clrDgSortOrder]="column.order"
        [clrDgSortBy]="column.sorter"
    >
        <ng-container *ngIf="column.hideable === false; else hideableColumn">
            {{ column.translateKey | translate }}
        </ng-container>
        <ng-template #hideableColumn>
            <ng-container *clrDgHideableColumn="{hidden: column.hidden === true}">
                {{ column.translateKey | translate }}
            </ng-container>
        </ng-template>

        <clr-dg-filter
            *ngIf="!column.filterType || column.filterType === DATAGRID_FILTER_TYPE.FUZZY"
            [clrDgFilter]="fuzzyFilter"
            [(clrDgFilterOpen)]="filterOpen[column.field]"
        >
            <shared-fuzzy-datagrid-filter
                [filterKey]="column.field"
                [filterOpen]="filterOpen[column.field]"
                [propertyGetter]="column.propertyGetter"
                #fuzzyFilter
            ></shared-fuzzy-datagrid-filter>
        </clr-dg-filter>

        <clr-dg-filter *ngIf="column.field === PROJECT_FIELD.status" [clrDgFilter]="statusFilter">
            <shared-generic-datagrid-filter
                [filter]="PROJECT_STATUS_FILTER"
                [filterKey]="column.field"
                [unfilteredEntities]="projects"
                #statusFilter
            ></shared-generic-datagrid-filter>
        </clr-dg-filter>

        <clr-dg-filter *ngIf="column.field === 'roleType'" [clrDgFilter]="roleTypeFilter">
            <shared-generic-datagrid-filter
                [filter]="PROJECT_ROLE_TYPE_FILTER"
                [filterKey]="column.field"
                [unfilteredEntities]="projects"
                #roleTypeFilter
            ></shared-generic-datagrid-filter>
        </clr-dg-filter>

        <clr-dg-filter
            [clrDgFilter]="selectFilter"
            *ngIf="
                column.filterType &&
                column.filterType === DATAGRID_FILTER_TYPE.SELECT &&
                column.field === 'client.user.name'
            "
            [(clrDgFilterOpen)]="filterOpen[column.field]"
        >
            <shared-select-datagrid-filter
                [emps]="clients"
                [filterKey]="column.field"
                [type]="SelectFilterType.CLIENT"
                [propertyGetter]="column.propertyGetter"
                [filterOpen]="filterOpen[column.field]"
                #selectFilter
            ></shared-select-datagrid-filter>
        </clr-dg-filter>

        <clr-dg-filter
            [clrDgFilter]="selectFilter"
            *ngIf="
                column.filterType && column.filterType === DATAGRID_FILTER_TYPE.SELECT && column.field === 'managers'
            "
            [(clrDgFilterOpen)]="filterOpen[column.field]"
        >
            <shared-select-datagrid-filter
                [emps]="managers"
                [filterKey]="column.field"
                [type]="SelectFilterType.MANAGER"
                [propertyGetter]="column.propertyGetter"
                [filterOpen]="filterOpen[column.field]"
                #selectFilter
            ></shared-select-datagrid-filter>
        </clr-dg-filter>
    </clr-dg-column>

    <clr-dg-row *clrDgItems="let project of projects" [clrDgItem]="project">
        <clr-dg-cell>
            <a *ngIf="!clrDgRowSelection; else projectWithoutLink" [routerLink]="['/project', project.id]">
                {{ project.obs }}
            </a>
            <ng-template #projectWithoutLink>
                {{ project.obs }}
            </ng-template>

            <app-long-avatar
                [code]="project.code"
                [bgColor]="project.color"
                [type]="LONG_AVATAR_TYPE.BADGE_100"
            ></app-long-avatar>
        </clr-dg-cell>
        <clr-dg-cell>
            <ng-container *ngIf="project.client && project.client.user">
                <a *ngIf="!clrDgRowSelection; else clientWithoutLink" [routerLink]="['/client', project.client.id]">
                    {{ project.client.user.name }}
                </a>
                <ng-template #clientWithoutLink>
                    {{ project.client.user.name }}
                </ng-template>

                <app-long-avatar
                    [type]="LONG_AVATAR_TYPE.BADGE_100"
                    [code]="project.client.user.code"
                    [bgColor]="project.client.user.color"
                ></app-long-avatar>
            </ng-container>
        </clr-dg-cell>
        <clr-dg-cell>{{ project.budgetSum | currency: 'EUR' }}</clr-dg-cell>
        <clr-dg-cell>
            <span [attr.class]="PROJECT_STATUS_FILTER[project.status].classList">
                <clr-icon [attr.shape]="PROJECT_STATUS_FILTER[project.status].icon"></clr-icon>
                {{ PROJECT_STATUS_FILTER[project.status].translation | translate }}
            </span>
        </clr-dg-cell>
        <clr-dg-cell>
            <ng-container *ngIf="project.managers && project.managers.length > 1; else managerList">
                {{ 'project_manager_count' | translate: {count: project.managers.length} }}
                <clr-signpost>
                    <clr-signpost-content [clrPosition]="'bottom-left'" *clrIfOpen>
                        <h3 class="mt-0" translate>project_managers</h3>
                        <ul class="list mt-05">
                            <ng-container *ngIf="false; else managerList"></ng-container>
                        </ul>
                    </clr-signpost-content>
                </clr-signpost>
            </ng-container>

            <ng-template #managerList>
                <li *ngFor="let manager of project.managers">
                    <ng-container *ngIf="manager.employee && manager.employee.user">
                        <a [routerLink]="['/employee', manager.employee.id]">{{ manager.employee.user.name }}</a>
                        <app-long-avatar
                            class="ml-02"
                            [type]="LONG_AVATAR_TYPE.BADGE_100"
                            [code]="manager.employee.user.code"
                            [bgColor]="manager.employee.user.color"
                        ></app-long-avatar>
                    </ng-container>
                </li>
            </ng-template>
        </clr-dg-cell>
        <clr-dg-cell>
            <span [attr.class]="PROJECT_ROLE_TYPE_FILTER[project.roleType]?.classList">
                {{ PROJECT_ROLE_TYPE_FILTER[project.roleType]?.translation | translate }}
            </span>
        </clr-dg-cell>
    </clr-dg-row>

    <clr-dg-footer>
        <clr-dg-column-toggle>
            <clr-dg-column-toggle-title>{{ 'projects_columns' | translate }}</clr-dg-column-toggle-title>
            <clr-dg-column-toggle-button>{{ 'show_all_columns' | translate }}</clr-dg-column-toggle-button>
        </clr-dg-column-toggle>

        <span *ngIf="projects">{{ 'count_projects' | translate: {count: projects.length} }}</span>
    </clr-dg-footer>
</clr-datagrid>

<ng-container *ngIf="layout === PROJECT_LIST_LAYOUT.ADD_DELETE">
    <app-project-wizard
        [clrWizardOpen]="showCreateModal"
        (clrWizardOpenChange)="showCreateModalChange.emit((showCreateModal = $event))"
        (finish)="addNewProject($event)"
        [clientId]="wizardClientId"
        [clients]="wizardClients"
        *sharedAccessControl="RIGHTS.PJ_CREATE"
    ></app-project-wizard>

    <app-modal [(show)]="showDeleteModal" [title]="'project_delete_modal_title' | translate">
        <ng-template appModalBody>
            <p>
                {{
                    'swal_delete_attch'
                        | translate
                            : {
                                  what:
                                      selectedProjects.length > 1
                                          ? ('count_projects' | translate: {count: selectedProjects.length})
                                          : selectedProjects[0].obs
                              }
                }}
            </p>
        </ng-template>

        <ng-template appModalFooter>
            <button
                class="btn btn-secondary"
                (click)="showDeleteModal = false"
                [sharedAutofocus]="showDeleteModal"
                translate
            >
                form_cancel
            </button>
            <button class="btn btn-danger" (click)="deleteSelectedProjects()">
                {{ (selectedProjects.length > 1 ? 'delete_projects' : 'delete_project') | translate }}
            </button>
        </ng-template>
    </app-modal>
</ng-container>
