import {AbstractControl, ValidatorFn} from '@angular/forms';
import semVer from 'semver';

export const semVerValidator = (): ValidatorFn => {
    return (control: AbstractControl): {[key: string]: any} | null => {
        const valid = semVer.valid(control.value);

        return valid ? null : {validSemVer: {value: control.value}};
    };
};
