import {Component, Input} from '@angular/core';
import {AssignedEmployeeType, TASK_STATUS_LABEL} from '../../../../defs/schema-static';
import {IDashTask} from '../dashboard/dashboard.component';

@Component({
    selector: 'app-dashboard-task',
    templateUrl: './dashboard-task.component.html',
    styleUrls: ['./dashboard-task.component.scss'],
})
export class DashboardTaskComponent {
    @Input() public task: IDashTask;

    public isReviewer = (task: IDashTask) => {
        return task.assigned.filter((a) => a.type === AssignedEmployeeType.REVIEWER).length > 0;
    };

    public readonly _TASK_STATUS_LABEL = TASK_STATUS_LABEL;
}
