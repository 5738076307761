var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { AfterContentInit, ElementRef, EventEmitter, OnDestroy, OnInit, QueryList, TemplateRef, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isColorDark } from '../app-static';
import { TabComponent } from './tab/tab.component';
var TabsComponent = (function () {
    function TabsComponent(router, route) {
        this.router = router;
        this.route = route;
        this.labels = {};
        this.sublime = false;
        this.activeTabChange = new EventEmitter();
        this.clicked = new EventEmitter();
        this.isColorDark = isColorDark;
    }
    TabsComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (!this.queryParamKey) {
            return;
        }
        this.queryParamsSubscription = this.route.queryParams.subscribe(function (queryParams) {
            _this.queryParam = queryParams[_this.queryParamKey];
            _this.selectTab(_this.getQueryParamTab());
        });
    };
    TabsComponent.prototype.ngAfterContentInit = function () {
        var _this = this;
        requestAnimationFrame(function () {
            if (_this._tabs.length && !_this.getActiveTab()) {
                _this.selectTab(_this.getQueryParamTab() || _this._tabs.find(function (tab) { return !tab.disabled; }), true);
            }
        });
    };
    TabsComponent.prototype.ngOnDestroy = function () {
        if (this.queryParamsSubscription) {
            this.queryParamsSubscription.unsubscribe();
            this.queryParamsSubscription = null;
        }
    };
    TabsComponent.prototype.selectTabFromName = function (name) {
        var tab = this._tabs.find(function (t) { return t.name.toString() === name; });
        this.selectTab(tab);
    };
    TabsComponent.prototype.selectTab = function (tab, replaceUrl) {
        var _this = this;
        if (replaceUrl === void 0) { replaceUrl = false; }
        if (!tab || tab.isActive || tab.justAButton) {
            return;
        }
        this._tabs.map(function (_tab) { return (_tab.isActive = false); });
        tab.isActive = true;
        this.scrollToTab(tab);
        (function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
            return [2, this.setQueryParamsFromTab(replaceUrl)];
        }); }); })();
        this.activeTabChange.emit((this.activeTab = tab));
    };
    TabsComponent.prototype.scrollToTab = function (tab) {
        var $tabsContainer = this.tabsContainer.nativeElement;
        if (!$tabsContainer || $tabsContainer.scrollWidth < $tabsContainer.offsetWidth) {
            return;
        }
        requestAnimationFrame(function () {
            var $activeTab = $tabsContainer.querySelector(".tab[data-tab=\"" + tab.name + "\"]");
            if (!$activeTab) {
                return;
            }
            var offsetLeft = $activeTab.offsetLeft;
            var offsetRight = $activeTab.offsetLeft + $activeTab.offsetWidth;
            var visibleLeft = $tabsContainer.scrollLeft;
            var visibleRight = visibleLeft + $tabsContainer.offsetWidth;
            if (offsetLeft < visibleLeft) {
                $tabsContainer.scrollLeft = offsetLeft;
            }
            else if (offsetRight > visibleRight) {
                $tabsContainer.scrollLeft = offsetRight - $tabsContainer.offsetWidth;
            }
        });
    };
    TabsComponent.prototype.getActiveTab = function () {
        if (!this._tabs) {
            return undefined;
        }
        return this._tabs.find(function (_tab) { return _tab.isActive; });
    };
    TabsComponent.prototype.getQueryParamTab = function () {
        var _this = this;
        if (!this._tabs || !this.queryParam) {
            return undefined;
        }
        return this._tabs.find(function (tab) { return tab.name === _this.queryParam; });
    };
    TabsComponent.prototype.setQueryParamsFromTab = function (replaceUrl) {
        if (replaceUrl === void 0) { replaceUrl = false; }
        return __awaiter(this, void 0, void 0, function () {
            var _a, activeTab, queryParams;
            return __generator(this, function (_b) {
                activeTab = this.getActiveTab();
                if (!this.queryParamKey || !activeTab) {
                    return [2, undefined];
                }
                queryParams = this.router.parseUrl(this.router.url).queryParams;
                if (queryParams[this.queryParamKey] === activeTab.name) {
                    return [2, undefined];
                }
                Object.assign(queryParams, __assign((_a = {}, _a[this.queryParamKey] = activeTab.name, _a), (activeTab.queryParams || {})));
                return [2, this.router.navigate([], {
                        replaceUrl: replaceUrl,
                        queryParams: queryParams,
                        queryParamsHandling: 'merge',
                    })];
            });
        });
    };
    return TabsComponent;
}());
export { TabsComponent };
