import {Component} from '@angular/core';
import {ClrDatagridComparatorInterface, ClrDatagridSortOrder} from '@clr/angular';
import moment from 'moment';
import {default as slugify} from 'slugify';
import {IProjectDash} from '../../../defs/businessRules';
import publicConfig from '../../../defs/config/config.json';
import {
    ALL_RIGHTS,
    BudgetStatus,
    MilestonesType,
    MimeTypes,
    ProjectStatusType,
    ReleaseStateType,
    RIGHTS,
    TaskStatusType,
    WikiPageType,
} from '../../../defs/schema-static';
import {IEmployee} from '../../../defs/schema/public/Employees';
import {IMilestone, MILESTONE_FIELD} from '../../../defs/schema/public/Milestones';
import {IProject} from '../../../defs/schema/public/Projects';
import {ITask, TASK_FIELD} from '../../../defs/schema/public/Tasks';
import {TASK_SORT_FUNCTIONS} from '../../../defs/sorters';

export interface IDashNumber {
    title: string;
    subtitle: string;
    color: string;
    value?: number;
    icon?: string;
    progress?: number;
    textAlign?: string;
    size?: string;
}

export interface Idough {
    labels: string[];
    data: number[];
    title: string;
}

export interface IBars {
    title: string;
    data: number[];
}

export interface IDashProject {
    id: number;
    name: string;
    client: string;
    duration: string;
    remaining: string;
    deltaCosts: number;
    completed: number;
    status: string;
}

export interface IDashEvent {
    avatar: string;
    title: string;
    subtitle: string;
    id: number;
    begin: moment.Moment;
    end: moment.Moment;
    type: string;
    color: string;
}

export interface IAvgTimeStatus {
    timeSpent: number;
    status: TaskStatusType;
}

export interface ICount {
    count: number;
}

export interface IStats {
    project?: IProject;
    taskCostEstimated?: any;
    taskCostReal?: any;
    projects?: IProjectDash[];
    employees?: IEmployee[];
    milestones?: IMilestone[];
    totalProjects?: number;
    waitingTimeByStatus?: IAvgTimeStatus;
    taskCreated?: any;
    taskComplete?: any;
    taskResolution?: any;
    calendarData?: any;
    costRepartition?: any;
    releasesDetail?: any;
    taskRepartition?: any;
    taskType?: any;
    projectCost?: any;
    projectsDetails?: any;
    projectsEstimation?: any;
    projectsRemaining?: any;
    timeSpentMilestones?: any;
    duplicateTimeline?: any;
    devVsReview?: any;
    costoverdays?: any;
    overdue?: any;
    timeLost?: any;
    estimatedRemaining?: any;
    cardData?: any;
    projectDivision?: any;
    budgets?: any;
    doneWorkload?: any;
    activity?: any;
}

export interface ISequelizeErrorReasons {
    type: SequelizeErrorType;
    table: string;
    foreignTable: string;
    fieldConcerned: string;
}

export enum SequelizeErrorType {
    INSERT = 'INSERT',
    UPDATE = 'UPDATE',
}

export enum SelectFilterType {
    CLIENT = 'CLIENT',
    CONTACT = 'CONTACT',
    EMPLOYEE = 'EMPLOYEE',
    PROJECT = 'PROJECT',
    MANAGER = 'MANAGER',
}

export interface ILoadableComponent extends Component {
    load(): void;
}

// enum
export enum APP_MODULE_ROUTE_PATH {
    APP = '',
    ADMIN = 'admin',
    STATS = 'stats',
    GANTT = 'gantt',
    WORKLOADTOOLS = 'workloadtools',
    CALENDAR = 'calendar',
    CLIENTS = 'clients',
    CLIENT = 'client',
    PROJECTS = 'projects',
    PROJECT = 'project',
    EMPLOYEES = 'employees',
    EMPLOYEE = 'employee',
    FIRON = 'firon',
    TODO = 'todo',
    NOTFOUND = 'notfound',
    REPORTS = 'reports',
    DASHBOARD = 'dashboard',
}

export enum AUTH_MODULE_ROUTE_PATH {
    AUTH = 'auth',
    LOG_IN = 'login',
    LOG_OUT = 'logout',
    /*SIGN_UP = 'signup',*/
    UNAUTHORIZED = 'unauthorized',
}

export enum IDEABOARD_MODULE_ROUTE_PATH {
    BOARD = 'board',
}

export enum WIKI_MODULE_ROUTE_PATH {
    WIKI = 'wiki',
    LIST = 'list',
    PAGE = 'page',
    EDIT = 'edit',
    ATTACHMENTS = 'attachments',
    HISTORY = 'history',
}

export enum PORTAL_MODULE_ROUTE_PATH {
    DASHBOARD = 'portal',
    REQUESTS = 'requests',
    INCIDENTS = 'incidents',
    CHANGELOGS = 'changelogs',
    CONTACTS = 'contacts',
}

export enum PROJECT_TABS {
    MILESTONES = 'milestones',
    BUDGETS = 'budgets',
    TASKS = 'tasks',
    RELEASES = 'releases',
    BACKUPS = 'backups',
    PARAMS = 'params',
    NOTES = 'notes',
}

export const APP_DEFAULT_ROUTE = `/${AUTH_MODULE_ROUTE_PATH.AUTH}/${AUTH_MODULE_ROUTE_PATH.LOG_IN}`;

export const PROJECT_TABS_LABELS: {[tab in PROJECT_TABS]: string} = {
    [PROJECT_TABS.MILESTONES]: 'Milestones',
    [PROJECT_TABS.BUDGETS]: 'Budgets',
    [PROJECT_TABS.TASKS]: 'Tasks',
    [PROJECT_TABS.RELEASES]: 'Releases',
    [PROJECT_TABS.BACKUPS]: 'Backups',
    [PROJECT_TABS.PARAMS]: 'Params',
    [PROJECT_TABS.NOTES]: 'Notes',
};

export const PROJECT_TABS_RIGHTS: {[tab in PROJECT_TABS]?: RIGHTS | RIGHTS[]} = {
    [PROJECT_TABS.MILESTONES]: RIGHTS.PROJECT_TAB_MS,
    [PROJECT_TABS.BUDGETS]: RIGHTS.PROJECT_TAB_BG,
    [PROJECT_TABS.TASKS]: RIGHTS.PROJECT_TAB_TK,
    [PROJECT_TABS.RELEASES]: RIGHTS.PROJECT_TAB_RL,
    [PROJECT_TABS.BACKUPS]: RIGHTS.PROJECT_TAB_BK,
    [PROJECT_TABS.PARAMS]: [RIGHTS.PROJECT_TAB_PR, RIGHTS.TAG_CREATE],
};

export enum CLIENT_TABS {
    MILESTONES = 'milestones',
    PROJECTS = 'projects',
    TASKS = 'tasks',
    BACKUPS = 'backups',
    REQUESTS = 'requests',
    INCIDENTS = 'incidents',
    CHANGELOGS = 'changelogs',
    NOTES = 'notes',
}

export const CLIENT_TABS_LABELS: {[tab in CLIENT_TABS]: string} = {
    [CLIENT_TABS.MILESTONES]: 'Milestones',
    [CLIENT_TABS.PROJECTS]: 'Projects',
    [CLIENT_TABS.TASKS]: 'Tasks',
    [CLIENT_TABS.BACKUPS]: 'Backups',
    [CLIENT_TABS.REQUESTS]: 'Requests',
    [CLIENT_TABS.INCIDENTS]: 'Incidents',
    [CLIENT_TABS.CHANGELOGS]: 'Changelogs',
    [CLIENT_TABS.NOTES]: 'Notes',
};

export const CLIENT_TABS_RIGHTS: {[tab in CLIENT_TABS]?: RIGHTS} = {
    [CLIENT_TABS.MILESTONES]: RIGHTS.CLIENT_TAB_MS,
    [CLIENT_TABS.PROJECTS]: RIGHTS.CLIENT_TAB_PJ,
    [CLIENT_TABS.BACKUPS]: RIGHTS.CLIENT_TAB_BK,
    [CLIENT_TABS.REQUESTS]: RIGHTS.CLIENT_TAB_RQ,
    [CLIENT_TABS.INCIDENTS]: RIGHTS.CLIENT_TAB_IN,
    [CLIENT_TABS.CHANGELOGS]: RIGHTS.CLIENT_TAB_CH,
};

export enum REPORT_TABS {
    STATS = 'stats',
    PROJECT = 'project',
    CLIENT = 'client',
    EMPLOYEE = 'employee',
    TASKS = 'tasks',
}

export const REPORT_TABS_LABELS: {[tab in REPORT_TABS]: string} = {
    [REPORT_TABS.STATS]: 'Stats',
    [REPORT_TABS.PROJECT]: 'Project',
    [REPORT_TABS.CLIENT]: 'Client',
    [REPORT_TABS.EMPLOYEE]: 'Employee',
    [REPORT_TABS.TASKS]: 'Tasks',
};

export enum ADMIN_PANEL_TABS {
    MAIN = 'main',
    CLIENTS = 'clients',
    TRANSLATIONS = 'i18n',
    ACCESS_CONTROL = 'access',
}

export const ADMIN_PANEL_TABS_LABELS: {[tab in ADMIN_PANEL_TABS]: string} = {
    [ADMIN_PANEL_TABS.MAIN]: 'main',
    [ADMIN_PANEL_TABS.CLIENTS]: 'clients',
    [ADMIN_PANEL_TABS.TRANSLATIONS]: 'i18n',
    [ADMIN_PANEL_TABS.ACCESS_CONTROL]: 'access',
};

export const DEFAULT_PAGE_TEMPLATE: {[key in WikiPageType]: string} = {
    [WikiPageType.PAGE]: '',
    [WikiPageType.MEETING_NOTE]: 'Participants : ',
    [WikiPageType.TECHNICAL_NOTE]: '',
    [WikiPageType.CHANGELOG]: 'Changelog',
};

export const TASK_STATUS_FILTER: IDatagridFilterMap<TaskStatusType> = {
    [TaskStatusType.BACKLOG]: {
        translation: 'backlog',
        classList: 'status-backlog',
        icon: 'folder',
        color: '#70acb5',
    },
    [TaskStatusType.TODO]: {
        translation: 'todo',
        classList: 'status-todo',
        icon: 'ellipsis-horizontal',
        color: '#61c84e',
    },
    [TaskStatusType.INPROGRESS]: {
        translation: 'inprogress',
        classList: 'status-inprogress',
        icon: 'note',
        color: '#ffba10',
    },
    [TaskStatusType.REVIEW]: {
        translation: 'review',
        classList: 'status-review',
        icon: 'clipboard',
        color: '#ff4c00',
    },
    [TaskStatusType.REVIEWING]: {
        translation: 'reviewing',
        classList: 'status-reviewing',
        icon: 'tasks',
        color: '#9d5973',
    },
    [TaskStatusType.DONE]: {
        translation: 'done',
        classList: 'status-done',
        icon: 'check',
        color: '#21bdd5',
    },
};

export const API_HTTP_ROOT = publicConfig.server.httpsPort
    ? `https://${publicConfig.server.apiDomain}:${publicConfig.server.httpsPort}` + `${publicConfig.server.apiPath}`
    : `http://${publicConfig.server.apiDomain}:${publicConfig.server.httpPort}` + `${publicConfig.server.apiPath}`;

export const API_VERSION = publicConfig.server.apiVersion;

export const trackByIndex = (index: number) => index;

// tslint:disable-next-line no-empty
export const noop = () => {};

export const containsSlug = (value: string, search: string): boolean =>
    slugify(value.toLocaleLowerCase()).indexOf(slugify(search.toLocaleLowerCase())) !== -1;

export const equalsSlug = (value: string, search: string): boolean =>
    slugify(value.toLocaleLowerCase()) === slugify(search.toLocaleLowerCase());

export const normalize = (val: number, max: number, min: number) => {
    if (max - min === 0) {
        return 0;
    }

    return (val - min) / (max - min) || 0;
};

export const formatTime = (date: string) => {
    if (!date) {
        return '';
    }
    const mDate = moment(date);
    if (!mDate.isValid()) {
        return '';
    }
    const time = mDate.format('HH:mm');
    if (time === '00:00') {
        return '';
    }

    return time;
};

export const isColorDark = (color: string) => {
    const hexToRgb = (hex: string) => {
        if (!hex) {
            return null;
        }
        const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;

        hex = hex.replace(shorthandRegex, (m, r: string, g: string, b: string) => r + r + g + g + b + b);

        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

        return result
            ? {
                  r: parseInt(result[1], 16),
                  g: parseInt(result[2], 16),
                  b: parseInt(result[3], 16),
              }
            : null;
    };

    const rgb = hexToRgb(color);
    if (!rgb) {
        return true;
    }

    const o = Math.round((rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000);

    return o >= 145;
};

export const sortNumbers = (a: number, b: number): number => {
    return a - b;
};

export const sortStrings = (a: string, b: string): number => {
    return a.localeCompare(b);
};

export const sortDates = (a: string | moment.Moment, b: string | moment.Moment): number => {
    return moment(a).isSameOrBefore(moment(b)) ? -1 : 1;
};

export const getSequelizeErrorReasons = (stack: string): ISequelizeErrorReasons => {
    const err = stack.split('\n')[0];

    const NEW_ROW_ERROR = 'SequelizeDatabaseError: new row for relation';
    const CONSTRAINT_VIOLATION = 'violates check constraint';
    const posNewRowError = err.indexOf(NEW_ROW_ERROR);

    if (posNewRowError > -1) {
        const posViolation = err.indexOf(CONSTRAINT_VIOLATION);

        const tableConcerned = err
            .substring(NEW_ROW_ERROR.length, posViolation)
            .replace(/"/g, '')
            .trim();

        return {
            type: SequelizeErrorType.INSERT,
            fieldConcerned: '',
            foreignTable: '',
            table: tableConcerned,
        };
    }

    const FOREIGN_KEY_ERROR = 'SequelizeForeignKeyConstraintError: update or delete on table';
    const CONSTRAINT_VIOLATION_FK = 'violates foreign key constraint';
    const ON_TABLE = 'on table';

    const posConstraintError = err.indexOf(CONSTRAINT_VIOLATION_FK);
    const posTargetTable = err.indexOf(ON_TABLE, posConstraintError);

    const firstTable = err
        .substring(FOREIGN_KEY_ERROR.length, posConstraintError)
        .replace(/"/g, '')
        .trim();
    const secondTable = err
        .substr(posTargetTable + ON_TABLE.length, err.length)
        .replace(/"/g, '')
        .trim();

    const violation = err
        .substr(CONSTRAINT_VIOLATION_FK.length + posConstraintError, err.length - posTargetTable)
        .replace(/"/g, '')
        .trim();
    const field = violation.substr(violation.indexOf('_') + 1);

    return {
        type: SequelizeErrorType.UPDATE,
        fieldConcerned: field,
        foreignTable: secondTable,
        table: firstTable,
    };
};

export const getMilestoneIcon = (eventType: MilestonesType) => {
    switch (eventType) {
        case MilestonesType.DEADLINE:
            return 'calendar-times';
        case MilestonesType.MEETING:
        case MilestonesType.MEETING_NO_NOTE:
            return 'users';
        case MilestonesType.CALL:
            return 'phone';
        case MilestonesType.REMINDER:
            return 'bell';
        case MilestonesType.RELEASE:
            return 'calendar-check';
        default:
            return null;
            break;
    }

    return '';
};

export const MAX_LENGTH_PROJECT_CODE = 5;
export const MAX_LENGTH_CLIENT_CODE = 5;
export const MAX_LENGTH_EMP_CODE = 3;

export interface IColorDomain {
    domain: string[];
}

export const chartMainColor: IColorDomain = {
    domain: [
        '#009CBF',
        '#00BFA9',
        '#62A420',
        '#FAC400',
        '#F57600',
        '#F54F47',
        '#ED186F',
        '#9B56BB',
        '#6870C4',
        '#0095D3',

        '#FF681C',
        '#A89E95',
        '#798893',
    ],
};

export const calendarMainColor: IColorDomain = {
    domain: [
        '#b0dde1',
        '#8bc9d2',
        '#64cad9',
        '#36C9E1',
        '#00B7D6',
        '#009CBF',
        '#0081A7',
        '#006690',
        '#005680',
        '#004A70',
    ],
};

export const getRandomColorHex = () => {
    // tslint:disable-next-line
    return `#${(Math.random().toString(16) + '000000').slice(2, 8)}`;
};

export enum DATAGRID_FILTER_TYPE {
    FUZZY = 'FUZZY',
    DATE = 'DATE',
    CUSTOM = 'CUSTOM',
    SELECT = 'SELECT',
}

export type FuzzyDatagridFilterPropertyGetter<T> = (entity: T) => string;

export interface IDatagridColumn<T = any> {
    field?: string;
    name?: string;
    translateKey?: string;
    order?: ClrDatagridSortOrder;
    right?: ALL_RIGHTS;
    sorter?: ClrDatagridComparatorInterface<T>;
    hidden?: boolean;
    width?: string;
    hideable?: boolean;
    filterType?: DATAGRID_FILTER_TYPE;
    propertyGetter?: FuzzyDatagridFilterPropertyGetter<T>;
}
export type IDatagridColumns<T = any> = IDatagridColumn<T>[];

export interface IDatagridFilter {
    value?: string | number;
    translation?: string;
    classList?: string | string[];
    icon?: string;
    color?: string;
}

export type IDatagridFilterMap<T extends string | number | symbol = any> = {[key in T]: IDatagridFilter};

export interface ISerializableDatagridFilter {
    filterKey: string;
    serialize(): string[];
}

export const sortFnArrayWrapper = <T>(sortFn: (e1: T, e2: T) => number): (e1: T[], e2: T[]) => number => (
    e1: T[],
    e2: T[]
) => {
    const [a1, a2] = [e1, e2].map((entity) => (Array.isArray(entity) ? entity : [entity])) as [T[], T[]];
    if (a1.length !== a2.length) {
        return a1.length > a2.length ? -1 : 1;
    }

    const [m1, m2] = [a1, a2].map((array) => array.sort(sortFn)[0]);

    return typeof m1 === 'undefined' ? -1 : typeof m2 === 'undefined' ? 1 : sortFn(m1, m2);
};

export const BUDGET_STATUS_FILTER: IDatagridFilterMap<BudgetStatus> = {
    [BudgetStatus.ESTIMATED]: {
        classList: 'text-budget-estimated',
        icon: 'unknown-status',
        translation: 'budget_estimated',
    },
    [BudgetStatus.BILLED]: {
        classList: 'text-budget-billed',
        icon: 'check',
        translation: 'budget_billed',
    },
    [BudgetStatus.CANCELLED]: {
        classList: 'text-budget-cancelled',
        icon: 'times',
        translation: 'budget_cancelled',
    },
};

export const MILESTONE_TYPE_FILTER: IDatagridFilterMap<MilestonesType> = {
    [MilestonesType.MEETING]: {
        classList: 'text-milestone-meeting',
        icon: 'users',
        translation: 'milestone_meeting',
        color: '#21bdd5',
    },
    [MilestonesType.MEETING_NO_NOTE]: {
        classList: 'text-milestone-meeting',
        icon: 'users',
        translation: 'milestone_meeting_no_note',
        color: '#21bdd5',
    },
    [MilestonesType.DEADLINE]: {
        classList: 'text-milestone-deadline',
        icon: 'step-forward-2',
        translation: 'milestone_deadline',
        color: '#ff4136',
    },
    [MilestonesType.REMINDER]: {
        classList: 'text-milestone-reminder',
        icon: 'bell',
        translation: 'milestone_reminder',
        color: '#ffba10',
    },
    [MilestonesType.RELEASE]: {
        classList: 'text-milestone-release',
        icon: 'checkbox-list',
        translation: 'milestone_release',
        color: '#ff4c00',
    },
    [MilestonesType.CALL]: {
        classList: 'text-milestone-call',
        icon: 'phone-handset',
        translation: 'milestone_call',
        color: '#36b358',
    },
};

export interface IFilterableEntity {
    [key: string]: string | number;
}

export const getGroupDisplayName = (name: string) => {
    return (!name && '') || name.substr(0, 1) === '_' ? name.substr(1) : name;
};

export interface IResettable {
    reset(): void;
}

export const PROJECT_STATUS_FILTER: IDatagridFilterMap<ProjectStatusType> = {
    [ProjectStatusType.OPEN]: {
        classList: 'text-success',
        icon: 'play',
        translation: 'project_open',
    },
    [ProjectStatusType.WAITING]: {
        classList: 'text-warning',
        icon: 'pause',
        translation: 'project_waiting',
    },
    [ProjectStatusType.CLOSE]: {
        classList: 'text-danger',
        icon: 'stop',
        translation: 'project_close',
    },
};

export const WIKI_PAGE_TYPE_FILTER: IDatagridFilterMap<WikiPageType> = {
    [WikiPageType.PAGE]: {icon: 'file', translation: 'PAGE'},
    [WikiPageType.MEETING_NOTE]: {icon: 'users', translation: 'meeting_note'},
    [WikiPageType.TECHNICAL_NOTE]: {icon: 'cog', translation: 'technical_note'},
    [WikiPageType.CHANGELOG]: {icon: 'code', translation: 'changelog'},
};

export const RELEASE_STATE_FILTER: IDatagridFilterMap<ReleaseStateType> = {
    [ReleaseStateType.STAGED]: {
        classList: 'text-info',
        icon: 'ellipsis-horizontal',
        translation: 'release_staged',
    },
    [ReleaseStateType.IN_DEVELOPMENT]: {
        classList: 'text-warning',
        icon: 'edit',
        translation: 'release_unreleased',
    },
    [ReleaseStateType.RELEASED]: {
        classList: 'text-success',
        icon: 'truck',
        translation: 'release_released',
    },
};

export const RELEASES_REPORT_COLUMNS: IDatagridColumn[] = [
    {field: MILESTONE_FIELD.obs, translateKey: 'table_name'},
    {field: 'projectname', translateKey: 'project'},
    {
        field: MILESTONE_FIELD.version,
        translateKey: 'version',
        sorter: {
            compare: TASK_SORT_FUNCTIONS[TASK_FIELD.targetReleaseId],
        } as ClrDatagridComparatorInterface<ITask>,
    },
    {field: MILESTONE_FIELD.beginDate, translateKey: 'date_begin', filterType: DATAGRID_FILTER_TYPE.DATE},
    {field: MILESTONE_FIELD.endDate, translateKey: 'date_end', filterType: DATAGRID_FILTER_TYPE.DATE},
    {field: MILESTONE_FIELD.releaseState, translateKey: 'table_status', filterType: DATAGRID_FILTER_TYPE.CUSTOM},
    {field: 'failedreview', translateKey: 'failed_review'},
    {translateKey: 'completion'},
    {translateKey: 'remaining'},
    {translateKey: 'budget'},
    {translateKey: 'profit'},
    {translateKey: 'variance'},
];

export const DEFAULT_ACCEPTED_TYPES: MimeTypes[] = [
    MimeTypes.XLS,
    MimeTypes.JPG,
    MimeTypes.JPEG,
    MimeTypes.PNG,
    MimeTypes.ZIP,
    MimeTypes.PDF,
    MimeTypes.DOCX,
    MimeTypes.PPTX,
    MimeTypes.XLSX,
    MimeTypes.DOC,
    MimeTypes.XML,
    MimeTypes.CSV,
    MimeTypes.RTF,
    MimeTypes.SVG,
    MimeTypes.XMLTEXT,
    MimeTypes.CSVEXCEL,
    MimeTypes.PLAIN,
];
