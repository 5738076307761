<form clrForm [formGroup]="form">
    <ng-select
        [items]="contacts"
        [hideSelected]="true"
        [placeholder]="'customer_contacts' | translate"
        [bindLabel]="CUSTOMER_CONTACT_FIELD.employee + '.' + EMPLOYEE_FIELD.user + '.' + USER_FIELD.name"
        multiple="true"
        [dropdownPosition]="dropdownPosition"
        [formControlName]="CUSTOMER_CONTACTS_FORM_KEYS.contacts"
    >
        <ng-template ng-label-tmp let-item="item">
            <shared-editable-employee-label
                [member]="item"
                [removable]="true"
                (remove)="removeContact($event)"
                (update)="updateContact($event)"
                [type]="EMPLOYEE_MEMBER_TYPE.CUSTOMER_CONTACT"
            >
            </shared-editable-employee-label>
        </ng-template>
        <ng-template ng-option-tmp let-item="item">
            <app-long-avatar [code]="item.employee.user.code" [bgColor]="item.employee.user.color"> </app-long-avatar>
            {{ item.employee.user.name }}
        </ng-template>
    </ng-select>
</form>
