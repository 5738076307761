<div class="clr-row" *ngIf="TASK_TARGET.ASSIGNED === taskTarget">
    <div class="clr-col-12">
        <ng-select
            class="less-pl"
            [items]="tasksProgress"
            bindLabel="displayName"
            bindValue="id"
            [placeholder]="'please_select_task' | translate"
            (clear)="filterTaskProgress()"
            (change)="filterTaskProgress($event?.id)"
            #ngSelectTaskProgress
            [ngModel]="form.value.assignedTask"
        >
            <ng-template ng-option-tmp let-item="item" let-index="index">
                <small class="label label-info va-m"> {{ getCodeProject(item?.projectId) }}-{{ item?.code }} </small>
                <span>{{ item?.name }}</span>
            </ng-template>
            <ng-template ng-label-tmp let-item="item">
                <small class="label label-info"> {{ getCodeProject(item?.projectId) }}-{{ item?.code }} </small>
                <span>{{ item?.name }}</span>
            </ng-template>
        </ng-select>
    </div>
</div>

<div class="clr-row" *ngIf="TASK_TARGET.EXISTING === taskTarget">
    <div class="clr-col-12">
        <ng-select
            class="less-pl"
            [items]="projects"
            bindLabel="obs"
            bindValue="id"
            [placeholder]="'please_select_project' | translate"
            (clear)="filterProject()"
            (change)="filterProject($event?.id)"
            #ngSelectProject
            [ngModel]="form.value.project"
        >
            <ng-template ng-option-tmp let-item="item" let-index="index">
                <app-long-avatar [code]="item?.code" [bgColor]="item.color"> </app-long-avatar>
                {{ item.obs }}
            </ng-template>
            <ng-template ng-label-tmp let-item="item">
                <app-long-avatar [code]="item?.code" [bgColor]="item.color"> </app-long-avatar>
                {{ item.obs }}
            </ng-template>
        </ng-select>
    </div>
</div>

<div class="clr-row mt-2" *ngIf="TASK_TARGET.EXISTING === taskTarget">
    <div class="clr-col-12">
        <ng-select
            class="less-pl"
            [items]="tasks | async"
            [typeahead]="tasksTH$"
            [loading]="tasksLoading"
            bindLabel="name"
            bindValue="id"
            [placeholder]="'please_select_task' | translate"
            (clear)="filterTask()"
            (change)="filterTask($event?.id)"
            #ngSelectTask
            [ngModel]="form.value.taskId"
            [disabled]="!form.controls.project.valid"
        >
            <ng-template ng-option-tmp let-item="item" let-index="index">
                <small class="label label-info va-m"> {{ item?.code }} </small> <span>{{ item?.name }}</span>
            </ng-template>
            <ng-template ng-label-tmp let-item="item">
                <small class="label label-info"> {{ item?.code }} </small> <span>{{ item?.name }}</span>
            </ng-template>
        </ng-select>
    </div>
</div>
