<div class="pie-wrapper progress-{{ progressPercent }} style-2 mr-1">
    <div class="pie">
        <div class="left-side half-circle"></div>
        <div class="right-side half-circle"></div>
    </div>

    <div class="shadow"></div>
</div>
<small class="ml-2 text-clip" style="font-size: 9px!important" *ngIf="part && total">{{ part }} / {{ total }}</small>
<small class="ml-2 text-clip" style="font-size: 9px!important" *ngIf="!(part && total) && progressPercent"
    >{{ progressPercent }}%</small
>
