import moment from 'moment';
import {ISchemaRoute, ProjectMemberType} from '../../schema-static';
import {IEmployee} from './Employees';
import {IProject} from './Projects';

export enum PROJECT_MEMBER_FIELD {
    projectId = 'projectId',
    id = 'id',
    employeeId = 'employeeId',
    createdAt = 'createdAt',
    updatedAt = 'updatedAt',
    type = 'type',
    project = 'project',
    employee = 'employee',
}

export interface IProjectMemberBare {
    createdAt?: moment.Moment;
    updatedAt?: moment.Moment;
    id?: number;
    projectId: number;
    employeeId: number;
    type: ProjectMemberType;
}

export interface IAssignedTaskBelong {
    project: Partial<IProject>;
    employee: Partial<IEmployee>;
}

export interface IProjectMember extends IAssignedTaskBelong, IProjectMemberBare {}

const PROJECT_MEMBER_SCHEMA_ROUTE: ISchemaRoute = {
    tableName: 'projectmembers',
    singularRoute: 'projectmember',
    pluralRoute: 'projectmembers',
};

export {PROJECT_MEMBER_SCHEMA_ROUTE};
