import {DATABASE_SCHEMA, ISchemaRoute} from '../../schema-static';

export enum TRANSLATIONS_FIELD {
    id = 'id',
    language = 'language',
    key = 'key',
    value = 'value',
}

export interface ITranslations {
    id?: number;
    language: string;
    key: string;
    value: string;
}

const TRANSLATIONS_SCHEMA_ROUTE: ISchemaRoute = {
    schema: DATABASE_SCHEMA.META,
    tableName: 'translations',
    singularRoute: 'translation',
    pluralRoute: 'translations',
};

export {TRANSLATIONS_SCHEMA_ROUTE};
