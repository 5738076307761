import {Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {Validators} from '@angular/forms';
import {ClrForm} from '@clr/angular';
import {FormControl, FormGroup} from 'ngx-strongly-typed-forms';
import {getWorkingHours} from '../../../../../defs/businessRules';
import {DECIMAL_RADIX, ISO_DATE_FORMAT, MAX_LENGTH_DEFAULT, MilestonesType} from '../../../../../defs/schema-static';
import {FormsAddMilestoneService, IMilestoneFormValues, MILESTONE_FORM_KEYS} from '../../forms/add-milestone.service';
import {requiredTrimValidator} from '../../forms/validators/required-trim.validator';
import {ConfigService} from '../../shared/config/config.service';
import {MomentService} from '../../shared/moment/moment.service';

export interface IMSDetails {
    description: string;
    milestone: string;
    target: string;
    startDate: string;
    startTime: string;
    endDate: string;
    endTime: string;
    version: string;
}

@Component({
    selector: 'app-milestone-details',
    templateUrl: './milestone-details.component.html',
    styleUrls: ['./milestone-details.component.scss'],
})
export class MilestoneDetailsComponent implements OnChanges {
    public readonly form = new FormGroup<IMSDetails>({
        description: new FormControl<string>(),
        milestone: new FormControl<string>(null, [requiredTrimValidator(), Validators.maxLength(MAX_LENGTH_DEFAULT)]),
        target: new FormControl<string>(null, requiredTrimValidator()),
        startDate: new FormControl<string>(null, Validators.required),
        startTime: new FormControl<string>(null, Validators.required),
        endDate: new FormControl<string>(null, Validators.required),
        endTime: new FormControl<string>(null, Validators.required),
        version: new FormControl<string>(),
    });

    @ViewChild(ClrForm) public clrForm: ClrForm;
    @ViewChild('milestoneName') public milestoneName: ElementRef;

    @ViewChild('beginDateInputElement') public beginDateInputElement: ElementRef;
    @ViewChild('targetDateInputElement') public targetDateInputElement: ElementRef;
    @ViewChild('endDateInputElement') public endDateInputElement: ElementRef;

    @Input() public type: MilestonesType;
    public readonly errorsDates: string[] = ['error_date_begin_only', 'error_date'];
    public errorDates = 0;

    public beginDate: Date;
    public endDate: Date;
    public targetDate: Date;

    public constructor(
        private readonly configService: ConfigService,
        private readonly formsAddMilestoneService: FormsAddMilestoneService,
        private readonly momentService: MomentService
    ) {}

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.type) {
            this.onLoad();
        }
    }

    public async onLoad(params?: IMilestoneFormValues) {
        const _now = this.momentService.moment();
        const config = await this.configService.get();
        const workingHours = getWorkingHours(config);
        const configStartTime = workingHours.start.split(':').map((e) => parseInt(e, DECIMAL_RADIX));
        const configEndTime = workingHours.end.split(':').map((e) => parseInt(e, DECIMAL_RADIX));
        let target = _now.format(ISO_DATE_FORMAT) as string;
        let startDate = _now.format(ISO_DATE_FORMAT) as string;
        let startTime = _now
            .set({
                hour: configStartTime[0],
                minute: configStartTime[1],
            })
            .format(FormsAddMilestoneService.HOUR_MINUTE_TIME_FORMAT);
        let endDate = _now.format(ISO_DATE_FORMAT) as string;
        let endTime = _now
            .set({
                hour: configEndTime[0],
                minute: configEndTime[1],
            })
            .format(FormsAddMilestoneService.HOUR_MINUTE_TIME_FORMAT);

        if (params && this.form.pristine) {
            this.type = params.type;

            this.beginDate =
                params.startDate && this.momentService.moment(params.startDate).isValid()
                    ? this.momentService.moment(params.startDate).toDate()
                    : new Date(startDate);
            this.endDate =
                params.endDate && this.momentService.moment(params.endDate).isValid()
                    ? this.momentService.moment(params.endDate).toDate()
                    : new Date(endDate);
            this.targetDate =
                params.target && this.momentService.moment(params.target).isValid()
                    ? this.momentService.moment(params.target).toDate()
                    : new Date(target);

            target = this.momentService.moment(this.targetDate).format('YYYY-MM-DD');
            startDate = this.momentService.moment(this.beginDate).format('YYYY-MM-DD');
            startTime = params.startTime ? params.startTime : startTime;
            endDate = this.momentService.moment(this.endDate).format('YYYY-MM-DD');
            endTime = params.endTime ? params.endTime : endTime;
        }

        this.form.patchValue({
            description: params && this.form.pristine ? params.description : this.form.value.description,
            milestone: params && this.form.pristine ? params.milestone : this.form.value.milestone,
            version: params && this.form.pristine ? params.version : this.form.value.version,
        });

        if (this.form.pristine) {
            this.form.patchValue({
                startDate,
                endDate,
                target,
            });
            if (this.targetDateInputElement) {
                this.targetDateInputElement.nativeElement.value = target; // fix for create release ideaboard
            }
        }

        this.toggle();
        requestAnimationFrame(() => {
            this.milestoneName.nativeElement.focus();
        });

        if (this.form.pristine) {
            setTimeout(() => {
                this.form.patchValue({
                    startTime,
                    endTime,
                });
            }, 50);
        }
    }

    public submit() {
        if (!this.form.valid) {
            this.clrForm.markAsDirty();

            return undefined;
        }

        return this.form;
    }

    private toggle() {
        this.errorDates = 1;
        if (MilestonesType.REMINDER === this.type) {
            this.errorDates = 0;
        }
        FormsAddMilestoneService.toggle(this.form, this.type);
    }

    public checkDates = () => {
        // fix datepicker reset not null

        if (this.beginDateInputElement && this.beginDateInputElement.nativeElement.value === '') {
            this.form.patchValue({startDate: null});
            this.beginDate = null;
        }
        if (this.endDateInputElement && this.endDateInputElement.nativeElement.value === '') {
            this.form.patchValue({endDate: null});
            this.endDate = null;
        }
        if (this.targetDateInputElement && this.targetDateInputElement.nativeElement.value === '') {
            this.form.patchValue({target: null});
            this.targetDate = null;
        }

        return this.formsAddMilestoneService.checkDates(
            this.form,
            this.beginDate,
            this.endDate,
            this.targetDate,
            this.type
        );
    };

    public readonly MILESTONE_FORM_KEYS = MILESTONE_FORM_KEYS;
    public readonly MILESTONE_TYPES = MilestonesType;
    public readonly MAX_LENGTH_DEFAULT = MAX_LENGTH_DEFAULT;
}
