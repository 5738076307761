<div class="clr-col-row">
    <div class="clr-col-12" *ngFor="let type of types; let i = index">
        <div
            class="clr-row item-choice c-hand"
            tabindex="{{ i }}"
            (click)="setType(type)"
            [ngClass]="{
                active:
                    type === form.value.type ||
                    (type === MilestoneType.MEETING && form.value.type === MilestoneType.MEETING_NO_NOTE)
            }"
        >
            <div class="clr-col-3"><clr-icon [attr.shape]="MILESTONE_TYPE_ICON[type]"></clr-icon></div>
            <div class="clr-icon-9">{{ 'milestone_' + type | lowercase | translate }}</div>
        </div>
    </div>
    <div
        class="clr-col-12"
        *ngIf="form.value.type === MilestoneType.MEETING || form.value.type === MilestoneType.MEETING_NO_NOTE"
    >
        <clr-checkbox-container>
            <clr-checkbox-wrapper>
                <input type="checkbox" clrCheckbox [ngModel]="withNote" (ngModelChange)="setNote($event)" />
                <label class="fs-07" translate>with_meeting_note</label>
            </clr-checkbox-wrapper>
        </clr-checkbox-container>
    </div>
</div>
