<app-modal
    [title]="'multiple_add' | translate"
    [(show)]="show"
    [class]="'modal-dialog modal-lg'"
    [appModalSize]="'xl'"
    [preventDismiss]="true"
    (shown)="load()"
    (showChange)="showChange.emit($event)"
>
    <ng-template appModalBody>
        <table class="table">
            <thead>
                <tr>
                    <th translate>task_name</th>
                    <th translate>project_or_estimated</th>
                    <th translate>attach_to_release</th>
                    <th translate>date_begin</th>
                    <th translate>date_end</th>
                    <th translate>task_bug</th>
                    <th translate>task_urgent</th>
                    <th translate></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <!-- parent -->
                    <td style="position: relative">
                        <span
                            style="position: absolute; top: 1em; left: 1em;"
                            class="float-left"
                            [class.text-blue]="parentTask.name"
                            translate
                            >task_parent</span
                        >
                        <input
                            clrInput
                            [placeholder]="'task_parent' | translate"
                            [(ngModel)]="parentTask.name"
                            [sharedAutofocus]="show"
                            [maxLength]="250"
                        />
                    </td>
                    <td style="vertical-align: middle;">
                        <ng-select
                            class="less-pl petitSelect"
                            [items]="activeProjects"
                            [(ngModel)]="parentTask.projectId"
                            bindValue="id"
                            groupBy="status"
                            appendTo="td"
                            [placeholder]="'project_select' | translate"
                            (clear)="getReleasesByProject(true)"
                            (change)="getReleasesByProject()"
                        >
                            <ng-template ng-optgroup-tmp let-item="item">
                                {{ 'project_' + item.status | lowercase | translate }}
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-index="index">
                                <app-long-avatar [code]="item?.code" [bgColor]="item.color"> </app-long-avatar>
                                {{ item.obs }}
                            </ng-template>
                            <ng-template ng-label-tmp let-item="item">
                                <app-long-avatar [code]="item?.code" [bgColor]="item.color"> </app-long-avatar>
                            </ng-template>
                        </ng-select>
                    </td>
                    <td style="vertical-align: middle;">
                        <ng-select
                            class="less-pl "
                            [items]="releases"
                            [disabled]="
                                !parentTask?.projectId ||
                                parentTask?.projectId?.toString() === '0' ||
                                releases?.length === 0
                            "
                            [(ngModel)]="parentTask.targetReleaseId"
                            bindValue="id"
                            groupBy="releaseState"
                            appendTo="td"
                            [placeholder]="'release_select' | translate"
                        >
                            <ng-template ng-optgroup-tmp let-item="item" let-group="group">
                                {{ item.releaseState | translate }}
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-index="index">
                                <small class="label label-info"> {{ item.version }} </small>{{ item.obs }}
                            </ng-template>
                            <ng-template ng-label-tmp let-item="item">
                                <small class="label label-info"> {{ item.version }} </small>
                            </ng-template>
                        </ng-select>

                        <!--
                            <select
                                clrSelect
                                [disabled]="parentTask.projectId.toString() === '0'"
                                [(ngModel)]="parentTask.targetReleaseId"
                            >
                                <option translate [ngValue]="0"> release_select</option>
                                <optgroup label="{{'unreleased' |translate }}">
                                    <option *ngFor="let release of unreleased" [ngValue]="release.id">
                                        {{ release.version }} - {{ release.obs }}
                                    </option>
                                </optgroup>
                                <optgroup label="{{'release_staged' |translate }}">
                                    <option *ngFor="let release of staged" [ngValue]="release.id">
                                        {{ release.version }} - {{ release.obs }}
                                    </option>
                                </optgroup>
                                <optgroup label="{{'release_released' |translate }}">
                                    <option *ngFor="let release of released" [ngValue]="release.id">
                                        {{ release.version }} - {{ release.obs }}
                                    </option>
                                </optgroup>
                            </select>
                        -->
                    </td>
                    <td style="width: 15%">
                        <small
                            *ngIf="parentTask.targetReleaseId"
                            class="ml-04 float-left release-date tooltip"
                            [attr.data-tooltip]="'release_begin_date' | translate"
                        >
                            ({{ getRelease(parentTask?.targetReleaseId)?.beginDate | momentFormat }})
                        </small>
                        <input clrInput type="date" appDateValidator [(ngModel)]="parentTask.beginDate" />
                    </td>
                    <td style="width: 15%">
                        <small
                            *ngIf="parentTask.targetReleaseId"
                            class="ml-04 float-left release-date tooltip"
                            [attr.data-tooltip]="'release_end_date' | translate"
                        >
                            ({{
                                getRelease(parentTask?.targetReleaseId)?.endDate ||
                                    getRelease(parentTask?.targetReleaseId)?.target | momentFormat
                            }})
                        </small>
                        <input clrInput type="date" appDateValidator [(ngModel)]="parentTask.endDate" />
                    </td>

                    <td style="vertical-align: middle;">
                        <clr-checkbox-wrapper>
                            <input clrCheckbox id="parentBug" type="checkbox" [(ngModel)]="parentTask.bug" />
                            <label for="parentBug" class="form-checkbox float-left">Bug</label>
                        </clr-checkbox-wrapper>
                    </td>
                    <td style="vertical-align: middle;">
                        <clr-checkbox-wrapper>
                            <input clrCheckbox id="parentUrgent" type="checkbox" [(ngModel)]="parentTask.urgent" />
                            <label for="parentUrgent" class="form-checkbox float-left">Urgent</label>
                        </clr-checkbox-wrapper>
                    </td>
                    <td style="vertical-align: middle;">
                        <clr-dropdown class="float-right  ">
                            <clr-icon shape="ellipsis-vertical" clrDropdownTrigger size="25"></clr-icon>
                            <clr-dropdown-menu clrPosition="bottom-left" *clrIfOpen>
                                <button type="button" clrDropdownItem translate (click)="clearParent()">
                                    clear_values
                                </button>
                            </clr-dropdown-menu>
                        </clr-dropdown>
                    </td>
                </tr>
                <tr>
                    <!-- blocker -->
                    <td style="position: relative">
                        <span
                            style="position: absolute; top: 1em; left: 1em;"
                            class="float-left"
                            [class.text-blue]="blockerTask.name"
                            translate
                            >task_blocker</span
                        >
                        <input
                            clrInput
                            [placeholder]="'task_blocker_name' | translate"
                            [maxLength]="250"
                            [(ngModel)]="blockerTask.name"
                        />
                    </td>
                    <td>
                        <clr-input-container>
                            <input
                                #blockerEstimated
                                clrInput
                                [placeholder]="'est_time' | translate"
                                [pattern]="PATTERN_TIME.source"
                                [(ngModel)]="blockerTask.estimatedTime"
                            />
                            <clr-control-helper style="padding-left:0.65rem; text-align: left;"
                                >10w 52d 10h 18m</clr-control-helper
                            >
                        </clr-input-container>
                    </td>
                    <td></td>
                    <td>
                        <input
                            clrInput
                            type="date"
                            tabindex="-1"
                            appDateValidator
                            [(ngModel)]="blockerTask.beginDate"
                        />
                    </td>

                    <td>
                        <input clrInput type="date" tabindex="-1" appDateValidator [(ngModel)]="blockerTask.endDate" />
                    </td>
                    <td style="vertical-align: middle;">
                        <clr-checkbox-wrapper>
                            <input
                                clrCheckbox
                                tabindex="-1"
                                id="blockerBug"
                                type="checkbox"
                                [(ngModel)]="blockerTask.bug"
                            />
                            <label for="blockerBug" class="form-checkbox float-left">Bug</label>
                        </clr-checkbox-wrapper>
                    </td>
                    <td style="vertical-align: middle;">
                        <clr-checkbox-wrapper>
                            <input
                                clrCheckbox
                                tabindex="-1"
                                id="blockerUrgent"
                                type="checkbox"
                                [(ngModel)]="blockerTask.urgent"
                            />
                            <label for="blockerUrgent" class="form-checkbox float-left">Urgent</label>
                        </clr-checkbox-wrapper>
                    </td>
                    <td style="vertical-align: middle;">
                        <clr-dropdown class="float-right  ">
                            <clr-icon shape="ellipsis-vertical" clrDropdownTrigger size="25"></clr-icon>
                            <clr-dropdown-menu clrPosition="bottom-left" *clrIfOpen>
                                <button type="button" clrDropdownItem translate (click)="clearBlocker()">
                                    clear_values
                                </button>
                            </clr-dropdown-menu>
                        </clr-dropdown>
                    </td>
                </tr>
                <!-- subs task -->
                <tr *ngFor="let temp of tempTasks; let i = index">
                    <td style="position: relative">
                        <span
                            style="position: absolute; top: 1em; left: 1em;"
                            class="float-left"
                            [class.text-blue]="temp.name"
                            translate
                            >task</span
                        >
                        <input
                            clrInput
                            class="ml-10"
                            [placeholder]="'task_name' | translate"
                            [(ngModel)]="temp.name"
                            [maxLength]="250"
                        />
                    </td>
                    <td>
                        <clr-input-container>
                            <input
                                #childEstTime
                                clrInput
                                class="ml-2"
                                [placeholder]="'est_time' | translate"
                                [pattern]="PATTERN_TIME.source"
                                [(ngModel)]="temp.estimatedTime"
                            />
                            <clr-control-helper style="font-size: 0.6rem;">10w 52d 10h 18m</clr-control-helper>
                        </clr-input-container>
                    </td>
                    <td></td>
                    <td><input clrInput type="date" tabindex="-1" appDateValidator [(ngModel)]="temp.beginDate" /></td>
                    <td><input clrInput type="date" tabindex="-1" appDateValidator [(ngModel)]="temp.endDate" /></td>
                    <td style="vertical-align: middle;">
                        <clr-checkbox-wrapper>
                            <input clrCheckbox tabindex="-1" [id]="'bug' + i" type="checkbox" [(ngModel)]="temp.bug" />
                            <label [for]="'bug' + i" class="form-checkbox float-left">Bug</label>
                        </clr-checkbox-wrapper>
                    </td>
                    <td style="vertical-align: middle;">
                        <clr-checkbox-wrapper>
                            <input
                                clrCheckbox
                                tabindex="-1"
                                [id]="'urgent' + i"
                                type="checkbox"
                                [(ngModel)]="temp.urgent"
                            />
                            <label [for]="'urgent' + i" class="form-checkbox float-left">Urgent</label>
                        </clr-checkbox-wrapper>
                    </td>
                    <td style="vertical-align: middle;">
                        <clr-dropdown class="float-right  ">
                            <clr-icon shape="ellipsis-vertical" clrDropdownTrigger size="25"></clr-icon>
                            <clr-dropdown-menu clrPosition="bottom-left" *clrIfOpen>
                                <button type="button" clrDropdownItem translate (click)="deleteTask(temp, i)">
                                    delete
                                </button>
                            </clr-dropdown-menu>
                        </clr-dropdown>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="alert alert-info">
            <div class="alert-items">
                <div class="alert-item static">
                    <div class="alert-icon-wrapper"><clr-icon class="alert-icon" shape="info-circle"> </clr-icon></div>
                    <span class="alert-text" [innerHTML]="'task_multiple_info' | translate"> </span>
                </div>
            </div>
        </div>
        <div class="alert alert-danger" role="alert" *ngIf="!getBlockerEstimatedValid() || getChildrenEstimatedValid()">
            <div class="alert-items">
                <div class="alert-item static">
                    <div class="alert-icon-wrapper">
                        <clr-icon class="alert-icon" shape="exclamation-circle"></clr-icon>
                    </div>
                    <span class="alert-text" translate [innerHTML]="'help_time' | translate"></span>
                </div>
            </div>
        </div>

        <div class="alert alert-danger" role="alert" *ngIf="error">
            <div class="alert-items">
                <div class="alert-item static">
                    <div class="alert-icon-wrapper">
                        <clr-icon class="alert-icon" shape="exclamation-circle"></clr-icon>
                    </div>
                    <span class="alert-text" translate>{{ error }}</span>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template appModalFooter>
        <button class="btn btn-footer-left" type="button" (click)="newTempTask()" translate>add_task</button>
        <button class="btn" type="button" (click)="show = false" translate>form_cancel</button>
        <button class="btn btn-primary btn-success float-right" type="button" (click)="submitTempTasks()" translate>
            multiple_add
        </button>
    </ng-template>
</app-modal>
