import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ClrDatagridSortOrder} from '@clr/angular';
import {ApiRoutePlurality, BACKUP_FIELD, HTTP_METHOD, IBackup, RIGHTS} from '../../../../defs/schema-static';
import {CLIENT_SCHEMA_ROUTE, IClient} from '../../../../defs/schema/public/Clients';
import {IProject, PROJECT_SCHEMA_ROUTE} from '../../../../defs/schema/public/Projects';
import {DATAGRID_FILTER_TYPE, IDatagridColumn} from '../app-static';
import {FileDownloaderService} from '../shared/file-downloader/file-downloader.service';
import {HttpRestService} from '../shared/http-rest/http-rest.service';

@Component({
    selector: 'app-backups-list',
    templateUrl: './backups-list.component.html',
})
export class BackupsListComponent implements OnInit, OnChanges {
    @Input() public project: IProject;
    @Input() public client: IClient;
    @Input() public backups: IBackup[];

    public prefix = '';

    public constructor(
        private readonly httpRest: HttpRestService,
        private readonly fileDownloader: FileDownloaderService
    ) {}

    public ngOnInit() {
        this.getBackups();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.project || changes.client) {
            this.backups = undefined;
            this.getBackups();
        }
    }

    public getBackups() {
        if ((!this.project || !this.project.id) && (!this.client || !this.client.id)) {
            return;
        }

        const route = !!this.project ? PROJECT_SCHEMA_ROUTE : CLIENT_SCHEMA_ROUTE;
        const id = (!!this.project && this.project.id) || (!!this.client && this.client.id);

        this.httpRest
            ._request<{backups: IBackup[]; prefix: string}>(
                HTTP_METHOD.GET,
                ApiRoutePlurality.SINGULAR,
                route,
                `backup/${id}`
            )
            .subscribe((res) => {
                const {backups, prefix} = res;

                this.backups = [...backups];
                this.prefix = prefix;
            });
    }

    public async downloadBackup(file: string) {
        const route = !!this.project ? PROJECT_SCHEMA_ROUTE.singularRoute : CLIENT_SCHEMA_ROUTE.singularRoute;

        return this.fileDownloader.downloadAPI([route, 'backup/file', file].join('/'));
    }

    public getKeyWithoutPrefix(backup: IBackup) {
        if (!this.prefix) {
            return backup.key;
        }

        return backup.key.replace(new RegExp(`^${this.prefix}`), '');
    }

    public readonly RIGHTS = RIGHTS;
    public readonly DATAGRID_FILTER_TYPE = DATAGRID_FILTER_TYPE;

    public readonly BACKUP_COLUMNS: IDatagridColumn[] = [
        {field: BACKUP_FIELD.key, translateKey: 'table_key'},
        {
            field: BACKUP_FIELD.date,
            translateKey: 'date',
            order: ClrDatagridSortOrder.DESC,
            filterType: DATAGRID_FILTER_TYPE.DATE,
        },
        {field: BACKUP_FIELD.size, translateKey: 'filesize'},
    ];
    public filterOpen: {[key: string]: boolean} = {};
}
