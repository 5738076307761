import {Component, OnDestroy, ViewEncapsulation} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {COGNITO_USER_GROUPS} from '../../../defs/schema-static';
import {SETTINGS} from '../../../defs/schema/public/Users';
import {appModuleRoutes, clientRoutesControlFlow, employeeRoutesControlFlow} from './app-routing.module';
import {AuthGuard} from './auth/auth.guard';
import {AuthService} from './auth/auth.service';
import {ClientAuthGuard} from './auth/client-auth.guard';
import {EmployeeAuthGuard} from './auth/employee-auth.guard';
import {SettingsService} from './settings/settings.service';
import {IControlFlowRoute} from './shared/control-flow/control-flow.component';
import {NotificationService} from './shared/notification.service';
import {ToastService} from './shared/toast/toast.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnDestroy {
    public routes = appModuleRoutes;
    public requiredGroup = COGNITO_USER_GROUPS._UNAUTHED;
    public routesControlFlow: {[path: string]: Partial<IControlFlowRoute>} = {};
    public settings = this.settingsService.settings;

    public isAuthenticated = this.authService.isAuthenticated;
    public isAuthenticatedSubscription: Subscription = this.authService.isAuthenticatedChange.subscribe(
        (isAuthenticated: boolean) => {
            this.isAuthenticated = isAuthenticated;

            if (this.employeeAuthGuard.isAuthorized) {
                this.routesControlFlow = employeeRoutesControlFlow;
                this.requiredGroup = this.employeeAuthGuard.requiredGroup;
            } else if (this.clientAuthGuard.isAuthorized) {
                this.routesControlFlow = clientRoutesControlFlow;
            } else {
                this.routesControlFlow = {};
            }
        }
    );

    public constructor(
        private readonly authService: AuthService,
        private readonly authGuard: AuthGuard,
        private readonly employeeAuthGuard: EmployeeAuthGuard,
        private readonly clientAuthGuard: ClientAuthGuard,
        private readonly settingsService: SettingsService,
        private readonly translate: TranslateService,
        private readonly notificationService: NotificationService,
        public toastService: ToastService
    ) {
        // this language will be used as a fallback when a translation isn't found in the current language
        // translate.setDefaultLang('EN');
        // the lang to use, if the lang isn't available, it will use the current loader to get them
        // move to settings.component.ts to try
        // translate.use(translate.getBrowserLang().toUpperCase());
    }

    public ngOnDestroy() {
        if (this.isAuthenticatedSubscription) {
            this.isAuthenticatedSubscription.unsubscribe();
        }
    }

    public readonly SETTINGS = SETTINGS;

    public static readonly TRANSLATION_PREFIX = '/assets/i18n/';
    public static readonly TRANSLATION_SUFFIX = '.json';
}
