<clr-wizard
    [clrWizardOpen]="clrWizardOpen"
    (clrWizardOpenChange)="clrWizardOpenChange.emit((clrWizardOpen = $event))"
    (clrWizardOnCancel)="triggerCancel(true)"
    clrWizardSize="lg"
>
    <clr-wizard-title>{{
        (form.value.type === MILESTONE_TYPE.RELEASE ? 'add_release' : 'add_milestone') | translate
    }}</clr-wizard-title>

    <clr-wizard-button [clrWizardButtonDisabled]="submitting" [type]="'cancel'">{{
        'cancel' | translate
    }}</clr-wizard-button>
    <clr-wizard-button [clrWizardButtonDisabled]="submitting" [type]="'previous'">{{
        'back' | translate
    }}</clr-wizard-button>
    <clr-wizard-button [clrWizardButtonDisabled]="submitting" [type]="'next'">{{
        'next' | translate
    }}</clr-wizard-button>
    <clr-wizard-button [clrWizardButtonDisabled]="submitting" [type]="'finish'">{{
        (form.value.type === MILESTONE_TYPE.RELEASE ? 'create_release' : 'create_milestone') | translate
    }}</clr-wizard-button>

    <clr-wizard-page
        (clrWizardPageOnLoad)="onLoad(1)"
        [clrWizardPageNextDisabled]="!typeComponent?.form.valid"
        *ngIf="!params || !params.type"
    >
        <ng-template clrPageTitle>{{ 'type' | translate }}</ng-template>
        <app-milestone-type [types]="filteredTypes" (switchType)="onCommit(1)"></app-milestone-type>
    </clr-wizard-page>

    <clr-wizard-page
        (clrWizardPageOnLoad)="onLoad(2)"
        [clrWizardPageNextDisabled]="!targetComponent?.form.valid"
        *ngIf="!params || (!params.projectId && !params.clientId)"
    >
        <ng-template clrPageTitle>{{ 'target' | translate }}</ng-template>
        <app-milestone-target
            (switchType)="onCommit(2)"
            [clients]="clients"
            [projects]="projects"
        ></app-milestone-target>
    </clr-wizard-page>

    <clr-wizard-page
        (clrWizardPageOnLoad)="onLoad(3)"
        [clrWizardPageNextDisabled]="!detailsComponent.form.valid"
        (clrWizardPageOnCancel)="triggerCancel()"
        (clrWizardPageOnCommit)="
            form.value.type &&
                form.value.type !== MILESTONE_TYPE.MEETING &&
                form.value.type !== MILESTONE_TYPE.MEETING_NO_NOTE &&
                triggerFinish()
        "
    >
        <ng-template clrPageTitle>{{ 'details' | translate }}</ng-template>
        <app-milestone-details [type]="form.value.type"></app-milestone-details>
    </clr-wizard-page>

    <clr-wizard-page
        *ngIf="
            form.value.type &&
            (form.value.type === MILESTONE_TYPE.MEETING || form.value.type === MILESTONE_TYPE.MEETING_NO_NOTE)
        "
        (clrWizardPageOnCancel)="triggerCancel()"
        (clrWizardPageOnCommit)="triggerFinish()"
    >
        <ng-template clrPageTitle>{{ 'meeting_participant' | translate }}</ng-template>
        <app-milestone-participants
            [targetType]="form.value.targetType"
            [id]="form.value.client || form.value.project"
            [clients]="clients"
            [projects]="projects"
        ></app-milestone-participants>
    </clr-wizard-page>
</clr-wizard>
