import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {Validators} from '@angular/forms';
import {ClrForm} from '@clr/angular';
import {FormControl, FormGroup} from 'ngx-strongly-typed-forms';
import {MILESTONE_TYPE_ICON, MilestonesType} from '../../../../../defs/schema-static';
import {IMilestoneFormValues} from '../../forms/add-milestone.service';

@Component({
    selector: 'app-milestone-type',
    templateUrl: './milestone-type.component.html',
    styleUrls: ['./milestone-type.component.scss'],
})
export class MilestoneTypeComponent {
    public readonly form = new FormGroup<{type: MilestonesType}>({
        type: new FormControl<MilestonesType>(null, Validators.required),
    });

    @Input() public types: MilestonesType[];

    @ViewChild(ClrForm) public clrForm: ClrForm;
    @Output() public switchType = new EventEmitter();

    public withNote: boolean;

    public onLoad(params?: IMilestoneFormValues) {
        if (params && params.type && !this.form.value.type) {
            this.setType(params.type);
        } else {
            this.setType(this.types[0]);
        }

        // focus on item selected to avoid enter key for next step
        requestAnimationFrame(() => {
            const idxElement = this.types.findIndex((f) => f === this.form.value.type);
            const el: HTMLElement = document.querySelector(`.item-choice[tabindex="${idxElement}"]`);
            if (el) {
                el.focus();
            }
        });
    }

    public setType(type: MilestonesType) {
        this.form.patchValue({
            type,
        });
        this.withNote = true;
        this.switchType.emit();
    }

    public setNote(note: boolean) {
        if (note) {
            this.form.patchValue({
                type: MilestonesType.MEETING,
            });
        } else {
            this.form.patchValue({
                type: MilestonesType.MEETING_NO_NOTE,
            });
        }
    }

    public submit() {
        if (!this.form.valid) {
            this.clrForm.markAsDirty();

            return undefined;
        }

        return this.form;
    }

    public readonly MILESTONE_TYPE_ICON = MILESTONE_TYPE_ICON;
    // tslint:disable-next-line
    public readonly MilestoneType = MilestonesType;
}
