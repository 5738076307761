var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClrDatagridSortOrder } from '@clr/angular';
import { TranslateService } from '@ngx-translate/core';
import { compare } from 'semver';
import { MilestonesType, RIGHTS } from '../../../../../defs/schema-static';
import { BUDGET_FIELD, BUDGET_SCHEMA_ROUTE } from '../../../../../defs/schema/public/Budget';
import { MILESTONE_FIELD } from '../../../../../defs/schema/public/Milestones';
import { BUDGET_STATUS_FILTER, DATAGRID_FILTER_TYPE, PROJECT_TABS } from '../../app-static';
import { AuthService } from '../../auth/auth.service';
import { HttpRestService } from '../../shared/http-rest/http-rest.service';
import { TOAST_TYPE, ToastService } from '../../shared/toast/toast.service';
var ProjectBudgetsComponent = (function () {
    function ProjectBudgetsComponent(httpRest, route, router, authService, toastService, translate) {
        this.httpRest = httpRest;
        this.route = route;
        this.router = router;
        this.authService = authService;
        this.toastService = toastService;
        this.translate = translate;
        this.selectedBudgets = [];
        this.showEditModal = false;
        this.showDeleteModal = false;
        this.RIGHTS = RIGHTS;
        this.BUDGET_FIELD = BUDGET_FIELD;
        this.DATAGRID_FILTER_TYPE = DATAGRID_FILTER_TYPE;
        this.PROJECT_TABS = PROJECT_TABS;
        this.BUDGET_STATUS_FILTER = BUDGET_STATUS_FILTER;
        this.BUDGETS_COLUMNS = [
            {
                name: 'Description',
                field: BUDGET_FIELD.description,
                translateKey: 'table_description',
            },
            {
                name: 'Price',
                field: BUDGET_FIELD.price,
                translateKey: 'table_price',
            },
            {
                name: 'Release',
                field: BUDGET_FIELD.release + "." + MILESTONE_FIELD.version,
                translateKey: 'release',
                sorter: {
                    compare: function (b1, b2) {
                        return b2.release && b2.release.version
                            ? b1.release && b1.release.version
                                ? compare(b2.release.version, b1.release.version)
                                : 1
                            : -1;
                    },
                },
            },
            {
                name: 'Creation date',
                field: BUDGET_FIELD.createdAt,
                translateKey: 'creation_date',
                order: ClrDatagridSortOrder.DESC,
                filterType: DATAGRID_FILTER_TYPE.DATE,
            },
            {
                name: 'Status',
                field: BUDGET_FIELD.status,
                translateKey: 'table_status',
                filterType: DATAGRID_FILTER_TYPE.CUSTOM,
            },
        ];
        this.filterOpen = {};
        this.isManagerOf = this.authService.isManagerOf.bind(this.authService);
    }
    ProjectBudgetsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.getBudgets();
        this.route.queryParams.subscribe(function (queryParams) {
            _this.editQueryParams = Number(queryParams.edit) || null;
            _this.selectedBudgetChange();
        });
    };
    ProjectBudgetsComponent.prototype.ngOnChanges = function (changes) {
        if (changes.project) {
            this.budgets = undefined;
            this.getBudgets();
        }
    };
    ProjectBudgetsComponent.prototype.selectedBudgetChange = function () {
        var _this = this;
        if (!this.editQueryParams) {
            this.selectedBudget = null;
            this.showEditModal = false;
            return;
        }
        var budget = (this.budgets || []).find(function (_budget) { return _budget.id === _this.editQueryParams; });
        if (budget) {
            this.selectedBudget = budget;
            this.showEditModal = true;
        }
    };
    ProjectBudgetsComponent.prototype.closeEditModal = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.router.navigate([], {
                        relativeTo: this.route,
                        queryParams: { edit: null },
                        queryParamsHandling: 'merge',
                    })];
            });
        });
    };
    ProjectBudgetsComponent.prototype.getBudgets = function () {
        if (!this.project) {
            return;
        }
        this.budgets = this.budgets || this.project.budgets;
        this.mapReleases();
        this.selectedBudgetChange();
    };
    ProjectBudgetsComponent.prototype.mapReleases = function () {
        var releases = this.releases;
        if (!this.budgets || !releases) {
            return;
        }
        this.budgets
            .filter(function (budget) { return !!budget.release; })
            .map(function (budget) {
            return (budget.release = __assign({}, budget.release, (releases.find(function (release) { return release.id === (budget.releaseId || budget.release.id); }) || {})));
        });
    };
    ProjectBudgetsComponent.prototype.budgetChange = function (budget) {
        var existingBudget = this.budgets.find(function (_budget) { return _budget.id === budget.id; });
        if (existingBudget) {
            Object.assign(existingBudget, budget);
        }
        else {
            this.budgets.push(budget);
        }
        this.mapReleases();
    };
    ProjectBudgetsComponent.prototype.deleteSelectedBudgets = function () {
        return __awaiter(this, void 0, void 0, function () {
            var deletedBudgetsIds, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        deletedBudgetsIds = this.selectedBudgets.map(function (budget) { return budget.id; });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4, this.httpRest.deleteIds(BUDGET_SCHEMA_ROUTE, deletedBudgetsIds).toPromise()];
                    case 2:
                        _a.sent();
                        return [3, 4];
                    case 3:
                        err_1 = _a.sent();
                        this.showDeleteModal = false;
                        return [2];
                    case 4:
                        this.selectedBudgets = [];
                        this.budgets = this.budgets.filter(function (budget) { return !deletedBudgetsIds.includes(budget.id); });
                        this.toastService.show({
                            type: TOAST_TYPE.SUCCESS,
                            text: 'success_delete_budget',
                        });
                        this.showDeleteModal = false;
                        return [2];
                }
            });
        });
    };
    Object.defineProperty(ProjectBudgetsComponent.prototype, "releases", {
        get: function () {
            return (this.project.milestones || [])
                .filter(function (milestone) { return milestone.type === MilestonesType.RELEASE; })
                .sort(function (r1, r2) { return compare(r2.version, r1.version); });
        },
        enumerable: true,
        configurable: true
    });
    return ProjectBudgetsComponent;
}());
export { ProjectBudgetsComponent };
