import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {
    ApiRoutePlurality,
    CustomerRequestStatus,
    DECIMAL_RADIX,
    HTTP_METHOD,
    STORED_TYPE,
} from '../../../../defs/schema-static';
import {CUSTOMER_REQUEST_SCHEMA_ROUTE, ICustomerRequest} from '../../../../defs/schema/public/CustomerRequests';
import {ICustomerRequestFile} from '../../../../defs/schema/storage/CustomerRequestFiles';
import {FileDownloaderService} from '../shared/file-downloader/file-downloader.service';
import {FileManagerService, IFileSigned} from '../shared/file-manager/file-manager.service';
import {HttpRestService} from '../shared/http-rest/http-rest.service';

@Component({
    selector: 'app-request',
    templateUrl: './request.component.html',
    styleUrls: ['./request.component.scss'],
})
export class RequestComponent implements OnInit {
    public requests: ICustomerRequest[];
    private clientId: number;
    private attachments: IFileSigned[][];

    public constructor(
        private readonly httpRest: HttpRestService,
        private readonly route: ActivatedRoute,
        private readonly fileManager: FileManagerService,
        private readonly fileDownloader: FileDownloaderService
    ) {}

    public ngOnInit() {
        this.attachments = [];
        this.route.params.subscribe((params) => (this.clientId = parseInt(params.id, DECIMAL_RADIX)));

        this.httpRest
            ._request<ICustomerRequest[]>(
                HTTP_METHOD.GET,
                ApiRoutePlurality.PLURAL,
                CUSTOMER_REQUEST_SCHEMA_ROUTE,
                `client/${this.clientId}`
            )
            .subscribe(async (requests) => {
                this.requests = await Promise.all(
                    requests.map(async (request) => {
                        await this.getAttachments(request);

                        return request;
                    })
                );
            });
    }

    protected changeRequestStatus(request: ICustomerRequest, status: CustomerRequestStatus) {
        this.httpRest
            .post<ICustomerRequest>(CUSTOMER_REQUEST_SCHEMA_ROUTE, {
                id: request.id,
                status,
            })
            .subscribe((updated) => {
                request.status = updated.status;
            });
    }

    protected async getAttachments(request: ICustomerRequest) {
        this.attachments[request.id] = await this.fileManager.signFiles(
            ...(await this.fileManager.getFileList(STORED_TYPE.CUSTOMER_REQUEST_FILE, {
                customerRequest: request,
                customerRequestId: request.id,
            } as Partial<ICustomerRequestFile>))
        );
    }

    protected readonly CustomerRequestStatus = CustomerRequestStatus;

    public readonly getFilename = FileManagerService.getFilename;
    public readonly downloadS3 = this.fileDownloader.downloadS3.bind(this.fileDownloader);
}
