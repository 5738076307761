var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, OnInit } from '@angular/core';
import { ClrLoadingState } from '@clr/angular';
import { NgSelectComponent } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, noop } from 'rxjs';
import { ApiRoutePlurality, HTTP_METHOD, MAX_LENGTH_COGNITO_CUSTOM_GROUP_NAME, MAX_LENGTH_COGNITO_GROUP_DESCRIPTION, RightCategory, } from '../../../../../defs/schema-static';
import { COGNITO_SCHEMA_ROUTE } from '../../../../../defs/schema/public/Cognito';
import { GROUP_RIGHTS_SCHEMA_ROUTE } from '../../../../../defs/schema/public/GroupRights';
import { RIGHT_SCHEMA_ROUTE } from '../../../../../defs/schema/public/Rights';
import { getGroupDisplayName, isColorDark } from '../../app-static';
import { AuthService } from '../../auth/auth.service';
import { ModalSimpleComponent } from '../../modal-simple/modal-simple.component';
import { ModalSimpleService } from '../../modal-simple/modal-simple.service';
import { HttpRestService } from '../../shared/http-rest/http-rest.service';
import { TOAST_TYPE, ToastService } from '../../shared/toast/toast.service';
export var ACCESS_CONTROL_LEVELS;
(function (ACCESS_CONTROL_LEVELS) {
    ACCESS_CONTROL_LEVELS["GROUPS"] = "GROUPS";
    ACCESS_CONTROL_LEVELS["USERS"] = "USERS";
})(ACCESS_CONTROL_LEVELS || (ACCESS_CONTROL_LEVELS = {}));
var createRightTree = function (rights) {
    var categories = Object.values(RightCategory).map(function (cat) { return ({
        name: cat,
        children: [],
        expanded: true,
    }); });
    rights.map(function (r) {
        var idx = categories.map(function (cat) { return cat.name; }).indexOf(r.category);
        if (idx !== -1) {
            categories[idx].children.push(r);
        }
    });
    categories.map(function (cat) {
        cat.children = cat.children.sort(function (a, b) {
            if (a.dependency === b.dependency) {
                return a.code.localeCompare(b.code);
            }
            if (!a.dependency) {
                return -1;
            }
            else if (!b.dependency) {
                return 1;
            }
            return a.dependency - b.dependency;
        });
    });
    return categories;
};
var ɵ0 = createRightTree;
var AdminAccessComponent = (function () {
    function AdminAccessComponent(httpRest, toastService, translate, cdRef, authService, modalSimpleService) {
        this.httpRest = httpRest;
        this.toastService = toastService;
        this.translate = translate;
        this.cdRef = cdRef;
        this.authService = authService;
        this.modalSimpleService = modalSimpleService;
        this.groups = [];
        this.users = [];
        this.rights = [];
        this.groupRights = [];
        this.treeRights = [];
        this.selectedLevel = ACCESS_CONTROL_LEVELS.GROUPS;
        this.selectItems = [];
        this.selectUserGroups = [];
        this.initialSelectUserGroups = [];
        this.selectUserGroupsChanged = false;
        this.saveUserGroupsBtnState = ClrLoadingState.DEFAULT;
        this.deleteGroupBtnState = ClrLoadingState.DEFAULT;
        this.showEditModal = false;
        this.editGroupBtnState = ClrLoadingState.DEFAULT;
        this.showConfirmSudokuModal = false;
        this.allExpanded = true;
        this.isColorDark = isColorDark;
        this.ACCESS_CONTROL_LEVELS = ACCESS_CONTROL_LEVELS;
        this.MAX_LENGTH_COGNITO_CUSTOM_GROUP_NAME = MAX_LENGTH_COGNITO_CUSTOM_GROUP_NAME;
        this.MAX_LENGTH_COGNITO_GROUP_DESCRIPTION = MAX_LENGTH_COGNITO_GROUP_DESCRIPTION;
        this.selectUserGroup = null;
    }
    AdminAccessComponent.prototype.ngOnInit = function () {
        var _this = this;
        forkJoin(this.getGroupList(), this.getUsersList(), this.getRightsList()).subscribe(function () {
            _this.itemsSelect.focus();
            _this.itemsSelect.open();
        });
        this.translate.get('depends_on').subscribe(function (translation) {
            _this.dependsOnStr = translation;
        });
    };
    AdminAccessComponent.prototype.getGroupList = function () {
        var _this = this;
        var obs = this.httpRest._request(HTTP_METHOD.GET, ApiRoutePlurality.SINGULAR, COGNITO_SCHEMA_ROUTE, 'groups');
        obs.subscribe(function (groups) {
            _this.groups = groups.map(function (g) {
                if (!!g.description) {
                    g.description = g.description.trim();
                }
                return g;
            });
            _this.selectItems = _this.selectItems.concat(_this.groups
                .filter(function (g) { return g.groupName !== 'admin'; })
                .map(function (g) { return ({
                cognitoId: g.groupName,
                displayName: getGroupDisplayName(g.groupName),
                level: ACCESS_CONTROL_LEVELS.GROUPS,
            }); }));
            _this.cdRef.markForCheck();
        });
        return obs;
    };
    AdminAccessComponent.prototype.expandAll = function () {
        var _this = this;
        this.allExpanded = !this.allExpanded;
        this.treeRights.map(function (tr) { return (tr.expanded = _this.allExpanded); });
    };
    AdminAccessComponent.prototype.getUsersList = function () {
        var _this = this;
        var obs = this.httpRest._request(HTTP_METHOD.GET, ApiRoutePlurality.SINGULAR, COGNITO_SCHEMA_ROUTE, 'users');
        obs.subscribe(function (users) {
            _this.selectItems = _this.selectItems.concat((_this.users = users.map(function (u) { return ({
                cognitoId: u.cognitoUsername,
                cognitoUsername: u.cognitoUsername,
                displayName: u.name,
                level: ACCESS_CONTROL_LEVELS.USERS,
                user: {
                    code: u.code,
                    color: u.color,
                },
                groups: u.groups.map(function (g) {
                    g.displayName = getGroupDisplayName(g.groupName);
                    return __assign({}, g, { cognitoId: g.groupName });
                }),
            }); })));
            _this.cdRef.markForCheck();
        });
        return obs;
    };
    AdminAccessComponent.prototype.getRightsList = function () {
        var _this = this;
        var obs = this.httpRest._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, RIGHT_SCHEMA_ROUTE, 'list');
        obs.subscribe(function (rights) {
            _this.rights = rights;
            _this.treeRights = createRightTree(rights);
        });
        return obs;
    };
    AdminAccessComponent.prototype.selectItem = function (item) {
        this.selectedUser = null;
        this.selectedGroup = null;
        if (!item) {
            this.selectedLevel = null;
            return;
        }
        this.selectedLevel = item.level;
        if (this.selectedLevel === ACCESS_CONTROL_LEVELS.USERS) {
            this.selectedUser = this.users.find(function (u) { return u.cognitoUsername === item.cognitoId; });
            this.setGroupSelectMenu();
        }
        else if (this.selectedLevel === ACCESS_CONTROL_LEVELS.GROUPS) {
            this.selectedGroup = this.groups.find(function (g) { return g.groupName === item.cognitoId; });
            this.getGroupRightsList();
        }
    };
    AdminAccessComponent.prototype.getAccessibility = function (right) {
        delete right.tip;
        if (!right.dependency) {
            return true;
        }
        var dependency = this.rights.find(function (r) { return r.id === right.dependency; });
        if (dependency && !dependency.active) {
            right.tip = this.dependsOnStr + " " + dependency.code;
            return false;
        }
        return true;
    };
    AdminAccessComponent.prototype.resetRightsList = function () {
        var _this = this;
        this.rights.map(function (r) {
            r.active = false;
            r.accessible = _this.getAccessibility(r);
        });
        this.rights = this.rights.slice();
        this.cdRef.markForCheck();
    };
    AdminAccessComponent.prototype.getGroupRightsList = function () {
        var _this = this;
        this.resetRightsList();
        this.httpRest
            ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, GROUP_RIGHTS_SCHEMA_ROUTE, "" + this.selectedGroup.groupName)
            .subscribe(function (groupRights) {
            _this.groupRights = groupRights;
            if (_this.groupRights.length > 0 && _this.groupRights[0].groupName === _this.selectedGroup.groupName) {
                _this.groupRights.map(function (gr) {
                    var right = _this.rights.find(function (r) { return r.id === gr.rightId; });
                    if (right) {
                        right.active = true;
                    }
                });
            }
            _this.rights.map(function (right) {
                right.accessible = _this.getAccessibility(right);
            });
            _this.rights = _this.rights.slice();
            _this.cdRef.markForCheck();
        });
    };
    AdminAccessComponent.prototype.resolveAccessibility = function (right) {
        var _this = this;
        this.rights
            .filter(function (r) { return r.dependency === right.id; })
            .map(function (r) {
            r.accessible = right.active;
            if (!right.active) {
                r.active = false;
                var groupRightIdx_1 = _this.groupRights.findIndex(function (f) { return f.groupName === _this.selectedGroup.groupName && f.rightId === r.id; });
                if (groupRightIdx_1 !== -1) {
                    _this.httpRest
                        .deleteId(GROUP_RIGHTS_SCHEMA_ROUTE, _this.groupRights[groupRightIdx_1].id)
                        .subscribe(function () {
                        _this.groupRights.splice(groupRightIdx_1, 1);
                        _this.cdRef.markForCheck();
                    });
                }
            }
        });
        this.rights = this.rights.slice();
        this.cdRef.markForCheck();
    };
    AdminAccessComponent.prototype.switchRight = function (right) {
        var _this = this;
        this.resolveAccessibility(right);
        if (right.active) {
            this.httpRest
                .put(GROUP_RIGHTS_SCHEMA_ROUTE, {
                rightId: right.id,
                groupName: this.selectedGroup.groupName,
            })
                .subscribe(function (groupRight) {
                _this.groupRights.push(groupRight);
                _this.cdRef.markForCheck();
            });
        }
        else {
            var groupRightIdx_2 = this.groupRights.findIndex(function (f) { return f.groupName === _this.selectedGroup.groupName && f.rightId === right.id; });
            if (groupRightIdx_2 !== -1) {
                this.httpRest.deleteId(GROUP_RIGHTS_SCHEMA_ROUTE, this.groupRights[groupRightIdx_2].id).subscribe(function () {
                    _this.groupRights.splice(groupRightIdx_2, 1);
                    _this.cdRef.markForCheck();
                });
            }
        }
    };
    AdminAccessComponent.prototype.deleteGroupModal = function () {
        var _this = this;
        this.modalSimpleService
            .open(ModalSimpleComponent, {
            title: 'group_delete_modal_title',
            contentI18n: 'swal_delete_attch',
            contentObj: { what: this.selectedItem.displayName },
            ok: {
                i18n: 'delete_group',
                class: 'btn-danger',
            },
        })
            .subscribe(function (closed) {
            if (closed.result) {
                _this.deleteSelectedGroup();
            }
        }, noop);
    };
    AdminAccessComponent.prototype.deleteSelectedGroup = function () {
        var _this = this;
        if (!this.selectedGroup) {
            return;
        }
        var groupName = this.selectedGroup.groupName;
        if (!groupName) {
            return;
        }
        this.httpRest
            ._request(HTTP_METHOD.DELETE, ApiRoutePlurality.SINGULAR, COGNITO_SCHEMA_ROUTE, "group/" + groupName)
            .subscribe(function (el) {
            var groupIdx = _this.groups.findIndex(function (g) { return g.groupName === el.groupName; });
            _this.groups.splice(groupIdx, 1);
            var itemIdx = _this.selectItems.findIndex(function (i) { return i.cognitoId === el.groupName; });
            _this.selectItems.splice(itemIdx, 1);
            _this.selectItems = _this.selectItems.slice();
            _this.users.map(function (user) {
                var userGroupIdx = user.groups.findIndex(function (g) { return g.groupName === el.groupName; });
                if (userGroupIdx !== -1) {
                    user.groups.splice(userGroupIdx, 1);
                }
            });
            _this.selectedItem = null;
            _this.selectedGroup = null;
            _this.cdRef.markForCheck();
        });
    };
    AdminAccessComponent.prototype.setGroupSelectMenu = function () {
        var _this = this;
        this.selectUserGroupsChanged = false;
        var user = this.users.find(function (u) { return u.cognitoUsername === _this.selectedUser.cognitoUsername; });
        if (!user) {
            this.selectUserGroups = [];
            this.initialSelectUserGroups = [];
            return;
        }
        var groupNames = (user.groups || []).map(function (_a) {
            var groupName = _a.groupName;
            return groupName;
        });
        this.selectUserGroups = this.groups.filter(function (_a) {
            var groupName = _a.groupName;
            return groupNames.includes(groupName);
        });
        this.initialSelectUserGroups = this.selectUserGroups.slice();
        this.cdRef.detectChanges();
    };
    AdminAccessComponent.prototype.checkSelectUserGroupsChanged = function () {
        var _a = [this.selectUserGroups, this.initialSelectUserGroups].map(function (groups) { return groups.map(function (_a) {
            var groupName = _a.groupName;
            return groupName;
        }).sort(); }), selectedSerialized = _a[0], initialSerialized = _a[1];
        this.selectUserGroupsChanged = selectedSerialized.toString() !== initialSerialized.toString();
    };
    AdminAccessComponent.prototype.saveUserGroups = function () {
        var _this = this;
        this.saveUserGroupsBtnState = ClrLoadingState.LOADING;
        var _a = [this.selectUserGroups, this.initialSelectUserGroups].map(function (groups) { return groups.map(function (_a) {
            var groupName = _a.groupName;
            return groupName;
        }); }), selectedGroupNames = _a[0], initialGroupNames = _a[1];
        var groupsToAdd = this.selectUserGroups.filter(function (_a) {
            var groupName = _a.groupName;
            return !initialGroupNames.includes(groupName);
        });
        var groupsToDelete = this.initialSelectUserGroups.filter(function (_a) {
            var groupName = _a.groupName;
            return !selectedGroupNames.includes(groupName);
        });
        forkJoin(groupsToAdd.map(function (_a) {
            var groupName = _a.groupName;
            return _this.addGroupToUser(groupName);
        }).concat(groupsToDelete.map(function (_a) {
            var groupName = _a.groupName;
            return _this.removeGroupFromUser(groupName);
        }))).subscribe(function () {
            _this.initialSelectUserGroups = _this.selectUserGroups.slice();
            _this.selectUserGroupsChanged = false;
            _this.saveUserGroupsBtnState = ClrLoadingState.DEFAULT;
            _this.cdRef.markForCheck();
        }, function () {
            _this.saveUserGroupsBtnState = ClrLoadingState.DEFAULT;
            _this.cdRef.markForCheck();
        });
        if (this.showConfirmSudokuModal) {
            this.showConfirmSudokuModal = false;
            this.cdRef.markForCheck();
        }
    };
    AdminAccessComponent.prototype.addGroupToUser = function (groupName) {
        return this.httpRest._request(HTTP_METHOD.PUT, ApiRoutePlurality.SINGULAR, COGNITO_SCHEMA_ROUTE, 'user', {
            username: this.selectedUser.cognitoUsername,
            groupName: groupName,
        });
    };
    AdminAccessComponent.prototype.removeGroupFromUser = function (groupName) {
        var _this = this;
        if (groupName === 'admin') {
            var admins = this.users.filter(function (u) {
                return u.cognitoUsername !== _this.selectedUser.cognitoUsername &&
                    u.groups.find(function (g) { return g.groupName === groupName; });
            }).length;
            if (!admins) {
                this.toastService.show({
                    type: TOAST_TYPE.ERROR,
                    text: 'cognito_minimum_admin',
                });
                return undefined;
            }
        }
        return this.httpRest._request(HTTP_METHOD.DELETE, ApiRoutePlurality.SINGULAR, COGNITO_SCHEMA_ROUTE, "user/" + this.selectedUser.cognitoUsername + "/" + groupName);
    };
    AdminAccessComponent.prototype.addGroup = function () {
        this.currentGroupEdit = {
            groupName: '',
            description: '',
            isNew: true,
            invalid: true,
        };
        this.showEditModal = true;
    };
    AdminAccessComponent.prototype.editGroup = function () {
        this.currentGroupEdit = {
            groupName: this.selectedItem.displayName,
            description: this.selectedGroup.description,
            isNew: false,
        };
        this.showEditModal = true;
    };
    AdminAccessComponent.prototype.duplicateGroup = function () {
        this.currentGroupEdit = {
            groupName: this.selectedItem.displayName,
            description: this.selectedGroup.description,
            isNew: true,
            copyFrom: this.selectedGroup.groupName,
            invalid: true,
        };
        this.showEditModal = true;
    };
    AdminAccessComponent.prototype.isValid = function () {
        this.currentGroupEdit.invalid = !!(!this.currentGroupEdit.groupName ||
            this.currentGroupEdit.groupName.indexOf(' ') !== -1 ||
            (this.currentGroupEdit.copyFrom &&
                getGroupDisplayName(this.currentGroupEdit.copyFrom) === this.currentGroupEdit.groupName));
    };
    AdminAccessComponent.prototype.saveGroup = function () {
        var _this = this;
        this.currentGroupEdit.description = (this.currentGroupEdit.description || '').trim();
        this.editGroupBtnState = ClrLoadingState.LOADING;
        if (this.currentGroupEdit.isNew) {
            var from_1 = this.currentGroupEdit.copyFrom;
            this.httpRest
                ._request(HTTP_METHOD.PUT, ApiRoutePlurality.SINGULAR, COGNITO_SCHEMA_ROUTE, 'group', this.currentGroupEdit)
                .subscribe(function (el) {
                if (from_1 !== '') {
                    _this.httpRest
                        ._request(HTTP_METHOD.POST, ApiRoutePlurality.PLURAL, GROUP_RIGHTS_SCHEMA_ROUTE, 'copy', {
                        from: from_1,
                        to: "_" + _this.currentGroupEdit.groupName,
                    })
                        .subscribe(function () {
                        _this.groups.push(el);
                        _this.selectItems.push({
                            cognitoId: el.groupName,
                            displayName: getGroupDisplayName(el.groupName),
                            level: ACCESS_CONTROL_LEVELS.GROUPS,
                        });
                        _this.selectItems = _this.selectItems.slice();
                        _this.selectedItem = _this.selectItems[_this.selectItems.length - 1];
                        _this.selectItem(_this.selectedItem);
                        _this.showEditModal = false;
                        _this.editGroupBtnState = ClrLoadingState.SUCCESS;
                        _this.cdRef.markForCheck();
                    }, function () { return (_this.editGroupBtnState = ClrLoadingState.ERROR); });
                }
                else {
                    _this.groups.push(el);
                    _this.selectItems.push({
                        cognitoId: el.groupName,
                        displayName: getGroupDisplayName(el.groupName),
                        level: ACCESS_CONTROL_LEVELS.GROUPS,
                    });
                    _this.selectItems = _this.selectItems.slice();
                    _this.selectedItem = _this.selectItems[_this.selectItems.length - 1];
                    _this.selectItem(_this.selectedItem);
                    _this.showEditModal = false;
                    _this.editGroupBtnState = ClrLoadingState.SUCCESS;
                    _this.cdRef.markForCheck();
                }
            }, function (err) {
                if (err && ['GroupExistsException'].includes(err.error.err)) {
                    _this.toastService.show({
                        type: TOAST_TYPE.ERROR,
                        text: 'group_name_exists',
                    });
                }
                if (err && ['InvalidParameterException'].includes(err.error.err)) {
                    _this.toastService.show({
                        type: TOAST_TYPE.ERROR,
                        text: 'group_name_no_spaces',
                    });
                }
                _this.editGroupBtnState = ClrLoadingState.ERROR;
            });
        }
        else {
            this.httpRest
                ._request(HTTP_METHOD.POST, ApiRoutePlurality.SINGULAR, COGNITO_SCHEMA_ROUTE, 'group', this.currentGroupEdit)
                .subscribe(function (el) {
                el.description = el.description.trim();
                _this.groups.find(function (m) { return m.groupName === el.groupName; }).description = el.description;
                _this.selectedGroup.description = el.description;
                _this.showEditModal = false;
                _this.editGroupBtnState = ClrLoadingState.SUCCESS;
                _this.cdRef.markForCheck();
            }, function () { return (_this.editGroupBtnState = ClrLoadingState.ERROR); });
        }
    };
    AdminAccessComponent.prototype.saveGroupsChanges = function () {
        if (this.selectedUser.id === this.authService.user.id) {
            this.showConfirmSudokuModal = true;
        }
        else {
            this.saveUserGroups();
        }
    };
    AdminAccessComponent.prototype.enter = function (right) {
        if (!right.dependency) {
            return;
        }
        var dependency = this.rights.find(function (r) { return r.id === right.dependency; });
        dependency.highlight = true;
    };
    AdminAccessComponent.prototype.leave = function (right) {
        if (!right.dependency) {
            return;
        }
        var dependency = this.rights.find(function (r) { return r.id === right.dependency; });
        dependency.highlight = false;
    };
    return AdminAccessComponent;
}());
export { AdminAccessComponent };
export { ɵ0 };
