import {ALL_RIGHTS, ISchemaRoute, RightCategory} from '../../schema-static';
import {IGroupRights} from './GroupRights';

export enum RIGHT_FIELD {
    id = 'id',
    code = 'code',
    visible = 'visible',
    category = 'category',
    dependency = 'dependency',
}

export interface IRight {
    id?: number;
    code: ALL_RIGHTS;
    visible: boolean;
    groupRights?: IGroupRights[];
    category?: RightCategory;
    dependency?: number;
}

const RIGHT_SCHEMA_ROUTE: ISchemaRoute = {
    tableName: 'rights',
    singularRoute: 'right',
    pluralRoute: 'rights',
};

export {RIGHT_SCHEMA_ROUTE};
