import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {COGNITO_USER_GROUPS} from '../../../../defs/schema-static';
import {AuthGuard} from './auth.guard';
import {AuthService} from './auth.service';

@Injectable()
export class ClientAuthGuard extends AuthGuard {
    public readonly requiredGroup: COGNITO_USER_GROUPS = COGNITO_USER_GROUPS.CLIENT;

    public constructor(protected authService: AuthService, protected router: Router) {
        super(authService, router);
    }
}
