import * as i0 from "./modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@clr/angular";
import * as i3 from "@angular/common";
import * as i4 from "../shared/movable/movable.directive";
import * as i5 from "./modal.component";
import * as i6 from "../shared/shortcut-handler/shortcut-handler.service";
import * as i7 from "../shared/control-flow/control-flow.service";
var styles_ModalComponent = [i0.styles];
var RenderType_ModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalComponent, data: {} });
export { RenderType_ModalComponent as RenderType_ModalComponent };
function View_ModalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal(true, true, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "clr-icon", [["aria-hidden", "true"], ["shape", "close"]], null, null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i2.ClrIconCustomTag, [], null, null)], null, null); }
function View_ModalComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_ModalComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalComponent_5)), i1.ɵdid(1, 540672, null, 0, i3.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.modalHeader; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ModalComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 2, 0, currVal_0); }); }
function View_ModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "modal-header movable-handle"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalComponent_3)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalComponent_4)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalComponent_6)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.preventDismiss; _ck(_v, 2, 0, currVal_0); var currVal_1 = !!_co.modalHeader; _ck(_v, 4, 0, currVal_1); var currVal_2 = (!_co.modalHeader && !!_co.title); _ck(_v, 6, 0, currVal_2); }, null); }
function View_ModalComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_ModalComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalComponent_8)), i1.ɵdid(2, 540672, null, 0, i3.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.modalBody; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ModalComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_ModalComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[1, 0], ["modalFooterRef", 1]], null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalComponent_10)), i1.ɵdid(2, 540672, null, 0, i3.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.modalFooter; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ModalComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["aria-hidden", "true"], ["class", "modal-backdrop"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal(true, true, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
function View_ModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [["modal", 1]], null, 11, "div", [["class", "modal"]], [[4, "zIndex", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["appMovable", ""], ["aria-hidden", "true"], ["class", "modal-dialog"], ["role", "dialog"]], [[2, "modal-sm", null], [2, "modal-lg", null], [2, "modal-xl", null]], [[null, "mousedown"], ["document", "mousemove"], ["document", "mouseup"]], function (_v, en, $event) { var ad = true; if (("mousedown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).mouseDown($event) !== false);
        ad = (pd_0 && ad);
    } if (("document:mousemove" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).mouseMove($event) !== false);
        ad = (pd_1 && ad);
    } if (("document:mouseup" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2).mouseUp($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 4210688, null, 0, i4.MovableDirective, [i1.NgZone, i1.ElementRef], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalComponent_2)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalComponent_7)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalComponent_9)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalComponent_11)), i1.ɵdid(11, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = (!!_co.modalHeader || !!_co.title); _ck(_v, 5, 0, currVal_4); var currVal_5 = !!_co.modalBody; _ck(_v, 7, 0, currVal_5); var currVal_6 = !!_co.modalFooter; _ck(_v, 9, 0, currVal_6); var currVal_7 = _co.show; _ck(_v, 11, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.zIndex; _ck(_v, 0, 0, currVal_0); var currVal_1 = (_co.size === "sm"); var currVal_2 = (_co.size === "lg"); var currVal_3 = (_co.size === "xl"); _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3); }); }
export function View_ModalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { modalFooterRef: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalComponent_1)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.show; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "app-modal", [], null, null, null, View_ModalComponent_0, RenderType_ModalComponent)), i1.ɵdid(1, 770048, null, 3, i5.ModalComponent, [i6.ShortcutHandlerService, i7.ControlFlowService, i1.ChangeDetectorRef], null, null), i1.ɵqud(335544320, 1, { modalHeader: 0 }), i1.ɵqud(335544320, 2, { modalBody: 0 }), i1.ɵqud(335544320, 3, { modalFooter: 0 })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalComponentNgFactory = i1.ɵccf("app-modal", i5.ModalComponent, View_ModalComponent_Host_0, { show: "show", title: "title", class: "class", preventDismiss: "preventDismiss", baseZIndex: "baseZIndex", size: "appModalSize" }, { showChange: "showChange", shown: "shown", hidden: "hidden", dismiss: "dismiss" }, []);
export { ModalComponentNgFactory as ModalComponentNgFactory };
