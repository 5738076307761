<div class="spinner-bg-c" *ngIf="!client"><div class="spinner-c"></div></div>
<div class="column-container" *ngIf="client">
    <div class="clr-row">
        <div class="clr-col-6">
            <ul class="breadcrumb" style="font-size: .7rem;">
                <li class="breadcrumb-item"><a [routerLink]="['/clients']" translate>clients</a></li>
                <li class="breadcrumb-item" *ngIf="client && client.user">
                    {{ client.user.name }}
                    <app-long-avatar
                        [type]="LONG_AVATAR_TYPE.BADGE_100"
                        [code]="client.user.code"
                        [bgColor]="client.user.color"
                    ></app-long-avatar>
                </li>
            </ul>
        </div>
        <div class="clr-col-6 text-right">
            <button class="btn btn-primary" *sharedAccessControl="RIGHTS.CLIENT_UPDATE" (click)="showEditModal = true">
                {{ 'edit_client_info' | translate }}
            </button>
        </div>
    </div>

    <div class="clr-row">
        <div class="clr-col-12 ox-h">
            <table class="info-table mb-07">
                <tbody>
                    <tr>
                        <td rowspan="2">
                            <app-long-avatar
                                [code]="client.user.code"
                                [bgColor]="client.user.color"
                                style="font-size: 2em; line-height: 2em;"
                            ></app-long-avatar>
                        </td>

                        <td rowspan="2">
                            <h3 class="mt-0 flex-1">
                                <strong>{{ client.user.name }}</strong>
                            </h3>
                        </td>

                        <td
                            *ngIf="
                                (client.projects && client.projects.length) ||
                                (client.contacts && client.contacts.length)
                            "
                        >
                            <ng-container *ngIf="client.projects?.length > 1; else projectList">
                                {{ 'projects_count' | translate: {count: client.projects.length} }}
                                <clr-signpost>
                                    <clr-signpost-content [clrPosition]="'bottom-right'" *clrIfOpen>
                                        <h3 class="mt-0" translate>projects</h3>
                                        <ul class="list mt-05">
                                            <ng-container *ngIf="false; else projectList"></ng-container>
                                        </ul>
                                    </clr-signpost-content>
                                </clr-signpost>
                            </ng-container>

                            <ng-template #projectList>
                                <p class="small-label" translate *ngIf="client.projects?.length === 1">
                                    project
                                </p>

                                <li *ngFor="let project of client.projects">
                                    <ng-container *ngIf="project">
                                        <a [routerLink]="['/project', project.id]">{{ project.obs }}</a>
                                        <app-long-avatar
                                            class="ml-02"
                                            [type]="LONG_AVATAR_TYPE.BADGE_100"
                                            [code]="project.code"
                                            [bgColor]="project.color"
                                        ></app-long-avatar>

                                        <span [classList]="PROJECT_STATUS_FILTER[project.status].classList">
                                            <clr-icon
                                                [attr.shape]="PROJECT_STATUS_FILTER[project.status].icon"
                                            ></clr-icon>
                                            {{ PROJECT_STATUS_FILTER[project.status].translation | translate }}
                                        </span>
                                    </ng-container>
                                </li>
                            </ng-template>
                        </td>
                        <td *ngIf="client.obs" rowspan="2">
                            <p class="small-label" translate>placeholder_observation</p>
                            <p class="observation mt-0 oy-a">{{ client.obs }}</p>
                        </td>
                    </tr>
                    <tr>
                        <td class="d-none"></td>
                        <td *ngIf="client.contacts && client.contacts.length">
                            <ng-container *ngIf="client.contacts.length > 1; else contactList">
                                {{ 'customer_contacts_count' | translate: {count: client.contacts.length} }}
                                <clr-signpost>
                                    <clr-signpost-content [clrPosition]="'bottom-right'" *clrIfOpen>
                                        <h3 class="mt-0" translate>customer_contacts</h3>
                                        <ul class="list mt-05">
                                            <ng-container *ngIf="false; else contactList"></ng-container>
                                        </ul>
                                    </clr-signpost-content>
                                </clr-signpost>
                            </ng-container>

                            <ng-template #contactList>
                                <p class="small-label" translate *ngIf="client.contacts?.length === 1">
                                    customer_contact
                                </p>

                                <li *ngFor="let contact of client.contacts">
                                    <ng-container *ngIf="contact && contact.user">
                                        <a [routerLink]="['/employee', contact.id]">{{ contact.user.name }}</a>
                                        <app-long-avatar
                                            class="ml-02"
                                            [type]="LONG_AVATAR_TYPE.BADGE_100"
                                            [code]="contact.user.code"
                                            [bgColor]="contact.user.color"
                                        ></app-long-avatar>
                                    </ng-container>
                                </li>
                            </ng-template>
                        </td>
                        <td *ngIf="client.obs" class="d-none"></td>
                    </tr>
                    <tr></tr>
                </tbody>
            </table>
        </div>
    </div>

    <!--
        <form clrForm #f="ngForm">
            <div class="clr-row">
                <div class="clr-col-4">
                    <clr-input-container>
                        <label class="clr-col-xs-12 clr-col-md-3" translate>placeholder_name</label>
                        <input
                            class="clr-col-xs-12 clr-col-md-9"
                            clrInput
                            type="text"
                            [disabled]="!authService.hasRight(RIGHTS.CLIENT_UPDATE)"
                            [(ngModel)]="client.user.name"
                            name="name"
                            appRequiredTrim
                            required
                        />
                    </clr-input-container>

                    <clr-input-container>
                        <label class="clr-col-xs-12 clr-col-md-3" translate>placeholder_code</label>
                        <input
                            class="clr-col-xs-12 clr-col-md-9"
                            clrInput
                            type="text"
                            [disabled]="!authService.hasRight(RIGHTS.CLIENT_UPDATE)"
                            [(ngModel)]="client.user.code"
                            maxlength="5"
                            name="code"
                            appRequiredTrim
                            appUppercaseInput
                            required
                        />

                        <clr-control-helper
                            >{{ MAX_LENGTH_CLIENT_CODE }} {{ 'max_length_code' | translate }}</clr-control-helper
                        >
                    </clr-input-container>
                    <clr-textarea-container>
                        <label class="clr-col-xs-12 clr-col-md-3" translate>placeholder_observation</label>
                        <textarea
                            class="clr-col-xs-12 clr-col-md-9"
                            clrTextarea
                            sharedListAutoIndent
                            sharedAutosize
                            [(ngModel)]="client.obs"
                            [disabled]="!authService.hasRight(RIGHTS.CLIENT_UPDATE)"
                            name="observation"
                        ></textarea>
                    </clr-textarea-container>
                </div>
                <div class="clr-col-4">
                    <div class="avatar-container">
                        <app-long-avatar [code]="client?.user.code" [bgColor]="pickedColor">
                            <clr-icon
                                *ngIf="authService.hasRight(RIGHTS.CLIENT_UPDATE)"
                                class="c-hand text-dark"
                                style="position: absolute; top: 0; right: 0"
                                shape="color-picker"
                                [(colorPicker)]="pickedColor"
                                [cpOutputFormat]="'hex'"
                                [cpOKButton]="true"
                                [cpCancelButton]="true"
                            ></clr-icon>
                        </app-long-avatar>
                    </div>
                </div>
                <div class="clr-col-4">
                    <ng-select
                        [items]="employees"
                        [hideSelected]="true"
                        bindLabel="user.name"
                        multiple="true"
                        (add)="addSelectedContact($event)"
                        [(ngModel)]="selectedEmployees"
                        [ngModelOptions]="{standalone: true}"
                        [placeholder]="'contact_select' | translate"
                    >
                        <ng-template ng-label-tmp let-item="item">
                            <span class="label label-gray">
                                <span class="px-02"> {{ item.user.name }}</span>
                                <clr-icon class="c-hand" shape="times" (click)="deleteContact(item)"></clr-icon>
                            </span>
                        </ng-template>
                    </ng-select>
                </div>
            </div>
        </form>

        <div class="clr-row">
            <div class="clr-col">
                <button
                    class="btn btn-success"
                    [clrLoading]="validateBtnState"
                    [disabled]="!f.dirty || !validate(f, false)"
                    (click)="saveClient(f)"
                >
                    {{ 'form_save' | translate }}
                </button>
            </div>
        </div>

        <div class="clr-row">
            <div class="clr-col"><div class="divider"></div></div>
        </div>
    -->

    <div class="clr-row flex-1">
        <app-tabs class="w-100" [labels]="CLIENT_TABS_LABELS" queryParamKey="tab">
            <app-tab
                label="{{ CLIENT_TABS.MILESTONES | translate }}"
                [name]="CLIENT_TABS.MILESTONES"
                *sharedAccessControl="CLIENT_TABS_RIGHTS[CLIENT_TABS.MILESTONES]"
            >
                <ng-template appTabContent>
                    <div class="column-container py-02">
                        <div class="clr-row flex-1">
                            <app-milestones-list
                                class="column-container w-100"
                                [client]="client"
                                [milestones]="milestones"
                            ></app-milestones-list>
                        </div>
                    </div>
                </ng-template>
            </app-tab>
            <app-tab
                [name]="CLIENT_TABS.PROJECTS"
                label="{{ CLIENT_TABS.PROJECTS | translate }}"
                *sharedAccessControl="CLIENT_TABS_RIGHTS[CLIENT_TABS.PROJECTS]"
            >
                <ng-template appTabContent>
                    <div class="column-container py-02">
                        <div class="clr-row flex-1">
                            <app-projects-list
                                class="column-container clr-col-12"
                                [wizardClients]="[client]"
                                [wizardClientId]="client.id"
                                [projects]="projects"
                                [columns]="projectsColumns"
                            ></app-projects-list>
                        </div>
                    </div>
                </ng-template>
            </app-tab>
            <ng-container *sharedAccessControl="CLIENT_TABS_RIGHTS[CLIENT_TABS.REQUESTS]">
                <app-tab
                    [name]="CLIENT_TABS.REQUESTS"
                    label="{{ CLIENT_TABS.REQUESTS | translate }}"
                    *ngIf="publicConfig.appParams.module_requests"
                >
                    <ng-template appTabContent> <app-request></app-request> </ng-template>
                </app-tab>
            </ng-container>
            <ng-container *sharedAccessControl="CLIENT_TABS_RIGHTS[CLIENT_TABS.INCIDENTS]">
                <app-tab
                    [name]="CLIENT_TABS.INCIDENTS"
                    label="{{ CLIENT_TABS.INCIDENTS | translate }}"
                    *ngIf="publicConfig.appParams.module_incidents"
                >
                    <ng-template appTabContent> <app-incident></app-incident> </ng-template>
                </app-tab>
            </ng-container>
            <ng-container *sharedAccessControl="CLIENT_TABS_RIGHTS[CLIENT_TABS.CHANGELOGS]">
                <app-tab
                    [name]="CLIENT_TABS.CHANGELOGS"
                    label="{{ CLIENT_TABS.CHANGELOGS | translate }}"
                    *ngIf="publicConfig.appParams.module_changelogs"
                >
                    <ng-template appTabContent>
                        <app-changelogs-list class="column-container w-100" [client]="client"></app-changelogs-list>
                    </ng-template>
                </app-tab>
            </ng-container>
            <ng-container *sharedAccessControl="CLIENT_TABS_RIGHTS[CLIENT_TABS.BACKUPS]">
                <app-tab
                    [name]="CLIENT_TABS.BACKUPS"
                    label="{{ CLIENT_TABS.BACKUPS | translate }}"
                    [disabled]="!!(client.id && !client.backupPrefix)"
                    *ngIf="publicConfig.appParams.module_backups"
                >
                    <ng-template appTabContent> <app-backups-list [client]="client"></app-backups-list> </ng-template>
                </app-tab>
            </ng-container>
            <ng-container *sharedAccessControl="RIGHTS.CLIENT_STICKY_READ">
                <app-tab [name]="CLIENT_TABS.NOTES" [label]="CLIENT_TABS.NOTES | translate">
                    <ng-template appTabContent>
                        <app-sticky-notes
                            [stickyNotes]="stickyNotes"
                            [clientId]="client?.id"
                            [editAllowed]="authService.hasRight(RIGHTS.CLIENT_STICKY_UPDATE)"
                        ></app-sticky-notes>
                    </ng-template>
                </app-tab>
            </ng-container>
        </app-tabs>
    </div>
</div>

<app-modal [(show)]="showEditModal" [title]="'client' | translate" [appModalSize]="'lg'">
    <ng-template appModalBody>
        <app-client-wizard-client [client]="client" [formLayout]="'vertical'"></app-client-wizard-client>
        <app-client-wizard-customer-contacts
            [client]="client"
            [contacts]="contacts"
            [dropdownPosition]="'top'"
        ></app-client-wizard-customer-contacts>
    </ng-template>
    <ng-template appModalFooter>
        <div class="mr-a">
            <button class="btn btn-danger" *sharedAccessControl="RIGHTS.PJ_DELETE" (click)="deleteClientModal()">
                {{ 'delete_client' | translate }}
            </button>
        </div>
        <div>
            <button class="btn btn-secondary" (click)="showEditModal = false">{{ 'form_cancel' | translate }}</button>
            <button
                class="btn btn-success"
                (click)="saveClient()"
                [disabled]="!clientEditComponent?.form.valid"
                [clrLoading]="validateBtnState"
            >
                {{ 'save_client' | translate }}
            </button>
        </div>
    </ng-template>
</app-modal>
