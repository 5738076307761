import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'convertNewLines',
})
export class ConvertNewLinesPipe implements PipeTransform {
    public transform = (value: string, separator: string): string => {
        return (value.trim() || '').replace(/\n/g, `${separator || '<br>'}\n`);
    };
}
