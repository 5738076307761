import {Component} from '@angular/core';
import {COGNITO_USER_GROUPS} from '../../../../defs/schema-static';
import {ADMIN_PANEL_TABS, ADMIN_PANEL_TABS_LABELS} from '../app-static';
import {AuthService} from '../auth/auth.service';

@Component({
    selector: 'app-admin-panel',
    templateUrl: './admin-panel.component.html',
    styleUrls: ['./admin-panel.component.scss'],
})
export class AdminComponent {
    public constructor(public authService: AuthService) {}

    public readonly COGNITO_USER_GROUPS = COGNITO_USER_GROUPS;
    public readonly ADMIN_PANEL_TABS = ADMIN_PANEL_TABS;
    public readonly ADMIN_PANEL_TABS_LABELS = ADMIN_PANEL_TABS_LABELS;
}
