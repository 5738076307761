var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnDestroy } from '@angular/core';
import { ApiRoutePlurality, HTTP_METHOD } from '../../../../defs/schema-static';
import { NOTIFICATION_SCHEMA_ROUTE, NOTIFICATION_TYPE } from '../../../../defs/schema/public/Notification';
import { AuthService } from '../auth/auth.service';
import { HttpRestService } from './http-rest/http-rest.service';
import { MomentService } from './moment/moment.service';
import * as i0 from "@angular/core";
import * as i1 from "./http-rest/http-rest.service";
import * as i2 from "../auth/auth.service";
import * as i3 from "./moment/moment.service";
var NotificationService = (function () {
    function NotificationService(httpRest, authService, momentService) {
        var _this = this;
        this.httpRest = httpRest;
        this.authService = authService;
        this.momentService = momentService;
        this.onNotificationClicked = function (notification) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.markNotificationAsSeen(notification)];
            });
        }); };
        this.userChangeSubscription = this.authService.userChange.subscribe(function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
            return [2, this.init()];
        }); }); });
        (function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
            return [2, this.init()];
        }); }); })();
    }
    NotificationService.prototype.ngOnDestroy = function () {
        clearInterval(this.checkInterval);
        if (this.userChangeSubscription) {
            this.userChangeSubscription.unsubscribe();
            this.userChangeSubscription = null;
        }
    };
    NotificationService.prototype.init = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                clearInterval(this.checkInterval);
                if (!this.authService.user) {
                    return [2];
                }
                this.notifications = sessionStorage.notifications ? JSON.parse(sessionStorage.notifications) : [];
                Notification.requestPermission().then(function () { return (_this.permission = Notification.permission); });
                (function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                    return [2, this.fetchNotifications()];
                }); }); })();
                this.checkInterval = window.setInterval(function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                    return [2, this.fetchNotifications()];
                }); }); }, 1000 * 60);
                return [2];
            });
        });
    };
    Object.defineProperty(NotificationService.prototype, "notificationsNotClosed", {
        get: function () {
            if (!this.authService.user || !this.notifications) {
                return [];
            }
            return this.notifications.filter(function (_a) {
                var closed = _a.closed;
                return !closed;
            });
        },
        enumerable: true,
        configurable: true
    });
    NotificationService.prototype.markNotificationAsSeen = function (notification) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                notification.seen = true;
                return [2, this._updateNotification(notification)];
            });
        });
    };
    NotificationService.prototype.markNotificationAsClosed = function (notification) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                notification.closed = true;
                return [2, this._updateNotification(notification)];
            });
        });
    };
    NotificationService.prototype.getNotificationText = function (notification) {
        if (notification.type === NOTIFICATION_TYPE.EVENT_REMINDER) {
            return notification.milestone.obs + " @ " + this.momentService
                .moment(notification.milestone.beginDate)
                .format('L LTS');
        }
        if (notification.type === NOTIFICATION_TYPE.TASK_ASSIGNED) {
            return notification.task.project.code + "-" + notification.task.code + " " + notification.task.name;
        }
        return 'unknown type';
    };
    NotificationService.prototype._updateNotification = function (notification) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.httpRest
                            .post(NOTIFICATION_SCHEMA_ROUTE, notification)
                            .toPromise()];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    NotificationService.prototype.sendNotification = function (notification) {
        var _this = this;
        if (this.permission === 'granted') {
            this._sendBrowserNotification(notification);
        }
        else if (this.permission !== 'denied') {
            Notification.requestPermission().then(function (permission) {
                _this.permission = permission;
                _this._sendBrowserNotification(notification);
            });
        }
    };
    NotificationService.prototype._sendBrowserNotification = function (notification) {
        var _this = this;
        var _notification = new Notification(this.getNotificationText(notification));
        _notification.onclose = _notification.onclick = function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
            return [2, this.onNotificationClicked(notification)];
        }); }); };
    };
    NotificationService.prototype.fetchNotifications = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, notifications, existingNotificationIds_1, newNotifications, newNotificationIds_1, removedNotifications;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4, this.httpRest
                            ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, NOTIFICATION_SCHEMA_ROUTE, '')
                            .toPromise()];
                    case 1:
                        notifications = _b.sent();
                        {
                            existingNotificationIds_1 = this.notifications.map(function (_a) {
                                var id = _a.id;
                                return id;
                            });
                            newNotifications = notifications.filter(function (_a) {
                                var id = _a.id;
                                return !existingNotificationIds_1.includes(id);
                            });
                            (_a = this.notifications).push.apply(_a, newNotifications);
                            newNotifications
                                .filter(function (_a) {
                                var seen = _a.seen, closed = _a.closed;
                                return !seen && !closed;
                            })
                                .map(function (notification) { return _this.sendNotification(notification); });
                        }
                        {
                            newNotificationIds_1 = notifications.map(function (_a) {
                                var id = _a.id;
                                return id;
                            });
                            removedNotifications = this.notifications.filter(function (_a) {
                                var id = _a.id;
                                return !newNotificationIds_1.includes(id);
                            });
                            if (removedNotifications.length) {
                                this.notifications = this.notifications.filter(function (_a) {
                                    var id = _a.id;
                                    return newNotificationIds_1.includes(id);
                                }).slice();
                            }
                        }
                        return [2];
                }
            });
        });
    };
    NotificationService.ngInjectableDef = i0.defineInjectable({ factory: function NotificationService_Factory() { return new NotificationService(i0.inject(i1.HttpRestService), i0.inject(i2.AuthService), i0.inject(i3.MomentService)); }, token: NotificationService, providedIn: "root" });
    return NotificationService;
}());
export { NotificationService };
