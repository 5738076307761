<div class="toaster">
    <clr-alert
        class="mb-02"
        [clrAlertAppLevel]="true"
        [clrAlertType]="toast.type"
        [(clrAlertClosed)]="toast.closed"
        *ngFor="let toast of toastService.toasts"
    >
        <clr-alert-item>
            <span class="alert-text" translate>{{ toast.text }}</span>
            <div class="alert-actions" *ngIf="toast.callback">
                <button class="btn alert-action" (click)="toast.callback()" translate>{{ toast.callbackText }}</button>
            </div>
        </clr-alert-item>
    </clr-alert>
</div>

<div class="top-toaster">
    <clr-alert [clrAlertAppLevel]="true" [clrAlertType]="toast.type" *ngFor="let toast of toastService.appLevelToasts">
        <clr-alert-item>
            <span class="alert-text" translate>{{ toast.text }}</span>

            <div class="alert-actions" *ngIf="toast.callback">
                <button class="btn alert-action" (click)="toast.callback()" translate>{{ toast.callbackText }}</button>
            </div>
        </clr-alert-item>
    </clr-alert>
</div>
