// tslint:disable max-classes-per-file
import {Directive, TemplateRef} from '@angular/core';

@Directive({selector: '[appModalHeader]'})
export class ModalHeaderDirective {
    public constructor(public template: TemplateRef<any>) {}
}

@Directive({selector: '[appModalBody]'})
export class ModalBodyDirective {
    public constructor(public template: TemplateRef<any>) {}
}

@Directive({selector: '[appModalFooter]'})
export class ModalFooterDirective {
    public constructor(public template: TemplateRef<any>) {}
}
