import * as i0 from "./incident.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "../../modal/modal.component.ngfactory";
import * as i5 from "../../modal/modal.component";
import * as i6 from "../../shared/shortcut-handler/shortcut-handler.service";
import * as i7 from "../../shared/control-flow/control-flow.service";
import * as i8 from "../../modal/modal.directives";
import * as i9 from "./incident.component";
import * as i10 from "../../shared/http-rest/http-rest.service";
import * as i11 from "../../auth/auth.service";
var styles_IncidentComponent = [i0.styles];
var RenderType_IncidentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IncidentComponent, data: {} });
export { RenderType_IncidentComponent as RenderType_IncidentComponent };
function View_IncidentComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "spinner-bg-c-small"], ["style", "margin-top: 15em;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "spinner-c"]], null, null, null, null, null))], null, null); }
function View_IncidentComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.selectedIncident == null) ? null : _co.selectedIncident.description); _ck(_v, 0, 0, currVal_0); }); }
export function View_IncidentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "clr-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "h2", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(3, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["incidents"])), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "column clr-col-12"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IncidentComponent_1)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "input", [["class", "d-block filter-input"], ["type", "text"]], [[8, "placeholder", 0]], [[null, "keyup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keyup" === en)) {
        var pd_0 = (_co.updateFilter($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(10, 0, null, null, 6, "app-modal", [], null, [[null, "showChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("showChange" === en)) {
        var pd_0 = ((_co.displayModal = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ModalComponent_0, i4.RenderType_ModalComponent)), i1.ɵdid(11, 770048, null, 3, i5.ModalComponent, [i6.ShortcutHandlerService, i7.ControlFlowService, i1.ChangeDetectorRef], { show: [0, "show"], title: [1, "title"] }, { showChange: "showChange" }), i1.ɵqud(335544320, 1, { modalHeader: 0 }), i1.ɵqud(335544320, 2, { modalBody: 0 }), i1.ɵqud(335544320, 3, { modalFooter: 0 }), (_l()(), i1.ɵand(0, [[2, 2]], null, 1, null, View_IncidentComponent_2)), i1.ɵdid(16, 16384, null, 0, i8.ModalBodyDirective, [i1.TemplateRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ""; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.pleaseWait; _ck(_v, 7, 0, currVal_1); var currVal_3 = _co.displayModal; var currVal_4 = ((_co.selectedIncident == null) ? null : _co.selectedIncident.title); _ck(_v, 11, 0, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("placeholder_filter")); _ck(_v, 8, 0, currVal_2); }); }
export function View_IncidentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-portal-incident", [], null, null, null, View_IncidentComponent_0, RenderType_IncidentComponent)), i1.ɵdid(1, 49152, null, 0, i9.IncidentComponent, [i10.HttpRestService, i11.AuthService], null, null)], null, null); }
var IncidentComponentNgFactory = i1.ɵccf("app-portal-incident", i9.IncidentComponent, View_IncidentComponent_Host_0, {}, {}, []);
export { IncidentComponentNgFactory as IncidentComponentNgFactory };
