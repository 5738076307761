<span class="label label-gray mb-02">
    <clr-icon
        *ngIf="type === EMPLOYEE_MEMBER_TYPE.PROJECT_MEMBER"
        shape="star"
        [class.c-hand]="updatable"
        [class.is-solid]="member?.type === ProjectMemberType.MANAGER"
        (click)="updatable && toggleType()"
    ></clr-icon>

    <span class="px-02"> {{ member?.employee?.user.name }}</span>
    <clr-icon *ngIf="removable" class="c-hand" shape="times" (click)="removeMember()"></clr-icon>
</span>
