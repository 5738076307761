<app-modal
    [class]="'modal-dialog modal-lg'"
    [appModalSize]="'lg'"
    [title]="title | lowercase | translate"
    [(show)]="show"
    (shown)="modalShown()"
    (hidden)="modalHidden()"
    (showChange)="showChange.emit($event)"
    *ngIf="!displayInline"
>
    <ng-template appModalBody>
        <div class="global-search-container">
            <dl class="clr-row" *ngIf="searchScope === AutocompleteCategory.GLOBAL">
                <ng-container *ngFor="let categoryKey of CATEGORY_KEY_KEYS">
                    <div class="column clr-col-2 text-center" *sharedAccessControl="CATEGORY_RIGHTS[categoryKey]">
                        <dt class="c-hand" (click)="searchScope = categoryKey">
                            {{ categoryKey | slugify | translate }}
                        </dt>
                        <dd>
                            <strong style="font-family: monospace">{{ CATEGORY_KEY[categoryKey] }}</strong>
                            <i>{{ categoryKey | slugify }}</i>
                        </dd>
                    </div>
                </ng-container>
            </dl>

            <div class="clr-row" *ngIf="searchScope !== AutocompleteCategory.GLOBAL">
                <div class="clr-col-3">
                    <button class="btn" (click)="searchScope = AutocompleteCategory.GLOBAL">
                        <clr-icon shape="caret" style="transform: rotate(270deg);"></clr-icon>
                        {{ 'global_search' | translate }}
                    </button>
                </div>
            </div>

            <form class="clr-row">
                <div class="clr-col-1">
                    <p
                        class="category-key text-right"
                        [class.d-invisible]="searchScope === AutocompleteCategory.GLOBAL"
                    >
                        {{ CATEGORY_KEY[searchScope] || '&nbsp;' }}
                    </p>
                </div>
                <div class="clr-col-10">
                    <clr-input-container>
                        <input
                            clrInput
                            placeholder="{{ 'placeholder_start_typing' | translate }}"
                            #autocompleteInput
                            name="autocomplete"
                            [(ngModel)]="query"
                            [sharedAutofocus]="show"
                        />
                    </clr-input-container>
                </div>
            </form>
        </div>
    </ng-template>
</app-modal>

<ng-container *ngIf="displayInline">
    <label class="searchbar-label" for="search-icons-sticky" [ngClass]="{isCollapsed: isCollapsed}">
        <clr-icon (click)="focusInput()" shape="search" size="20"></clr-icon>
        <button class="close" aria-label="Close" type="button" (click)="query = ''" [ngClass]="{active: query !== ''}">
            <clr-icon shape="close" size="20"></clr-icon>
        </button>
        <input
            #autocompleteInput
            class="searchbar-input ng-pristine ng-valid ng-touched"
            id="search-icons-sticky"
            [placeholder]="'global_search' | translate"
            type="text"
            name="autocomplete"
            [(ngModel)]="query"
            [sharedAutofocus]="show"
        />
    </label>
</ng-container>
