import * as i0 from "@angular/core";
import * as i1 from "@clr/angular";
import * as i2 from "@angular/common";
import * as i3 from "./editable-employee-label.component";
var styles_EditableEmployeeLabelComponent = [];
var RenderType_EditableEmployeeLabelComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EditableEmployeeLabelComponent, data: {} });
export { RenderType_EditableEmployeeLabelComponent as RenderType_EditableEmployeeLabelComponent };
function View_EditableEmployeeLabelComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "clr-icon", [["shape", "star"]], [[2, "c-hand", null], [2, "is-solid", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.updatable && _co.toggleType()) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 16384, null, 0, i1.ClrIconCustomTag, [], null, null)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.updatable; var currVal_1 = (((_co.member == null) ? null : _co.member.type) === _co.ProjectMemberType.MANAGER); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_EditableEmployeeLabelComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "clr-icon", [["class", "c-hand"], ["shape", "times"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeMember() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 16384, null, 0, i1.ClrIconCustomTag, [], null, null)], null, null); }
export function View_EditableEmployeeLabelComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "span", [["class", "label label-gray mb-02"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EditableEmployeeLabelComponent_1)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [["class", "px-02"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, [" ", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EditableEmployeeLabelComponent_2)), i0.ɵdid(6, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.type === _co.EMPLOYEE_MEMBER_TYPE.PROJECT_MEMBER); _ck(_v, 2, 0, currVal_0); var currVal_2 = _co.removable; _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.member == null) ? null : ((_co.member.employee == null) ? null : _co.member.employee.user.name)); _ck(_v, 4, 0, currVal_1); }); }
export function View_EditableEmployeeLabelComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "shared-editable-employee-label", [], null, null, null, View_EditableEmployeeLabelComponent_0, RenderType_EditableEmployeeLabelComponent)), i0.ɵdid(1, 49152, null, 0, i3.EditableEmployeeLabelComponent, [], null, null)], null, null); }
var EditableEmployeeLabelComponentNgFactory = i0.ɵccf("shared-editable-employee-label", i3.EditableEmployeeLabelComponent, View_EditableEmployeeLabelComponent_Host_0, { member: "member", type: "type", updatable: "updatable", removable: "removable" }, { update: "update", remove: "remove" }, []);
export { EditableEmployeeLabelComponentNgFactory as EditableEmployeeLabelComponentNgFactory };
