import {EventEmitter, Injectable} from '@angular/core';
import {Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {FormControl, FormGroup} from 'ngx-strongly-typed-forms';
import {ITask} from '../../../../defs/schema/public/Tasks';
import {ITime, TIME_SCHEMA_ROUTE} from '../../../../defs/schema/public/Times';
import {AuthService} from '../auth/auth.service';
import {HttpRestService} from '../shared/http-rest/http-rest.service';
import {MomentService} from '../shared/moment/moment.service';
import {TimerService} from '../shared/timer/timer.service';
import {TOAST_TYPE, ToastService} from '../shared/toast/toast.service';
import {FormsAddTaskService, IAddTaskFormModal} from './add-task/add-task.service';
import {requiredTrimValidator} from './validators/required-trim.validator';

export enum TaskTarget {
    ASSIGNED = 'assigned',
    EXISTING = 'existing',
    NEW = 'new',
}

export enum AddTimeFormField {
    taskTarget = 'taskTarget',
    assignedTask = 'assignedTask',
    project = 'project',
    task = 'task',
    taskId = 'taskId',
    startDate = 'startDate',
    endDate = 'endDate',
    time = 'time',
    employeeId = 'employeeId',
}

export interface IAddTimeFormModal {
    taskTarget: TaskTarget;
    assignedTask: number;
    project: number;
    task?: string;
    taskId: number;
    startDate: string;
    endDate: string;
    time: string;
    timeId?: number;
    employeeId: number;
}

@Injectable({
    providedIn: 'root',
})
export class FormsAddTimeService {
    public onSubmit: EventEmitter<ITime> = new EventEmitter();

    public constructor(
        private readonly httpRest: HttpRestService,
        private readonly toastService: ToastService,
        private readonly authService: AuthService,
        private readonly formsAddTaskService: FormsAddTaskService,
        private readonly translate: TranslateService,
        private readonly timerService: TimerService,
        private readonly momentService: MomentService
    ) {}

    public static getFormGroup(): FormGroup<IAddTimeFormModal> {
        return new FormGroup<IAddTimeFormModal>({
            taskTarget: new FormControl<TaskTarget>(null, Validators.required),
            assignedTask: new FormControl<number>(null, Validators.required),
            project: new FormControl<number>(null, Validators.required),
            task: new FormControl<string>(),
            timeId: new FormControl<number>(),
            taskId: new FormControl<number>(null, Validators.required),
            startDate: new FormControl<string>(null, Validators.required),
            endDate: new FormControl<string>(null, Validators.required),
            time: new FormControl<string>(null, requiredTrimValidator()),
            employeeId: new FormControl<number>(null, Validators.required),
        });
    }

    public async submitTask(taskForm: FormGroup<IAddTaskFormModal>): Promise<number> {
        return new Promise<number>((resolve, reject) => {
            this.formsAddTaskService.submit(taskForm, (task: ITask) => {
                if (!task) {
                    return reject(0);
                }

                return resolve(task.id);
            });
        });
    }

    public async submit(f: IAddTimeFormModal, taskForm?: FormGroup<IAddTaskFormModal>, callback?: (t: ITime) => void) {
        if (!f.taskTarget) {
            return false;
        }

        let taskId: number;

        if (f.taskTarget === TaskTarget.NEW) {
            taskId = await this.submitTask(taskForm);

            if (!taskId || taskId <= 0) {
                return false;
            }
        } else {
            taskId = TaskTarget.ASSIGNED === f.taskTarget ? f.assignedTask : f.taskId;
            if (!taskId) {
                this.toastService.show({
                    type: TOAST_TYPE.WARNING,
                    text: 'please_select_task',
                });

                return false;
            }
        }

        await this.doSubmit(f, taskId, callback);
        this.timerService.timer.stop();

        return true;
    }

    public static reset(f: FormGroup<IAddTimeFormModal>) {
        f.reset();
    }

    public async doSubmit(f: IAddTimeFormModal, taskId: number, callback?: (t: ITime) => void) {
        let time;
        if (!f.timeId) {
            time = await this.httpRest
                .put<ITime>(TIME_SCHEMA_ROUTE, {
                    employeeId: f.employeeId,
                    taskId,
                    time: f.time,
                    beginDate: this.momentService.moment(f.startDate),
                    endDate: this.momentService.moment(f.startDate),
                })
                .toPromise();
            {
                this.toastService.show({
                    type: TOAST_TYPE.SUCCESS,
                    text: 'success_insert_time',
                });
                if (callback) {
                    callback(time);
                }
                this.onSubmit.emit(time);
            }

            return;
        }

        time = await this.httpRest
            .post<ITime>(TIME_SCHEMA_ROUTE, {
                id: f.timeId,
                employeeId: f.employeeId,
                time: f.time,
                beginDate: this.momentService.moment(f.startDate),
            })
            .toPromise();
        {
            this.toastService.show({
                type: TOAST_TYPE.SUCCESS,
                text: 'success_update_time',
            });
            if (callback) {
                callback(time);
            }
            this.onSubmit.emit(time);
        }
    }
}
