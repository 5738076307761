var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a, _b, _c, _d;
import { NgZone, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { RIGHTS } from '../../../../../defs/schema-static';
import { APP_MODULE_ROUTE_PATH, WIKI_MODULE_ROUTE_PATH } from '../../app-static';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
export var SHORTCUT_CREATE;
(function (SHORTCUT_CREATE) {
    SHORTCUT_CREATE["CLIENT"] = "CREATE_CLIENT";
    SHORTCUT_CREATE["PROJECT"] = "CREATE_PROJECT";
    SHORTCUT_CREATE["EMPLOYEE"] = "CREATE_EMPLOYEE";
    SHORTCUT_CREATE["TASK"] = "CREATE_TASK";
    SHORTCUT_CREATE["MILESTONE"] = "CREATE_MILESTONE";
    SHORTCUT_CREATE["TIME"] = "CREATE_TIME";
    SHORTCUT_CREATE["WIKI_PAGE"] = "CREATE_WIKI_PAGE";
})(SHORTCUT_CREATE || (SHORTCUT_CREATE = {}));
export var SHORTCUT_LOCAL;
(function (SHORTCUT_LOCAL) {
    SHORTCUT_LOCAL["CREATE"] = "LOCAL_CREATE";
    SHORTCUT_LOCAL["CANCEL"] = "LOCAL_CANCEL";
    SHORTCUT_LOCAL["CONFIRM"] = "LOCAL_CONFIRM";
})(SHORTCUT_LOCAL || (SHORTCUT_LOCAL = {}));
export var SHORTCUT_TODO;
(function (SHORTCUT_TODO) {
    SHORTCUT_TODO["CANCEL_TODO"] = "CANCEL_TODO_TODO";
    SHORTCUT_TODO["DONE_TODO"] = "DONE_TODO_TODO";
    SHORTCUT_TODO["SAVE_TODO"] = "SAVE_TODO_TODO";
    SHORTCUT_TODO["ADD_CATEGORY"] = "ADD_CATEGORY_TODO";
    SHORTCUT_TODO["MOVE_TODO_UP"] = "MOVE_TODO_UP";
    SHORTCUT_TODO["MOVE_TODO_DOWN"] = "MOVE_TODO_DOWN";
    SHORTCUT_TODO["DELETE_TODO"] = "DELETE_TODO";
})(SHORTCUT_TODO || (SHORTCUT_TODO = {}));
export var SHORTCUT_MULTIPLE_ADD;
(function (SHORTCUT_MULTIPLE_ADD) {
    SHORTCUT_MULTIPLE_ADD["ADD_TEMP"] = "ADD_TEMP";
})(SHORTCUT_MULTIPLE_ADD || (SHORTCUT_MULTIPLE_ADD = {}));
export var SHORTCUT_WIZARD;
(function (SHORTCUT_WIZARD) {
    SHORTCUT_WIZARD["NEXT"] = "NEXT";
})(SHORTCUT_WIZARD || (SHORTCUT_WIZARD = {}));
export var SHORTCUT_QUICK_ACTION;
(function (SHORTCUT_QUICK_ACTION) {
    SHORTCUT_QUICK_ACTION["CREATE_TASK"] = "CREATE_TASK_QUICK_ACTION";
    SHORTCUT_QUICK_ACTION["CREATE_MILESTONE"] = "CREATE_MILESTONE_QUICK_ACTION";
    SHORTCUT_QUICK_ACTION["CREATE_TIME"] = "CREATE_TIME_QUICK_ACTION";
})(SHORTCUT_QUICK_ACTION || (SHORTCUT_QUICK_ACTION = {}));
export var SHORTCUT_MISC;
(function (SHORTCUT_MISC) {
    SHORTCUT_MISC["SIDEBAR"] = "MISC_SIDEBAR";
    SHORTCUT_MISC["QUICK_ACTION"] = "MISC_QUICK_ACTION";
    SHORTCUT_MISC["GLOBAL_SEARCH"] = "MISC_GLOBAL_SEARCH";
    SHORTCUT_MISC["TOGGLE_TIMER"] = "MISC_TOGGLE_TIMER";
    SHORTCUT_MISC["TEXTAREA_SUBMIT"] = "MISC_TEXTAREA_SUBMIT";
})(SHORTCUT_MISC || (SHORTCUT_MISC = {}));
export var SHORTCUT_NUMBER = [
    ['²', '~', '@'],
    ['1', '&'],
    ['2', 'é'],
    ['3', '"'],
    ['4', "'"],
    ['5', '('],
    ['6', '-', '§'],
    ['7', 'è'],
    ['8', '_', '!'],
    ['9', 'ç'],
    ['0', 'à'],
    ['°', ')'],
];
export var SHORTCUT_COMBO = (_a = {},
    _a[SHORTCUT_LOCAL.CREATE] = 'c',
    _a[SHORTCUT_LOCAL.CANCEL] = 'Esc',
    _a[SHORTCUT_LOCAL.CONFIRM] = 'Enter',
    _a[SHORTCUT_MISC.GLOBAL_SEARCH] = ['/', ':'],
    _a[SHORTCUT_MISC.QUICK_ACTION] = 'shiftKey+C',
    _a[SHORTCUT_MISC.TOGGLE_TIMER] = 'ctrlKey+ ',
    _a[SHORTCUT_MISC.TEXTAREA_SUBMIT] = 'ctrlKey+Enter',
    _a[SHORTCUT_TODO.CANCEL_TODO] = 'ctrlKey+altKey+F',
    _a[SHORTCUT_TODO.MOVE_TODO_UP] = 'ctrlKey+shiftKey+ArrowUp',
    _a[SHORTCUT_TODO.MOVE_TODO_DOWN] = 'ctrlKey+shiftKey+ArrowDown',
    _a[SHORTCUT_TODO.DELETE_TODO] = 'ctrlKey+shiftKey+K',
    _a[SHORTCUT_TODO.DONE_TODO] = 'ctrlKey+shiftKey+D',
    _a[SHORTCUT_TODO.SAVE_TODO] = 'Enter',
    _a[SHORTCUT_TODO.ADD_CATEGORY] = 'shiftKey+Enter',
    _a[SHORTCUT_MULTIPLE_ADD.ADD_TEMP] = 'Enter',
    _a[SHORTCUT_WIZARD.NEXT] = 'Enter',
    _a[SHORTCUT_QUICK_ACTION.CREATE_TASK] = SHORTCUT_NUMBER[1],
    _a[SHORTCUT_QUICK_ACTION.CREATE_MILESTONE] = SHORTCUT_NUMBER[2],
    _a[SHORTCUT_QUICK_ACTION.CREATE_TIME] = SHORTCUT_NUMBER[3],
    _a);
export var SHORTCUT_ROUTE = (_b = {},
    _b[SHORTCUT_CREATE.CLIENT] = "/" + APP_MODULE_ROUTE_PATH.CLIENTS,
    _b[SHORTCUT_CREATE.PROJECT] = "/" + APP_MODULE_ROUTE_PATH.PROJECTS,
    _b[SHORTCUT_CREATE.EMPLOYEE] = "/" + APP_MODULE_ROUTE_PATH.EMPLOYEES,
    _b[SHORTCUT_CREATE.WIKI_PAGE] = "/" + WIKI_MODULE_ROUTE_PATH.WIKI + "/" + WIKI_MODULE_ROUTE_PATH.LIST,
    _b);
export var SHORTCUT_LABEL = (_c = {},
    _c[SHORTCUT_CREATE.CLIENT] = 'create_new_client',
    _c[SHORTCUT_CREATE.PROJECT] = 'create_new_project',
    _c[SHORTCUT_CREATE.EMPLOYEE] = 'create_new_employee',
    _c[SHORTCUT_CREATE.TASK] = 'create_new_task',
    _c[SHORTCUT_CREATE.MILESTONE] = 'create_new_milestone',
    _c[SHORTCUT_CREATE.TIME] = 'create_new_time',
    _c[SHORTCUT_CREATE.WIKI_PAGE] = 'create_new_wiki_page',
    _c[SHORTCUT_MISC.QUICK_ACTION] = 'open_quick_action',
    _c[SHORTCUT_MISC.TOGGLE_TIMER] = 'toggle_timer',
    _c);
export var SHORTCUT_RIGHTS = (_d = {},
    _d[SHORTCUT_CREATE.CLIENT] = RIGHTS.CLIENT_CREATE,
    _d[SHORTCUT_CREATE.PROJECT] = RIGHTS.PROJECT_CREATE,
    _d[SHORTCUT_CREATE.EMPLOYEE] = RIGHTS.EMPLOYEE_CREATE,
    _d[SHORTCUT_CREATE.TASK] = RIGHTS.TASK_CREATE,
    _d[SHORTCUT_CREATE.MILESTONE] = RIGHTS.MS_UPDATE,
    _d[SHORTCUT_CREATE.TIME] = RIGHTS.TIME_UPDATE,
    _d[SHORTCUT_CREATE.WIKI_PAGE] = RIGHTS.WIKI_UPDATE,
    _d[SHORTCUT_MISC.TOGGLE_TIMER] = RIGHTS.TIME_UPDATE,
    _d);
var ESCAPE_KEYS = ['Escape', 'Esc'];
var ENTER_KEYS = ['Enter', 'Return'];
var ARROW_KEYS = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'];
var SPECIAL_KEYS = ESCAPE_KEYS.concat(ENTER_KEYS, ARROW_KEYS);
var MODIFIER_KEYS = ['ctrlKey', 'shiftKey', 'altKey', 'metaKey'];
var ShortcutHandlerService = (function () {
    function ShortcutHandlerService(router, zone) {
        var _this = this;
        this.router = router;
        this.zone = zone;
        this.handlers = [];
        this.isChrome = /chrome/i.test(navigator.userAgent);
        var events = {
            keydown: function (e) { return _this.isKeydown(e); },
            keypress: function (e) { return !_this.isKeydown(e); },
        };
        this.zone.runOutsideAngular(function () {
            Object.keys(events).map(function (event) {
                fromEvent(window, event)
                    .pipe(debounceTime(50))
                    .subscribe(function (e) {
                    _this.zone.run(function () { return events[event](e) && _this.trigger(e); });
                });
            });
        });
    }
    ShortcutHandlerService.prototype.isKeydown = function (event) {
        return this.isChrome || SPECIAL_KEYS.indexOf(event.key) !== -1 || event.ctrlKey;
    };
    ShortcutHandlerService.prototype.trigger = function (event) {
        if (!event.key) {
            return;
        }
        var handler = this.handlers.slice().reverse().find(function (_handler) { return _handler.shortcutFn(event); });
        if (!handler || (event.target.tagName !== 'BODY' && !handler.forceListen)) {
            return;
        }
        event.preventDefault();
        event.stopPropagation();
        handler.callback(event);
    };
    ShortcutHandlerService.prototype.execute = function (shortcut, params) {
        return __awaiter(this, void 0, void 0, function () {
            var handler;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        handler = this.handlers.find(function (_handler) { return _handler.name === shortcut; });
                        if (handler) {
                            handler.callback(params);
                        }
                        if (!(SHORTCUT_ROUTE[shortcut] && this.router.url !== SHORTCUT_ROUTE[shortcut])) return [3, 2];
                        return [4, this.router.navigate([SHORTCUT_ROUTE[shortcut]])];
                    case 1:
                        if (_a.sent()) {
                            return [2, this.execute(shortcut)];
                        }
                        _a.label = 2;
                    case 2: return [2];
                }
            });
        });
    };
    ShortcutHandlerService.prototype.register = function (params, unregisterOnDestroy) {
        var _this = this;
        if (!params.shortcut && !params.name) {
            throw new Error('undefined shortcut');
        }
        var shortcuts = (typeof SHORTCUT_COMBO[params.shortcut] === 'string'
            ? [SHORTCUT_COMBO[params.shortcut]]
            : SHORTCUT_COMBO[params.shortcut]);
        if (shortcuts &&
            this.handlers.find(function (_handler) {
                return shortcuts.indexOf(_handler.shortcut) !== -1 &&
                    (!params.context || _handler.context === params.context) &&
                    (!params.name || _handler.name === params.name);
            })) {
            console.warn("Duplicate shortcut: " + shortcuts + " (" + params.shortcut + ")");
            return undefined;
        }
        if (params.context && unregisterOnDestroy) {
            if (!params.context.ngOnDestroy) {
                throw new Error("unregisterOnDestroy, but component doesn't implement OnDestroy");
            }
            var context_1 = params.context;
            var onDestroy_1 = context_1.ngOnDestroy.bind(context_1);
            context_1.ngOnDestroy = function () {
                onDestroy_1();
                _this.unregister(context_1);
            };
        }
        var handler = {
            name: params.name,
            shortcut: params.shortcut,
            shortcutFn: function (event) {
                return shortcuts &&
                    !!shortcuts.find(function (shortcut) {
                        return !shortcut
                            .split('+')
                            .find(function (token) {
                            return !!MODIFIER_KEYS.find(function (mod) { return !shortcut.includes(mod) && !!event[mod]; }) ||
                                (ESCAPE_KEYS.includes(token) && !ESCAPE_KEYS.includes(event.key)) ||
                                (ENTER_KEYS.includes(token) && !ENTER_KEYS.includes(event.key)) ||
                                ((ARROW_KEYS.includes(token) || token.length === 1) &&
                                    event.key.toLowerCase() !== token.toLowerCase()) ||
                                (!SPECIAL_KEYS.includes(token) && token.length > 1 && !event[token]);
                        });
                    });
            },
            callback: params.callback,
            context: params.context,
            forceListen: params.forceListen,
        };
        this.handlers.push(handler);
        return handler;
    };
    ShortcutHandlerService.prototype.unregisterHandlers = function () {
        var handlers = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            handlers[_i] = arguments[_i];
        }
        this.handlers = this.handlers.filter(function (handler) { return !handlers.includes(handler); });
    };
    ShortcutHandlerService.prototype.unregister = function (context) {
        var shortcuts = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            shortcuts[_i - 1] = arguments[_i];
        }
        this.handlers = this.handlers.filter(function (handler) { return handler.context !== context || (shortcuts.length && shortcuts.indexOf(handler.shortcut) === -1); });
    };
    ShortcutHandlerService.ngInjectableDef = i0.defineInjectable({ factory: function ShortcutHandlerService_Factory() { return new ShortcutHandlerService(i0.inject(i1.Router), i0.inject(i0.NgZone)); }, token: ShortcutHandlerService, providedIn: "root" });
    return ShortcutHandlerService;
}());
export { ShortcutHandlerService };
