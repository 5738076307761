import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiRoutePlurality, HTTP_METHOD} from '../../../../../defs/schema-static';
import {CONFIG_SCHEMA_ROUTE, IConfig, IRules} from '../../../../../defs/schema/meta/Config';
import {AuthService} from '../../auth/auth.service';
import {HttpRestService} from '../http-rest/http-rest.service';

@Injectable({
    providedIn: 'root',
})
export class ConfigService {
    public config: IConfig[];
    private _configObservable: Observable<IConfig[]>;

    public constructor(private readonly httpRest: HttpRestService, private readonly authService: AuthService) {
        if (this.authService.user) {
            (async () => this.reload())();
        }

        this.authService.userChange.subscribe(async () => this.reload());
    }

    public async get(): Promise<IConfig[]> {
        return this.config || (this.configObservable && this.configObservable.toPromise());
    }

    public async set(config: Partial<IRules>) {
        this.config = null;
        this._configObservable = this.httpRest._request<IConfig[]>(
            HTTP_METHOD.POST,
            ApiRoutePlurality.SINGULAR,
            CONFIG_SCHEMA_ROUTE,
            'businessRules',
            config
        );
    }

    private async reload() {
        // tslint:disable-next-line:no-unused-expression
        this.configObservable &&
            this.configObservable.toPromise().then((_config) => {
                this.config = _config;
            });
    }

    public get configObservable(): Observable<IConfig[]> {
        if (!this.authService.user) {
            return null;
        }

        if (this._configObservable) {
            return this._configObservable;
        }

        return (this._configObservable = this.httpRest._request<IConfig[]>(
            HTTP_METHOD.GET,
            ApiRoutePlurality.SINGULAR,
            CONFIG_SCHEMA_ROUTE,
            'businessRules'
        ));
    }
}
