var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { EventEmitter } from '@angular/core';
import { Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, FormGroup } from 'ngx-strongly-typed-forms';
import { ISO_DATE_FORMAT, MAX_LENGTH_DEFAULT, MilestonesType } from '../../../../defs/schema-static';
import { MEETING_PARTICIPANT_SCHEMA_ROUTE, } from '../../../../defs/schema/public/MeetingParticipants';
import { MILESTONE_SCHEMA_ROUTE } from '../../../../defs/schema/public/Milestones';
import { noop } from '../app-static';
import { AuthService } from '../auth/auth.service';
import { HttpRestService } from '../shared/http-rest/http-rest.service';
import { MomentService } from '../shared/moment/moment.service';
import { TOAST_TYPE, ToastService } from '../shared/toast/toast.service';
import { requiredTrimValidator } from './validators/required-trim.validator';
import { semVerValidator } from './validators/semver.validator';
import * as i0 from "@angular/core";
import * as i1 from "../shared/http-rest/http-rest.service";
import * as i2 from "../shared/toast/toast.service";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../auth/auth.service";
import * as i5 from "../shared/moment/moment.service";
export var MilestoneTarget;
(function (MilestoneTarget) {
    MilestoneTarget["CLIENT"] = "client";
    MilestoneTarget["PROJECT"] = "project";
})(MilestoneTarget || (MilestoneTarget = {}));
export var MILESTONE_FORM_KEYS;
(function (MILESTONE_FORM_KEYS) {
    MILESTONE_FORM_KEYS["type"] = "type";
    MILESTONE_FORM_KEYS["targetType"] = "targetType";
    MILESTONE_FORM_KEYS["project"] = "project";
    MILESTONE_FORM_KEYS["client"] = "client";
    MILESTONE_FORM_KEYS["milestone"] = "milestone";
    MILESTONE_FORM_KEYS["description"] = "description";
    MILESTONE_FORM_KEYS["target"] = "target";
    MILESTONE_FORM_KEYS["startDate"] = "startDate";
    MILESTONE_FORM_KEYS["startTime"] = "startTime";
    MILESTONE_FORM_KEYS["endDate"] = "endDate";
    MILESTONE_FORM_KEYS["endTime"] = "endTime";
    MILESTONE_FORM_KEYS["recursive"] = "recursive";
    MILESTONE_FORM_KEYS["version"] = "version";
    MILESTONE_FORM_KEYS["participants"] = "participants";
})(MILESTONE_FORM_KEYS || (MILESTONE_FORM_KEYS = {}));
var FormsAddMilestoneService = (function () {
    function FormsAddMilestoneService(httpRest, toastService, translate, authService, momentService) {
        var _this = this;
        this.httpRest = httpRest;
        this.toastService = toastService;
        this.translate = translate;
        this.authService = authService;
        this.momentService = momentService;
        this.onSubmit = new EventEmitter();
        this.onAbort = new EventEmitter();
        this.beginEndDates = function (b, bt, e, et) {
            var beginDate = _this.momentService.moment(b);
            var endDate = _this.momentService.moment(e);
            if (bt && et && _this.momentService.moment(beginDate).isSame(_this.momentService.moment(endDate))) {
                return bt <= et;
            }
            return _this.momentService.moment(beginDate).isSameOrBefore(_this.momentService.moment(endDate));
        };
        this.checkDates = function (form, begin, end, target, type) {
            form.patchValue({
                startDate: begin ? _this.momentService.moment(begin).format(ISO_DATE_FORMAT) : null,
                endDate: end ? _this.momentService.moment(end).format(ISO_DATE_FORMAT) : null,
                target: target ? _this.momentService.moment(target).format(ISO_DATE_FORMAT) : null,
            });
            var result = false;
            switch (type) {
                case MilestonesType.CALL:
                case MilestonesType.REMINDER:
                    result = form.controls.startDate.valid && Boolean(form.value.startTime);
                    break;
                case MilestonesType.DEADLINE:
                    result = form.controls.startDate.valid;
                    break;
                case MilestonesType.MEETING:
                case MilestonesType.MEETING_NO_NOTE:
                    result =
                        form.controls.startDate.valid &&
                            Boolean(form.value.startTime) &&
                            form.controls.endDate.valid &&
                            Boolean(form.value.endTime) &&
                            _this.beginEndDates(form.value.startDate, form.value.startTime, form.value.endDate, form.value.endTime);
                    break;
                case MilestonesType.RELEASE:
                    result =
                        form.controls.startDate.valid &&
                            form.controls.target.valid &&
                            _this.beginEndDates(form.value.startDate, null, form.value.target, null);
                    break;
                default:
                    break;
            }
            if (!result) {
                form.controls.startDate.setErrors({ validDate: { value: null } });
            }
            return result;
        };
    }
    FormsAddMilestoneService.getFormGroup = function () {
        return new FormGroup({
            type: new FormControl(null, Validators.required),
            targetType: new FormControl(null, Validators.required),
            project: new FormControl(),
            client: new FormControl(),
            description: new FormControl(),
            milestone: new FormControl(null, requiredTrimValidator()),
            target: new FormControl(null, requiredTrimValidator()),
            startDate: new FormControl(null, Validators.required),
            startTime: new FormControl(),
            endDate: new FormControl(null, Validators.required),
            endTime: new FormControl(),
            version: new FormControl(),
            participants: new FormControl(),
        });
    };
    FormsAddMilestoneService.prototype.getEndDate = function (milestone) {
        switch (milestone.type) {
            case MilestonesType.DEADLINE:
            case MilestonesType.RELEASE:
                return null;
            case MilestonesType.MEETING:
            case MilestonesType.MEETING_NO_NOTE:
                return this.momentService.moment(milestone.endDate + "T" + milestone.endTime);
            case MilestonesType.CALL:
            case MilestonesType.REMINDER:
                return this.momentService.moment(milestone.startDate + "T" + milestone.startTime).add(15, 'minutes');
            default:
                return undefined;
        }
    };
    FormsAddMilestoneService.prototype.getEndDateOnEdit = function (milestone) {
        switch (milestone.type) {
            case MilestonesType.DEADLINE:
            case MilestonesType.RELEASE:
                return this.momentService.moment(milestone.endDate + "T" + milestone.endTime).isValid()
                    ? this.momentService.moment(milestone.endDate + "T" + milestone.endTime)
                    : null;
            case MilestonesType.MEETING:
            case MilestonesType.MEETING_NO_NOTE:
                return this.momentService.moment(milestone.endDate + "T" + milestone.endTime);
            case MilestonesType.CALL:
            case MilestonesType.REMINDER:
                return this.momentService.moment(milestone.startDate + "T" + milestone.startTime).add(15, 'minutes');
            default:
                return undefined;
        }
    };
    FormsAddMilestoneService.prototype.getDate = function (milestone) {
        var beginDate = milestone.startDate;
        if (milestone.startTime && milestone.startTime !== 'Invalid date') {
            beginDate += "T" + milestone.startTime;
        }
        return {
            target: milestone.type === MilestonesType.RELEASE
                ? this.momentService
                    .moment(milestone.target + "T17:00:00Z")
                    .add(-this.momentService.moment().utcOffset(), 'm')
                : null,
            beginDate: this.momentService.moment(beginDate),
            endDate: this.getEndDateOnEdit(milestone),
        };
    };
    FormsAddMilestoneService.prototype.edit = function (f, added, removed, callback) {
        if (added === void 0) { added = []; }
        if (removed === void 0) { removed = []; }
        if (callback === void 0) { callback = noop; }
        return __awaiter(this, void 0, void 0, function () {
            var milestoneDates, milestone;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        milestoneDates = this.getDate(f);
                        if (f.type !== MilestonesType.MEETING && f.type !== MilestonesType.MEETING_NO_NOTE) {
                            milestoneDates.endDate = null;
                        }
                        if (f.type !== MilestonesType.RELEASE) {
                            milestoneDates.target = null;
                        }
                        return [4, this.httpRest
                                .post(MILESTONE_SCHEMA_ROUTE, {
                                id: f.id,
                                obs: f.milestone,
                                description: f.description,
                                version: f.version,
                                beginDate: milestoneDates.beginDate,
                                endDate: milestoneDates.endDate,
                                target: milestoneDates.target,
                            })
                                .toPromise()];
                    case 1:
                        milestone = _a.sent();
                        if (!(removed.length > 0)) return [3, 3];
                        return [4, Promise.all(removed.map(function (id) { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4, this.httpRest
                                                .deleteQuery(MEETING_PARTICIPANT_SCHEMA_ROUTE, {
                                                employeeId: id,
                                                milestoneId: milestone.id,
                                            })
                                                .toPromise()];
                                        case 1:
                                            _a.sent();
                                            return [2];
                                    }
                                });
                            }); }))];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        if (!(added.length > 0)) return [3, 5];
                        return [4, Promise.all(added.map(function (emp) { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4, this.httpRest
                                                .put(MEETING_PARTICIPANT_SCHEMA_ROUTE, {
                                                employeeId: emp,
                                                milestoneId: milestone.id,
                                            })
                                                .toPromise()];
                                        case 1:
                                            _a.sent();
                                            return [2];
                                    }
                                });
                            }); }))];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5:
                        this.toastService.show({
                            type: TOAST_TYPE.SUCCESS,
                            text: 'toast_updated_milestone',
                        });
                        if (callback) {
                            callback(milestone);
                        }
                        this.onSubmit.emit(milestone);
                        return [2, milestone];
                }
            });
        });
    };
    FormsAddMilestoneService.prototype.submit = function (f, callback) {
        if (callback === void 0) { callback = noop; }
        return __awaiter(this, void 0, void 0, function () {
            var participants, milestoneDates, milestone;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        participants = f.participants ? f.participants.slice() : [];
                        milestoneDates = this.getDate(f);
                        if (f.type !== MilestonesType.MEETING && f.type !== MilestonesType.MEETING_NO_NOTE) {
                            milestoneDates.endDate = null;
                        }
                        if (f.type !== MilestonesType.RELEASE) {
                            milestoneDates.target = null;
                        }
                        return [4, this.httpRest
                                .put(MILESTONE_SCHEMA_ROUTE, {
                                target: milestoneDates.target,
                                beginDate: milestoneDates.beginDate,
                                endDate: milestoneDates.endDate,
                                version: f.version,
                                type: f.type,
                                description: f.description,
                                obs: f.milestone,
                                clientId: (f.targetType === MilestoneTarget.CLIENT && f.client) || null,
                                projectId: (f.targetType === MilestoneTarget.PROJECT && f.project) || null,
                                employeeId: this.authService.user.employee.id,
                            })
                                .toPromise()];
                    case 1:
                        milestone = _a.sent();
                        if (!(participants.length > 0)) return [3, 3];
                        return [4, this.httpRest
                                .putEntities(MEETING_PARTICIPANT_SCHEMA_ROUTE, participants.map(function (emp) { return ({
                                employeeId: emp.employee.id,
                                milestoneId: milestone.id,
                            }); }))
                                .toPromise()];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        this.toastService.show({
                            type: TOAST_TYPE.SUCCESS,
                            text: 'toast_added_milestone',
                        });
                        Object.assign(milestone, {
                            participants: participants.map(function (p) {
                                return {
                                    employeeId: p.employee.id,
                                    employee: p.employee,
                                    milestoneId: milestone.id,
                                };
                            }),
                        });
                        if (callback) {
                            callback(milestone);
                        }
                        this.onSubmit.emit(milestone);
                        return [2, milestone];
                }
            });
        });
    };
    FormsAddMilestoneService.prototype.abort = function () {
        this.onAbort.emit();
    };
    FormsAddMilestoneService.toggle = function (form, type) {
        var controls = form.controls, value = form.value;
        var _type = value.type || type;
        controls.endTime.disable();
        controls.startTime.disable();
        switch (_type) {
            case MilestonesType.CALL:
            case MilestonesType.REMINDER:
                controls.version.clearValidators();
                controls.version.updateValueAndValidity();
                controls.target.disable();
                controls.endDate.disable();
                controls.startTime.enable();
                break;
            case MilestonesType.DEADLINE:
                controls.version.clearValidators();
                controls.version.updateValueAndValidity();
                controls.target.disable();
                controls.endDate.disable();
                break;
            case MilestonesType.MEETING:
            case MilestonesType.MEETING_NO_NOTE:
                requestAnimationFrame(function () {
                    controls.version.clearValidators();
                    controls.version.updateValueAndValidity();
                    controls.target.disable();
                    controls.endDate.enable();
                    controls.endTime.enable();
                    controls.startTime.enable();
                });
                break;
            case MilestonesType.RELEASE:
                controls.endDate.disable();
                controls.version.setValidators([semVerValidator(), Validators.maxLength(MAX_LENGTH_DEFAULT)]);
                controls.target.enable();
                break;
            default:
                return;
        }
    };
    FormsAddMilestoneService.HOUR_MINUTE_TIME_FORMAT = 'HH:mm';
    FormsAddMilestoneService.ngInjectableDef = i0.defineInjectable({ factory: function FormsAddMilestoneService_Factory() { return new FormsAddMilestoneService(i0.inject(i1.HttpRestService), i0.inject(i2.ToastService), i0.inject(i3.TranslateService), i0.inject(i4.AuthService), i0.inject(i5.MomentService)); }, token: FormsAddMilestoneService, providedIn: "root" });
    return FormsAddMilestoneService;
}());
export { FormsAddMilestoneService };
