import {ChangeDetectorRef, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';

@Component({
    selector: 'app-gantt-line',
    templateUrl: './gantt-line.component.html',
    styleUrls: ['./gantt-line.component.scss'],
})
export class GanttLineComponent implements OnChanges {
    @ViewChild('arrow')
    private readonly arrow: ElementRef;

    @Input()
    public readonly sourceId: number;

    @Input()
    public readonly targetId: number;

    @Input()
    public displayMode: string;

    @Input()
    public blocker = false;

    @Input()
    public arrowRedraw: boolean;

    public constructor(private readonly cdRef: ChangeDetectorRef) {}

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.sourceId || changes.targetId || changes.displayMode || changes.blocker || changes.arrowRedraw) {
            window.setTimeout(() => {
                this.drawLine();
                this.cdRef.markForCheck();
            }, 500);
        }
    }

    private drawLine() {
        const taskBar1 = document.getElementById(`taskbar-${this.sourceId}`) as HTMLElement;
        const taskBar2 = document.getElementById(`taskbar-${this.targetId}`) as HTMLElement;
        if (taskBar1 && taskBar2) {
            const t1Height = parseInt(taskBar1.style.height.replace('px', ''), 10) || 0;
            const t1Width = parseInt(taskBar1.style.width.replace('px', ''), 10) || 0;

            const t2Height = parseInt(taskBar2.style.height.replace('px', ''), 10) || 0;
            const t2Width = parseInt(taskBar2.style.width.replace('px', ''), 10) || 0;

            const x1 = taskBar1.offsetLeft + t1Width;
            const y1 = taskBar1.parentElement.parentElement.offsetTop + t1Height;
            const x2 = taskBar2.offsetLeft + t2Width;
            const y2 = taskBar2.parentElement.parentElement.offsetTop + t2Height;

            const left1 = parseInt(taskBar1.style.left.replace('px', ''), 10) || 0;
            const left2 = parseInt(taskBar2.style.left.replace('px', ''), 10) || 0;

            this.arrow.nativeElement.style.height = `calc(${Math.abs(y1 - y2)}px + 0.5em)`;
            if (y2 > y1) {
                this.arrow.nativeElement.style.bottom = '0.5em';
            } else {
                this.arrow.nativeElement.style.bottom = `calc(${y2}px - ${y1}px + 0.5em)`;
            }
            if (left2 < left1) {
                this.arrow.nativeElement.style.left = `${left2 + t2Width}px`;
            } else {
                this.arrow.nativeElement.style.left = `${left1 + t1Width}px`;
            }

            this.arrow.nativeElement.style.width = `${Math.abs(x1 - x2) - t2Width}px`;

            if (this.arrow.nativeElement.offsetLeft > taskBar2.offsetLeft) {
                this.arrow.nativeElement.classList.add('left');
            } else {
                this.arrow.nativeElement.classList.remove('left');
            }
        } else {
            this.arrow.nativeElement.style.display = 'none';
        }
    }
}
