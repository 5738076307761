import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {NgSelectComponent} from '@ng-select/ng-select';
import {TranslateService} from '@ngx-translate/core';
import {Duration, Moment} from 'moment';
import {getConfigKeys, getWorkingHours} from '../../../../defs/businessRules';
import {
    ApiRoutePlurality,
    DECIMAL_RADIX,
    HTTP_METHOD,
    MilestonesType,
    ProjectStatusType,
    ReleaseStateType,
    TaskStatusType,
} from '../../../../defs/schema-static';
import {CLIENT_SCHEMA_ROUTE, IClient} from '../../../../defs/schema/public/Clients';
import {EMPLOYEE_SCHEMA_ROUTE, IEmployee} from '../../../../defs/schema/public/Employees';
import {IMilestone} from '../../../../defs/schema/public/Milestones';
import {IProject, PROJECT_SCHEMA_ROUTE} from '../../../../defs/schema/public/Projects';
import {ITask} from '../../../../defs/schema/public/Tasks';
import {isColorDark} from '../app-static';
import {AuthService} from '../auth/auth.service';
import {ConfigService} from '../shared/config/config.service';
import {HttpRestService} from '../shared/http-rest/http-rest.service';
import {MomentService} from '../shared/moment/moment.service';

export enum GANTT_VIEW_VALUES {
    MONTH = 'MONTH',
    DAY = 'DAY',
    WEEK = 'WEEK',
}

export enum GANTT_MODE_VALUES {
    PROJECTS = 'PROJECTS',
    EMPLOYEES = 'EMPLOYEES',
}

export interface IGanttProject {
    project: IProject;
    beginDate: Moment;
    endDate: Moment;
    progress: number;
    taskDone: number;
    ganttMS: IGanttMilestone[];
    displayMore: boolean;
    who: Partial<IEmployee>[];
}

export interface IGanttMilestone {
    milestone: Partial<IMilestone>;
    taskDone: number;
    progress: number;
    tasks?: Partial<ITask>[];
    displayMore: boolean;
    who: Partial<IEmployee>[];
}

@Component({
    selector: 'app-gantt',
    templateUrl: './gantt.component.html',
    styleUrls: ['./gantt.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GanttComponent implements OnInit, AfterViewInit {
    public projects: IGanttProject[] = [];

    public timespan: string[];
    public currentView: GANTT_VIEW_VALUES;
    public currentMode: GANTT_MODE_VALUES = GANTT_MODE_VALUES.PROJECTS;

    public minDate: Moment;
    public maxDate: Moment;
    public readonly tdWidth = 140;
    public clientFilter: number = null;
    public projectStatusFilter: string = null;
    public fetching = false;
    public readonly projectColorList = [
        '#C92100',
        '#660092',
        '#004D8A',
        '#266900',
        '#C25400',
        '#DE400F',
        '#B0105B',
        '#1A23A0',
    ];
    public readonly msColorList = [
        '#F54F47',
        '#8939AD',
        '#0079B8',
        '#48960C',
        '#E46C00',
        '#FF5500',
        '#D91468',
        '#4E56B8',
    ];
    public readonly taskColorList = [
        '#F76F6C',
        '#AD73C8',
        '#49AFD9',
        '#60B515',
        '#FF8400',
        '#FF8142',
        '#F1428A',
        '#838ACF',
    ];
    public readonly MIN_WIDTH: number = 140;
    private noRelease = 'Tasks without release';
    public loading = true;
    public projectFilter: number[] = null;
    public employeeFilter: number[];
    public projectList: Partial<IProject>[];
    public projectsForWorkload: Partial<IProject>[] = [];

    public clients: Partial<IClient>[];
    public employees: Partial<IEmployee>[];
    public employeesForWorkload: Partial<IEmployee>[];

    public dayDuration: Duration;
    public lunchDuration: Duration;
    public weeksDays: number;

    @ViewChild('projectSelect')
    public projectSelect: NgSelectComponent;

    public constructor(
        private readonly httpRest: HttpRestService,
        public cdRef: ChangeDetectorRef,
        private readonly translate: TranslateService,
        public readonly authService: AuthService,
        private readonly configService: ConfigService,
        private readonly momentService: MomentService
    ) {}

    public ngOnInit() {
        if (!this.isFirefox()) {
            const bodyElement = document.querySelector('body');
            if (!bodyElement.classList.contains('overflow-x-hidden')) {
                bodyElement.classList.add('overflow-x-hidden');
            }
        }
        this.loading = false;
        if (this.currentMode === GANTT_MODE_VALUES.PROJECTS) {
            this.fetchData();
        }

        this.httpRest
            ._request<IProject[]>(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, PROJECT_SCHEMA_ROUTE, 'ganttList')
            .subscribe((simpleProjects) => {
                this.projectsForWorkload = simpleProjects;
                this.cdRef.detectChanges();
            });

        this.httpRest
            ._request<IEmployee[]>(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, EMPLOYEE_SCHEMA_ROUTE, 'list/ganttEmp')
            .subscribe((emps) => {
                this.employeesForWorkload = emps;

                // this.changeViewMode(GANTT_VIEW_VALUES.DAY);
                this.cdRef.detectChanges();
            });

        window.setTimeout(() => {
            this.translate.get('gantt_no_release').subscribe((e) => {
                this.noRelease = e;
                this.cdRef.markForCheck();
            });
            this.filterProjectStatus(ProjectStatusType.OPEN);
            this.defineConfigHours();
        }, 200);
    }

    public ngAfterViewInit() {
        this.fetching = true;
    }

    public async fetchData() {
        this.clients = [];
        this.httpRest
            ._request<IProject[]>(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, PROJECT_SCHEMA_ROUTE, 'light')
            .subscribe(async (projectList) => {
                this.projectList = projectList.sort((a, b) => a.obs.localeCompare(b.obs));
                setTimeout(() => {
                    this.projectSelect.focus();
                    this.projectSelect.open();
                }, 200);
                this.clients = await this.httpRest
                    ._request<IClient[]>(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, CLIENT_SCHEMA_ROUTE, 'list')
                    .toPromise();

                this.employees = await this.httpRest
                    ._request<IEmployee[]>(
                        HTTP_METHOD.GET,
                        ApiRoutePlurality.PLURAL,
                        EMPLOYEE_SCHEMA_ROUTE,
                        'list/gantt'
                    )
                    .toPromise();
                // projectIds.map(async (projectId) => {

                //         });
                this.clients = this.clients.sort((a, b) => a.user.name.localeCompare(b.user.name));
                // });
            });
    }

    private fetchProjectData(projectId: number) {
        const pro = this.projectList.find((p) => p.id === projectId) as any;
        const ganttProject = {
            project: pro,
            beginDate: this.momentService.moment(),
            endDate: this.momentService.moment(),
            progress: 0,
            ganttMS: ([] as unknown) as IGanttMilestone[],
            taskDone: 0,
            displayMore: false,
            who: [] as unknown,
            fetching: true,
        };

        this.projects.push(ganttProject as any);
        // this.projects = [...this.projects];
        // this.cdRef.detectChanges();
        this.httpRest
            ._request<IProject[]>(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, PROJECT_SCHEMA_ROUTE, `gantt/${projectId}`)
            .subscribe((projects) => {
                ganttProject.fetching = false;
                // this.projects = [...this.projects];
                if (projects.length === 0) {
                    this.changeViewMode(this.currentView);
                    this.getExtremeDates();
                }
                this.cdRef.detectChanges();

                projects.map((project) => {
                    project.client = this.clients.find((_client) => _client.id === project.clientId);
                    const c = {
                        id: project.client.id,
                        user: {
                            name: project.client.user.name,
                            code: project.client.user.code,
                            color: project.client.user.color,
                        },
                    };

                    // if (this.clients.findIndex((_c) => _c.id === c.id) === -1) {
                    //     this.clients.push(c);
                    // }

                    const ganttMS: IGanttMilestone[] = project.milestones.map((ms) => {
                        if (ms.participants.length > 0) {
                            ms.participants.map((pa) => {
                                pa.employee = this.employees.find((e) => e.id === pa.employeeId);
                            });
                        }
                        const whoMS: Partial<IEmployee>[] = [];
                        project.tasks
                            .filter((t) => t.targetReleaseId === ms.id)
                            .map((t) => {
                                t.assigned.map((assignation) => {
                                    assignation.employee = this.employees.find((e) => e.id === assignation.employeeId);
                                    if (whoMS.findIndex((e) => e.id === assignation.employee.id) === -1) {
                                        whoMS.push(assignation.employee);
                                    }
                                });
                            });

                        return {
                            who: whoMS,
                            displayMore: false,
                            milestone: ms,
                            tasks: project.tasks
                                .filter((t) => t.targetReleaseId === ms.id)
                                .sort((a, b) => {
                                    return this.momentService
                                        .moment(a._metadata.beginDate)
                                        .diff(this.momentService.moment(b._metadata.beginDate));
                                }),
                            taskDone: project.tasks.filter(
                                (t) => t.targetReleaseId === ms.id && t.status === TaskStatusType.DONE
                            ).length,
                            progress: Math.floor(
                                (project.tasks.filter(
                                    (t) => t.targetReleaseId === ms.id && t.status === TaskStatusType.DONE
                                ).length /
                                    project.tasks.filter((t) => t.targetReleaseId === ms.id).length) *
                                    100
                            ),
                        };
                    });
                    const taskWithoutReleases = project.tasks.filter((t) => !t.targetReleaseId);
                    const fakeMs = {
                        obs: this.noRelease,
                        target: this.momentService.moment(),
                        beginDate: this.momentService.moment(),
                        endDate: null as Moment,
                        type: MilestonesType.RELEASE,
                        clientId: project.clientId,
                        projectId: project.id,
                        description: this.noRelease,
                        releaseState: ReleaseStateType.IN_DEVELOPMENT,
                        version: '0.0.0',
                    };
                    const fakeRelease = {
                        who: [] as Partial<IEmployee>[],
                        displayMore: false,
                        milestone: fakeMs,
                        tasks: taskWithoutReleases,
                        taskDone: taskWithoutReleases.filter((t) => t.status === TaskStatusType.DONE).length,
                        progress: 0,
                    };

                    let min: Moment = null;
                    taskWithoutReleases.map((t) => {
                        if (!min) {
                            min = this.momentService.moment(t.beginDate);
                        }

                        if (this.momentService.moment(t.beginDate).isBefore(min)) {
                            min = this.momentService.moment(t.beginDate);
                        }
                    });
                    let max: Moment = null;
                    taskWithoutReleases.map((t) => {
                        if (!max) {
                            max = this.momentService.moment(t.endDate);
                        }
                        if (this.momentService.moment(t.endDate).isAfter(max)) {
                            max = this.momentService.moment(t.endDate);
                        }
                    });
                    fakeMs.target = max;
                    fakeMs.beginDate = min;

                    ganttMS.push(fakeRelease);

                    const who: Partial<IEmployee>[] = [];
                    project.tasks.map((t) => {
                        t.assigned.map((assignation) => {
                            assignation.employee = this.employees.find((e) => e.id === assignation.employeeId);
                            if (who.findIndex((e) => e.id === assignation.employee.id) === -1) {
                                who.push(assignation.employee);
                            }
                        });
                    });

                    // ganttProject = {
                    //     project,
                    //     beginDate:
                    //     endDate: ,
                    //     progress: this.getProgressProject(project),
                    //     ganttMS,
                    //     taskDone: project.tasks.filter((t) => t.status === TaskStatusType.DONE).length,
                    //     displayMore: false,
                    //     who,
                    // };

                    ganttProject.beginDate = this.getMinDateProject(project);
                    ganttProject.endDate = this.getMaxDateProject(project);
                    ganttProject.progress = this.getProgressProject(project);
                    ganttProject.ganttMS = ganttMS;
                    ganttProject.who = who;
                    ganttProject.taskDone = project.tasks.filter((t) => t.status === TaskStatusType.DONE).length;

                    if (project.tasks.length > 0) {
                        this.cdRef.detectChanges();
                    }
                    // if (i === projectIds.length) {
                    // this.projects = [...this.projects];
                    this.getExtremeDates();

                    this.changeViewMode(GANTT_VIEW_VALUES.MONTH);

                    // }

                    this.cdRef.detectChanges();
                });

                // this.projectList = this.projects
                //     .map((p) => ({
                //         id: p.project.id,
                //         code: p.project.code,
                //         obs: p.project.obs,
                //         color: p.project.color,
                //     }))this.projectL
                //     .sort((a, b) => a.obs.localeCompare(b.obs));

                this.loading = false;
                this.cdRef.detectChanges();
            });
    }

    private getExtremeDates() {
        if (!this.projects) {
            return;
        }

        this.projects.map((gproject) => {
            if (!gproject.beginDate && !gproject.endDate) {
                return;
            }

            if (!this.minDate) {
                this.minDate = gproject.beginDate.clone();
                this.maxDate = gproject.beginDate.clone();
            }
            if (this.minDate.isAfter(gproject.beginDate)) {
                this.minDate = gproject.beginDate.clone();
            }

            if (!this.minDate) {
                this.minDate = this.momentService.moment();
            }
            if (!this.maxDate) {
                this.maxDate = this.momentService.moment();
            }

            if (this.maxDate.isBefore(gproject.endDate)) {
                this.maxDate = gproject.endDate.clone();
            }

            if (gproject.project.milestones) {
                gproject.project.milestones.map((ms) => {
                    if (this.minDate.isAfter(this.momentService.moment(ms.beginDate))) {
                        this.minDate = this.momentService.moment(ms.beginDate).clone();
                    }

                    if (this.maxDate.isBefore(this.momentService.moment(ms.endDate))) {
                        this.maxDate = this.momentService.moment(ms.endDate).clone();
                    }
                });
            }
        });

        if (!this.minDate) {
            this.minDate = this.momentService.moment();
        }
        if (!this.maxDate) {
            this.maxDate = this.momentService.moment();
        }

        this.minDate.add(-1, 'month');
        this.maxDate.add(1, 'month');

        this.fetching = false;
    }

    private readonly getMaxDateProject = (project: IProject) => {
        if (!project.tasks || !project.tasks[0]) {
            return this.momentService.moment();
        }

        let max = this.momentService.moment(project.tasks[0]._metadata.endDate);
        project.tasks.map((t) => {
            if (this.momentService.moment(t._metadata.endDate).isAfter(max)) {
                max = this.momentService.moment(t._metadata.endDate);
            }
        });

        project.milestones.map((ms) => {
            if (this.momentService.moment(ms.target).isAfter(max)) {
                max = this.momentService.moment(ms.target);
            }
        });

        return this.momentService.moment(max);
    };

    private readonly getMinDateProject = (project: IProject) => {
        if (!project.tasks || !project.tasks[0]) {
            return this.momentService.moment();
        }
        let min = this.momentService.moment(project.tasks[0]._metadata.beginDate);

        project.tasks.map((t) => {
            if (this.momentService.moment(t._metadata.beginDate).isBefore(min)) {
                min = this.momentService.moment(t._metadata.beginDate);
            }
        });

        project.milestones.map((ms) => {
            if (ms.type !== MilestonesType.RELEASE) {
                return;
            }
            if (this.momentService.moment(ms.beginDate).isBefore(min)) {
                min = this.momentService.moment(ms.beginDate);
            }
        });

        return this.momentService.moment(min);
    };

    public async defineConfigHours() {
        const config = await this.configService.get();
        const configWorkHours = getWorkingHours(config);
        const configKeys = getConfigKeys(config, ['lunchHours', 'workingDays']);
        const lunchHours = configKeys.lunchHours;

        const confStart = configWorkHours.start.split(':').map((t) => parseInt(t, DECIMAL_RADIX));
        const start = this.momentService
            .moment()
            .hours(confStart[0])
            .minutes(confStart[1])
            .seconds(0)
            .milliseconds(0);
        const confEnd = configWorkHours.end.split(':').map((t) => parseInt(t, DECIMAL_RADIX));
        const end = this.momentService
            .moment()
            .hours(confEnd[0])
            .minutes(confEnd[1])
            .seconds(0)
            .milliseconds(0);

        const lunchStartTime = lunchHours.start.split(':').map((t) => parseInt(t, DECIMAL_RADIX));
        const lunchStart = this.momentService
            .moment()
            .hours(lunchStartTime[0])
            .minutes(lunchStartTime[1])
            .seconds(0)
            .milliseconds(0);
        const lunchEndTime = lunchHours.end.split(':').map((t) => parseInt(t, DECIMAL_RADIX));
        const lunchEnd = this.momentService
            .moment()
            .hours(lunchEndTime[0])
            .minutes(lunchEndTime[1])
            .seconds(0)
            .milliseconds(0);

        this.dayDuration = this.momentService.moment.duration(end.diff(start));
        this.lunchDuration = this.momentService.moment.duration(lunchEnd.diff(lunchStart));
        this.weeksDays = configKeys.workingDays.length;
    }

    public getNbHoursConfig(): string {
        if (!this.lunchDuration || !this.dayDuration || !this.weeksDays) {
            return '-';
        }

        let duration = null;
        if (this.lunchDuration.asMilliseconds() > 0) {
            duration = this.dayDuration.clone().subtract(this.lunchDuration);
        } else {
            duration = this.dayDuration.clone();
        }

        if (this.currentView === GANTT_VIEW_VALUES.WEEK) {
            const baseDuration = duration.clone();
            for (let i = 0; i < this.weeksDays - 1; i++) {
                duration
                    .add(baseDuration.hours(), 'hours')
                    .add(baseDuration.minutes(), 'minutes')
                    .add(baseDuration.seconds(), 'seconds')
                    .add(0, 'milliseconds');
            }
        }

        return Math.round(duration.asHours()).toString();
    }

    public filterClient(filterBy: string) {
        if (filterBy === 'null') {
            this.clientFilter = null;
        } else {
            this.clientFilter = parseInt(filterBy, 10);
        }
    }

    public selectedProjects() {
        if (!this.projectFilter) {
            return [];
        }

        return this.projects.filter((p) => this.projectFilter.includes(p.project.id));
    }

    public filterProject(filterBy?: IProject[]) {
        if (!filterBy) {
            this.projectFilter = null;

            return;
        } else {
            this.projectFilter = filterBy.map((p) => {
                return p.id;
            });
        }

        this.projectFilter
            .filter((pf) => this.projects.findIndex((p) => p.project.id === pf) === -1)
            .map((pf) => this.fetchProjectData(pf));
    }

    public clearFilterEmployee() {
        this.employeeFilter = null;
    }

    public removeFromEmpFilter(empId: number) {
        this.employeeFilter = this.employeeFilter.filter((id) => id !== empId);
        if (this.employeeFilter.length === 0) {
            this.clearFilterEmployee();
        }
    }
    public filterProjectStatus(filterBy: string) {
        if (filterBy === 'null') {
            this.projectStatusFilter = null;
        } else {
            this.projectStatusFilter = filterBy;
        }
    }

    private readonly getProgressProject = (project: IProject) => {
        if (!project.tasks || !project.tasks[0]) {
            return 0;
        }

        return Math.floor(
            (project.tasks.filter((t) => t.status === TaskStatusType.DONE).length / project.tasks.length) * 100
        );
    };

    public changeMode(value: GANTT_MODE_VALUES) {
        if (this.currentMode === value) {
            return;
        }
        if (this.currentView === GANTT_VIEW_VALUES.MONTH || !this.currentView) {
            this.currentView = GANTT_VIEW_VALUES.WEEK;
        }
        this.currentMode = value;
        if (this.currentMode === GANTT_MODE_VALUES.PROJECTS && !this.clients) {
            this.fetchData();
        }
    }

    public changeViewMode(value: GANTT_VIEW_VALUES) {
        // if (this.currentView === value) {
        //     return;
        // }
        this.currentView = value;

        if (!this.minDate) {
            this.minDate = this.momentService.moment();
        }
        if (!this.maxDate) {
            this.maxDate = this.momentService.moment();
        }

        if (this.currentView === GANTT_VIEW_VALUES.MONTH) {
            this.timespan = [];
            const currDate = this.minDate.clone().startOf('month');
            const lastDate = this.maxDate.clone().endOf('month');

            this.timespan.push(currDate.clone().format('MM-YYYY'));
            while (currDate.add(1, 'month').diff(lastDate) < 1) {
                this.timespan.push(currDate.clone().format('MM-YYYY'));
            }

            // this.timespan = Array.apply(0, Array(12)).map(function (_, i) {
            //   return this.momentService.moment().month(i).format('MMMM');
            // });
            //
        } else if (this.currentView === GANTT_VIEW_VALUES.WEEK) {
            this.timespan = [];
            const currDate = this.minDate.clone().startOf('isoWeek');
            const lastDate = this.maxDate.clone().startOf('isoWeek');

            this.timespan.push(currDate.clone().format('DD-MM-YYYY (WW)'));
            while (currDate.add(1, 'week').diff(lastDate) < 1) {
                this.timespan.push(currDate.clone().format('DD-MM-YYYY (WW)'));
            }
        } else {
            this.timespan = [];
            const currDate = this.minDate.clone();
            const lastDate = this.maxDate.clone();
            while (currDate.add(1, 'days').diff(lastDate) < 0) {
                this.timespan.push(currDate.clone().format('DD-MM-YYYY'));
            }
        }

        this.cdRef.markForCheck();
    }

    public isFirefox = () => {
        return navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    };

    public readonly isColorDark = isColorDark;
    public readonly GANTT_VIEW_VALUES = GANTT_VIEW_VALUES;
    public readonly GANTT_MODE_VALUES = GANTT_MODE_VALUES;

    public readonly MilestonesType = MilestonesType;
    public readonly ReleaseStateType = ReleaseStateType;
    public readonly PROJECT_STATUS_TYPE = Object.values(ProjectStatusType);
    public readonly moment = this.momentService.moment;
}
