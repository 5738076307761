<div class="clr-row">
    <div class="clr-col-xl-6 clr-col-lg-12">
        <div class="clr-col">
            <ng-select
                #clientsNgSelect
                [items]="clients"
                [placeholder]="'client_select' | translate"
                (change)="clientChange($event)"
            >
                <ng-template ng-option-tmp let-item="item">
                    <app-long-avatar [code]="item.user.code" [bgColor]="item.user.color"> </app-long-avatar>
                    {{ item.user.name }}
                </ng-template>
                <ng-template ng-label-tmp let-item="item">
                    <app-long-avatar [code]="item.user.code" [bgColor]="item.user.color"> </app-long-avatar>
                    {{ item.user.name }}
                </ng-template>
            </ng-select>
        </div>
        <ng-container *ngIf="currentClient">
            <form clrForm>
                <clr-input-container>
                    <label class="clr-col-md-4 clr-col-xs-12">{{ 'placeholder_backupprefix' | translate }}</label>
                    <input
                        class="clr-col-md-8 clr-col-xs-12"
                        clrInput
                        type="text"
                        [(ngModel)]="currentClient.backupPrefix"
                        name="backupPrefix"
                        placeholder="backup/"
                        maxlength="{{ MAX_LENGTH_DEFAULT }}"
                    />
                </clr-input-container>

                <clr-input-container>
                    <label class="clr-col-md-4 clr-col-xs-12">{{ 'placeholder_email' | translate }}</label>
                    <input
                        class="clr-col-md-8 clr-col-xs-12"
                        clrInput
                        type="email"
                        [(ngModel)]="currentClient.user.email"
                        name="clt_email"
                        placeholder="contact@example.com"
                        email
                        maxlength="{{ MAX_LENGTH_DEFAULT }}"
                    />
                </clr-input-container>

                <clr-input-container>
                    <label class="clr-col-md-4 clr-col-xs-12">{{ 'placeholder_cognito' | translate }}</label>
                    <input
                        class="clr-col-md-8 clr-col-xs-12"
                        clrInput
                        type="text"
                        [(ngModel)]="currentClient.user.cognitoUsername"
                        name="clientCognitoUsername"
                        placeholder="john.doe"
                        maxlength="{{ MAX_LENGTH_COGNITO_USERNAME }}"
                        appLowercaseInput
                        [pattern]="PATTERN_COGNITO_USERNAME.source"
                    />

                    <clr-control-error *clrIfError="'requiredTrim'" translate>error_field_empty</clr-control-error>
                    <clr-control-error *clrIfError="'maxLength'" translate>error_field_text_overflow</clr-control-error>
                    <clr-control-error *clrIfError="'pattern'" translate
                        >error_field_pattern_lower_numeric</clr-control-error
                    >
                </clr-input-container>
            </form>

            <div class="clr-row">
                <div class="clr-col">
                    <button [clrLoading]="clientValidateBtnState" class="btn btn-success mt-1" (click)="saveClient()">
                        {{ 'save_client' | translate }}
                    </button>
                </div>
            </div>

            <div class="clr-row hidden-xl-up">
                <div class="clr-col"><div class="divider"></div></div>
            </div>
        </ng-container>
    </div>

    <div class="clr-col-xl-6 clr-col-lg-12" *ngIf="currentClient">
        <div class="clr-row" [class.d-none]="!projects || !projects.length">
            <div class="clr-col">
                <ng-select
                    #projectsNgSelect
                    [items]="projects"
                    [placeholder]="'project_select' | translate"
                    (change)="projectChange($event)"
                >
                    <ng-template ng-option-tmp let-item="item">
                        <app-long-avatar [code]="item.code" [bgColor]="item.color"> </app-long-avatar>
                        {{ item.obs }}
                    </ng-template>
                    <ng-template ng-label-tmp let-item="item">
                        <app-long-avatar [code]="item.code" [bgColor]="item.color"> </app-long-avatar>
                        {{ item.obs }}
                    </ng-template>
                </ng-select>
            </div>
        </div>
        <ng-container *ngIf="currentProject">
            <form clrForm>
                <div class="clr-row backup-client">
                    <div class="clr-col-md-8 clr-offset-md-4 clr-col-xs-12">
                        <small> {{ currentClient.backupPrefix }} </small>
                    </div>
                </div>
                <clr-input-container class="mt-0">
                    <label class="clr-col-md-4 clr-col-xs-12"> {{ 'placeholder_backupprefix' | translate }} </label>
                    <input
                        class="clr-col-md-8 clr-col-xs-12"
                        clrInput
                        type="text"
                        [(ngModel)]="currentProject.backupPrefix"
                        name="backupPrefix"
                        placeholder="backup/"
                        maxlength="{{ MAX_LENGTH_DEFAULT }}"
                    />
                </clr-input-container>

                <clr-input-container>
                    <label class="clr-col-md-4 clr-col-xs-12">{{ 'placeholder_discord_path' | translate }}</label>
                    <input
                        class="clr-col-md-8 clr-col-xs-12"
                        clrInput
                        type="text"
                        [(ngModel)]="currentProject.discordPath"
                        name="discordPath"
                    />
                </clr-input-container>

                <clr-input-container>
                    <label class="clr-col-md-4 clr-col-xs-12">{{ 'git_repo' | translate }}</label>
                    <input
                        class="clr-col-md-8 clr-col-xs-12"
                        clrInput
                        type="text"
                        [(ngModel)]="currentProject.repositoryUrl"
                        name="repositoryUrl"
                        placeholder="git@example.com:repo.git"
                        maxlength="{{ MAX_LENGTH_DEFAULT }}"
                    />
                </clr-input-container>
            </form>

            <div class="clr-row">
                <div class="clr-col">
                    <button [clrLoading]="projectValidateBtnState" class="btn btn-success mt-1" (click)="saveProject()">
                        {{ 'save_project' | translate }}
                    </button>
                </div>
            </div>
        </ng-container>
    </div>
</div>
