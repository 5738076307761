<div class="card" [class.card-tile]="tile" #card>
    <div class="card-header clr-row px-0 mx-0" [class.highlight]="highlight">
        <div class="clr-col">
            <ng-container *ngIf="!!user">
                <app-long-avatar [code]="user.code" [bgColor]="user.color"> </app-long-avatar>
                {{ user.name }}
            </ng-container>
            <ng-container *ngIf="!!createdAt">
                <span class="card-date tooltip-sm" [attr.data-tooltip]="createdAt | momentFormat: 'L LTS'">
                    {{ createdAt | momentDuration }}
                </span>
                <span
                    *ngIf="!!updatedAt && updatedAt !== createdAt"
                    class="card-date tooltip-sm"
                    [attr.data-tooltip]="updatedAt | momentFormat: 'L LTS'"
                >
                    {{ 'comment_edited' | translate }}
                </span>
            </ng-container>
        </div>

        <div class="clr-col-0" *ngIf="headerRight"><ng-template [ngTemplateOutlet]="headerRight"></ng-template></div>
    </div>
    <div class="card-block" *ngIf="cardBody">
        <div class="card-text"><ng-template [ngTemplateOutlet]="cardBody"></ng-template></div>
    </div>
    <div class="card-footer clr-row" *ngIf="cardFooter">
        <div class="clr-col">
            <ng-template [ngTemplateOutlet]="cardFooter"></ng-template>
            <clr-icon
                *ngIf="!!icon"
                [attr.shape]="icon"
                [style.height]="iconSize + 'rem'"
                [style.width]="iconSize + 'rem'"
            ></clr-icon>
        </div>
        <div class="clr-col-0 pt-02" *ngIf="footerRight">
            <ng-template [ngTemplateOutlet]="footerRight"></ng-template>
        </div>
    </div>
</div>
