import {Duration, Moment} from 'moment';
import {MomentService} from '../moment/moment.service';

export class Timer {
    public isStarted: boolean;
    public isRunning: boolean;
    public hTime: string;
    public time: Duration;
    public startDate: Moment;
    private timeInterval: number;
    private lastTime: Moment;

    public constructor(private readonly momentService: MomentService) {
        this.isStarted = false;
        this.isRunning = false;
        const time = parseInt(localStorage.getItem('timerTime'), 10);
        if (time) {
            this.isStarted = true;
            const startDate = parseInt(localStorage.getItem('timerBegin'), 10);
            if (startDate) {
                this.startDate = this.momentService.moment.unix(startDate);
            } else {
                this.startDate = this.momentService.moment();
            }
            this.time = this.momentService.moment.duration(time, 'milliseconds');
            const pause = localStorage.getItem('timerPause') === 'true';
            if (pause) {
                this.formatTime();
            } else {
                const lastTime = parseInt(localStorage.getItem('timerLast'), 10);
                if (lastTime) {
                    this.lastTime = this.momentService.moment.unix(lastTime);
                    this.updateTime();
                    this.toggle();
                }
            }
        } else {
            this.time = this.momentService.moment.duration();
        }
    }

    private formatTime() {
        const pad = (n = 0) => {
            return `00${n}`.slice(-2);
        };

        this.hTime = '';
        const hours = this.time.days() * 24 + this.time.hours();
        if (hours) {
            this.hTime += `${pad(hours)}:`; // hours
        }
        this.hTime += `${pad(this.time.minutes())}:${pad(this.time.seconds())}`; // minutes and seconds
    }

    private updateTime() {
        const now = this.momentService.moment();
        this.time.add(now.diff(this.lastTime));
        localStorage.setItem('timerTime', this.time.asMilliseconds().toString());
        this.lastTime = now;
        localStorage.setItem('timerLast', this.lastTime.unix().toString());
        this.formatTime();
    }

    public toggle() {
        if (this.isRunning) {
            this.isRunning = false;
            localStorage.setItem('timerPause', 'true');
            this.formatTime();
            clearInterval(this.timeInterval);
        } else {
            this.isRunning = true;
            this.isStarted = true;
            localStorage.removeItem('timerPause'); // just in case

            const now = this.momentService.moment();
            const nowStr = now.unix().toString();

            this.startDate = now;
            localStorage.setItem('timerBegin', nowStr);

            this.lastTime = now;
            localStorage.setItem('timerLast', nowStr);

            this.updateTime();
            this.timeInterval = window.setInterval(() => {
                this.updateTime();
            }, 1000);
        }
    }

    public stop() {
        this.isRunning = false;
        this.isStarted = false;
        this.formatTime();
        clearInterval(this.timeInterval);
        delete this.hTime;

        localStorage.removeItem('timerTime');
        localStorage.removeItem('timerPause');

        this.time = this.momentService.moment.duration();
        delete this.startDate;
    }
}
