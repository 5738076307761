var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { EventEmitter, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ClrWizard } from '@clr/angular';
import { getWorkingHours } from '../../../../defs/businessRules';
import { ApiRoutePlurality, HTTP_METHOD, ISO_DATE_FORMAT, MilestonesType, RIGHTS } from '../../../../defs/schema-static';
import { CLIENT_SCHEMA_ROUTE } from '../../../../defs/schema/public/Clients';
import { PROJECT_SCHEMA_ROUTE } from '../../../../defs/schema/public/Projects';
import { AuthService } from '../auth/auth.service';
import { FormsAddMilestoneService, MilestoneTarget } from '../forms/add-milestone.service';
import { ConfigService } from '../shared/config/config.service';
import { HttpRestService } from '../shared/http-rest/http-rest.service';
import { MomentService } from '../shared/moment/moment.service';
import { SHORTCUT_WIZARD, ShortcutHandlerService } from '../shared/shortcut-handler/shortcut-handler.service';
import { MilestoneDetailsComponent } from './milestone-details/milestone-details.component';
import { MilestoneParticipantsComponent } from './milestone-participants/milestone-participants.component';
import { MilestoneTargetComponent } from './milestone-target/milestone-target.component';
import { MilestoneTypeComponent } from './milestone-type/milestone-type.component';
var MilestoneWizardComponent = (function () {
    function MilestoneWizardComponent(httpRest, configService, authService, shortcutHandlerService, momentService) {
        this.httpRest = httpRest;
        this.configService = configService;
        this.authService = authService;
        this.shortcutHandlerService = shortcutHandlerService;
        this.momentService = momentService;
        this.clrWizardOpen = false;
        this.clrWizardOpenChange = new EventEmitter();
        this.finish = new EventEmitter();
        this.cancel = new EventEmitter();
        this.clients = [];
        this.projects = [];
        this.filteredTypes = Object.values(MilestonesType).filter(function (m) { return m !== MilestonesType.MEETING_NO_NOTE; });
        this.form = FormsAddMilestoneService.getFormGroup();
        this.submitting = false;
        this.MILESTONE_TYPE = MilestonesType;
    }
    MilestoneWizardComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (!this.clients || !this.clients.length) {
            (function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                return [2, this.getClients()];
            }); }); })();
        }
        if (!this.projects || !this.projects.length) {
            (function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                return [2, this.getProjects()];
            }); }); })();
        }
        requestAnimationFrame(function () {
            _this.load();
        });
        this.shortcutHandlerService.register({
            shortcut: SHORTCUT_WIZARD.NEXT,
            callback: function (e) {
                if (!_this.wizard.currentPage.nextStepDisabled && e.target.tagName !== 'TEXTAREA') {
                    if (_this.wizard.isLast) {
                        _this.triggerFinish();
                    }
                    else {
                        _this.wizard.next();
                    }
                }
            },
            context: this,
            forceListen: true,
        });
    };
    MilestoneWizardComponent.prototype.ngOnChanges = function (changes) {
        if (changes.clrWizardOpen && changes.clrWizardOpen.currentValue) {
            this.load();
        }
    };
    MilestoneWizardComponent.prototype.getClients = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4, this.httpRest
                                ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, CLIENT_SCHEMA_ROUTE, 'light')
                                .toPromise()];
                    case 1:
                        _a.clients = (_b.sent()).sort(function (c1, c2) { return c1.user.name.localeCompare(c2.user.name); });
                        return [2];
                }
            });
        });
    };
    MilestoneWizardComponent.prototype.getProjects = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4, this.httpRest
                                ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, PROJECT_SCHEMA_ROUTE, 'light/true')
                                .toPromise()];
                    case 1:
                        _a.projects = (_b.sent()).sort(function (c1, c2) { return c1.obs.localeCompare(c2.obs); });
                        return [2];
                }
            });
        });
    };
    MilestoneWizardComponent.prototype.load = function () {
        var _this = this;
        this.wizard.reset();
        var managerOf = this.authService.getProjectsManagerOf();
        if (!this.params) {
            if (!(managerOf.length > 0) && !this.authService.hasRight(RIGHTS.PROJECT_ADMIN_ALL)) {
                if (!this.authService.hasRight(RIGHTS.MS_RELEASE)) {
                    this.filteredTypes = Object.values(MilestonesType).filter(function (f) { return f !== MilestonesType.DEADLINE && f !== MilestonesType.RELEASE; });
                }
                if (!this.authService.hasRight(RIGHTS.MS_UPDATE)) {
                    this.filteredTypes = this.filteredTypes.filter(function (f) { return f !== MilestonesType.MEETING && f !== MilestonesType.MEETING_NO_NOTE; });
                }
            }
            requestAnimationFrame(function () {
                _this.typeComponent.onLoad(_this.form.value);
            });
            return;
        }
        var _now = this.momentService.moment();
        var workingHours = getWorkingHours(this.configService.config);
        var configStartTime = workingHours.start.split(':').map(Number);
        var configEndTime = workingHours.end.split(':').map(Number);
        var target = _now.format(ISO_DATE_FORMAT);
        var startDate = _now.format(ISO_DATE_FORMAT);
        var startTime = _now
            .set({
            hour: configStartTime[0],
            minute: configStartTime[1],
        })
            .format(MilestoneWizardComponent.HOUR_MINUTE_TIME_FORMAT);
        var endDate = _now.format(ISO_DATE_FORMAT);
        var endTime = _now
            .set({
            hour: configEndTime[0],
            minute: configEndTime[1],
        })
            .format(MilestoneWizardComponent.HOUR_MINUTE_TIME_FORMAT);
        if (this.params.start && this.params.end) {
            target = this.params.start.format(ISO_DATE_FORMAT);
            startDate = this.params.start.format(ISO_DATE_FORMAT);
            startTime = this.params.start.format(MilestoneWizardComponent.HOUR_MINUTE_TIME_FORMAT);
            endDate = this.params.end.format(ISO_DATE_FORMAT);
            endTime = this.params.end.format(MilestoneWizardComponent.HOUR_MINUTE_TIME_FORMAT);
        }
        var targetType = this.params.clientId ? MilestoneTarget.CLIENT : MilestoneTarget.PROJECT;
        this.form.reset({
            targetType: targetType,
            target: target,
            startDate: startDate,
            startTime: startTime,
            endDate: endDate,
            endTime: endTime,
            client: this.params.clientId || null,
            project: this.params.projectId || null,
            type: this.params.type || null,
            description: this.params.description || null,
            milestone: this.params.milestone || null,
            version: this.params.version || null,
        });
        if ((this.params && this.params.clientId && targetType === MilestoneTarget.CLIENT) ||
            !this.authService.hasRight(RIGHTS.MS_RELEASE)) {
            if (!(managerOf.length > 0) && !this.authService.hasRight(RIGHTS.PROJECT_ADMIN_ALL)) {
                this.filteredTypes = Object.values(MilestonesType).filter(function (f) { return f !== MilestonesType.DEADLINE && f !== MilestonesType.RELEASE; });
            }
        }
        if (!this.params.type) {
            requestAnimationFrame(function () {
                _this.typeComponent.onLoad(_this.form.value);
            });
        }
    };
    MilestoneWizardComponent.prototype.onLoad = function (page) {
        switch (page) {
            case 1:
                this.typeComponent.onLoad();
                break;
            case 2:
                this.targetComponent.onLoad(this.form.value);
                break;
            case 3:
                this.detailsComponent.onLoad(this.form.value);
                break;
            default:
                return;
        }
    };
    MilestoneWizardComponent.prototype.onCommit = function (page) {
        switch (page) {
            case 1:
                this.form.patchValue({
                    type: this.typeComponent.form.value.type,
                });
                break;
            case 2:
                this.form.patchValue({
                    targetType: this.targetComponent.form.value.targetType,
                    client: this.targetComponent.form.value.client,
                    project: this.targetComponent.form.value.project,
                });
                break;
            default:
                return;
        }
    };
    MilestoneWizardComponent.prototype.triggerFinish = function () {
        return __awaiter(this, void 0, void 0, function () {
            var milestone;
            return __generator(this, function (_a) {
                this.submitting = true;
                try {
                    milestone = __assign({}, this.detailsComponent.submit().value);
                    if (this.typeComponent) {
                        Object.assign(milestone, this.typeComponent.submit().value);
                    }
                    else {
                        milestone.type = this.params.type;
                    }
                    if (this.targetComponent) {
                        Object.assign(milestone, this.targetComponent.submit().value);
                    }
                    else {
                        if (this.params.clientId) {
                            milestone.targetType = MilestoneTarget.CLIENT;
                            milestone.client = this.params.clientId;
                        }
                        else {
                            milestone.targetType = MilestoneTarget.PROJECT;
                            milestone.project = this.params.projectId;
                        }
                    }
                    if (this.participantsComponent) {
                        Object.assign(milestone, this.participantsComponent.submit().value);
                    }
                    if (!milestone) {
                        return [2];
                    }
                }
                catch (err) {
                    return [2];
                }
                finally {
                    this.submitting = false;
                }
                this.finish.emit(milestone);
                this.triggerCancel();
                return [2];
            });
        });
    };
    MilestoneWizardComponent.prototype.triggerCancel = function (onPage) {
        this.reset();
        this.wizard.forceFinish();
        if (onPage) {
            this.cancel.emit();
        }
    };
    MilestoneWizardComponent.prototype.reset = function () {
        this.wizard.reset();
        [this, this.typeComponent, this.targetComponent, this.participantsComponent, this.detailsComponent]
            .filter(function (component) { return component && component.form; })
            .map(function (_a) {
            var form = _a.form;
            return form.reset();
        });
    };
    MilestoneWizardComponent.HOUR_MINUTE_TIME_FORMAT = 'HH:mm';
    return MilestoneWizardComponent;
}());
export { MilestoneWizardComponent };
