import * as i0 from "./log-out.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./log-out.component";
import * as i3 from "../auth.service";
import * as i4 from "../../shared/toast/toast.service";
var styles_LogOutComponent = [i0.styles];
var RenderType_LogOutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LogOutComponent, data: {} });
export { RenderType_LogOutComponent as RenderType_LogOutComponent };
export function View_LogOutComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_LogOutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-log-out", [], null, null, null, View_LogOutComponent_0, RenderType_LogOutComponent)), i1.ɵdid(1, 49152, null, 0, i2.LogOutComponent, [i3.AuthService, i4.ToastService], null, null)], null, null); }
var LogOutComponentNgFactory = i1.ɵccf("app-log-out", i2.LogOutComponent, View_LogOutComponent_Host_0, {}, {}, []);
export { LogOutComponentNgFactory as LogOutComponentNgFactory };
