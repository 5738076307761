// tslint:disable max-classes-per-file
import {Directive, TemplateRef} from '@angular/core';

@Directive({selector: '[appCardHeaderRight]'})
export class CardHeaderRightDirective {
    public constructor(public template: TemplateRef<any>) {}
}

@Directive({selector: '[appCardBody]'})
export class CardBodyDirective {
    public constructor(public template: TemplateRef<any>) {}
}

@Directive({selector: '[appCardFooter]'})
export class CardFooterDirective {
    public constructor(public template: TemplateRef<any>) {}
}

@Directive({selector: '[appCardFooterRight]'})
export class CardFooterRightDirective {
    public constructor(public template: TemplateRef<any>) {}
}
