import moment from 'moment';
import {ISchemaRoute} from '../../schema-static';
import {IEmployee} from './Employees';
import {IMilestone} from './Milestones';

export enum MEETING_PARTICIPANT_FIELD {
    id = 'id',
    milestoneId = 'milestoneId',
    employeeId = 'employeeId',
    createdAt = 'createdAt',
    updatedAt = 'updatedAt',
}

export interface IMeetingParticipantBare {
    id?: number;
    createdAt?: moment.Moment;
    updatedAt?: moment.Moment;
    milestoneId: number;
    employeeId: number;
}

export interface IMeetingParticipantBelong {
    milestone?: Partial<IMilestone>;
    employee?: Partial<IEmployee>;
}

export interface IMeetingParticipant extends IMeetingParticipantBare, IMeetingParticipantBelong {}

const MEETING_PARTICIPANT_SCHEMA_ROUTE: ISchemaRoute = {
    tableName: 'meetingParticipants',
    singularRoute: 'meetingParticipant',
    pluralRoute: 'meetingParticipants',
};

export {MEETING_PARTICIPANT_SCHEMA_ROUTE};
