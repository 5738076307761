import { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { NestedProperty } from '../nested-properties';
export var DATAGRID_FILTER_DISPLAY_TYPE;
(function (DATAGRID_FILTER_DISPLAY_TYPE) {
    DATAGRID_FILTER_DISPLAY_TYPE["CHECKBOX_LIST"] = "CHECKBOX_LIST";
    DATAGRID_FILTER_DISPLAY_TYPE["ICON_GRID"] = "ICON_GRID";
})(DATAGRID_FILTER_DISPLAY_TYPE || (DATAGRID_FILTER_DISPLAY_TYPE = {}));
var GenericDatagridFilterComponent = (function () {
    function GenericDatagridFilterComponent() {
        this.display = DATAGRID_FILTER_DISPLAY_TYPE.CHECKBOX_LIST;
        this.state = {};
        this.changes = new Subject();
        this.toggleAll = true;
        this.DATAGRID_FILTER_DISPLAY_TYPE = DATAGRID_FILTER_DISPLAY_TYPE;
    }
    GenericDatagridFilterComponent.prototype.ngOnInit = function () {
        this.setFilterKey();
        this.generateToggledMap();
    };
    GenericDatagridFilterComponent.prototype.ngOnChanges = function (changes) {
        if (changes.filterKey) {
            this.setFilterKey();
        }
        if ((changes.filter || changes.unfilteredEntities) && (!changes.state || !this.state)) {
            this.generateToggledMap();
        }
    };
    GenericDatagridFilterComponent.prototype.resetState = function () {
        var properties = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            properties[_i] = arguments[_i];
        }
        this.state = Object.keys(this.state).reduce(function (_filterMap, filterKey) {
            _filterMap[filterKey] = properties.includes(filterKey);
            return _filterMap;
        }, {});
        this.emitChanges();
    };
    GenericDatagridFilterComponent.prototype.setFilterKey = function () {
        this.nestedProp = this.filterKey ? new NestedProperty(this.filterKey) : undefined;
        this.emitChanges();
    };
    GenericDatagridFilterComponent.prototype.accepts = function (item) {
        var _this = this;
        if (!this.filterKey) {
            console.warn('missing filterKey');
            return true;
        }
        var filterKeys = this.filterKeys;
        if (!filterKeys.length) {
            return true;
        }
        var filterValues = this.filterValues;
        var itemValue = this.getItemValue(item);
        return !!filterKeys
            .filter(function (_filterKey) { return _this.state[_filterKey]; })
            .find(function (_filterKey) { return itemValue === filterValues[_filterKey]; });
    };
    GenericDatagridFilterComponent.prototype.emitChanges = function () {
        var allToggled = this.allToggled;
        if (allToggled !== null && this.toggleAll !== allToggled) {
            this.toggleAll = allToggled;
        }
        this.changes.next(this.filterKey);
    };
    GenericDatagridFilterComponent.prototype.toggleToggleAll = function () {
        var _this = this;
        this.state = Object.keys(this.state).reduce(function (_filterMap, filterKey) {
            _filterMap[filterKey] = _this.toggleAll;
            return _filterMap;
        }, {});
        this.emitChanges();
    };
    GenericDatagridFilterComponent.prototype.generateToggledMap = function () {
        var _this = this;
        this.state = this.filterKeys.reduce(function (_filterMap, filterKey) {
            _filterMap[filterKey] = _this.state.hasOwnProperty(filterKey) ? _this.state[filterKey] : true;
            return _filterMap;
        }, {});
        this.filterCount =
            (this.unfilteredEntities &&
                this.filterKeys.reduce(function (_filterCount, key) {
                    _filterCount[key] = _this.unfilteredEntities.filter(function (entity) { return _this.getItemValue(entity) === key; }).length;
                    return _filterCount;
                }, {})) ||
                undefined;
        this.emitChanges();
    };
    Object.defineProperty(GenericDatagridFilterComponent.prototype, "filterKeys", {
        get: function () {
            var _this = this;
            var keys = Object.keys(this.filter || {});
            if (!this.unfilteredEntities || !this.unfilteredEntities.length) {
                return Object.keys(this.filter || {});
            }
            return keys.filter(function (key) { return !!_this.unfilteredEntities.find(function (entity) { return _this.getItemValue(entity) === key; }); });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GenericDatagridFilterComponent.prototype, "filterValues", {
        get: function () {
            var _this = this;
            return this.filterKeys.reduce(function (_filterValues, filterKey) {
                _filterValues[filterKey] =
                    _this.filter.hasOwnProperty(filterKey) && _this.filter[filterKey].hasOwnProperty('value')
                        ? _this.filter[filterKey].value
                        : filterKey;
                return _filterValues;
            }, {});
        },
        enumerable: true,
        configurable: true
    });
    GenericDatagridFilterComponent.prototype.serialize = function () {
        var _this = this;
        return this.filterKeys.filter(function (filterKey) { return _this.state[filterKey]; });
    };
    Object.defineProperty(GenericDatagridFilterComponent.prototype, "allToggled", {
        get: function () {
            var set = Array.from(new Set(Object.values(this.state)));
            if (set.length === 1) {
                return set[0];
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    GenericDatagridFilterComponent.prototype.getItemValue = function (item) {
        if (!this.nestedProp) {
            return '';
        }
        return this.nestedProp.getPropValue(item);
    };
    GenericDatagridFilterComponent.prototype.isActive = function () {
        return this.serialize().length !== this.filterKeys.length;
    };
    return GenericDatagridFilterComponent;
}());
export { GenericDatagridFilterComponent };
