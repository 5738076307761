import * as i0 from "./user-view.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../settings/settings.component.ngfactory";
import * as i3 from "../settings/settings.component";
import * as i4 from "../shared/shortcut-handler/shortcut-handler.service";
import * as i5 from "../shared/control-flow/control-flow.service";
import * as i6 from "../settings/settings.service";
import * as i7 from "@ngx-translate/core";
import * as i8 from "../shared/toast/toast.service";
import * as i9 from "../shared/http-rest/http-rest.service";
import * as i10 from "../shared/config/config.service";
import * as i11 from "../auth/auth.service";
import * as i12 from "@ng-select/ng-select";
import * as i13 from "@angular/common";
import * as i14 from "@clr/angular";
import * as i15 from "./user-view.component";
import * as i16 from "../modal-simple/modal-simple.service";
import * as i17 from "@angular/router";
var styles_UserViewComponent = [i0.styles];
var RenderType_UserViewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserViewComponent, data: {} });
export { RenderType_UserViewComponent as RenderType_UserViewComponent };
function View_UserViewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "app-settings", [], null, [[null, "showChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("showChange" === en)) {
        var pd_0 = ((_co.showSettings = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_SettingsComponent_0, i2.RenderType_SettingsComponent)), i1.ɵdid(1, 770048, null, 3, i3.SettingsComponent, [i4.ShortcutHandlerService, i1.ChangeDetectorRef, i5.ControlFlowService, i6.SettingsService, i7.TranslateService, i8.ToastService, i9.HttpRestService, i10.ConfigService, i11.AuthService, i12.NgSelectConfig], { show: [0, "show"] }, { showChange: "showChange" }), i1.ɵqud(335544320, 1, { modalHeader: 0 }), i1.ɵqud(335544320, 2, { modalBody: 0 }), i1.ɵqud(335544320, 3, { modalFooter: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showSettings; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_UserViewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "li", [], [[8, "title", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 14, "div", [["class", "sidebar-container"]], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i13.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 5, "a", [["class", "clr-col-6 c-hand user-view-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.showSettings = true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "clr-icon", [["class", "is-solid"], ["shape", "cog"]], null, null, null, null, null)), i1.ɵdid(5, 16384, null, 0, i14.ClrIconCustomTag, [], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "span", [["class", "menu-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(9, 0, null, null, 6, "a", [["class", "clr-col-6 c-hand user-view-button active"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 3, "clr-icon", [["class", "is-solid"], ["shape", "logout"]], null, null, null, null, null)), i1.ɵdid(11, 278528, null, 0, i13.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(12, { color: 0 }), i1.ɵdid(13, 16384, null, 0, i14.ClrIconCustomTag, [], null, null), (_l()(), i1.ɵeld(14, 0, null, null, 1, "span", [["class", "menu-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(15, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserViewComponent_1)), i1.ɵdid(17, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "sidebar-container"; var currVal_2 = (_co.isCollapsed ? "is-collapsed" : ""); _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_4 = _ck(_v, 12, 0, ((_co.authService.user == null) ? null : _co.authService.user.color)); _ck(_v, 11, 0, currVal_4); var currVal_6 = _co.authService.user; _ck(_v, 17, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.authService.user == null) ? null : _co.authService.user.code); _ck(_v, 0, 0, currVal_0); var currVal_3 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("settings")); _ck(_v, 7, 0, currVal_3); var currVal_5 = ((_co.authService.user == null) ? null : _co.authService.user.code); _ck(_v, 15, 0, currVal_5); }); }
export function View_UserViewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-user-view", [], null, null, null, View_UserViewComponent_0, RenderType_UserViewComponent)), i1.ɵdid(1, 49152, null, 0, i15.UserViewComponent, [i16.ModalSimpleService, i11.AuthService, i17.Router], null, null)], null, null); }
var UserViewComponentNgFactory = i1.ɵccf("app-user-view", i15.UserViewComponent, View_UserViewComponent_Host_0, { isCollapsed: "isCollapsed" }, {}, []);
export { UserViewComponentNgFactory as UserViewComponentNgFactory };
