import {ISchemaRoute} from '../../schema-static';

export interface ICognitoGroup {
    groupName: string;
    description?: string;
    displayName?: string;
}

const COGNITO_SCHEMA_ROUTE: ISchemaRoute = {
    singularRoute: 'cognito',
    pluralRoute: 'cognitos',
};

export {COGNITO_SCHEMA_ROUTE};
