import {Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ApiRoutePlurality, HTTP_METHOD, ProjectMemberType, RIGHTS} from '../../../../../defs/schema-static';
import {IEmployee} from '../../../../../defs/schema/public/Employees';
import {IProject} from '../../../../../defs/schema/public/Projects';
import {ITag, ITagWithTaskCount, TAG_SCHEMA_ROUTE} from '../../../../../defs/schema/public/Tags';
import {getRandomColorHex} from '../../app-static';
import {AuthService} from '../../auth/auth.service';
import {ProjectWizardProjectMembersComponent} from '../../project-wizard/project-members/project-wizard-project-members.component';
import {HttpRestService} from '../../shared/http-rest/http-rest.service';
import {TOAST_TYPE, ToastService} from '../../shared/toast/toast.service';

@Component({
    selector: 'app-project-params',
    templateUrl: './project-params.component.html',
    styleUrls: ['./project-params.component.scss'],
})
export class ProjectParamsComponent implements OnInit, OnChanges {
    @Input() public project: IProject;
    @Input() public employees: IEmployee[];
    @Input() public tags: ITagWithTaskCount[];

    public selectedTag: ITagWithTaskCount;

    public notDoneTags: ITagWithTaskCount[];
    public doneTags: ITagWithTaskCount[];
    public inactiveTags: ITagWithTaskCount[];

    @ViewChild(ProjectWizardProjectMembersComponent)
    private readonly membersComponent: ProjectWizardProjectMembersComponent;
    @ViewChild('tagInput') public tagInput: ElementRef;

    public showDeleteModal = false;

    public tagInputText = '';
    public tagInputColor = getRandomColorHex();
    public tagInputBranch = false;

    public constructor(
        private readonly authService: AuthService,
        private readonly httpRest: HttpRestService,
        private readonly translate: TranslateService,
        private readonly toastService: ToastService
    ) {}
    public ngOnInit(): void {
        if (!this.tags) {
            (async () => this.getTags())();
        } else {
            this.setActiveTags();
        }
    }
    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.project) {
            (async () => this.getTags())();
        }
    }

    public get isManager(): boolean {
        return (
            !!(this.project.projectMembers || []).find(
                (member) =>
                    member.employeeId === this.authService.user.employee.id && member.type === ProjectMemberType.MANAGER
            ) || this.authService.hasRight(RIGHTS.PROJECT_ADMIN_ALL)
        );
    }

    public async getTags() {
        if (!this.project || !this.project.id) {
            return;
        }

        this.tags = await this.httpRest
            ._request<ITagWithTaskCount[]>(
                HTTP_METHOD.GET,
                ApiRoutePlurality.PLURAL,
                TAG_SCHEMA_ROUTE,
                `project/taskCount/${this.project.id}`
            )
            .toPromise();

        this.setActiveTags();
    }

    public setActiveTags() {
        this.notDoneTags = this.tags.filter(({taskTagsCount}) => taskTagsCount.notDone);
        this.doneTags = this.tags.filter(({taskTagsCount}) => taskTagsCount.done && !taskTagsCount.notDone);
        this.inactiveTags = this.tags.filter(({taskTagsCount}) => !taskTagsCount.done && !taskTagsCount.notDone);
    }

    public async addTag() {
        const text = this.tagInputText.trim();
        if (!text) {
            return;
        }

        if (this.tags.find((_tag) => _tag.text === text)) {
            this.resetTagInput();
            this.toastService.show({
                type: TOAST_TYPE.WARNING,
                text: 'warning_duplicate_tag',
            });

            return;
        }

        const tag = await this.updateTag({
            text,
            color: this.tagInputColor,
            branch: this.tagInputBranch,
            projectId: this.project.id,
        });

        this.tags.push({...tag, taskTagsCount: {done: 0, notDone: 0}} as ITagWithTaskCount);

        this.toastService.show({
            type: TOAST_TYPE.SUCCESS,
            text: 'success_insert_tag',
        });

        this.setActiveTags();
        this.resetTagInput();
    }

    public async toggleTagBranch(tag: ITagWithTaskCount): Promise<ITag> {
        tag.branch = !tag.branch;

        return this.updateTag(tag);
    }

    public async updateTag(tag: ITag): Promise<ITag> {
        if (tag.id) {
            // @ts-ignore
            return this.httpRest.post<ITag>(TAG_SCHEMA_ROUTE, {...tag}).toPromise();
        } else {
            return this.httpRest.put<ITag>(TAG_SCHEMA_ROUTE, {...tag}).toPromise();
        }
    }

    public async removeTag(tag: ITagWithTaskCount, force = false) {
        if (!force && (tag.taskTagsCount.notDone || tag.taskTagsCount.done)) {
            this.selectedTag = tag;
            this.showDeleteModal = true;

            return;
        }

        try {
            await this.httpRest.deleteId(TAG_SCHEMA_ROUTE, tag.id).toPromise();
        } catch (err) {
            return;
        } finally {
            this.showDeleteModal = false;
            this.selectedTag = undefined;
        }

        this.tags.splice(this.tags.indexOf(tag), 1);
        this.setActiveTags();
    }

    private resetTagInput() {
        this.tagInputText = '';
        this.tagInputColor = getRandomColorHex();

        const $tagInput = this.tagInput && (this.tagInput.nativeElement as HTMLInputElement);
        if ($tagInput) {
            $tagInput.focus();
        }
    }

    public readonly RIGHTS = RIGHTS;
}
