import {Component, OnInit} from '@angular/core';
import {formatNumber} from 'libphonenumber-js';
import {ApiRoutePlurality, HTTP_METHOD, RELEASE_TYPE_LABEL, ReleaseStateType} from '../../../../../defs/schema-static';
import {CLIENT_SCHEMA_ROUTE} from '../../../../../defs/schema/public/Clients';
import {IDashNumber, isColorDark} from '../../app-static';
import {AuthService} from '../../auth/auth.service';
import {HttpRestService} from '../../shared/http-rest/http-rest.service';

@Component({
    selector: 'app-dash-client',
    templateUrl: './dash-client.component.html',
    styleUrls: ['./dash-client.component.scss'],
})
export class DashClientComponent implements OnInit {
    public portalData: any;

    public globalNumber: IDashNumber;
    public requestNumber: IDashNumber;
    public incidentNumber: IDashNumber;

    public constructor(private readonly httpRest: HttpRestService, public readonly authService: AuthService) {
        this.globalNumber = {
            title: 'global_completion',
            subtitle: '',
            color: 'completion',
            icon: 'chart-pie',
            textAlign: 'right',
        };

        this.requestNumber = {
            title: 'request_count',
            subtitle: '',
            color: 'request',
            icon: 'chalkboard-teacher',
            textAlign: 'right',
        };

        this.incidentNumber = {
            title: 'incident_count',
            subtitle: '',
            color: 'incident',
            icon: 'life-ring',
            textAlign: 'right',
        };
    }

    public ngOnInit() {
        this.httpRest
            ._request(
                HTTP_METHOD.GET,
                ApiRoutePlurality.SINGULAR,
                CLIENT_SCHEMA_ROUTE,
                `portaldata/${this.authService.user.client.id}`
            )
            .subscribe((data) => {
                this.portalData = data;

                this.globalNumber.subtitle = `${(
                    (this.portalData.data.taskFinished / this.portalData.data.taskTotal) * 100 || 0
                )
                    .toFixed(2)
                    .toString()} %`;
                this.requestNumber.subtitle = this.portalData.data.customerRequest.length.toString() || '';
                this.incidentNumber.subtitle = this.portalData.data.incidents.length.toString() || '';
            });
    }

    protected mailTo = (mail: string) => {
        window.open(`mailto:${mail}`, '_self');
    };

    protected formattedProjectList() {
        return this.portalData.data._project.map((p: any) => p.obs).join(', ');
    }

    protected readonly isColorDark = isColorDark;
    protected readonly RELEASE_TYPE_LABEL = RELEASE_TYPE_LABEL;
    protected readonly ReleaseStateType = ReleaseStateType;
    protected readonly formatNumber = formatNumber;
}
