import * as i0 from "./sticky-notes.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@clr/angular";
import * as i4 from "../../../node_modules/@clr/angular/clr-angular.ngfactory";
import * as i5 from "@angular/forms";
import * as i6 from "../shared/list-auto-indent/list-auto-indent.directive";
import * as i7 from "../shared/autoresize/autoresize.directive";
import * as i8 from "../shared/convert-checkboxes/convert-checkboxes.pipe";
import * as i9 from "@angular/platform-browser";
import * as i10 from "ngx-markdown";
import * as i11 from "../shared/prepare-markdown/prepare-markdown.pipe";
import * as i12 from "./sticky-notes.component";
import * as i13 from "@ngx-translate/core";
import * as i14 from "../shared/http-rest/http-rest.service";
import * as i15 from "../auth/auth.service";
import * as i16 from "../shared/moment/moment.service";
import * as i17 from "../modal-simple/modal-simple.service";
var styles_StickyNotesComponent = [i0.styles];
var RenderType_StickyNotesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StickyNotesComponent, data: {} });
export { RenderType_StickyNotesComponent as RenderType_StickyNotesComponent };
function View_StickyNotesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "clr-col-12 add-sticky"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addNote() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["+"]))], null, null); }
function View_StickyNotesComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "color-item c-hand"], ["clrDropdownItem", ""]], [[2, "disabled", null], [2, "dropdown-item", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeColor(_v.parent.parent.parent.context.index, _v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(2, { background: 0 }), i1.ɵdid(3, 4210688, null, 0, i3.ClrDropdownItem, [i3.ClrDropdown, i1.ElementRef, i3.ɵe, i1.Renderer2], null, null)], function (_ck, _v) { var currVal_2 = _ck(_v, 2, 0, _v.context.$implicit.color); _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit.color === _co.colors[_v.parent.parent.parent.context.$implicit.color]); var currVal_1 = true; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_StickyNotesComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "clr-dropdown-menu", [["clrPosition", "bottom-right"]], [[2, "dropdown-menu", null], [2, "is-off-screen", null]], null, null, i4.View_ClrDropdownMenu_0, i4.RenderType_ClrDropdownMenu)), i1.ɵdid(1, 8568832, null, 0, i3.ClrDropdownMenu, [i1.Injector, [2, i3.ɵh], [3, i3.ClrDropdownMenu]], { position: [0, "position"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_StickyNotesComponent_5)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "bottom-right"; _ck(_v, 1, 0, currVal_2); var currVal_3 = _co.colorsAvailable; _ck(_v, 3, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = true; var currVal_1 = i1.ɵnov(_v, 1).isOffScreen; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_StickyNotesComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "clr-dropdown", [], [[2, "dropdown", null], [2, "open", null]], null, null, i4.View_ClrDropdown_0, i4.RenderType_ClrDropdown)), i1.ɵprd(6144, null, i3.ɵh, null, [i1.ElementRef]), i1.ɵprd(512, null, i3.ɵd, i3.ɵd, []), i1.ɵprd(1024, null, i3.ɵe, i3.ɵf, [[3, i3.ɵe]]), i1.ɵdid(4, 180224, null, 0, i3.ClrDropdown, [[3, i3.ClrDropdown], i3.ɵd, i1.ChangeDetectorRef, i3.ɵe], null, null), (_l()(), i1.ɵeld(5, 0, null, 0, 3, "button", [["class", "dropdown-toggle btn-icon btn btn-primary"], ["clrDropdownTrigger", ""], ["style", "padding-right: 0.5rem"], ["tabindex", "-1"]], [[2, "dropdown-toggle", null], [2, "dropdown-item", null], [2, "expandable", null], [2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onDropdownTriggerClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 16384, null, 0, i3.ClrDropdownTrigger, [i3.ClrDropdown, i3.ɵd], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "clr-icon", [["class", "is-solid"], ["shape", "color-picker"]], null, null, null, null, null)), i1.ɵdid(8, 16384, null, 0, i3.ClrIconCustomTag, [], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_StickyNotesComponent_4)), i1.ɵdid(10, 147456, null, 0, i3.ClrIfOpen, [i3.ɵd, i1.TemplateRef, i1.ViewContainerRef], { open: [0, "open"] }, null)], function (_ck, _v) { var currVal_6 = null; _ck(_v, 10, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = true; var currVal_1 = i1.ɵnov(_v, 4).ifOpenService.open; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵnov(_v, 6).isRootLevelToggle; var currVal_3 = !i1.ɵnov(_v, 6).isRootLevelToggle; var currVal_4 = !i1.ɵnov(_v, 6).isRootLevelToggle; var currVal_5 = i1.ɵnov(_v, 6).active; _ck(_v, 5, 0, currVal_2, currVal_3, currVal_4, currVal_5); }); }
function View_StickyNotesComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "dropdown-toggle btn btn-danger btn-icon"], ["tabindex", "-1"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.delete(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "clr-icon", [["shape", "trash"]], null, null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i3.ClrIconCustomTag, [], null, null)], null, null); }
function View_StickyNotesComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "textarea", [["class", "fck-word-breaker"], ["sharedAutosize", ""], ["sharedListAutoIndent", ""]], [[8, "id", 0], [1, "data-index", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "focus"], [null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"], ["window", "resize"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("input" === en)) {
        var pd_4 = (i1.ɵnov(_v, 6).onInput($event) !== false);
        ad = (pd_4 && ad);
    } if (("window:resize" === en)) {
        var pd_5 = (i1.ɵnov(_v, 7).onResize($event.target) !== false);
        ad = (pd_5 && ad);
    } if (("input" === en)) {
        var pd_6 = (i1.ɵnov(_v, 7).onInput($event.target) !== false);
        ad = (pd_6 && ad);
    } if (("focus" === en)) {
        var pd_7 = ((_co.editAllowed && (_v.parent.context.$implicit.edit = true)) !== false);
        ad = (pd_7 && ad);
    } if (("ngModelChange" === en)) {
        var pd_8 = ((_v.parent.context.$implicit.text = $event) !== false);
        ad = (pd_8 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i5.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i5.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.DefaultValueAccessor]), i1.ɵdid(3, 671744, null, 0, i5.NgModel, [[8, null], [8, null], [8, null], [6, i5.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i5.NgControl, null, [i5.NgModel]), i1.ɵdid(5, 16384, null, 0, i5.NgControlStatus, [[4, i5.NgControl]], null, null), i1.ɵdid(6, 16384, null, 0, i6.ListAutoIndentDirective, [], { ngModel: [0, "ngModel"] }, { ngModelChange: "ngModelChange" }), i1.ɵdid(7, 4734976, null, 0, i7.AutoresizeDirective, [i1.ElementRef], { ngModel: [0, "ngModel"] }, { ngModelChange: "ngModelChange" }), (_l()(), i1.ɵted(-1, null, ["                        "]))], function (_ck, _v) { var currVal_9 = i1.ɵinlineInterpolate(1, "text-", _v.parent.context.index, ""); var currVal_10 = _v.parent.context.$implicit.text; _ck(_v, 3, 0, currVal_9, currVal_10); var currVal_11 = _v.parent.context.$implicit.text; _ck(_v, 6, 0, currVal_11); var currVal_12 = _v.parent.context.$implicit.text; _ck(_v, 7, 0, currVal_12); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "sn-text-", _v.parent.context.index, ""); var currVal_1 = _v.parent.context.index; var currVal_2 = i1.ɵnov(_v, 5).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 5).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 5).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 5).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 5).ngClassValid; var currVal_7 = i1.ɵnov(_v, 5).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 5).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
function View_StickyNotesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 23, "div", [["class", "card p-04"]], [[1, "data-index", 0]], null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(2, { background: 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 12, "div", [["class", "clr-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [["class", "clr-col-8 title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 5, "input", [["type", "text"]], [[8, "id", 0], [1, "data-index", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 6).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 6)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 6)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_v.context.$implicit.title = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 16384, null, 0, i5.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i5.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.DefaultValueAccessor]), i1.ɵdid(8, 671744, null, 0, i5.NgModel, [[8, null], [8, null], [8, null], [6, i5.NG_VALUE_ACCESSOR]], { name: [0, "name"], isDisabled: [1, "isDisabled"], model: [2, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i5.NgControl, null, [i5.NgModel]), i1.ɵdid(10, 16384, null, 0, i5.NgControlStatus, [[4, i5.NgControl]], null, null), (_l()(), i1.ɵeld(11, 0, null, null, 4, "div", [["class", "clr-col-4"], ["style", "text-align: right;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StickyNotesComponent_3)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StickyNotesComponent_6)), i1.ɵdid(15, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(16, 0, null, null, 7, "div", [["class", "clr-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 6, "div", [["class", "clr-col-12 content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StickyNotesComponent_7)), i1.ɵdid(19, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(20, 0, null, null, 3, "div", [], [[8, "hidden", 0], [8, "innerHTML", 1]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.editAllowed && _co.edit(_v.context.index, $event)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵppd(21, 1), i1.ɵppd(22, 1), i1.ɵppd(23, 1)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 2, 0, _co.colors[_v.context.$implicit.color]); _ck(_v, 1, 0, currVal_1); var currVal_11 = i1.ɵinlineInterpolate(1, "title-", _v.context.index, ""); var currVal_12 = !_co.editAllowed; var currVal_13 = _v.context.$implicit.title; _ck(_v, 8, 0, currVal_11, currVal_12, currVal_13); var currVal_14 = _co.editAllowed; _ck(_v, 13, 0, currVal_14); var currVal_15 = _co.editAllowed; _ck(_v, 15, 0, currVal_15); var currVal_16 = (_v.context.$implicit.edit || (_v.context.$implicit.text === "")); _ck(_v, 19, 0, currVal_16); }, function (_ck, _v) { var currVal_0 = _v.context.index; _ck(_v, 0, 0, currVal_0); var currVal_2 = i1.ɵinlineInterpolate(1, "sn-title-", _v.context.index, ""); var currVal_3 = _v.context.index; var currVal_4 = i1.ɵnov(_v, 10).ngClassUntouched; var currVal_5 = i1.ɵnov(_v, 10).ngClassTouched; var currVal_6 = i1.ɵnov(_v, 10).ngClassPristine; var currVal_7 = i1.ɵnov(_v, 10).ngClassDirty; var currVal_8 = i1.ɵnov(_v, 10).ngClassValid; var currVal_9 = i1.ɵnov(_v, 10).ngClassInvalid; var currVal_10 = i1.ɵnov(_v, 10).ngClassPending; _ck(_v, 5, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); var currVal_17 = (_v.context.$implicit.edit || (_v.context.$implicit.text === "")); var currVal_18 = i1.ɵunv(_v, 20, 1, _ck(_v, 23, 0, i1.ɵnov(_v.parent, 0), i1.ɵunv(_v, 20, 1, _ck(_v, 22, 0, i1.ɵnov(_v.parent, 1), i1.ɵunv(_v, 20, 1, _ck(_v, 21, 0, i1.ɵnov(_v.parent, 2), _v.context.$implicit.text)))))); _ck(_v, 20, 0, currVal_17, currVal_18); }); }
export function View_StickyNotesComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i8.ConvertCheckboxesPipe, [i9.DomSanitizer]), i1.ɵpid(0, i10.MarkdownPipe, [i1.ElementRef, i10.MarkdownService, i1.NgZone]), i1.ɵpid(0, i11.PrepareMarkdownPipe, []), (_l()(), i1.ɵeld(3, 0, null, null, 7, "div", [["class", "sticky-notes-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "clr-row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StickyNotesComponent_1)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "scrollable-sticky-notes"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "card-columns"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StickyNotesComponent_2)), i1.ɵdid(10, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.editAllowed; _ck(_v, 6, 0, currVal_0); var currVal_1 = _co.stickyNotes; _ck(_v, 10, 0, currVal_1); }, null); }
export function View_StickyNotesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-sticky-notes", [], null, [["document", "click"], [null, "change"]], function (_v, en, $event) { var ad = true; if (("document:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("change" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).checkboxChange($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_StickyNotesComponent_0, RenderType_StickyNotesComponent)), i1.ɵdid(1, 573440, null, 0, i12.StickyNotesComponent, [i13.TranslateService, i14.HttpRestService, i15.AuthService, i16.MomentService, i17.ModalSimpleService], null, null)], null, null); }
var StickyNotesComponentNgFactory = i1.ɵccf("app-sticky-notes", i12.StickyNotesComponent, View_StickyNotesComponent_Host_0, { stickyNotes: "stickyNotes", projectId: "projectId", clientId: "clientId", editAllowed: "editAllowed" }, {}, []);
export { StickyNotesComponentNgFactory as StickyNotesComponentNgFactory };
