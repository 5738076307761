<div class="file-manager-container" [ngClass]="{hidden: !visible, minimized: reduced}">
    <div class="clr-row p-0 file-manager-header">
        <div class="clr-col-6">
            <h3 class="mt-05">
                <clr-icon shape="upload"></clr-icon>
                {{ 'file_manager_title' | translate }}
            </h3>
        </div>
        <div class="clr-col-6 p-0 fm-buttons">
            <button
                class="btn btn-icon btn-danger"
                *ngIf="filesRunning.length"
                [title]="'cancel_all' | translate"
                (click)="cancelAll()"
            >
                <clr-icon shape="times"></clr-icon>
            </button>

            <button
                class="btn btn-icon btn-danger-outline"
                *ngIf="getFilesState(FileStateType.UPLOADING).length"
                [title]="'pause_all' | translate"
                (click)="pauseAll()"
            >
                <clr-icon shape="pause"></clr-icon>
            </button>

            <button
                class="btn btn-icon btn-success-outline"
                *ngIf="getFilesState(FileStateType.PAUSED).length"
                [title]="'resume_all' | translate"
                (click)="resumeAll()"
            >
                <clr-icon shape="play"></clr-icon>
            </button>

            <button
                class="btn btn-icon btn-success"
                [disabled]="!filesOver.length"
                *ngIf="!reduced"
                [title]="'empty_list' | translate"
                (click)="clear()"
            >
                <clr-icon shape="trash"></clr-icon>
            </button>

            <button class="btn btn-icon" *ngIf="!reduced" [title]="'reduce' | translate" (click)="reduce()">
                <clr-icon shape="window-min"></clr-icon>
            </button>

            <button class="btn btn-icon" *ngIf="reduced" [title]="'restore' | translate" (click)="this.maximize()">
                <clr-icon shape="window-restore"></clr-icon>
            </button>
        </div>
    </div>

    <div class="file-manager-summary" *ngIf="reduced" [ngClass]="{'c-hand': filesOver.length}">
        <p *ngIf="getFilesState(FileStateType.UPLOADING).length">
            {{ 'uploading_files_count' | translate: {count: getFilesState(FileStateType.UPLOADING).length} }}
        </p>
        <p *ngIf="getFilesState(FileStateType.WAITING, FileStateType.PENDING, FileStateType.PAUSED).length">
            {{
                'pending_files_count'
                    | translate
                        : {
                              count: getFilesState(FileStateType.WAITING, FileStateType.PENDING, FileStateType.PAUSED)
                                  .length
                          }
            }}
        </p>
        <p *ngIf="getFilesState(FileStateType.DONE).length">
            {{ 'uploaded_files_count' | translate: {count: files.length} }}
        </p>
        <p *ngIf="getFilesState(FileStateType.CANCELLED, FileStateType.REMOVED, FileStateType.ERROR).length">
            {{
                'cancelled_files_count'
                    | translate
                        : {
                              count: getFilesState(FileStateType.CANCELLED, FileStateType.REMOVED, FileStateType.ERROR)
                                  .length
                          }
            }}
        </p>

        <span *ngIf="averageProgress < 1"> {{ ((averageProgress || 0) * 100).toFixed() }}% </span>
    </div>

    <div class="file-manager-list" *ngIf="!reduced">
        <ng-container *ngFor="let _files of [filesRunning, filesOver]; let last = last">
            <ul>
                <li *ngFor="let file of _files" class="file-manager-file clr-row">
                    <div class="clr-col-7 file-manager-filename text-bold" [title]="file.path">
                        <ng-container *ngIf="file.state !== FileStateType.DONE">
                            {{ getFilename(file.path) }}
                        </ng-container>
                        <a class="c-hand" *ngIf="file.state === FileStateType.DONE" (click)="downloadS3(file)">
                            {{ getFilename(file.path) }}
                        </a>
                    </div>

                    <div class="clr-col-3 file-manager-progress text-center">
                        <app-file-manager-progress [file]="file"></app-file-manager-progress>
                    </div>

                    <div class="clr-col-2 file-manager-actions">
                        <clr-icon
                            *ngIf="file.state === FileStateType.UPLOADING || file.state === FileStateType.DONE"
                            (click)="file.cancel()"
                            shape="times"
                            class="c-hand"
                            [title]="'cancel' | translate"
                        ></clr-icon>
                        <clr-icon
                            *ngIf="file.state === FileStateType.UPLOADING"
                            (click)="file.pause()"
                            shape="pause"
                            class="c-hand"
                            [title]="'pause' | translate"
                        ></clr-icon>
                        <clr-icon
                            *ngIf="file.state === FileStateType.PAUSED"
                            (click)="file.resume()"
                            shape="play"
                            class="c-hand"
                            [title]="'resume' | translate"
                        ></clr-icon>
                        <clr-icon
                            *ngIf="
                                file.state === FileStateType.REMOVED ||
                                file.state === FileStateType.CANCELLED ||
                                file.state === FileStateType.ERROR
                            "
                            (click)="file.resend()"
                            shape="redo"
                            class="c-hand"
                            [title]="'resend' | translate"
                        ></clr-icon>
                    </div>
                </li>
            </ul>
            <div class="divider" *ngIf="!last && filesRunning.length && filesOver.length"></div>
        </ng-container>
    </div>
</div>
