var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { ApiRoutePlurality, HTTP_METHOD, ISchemaRoute } from '../../../../../defs/schema-static';
import { SETTING_LANGUAGE_VALUES, USER_FIELD } from '../../../../../defs/schema/public/Users';
import { API_HTTP_ROOT, API_VERSION, noop } from '../../app-static';
import { TOAST_TYPE, ToastService } from '../toast/toast.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../toast/toast.service";
import * as i3 from "@ngx-translate/core";
var API_VERSIONS;
(function (API_VERSIONS) {
    API_VERSIONS["V1"] = "v1";
    API_VERSIONS["V2"] = "v2";
})(API_VERSIONS || (API_VERSIONS = {}));
var HttpRestService = (function () {
    function HttpRestService(http, toastService, translate) {
        this.http = http;
        this.toastService = toastService;
        this.translate = translate;
        this.modal = {
            show: false,
        };
    }
    HttpRestService.prototype.getQuery = function (route, query) {
        return this._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, route, HttpRestService.queryString(query));
    };
    HttpRestService.prototype.post = function (route, entity) {
        return this._request(HTTP_METHOD.POST, ApiRoutePlurality.SINGULAR, route, entity.id.toString(), HttpRestService.stripDepth(entity));
    };
    HttpRestService.prototype.postEntities = function (route, entities) {
        return this._request(HTTP_METHOD.POST, ApiRoutePlurality.PLURAL, route, entities.map(function (entity) { return entity.id; }).toString(), entities.map(function (entity) { return HttpRestService.stripDepth(entity); }));
    };
    HttpRestService.prototype.postBulk = function (route, values, filter) {
        return this._request(HTTP_METHOD.POST, ApiRoutePlurality.PLURAL, route, HttpRestService.queryString({ q: filter, bulk: true }), values);
    };
    HttpRestService.prototype.put = function (route, entity) {
        return this._request(HTTP_METHOD.PUT, ApiRoutePlurality.SINGULAR, route, '', HttpRestService.stripDepth(entity));
    };
    HttpRestService.prototype.putEntities = function (route, entities) {
        return this._request(HTTP_METHOD.PUT, ApiRoutePlurality.PLURAL, route, '', entities.map(function (entity) { return HttpRestService.stripDepth(entity); }));
    };
    HttpRestService.prototype.deleteId = function (route, id) {
        return this._request(HTTP_METHOD.DELETE, ApiRoutePlurality.SINGULAR, route, id.toString());
    };
    HttpRestService.prototype.deleteIds = function (route, ids) {
        if (!ids || !ids.length) {
            return of({ count: 0 });
        }
        if (ids.length === 1) {
            return this.deleteId(route, ids[0]);
        }
        return this._request(HTTP_METHOD.DELETE, ApiRoutePlurality.PLURAL, route, ids.toString());
    };
    HttpRestService.prototype.deleteQuery = function (route, query) {
        return this._request(HTTP_METHOD.DELETE, ApiRoutePlurality.PLURAL, route, HttpRestService.queryString(query));
    };
    HttpRestService.prototype._requestString = function (verb, plurality, route, query, body) {
        var method = [
            API_HTTP_ROOT,
            API_VERSION,
            route.schema,
            plurality === ApiRoutePlurality.SINGULAR ? route.singularRoute : route.pluralRoute,
            query || '',
        ]
            .filter(function (str) { return !!str; })
            .join('/');
        var responseType = 'text';
        var request;
        switch (verb) {
            case HTTP_METHOD.GET:
                request = this.http.get(method, { responseType: responseType });
                break;
            case HTTP_METHOD.POST:
                request = this.http.post(method, body, { responseType: responseType });
                break;
            case HTTP_METHOD.PUT:
                request = this.http.put(method, body, { responseType: responseType });
                break;
            case HTTP_METHOD.DELETE:
                request = this.http.delete(method, { responseType: responseType });
                break;
            default:
                throw new Error("Unsupported HTTP method: " + verb);
        }
        return this.sendRequest(request);
    };
    HttpRestService.prototype._request = function (verb, plurality, route, query, body) {
        var method = [
            API_HTTP_ROOT,
            API_VERSION,
            route.schema,
            plurality === ApiRoutePlurality.SINGULAR ? route.singularRoute : route.pluralRoute,
            query || '',
        ]
            .filter(function (str) { return !!str; })
            .join('/');
        var request;
        switch (verb) {
            case HTTP_METHOD.GET:
                request = this.http.get(method);
                break;
            case HTTP_METHOD.POST:
                request = this.http.post(method, body);
                break;
            case HTTP_METHOD.PUT:
                request = this.http.put(method, body);
                break;
            case HTTP_METHOD.DELETE:
                request = this.http.delete(method);
                break;
            default:
                throw new Error("Unsupported HTTP method: " + verb);
        }
        return this.sendRequest(request);
    };
    HttpRestService.prototype.sendRequest = function (request) {
        var _this = this;
        var replay = request.pipe(shareReplay());
        void (function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
            return [2, replay.subscribe(noop, this.errorHandler.bind(this))];
        }); }); })();
        return replay;
    };
    HttpRestService.queryString = function (query) {
        return "?" + Object.keys(query)
            .map(function (key) {
            if (['q', 'where'].includes(key)) {
                return key + "=" + JSON.stringify(query[key]);
            }
            return key + "=" + query[key].toString();
        })
            .join('&');
    };
    HttpRestService.stripDepth = function (obj, depth) {
        if (depth === void 0) { depth = 1; }
        return Object.keys(obj).reduce(function (_strippedObj, key) {
            if (!(_strippedObj[key] instanceof Object)) {
                return _strippedObj;
            }
            if (_strippedObj[key] && _strippedObj[key].hasOwnProperty('_isAMomentObject')) {
                _strippedObj[key] = _strippedObj[key].format();
                return _strippedObj;
            }
            if (HttpRestService.ALLOWED_OBJECT_FIELD.includes(key)) {
                return _strippedObj;
            }
            if (depth - 1 <= 0) {
                delete _strippedObj[key];
            }
            else {
                _strippedObj[key] = HttpRestService.stripDepth(_strippedObj[key], depth - 1);
            }
            return _strippedObj;
        }, __assign({}, obj));
    };
    HttpRestService.prototype.errorHandler = function (_a) {
        var error = _a.error;
        return __awaiter(this, void 0, void 0, function () {
            var browserLang, lang, translations, _b, _c, _d, _e;
            var _this = this;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        if (!error) {
                            return [2];
                        }
                        if (!!this.translate.currentLang) return [3, 2];
                        browserLang = this.translate.getBrowserLang().toUpperCase();
                        lang = Object.values(SETTING_LANGUAGE_VALUES).includes(browserLang)
                            ? browserLang
                            : SETTING_LANGUAGE_VALUES.EN;
                        this.translate.use(lang);
                        return [4, new Promise(function (resolve, reject) { return _this.translate.onLangChange.subscribe(resolve, reject); })];
                    case 1:
                        _f.sent();
                        _f.label = 2;
                    case 2: return [4, this.translate.get(['generic_error']).toPromise()];
                    case 3:
                        translations = _f.sent();
                        if (!error.err) return [3, 8];
                        _b = error.err;
                        switch (_b) {
                            case 'exception_version': return [3, 4];
                        }
                        return [3, 5];
                    case 4:
                        this.modal = {
                            show: true,
                            title: 'generic_error',
                            content: error.err + "_" + error.reason,
                            callback: function () {
                                window.location.reload(true);
                                _this.modal.show = false;
                            },
                            callbackLabel: 'reload',
                        };
                        return [3, 7];
                    case 5:
                        _d = (_c = Object).assign;
                        _e = [translations];
                        return [4, this.translate.get([error.err, error.reason || '']).toPromise()];
                    case 6:
                        _d.apply(_c, _e.concat([_f.sent()]));
                        if (translations[error.reason] === error.reason) {
                            error.reason = 'generic_error';
                        }
                        this.toastService.show({
                            type: TOAST_TYPE.ERROR,
                            text: translations[error.err],
                            callbackText: 'more_info',
                            callback: function () {
                                _this.modal = {
                                    show: true,
                                    title: error.err,
                                    content: translations[error.reason],
                                    callback: undefined,
                                };
                            },
                        });
                        return [3, 7];
                    case 7: return [3, 9];
                    case 8:
                        this.toastService.show({
                            type: TOAST_TYPE.ERROR,
                            text: translations.generic_error,
                        });
                        _f.label = 9;
                    case 9: return [2];
                }
            });
        });
    };
    HttpRestService.ALLOWED_OBJECT_FIELD = [USER_FIELD.settings, 'where', 'position', 'size'];
    HttpRestService.ngInjectableDef = i0.defineInjectable({ factory: function HttpRestService_Factory() { return new HttpRestService(i0.inject(i1.HttpClient), i0.inject(i2.ToastService), i0.inject(i3.TranslateService)); }, token: HttpRestService, providedIn: "root" });
    return HttpRestService;
}());
export { HttpRestService };
