import {EventEmitter, Injectable, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {
    AuthenticationDetails,
    CognitoIdToken,
    CognitoUser,
    CognitoUserAttribute,
    CognitoUserPool,
    CognitoUserSession,
} from 'amazon-cognito-identity-js';
import {CognitoIdentityCredentials} from 'aws-sdk';
import {GetCredentialsForIdentityResponse} from 'aws-sdk/clients/cognitoidentity';
import publicConfig from '../../../../defs/config/config.json';
import {
    ApiRoutePlurality,
    COGNITO_USER_GROUPS,
    COGNITO_USER_GROUPS_PRECEDENCE,
    HTTP_METHOD,
    RIGHTS,
} from '../../../../defs/schema-static';
import {COGNITO_PUBLIC_SCHEMA_ROUTE} from '../../../../defs/schema/public/CognitoPublic';
import {PROJECT_SCHEMA_ROUTE} from '../../../../defs/schema/public/Projects';
import {IRight, RIGHT_SCHEMA_ROUTE} from '../../../../defs/schema/public/Rights';
import {IUser, USER_SCHEMA_ROUTE} from '../../../../defs/schema/public/Users';
import {noop} from '../app-static';
import {HttpRestService} from '../shared/http-rest/http-rest.service';
import {MomentService} from '../shared/moment/moment.service.js';

interface ICodeDeliveryDetails {
    CodeDeliveryDetails: {
        AttributeName: string;
        DeliveryMedium: string;
        Destination: string;
    };
}

export interface ICognitoError {
    code: string;
    name: string;
    message: string;
}

interface IModal {
    show: boolean;
    title?: string;
    content?: string;
    callback?(value: number): void;
    callbackLabel?: string;
    value?: any;
    valueBis?: string;
    cancelable?: boolean;
    cancel?(): void;
    error?: string;
    confirmPassword?: string;
}

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    public modalConfirmNumber: IModal = {
        show: false,
        cancelable: false,
    };

    public modalAlert: IModal = {
        show: false,
        callback: undefined,
    };

    public modalUpdate: IModal = {
        show: false,
        cancelable: false,
    };

    public modalConfirmPass: IModal = {
        show: false,
        cancelable: true,
    };

    @Output() public isAuthenticatedChange = new EventEmitter<boolean>(true);
    public isAuthenticated = false;

    @Output() public cognitoUsernameChange = new EventEmitter<string>(true);
    public cognitoUsername: string;

    @Output() public userChange = new EventEmitter<IUser>();
    public user: IUser;

    public rights: IRight[];

    private credentials: CognitoIdentityCredentials;

    public cognitoGroups: COGNITO_USER_GROUPS[] = [COGNITO_USER_GROUPS._UNAUTHED];

    private readonly storage = window.localStorage;

    private readonly userPool = new CognitoUserPool({
        UserPoolId: publicConfig.cognito.userPoolId,
        ClientId: publicConfig.cognito.clientId,
        Storage: this.storage,
    });

    public constructor(
        private readonly httpRest: HttpRestService,
        private readonly translate: TranslateService,
        private readonly momentService: MomentService
    ) {}

    private cognitoUser: CognitoUser;
    private cognitoSession: CognitoUserSession;

    public get accessToken(): CognitoIdToken {
        return this.cognitoSession && this.cognitoSession.getAccessToken();
    }

    public get idToken(): CognitoIdToken {
        return this.cognitoSession && this.cognitoSession.getIdToken();
    }

    public get accessJWTToken(): string {
        return this.accessToken && this.accessToken.getJwtToken();
    }

    public get idJWTToken(): string {
        return this.idToken && this.idToken.getJwtToken();
    }

    private credentialsGetPromise: Promise<void>;
    private credentialsRefreshPromise: Promise<void>;

    public async getAwsCredentials(loop = 0): Promise<CognitoIdentityCredentials> {
        if (!this.idJWTToken) {
            return null;
            // FIXME prolly not necessary, no anonymous requests
            /*return new CognitoIdentityCredentials({
        IdentityPoolId: publicConfig.cognito.identityPoolId,
        Logins: {}
      });*/
        }

        if (!this.credentials || this.credentials.expired) {
            // prevents multiple requests
            try {
                if (!this.credentialsGetPromise) {
                    this.credentialsGetPromise = new Promise<void>(async (resolve, reject) => {
                        try {
                            // FIXME remove force when 'was supposed to expire' is fixed
                            await this.refreshSession(true);
                            // https://github.com/aws-amplify/amplify-js/issues/405
                            // https://gist.github.com/kndt84/5be8e86a15468ed1c8fc3699429003ad
                            this.credentials = new CognitoIdentityCredentials(
                                {
                                    IdentityPoolId: publicConfig.cognito.identityPoolId,
                                    Logins: {
                                        [this.COGNITO_RESOURCE_URL]: this.idJWTToken,
                                    },
                                    // . RoleArn: publicConfig.cognito.roles[this.precedenceGroup],
                                },
                                {region: publicConfig.cognito.region}
                            );

                            await this.credentials.getPromise();
                            resolve();
                        } catch (err) {
                            reject(err);
                        }
                    });
                }

                await this.credentialsGetPromise;
            } catch (err) {
                // tslint:disable-next-line:no-console
                console.error(err);

                if (loop >= 2) {
                    // tslint:disable-next-line:no-console
                    console.error('giving up');

                    return undefined;
                }

                this.credentialsGetPromise = null;

                return new Promise<CognitoIdentityCredentials>((resolve, reject) => {
                    this.restoreSession(async (err) => {
                        if (err) {
                            reject(err);

                            return;
                        }

                        try {
                            const credentials = await this.getAwsCredentials(loop + 1);
                            resolve(credentials);
                        } catch (err) {
                            reject(err);
                        }
                    });
                });
            }

            this.credentialsGetPromise = null;
        }

        if (this.credentials.needsRefresh()) {
            // prevents multiple requests
            if (!this.credentialsRefreshPromise) {
                this.credentialsRefreshPromise = new Promise<void>(async (resolve, reject) => {
                    try {
                        // FIXME remove force when 'was supposed to expire' is fixed
                        await this.refreshSession(true);
                        await this.credentials.refreshPromise();
                        resolve();
                    } catch (err) {
                        reject(err);
                    }
                });
            }

            await this.credentialsRefreshPromise;
            this.credentialsRefreshPromise = null;
        }

        {
            // update user.cognitoIdentityGuid
            const cognitoIdentityGuid = (
                ((this.credentials as CognitoIdentityCredentials).data as GetCredentialsForIdentityResponse)
                    .IdentityId || ''
            )
                // remove region
                .split(':')[1];

            if (cognitoIdentityGuid && cognitoIdentityGuid !== this.user.cognitoIdentityGuid) {
                this.user.cognitoIdentityGuid = cognitoIdentityGuid;

                await this.httpRest
                    ._request<IUser>(
                        HTTP_METHOD.POST,
                        ApiRoutePlurality.SINGULAR,
                        USER_SCHEMA_ROUTE,
                        'cognito/identityGuid',
                        {
                            cognitoIdentityGuid,
                        }
                    )
                    .toPromise();
            }
        }

        return this.credentials;
    }

    public get precedenceGroup(): COGNITO_USER_GROUPS {
        return this.cognitoGroups.sort(
            (g1, g2) =>
                (COGNITO_USER_GROUPS_PRECEDENCE[g1] || Number.MAX_SAFE_INTEGER) -
                (COGNITO_USER_GROUPS_PRECEDENCE[g2] || Number.MAX_SAFE_INTEGER)
        )[0];
    }

    public signUp(username: string, email: string, password: string, callback: (err?: Error) => void = noop) {
        this.userPool.signUp(
            username,
            password,
            [new CognitoUserAttribute({Name: 'email', Value: email})],
            null,
            async (err: ICognitoError, result) => {
                if (err) {
                    if (err.code !== 'UsernameExistsException') {
                        this.errorHandler(err);
                    }

                    return callback(err);
                }

                const cognitoUser = result.user;
                const text = await this.translate.get('auth_mail_sent', {email}).toPromise();

                Object.assign(this.modalConfirmNumber, {
                    show: true,
                    title: 'Registration confirmation',
                    content: text,
                    callback: (value: number) => {
                        cognitoUser.confirmRegistration(value.toString(), true, (_err: ICognitoError) => {
                            if (_err) {
                                this.errorHandler(_err);
                                this.modalConfirmNumber.show = false;

                                return callback(_err);
                            }

                            this.authenticate(username, password, callback);
                            this.modalConfirmNumber.show = false;
                        });
                    },
                    callbackLabel: 'form_validate',
                    cancel: undefined,
                });
            }
        );
    }

    public signOut() {
        if (this.cognitoUser) {
            this.cognitoUser.signOut();
        }

        this.cognitoUser = null;
        this.cognitoSession = null;
        this.storage.clear();
        this.rights = undefined;
        this.getRightsPromise = undefined;
        this.managerOf = [];

        if (this.credentials) {
            this.credentials.clearCachedId();
            this.credentials = undefined;
        }

        localStorage[this.LOCAL_STORAGE_COGNITO_USERNAME_KEY] = '';
        this.cognitoUsernameChange.emit((this.cognitoUsername = ''));
        this.userChange.emit((this.user = undefined));
        this.isAuthenticatedChange.emit((this.isAuthenticated = false));
    }

    public static parsePasswordError(err: ICognitoError) {
        if (err.message.includes('Password not long enough') || err.message.includes('must have length greater than')) {
            return 'password_error_not_long_enough';
        } else if (err.message.includes('Password must have uppercase characters')) {
            return 'password_error_missing_uppercase';
        } else if (err.message.includes('Password must have lowercase characters')) {
            return 'password_error_missing_lowercase';
        } else if (err.message.includes('Password must have numeric characters')) {
            return 'password_error_missing_numeric';
        } else if (err.message.includes('Password must have symbol characters')) {
            return 'password_error_missing_symbol';
        }

        return err.message;
    }

    public authenticate(username: string, password?: string, callback: (err?: Error) => void = noop) {
        const authenticationData = {
            Username: username,
            Password: password,
        };

        const authenticationDetails = new AuthenticationDetails(authenticationData);
        const cognitoUser = (this.cognitoUser = new CognitoUser({
            Username: username,
            Pool: this.userPool,
            Storage: this.storage,
        }));

        const _t = this.translate;

        const mfaRequired = async (codeDeliveryDetails: any) => {
            Object.assign(this.modalConfirmNumber, {
                show: true,
                title: 'Please input verification code',
                content: '',
                callback: (value: number) => {
                    // @ts-ignore
                    cognitoUser.sendMFACode(value, this);
                    this.modalConfirmNumber.show = false;
                },
                callbackLabel: 'form_validate',
                cancel: () => {
                    this.modalConfirmNumber.show = false;

                    return callback(new Error('cancelled'));
                },
            });
        };

        const done = (session: CognitoUserSession) => {
            this.cognitoSession = session;
            this.cognitoGroups = AuthService.getCognitoGroupsFromSession(this.cognitoSession);
            localStorage[this.LOCAL_STORAGE_COGNITO_USERNAME_KEY] = username;
            this.getUser(callback);
        };

        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: (session) => {
                done(session);
            },
            onFailure: (err: Error) => {
                callback(err);
            },
            newPasswordRequired: async (userAttributes, requiredAttributes) => {
                delete userAttributes.email_verified;
                const title = await _t.get('must_update_password').toPromise();

                Object.assign(this.modalUpdate, {
                    show: true,
                    title,
                    callback: (value: string) => {
                        // @ts-ignore
                        cognitoUser.completeNewPasswordChallenge(value, userAttributes, {
                            onFailure: (err: ICognitoError) => {
                                this.modalUpdate.error = AuthService.parsePasswordError(err);
                            },
                            onSuccess: (session) => {
                                this.modalUpdate.error = undefined;
                                this.modalUpdate.show = false;

                                done(session);
                            },
                            mfaRequired,
                            // FIXME customChallenge
                        });
                    },
                    callbackLabel: 'form_validate',
                    cancel: () => {
                        this.modalUpdate.show = false;

                        return callback(new Error('cancelled'));
                    },
                });
            },
            mfaRequired,
        });
    }

    public resetPassword(
        username: string,
        callback: (err?: Error | ICognitoError, result?: {resend?: true; password?: true}) => void = noop
    ) {
        const cognitoUser = (this.cognitoUser = new CognitoUser({
            Username: username,
            Pool: this.userPool,
            Storage: this.storage,
        }));
        const _t = this.translate;
        this.modalConfirmPass.value = undefined;
        this.modalConfirmPass.valueBis = undefined;
        cognitoUser.forgotPassword({
            onSuccess: (data) => callback(),
            onFailure: async (err: ICognitoError) => {
                if (
                    err.code === 'NotAuthorizedException' &&
                    err.message.includes('User password cannot be reset in the current state')
                ) {
                    try {
                        await this.resendVerification(username);
                    } catch (err) {
                        return callback(err);
                    }

                    return callback(null, {resend: true});
                }

                callback(err);
            },
            inputVerificationCode: async (data: ICodeDeliveryDetails) => {
                const title = await _t.get('password_reset').toPromise();
                const detail = await _t
                    .get('auth_detail_sent', {
                        smth: data.CodeDeliveryDetails.AttributeName,
                        dest: data.CodeDeliveryDetails.Destination,
                    })
                    .toPromise();

                const plsDetail = await _t.get('please_enter_confirmation').toPromise();

                Object.assign(this.modalConfirmPass, {
                    show: true,
                    title,
                    content: `<p>${detail}</p><br><p>${plsDetail}</p>`,
                    callback: (value: number, valueBis: string) => {
                        cognitoUser.confirmPassword(value.toString(), valueBis, {
                            onSuccess: () => {
                                this.modalConfirmPass.error = undefined;
                                this.modalConfirmPass.show = false;
                                callback(null, {password: true});
                            },
                            onFailure: (err: ICognitoError) => {
                                this.modalConfirmPass.error = AuthService.parsePasswordError(err);
                            },
                        });
                    },
                    callbackLabel: 'form_validate',
                    cancel: () => {
                        return callback(new Error('cancelled'));
                    },
                });
            },
        });
    }

    private async resendVerification(username: string) {
        if (!username) {
            return undefined;
        }

        return this.httpRest
            ._request(
                HTTP_METHOD.GET,
                ApiRoutePlurality.SINGULAR,
                COGNITO_PUBLIC_SCHEMA_ROUTE,
                `user/resend/${username}`
            )
            .toPromise();
    }

    public restoreSession(callback: (err?: Error, session?: CognitoUserSession) => void = noop) {
        if (!localStorage[this.LOCAL_STORAGE_COGNITO_USERNAME_KEY]) {
            return callback();
        }

        const userData = {
            Username: localStorage[this.LOCAL_STORAGE_COGNITO_USERNAME_KEY],
            Pool: this.userPool,
            Storage: this.storage,
        };

        this.cognitoUser = new CognitoUser(userData);
        this.cognitoUser.getSession((err?: Error, session?: CognitoUserSession) => {
            if (!session) {
                return callback(err);
            }

            this.cognitoSession = session;
            this.cognitoGroups = AuthService.getCognitoGroupsFromSession(this.cognitoSession);
            this.getUser((_err) => callback(_err, session));
        });
    }

    public get isSessionValid(): boolean {
        if (!this.cognitoSession || !this.cognitoSession.getAccessToken()) {
            return false;
        }

        const timeExpiration = 1000;
        const expiration = this.momentService.moment(
            this.cognitoSession.getAccessToken().getExpiration() * timeExpiration
        );
        const remainingSeconds = this.momentService.moment
            .duration(expiration.diff(this.momentService.moment()))
            .as('seconds');

        return this.cognitoSession.isValid() && remainingSeconds > this.COGNITO_AUTH_REFRESH_THRESHOLD;
    }

    private sessionRefreshPromise: Promise<CognitoUserSession>;

    public async refreshSession(force = false): Promise<CognitoUserSession> {
        if (!this.cognitoSession) {
            await new Promise((resolve, reject) => this.restoreSession((err) => (err ? reject(err) : resolve())));

            return undefined;
            // return callback(new Error('no session'));
        }

        if (!force && this.cognitoSession.isValid()) {
            return undefined;
        }

        if (!force && !this.isSessionValid) {
            return undefined;
        }

        // FIXME should work, doesn't, frak it
        /*if (force && this.cognitoSession.isValid()) {
      alert('isValid is a big fat liar, see aws-cognito-angular-quickstart#39');
    }*/

        const userData = {
            Username: localStorage[this.LOCAL_STORAGE_COGNITO_USERNAME_KEY],
            Pool: this.userPool,
            Storage: this.storage,
        };

        this.cognitoUser = new CognitoUser(userData);

        // prevents multiple requests
        if (!this.sessionRefreshPromise) {
            this.sessionRefreshPromise = new Promise((resolve, reject) =>
                this.cognitoUser.refreshSession(
                    this.cognitoSession.getRefreshToken(),
                    (err, _session?: CognitoUserSession) => (err ? reject(err) : resolve(_session))
                )
            );
        }

        this.cognitoSession = await this.sessionRefreshPromise;
        this.sessionRefreshPromise = null;

        this.cognitoGroups = AuthService.getCognitoGroupsFromSession(this.cognitoSession);
    }

    private getUserPromise: Promise<IUser>;

    public hasRight(right: string) {
        if (!this.rights) {
            return false;
        }

        return this.rights.findIndex((r) => r.code === right) > -1;
    }

    private managerOf: number[] = [];
    public isManagerOf(projectId: number): boolean {
        return this.hasRight(RIGHTS.PROJECT_ADMIN_ALL) || (!!this.managerOf && this.managerOf.includes(projectId));
    }

    public addManagerOf(projectId: number): boolean {
        if (this.managerOf.indexOf(projectId) === -1) {
            this.managerOf.push(projectId);

            return true;
        }

        return false;
    }

    public getProjectsManagerOf(): number[] {
        return this.managerOf;
    }

    private async getManagerOf() {
        this.managerOf = await this.httpRest
            ._request<number[]>(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, PROJECT_SCHEMA_ROUTE, 'managerOf')
            .toPromise();
    }

    private getUser(callback: (err?: Error) => void = noop) {
        (async () => {
            if (!this.getUserPromise) {
                this.getUserPromise = this.httpRest
                    ._request<IUser>(
                        HTTP_METHOD.GET,
                        ApiRoutePlurality.SINGULAR,
                        USER_SCHEMA_ROUTE,
                        `cognito/${this.cognitoUsername || localStorage.getItem('cognitoUsername')}`
                    )
                    .toPromise();
            }

            let user: IUser;
            try {
                user = await this.getUserPromise;
            } catch (err) {
                return callback(err);
            } finally {
                this.getUserPromise = null;
            }

            if (!user) {
                this.cognitoUsernameChange.emit((this.cognitoUsername = ''));
                // FIXME prevents losing cognitoUsername on 401 errors
                /*localStorage.cognitoUsername = ''*/
                this.isAuthenticatedChange.emit((this.isAuthenticated = false));
                this.userChange.emit((this.user = undefined));

                return callback(new Error('Missing user'));
            }

            localStorage[this.LOCAL_STORAGE_COGNITO_USERNAME_KEY] = user.cognitoUsername;

            if (this.cognitoUsername !== localStorage[this.LOCAL_STORAGE_COGNITO_USERNAME_KEY]) {
                this.cognitoUsernameChange.emit(
                    (this.cognitoUsername = localStorage[this.LOCAL_STORAGE_COGNITO_USERNAME_KEY])
                );
            }

            if (!this.isAuthenticated) {
                this.isAuthenticatedChange.emit((this.isAuthenticated = true));
            }

            if (this.user !== user) {
                this.userChange.emit((this.user = user));
            }

            this.rights = await this.getRights();
            await this.getManagerOf();
            callback();
        })();
    }

    public getRightsPromise: Promise<IRight[]>;

    private async getRights() {
        return (this.getRightsPromise = this.httpRest
            ._request<IRight[]>(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, RIGHT_SCHEMA_ROUTE, '')
            .toPromise());
    }

    private static getCognitoGroupsFromSession(session: CognitoUserSession): COGNITO_USER_GROUPS[] {
        if (!session.isValid()) {
            return [COGNITO_USER_GROUPS._UNAUTHED];
        }

        return [COGNITO_USER_GROUPS._AUTHED, ...((session.getIdToken().decodePayload() || {})['cognito:groups'] || [])];
    }

    private async errorHandler(err: Error) {
        const title = await this.translate.get('login_error').toPromise();
        (async () => {
            Object.assign(this.modalAlert, {
                showInline: true,
                title,
                content: `<div style="overflow: auto; max-height: 30vh"><code>${JSON.stringify(err)}</code></div>`,
            });
        })();
    }

    // refresh a minute before expiration
    private readonly COGNITO_AUTH_REFRESH_THRESHOLD = this.momentService.moment.duration(1, 'minute').as('seconds');
    public readonly COGNITO_RESOURCE_URL = `cognito-idp.${publicConfig.cognito.region}.amazonaws.com/${
        publicConfig.cognito.userPoolId
    }`;

    public readonly LOCAL_STORAGE_COGNITO_USERNAME_KEY = 'cognitoUsername';
    public readonly LOCAL_STORAGE_COGNITO_SUB_KEY = 'cognitoSub';
}
