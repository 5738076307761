import * as i0 from "./unauthorized.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@clr/angular";
import * as i3 from "@ngx-translate/core";
import * as i4 from "./unauthorized.component";
var styles_UnauthorizedComponent = [i0.styles];
var RenderType_UnauthorizedComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UnauthorizedComponent, data: {} });
export { RenderType_UnauthorizedComponent as RenderType_UnauthorizedComponent };
export function View_UnauthorizedComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "flex-vertical-center h-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "unauthorized text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "clr-icon", [["class", "is-solid"], ["shape", "times-circle"], ["size", "64"]], null, null, null, null, null)), i1.ɵdid(3, 16384, null, 0, i2.ClrIconCustomTag, [], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "h1", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(5, 8536064, null, 0, i3.TranslateDirective, [i3.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["unauthorized"]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_UnauthorizedComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-unauthorized", [], null, null, null, View_UnauthorizedComponent_0, RenderType_UnauthorizedComponent)), i1.ɵdid(1, 49152, null, 0, i4.UnauthorizedComponent, [i3.TranslateService], null, null)], null, null); }
var UnauthorizedComponentNgFactory = i1.ɵccf("app-unauthorized", i4.UnauthorizedComponent, View_UnauthorizedComponent_Host_0, {}, {}, []);
export { UnauthorizedComponentNgFactory as UnauthorizedComponentNgFactory };
