<div class="Wrapper {{ class }}">
    <div
        class="column-container Sidebar"
        [class.is-collapsed]="isCollapsed"
        [class.d-invisible]="hidden"
        [class.d-none]="controlFlowService.isNotFound"
    >
        <div>
            <div class="Sidebar-logo"><img src="../../../assets/logo_lg.png" *ngIf="!isCollapsed" /></div>

            <div class="notif-container">
                <div class="signpost-triggers-wrapper">
                    <div class="signpost-trigger-demo">
                        <div class="signpost-item">
                            <clr-signpost>
                                <clr-icon
                                    shape="bell"
                                    [ngClass]="{
                                        'has-badge-info': notificationService?.notificationsNotClosed.length
                                    }"
                                    clrSignpostTrigger
                                >
                                </clr-icon>
                                <clr-signpost-content
                                    [clrPosition]="'bottom-right'"
                                    *clrIfOpen
                                    style="min-width:17rem;"
                                >
                                    <app-notifications></app-notifications>
                                </clr-signpost-content>
                            </clr-signpost>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ng-container
            *ngIf="!activeRoute || (activeRoute.depth >= displayedDepths[0].index + 1 && activeRoute.fullPath !== '')"
        >
            <app-user-view [class.mt-04]="isCollapsed" [isCollapsed]="isCollapsed"></app-user-view>
            <app-global-search
                [displayInline]="true"
                *ngIf="authService.user"
                (click)="isCollapsed = false"
                [isCollapsed]="isCollapsed"
            ></app-global-search>
        </ng-container>

        <!-- PM-cvzw called component twice caused by ngfor unnecessary, for the moment -->
        <nav class="flex-1 column-container oy-a" [class.mt-1-5e]="isCollapsed" role="navigation">
            <!-- *ngFor="let depth of displayedDepths" -->
            <ng-container
                *ngIf="
                    !activeRoute || (activeRoute.depth >= displayedDepths[0].index + 1 && activeRoute.fullPath !== '')
                "
            >
                <ul class="oy-a ox-h">
                    <ng-container *ngFor="let route of displayedDepths[0].routes">
                        <ng-container
                            *ngIf="
                                (displayedDepths[0].index == 0 || (route.parentRoute && route.parentRoute.active)) &&
                                route.icon != null &&
                                publicConfig.appParams['module_' + route.label.toLowerCase()]
                            "
                        >
                            <li
                                *sharedAccessControl="'NAVIGATE_' + route.label.toUpperCase()"
                                [ngClass]="{
                                    active: route.active,
                                    'has-success': route.valid,
                                    'has-error': route.valid === false
                                }"
                                [title]="'route_' + route.label | lowercase | translate"
                                class="Sidebar-navItem"
                            >
                                <a [routerLink]="!route.disabled ? getRouteUrl(route) : null">
                                    <clr-icon
                                        class="Sidebar-menuIcon"
                                        [attr.shape]="route.icon"
                                        [ngStyle]="route.active && {color: authService.user?.color}"
                                    ></clr-icon>
                                    <span
                                        [ngStyle]="route.active && {color: authService.user?.color}"
                                        translate
                                        *ngIf="!isCollapsed"
                                        >route_{{ route.label | lowercase }}</span
                                    >
                                </a>
                            </li>
                        </ng-container>
                    </ng-container>
                </ul>
                <ng-container
                    *ngIf="
                        COGNITO_USER_GROUPS_PRECEDENCE[requiredGroup] <=
                        COGNITO_USER_GROUPS_PRECEDENCE[COGNITO_USER_GROUPS.EMPLOYEE]
                    "
                >
                    <app-quick-action
                        *ngIf="publicConfig.appParams.quickaction"
                        [isCollapsed]="isCollapsed"
                    ></app-quick-action>
                    <app-timer
                        class="pos-r"
                        *ngIf="publicConfig.appParams.timer"
                        [isCollapsed]="isCollapsed"
                    ></app-timer>
                </ng-container>
            </ng-container>
        </nav>

        <app-wander [isCollapsed]="isCollapsed" *ngIf="publicConfig.appParams.bobby"></app-wander>

        <footer class="Sidebar-footer" (click)="switchSidebar()">
            <clr-icon
                class="Sidebar-toggleArrow Sidebar-menuIcon"
                shape="arrow"
                [ngStyle]="{transform: isCollapsed ? 'rotate(90deg)' : 'rotate(270deg)'}"
            ></clr-icon>
            <span class="Sidebar-toggleText" translate style="font-size:0.8rem">menu_close</span>
        </footer>
    </div>

    <div
        class="Main"
        [ngClass]="{
            'is-collapsed': isCollapsed,
            'is-without-sidebar': hidden
        }"
    >
        <router-outlet></router-outlet>
    </div>

    <app-file-manager></app-file-manager>
</div>
