import moment from 'moment';
import {ISchemaRoute, WikiPageType} from '../../schema-static';
import {IFile} from '../storage/Files';
import {IWikiAttachment} from '../storage/WikiAttachments';
import {IClient} from './Clients';
import {IEmployee} from './Employees';
import {IMilestone} from './Milestones';
import {IProject} from './Projects';

export enum WIKI_PAGE_FIELD {
    id = 'id',
    title = 'title',
    uri = 'uri',
    hash = 'hash',
    fileId = 'fileId',
    authorId = 'authorId',
    clientId = 'clientId',
    projectId = 'projectId',
    milestoneId = 'milestoneId',
    type = 'type',
    draft = 'draft',
    file = 'file',
    author = 'author',
    client = 'client',
    project = 'project',
    milestone = 'milestone',
    wikiAttachments = 'wikiAttachments',
    createdAt = 'createdAt',
    updatedAt = 'updatedAt',
}

export interface IWikiPageBare {
    id?: number;
    createdAt?: moment.Moment;
    updatedAt?: moment.Moment;
    title: string;
    uri: string;
    hash: string;
    fileId?: number;
    authorId: number;
    clientId: number;
    projectId?: number;
    milestoneId?: number;
    type: WikiPageType;
    draft: boolean;
}

export interface IWikiPageBelong {
    file?: Partial<IFile>;
    author?: Partial<IEmployee>;
    client?: Partial<IClient>;
    project?: Partial<IProject>;
    milestone?: Partial<IMilestone>;
}

export interface IWikiPageMany {
    wikiAttachments?: Partial<IWikiAttachment>[];
}

export interface IWikiPage extends IWikiPageBare, IWikiPageBelong, IWikiPageMany {}

const WIKI_PAGE_SCHEMA_ROUTE: ISchemaRoute = {
    tableName: 'wikipages',
    singularRoute: 'wikipage',
    pluralRoute: 'wikipages',
};

export {WIKI_PAGE_SCHEMA_ROUTE};
