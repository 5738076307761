import {Component, OnInit} from '@angular/core';
import {ApiRoutePlurality, HTTP_METHOD} from '../../../../../defs/schema-static';
import {IIncident, INCIDENT_SCHEMA_ROUTE} from '../../../../../defs/schema/public/Incidents';
import {IProject, PROJECT_SCHEMA_ROUTE} from '../../../../../defs/schema/public/Projects';
import {AuthService} from '../../auth/auth.service';
import {HttpRestService} from '../../shared/http-rest/http-rest.service';

@Component({
    selector: 'app-portal-incident',
    templateUrl: './incident.component.html',
    styleUrls: ['./incident.component.scss'],
})
export class IncidentComponent {
    private incidents: IIncident[] = [];
    // private displayIncidents: IIncident[] = [];
    public pleaseWait = true;
    private projects: IProject[] = [];
    public displayModal = false;
    public selectedIncident: IIncident;

    public constructor(private readonly httpRest: HttpRestService, private readonly authService: AuthService) {
        this.httpRest
            ._request<IIncident[]>(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, INCIDENT_SCHEMA_ROUTE, 'client')
            .subscribe((incidents) => {
                this.incidents = incidents;
                this.pleaseWait = false;
                // this.displayIncidents = [...this.incidents];
            });

        this.httpRest
            ._request<IProject[]>(
                HTTP_METHOD.GET,
                ApiRoutePlurality.PLURAL,
                PROJECT_SCHEMA_ROUTE,
                `clientLight/${this.authService.user.client.id}`
            )
            .subscribe((projects) => (this.projects = projects));
    }

    protected getProject(projectId: number): IProject {
        return this.projects.find((p) => p.id === projectId);
    }

    public updateFilter = (event: Event) => {
        const target = event.target as HTMLInputElement;

        const val = target.value.toLowerCase();

        // this.displayIncidents = this.incidents.filter((d) => {
        //     return (
        //         d.title.toLowerCase().indexOf(val) !== -1 ||
        //         d.description.toLowerCase().indexOf(val) !== -1 ||
        //         d.status.toLowerCase().indexOf(val) !== -1 ||
        //         this.getProject(d.projectId)
        //             .obs.toLowerCase()
        //             .indexOf(val) !== -1 ||
        //         !val
        //     );
        // });
    };

    protected displayDescription({selected}: {selected: IIncident[]}) {
        this.selectedIncident = selected[0];
        this.displayModal = true;
    }
}
