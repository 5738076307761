import { MomentService } from '../moment/moment.service';
import { Timer } from './timer.class';
import * as i0 from "@angular/core";
import * as i1 from "../moment/moment.service";
var TimerService = (function () {
    function TimerService(momentService) {
        this.momentService = momentService;
        this.timer = new Timer(this.momentService);
    }
    TimerService.ngInjectableDef = i0.defineInjectable({ factory: function TimerService_Factory() { return new TimerService(i0.inject(i1.MomentService)); }, token: TimerService, providedIn: "root" });
    return TimerService;
}());
export { TimerService };
