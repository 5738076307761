import moment from 'moment';
import {ISchemaRoute} from '../../schema-static';
import {ITag} from './Tags';
import {ITask} from './Tasks';

export enum TASK_TAG_FIELD {
    id = 'id',
    createdAt = 'createdAt',
    updatedAt = 'updatedAt',
    taskId = 'taskId',
    tagId = 'tagId',
}

export interface ITaskTagBare {
    id?: number;
    createdAt?: moment.Moment;
    updatedAt?: moment.Moment;
    taskId: number;
    tagId: number;
}

export interface ITaskTagBelong {
    task?: Partial<ITask>;
    tag?: Partial<ITag>;
}

export interface ITaskTag extends ITaskTagBare, ITaskTagBelong {}

const TASK_TAG_SCHEMA_ROUTE: ISchemaRoute = {
    tableName: 'tasktags',
    singularRoute: 'tasktag',
    pluralRoute: 'tasktags',
};

export {TASK_TAG_SCHEMA_ROUTE};
