import {
    AfterContentInit,
    Component,
    ContentChild,
    ContentChildren,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {isColorDark} from '../app-static';
import {TabComponent} from './tab/tab.component';
import {TabsHeaderDirective} from './tabs-header.directive';

@Component({
    selector: 'app-tabs',
    templateUrl: './tabs.component.html',
    styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements OnInit, AfterContentInit, OnDestroy {
    @ContentChildren(TabComponent) public _tabs: QueryList<TabComponent>;
    @ContentChild(TabsHeaderDirective, {read: TemplateRef}) public tabsHeader: TemplateRef<any>;

    @ViewChild('tabsContainer') public tabsContainer: ElementRef;

    @Input() public labels: {[key: string]: string} = {};
    @Input() public queryParamKey: string;
    @Input() public sublime = false;

    public queryParam: string;
    private queryParamsSubscription: Subscription;

    @Input() public activeTab: TabComponent;
    @Output() public activeTabChange = new EventEmitter<TabComponent>();
    @Output() public clicked = new EventEmitter<boolean>();

    public constructor(private readonly router: Router, private readonly route: ActivatedRoute) {}

    public ngOnInit() {
        if (!this.queryParamKey) {
            return;
        }

        this.queryParamsSubscription = this.route.queryParams.subscribe((queryParams) => {
            this.queryParam = queryParams[this.queryParamKey];
            this.selectTab(this.getQueryParamTab());
        });
    }

    public ngAfterContentInit() {
        requestAnimationFrame(() => {
            if (this._tabs.length && !this.getActiveTab()) {
                this.selectTab(this.getQueryParamTab() || this._tabs.find((tab) => !tab.disabled), true);
            }
        });
    }

    public ngOnDestroy() {
        if (this.queryParamsSubscription) {
            this.queryParamsSubscription.unsubscribe();
            this.queryParamsSubscription = null;
        }
    }

    public selectTabFromName(name: string) {
        const tab = this._tabs.find((t) => t.name.toString() === name);
        this.selectTab(tab);
    }

    public selectTab(tab: TabComponent, replaceUrl = false) {
        if (!tab || tab.isActive || tab.justAButton) {
            return;
        }

        this._tabs.map((_tab) => (_tab.isActive = false));
        tab.isActive = true;

        this.scrollToTab(tab);
        (async () => this.setQueryParamsFromTab(replaceUrl))();

        this.activeTabChange.emit((this.activeTab = tab));
    }

    private scrollToTab(tab: TabComponent) {
        const $tabsContainer = this.tabsContainer.nativeElement as HTMLDivElement;
        if (!$tabsContainer || $tabsContainer.scrollWidth < $tabsContainer.offsetWidth) {
            return;
        }

        // waits for tab to be rendered
        requestAnimationFrame(() => {
            const $activeTab = $tabsContainer.querySelector(`.tab[data-tab="${tab.name}"]`) as HTMLDivElement;

            if (!$activeTab) {
                return;
            }

            const offsetLeft = $activeTab.offsetLeft;
            const offsetRight = $activeTab.offsetLeft + $activeTab.offsetWidth;

            const visibleLeft = $tabsContainer.scrollLeft;
            const visibleRight = visibleLeft + $tabsContainer.offsetWidth;

            if (offsetLeft < visibleLeft) {
                $tabsContainer.scrollLeft = offsetLeft;
            } else if (offsetRight > visibleRight) {
                $tabsContainer.scrollLeft = offsetRight - $tabsContainer.offsetWidth;
            }
        });
    }

    private getActiveTab(): TabComponent {
        if (!this._tabs) {
            return undefined;
        }

        return this._tabs.find((_tab) => _tab.isActive);
    }

    private getQueryParamTab(): TabComponent {
        if (!this._tabs || !this.queryParam) {
            return undefined;
        }

        return this._tabs.find((tab) => tab.name === this.queryParam);
    }

    private async setQueryParamsFromTab(replaceUrl = false) {
        const activeTab = this.getActiveTab();
        if (!this.queryParamKey || !activeTab) {
            return undefined;
        }

        const {queryParams} = this.router.parseUrl(this.router.url);
        if (queryParams[this.queryParamKey] === activeTab.name) {
            return undefined;
        }

        Object.assign(queryParams, {
            [this.queryParamKey]: activeTab.name,
            ...(activeTab.queryParams || {}),
        });

        return this.router.navigate([], {
            replaceUrl,
            queryParams,
            queryParamsHandling: 'merge',
        });
    }

    public isColorDark = isColorDark;
}
