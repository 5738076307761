import moment from 'moment';
import {ISchemaRoute} from '../../schema-static';
import {IEmployee} from './Employees';

export enum TODO_CATEGORY_FIELD {
    id = 'id',
    createdAt = 'createdAt',
    updatedAt = 'updatedAt',
    text = 'text',
    orderTodos = 'orderTodos',
    defaultCategory = 'defaultCategory',
    employeeId = 'employeeId',
}

export interface ITodoCategory {
    id?: number;
    createdAt?: moment.Moment;
    updatedAt?: moment.Moment;
    text?: string;
    orderTodos?: number[];
    defaultCategory: boolean;
    employeeId: number;
}

export interface ITodoCategoryComplete {
    employee?: Partial<IEmployee>[];
}

const TODO_CATEGORY_SCHEMA_ROUTE: ISchemaRoute = {
    tableName: 'todoCategories',
    singularRoute: 'todoCategory',
    pluralRoute: 'todoCategories',
};

export {TODO_CATEGORY_SCHEMA_ROUTE};
