var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { UNAUTHORIZED } from 'http-status-codes';
import { from } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ApiHttpError } from '../../../../defs/api-error';
import { MomentService } from '../shared/moment/moment.service';
import { TOAST_TYPE, ToastService } from '../shared/toast/toast.service';
import { AuthService } from './auth.service';
var AuthInterceptor = (function () {
    function AuthInterceptor(authService, translate, toastService, momentService) {
        this.authService = authService;
        this.translate = translate;
        this.toastService = toastService;
        this.momentService = momentService;
    }
    AuthInterceptor.prototype.intercept = function (request, next, force, retries) {
        var _this = this;
        if (force === void 0) { force = false; }
        if (retries === void 0) { retries = 1; }
        return this.refreshBearer(request, next, force).pipe(catchError(function (err, caught) {
            if (err instanceof HttpErrorResponse &&
                err.status === UNAUTHORIZED &&
                err.error === ApiHttpError.ForceLogoutError) {
                (function () { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        this.toastService.show({
                            type: TOAST_TYPE.INFO,
                            text: 'force_logout_error',
                            appLevel: true,
                        });
                        return [2];
                    });
                }); })();
                _this.authService.signOut();
                return undefined;
            }
            if (retries < AuthInterceptor.MAX_REFRESH_RETRIES &&
                err instanceof HttpErrorResponse &&
                err.status === UNAUTHORIZED &&
                (!err.error || err.error.name !== 'InsufficientAuthorization')) {
                if (_this.authService.accessToken) {
                    console.error("was supposed to expire " + _this.momentService
                        .moment(_this.authService.accessToken.getExpiration() * 1000)
                        .format());
                }
                return _this.intercept(request.clone(), next, true, retries + 1);
            }
            throw err;
            return next.handle(_this.setAuthorizationHeader(request));
        }));
    };
    AuthInterceptor.prototype.setAuthorizationHeader = function (request) {
        if (!this.authService.accessJWTToken) {
            return request;
        }
        return request.clone({
            setHeaders: {
                Authorization: "Bearer " + this.authService.accessJWTToken,
            },
        });
    };
    AuthInterceptor.prototype.refreshBearer = function (request, next, force) {
        var _this = this;
        if (force === void 0) { force = false; }
        return from((function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!force && this.authService.isSessionValid) {
                            return [2, next.handle(this.setAuthorizationHeader(request))];
                        }
                        if (!this.refreshSessionPromise) {
                            this.refreshSessionPromise = this.authService.refreshSession(force);
                        }
                        return [4, this.refreshSessionPromise];
                    case 1:
                        _a.sent();
                        this.refreshSessionPromise = null;
                        return [2];
                }
            });
        }); })()).pipe(switchMap(function () { return next.handle(_this.setAuthorizationHeader(request)); }));
    };
    AuthInterceptor.MAX_REFRESH_RETRIES = 3;
    return AuthInterceptor;
}());
export { AuthInterceptor };
