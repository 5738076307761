<div class="container">
    <app-tabs queryParamKey="tab" [labels]="ADMIN_PANEL_TABS_LABELS">
        <app-tab [name]="ADMIN_PANEL_TABS.MAIN" [label]="ADMIN_PANEL_TABS.MAIN | translate">
            <ng-template appTabContent> <app-admin-main></app-admin-main> </ng-template>
        </app-tab>
        <app-tab [name]="ADMIN_PANEL_TABS.CLIENTS" [label]="ADMIN_PANEL_TABS.CLIENTS | translate">
            <ng-template appTabContent> <app-admin-clients></app-admin-clients> </ng-template>
        </app-tab>
        <app-tab [name]="ADMIN_PANEL_TABS.TRANSLATIONS" [label]="ADMIN_PANEL_TABS.TRANSLATIONS | translate">
            <ng-template appTabContent> <app-admin-i18n></app-admin-i18n> </ng-template>
        </app-tab>
        <app-tab
            [name]="ADMIN_PANEL_TABS.ACCESS_CONTROL"
            [label]="ADMIN_PANEL_TABS.ACCESS_CONTROL | translate"
            *ngIf="authService.cognitoGroups.includes(COGNITO_USER_GROUPS.ADMIN)"
        >
            <ng-template appTabContent> <app-admin-access></app-admin-access> </ng-template>
        </app-tab>
    </app-tabs>
</div>
