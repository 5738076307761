import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ClrLoadingState} from '@clr/angular';
import {TranslateService} from '@ngx-translate/core';
import {SETTING_LANGUAGE_VALUES} from '../../../../../defs/schema/public/Users';
import {TOAST_TYPE, ToastService} from '../../shared/toast/toast.service';
import {AuthService, ICognitoError} from '../auth.service';

@Component({
    selector: 'app-log-in',
    templateUrl: './log-in.component.html',
    styleUrls: ['./log-in.component.scss'],
})
export class LogInComponent implements OnInit {
    public username = '';
    public password = '';

    public error: string;

    public loadingState = ClrLoadingState.DEFAULT;

    public constructor(
        private readonly authService: AuthService,
        private readonly route: ActivatedRoute,
        private readonly translate: TranslateService,
        private readonly toastService: ToastService
    ) {
        this.translate.use(this.translate.getBrowserLang().toUpperCase()).subscribe(() => {
            this.translate.setDefaultLang(SETTING_LANGUAGE_VALUES.FR);
        });
    }

    public ngOnInit() {
        this.authService.restoreSession();

        this.route.queryParams.subscribe((queryParams) => {
            if (queryParams.username) {
                this.username = queryParams.username;
            }
        });
    }

    public submit() {
        this.loadingState = ClrLoadingState.LOADING;
        this.authService.authenticate(this.username.toLowerCase(), this.password, (err: ICognitoError) => {
            if (err) {
                if (err.message === 'cancelled') {
                    this.loadingState = ClrLoadingState.DEFAULT;

                    return;
                }

                switch (err.code) {
                    default:
                        this.error = 'login_error';
                        break;
                }
                this.loadingState = ClrLoadingState.DEFAULT;

                return;
            }

            this.loadingState = ClrLoadingState.SUCCESS;
            this.error = undefined;
        });
    }

    public forgotPassword() {
        if (!this.username) {
            this.error = 'forgot_password_missing_email_error';

            return;
        }

        this.error = undefined;
        this.authService.resetPassword(this.username.toLowerCase(), async (err: ICognitoError, result) => {
            if (err) {
                switch (err.code) {
                    case 'UserNotFoundException':
                        this.error = 'forgot_password_unknown_email_error';
                        break;
                    default:
                        this.error = 'forgot_password_unknown_error';
                        break;
                }

                return;
            }

            if (result) {
                if (result.resend) {
                    this.toastService.show({
                        type: TOAST_TYPE.INFO,
                        text: 'resend_email_sent',
                    });
                } else if (result.password) {
                    this.toastService.show({
                        type: TOAST_TYPE.SUCCESS,
                        text: 'password_successfully_updated',
                    });
                }
            }
        });
    }

    public valid() {
        return this.username && this.password;
    }

    public readonly ClrLoadingState = ClrLoadingState;
}
