<form id="milestoneDetailsForm" clrForm [formGroup]="form" clrLayout="horizontal">
    <clr-input-container style="margin-top: 0;">
        <label class="clr-col-xs-12 clr-col-md-3" translate>milestone_name</label>
        <input
            class="clr-col-xs-12 clr-col-md-9"
            clrInput
            type="text"
            [formControlName]="MILESTONE_FORM_KEYS.milestone"
            name="milestone"
            maxlength="{{ MAX_LENGTH_DEFAULT }}"
            #milestoneName
        />

        <clr-control-error *clrIfError="'requiredTrim'" translate>error_field_empty</clr-control-error>
        <clr-control-error *clrIfError="'maxLength'" translate>error_field_text_overflow</clr-control-error>
    </clr-input-container>
    <clr-input-container *ngIf="type === MILESTONE_TYPES.RELEASE">
        <label class="clr-col-xs-12 clr-col-md-3" translate>milestone_version</label>
        <input
            [placeholder]="'sermver_placeholder' | translate"
            class="clr-col-xs-12 clr-col-md-9"
            clrInput
            type="text"
            [formControlName]="MILESTONE_FORM_KEYS.version"
            maxlength="{{ MAX_LENGTH_DEFAULT }}"
            name="example"
        />
        <clr-control-error *clrIfError="'validSemVer'" [innerHTML]="'help_version' | translate"></clr-control-error>
        <clr-control-error *clrIfError="'maxLength'" translate>error_field_text_overflow</clr-control-error>
    </clr-input-container>
    <clr-textarea-container>
        <label class="clr-col-xs-12 clr-col-md-3" translate>milestone_description</label>
        <textarea
            class="clr-col-xs-12 clr-col-md-9"
            clrTextarea
            [formControlName]="MILESTONE_FORM_KEYS.description"
            name="description"
        ></textarea>
    </clr-textarea-container>

    <div class="clr-row">
        <div class="clr-col-8" *ngIf="type !== MILESTONE_TYPES.RELEASE && type !== MILESTONE_TYPES.DEADLINE">
            <clr-date-container class="clr-row">
                <label class="clr-col-12 clr-col-md-5" translate>date_begin</label>
                <input
                    class="clr-col-12 clr-col-md-7"
                    type="date"
                    id="date"
                    name="date"
                    [(clrDate)]="beginDate"
                    #startDateInputElement
                />
            </clr-date-container>
        </div>
        <div class="clr-col" *ngIf="type === MILESTONE_TYPES.RELEASE || type === MILESTONE_TYPES.DEADLINE">
            <clr-date-container class="clr-row">
                <label class="clr-col-12 clr-col-md-3" translate>date_begin</label>
                <input
                    class="clr-col-12 clr-col-md-9"
                    type="date"
                    id="dateBegin"
                    name="date"
                    [(clrDate)]="beginDate"
                    #startDateInputElement
                />
            </clr-date-container>
        </div>
        <div
            style="margin-top: 1rem;"
            class="clr-col-4"
            *ngIf="type !== MILESTONE_TYPES.RELEASE && type !== MILESTONE_TYPES.DEADLINE"
        >
            <input type="time" [formControlName]="MILESTONE_FORM_KEYS.startTime" />
        </div>
    </div>

    <clr-date-container class="clr-row" *ngIf="type === MILESTONE_TYPES.RELEASE">
        <label class="clr-col-12 clr-col-md-3" translate>table_target</label>
        <input
            class="clr-col-12 clr-col-md-9"
            type="date"
            id="dateTarget"
            name="date"
            [(clrDate)]="targetDate"
            #targetDateInputElement
        />
    </clr-date-container>

    <div class="clr-row" *ngIf="type === MILESTONE_TYPES.MEETING || type === MILESTONE_TYPES.MEETING_NO_NOTE">
        <div class="clr-col-8">
            <clr-date-container class="clr-row">
                <label class="clr-col-12 clr-col-md-5" translate>date_end</label>
                <input
                    class="clr-col-12 clr-col-md-7"
                    type="date"
                    id="dateEnd"
                    name="date"
                    [(clrDate)]="endDate"
                    #endDateInputElement
                />
            </clr-date-container>
        </div>
        <div style="margin-top: 1rem;" class="clr-col-4">
            <input type="time" [formControlName]="MILESTONE_FORM_KEYS.endTime" />
        </div>
    </div>

    <div class="clr-row mt-2" *ngIf="!checkDates()">
        <div class="clr-col-12">
            <div class="alert alert-danger" role="alert">
                <div class="alert-items">
                    <div class="alert-item static">
                        <div class="alert-icon-wrapper">
                            <clr-icon class="alert-icon" shape="exclamation-circle"></clr-icon>
                        </div>
                        <span class="alert-text" [innerHTML]="errorsDates[errorDates] | translate"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
