import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {QuillModule} from 'ngx-quill';
import {SharedModule} from '../shared/shared.module';
import {ChangelogComponent} from './changelog/changelog.component';
import {ContactsComponent} from './contacts/contacts.component';
import {CustomerRequestComponent} from './customer-request/customer-request.component';
import {DashClientComponent} from './dash-client/dash-client.component';
import {IncidentComponent} from './incident/incident.component';
import {PortalComponent} from './portal/portal.component';

@NgModule({
    imports: [CommonModule, QuillModule, FormsModule, RouterModule, SharedModule],
    declarations: [
        CustomerRequestComponent,
        IncidentComponent,
        ChangelogComponent,
        DashClientComponent,
        PortalComponent,
        ContactsComponent,
    ],
})
export class PortalModule {}
