import { EventEmitter } from '@angular/core';
var ListAutoIndentDirective = (function () {
    function ListAutoIndentDirective() {
        this.laiBullets = [/^\s*(\*)\s*/, /^\s*((\d+)\.)\s*/, /^\s*(\[(\s*[xvXV]?\s*)])\s*/];
        this.ngModelChange = new EventEmitter();
    }
    ListAutoIndentDirective.prototype.onInput = function ($event) {
        var _this = this;
        var $textarea = $event.target;
        var value = $textarea.value, selectionStart = $textarea.selectionStart;
        var lines = value.split(/\n/);
        var previousValue = this.previousValue || this.ngModel;
        this.previousValue = value;
        if (typeof previousValue !== 'undefined' && previousValue.length > value.length) {
            return;
        }
        if (lines.length < 2) {
            return;
        }
        var linesLengthSum = ListAutoIndentDirective.getLinesLengthsSum(lines);
        var currentLineIndex = linesLengthSum.findIndex(function (lengthSum) { return lengthSum > selectionStart; }) || lines.length - 1;
        var beforeCurrentLineIndex = currentLineIndex - 1;
        if (beforeCurrentLineIndex < 0) {
            return;
        }
        var _a = [currentLineIndex, beforeCurrentLineIndex].map(function (index) { return lines[index]; }), currentLine = _a[0], beforeCurrentLine = _a[1];
        if (!currentLine.match(/^\s*$/) && linesLengthSum[beforeCurrentLineIndex] !== selectionStart) {
            return;
        }
        var _b = [beforeCurrentLine, currentLine].map(function (line) {
            return _this.laiBullets.map(function (bulletRegex) { return line.match(bulletRegex); }).find(function (match) { return !!match; });
        }), bulletMatch = _b[0], currentBulletMatch = _b[1];
        if (!bulletMatch) {
            return;
        }
        var fullMatch = bulletMatch[0], _ = bulletMatch[1], num = bulletMatch[2];
        var __ = bulletMatch[0], bullet = bulletMatch[1];
        if (num) {
            if (isNaN(parseInt(num, 10))) {
                bullet = bullet.replace(num, '');
            }
            else {
                bullet = bullet.replace(num, (parseInt(num, 10) + 1).toString());
            }
        }
        var selection = linesLengthSum[beforeCurrentLineIndex];
        if (fullMatch.length === beforeCurrentLine.length) {
            selection -= lines[beforeCurrentLineIndex].length;
            delete lines[beforeCurrentLineIndex];
        }
        else if (currentBulletMatch) {
            selection += currentBulletMatch[0].length;
        }
        else {
            lines[currentLineIndex] = bullet + " " + lines[currentLineIndex];
            selection += (bullet + " ").length;
        }
        this.previousValue = $textarea.value = lines.join('\n');
        if (this.ngModelChange) {
            this.ngModelChange.emit(this.previousValue);
        }
        $textarea.setSelectionRange(selection, selection);
    };
    ListAutoIndentDirective.getLinesLengthsSum = function (lines) {
        return lines.reduce(function (_lengthSums, line, index) {
            return _lengthSums.push(line.length + 1 + (_lengthSums[index - 1] || 0)) && _lengthSums;
        }, []);
    };
    return ListAutoIndentDirective;
}());
export { ListAutoIndentDirective };
