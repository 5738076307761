<span class="label label-gray">
    <app-long-avatar
        *ngIf="updatable"
        class="avatar"
        [(colorPicker)]="tag.color"
        [cpOutputFormat]="'hex'"
        [bgColor]="tag.color"
        [ngStyle]="{'background-color': tag.color}"
        [hidden]="true"
        (colorPickerClose)="update.emit(tag)"
        [cpOKButton]="true"
        [cpCancelButton]="true"
    ></app-long-avatar>
    <app-long-avatar
        *ngIf="!updatable"
        class="avatar"
        [bgColor]="tag.color"
        [ngStyle]="{'background-color': tag.color}"
        [hidden]="true"
    >
    </app-long-avatar>
    <clr-icon
        *ngIf="branchable"
        shape="tree"
        class="c-hand"
        [ngStyle]="{opacity: tag.branch ? 1 : 0.3}"
        (click)="updateBranch.emit(tag)"
    ></clr-icon>
    <clr-icon *ngIf="!branchable && tag.branch" shape="tree"></clr-icon>
    <span class="span-tag px-02">{{ tag.text }}</span>
    <clr-icon *ngIf="removable" class="c-hand" shape="times" (click)="doDelete()"></clr-icon>
    <clr-icon *ngIf="deletable" class="c-hand" shape="trash" (click)="delete()"></clr-icon>
</span>

<app-modal [(show)]="showDeleteModal" [title]="'delete_tag' | translate">
    <ng-template appModalBody>
        {{ 'tag_delete_confirm' | translate }} <br />
        <ng-container *ngIf="!!count"> {{ 'tag_delete_confirm_count' | translate: {count: count || 0} }} </ng-container>
    </ng-template>
    <ng-template appModalFooter>
        <button class="btn btn-secondary" (click)="showDeleteModal = false" translate>form_cancel</button>
        <button class="btn btn-danger" (click)="doDelete()">{{ 'form_validate' | translate }}</button>
    </ng-template>
</app-modal>
