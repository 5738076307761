import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "./list/projects-list.component.ngfactory";
import * as i3 from "./list/projects-list.component";
import * as i4 from "../shared/http-rest/http-rest.service";
import * as i5 from "../shared/shortcut-handler/shortcut-handler.service";
import * as i6 from "../auth/auth.service";
import * as i7 from "../shared/toast/toast.service";
import * as i8 from "./projects.component";
import * as i9 from "@angular/router";
var styles_ProjectsComponent = [];
var RenderType_ProjectsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ProjectsComponent, data: {} });
export { RenderType_ProjectsComponent as RenderType_ProjectsComponent };
export function View_ProjectsComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { form: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 8, "div", [["class", "column-container py-02"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "div", [["class", "clr-row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 3, "div", [["class", "clr-col-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 2, "h1", [["class", "mt-0"], ["translate", ""]], null, null, null, null, null)), i0.ɵdid(5, 8536064, null, 0, i1.TranslateDirective, [i1.TranslateService, i0.ElementRef, i0.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i0.ɵted(-1, null, ["title_projects"])), (_l()(), i0.ɵeld(7, 0, null, null, 2, "div", [["class", "clr-row flex-1"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "app-projects-list", [["class", "column-container clr-col-12"]], null, [[null, "showCreateModalChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("showCreateModalChange" === en)) {
        var pd_0 = ((_co.showCreateModal = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ProjectsListComponent_0, i2.RenderType_ProjectsListComponent)), i0.ɵdid(9, 770048, null, 0, i3.ProjectsListComponent, [i4.HttpRestService, i5.ShortcutHandlerService, i6.AuthService, i1.TranslateService, i7.ToastService], { projects: [0, "projects"], actionBarButtonClassList: [1, "actionBarButtonClassList"], addButtonClassList: [2, "addButtonClassList"], wizardClients: [3, "wizardClients"], wizardClientId: [4, "wizardClientId"], showCreateModal: [5, "showCreateModal"] }, { showCreateModalChange: "showCreateModalChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = ""; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.projects; var currVal_2 = ""; var currVal_3 = "btn-primary"; var currVal_4 = _co.clients; var currVal_5 = _co.queryClientId; var currVal_6 = _co.showCreateModal; _ck(_v, 9, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
export function View_ProjectsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-projects", [], null, null, null, View_ProjectsComponent_0, RenderType_ProjectsComponent)), i0.ɵdid(1, 49152, null, 0, i8.ProjectsComponent, [i4.HttpRestService, i9.ActivatedRoute, i9.Router, i6.AuthService], null, null)], null, null); }
var ProjectsComponentNgFactory = i0.ɵccf("app-projects", i8.ProjectsComponent, View_ProjectsComponent_Host_0, {}, {}, []);
export { ProjectsComponentNgFactory as ProjectsComponentNgFactory };
