import {NgModule} from '@angular/core';
import {Route, RouterModule} from '@angular/router';
import {NAVIGATE_RIGHTS} from '../../../defs/schema-static';
import {AccessGuard} from './access-guard/access-guard.guard';
import {AdminComponent} from './admin-panel/admin-panel.component';
import {
    APP_DEFAULT_ROUTE,
    APP_MODULE_ROUTE_PATH,
    AUTH_MODULE_ROUTE_PATH,
    IDEABOARD_MODULE_ROUTE_PATH,
    PORTAL_MODULE_ROUTE_PATH,
    WIKI_MODULE_ROUTE_PATH,
} from './app-static';
import {authRoutes} from './auth/auth-routing.module';
import {AuthComponent} from './auth/auth.component';
import {AuthGuard} from './auth/auth.guard';
import {ClientAuthGuard} from './auth/client-auth.guard';
import {EmployeeAuthGuard} from './auth/employee-auth.guard';
import {CalendarComponent} from './calendar/calendar.component';
import {ClientComponent} from './client/client.component';
import {ClientsComponent} from './clients/clients.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {EmployeeComponent} from './employee/employee.component';
import {EmployeesComponent} from './employees/employees.component';
import {GanttComponent} from './gantt/gantt.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {ChangelogComponent} from './portal/changelog/changelog.component';
import {ContactsComponent} from './portal/contacts/contacts.component';
import {CustomerRequestComponent} from './portal/customer-request/customer-request.component';
import {IncidentComponent} from './portal/incident/incident.component';
import {PortalComponent} from './portal/portal/portal.component';
import {ProjectComponent} from './project/project.component';
import {ProjectsComponent} from './projects/projects.component';
import {CanDeactivateGuard} from './shared/can-deactivate-guard/can-deactivate-guard';
import {IControlFlowRoute} from './shared/control-flow/control-flow.component';
import {TodoComponent} from './todo/todo.component';

export const appModuleRoutes: Route[] = [
    {path: AUTH_MODULE_ROUTE_PATH.AUTH, component: AuthComponent, children: [...authRoutes]},
    {
        path: PORTAL_MODULE_ROUTE_PATH.DASHBOARD,
        component: PortalComponent,
        canActivate: [ClientAuthGuard],
    },
    {
        path: PORTAL_MODULE_ROUTE_PATH.REQUESTS,
        component: CustomerRequestComponent,
        canActivate: [ClientAuthGuard],
    },
    {
        path: PORTAL_MODULE_ROUTE_PATH.INCIDENTS,
        component: IncidentComponent,
        canActivate: [ClientAuthGuard],
    },
    {
        path: PORTAL_MODULE_ROUTE_PATH.CHANGELOGS,
        component: ChangelogComponent,
        canActivate: [ClientAuthGuard],
    },
    {
        path: PORTAL_MODULE_ROUTE_PATH.CONTACTS,
        component: ContactsComponent,
        canActivate: [ClientAuthGuard],
    },
    {
        path: WIKI_MODULE_ROUTE_PATH.WIKI,
        // component: WikiComponent,
        loadChildren: './wiki/wiki.module#WikiModule',
        canActivate: [EmployeeAuthGuard, AccessGuard],
        data: {
            expectedRight: NAVIGATE_RIGHTS.NAVIGATE_WIKI,
        },
    },
    {
        path: APP_MODULE_ROUTE_PATH.DASHBOARD,
        component: DashboardComponent,
        data: {
            expectedRight: NAVIGATE_RIGHTS.NAVIGATE_DASHBOARD,
        },
        canActivate: [EmployeeAuthGuard, AccessGuard],
    },
    {
        path: APP_MODULE_ROUTE_PATH.REPORTS,
        // component: ReportComponent,
        loadChildren: './report/report.module#ReportModule',
        canActivate: [EmployeeAuthGuard, AccessGuard],
        data: {
            expectedRight: NAVIGATE_RIGHTS.NAVIGATE_REPORTS,
        },
    },
    {
        path: APP_MODULE_ROUTE_PATH.GANTT,
        component: GanttComponent,
        canActivate: [EmployeeAuthGuard, AccessGuard],
        data: {
            expectedRight: NAVIGATE_RIGHTS.NAVIGATE_GANTT,
        },
    },
    {
        path: APP_MODULE_ROUTE_PATH.WORKLOADTOOLS,
        loadChildren: './workload/workload.module#WorkloadModule',
        // component: WorkloadToolsComponent,
        canActivate: [EmployeeAuthGuard, AccessGuard],
        data: {
            expectedRight: NAVIGATE_RIGHTS.NAVIGATE_WORKLOADTOOLS,
        },
    },
    {
        path: APP_MODULE_ROUTE_PATH.FIRON,
        // component: FironComponent,
        loadChildren: './firon/firon.module#FironModule',
        canActivate: [EmployeeAuthGuard, AccessGuard],
        data: {
            expectedRight: NAVIGATE_RIGHTS.NAVIGATE_FIRON,
        },
    },
    {
        path: APP_MODULE_ROUTE_PATH.TODO,
        component: TodoComponent,
        canActivate: [EmployeeAuthGuard, AccessGuard],
        data: {
            expectedRight: NAVIGATE_RIGHTS.NAVIGATE_TODO,
        },
    },
    {
        path: APP_MODULE_ROUTE_PATH.CALENDAR,
        component: CalendarComponent,
        canActivate: [EmployeeAuthGuard, AccessGuard],
        data: {
            expectedRight: NAVIGATE_RIGHTS.NAVIGATE_CALENDAR,
        },
    },
    {
        path: APP_MODULE_ROUTE_PATH.CLIENTS,
        component: ClientsComponent,
        canActivate: [EmployeeAuthGuard, AccessGuard],
        data: {
            expectedRight: NAVIGATE_RIGHTS.NAVIGATE_CLIENTS,
        },
    },
    {
        path: `${APP_MODULE_ROUTE_PATH.CLIENT}/:id`,
        component: ClientComponent,
        canActivate: [EmployeeAuthGuard, AccessGuard],
        data: {
            expectedRight: NAVIGATE_RIGHTS.NAVIGATE_CLIENT,
        },
    },
    {
        path: APP_MODULE_ROUTE_PATH.PROJECTS,
        component: ProjectsComponent,
        canActivate: [EmployeeAuthGuard, AccessGuard],
        data: {
            expectedRight: NAVIGATE_RIGHTS.NAVIGATE_PROJECTS,
        },
    },
    {
        path: `${APP_MODULE_ROUTE_PATH.PROJECT}/:id`,
        component: ProjectComponent,
        canActivate: [EmployeeAuthGuard, AccessGuard],
        data: {
            expectedRight: NAVIGATE_RIGHTS.NAVIGATE_PROJECT,
        },
    },
    {
        path: APP_MODULE_ROUTE_PATH.EMPLOYEES,
        component: EmployeesComponent,
        canActivate: [EmployeeAuthGuard, AccessGuard],
        data: {
            expectedRight: NAVIGATE_RIGHTS.NAVIGATE_EMPLOYEES,
        },
    },
    {
        path: `${APP_MODULE_ROUTE_PATH.EMPLOYEE}/:id`,
        component: EmployeeComponent,
        canDeactivate: [CanDeactivateGuard],
        canActivate: [EmployeeAuthGuard, AccessGuard],
        data: {
            expectedRight: NAVIGATE_RIGHTS.NAVIGATE_EMPLOYEE,
        },
    },
    {
        path: IDEABOARD_MODULE_ROUTE_PATH.BOARD,
        // component: TabsBoardComponent,
        loadChildren: './ideaboard/ideaboard.module#IdeaboardModule',
        canActivate: [EmployeeAuthGuard, AccessGuard],
        data: {
            expectedRight: NAVIGATE_RIGHTS.NAVIGATE_IDEABOARD,
        },
    },
    {
        path: `${APP_MODULE_ROUTE_PATH.ADMIN}`,
        component: AdminComponent,
        canActivate: [EmployeeAuthGuard, AccessGuard],
        data: {
            expectedRight: NAVIGATE_RIGHTS.NAVIGATE_ADMIN,
        },
    },
    {path: '**', component: NotFoundComponent},
];

export const employeeRoutesControlFlow: {[path: string]: Partial<IControlFlowRoute>} = {
    [APP_MODULE_ROUTE_PATH.ADMIN]: {
        label: 'Admin',
        icon: 'administrator',
    },
    [APP_MODULE_ROUTE_PATH.DASHBOARD]: {
        label: 'Dashboard',
        icon: 'pie-chart',
    },
    [APP_MODULE_ROUTE_PATH.REPORTS]: {
        label: 'Reports',
        icon: 'line-chart',
    },
    [IDEABOARD_MODULE_ROUTE_PATH.BOARD]: {
        label: 'IdeaBoard',
        icon: 'lightbulb',
    },
    [APP_MODULE_ROUTE_PATH.FIRON]: {
        label: 'Firon',
        icon: 'flame',
    },
    [APP_MODULE_ROUTE_PATH.TODO]: {
        label: 'TODO',
        icon: 'checkbox-list',
    },
    [APP_MODULE_ROUTE_PATH.WORKLOADTOOLS]: {
        label: 'Workloadtools',
        icon: 'bar-chart',
    },
    [APP_MODULE_ROUTE_PATH.GANTT]: {
        label: 'Gantt',
        icon: 'tree-view',
    },
    [APP_MODULE_ROUTE_PATH.CALENDAR]: {
        label: 'Calendar',
        icon: 'calendar',
    },
    [APP_MODULE_ROUTE_PATH.CLIENTS]: {
        label: 'Clients',
        icon: 'users',
        aliases: [APP_MODULE_ROUTE_PATH.CLIENT],
    },
    [APP_MODULE_ROUTE_PATH.PROJECTS]: {
        label: 'Projects',
        icon: 'briefcase',
        aliases: [APP_MODULE_ROUTE_PATH.PROJECT],
    },
    [APP_MODULE_ROUTE_PATH.EMPLOYEES]: {
        label: 'Employees',
        icon: 'user',
        aliases: [APP_MODULE_ROUTE_PATH.EMPLOYEE],
    },
    [WIKI_MODULE_ROUTE_PATH.WIKI]: {
        label: 'Wiki',
        icon: 'library',
    },
};

export const clientRoutesControlFlow: {[path: string]: Partial<IControlFlowRoute>} = {
    [PORTAL_MODULE_ROUTE_PATH.DASHBOARD]: {
        label: 'Dashboard',
        icon: 'chart-line fas',
    },
    [PORTAL_MODULE_ROUTE_PATH.REQUESTS]: {
        label: 'Requests',
        icon: 'chalkboard-teacher fa',
    },
    [PORTAL_MODULE_ROUTE_PATH.INCIDENTS]: {
        label: 'Incidents',
        icon: 'life-ring fas',
    },
    [PORTAL_MODULE_ROUTE_PATH.CHANGELOGS]: {
        label: 'Changelogs',
        icon: 'exchange-alt fas',
    },
    [PORTAL_MODULE_ROUTE_PATH.CONTACTS]: {
        label: 'Contacts',
        icon: 'user-friends fas',
    },
};

const appRoutes: Route[] = [
    {
        path: APP_MODULE_ROUTE_PATH.APP,
        redirectTo: APP_DEFAULT_ROUTE,
        pathMatch: 'full',
    },
    {
        path: APP_MODULE_ROUTE_PATH.APP,
        canActivate: [AuthGuard],
        children: [...appModuleRoutes],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes)],
    exports: [RouterModule],
    providers: [AuthGuard, EmployeeAuthGuard, ClientAuthGuard, CanDeactivateGuard],
})
export class AppRoutingModule {
    // . Dynamic routes, prevent AOT errors
    /*public constructor(router: Router) {
    appRoutes.push(...dynamicRoutes);

    router.config.push(...dynamicRoutes);
    router.resetConfig(router.config);
  }*/
}
