import moment from 'moment';
import {ISchemaRoute} from '../../schema-static';
import {IClient} from './Clients';
import {IEmployee} from './Employees';

export enum CUSTOMER_CONTACT_FIELD {
    id = 'id',
    createdAt = 'createdAt',
    updatedAt = 'updatedAt',
    clientId = 'clientId',
    employeeId = 'employeeId',
    employee = 'employee',
    client = 'client',
}

export interface ICustomerContactBare {
    id?: number;
    createdAt?: moment.Moment;
    updatedAt?: moment.Moment;
    clientId: number;
    employeeId: number;
}

export interface ICustomerContactBelong {
    employee: Partial<IEmployee>;
    client: Partial<IClient>;
}

export interface ICustomerContact extends ICustomerContactBare, ICustomerContactBelong {}

const CUSTOMER_CONTACT_SCHEMA_ROUTE: ISchemaRoute = {
    tableName: 'customercontacts',
    singularRoute: 'customercontact',
    pluralRoute: 'customercontacts',
};

export {CUSTOMER_CONTACT_SCHEMA_ROUTE};
