<div class="column-container py-02">
    <div class="clr-row">
        <div class="clr-col-12"><h1 class="mt-0" translate>title_projects</h1></div>
    </div>

    <div class="clr-row flex-1">
        <app-projects-list
            class="column-container clr-col-12"
            [projects]="projects"
            [wizardClients]="clients"
            [wizardClientId]="queryClientId"
            [actionBarButtonClassList]="''"
            [addButtonClassList]="'btn-primary'"
            [(showCreateModal)]="showCreateModal"
        ></app-projects-list>
    </div>
</div>
