import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {ControlFlowService} from '../shared/control-flow/control-flow.service';

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class NotFoundComponent implements OnInit, OnDestroy {
    public constructor(private readonly ctrlFlowService: ControlFlowService, private readonly router: Router) {}

    public ngOnInit() {
        this.ctrlFlowService.isNotFound = true;
    }

    public ngOnDestroy() {
        this.ctrlFlowService.isNotFound = false;
    }

    public backToHome() {
        this.ctrlFlowService.isNotFound = false;
        (async () => this.router.navigate(['/dashboard']))();
    }
}
