import * as i0 from "./http-rest.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "../../modal/modal.component.ngfactory";
import * as i5 from "../../modal/modal.component";
import * as i6 from "../shortcut-handler/shortcut-handler.service";
import * as i7 from "../control-flow/control-flow.service";
import * as i8 from "../../modal/modal.directives";
import * as i9 from "./http-rest.component";
import * as i10 from "./http-rest.service";
var styles_HttpRestComponent = [i0.styles];
var RenderType_HttpRestComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HttpRestComponent, data: {} });
export { RenderType_HttpRestComponent as RenderType_HttpRestComponent };
function View_HttpRestComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.httpRest.modal.content; _ck(_v, 0, 0, currVal_0); }); }
function View_HttpRestComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.httpRest.modal.show = false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("form_close")); _ck(_v, 1, 0, currVal_0); }); }
function View_HttpRestComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.httpRest.modal.callback() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.httpRest.modal.callbackLabel)); _ck(_v, 1, 0, currVal_0); }); }
function View_HttpRestComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_HttpRestComponent_3)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HttpRestComponent_4)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.httpRest.modal.callback; _ck(_v, 1, 0, currVal_0); var currVal_1 = !!_co.httpRest.modal.callback; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_HttpRestComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "app-modal", [], null, [[null, "showChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("showChange" === en)) {
        var pd_0 = ((_co.httpRest.modal.show = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ModalComponent_0, i4.RenderType_ModalComponent)), i1.ɵdid(1, 770048, null, 3, i5.ModalComponent, [i6.ShortcutHandlerService, i7.ControlFlowService, i1.ChangeDetectorRef], { show: [0, "show"], title: [1, "title"] }, { showChange: "showChange" }), i1.ɵqud(335544320, 1, { modalHeader: 0 }), i1.ɵqud(335544320, 2, { modalBody: 0 }), i1.ɵqud(335544320, 3, { modalFooter: 0 }), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, [[2, 2]], null, 1, null, View_HttpRestComponent_1)), i1.ɵdid(7, 16384, null, 0, i8.ModalBodyDirective, [i1.TemplateRef], null, null), (_l()(), i1.ɵand(0, [[3, 2]], null, 1, null, View_HttpRestComponent_2)), i1.ɵdid(9, 16384, null, 0, i8.ModalFooterDirective, [i1.TemplateRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.httpRest.modal.show; var currVal_1 = i1.ɵunv(_v, 1, 1, i1.ɵnov(_v, 5).transform(_co.httpRest.modal.title)); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_HttpRestComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "shared-http-rest", [], null, null, null, View_HttpRestComponent_0, RenderType_HttpRestComponent)), i1.ɵdid(1, 49152, null, 0, i9.HttpRestComponent, [i10.HttpRestService], null, null)], null, null); }
var HttpRestComponentNgFactory = i1.ɵccf("shared-http-rest", i9.HttpRestComponent, View_HttpRestComponent_Host_0, {}, {}, []);
export { HttpRestComponentNgFactory as HttpRestComponentNgFactory };
