import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ClrDatagridComparatorInterface, ClrDatagridSortOrder} from '@clr/angular';
import {TranslateService} from '@ngx-translate/core';
import {compare} from 'semver';
import {MilestonesType, RIGHTS} from '../../../../../defs/schema-static';
import {BUDGET_FIELD, BUDGET_SCHEMA_ROUTE, IBudget} from '../../../../../defs/schema/public/Budget';
import {IMilestone, MILESTONE_FIELD} from '../../../../../defs/schema/public/Milestones';
import {IProject} from '../../../../../defs/schema/public/Projects';
import {BUDGET_STATUS_FILTER, DATAGRID_FILTER_TYPE, IDatagridColumn, PROJECT_TABS} from '../../app-static';
import {AuthService} from '../../auth/auth.service';
import {HttpRestService} from '../../shared/http-rest/http-rest.service';
import {TOAST_TYPE, ToastService} from '../../shared/toast/toast.service';

@Component({
    selector: 'app-project-budgets',
    templateUrl: './project-budgets.component.html',
})
export class ProjectBudgetsComponent implements OnInit, OnChanges {
    @Input() public project: IProject;
    @Input() public budgets: Partial<IBudget>[];

    public selectedBudget: Partial<IBudget>;
    public selectedBudgets: Partial<IBudget>[] = [];

    public showEditModal = false;
    public showDeleteModal = false;

    private editQueryParams: number;

    public constructor(
        private readonly httpRest: HttpRestService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly authService: AuthService,
        private readonly toastService: ToastService,
        private readonly translate: TranslateService
    ) {}

    public ngOnInit() {
        this.getBudgets();

        this.route.queryParams.subscribe((queryParams) => {
            this.editQueryParams = Number(queryParams.edit) || null;
            this.selectedBudgetChange();
        });
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.project) {
            this.budgets = undefined;
            this.getBudgets();
        }
    }

    public selectedBudgetChange() {
        if (!this.editQueryParams) {
            this.selectedBudget = null;
            this.showEditModal = false;

            return;
        }

        const budget = (this.budgets || []).find((_budget) => _budget.id === this.editQueryParams);

        if (budget) {
            this.selectedBudget = budget;
            this.showEditModal = true;
        }
    }

    public async closeEditModal() {
        return this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {edit: null},
            queryParamsHandling: 'merge',
        });
    }

    public getBudgets() {
        if (!this.project) {
            return;
        }

        this.budgets = this.budgets || this.project.budgets;
        this.mapReleases();

        this.selectedBudgetChange();
    }

    public mapReleases() {
        const releases = this.releases;
        if (!this.budgets || !releases) {
            return;
        }
        this.budgets
            .filter((budget) => !!budget.release)
            .map(
                (budget) =>
                    (budget.release = {
                        ...budget.release,
                        ...(releases.find((release) => release.id === (budget.releaseId || budget.release.id)) || {}),
                    })
            );
    }

    public budgetChange(budget: Partial<IBudget>) {
        const existingBudget = this.budgets.find((_budget) => _budget.id === budget.id);

        if (existingBudget) {
            Object.assign(existingBudget, budget);
        } else {
            this.budgets.push(budget);
        }

        this.mapReleases();
    }

    public async deleteSelectedBudgets() {
        const deletedBudgetsIds = this.selectedBudgets.map((budget) => budget.id);
        try {
            await this.httpRest.deleteIds(BUDGET_SCHEMA_ROUTE, deletedBudgetsIds).toPromise();
        } catch (err) {
            this.showDeleteModal = false;

            return;
        }
        this.selectedBudgets = [];
        this.budgets = this.budgets.filter((budget) => !deletedBudgetsIds.includes(budget.id));

        this.toastService.show({
            type: TOAST_TYPE.SUCCESS,
            text: 'success_delete_budget',
        });

        this.showDeleteModal = false;
    }

    public get releases(): Partial<IMilestone>[] {
        return (this.project.milestones || [])
            .filter((milestone) => milestone.type === MilestonesType.RELEASE)
            .sort((r1, r2) => compare(r2.version, r1.version));
    }

    public readonly RIGHTS = RIGHTS;
    public readonly BUDGET_FIELD = BUDGET_FIELD;
    public readonly DATAGRID_FILTER_TYPE = DATAGRID_FILTER_TYPE;
    public readonly PROJECT_TABS = PROJECT_TABS;

    public readonly BUDGET_STATUS_FILTER = BUDGET_STATUS_FILTER;
    public readonly BUDGETS_COLUMNS: IDatagridColumn[] = [
        {
            name: 'Description',
            field: BUDGET_FIELD.description,
            translateKey: 'table_description',
        },
        {
            name: 'Price',
            field: BUDGET_FIELD.price,
            translateKey: 'table_price',
        },
        {
            name: 'Release',
            field: `${BUDGET_FIELD.release}.${MILESTONE_FIELD.version}`,
            translateKey: 'release',
            sorter: {
                compare: (b1: IBudget, b2: IBudget) =>
                    b2.release && b2.release.version
                        ? b1.release && b1.release.version
                            ? compare(b2.release.version, b1.release.version)
                            : 1
                        : -1,
            } as ClrDatagridComparatorInterface<IBudget>,
        },
        {
            name: 'Creation date',
            field: BUDGET_FIELD.createdAt,
            translateKey: 'creation_date',
            order: ClrDatagridSortOrder.DESC,
            filterType: DATAGRID_FILTER_TYPE.DATE,
        },
        {
            name: 'Status',
            field: BUDGET_FIELD.status,
            translateKey: 'table_status',
            filterType: DATAGRID_FILTER_TYPE.CUSTOM,
        },
    ];
    public filterOpen: {[key: string]: boolean} = {};

    public readonly isManagerOf: (projectId: number) => boolean = this.authService.isManagerOf.bind(this.authService);
}
