<li title="Timer" class="Sidebar-navItem" data-tab="timer" [class.d-none]="isCollapsed">
    <div class="sidebar-container" [class.is-collapsed]="isCollapsed">
        <a class="timer-icon-main">
            <clr-icon
                shape="clock"
                [ngStyle]="timerService.timer.isRunning && {color: authService.user?.color}"
                class="Sidebar-menuIcon"
            ></clr-icon>

            <span class="menu-title" translate>route_timer</span>
            <span *ngIf="timerService.timer.isStarted" [class.blink]="!timerService.timer.isRunning">
                ({{ timerService.timer.hTime }})</span
            >
        </a>

        <span class="timer-icon-container c-hand" (click)="timerService.timer.toggle()"
            ><clr-icon [attr.shape]="timerService.timer.isRunning ? 'pause' : 'play'" class="collapse-active"></clr-icon
        ></span>

        <span class="timer-icon-container c-hand" (click)="openModal()" [title]="'stop_timer' | translate"
            ><clr-icon *ngIf="timerService.timer.isStarted && !isCollapsed" shape="stop"></clr-icon
        ></span>

        <span class="timer-icon-container c-hand" (click)="reset()" [title]="'cancel_timer' | translate"
            ><clr-icon *ngIf="timerService.timer.isStarted && !isCollapsed" shape="times" class="is-solid"></clr-icon
        ></span>
    </div>
</li>

<li
    class="Sidebar-navItem c-default"
    (mouseenter)="showCollapsedDrawer = true"
    (mouseleave)="showCollapsedDrawer = false"
    [class.d-none]="!isCollapsed"
>
    <div class="collapsed-drawer" [class.shown]="showCollapsedDrawer && timerService.timer.isStarted">
        <span class="icon-container c-hand" (click)="openModal()" [title]="'stop_timer' | translate"
            ><clr-icon shape="stop" size="20"></clr-icon
        ></span>

        <span class="icon-container c-hand" (click)="reset()" [title]="'cancel_timer' | translate">
            <clr-icon shape="times" size="20" class="is-solid"></clr-icon>
        </span>

        <span class="mr-04  ml-04 va-m" [class.blink]="!timerService.timer.isRunning">{{
            timerService.timer.hTime
        }}</span>
    </div>

    <clr-icon
        [attr.shape]="timerService.timer.isRunning ? 'pause' : 'play'"
        class="collapsed-item c-hand"
        (click)="timerService.timer.toggle()"
        [title]="
            (timerService.timer.isRunning
                ? 'pause_timer'
                : timerService.timer.isStarted
                ? 'restart_timer'
                : 'start_timer') | translate
        "
    >
    </clr-icon>
</li>

<app-time-wizard
    *ngIf="showTimerModal"
    [(clrWizardOpen)]="showTimerModal"
    (finish)="assign($event)"
    [params]="paramsTimerModal"
></app-time-wizard>
