import moment from 'moment';
import {ISchemaRoute} from '../../schema-static';
import {IEmployee} from './Employees';
import {ITask} from './Tasks';

export enum TASK_MAIL_FIELD {
    id = 'id',
    createdAt = 'createdAt',
    updatedAt = 'updatedAt',
    taskId = 'taskId',
    subject = 'subject',
    employeeId = 'employeeId',
    weblink = 'weblink',
    dismissed = 'dismissed',
}

export interface ITaskMail {
    id?: number;
    createdAt?: moment.Moment;
    updatedAt?: moment.Moment;
    taskId: number;
    task?: Partial<ITask>;
    subject: string;
    employeeId: number;
    employee?: Partial<IEmployee>;
    weblink: string;
    dismissed: boolean;
}

const TASK_MAIL_SCHEMA_ROUTE: ISchemaRoute = {
    tableName: 'taskmails',
    singularRoute: 'taskmail',
    pluralRoute: 'taskmails',
};

export {TASK_MAIL_SCHEMA_ROUTE};
