import {Component, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {DndDropEvent} from 'ngx-drag-drop';
import {forkJoin, noop} from 'rxjs';
import {ApiRoutePlurality, HTTP_METHOD, MAX_LENGTH_DEFAULT, TodoStatusType} from '../../../../defs/schema-static';
import {IEmployee} from '../../../../defs/schema/public/Employees';
import {ITodoCategory, TODO_CATEGORY_SCHEMA_ROUTE} from '../../../../defs/schema/public/TodoCategory';
import {ITodo, TODO_SCHEMA_ROUTE} from '../../../../defs/schema/public/Todos';
import {AuthService} from '../auth/auth.service';
import {ModalSimpleComponent} from '../modal-simple/modal-simple.component';
import {ModalSimpleService} from '../modal-simple/modal-simple.service';
import {DEBOUNCE_EVENT_TYPE} from '../shared/debounce/debounce.directive';
import {HttpRestService} from '../shared/http-rest/http-rest.service';
import {MomentService} from '../shared/moment/moment.service';
import {SHORTCUT_TODO, ShortcutHandlerService} from '../shared/shortcut-handler/shortcut-handler.service';

export interface IDisplayTodoCategory {
    category: ITodoCategory;
    todos: ITodo[];
    collapsed: boolean;
}

@Component({
    selector: 'app-todo',
    templateUrl: './todo.component.html',
    styleUrls: ['./todo.component.scss'],
})
export class TodoComponent implements OnDestroy {
    private todos: ITodo[];
    public selectedTodo: ITodo;
    public selectedCategory: ITodoCategory;
    private todoCategories: ITodoCategory[];
    public displayCategory: IDisplayTodoCategory[];
    public currentUser: Partial<IEmployee>;
    private alreadyDefault = false;
    private orderCategory: number[];

    private defaultCategory: ITodoCategory;

    private readonly BARE_TODO: ITodo = {
        id: -1,
        text: '',
        status: TodoStatusType.TODO,
        employeeId: null,
        todoCategoryId: null,
        dateAction: null,
    };

    private readonly BARE_CATEGORY: ITodoCategory = {
        text: '',
        employeeId: null,
        defaultCategory: false,
        orderTodos: [],
    };

    private readonly userChangeSubscriber: {unsubscribe(): void};

    public constructor(
        private readonly httpRest: HttpRestService,
        public readonly authService: AuthService,
        private readonly translate: TranslateService,
        private readonly keyService: ShortcutHandlerService,
        private readonly router: Router,
        private readonly momentService: MomentService,
        private readonly modalSimpleService: ModalSimpleService
    ) {
        this.setupKeyEvent();

        this.userChangeSubscriber = this.authService.userChange.subscribe(() => {
            this.refreshComponent();
        });

        if (localStorage.getItem('orderCategory')) {
            this.orderCategory = localStorage
                .getItem('orderCategory')
                .split(',')
                .map((e) => parseInt(e, 10));
        }

        this.refreshComponent();
    }

    private refreshComponent() {
        this.currentUser = this.authService.user.employee;
        this.BARE_CATEGORY.employeeId = this.currentUser.id;
        this.BARE_TODO.employeeId = this.currentUser.id;

        if (this.currentUser.id) {
            this.httpRest
                ._request<ITodoCategory[]>(
                    HTTP_METHOD.GET,
                    ApiRoutePlurality.PLURAL,
                    TODO_CATEGORY_SCHEMA_ROUTE,
                    'list'
                )
                .subscribe((todoCats) => {
                    this.todoCategories = todoCats;
                    this.httpRest
                        ._request<ITodo[]>(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, TODO_SCHEMA_ROUTE, 'list')
                        .subscribe((todos) => {
                            if (todoCats.length === 0 && !this.alreadyDefault) {
                                const DEFAULT_CATEGORY = {...this.BARE_CATEGORY};
                                DEFAULT_CATEGORY.defaultCategory = true;
                                this.alreadyDefault = true;
                                DEFAULT_CATEGORY.text = 'MISC.';
                                this.httpRest
                                    .put<ITodoCategory>(TODO_CATEGORY_SCHEMA_ROUTE, {...DEFAULT_CATEGORY})
                                    .subscribe((todoCat) => {
                                        this.todoCategories = [];
                                        this.todoCategories.push(todoCat);
                                        this.selectedCategory = todoCat;
                                        this.todos = [];
                                        this.addTodo();
                                    });
                            } else if (todos.length > 0) {
                                this.todos = todos;
                            } else {
                                this.todos = [];
                                this.selectedCategory = todoCats[0];
                                this.addTodo();
                            }
                            this.setDisplayCategory();
                            window.setTimeout(() => {
                                const el = document.querySelector('.todo') as HTMLElement;
                                if (el) {
                                    el.focus();
                                }
                            }, 150);
                        });
                });
        }
    }

    private deleteTodo(todo: ITodo) {
        const obs = this.httpRest.deleteId(TODO_SCHEMA_ROUTE, todo.id);
        obs.subscribe(() => {
            const idx = this.todos.findIndex((t) => t.id === todo.id);
            this.todos.splice(idx, 1);
            this.setDisplayCategory();
        });

        return obs;
    }

    public async deleteCategory(category: ITodoCategory) {
        this.selectedCategory = category;
        this.modalSimpleService
            .open(ModalSimpleComponent, {
                title: 'swal_are_you_sure',
                contentI18n: 'swal_cant_revert',
                ok: {
                    i18n: 'form_validate',
                    class: 'btn-danger',
                },
            })
            .subscribe((closed) => {
                if (closed.result) {
                    this.doDeleteCategory();
                }
            }, noop);
    }

    public doDeleteCategory() {
        const todos = this.getTodoOfCategory(this.selectedCategory);

        const del = () => {
            this.httpRest.deleteId(TODO_CATEGORY_SCHEMA_ROUTE, this.selectedCategory.id).subscribe(() => {
                const idx = this.todoCategories.findIndex((t: any) => t.id === this.selectedCategory.id);
                this.todoCategories.splice(idx, 1);
                this.setDisplayCategory();
            });
        };

        if (todos.length) {
            forkJoin(todos.map((todo) => this.deleteTodo(todo))).subscribe(del);
        } else {
            del();
        }
    }

    private setupKeyEvent() {
        this.keyService.register({
            shortcut: SHORTCUT_TODO.MOVE_TODO_DOWN,
            callback: () => {
                this.moveTodo(1);
            },
            context: this,
            forceListen: true,
        });

        this.keyService.register({
            shortcut: SHORTCUT_TODO.DELETE_TODO,
            callback: () => {
                this.deleteTodo(this.selectedTodo);
            },
            context: this,
            forceListen: true,
        });

        this.keyService.register({
            shortcut: SHORTCUT_TODO.MOVE_TODO_UP,
            callback: () => {
                this.moveTodo(-1);
            },
            context: this,
            forceListen: true,
        });
        this.keyService.register({
            shortcut: SHORTCUT_TODO.CANCEL_TODO,
            callback: () => {
                this.cancelTodo(this.selectedTodo);
            },
            context: this,
            forceListen: true,
        });
        this.keyService.register({
            shortcut: SHORTCUT_TODO.DONE_TODO,
            callback: () => {
                this.doneTodo(this.selectedTodo);
            },
            context: this,
            forceListen: true,
        });
        this.keyService.register({
            shortcut: SHORTCUT_TODO.ADD_CATEGORY,
            callback: () => {
                this.addCategory();
            },
            context: this,
            forceListen: true,
        });

        this.keyService.register({
            shortcut: SHORTCUT_TODO.SAVE_TODO,
            callback: () => {
                this.saveTodo(this.selectedTodo, true);
            },
            context: this,
            forceListen: true,
        });
    }

    public ngOnDestroy() {
        this.userChangeSubscriber.unsubscribe();
    }

    public changeTodoStatus(todo: ITodo) {
        switch (todo.status) {
            case this.TodoStatusType.TODO:
                todo.status = this.TodoStatusType.DONE;
                break;
            case this.TodoStatusType.DONE:
                todo.status = this.TodoStatusType.CANCELED;
                break;
            case this.TodoStatusType.CANCELED:
                todo.status = this.TodoStatusType.TODO;
                break;
            default:
                break;
        }
        this.updateTodo(todo);
    }

    private setDisplayCategory() {
        this.displayCategory = [];

        this.todoCategories.sort((a, b) => {
            return a.defaultCategory === b.defaultCategory ? 0 : b.defaultCategory ? -1 : 1;
        });

        if (!this.orderCategory || this.orderCategory.length === 0) {
            this.orderCategory = this.todoCategories.map((cat) => cat.id);
            localStorage.setItem('orderCategory', this.orderCategory.toString());
        }

        this.mapOrder(this.todoCategories, this.orderCategory, 'id');

        this.todoCategories.map((category) => {
            if (category.defaultCategory) {
                this.defaultCategory = category;
            }
            const todosOf = this.getTodoOfCategory(category);
            this.displayCategory.push({
                category,
                todos: todosOf,
                collapsed: localStorage.getItem(`category${category.id}`)
                    ? localStorage.getItem(`category${category.id}`) === 'true'
                    : true,
            });
        });
    }

    public selectTodo(todo: ITodo) {
        this.selectedTodo = todo;
    }

    public selectCategory(category: ITodoCategory) {
        this.selectedCategory = category;
    }

    public addTodo() {
        const i = this.todos.findIndex((todo) => todo.text.trim() === '');
        if (i > -1) {
            this.todos.splice(i, 1);
        }
        const todoToAdd = {...this.BARE_TODO};
        todoToAdd.id = -1;
        todoToAdd.dateAction = this.momentService.moment();
        if (this.selectedCategory) {
            todoToAdd.todoCategoryId = this.selectedCategory.id;
            if (this.selectedTodo) {
                if (this.selectedCategory.orderTodos) {
                    const todoIdx = this.selectedCategory.orderTodos.findIndex((idx) => idx === this.selectedTodo.id);
                    this.selectedCategory.orderTodos.splice(todoIdx + 1, 0, -1);
                }
            }
        } else {
            todoToAdd.todoCategoryId = this.defaultCategory.id;
        }
        this.todos.push(todoToAdd);
        this.setDisplayCategory();
        window.setTimeout(() => {
            const el = document.getElementById(`todo${todoToAdd.id.toString()}`);
            if (el) {
                el.focus();
                this.selectedTodo = todoToAdd;
            }
        }, 10);
    }

    public saveTodo(todoToAdd: ITodo, createNewTodo?: boolean): void {
        if (!todoToAdd) {
            if (createNewTodo) {
                this.addTodo();
            }

            return;
        }
        if (todoToAdd.id > -1 && todoToAdd.text.trim() === '') {
            this.httpRest.deleteId(TODO_SCHEMA_ROUTE, todoToAdd.id);
            this.todos.splice(this.todos.indexOf(todoToAdd), 1);
            this.setDisplayCategory();

            return;
        }

        todoToAdd.dateAction = this.momentService.moment();
        if (this.selectedCategory && !todoToAdd.todoCategoryId) {
            todoToAdd.todoCategoryId = this.selectedCategory.id;
        }

        if (todoToAdd.text.trim() === '') {
            const i = this.todos.findIndex((todo) => todo.text.trim() === '');
            if (i > -1) {
                this.todos.splice(i, 1);
                this.setDisplayCategory();
            }
            if (createNewTodo) {
                this.addTodo();
            }

            return;
        }

        if (todoToAdd.id > -1) {
            this.updateTodo(todoToAdd, createNewTodo);
        } else {
            delete todoToAdd.id;
            this.httpRest.put<ITodo>(TODO_SCHEMA_ROUTE, todoToAdd).subscribe((ntodo) => {
                todoToAdd.id = ntodo.id;
                if (this.selectedCategory) {
                    this.selectedCategory.orderTodos.splice(
                        this.selectedCategory.orderTodos.indexOf(-1),
                        1,
                        todoToAdd.id
                    );
                    this.updateTodoCategory(this.selectedCategory, null);
                }

                this.setDisplayCategory();

                window.setTimeout(() => {
                    const el = document.getElementById(`todo${todoToAdd.id.toString()}`);
                    if (el) {
                        el.focus();
                    }
                }, 10);
                if (createNewTodo) {
                    this.addTodo();
                }
            });
        }
    }

    // tslint:disable-next-line no-empty prefer-function-over-method
    public onDragStart = ($event: any, todoMoved: ITodo) => {};

    // tslint:disable-next-line no-empty prefer-function-over-method
    public onDragCanceled = ($event: any) => {};

    public changeCollapsed = (el: IDisplayTodoCategory) => {
        el.collapsed = !el.collapsed;
        localStorage.setItem(`category${el.category.id}`, el.collapsed ? 'true' : 'false');
    };

    public moveTodo(relative: number) {
        if (!this.selectedTodo) {
            return;
        }
        const oldIdx = this.selectedCategory.orderTodos.indexOf(this.selectedTodo.id);
        let newIdx = 0;
        if (oldIdx > -1) {
            if (oldIdx + relative >= 0) {
                newIdx = oldIdx + relative;
            }
            this.selectedCategory.orderTodos.splice(oldIdx, 1);
        }
        this.selectedCategory.orderTodos.splice(newIdx, 0, this.selectedTodo.id);
        this.httpRest
            .post<ITodoCategory>(TODO_CATEGORY_SCHEMA_ROUTE, {
                id: this.selectedCategory.id,
                orderTodos: this.selectedCategory.orderTodos || [],
            })
            .subscribe(() => {
                this.setDisplayCategory();
            });
    }

    public onDrop(event: DndDropEvent, category: ITodoCategory) {
        if (event.data.orderTodos) {
            const idx = this.orderCategory.indexOf(event.data.id);
            let newidx = event.index;
            if (event.index > 0) {
                newidx = this.orderCategory.indexOf(category.id);
            }
            if (newidx >= this.orderCategory.length - 1) {
                return;
            }

            if (event.index === 0) {
                if ((event.event.target as HTMLElement).clientTop !== 0) {
                    this.orderCategory.splice(idx, 1);
                    this.orderCategory.splice(idx - 1, 0, event.data.id);
                    localStorage.setItem('orderCategory', this.orderCategory.toString());
                    this.setDisplayCategory();

                    return;
                }
            }
            this.orderCategory.splice(idx, 1);

            this.orderCategory.splice(newidx, 0, event.data.id);

            localStorage.setItem('orderCategory', this.orderCategory.toString());
            this.setDisplayCategory();

            return;
        }
        const newIdx = event.index - 1;
        const todoMoved = event.data;

        const oldIdx = category.orderTodos.indexOf(event.data.id);
        if (oldIdx > -1) {
            category.orderTodos.splice(oldIdx, 1);
        }
        category.orderTodos.splice(newIdx, 0, todoMoved.id);
        this.httpRest
            .post<ITodoCategory>(TODO_CATEGORY_SCHEMA_ROUTE, {
                id: category.id,
                orderTodos: category.orderTodos,
            })
            .subscribe(() => {
                this.setDisplayCategory();
            });
    }

    public addCategory() {
        this.httpRest.put<ITodoCategory>(TODO_CATEGORY_SCHEMA_ROUTE, this.BARE_CATEGORY).subscribe((ncategory) => {
            this.todoCategories.push(ncategory);

            this.selectedCategory = ncategory;

            window.setTimeout(() => {
                this.setDisplayCategory();
            }, 100);

            window.setTimeout(() => {
                const el = document.getElementById(`category${ncategory.id.toString()}`);
                if (el) {
                    el.focus();
                }
            }, 250);
        });
    }

    public cancelTodo(todo: ITodo) {
        todo.status = TodoStatusType.CANCELED;
        todo.dateAction = this.momentService.moment();
        this.updateTodo(todo);
    }

    public doneTodo(todo: ITodo) {
        todo.status = TodoStatusType.DONE;
        todo.dateAction = this.momentService.moment();
        this.updateTodo(todo);
    }

    public updateTodoCategory(category: ITodoCategory, todoList: ITodo[]) {
        if (category.text.trim() === '') {
            this.httpRest.deleteId(TODO_CATEGORY_SCHEMA_ROUTE, category.id).subscribe((res) => {
                this.todoCategories.splice(this.todoCategories.indexOf(category), 1);
                this.setDisplayCategory();
            });

            return;
        }
        if (todoList) {
            category.orderTodos = todoList.map((todo) => todo.id);
        }

        this.httpRest.post<ITodoCategory>(TODO_CATEGORY_SCHEMA_ROUTE, {
            id: category.id,
            text: category.text,
            orderTodos: category.orderTodos,
        });
    }

    public updateTodo(todo: ITodo, createNewTodo?: boolean) {
        if (todo.id === -1) {
            if (todo.text.trim() === '') {
                return;
            }

            this.saveTodo(todo, createNewTodo);
        }

        if (todo.text.trim() === '') {
            this.httpRest.deleteId(TODO_SCHEMA_ROUTE, todo.id);
            this.todos.splice(this.todos.indexOf(todo), 1);
            this.setDisplayCategory();

            return;
        }
        this.httpRest.post<Partial<ITodo>>(TODO_SCHEMA_ROUTE, {id: todo.id, text: todo.text, status: todo.status});

        if (createNewTodo) {
            this.addTodo();
        }
    }

    private readonly mapOrder = (array: any[], order: any, key: any) => {
        array.sort((a, b) => {
            const A = a[key];
            const B = b[key];
            if (order) {
                return order.indexOf(A) - order.indexOf(B);
            }
        });

        return array;
    };

    public getTodoOfCategory(category: ITodoCategory): ITodo[] {
        const todos = this.todos.filter((todo) => todo.todoCategoryId === category.id);

        return this.mapOrder(todos, category.orderTodos, 'id');
    }

    public getTodoWithoutCategory(): ITodo[] {
        return this.todos.filter((todo) => !todo.todoCategoryId);
    }

    public displayDate = (date: string) => {
        return this.momentService.moment(date).format('L LTS');
    };

    public readonly TodoStatusType = TodoStatusType;
    public readonly DEBOUNCE_EVENT_TYPE = DEBOUNCE_EVENT_TYPE;
    public readonly MAX_LENGTH_DEFAULT = MAX_LENGTH_DEFAULT;
}
