import {Injectable} from '@angular/core';

export enum TOAST_POSITION {
    TOP_LEFT = 'top-left',
    TOP = 'top',
    TOP_RIGHT = 'top-right',
    RIGHT = 'right',
    BOTTOM_RIGHT = 'bottom-right',
    BOTTOM = 'bottom',
    BOTTOM_LEFT = 'bottom-left',
    LEFT = 'left',
}

export enum TOAST_TYPE {
    INFO = 'info',
    SUCCESS = 'success',
    WARNING = 'warning',
    ERROR = 'danger',
}

interface IToastParams {
    type?: TOAST_TYPE;
    text: string;
    autoClose?: number; // auto-close delay (in ms)
    hideClear?: boolean; // whether or not to hide the clear button
    callback?(): void;
    callbackText?: string;
    appLevel?: boolean;
    closed?: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class ToastService {
    public toasts: IToastParams[] = [];
    public appLevelToasts: IToastParams[] = [];

    public show(params: IToastParams) {
        if (!params.appLevel) {
            params.closed = false;
            this.toasts.push(params);
            window.setTimeout(() => {
                params.closed = true;
            }, 6000);

            return;
        }

        this.appLevelToasts.push(params);
    }

    public hideAll() {
        this.appLevelToasts.map((toast) => (toast.closed = true));
        this.appLevelToasts.length = 0;
    }
}
