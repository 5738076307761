import {AbstractControl, ValidatorFn} from '@angular/forms';
import {ProjectMemberType} from '../../../../../defs/schema-static';
import {IProjectMember} from '../../../../../defs/schema/public/ProjectMembers';

export const requiredProjectMembers = (): ValidatorFn => (control: AbstractControl): {[key: string]: any} | null => {
    const members = (control.value || []) as Partial<IProjectMember>[];

    return members.find((member) => member.type === ProjectMemberType.MANAGER)
        ? null
        : {projectMembers: {value: control.value}};
};
