import {Component, Input} from '@angular/core';
import {Moment} from 'moment';
import {MILESTONE_TYPE_ICON} from '../../../../defs/schema-static';
import {INotification, NOTIFICATION_TYPE} from '../../../../defs/schema/public/Notification';
import {MomentService} from '../shared/moment/moment.service';
import {NotificationService} from '../shared/notification.service';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent {
    @Input()
    public displayNotifications = false;

    public notifications: Partial<INotification>[];

    public constructor(public notificationService: NotificationService, private readonly momentService: MomentService) {
        this.notifications = notificationService.notifications;
    }

    public isLate(date: Moment) {
        return this.momentService.moment(date).isBefore(this.momentService.moment());
    }

    public readonly MILESTONE_TYPE_ICON = MILESTONE_TYPE_ICON;
    public readonly NOTIFICATION_TYPE = NOTIFICATION_TYPE;
    public readonly getNotificationText = this.notificationService.getNotificationText;
}
