var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ClrLoadingState } from '@clr/angular';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { getConfigKeys } from '../../../../../defs/businessRules';
import { ApiRoutePlurality, HTTP_METHOD } from '../../../../../defs/schema-static';
import { TRANSLATIONS_SCHEMA_ROUTE } from '../../../../../defs/schema/meta/Translations';
import { SETTING_LANGUAGE_VALUES } from '../../../../../defs/schema/public/Users';
import { AppComponent } from '../../app.component';
import { ConfigService } from '../../shared/config/config.service';
import { HttpRestService } from '../../shared/http-rest/http-rest.service';
import { TOAST_TYPE, ToastService } from '../../shared/toast/toast.service';
var prepareCustomFile = function (file, customTranslations) {
    var custom = {};
    Object.keys(file).map(function (key) {
        var customtr = customTranslations.find(function (tr) { return tr.key === key; });
        if (customtr) {
            custom[key] = customtr.value;
        }
        else {
            custom[key] = '';
        }
    });
    return custom;
};
var ɵ0 = prepareCustomFile;
var AdminI18nComponent = (function () {
    function AdminI18nComponent(httpRest, http, configService, toastService, translate, cdRef) {
        this.httpRest = httpRest;
        this.http = http;
        this.configService = configService;
        this.toastService = toastService;
        this.translate = translate;
        this.cdRef = cdRef;
        this.files = [];
        this.activeFiles = [];
        this.inactiveFiles = [];
        this.currentFile = null;
        this.validateBtnState = ClrLoadingState.DEFAULT;
        this.hasVariableMissingError = function () {
            var els = document.getElementsByClassName('alert-variable');
            return els && els.length > 0;
        };
    }
    AdminI18nComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var config, enabledI18n, i18n;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.configService.get()];
                    case 1:
                        config = _a.sent();
                        enabledI18n = getConfigKeys(config, ['enabledI18n']).enabledI18n;
                        this.enabledI18n = enabledI18n;
                        i18n = Object.values(SETTING_LANGUAGE_VALUES);
                        this.files = i18n.map(function (name) { return ({
                            name: name,
                            enabled: enabledI18n.includes(name),
                        }); });
                        this.activeFiles = this.files.filter(function (f) { return f.enabled; });
                        this.inactiveFiles = this.files.filter(function (f) { return !f.enabled; });
                        this.cdRef.detectChanges();
                        return [2];
                }
            });
        });
    };
    AdminI18nComponent.prototype.fileChange = function () {
        return __awaiter(this, void 0, void 0, function () {
            var name, _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!this.currentFile) {
                            this.currentJSON = null;
                            this.customJSON = null;
                            return [2];
                        }
                        name = this.currentFile.name;
                        _a = this;
                        return [4, this.http
                                .get([AppComponent.TRANSLATION_PREFIX, name, AppComponent.TRANSLATION_SUFFIX].join(''), {
                                responseType: 'json',
                            })
                                .toPromise()];
                    case 1:
                        _a.currentJSON = (_c.sent());
                        _b = this;
                        return [4, this.httpRest
                                ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, TRANSLATIONS_SCHEMA_ROUTE, name.toLowerCase())
                                .toPromise()];
                    case 2:
                        _b.translations = _c.sent();
                        this.customJSON = prepareCustomFile(this.currentJSON, this.translations);
                        this.beforeEdit = prepareCustomFile(this.currentJSON, this.translations);
                        if (this.form && this.form.dirty) {
                            this.form.form.markAsPristine();
                        }
                        this.filterJSON();
                        return [2];
                }
            });
        });
    };
    AdminI18nComponent.prototype.refresh = function () {
        this.cdRef.detectChanges();
    };
    AdminI18nComponent.prototype.saveFile = function () {
        var _this = this;
        this.validateBtnState = ClrLoadingState.LOADING;
        var i18nCode = this.currentFile.name;
        var obs = [];
        var deletedIds = [];
        Object.keys(this.beforeEdit)
            .filter(function (key) { return _this.beforeEdit[key] !== ''; })
            .map(function (key) {
            if (!_this.customJSON[key]) {
                var translation = _this.translations.find(function (t) { return t.key === key; });
                if (!!translation) {
                    deletedIds.push(translation.id);
                }
            }
        });
        if (deletedIds.length) {
            obs.push(this.httpRest.deleteIds(TRANSLATIONS_SCHEMA_ROUTE, deletedIds));
        }
        var inserted = [];
        var updated = [];
        Object.keys(this.customJSON).map(function (key) {
            _this.customJSON[key] = _this.customJSON[key].trim();
            if (_this.customJSON[key] && _this.customJSON[key] !== _this.beforeEdit[key]) {
                var translation = _this.translations.find(function (t) { return t.key === key; });
                if (!!translation) {
                    updated.push({
                        id: translation.id,
                        value: _this.customJSON[key],
                    });
                }
                else {
                    inserted.push({
                        key: key,
                        language: i18nCode.toLowerCase(),
                        value: _this.customJSON[key],
                    });
                }
            }
        });
        if (inserted.length) {
            obs.push(this.httpRest.putEntities(TRANSLATIONS_SCHEMA_ROUTE, inserted));
        }
        if (updated.length) {
            obs.push(this.httpRest.postEntities(TRANSLATIONS_SCHEMA_ROUTE, updated));
        }
        if (obs.length) {
            this.toastService.show({
                type: TOAST_TYPE.INFO,
                text: 'toast_refresh_translation',
                appLevel: true,
                callbackText: 'refresh',
                callback: function () {
                    window.location.reload(true);
                },
            });
            forkJoin(obs).subscribe(function () {
                _this.validateBtnState = ClrLoadingState.SUCCESS;
                _this.form.form.markAsPristine();
                _this.cdRef.markForCheck();
            });
            this.fileChange();
        }
        else {
            this.validateBtnState = ClrLoadingState.SUCCESS;
            this.toastService.show({
                type: TOAST_TYPE.INFO,
                text: 'toast_refresh_translation',
                appLevel: true,
                callbackText: 'refresh',
                callback: function () {
                    window.location.reload(true);
                },
            });
            this.form.form.markAsPristine();
            this.cdRef.markForCheck();
        }
        var i18nIdx = this.enabledI18n.indexOf(i18nCode);
        if (this.currentFile.enabled !== (i18nIdx !== -1)) {
            if (this.currentFile.enabled) {
                this.enabledI18n.push(i18nCode);
            }
            else {
                this.enabledI18n.splice(i18nIdx, 1);
            }
            (function () { return __awaiter(_this, void 0, void 0, function () {
                var _this = this;
                return __generator(this, function (_a) {
                    this.configService.set({ enabledI18n: this.enabledI18n });
                    this.configService.configObservable.subscribe(function () {
                        _this.toastService.show({
                            type: TOAST_TYPE.INFO,
                            text: 'toast_refresh_translation',
                            appLevel: true,
                            callbackText: 'refresh',
                            callback: function () {
                                window.location.reload(true);
                            },
                        });
                    });
                    return [2];
                });
            }); })();
        }
    };
    AdminI18nComponent.prototype.filterJSON = function () {
        var _this = this;
        this.filteredJSON = Object.keys(this.currentJSON).filter(function (k) {
            return (!_this.customFilter || !!_this.customJSON[k]) &&
                (!_this.textFilter ||
                    k.indexOf(_this.textFilter) !== -1 ||
                    (!!_this.currentJSON[k] && _this.currentJSON[k].indexOf(_this.textFilter) !== -1) ||
                    (!!_this.customJSON[k] && _this.customJSON[k].indexOf(_this.textFilter) !== -1));
        });
        this.cdRef.markForCheck();
    };
    AdminI18nComponent.prototype.variableRemainCount = function (key) {
        var currentTranslation = this.currentJSON[key];
        var newTranslation = this.customJSON[key];
        var variablesNeeded = currentTranslation.match(/{{[a-zA-Z]*}}/g) || [];
        var variableRemaningCount = variablesNeeded.length;
        variablesNeeded.map(function (v) {
            if (newTranslation.includes(v)) {
                variableRemaningCount -= 1;
            }
        });
        return variableRemaningCount;
    };
    AdminI18nComponent.prototype.variableRemainList = function (key) {
        var currentTranslation = this.currentJSON[key];
        var newTranslation = this.customJSON[key];
        var variablesNeeded = currentTranslation.match(/{{[a-zA-Z]*}}/g) || [];
        var list = [];
        variablesNeeded.map(function (v) {
            if (!newTranslation.includes(v)) {
                list.push(v);
            }
        });
        return list.join(',');
    };
    return AdminI18nComponent;
}());
export { AdminI18nComponent };
export { ɵ0 };
