var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClrDatagridSortOrder } from '@clr/angular';
import { TranslateService } from '@ngx-translate/core';
import { ISO_DATE_FORMAT, MilestonesType, ReleaseStateType, RIGHTS } from '../../../../defs/schema-static';
import { MILESTONE_FIELD, MILESTONE_SCHEMA_ROUTE } from '../../../../defs/schema/public/Milestones';
import { DATAGRID_FILTER_TYPE, MILESTONE_TYPE_FILTER } from '../app-static';
import { FormsAddMilestoneService, MilestoneTarget } from '../forms/add-milestone.service';
import { HttpRestService } from '../shared/http-rest/http-rest.service';
import { MomentService } from '../shared/moment/moment.service';
import { TOAST_TYPE, ToastService } from '../shared/toast/toast.service';
var MilestonesListComponent = (function () {
    function MilestonesListComponent(httpRest, formsAddMilestoneService, route, router, toastService, translate, momentService) {
        this.httpRest = httpRest;
        this.formsAddMilestoneService = formsAddMilestoneService;
        this.route = route;
        this.router = router;
        this.toastService = toastService;
        this.translate = translate;
        this.momentService = momentService;
        this.displayMilestones = [];
        this.filter = '';
        this.selectedMilestones = [];
        this.showCreateModal = false;
        this.showEditModal = false;
        this.showDeleteModal = false;
        this.RIGHTS = RIGHTS;
        this.MILESTONE_FIELD = MILESTONE_FIELD;
        this.DATAGRID_FILTER_TYPE = DATAGRID_FILTER_TYPE;
        this.MilestonesType = MilestonesType;
        this.ReleaseStateType = ReleaseStateType;
        this.MILESTONE_TYPE_FILTER = MILESTONE_TYPE_FILTER;
        this.MILESTONE_TYPE_FILTER_STATE_WITHOUT_RELEASES = Object.keys(MILESTONE_TYPE_FILTER).reduce(function (_state, filterKey) {
            _state[filterKey] = filterKey !== MilestonesType.RELEASE;
            return _state;
        }, {});
        this.MILESTONES_COLUMNS = [
            {
                field: MILESTONE_FIELD.obs,
                translateKey: 'table_name',
                hideable: false,
            },
            {
                name: 'Description',
                field: MILESTONE_FIELD.description,
                translateKey: 'table_description',
            },
            {
                field: MILESTONE_FIELD.target,
                translateKey: 'table_target',
                order: ClrDatagridSortOrder.ASC,
                filterType: DATAGRID_FILTER_TYPE.DATE,
                hidden: true,
            },
            {
                field: MILESTONE_FIELD.beginDate,
                translateKey: 'begin_date',
                filterType: DATAGRID_FILTER_TYPE.DATE,
            },
            {
                field: MILESTONE_FIELD.endDate,
                translateKey: 'end_date',
                filterType: DATAGRID_FILTER_TYPE.DATE,
            },
            {
                field: MILESTONE_FIELD.type,
                translateKey: 'table_type',
                filterType: DATAGRID_FILTER_TYPE.CUSTOM,
            },
        ];
        this.filterOpen = {};
    }
    MilestonesListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParams.subscribe(function (queryParams) {
            _this.editQueryParams = Number(queryParams.edit) || null;
            _this.selectedMilestoneChange();
        });
        this.getMilestones();
    };
    MilestonesListComponent.prototype.ngOnChanges = function (changes) {
        if (changes.milestones && this.milestones) {
            this.milestones = this.milestones.slice();
            this.getMilestones();
        }
    };
    MilestonesListComponent.prototype.selectedMilestoneChange = function () {
        var _this = this;
        if (!this.editQueryParams) {
            this.selectedMilestone = null;
            this.showEditModal = false;
            return;
        }
        this.selectedMilestone = (this.milestones || []).find(function (_milestone) { return _milestone.id === _this.editQueryParams; });
        if (this.selectedMilestone) {
            this.editMilestone();
        }
    };
    MilestonesListComponent.prototype.closeEditModal = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.router.navigate([], {
                        relativeTo: this.route,
                        queryParams: { edit: null },
                        queryParamsHandling: 'merge',
                    })];
            });
        });
    };
    MilestonesListComponent.prototype.editMilestone = function () {
        var milestone = this.selectedMilestone;
        this.editionMilestone = {
            id: milestone.id,
            author: milestone.employeeId,
            description: milestone.description,
            milestone: milestone.obs,
            participants: milestone.participants,
            client: this.client && this.client.id,
            project: this.project && this.project.id,
            startDate: this.momentService.moment(milestone.beginDate).format(ISO_DATE_FORMAT),
            startTime: this.momentService
                .moment(milestone.beginDate)
                .format(FormsAddMilestoneService.HOUR_MINUTE_TIME_FORMAT),
            endDate: this.momentService.moment(milestone.endDate).format(ISO_DATE_FORMAT),
            endTime: this.momentService
                .moment(milestone.endDate)
                .format(FormsAddMilestoneService.HOUR_MINUTE_TIME_FORMAT),
            target: milestone.target ? this.momentService.moment(milestone.target).format(ISO_DATE_FORMAT) : null,
            targetType: this.client ? MilestoneTarget.CLIENT : MilestoneTarget.PROJECT,
            type: milestone.type,
            version: milestone.version,
        };
        this.showEditModal = true;
    };
    MilestonesListComponent.prototype.getMilestones = function () {
        if (!this.milestones) {
            return;
        }
        this.updateFilter();
        this.selectedMilestoneChange();
    };
    MilestonesListComponent.prototype.updateFilter = function () {
        var val = this.filter.toLowerCase();
        this.displayMilestones = (this.milestones || []).filter(function (milestone) {
            return (milestone.obs || '').toLowerCase().includes(val) ||
                (milestone.type || '').toLowerCase().includes(val) ||
                (milestone.description || '').toLowerCase().includes(val) ||
                (milestone.version || '').toLowerCase().includes(val) ||
                (milestone.releaseState || '').toLowerCase().includes(val) ||
                !val;
        });
    };
    MilestonesListComponent.prototype.addNewMilestone = function (milestone) {
        return __awaiter(this, void 0, void 0, function () {
            var _milestone;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.formsAddMilestoneService.submit(milestone)];
                    case 1:
                        _milestone = _a.sent();
                        if (!_milestone) {
                            return [2, undefined];
                        }
                        this.milestones.push(_milestone);
                        this.updateFilter();
                        return [2, this.closeEditModal()];
                }
            });
        });
    };
    MilestonesListComponent.prototype.editedMilestone = function (milestone) {
        return __awaiter(this, void 0, void 0, function () {
            var participants, delParticipants, addedParticipants, existingMilestone;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        participants = milestone.participants ? milestone.participants.slice() : [];
                        delParticipants = this.selectedMilestone.participants
                            .filter(function (f) { return !participants.map(function (p) { return p.employeeId; }).includes(f.employeeId); })
                            .map(function (m) { return m.employeeId; });
                        addedParticipants = participants
                            .filter(function (f) {
                            return !_this.selectedMilestone.participants
                                .map(function (p) { return p.employeeId; })
                                .includes(f.employeeId);
                        })
                            .map(function (m) { return m.employee.id; });
                        this.submitSubscriber = this.formsAddMilestoneService.onSubmit.subscribe(function () { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                this.showEditModal = false;
                                this.submitSubscriber.unsubscribe();
                                delete this.submitSubscriber;
                                return [2, this.closeEditModal()];
                            });
                        }); });
                        return [4, this.formsAddMilestoneService.edit(__assign({}, milestone, { id: this.selectedMilestone.id, type: this.selectedMilestone.type }), addedParticipants, delParticipants)];
                    case 1:
                        _a.sent();
                        this.selectedMilestone = undefined;
                        existingMilestone = this.milestones.find(function (_a) {
                            var id = _a.id;
                            return id === milestone.id;
                        });
                        if (!existingMilestone) {
                            return [2];
                        }
                        if (!milestone.target || milestone.target === 'Invalid date') {
                            delete milestone.target;
                        }
                        Object.assign(existingMilestone, __assign({}, milestone, { obs: milestone.milestone, beginDate: this.momentService.moment(milestone.startDate + "T" + milestone.startTime), endDate: this.momentService.moment(milestone.endDate + "T" + milestone.endTime), target: milestone.target ? this.momentService.moment("" + milestone.target) : undefined }));
                        this.updateFilter();
                        return [2];
                }
            });
        });
    };
    MilestonesListComponent.prototype.deleteSelectedMilestones = function () {
        return __awaiter(this, void 0, void 0, function () {
            var deletedMilestoneIds, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        deletedMilestoneIds = this.selectedMilestones.map(function (milestone) { return milestone.id; });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4, this.httpRest.deleteIds(MILESTONE_SCHEMA_ROUTE, deletedMilestoneIds).toPromise()];
                    case 2:
                        _a.sent();
                        return [3, 4];
                    case 3:
                        err_1 = _a.sent();
                        this.showDeleteModal = false;
                        return [2];
                    case 4:
                        this.selectedMilestones = [];
                        this.milestones = this.milestones.filter(function (milestone) { return !deletedMilestoneIds.includes(milestone.id); });
                        this.updateFilter();
                        this.toastService.show({
                            type: TOAST_TYPE.SUCCESS,
                            text: 'success_delete_milestone',
                        });
                        this.showDeleteModal = false;
                        return [2];
                }
            });
        });
    };
    MilestonesListComponent.prototype.deleteSelectedMilestone = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.milestones = this.milestones.filter(function (milestone) { return milestone.id !== _this.selectedMilestone.id; });
                this.selectedMilestones = [];
                this.editionMilestone = null;
                this.updateFilter();
                this.toastService.show({
                    type: TOAST_TYPE.SUCCESS,
                    text: 'success_delete_milestone',
                });
                this.showEditModal = false;
                return [2];
            });
        });
    };
    return MilestonesListComponent;
}());
export { MilestonesListComponent };
