import moment from 'moment';
import {AssignedEmployeeType, ISchemaRoute} from '../../schema-static';
import {IEmployee} from './Employees';
import {ITask} from './Tasks';

export enum ASSIGNED_TASK_FIELD {
    id = 'id',
    taskId = 'taskId',
    employeeId = 'employeeId',
    employee = 'employee',
    assignerId = 'assignerId',
    assigner = 'assigner',
    type = 'type',
    createdAt = 'createdAt',
    updatedAt = 'updatedAt',
    active = 'active',
}

export interface IAssignedTaskBare {
    id?: number;
    createdAt?: moment.Moment;
    updatedAt?: moment.Moment;
    taskId: number;
    employeeId: number;
    assignerId: number;
    type: AssignedEmployeeType;
    active: boolean;
}

export interface IAssignedTaskBelong {
    task: Partial<ITask>;
    employee: Partial<IEmployee>;
    assigner: Partial<IEmployee>;
}

export interface IAssignedTask extends IAssignedTaskBare, IAssignedTaskBelong {}

const ASSIGNED_TASK_SCHEMA_ROUTE: ISchemaRoute = {
    tableName: 'assignedtasks',
    singularRoute: 'assignedtask',
    pluralRoute: 'assignedtasks',
};

export {ASSIGNED_TASK_SCHEMA_ROUTE};
