var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
var _a, _b, _c, _d, _e, _f;
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CognitoIdentityCredentials, util } from 'aws-sdk';
import S3 from 'aws-sdk/clients/s3';
import { camelCase, paramCase } from 'change-case';
import Evaporate from 'evaporate';
import PQueue from 'p-queue';
import slugify from 'slugify';
import publicConfig from '../../../../../defs/config/config.json';
import { ApiRoutePlurality, FileStateType, HTTP_METHOD, STORAGE_RIGHT, STORED_TYPE, } from '../../../../../defs/schema-static';
import { FILE_SCHEMA_ROUTE, FileEntity, IFile, IFileEntity } from '../../../../../defs/schema/storage/Files';
import { noop } from '../../app-static';
import { AuthService } from '../../auth/auth.service';
import { HttpRestService } from '../http-rest/http-rest.service';
import { MomentService } from '../moment/moment.service.js';
import { TOAST_TYPE, ToastService } from '../toast/toast.service';
import * as i0 from "@angular/core";
import * as i1 from "../../auth/auth.service";
import * as i2 from "../http-rest/http-rest.service";
import * as i3 from "../toast/toast.service";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../moment/moment.service";
var FileManagerService = (function () {
    function FileManagerService(authService, httpRest, toastService, translate, momentService) {
        this.authService = authService;
        this.httpRest = httpRest;
        this.toastService = toastService;
        this.translate = translate;
        this.momentService = momentService;
        this.uploadQueue = new PQueue({ concurrency: FileManagerService.MAX_CONCURRENT_UPLOADS });
        this._uploadingFiles = [];
        this.uploadingFileState = new EventEmitter();
        this.uploadingFileProgress = new EventEmitter();
        this.s3 = new S3({
            signatureVersion: 'v4',
            region: FileManagerService.REGION,
            computeChecksums: true,
            httpOptions: {
                timeout: 0,
            },
        });
    }
    FileManagerService.prototype.ngOnInit = function () {
        var _this = this;
        this.authSubscription = this.authService.isAuthenticatedChange.subscribe(function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
            return [2, this.updateCredentials()];
        }); }); });
        if (this.authService.idJWTToken) {
            (function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                return [2, this.updateCredentials()];
            }); }); })();
        }
    };
    FileManagerService.prototype.ngOnDestroy = function () {
        if (this.authSubscription) {
            this.authSubscription.unsubscribe();
            this.authSubscription = null;
        }
    };
    FileManagerService.prototype.updateCredentials = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b, _c, _d, accessKeyId, secretAccessKey, sessionToken, _e, sessionHeaders;
            var _this = this;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        if (!(this.s3.config.credentials && !this.s3.config.credentials.expired)) return [3, 1];
                        if (!this.authService.isAuthenticated) {
                            this.s3.config.credentials.clearCachedId();
                            this.s3.config.update({ credentials: new CognitoIdentityCredentials() });
                        }
                        return [3, 3];
                    case 1:
                        _b = (_a = this.s3.config).update;
                        _c = {};
                        return [4, this.authService.getAwsCredentials()];
                    case 2:
                        _b.apply(_a, [(_c.credentials = _f.sent(), _c)]);
                        _f.label = 3;
                    case 3: return [4, this.authService.getAwsCredentials()];
                    case 4:
                        _d = _f.sent(), accessKeyId = _d.accessKeyId, secretAccessKey = _d.secretAccessKey, sessionToken = _d.sessionToken;
                        if (!!this.evaporate) return [3, 6];
                        _e = this;
                        return [4, Evaporate.create({
                                bucket: FileManagerService.BUCKET,
                                aws_key: accessKeyId,
                                awsRegion: FileManagerService.REGION,
                                logging: false,
                                sendCanonicalRequestToSignerUrl: true,
                                maxConcurrentParts: 1,
                                partSize: 5 * 1024 * 1024,
                                computeContentMd5: true,
                                cryptoMd5Method: function (data) { return util.crypto.md5(data, 'base64'); },
                                cryptoHexEncodedHash256: function (data) { return util.crypto.sha256(data, 'hex'); },
                                customAuthMethod: function (_signParams, _signHeaders, stringToSign, _signatureDateTime, canonicalRequest) { return __awaiter(_this, void 0, void 0, function () {
                                    var stringToSignDecoded, splitStringToSignDecoded, requestScope, _a, date, region, service, signatureType, dateRound, regionRound, serviceRound, signatureTypeRound, fixedCanonicalRequest, fixedCanonicalHash, fixedStringToSignDecoded;
                                    return __generator(this, function (_b) {
                                        stringToSignDecoded = decodeURIComponent(stringToSign);
                                        splitStringToSignDecoded = stringToSignDecoded.split('\n');
                                        requestScope = splitStringToSignDecoded[2];
                                        _a = requestScope.split('/'), date = _a[0], region = _a[1], service = _a[2], signatureType = _a[3];
                                        dateRound = util.crypto.hmac("AWS4" + secretAccessKey, date, 'buffer');
                                        regionRound = util.crypto.hmac(dateRound, region, 'buffer');
                                        serviceRound = util.crypto.hmac(regionRound, service, 'buffer');
                                        signatureTypeRound = util.crypto.hmac(serviceRound, signatureType, 'buffer');
                                        fixedCanonicalRequest = canonicalRequest.replace(/(x-amz-date:)[\dTZ]+/, "$1" + this.momentService
                                            .moment()
                                            .utc()
                                            .format('YYYYMMDDTHHmmss\\Z'));
                                        fixedCanonicalHash = util.crypto.sha256(fixedCanonicalRequest, 'hex');
                                        if (splitStringToSignDecoded[3] !== fixedCanonicalHash) {
                                            console.warn('wrong x-amz-date, fu evaporate', splitStringToSignDecoded[3], fixedCanonicalHash);
                                            splitStringToSignDecoded[3] = fixedCanonicalHash;
                                        }
                                        fixedStringToSignDecoded = splitStringToSignDecoded.join('\n');
                                        return [2, util.crypto.hmac(signatureTypeRound, fixedStringToSignDecoded, 'hex')];
                                    });
                                }); },
                            })];
                    case 5:
                        _e.evaporate = (_f.sent());
                        _f.label = 6;
                    case 6:
                        sessionHeaders = { 'x-amz-security-token': sessionToken };
                        this.evaporate.config.aws_key = accessKeyId;
                        this.evaporate.filesInProcess.forEach(function (fileInProcess) {
                            Object.assign(fileInProcess.xAmzHeadersCommon, sessionHeaders);
                            Object.assign(fileInProcess.xAmzHeadersAtInitiate, sessionHeaders);
                            if (fileInProcess.s3Parts && fileInProcess.s3Parts.length > 0) {
                                fileInProcess.s3Parts.forEach(function (part) {
                                    part.awsRequest.con.aws_key = accessKeyId;
                                    Object.assign(part.awsRequest.request.x_amz_headers, sessionHeaders);
                                });
                            }
                        });
                        return [2];
                }
            });
        });
    };
    FileManagerService.prototype.getFileList = function (storedType, entity) {
        return __awaiter(this, void 0, void 0, function () {
            var route;
            return __generator(this, function (_a) {
                route = ['fileEntity'];
                if (storedType) {
                    route.push(storedType);
                    if (entity) {
                        route.push(FileManagerService.STORED_TYPE_ENTITY_ID[storedType](entity).toString());
                    }
                }
                return [2, this.httpRest
                        ._request(HTTP_METHOD.GET, ApiRoutePlurality.SINGULAR, FILE_SCHEMA_ROUTE, route.join('/'))
                        .toPromise()];
            });
        });
    };
    FileManagerService.prototype.getFileVersionList = function (storedType, entity, filename) {
        return __awaiter(this, void 0, void 0, function () {
            var prefix, _a, _b, Versions;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _b = (_a = FileManagerService).buildPath;
                        return [4, this.getStoragePrefix(storedType, entity)];
                    case 1:
                        prefix = _b.apply(_a, [_c.sent(), filename]);
                        return [4, this.updateCredentials()];
                    case 2:
                        _c.sent();
                        return [4, this.s3
                                .listObjectVersions({
                                Bucket: FileManagerService.BUCKET,
                                Prefix: prefix,
                            })
                                .promise()];
                    case 3:
                        Versions = (_c.sent()).Versions;
                        return [2, Versions];
                }
            });
        });
    };
    FileManagerService.prototype.signFiles = function () {
        var files = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            files[_i] = arguments[_i];
        }
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2, Promise.all(files.map(function (file) { return __awaiter(_this, void 0, void 0, function () {
                        var _a, _b, _c;
                        return __generator(this, function (_d) {
                            switch (_d.label) {
                                case 0:
                                    _a = [{}, file];
                                    _b = {};
                                    _c = {};
                                    return [4, this.getSignedGetObjectUrls({}, file)];
                                case 1:
                                    _c.getObject = (_d.sent())[0];
                                    return [4, this.getSignedGetObjectUrls({
                                            ResponseContentDisposition: "attachment; filename=" + slugify(FileManagerService.getFilename(file)),
                                        }, file)];
                                case 2: return [2, (__assign.apply(void 0, _a.concat([(_b.signed = (_c.getObjectAttachment = (_d.sent())[0],
                                            _c), _b)])))];
                            }
                        });
                    }); }))];
            });
        });
    };
    FileManagerService.prototype.getSignedGetObjectUrls = function (params) {
        var files = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            files[_i - 1] = arguments[_i];
        }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.getSignedUrls.apply(this, ['getObject', params].concat(files))];
            });
        });
    };
    FileManagerService.prototype.getSignedUrls = function (operation, params) {
        var files = [];
        for (var _i = 2; _i < arguments.length; _i++) {
            files[_i - 2] = arguments[_i];
        }
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.updateCredentials()];
                    case 1:
                        _a.sent();
                        return [2, files.map(function (file) {
                                return _this.s3.getSignedUrl(operation, __assign({}, params, { Bucket: FileManagerService.BUCKET, Key: file.path, VersionId: file.version }));
                            })];
                }
            });
        });
    };
    FileManagerService.prototype.deleteFiles = function () {
        var files = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            files[_i] = arguments[_i];
        }
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2, Promise.all(files.map(function (file) { return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            return [2, this.httpRest
                                    ._request(HTTP_METHOD.DELETE, ApiRoutePlurality.SINGULAR, FILE_SCHEMA_ROUTE, "fileEntity/" + file.path)
                                    .toPromise()];
                        });
                    }); }))];
            });
        });
    };
    FileManagerService.prototype.uploadFiles = function (storedType, entity) {
        var files = [];
        for (var _i = 2; _i < arguments.length; _i++) {
            files[_i - 2] = arguments[_i];
        }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this._uploadFiles.apply(this, [storedType, entity, false].concat(files))];
            });
        });
    };
    FileManagerService.prototype.uploadFilesQuiet = function (storedType, entity) {
        var files = [];
        for (var _i = 2; _i < arguments.length; _i++) {
            files[_i - 2] = arguments[_i];
        }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this._uploadFiles.apply(this, [storedType, entity, true].concat(files))];
            });
        });
    };
    FileManagerService.prototype._uploadFiles = function (storedType, entity, quiet) {
        if (quiet === void 0) { quiet = false; }
        var files = [];
        for (var _i = 3; _i < arguments.length; _i++) {
            files[_i - 3] = arguments[_i];
        }
        return __awaiter(this, void 0, void 0, function () {
            var _a, uploadingFiles;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4, Promise.all(files.map(function (file) { return __awaiter(_this, void 0, void 0, function () {
                            var path, uploadingFile;
                            var _this = this;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4, this.getUploadFilename(storedType, entity, file)];
                                    case 1:
                                        path = _a.sent();
                                        return [4, this.signFiles({
                                                file: file,
                                                state: FileStateType.WAITING,
                                                path: path,
                                                md5: util.crypto.md5(file, 'hex'),
                                                size: file.size,
                                                mimeType: file.type,
                                                progress: 0,
                                                storedType: storedType,
                                                entity: entity,
                                                quiet: quiet,
                                            })];
                                    case 2:
                                        uploadingFile = (_a.sent())[0];
                                        uploadingFile.cancel = function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                                            return [2, this.cancelFiles(uploadingFile)];
                                        }); }); };
                                        uploadingFile.pause = function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                                            return [2, this.pauseFiles(uploadingFile)];
                                        }); }); };
                                        uploadingFile.resume = function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                                            return [2, this.resumeFiles(uploadingFile)];
                                        }); }); };
                                        uploadingFile.resend = function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                                            return [2, this.resendFiles(uploadingFile)];
                                        }); }); };
                                        return [2, uploadingFile];
                                }
                            });
                        }); }))];
                    case 1:
                        uploadingFiles = _b.sent();
                        (_a = this._uploadingFiles).push.apply(_a, uploadingFiles);
                        return [2, Promise.all(uploadingFiles.map(function (uploadingFile) { return __awaiter(_this, void 0, void 0, function () {
                                var _this = this;
                                return __generator(this, function (_a) {
                                    return [2, this.uploadQueue.add(function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                                            return [2, this.uploadFile(uploadingFile)];
                                        }); }); })];
                                });
                            }); }))];
                }
            });
        });
    };
    FileManagerService.prototype.getUploadFilename = function (storedType, entity, file) {
        return __awaiter(this, void 0, void 0, function () {
            var prefix, expectedFilename, path, filename, _a, _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0: return [4, this.getStoragePrefix(storedType, entity)];
                    case 1:
                        prefix = _d.sent();
                        if (FileManagerService.STORED_TYPE_ENTITY_UNIQUE_FILENAME[storedType]) {
                            return [2, prefix];
                        }
                        expectedFilename = slugify(file.name);
                        path = [prefix, expectedFilename].join('/');
                        return [4, this.httpRest
                                ._request(HTTP_METHOD.GET, ApiRoutePlurality.SINGULAR, FILE_SCHEMA_ROUTE, ['uniqueFilename', path].join('/'))
                                .toPromise()];
                    case 2:
                        filename = (_d.sent()).filename;
                        if (!(expectedFilename !== filename)) return [3, 5];
                        return [4, this.getStoragePrefix(storedType, entity)];
                    case 3:
                        path = [_d.sent(), filename].join('/');
                        _b = (_a = this.toastService).show;
                        _c = {
                            type: TOAST_TYPE.WARNING
                        };
                        return [4, this.translate
                                .get('duplicate_filename', {
                                renamed: filename,
                            })
                                .toPromise()];
                    case 4:
                        _b.apply(_a, [(_c.text = _d.sent(),
                                _c)]);
                        _d.label = 5;
                    case 5: return [2, path];
                }
            });
        });
    };
    FileManagerService.prototype.uploadFile = function (uploadingFile) {
        return __awaiter(this, void 0, void 0, function () {
            var file, storedType, entity, fileEntity, storageFile, state, sessionToken, xAmzHeadersCommon, upload, err;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (uploadingFile.state === FileStateType.CANCELLED) {
                            return [2, undefined];
                        }
                        uploadingFile.state = FileStateType.PENDING;
                        this.uploadingFileState.emit(uploadingFile);
                        return [4, this.updateCredentials()];
                    case 1:
                        _a.sent();
                        if (uploadingFile.state === FileStateType.CANCELLED) {
                            return [2, undefined];
                        }
                        file = uploadingFile.file, storedType = uploadingFile.storedType, entity = uploadingFile.entity, fileEntity = __rest(uploadingFile, ["file", "storedType", "entity"]);
                        return [4, this.httpRest
                                ._request(HTTP_METHOD.PUT, ApiRoutePlurality.SINGULAR, FILE_SCHEMA_ROUTE, 'fileEntity', __assign({ storedType: storedType, entity: typeof entity === 'function' ? entity() : entity }, fileEntity))
                                .toPromise()];
                    case 2:
                        storageFile = _a.sent();
                        uploadingFile.fileId = storageFile.id;
                        if (uploadingFile.state === FileStateType.CANCELLED) {
                            return [2, undefined];
                        }
                        state = uploadingFile.state;
                        uploadingFile.state = FileStateType.UPLOADING;
                        this.uploadingFileState.emit(uploadingFile);
                        sessionToken = this.s3.config.credentials.sessionToken;
                        xAmzHeadersCommon = { 'x-amz-security-token': sessionToken };
                        upload = this.evaporate.add({
                            xAmzHeadersCommon: xAmzHeadersCommon,
                            xAmzHeadersAtInitiate: __assign({}, xAmzHeadersCommon, FileManagerService.formatMetadataParams(__assign({ userId: this.authService.user.id.toString() }, ((FileManagerService.STORED_TYPE_METADATA[storedType] &&
                                FileManagerService.STORED_TYPE_METADATA[storedType](entity)) ||
                                {})))),
                            file: file,
                            name: fileEntity.path,
                            contentType: file.type,
                            progress: function (progress, transferStats) {
                                uploadingFile.progress = progress;
                                uploadingFile.transferStats = transferStats;
                                _this.uploadingFileProgress.emit(uploadingFile);
                            },
                        });
                        if (!(state === FileStateType.PAUSED)) return [3, 4];
                        return [4, this.evaporate.pause(upload, { force: true })];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4:
                        err = null;
                        return [4, upload.catch(function (_err) { return (err = _err); })];
                    case 5:
                        _a.sent();
                        if (!(err === 'User aborted the upload')) return [3, 7];
                        return [4, this.cancelFiles(uploadingFile)];
                    case 6:
                        _a.sent();
                        return [2, uploadingFile];
                    case 7:
                        uploadingFile.state = storageFile.state = err ? FileStateType.ERROR : FileStateType.DONE;
                        this.uploadingFileState.emit(uploadingFile);
                        return [4, this.httpRest
                                .post(FILE_SCHEMA_ROUTE, {
                                id: storageFile.id,
                                state: storageFile.state,
                            })
                                .toPromise()];
                    case 8:
                        _a.sent();
                        if (err) {
                            throw err;
                        }
                        return [2, uploadingFile];
                }
            });
        });
    };
    FileManagerService.prototype.headFile = function (storedType, entity, filename, versionId) {
        return __awaiter(this, void 0, void 0, function () {
            var key, _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _b = (_a = FileManagerService).buildPath;
                        return [4, this.getStoragePrefix(storedType, entity)];
                    case 1:
                        key = _b.apply(_a, [_c.sent(), filename]);
                        return [4, this.updateCredentials()];
                    case 2:
                        _c.sent();
                        return [2, this.s3
                                .headObject({
                                Bucket: FileManagerService.BUCKET,
                                Key: key,
                                VersionId: versionId || undefined,
                            })
                                .promise()];
                }
            });
        });
    };
    FileManagerService.prototype.getFile = function (storedType, entity, filename, versionId) {
        return __awaiter(this, void 0, void 0, function () {
            var key, _a, _b, Body;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _b = (_a = FileManagerService).buildPath;
                        return [4, this.getStoragePrefix(storedType, entity)];
                    case 1:
                        key = _b.apply(_a, [_c.sent(), filename]);
                        return [4, this.updateCredentials()];
                    case 2:
                        _c.sent();
                        return [4, this.s3
                                .getObject({
                                Bucket: FileManagerService.BUCKET,
                                Key: key,
                                VersionId: versionId || undefined,
                            })
                                .promise()];
                    case 3:
                        Body = (_c.sent()).Body;
                        return [2, Body.toString('utf8')];
                }
            });
        });
    };
    FileManagerService.prototype.pauseFiles = function () {
        var uploadingFiles = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            uploadingFiles[_i] = arguments[_i];
        }
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, Promise.all(uploadingFiles.map(function (uploadingFile) { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4, this.evaporate
                                            .pause([FileManagerService.BUCKET, uploadingFile.path].join('/'), {
                                            force: true,
                                        })
                                            .catch(noop)];
                                    case 1:
                                        _a.sent();
                                        if (uploadingFile.progress < 1) {
                                            uploadingFile.state = FileStateType.PAUSED;
                                            this.uploadingFileState.emit(uploadingFile);
                                        }
                                        return [2];
                                }
                            });
                        }); }))];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    FileManagerService.prototype.resumeFiles = function () {
        var uploadingFiles = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            uploadingFiles[_i] = arguments[_i];
        }
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, Promise.all(uploadingFiles.map(function (uploadingFile) { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4, this.evaporate.resume([FileManagerService.BUCKET, uploadingFile.path].join('/')).catch(noop)];
                                    case 1:
                                        _a.sent();
                                        uploadingFile.state = uploadingFile.progress < 1 ? FileStateType.UPLOADING : FileStateType.DONE;
                                        this.uploadingFileState.emit(uploadingFile);
                                        return [2];
                                }
                            });
                        }); }))];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    FileManagerService.prototype.cancelFiles = function () {
        var uploadingFiles = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            uploadingFiles[_i] = arguments[_i];
        }
        return __awaiter(this, void 0, void 0, function () {
            var fileIds;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, Promise.all(uploadingFiles.map(function (uploadingFile) { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!(uploadingFile.state === FileStateType.DONE)) return [3, 2];
                                        return [4, this.deleteFiles(uploadingFile)];
                                    case 1:
                                        _a.sent();
                                        uploadingFile.state = FileStateType.REMOVED;
                                        this.uploadingFileState.emit(uploadingFile);
                                        return [2, undefined];
                                    case 2: return [4, this.evaporate.cancel([FileManagerService.BUCKET, uploadingFile.path].join('/')).catch(noop)];
                                    case 3:
                                        _a.sent();
                                        uploadingFile.state = FileStateType.CANCELLED;
                                        this.uploadingFileState.emit(uploadingFile);
                                        return [2];
                                }
                            });
                        }); }))];
                    case 1:
                        _a.sent();
                        fileIds = uploadingFiles.filter(function (_a) {
                            var fileId = _a.fileId;
                            return fileId;
                        }).map(function (_a) {
                            var fileId = _a.fileId;
                            return fileId;
                        });
                        if (!fileIds.length) return [3, 3];
                        return [4, this.httpRest.deleteIds(FILE_SCHEMA_ROUTE, fileIds).toPromise()];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [2];
                }
            });
        });
    };
    FileManagerService.prototype.resendFiles = function () {
        var uploadingFiles = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            uploadingFiles[_i] = arguments[_i];
        }
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, Promise.all(uploadingFiles.map(function (uploadingFile) { return __awaiter(_this, void 0, void 0, function () {
                            var _this = this;
                            return __generator(this, function (_a) {
                                uploadingFile.state = FileStateType.PENDING;
                                this.uploadingFileState.emit(uploadingFile);
                                return [2, this.uploadQueue.add(function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                                        return [2, this.uploadFile(uploadingFile)];
                                    }); }); })];
                            });
                        }); }))];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    FileManagerService.prototype.userBucketPrefix = function (storedType) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, credentials;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = FileManagerService.STORED_TYPE_RIGHTS[storedType];
                        switch (_a) {
                            case STORAGE_RIGHT.EMPLOYEE: return [3, 1];
                            case STORAGE_RIGHT.CLIENT: return [3, 2];
                            case STORAGE_RIGHT.PUBLIC: return [3, 4];
                        }
                        return [3, 5];
                    case 1: return [2, FileManagerService.buildPath(FileManagerService.PREFIX, FileManagerService.STORED_TYPE_PREFIX[storedType])];
                    case 2: return [4, this.authService.getAwsCredentials()];
                    case 3:
                        credentials = _b.sent();
                        return [2, FileManagerService.buildPath(FileManagerService.PREFIX, credentials.data.IdentityId, FileManagerService.STORED_TYPE_PREFIX[storedType])];
                    case 4: return [2, FileManagerService.buildPath(FileManagerService.PREFIX, 'public', FileManagerService.STORED_TYPE_PREFIX[storedType])];
                    case 5: throw new Error("Unkown stored type " + storedType);
                }
            });
        });
    };
    FileManagerService.prototype.getStoragePrefix = function (storedType, entity) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (typeof entity === 'function') {
                            entity = entity();
                        }
                        if (FileManagerService.STORED_TYPE_ENTITY_PREFIX[storedType] && !entity) {
                            throw new Error("Expected entity for " + storedType + " storage type, got " + entity);
                        }
                        _b = (_a = FileManagerService).buildPath;
                        return [4, this.userBucketPrefix(storedType)];
                    case 1: return [2, _b.apply(_a, [_c.sent(),
                            FileManagerService.STORED_TYPE_ENTITY_PREFIX[storedType](entity),
                            (FileManagerService.STORED_TYPE_ENTITY_UNIQUE_FILENAME[storedType] &&
                                FileManagerService.STORED_TYPE_ENTITY_UNIQUE_FILENAME[storedType](entity)) ||
                                ''])];
                }
            });
        });
    };
    FileManagerService.buildPath = function () {
        var components = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            components[_i] = arguments[_i];
        }
        return components.filter(function (prefix) { return !!prefix; }).join('/');
    };
    FileManagerService.getFilename = function (file) {
        return (file.path || file.Key || file).split('/').reverse()[0];
    };
    FileManagerService.formatMetadataParams = function (metadata) {
        return Object.keys(metadata || {}).reduce(function (_metadata, key) {
            _metadata["x-amz-meta-" + paramCase(key)] = metadata[key].toString();
            return _metadata;
        }, {});
    };
    FileManagerService.parseMetadataParams = function (metadata) {
        return Object.keys(metadata || {}).reduce(function (_metadata, key) {
            var value = metadata[key];
            var valueNumber = Number(value);
            _metadata[camelCase(key.replace(/^x-amz-meta-/, ''))] = isNaN(valueNumber) ? value : valueNumber;
            return _metadata;
        }, {});
    };
    Object.defineProperty(FileManagerService.prototype, "uploadingFiles", {
        get: function () {
            return this._uploadingFiles.filter(function (file) { return !file.quiet; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FileManagerService.prototype, "uploadingFilesRunning", {
        get: function () {
            return this.uploadingFiles.filter(function (file) { return FileManagerService.FILE_STATE_RUNNING.includes(file.state); });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FileManagerService.prototype, "uploadingFilesOver", {
        get: function () {
            return this.uploadingFiles.filter(function (file) { return FileManagerService.FILE_STATE_OVER.includes(file.state); });
        },
        enumerable: true,
        configurable: true
    });
    FileManagerService.prototype.clearFilesOver = function () {
        var _a;
        var files = this.uploadingFilesRunning;
        var clearedFilesCount = this._uploadingFiles.length - files.length;
        this._uploadingFiles.length = 0;
        (_a = this._uploadingFiles).push.apply(_a, files);
        if (clearedFilesCount) {
            this.uploadingFileState.emit();
        }
        return clearedFilesCount;
    };
    FileManagerService.REGION = publicConfig.aws.storage.region || publicConfig.aws.region;
    FileManagerService.BUCKET = publicConfig.aws.storage.bucket;
    FileManagerService.PREFIX = publicConfig.aws.storage.prefix;
    FileManagerService.STORED_TYPE_ENTITY_ID = (_a = {},
        _a[STORED_TYPE.WIKI_PAGE] = function (wikiPage) {
            return (typeof wikiPage === 'function' ? wikiPage() : wikiPage).id;
        },
        _a[STORED_TYPE.WIKI_ATTACHMENT] = function (wikiAttachment) {
            return (typeof wikiAttachment === 'function' ? wikiAttachment() : wikiAttachment).wikiPageId;
        },
        _a[STORED_TYPE.CUSTOMER_REQUEST_FILE] = function (customerRequestFile) {
            return (typeof customerRequestFile === 'function' ? customerRequestFile() : customerRequestFile).customerRequestId;
        },
        _a[STORED_TYPE.TASK_FILE] = function (taskFile) {
            return (typeof taskFile === 'function' ? taskFile() : taskFile).taskId;
        },
        _a[STORED_TYPE.IDEA_FILE] = function (ideaFile) {
            return (typeof ideaFile === 'function' ? ideaFile() : ideaFile).ideaId;
        },
        _a);
    FileManagerService.STORED_TYPE_ENTITY_PREFIX = (_b = {},
        _b[STORED_TYPE.WIKI_PAGE] = function (wikiPage) {
            return (typeof wikiPage === 'function' ? wikiPage() : wikiPage).hash;
        },
        _b[STORED_TYPE.WIKI_ATTACHMENT] = function (wikiAttachment) {
            return (typeof wikiAttachment === 'function' ? wikiAttachment() : wikiAttachment).wikiPage.hash;
        },
        _b[STORED_TYPE.CUSTOMER_REQUEST_FILE] = function (customerRequestFile) {
            return (typeof customerRequestFile === 'function'
                ? customerRequestFile()
                : customerRequestFile).customerRequestId.toString();
        },
        _b[STORED_TYPE.TASK_FILE] = function (taskFile) {
            return (typeof taskFile === 'function' ? taskFile() : taskFile).task.code;
        },
        _b[STORED_TYPE.IDEA_FILE] = function (ideaFile) {
            return (typeof ideaFile === 'function' ? ideaFile() : ideaFile).ideaId.toString();
        },
        _b);
    FileManagerService.STORED_TYPE_ENTITY_UNIQUE_FILENAME = (_c = {},
        _c[STORED_TYPE.WIKI_PAGE] = function (wikiPage) { return 'content.html'; },
        _c);
    FileManagerService.STORED_TYPE_METADATA = (_d = {},
        _d[STORED_TYPE.WIKI_ATTACHMENT] = function (wikiAttachment) { return ({
            wikiPageId: (typeof wikiAttachment === 'function' ? wikiAttachment() : wikiAttachment).wikiPageId,
        }); },
        _d[STORED_TYPE.CUSTOMER_REQUEST_FILE] = function (customerRequestFile) { return ({
            customerRequestId: (typeof customerRequestFile === 'function' ? customerRequestFile() : customerRequestFile)
                .customerRequestId,
        }); },
        _d[STORED_TYPE.TASK_FILE] = function (taskFile) {
            var task = (typeof taskFile === 'function' ? taskFile() : taskFile).task;
            return { taskId: task.id, clientId: task.project.clientId };
        },
        _d);
    FileManagerService.STORED_TYPE_PREFIX = (_e = {},
        _e[STORED_TYPE.WIKI_PAGE] = 'wiki_page',
        _e[STORED_TYPE.WIKI_ATTACHMENT] = 'wiki_attachment',
        _e[STORED_TYPE.CUSTOMER_REQUEST_FILE] = 'customer_request',
        _e[STORED_TYPE.TASK_FILE] = 'task',
        _e[STORED_TYPE.IDEA_FILE] = 'idea',
        _e);
    FileManagerService.STORED_TYPE_RIGHTS = (_f = {},
        _f[STORED_TYPE.WIKI_PAGE] = STORAGE_RIGHT.EMPLOYEE,
        _f[STORED_TYPE.WIKI_ATTACHMENT] = STORAGE_RIGHT.EMPLOYEE,
        _f[STORED_TYPE.CUSTOMER_REQUEST_FILE] = STORAGE_RIGHT.CLIENT,
        _f[STORED_TYPE.TASK_FILE] = STORAGE_RIGHT.EMPLOYEE,
        _f[STORED_TYPE.IDEA_FILE] = STORAGE_RIGHT.EMPLOYEE,
        _f);
    FileManagerService.MAX_CONCURRENT_UPLOADS = 3;
    FileManagerService.FILE_STATE_RUNNING = [
        FileStateType.WAITING,
        FileStateType.PENDING,
        FileStateType.UPLOADING,
        FileStateType.PAUSED,
    ];
    FileManagerService.FILE_STATE_OVER = [
        FileStateType.DONE,
        FileStateType.CANCELLED,
        FileStateType.ERROR,
        FileStateType.REMOVED,
    ];
    FileManagerService.ngInjectableDef = i0.defineInjectable({ factory: function FileManagerService_Factory() { return new FileManagerService(i0.inject(i1.AuthService), i0.inject(i2.HttpRestService), i0.inject(i3.ToastService), i0.inject(i4.TranslateService), i0.inject(i5.MomentService)); }, token: FileManagerService, providedIn: "root" });
    return FileManagerService;
}());
export { FileManagerService };
