import {AfterViewInit, Directive, ElementRef, HostListener, NgZone} from '@angular/core';

@Directive({
    selector: '[appMovable]',
})
export class MovableDirective implements AfterViewInit {
    public el: HTMLElement;
    public handle: HTMLElement;

    private diffY: number;
    private diffX: number;
    private elmHeight: number;
    private elmWidth: number;
    private containerHeight: number;
    private containerWidth: number;
    private isMouseDown = false;

    public constructor(private readonly zone: NgZone, public element: ElementRef) {
        this.el = element.nativeElement;
    }

    public ngAfterViewInit() {
        this.handle = this.el.querySelector('.movable-handle') as HTMLElement;
        // force element aboslute position
        this.el.style.position = 'absolute';
        this.handle.classList.add('c-grab', 'user-select-text');
    }

    @HostListener('mousedown', ['$event'])
    public mouseDown(e: MouseEvent) {
        if (!(e.target as HTMLElement).closest('.movable-handle')) {
            return;
        }
        this.zone.runOutsideAngular(() => {
            this.isMouseDown = true;
            // get initial mousedown coordinated
            const mouseY = e.clientY;
            const mouseX = e.clientX;

            // this.el for element bounding
            const boundingElement = this.handle;

            // get element top and left positions
            const elmY = this.el.offsetTop;
            const elmX = this.el.offsetLeft;

            // get elm dimensions
            this.elmWidth = boundingElement.offsetWidth;
            this.elmHeight = boundingElement.offsetHeight;

            // get container dimensions
            const container = this.el.offsetParent as HTMLElement;
            this.containerWidth = container.offsetWidth;
            this.containerHeight = container.offsetHeight;

            // get diff from (0,0) to mousedown point
            this.diffY = mouseY - elmY;
            this.diffX = mouseX - elmX;
        });
    }

    @HostListener('document:mousemove', ['$event'])
    public mouseMove(e: MouseEvent) {
        if (!this.isMouseDown) {
            return;
        }
        this.zone.runOutsideAngular(() => {
            const elm = this.el;
            // get new mouse coordinates
            const newMouseY = e.clientY;
            const newMouseX = e.clientX;

            // calc new top, left pos of elm
            let newElmTop = newMouseY - this.diffY;
            let newElmLeft = newMouseX - this.diffX;

            // calc new bottom, right pos of elm
            const newElmBottom = newElmTop + this.elmHeight;
            const newElmRight = newElmLeft + this.elmWidth;

            if (
                newElmTop < 0 ||
                newElmLeft < 0 ||
                newElmTop + this.elmHeight > this.containerHeight ||
                newElmLeft + this.elmWidth > this.containerWidth
            ) {
                // if elm is being dragged off top of the container...
                if (newElmTop < 0) {
                    newElmTop = 0;
                }

                // if elm is being dragged off left of the container...
                if (newElmLeft < 0) {
                    newElmLeft = 0;
                }

                // if elm is being dragged off bottom of the container...
                if (newElmBottom > this.containerHeight) {
                    newElmTop = this.containerHeight - this.elmHeight;
                }

                // if elm is being dragged off right of the container...
                if (newElmRight > this.containerWidth) {
                    newElmLeft = this.containerWidth - this.elmWidth;
                }
            }

            MovableDirective.moveElm(elm, newElmTop, newElmLeft);
        });
    }

    public static moveElm(elm: HTMLElement, yPos: number, xPos: number) {
        elm.style.top = `${yPos}px`;
        elm.style.left = `${xPos}px`;
    }

    @HostListener('document:mouseup', ['$event'])
    public mouseUp() {
        this.isMouseDown = false;
    }
}
