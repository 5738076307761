var _a;
import moment from 'moment';
import semVer from 'semver';
import { ProjectMemberType, ProjectStatusType, TaskResolution, TaskStatusType, TaskType } from './schema-static';
import { TASK_FIELD } from './schema/public/Tasks';
export var PROJECT_STATUS_SORT_ORDER = [ProjectStatusType.OPEN, ProjectStatusType.WAITING, ProjectStatusType.CLOSE];
export var PROJECT_STATUS_SORT_FUNCTION = function (p1, p2) {
    if (p1.status !== p2.status) {
        return PROJECT_STATUS_SORT_ORDER.indexOf(p1.status) - PROJECT_STATUS_SORT_ORDER.indexOf(p2.status);
    }
    return p1.obs.localeCompare(p2.obs);
};
export var PROJECT_SORT_FUNCTION = function (p1, p2) {
    return p1.obs.localeCompare(p2.obs);
};
export var CLIENT_SORT_FUNCTION = function (c1, c2) {
    var _a = [c1, c2].map(function (client) { return (client.user && client.user.name) || ''; }), u1 = _a[0], u2 = _a[1];
    return u1.localeCompare(u2);
};
export var EMPLOYEE_SORT_FUNCTION = function (e1, e2) {
    var _a = [e1, e2].map(function (employee) { return (employee.user && employee.user.name) || ''; }), u1 = _a[0], u2 = _a[1];
    return u1.localeCompare(u2);
};
export var PROJECT_MEMBER_SORT_FUNCTION = function (m1, m2) {
    if (m1.type !== m2.type) {
        return m1.type === ProjectMemberType.MANAGER ? -1 : 1;
    }
    var _a = [m1, m2].map(function (_a) {
        var employee = _a.employee;
        return employee;
    }), e1 = _a[0], e2 = _a[1];
    return EMPLOYEE_SORT_FUNCTION(e1, e2);
};
export var CUSTOMER_CONTACT_SORT_FUNCTION = function (c1, C2) {
    var _a = [c1, C2].map(function (_a) {
        var employee = _a.employee;
        return employee;
    }), e1 = _a[0], e2 = _a[1];
    return EMPLOYEE_SORT_FUNCTION(e1, e2);
};
export var TASK_TYPE_SORT_ORDER = [TaskType.BUG, TaskType.TASK];
export var TASK_STATUS_SORT_ORDER = [
    TaskStatusType.INPROGRESS,
    TaskStatusType.TODO,
    TaskStatusType.BACKLOG,
    TaskStatusType.REVIEWING,
    TaskStatusType.REVIEW,
];
export var TASK_RESOLUTION_SORT_ORDER = [
    TaskResolution.FIXED,
    TaskResolution.DUPLICATE,
    TaskResolution.CANT_REPRODUCE,
    TaskResolution.WONT_FIX,
];
export var TASK_SORT_FUNCTIONS = (_a = {},
    _a[TASK_FIELD.urgent] = function (t1, t2) { return (t1.urgent !== t2.urgent ? +t2.urgent - +t1.urgent : 0); },
    _a[TASK_FIELD.type] = function (t1, t2) {
        return TASK_TYPE_SORT_ORDER.indexOf(t1.type) - TASK_TYPE_SORT_ORDER.indexOf(t2.type);
    },
    _a[TASK_FIELD.status] = function (t1, t2) {
        return TASK_STATUS_SORT_ORDER.indexOf(t1.status) - TASK_STATUS_SORT_ORDER.indexOf(t2.status);
    },
    _a[TASK_FIELD.resolution] = function (t1, t2) {
        return TASK_RESOLUTION_SORT_ORDER.indexOf(t1.resolution) - TASK_RESOLUTION_SORT_ORDER.indexOf(t2.resolution);
    },
    _a[TASK_FIELD.endDate] = function (t1, t2) {
        return moment(t1._metadata.endDate).isAfter(t2._metadata.endDate) ? 1 : -1;
    },
    _a[TASK_FIELD.createdAt] = function (t1, t2) { return (moment(t1.createdAt).isAfter(t2.createdAt) ? 1 : -1); },
    _a[TASK_FIELD.code] = function (t1, t2) { return t1.code.localeCompare(t2.code); },
    _a[TASK_FIELD.targetReleaseId] = function (t1, t2) {
        if (t1.targetRelease && !t2.targetRelease) {
            return -1;
        }
        else if (!t1.targetRelease && t2.targetRelease) {
            return 1;
        }
        else if (!t1.targetRelease && !t2.targetRelease) {
            return 0;
        }
        else {
            return semVer.compare(t2.targetRelease.version, t1.targetRelease.version);
        }
    },
    _a);
