import moment from 'moment';
import {ISchemaRoute, TodoStatusType} from '../../schema-static';
import {IEmployee} from './Employees';
import {ITodoCategory} from './TodoCategory';

export enum TODO_FIELD {
    id = 'id',
    createdAt = 'createdAt',
    updatedAt = 'updatedAt',
    text = 'text',
    status = 'status',
    dateAction = 'dateAction',
    todoCategoryId = 'todoCategoryId',
    employeeId = 'employeeId',
}

export interface ITodo {
    id?: number;
    createdAt?: moment.Moment;
    updatedAt?: moment.Moment;
    text?: string;
    status: TodoStatusType;
    dateAction?: moment.Moment;
    todoCategoryId: number;
    employeeId: number;
}

export interface ITodoComplete {
    employee?: Partial<IEmployee>[];
    todoCategory?: Partial<ITodoCategory>;
}

const TODO_SCHEMA_ROUTE: ISchemaRoute = {
    tableName: 'todos',
    singularRoute: 'todo',
    pluralRoute: 'todos',
};

export {TODO_SCHEMA_ROUTE};
