import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {ClrForm} from '@clr/angular';
import {FormControl, FormGroup} from 'ngx-strongly-typed-forms';
import {ApiRoutePlurality, HTTP_METHOD} from '../../../../../defs/schema-static';
import {IClient} from '../../../../../defs/schema/public/Clients';
import {
    CUSTOMER_CONTACT_FIELD,
    CUSTOMER_CONTACT_SCHEMA_ROUTE,
    ICustomerContact,
} from '../../../../../defs/schema/public/CustomerContacts';
import {EMPLOYEE_FIELD, EMPLOYEE_SCHEMA_ROUTE, IEmployee} from '../../../../../defs/schema/public/Employees';
import {USER_FIELD} from '../../../../../defs/schema/public/Users';
import {CUSTOMER_CONTACT_SORT_FUNCTION, EMPLOYEE_SORT_FUNCTION} from '../../../../../defs/sorters';
import {EMPLOYEE_MEMBER_TYPE} from '../../shared/editable-employee-label/editable-employee-label.component';
import {HttpRestService} from '../../shared/http-rest/http-rest.service';

enum CUSTOMER_CONTACTS_FORM_KEYS {
    contacts = 'contacts',
}

interface ICustomerContactsFormValues {
    contacts: Partial<ICustomerContact>[];
}

@Component({
    selector: 'app-client-wizard-customer-contacts',
    templateUrl: './client-wizard-customer-contacts.component.html',
})
export class ClientWizardCustomerContactsComponent implements OnInit, OnChanges {
    public readonly form = new FormGroup<ICustomerContactsFormValues>({
        [CUSTOMER_CONTACTS_FORM_KEYS.contacts]: new FormControl<Partial<ICustomerContact>[]>(),
    });

    @ViewChild(ClrForm) private readonly clrForm: ClrForm;

    @Input() public employees: Partial<IEmployee>[] = [];
    @Input() public client: Partial<IClient>;

    @Input() public contacts: Partial<ICustomerContact>[];
    @Input() public dropdownPosition = 'bottom';

    public constructor(private readonly httpRest: HttpRestService) {}

    public ngOnInit() {
        if (this.contacts) {
            this.form.patchValue({contacts: this.contacts});
        }

        if (!this.employees || !this.employees.length) {
            (async () => this.getEmployees())();
        } else {
            this.setContacts();
        }
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.contacts) {
            this.form.patchValue({contacts: this.contacts});
            this.setContacts();
        }
    }

    public async getEmployees(): Promise<void> {
        this.employees = (await this.httpRest
            ._request<IEmployee[]>(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, EMPLOYEE_SCHEMA_ROUTE, 'project')
            .toPromise()).sort(EMPLOYEE_SORT_FUNCTION);

        this.setContacts();
    }

    public setContacts() {
        const existingIds = (this.form.value.contacts || []).map((contact) => contact.employee.id);

        this.contacts = [
            ...(this.form.value.contacts || []),
            ...this.employees
                .filter((employee) => !existingIds.includes(employee.id))
                .map(
                    (employee) =>
                        ({
                            employee,
                            employeeId: employee.id,
                        } as Partial<ICustomerContact>)
                ),
        ];
    }

    public removeContact(contact: ICustomerContact) {
        const {contacts} = this.form.value;
        contacts.splice(contacts.indexOf(contact), 1);
        this.form.patchValue({contacts});
    }

    public updateContact() {
        const {contacts} = this.form.value;
        this.form.patchValue({contacts});
    }

    public async submit(): Promise<ICustomerContact[]> {
        if (!this.client || !this.client.id) {
            throw new Error("missing client id, can't submit ");
        }

        if (!this.form.valid) {
            this.clrForm.markAsDirty();

            return undefined;
        }

        const {contacts} = this.form.value;

        const customerContacts = await Promise.all(
            (contacts || []).map(async (contact) =>
                this.httpRest
                    .put<ICustomerContact>(CUSTOMER_CONTACT_SCHEMA_ROUTE, {
                        clientId: this.client.id,
                        employeeId: contact.employee.id,
                    })
                    .toPromise()
            )
        );

        return customerContacts
            .map((contact) => ({
                ...contact,
                employee: this.employees.find(({id}) => contact.employeeId === id),
            }))
            .sort(CUSTOMER_CONTACT_SORT_FUNCTION);
    }

    public readonly CUSTOMER_CONTACTS_FORM_KEYS = CUSTOMER_CONTACTS_FORM_KEYS;
    public readonly EMPLOYEE_MEMBER_TYPE = EMPLOYEE_MEMBER_TYPE;

    public readonly CUSTOMER_CONTACT_FIELD = CUSTOMER_CONTACT_FIELD;
    public readonly EMPLOYEE_FIELD = EMPLOYEE_FIELD;
    public readonly USER_FIELD = USER_FIELD;
}
