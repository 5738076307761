var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { Validators } from '@angular/forms';
import { ClrForm } from '@clr/angular';
import { FormControl, FormGroup } from 'ngx-strongly-typed-forms';
import { getWorkingHours } from '../../../../../defs/businessRules';
import { DECIMAL_RADIX, ISO_DATE_FORMAT, MAX_LENGTH_DEFAULT, MilestonesType } from '../../../../../defs/schema-static';
import { FormsAddMilestoneService, MILESTONE_FORM_KEYS } from '../../forms/add-milestone.service';
import { requiredTrimValidator } from '../../forms/validators/required-trim.validator';
import { ConfigService } from '../../shared/config/config.service';
import { MomentService } from '../../shared/moment/moment.service';
var MilestoneDetailsComponent = (function () {
    function MilestoneDetailsComponent(configService, formsAddMilestoneService, momentService) {
        var _this = this;
        this.configService = configService;
        this.formsAddMilestoneService = formsAddMilestoneService;
        this.momentService = momentService;
        this.form = new FormGroup({
            description: new FormControl(),
            milestone: new FormControl(null, [requiredTrimValidator(), Validators.maxLength(MAX_LENGTH_DEFAULT)]),
            target: new FormControl(null, requiredTrimValidator()),
            startDate: new FormControl(null, Validators.required),
            startTime: new FormControl(null, Validators.required),
            endDate: new FormControl(null, Validators.required),
            endTime: new FormControl(null, Validators.required),
            version: new FormControl(),
        });
        this.errorsDates = ['error_date_begin_only', 'error_date'];
        this.errorDates = 0;
        this.checkDates = function () {
            if (_this.beginDateInputElement && _this.beginDateInputElement.nativeElement.value === '') {
                _this.form.patchValue({ startDate: null });
                _this.beginDate = null;
            }
            if (_this.endDateInputElement && _this.endDateInputElement.nativeElement.value === '') {
                _this.form.patchValue({ endDate: null });
                _this.endDate = null;
            }
            if (_this.targetDateInputElement && _this.targetDateInputElement.nativeElement.value === '') {
                _this.form.patchValue({ target: null });
                _this.targetDate = null;
            }
            return _this.formsAddMilestoneService.checkDates(_this.form, _this.beginDate, _this.endDate, _this.targetDate, _this.type);
        };
        this.MILESTONE_FORM_KEYS = MILESTONE_FORM_KEYS;
        this.MILESTONE_TYPES = MilestonesType;
        this.MAX_LENGTH_DEFAULT = MAX_LENGTH_DEFAULT;
    }
    MilestoneDetailsComponent.prototype.ngOnChanges = function (changes) {
        if (changes.type) {
            this.onLoad();
        }
    };
    MilestoneDetailsComponent.prototype.onLoad = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var _now, config, workingHours, configStartTime, configEndTime, target, startDate, startTime, endDate, endTime;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _now = this.momentService.moment();
                        return [4, this.configService.get()];
                    case 1:
                        config = _a.sent();
                        workingHours = getWorkingHours(config);
                        configStartTime = workingHours.start.split(':').map(function (e) { return parseInt(e, DECIMAL_RADIX); });
                        configEndTime = workingHours.end.split(':').map(function (e) { return parseInt(e, DECIMAL_RADIX); });
                        target = _now.format(ISO_DATE_FORMAT);
                        startDate = _now.format(ISO_DATE_FORMAT);
                        startTime = _now
                            .set({
                            hour: configStartTime[0],
                            minute: configStartTime[1],
                        })
                            .format(FormsAddMilestoneService.HOUR_MINUTE_TIME_FORMAT);
                        endDate = _now.format(ISO_DATE_FORMAT);
                        endTime = _now
                            .set({
                            hour: configEndTime[0],
                            minute: configEndTime[1],
                        })
                            .format(FormsAddMilestoneService.HOUR_MINUTE_TIME_FORMAT);
                        if (params && this.form.pristine) {
                            this.type = params.type;
                            this.beginDate =
                                params.startDate && this.momentService.moment(params.startDate).isValid()
                                    ? this.momentService.moment(params.startDate).toDate()
                                    : new Date(startDate);
                            this.endDate =
                                params.endDate && this.momentService.moment(params.endDate).isValid()
                                    ? this.momentService.moment(params.endDate).toDate()
                                    : new Date(endDate);
                            this.targetDate =
                                params.target && this.momentService.moment(params.target).isValid()
                                    ? this.momentService.moment(params.target).toDate()
                                    : new Date(target);
                            target = this.momentService.moment(this.targetDate).format('YYYY-MM-DD');
                            startDate = this.momentService.moment(this.beginDate).format('YYYY-MM-DD');
                            startTime = params.startTime ? params.startTime : startTime;
                            endDate = this.momentService.moment(this.endDate).format('YYYY-MM-DD');
                            endTime = params.endTime ? params.endTime : endTime;
                        }
                        this.form.patchValue({
                            description: params && this.form.pristine ? params.description : this.form.value.description,
                            milestone: params && this.form.pristine ? params.milestone : this.form.value.milestone,
                            version: params && this.form.pristine ? params.version : this.form.value.version,
                        });
                        if (this.form.pristine) {
                            this.form.patchValue({
                                startDate: startDate,
                                endDate: endDate,
                                target: target,
                            });
                            if (this.targetDateInputElement) {
                                this.targetDateInputElement.nativeElement.value = target;
                            }
                        }
                        this.toggle();
                        requestAnimationFrame(function () {
                            _this.milestoneName.nativeElement.focus();
                        });
                        if (this.form.pristine) {
                            setTimeout(function () {
                                _this.form.patchValue({
                                    startTime: startTime,
                                    endTime: endTime,
                                });
                            }, 50);
                        }
                        return [2];
                }
            });
        });
    };
    MilestoneDetailsComponent.prototype.submit = function () {
        if (!this.form.valid) {
            this.clrForm.markAsDirty();
            return undefined;
        }
        return this.form;
    };
    MilestoneDetailsComponent.prototype.toggle = function () {
        this.errorDates = 1;
        if (MilestonesType.REMINDER === this.type) {
            this.errorDates = 0;
        }
        FormsAddMilestoneService.toggle(this.form, this.type);
    };
    return MilestoneDetailsComponent;
}());
export { MilestoneDetailsComponent };
