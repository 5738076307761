import {Component, Input} from '@angular/core';
import {Router} from '@angular/router';
import {IEmployee} from '../../../../defs/schema/public/Employees';
import {AUTH_MODULE_ROUTE_PATH, noop} from '../app-static';
import {AuthService} from '../auth/auth.service';
import {ModalSimpleComponent} from '../modal-simple/modal-simple.component';
import {ModalSimpleService} from '../modal-simple/modal-simple.service';

@Component({
    selector: 'app-user-view',
    templateUrl: './user-view.component.html',
    styleUrls: ['./user-view.component.scss'],
})
export class UserViewComponent {
    @Input() public isCollapsed: boolean;

    public showSettings = false;

    public constructor(
        private readonly modalSimpleService: ModalSimpleService,
        public readonly authService: AuthService,
        private readonly router: Router
    ) {}

    public async logout() {
        this.modalSimpleService
            .open(ModalSimpleComponent, {
                title: 'logout_confirm',
                contentI18n: 'swal_logout',
                ok: {
                    i18n: 'logout',
                    class: 'btn-danger',
                },
            })
            .subscribe((closed) => {
                if (closed.result) {
                    this.doLogout();
                }
            }, noop);
    }

    public async doLogout() {
        return this.router.navigate([`/${AUTH_MODULE_ROUTE_PATH.AUTH}/${AUTH_MODULE_ROUTE_PATH.LOG_OUT}`]);
    }
}
