import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {getTimeString} from '../../../../../defs/schema/public/Times';
import {MomentService} from '../moment/moment.service';

@Pipe({
    name: 'momentDuration',
})
export class MomentDurationPipe implements PipeTransform {
    public constructor(private readonly translate: TranslateService, private readonly momentService: MomentService) {}

    public transform(value: string, short = false, maxComponents = Number.MAX_SAFE_INTEGER): string {
        const diff = this.momentService.moment.duration(
            this.momentService.moment(value).diff(this.momentService.moment())
        );
        if (!short) {
            return diff.locale(this.translate.currentLang).humanize(true);
        }

        return getTimeString(diff, maxComponents, true);
    }
}
