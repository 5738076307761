import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SETTING_LANGUAGE_VALUES} from '../../../../../defs/schema/public/Users';

@Component({
    selector: 'app-unauthorized',
    templateUrl: './unauthorized.component.html',
    styleUrls: ['./unauthorized.component.scss'],
})
export class UnauthorizedComponent {
    public constructor(private readonly translate: TranslateService) {
        this.getUnauthMessage();
    }

    public getUnauthMessage() {
        if (!this.translate.currentLang) {
            const browserLang = this.translate.getBrowserLang().toUpperCase() as SETTING_LANGUAGE_VALUES;
            const lang = Object.values(SETTING_LANGUAGE_VALUES).includes(browserLang)
                ? browserLang
                : SETTING_LANGUAGE_VALUES.EN;

            this.translate.use(lang);
        }
    }
}
