<li class="list-group-item">
    <div class="item">
        <app-long-avatar
            [code]="meeting?.client?.user.code || meeting?.project.code"
            [bgColor]="meeting?.client?.user.color || meeting?.project.color"
        >
        </app-long-avatar>
        <span class="event-name"> {{ meeting?.client?.user.name || meeting?.project.obs }}</span>
        <div class="event-subtitle fck-word-breaker">
            <a
                [queryParams]="{tab: 'milestone', edit: meeting.id}"
                [routerLink]="meeting?.client ? '/client/' + meeting?.client?.id : '/project/' + meeting?.project?.id"
            >
                {{ meeting.obs }}
            </a>
        </div>
        <div class="event-detail" [title]="meeting.beginDate | momentDuration">
            <i>{{ meeting.beginDate | momentFormat }}</i>
        </div>
    </div>
    <div class="action">
        <a (click)="show()" class="c-hand" [attr.title]="'meeting_note_create' | translate">
            <clr-icon size="24" shape="compass"></clr-icon>
        </a>
    </div>
</li>

<app-modal [(show)]="showModal" [title]="'confirm_dates' | translate">
    <ng-template appModalBody>
        <span translate>milestone_meeting</span>: {{ meeting.obs }}
        <form id="dashbordReminderForm" clrForm>
            <clr-input-container>
                <label translate>date_begin</label>
                <input clrInput type="time" [(ngModel)]="startTime" name="startTime" required />
            </clr-input-container>
            <clr-input-container>
                <label translate>date_end</label>
                <input clrInput type="time" [(ngModel)]="endTime" name="endTime" required />
            </clr-input-container>
        </form>
    </ng-template>
    <ng-template appModalFooter>
        <button (click)="showModal = false" class="btn btn-secondary" translate>form_cancel</button>
        <button form="dashboardReminderForm" [disabled]="!validate()" (click)="valid()" class="btn btn-success">
            {{ 'save_note' | translate }}
        </button>
    </ng-template>
</app-modal>
