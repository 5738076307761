<div class="container">
    <div class="clr-row">
        <h2 translate>incidents</h2>
        <div class="column clr-col-12">
            <div class="spinner-bg-c-small" style="margin-top: 15em;" *ngIf="pleaseWait">
                <div class="spinner-c"></div>
            </div>
            <input
                class="d-block filter-input"
                type="text"
                [placeholder]="'placeholder_filter' | translate"
                (keyup)="updateFilter($event)"
            />

            <!--
                <ngx-datatable
                    *ngIf="!pleaseWait"
                    class="c-hand"
                    [rows]="displayIncidents"
                    [columnMode]="'force'"
                    [headerHeight]="50"
                    [messages]="{emptyMessage: 'no_data' | translate, totalMessage: 'total'}"
                    (select)="displayDescription($event)"
                    [selectionType]="'single'"
                    [rowHeight]="65"
                    [scrollbarV]="true"
                >
                    <ngx-datatable-column [name]="'table_title' | translate">
                        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                            {{ row.title }}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column [name]="'table_description' | translate">
                        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                            {{ row.description.length > 20 ? row.description.slice(0, 20) + '...' : row.description }}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column [name]="'project' | translate">
                        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                            {{ getProject(row.projectId)?.obs }}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column [name]="'created_at' | translate">
                        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                            {{ row.createdAt | momentFormat: 'L LT' }}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column [name]="'updated_at' | translate">
                        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                            {{ row.updatedAt | momentFormat: 'L LT' }}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column [name]="'table_status' | translate">
                        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                            <span class="{{ row.status.toLowerCase() }}">{{
                                'incident_' + row.status | lowercase | translate
                            }}</span>
                        </ng-template>
                    </ngx-datatable-column>
                </ngx-datatable>
            -->
        </div>
    </div>
</div>

<app-modal [(show)]="displayModal" [title]="selectedIncident?.title">
    <ng-template appModalBody>{{ selectedIncident?.description }}</ng-template>
</app-modal>
