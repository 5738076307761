import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-progress-circle',
    templateUrl: './progress-circle.component.html',
    styleUrls: ['./progress-circle.component.scss'],
})
export class ProgressCircleComponent {
    @Input()
    public progressPercent: number;

    @Input()
    public total: number;

    @Input()
    public part: number;
}
