<div class="column-container py-02">
    <div class="clr-row flex-1">
        <div class="clr-col-12">
            <clr-datagrid appDatagridToggle class="h-100" [clrDgLoading]="!changelogs">
                <clr-dg-column
                    *ngFor="let column of CHANGELOG_COLUMNS"
                    [clrDgField]="column.field"
                    [clrDgSortOrder]="column.order"
                >
                    {{ column.translateKey | translate }}

                    <clr-dg-filter
                        *ngIf="!column.filterType || column.filterType === DATAGRID_FILTER_TYPE.FUZZY"
                        [clrDgFilter]="fuzzyFilter"
                        [(clrDgFilterOpen)]="filterOpen[column.field]"
                    >
                        <shared-fuzzy-datagrid-filter
                            [filterKey]="column.field"
                            [filterOpen]="filterOpen[column.field]"
                            #fuzzyFilter
                        ></shared-fuzzy-datagrid-filter>
                    </clr-dg-filter>

                    <clr-dg-filter
                        *ngIf="column.filterType === DATAGRID_FILTER_TYPE.DATE"
                        [clrDgFilter]="dateFilter"
                        [(clrDgFilterOpen)]="filterOpen[column.field]"
                    >
                        <shared-date-datagrid-filter
                            [filterKey]="column.field"
                            [filterOpen]="filterOpen[column.field]"
                            #dateFilter
                        ></shared-date-datagrid-filter>
                    </clr-dg-filter>
                </clr-dg-column>
                <clr-dg-row *clrDgItems="let changelog of changelogs" [clrDgItem]="changelog">
                    <clr-dg-cell>
                        <a style="color: #0079b8;" class="c-hand " [routerLink]="['/wiki/page', changelog.hash]">
                            {{ changelog.title }}
                        </a>
                    </clr-dg-cell>

                    <clr-dg-cell>
                        <a [routerLink]="['/project', changelog.project.id]"> {{ changelog.project.obs }} </a>
                        <app-long-avatar
                            [code]="changelog.project.code"
                            [bgColor]="changelog.project.color"
                            [type]="LONG_AVATAR_TYPE.BADGE_100"
                        ></app-long-avatar>
                    </clr-dg-cell>

                    <clr-dg-cell>
                        <a [routerLink]="['/employee', changelog.author.id]"> {{ changelog.author.user.name }} </a>
                        <app-long-avatar
                            [code]="changelog.author.user.code"
                            [bgColor]="changelog.author.user.color"
                            [type]="LONG_AVATAR_TYPE.BADGE_100"
                        ></app-long-avatar>
                    </clr-dg-cell>

                    <clr-dg-cell class="monospace-date" [title]="changelog.updatedAt | momentDuration">
                        {{ changelog.updatedAt | momentFormat: 'L LT' }}
                    </clr-dg-cell>
                </clr-dg-row>

                <clr-dg-footer>
                    <span *ngIf="changelogs">{{ 'count_changelogs' | translate: {count: changelogs.length} }}</span>
                </clr-dg-footer>
            </clr-datagrid>
        </div>
    </div>
</div>
