import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {NgForm} from '@angular/forms';
import {ApiRoutePlurality, HTTP_METHOD, STORED_TYPE} from '../../../../../defs/schema-static';
import {CUSTOMER_REQUEST_SCHEMA_ROUTE, ICustomerRequest} from '../../../../../defs/schema/public/CustomerRequests';
import {IProject, PROJECT_SCHEMA_ROUTE} from '../../../../../defs/schema/public/Projects';
import {ICustomerRequestFile} from '../../../../../defs/schema/storage/CustomerRequestFiles';
import {DEFAULT_ACCEPTED_TYPES} from '../../app-static';
import {AuthService} from '../../auth/auth.service';
import {FileManagerDropZoneComponent} from '../../shared/file-manager-drop-zone/file-manager-drop-zone.component';
import {HttpRestService} from '../../shared/http-rest/http-rest.service';

@Component({
    selector: 'app-customer-request',
    templateUrl: './customer-request.component.html',
    styleUrls: ['./customer-request.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CustomerRequestComponent {
    private requests: ICustomerRequest[] = [];
    // private displayRequest: ICustomerRequest[] = [];
    public showNewRequestModal = false;
    public pleaseWait = true;
    public displayModal = false;
    public selectedRequest: ICustomerRequest;
    private projects: IProject[] = [];
    // private readonly requestColumns = [
    //     {name: 'Title', prop: 'title'},
    //     {name: 'Request', prop: 'request'},
    //     {name: 'Description', prop: 'description'},
    //     {name: 'Project', prop: 'project.name'},
    //     {name: 'Creation Date', prop: 'createdAt'},
    //     {name: 'Update Date', prop: 'updatedAt'},
    // ];

    public newRequest: ICustomerRequest;

    @ViewChild(FileManagerDropZoneComponent) public fileInput: FileManagerDropZoneComponent;

    public readonly ACCEPTED_TYPES = DEFAULT_ACCEPTED_TYPES;

    public constructor(private readonly httpRest: HttpRestService, private readonly authService: AuthService) {
        this.httpRest
            ._request<ICustomerRequest[]>(
                HTTP_METHOD.GET,
                ApiRoutePlurality.PLURAL,
                CUSTOMER_REQUEST_SCHEMA_ROUTE,
                'client'
            )
            .subscribe((requests) => {
                this.requests = requests;
                this.pleaseWait = false;
                // this.displayRequest = [...this.requests];
            });

        this.httpRest
            ._request<IProject[]>(
                HTTP_METHOD.GET,
                ApiRoutePlurality.PLURAL,
                PROJECT_SCHEMA_ROUTE,
                `clientLight/${this.authService.user.client.id}`
            )
            .subscribe((projects) => (this.projects = projects));
    }

    protected displayDescription({selected}: {selected: ICustomerRequest[]}) {
        this.selectedRequest = selected[0];
        this.displayModal = true;
    }

    protected getProject(projectId: number): IProject {
        return this.projects.find((p) => p.id === projectId);
    }

    protected updateFilter = (event: Event) => {
        const target = event.target as HTMLInputElement;

        const val = target.value.toLowerCase();

        // this.displayRequest = this.requests.filter((d) => {
        //     return (
        //         d.title.toLowerCase().indexOf(val) !== -1 ||
        //         d.description.toLowerCase().indexOf(val) !== -1 ||
        //         d.request.toLowerCase().indexOf(val) !== -1 ||
        //         d.status.toLowerCase().indexOf(val) !== -1 ||
        //         this.getProject(d.projectId)
        //             .obs.toLowerCase()
        //             .indexOf(val) !== -1 ||
        //         !val
        //     );
        // });
    };

    public readonly getEntity: () => Partial<ICustomerRequestFile> = () => {
        return (
            this.newRequest && {
                customerRequest: HttpRestService.stripDepth(this.newRequest),
                customerRequestId: this.newRequest.id,
            }
        );
    };

    protected onSubmit(f: NgForm) {
        if (!f.valid) {
            return;
        }

        const {title, description, project, request} = f.value;
        this.httpRest
            .put<ICustomerRequest>(CUSTOMER_REQUEST_SCHEMA_ROUTE, {
                title,
                description,
                request,
                clientId: this.authService.user.client.id,
                projectId: project,
            })
            .subscribe(async (_request) => {
                this.requests.push((this.newRequest = _request));
                await this.fileInput.upload();
                this.newRequest = null;

                // this.displayRequest = [...this.requests];
                f.reset();
                this.showNewRequestModal = false;
            });
    }

    public readonly STORED_TYPE = STORED_TYPE;
}
