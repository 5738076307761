import {ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {Validators} from '@angular/forms';
import {ClrForm} from '@clr/angular';
import {NgSelectComponent} from '@ng-select/ng-select';
import {FormControl, FormGroup} from 'ngx-strongly-typed-forms';
import {minutesToWorkingTime, overEstimatedTime} from '../../../../../defs/businessRules';
import {ApiRoutePlurality, HTTP_METHOD, ISO_DATE_FORMAT, PATTERN_TIME, RIGHTS} from '../../../../../defs/schema-static';
import {EMPLOYEE_SCHEMA_ROUTE, IEmployee} from '../../../../../defs/schema/public/Employees';
import {ITime} from '../../../../../defs/schema/public/Times';
import {AuthService} from '../../auth/auth.service';
import {IAddTimeFormModal} from '../../forms/add-time.service';
import {requiredTrimValidator} from '../../forms/validators/required-trim.validator';
import {ConfigService} from '../../shared/config/config.service';
import {HttpRestService} from '../../shared/http-rest/http-rest.service';
import {MomentService} from '../../shared/moment/moment.service';
import {TimerService} from '../../shared/timer/timer.service';

@Component({
    selector: 'app-time-details',
    templateUrl: './time-details.component.html',
    styleUrls: ['./time-details.component.scss'],
})
export class TimeDetailsComponent implements OnInit {
    public readonly form = new FormGroup<Partial<IAddTimeFormModal>>({
        startDate: new FormControl<string>(null, Validators.required),
        time: new FormControl<string>(null, [requiredTrimValidator(), Validators.pattern(PATTERN_TIME)]),
        employeeId: new FormControl<number>(null, Validators.required),
    });

    @ViewChild(ClrForm) public clrForm: ClrForm;
    @ViewChild('ngSelectEmployee') public ngSelectEmployee: NgSelectComponent;
    @ViewChild('beginDateInputElement') public beginDateInputElement: ElementRef;
    @ViewChild('timeInputElement') public timeInputElement: ElementRef;

    @Input() public employees: Partial<IEmployee>[];
    @Input() public times: Partial<ITime>[];

    public beginDate: Date;

    public constructor(
        private readonly authService: AuthService,
        private readonly httpRest: HttpRestService,
        private readonly cdRef: ChangeDetectorRef,
        private readonly configService: ConfigService,
        public timerService: TimerService,
        private readonly momentService: MomentService
    ) {}

    public ngOnInit() {
        if (!this.employees || !this.employees.length) {
            (async () => this.getEmployees())();
        } else if (!this.authService.hasRight(RIGHTS.TIME_UPDATE)) {
            this.employees = this.employees.filter((e) => e.id === this.authService.user.employee.id);
        }
    }

    public async getEmployees(): Promise<void> {
        this.employees = (await this.httpRest
            ._request<IEmployee[]>(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, EMPLOYEE_SCHEMA_ROUTE, 'list')
            .toPromise()).sort((c1, c2) => c1.user.name.localeCompare(c2.user.name));
    }

    public onLoad(params: Partial<IAddTimeFormModal>) {
        if (params) {
            this.beginDate = params.startDate ? this.momentService.moment(params.startDate).toDate() : new Date();

            let time = '1m';
            if (this.timerService.timer.isStarted && !params.timeId) {
                let duration = this.momentService.moment.duration(this.timerService.timer.time);
                if (duration.asSeconds() < 60) {
                    // minimum time of 1 minute
                    duration = this.momentService.moment.duration(1, 'minute');
                } else if (duration.seconds() > 0) {
                    // if the minute started, it counts as a whole one
                    duration.add(1, 'minute');
                }
                time = minutesToWorkingTime(this.configService.config, Math.floor(duration.asMinutes()));
            } else if (params && params.time) {
                time = params.time;
            }

            this.form.patchValue({
                startDate: this.momentService.moment(this.beginDate).format(ISO_DATE_FORMAT),
                employeeId: params.employeeId ? params.employeeId : this.authService.user.employee.id,
                time,
            });
        } else {
            this.beginDate = new Date();
            this.form.patchValue({
                startDate: this.momentService.moment(this.beginDate).format(ISO_DATE_FORMAT),
            });
        }

        requestAnimationFrame(() => {
            this.cdRef.markForCheck();
        });
        this.toggle();
    }

    public filterEmployee(id?: number) {
        this.form.patchValue({
            employeeId: id || null,
        });
        this.setFocus();
    }

    public submit() {
        if (!this.form.valid) {
            return undefined;
        }

        return this.form;
    }

    public checkDates = () => {
        // fix datepicker reset not null
        if (this.beginDateInputElement && this.beginDateInputElement.nativeElement.value === '') {
            this.form.patchValue({startDate: null});
            this.beginDate = null;
        } else {
            this.form.patchValue({
                startDate: this.beginDate ? this.momentService.moment(this.beginDate).format(ISO_DATE_FORMAT) : null,
            });
        }

        return this.form.controls.startDate.valid;
    };

    private toggle() {
        this.form.controls.startDate.enable();
        this.form.controls.time.enable();
        this.form.controls.employeeId.enable();
        this.setFocus();
        setTimeout(() => {
            this.cdRef.markForCheck();
        }, 200);
    }

    private setFocus() {
        requestAnimationFrame(() => {
            if (!this.form.value.employeeId) {
                if (this.ngSelectEmployee) {
                    this.ngSelectEmployee.focus();
                    this.ngSelectEmployee.open();
                }
            } else if (!this.form.value.startDate) {
                if (this.beginDateInputElement) {
                    this.beginDateInputElement.nativeElement.focus();
                }
            } else {
                this.setTimeInputSelectionRange();
            }

            this.cdRef.markForCheck();
        });
    }

    private setTimeInputSelectionRange() {
        if (!this.timeInputElement) {
            return;
        }

        const $timeInput = this.timeInputElement.nativeElement as HTMLInputElement;
        const time = this.form.value.time || '';
        const match = time.match(/(\d+)\w+\s*$/);

        if (match) {
            $timeInput.setSelectionRange(match.index, match.index + match[1].length, 'none');
            $timeInput.focus();
        }
    }

    public checkValidationTime() {
        if (overEstimatedTime(this.configService.config, this.form.value.time)) {
            this.form.controls.time.setErrors({pattern: true});
        }
    }
}
