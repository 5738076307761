var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnDestroy } from '@angular/core';
import { ClrLoadingState } from '@clr/angular';
import { SHORTCUT_LOCAL, ShortcutHandlerService } from '../shared/shortcut-handler/shortcut-handler.service';
import { ModalSimpleService } from './modal-simple.service';
var ModalSimpleComponent = (function () {
    function ModalSimpleComponent(shortcutHandlerService, modalSimpleService) {
        this.shortcutHandlerService = shortcutHandlerService;
        this.modalSimpleService = modalSimpleService;
        this.size = 'md';
        this.ok = {
            i18n: 'form_validate',
            class: 'btn-success',
        };
        this.ko = {
            i18n: 'form_cancel',
            class: 'btn-secondary',
        };
        this.cancelBtnState = ClrLoadingState.DEFAULT;
        this.validateBtnState = ClrLoadingState.DEFAULT;
    }
    ModalSimpleComponent.prototype.ngOnDestroy = function () { };
    ModalSimpleComponent.prototype.onInjectInputs = function (options) {
        var _this = this;
        this.title = options.title;
        this.size = options.size || 'md';
        this.contentString = options.contentString;
        this.contentI18n = options.contentI18n;
        this.contentObj = options.contentObj;
        if (options.ok) {
            this.ok = __assign({}, this.ok, options.ok);
        }
        if (options.ko) {
            this.ko = __assign({}, this.ko, options.ko);
        }
        this.shortcutHandlerService.register({
            shortcut: SHORTCUT_LOCAL.CANCEL,
            callback: function () {
                _this.dismiss();
            },
            context: this,
            forceListen: true,
        });
        this.shortcutHandlerService.register({
            shortcut: SHORTCUT_LOCAL.CONFIRM,
            callback: function (e) {
                _this.validate();
            },
            context: this,
            forceListen: true,
        });
    };
    ModalSimpleComponent.prototype.dismiss = function () {
        this.modalSimpleService.dismiss();
        this.unregister();
    };
    ModalSimpleComponent.prototype.close = function () {
        this.cancelBtnState = ClrLoadingState.LOADING;
        this.modalSimpleService.close();
        this.unregister();
    };
    ModalSimpleComponent.prototype.validate = function () {
        this.validateBtnState = ClrLoadingState.LOADING;
        this.modalSimpleService.validate();
        this.unregister();
    };
    ModalSimpleComponent.prototype.unregister = function () {
        this.shortcutHandlerService.unregister(this, SHORTCUT_LOCAL.CANCEL);
        this.shortcutHandlerService.unregister(this, SHORTCUT_LOCAL.CONFIRM);
    };
    return ModalSimpleComponent;
}());
export { ModalSimpleComponent };
