<div class="clr-row" *ngIf="taskDetailled">
    <div class="column clr-col-4">
        <table class="task-info-table text-left">
            <tbody>
                <tr *ngIf="taskDetailled.name">
                    <th style="vertical-align: top;" translate>task_name</th>
                    <td>{{ taskDetailled.name }}</td>
                </tr>
                <tr *ngIf="taskDetailled.authorId">
                    <th translate>author</th>
                    <td>{{ getEmployee(taskDetailled.authorId)?.user?.name }}</td>
                </tr>
                <tr *ngIf="taskDetailled.progress">
                    <th translate>task_progress_wp</th>
                    <td>{{ taskDetailled.progress }}%</td>
                </tr>
                <tr *ngIf="taskDetailled.project?.obs">
                    <th translate>project</th>
                    <td>{{ taskDetailled.project.obs }}</td>
                </tr>
                <tr *ngIf="taskDetailled.project?.client?.user?.name">
                    <th translate>client</th>
                    <td>{{ taskDetailled.project?.client?.user?.name }}</td>
                </tr>
                <tr *ngIf="taskDetailled.targetRelease">
                    <th translate>release</th>
                    <td>{{ taskDetailled.targetRelease.obs }}</td>
                </tr>
                <tr>
                    <th translate>date</th>
                    <td>
                        {{ taskDetailled._metadata.beginDate | momentFormat }} -
                        {{ taskDetailled._metadata.endDate | momentFormat }}
                    </td>
                </tr>
                <tr *ngIf="taskDetailled.status">
                    <th translate>task_status</th>
                    <td>{{ taskDetailled.status | lowercase | translate }}</td>
                </tr>
                <tr *ngIf="taskDetailled.type === TASK_TYPE.BUG || taskDetailled.urgent">
                    <th translate>task_type</th>
                    <td>
                        <i class="fas fa-bug" *ngIf="taskDetailled?.type === TASK_TYPE.BUG"></i>
                        <i class="fas fa-exclamation" *ngIf="taskDetailled?.urgent"></i>
                    </td>
                </tr>
                <tr *ngIf="taskDetailled.estimatedTime">
                    <th translate>est_time</th>
                    <td>{{ taskDetailled.estimatedTime }}</td>
                </tr>
                <tr *ngIf="taskDetailled.resolution">
                    <th translate>resolution</th>
                    <td>{{ taskDetailled.resolution | lowercase | translate }}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="column clr-col-4">
        <div *ngIf="taskDetailled.taskComments.length > 0">
            <h5 translate class="mt-0">comments</h5>
            <ng-container *ngFor="let comment of taskDetailled.taskComments">
                <app-card
                    [user]="getEmployee(comment.employeeId)?.user"
                    [createdAt]="comment.createdAt"
                    [updatedAt]="comment.updatedAt"
                    [tile]="true"
                >
                    <ng-template appCardBody> {{ comment.comment }} </ng-template>
                </app-card>
            </ng-container>
        </div>
        <div *ngIf="taskDetailled.taskHistories.length > 0">
            <h5 translate [class.mt-0]="taskDetailled.taskComments.length === 0">history</h5>
            <ng-container *ngFor="let history of taskDetailled.taskHistories">
                <app-card
                    [user]="getEmployee(history.employeeId)?.user"
                    [tileContent]="
                        (history.previousStatus | lowercase | translate) +
                        ' -> ' +
                        (history.newStatus | lowercase | translate)
                    "
                    [createdAt]="history.createdAt"
                    [updatedAt]="history.updatedAt"
                    [tile]="true"
                >
                    <ng-template appCardBody>
                        {{
                            (history.previousStatus | lowercase | translate) +
                                ' -> ' +
                                (history.newStatus | lowercase | translate)
                        }}
                    </ng-template>
                </app-card>
            </ng-container>
        </div>
    </div>
    <div class="column clr-col-4">
        <div *ngIf="taskDetailled.times.length > 0">
            <h5 translate class="mt-0">times</h5>
            <ng-container *ngFor="let time of taskDetailled.times">
                <app-card
                    [user]="getEmployee(time.employeeId)?.user"
                    [createdAt]="time.createdAt"
                    [updatedAt]="time.updatedAt"
                    [tile]="true"
                >
                    <ng-template appCardBody> {{ time.time }} </ng-template>
                </app-card>
            </ng-container>
        </div>

        <div *ngIf="taskDetailled.failedReviews.length > 0">
            <h5 translate [class.mt-0]="taskDetailled.times.length === 0">failed_review</h5>
            <ng-container *ngFor="let failedReview of taskDetailled.failedReviews">
                <app-card
                    [user]="getEmployee(failedReview.employeeId)?.user"
                    [createdAt]="failedReview.createdAt"
                    [updatedAt]="failedReview.updatedAt"
                    [tile]="true"
                >
                    <ng-template appCardBody> {{ failedReview.reason | lowercase | translate }} </ng-template>
                </app-card>
            </ng-container>
        </div>
    </div>
</div>
