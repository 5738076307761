import {Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {FormControl, FormGroup} from 'ngx-strongly-typed-forms';
import {
    ApiRoutePlurality,
    HTTP_METHOD,
    ISO_DATE_FORMAT,
    MAX_LENGTH_DEFAULT,
    MilestonesType,
    RIGHTS,
} from '../../../../defs/schema-static';
import {CLIENT_SCHEMA_ROUTE, IClient} from '../../../../defs/schema/public/Clients';
import {EMPLOYEE_SCHEMA_ROUTE, IEmployee} from '../../../../defs/schema/public/Employees';
import {IMeetingParticipant} from '../../../../defs/schema/public/MeetingParticipants';
import {MILESTONE_SCHEMA_ROUTE} from '../../../../defs/schema/public/Milestones';
import {IProjectMember} from '../../../../defs/schema/public/ProjectMembers';
import {IProject, PROJECT_SCHEMA_ROUTE} from '../../../../defs/schema/public/Projects';
import {AuthService} from '../auth/auth.service';
import {
    FormsAddMilestoneService,
    IMilestoneFormValues,
    MILESTONE_FORM_KEYS,
    MilestoneTarget,
} from '../forms/add-milestone.service';
import {requiredTrimValidator} from '../forms/validators/required-trim.validator';
import {HttpRestService} from '../shared/http-rest/http-rest.service';
import {MomentService} from '../shared/moment/moment.service';

@Component({
    selector: 'app-edit-milestone',
    templateUrl: './edit-milestone.component.html',
    styleUrls: ['./edit-milestone.component.scss'],
})
export class EditMilestoneComponent implements OnChanges {
    public readonly form = new FormGroup<Partial<IMilestoneFormValues>>({
        description: new FormControl<string>(),
        milestone: new FormControl<string>(null, [requiredTrimValidator(), Validators.maxLength(MAX_LENGTH_DEFAULT)]),
        target: new FormControl<string>(null, requiredTrimValidator()),
        startDate: new FormControl<string>(null, Validators.required),
        startTime: new FormControl<string>(null, Validators.required),
        endDate: new FormControl<string>(null, Validators.required),
        endTime: new FormControl<string>(null, Validators.required),
        version: new FormControl<string>(),
        participants: new FormControl<Partial<IMeetingParticipant>[]>(),
    });

    @Input()
    public selectedEvent: IMilestoneFormValues;

    @Input()
    public showModal = false;
    @Output()
    public showModalChange = new EventEmitter<boolean>();

    @Output()
    public hidden = new EventEmitter<void>();

    @Output()
    public saved = new EventEmitter<IMilestoneFormValues>();

    @Output()
    public removed = new EventEmitter<void>();

    @Input()
    public deletable = true;

    @ViewChild('beginDateInputElement') public beginDateInputElement: ElementRef;
    @ViewChild('targetDateInputElement') public targetDateInputElement: ElementRef;
    @ViewChild('endDateInputElement') public endDateInputElement: ElementRef;

    public employees: IEmployee[];
    public projects: IProject[];
    public clients: IClient[];

    public readonly errorsDates: string[] = ['error_date_begin_only', 'error_date'];
    public errorDates = 0;

    public beginDate: Date;
    public endDate: Date;
    public targetDate: Date;

    public members: Partial<IMeetingParticipant>[];

    public showDeleteModal = false;
    public selectedEmployee: IEmployee;
    public selectedClient: IClient;
    public selectedProject: IProject;

    public constructor(
        private readonly translate: TranslateService,
        private readonly httpRest: HttpRestService,
        public authService: AuthService,
        private readonly momentService: MomentService,
        private readonly formsAddMilestoneService: FormsAddMilestoneService
    ) {
        (async () => this.getData())();
    }

    public async getData() {
        [this.employees, this.projects, this.clients] = await Promise.all([
            this.httpRest
                ._request<IEmployee[]>(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, EMPLOYEE_SCHEMA_ROUTE, 'calendar')
                .toPromise(),
            this.httpRest
                ._request<IProject[]>(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, PROJECT_SCHEMA_ROUTE, 'light')
                .toPromise(),
            this.httpRest
                ._request<IClient[]>(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, CLIENT_SCHEMA_ROUTE, 'light')
                .toPromise(),
        ]);

        this.setMembers();
        this.setEmp();
        this.setClient();
        this.setProject();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.selectedEvent && this.selectedEvent) {
            this.form.reset();
            this.initEditMilestone();
            this.setMembers();
            this.setEmp();
            this.setClient();
            this.setProject();

            if (
                !(
                    this.authService.hasRight(RIGHTS.MS_UPDATE) ||
                    this.selectedEvent.author === this.authService.user.employee.id
                )
            ) {
                this.form.disable();
            }
        }
    }

    public get canEdit() {
        return (
            this.authService.hasRight(RIGHTS.MS_UPDATE) ||
            this.selectedEvent.author === this.authService.user.employee.id
        );
    }

    public hideModale() {
        this.hidden.emit();
    }

    public deleteMilestone() {
        this.httpRest.deleteId(MILESTONE_SCHEMA_ROUTE, this.selectedEvent.id).subscribe(async (res) => {
            if (res.count > 0) {
                this.removed.emit();
                this.showModal = false;
                this.showDeleteModal = false;
            }
        });
    }

    public setClient() {
        if (!this.selectedEvent || !(this.selectedEvent.client && this.selectedEvent.client > 0) || !this.clients) {
            return '';
        }
        this.selectedClient = this.clients.find((f) => f.id === this.selectedEvent.client);
    }

    public setProject() {
        if (!this.selectedEvent || !(this.selectedEvent.project && this.selectedEvent.project > 0) || !this.projects) {
            return '';
        }
        this.selectedProject = this.projects.find((f) => f.id === this.selectedEvent.project);
    }

    public setEmp() {
        if (!this.selectedEvent || !this.employees || this.employees.length === 0 || !this.selectedEvent.author) {
            return;
        }
        this.selectedEmployee = this.employees.find((f) => f.id === this.selectedEvent.author);
    }

    public initEditMilestone() {
        if (!this.selectedEvent.participants) {
            this.selectedEvent.participants = [];
        }

        this.beginDate = this.momentService.moment(this.selectedEvent.startDate).toDate();
        this.endDate = this.momentService.moment(this.selectedEvent.endDate).toDate();
        this.targetDate = this.momentService.moment(this.selectedEvent.target).toDate();

        this.toggle(this.selectedEvent.type);

        this.form.patchValue({
            milestone: this.selectedEvent.milestone,
            description: this.selectedEvent.description,
            version: this.selectedEvent.version,
            participants: [...this.selectedEvent.participants],
            targetType: this.selectedEvent.client ? MilestoneTarget.CLIENT : MilestoneTarget.PROJECT,
            type: this.selectedEvent.type,
            startDate: this.beginDate ? this.momentService.moment(this.beginDate).format(ISO_DATE_FORMAT) : null,
            endDate: this.endDate ? this.momentService.moment(this.endDate).format(ISO_DATE_FORMAT) : null,
            target: this.targetDate ? this.momentService.moment(this.targetDate).format(ISO_DATE_FORMAT) : null,
        });

        setTimeout(() => {
            this.form.patchValue({
                startTime: this.selectedEvent.startTime,
                endTime: this.selectedEvent.endTime,
            });
        }, 50);
    }

    private toggle(type?: MilestonesType) {
        const _type = this.form.value.type || type;
        this.errorDates = 1;
        if (MilestonesType.REMINDER === _type) {
            this.errorDates = 0;
        }
        FormsAddMilestoneService.toggle(this.form, type);
    }
    public checkDates = () => {
        // fix datepicker reset not null
        if (this.beginDateInputElement && this.beginDateInputElement.nativeElement.value === '') {
            this.form.patchValue({startDate: null});
            this.beginDate = null;
        }
        if (this.endDateInputElement && this.endDateInputElement.nativeElement.value === '') {
            this.form.patchValue({endDate: null});
            this.endDate = null;
        }
        if (this.targetDateInputElement && this.targetDateInputElement.nativeElement.value === '') {
            this.form.patchValue({target: null});
            this.targetDate = null;
        }

        // view milestone without rights to save and see everything
        if (this.selectedEvent.client === -1 && this.selectedEvent.project === -1) {
            return true; // don't show it
        }

        return this.formsAddMilestoneService.checkDates(
            this.form,
            this.beginDate,
            this.endDate,
            this.targetDate,
            this.selectedEvent.type
        );
    };

    public saveMilestone() {
        if (!this.form.valid) {
            return;
        }

        return this.saved.emit({
            ...this.selectedEvent,
            ...this.form.value,
        });
    }

    public setMembers() {
        if (
            !this.selectedEvent ||
            (this.selectedEvent.type !== MilestonesType.MEETING &&
                this.selectedEvent.type !== MilestonesType.MEETING_NO_NOTE)
        ) {
            return;
        }

        const existingIds = (this.form.value.participants || []).map((participant) => participant.employee.id);

        let validEmployees: Partial<IEmployee>[] = [];
        if (this.selectedEvent.targetType === MilestoneTarget.PROJECT && this.projects && this.selectedEvent.project) {
            validEmployees = this.employees.filter((e) => {
                const proj =
                    this.projects.length <= 0
                        ? undefined
                        : this.projects.find((p) => p.id === Number(this.selectedEvent.project));

                return !proj
                    ? false
                    : proj.projectMembers.findIndex((_e) => _e.employeeId === e.id) !== -1 &&
                          (this.form.value.participants || []).findIndex((_e) => _e.employeeId === e.id) === -1;
            });
        } else if (
            this.selectedEvent.targetType === MilestoneTarget.CLIENT &&
            this.clients &&
            this.selectedEvent.client
        ) {
            validEmployees = this.employees.filter((e) => {
                const proj =
                    this.projects.length <= 0
                        ? undefined
                        : this.projects.filter((p) => p.clientId === Number(this.selectedEvent.client));

                return !proj
                    ? false
                    : proj
                          .reduce((pm, p) => pm.concat(p.projectMembers), [] as Partial<IProjectMember>[])
                          .findIndex((_e) => _e.employeeId === e.id) !== -1 &&
                          (this.form.value.participants || []).findIndex((_e) => _e.employeeId === e.id) === -1;
            });
        }

        this.members = [
            ...(this.form.value.participants ? this.form.value.participants : []),
            ...validEmployees
                .filter((employee) => !existingIds.includes(employee.id))
                .map(
                    (employee) =>
                        ({
                            employee,
                        } as Partial<IMeetingParticipant>)
                ),
        ];
    }

    public removeMember(participant: IMeetingParticipant) {
        const {participants} = this.form.value;
        participants.splice(participants.indexOf(participant), 1);
        this.form.patchValue({participants});
    }

    public updateMember() {
        const {participants} = this.form.value;
        this.form.patchValue({participants});
    }

    public readonly MILESTONE_FORM_KEYS = MILESTONE_FORM_KEYS;
    public readonly MILESTONE_TYPES = MilestonesType;
    private static readonly HOUR_MINUTE_TIME_FORMAT = 'HH:mm';

    public readonly MAX_LENGTH_DEFAULT = MAX_LENGTH_DEFAULT;
    public readonly RIGHTS = RIGHTS;
}
