import * as i0 from "./card.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../shared/long-avatar/long-avatar.component.ngfactory";
import * as i3 from "../shared/long-avatar/long-avatar.component";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@angular/common";
import * as i6 from "@clr/angular";
import * as i7 from "../shared/moment-format/moment-format.pipe";
import * as i8 from "../shared/moment/moment.service";
import * as i9 from "../shared/moment-duration/moment-duration.pipe";
import * as i10 from "./card.component";
import * as i11 from "../auth/auth.service";
var styles_CardComponent = [i0.styles];
var RenderType_CardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CardComponent, data: {} });
export { RenderType_CardComponent as RenderType_CardComponent };
function View_CardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-long-avatar", [], null, null, null, i2.View_LongAvatarComponent_0, i2.RenderType_LongAvatarComponent)), i1.ɵdid(2, 49152, null, 0, i3.LongAvatarComponent, [], { code: [0, "code"], bgColor: [1, "bgColor"] }, null), (_l()(), i1.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.user.code; var currVal_1 = _co.user.color; _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.user.name; _ck(_v, 3, 0, currVal_2); }); }
function View_CardComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [["class", "card-date tooltip-sm"]], [[1, "data-tooltip", 0]], null, null, null, null)), i1.ɵppd(1, 2), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i1.ɵnov(_v.parent.parent, 0), _co.updatedAt, "L LTS")); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("comment_edited")); _ck(_v, 2, 0, currVal_1); }); }
function View_CardComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "span", [["class", "card-date tooltip-sm"]], [[1, "data-tooltip", 0]], null, null, null, null)), i1.ɵppd(2, 2), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵppd(4, 1), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CardComponent_3)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_2 = (!!_co.updatedAt && (_co.updatedAt !== _co.createdAt)); _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), _co.createdAt, "L LTS")); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent, 1), _co.createdAt)); _ck(_v, 3, 0, currVal_1); }); }
function View_CardComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_CardComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "clr-col-0"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CardComponent_5)), i1.ɵdid(2, 540672, null, 0, i5.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.headerRight; _ck(_v, 2, 0, currVal_0); }, null); }
function View_CardComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_CardComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "card-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "card-text"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CardComponent_7)), i1.ɵdid(3, 540672, null, 0, i5.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.cardBody; _ck(_v, 3, 0, currVal_0); }, null); }
function View_CardComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_CardComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "clr-icon", [], [[1, "shape", 0], [4, "height", null], [4, "width", null]], null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i6.ClrIconCustomTag, [], null, null)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.icon; var currVal_1 = (_co.iconSize + "rem"); var currVal_2 = (_co.iconSize + "rem"); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_CardComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_CardComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "clr-col-0 pt-02"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CardComponent_12)), i1.ɵdid(2, 540672, null, 0, i5.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.footerRight; _ck(_v, 2, 0, currVal_0); }, null); }
function View_CardComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "card-footer clr-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "clr-col"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CardComponent_9)), i1.ɵdid(3, 540672, null, 0, i5.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CardComponent_10)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CardComponent_11)), i1.ɵdid(7, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.cardFooter; _ck(_v, 3, 0, currVal_0); var currVal_1 = !!_co.icon; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.footerRight; _ck(_v, 7, 0, currVal_2); }, null); }
export function View_CardComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i7.MomentFormatPipe, [i4.TranslateService, i8.MomentService]), i1.ɵpid(0, i9.MomentDurationPipe, [i4.TranslateService, i8.MomentService]), (_l()(), i1.ɵeld(2, 0, [["card", 1]], null, 12, "div", [["class", "card"]], [[2, "card-tile", null]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 7, "div", [["class", "card-header clr-row px-0 mx-0"]], [[2, "highlight", null]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "clr-col"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CardComponent_1)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CardComponent_2)), i1.ɵdid(8, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CardComponent_4)), i1.ɵdid(10, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CardComponent_6)), i1.ɵdid(12, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CardComponent_8)), i1.ɵdid(14, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = !!_co.user; _ck(_v, 6, 0, currVal_2); var currVal_3 = !!_co.createdAt; _ck(_v, 8, 0, currVal_3); var currVal_4 = _co.headerRight; _ck(_v, 10, 0, currVal_4); var currVal_5 = _co.cardBody; _ck(_v, 12, 0, currVal_5); var currVal_6 = _co.cardFooter; _ck(_v, 14, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tile; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.highlight; _ck(_v, 3, 0, currVal_1); }); }
export function View_CardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "app-card", [], null, null, null, View_CardComponent_0, RenderType_CardComponent)), i1.ɵdid(1, 114688, null, 4, i10.CardComponent, [i11.AuthService, i1.ChangeDetectorRef], null, null), i1.ɵqud(335544320, 1, { headerRight: 0 }), i1.ɵqud(335544320, 2, { cardBody: 0 }), i1.ɵqud(335544320, 3, { cardFooter: 0 }), i1.ɵqud(335544320, 4, { footerRight: 0 })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CardComponentNgFactory = i1.ɵccf("app-card", i10.CardComponent, View_CardComponent_Host_0, { user: "user", createdAt: "createdAt", updatedAt: "updatedAt", tile: "tile", highlight: "highlight", icon: "icon", iconSize: "iconSize" }, {}, []);
export { CardComponentNgFactory as CardComponentNgFactory };
