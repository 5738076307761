<clr-wizard
    [clrWizardOpen]="clrWizardOpen"
    (clrWizardOpenChange)="clrWizardOpenChange.emit((clrWizardOpen = $event))"
    (clrWizardOnCancel)="triggerCancel()"
>
    <clr-wizard-title translate>add_client</clr-wizard-title>

    <clr-wizard-page [clrWizardPageNextDisabled]="!clientComponent.form.valid">
        <ng-template clrPageTitle>{{ 'client' | translate }}</ng-template>
        <app-client-wizard-client [clrWizardOpen]="clrWizardOpen"></app-client-wizard-client>

        <ng-template clrPageButtons>
            <ng-container *ngIf="true; then checkbox"></ng-container>
            <clr-wizard-button [clrWizardButtonDisabled]="submitting" [type]="'cancel'">{{
                'cancel' | translate
            }}</clr-wizard-button>
            <clr-wizard-button [clrWizardButtonDisabled]="submitting" [type]="'previous'">{{
                'back' | translate
            }}</clr-wizard-button>
            <clr-wizard-button [clrWizardButtonDisabled]="submitting" [type]="'next'">{{
                'next' | translate
            }}</clr-wizard-button>
            <clr-wizard-button [clrWizardButtonDisabled]="submitting" [type]="'finish'">{{
                'create_client' | translate
            }}</clr-wizard-button>
        </ng-template>
    </clr-wizard-page>

    <clr-wizard-page
        clrWizardPagePreventDefault="true"
        (clrWizardPageOnCancel)="triggerCancel()"
        (clrWizardPagePrevious)="wizard.previous()"
        (clrWizardPageOnCommit)="triggerFinish()"
    >
        <ng-template clrPageTitle>{{ 'customer_contacts' | translate }}</ng-template>

        <app-client-wizard-customer-contacts></app-client-wizard-customer-contacts>
        <ng-template clrPageButtons>
            <ng-container *ngIf="true; then checkbox"></ng-container>
            <clr-wizard-button [clrWizardButtonDisabled]="submitting" [type]="'cancel'">{{
                'cancel' | translate
            }}</clr-wizard-button>
            <clr-wizard-button [clrWizardButtonDisabled]="submitting" [type]="'previous'">{{
                'back' | translate
            }}</clr-wizard-button>
            <clr-wizard-button [clrWizardButtonDisabled]="submitting" [type]="'next'">{{
                'next' | translate
            }}</clr-wizard-button>
            <clr-wizard-button [clrWizardButtonDisabled]="submitting" [type]="'finish'">{{
                'create_client' | translate
            }}</clr-wizard-button>
        </ng-template>
    </clr-wizard-page>
</clr-wizard>

<ng-template #checkbox>
    <clr-checkbox-container class="pl-1" style="position:absolute; left: 13rem; bottom: 1rem;">
        <clr-checkbox-wrapper>
            <input clrCheckbox type="checkbox" [(ngModel)]="createProjectOnFinish" />
            <label translate>create_project_on_client_exit</label>
        </clr-checkbox-wrapper>
    </clr-checkbox-container>
</ng-template>
