import {Injectable} from '@angular/core';
import {CanDeactivate} from '@angular/router';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router/src/router_state';
import {TranslateService} from '@ngx-translate/core';

export interface ICanComponentDeactivate {
    canDeactivate(
        component: ICanComponentDeactivate,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState?: RouterStateSnapshot
    ): boolean;
    cannotDeactivateText?(): string;
}

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<ICanComponentDeactivate> {
    public constructor(private readonly translate: TranslateService) {}

    public async canDeactivate(
        component: ICanComponentDeactivate,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState?: RouterStateSnapshot
    ) {
        const canDeactivate =
            !component.canDeactivate || component.canDeactivate(component, currentRoute, currentState, nextState);
        if (canDeactivate) {
            return true;
        }

        const text = await this.translate.get('swal_are_you_sure').toPromise();
        const DEFAULT_CANNOT_DEACTIVATE_TEXT = await this.translate.get('changes_will_lost').toPromise();

        return confirm(
            (component.cannotDeactivateText && component.cannotDeactivateText()) || DEFAULT_CANNOT_DEACTIVATE_TEXT
        );
    }
}
