import * as i0 from "./portal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../dash-client/dash-client.component.ngfactory";
import * as i3 from "../dash-client/dash-client.component";
import * as i4 from "../../shared/http-rest/http-rest.service";
import * as i5 from "../../auth/auth.service";
import * as i6 from "./portal.component";
var styles_PortalComponent = [i0.styles];
var RenderType_PortalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PortalComponent, data: {} });
export { RenderType_PortalComponent as RenderType_PortalComponent };
export function View_PortalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", " Portal"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-dash-client", [], null, null, null, i2.View_DashClientComponent_0, i2.RenderType_DashClientComponent)), i1.ɵdid(4, 114688, null, 0, i3.DashClientComponent, [i4.HttpRestService, i5.AuthService], null, null)], function (_ck, _v) { _ck(_v, 4, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.authService.user.name; _ck(_v, 2, 0, currVal_0); }); }
export function View_PortalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-portal", [], null, null, null, View_PortalComponent_0, RenderType_PortalComponent)), i1.ɵdid(1, 49152, null, 0, i6.PortalComponent, [i5.AuthService], null, null)], null, null); }
var PortalComponentNgFactory = i1.ɵccf("app-portal", i6.PortalComponent, View_PortalComponent_Host_0, {}, {}, []);
export { PortalComponentNgFactory as PortalComponentNgFactory };
