import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ClrDatagridFilterInterface} from '@clr/angular';
import fuzzysort from 'fuzzysort';
import {Subject} from 'rxjs';
import {stripAccents} from '../../../../../defs/schema-static';
import {FuzzyDatagridFilterPropertyGetter, IFilterableEntity, ISerializableDatagridFilter} from '../../app-static';
import {NestedProperty} from '../nested-properties';

@Component({
    selector: 'shared-fuzzy-datagrid-filter',
    templateUrl: './fuzzy-datagrid-filter.component.html',
})
export class FuzzyDatagridFilterComponent<T extends IFilterableEntity = any>
    implements ClrDatagridFilterInterface<T>, ISerializableDatagridFilter, OnInit, OnChanges {
    @Input() public filterKey: string;
    @Input() public propertyGetter: FuzzyDatagridFilterPropertyGetter<T>;
    @Input() public filter = '';

    @Input() public filterOpen = false;

    public changes = new Subject<any>();

    private nestedProp: NestedProperty<T>;

    public ngOnInit(): void {
        this.setFilter();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.filterKey || changes.propertyGetter) {
            this.setFilter();
        }
    }

    public setFilter() {
        this.nestedProp = this.filterKey ? new NestedProperty(this.filterKey) : undefined;
        this.changes.next(this.propertyGetter || this.filterKey);
    }

    public accepts(item: T): boolean {
        if (!this.propertyGetter && !this.filterKey) {
            // tslint:disable-next-line:no-console
            console.warn('missing propertyGetter/filterKey');

            return true;
        }

        const {filterValue} = this;

        if (!filterValue.length) {
            return true;
        }

        const result = fuzzysort.single(filterValue, this.getItemValue(item));

        return result && result.score >= FuzzyDatagridFilterComponent.FUZZYSORT_THRESHOLD;
    }

    public isActive(): boolean {
        return this.filterValue.length > 0;
    }

    public serialize(): string[] {
        const {filterValue} = this;

        if (!filterValue) {
            return [];
        }

        return [filterValue];
    }

    public get filterValue(): string {
        return stripAccents(this.filter.trim()) || '';
    }

    public getItemValue(item: T): string {
        if (!this.propertyGetter && !this.nestedProp) {
            return '';
        }

        const prop = this.propertyGetter ? this.propertyGetter(item) : this.nestedProp.getPropValue(item);

        return stripAccents(FuzzyDatagridFilterComponent.castToString(prop));
    }

    public static castToString(value: string | number | null | undefined): string {
        if (typeof value === 'undefined' || value === null) {
            return '';
        }

        return value.toString();
    }

    public static readonly FUZZYSORT_THRESHOLD = -Infinity;
}
