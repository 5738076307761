import moment from 'moment';
import {ISchemaRoute} from '../../schema-static';
import {IAssignedTask} from './AssignedTasks';
import {ITime} from './Times';
import {IUser} from './Users';

export enum EMPLOYEE_FIELD {
    id = 'id',
    dayPrice = 'dayPrice',
    discordSnowflake = 'discordSnowflake',
    userId = 'userId',
    user = 'user',
    times = 'times',
    phoneNumber = 'phoneNumber',
    mobileNumber = 'mobileNumber',
    createdAt = 'createdAt',
    updatedAt = 'updatedAt',
}

export interface IEmployeeBare {
    id?: number;
    createdAt?: moment.Moment;
    updatedAt?: moment.Moment;
    dayPrice?: number;
    discordSnowflake?: string;
    phoneNumber?: string;
    mobileNumber?: string;
    userId: number;
    AssignedTasks?: Partial<IAssignedTask>;
}

export interface IEmployeeBelong {
    user: Partial<IUser>;
}

export interface IEmployeeMany {
    times?: Partial<ITime>[];
}

export interface IEmployee extends IEmployeeBare, IEmployeeBelong, IEmployeeMany {}

const EMPLOYEE_SCHEMA_ROUTE: ISchemaRoute = {
    tableName: 'employees',
    singularRoute: 'employee',
    pluralRoute: 'employees',
};

export {EMPLOYEE_SCHEMA_ROUTE};
