import { APP_HTTP_HEADERS } from '../../../../defs/schema-static';
import { environment } from '../../environments/environment';
var VersionInterceptor = (function () {
    function VersionInterceptor() {
        this.intercept = function (request, next) {
            return next.handle(VersionInterceptor.setVersionHeader(request));
        };
    }
    VersionInterceptor.setVersionHeader = function (request) {
        var _a;
        return request.clone({
            setHeaders: (_a = {},
                _a[APP_HTTP_HEADERS.WWW_VERSION] = "" + VersionInterceptor.WWW_VERSION,
                _a),
        });
    };
    VersionInterceptor.WWW_VERSION = environment.version;
    return VersionInterceptor;
}());
export { VersionInterceptor };
