import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./shared/toast/toast.component.ngfactory";
import * as i3 from "./shared/toast/toast.component";
import * as i4 from "./shared/toast/toast.service";
import * as i5 from "./shared/control-flow/control-flow.component.ngfactory";
import * as i6 from "./shared/control-flow/control-flow.component";
import * as i7 from "@angular/router";
import * as i8 from "./shared/control-flow/control-flow.service";
import * as i9 from "./auth/auth.service";
import * as i10 from "./shared/notification.service";
import * as i11 from "./shared/http-rest/http-rest.component.ngfactory";
import * as i12 from "./shared/http-rest/http-rest.component";
import * as i13 from "./shared/http-rest/http-rest.service";
import * as i14 from "./app.component";
import * as i15 from "./auth/auth.guard";
import * as i16 from "./auth/employee-auth.guard";
import * as i17 from "./auth/client-auth.guard";
import * as i18 from "./settings/settings.service";
import * as i19 from "@ngx-translate/core";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "shared-toast", [], null, null, null, i2.View_ToastComponent_0, i2.RenderType_ToastComponent)), i1.ɵdid(1, 49152, null, 0, i3.ToastComponent, [i4.ToastService], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "shared-control-flow", [], null, null, null, i5.View_ControlFlowComponent_0, i5.RenderType_ControlFlowComponent)), i1.ɵdid(3, 770048, null, 0, i6.ControlFlowComponent, [i7.Router, i7.ActivatedRoute, i8.ControlFlowService, i1.ChangeDetectorRef, i9.AuthService, i10.NotificationService], { routes: [0, "routes"], routesControlFlow: [1, "routesControlFlow"], maxDepth: [2, "maxDepth"], maxDisplayedDepth: [3, "maxDisplayedDepth"], hidden: [4, "hidden"], requiredGroup: [5, "requiredGroup"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "shared-http-rest", [], null, null, null, i11.View_HttpRestComponent_0, i11.RenderType_HttpRestComponent)), i1.ɵdid(5, 49152, null, 0, i12.HttpRestComponent, [i13.HttpRestService], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.routes; var currVal_1 = _co.routesControlFlow; var currVal_2 = 3; var currVal_3 = 1; var currVal_4 = !_co.isAuthenticated; var currVal_5 = _co.requiredGroup; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 180224, null, 0, i14.AppComponent, [i9.AuthService, i15.AuthGuard, i16.EmployeeAuthGuard, i17.ClientAuthGuard, i18.SettingsService, i19.TranslateService, i10.NotificationService, i4.ToastService], null, null)], null, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i14.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
