<div class="clr-row">
    <div class="spinner-bg-c-small" style="margin-top: 15em;" *ngIf="pleaseWait"><div class="spinner-c"></div></div>
    <input
        class="d-block filter-input"
        type="text"
        placeholder="{{ 'placeholder_filter' | translate }}"
        (keyup)="updateFilter($event)"
    />
    <button
        class="btn float-right"
        style="margin-top: 1.2rem;margin-right: 2rem;"
        (click)="showNewIncidentModal = true"
    >
        <i class="fa fa-plus mr-2"></i>Incident
    </button>
    <div class="clr-col-12">
        <!--
            <ngx-datatable
                *ngIf="!pleaseWait"
                class="c-hand"
                [rows]="displayIncidents"
                [messages]="{emptyMessage: 'no_data' | translate, totalMessage: 'total'}"
                [columnMode]="'force'"
                [headerHeight]="50"
                [rowHeight]="65"
                [scrollbarV]="true"
            >
                <ngx-datatable-column name="{{ 'table_title' | translate }}" [comparator]="sortStrings" prop="title">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template> {{ row.title }} </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column
                    name="{{ 'table_description' | translate }}"
                    [comparator]="sortStrings"
                    prop="description"
                >
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                        {{ row.description }}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="{{ 'projects' | translate }}" [sortable]="false">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                        {{ getProject(row.projectId)?.obs }}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="{{ 'creation_date' | translate }}" [comparator]="sortDates" prop="createdAt">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                        {{ row.createdAt | momentFormat: 'L LT' }}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="{{ 'update_date' | translate }}" [comparator]="sortDates" prop="updatedAt">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                        {{ row.updatedAt | momentFormat: 'L LT' }}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="{{ 'table_status' | translate }}" [comparator]="sortStrings" prop="status">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                        <span class="{{ row.status.toLowerCase() }}">{{
                            'incident_' + row.status | lowercase | translate
                        }}</span>
                    </ng-template>
                </ngx-datatable-column>
            </ngx-datatable>
        -->
    </div>
</div>
<!--
    <app-modal [(show)]="showNewIncidentModal" [title]="'new_incident' | translate">
        <ng-template appModalBody>
            <form [formGroup]="form" novalidate autocomplete="off">
                <label class="form-label mt-2 float-left" for="title" translate>table_title</label>
                <input
                    class="d-block"
                    id="title"
                    [formControlName]="INCIDENT_FORM_KEYS.title"
                    name="title"
                    appRequiredTrim
                />
                <label class="form-label mt-2 float-left" for="description" translate>table_description</label>
                <textarea
                    rows="5"
                    class="d-block"
                    type="text"
                    id="description"
                    [formControlName]="INCIDENT_FORM_KEYS.description"
                    name="description"
                    appRequiredTrim
                ></textarea>

                <label class="form-label mt-2 float-left" for="project" translate>project</label>
                <select id="project" [formControlName]="INCIDENT_FORM_KEYS.project" name="project" required>
                    <option *ngFor="let project of (projects | sharedArraySort: 'obs')" [value]="project.id">
                        {{ project.obs }}
                    </option>
                </select>
            </form>
        </ng-template>

        <ng-template appModalFooter>
            <button class="btn btn-primary" (click)="onSubmit(form)" [disabled]="!form.valid" translate>form_save</button>
        </ng-template>
    </app-modal>
-->
