import * as i0 from "./incident.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "./incident.component";
import * as i5 from "../shared/http-rest/http-rest.service";
import * as i6 from "@angular/router";
var styles_IncidentComponent = [i0.styles];
var RenderType_IncidentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IncidentComponent, data: {} });
export { RenderType_IncidentComponent as RenderType_IncidentComponent };
function View_IncidentComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "spinner-bg-c-small"], ["style", "margin-top: 15em;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "spinner-c"]], null, null, null, null, null))], null, null); }
export function View_IncidentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "clr-row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IncidentComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "input", [["class", "d-block filter-input"], ["type", "text"]], [[8, "placeholder", 0]], [[null, "keyup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keyup" === en)) {
        var pd_0 = (_co.updateFilter($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 2, "button", [["class", "btn float-right"], ["style", "margin-top: 1.2rem;margin-right: 2rem;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.showNewIncidentModal = true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["class", "fa fa-plus mr-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Incident "])), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["class", "clr-col-12"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.pleaseWait; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("placeholder_filter")), ""); _ck(_v, 3, 0, currVal_1); }); }
export function View_IncidentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-incident", [], null, null, null, View_IncidentComponent_0, RenderType_IncidentComponent)), i1.ɵdid(1, 49152, null, 0, i4.IncidentComponent, [i5.HttpRestService, i6.ActivatedRoute], null, null)], null, null); }
var IncidentComponentNgFactory = i1.ɵccf("app-incident", i4.IncidentComponent, View_IncidentComponent_Host_0, {}, {}, []);
export { IncidentComponentNgFactory as IncidentComponentNgFactory };
