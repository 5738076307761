import {DATABASE_SCHEMA, ISchemaRoute} from '../../schema-static';
import {SETTING_LANGUAGE_VALUES, SETTING_THEME_VALUES} from '../public/Users';

export enum DAYS {
    MONDAY = 1,
    TUESDAY = 2,
    WEDNESDAY = 3,
    THURSDAY = 4,
    FRIDAY = 5,
    SATURDAY = 6,
    SUNDAY = 7,
}

export interface IHours {
    start: string;
    end: string;
}

export interface IRules {
    appName: string;
    mailDomain: string;
    workingDays: DAYS[];
    workingHours: IHours;
    lunchHours: IHours;
    enabledI18n: SETTING_LANGUAGE_VALUES[];
    defaultTheme: SETTING_THEME_VALUES;
    calendarHours: IHours;
}
export enum CONFIG_FIELD {
    id = 'id',
    key = 'key',
    value = 'value',
}

export interface IConfig {
    id?: number;
    key: keyof IRules;
    value: object | string | number | null;
}

const CONFIG_SCHEMA_ROUTE: ISchemaRoute = {
    schema: DATABASE_SCHEMA.META,
    tableName: 'config',
    singularRoute: 'config',
    pluralRoute: 'configs',
};

export {CONFIG_SCHEMA_ROUTE};
