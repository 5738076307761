<ul *ngIf="display === DATAGRID_FILTER_DISPLAY_TYPE.CHECKBOX_LIST">
    <li class="pt-05">
        <clr-checkbox-container class="mt-0">
            <clr-checkbox-wrapper>
                <input clrCheckbox type="checkbox" [(ngModel)]="toggleAll" (change)="toggleToggleAll()" />
                <label translate>toggle_all_filter</label>
            </clr-checkbox-wrapper>
        </clr-checkbox-container>
    </li>
    <li *ngFor="let filterKey of filterKeys" class="pt-05">
        <clr-checkbox-container class="mt-0">
            <clr-checkbox-wrapper>
                <input clrCheckbox type="checkbox" [(ngModel)]="state[filterKey]" (change)="emitChanges()" />
                <label>
                    <span [classList]="filter[filterKey].classList">
                        <clr-icon [attr.shape]="filter[filterKey].icon" *ngIf="filter[filterKey].icon"></clr-icon>

                        {{ filter[filterKey].translation ? (filter[filterKey].translation | translate) : filterKey }}
                    </span>

                    <span class="badge" *ngIf="filterCount">{{ filterCount[filterKey] }}</span>
                </label>
            </clr-checkbox-wrapper>
        </clr-checkbox-container>
    </li>
</ul>

<div class="icon-grid pt-02" *ngIf="display === DATAGRID_FILTER_DISPLAY_TYPE.ICON_GRID">
    <span
        *ngFor="let filterKey of filterKeys"
        class="icon c-hand mx-02"
        [class.icon-active]="state[filterKey]"
        [class.icon-inactive]="!state[filterKey]"
        (click)="state[filterKey] = !state[filterKey]; emitChanges()"
        title="{{ filter[filterKey].translation ? (filter[filterKey].translation | translate) : filterKey }} {{
            filterCount ? '(' + filterCount[filterKey] + ')' : ''
        }}"
    >
        <clr-icon
            [classList]="filter[filterKey].classList"
            [attr.shape]="filter[filterKey].icon"
            *ngIf="filter[filterKey].icon"
        ></clr-icon>
    </span>
</div>
