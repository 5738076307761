import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ProjectMemberType} from '../../../../../defs/schema-static';
import {IEmployee} from '../../../../../defs/schema/public/Employees';
import {IProjectMember} from '../../../../../defs/schema/public/ProjectMembers';

interface IEmployeeMember {
    id?: number;
    employeeId: number;
    employee: Partial<IEmployee>;
}

export enum EMPLOYEE_MEMBER_TYPE {
    PROJECT_MEMBER = 'PROJECT_MEMBER',
    CUSTOMER_CONTACT = 'CUSTOMER_CONTACT',
}

@Component({
    selector: 'shared-editable-employee-label',
    templateUrl: './editable-employee-label.component.html',
})
export class EditableEmployeeLabelComponent<T extends IEmployeeMember> {
    @Input() public member: Partial<T>;

    @Input() public type: EMPLOYEE_MEMBER_TYPE;
    @Input() public updatable = false;
    @Input() public removable = false;

    @Output() public update = new EventEmitter<Partial<T>>();
    @Output() public remove = new EventEmitter<Partial<T>>();

    public removeMember() {
        this.remove.emit(this.member);
    }

    public toggleType() {
        const {MEMBER, MANAGER} = ProjectMemberType;

        const member = (this.member as unknown) as IProjectMember;
        member.type = member.type === MANAGER ? MEMBER : MANAGER;

        this.update.emit(this.member);
    }

    public readonly EMPLOYEE_MEMBER_TYPE = EMPLOYEE_MEMBER_TYPE;

    public readonly ProjectMemberType = ProjectMemberType;
}
