<div class="clr-row" style="margin-bottom: 25px;">
    <blockquote>
        {{ 'welcome' | translate }} <br />
        <ng-container *ngIf="this.portalData?.data?._project" translate
            >{{ 'welcome_portal' | translate }} {{ formattedProjectList() }}
        </ng-container>
    </blockquote>
</div>
<div class="clr-row" style="padding: 1rem;">
    <div class="clr-row clr-col-12" style="height: 100px;">
        <div class="column clr-col-4 numbers">
            <app-dashboard-numbers [card]="globalNumber"></app-dashboard-numbers>
        </div>

        <div class="column clr-col-4 numbers">
            <app-dashboard-numbers [card]="requestNumber"></app-dashboard-numbers>
        </div>

        <div class="column clr-col-4 numbers">
            <app-dashboard-numbers [card]="incidentNumber"></app-dashboard-numbers>
        </div>
    </div>

    <div class="clr-row clr-col-12 mt-4">
        <div class="column clr-col-4 sub-part">
            <h4 translate>incoming_releases</h4>
            <table class="table table-striped scroll">
                <thead>
                    <tr>
                        <th translate>planned_release</th>
                        <th translate>release</th>
                        <th translate class="padfix">table_status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let release of portalData?.data?._milestones">
                        <td>{{ release.target | momentFormat }}</td>
                        <td>{{ release.obs }}</td>
                        <td
                            [ngClass]="{
                                released: release.releaseState === ReleaseStateType.RELEASED,
                                dev: release.releaseState === ReleaseStateType.IN_DEVELOPMENT,
                                staged: release.releaseState === ReleaseStateType.STAGED
                            }"
                        >
                            {{ release.releaseState | translate }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="column clr-col-4 sub-part">
            <h4 translate>last_requests</h4>
            <table class="table table-striped scroll">
                <thead>
                    <tr>
                        <th translate>request</th>
                        <th translate>table_status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let request of portalData?.data?.customerRequest">
                        <td>{{ request.title }}</td>
                        <td [class]="request.status | lowercase">
                            {{ 'request_' + request.status | lowercase | translate }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="column clr-col-4 sub-part">
            <h4 translate>last_incidents</h4>
            <table class="table table-striped scroll">
                <thead>
                    <tr>
                        <th translate>incident_date</th>
                        <th translate>table_title</th>
                        <th translate class="padfix">table_status</th>
                        <th translate class="padfix">Last Update</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let incident of portalData?.data?.incidents">
                        <td>{{ incident.createdAt | momentFormat }}</td>
                        <td>{{ incident.title }}</td>
                        <td [class]="incident.status | lowercase">
                            {{ 'incident_' + incident.status | lowercase | translate }}
                        </td>
                        <td>{{ incident.updatedAt | momentFormat }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
