import moment from 'moment';
import {ISchemaRoute, TIME_COMPONENTS} from '../../schema-static';
import {IEmployee} from './Employees';
import {ITask} from './Tasks';

export enum TIME_FIELD {
    id = 'id',
    time = 'time',
    weeks = 'weeks',
    days = 'days',
    hours = 'hours',
    minutes = 'minutes',
    beginDate = 'beginDate',
    endDate = 'endDate',
    taskId = 'taskId',
    employeeId = 'employeeId',
    updatedAt = 'updatedAt',
    createdAt = 'createdAt',
}

export interface ITimeBare {
    id?: number;
    createdAt?: moment.Moment;
    updatedAt?: moment.Moment;
    time: string;
    weeks: number;
    days: number;
    hours: number;
    minutes: number;
    beginDate: moment.Moment;
    endDate: moment.Moment;
    employeeId: number;
    taskId: number;
}

export interface ITimeBelong {
    task?: Partial<ITask>;
    employee: Partial<IEmployee>;
}

export interface ITime extends ITimeBare, ITimeBelong {}

export const TIME_SCHEMA_ROUTE: ISchemaRoute = {
    tableName: 'times',
    singularRoute: 'time',
    pluralRoute: 'times',
};

export type TimeComponent = moment.unitOfTime.Base & keyof ITime;
export type TimeComponentObj = {[key in TimeComponent]?: number};

export const TIMES_TIME_COMPONENTS: TimeComponent[] = ['weeks', 'days', 'hours', 'minutes'];

export const getTimeString = (
    duration: moment.Duration,
    maxComponents = Number.MAX_SAFE_INTEGER,
    since = false
): string => {
    return (
        (Object.keys(TIME_COMPONENTS) as moment.unitOfTime.Base[])
            .filter((component) => !!duration.get(component))
            .slice(0, maxComponents)
            .reduce(
                (_res, component) => `${_res}${Math.abs(duration.get(component))}${TIME_COMPONENTS[component]} `,
                ''
            )
            .trim() + (since && duration.asSeconds() < 0 ? ' ago' : '')
    );
};
