import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {ClrWizard} from '@clr/angular';
import {FormGroup} from 'ngx-strongly-typed-forms';
import {IClient} from '../../../../defs/schema/public/Clients';
import {SHORTCUT_WIZARD, ShortcutHandlerService} from '../shared/shortcut-handler/shortcut-handler.service';
import {ClientWizardClientComponent} from './client/client-wizard-client.component';
import {ClientWizardCustomerContactsComponent} from './customer-contacts/client-wizard-customer-contacts.component';

@Component({
    selector: 'app-client-wizard',
    templateUrl: './client-wizard.component.html',
})
export class ClientWizardComponent implements OnChanges, OnInit {
    @ViewChild(ClrWizard) public wizard: ClrWizard;
    @ViewChild(ClientWizardClientComponent) public clientComponent: ClientWizardClientComponent;
    @ViewChild(ClientWizardCustomerContactsComponent)
    public customerContactsComponent: ClientWizardCustomerContactsComponent;

    @Input() public clrWizardOpen = false;
    @Output() public clrWizardOpenChange = new EventEmitter<boolean>();

    @Output() public finish = new EventEmitter<IClient>();

    public createProjectOnFinish = false;

    public submitting = false;

    public constructor(
        private readonly router: Router,
        private readonly shortcutHandlerService: ShortcutHandlerService
    ) {}

    public ngOnInit() {
        this.shortcutHandlerService.register({
            shortcut: SHORTCUT_WIZARD.NEXT,
            callback: (e) => {
                if (!this.wizard.currentPage.nextStepDisabled && (e.target as HTMLElement).tagName !== 'TEXTAREA') {
                    if (this.wizard.isLast) {
                        this.triggerFinish();
                    } else {
                        this.wizard.next();
                    }
                }
            },
            context: this,
            forceListen: true,
        });
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.clrWizardOpen && changes.clrWizardOpen.currentValue) {
            this.clientComponent.onLoad();
        }
    }

    public async triggerFinish() {
        this.submitting = true;
        let client: IClient;
        try {
            client = await this.clientComponent.submit();
            if (!client) {
                return undefined;
            }

            this.customerContactsComponent.client = client;
            client.contacts = (await this.customerContactsComponent.submit()).map(({employee}) => employee);

            this.finish.emit(client);
        } catch (err) {
            return undefined;
        } finally {
            this.submitting = false;
        }

        this.triggerCancel();

        if (this.createProjectOnFinish) {
            return this.router.navigate(['/projects'], {
                queryParams: {
                    action: 'create',
                    clientId: client.id,
                },
            });
        }
    }

    public triggerCancel() {
        this.reset();
        this.wizard.forceFinish();
    }

    public reset(): void {
        this.wizard.reset();
        this.createProjectOnFinish = false;

        [this.clientComponent].map((component) => component.reset());
        [this.customerContactsComponent].map(({form}: {form: FormGroup<any>}) => form.reset());
    }
}
