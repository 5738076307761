import {
    AfterViewInit,
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';

@Directive({
    selector: 'textarea[sharedAutosize]',
})
export class AutoresizeDirective implements AfterViewInit, OnChanges {
    private readonly el: HTMLElement;
    private _minHeight: string;
    private _maxHeight: string;
    private readonly _lastHeight: number;
    private _clientWidth: number;

    @Input('minHeight')
    public get minHeight(): string {
        return this._minHeight;
    }

    public set minHeight(val: string) {
        this._minHeight = val;

        requestAnimationFrame(() => {
            this.updateMinHeight();
        });
    }

    @Input() public ngModel: string;

    @Output() public ngModelChange = new EventEmitter();

    public constructor(public element: ElementRef) {
        this.el = element.nativeElement;
        this._clientWidth = this.el.clientWidth;
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes) {
            requestAnimationFrame(() => {
                this.adjust();
            });
        }
    }

    @Input('maxHeight')
    public get maxHeight(): string {
        return this._maxHeight;
    }

    public set maxHeight(val: string) {
        this._maxHeight = val;
        this.updateMaxHeight();
    }

    @HostListener('window:resize', ['$event.target'])
    public onResize(textArea: HTMLTextAreaElement): void {
        // only apply adjustment if element width had changed.
        if (this.el.clientWidth === this._clientWidth) {
            return;
        }
        this._clientWidth = this.element.nativeElement.clientWidth;
        this.adjust();
    }

    public ngAfterViewInit(): void {
        // set element resize allowed manually by user
        const style = window.getComputedStyle(this.el, null);
        if (style.resize === 'both') {
            this.el.style.resize = 'horizontal';
        } else if (style.resize === 'vertical') {
            this.el.style.resize = 'none';
        }
        requestAnimationFrame(() => {
            this.adjust();
        });
    }

    @HostListener('input', ['$event.target'])
    public onInput(textArea: HTMLTextAreaElement): void {
        this.adjust();
    }

    public adjust(): void {
        if (this.el.scrollHeight === 0) {
            return;
        }
        // perform height adjustments after input changes, if height is different
        if (this.el.style.height === `${this.element.nativeElement.scrollHeight}px`) {
            return;
        }
        this.el.style.overflow = 'hidden';
        this.el.style.height = 'auto';
        this.el.style.height = `${this.el.scrollHeight}px`;
    }

    public updateMinHeight(): void {
        // set textarea min height if input defined
        this.el.style.minHeight = `${this._minHeight}px`;
        this.el.style.overflow = 'hidden';
    }

    public updateMaxHeight(): void {
        // set textarea max height if input defined
        this.el.style.maxHeight = `${this._maxHeight}px`;
    }
}
