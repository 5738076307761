import {Directive, ElementRef, HostListener, Input, NgZone, OnDestroy, Renderer2} from '@angular/core';
import {Edges} from './interfaces/edges.interface';
import {ResizableDirective} from './resizable.directive';

/**
 * An element placed inside a `mwlResizable` directive to be used as a drag and resize handle
 *
 * For example
 *
 * ```html
 * <div mwlResizable>
 *   <div mwlResizeHandle [resizeEdges]="{bottom: true, right: true}"></div>
 * </div>
 * ```
 */
@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[mwlResizeHandle]',
})
export class ResizeHandleDirective implements OnDestroy {
    /**
     * The `Edges` object that contains the edges of the parent element that dragging the
     * handle will trigger a resize on
     */
    @Input() public resizeEdges: Edges = {};

    private readonly eventListeners: {
        touchmove?(): void;
        mousemove?(): void;
        [key: string]: (() => void) | undefined;
    } = {};

    public constructor(
        private readonly renderer: Renderer2,
        private readonly element: ElementRef,
        private readonly zone: NgZone,
        private readonly resizable: ResizableDirective
    ) {}

    public ngOnDestroy(): void {
        this.unsubscribeEventListeners();
    }

    /**
     * @hidden
     */
    @HostListener('touchstart', ['$event', '$event.touches[0].clientX', '$event.touches[0].clientY'])
    @HostListener('mousedown', ['$event', '$event.clientX', '$event.clientY'])
    public onMousedown(event: MouseEvent | TouchEvent, clientX: number, clientY: number): void {
        event.preventDefault();
        this.zone.runOutsideAngular(() => {
            if (!this.eventListeners.touchmove) {
                this.eventListeners.touchmove = this.renderer.listen(
                    this.element.nativeElement,
                    'touchmove',
                    (touchMoveEvent: TouchEvent) => {
                        this.onMousemove(
                            touchMoveEvent,
                            touchMoveEvent.targetTouches[0].clientX,
                            touchMoveEvent.targetTouches[0].clientY
                        );
                    }
                );
            }
            if (!this.eventListeners.mousemove) {
                this.eventListeners.mousemove = this.renderer.listen(
                    this.element.nativeElement,
                    'mousemove',
                    (mouseMoveEvent: MouseEvent) => {
                        this.onMousemove(mouseMoveEvent, mouseMoveEvent.clientX, mouseMoveEvent.clientY);
                    }
                );
            }
            this.resizable.mousedown.next({
                clientX,
                clientY,
                edges: this.resizeEdges,
            });
        });
    }

    /**
     * @hidden
     */
    @HostListener('touchend', ['$event.changedTouches[0].clientX', '$event.changedTouches[0].clientY'])
    @HostListener('touchcancel', ['$event.changedTouches[0].clientX', '$event.changedTouches[0].clientY'])
    @HostListener('mouseup', ['$event.clientX', '$event.clientY'])
    public onMouseup(clientX: number, clientY: number): void {
        this.zone.runOutsideAngular(() => {
            this.unsubscribeEventListeners();
            this.resizable.mouseup.next({
                clientX,
                clientY,
                edges: this.resizeEdges,
            });
        });
    }

    private onMousemove(event: MouseEvent | TouchEvent, clientX: number, clientY: number): void {
        this.resizable.mousemove.next({
            clientX,
            clientY,
            edges: this.resizeEdges,
            event,
        });
    }

    private unsubscribeEventListeners(): void {
        Object.keys(this.eventListeners).forEach((type) => {
            (this as any).eventListeners[type]();
            delete this.eventListeners[type];
        });
    }
}
