<ng-container *ngIf="ready">
    <h2 translate>admin_application_settings</h2>
    <form clrForm #f="ngForm">
        <clr-input-container>
            <label><clr-icon shape="flag"></clr-icon> {{ 'app_name' | translate }}</label>
            <input
                clrInput
                class="clr-col-xl-6"
                type="text"
                [(ngModel)]="appName"
                name="appName"
                placeholder="Project Manager"
                required
            />
        </clr-input-container>

        <clr-input-container>
            <label><clr-icon shape="envelope"></clr-icon> {{ 'mail_domain' | translate }}</label>
            <input
                clrInput
                class="clr-col-xl-6"
                type="text"
                [(ngModel)]="mailDomain"
                name="mailDomain"
                placeholder="example.com"
                pattern="^([a-z][a-z0-9]+\.[a-z]+)+$"
                required
            />
        </clr-input-container>

        <div class="clr-row btn-week">
            <label class="clr-control-label">
                <clr-icon shape="event"></clr-icon> {{ 'working_days' | translate }}
            </label>
            <div class="clr-col clr-col-xl-6 clr-col-lg-10 clr-col-md-10">
                <div class="btn-group btn-primary">
                    <button
                        class="btn"
                        [ngClass]="{'btn-danger': !day.enabled}"
                        (click)="day.enabled = !day.enabled"
                        *ngFor="let day of days"
                    >
                        {{ day.initial }}
                    </button>
                </div>
            </div>
        </div>

        <clr-input-container>
            <label><clr-icon shape="clock"></clr-icon> {{ 'working_hours' | translate }}</label>
            <clr-control-helper>
                <span [ngClass]="{'day-error': workingHours.error}">
                    {{ 'duration' | translate }}: {{ workingHours.durationStr }}
                </span>
            </clr-control-helper>
        </clr-input-container>

        <div class="hour-container">
            <clr-input-container>
                <label>{{ 'day_start' | translate }}</label>
                <input
                    clrInput
                    class="clr-col-xl-6"
                    type="time"
                    [(ngModel)]="workingHours.start"
                    (ngModelChange)="updateDuration(workingHours)"
                    name="workingHoursStart"
                    required
                />
            </clr-input-container>

            <clr-input-container>
                <label>{{ 'day_end' | translate }}</label>
                <input
                    clrInput
                    class="clr-col-xl-6"
                    type="time"
                    [(ngModel)]="workingHours.end"
                    (ngModelChange)="updateDuration(workingHours)"
                    name="workingHoursEnd"
                    required
                />
            </clr-input-container>
        </div>

        <clr-input-container>
            <label><clr-icon shape="hourglass"></clr-icon> {{ 'lunch_time' | translate }}</label>
            <clr-control-helper>
                <span [ngClass]="{'day-error': lunchHours.error}">
                    {{ 'duration' | translate }}: {{ lunchHours.durationStr }}
                </span>
            </clr-control-helper>
        </clr-input-container>

        <div class="hour-container">
            <clr-input-container>
                <label>{{ 'lunch_start' | translate }}</label>
                <input
                    clrInput
                    class="clr-col-xl-6"
                    type="time"
                    [(ngModel)]="lunchHours.start"
                    (ngModelChange)="updateDuration(lunchHours, true)"
                    name="lunchHoursStart"
                    required
                />
            </clr-input-container>

            <clr-input-container>
                <label>{{ 'lunch_end' | translate }}</label>
                <input
                    clrInput
                    class="clr-col-xl-6"
                    type="time"
                    [(ngModel)]="lunchHours.end"
                    (ngModelChange)="updateDuration(lunchHours, true)"
                    name="lunchHoursEnd"
                    required
                />
            </clr-input-container>
        </div>

        <clr-input-container>
            <label><clr-icon shape="calendar"></clr-icon> {{ 'calendar_time' | translate }}</label>
            <clr-control-helper *ngIf="calendarHours.error">
                <span [ngClass]="{'day-error': calendarHours.error}" translate>
                    hours_begin_before_end
                </span>
            </clr-control-helper>
        </clr-input-container>

        <div class="hour-container">
            <clr-input-container>
                <label>{{ 'calendar_start' | translate }}</label>
                <input
                    clrInput
                    class="clr-col-xl-6"
                    type="time"
                    [(ngModel)]="calendarHours.start"
                    name="calendarHoursStart"
                    (ngModelChange)="checkStartEnd(calendarHours)"
                    required
                />
            </clr-input-container>

            <clr-input-container>
                <label>{{ 'calendar_end' | translate }}</label>
                <input
                    clrInput
                    class="clr-col-xl-6"
                    type="time"
                    [(ngModel)]="calendarHours.end"
                    (ngModelChange)="checkStartEnd(calendarHours)"
                    name="calendarHoursEnd"
                    required
                />
            </clr-input-container>
        </div>

        <clr-select-container>
            <label><clr-icon shape="paint-roller"></clr-icon> {{ 'default_theme' | translate }}</label>
            <select clrSelect name="defaultTheme" [(ngModel)]="defaultTheme">
                <option *ngFor="let theme of SETTING_THEME_VALUES_VALUES" [ngValue]="theme">
                    {{ 'theme_' + theme | lowercase | translate }}
                </option>
            </select>
        </clr-select-container>
    </form>

    <button
        class="btn btn-success mt-1"
        [clrLoading]="validateBtnState"
        [disabled]="
            calendarHours?.error ||
            workingHours?.error ||
            lunchHours?.error ||
            (!f.dirty && !workingDaysTouched()) ||
            !validate(f, false)
        "
        (click)="save()"
    >
        {{ 'form_save' | translate }}
    </button>
    <ng-container *ngIf="authService?.user?.settings?.super_admin">
        <h2 translate>admin_application_tools</h2>

        <form (submit)="openSignedLink()">
            <clr-input-container>
                <label><clr-icon shape="link"></clr-icon> {{ 'open_signed_link_label' | translate }}</label>
                <input
                    clrInput
                    class="clr-col-xl-6"
                    type="text"
                    [(ngModel)]="link"
                    name="lunchHoursEnd"
                    appRequiredTrim
                />
            </clr-input-container>

            <button class="btn btn-outline mt-1" [disabled]="!link">{{ 'open_link' | translate }}</button>
        </form>
    </ng-container>

    <form class="mb-2">
        <clr-input-container>
            <label><clr-icon shape="code"></clr-icon> {{ 'version' | translate }}</label>
            <input clrInput class="clr-col-xl-6" type="text" [attr.value]="WWW_VERSION" name="appName" disabled />
        </clr-input-container>
    </form>
</ng-container>
