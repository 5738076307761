import * as i0 from "./tabs.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@clr/angular";
import * as i3 from "@angular/common";
import * as i4 from "./tabs.component";
import * as i5 from "@angular/router";
var styles_TabsComponent = [i0.styles];
var RenderType_TabsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TabsComponent, data: {} });
export { RenderType_TabsComponent as RenderType_TabsComponent };
function View_TabsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "tab c-hand\n                "]], [[2, "sublime", null], [2, "active", null], [4, "background", null], [1, "data-tab", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clicked.emit(_v.context.$implicit.justAButton) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "input", [["class", "d-none"], ["type", "radio"]], [[8, "id", 0], [8, "checked", 0], [8, "disabled", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.selectTab(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "label", [], [[2, "text-dark", null], [2, "text-light", null], [1, "for", 0]], null, null, null, null)), i1.ɵdid(3, 212992, null, 0, i2.ClrLabel, [[2, i2.ɵq], [2, i2.ɵr], [2, i2.ɵs], i1.Renderer2, i1.ElementRef], { forAttr: [0, "forAttr"] }, null), (_l()(), i1.ɵted(4, null, ["", ""]))], function (_ck, _v) { var currVal_10 = ("tab" + _v.context.index); _ck(_v, 3, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.sublime; var currVal_1 = _v.context.$implicit.isActive; var currVal_2 = _v.context.$implicit.bgColor; var currVal_3 = _v.context.$implicit.name; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = ("tab" + _v.context.index); var currVal_5 = _v.context.$implicit.isActive; var currVal_6 = _v.context.$implicit.disabled; _ck(_v, 1, 0, currVal_4, currVal_5, currVal_6); var currVal_7 = (_v.context.$implicit.bgColor ? _co.isColorDark(_v.context.$implicit.bgColor) : false); var currVal_8 = (_v.context.$implicit.bgColor ? !_co.isColorDark(_v.context.$implicit.bgColor) : false); var currVal_9 = i1.ɵnov(_v, 3).forAttr; _ck(_v, 2, 0, currVal_7, currVal_8, currVal_9); var currVal_11 = ((_v.context.$implicit.label || _co.labels[_v.context.$implicit.name]) || _v.context.$implicit.name); _ck(_v, 4, 0, currVal_11); }); }
function View_TabsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
export function View_TabsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { tabsContainer: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "tabs-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "clr-row"]], [[2, "sublime", null]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, [[1, 0], ["tabsContainer", 1]], null, 2, "div", [["class", "clr-row tabs"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TabsComponent_1)), i1.ɵdid(5, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TabsComponent_2)), i1.ɵdid(7, 540672, null, 0, i3.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "tab-container"]], null, null, null, null, null)), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co._tabs; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.tabsHeader; _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.sublime; _ck(_v, 2, 0, currVal_0); }); }
export function View_TabsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-tabs", [], null, null, null, View_TabsComponent_0, RenderType_TabsComponent)), i1.ɵdid(1, 1294336, null, 2, i4.TabsComponent, [i5.Router, i5.ActivatedRoute], null, null), i1.ɵqud(603979776, 1, { _tabs: 1 }), i1.ɵqud(335544320, 2, { tabsHeader: 0 })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TabsComponentNgFactory = i1.ɵccf("app-tabs", i4.TabsComponent, View_TabsComponent_Host_0, { labels: "labels", queryParamKey: "queryParamKey", sublime: "sublime", activeTab: "activeTab" }, { activeTabChange: "activeTabChange", clicked: "clicked" }, ["*"]);
export { TabsComponentNgFactory as TabsComponentNgFactory };
