import {ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {FileStateType} from '../../../../defs/schema-static';
import {FileDownloaderService} from '../shared/file-downloader/file-downloader.service';
import {FileManagerService, IUploadingFile} from '../shared/file-manager/file-manager.service';

@Component({
    selector: 'app-file-manager',
    templateUrl: './file-manager.component.html',
    styleUrls: ['./file-manager.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileManagerComponent implements OnInit, OnDestroy {
    public reduced = false;
    public minimized = true;

    private uploadingFileStateSubscription: Subscription;

    public constructor(
        private readonly fileManager: FileManagerService,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly fileDownloader: FileDownloaderService
    ) {}

    public ngOnInit() {
        this.uploadingFileStateSubscription = this.fileManager.uploadingFileState.subscribe(() => {
            this.changeDetectorRef.detectChanges();
        });
    }

    public ngOnDestroy() {
        if (this.uploadingFileStateSubscription) {
            this.uploadingFileStateSubscription.unsubscribe();
            this.uploadingFileStateSubscription = undefined;
        }
    }

    public reduce() {
        this.reduced = true;
        this.minimized = false;
    }

    public maximize() {
        this.minimized = this.reduced = false;
    }

    public get files(): IUploadingFile[] {
        return this.fileManager.uploadingFiles;
    }

    public get filesRunning(): IUploadingFile[] {
        return this.fileManager.uploadingFilesRunning;
    }

    public get filesOver(): IUploadingFile[] {
        return this.fileManager.uploadingFilesOver;
    }

    public getFilesState(...states: FileStateType[]) {
        return this.files.filter((file) => states.includes(file.state));
    }

    public clear() {
        this.fileManager.clearFilesOver();
    }

    public async cancelAll() {
        return this.fileManager.cancelFiles(...this.filesRunning);
    }

    public async pauseAll() {
        return this.fileManager.pauseFiles(...this.filesRunning);
    }

    public async resumeAll() {
        return this.fileManager.resumeFiles(...this.filesRunning);
    }

    public get visible(): boolean {
        return this.files.length > 0;
    }

    public get averageProgress() {
        return (
            this.filesRunning.reduce(
                (sum: number, file) =>
                    sum +
                        Number(file.transferStats && (file.transferStats.totalUploaded || file.transferStats.loaded)) ||
                    0,
                0
            ) / this.filesRunning.reduce((sum, file) => sum + file.size || 0, 0) || 1
        );
    }

    @HostListener('window:beforeunload', ['$event'])
    public confirmUnloadChanges($event: BeforeUnloadEvent) {
        if (this.filesRunning.length > 0) {
            $event.preventDefault();

            return ($event.returnValue = true);
        }
    }

    public readonly getFilename = FileManagerService.getFilename;
    public readonly FileStateType = FileStateType;
    public readonly downloadS3 = this.fileDownloader.downloadS3.bind(this.fileDownloader);
}
