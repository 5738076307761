import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ClrLoadingState} from '@clr/angular';
import {TranslateService} from '@ngx-translate/core';
import {IProjectDash} from '../../../../defs/businessRules';
import publicConfig from '../../../../defs/config/config.json';
import {
    ApiRoutePlurality,
    HTTP_METHOD,
    MilestonesType,
    ProjectMemberType,
    ProjectStatusType,
    ReleaseStateType,
    RIGHTS,
    TaskStatusType,
} from '../../../../defs/schema-static';
import {EMPLOYEE_SCHEMA_ROUTE, IEmployee} from '../../../../defs/schema/public/Employees';
import {IProject, PROJECT_SCHEMA_ROUTE} from '../../../../defs/schema/public/Projects';
import {IStickyNote, STICKY_NOTE_SCHEMA_ROUTE} from '../../../../defs/schema/public/StickyNote';
import {EMPLOYEE_SORT_FUNCTION, PROJECT_MEMBER_SORT_FUNCTION} from '../../../../defs/sorters';
import {
    isColorDark,
    MAX_LENGTH_PROJECT_CODE,
    noop,
    PROJECT_STATUS_FILTER,
    PROJECT_TABS,
    PROJECT_TABS_LABELS,
    PROJECT_TABS_RIGHTS,
} from '../app-static';
import {AuthService} from '../auth/auth.service';
import {validate} from '../forms/validators/form.validator';
import {ModalSimpleComponent} from '../modal-simple/modal-simple.component';
import {ModalSimpleService} from '../modal-simple/modal-simple.service';
import {ProjectWizardProjectComponent} from '../project-wizard/project/project-wizard-project.component';
import {HttpRestService} from '../shared/http-rest/http-rest.service';
import {LONG_AVATAR_TYPE} from '../shared/long-avatar/long-avatar.component';
import {SHORTCUT_LOCAL, ShortcutHandlerService} from '../shared/shortcut-handler/shortcut-handler.service';
import {TOAST_TYPE, ToastService} from '../shared/toast/toast.service';

@Component({
    selector: 'app-project',
    templateUrl: './project.component.html',
})
export class ProjectComponent implements OnInit, OnDestroy {
    @ViewChild(ProjectWizardProjectComponent) public editComponent: ProjectWizardProjectComponent;

    public project: IProjectDash = {
        code: '',
        obs: '',
        status: ProjectStatusType.OPEN,
        clientId: 0,
        tasks: [],
    }; // project
    public displayProject: IProjectDash = {...this.project};

    public showEditModal = false;

    public employees: IEmployee[] = [];
    public stickyNotes: IStickyNote[] = [];

    public validateBtnState = ClrLoadingState.DEFAULT;

    public constructor(
        private readonly httpRest: HttpRestService,
        private readonly route: ActivatedRoute,
        public authService: AuthService,
        private readonly router: Router,
        private readonly shortcutHandlerService: ShortcutHandlerService,
        private readonly toastService: ToastService,
        private readonly translate: TranslateService,
        private readonly modalSimpleService: ModalSimpleService
    ) {
        this.fetchData();
    }

    public ngOnInit() {
        this.shortcutHandlerService.register(
            {
                shortcut: SHORTCUT_LOCAL.CANCEL,
                callback: () => {
                    (async () => this.router.navigate(['/projects']))();
                },
                context: this,
            },
            true
        );
    }

    // tslint:disable-next-line:prefer-function-over-method no-empty
    public ngOnDestroy() {}

    private fetchData() {
        this.route.params.subscribe(async ({id}: {id: string}) => {
            // milestones, budgets, tasks, times, releases, backups
            this.httpRest
                ._request<IProjectDash>(
                    HTTP_METHOD.GET,
                    ApiRoutePlurality.SINGULAR,
                    PROJECT_SCHEMA_ROUTE,
                    `complete/${id}`
                )
                .subscribe((project) => {
                    this.project = {
                        ...project,
                        tasks: [],
                        projectMembers: (project.projectMembers || []).sort(PROJECT_MEMBER_SORT_FUNCTION),
                    };
                    this.displayProject = JSON.parse(JSON.stringify(this.project));
                });

            // stickyNotes
            this.httpRest
                ._request<IStickyNote[]>(
                    HTTP_METHOD.GET,
                    ApiRoutePlurality.PLURAL,
                    STICKY_NOTE_SCHEMA_ROUTE,
                    `project/${id}`
                )
                .subscribe((stickyNotes) => (this.stickyNotes = stickyNotes));

            this.httpRest
                ._request<IEmployee[]>(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, EMPLOYEE_SCHEMA_ROUTE, 'project')
                .subscribe((employees) => (this.employees = employees.sort(EMPLOYEE_SORT_FUNCTION)));
        });
    }

    // ------------------------------------ Project
    public async saveProject() {
        if (!this.editComponent.form || !this.editComponent.form.valid) {
            return;
        }

        this.validateBtnState = ClrLoadingState.LOADING;

        const project = this.editComponent.form.getRawValue();

        try {
            await this.httpRest
                .post<IProject>(PROJECT_SCHEMA_ROUTE, {
                    id: this.project.id,
                    ...project,
                })
                .toPromise();
        } catch (err) {
            return;
        } finally {
            this.showEditModal = false;
        }

        this.validateBtnState = ClrLoadingState.SUCCESS;
        Object.assign(this.project, project);

        this.toastService.show({
            type: TOAST_TYPE.SUCCESS,
            text: 'success_update_project',
        });
    }

    public deleteProjectModal() {
        this.modalSimpleService
            .open(ModalSimpleComponent, {
                title: 'project_delete_modal_title',
                contentI18n: 'swal_delete_attch',
                contentObj: {what: this.project.obs},
                ok: {
                    i18n: 'delete_project',
                    class: 'btn-danger',
                },
            })
            .subscribe((closed) => {
                if (closed.result) {
                    this.deleteProject();
                }
            }, noop);
    }

    public async deleteProject() {
        try {
            await this.httpRest.deleteId(PROJECT_SCHEMA_ROUTE, this.project.id).toPromise();
        } catch (err) {
            return undefined;
        }

        this.toastService.show({
            type: TOAST_TYPE.SUCCESS,
            text: 'success_delete_project',
        });

        return this.router.navigate(['/projects']);
    }

    // ------------------------------------ Readonly
    public readonly MilestonesType = MilestonesType;
    public readonly ReleaseStateType = ReleaseStateType;
    protected readonly TaskStatusType = TaskStatusType;

    public readonly PROJECT_TABS = PROJECT_TABS;
    public readonly PROJECT_TABS_LABELS = PROJECT_TABS_LABELS;
    public readonly PROJECT_STATUS_FILTER = PROJECT_STATUS_FILTER;
    public readonly validate = validate;

    public readonly LONG_AVATAR_TYPE = LONG_AVATAR_TYPE;

    protected readonly isColorDark = isColorDark;

    protected readonly ProjectMemberType = ProjectMemberType;

    protected readonly publicConfig = publicConfig;

    protected readonly RIGHTS = RIGHTS;

    public readonly PROJECT_TABS_RIGHTS = PROJECT_TABS_RIGHTS;
    public readonly statusList = Object.keys(ProjectStatusType);

    public readonly MAX_LENGTH_PROJECT_CODE = MAX_LENGTH_PROJECT_CODE;
}
