import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Moment} from 'moment';
import {
    ApiRoutePlurality,
    AssignedEmployeeType,
    HTTP_METHOD,
    MilestonesType,
    TaskStatusType,
} from '../../../../defs/schema-static';
import {IAssignedTask} from '../../../../defs/schema/public/AssignedTasks';
import {IClient} from '../../../../defs/schema/public/Clients';
import {IEmployee} from '../../../../defs/schema/public/Employees';
import {IMilestone, MILESTONE_SCHEMA_ROUTE} from '../../../../defs/schema/public/Milestones';
import {IProject, PROJECT_SCHEMA_ROUTE} from '../../../../defs/schema/public/Projects';
import {ITaskMail, TASK_MAIL_SCHEMA_ROUTE} from '../../../../defs/schema/public/TaskMails';
import {ITask, TASK_SCHEMA_ROUTE} from '../../../../defs/schema/public/Tasks';
import {SETTINGS, SETTINGS_FILTER_ROADMAP_VALUES} from '../../../../defs/schema/public/Users';
import {chartMainColor, IDashEvent} from '../app-static';
import {AuthService} from '../auth/auth.service';
import {INgxSerie} from '../report/report-project/report-project.component';
import {SettingsService} from '../settings/settings.service';
import {HttpRestService} from '../shared/http-rest/http-rest.service';
import {MomentService} from '../shared/moment/moment.service';

export interface IDistribution {
    [key: string]: number;
}

export interface IDashMilestoneTime {
    total: number;
    done: number;
    lateTasks: any[];
}

export interface IDashMilestone {
    client: Partial<IClient>;
    milestone: Partial<IMilestone>;
    distribution: IDistribution;
    time: IDashMilestoneTime;
    devs: number[];
    project: Partial<IProject>;
}

export interface IDashActivity {
    date: Moment;
    subjectId: number;
    subject: string;
    topic: string;
    type: string;
    who: Partial<IEmployee>;
    params: any;
    subtitle: string;
}

export interface IDashTask {
    id: number;
    projectId: number;
    project: IProject;
    code: string;
    name: string;
    status: TaskStatusType;
    assigned: IAssignedTask[];
    _metadata: any;
}

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
    public pieTasks: any;
    public pieTasksShow = false;
    public pieBugs: any;
    public pieBugsShow = false;
    public pieTimeSheet: any;
    public pieTimeSheetShow = false;
    public pieCompletion: any;
    public pieCompletionShow = false;

    public events: IDashEvent[];

    public pleaseWaitActivities = true;
    public activities: IDashActivity[] = [];

    public projectsState: any;
    public projectsStateShow = false;

    public projectsDivision: any;
    public projectsDivisionShow = false;

    public pleaseWaitRoad = true;
    public roadmap: IDashMilestone[];
    public roadmapFilter: string;
    public roadmapTmp: IDashMilestone[];

    public tasks: IDashTask[];
    public pleaseWaitTask = true;

    // overall tasks to do
    public mails: Partial<ITaskMail>[];
    public pleaseWaitMail = true;
    public meetings: IMilestone[];
    public pleaseWaitMeeting = true;

    public readonly colors = chartMainColor;

    public readonly rodmapSelectOptions = [
        {
            i18n: 'show_all_releases',
            value: SETTINGS_FILTER_ROADMAP_VALUES.ALL,
        },
        {
            i18n: 'only_my_releases',
            value: SETTINGS_FILTER_ROADMAP_VALUES.MY,
        },
    ];

    public constructor(
        private readonly httpRest: HttpRestService,
        private readonly auth: AuthService,
        private readonly settingsService: SettingsService,
        private readonly translate: TranslateService,
        private readonly momentService: MomentService
    ) {}

    public ngOnInit() {
        this.getPieTasks();
        this.getPieBugs();
        this.getOverallCompletion();
        this.getTimeSheet();
        this.getProjectsDivision();
        this.getProjectsState();
        this.getRoadMap().subscribe((milestones) => {
            this.roadmapTmp = JSON.parse(JSON.stringify(milestones));
            this.roadmapFilter =
                this.settingsService.settings[SETTINGS.ROADMAP_FILTER] || SETTINGS_FILTER_ROADMAP_VALUES.ALL;
            this.changeRoadmapFilter();
            this.pleaseWaitRoad = false;
        });
        this.getCalendarEvents().subscribe((milestones) => {
            this.events = [];
            milestones.map((milestone) => {
                if (milestone.type === MilestonesType.MEETING) {
                    if (milestone.participants.findIndex((p) => p.employeeId === this.auth.user.employee.id) > -1) {
                        this.events.push({
                            avatar: DashboardComponent.getMileStoneAvatar(milestone),
                            id: milestone.id,
                            subtitle: milestone.obs,
                            type: milestone.type,
                            color: DashboardComponent.getMilestoneColor(milestone),
                            title: DashboardComponent.getMilestoneTitle(milestone),
                            begin: this.momentService.moment(milestone.beginDate),
                            end: this.getEndDate(milestone),
                        });
                    }

                    return undefined;
                }
                this.events.push({
                    avatar: DashboardComponent.getMileStoneAvatar(milestone),
                    id: milestone.id,
                    subtitle: milestone.obs,
                    type: milestone.type,
                    color: DashboardComponent.getMilestoneColor(milestone),
                    title: DashboardComponent.getMilestoneTitle(milestone),
                    begin: this.momentService.moment(milestone.beginDate),
                    end: this.getEndDate(milestone),
                });
            });
        });
        this.getAssignedTasks();
        // this.getActivities();
        this.getPastMeetings();
        this.getMails();
    }

    public isDark = () => document.querySelector('.Wrapper').classList.contains('theme-dark');

    public getProjectsState() {
        this.httpRest
            ._request<IProject[]>(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, PROJECT_SCHEMA_ROUTE, 'dashboard/state')
            .subscribe((data) => {
                this.projectsState = data;
                this.projectsStateShow = true;
            });
    }

    public getProjectsDivision() {
        this.httpRest
            ._request<IProject[]>(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, PROJECT_SCHEMA_ROUTE, 'dashboard/division')
            .subscribe((data) => {
                this.projectsDivision = data;
                this.projectsDivisionShow = true;
            });
    }

    public getTimeSheet() {
        this.httpRest
            ._request<ITask[]>(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, TASK_SCHEMA_ROUTE, 'dashboard/timesheet')
            .subscribe(async (data) => {
                this.pieTimeSheet = await this.translateSerie(data);
                this.pieTimeSheetShow = true;
            });
    }

    public getPieTasks() {
        this.httpRest
            ._request<ITask[]>(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, TASK_SCHEMA_ROUTE, 'dashboard/division')
            .subscribe(async (data) => {
                this.pieTasks = await this.translateSerie(data);
                this.pieTasksShow = true;
            });
    }

    public getPieBugs() {
        this.httpRest
            ._request<ITask[]>(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, TASK_SCHEMA_ROUTE, 'dashboard/bugs')
            .subscribe(async (data) => {
                this.pieBugs = await this.translateSerie(data, 'project_');
                this.pieBugsShow = true;
            });
    }

    public getOverallCompletion() {
        this.httpRest
            ._request<IProject[]>(
                HTTP_METHOD.GET,
                ApiRoutePlurality.PLURAL,
                PROJECT_SCHEMA_ROUTE,
                'dashboard/completion'
            )
            .subscribe(async (data) => {
                this.pieCompletion = await this.translateSerie(data, 'project_');
                this.pieCompletionShow = true;
            });
    }

    public changeRoadmapFilter() {
        if (this.roadmapFilter === SETTINGS_FILTER_ROADMAP_VALUES.ALL) {
            this.roadmap = this.roadmapTmp;
        } else {
            this.roadmap = this.roadmapTmp.filter((f) => f.devs.indexOf(this.auth.user.employee.id) !== -1);
        }
        if (this.settingsService.settings[SETTINGS.ROADMAP_FILTER] !== this.roadmapFilter) {
            this.settingsService.settings[SETTINGS.ROADMAP_FILTER] = this.roadmapFilter;
            (async () => this.settingsService.save())();
        }
    }

    public static getMileStoneAvatar(milestone: IMilestone) {
        if (milestone.client) {
            return milestone.client.user.code;
        } else {
            return milestone.project.code;
        }
    }

    public static getMilestoneColor(milestone: IMilestone) {
        if (milestone.client) {
            return milestone.client.user.color;
        } else {
            return milestone.project.color;
        }
    }

    private async translateSerie(serie: any[], prefix = '') {
        return Promise.all<INgxSerie>(
            serie.map(async (m) => {
                return {
                    value: m.value,
                    name: await this.translate.get(prefix + (m.name as string).toLowerCase()).toPromise(),
                };
            })
        );
    }

    public static getMilestoneTitle(milestone: IMilestone) {
        const base = milestone.type;
        if (milestone.client) {
            return milestone.client ? `${milestone.client.user.name}(${milestone.client.user.code})` : 'Unknown';
        } else {
            return milestone.project ? `${milestone.project.obs}(${milestone.project.code})` : 'Unknown';
        }
    }

    public getEndDate(milestone: IMilestone) {
        if (milestone.type === MilestonesType.RELEASE) {
            return this.momentService.moment(milestone.endDate || milestone.target);
        }

        return null;
    }

    public getCalendarEvents() {
        return this.httpRest._request<IMilestone[]>(
            HTTP_METHOD.GET,
            ApiRoutePlurality.PLURAL,
            MILESTONE_SCHEMA_ROUTE,
            'calendar/events'
        );
    }

    public dismissTaskMail(mail: ITaskMail) {
        this.httpRest
            .post<ITaskMail>(TASK_MAIL_SCHEMA_ROUTE, {
                id: mail.id,
                dismissed: true,
            })
            .subscribe((_mail) => {
                mail.dismissed = _mail.dismissed;
            });
    }

    public getMails() {
        this.httpRest
            ._request<ITaskMail[]>(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, TASK_MAIL_SCHEMA_ROUTE, 'employee')
            .subscribe((mails) => {
                this.mails = mails;
                this.pleaseWaitMail = false;
            });
    }

    private getPastMeetings() {
        this.httpRest
            ._request<IMilestone[]>(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, MILESTONE_SCHEMA_ROUTE, 'meeting')
            .subscribe((meetings) => {
                this.meetings = meetings
                    .filter((meeting) => {
                        return (
                            meeting.participants.length === 0 ||
                            meeting.participants.findIndex((p) => p.employeeId === this.auth.user.employee.id) > -1
                        );
                    })
                    .sort((a, b) => {
                        const _a = this.momentService.moment(a.beginDate);
                        const _b = this.momentService.moment(b.beginDate);

                        return _a.isBefore(_b) ? 1 : -1;
                    });
                this.pleaseWaitMeeting = false;
            });
    }

    public getRoadMap() {
        return this.httpRest._request<IDashMilestone[]>(
            HTTP_METHOD.GET,
            ApiRoutePlurality.PLURAL,
            MILESTONE_SCHEMA_ROUTE,
            'roadmap'
        );
    }

    // public getActivities() {
    //     this.httpRest
    //         ._request<IDashActivity[]>(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, TASK_SCHEMA_ROUTE, 'activities')
    //         .subscribe((data) => {
    //             this.activities = data;
    //             this.pleaseWaitActivities = false;
    //         });
    // }

    public getAssignedTasks() {
        this.httpRest
            ._request<IDashTask[]>(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, TASK_SCHEMA_ROUTE, 'assigned')
            .subscribe((data) => {
                this.tasks = data;
                this.tasks = this.tasks.filter((t) => {
                    if (t.assigned[0].type === AssignedEmployeeType.DEVELOPER) {
                        return true;
                    } else {
                        if (t.status === TaskStatusType.REVIEWING || t.status === TaskStatusType.REVIEW) {
                            return true;
                        }
                    }

                    return false;
                });
                this.pleaseWaitTask = false;
            });
    }

    public getCompletion = (project: any) => {
        if (!project) {
            return 0;
        }

        return parseFloat(((project.completed / project.total) * 100).toFixed(0));
    };

    public getDevVSTest = (project: any) => {
        return `${project.dev} / ${project.test}`;
    };

    public getFailedReviewRate = (project: any) => {
        if (project.dev === 0) {
            return (0).toFixed(2);
        }

        return ((project.failedReview / project.dev) * 100).toFixed(2);
    };
}
