import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ClrDatagridSortOrder} from '@clr/angular';
import {ApiRoutePlurality, HTTP_METHOD, RIGHTS} from '../../../../defs/schema-static';
import {IClient} from '../../../../defs/schema/public/Clients';
import {EMPLOYEE_FIELD} from '../../../../defs/schema/public/Employees';
import {PROJECT_FIELD} from '../../../../defs/schema/public/Projects';
import {USER_FIELD} from '../../../../defs/schema/public/Users';
import {IWikiPage, WIKI_PAGE_FIELD, WIKI_PAGE_SCHEMA_ROUTE} from '../../../../defs/schema/public/WikiPage';
import {DATAGRID_FILTER_TYPE, IDatagridColumn} from '../app-static';
import {HttpRestService} from '../shared/http-rest/http-rest.service';
import {LONG_AVATAR_TYPE} from '../shared/long-avatar/long-avatar.component';

@Component({
    selector: 'app-changelogs-list',
    templateUrl: './changelogs-list.component.html',
    styleUrls: ['./changelogs-list.component.scss'],
})
export class ChangelogsListComponent implements OnInit, OnChanges {
    @Input() public client: IClient;
    @Input() public changelogs: IWikiPage[];

    public constructor(private readonly httpRest: HttpRestService) {}

    public ngOnInit() {
        this.getChangelogs();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.client) {
            this.changelogs = undefined;
            this.getChangelogs();
        }
    }

    public getChangelogs() {
        if (!this.client || !this.client.id) {
            return;
        }

        this.httpRest
            ._request<IWikiPage[]>(
                HTTP_METHOD.GET,
                ApiRoutePlurality.PLURAL,
                WIKI_PAGE_SCHEMA_ROUTE,
                `changelog/client/${this.client.id}`
            )
            .subscribe((changelogs) => {
                this.changelogs = [...changelogs];
            });
    }

    public readonly RIGHTS = RIGHTS;
    public readonly DATAGRID_FILTER_TYPE = DATAGRID_FILTER_TYPE;

    public readonly CHANGELOG_COLUMNS: IDatagridColumn[] = [
        {field: WIKI_PAGE_FIELD.title, translateKey: 'table_title'},
        {
            field: `${WIKI_PAGE_FIELD.project}.${PROJECT_FIELD.obs}`,
            translateKey: 'project',
        },
        {field: `${WIKI_PAGE_FIELD.author}.${EMPLOYEE_FIELD.user}.${USER_FIELD.name}`, translateKey: 'author'},
        {
            field: WIKI_PAGE_FIELD.updatedAt,
            translateKey: 'date',
            order: ClrDatagridSortOrder.DESC,
            filterType: DATAGRID_FILTER_TYPE.DATE,
        },
    ];
    public filterOpen: {[key: string]: boolean} = {};

    public readonly LONG_AVATAR_TYPE = LONG_AVATAR_TYPE;
}
