import * as i0 from "@angular/core";
import * as i1 from "../../admin-panel/admin-access/users-list/admin-access-users-list.component.ngfactory";
import * as i2 from "../../admin-panel/admin-access/users-list/admin-access-users-list.component";
import * as i3 from "./employee-wizard-group-rights.component";
import * as i4 from "../../shared/http-rest/http-rest.service";
var styles_EmployeeWizardGroupRightsComponent = [];
var RenderType_EmployeeWizardGroupRightsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EmployeeWizardGroupRightsComponent, data: {} });
export { RenderType_EmployeeWizardGroupRightsComponent as RenderType_EmployeeWizardGroupRightsComponent };
export function View_EmployeeWizardGroupRightsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-admin-access-users-list", [], null, [[null, "selectedUserGroupsChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedUserGroupsChange" === en)) {
        var pd_0 = ((_co.selectedGroups = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_AdminAccessUsersListComponent_0, i1.RenderType_AdminAccessUsersListComponent)), i0.ɵdid(1, 49152, null, 0, i2.AdminAccessUsersListComponent, [], { selectedUserGroups: [0, "selectedUserGroups"], groups: [1, "groups"] }, { selectedUserGroupsChange: "selectedUserGroupsChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedGroups; var currVal_1 = _co.groups; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_EmployeeWizardGroupRightsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-employee-wizard-group-rights", [], null, null, null, View_EmployeeWizardGroupRightsComponent_0, RenderType_EmployeeWizardGroupRightsComponent)), i0.ɵdid(1, 114688, null, 0, i3.EmployeeWizardGroupRightsComponent, [i4.HttpRestService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EmployeeWizardGroupRightsComponentNgFactory = i0.ɵccf("app-employee-wizard-group-rights", i3.EmployeeWizardGroupRightsComponent, View_EmployeeWizardGroupRightsComponent_Host_0, { employee: "employee", groups: "groups" }, {}, []);
export { EmployeeWizardGroupRightsComponentNgFactory as EmployeeWizardGroupRightsComponentNgFactory };
