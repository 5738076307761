<td width="11%" class="left">
    <span class="when monospace-date" [title]="task._metadata.endDate | momentDuration">{{
        task._metadata.endDate | momentFormat
    }}</span>
</td>
<td width="20%" class="left">
    <span class="assignement-title">
        <a
            class="c-hand"
            [routerLink]="['/firon']"
            [queryParams]="{
                projectId: task.projectId,
                taskId: task.id,
                displayRelated: 1
            }"
        >
            {{ task.project.code }}-{{ task.code }}
        </a>
    </span>
</td>
<td width="44%" class="left name">{{ task.name }}</td>
<td width="17%" class="right text-task-status-{{ task.status | lowercase }}">
    {{ task.status | lowercase | translate }}
</td>
<td width="8%" class="right">
    <clr-icon style="margin-top: -5px;" [attr.shape]="isReviewer(task) ? 'tasks' : 'note'"></clr-icon>
</td>
