<div class="clr-col-row">
    <div class="clr-col-12" *ngFor="let type of taskTarget; let i = index">
        <div
            class="clr-row item-choice c-hand"
            tabindex="{{ i }}"
            (click)="setType(type)"
            [ngClass]="{active: type === form.value.taskTarget}"
        >
            <div class="clr-col-3"><clr-icon [attr.shape]="iconTarget[type]"></clr-icon></div>
            <div class="clr-icon-9" translate>{{ i18nTarget[type] }}</div>
        </div>
    </div>
</div>
