<div class="sticky-notes-container">
    <div class="clr-row"><div class="clr-col-12 add-sticky" (click)="addNote()" *ngIf="editAllowed">+</div></div>
    <div class="scrollable-sticky-notes">
        <div class="card-columns">
            <div
                class="card p-04"
                *ngFor="let stickyNote of stickyNotes; let i = index"
                [ngStyle]="{background: colors[stickyNote.color]}"
                [attr.data-index]="i"
            >
                <div class="clr-row">
                    <div class="clr-col-8 title">
                        <input
                            type="text"
                            id="sn-title-{{ i }}"
                            [attr.data-index]="i"
                            name="title-{{ i }}"
                            [disabled]="!editAllowed"
                            [(ngModel)]="stickyNote.title"
                        />
                    </div>
                    <div class="clr-col-4" style="text-align: right;">
                        <clr-dropdown *ngIf="editAllowed">
                            <button
                                class="dropdown-toggle btn-icon btn btn-primary"
                                tabindex="-1"
                                clrDropdownTrigger
                                style="padding-right: 0.5rem"
                            >
                                <clr-icon shape="color-picker" class="is-solid"></clr-icon>
                            </button>
                            <clr-dropdown-menu clrPosition="bottom-right" *clrIfOpen>
                                <div
                                    class="color-item c-hand"
                                    clrDropdownItem
                                    *ngFor="let item of colorsAvailable; let j = index"
                                    (click)="changeColor(i, j)"
                                    [ngStyle]="{background: item.color}"
                                    [class.disabled]="item.color === colors[stickyNote.color]"
                                ></div>
                            </clr-dropdown-menu>
                        </clr-dropdown>
                        <button
                            class="dropdown-toggle btn btn-danger btn-icon"
                            tabindex="-1"
                            (click)="delete(stickyNote)"
                            *ngIf="editAllowed"
                        >
                            <clr-icon shape="trash"></clr-icon>
                        </button>
                    </div>
                </div>
                <div class="clr-row">
                    <div class="clr-col-12 content">
                        <textarea
                            class="fck-word-breaker"
                            name="text-{{ i }}"
                            *ngIf="stickyNote.edit || stickyNote.text === ''"
                            (focus)="editAllowed && (stickyNote.edit = true)"
                            sharedAutosize
                            sharedListAutoIndent
                            id="sn-text-{{ i }}"
                            [(ngModel)]="stickyNote.text"
                            [attr.data-index]="i"
                        >
                        </textarea>
                        <div
                            [hidden]="stickyNote.edit || stickyNote.text === ''"
                            [innerHTML]="stickyNote.text | prepareMarkdown | markdown | convertCheckboxes"
                            (click)="editAllowed && edit(i, $event)"
                        ></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
