<div class="column-container py-02">
    <div class="clr-row flex-1-set">
        <div class="clr-col-12">
            <clr-datagrid
                appDatagridToggle
                class="h-100"
                [(clrDgSingleSelected)]="selectedRelease"
                [clrDgLoading]="!releases"
            >
                <clr-dg-action-bar *ngIf="hasRight(RIGHTS[RIGHTS.MS_RELEASE]) || isManagerOf(project.id)">
                    <div class="btn-group">
                        <button class="btn btn-sm" (click)="showCreateModal = true" translate>
                            add_release
                        </button>
                    </div>

                    <div class="btn-group">
                        <button
                            class="btn btn-sm btn-success"
                            (click)="showReleaseModal = true"
                            *ngIf="!selectedRelease || selectedRelease.releaseState !== ReleaseStateType.RELEASED"
                            [disabled]="!selectedRelease"
                            translate
                        >
                            milestone_release
                        </button>
                        <button
                            class="btn btn-sm btn-success"
                            (click)="getChangelog()"
                            *ngIf="selectedRelease?.releaseState === ReleaseStateType.RELEASED"
                            [disabled]="!selectedRelease"
                            translate
                        >
                            changelog
                        </button>
                    </div>

                    <div class="btn-group">
                        <button
                            class="btn btn-sm btn-danger"
                            (click)="showDeleteModal = true"
                            [disabled]="!selectedRelease"
                            translate
                        >
                            delete_release
                        </button>
                    </div>
                </clr-dg-action-bar>

                <clr-dg-column
                    *ngFor="let column of RELEASES_COLUMNS"
                    [clrDgField]="column.field"
                    [clrDgSortOrder]="column.order"
                    [clrDgSortBy]="column.sorter"
                >
                    <ng-container *ngIf="column.hideable === false; else hideableColumn">
                        {{ column.translateKey | translate }}
                    </ng-container>
                    <ng-template #hideableColumn>
                        <ng-container *clrDgHideableColumn="{hidden: column.hidden === true}">
                            {{ column.translateKey | translate }}
                        </ng-container>
                    </ng-template>

                    <clr-dg-filter
                        *ngIf="!column.filterType || column.filterType === DATAGRID_FILTER_TYPE.FUZZY"
                        [clrDgFilter]="fuzzyFilter"
                        [(clrDgFilterOpen)]="filterOpen[column.field]"
                    >
                        <shared-fuzzy-datagrid-filter
                            [filterKey]="column.field"
                            [filterOpen]="filterOpen[column.field]"
                            #fuzzyFilter
                        ></shared-fuzzy-datagrid-filter>
                    </clr-dg-filter>

                    <clr-dg-filter
                        *ngIf="column.filterType === DATAGRID_FILTER_TYPE.DATE"
                        [clrDgFilter]="dateFilter"
                        [(clrDgFilterOpen)]="filterOpen[column.field]"
                    >
                        <shared-date-datagrid-filter
                            [filterKey]="column.field"
                            [filterOpen]="filterOpen[column.field]"
                            #dateFilter
                        ></shared-date-datagrid-filter>
                    </clr-dg-filter>

                    <clr-dg-filter *ngIf="column.field === MILESTONE_FIELD.releaseState" [clrDgFilter]="stateFilter">
                        <shared-generic-datagrid-filter
                            [filter]="RELEASE_STATE_FILTER"
                            [filterKey]="column.field"
                            [unfilteredEntities]="releases"
                            #stateFilter
                        ></shared-generic-datagrid-filter>
                    </clr-dg-filter>
                </clr-dg-column>

                <clr-dg-row *clrDgItems="let release of releases" [clrDgItem]="release">
                    <clr-dg-cell>
                        <ng-container *sharedAccessControl="RIGHTS.MS_UPDATE; else versionWithoutLink">
                            <!-- (click)="editRelease(release)" -->
                            <a
                                [routerLink]="['.']"
                                [queryParams]="{edit: release.id}"
                                [queryParamsHandling]="'merge'"
                                >{{ release.version }}</a
                            >
                        </ng-container>
                        <ng-template #versionWithoutLink> {{ release.version }} </ng-template>
                    </clr-dg-cell>
                    <clr-dg-cell> {{ release.obs }} </clr-dg-cell>
                    <clr-dg-cell> {{ release.description }} </clr-dg-cell>
                    <clr-dg-cell class="monospace-date" [title]="release.target | momentDuration">
                        {{ release.target | momentFormat: 'L' }}
                    </clr-dg-cell>
                    <clr-dg-cell class="monospace-date" [title]="release.target | momentDuration">
                        {{ release.target | momentFormat: 'LT' }}
                    </clr-dg-cell>
                    <clr-dg-cell class="monospace-date" [title]="release.beginDate | momentDuration">
                        {{ release.beginDate | momentFormat: 'L' }}
                    </clr-dg-cell>
                    <clr-dg-cell class="monospace-date" [title]="release.beginDate | momentDuration">
                        {{ release.beginDate | momentFormat: 'LT' }}
                    </clr-dg-cell>
                    <clr-dg-cell class="monospace-date" [title]="release.endDate | momentDuration">
                        {{ release.endDate | momentFormat: 'L' }}
                    </clr-dg-cell>
                    <clr-dg-cell class="monospace-date" [title]="release.endDate | momentDuration">
                        {{ release.endDate | momentFormat: 'LT' }}
                    </clr-dg-cell>
                    <clr-dg-cell>
                        <span [classList]="RELEASE_STATE_FILTER[release.releaseState].classList">
                            <clr-icon [attr.shape]="RELEASE_STATE_FILTER[release.releaseState].icon"></clr-icon>
                            {{ RELEASE_STATE_FILTER[release.releaseState].translation | translate }}
                        </span>
                    </clr-dg-cell>
                </clr-dg-row>

                <clr-dg-footer>
                    <clr-dg-column-toggle>
                        <clr-dg-column-toggle-title>{{ 'releases_columns' | translate }}</clr-dg-column-toggle-title>
                        <clr-dg-column-toggle-button>{{ 'show_all_columns' | translate }}</clr-dg-column-toggle-button>
                    </clr-dg-column-toggle>

                    <span *ngIf="releases">{{ 'count_releases' | translate: {count: releases.length} }}</span>
                </clr-dg-footer>
            </clr-datagrid>
        </div>
    </div>
</div>

<app-milestone-wizard
    [(clrWizardOpen)]="showCreateModal"
    (finish)="addNewRelease($event)"
    [params]="{projectId: project.id, type: MilestonesType.RELEASE}"
></app-milestone-wizard>

<app-edit-milestone
    [selectedEvent]="editionRelease"
    [showModal]="showEditModal"
    (hidden)="closeEditModal()"
    (saved)="editedRelease($event)"
    (removed)="deleteSelectedRelease()"
>
</app-edit-milestone>

<app-modal [(show)]="showReleaseModal" [title]="'release_modal_title' | translate">
    <ng-template appModalBody>
        <p translate [translateParams]="{version: selectedRelease?.version}">release_confirmation_text</p>

        <p translate>swal_cant_revert</p>
    </ng-template>
    <ng-template appModalFooter>
        <button
            [clrLoading]="modalLoading"
            class="btn btn-secondary"
            (click)="showReleaseModal = false"
            [sharedAutofocus]="showReleaseModal"
        >
            {{ 'form_cancel' | translate }}
        </button>
        <button [clrLoading]="modalLoading" class="btn btn-success" (click)="getUndoneTasksCount()">
            {{ 'milestone_release' | translate }}
        </button>
    </ng-template>
</app-modal>

<app-modal [(show)]="showMoveTasksModal" [title]="'release_delete_modal_title' | translate: {count: undoneTasksCount}">
    <ng-template appModalBody>
        <form
            id="projectReleasesForm"
            clrForm
            [clrLayout]="'horizontal'"
            *ngIf="otherUnreleased.length; else unavailableRelease"
        >
            <p translate>swal_select_release</p>

            <clr-select-container>
                <label translate>target_release</label>
                <select clrSelect [(ngModel)]="targetRelease" name="targetRelease">
                    <option *ngFor="let release of otherUnreleased" [ngValue]="release">
                        {{ release.version }} - {{ release.obs }}
                    </option>
                </select>
            </clr-select-container>
        </form>

        <ng-template #unavailableRelease>
            <p translate>swal_no_release_available</p>

            <div class="alert alert-info" role="alert">
                <div class="alert-items">
                    <div class="alert-item static">
                        <div class="alert-icon-wrapper">
                            <clr-icon class="alert-icon" shape="info-circle"></clr-icon>
                        </div>
                        <div class="alert-text" translate>no_release_available_help</div>
                    </div>
                </div>
            </div>
        </ng-template>
    </ng-template>
    <ng-template appModalFooter>
        <button
            [clrLoading]="modalLoading"
            class="btn btn-secondary"
            (click)="showMoveTasksModal = false"
            [sharedAutofocus]="showMoveTasksModal"
        >
            {{ 'form_cancel' | translate }}
        </button>
        <button
            form="projectReleasesForm"
            *ngIf="otherUnreleased.length"
            [clrLoading]="modalLoading"
            class="btn btn-success"
            [disabled]="!targetRelease"
            (click)="updateReleaseState()"
        >
            <ng-container *ngIf="targetRelease; else moveTasksWithoutTarget">
                {{ 'move_tasks_release_version' | translate: {version: targetRelease?.version} }}
            </ng-container>
            <ng-template #moveTasksWithoutTarget> {{ 'move_tasks_release' | translate }} </ng-template>
        </button>
    </ng-template>
</app-modal>

<app-modal [(show)]="showCreateChangelogModal" [title]="'changelog_create_modal_title' | translate">
    <ng-template appModalBody
        ><p translate [translateParams]="{version: selectedRelease?.version}">swal_changelog_creation</p></ng-template
    >
    <ng-template appModalFooter>
        <button
            class="btn btn-secondary"
            (click)="showCreateChangelogModal = false"
            [sharedAutofocus]="showCreateChangelogModal"
        >
            {{ 'form_cancel' | translate }}
        </button>
        <a
            class="btn btn-success"
            target="_blank"
            [routerLink]="['/wiki/edit']"
            [queryParams]="{
                releaseId: selectedRelease?.id,
                type: WikiPageType.CHANGELOG,
                client: project?.clientId,
                project: project?.id
            }"
            translate
            (click)="!(showCreateChangelogModal = false)"
        >
            {{ 'generate_changelog' | translate }}
        </a>
    </ng-template>
</app-modal>

<app-modal [(show)]="showDeleteModal" [title]="'release_delete_modal_title' | translate">
    <ng-template appModalBody>
        <p>
            {{ 'swal_delete_attch' | translate: {what: selectedRelease?.version} }}
        </p>
    </ng-template>
    <ng-template appModalFooter>
        <button class="btn btn-secondary" (click)="showDeleteModal = false" [sharedAutofocus]="showDeleteModal">
            {{ 'form_cancel' | translate }}
        </button>
        <button class="btn btn-danger" (click)="deleteSelectedRelease()" translate>
            {{ 'delete_release' | translate }}
        </button>
    </ng-template>
</app-modal>
