import {APP_BASE_HREF, registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import {CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule, NgModel, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouteReuseStrategy} from '@angular/router';
import {ClarityModule} from '@clr/angular';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {AdminAccessComponent} from './admin-panel/admin-access/admin-access.component';
import {AdminAccessUsersListComponent} from './admin-panel/admin-access/users-list/admin-access-users-list.component';
import {AdminClientsComponent} from './admin-panel/admin-clients/admin-clients.component';
import {AdminI18nComponent} from './admin-panel/admin-i18n/admin-i18n.component';
import {AdminMainComponent} from './admin-panel/admin-main/admin-main.component';
import {AdminComponent} from './admin-panel/admin-panel.component';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AuthInterceptor} from './auth/auth.interceptor';
import {AuthModule} from './auth/auth.module';
import {BackupsListComponent} from './backups-list/backups-list.component';
import {CalendarComponent} from './calendar/calendar.component';
import {ChangelogsListComponent} from './changelogs-list/changelogs-list.component';
import {ClientWizardComponent} from './client-wizard/client-wizard.component';
import {ClientWizardClientComponent} from './client-wizard/client/client-wizard-client.component';
import {ClientWizardCustomerContactsComponent} from './client-wizard/customer-contacts/client-wizard-customer-contacts.component';
import {ClientComponent} from './client/client.component';
import {ClientsComponent} from './clients/clients.component';
import {DashboardActivityComponent} from './dashboard-activity/dashboard-activity.component';
import {DashboardCalendarComponent} from './dashboard-calendar/dashboard-calendar.component';
import {DashboardProjectsComponent} from './dashboard-projects/dashboard-projects.component';
import {DashboardReminderComponent} from './dashboard-reminder/dashboard-reminder.component';
import {DashboardRoadmapComponent} from './dashboard-roadmap/dashboard-roadmap.component';
import {DashboardTaskComponent} from './dashboard-task/dashboard-task.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {EmployeeWizardComponent} from './employee-wizard/employee-wizard.component';
import {EmployeeWizardEmployeeComponent} from './employee-wizard/employee/employee-wizard-employee.component';
import {EmployeeWizardGroupRightsComponent} from './employee-wizard/group-rights/employee-wizard-group-rights.component';
import {EmployeeWizardProjectsComponent} from './employee-wizard/projects/employee-wizard-projects.component';
import {EmployeeComponent} from './employee/employee.component';
import {EmployeesComponent} from './employees/employees.component';
import {FailedReviewComponent} from './failed-review/failed-review.component';
import {FileManagerComponent} from './file-manager/file-manager.component';
import {FormsAddTaskComponent} from './forms/add-task/add-task.component';
import {DateValidatorDirective} from './forms/validators/date.directive';
import {SemverDirective} from './forms/validators/semver.directive';
import {GanttLineComponent} from './gantt-line/gantt-line.component';
import {GanttMilestoneComponent} from './gantt-milestone/gantt-milestone.component';
import {GanttPopoverComponent} from './gantt-popover/gantt-popover.component';
import {GanttProjectsComponent} from './gantt-projects/gantt-projects.component';
import {GanttTaskEmployeeComponent} from './gantt-task-employee/gantt-task-employee.component';
import {GanttComponent} from './gantt/gantt.component';
import {GlobalSearchModalComponent} from './global-search-modal/global-search-modal.component';
import {IncidentComponent} from './incident/incident.component';
import {MilestoneDetailsComponent} from './milestone-wizard/milestone-details/milestone-details.component';
import {MilestoneParticipantsComponent} from './milestone-wizard/milestone-participants/milestone-participants.component';
import {MilestoneTargetComponent} from './milestone-wizard/milestone-target/milestone-target.component';
import {MilestoneTypeComponent} from './milestone-wizard/milestone-type/milestone-type.component';
import {MilestoneWizardComponent} from './milestone-wizard/milestone-wizard.component';
import {MilestonesListComponent} from './milestones-list/milestones-list.component';
import {ModalSimpleComponent} from './modal-simple/modal-simple.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {NotificationsComponent} from './notifications/notifications.component';
import {PortalModule} from './portal/portal.module';
import {ProgressCircleComponent} from './progress-circle/progress-circle.component';
import {ProjectBudgetsComponent} from './project/project-budgets/project-budgets.component';
import {ProjectParamsComponent} from './project/project-params/project-params.component';
import {ProjectReleasesComponent} from './project/project-releases/project-releases.component';
import {ProjectTasksComponent} from './project/project-tasks/project-tasks.component';
import {ProjectComponent} from './project/project.component';
import {ProjectsListComponent} from './projects/list/projects-list.component';
import {ProjectsComponent} from './projects/projects.component';
import {RequestComponent} from './request/request.component';
import {SettingsComponent} from './settings/settings.component';
import {ControlFlowComponent} from './shared/control-flow/control-flow.component';
import {FileManagerProgressComponent} from './shared/file-manager-progress/file-manager-progress.component';
import {ParseUrlPipe} from './shared/parse-url/parse-url.pipe';
import {QuickActionComponent} from './shared/quick-action/quick-action.component';
import {CustomReuseStrategy} from './shared/route-reuse-strategy';
import {SharedModule} from './shared/shared.module';
import {SlugifyPipe} from './shared/slugify/slugify.pipe';
import {TimerComponent} from './shared/timer/timer.component';
import {WINDOW_PROVIDERS} from './shared/windowProvider';
import {StickyNotesComponent} from './sticky-notes/sticky-notes.component';
import {TaskCommitsComponent} from './task-commits/task-commits.component';
import {TodoComponent} from './todo/todo.component';
import {UserViewComponent} from './user-view/user-view.component';
import {VersionInterceptor} from './version/version.interceptor';
import {WanderComponent} from './wander/wander.component';

registerLocaleData(localeFr, 'fr');

// . AoT requires an exported function for factories
// tslint:disable-next-line
export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(
        {
            // error wrapper, prevents infinite JSON.parse loop
            get: (url: string) => http.get(url), // FIXME
        } as HttpClient,
        AppComponent.TRANSLATION_PREFIX,
        AppComponent.TRANSLATION_SUFFIX
    );
}

@NgModule({
    declarations: [
        AppComponent,
        ControlFlowComponent,
        EmployeesComponent,
        GanttComponent,
        CalendarComponent,
        ClientsComponent,
        ClientComponent,
        ProjectsComponent,
        ProjectComponent,
        EmployeeComponent,
        DashboardCalendarComponent,
        DashboardProjectsComponent,
        WanderComponent,
        UserViewComponent,
        QuickActionComponent,
        FormsAddTaskComponent,
        TimerComponent,
        GlobalSearchModalComponent,
        TodoComponent,
        NotFoundComponent,
        AdminComponent,
        AdminMainComponent,
        AdminClientsComponent,
        AdminI18nComponent,
        AdminAccessComponent,
        DateValidatorDirective,
        SemverDirective,
        SlugifyPipe,
        ParseUrlPipe,
        DashboardComponent,
        DashboardRoadmapComponent,
        DashboardActivityComponent,
        DashboardTaskComponent,
        DashboardTaskComponent,
        RequestComponent,
        IncidentComponent,
        IncidentComponent,
        DashboardReminderComponent,
        SettingsComponent,
        TaskCommitsComponent,
        ProgressCircleComponent,
        GanttMilestoneComponent,
        GanttTaskEmployeeComponent,
        GanttLineComponent,
        GanttPopoverComponent,
        StickyNotesComponent,
        FileManagerComponent,
        FileManagerProgressComponent,
        NotificationsComponent,
        MilestoneWizardComponent,
        MilestoneTypeComponent,
        MilestoneTargetComponent,
        MilestoneDetailsComponent,
        MilestoneParticipantsComponent,
        ClientWizardComponent,
        ClientWizardClientComponent,
        ClientWizardCustomerContactsComponent,
        EmployeeWizardComponent,
        EmployeeWizardEmployeeComponent,
        MilestonesListComponent,
        ProjectBudgetsComponent,
        ProjectTasksComponent,
        ProjectReleasesComponent,
        BackupsListComponent,
        ProjectParamsComponent,
        EmployeeWizardProjectsComponent,
        EmployeeWizardGroupRightsComponent,
        ProjectsListComponent,
        ChangelogsListComponent,
        AdminAccessUsersListComponent,
        GanttProjectsComponent,
    ],
    imports: [
        BrowserModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }),
        AppRoutingModule,
        FormsModule,
        HttpClientModule,
        SharedModule,
        PortalModule,
        AuthModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        ClarityModule,
    ],
    providers: [
        {
            provide: APP_BASE_HREF,
            useValue: '/',
        },
        NgModel,
        WINDOW_PROVIDERS,
        {provide: RouteReuseStrategy, useClass: CustomReuseStrategy},
        {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: VersionInterceptor, multi: true},
        {provide: LOCALE_ID, useValue: 'fr-FR'},
    ],
    entryComponents: [ModalSimpleComponent],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
