<clr-wizard
    [clrWizardOpen]="clrWizardOpen"
    (clrWizardOpenChange)="clrWizardOpenChange.emit((clrWizardOpen = $event))"
    (clrWizardOnCancel)="triggerCancel()"
>
    <clr-wizard-title translate>add_project</clr-wizard-title>

    <clr-wizard-button [clrWizardButtonDisabled]="submitting" [type]="'cancel'">{{
        'cancel' | translate
    }}</clr-wizard-button>
    <clr-wizard-button [clrWizardButtonDisabled]="submitting" [type]="'previous'">{{
        'back' | translate
    }}</clr-wizard-button>
    <clr-wizard-button [clrWizardButtonDisabled]="submitting" [type]="'next'">{{
        'next' | translate
    }}</clr-wizard-button>
    <clr-wizard-button [clrWizardButtonDisabled]="submitting" [type]="'finish'">{{
        'create_project' | translate
    }}</clr-wizard-button>

    <clr-wizard-page [clrWizardPageNextDisabled]="!projectComponent.form.valid">
        <ng-template clrPageTitle>{{ 'project' | translate }}</ng-template>
        <app-project-wizard-project
            [clrWizardOpen]="clrWizardOpen"
            [clientId]="clientId"
            [clients]="clients"
        ></app-project-wizard-project>
    </clr-wizard-page>

    <clr-wizard-page
        [clrWizardPageNextDisabled]="!membersComponent.form.valid"
        clrWizardPagePreventDefault="true"
        (clrWizardPageOnCancel)="triggerCancel()"
        (clrWizardPagePrevious)="wizard.previous()"
        (clrWizardPageOnCommit)="triggerFinish()"
    >
        <ng-template clrPageTitle>{{ 'team_members' | translate }}</ng-template>
        <app-project-wizard-project-members [members]="projectMembers"></app-project-wizard-project-members>
    </clr-wizard-page>
</clr-wizard>
