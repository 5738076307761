import { EventEmitter } from '@angular/core';
import { Validators } from '@angular/forms';
import { ClrForm } from '@clr/angular';
import { FormControl, FormGroup } from 'ngx-strongly-typed-forms';
import { RIGHTS } from '../../../../../defs/schema-static';
import { AuthService } from '../../auth/auth.service';
import { TaskTarget } from '../../forms/add-time.service';
var TimeTypeComponent = (function () {
    function TimeTypeComponent(authService) {
        var _a, _b;
        this.authService = authService;
        this.form = new FormGroup({
            taskTarget: new FormControl(null, Validators.required),
        });
        this.taskTarget = Object.values(TaskTarget);
        this.i18nTarget = (_a = {},
            _a[TaskTarget.ASSIGNED] = 'assigned_task_inprogress',
            _a[TaskTarget.EXISTING] = 'existing_task',
            _a[TaskTarget.NEW] = 'new_task',
            _a);
        this.iconTarget = (_b = {},
            _b[TaskTarget.ASSIGNED] = 'assign-user',
            _b[TaskTarget.EXISTING] = 'check',
            _b[TaskTarget.NEW] = 'plus',
            _b);
        this.switchType = new EventEmitter();
    }
    TimeTypeComponent.prototype.onLoad = function (params) {
        var _this = this;
        this.taskTarget = Object.values(TaskTarget);
        if (!this.authService.hasRight(RIGHTS.TASK_CREATE)) {
            this.taskTarget = this.taskTarget.filter(function (t) { return t !== TaskTarget.NEW; });
        }
        if (params && params.taskTarget && !this.form.value.taskTarget) {
            this.setType(params.taskTarget);
        }
        else if (this.taskTarget.length > 0 && !this.form.value.taskTarget) {
            this.setType(this.taskTarget[0]);
        }
        requestAnimationFrame(function () {
            var idxElement = _this.taskTarget.findIndex(function (f) { return f === _this.form.value.taskTarget; });
            var el = document.querySelector(".item-choice[tabindex=\"" + idxElement + "\"]");
            if (el) {
                el.focus();
            }
        });
    };
    TimeTypeComponent.prototype.setType = function (taskTarget) {
        this.form.patchValue({
            taskTarget: taskTarget,
        });
        this.switchType.emit();
    };
    TimeTypeComponent.prototype.submit = function () {
        if (!this.form.valid) {
            this.clrForm.markAsDirty();
            return undefined;
        }
        return this.form;
    };
    return TimeTypeComponent;
}());
export { TimeTypeComponent };
