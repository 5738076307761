import moment from 'moment';
import {ISchemaRoute} from '../../schema-static';

export enum TASK_BLOCKER_FIELD {
    id = 'id',
    taskId = 'taskId',
    blockerId = 'blockerId',
    active = 'active',
}

export interface ITaskBlocker {
    id?: number;
    createdAt?: moment.Moment;
    updatedAt?: moment.Moment;
    taskId: number;
    blockerId: number;
    active: boolean;
}

const TASK_BLOCKER_SCHEMA_ROUTE: ISchemaRoute = {
    tableName: 'taskblockers',
    singularRoute: 'taskblocker',
    pluralRoute: 'taskblockers',
};

export {TASK_BLOCKER_SCHEMA_ROUTE};
