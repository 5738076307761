import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Moment} from 'moment';
import {MomentService} from '../moment/moment.service';

@Pipe({
    name: 'momentFormat',
})
export class MomentFormatPipe implements PipeTransform {
    public constructor(private readonly translate: TranslateService, private readonly momentService: MomentService) {}

    public transform(value: string, format = 'L'): string {
        if (!value || value === '') {
            return '';
        }
        if (typeof value === 'object' && !(value as Moment).isValid()) {
            return '';
        }

        return this.momentService
            .moment(value)
            .locale(this.translate.currentLang || null)
            .format(format);
    }
}
