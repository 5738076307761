var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { AfterViewInit, ChangeDetectorRef, OnInit } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';
import { getConfigKeys, getWorkingHours } from '../../../../defs/businessRules';
import { ApiRoutePlurality, DECIMAL_RADIX, HTTP_METHOD, MilestonesType, ProjectStatusType, ReleaseStateType, TaskStatusType, } from '../../../../defs/schema-static';
import { CLIENT_SCHEMA_ROUTE } from '../../../../defs/schema/public/Clients';
import { EMPLOYEE_SCHEMA_ROUTE } from '../../../../defs/schema/public/Employees';
import { PROJECT_SCHEMA_ROUTE } from '../../../../defs/schema/public/Projects';
import { isColorDark } from '../app-static';
import { AuthService } from '../auth/auth.service';
import { ConfigService } from '../shared/config/config.service';
import { HttpRestService } from '../shared/http-rest/http-rest.service';
import { MomentService } from '../shared/moment/moment.service';
export var GANTT_VIEW_VALUES;
(function (GANTT_VIEW_VALUES) {
    GANTT_VIEW_VALUES["MONTH"] = "MONTH";
    GANTT_VIEW_VALUES["DAY"] = "DAY";
    GANTT_VIEW_VALUES["WEEK"] = "WEEK";
})(GANTT_VIEW_VALUES || (GANTT_VIEW_VALUES = {}));
export var GANTT_MODE_VALUES;
(function (GANTT_MODE_VALUES) {
    GANTT_MODE_VALUES["PROJECTS"] = "PROJECTS";
    GANTT_MODE_VALUES["EMPLOYEES"] = "EMPLOYEES";
})(GANTT_MODE_VALUES || (GANTT_MODE_VALUES = {}));
var GanttComponent = (function () {
    function GanttComponent(httpRest, cdRef, translate, authService, configService, momentService) {
        var _this = this;
        this.httpRest = httpRest;
        this.cdRef = cdRef;
        this.translate = translate;
        this.authService = authService;
        this.configService = configService;
        this.momentService = momentService;
        this.projects = [];
        this.currentMode = GANTT_MODE_VALUES.PROJECTS;
        this.tdWidth = 140;
        this.clientFilter = null;
        this.projectStatusFilter = null;
        this.fetching = false;
        this.projectColorList = [
            '#C92100',
            '#660092',
            '#004D8A',
            '#266900',
            '#C25400',
            '#DE400F',
            '#B0105B',
            '#1A23A0',
        ];
        this.msColorList = [
            '#F54F47',
            '#8939AD',
            '#0079B8',
            '#48960C',
            '#E46C00',
            '#FF5500',
            '#D91468',
            '#4E56B8',
        ];
        this.taskColorList = [
            '#F76F6C',
            '#AD73C8',
            '#49AFD9',
            '#60B515',
            '#FF8400',
            '#FF8142',
            '#F1428A',
            '#838ACF',
        ];
        this.MIN_WIDTH = 140;
        this.noRelease = 'Tasks without release';
        this.loading = true;
        this.projectFilter = null;
        this.projectsForWorkload = [];
        this.getMaxDateProject = function (project) {
            if (!project.tasks || !project.tasks[0]) {
                return _this.momentService.moment();
            }
            var max = _this.momentService.moment(project.tasks[0]._metadata.endDate);
            project.tasks.map(function (t) {
                if (_this.momentService.moment(t._metadata.endDate).isAfter(max)) {
                    max = _this.momentService.moment(t._metadata.endDate);
                }
            });
            project.milestones.map(function (ms) {
                if (_this.momentService.moment(ms.target).isAfter(max)) {
                    max = _this.momentService.moment(ms.target);
                }
            });
            return _this.momentService.moment(max);
        };
        this.getMinDateProject = function (project) {
            if (!project.tasks || !project.tasks[0]) {
                return _this.momentService.moment();
            }
            var min = _this.momentService.moment(project.tasks[0]._metadata.beginDate);
            project.tasks.map(function (t) {
                if (_this.momentService.moment(t._metadata.beginDate).isBefore(min)) {
                    min = _this.momentService.moment(t._metadata.beginDate);
                }
            });
            project.milestones.map(function (ms) {
                if (ms.type !== MilestonesType.RELEASE) {
                    return;
                }
                if (_this.momentService.moment(ms.beginDate).isBefore(min)) {
                    min = _this.momentService.moment(ms.beginDate);
                }
            });
            return _this.momentService.moment(min);
        };
        this.getProgressProject = function (project) {
            if (!project.tasks || !project.tasks[0]) {
                return 0;
            }
            return Math.floor((project.tasks.filter(function (t) { return t.status === TaskStatusType.DONE; }).length / project.tasks.length) * 100);
        };
        this.isFirefox = function () {
            return navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
        };
        this.isColorDark = isColorDark;
        this.GANTT_VIEW_VALUES = GANTT_VIEW_VALUES;
        this.GANTT_MODE_VALUES = GANTT_MODE_VALUES;
        this.MilestonesType = MilestonesType;
        this.ReleaseStateType = ReleaseStateType;
        this.PROJECT_STATUS_TYPE = Object.values(ProjectStatusType);
        this.moment = this.momentService.moment;
    }
    GanttComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (!this.isFirefox()) {
            var bodyElement = document.querySelector('body');
            if (!bodyElement.classList.contains('overflow-x-hidden')) {
                bodyElement.classList.add('overflow-x-hidden');
            }
        }
        this.loading = false;
        if (this.currentMode === GANTT_MODE_VALUES.PROJECTS) {
            this.fetchData();
        }
        this.httpRest
            ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, PROJECT_SCHEMA_ROUTE, 'ganttList')
            .subscribe(function (simpleProjects) {
            _this.projectsForWorkload = simpleProjects;
            _this.cdRef.detectChanges();
        });
        this.httpRest
            ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, EMPLOYEE_SCHEMA_ROUTE, 'list/ganttEmp')
            .subscribe(function (emps) {
            _this.employeesForWorkload = emps;
            _this.cdRef.detectChanges();
        });
        window.setTimeout(function () {
            _this.translate.get('gantt_no_release').subscribe(function (e) {
                _this.noRelease = e;
                _this.cdRef.markForCheck();
            });
            _this.filterProjectStatus(ProjectStatusType.OPEN);
            _this.defineConfigHours();
        }, 200);
    };
    GanttComponent.prototype.ngAfterViewInit = function () {
        this.fetching = true;
    };
    GanttComponent.prototype.fetchData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.clients = [];
                this.httpRest
                    ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, PROJECT_SCHEMA_ROUTE, 'light')
                    .subscribe(function (projectList) { return __awaiter(_this, void 0, void 0, function () {
                    var _a, _b;
                    var _this = this;
                    return __generator(this, function (_c) {
                        switch (_c.label) {
                            case 0:
                                this.projectList = projectList.sort(function (a, b) { return a.obs.localeCompare(b.obs); });
                                setTimeout(function () {
                                    _this.projectSelect.focus();
                                    _this.projectSelect.open();
                                }, 200);
                                _a = this;
                                return [4, this.httpRest
                                        ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, CLIENT_SCHEMA_ROUTE, 'list')
                                        .toPromise()];
                            case 1:
                                _a.clients = _c.sent();
                                _b = this;
                                return [4, this.httpRest
                                        ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, EMPLOYEE_SCHEMA_ROUTE, 'list/gantt')
                                        .toPromise()];
                            case 2:
                                _b.employees = _c.sent();
                                this.clients = this.clients.sort(function (a, b) { return a.user.name.localeCompare(b.user.name); });
                                return [2];
                        }
                    });
                }); });
                return [2];
            });
        });
    };
    GanttComponent.prototype.fetchProjectData = function (projectId) {
        var _this = this;
        var pro = this.projectList.find(function (p) { return p.id === projectId; });
        var ganttProject = {
            project: pro,
            beginDate: this.momentService.moment(),
            endDate: this.momentService.moment(),
            progress: 0,
            ganttMS: [],
            taskDone: 0,
            displayMore: false,
            who: [],
            fetching: true,
        };
        this.projects.push(ganttProject);
        this.httpRest
            ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, PROJECT_SCHEMA_ROUTE, "gantt/" + projectId)
            .subscribe(function (projects) {
            ganttProject.fetching = false;
            if (projects.length === 0) {
                _this.changeViewMode(_this.currentView);
                _this.getExtremeDates();
            }
            _this.cdRef.detectChanges();
            projects.map(function (project) {
                project.client = _this.clients.find(function (_client) { return _client.id === project.clientId; });
                var c = {
                    id: project.client.id,
                    user: {
                        name: project.client.user.name,
                        code: project.client.user.code,
                        color: project.client.user.color,
                    },
                };
                var ganttMS = project.milestones.map(function (ms) {
                    if (ms.participants.length > 0) {
                        ms.participants.map(function (pa) {
                            pa.employee = _this.employees.find(function (e) { return e.id === pa.employeeId; });
                        });
                    }
                    var whoMS = [];
                    project.tasks
                        .filter(function (t) { return t.targetReleaseId === ms.id; })
                        .map(function (t) {
                        t.assigned.map(function (assignation) {
                            assignation.employee = _this.employees.find(function (e) { return e.id === assignation.employeeId; });
                            if (whoMS.findIndex(function (e) { return e.id === assignation.employee.id; }) === -1) {
                                whoMS.push(assignation.employee);
                            }
                        });
                    });
                    return {
                        who: whoMS,
                        displayMore: false,
                        milestone: ms,
                        tasks: project.tasks
                            .filter(function (t) { return t.targetReleaseId === ms.id; })
                            .sort(function (a, b) {
                            return _this.momentService
                                .moment(a._metadata.beginDate)
                                .diff(_this.momentService.moment(b._metadata.beginDate));
                        }),
                        taskDone: project.tasks.filter(function (t) { return t.targetReleaseId === ms.id && t.status === TaskStatusType.DONE; }).length,
                        progress: Math.floor((project.tasks.filter(function (t) { return t.targetReleaseId === ms.id && t.status === TaskStatusType.DONE; }).length /
                            project.tasks.filter(function (t) { return t.targetReleaseId === ms.id; }).length) *
                            100),
                    };
                });
                var taskWithoutReleases = project.tasks.filter(function (t) { return !t.targetReleaseId; });
                var fakeMs = {
                    obs: _this.noRelease,
                    target: _this.momentService.moment(),
                    beginDate: _this.momentService.moment(),
                    endDate: null,
                    type: MilestonesType.RELEASE,
                    clientId: project.clientId,
                    projectId: project.id,
                    description: _this.noRelease,
                    releaseState: ReleaseStateType.IN_DEVELOPMENT,
                    version: '0.0.0',
                };
                var fakeRelease = {
                    who: [],
                    displayMore: false,
                    milestone: fakeMs,
                    tasks: taskWithoutReleases,
                    taskDone: taskWithoutReleases.filter(function (t) { return t.status === TaskStatusType.DONE; }).length,
                    progress: 0,
                };
                var min = null;
                taskWithoutReleases.map(function (t) {
                    if (!min) {
                        min = _this.momentService.moment(t.beginDate);
                    }
                    if (_this.momentService.moment(t.beginDate).isBefore(min)) {
                        min = _this.momentService.moment(t.beginDate);
                    }
                });
                var max = null;
                taskWithoutReleases.map(function (t) {
                    if (!max) {
                        max = _this.momentService.moment(t.endDate);
                    }
                    if (_this.momentService.moment(t.endDate).isAfter(max)) {
                        max = _this.momentService.moment(t.endDate);
                    }
                });
                fakeMs.target = max;
                fakeMs.beginDate = min;
                ganttMS.push(fakeRelease);
                var who = [];
                project.tasks.map(function (t) {
                    t.assigned.map(function (assignation) {
                        assignation.employee = _this.employees.find(function (e) { return e.id === assignation.employeeId; });
                        if (who.findIndex(function (e) { return e.id === assignation.employee.id; }) === -1) {
                            who.push(assignation.employee);
                        }
                    });
                });
                ganttProject.beginDate = _this.getMinDateProject(project);
                ganttProject.endDate = _this.getMaxDateProject(project);
                ganttProject.progress = _this.getProgressProject(project);
                ganttProject.ganttMS = ganttMS;
                ganttProject.who = who;
                ganttProject.taskDone = project.tasks.filter(function (t) { return t.status === TaskStatusType.DONE; }).length;
                if (project.tasks.length > 0) {
                    _this.cdRef.detectChanges();
                }
                _this.getExtremeDates();
                _this.changeViewMode(GANTT_VIEW_VALUES.MONTH);
                _this.cdRef.detectChanges();
            });
            _this.loading = false;
            _this.cdRef.detectChanges();
        });
    };
    GanttComponent.prototype.getExtremeDates = function () {
        var _this = this;
        if (!this.projects) {
            return;
        }
        this.projects.map(function (gproject) {
            if (!gproject.beginDate && !gproject.endDate) {
                return;
            }
            if (!_this.minDate) {
                _this.minDate = gproject.beginDate.clone();
                _this.maxDate = gproject.beginDate.clone();
            }
            if (_this.minDate.isAfter(gproject.beginDate)) {
                _this.minDate = gproject.beginDate.clone();
            }
            if (!_this.minDate) {
                _this.minDate = _this.momentService.moment();
            }
            if (!_this.maxDate) {
                _this.maxDate = _this.momentService.moment();
            }
            if (_this.maxDate.isBefore(gproject.endDate)) {
                _this.maxDate = gproject.endDate.clone();
            }
            if (gproject.project.milestones) {
                gproject.project.milestones.map(function (ms) {
                    if (_this.minDate.isAfter(_this.momentService.moment(ms.beginDate))) {
                        _this.minDate = _this.momentService.moment(ms.beginDate).clone();
                    }
                    if (_this.maxDate.isBefore(_this.momentService.moment(ms.endDate))) {
                        _this.maxDate = _this.momentService.moment(ms.endDate).clone();
                    }
                });
            }
        });
        if (!this.minDate) {
            this.minDate = this.momentService.moment();
        }
        if (!this.maxDate) {
            this.maxDate = this.momentService.moment();
        }
        this.minDate.add(-1, 'month');
        this.maxDate.add(1, 'month');
        this.fetching = false;
    };
    GanttComponent.prototype.defineConfigHours = function () {
        return __awaiter(this, void 0, void 0, function () {
            var config, configWorkHours, configKeys, lunchHours, confStart, start, confEnd, end, lunchStartTime, lunchStart, lunchEndTime, lunchEnd;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.configService.get()];
                    case 1:
                        config = _a.sent();
                        configWorkHours = getWorkingHours(config);
                        configKeys = getConfigKeys(config, ['lunchHours', 'workingDays']);
                        lunchHours = configKeys.lunchHours;
                        confStart = configWorkHours.start.split(':').map(function (t) { return parseInt(t, DECIMAL_RADIX); });
                        start = this.momentService
                            .moment()
                            .hours(confStart[0])
                            .minutes(confStart[1])
                            .seconds(0)
                            .milliseconds(0);
                        confEnd = configWorkHours.end.split(':').map(function (t) { return parseInt(t, DECIMAL_RADIX); });
                        end = this.momentService
                            .moment()
                            .hours(confEnd[0])
                            .minutes(confEnd[1])
                            .seconds(0)
                            .milliseconds(0);
                        lunchStartTime = lunchHours.start.split(':').map(function (t) { return parseInt(t, DECIMAL_RADIX); });
                        lunchStart = this.momentService
                            .moment()
                            .hours(lunchStartTime[0])
                            .minutes(lunchStartTime[1])
                            .seconds(0)
                            .milliseconds(0);
                        lunchEndTime = lunchHours.end.split(':').map(function (t) { return parseInt(t, DECIMAL_RADIX); });
                        lunchEnd = this.momentService
                            .moment()
                            .hours(lunchEndTime[0])
                            .minutes(lunchEndTime[1])
                            .seconds(0)
                            .milliseconds(0);
                        this.dayDuration = this.momentService.moment.duration(end.diff(start));
                        this.lunchDuration = this.momentService.moment.duration(lunchEnd.diff(lunchStart));
                        this.weeksDays = configKeys.workingDays.length;
                        return [2];
                }
            });
        });
    };
    GanttComponent.prototype.getNbHoursConfig = function () {
        if (!this.lunchDuration || !this.dayDuration || !this.weeksDays) {
            return '-';
        }
        var duration = null;
        if (this.lunchDuration.asMilliseconds() > 0) {
            duration = this.dayDuration.clone().subtract(this.lunchDuration);
        }
        else {
            duration = this.dayDuration.clone();
        }
        if (this.currentView === GANTT_VIEW_VALUES.WEEK) {
            var baseDuration = duration.clone();
            for (var i = 0; i < this.weeksDays - 1; i++) {
                duration
                    .add(baseDuration.hours(), 'hours')
                    .add(baseDuration.minutes(), 'minutes')
                    .add(baseDuration.seconds(), 'seconds')
                    .add(0, 'milliseconds');
            }
        }
        return Math.round(duration.asHours()).toString();
    };
    GanttComponent.prototype.filterClient = function (filterBy) {
        if (filterBy === 'null') {
            this.clientFilter = null;
        }
        else {
            this.clientFilter = parseInt(filterBy, 10);
        }
    };
    GanttComponent.prototype.selectedProjects = function () {
        var _this = this;
        if (!this.projectFilter) {
            return [];
        }
        return this.projects.filter(function (p) { return _this.projectFilter.includes(p.project.id); });
    };
    GanttComponent.prototype.filterProject = function (filterBy) {
        var _this = this;
        if (!filterBy) {
            this.projectFilter = null;
            return;
        }
        else {
            this.projectFilter = filterBy.map(function (p) {
                return p.id;
            });
        }
        this.projectFilter
            .filter(function (pf) { return _this.projects.findIndex(function (p) { return p.project.id === pf; }) === -1; })
            .map(function (pf) { return _this.fetchProjectData(pf); });
    };
    GanttComponent.prototype.clearFilterEmployee = function () {
        this.employeeFilter = null;
    };
    GanttComponent.prototype.removeFromEmpFilter = function (empId) {
        this.employeeFilter = this.employeeFilter.filter(function (id) { return id !== empId; });
        if (this.employeeFilter.length === 0) {
            this.clearFilterEmployee();
        }
    };
    GanttComponent.prototype.filterProjectStatus = function (filterBy) {
        if (filterBy === 'null') {
            this.projectStatusFilter = null;
        }
        else {
            this.projectStatusFilter = filterBy;
        }
    };
    GanttComponent.prototype.changeMode = function (value) {
        if (this.currentMode === value) {
            return;
        }
        if (this.currentView === GANTT_VIEW_VALUES.MONTH || !this.currentView) {
            this.currentView = GANTT_VIEW_VALUES.WEEK;
        }
        this.currentMode = value;
        if (this.currentMode === GANTT_MODE_VALUES.PROJECTS && !this.clients) {
            this.fetchData();
        }
    };
    GanttComponent.prototype.changeViewMode = function (value) {
        this.currentView = value;
        if (!this.minDate) {
            this.minDate = this.momentService.moment();
        }
        if (!this.maxDate) {
            this.maxDate = this.momentService.moment();
        }
        if (this.currentView === GANTT_VIEW_VALUES.MONTH) {
            this.timespan = [];
            var currDate = this.minDate.clone().startOf('month');
            var lastDate = this.maxDate.clone().endOf('month');
            this.timespan.push(currDate.clone().format('MM-YYYY'));
            while (currDate.add(1, 'month').diff(lastDate) < 1) {
                this.timespan.push(currDate.clone().format('MM-YYYY'));
            }
        }
        else if (this.currentView === GANTT_VIEW_VALUES.WEEK) {
            this.timespan = [];
            var currDate = this.minDate.clone().startOf('isoWeek');
            var lastDate = this.maxDate.clone().startOf('isoWeek');
            this.timespan.push(currDate.clone().format('DD-MM-YYYY (WW)'));
            while (currDate.add(1, 'week').diff(lastDate) < 1) {
                this.timespan.push(currDate.clone().format('DD-MM-YYYY (WW)'));
            }
        }
        else {
            this.timespan = [];
            var currDate = this.minDate.clone();
            var lastDate = this.maxDate.clone();
            while (currDate.add(1, 'days').diff(lastDate) < 0) {
                this.timespan.push(currDate.clone().format('DD-MM-YYYY'));
            }
        }
        this.cdRef.markForCheck();
    };
    return GanttComponent;
}());
export { GanttComponent };
