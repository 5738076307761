import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ClrDatagridFilterInterface} from '@clr/angular';
import {Subject} from 'rxjs';
import {IDatagridFilterMap, IFilterableEntity, ISerializableDatagridFilter} from '../../app-static';
import {NestedProperty} from '../nested-properties';

export enum DATAGRID_FILTER_DISPLAY_TYPE {
    CHECKBOX_LIST = 'CHECKBOX_LIST',
    ICON_GRID = 'ICON_GRID',
}

@Component({
    selector: 'shared-generic-datagrid-filter',
    templateUrl: './generic-datagrid-filter.component.html',
    styleUrls: ['./generic-datagrid-filter.component.scss'],
})
export class GenericDatagridFilterComponent<T extends IFilterableEntity = any>
    implements ClrDatagridFilterInterface<T>, ISerializableDatagridFilter, OnInit, OnChanges {
    @Input() public filterKey: string;
    @Input() public filter: IDatagridFilterMap;
    @Input() public display: DATAGRID_FILTER_DISPLAY_TYPE = DATAGRID_FILTER_DISPLAY_TYPE.CHECKBOX_LIST;
    @Input() public state: {[key in keyof IDatagridFilterMap]: boolean} = {};

    @Input() public unfilteredEntities?: T[];

    public filterCount?: {[key in keyof IDatagridFilterMap]: number};

    public changes = new Subject<any>();

    public toggleAll = true;

    private nestedProp: NestedProperty<T>;

    public ngOnInit(): void {
        this.setFilterKey();
        this.generateToggledMap();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.filterKey) {
            this.setFilterKey();
        }

        if ((changes.filter || changes.unfilteredEntities) && (!changes.state || !this.state)) {
            this.generateToggledMap();
        }
    }

    public resetState(...properties: (keyof IDatagridFilterMap)[]) {
        this.state = Object.keys(this.state).reduce(
            (_filterMap, filterKey: keyof IDatagridFilterMap) => {
                _filterMap[filterKey] = properties.includes(filterKey);

                return _filterMap;
            },
            {} as {[key in keyof IDatagridFilterMap]: boolean}
        );

        this.emitChanges();
    }

    public setFilterKey() {
        this.nestedProp = this.filterKey ? new NestedProperty(this.filterKey) : undefined;
        this.emitChanges();
    }

    public accepts(item: T): boolean {
        if (!this.filterKey) {
            // tslint:disable-next-line:no-console
            console.warn('missing filterKey');

            return true;
        }

        const filterKeys = this.filterKeys;

        if (!filterKeys.length) {
            return true;
        }

        const filterValues = this.filterValues;
        const itemValue = this.getItemValue(item);

        return !!filterKeys
            .filter((_filterKey) => this.state[_filterKey])
            .find((_filterKey) => itemValue === filterValues[_filterKey]);
    }

    /*public getFilterTranslation(key: string) {
        if (typeof this.filterTranslate === 'function') {
            return this.filterTranslate(key) || key;
        }

        return this.filterTranslate && this.filterTranslate.hasOwnProperty(key) ? this.filterTranslate[key] : key;
    }*/

    public emitChanges() {
        const allToggled = this.allToggled;
        if (allToggled !== null && this.toggleAll !== allToggled) {
            this.toggleAll = allToggled;
        }

        this.changes.next(this.filterKey);
    }

    public toggleToggleAll() {
        this.state = Object.keys(this.state).reduce(
            (_filterMap, filterKey) => {
                _filterMap[filterKey] = this.toggleAll;

                return _filterMap;
            },
            {} as {[key in keyof IDatagridFilterMap]: boolean}
        );

        this.emitChanges();
    }

    private generateToggledMap() {
        this.state = this.filterKeys.reduce(
            (_filterMap, filterKey) => {
                _filterMap[filterKey] = this.state.hasOwnProperty(filterKey) ? this.state[filterKey] : true;

                return _filterMap;
            },
            {} as {[key in keyof IDatagridFilterMap]: boolean}
        );

        this.filterCount =
            (this.unfilteredEntities &&
                this.filterKeys.reduce(
                    (_filterCount, key) => {
                        _filterCount[key] = this.unfilteredEntities.filter(
                            (entity) => this.getItemValue(entity) === key
                        ).length;

                        return _filterCount;
                    },
                    {} as {[key in keyof IDatagridFilterMap]: number}
                )) ||
            undefined;

        this.emitChanges();
    }

    public get filterKeys(): (keyof IDatagridFilterMap)[] {
        const keys = Object.keys(this.filter || {});

        if (!this.unfilteredEntities || !this.unfilteredEntities.length) {
            return Object.keys(this.filter || {});
        }

        return keys.filter((key) => !!this.unfilteredEntities.find((entity) => this.getItemValue(entity) === key));
    }

    public get filterValues(): {[key in keyof IDatagridFilterMap]: string | number} {
        return this.filterKeys.reduce(
            (_filterValues, filterKey) => {
                _filterValues[filterKey] =
                    this.filter.hasOwnProperty(filterKey) && this.filter[filterKey].hasOwnProperty('value')
                        ? this.filter[filterKey].value
                        : filterKey;

                return _filterValues;
            },
            {} as {[key in keyof IDatagridFilterMap]: string | number}
        );
    }

    public serialize(): (keyof IDatagridFilterMap)[] {
        return this.filterKeys.filter((filterKey) => this.state[filterKey]);
    }

    public get allToggled(): boolean | null {
        const set: boolean[] = Array.from(new Set(Object.values(this.state)));
        if (set.length === 1) {
            return set[0];
        }

        return null;
    }

    public getItemValue(item: T): string {
        if (!this.nestedProp) {
            return '';
        }

        return this.nestedProp.getPropValue(item);
    }

    public isActive(): boolean {
        return this.serialize().length !== this.filterKeys.length;
    }

    public readonly DATAGRID_FILTER_DISPLAY_TYPE = DATAGRID_FILTER_DISPLAY_TYPE;
}
