var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ApiRoutePlurality, HTTP_METHOD, STORED_TYPE } from '../../../../../defs/schema-static';
import { CUSTOMER_REQUEST_SCHEMA_ROUTE } from '../../../../../defs/schema/public/CustomerRequests';
import { PROJECT_SCHEMA_ROUTE } from '../../../../../defs/schema/public/Projects';
import { DEFAULT_ACCEPTED_TYPES } from '../../app-static';
import { AuthService } from '../../auth/auth.service';
import { FileManagerDropZoneComponent } from '../../shared/file-manager-drop-zone/file-manager-drop-zone.component';
import { HttpRestService } from '../../shared/http-rest/http-rest.service';
var CustomerRequestComponent = (function () {
    function CustomerRequestComponent(httpRest, authService) {
        var _this = this;
        this.httpRest = httpRest;
        this.authService = authService;
        this.requests = [];
        this.showNewRequestModal = false;
        this.pleaseWait = true;
        this.displayModal = false;
        this.projects = [];
        this.ACCEPTED_TYPES = DEFAULT_ACCEPTED_TYPES;
        this.updateFilter = function (event) {
            var target = event.target;
            var val = target.value.toLowerCase();
        };
        this.getEntity = function () {
            return (_this.newRequest && {
                customerRequest: HttpRestService.stripDepth(_this.newRequest),
                customerRequestId: _this.newRequest.id,
            });
        };
        this.STORED_TYPE = STORED_TYPE;
        this.httpRest
            ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, CUSTOMER_REQUEST_SCHEMA_ROUTE, 'client')
            .subscribe(function (requests) {
            _this.requests = requests;
            _this.pleaseWait = false;
        });
        this.httpRest
            ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, PROJECT_SCHEMA_ROUTE, "clientLight/" + this.authService.user.client.id)
            .subscribe(function (projects) { return (_this.projects = projects); });
    }
    CustomerRequestComponent.prototype.displayDescription = function (_a) {
        var selected = _a.selected;
        this.selectedRequest = selected[0];
        this.displayModal = true;
    };
    CustomerRequestComponent.prototype.getProject = function (projectId) {
        return this.projects.find(function (p) { return p.id === projectId; });
    };
    CustomerRequestComponent.prototype.onSubmit = function (f) {
        var _this = this;
        if (!f.valid) {
            return;
        }
        var _a = f.value, title = _a.title, description = _a.description, project = _a.project, request = _a.request;
        this.httpRest
            .put(CUSTOMER_REQUEST_SCHEMA_ROUTE, {
            title: title,
            description: description,
            request: request,
            clientId: this.authService.user.client.id,
            projectId: project,
        })
            .subscribe(function (_request) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.requests.push((this.newRequest = _request));
                        return [4, this.fileInput.upload()];
                    case 1:
                        _a.sent();
                        this.newRequest = null;
                        f.reset();
                        this.showNewRequestModal = false;
                        return [2];
                }
            });
        }); });
    };
    return CustomerRequestComponent;
}());
export { CustomerRequestComponent };
