var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import { ChangeDetectorRef, ElementRef, OnDestroy } from '@angular/core';
import { Validators } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { concat, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import * as SemVer from 'semver';
import { overEstimatedTime } from '../../../../../defs/businessRules';
import { ApiRoutePlurality, DECIMAL_RADIX, HTTP_METHOD, ISO_DATE_FORMAT, MAX_LENGTH_TASK_NAME, PATTERN_TIME, ProjectStatusType, ReleaseStateType, RIGHTS, } from '../../../../../defs/schema-static';
import { EMPLOYEE_FIELD, EMPLOYEE_SCHEMA_ROUTE } from '../../../../../defs/schema/public/Employees';
import { MILESTONE_SCHEMA_ROUTE } from '../../../../../defs/schema/public/Milestones';
import { PROJECT_SCHEMA_ROUTE } from '../../../../../defs/schema/public/Projects';
import { TAG_SCHEMA_ROUTE } from '../../../../../defs/schema/public/Tags';
import { TASK_SCHEMA_ROUTE } from '../../../../../defs/schema/public/Tasks';
import { USER_FIELD } from '../../../../../defs/schema/public/Users';
import { AddBlockersService } from '../../add-blockers-modal/add-blockers.service';
import { getRandomColorHex, isColorDark } from '../../app-static';
import { AuthService } from '../../auth/auth.service';
import { ConfigService } from '../../shared/config/config.service';
import { EMPLOYEE_MEMBER_TYPE } from '../../shared/editable-employee-label/editable-employee-label.component';
import { HttpRestService } from '../../shared/http-rest/http-rest.service';
import { MomentService } from '../../shared/moment/moment.service';
import { SHORTCUT_MISC } from '../../shared/shortcut-handler/shortcut-handler.service';
import { AddTaskFormField, FormsAddTaskService } from './add-task.service';
var FormsAddTaskComponent = (function () {
    function FormsAddTaskComponent(httpRest, addBlockers, formsAddTaskService, authService, momentService, cdRef, configService) {
        var _this = this;
        this.httpRest = httpRest;
        this.addBlockers = addBlockers;
        this.formsAddTaskService = formsAddTaskService;
        this.authService = authService;
        this.momentService = momentService;
        this.cdRef = cdRef;
        this.configService = configService;
        this.assigned = [];
        this.form = FormsAddTaskService.getFormGroup();
        this.tasksParentLoading = false;
        this.tasksParent$ = new Subject();
        this.employeesNotAssigned = [];
        this.alreadyInit = false;
        this.selectedTagCopy = [];
        this.checkDates = function () {
            var beginDate;
            var endDate;
            var error = false;
            if (!_this.beginDate && _this.beginDateInputElement && _this.beginDateInputElement.nativeElement.value !== '') {
                beginDate = _this.beginDateInputElement.nativeElement.value;
                if (!_this.momentService.moment(beginDate, ISO_DATE_FORMAT, true).isValid()) {
                    error = true;
                }
            }
            if (!_this.endDate && _this.endDateInputElement && _this.endDateInputElement.nativeElement.value !== '') {
                endDate = _this.endDateInputElement.nativeElement.value;
                if (!_this.momentService.moment(endDate, ISO_DATE_FORMAT, true).isValid()) {
                    error = true;
                }
            }
            _this.form.patchValue({
                startDate: _this.beginDate ? _this.momentService.moment(_this.beginDate).toISOString() : null,
                endDate: _this.endDate ? _this.momentService.moment(_this.endDate).toISOString() : null,
            });
            if ((!_this.beginDate || !_this.endDate) && !_this.form.value.releaseId) {
                return false;
            }
            else if (_this.momentService
                .moment(_this.beginDate)
                .startOf('day')
                .isAfter(_this.momentService.moment(_this.endDate).endOf('day'))) {
                return false;
            }
            else if (error) {
                return false;
            }
            else {
                return true;
            }
        };
        this.onTagSubmit = function (text) { return __awaiter(_this, void 0, void 0, function () {
            var value, tag;
            return __generator(this, function (_a) {
                value = text.trim();
                if (!value) {
                    return [2, undefined];
                }
                tag = {
                    text: value,
                    color: getRandomColorHex(),
                    branch: false,
                    projectId: this.form.value.project,
                };
                this.formsAddTaskService.pendingTags.push(tag);
                this.tags.push(__assign({}, tag, { selected: true }));
                return [2, tag];
            });
        }); };
        this.AddTaskFormField = AddTaskFormField;
        this.MAX_LENGTH_TASK_NAME = MAX_LENGTH_TASK_NAME;
        this.isColorDark = isColorDark;
        this.RIGHTS = RIGHTS;
        this.SHORTCUT_MISC = SHORTCUT_MISC;
        this.EMPLOYEE_FIELD = EMPLOYEE_FIELD;
        this.USER_FIELD = USER_FIELD;
        this.EMPLOYEE_MEMBER_TYPE = EMPLOYEE_MEMBER_TYPE;
        this.tasks = [];
        this.tags = [];
        this.released = [];
        this.releases = [];
        this.staged = [];
        this.unreleased = [];
        this.parentTask = { name: '' };
    }
    FormsAddTaskComponent.prototype.ngOnDestroy = function () { };
    FormsAddTaskComponent.prototype.loadTasks = function (term) {
        return this.httpRest._request(HTTP_METHOD.POST, ApiRoutePlurality.PLURAL, TASK_SCHEMA_ROUTE, 'searchTasks', {
            search: term,
            projectId: this.form.value.project,
        });
    };
    FormsAddTaskComponent.prototype.loadParentTasks = function () {
        var _this = this;
        this.tasksParent = concat(of([]), this.tasksParent$.pipe(debounceTime(200), distinctUntilChanged(), tap(function () { return (_this.tasksParentLoading = true); }), switchMap(function (term) {
            if (!term || term === '') {
                _this.tasksParentLoading = false;
                return of([]);
            }
            return _this.loadTasks(term).pipe(catchError(function () { return of([]); }), tap(function () { return (_this.tasksParentLoading = false); }));
        })));
    };
    FormsAddTaskComponent.prototype.getInputNameLength = function () {
        return this.nameInput.nativeElement.value.length;
    };
    FormsAddTaskComponent.prototype.getNotAssigned = function () {
        var _this = this;
        if (!this.employees || !this.activeProjects) {
            return [];
        }
        var project = this.activeProjects.find(function (p) { return p.id === +_this.form.value.project; });
        if (!project) {
            return [];
        }
        return this.employees
            .filter(function (e) { return project.projectMembers.findIndex(function (pm) { return pm.employeeId === e.id; }) > -1; })
            .filter(function (e) { return _this.assigned.findIndex(function (_e) { return _e.id === e.id; }) === -1; })
            .sort(function (a, b) {
            return a.user.name.localeCompare(b.user.name);
        });
    };
    FormsAddTaskComponent.prototype.addAssigned = function () {
        var _this = this;
        if (this.assignedSelect.nativeElement.value === '') {
            return;
        }
        var emp = this.employees.find(function (e) { return e.id === parseInt(_this.assignedSelect.nativeElement.value, DECIMAL_RADIX); });
        this.assigned.push(emp);
        this.form.patchValue({
            assigned: this.assigned,
        });
    };
    FormsAddTaskComponent.prototype.getEmployee = function (id) {
        return this.employees.find(function (e) { return e.id === id; });
    };
    FormsAddTaskComponent.prototype.removeAssigned = function (emp) {
        this.form.value.assigned = this.form.value.assigned.filter(function (e) { return e.id !== emp.id; });
        this.form.patchValue({
            assigned: this.form.value.assigned,
        });
    };
    FormsAddTaskComponent.prototype.load = function (params) {
        var _this = this;
        var _a;
        if (this.alreadyInit) {
            return;
        }
        this.alreadyInit = true;
        this.clearParentTask();
        this.assigned = [];
        if (params && params.parentTask) {
            Object.assign(this.parentTask, {
                name: params.parentTask.name,
                id: params.parentTask.id,
                code: params.parentTask.code,
            });
            this.form.reset({
                tracked: true,
                bug: false,
                urgent: false,
                project: params.parentTask.projectId,
                parentTaskId: params.parentTask.id,
                releaseId: 0,
                assigned: this.assigned,
            });
        }
        else if (params && params.name) {
            this.form.reset({
                name: params.name,
                comment: params.comment,
                tracked: true,
                bug: false,
                urgent: false,
                parentTaskId: null,
                releaseId: 0,
                assigned: this.assigned,
            });
        }
        else {
            this.form.reset({
                tracked: true,
                bug: false,
                urgent: false,
                parentTaskId: null,
                releaseId: 0,
                assigned: this.assigned,
            });
        }
        this.form.value.release = true;
        this.form.value.parentTask = true;
        var pattern = Validators.pattern(PATTERN_TIME);
        this.form.controls.time.setValidators([pattern]);
        if (!this.employees) {
            this.httpRest
                ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, EMPLOYEE_SCHEMA_ROUTE, 'list')
                .subscribe(function (employees) { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    this.employees = employees;
                    if (this.activeProjects) {
                        this.loadProject();
                    }
                    return [2];
                });
            }); });
        }
        this.httpRest
            ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, PROJECT_SCHEMA_ROUTE, 'light')
            .subscribe(function (projects) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.activeProjects = projects
                    .filter(function (p) { return p.status === ProjectStatusType.OPEN; })
                    .sort(function (a, b) {
                    return a.obs.localeCompare(b.obs);
                })
                    .concat(projects
                    .filter(function (p) { return p.status === ProjectStatusType.WAITING; })
                    .sort(function (a, b) {
                    return a.obs.localeCompare(b.obs);
                }))
                    .concat(projects
                    .filter(function (p) { return p.status === ProjectStatusType.CLOSE; })
                    .sort(function (a, b) {
                    return a.obs.localeCompare(b.obs);
                }));
                if (projects.length) {
                    if (params && params.parentTask) {
                        this.form.patchValue({
                            project: params.parentTask.projectId,
                        });
                    }
                    else if (params && params.projectId) {
                        this.form.patchValue({
                            project: params.projectId,
                        });
                    }
                    else {
                        this.form.patchValue({
                            project: parseInt(sessionStorage.getItem('projectId'), 10) || this.activeProjects[0].id,
                        });
                    }
                    this.loadProject();
                }
                return [2];
            });
        }); });
        if (params && params.tags) {
            var formattedTags = params.tags.map(function (t) {
                return {
                    text: t.text,
                    color: t.color,
                    branch: false,
                    projectId: _this.form.value.project,
                    selected: true,
                };
            });
            this.formsAddTaskService.pendingTags = formattedTags;
            (_a = this.tags).push.apply(_a, formattedTags);
            var transf = formattedTags.map(function (t) {
                return {
                    disabled: undefined,
                    index: _this.tags.findIndex(function (_t) { return _t.text === t.text; }),
                    label: t.text,
                    value: t,
                    selected: true,
                };
            });
            this.selectedTagCopy = transf.slice();
        }
        else {
            this.formsAddTaskService.pendingTags = [];
        }
    };
    FormsAddTaskComponent.prototype.loadProject = function () {
        var _this = this;
        if (!this.activeProjects) {
            return;
        }
        this.project = this.activeProjects.find(function (project) { return project.id === _this.form.value.project; });
        this.form.patchValue({ assigned: [] });
        this.employeesNotAssigned = this.getNotAssigned();
        this.httpRest
            ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, TASK_SCHEMA_ROUTE, "loadProject/" + this.form.value.project)
            .subscribe(function (tasks) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.tasks = tasks;
                this.loadParentTasks();
                return [2];
            });
        }); });
        this.httpRest
            ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, MILESTONE_SCHEMA_ROUTE, "release/project/" + this.form.value.project)
            .subscribe(function (releases) {
            _this.released = releases.filter(function (release) { return release.releaseState === ReleaseStateType.RELEASED; });
            _this.releases = releases.sort(function (a, b) {
                if (a.releaseState === ReleaseStateType.IN_DEVELOPMENT &&
                    b.releaseState === ReleaseStateType.IN_DEVELOPMENT) {
                    return SemVer.compare(a.version, b.version);
                }
                else if (a.releaseState === ReleaseStateType.IN_DEVELOPMENT &&
                    b.releaseState !== ReleaseStateType.IN_DEVELOPMENT) {
                    return -1;
                }
                return SemVer.compare(b.version, a.version);
            });
            _this.staged = releases.filter(function (release) { return release.releaseState === ReleaseStateType.STAGED; });
            _this.unreleased = releases.filter(function (release) { return release.releaseState === ReleaseStateType.IN_DEVELOPMENT; });
            if (releases.length) {
                var releaseId = void 0;
                if (_this.unreleased.length) {
                    releaseId = _this.unreleased[0].id;
                }
                _this.form.patchValue({
                    releaseId: releaseId,
                    startDate: null,
                    endDate: null,
                });
                _this.onReleaseIdChange({ id: releaseId });
            }
            else {
                var nowStr = _this.momentService.moment();
                _this.form.patchValue({
                    releaseId: null,
                    startDate: nowStr.format(ISO_DATE_FORMAT),
                    endDate: nowStr.format(ISO_DATE_FORMAT),
                });
                _this.onReleaseIdChange({ id: null });
            }
        });
        this.httpRest
            ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, TAG_SCHEMA_ROUTE, "project/" + this.form.value.project)
            .subscribe(function (tags) {
            var _a;
            if (_this.selectedTagCopy && _this.selectedTagCopy.length > 0 && _this.tags && _this.tags.length > 0) {
                tags.map(function (tag) {
                    var tagExist = _this.tags.find(function (t) { return t.text === tag.text; });
                    if (tagExist) {
                        tagExist.id = tag.id;
                        tag.color = tag.color;
                        tagExist.selected = true;
                        _this.formsAddTaskService.pendingTags = _this.formsAddTaskService.pendingTags.filter(function (pending) {
                            return pending.text !== tag.text;
                        });
                        if (!_this.form.value.tags) {
                            _this.form.value.tags = [];
                        }
                        _this.form.value.tags.push(tag.id);
                        _this.form.patchValue({
                            tags: _this.form.value.tags,
                        });
                    }
                    else {
                        _this.tags.push(__assign({}, tag, { selected: false }));
                    }
                });
            }
            else {
                _this.form.value.tags = [];
                _this.formsAddTaskService.pendingTags = [];
                _this.form.patchValue({
                    tags: _this.form.value.tags,
                });
                _this.tags = tags.map(function (tag) { return (__assign({}, tag, { selected: false })); });
            }
            if (_this.selectedTagCopy && _this.selectedTagCopy.length > 0) {
                var transf = _this.selectedTagCopy
                    .map(function (t) {
                    return {
                        disabled: undefined,
                        index: _this.tags.findIndex(function (_t) { return _t.text === t.label; }),
                        label: t.label,
                        value: t.value,
                        selected: true,
                    };
                })
                    .filter(function (a) { return a.label; });
                _this.selectedTagCopy = transf.slice();
                _this.selectTag.clearModel();
                (_a = _this.selectTag.selectedItems).push.apply(_a, transf);
                _this.formsAddTaskService.selectedTags = _this.selectedTags().map(function (t) { return t.id; });
                _this.cdRef.detectChanges();
            }
        });
        this.form.patchValue({
            parentTask: true,
            release: true,
        });
        this.addBlockers.init(false, this.form);
    };
    FormsAddTaskComponent.prototype.selectedTags = function () {
        return this.tags.filter(function (tag) { return tag.selected; });
    };
    FormsAddTaskComponent.prototype.removeTag = function (tag) {
        this.form.patchValue({
            tags: this.form.value.tags.filter(function (_tag) { return _tag !== tag.id; }),
        });
    };
    FormsAddTaskComponent.prototype.updateTag = function (el, tag) {
        var _this = this;
        var clone = __rest(tag, []);
        this.httpRest.put(TAG_SCHEMA_ROUTE, clone).subscribe(function (_tag) {
            if (el) {
                _this.tags.push(__assign({}, _tag, { selected: true }));
                _this.form.patchValue({
                    tags: _this.tags.filter(function (selectedTag) { return selectedTag.selected; }).map(function (selectedTag) { return selectedTag.id; }),
                });
                el.value = '';
                el.focus();
            }
        });
    };
    FormsAddTaskComponent.prototype.onRecursiveChange = function () {
        var _this = this;
        if (this.form.value.recursive) {
            this.onSubmit = this.formsAddTaskService.onSubmit.subscribe(function (task) {
                _this.tasks.unshift(task);
            });
        }
        else {
            this.onSubmit.unsubscribe();
        }
    };
    FormsAddTaskComponent.prototype.toggle = function () {
        this.form.value.parentTask = true;
        this.form.controls.parentTaskId.enable();
        this.form.controls.parentTaskId.updateValueAndValidity();
        this.clearParentTask();
    };
    FormsAddTaskComponent.prototype.clearParentTask = function () {
        Object.assign(this.parentTask, {
            name: '',
            id: null,
        });
        this.form.patchValue({
            parentTaskId: null,
        });
        var el = document.getElementById('parentTaskId');
        if (el) {
            window.setTimeout(function () {
                el.focus();
            }, 200);
        }
    };
    FormsAddTaskComponent.prototype.toggleRelease = function () {
        if (this.form.value.releaseId) {
            this.form.controls.startDate.clearValidators();
            this.form.controls.endDate.clearValidators();
            this.form.patchValue({
                startDate: null,
                endDate: null,
            });
            this.beginDate = null;
            this.endDate = null;
        }
        else {
            this.form.controls.startDate.setValidators([Validators.required]);
            this.form.controls.endDate.setValidators([Validators.required]);
            var nowStr = this.momentService.moment().format(ISO_DATE_FORMAT);
            this.form.patchValue({
                startDate: this.form.value.startDate || nowStr,
                endDate: this.form.value.endDate || nowStr,
            });
        }
        this.form.controls.startDate.updateValueAndValidity();
        this.form.controls.endDate.updateValueAndValidity();
    };
    FormsAddTaskComponent.prototype.onParentChange = function ($event) {
        if ($event === void 0) { $event = null; }
        this.form.patchValue({ parentTaskId: $event ? $event.id : null });
    };
    FormsAddTaskComponent.prototype.onReleaseIdChange = function ($event) {
        var _this = this;
        if ($event === void 0) { $event = null; }
        var nowStr = this.momentService.moment();
        var currentValueStart = this.form.value.startDate || nowStr.format(ISO_DATE_FORMAT);
        var currentValueEnd = this.form.value.endDate || nowStr.format(ISO_DATE_FORMAT);
        this.form.patchValue({
            releaseId: $event ? $event.id : null,
            startDate: $event ? null : currentValueStart,
            endDate: $event ? null : currentValueEnd,
        });
        if ($event && $event.id) {
            this.beginDate = null;
            this.beginDateInputElement.nativeElement.value = '';
            this.endDate = null;
            this.endDateInputElement.nativeElement.value = '';
        }
        else {
            this.beginDate = nowStr.toDate();
            this.endDate = nowStr.toDate();
        }
        this.toggleRelease();
        this.currentRelease = this.releases.find(function (ms) { return ms.id === _this.form.value.releaseId; });
    };
    FormsAddTaskComponent.prototype.jumpFromNameToComment = function ($event) {
        if (($event.key === 'Enter' || $event.key === 'Return') && $event.target === this.nameInput.nativeElement) {
            $event.preventDefault();
            this.commentInput.nativeElement.focus();
        }
    };
    FormsAddTaskComponent.prototype.isTimeInvalid = function () {
        return this.form.controls.time.status === 'INVALID';
    };
    FormsAddTaskComponent.prototype.checkValidationTime = function () {
        if (overEstimatedTime(this.configService.config, this.form.value.time)) {
            this.form.controls.time.setErrors({ over53Weeks: true });
        }
    };
    return FormsAddTaskComponent;
}());
export { FormsAddTaskComponent };
