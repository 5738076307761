import {ChangeDetectorRef, Component, ContentChild, Input, OnInit, TemplateRef} from '@angular/core';
import {Moment} from 'moment';
import {RIGHTS} from '../../../../defs/schema-static';
import {IUser} from '../../../../defs/schema/public/Users';
import {isColorDark} from '../app-static';
import {AuthService} from '../auth/auth.service';
import {
    CardBodyDirective,
    CardFooterDirective,
    CardFooterRightDirective,
    CardHeaderRightDirective,
} from './card.directives';

@Component({
    selector: 'app-card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit {
    @Input()
    public user: IUser;
    @Input()
    public createdAt: Moment;
    @Input()
    public updatedAt: Moment;
    @Input()
    public tile = false;
    @Input()
    public highlight = false;
    @Input()
    public icon: string;
    @Input()
    public iconSize = 2.5;

    @ContentChild(CardHeaderRightDirective, {read: TemplateRef})
    public headerRight: TemplateRef<any>;
    @ContentChild(CardBodyDirective, {read: TemplateRef})
    public cardBody: TemplateRef<any>;
    @ContentChild(CardFooterDirective, {read: TemplateRef})
    public cardFooter: TemplateRef<any>;
    @ContentChild(CardFooterRightDirective, {read: TemplateRef})
    public footerRight: TemplateRef<any>;

    public constructor(public authService: AuthService, private readonly cdRef: ChangeDetectorRef) {}

    public ngOnInit() {
        if (this.highlight) {
            setTimeout(() => {
                this.highlight = false;
                this.cdRef.markForCheck();
            }, 1000);
        }
    }

    protected readonly isColorDark = isColorDark;
    protected readonly RIGHTS = RIGHTS;
}
