var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
import moment from 'moment';
import { default as slugify } from 'slugify';
import publicConfig from '../../../defs/config/config.json';
import { BudgetStatus, MilestonesType, MimeTypes, ProjectStatusType, ReleaseStateType, RIGHTS, TaskStatusType, WikiPageType, } from '../../../defs/schema-static';
import { MILESTONE_FIELD } from '../../../defs/schema/public/Milestones';
import { TASK_FIELD } from '../../../defs/schema/public/Tasks';
import { TASK_SORT_FUNCTIONS } from '../../../defs/sorters';
export var SequelizeErrorType;
(function (SequelizeErrorType) {
    SequelizeErrorType["INSERT"] = "INSERT";
    SequelizeErrorType["UPDATE"] = "UPDATE";
})(SequelizeErrorType || (SequelizeErrorType = {}));
export var SelectFilterType;
(function (SelectFilterType) {
    SelectFilterType["CLIENT"] = "CLIENT";
    SelectFilterType["CONTACT"] = "CONTACT";
    SelectFilterType["EMPLOYEE"] = "EMPLOYEE";
    SelectFilterType["PROJECT"] = "PROJECT";
    SelectFilterType["MANAGER"] = "MANAGER";
})(SelectFilterType || (SelectFilterType = {}));
export var APP_MODULE_ROUTE_PATH;
(function (APP_MODULE_ROUTE_PATH) {
    APP_MODULE_ROUTE_PATH["APP"] = "";
    APP_MODULE_ROUTE_PATH["ADMIN"] = "admin";
    APP_MODULE_ROUTE_PATH["STATS"] = "stats";
    APP_MODULE_ROUTE_PATH["GANTT"] = "gantt";
    APP_MODULE_ROUTE_PATH["WORKLOADTOOLS"] = "workloadtools";
    APP_MODULE_ROUTE_PATH["CALENDAR"] = "calendar";
    APP_MODULE_ROUTE_PATH["CLIENTS"] = "clients";
    APP_MODULE_ROUTE_PATH["CLIENT"] = "client";
    APP_MODULE_ROUTE_PATH["PROJECTS"] = "projects";
    APP_MODULE_ROUTE_PATH["PROJECT"] = "project";
    APP_MODULE_ROUTE_PATH["EMPLOYEES"] = "employees";
    APP_MODULE_ROUTE_PATH["EMPLOYEE"] = "employee";
    APP_MODULE_ROUTE_PATH["FIRON"] = "firon";
    APP_MODULE_ROUTE_PATH["TODO"] = "todo";
    APP_MODULE_ROUTE_PATH["NOTFOUND"] = "notfound";
    APP_MODULE_ROUTE_PATH["REPORTS"] = "reports";
    APP_MODULE_ROUTE_PATH["DASHBOARD"] = "dashboard";
})(APP_MODULE_ROUTE_PATH || (APP_MODULE_ROUTE_PATH = {}));
export var AUTH_MODULE_ROUTE_PATH;
(function (AUTH_MODULE_ROUTE_PATH) {
    AUTH_MODULE_ROUTE_PATH["AUTH"] = "auth";
    AUTH_MODULE_ROUTE_PATH["LOG_IN"] = "login";
    AUTH_MODULE_ROUTE_PATH["LOG_OUT"] = "logout";
    AUTH_MODULE_ROUTE_PATH["UNAUTHORIZED"] = "unauthorized";
})(AUTH_MODULE_ROUTE_PATH || (AUTH_MODULE_ROUTE_PATH = {}));
export var IDEABOARD_MODULE_ROUTE_PATH;
(function (IDEABOARD_MODULE_ROUTE_PATH) {
    IDEABOARD_MODULE_ROUTE_PATH["BOARD"] = "board";
})(IDEABOARD_MODULE_ROUTE_PATH || (IDEABOARD_MODULE_ROUTE_PATH = {}));
export var WIKI_MODULE_ROUTE_PATH;
(function (WIKI_MODULE_ROUTE_PATH) {
    WIKI_MODULE_ROUTE_PATH["WIKI"] = "wiki";
    WIKI_MODULE_ROUTE_PATH["LIST"] = "list";
    WIKI_MODULE_ROUTE_PATH["PAGE"] = "page";
    WIKI_MODULE_ROUTE_PATH["EDIT"] = "edit";
    WIKI_MODULE_ROUTE_PATH["ATTACHMENTS"] = "attachments";
    WIKI_MODULE_ROUTE_PATH["HISTORY"] = "history";
})(WIKI_MODULE_ROUTE_PATH || (WIKI_MODULE_ROUTE_PATH = {}));
export var PORTAL_MODULE_ROUTE_PATH;
(function (PORTAL_MODULE_ROUTE_PATH) {
    PORTAL_MODULE_ROUTE_PATH["DASHBOARD"] = "portal";
    PORTAL_MODULE_ROUTE_PATH["REQUESTS"] = "requests";
    PORTAL_MODULE_ROUTE_PATH["INCIDENTS"] = "incidents";
    PORTAL_MODULE_ROUTE_PATH["CHANGELOGS"] = "changelogs";
    PORTAL_MODULE_ROUTE_PATH["CONTACTS"] = "contacts";
})(PORTAL_MODULE_ROUTE_PATH || (PORTAL_MODULE_ROUTE_PATH = {}));
export var PROJECT_TABS;
(function (PROJECT_TABS) {
    PROJECT_TABS["MILESTONES"] = "milestones";
    PROJECT_TABS["BUDGETS"] = "budgets";
    PROJECT_TABS["TASKS"] = "tasks";
    PROJECT_TABS["RELEASES"] = "releases";
    PROJECT_TABS["BACKUPS"] = "backups";
    PROJECT_TABS["PARAMS"] = "params";
    PROJECT_TABS["NOTES"] = "notes";
})(PROJECT_TABS || (PROJECT_TABS = {}));
export var APP_DEFAULT_ROUTE = "/" + AUTH_MODULE_ROUTE_PATH.AUTH + "/" + AUTH_MODULE_ROUTE_PATH.LOG_IN;
export var PROJECT_TABS_LABELS = (_a = {},
    _a[PROJECT_TABS.MILESTONES] = 'Milestones',
    _a[PROJECT_TABS.BUDGETS] = 'Budgets',
    _a[PROJECT_TABS.TASKS] = 'Tasks',
    _a[PROJECT_TABS.RELEASES] = 'Releases',
    _a[PROJECT_TABS.BACKUPS] = 'Backups',
    _a[PROJECT_TABS.PARAMS] = 'Params',
    _a[PROJECT_TABS.NOTES] = 'Notes',
    _a);
export var PROJECT_TABS_RIGHTS = (_b = {},
    _b[PROJECT_TABS.MILESTONES] = RIGHTS.PROJECT_TAB_MS,
    _b[PROJECT_TABS.BUDGETS] = RIGHTS.PROJECT_TAB_BG,
    _b[PROJECT_TABS.TASKS] = RIGHTS.PROJECT_TAB_TK,
    _b[PROJECT_TABS.RELEASES] = RIGHTS.PROJECT_TAB_RL,
    _b[PROJECT_TABS.BACKUPS] = RIGHTS.PROJECT_TAB_BK,
    _b[PROJECT_TABS.PARAMS] = [RIGHTS.PROJECT_TAB_PR, RIGHTS.TAG_CREATE],
    _b);
export var CLIENT_TABS;
(function (CLIENT_TABS) {
    CLIENT_TABS["MILESTONES"] = "milestones";
    CLIENT_TABS["PROJECTS"] = "projects";
    CLIENT_TABS["TASKS"] = "tasks";
    CLIENT_TABS["BACKUPS"] = "backups";
    CLIENT_TABS["REQUESTS"] = "requests";
    CLIENT_TABS["INCIDENTS"] = "incidents";
    CLIENT_TABS["CHANGELOGS"] = "changelogs";
    CLIENT_TABS["NOTES"] = "notes";
})(CLIENT_TABS || (CLIENT_TABS = {}));
export var CLIENT_TABS_LABELS = (_c = {},
    _c[CLIENT_TABS.MILESTONES] = 'Milestones',
    _c[CLIENT_TABS.PROJECTS] = 'Projects',
    _c[CLIENT_TABS.TASKS] = 'Tasks',
    _c[CLIENT_TABS.BACKUPS] = 'Backups',
    _c[CLIENT_TABS.REQUESTS] = 'Requests',
    _c[CLIENT_TABS.INCIDENTS] = 'Incidents',
    _c[CLIENT_TABS.CHANGELOGS] = 'Changelogs',
    _c[CLIENT_TABS.NOTES] = 'Notes',
    _c);
export var CLIENT_TABS_RIGHTS = (_d = {},
    _d[CLIENT_TABS.MILESTONES] = RIGHTS.CLIENT_TAB_MS,
    _d[CLIENT_TABS.PROJECTS] = RIGHTS.CLIENT_TAB_PJ,
    _d[CLIENT_TABS.BACKUPS] = RIGHTS.CLIENT_TAB_BK,
    _d[CLIENT_TABS.REQUESTS] = RIGHTS.CLIENT_TAB_RQ,
    _d[CLIENT_TABS.INCIDENTS] = RIGHTS.CLIENT_TAB_IN,
    _d[CLIENT_TABS.CHANGELOGS] = RIGHTS.CLIENT_TAB_CH,
    _d);
export var REPORT_TABS;
(function (REPORT_TABS) {
    REPORT_TABS["STATS"] = "stats";
    REPORT_TABS["PROJECT"] = "project";
    REPORT_TABS["CLIENT"] = "client";
    REPORT_TABS["EMPLOYEE"] = "employee";
    REPORT_TABS["TASKS"] = "tasks";
})(REPORT_TABS || (REPORT_TABS = {}));
export var REPORT_TABS_LABELS = (_e = {},
    _e[REPORT_TABS.STATS] = 'Stats',
    _e[REPORT_TABS.PROJECT] = 'Project',
    _e[REPORT_TABS.CLIENT] = 'Client',
    _e[REPORT_TABS.EMPLOYEE] = 'Employee',
    _e[REPORT_TABS.TASKS] = 'Tasks',
    _e);
export var ADMIN_PANEL_TABS;
(function (ADMIN_PANEL_TABS) {
    ADMIN_PANEL_TABS["MAIN"] = "main";
    ADMIN_PANEL_TABS["CLIENTS"] = "clients";
    ADMIN_PANEL_TABS["TRANSLATIONS"] = "i18n";
    ADMIN_PANEL_TABS["ACCESS_CONTROL"] = "access";
})(ADMIN_PANEL_TABS || (ADMIN_PANEL_TABS = {}));
export var ADMIN_PANEL_TABS_LABELS = (_f = {},
    _f[ADMIN_PANEL_TABS.MAIN] = 'main',
    _f[ADMIN_PANEL_TABS.CLIENTS] = 'clients',
    _f[ADMIN_PANEL_TABS.TRANSLATIONS] = 'i18n',
    _f[ADMIN_PANEL_TABS.ACCESS_CONTROL] = 'access',
    _f);
export var DEFAULT_PAGE_TEMPLATE = (_g = {},
    _g[WikiPageType.PAGE] = '',
    _g[WikiPageType.MEETING_NOTE] = 'Participants : ',
    _g[WikiPageType.TECHNICAL_NOTE] = '',
    _g[WikiPageType.CHANGELOG] = 'Changelog',
    _g);
export var TASK_STATUS_FILTER = (_h = {},
    _h[TaskStatusType.BACKLOG] = {
        translation: 'backlog',
        classList: 'status-backlog',
        icon: 'folder',
        color: '#70acb5',
    },
    _h[TaskStatusType.TODO] = {
        translation: 'todo',
        classList: 'status-todo',
        icon: 'ellipsis-horizontal',
        color: '#61c84e',
    },
    _h[TaskStatusType.INPROGRESS] = {
        translation: 'inprogress',
        classList: 'status-inprogress',
        icon: 'note',
        color: '#ffba10',
    },
    _h[TaskStatusType.REVIEW] = {
        translation: 'review',
        classList: 'status-review',
        icon: 'clipboard',
        color: '#ff4c00',
    },
    _h[TaskStatusType.REVIEWING] = {
        translation: 'reviewing',
        classList: 'status-reviewing',
        icon: 'tasks',
        color: '#9d5973',
    },
    _h[TaskStatusType.DONE] = {
        translation: 'done',
        classList: 'status-done',
        icon: 'check',
        color: '#21bdd5',
    },
    _h);
export var API_HTTP_ROOT = publicConfig.server.httpsPort
    ? "https://" + publicConfig.server.apiDomain + ":" + publicConfig.server.httpsPort + ("" + publicConfig.server.apiPath)
    : "http://" + publicConfig.server.apiDomain + ":" + publicConfig.server.httpPort + ("" + publicConfig.server.apiPath);
export var API_VERSION = publicConfig.server.apiVersion;
export var trackByIndex = function (index) { return index; };
export var noop = function () { };
export var containsSlug = function (value, search) {
    return slugify(value.toLocaleLowerCase()).indexOf(slugify(search.toLocaleLowerCase())) !== -1;
};
export var equalsSlug = function (value, search) {
    return slugify(value.toLocaleLowerCase()) === slugify(search.toLocaleLowerCase());
};
export var normalize = function (val, max, min) {
    if (max - min === 0) {
        return 0;
    }
    return (val - min) / (max - min) || 0;
};
export var formatTime = function (date) {
    if (!date) {
        return '';
    }
    var mDate = moment(date);
    if (!mDate.isValid()) {
        return '';
    }
    var time = mDate.format('HH:mm');
    if (time === '00:00') {
        return '';
    }
    return time;
};
export var isColorDark = function (color) {
    var hexToRgb = function (hex) {
        if (!hex) {
            return null;
        }
        var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
        hex = hex.replace(shorthandRegex, function (m, r, g, b) { return r + r + g + g + b + b; });
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result
            ? {
                r: parseInt(result[1], 16),
                g: parseInt(result[2], 16),
                b: parseInt(result[3], 16),
            }
            : null;
    };
    var rgb = hexToRgb(color);
    if (!rgb) {
        return true;
    }
    var o = Math.round((rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000);
    return o >= 145;
};
export var sortNumbers = function (a, b) {
    return a - b;
};
export var sortStrings = function (a, b) {
    return a.localeCompare(b);
};
export var sortDates = function (a, b) {
    return moment(a).isSameOrBefore(moment(b)) ? -1 : 1;
};
export var getSequelizeErrorReasons = function (stack) {
    var err = stack.split('\n')[0];
    var NEW_ROW_ERROR = 'SequelizeDatabaseError: new row for relation';
    var CONSTRAINT_VIOLATION = 'violates check constraint';
    var posNewRowError = err.indexOf(NEW_ROW_ERROR);
    if (posNewRowError > -1) {
        var posViolation = err.indexOf(CONSTRAINT_VIOLATION);
        var tableConcerned = err
            .substring(NEW_ROW_ERROR.length, posViolation)
            .replace(/"/g, '')
            .trim();
        return {
            type: SequelizeErrorType.INSERT,
            fieldConcerned: '',
            foreignTable: '',
            table: tableConcerned,
        };
    }
    var FOREIGN_KEY_ERROR = 'SequelizeForeignKeyConstraintError: update or delete on table';
    var CONSTRAINT_VIOLATION_FK = 'violates foreign key constraint';
    var ON_TABLE = 'on table';
    var posConstraintError = err.indexOf(CONSTRAINT_VIOLATION_FK);
    var posTargetTable = err.indexOf(ON_TABLE, posConstraintError);
    var firstTable = err
        .substring(FOREIGN_KEY_ERROR.length, posConstraintError)
        .replace(/"/g, '')
        .trim();
    var secondTable = err
        .substr(posTargetTable + ON_TABLE.length, err.length)
        .replace(/"/g, '')
        .trim();
    var violation = err
        .substr(CONSTRAINT_VIOLATION_FK.length + posConstraintError, err.length - posTargetTable)
        .replace(/"/g, '')
        .trim();
    var field = violation.substr(violation.indexOf('_') + 1);
    return {
        type: SequelizeErrorType.UPDATE,
        fieldConcerned: field,
        foreignTable: secondTable,
        table: firstTable,
    };
};
export var getMilestoneIcon = function (eventType) {
    switch (eventType) {
        case MilestonesType.DEADLINE:
            return 'calendar-times';
        case MilestonesType.MEETING:
        case MilestonesType.MEETING_NO_NOTE:
            return 'users';
        case MilestonesType.CALL:
            return 'phone';
        case MilestonesType.REMINDER:
            return 'bell';
        case MilestonesType.RELEASE:
            return 'calendar-check';
        default:
            return null;
            break;
    }
    return '';
};
export var MAX_LENGTH_PROJECT_CODE = 5;
export var MAX_LENGTH_CLIENT_CODE = 5;
export var MAX_LENGTH_EMP_CODE = 3;
export var chartMainColor = {
    domain: [
        '#009CBF',
        '#00BFA9',
        '#62A420',
        '#FAC400',
        '#F57600',
        '#F54F47',
        '#ED186F',
        '#9B56BB',
        '#6870C4',
        '#0095D3',
        '#FF681C',
        '#A89E95',
        '#798893',
    ],
};
export var calendarMainColor = {
    domain: [
        '#b0dde1',
        '#8bc9d2',
        '#64cad9',
        '#36C9E1',
        '#00B7D6',
        '#009CBF',
        '#0081A7',
        '#006690',
        '#005680',
        '#004A70',
    ],
};
export var getRandomColorHex = function () {
    return "#" + (Math.random().toString(16) + '000000').slice(2, 8);
};
export var DATAGRID_FILTER_TYPE;
(function (DATAGRID_FILTER_TYPE) {
    DATAGRID_FILTER_TYPE["FUZZY"] = "FUZZY";
    DATAGRID_FILTER_TYPE["DATE"] = "DATE";
    DATAGRID_FILTER_TYPE["CUSTOM"] = "CUSTOM";
    DATAGRID_FILTER_TYPE["SELECT"] = "SELECT";
})(DATAGRID_FILTER_TYPE || (DATAGRID_FILTER_TYPE = {}));
export var sortFnArrayWrapper = function (sortFn) { return function (e1, e2) {
    var _a = [e1, e2].map(function (entity) { return (Array.isArray(entity) ? entity : [entity]); }), a1 = _a[0], a2 = _a[1];
    if (a1.length !== a2.length) {
        return a1.length > a2.length ? -1 : 1;
    }
    var _b = [a1, a2].map(function (array) { return array.sort(sortFn)[0]; }), m1 = _b[0], m2 = _b[1];
    return typeof m1 === 'undefined' ? -1 : typeof m2 === 'undefined' ? 1 : sortFn(m1, m2);
}; };
export var BUDGET_STATUS_FILTER = (_j = {},
    _j[BudgetStatus.ESTIMATED] = {
        classList: 'text-budget-estimated',
        icon: 'unknown-status',
        translation: 'budget_estimated',
    },
    _j[BudgetStatus.BILLED] = {
        classList: 'text-budget-billed',
        icon: 'check',
        translation: 'budget_billed',
    },
    _j[BudgetStatus.CANCELLED] = {
        classList: 'text-budget-cancelled',
        icon: 'times',
        translation: 'budget_cancelled',
    },
    _j);
export var MILESTONE_TYPE_FILTER = (_k = {},
    _k[MilestonesType.MEETING] = {
        classList: 'text-milestone-meeting',
        icon: 'users',
        translation: 'milestone_meeting',
        color: '#21bdd5',
    },
    _k[MilestonesType.MEETING_NO_NOTE] = {
        classList: 'text-milestone-meeting',
        icon: 'users',
        translation: 'milestone_meeting_no_note',
        color: '#21bdd5',
    },
    _k[MilestonesType.DEADLINE] = {
        classList: 'text-milestone-deadline',
        icon: 'step-forward-2',
        translation: 'milestone_deadline',
        color: '#ff4136',
    },
    _k[MilestonesType.REMINDER] = {
        classList: 'text-milestone-reminder',
        icon: 'bell',
        translation: 'milestone_reminder',
        color: '#ffba10',
    },
    _k[MilestonesType.RELEASE] = {
        classList: 'text-milestone-release',
        icon: 'checkbox-list',
        translation: 'milestone_release',
        color: '#ff4c00',
    },
    _k[MilestonesType.CALL] = {
        classList: 'text-milestone-call',
        icon: 'phone-handset',
        translation: 'milestone_call',
        color: '#36b358',
    },
    _k);
export var getGroupDisplayName = function (name) {
    return (!name && '') || name.substr(0, 1) === '_' ? name.substr(1) : name;
};
export var PROJECT_STATUS_FILTER = (_l = {},
    _l[ProjectStatusType.OPEN] = {
        classList: 'text-success',
        icon: 'play',
        translation: 'project_open',
    },
    _l[ProjectStatusType.WAITING] = {
        classList: 'text-warning',
        icon: 'pause',
        translation: 'project_waiting',
    },
    _l[ProjectStatusType.CLOSE] = {
        classList: 'text-danger',
        icon: 'stop',
        translation: 'project_close',
    },
    _l);
export var WIKI_PAGE_TYPE_FILTER = (_m = {},
    _m[WikiPageType.PAGE] = { icon: 'file', translation: 'PAGE' },
    _m[WikiPageType.MEETING_NOTE] = { icon: 'users', translation: 'meeting_note' },
    _m[WikiPageType.TECHNICAL_NOTE] = { icon: 'cog', translation: 'technical_note' },
    _m[WikiPageType.CHANGELOG] = { icon: 'code', translation: 'changelog' },
    _m);
export var RELEASE_STATE_FILTER = (_o = {},
    _o[ReleaseStateType.STAGED] = {
        classList: 'text-info',
        icon: 'ellipsis-horizontal',
        translation: 'release_staged',
    },
    _o[ReleaseStateType.IN_DEVELOPMENT] = {
        classList: 'text-warning',
        icon: 'edit',
        translation: 'release_unreleased',
    },
    _o[ReleaseStateType.RELEASED] = {
        classList: 'text-success',
        icon: 'truck',
        translation: 'release_released',
    },
    _o);
export var RELEASES_REPORT_COLUMNS = [
    { field: MILESTONE_FIELD.obs, translateKey: 'table_name' },
    { field: 'projectname', translateKey: 'project' },
    {
        field: MILESTONE_FIELD.version,
        translateKey: 'version',
        sorter: {
            compare: TASK_SORT_FUNCTIONS[TASK_FIELD.targetReleaseId],
        },
    },
    { field: MILESTONE_FIELD.beginDate, translateKey: 'date_begin', filterType: DATAGRID_FILTER_TYPE.DATE },
    { field: MILESTONE_FIELD.endDate, translateKey: 'date_end', filterType: DATAGRID_FILTER_TYPE.DATE },
    { field: MILESTONE_FIELD.releaseState, translateKey: 'table_status', filterType: DATAGRID_FILTER_TYPE.CUSTOM },
    { field: 'failedreview', translateKey: 'failed_review' },
    { translateKey: 'completion' },
    { translateKey: 'remaining' },
    { translateKey: 'budget' },
    { translateKey: 'profit' },
    { translateKey: 'variance' },
];
export var DEFAULT_ACCEPTED_TYPES = [
    MimeTypes.XLS,
    MimeTypes.JPG,
    MimeTypes.JPEG,
    MimeTypes.PNG,
    MimeTypes.ZIP,
    MimeTypes.PDF,
    MimeTypes.DOCX,
    MimeTypes.PPTX,
    MimeTypes.XLSX,
    MimeTypes.DOC,
    MimeTypes.XML,
    MimeTypes.CSV,
    MimeTypes.RTF,
    MimeTypes.SVG,
    MimeTypes.XMLTEXT,
    MimeTypes.CSVEXCEL,
    MimeTypes.PLAIN,
];
