import * as i0 from "./modal-simple.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@clr/angular";
import * as i3 from "@ngx-translate/core";
import * as i4 from "@angular/common";
import * as i5 from "../shared/movable/movable.directive";
import * as i6 from "../../../node_modules/@clr/angular/clr-angular.ngfactory";
import * as i7 from "./modal-simple.component";
import * as i8 from "../shared/shortcut-handler/shortcut-handler.service";
import * as i9 from "./modal-simple.service";
var styles_ModalSimpleComponent = [i0.styles];
var RenderType_ModalSimpleComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalSimpleComponent, data: {} });
export { RenderType_ModalSimpleComponent as RenderType_ModalSimpleComponent };
function View_ModalSimpleComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "modal-header movable-handle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "clr-icon", [["aria-hidden", "true"], ["shape", "close"]], null, null, null, null, null)), i1.ɵdid(3, 16384, null, 0, i2.ClrIconCustomTag, [], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "h3", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co.title)); _ck(_v, 5, 0, currVal_0); }); }
function View_ModalSimpleComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.contentString; _ck(_v, 1, 0, currVal_0); }); }
function View_ModalSimpleComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.contentI18n, _co.contentObj)); _ck(_v, 2, 0, currVal_0); }); }
function View_ModalSimpleComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalSimpleComponent_3)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalSimpleComponent_4)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !!_co.contentString; _ck(_v, 2, 0, currVal_0); var currVal_1 = !!_co.contentI18n; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_ModalSimpleComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 21, "div", [["class", "modal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 19, "div", [["appMovable", ""], ["aria-hidden", "true"], ["class", "modal-dialog"], ["role", "dialog"]], [[2, "modal-sm", null], [2, "modal-lg", null], [2, "modal-xl", null]], [[null, "mousedown"], ["document", "mousemove"], ["document", "mouseup"]], function (_v, en, $event) { var ad = true; if (("mousedown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).mouseDown($event) !== false);
        ad = (pd_0 && ad);
    } if (("document:mousemove" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).mouseMove($event) !== false);
        ad = (pd_1 && ad);
    } if (("document:mouseup" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2).mouseUp($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 4210688, null, 0, i5.MovableDirective, [i1.NgZone, i1.ElementRef], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 17, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalSimpleComponent_1)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalSimpleComponent_2)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 12, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 5, "button", [], [[8, "className", 0], [1, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_ClrLoadingButton_0, i6.RenderType_ClrLoadingButton)), i1.ɵdid(10, 49152, null, 0, i2.ClrLoadingButton, [i1.ElementRef, i1.Renderer2], null, null), i1.ɵprd(2048, null, i2.LoadingListener, null, [i2.ClrLoadingButton]), i1.ɵdid(12, 147456, null, 0, i2.ClrLoading, [[2, i2.LoadingListener]], { loadingState: [0, "loadingState"] }, null), (_l()(), i1.ɵted(13, 0, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(15, 0, null, null, 5, "button", [], [[8, "className", 0], [1, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.validate() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_ClrLoadingButton_0, i6.RenderType_ClrLoadingButton)), i1.ɵdid(16, 49152, null, 0, i2.ClrLoadingButton, [i1.ElementRef, i1.Renderer2], null, null), i1.ɵprd(2048, null, i2.LoadingListener, null, [i2.ClrLoadingButton]), i1.ɵdid(18, 147456, null, 0, i2.ClrLoading, [[2, i2.LoadingListener]], { loadingState: [0, "loadingState"] }, null), (_l()(), i1.ɵted(19, 0, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(21, 0, null, null, 0, "div", [["aria-hidden", "true"], ["class", "modal-backdrop"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_3 = !!_co.title; _ck(_v, 5, 0, currVal_3); var currVal_4 = (!!_co.contentString || !!_co.contentI18n); _ck(_v, 7, 0, currVal_4); var currVal_7 = _co.cancelBtnState; _ck(_v, 12, 0, currVal_7); var currVal_11 = _co.validateBtnState; _ck(_v, 18, 0, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.size === "sm"); var currVal_1 = (_co.size === "lg"); var currVal_2 = (_co.size === "xl"); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_5 = i1.ɵinlineInterpolate(1, "btn ", _co.ko.class, ""); var currVal_6 = (i1.ɵnov(_v, 10).disabled ? "" : null); _ck(_v, 9, 0, currVal_5, currVal_6); var currVal_8 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform(_co.ko.i18n)); _ck(_v, 13, 0, currVal_8); var currVal_9 = i1.ɵinlineInterpolate(1, "btn ", _co.ok.class, ""); var currVal_10 = (i1.ɵnov(_v, 16).disabled ? "" : null); _ck(_v, 15, 0, currVal_9, currVal_10); var currVal_12 = i1.ɵunv(_v, 19, 0, i1.ɵnov(_v, 20).transform(_co.ok.i18n)); _ck(_v, 19, 0, currVal_12); }); }
export function View_ModalSimpleComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal-simple", [], null, null, null, View_ModalSimpleComponent_0, RenderType_ModalSimpleComponent)), i1.ɵdid(1, 180224, null, 0, i7.ModalSimpleComponent, [i8.ShortcutHandlerService, i9.ModalSimpleService], null, null)], null, null); }
var ModalSimpleComponentNgFactory = i1.ɵccf("app-modal-simple", i7.ModalSimpleComponent, View_ModalSimpleComponent_Host_0, {}, {}, []);
export { ModalSimpleComponentNgFactory as ModalSimpleComponentNgFactory };
