var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiRoutePlurality, CustomerRequestStatus, DECIMAL_RADIX, HTTP_METHOD, STORED_TYPE, } from '../../../../defs/schema-static';
import { CUSTOMER_REQUEST_SCHEMA_ROUTE } from '../../../../defs/schema/public/CustomerRequests';
import { FileDownloaderService } from '../shared/file-downloader/file-downloader.service';
import { FileManagerService } from '../shared/file-manager/file-manager.service';
import { HttpRestService } from '../shared/http-rest/http-rest.service';
var RequestComponent = (function () {
    function RequestComponent(httpRest, route, fileManager, fileDownloader) {
        this.httpRest = httpRest;
        this.route = route;
        this.fileManager = fileManager;
        this.fileDownloader = fileDownloader;
        this.CustomerRequestStatus = CustomerRequestStatus;
        this.getFilename = FileManagerService.getFilename;
        this.downloadS3 = this.fileDownloader.downloadS3.bind(this.fileDownloader);
    }
    RequestComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.attachments = [];
        this.route.params.subscribe(function (params) { return (_this.clientId = parseInt(params.id, DECIMAL_RADIX)); });
        this.httpRest
            ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, CUSTOMER_REQUEST_SCHEMA_ROUTE, "client/" + this.clientId)
            .subscribe(function (requests) { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4, Promise.all(requests.map(function (request) { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4, this.getAttachments(request)];
                                        case 1:
                                            _a.sent();
                                            return [2, request];
                                    }
                                });
                            }); }))];
                    case 1:
                        _a.requests = _b.sent();
                        return [2];
                }
            });
        }); });
    };
    RequestComponent.prototype.changeRequestStatus = function (request, status) {
        this.httpRest
            .post(CUSTOMER_REQUEST_SCHEMA_ROUTE, {
            id: request.id,
            status: status,
        })
            .subscribe(function (updated) {
            request.status = updated.status;
        });
    };
    RequestComponent.prototype.getAttachments = function (request) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b, _c, _d, _e, _f;
            return __generator(this, function (_g) {
                switch (_g.label) {
                    case 0:
                        _b = this.attachments;
                        _c = request.id;
                        _e = (_d = (_a = this.fileManager).signFiles).apply;
                        _f = [_a];
                        return [4, this.fileManager.getFileList(STORED_TYPE.CUSTOMER_REQUEST_FILE, {
                                customerRequest: request,
                                customerRequestId: request.id,
                            })];
                    case 1: return [4, _e.apply(_d, _f.concat([(_g.sent())]))];
                    case 2:
                        _b[_c] = _g.sent();
                        return [2];
                }
            });
        });
    };
    return RequestComponent;
}());
export { RequestComponent };
