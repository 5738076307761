// tslint:disable no-magic-numbers
import moment from 'moment';
import {DateFerie} from './moment.ferie';
import {
    DECIMAL_RADIX,
    MilestonesType,
    MILLISECONDS_IN_SECOND,
    ProjectMemberType,
    SECONDS_IN_MINUTE,
} from './schema-static';
import {DAYS, IConfig, IRules} from './schema/meta/Config';
import {IMilestone} from './schema/public/Milestones';
import {IProjectMember} from './schema/public/ProjectMembers';
import {IProject} from './schema/public/Projects';
import {ITime} from './schema/public/Times';
import {SETTING_LANGUAGE_VALUES, SETTING_THEME_VALUES} from './schema/public/Users';

export const RULES_DEFAULT_VALUES: IRules = {
    appName: 'Project Manager 2k018',
    mailDomain: 'example.com',
    workingDays: [DAYS.MONDAY, DAYS.TUESDAY, DAYS.WEDNESDAY, DAYS.THURSDAY, DAYS.FRIDAY],
    workingHours: {
        start: '08:30',
        end: '17:00',
    },
    lunchHours: {
        start: '12:00',
        end: '13:30',
    },
    enabledI18n: [SETTING_LANGUAGE_VALUES.FR, SETTING_LANGUAGE_VALUES.EN],
    defaultTheme: SETTING_THEME_VALUES.DARK,
    calendarHours: {
        start: '08:00',
        end: '18:00',
    },
};

export const HOURS_IN_DAY = 24;
export const MINUTES_IN_HOUR = 60;

// export const WORKING_DAYS = [1, 2, 3, 4, 5];
// export const HOURSW_IN_DAY = 7;
// export const MINUTES_IN_DAY = HOURSW_IN_DAY * MINUTES_IN_HOUR;

// export const START_HOURS_DAY = 8;
// export const START_MINUTES_DAY = 30;
// export const END_HOURS_DAY = 17;
// export const END_MINUTES_DAY = 0;
// export const START_LUNCH_HOUR = 12;
// export const START_LUNCH_MINUTES = 30;

// export const AVERAGE_PRICE_EMP = 520;

// const MEAL_TIME_HOURS = 1.5;
// export const MEAL_TIME = MEAL_TIME_HOURS * MINUTES_IN_HOUR;
// export const NOT_WORKED_MINUTES_DAY = 17 * MINUTES_IN_HOUR;

// export const WORKING_MINUTES_IN_DAY = END_HOURS_DAY * MINUTES_IN_HOUR + END_MINUTES_DAY -
//   (START_HOURS_DAY * MINUTES_IN_HOUR + START_MINUTES_DAY) - MEAL_TIME;
// export const WORKING_HOURS_IN_DAY = WORKING_MINUTES_IN_DAY / MINUTES_IN_HOUR;
// export const WORKING_DAYS_IN_WEEK = 5;

export interface IProjectProgress {
    progress: number;
    totalTimeMinutes: number;
    overTime: number;
}

export interface ITaskDetail {
    progress: number;
    estimatedTime: number;
    totalTimeMinutes: number;
    overTime: number;
}

export interface IProjectDash extends IProject {
    progress?: number;
    totalTimeMinutes?: number;
    price?: number;
    overTime?: number;
    budgetSum?: number;
    roleType?: ProjectMemberType;
    managers?: Partial<IProjectMember>[];
}

export const normalize = (val: number, max: number, min: number) => {
    if (max - min === 0) {
        return 0;
    }

    return (val - min) / (max - min) || 0;
};

export const getConfigKeys = (config: IConfig[], rules: (keyof IRules)[]) => {
    const configRules = config.reduce(
        (o, cur: any) => {
            o[cur.key as keyof IRules] = cur.value;

            return o;
        },
        {} as Partial<IRules>
    );

    const rulesObj = rules.reduce(
        (obj: IRules, key: keyof IRules) => {
            // @ts-ignore
            obj[key] = configRules[key];

            return obj;
        },
        {} as Partial<IRules>
    );

    rules.map((rule) => {
        if (!rulesObj[rule]) {
            // @ts-ignore
            rulesObj[rule] = RULES_DEFAULT_VALUES[rule];
        }
    });

    return rulesObj;
};

export const isWorkingDay = (config: IConfig[], date: moment.Moment) => {
    return getConfigKeys(config, ['workingDays']).workingDays.indexOf(date.isoWeekday()) !== -1;
};

export const isWorkingDate = (config: IConfig[], date: moment.Moment) => {
    return !new DateFerie(date).isFerie() && isWorkingDay(config, date);
};

export const setTime = (m: moment.Moment, h: string) => {
    const t = h.split(':').map((_t) => parseInt(_t, DECIMAL_RADIX));

    return m
        .clone()
        .hours(t[0])
        .minutes(t[1])
        .seconds(0)
        .milliseconds(0);
};

export const getWorkingTimePerDay = (c: IConfig[], unit: moment.unitOfTime.Base) => {
    const config = getConfigKeys(c, ['workingHours', 'lunchHours']);
    const now = moment();

    const workStart = setTime(now, config.workingHours.start);
    const workEnd = setTime(now, config.workingHours.end);

    const workDuration = moment.duration(workEnd.diff(workStart)).as(unit);

    const lunchStart = setTime(now, config.lunchHours.start);

    const lunchEnd = setTime(now, config.lunchHours.end);

    const lunchDuration = moment.duration(lunchEnd.diff(lunchStart)).as(unit);

    return workDuration - lunchDuration;
};

export const stringTimeToMinute = (c: IConfig[], estimated: string): number => {
    if (!estimated || estimated === '') {
        return 0;
    }

    const config = getConfigKeys(c, ['workingDays', 'workingHours']);

    const timeArray = estimated.split(' ');
    let weeks = 0;
    let days = 0;
    let hours = 0;
    let minutes = 0;
    timeArray.map((_time) => {
        if (_time.indexOf('w') > -1) {
            weeks = parseInt(_time.replace('w', ''), 10);
        } else if (_time.indexOf('d') > -1) {
            days = parseInt(_time.replace('d', ''), 10);
        } else if (_time.indexOf('h') > -1) {
            hours = parseInt(_time.replace('h', ''), 10);
        } else if (_time.indexOf('m') > -1) {
            minutes = parseInt(_time.replace('m', ''), 10);
        }
    });

    const workingHours = getWorkingTimePerDay(c, 'hours');

    if (weeks) {
        minutes += weeks * config.workingDays.length * workingHours * MINUTES_IN_HOUR;
    }
    if (days) {
        minutes += days * workingHours * MINUTES_IN_HOUR;
    }
    if (hours) {
        minutes += hours * MINUTES_IN_HOUR;
    }

    return minutes;
};

export const convertTimeToMinutes = (config: IConfig[], time: Partial<ITime>): number => {
    const c = getConfigKeys(config, ['workingDays']);
    let minutes = 0;
    if (time.weeks) {
        minutes += time.weeks * c.workingDays.length * getWorkingTimePerDay(config, 'hours') * MINUTES_IN_HOUR;
    }
    if (time.days) {
        minutes += time.days * getWorkingTimePerDay(config, 'hours') * MINUTES_IN_HOUR;
    }
    if (time.hours) {
        minutes += time.hours * MINUTES_IN_HOUR;
    }
    if (time.minutes) {
        minutes += time.minutes;
    }

    return minutes;
};

export const getLunchTimePerDay = (c: IConfig[], unit: moment.unitOfTime.Base) => {
    const config = getConfigKeys(c, ['lunchHours']);

    const now = moment();

    const lunchStart = setTime(now, config.lunchHours.start);

    const lunchEnd = setTime(now, config.lunchHours.end);

    return moment.duration(lunchEnd.diff(lunchStart)).as(unit);
};

export const getWorkingHours = (c: IConfig[]) => {
    const config = getConfigKeys(c, ['workingHours']);

    return config.workingHours;
};

export const calculCostFromTime = (
    c: IConfig[],
    weeks: number,
    days: number,
    hours: number,
    minutes: number,
    costDay: number
) => {
    const config = getConfigKeys(c, ['workingDays']);
    const workingTimePerDay = getWorkingTimePerDay(c, 'hours');

    let total = 0;
    if (weeks) {
        total += weeks * config.workingDays.length;
    }
    if (days) {
        total += days;
    }
    if (hours) {
        total += hours / workingTimePerDay;
    }
    if (minutes) {
        total += minutes / MINUTES_IN_HOUR / workingTimePerDay;
    }

    return total * costDay;
};

export const minutesToWorkingTime = (c: IConfig[], totalMinutes: number): string => {
    if (!totalMinutes) {
        return '';
    }

    const config = getConfigKeys(c, ['workingDays']);
    const workingTimePerDay = getWorkingTimePerDay(c, 'hours');

    const weeksRatio = config.workingDays.length * workingTimePerDay * MINUTES_IN_HOUR;

    const weeks = Math.floor(totalMinutes / weeksRatio);
    totalMinutes -= weeks * weeksRatio;

    const daysRatio = workingTimePerDay * MINUTES_IN_HOUR;
    const days = Math.floor(totalMinutes / daysRatio);
    totalMinutes -= days * daysRatio;

    const hoursRatio = MINUTES_IN_HOUR;
    const hours = Math.floor(totalMinutes / hoursRatio);
    totalMinutes -= hours * hoursRatio;

    const minutes = Math.round(totalMinutes);

    let time = '';
    if (weeks) {
        time += `${weeks}w `;
    }
    if (days) {
        time += `${days}d `;
    }
    if (hours) {
        time += `${hours}h `;
    }
    if (minutes) {
        time += `${minutes}m`;
    }

    return time.trim();
};

export const getMinutesToString = (minutes: number, full = true) => {
    if (minutes === 0) {
        return '';
    }
    const now = moment();
    const then = moment().add(minutes, 'minutes');
    const ms = moment(then, 'DD/MM/YYYY HH:mm').diff(moment(now, 'DD/MM/YYYY HH:mm'));
    const d = moment.duration(ms);

    let strTime = '';
    if (full) {
        if (d.get('months')) {
            strTime += `${d.get('months')} mois `;
        }

        if (d.get('days')) {
            strTime += `${d.get('days')}${d.get('days') > 1 ? ' jours ' : ' jour '}`;
        }

        if (d.get('hours')) {
            strTime += `${d.get('hours')}${d.get('hours') > 1 ? ' heures ' : ' heure '}`;
        }

        if (d.get('minutes')) {
            strTime += `${d.get('minutes')}${d.get('minutes') > 1 ? ' minutes ' : ' minute '}`;
        }
    } else {
        if (d.get('months')) {
            strTime += `${d.get('months')} M `;
        }

        if (d.get('days')) {
            strTime += `${d.get('days')}${d.get('days') > 1 ? ' d ' : ' d '}`;
        }

        if (d.get('hours')) {
            strTime += `${d.get('hours')}${d.get('hours') > 1 ? ' h ' : ' h '}`;
        }

        if (d.get('minutes')) {
            strTime += `${d.get('minutes')}${d.get('minutes') > 1 ? ' m ' : ' m '}`;
        }
    }

    return strTime;
};

export const checkOrderDates = (a: moment.Moment, b: moment.Moment) => {
    if (a.isAfter(b)) {
        // swap
        const tmp = b;
        b = a.clone();
        a = tmp.clone();
    } else {
        a = a.clone();
        b = b.clone();
    }
};

export const notWorkingDays = (c: IConfig[], a: moment.Moment, b: moment.Moment, unit: moment.unitOfTime.Base) => {
    checkOrderDates(a, b);
    const days = moment.duration(0);
    while (a.isSameOrBefore(b, 'days')) {
        if (!isWorkingDate(c, a)) {
            days.add(1, 'day');
        }
        a.add(1, 'day');
    }

    return days.as(unit);
};

export const workingDays = (config: IConfig[], a: moment.Moment, b: moment.Moment) => {
    checkOrderDates(a, b);
    let days = 0;
    while (a.isSameOrBefore(b, 'days')) {
        if (isWorkingDate(config, a)) {
            days += 1;
        }
        a.add(1, 'day');
    }

    return days;
};

export const mealTimeForTheDay = (c: IConfig[], a: moment.Moment, b: moment.Moment, unit: moment.unitOfTime.Base) => {
    if (!a.isSame(b, 'day')) {
        return 0;
    }

    const config = getConfigKeys(c, ['lunchHours']);

    const lunchStart = setTime(a, config.lunchHours.start);

    if (a.isAfter(lunchStart) || b.isSameOrBefore(lunchStart)) {
        return 0;
    }

    return getLunchTimePerDay(c, 'minutes');
};

export const calculMealTime = (c: IConfig[], a: moment.Moment, b: moment.Moment, unit: moment.unitOfTime.Base) => {
    const config = getConfigKeys(c, ['workingHours']);

    let total = 0;
    let current = a.clone();
    let to = setTime(a, config.workingHours.end);
    while (current.isBefore(b)) {
        if (isWorkingDate(c, current)) {
            total += mealTimeForTheDay(c, current, to, unit);
            current = setTime(current.add(1, 'day'), config.workingHours.start);
            if (current.isSame(b, 'day')) {
                to = b;
            } else {
                to = setTime(current, config.workingHours.end);
            }
        } else {
            current = setTime(current.add(1, 'day'), config.workingHours.start);
        }
    }

    return total;
};

export const workingTimeInMinutes = (config: IConfig[], begin: moment.Moment, end: moment.Moment): number => {
    checkOrderDates(begin, end);
    let diffMinutes = Math.abs(begin.diff(end, 'minutes'));
    diffMinutes -= notWorkingDays(config, begin, end, 'milliseconds') / MILLISECONDS_IN_SECOND / SECONDS_IN_MINUTE;
    diffMinutes -= calculMealTime(config, begin, end, 'minutes');

    return diffMinutes;
};

export const workingTime = (config: IConfig[], a: moment.Moment, b: moment.Moment, unit: moment.unitOfTime.Base) => {
    checkOrderDates(a, b);
    let diff = Math.abs(a.diff(b, unit));
    diff -= notWorkingDays(config, a, b, unit);
    diff -= calculMealTime(config, a, b, unit);

    return diff;
};

export const getTotalTimeMS = (milestone: Partial<IMilestone>): number => {
    switch (milestone.type) {
        case MilestonesType.MEETING:
        case MilestonesType.MEETING_NO_NOTE:
            return Math.abs(moment(milestone.beginDate).diff(moment(milestone.endDate), 'minutes'));
        default:
            return 0;
    }
};

export const BOARD_ACTIVITY_DELAY_MIN = 10; // 10 minutes before sustain of activities
export const isUnderBoardDelay = (date: moment.Moment): boolean => {
    return (
        moment()
            .utc()
            .diff(date, 'minutes') <= BOARD_ACTIVITY_DELAY_MIN
    );
};

export const BOARD_ACTIVITY_MAX_COMMENT = 40; // first 40 characters
export const getLimitedText = (text: string): string => {
    // return if under max length (with 20% of marge, to avoid cutting for 5 characters)
    if (text.length <= BOARD_ACTIVITY_MAX_COMMENT + Math.round((BOARD_ACTIVITY_MAX_COMMENT * 20) / 100)) {
        return text;
    }

    return `${text.substr(0, BOARD_ACTIVITY_MAX_COMMENT)}...`;
};

export const overEstimatedTime = (config: IConfig[], estimatedTime: string): boolean => {
    const WORKED_MINUTES_IN_DAY = Math.round(getWorkingTimePerDay(config, 'hours') * MINUTES_IN_HOUR);
    const WORKED_MINUTES_IN_WEEK = Math.round(
        getConfigKeys(config, ['workingDays']).workingDays.length * WORKED_MINUTES_IN_DAY
    );

    return stringTimeToMinute(config, estimatedTime) > 53 * WORKED_MINUTES_IN_WEEK;
};
