import * as i0 from "./gantt-line.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./gantt-line.component";
var styles_GanttLineComponent = [i0.styles];
var RenderType_GanttLineComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GanttLineComponent, data: {} });
export { RenderType_GanttLineComponent as RenderType_GanttLineComponent };
export function View_GanttLineComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { arrow: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["arrow", 1]], null, 2, "div", [["class", "arrow-connector"]], [[8, "title", 0]], null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { critical: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_1 = "arrow-connector"; var currVal_2 = _ck(_v, 3, 0, _co.blocker); _ck(_v, 2, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(2, "", _co.sourceId, " -> ", _co.targetId, ""); _ck(_v, 1, 0, currVal_0); }); }
export function View_GanttLineComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-gantt-line", [], null, null, null, View_GanttLineComponent_0, RenderType_GanttLineComponent)), i1.ɵdid(1, 573440, null, 0, i3.GanttLineComponent, [i1.ChangeDetectorRef], null, null)], null, null); }
var GanttLineComponentNgFactory = i1.ɵccf("app-gantt-line", i3.GanttLineComponent, View_GanttLineComponent_Host_0, { sourceId: "sourceId", targetId: "targetId", displayMode: "displayMode", blocker: "blocker", arrowRedraw: "arrowRedraw" }, {}, []);
export { GanttLineComponentNgFactory as GanttLineComponentNgFactory };
