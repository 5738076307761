import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Moment} from 'moment';
import {Observable, of} from 'rxjs';
import {shareReplay} from 'rxjs/operators';
import {API_ERROR_TYPE, ApiRoutePlurality, HTTP_METHOD, ISchemaRoute} from '../../../../../defs/schema-static';
import {SETTING_LANGUAGE_VALUES, USER_FIELD} from '../../../../../defs/schema/public/Users';
import {API_HTTP_ROOT, API_VERSION, getSequelizeErrorReasons, noop, SequelizeErrorType} from '../../app-static';
import {TOAST_TYPE, ToastService} from '../toast/toast.service';

enum API_VERSIONS {
    V1 = 'v1',
    V2 = 'v2',
}

export interface IQueryObject {
    [key: string]: string | number | boolean | string[] | IQueryObject | IQueryObject[];
}

export interface IEntityId {
    id: number;
}

export interface IDeleteCount {
    count: number;
}

export type IEntity<T> = Partial<T> & IEntityId;

interface IModal {
    show: boolean;
    title?: string;
    content?: string;
    callback?(): void;
    callbackLabel?: string;
}

@Injectable({
    providedIn: 'root',
})
export class HttpRestService {
    public modal: IModal = {
        show: false,
    };

    public constructor(
        private readonly http: HttpClient,
        private readonly toastService: ToastService,
        private readonly translate: TranslateService
    ) {}

    public getQuery<T>(route: ISchemaRoute, query: IQueryObject): Observable<T[]> {
        return this._request<T[]>(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, route, HttpRestService.queryString(query));
    }

    public post<T>(route: ISchemaRoute, entity: IEntity<T>): Observable<T> {
        return this._request<T>(
            HTTP_METHOD.POST,
            ApiRoutePlurality.SINGULAR,
            route,
            entity.id.toString(),
            HttpRestService.stripDepth(entity)
        );
    }

    public postEntities<T>(route: ISchemaRoute, entities: IEntity<T>[]): Observable<T[]> {
        return this._request<T[]>(
            HTTP_METHOD.POST,
            ApiRoutePlurality.PLURAL,
            route,
            entities.map((entity) => entity.id).toString(),
            entities.map((entity) => HttpRestService.stripDepth(entity))
        );
    }

    public postBulk<T>(route: ISchemaRoute, values: Partial<T>, filter: IQueryObject): Observable<T> {
        return this._request<T>(
            HTTP_METHOD.POST,
            ApiRoutePlurality.PLURAL,
            route,
            HttpRestService.queryString({q: filter, bulk: true}),
            values
        );
    }

    public put<T>(route: ISchemaRoute, entity: Partial<T>): Observable<T> {
        return this._request<T>(
            HTTP_METHOD.PUT,
            ApiRoutePlurality.SINGULAR,
            route,
            '',
            HttpRestService.stripDepth(entity)
        );
    }

    public putEntities<T>(route: ISchemaRoute, entities: Partial<T>[]): Observable<T[]> {
        return this._request<T[]>(
            HTTP_METHOD.PUT,
            ApiRoutePlurality.PLURAL,
            route,
            '',
            entities.map((entity) => HttpRestService.stripDepth(entity))
        );
    }

    public deleteId(route: ISchemaRoute, id: number): Observable<IDeleteCount> {
        return this._request<IDeleteCount>(HTTP_METHOD.DELETE, ApiRoutePlurality.SINGULAR, route, id.toString());
    }

    public deleteIds(route: ISchemaRoute, ids: number[]): Observable<IDeleteCount> {
        if (!ids || !ids.length) {
            return of({count: 0});
        }

        if (ids.length === 1) {
            return this.deleteId(route, ids[0]);
        }

        return this._request<IDeleteCount>(HTTP_METHOD.DELETE, ApiRoutePlurality.PLURAL, route, ids.toString());
    }

    public deleteQuery(route: ISchemaRoute, query: IQueryObject): Observable<IDeleteCount> {
        return this._request<IDeleteCount>(
            HTTP_METHOD.DELETE,
            ApiRoutePlurality.PLURAL,
            route,
            HttpRestService.queryString(query)
        );
    }

    public _requestString(
        verb: HTTP_METHOD,
        plurality: ApiRoutePlurality,
        route: ISchemaRoute,
        query?: string,
        body?: any
    ): Observable<string> {
        const method = [
            API_HTTP_ROOT,
            API_VERSION,
            route.schema,
            plurality === ApiRoutePlurality.SINGULAR ? route.singularRoute : route.pluralRoute,
            query || '',
        ]
            .filter((str) => !!str)
            .join('/');

        const responseType = 'text';

        let request: Observable<string>;
        switch (verb) {
            case HTTP_METHOD.GET:
                request = this.http.get(method, {responseType});
                break;
            case HTTP_METHOD.POST:
                request = this.http.post(method, body, {responseType});
                break;
            case HTTP_METHOD.PUT:
                request = this.http.put(method, body, {responseType});
                break;
            case HTTP_METHOD.DELETE:
                request = this.http.delete(method, {responseType});
                break;
            default:
                throw new Error(`Unsupported HTTP method: ${verb}`);
        }

        return this.sendRequest(request);
    }

    public _request<T>(
        verb: HTTP_METHOD,
        plurality: ApiRoutePlurality,
        route: ISchemaRoute,
        query?: string,
        body?: any
    ): Observable<T> {
        const method = [
            API_HTTP_ROOT,
            API_VERSION,
            route.schema,
            plurality === ApiRoutePlurality.SINGULAR ? route.singularRoute : route.pluralRoute,
            query || '',
        ]
            .filter((str) => !!str)
            .join('/');

        let request: Observable<T>;
        switch (verb) {
            case HTTP_METHOD.GET:
                request = this.http.get<T>(method);
                break;
            case HTTP_METHOD.POST:
                request = this.http.post<T>(method, body);
                break;
            case HTTP_METHOD.PUT:
                request = this.http.put<T>(method, body);
                break;
            case HTTP_METHOD.DELETE:
                request = this.http.delete<T>(method);
                break;
            default:
                throw new Error(`Unsupported HTTP method: ${verb}`);
        }

        return this.sendRequest(request);
    }

    private sendRequest<T>(request: Observable<T>): Observable<T> {
        const replay = request.pipe(shareReplay());
        void (async () => replay.subscribe(noop, this.errorHandler.bind(this)))();

        return replay;
    }

    public static queryString(query: IQueryObject): string {
        return `?${Object.keys(query)
            .map((key) => {
                if (['q', 'where'].includes(key)) {
                    return `${key}=${JSON.stringify(query[key])}`;
                }

                return `${key}=${query[key].toString()}`;
            })
            .join('&')}`;
    }

    // remove depth from the given object, add properties in ALLOWED_OBJECT_FIELD to keep them
    public static stripDepth<T extends {[key: string]: any}>(obj: T, depth = 1): T {
        return (Object.keys(obj) as (keyof T)[]).reduce(
            (_strippedObj: T, key) => {
                if (!((_strippedObj[key] as any) instanceof Object)) {
                    return _strippedObj;
                }

                if (_strippedObj[key] && _strippedObj[key].hasOwnProperty('_isAMomentObject')) {
                    _strippedObj[key] = (_strippedObj[key] as Moment).format() as T[keyof T];

                    return _strippedObj;
                }

                if (HttpRestService.ALLOWED_OBJECT_FIELD.includes(key as string)) {
                    return _strippedObj;
                }

                if (depth - 1 <= 0) {
                    delete _strippedObj[key];
                } else {
                    // @ts-ignore
                    _strippedObj[key] = HttpRestService.stripDepth(_strippedObj[key] as object, depth - 1);
                }

                return _strippedObj;
            },
            {...(obj as object)} as T
        ) as T;
    }

    private async errorHandler<T>({error}: HttpErrorResponse) {
        if (!error) {
            return;
        }

        if (!this.translate.currentLang) {
            const browserLang = this.translate.getBrowserLang().toUpperCase() as SETTING_LANGUAGE_VALUES;
            const lang = Object.values(SETTING_LANGUAGE_VALUES).includes(browserLang)
                ? browserLang
                : SETTING_LANGUAGE_VALUES.EN;

            this.translate.use(lang);

            await new Promise((resolve, reject) => this.translate.onLangChange.subscribe(resolve, reject));
        }

        const translations: {[key: string]: string} = await this.translate.get(['generic_error']).toPromise();

        if (error.err) {
            switch (error.err) {
                case 'exception_version':
                    this.modal = {
                        show: true,
                        title: 'generic_error',
                        content: `${error.err}_${error.reason}`,
                        callback: () => {
                            // tslint:disable-next-line: deprecation
                            window.location.reload(true);
                            this.modal.show = false;
                        },
                        callbackLabel: 'reload',
                    };
                    break;
                default:
                    Object.assign(translations, await this.translate.get([error.err, error.reason || '']).toPromise());
                    if (translations[error.reason] === error.reason) {
                        error.reason = 'generic_error';
                    }
                    this.toastService.show({
                        type: TOAST_TYPE.ERROR,
                        text: translations[error.err],
                        callbackText: 'more_info',
                        callback: () => {
                            this.modal = {
                                show: true,
                                title: error.err,
                                content: translations[error.reason],
                                callback: undefined,
                            };
                        },
                    });
                    break;
            }
        } else {
            this.toastService.show({
                type: TOAST_TYPE.ERROR,
                text: translations.generic_error,
            });
        }
    }

    // FIXME really kinda fugly
    private static readonly ALLOWED_OBJECT_FIELD: string[] = [USER_FIELD.settings, 'where', 'position', 'size'];
}
