import moment from 'moment';
import {BudgetStatus, ISchemaRoute} from '../../schema-static';
import {IMilestone} from './Milestones';
import {IProject} from './Projects';

export enum BUDGET_FIELD {
    id = 'id',
    createdAt = 'createdAt',
    updatedAt = 'updatedAt',
    description = 'description',
    price = 'price',
    projectId = 'projectId',
    status = 'status',
    releaseId = 'releaseId',
    project = 'project',
    release = 'release',
}

export interface IBudgetBare {
    id?: number;
    createdAt?: moment.Moment;
    updatedAt?: moment.Moment;
    description?: string;
    price: number;
    projectId: number;
    status: BudgetStatus;
    releaseId?: number;
}

export interface IBudgetBelong {
    project: Partial<IProject>;
    release?: Partial<IMilestone>;
}

export interface IBudget extends IBudgetBare, IBudgetBelong {}

const BUDGET_SCHEMA_ROUTE: ISchemaRoute = {
    tableName: 'budget',
    singularRoute: 'budget',
    pluralRoute: 'budgets',
};

export {BUDGET_SCHEMA_ROUTE};
