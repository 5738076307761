var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { EventEmitter } from '@angular/core';
import { Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, FormGroup } from 'ngx-strongly-typed-forms';
import { forkJoin } from 'rxjs';
import { getWorkingHours } from '../../../../../defs/businessRules';
import { AssignedEmployeeType, DECIMAL_RADIX, TaskStatusType, TaskType } from '../../../../../defs/schema-static';
import { ASSIGNED_TASK_SCHEMA_ROUTE } from '../../../../../defs/schema/public/AssignedTasks';
import { TAG_SCHEMA_ROUTE } from '../../../../../defs/schema/public/Tags';
import { TASK_BLOCKER_SCHEMA_ROUTE } from '../../../../../defs/schema/public/TaskBlockers';
import { TASK_COMMENT_SCHEMA_ROUTE } from '../../../../../defs/schema/public/TaskComments';
import { TASK_SCHEMA_ROUTE } from '../../../../../defs/schema/public/Tasks';
import { TASK_TAG_SCHEMA_ROUTE } from '../../../../../defs/schema/public/TaskTags';
import { noop } from '../../app-static';
import { AuthService } from '../../auth/auth.service';
import { ConfigService } from '../../shared/config/config.service';
import { HttpRestService } from '../../shared/http-rest/http-rest.service';
import { MomentService } from '../../shared/moment/moment.service';
import { TOAST_TYPE, ToastService } from '../../shared/toast/toast.service';
import { validate } from '../validators/form.validator';
import { requiredTrimValidator } from '../validators/required-trim.validator';
import * as i0 from "@angular/core";
import * as i1 from "../../shared/http-rest/http-rest.service";
import * as i2 from "../../shared/toast/toast.service";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../../auth/auth.service";
import * as i5 from "../../shared/config/config.service";
import * as i6 from "../../shared/moment/moment.service";
export var AddTaskFormField;
(function (AddTaskFormField) {
    AddTaskFormField["tracked"] = "tracked";
    AddTaskFormField["startDate"] = "startDate";
    AddTaskFormField["endDate"] = "endDate";
    AddTaskFormField["parentTaskId"] = "parentTaskId";
    AddTaskFormField["releaseId"] = "releaseId";
    AddTaskFormField["project"] = "project";
    AddTaskFormField["parentTask"] = "parentTask";
    AddTaskFormField["release"] = "release";
    AddTaskFormField["recursive"] = "recursive";
    AddTaskFormField["name"] = "name";
    AddTaskFormField["comment"] = "comment";
    AddTaskFormField["bug"] = "bug";
    AddTaskFormField["time"] = "time";
    AddTaskFormField["urgent"] = "urgent";
    AddTaskFormField["assigned"] = "assigned";
})(AddTaskFormField || (AddTaskFormField = {}));
export var EditTaskFormField;
(function (EditTaskFormField) {
    EditTaskFormField["tracked"] = "tracked";
    EditTaskFormField["startDate"] = "startDate";
    EditTaskFormField["endDate"] = "endDate";
    EditTaskFormField["parentTaskId"] = "parentTaskId";
    EditTaskFormField["releaseId"] = "releaseId";
    EditTaskFormField["project"] = "project";
    EditTaskFormField["parentTask"] = "parentTask";
    EditTaskFormField["release"] = "release";
    EditTaskFormField["recursive"] = "recursive";
    EditTaskFormField["name"] = "name";
    EditTaskFormField["comment"] = "comment";
    EditTaskFormField["blockers"] = "blockers";
    EditTaskFormField["bug"] = "bug";
    EditTaskFormField["estimated"] = "estimated";
    EditTaskFormField["progress"] = "progress";
    EditTaskFormField["urgent"] = "urgent";
    EditTaskFormField["assigned"] = "assigned";
})(EditTaskFormField || (EditTaskFormField = {}));
var FormsAddTaskService = (function () {
    function FormsAddTaskService(httpRest, toastService, translate, authService, configService, momentService) {
        this.httpRest = httpRest;
        this.toastService = toastService;
        this.translate = translate;
        this.authService = authService;
        this.configService = configService;
        this.momentService = momentService;
        this.onSubmit = new EventEmitter();
    }
    FormsAddTaskService.getFormGroup = function () {
        return new FormGroup({
            project: new FormControl(null, Validators.required),
            parentTask: new FormControl(),
            parentTaskId: new FormControl({ value: null, disabled: false }),
            release: new FormControl(),
            releaseId: new FormControl({ value: null, disabled: false }),
            name: new FormControl(null, requiredTrimValidator()),
            bug: new FormControl(),
            tracked: new FormControl(),
            time: new FormControl(),
            startDate: new FormControl(null, Validators.required),
            endDate: new FormControl(null, Validators.required),
            recursive: new FormControl(),
            blockers: new FormControl(),
            tags: new FormControl(),
            urgent: new FormControl(),
            comment: new FormControl(),
            assigned: new FormControl(),
        });
    };
    FormsAddTaskService.getEditFormGroup = function () {
        return new FormGroup({
            parentTask: new FormControl(),
            parentTaskId: new FormControl({ value: null, disabled: false }),
            release: new FormControl(),
            releaseId: new FormControl({ value: null, disabled: false }),
            progress: new FormControl(),
            name: new FormControl(null, requiredTrimValidator()),
            bug: new FormControl(),
            tracked: new FormControl(),
            estimated: new FormControl(),
            startDate: new FormControl(null, Validators.required),
            endDate: new FormControl(null, Validators.required),
            project: new FormControl(null, Validators.required),
            blockers: new FormControl(),
            tags: new FormControl(),
            urgent: new FormControl(),
        });
    };
    FormsAddTaskService.prototype.submit = function (f, callback) {
        if (callback === void 0) { callback = noop; }
        return __awaiter(this, void 0, void 0, function () {
            var assigned, employeeId, _a, recursive, blockers, time, tracked, startDate, endDate, urgent, bug, release, parentTask, project, releaseId, parentTaskId, tags, name, comment, status, _task, workingHours, startTime, endTime, _putTask, _putBlockers, newTags, _putTags, _putComment, _done, t, observables_1, el;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        assigned = f.value.assigned.slice();
                        if (this.selectedTags && this.selectedTags.length > 0) {
                            f.patchValue({
                                tags: this.selectedTags,
                            });
                        }
                        if (!validate(f)) {
                            return [2, false];
                        }
                        employeeId = this.authService.user.employee.id;
                        _a = f.value, recursive = _a.recursive, blockers = _a.blockers, time = _a.time, tracked = _a.tracked, startDate = _a.startDate, endDate = _a.endDate, urgent = _a.urgent, bug = _a.bug, release = _a.release, parentTask = _a.parentTask, project = _a.project, releaseId = _a.releaseId, parentTaskId = _a.parentTaskId;
                        tags = f.value.tags;
                        name = f.value.name.trim();
                        comment = f.value.comment;
                        if (comment) {
                            comment = comment.trim();
                        }
                        if (!name) {
                            this.toastService.show({ text: 'error_noname_tasks' });
                            return [2, false];
                        }
                        status = tracked
                            ? assigned.length > 0
                                ? TaskStatusType.TODO
                                : TaskStatusType.BACKLOG
                            : TaskStatusType.DONE;
                        _task = {
                            beginDate: startDate ? this.momentService.moment(startDate) : undefined,
                            endDate: endDate ? this.momentService.moment(endDate) : undefined,
                            name: name,
                            type: TaskType[bug ? TaskType.BUG : TaskType.TASK],
                            urgent: urgent,
                            tracked: tracked,
                            status: status,
                            progress: tracked ? 0 : 100,
                            estimatedTime: time,
                            projectId: project,
                            parentId: parentTaskId || null,
                            targetReleaseId: releaseId || null,
                            authorId: employeeId,
                        };
                        if (_task.beginDate && _task.endDate && _task.endDate.isBefore(_task.beginDate)) {
                            f.controls.endDate.setErrors({ endDateBeforeBeginDate: '' });
                            this.toastService.show({ text: 'error_insert_tasks' });
                            return [2, false];
                        }
                        workingHours = getWorkingHours(this.configService.config);
                        startTime = workingHours.start.split(':').map(function (e) { return parseInt(e, DECIMAL_RADIX); });
                        endTime = workingHours.end.split(':').map(function (e) { return parseInt(e, DECIMAL_RADIX); });
                        if (_task.beginDate && _task.endDate && !_task.targetReleaseId) {
                            if (_task.beginDate.isSame(_task.endDate)) {
                                _task.beginDate.set({
                                    hour: startTime[0],
                                    minute: startTime[1],
                                });
                                _task.endDate.set({ hour: endTime[0], minute: endTime[1] });
                            }
                            else {
                                _task.beginDate.set({
                                    hour: startTime[0],
                                    minute: startTime[1],
                                });
                                _task.endDate.set({ hour: startTime[0], minute: startTime[1] });
                            }
                        }
                        _putTask = function () { return _this.httpRest.put(TASK_SCHEMA_ROUTE, _task); };
                        _putBlockers = function (task) {
                            return blockers.map(function (blocker) {
                                return _this.httpRest.put(TASK_BLOCKER_SCHEMA_ROUTE, {
                                    taskId: task.id,
                                    blockerId: blocker,
                                });
                            });
                        };
                        if (!this.pendingTags.length) return [3, 2];
                        return [4, this.httpRest
                                .putEntities(TAG_SCHEMA_ROUTE, this.pendingTags.map(function (p) {
                                if (project) {
                                    p.projectId = project;
                                }
                                delete p.selected;
                                return p;
                            }))
                                .toPromise()];
                    case 1:
                        newTags = _b.sent();
                        newTags.map(function (_tag) {
                            if (!tags) {
                                tags = [];
                            }
                            tags.push(_tag.id);
                        });
                        _b.label = 2;
                    case 2:
                        _putTags = function (task) {
                            return _this.httpRest.putEntities(TASK_TAG_SCHEMA_ROUTE, tags
                                .filter(function (_t) { return !!_t; })
                                .map(function (tag) { return ({
                                taskId: task.id,
                                tagId: tag,
                            }); }));
                        };
                        _putComment = function (task) {
                            return _this.httpRest.put(TASK_COMMENT_SCHEMA_ROUTE, {
                                comment: comment,
                                employeeId: employeeId,
                                taskId: task.id,
                            });
                        };
                        _done = function (task) { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                this.toastService.show({
                                    type: TOAST_TYPE.SUCCESS,
                                    text: 'success_insert_task',
                                });
                                this.pendingTags = [];
                                callback(task);
                                this.onSubmit.emit(task);
                                return [2];
                            });
                        }); };
                        return [4, _putTask().toPromise()];
                    case 3:
                        t = _b.sent();
                        {
                            observables_1 = [];
                            if (blockers && blockers.length) {
                                observables_1.push.apply(observables_1, _putBlockers(t));
                            }
                            if (tags && tags.length) {
                                observables_1.push(_putTags(t));
                            }
                            if (comment) {
                                observables_1.push(_putComment(t));
                            }
                            if (assigned.length > 0) {
                                assigned.map(function (emp) {
                                    observables_1.push(_this.httpRest.put(ASSIGNED_TASK_SCHEMA_ROUTE, {
                                        employeeId: emp.id,
                                        assignerId: _this.authService.user.employee.id,
                                        taskId: t.id,
                                        type: AssignedEmployeeType.DEVELOPER,
                                    }));
                                });
                            }
                            if (!observables_1.length) {
                                _done(t);
                            }
                            forkJoin(observables_1).subscribe(function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                                return [2, _done(t)];
                            }); }); });
                        }
                        if (recursive) {
                            f.controls.name.reset();
                            f.controls.comment.reset();
                            f.controls.time.reset();
                            el = document.getElementById('name');
                            if (el) {
                                el.focus();
                            }
                        }
                        else {
                            f.reset();
                        }
                        return [2, !recursive];
                }
            });
        });
    };
    FormsAddTaskService.ngInjectableDef = i0.defineInjectable({ factory: function FormsAddTaskService_Factory() { return new FormsAddTaskService(i0.inject(i1.HttpRestService), i0.inject(i2.ToastService), i0.inject(i3.TranslateService), i0.inject(i4.AuthService), i0.inject(i5.ConfigService), i0.inject(i6.MomentService)); }, token: FormsAddTaskService, providedIn: "root" });
    return FormsAddTaskService;
}());
export { FormsAddTaskService };
