<clr-wizard
    [clrWizardOpen]="clrWizardOpen"
    (clrWizardOpenChange)="clrWizardOpenChange.emit((clrWizardOpen = $event))"
    (clrWizardOnCancel)="triggerCancel()"
>
    <clr-wizard-title translate>add_employee</clr-wizard-title>

    <clr-wizard-button [clrWizardButtonDisabled]="submitting" [type]="'cancel'">{{
        'cancel' | translate
    }}</clr-wizard-button>
    <clr-wizard-button [clrWizardButtonDisabled]="submitting" [type]="'previous'">{{
        'back' | translate
    }}</clr-wizard-button>
    <clr-wizard-button [clrWizardButtonDisabled]="submitting" [type]="'next'">{{
        'next' | translate
    }}</clr-wizard-button>
    <clr-wizard-button [clrWizardButtonDisabled]="submitting" [type]="'finish'">{{
        'create_employee' | translate
    }}</clr-wizard-button>

    <clr-wizard-page [clrWizardPageNextDisabled]="!employeeComponent.form.valid">
        <ng-template clrPageTitle>{{ 'employee' | translate }}</ng-template>
        <app-employee-wizard-employee [edit]="edit"></app-employee-wizard-employee>
    </clr-wizard-page>

    <clr-wizard-page>
        <ng-template clrPageTitle>{{ 'projects' | translate }}</ng-template>
        <app-employee-wizard-projects [projects]="projects"></app-employee-wizard-projects>
    </clr-wizard-page>

    <clr-wizard-page
        clrWizardPagePreventDefault="true"
        (clrWizardPageOnCancel)="triggerCancel()"
        (clrWizardPagePrevious)="wizard.previous()"
        (clrWizardPageOnCommit)="triggerFinish()"
    >
        <ng-template clrPageTitle>{{ 'access' | translate }}</ng-template>
        <app-employee-wizard-group-rights [groups]="groups"></app-employee-wizard-group-rights>
    </clr-wizard-page>
</clr-wizard>
