<form clrForm [formGroup]="form" [clrLayout]="formLayout">
    <clr-input-container>
        <label translate>placeholder_name</label>
        <input
            clrInput
            [formControlName]="EMPLOYEE_FORM_KEYS.name"
            type="text"
            #nameElement
            maxlength="{{ MAX_LENGTH_DEFAULT }}"
        />

        <clr-control-error *clrIfError="'requiredTrim'" translate>error_field_empty</clr-control-error>
        <clr-control-error *clrIfError="'maxLength'" translate>error_field_text_overflow</clr-control-error>
    </clr-input-container>

    <clr-input-container>
        <label translate>placeholder_code</label>
        <input
            clrInput
            [formControlName]="EMPLOYEE_FORM_KEYS.code"
            type="text"
            maxlength="{{ MAX_LENGTH_EMP_CODE }}"
            appUppercaseInput
        />
        <clr-control-helper>{{ MAX_LENGTH_EMP_CODE }} {{ 'max_length_code' | translate }}</clr-control-helper>

        <clr-control-error *clrIfError="'requiredTrim'" translate>error_field_empty</clr-control-error>
        <clr-control-error *clrIfError="'maxLength'" translate>error_field_text_overflow</clr-control-error>
        <clr-control-error *clrIfError="'pattern'" translate>error_field_pattern_capital_numeric</clr-control-error>
    </clr-input-container>

    <clr-input-container>
        <label translate>badge_color</label>
        <input clrInput [formControlName]="EMPLOYEE_FORM_KEYS.color" type="color" />

        <clr-control-error translate>error_field_empty</clr-control-error>
    </clr-input-container>

    <clr-input-container>
        <label translate>table_price_day</label>
        <input clrInput [formControlName]="EMPLOYEE_FORM_KEYS.dayPrice" type="number" />

        <clr-control-error *clrIfError="'required'" translate>error_field_empty</clr-control-error>
        <clr-control-error *clrIfError="'min'" translate>error_field_negative</clr-control-error>
        <clr-control-error *clrIfError="'max'" translate>error_field_number_overflow</clr-control-error>
    </clr-input-container>

    <clr-input-container>
        <label translate>placeholder_email</label>
        <input
            clrInput
            [formControlName]="EMPLOYEE_FORM_KEYS.email"
            type="email"
            maxlength="{{ MAX_LENGTH_DEFAULT }}"
        />

        <clr-control-error *clrIfError="'requiredTrim'" translate>error_field_empty</clr-control-error>
        <clr-control-error *clrIfError="'maxLength'" translate>error_field_text_overflow</clr-control-error>
        <clr-control-error *clrIfError="'email'" translate>error_field_email</clr-control-error>
    </clr-input-container>

    <clr-input-container *ngIf="!edit">
        <label translate>placeholder_cognito</label>
        <input
            clrInput
            [formControlName]="EMPLOYEE_FORM_KEYS.cognitoUsername"
            type="text"
            maxlength="{{ MAX_LENGTH_COGNITO_USERNAME }}"
            appLowercaseInput
        />

        <clr-control-error *clrIfError="'requiredTrim'" translate>error_field_empty</clr-control-error>
        <clr-control-error *clrIfError="'maxLength'" translate>error_field_text_overflow</clr-control-error>
        <clr-control-error *clrIfError="'pattern'" translate>error_field_pattern_lower_numeric</clr-control-error>
    </clr-input-container>

    <clr-input-container>
        <label translate>label_discord_snowflake</label>
        <input
            clrInput
            [formControlName]="EMPLOYEE_FORM_KEYS.discordSnowflake"
            type="text"
            maxlength="{{ MAX_LENGTH_DISCORD_SNOWFLAKE }}"
        />
        <clr-control-helper translate>helper_field_optional</clr-control-helper>

        <clr-control-error *clrIfError="'maxLength'" translate>error_field_text_overflow</clr-control-error>
        <clr-control-error *clrIfError="'pattern'" translate>error_field_pattern_numeric</clr-control-error>
    </clr-input-container>

    <clr-input-container>
        <label translate>placeholder_phonenumber</label>
        <input
            clrInput
            [formControlName]="EMPLOYEE_FORM_KEYS.phoneNumber"
            type="tel"
            placeholder="+33 4 12 34 56 78"
            maxlength="{{ MAX_LENGTH_DEFAULT }}"
        />
        <clr-control-helper translate>helper_field_optional</clr-control-helper>

        <clr-control-error *clrIfError="'maxLength'" translate>error_field_text_overflow</clr-control-error>
    </clr-input-container>

    <clr-input-container>
        <label translate>placeholder_mobilenumber</label>
        <input
            clrInput
            [formControlName]="EMPLOYEE_FORM_KEYS.mobileNumber"
            type="tel"
            placeholder="+33 6 12 34 56 78"
            maxlength="{{ MAX_LENGTH_DEFAULT }}"
        />
        <clr-control-helper translate>helper_field_optional</clr-control-helper>
        <clr-control-error *clrIfError="'maxLength'" translate>error_field_text_overflow</clr-control-error>
    </clr-input-container>
</form>
