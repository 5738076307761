var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { ApiRoutePlurality, HTTP_METHOD, ProjectMemberType } from '../../../../../defs/schema-static';
import { CLIENT_FIELD, CLIENT_SCHEMA_ROUTE } from '../../../../../defs/schema/public/Clients';
import { PROJECT_MEMBER_SCHEMA_ROUTE } from '../../../../../defs/schema/public/ProjectMembers';
import { PROJECT_FIELD, PROJECT_SCHEMA_ROUTE } from '../../../../../defs/schema/public/Projects';
import { USER_FIELD } from '../../../../../defs/schema/public/Users';
import { PROJECT_LIST_LAYOUT, ProjectsListComponent } from '../../projects/list/projects-list.component';
import { HttpRestService } from '../../shared/http-rest/http-rest.service';
var EmployeeWizardProjectsComponent = (function () {
    function EmployeeWizardProjectsComponent(httpRest) {
        this.httpRest = httpRest;
        this.columns = ProjectsListComponent.DEFAULT_PROJECTS_COLUMNS.map(function (column) { return (__assign({}, column)); });
        this.selectedProjects = [];
        this.PROJECT_LIST_LAYOUT = PROJECT_LIST_LAYOUT;
        this.columns
            .filter(function (_a) {
            var field = _a.field;
            return !EmployeeWizardProjectsComponent.COLUMNS_FIELDS.includes(field);
        })
            .map(function (column) { return (column.hidden = true); });
    }
    EmployeeWizardProjectsComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (!this.projects) {
            (function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                return [2, this.getProjects()];
            }); }); })();
        }
    };
    EmployeeWizardProjectsComponent.prototype.getProjects = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, clients;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4, this.httpRest
                                ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, PROJECT_SCHEMA_ROUTE, 'light_manager')
                                .toPromise()];
                    case 1:
                        _a.projects = (_b.sent()).sort(function (p1, p2) { return p1.code.localeCompare(p2.code); });
                        return [4, this.httpRest
                                ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, CLIENT_SCHEMA_ROUTE, 'light')
                                .toPromise()];
                    case 2:
                        clients = _b.sent();
                        this.projects.map(function (project) { return (project.client = clients.find(function (_a) {
                            var id = _a.id;
                            return id === project.clientId;
                        })); });
                        this.projects = this.projects.slice();
                        return [2];
                }
            });
        });
    };
    EmployeeWizardProjectsComponent.prototype.submit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                if (!this.employee || !this.employee.id) {
                    throw new Error("missing employee id, can't submit ");
                }
                if (!this.selectedProjects || !this.selectedProjects.length) {
                    return [2, undefined];
                }
                return [2, this.httpRest
                        ._request(HTTP_METHOD.PUT, ApiRoutePlurality.PLURAL, PROJECT_MEMBER_SCHEMA_ROUTE, '', (this.selectedProjects || []).map(function (project) { return ({
                        projectId: project.id,
                        type: ProjectMemberType.MEMBER,
                        employeeId: _this.employee.id,
                    }); }))
                        .toPromise()];
            });
        });
    };
    EmployeeWizardProjectsComponent.prototype.reset = function () {
        this.selectedProjects = [];
    };
    EmployeeWizardProjectsComponent.COLUMNS_FIELDS = [
        PROJECT_FIELD.obs,
        PROJECT_FIELD.client + "." + CLIENT_FIELD.user + "." + USER_FIELD.name,
        'state',
    ];
    return EmployeeWizardProjectsComponent;
}());
export { EmployeeWizardProjectsComponent };
