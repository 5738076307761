import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormGroup} from 'ngx-strongly-typed-forms';
import {RIGHTS} from '../../../../../defs/schema-static';
import {ILoadableComponent} from '../../app-static';
import {AuthService} from '../../auth/auth.service';
import {FormsAddMilestoneService} from '../../forms/add-milestone.service';
import {FormsAddTaskComponent} from '../../forms/add-task/add-task.component';
import {FormsAddTaskService, IAddTaskFormModal} from '../../forms/add-task/add-task.service';
import {FormsAddTimeService} from '../../forms/add-time.service';
import {validate} from '../../forms/validators/form.validator';
import {GlobalSearchModalComponent} from '../../global-search-modal/global-search-modal.component';
import {
    ComponentContext,
    SHORTCUT_COMBO,
    SHORTCUT_CREATE,
    SHORTCUT_MISC,
    SHORTCUT_NAME,
    SHORTCUT_QUICK_ACTION,
    ShortcutHandlerService,
} from '../shortcut-handler/shortcut-handler.service';

enum IActionName {
    TASK = 'task',
    MILESTONE = 'milestone',
    TIME = 'time',
    SEARCH = 'search',
    COMMAND = 'command',
}

interface IAction {
    label: string;
    icon: string;
    class?: string;
    shortcut?: SHORTCUT_NAME;
    quickShortcut?: SHORTCUT_NAME;
    requiredRight?: RIGHTS;
    globalShortcut?: boolean;
    hide?(action: IAction): boolean;
    showModal: boolean;
    component?: ComponentContext & ILoadableComponent;
    params?: any;
}

@Component({
    selector: 'app-quick-action',
    templateUrl: './quick-action.component.html',
    styleUrls: ['./quick-action.component.scss'],
})
export class QuickActionComponent implements OnInit {
    @Input() public isCollapsed = true;

    @ViewChild(FormsAddTaskComponent)
    public addTaskComponent: FormsAddTaskComponent;

    @ViewChild(GlobalSearchModalComponent)
    public globalSearchComponent: GlobalSearchModalComponent;

    public actions: {[name in IActionName]: IAction} = {
        [IActionName.TASK]: {
            label: 'add_task',
            shortcut: SHORTCUT_CREATE.TASK,
            quickShortcut: SHORTCUT_QUICK_ACTION.CREATE_TASK,
            icon: 'tasks',
            requiredRight: RIGHTS.TASK_CREATE,
            showModal: false,
            class: 'quick-action-task',

            component: this.addTaskComponent,
        },
        [IActionName.MILESTONE]: {
            label: 'add_milestone',
            shortcut: SHORTCUT_CREATE.MILESTONE,
            quickShortcut: SHORTCUT_QUICK_ACTION.CREATE_MILESTONE,
            icon: 'bookmark',
            showModal: false,
            class: 'quick-action-milestone',
        },
        [IActionName.TIME]: {
            label: 'add_time',
            shortcut: SHORTCUT_CREATE.TIME,
            quickShortcut: SHORTCUT_QUICK_ACTION.CREATE_TIME,
            icon: 'clock',
            showModal: false,
            class: 'quick-action-time',
        },
        [IActionName.SEARCH]: {
            label: 'global_search',
            shortcut: SHORTCUT_MISC.GLOBAL_SEARCH,
            globalShortcut: true,
            icon: 'search',
            showModal: false,
            class: 'quick-action-search',
            component: this.globalSearchComponent,
        },
        [IActionName.COMMAND]: {
            label: 'Run a command',
            icon: 'terminal',
            showModal: false,
            hide: (action: IAction) => !this.process || !this.process.spawn,
        },
    };

    private readonly process: any;
    public showMultiple = false;
    public prompt = false;
    public command = '';

    public showMenuModal = false;

    public constructor(
        private readonly formsAddTaskService: FormsAddTaskService,
        private readonly formsAddMilestoneService: FormsAddMilestoneService,
        private readonly formsAddTimeService: FormsAddTimeService,
        private readonly shortcutHandlerService: ShortcutHandlerService,
        public authService: AuthService
    ) {
        if (!(window as any).require) {
            try {
                this.process = (window as any).require('child_process');
            } catch (e) {}
        }
    }

    public ngOnInit() {
        this.shortcutHandlerService.register({
            name: SHORTCUT_MISC.QUICK_ACTION,
            shortcut: SHORTCUT_MISC.QUICK_ACTION,
            callback: () => {
                this.showMenuModal = true;
            },
            context: this,
        });

        (Object.values(this.actions) as IAction[])
            .filter((action: IAction) => action.shortcut)
            .map((action: IAction) => {
                this.shortcutHandlerService.register({
                    name: action.shortcut,
                    shortcut: action.shortcut,
                    callback: (params?) => {
                        action.params = params;
                        this.showAction(action);
                    },
                });
            });

        this.actions[IActionName.TASK].component = this.addTaskComponent;
    }

    public registerShortcuts() {
        (Object.values(this.actions) as IAction[])
            .filter((action) => action.quickShortcut)
            .map((action) => {
                this.shortcutHandlerService.register({
                    shortcut: action.quickShortcut,
                    callback: (params?) => {
                        this.showAction(action);
                    },
                    context: this,
                });
            });
    }

    public unregisterShortcuts() {
        this.shortcutHandlerService.unregister(
            this,
            ...Object.values(this.actions)
                .filter((action) => action.quickShortcut)
                .map((action) => action.quickShortcut)
        );
    }

    public openModal(name: IActionName) {
        this.unregisterShortcuts();
    }

    public resetParams(name: IActionName) {
        delete this.actions[name].params;
    }

    public closeModal(name: IActionName) {
        this.actions[name].showModal = false;
        this.showMenuModal = false;
    }

    public showAction(action: IAction) {
        action.showModal = true;
        if (this.showMenuModal) {
            this.showMenuModal = false;
        }
    }

    public async addTask() {
        if (await this.formsAddTaskService.submit(this.addTaskComponent.form)) {
            this.addTaskComponent.alreadyInit = false;
            this.resetParams(IActionName.TASK);
            this.closeModal(IActionName.TASK);
        }
    }

    public async addNewMilestone(milestone: any) {
        if (await this.formsAddMilestoneService.submit(milestone)) {
            this.closeModal(IActionName.MILESTONE);
        }
    }

    public cancelMilestoneCreation() {
        this.formsAddMilestoneService.abort();
        this.resetParams(IActionName.MILESTONE);
    }

    public async addTime(item: any) {
        if (await this.formsAddTimeService.submit(item.time, item.task)) {
            this.closeModal(IActionName.TIME);
            this.resetParams(IActionName.TIME);
        }
    }

    public loadAddTask() {
        requestAnimationFrame(() => {
            this.addTaskComponent.load(this.actions[IActionName.TASK].params);
        });
    }

    public run(command: string) {
        const cmd = this.process.spawn(command);

        // tslint:disable-next-line:no-console
        cmd.stdout.on('data', (data: Buffer | string) => console.log(command, data.toString()));
        // tslint:disable-next-line:no-console
        cmd.stderr.on('data', (data: Buffer | string) => console.error(command, data.toString()));

        cmd.on('close', () => (this.actions[IActionName.COMMAND].showModal = false));
    }

    public readonly IActionName = IActionName;
    public readonly objectValues = Object.values;
    public readonly SHORTCUT_COMOBO = SHORTCUT_COMBO;
    public readonly validate = validate;
    public readonly RIGHTS = RIGHTS;
}
