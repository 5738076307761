<div id="gantt-container" class="clr-row flex-1">
    <div id="gantt-left-panel" class="column clr-col-3" [ngClass]="{overflowYAuto: isFirefox()}">
        <table>
            <thead>
                <tr>
                    <th style="width: 55%" translate>project</th>
                    <th style="width: 20%" translate>duration</th>
                    <th style="width: 25%; text-align: left" translate>task_progress</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let project of projectsFiltered()">
                    <tr>
                        <td
                            style="width: 55%"
                            class="text-ellipsis c-hand"
                            [title]="project.project.obs"
                            (click)="project.displayMore = !project.displayMore; cdRef.markForCheck()"
                        >
                            <ng-container *ngIf="project.ganttMS.length > 0">
                                <clr-icon
                                    [ngStyle]="{color: project.project.color}"
                                    shape="plus-circle"
                                    class="is-solid"
                                    *ngIf="!project.displayMore"
                                ></clr-icon>
                                <clr-icon
                                    [ngStyle]="{color: project.project.color}"
                                    shape="minus-circle"
                                    class="is-solid"
                                    *ngIf="project.displayMore"
                                ></clr-icon>
                            </ng-container>
                            {{ project.project.obs }}
                        </td>
                        <td style="width: 20%; text-align: center">
                            {{ project.endDate.diff(project.beginDate, 'day') || '&nbsp;' }}
                            <ng-container *ngIf="project.endDate.diff(project.beginDate, 'day')">
                                {{
                                    project.endDate.diff(project.beginDate, 'day') === 1
                                        ? ('date_day' | translate)
                                        : ('date_days' | translate)
                                }}
                            </ng-container>
                        </td>
                        <td style="width: 25%; top: -5px; position: relative; " class="padd text-ellipsis">
                            <app-progress-circle
                                [progressPercent]="project.progress"
                                [part]="project.taskDone"
                                [total]="project?.project?.tasks?.length"
                            ></app-progress-circle>
                        </td>
                    </tr>
                    <ng-container *ngIf="project.displayMore">
                        <ng-container *ngFor="let ganttMs of getReleases(project.ganttMS)">
                            <tr *ngIf="ganttMs.tasks.length > 0">
                                <td
                                    class="text-ellipsis c-hand"
                                    style=" padding-left:10px;"
                                    (click)="ganttMs.displayMore = !ganttMs.displayMore; cdRef.markForCheck()"
                                >
                                    <ng-container *ngIf="ganttMs.tasks.length > 0">
                                        <clr-icon
                                            [ngStyle]="{color: project.project.color}"
                                            shape="angle right"
                                            class="is-solid"
                                            *ngIf="!ganttMs.displayMore"
                                        ></clr-icon>
                                        <clr-icon
                                            [ngStyle]="{color: project.project.color}"
                                            shape="angle down"
                                            class="is-solid"
                                            *ngIf="ganttMs.displayMore"
                                        ></clr-icon>
                                    </ng-container>
                                    {{ ganttMs.milestone.obs }}
                                </td>
                                <td class="text-ellipsis" style="text-align: center">
                                    {{ getDuration(ganttMs.milestone.target, ganttMs.milestone.beginDate) }}
                                </td>
                                <td class="text-ellipsis">
                                    <app-progress-circle
                                        [progressPercent]="ganttMs.progress"
                                        [part]="ganttMs.taskDone"
                                        [total]="ganttMs.tasks.length"
                                    ></app-progress-circle>
                                </td>
                            </tr>
                            <ng-container *ngIf="ganttMs.displayMore">
                                <tr *ngFor="let task of ganttMs.tasks">
                                    <td class="text-ellipsis" style=" padding-left:2em;">{{ task.name }}</td>
                                    <td>{{ task.estimatedTime }}</td>
                                    <td class="text-ellipsis">
                                        <app-progress-circle [progressPercent]="task.progress"></app-progress-circle>
                                    </td>
                                </tr>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </tbody>
        </table>
    </div>

    <div class="divider-vert pl-0"></div>
    <!--
            <div style="position:relative">
              <table style="position:absolute">
                <tr *ngFor="let project of projects" style="border: 1px solid blue">
                  &nbsp;
                </tr>
              </table>
            </div>
        -->
    <div id="gantt-detail" class="clr-col" [ngClass]="{overflowYAuto: isFirefox(), 'no-before': !isFirefox()}">
        <table (mousemove)="y = $event.y" #mainTable>
            <thead>
                <tr>
                    <th *ngFor="let d of timespan">
                        {{ d }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let project of projectsFiltered(); let i = index">
                    <ng-container *ngFor="let ganttMs of getOtherMS(project.ganttMS)">
                        <app-gantt-milestone
                            *ngIf="!project.fetching"
                            [ngClass]="{'top-1': !isFirefox()}"
                            [ngStyle]="{
                                left: startProjectToLeftPosition(ganttMs.milestone.beginDate) + 'px',
                                color: projectColorList[i % (projectColorList.length + 1)]
                            }"
                            [ganttms]="ganttMs"
                            [popBottom]="popBottom()"
                        >
                        </app-gantt-milestone>
                    </ng-container>
                    <tr>
                        <div class="progress loop fetcher" *ngIf="project.fetching"><progress></progress></div>
                        <td>
                            <div
                                *ngIf="!project.fetching"
                                class="task-bar popover parent-pop"
                                [class.popover-bottom]="popBottom()"
                                [class.popover-top]="!popBottom()"
                                mwlResizable
                                id="projectbar-{{ project.project.id }}"
                                [enableGhostResize]="true"
                                [resizeEdges]="{bottom: false, right: false, top: false, left: false}"
                                (resizeEnd)="onResizeEndProject(project, $event)"
                                [ngStyle]="{
                                    width: convertIntervalToWidth(project.beginDate, project.endDate) + 'px',
                                    left: startProjectToLeftPosition(project.beginDate) + 'px'
                                }"
                            >
                                <div class="child-pop">
                                    <app-gantt-popover [project]="project"></app-gantt-popover>
                                </div>
                                <div
                                    class="task"
                                    [ngClass]="{
                                        'text-dark': isColorDark(projectColorList[i % (projectColorList.length + 1)]),
                                        'text-light': !isColorDark(projectColorList[i % (projectColorList.length + 1)])
                                    }"
                                    [ngStyle]="{
                                        'background-color': projectColorList[i % (projectColorList.length + 1)],
                                        'border-color': project.project.color
                                    }"
                                >
                                    <div
                                        class="task-completion"
                                        [ngStyle]="{
                                            width: convertIntervalToWidth(project) * project.progress + 'px'
                                        }"
                                    ></div>
                                    <div class="task-content pl-2 pr-2">
                                        <div
                                            class="task-template text-ellipsis"
                                            *ngIf="project"
                                            [title]="project.project.obs"
                                        >
                                            {{ project.project.obs }}
                                        </div>
                                    </div>
                                </div>

                                <ng-container *ngIf="displayContent('projectbar-' + project.project.id)">
                                    <div class="task-emp">
                                        <ng-container *ngFor="let emp of project.who">
                                            <app-gantt-task-employee [code]="emp.user?.code" [color]="emp.user?.color">
                                            </app-gantt-task-employee>
                                        </ng-container>
                                    </div>
                                </ng-container>
                            </div>
                        </td>
                    </tr>

                    <ng-container *ngIf="project.displayMore">
                        <ng-container *ngFor="let ganttMs of getReleases(project.ganttMS)">
                            <tr *ngIf="ganttMs.tasks.length > 0">
                                <td>
                                    <div
                                        class="task-bar popover parent-pop"
                                        [class.popover-bottom]="popBottom()"
                                        [class.popover-top]="!popBottom()"
                                        mwlResizable
                                        id="msbar-{{ ganttMs.milestone.id }}"
                                        [enableGhostResize]="true"
                                        [resizeEdges]="{
                                            bottom: false,
                                            right: authService.isManagerOf(project.project.id) && ganttMs.milestone.id,
                                            top: false,
                                            left: authService.isManagerOf(project.project.id) && ganttMs.milestone.id
                                        }"
                                        (resizeEnd)="onResizeEndMS(ganttMs, $event, project)"
                                        (resizing)="resizingMS(ganttMs, $event)"
                                        [ngStyle]="{
                                            width:
                                                convertIntervalToWidth(
                                                    ganttMs.milestone.beginDate,
                                                    ganttMs.milestone.target
                                                ) + 'px',
                                            left: startProjectToLeftPosition(ganttMs.milestone.beginDate) + 'px'
                                        }"
                                    >
                                        <div class="child-pop">
                                            <app-gantt-popover [milestone]="ganttMs"></app-gantt-popover>
                                        </div>
                                        <div
                                            class="task"
                                            [ngClass]="{
                                                'text-dark': isColorDark(msColorList[i % (msColorList.length + 1)]),
                                                'text-light': !isColorDark(msColorList[i % (msColorList.length + 1)]),
                                                released: ganttMs.milestone.releaseState === ReleaseStateType.RELEASED
                                            }"
                                            [ngStyle]="{
                                                'background-color': msColorList[i % (msColorList.length + 1)],

                                                'border-color': project.project.color
                                            }"
                                        >
                                            <div
                                                class="task-completion"
                                                [ngStyle]="{
                                                    width: convertIntervalToWidth(project) * project.progress + 'px'
                                                }"
                                            ></div>
                                            <div class="task-content pl-2 pr-2">
                                                <div
                                                    class="task-template text-ellipsis"
                                                    *ngIf="project"
                                                    [title]="ganttMs.milestone.obs"
                                                >
                                                    {{ ganttMs.milestone.obs }}
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="task-dot task-start"
                                            *ngIf="authService.isManagerOf(project.project.id) && ganttMs.milestone.id"
                                        >
                                            <clr-icon
                                                shape="drag-handle"
                                                class="c-colresize"
                                                mwlResizeHandle
                                                [resizeEdges]="{
                                                    bottom: false,
                                                    left:
                                                        authService.isManagerOf(project.project.id) &&
                                                        ganttMs.milestone.id,
                                                    top: false,
                                                    right: false
                                                }"
                                            ></clr-icon>
                                        </div>
                                        <div
                                            class="task-dot task-end"
                                            *ngIf="authService.isManagerOf(project.project.id) && ganttMs.milestone.id"
                                        >
                                            <clr-icon
                                                shape="drag-handle"
                                                class="c-colresize"
                                                mwlResizeHandle
                                                [resizeEdges]="{
                                                    bottom: false,
                                                    left: false,
                                                    top: false,
                                                    right:
                                                        authService.isManagerOf(project.project.id) &&
                                                        ganttMs.milestone.id
                                                }"
                                            ></clr-icon>
                                        </div>
                                        <div class="task-emp" *ngIf="displayContent('msbar-' + ganttMs.milestone.id)">
                                            <ng-container *ngFor="let emp of project.who">
                                                <app-gantt-task-employee
                                                    [code]="emp.user?.code"
                                                    [color]="emp.user?.color"
                                                >
                                                </app-gantt-task-employee>
                                            </ng-container>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <ng-container *ngIf="ganttMs.displayMore">
                                <tr *ngFor="let task of ganttMs.tasks">
                                    <!-- RELEASE DE LA V2 VPM -->

                                    <td>
                                        <app-gantt-line
                                            [sourceId]="task.parentId"
                                            [targetId]="task.id"
                                            [arrowRedraw]="arrowChecker"
                                            [displayMode]="currentView"
                                            *ngIf="task.parentId"
                                        ></app-gantt-line>
                                        <ng-container *ngIf="task.taskBlockers.length > 0">
                                            <ng-container *ngFor="let blocker of task.taskBlockers">
                                                <app-gantt-line
                                                    [sourceId]="blocker.blockerId"
                                                    [targetId]="task.id"
                                                    [displayMode]="currentView"
                                                    [blocker]="true"
                                                    [arrowRedraw]="arrowChecker"
                                                    *ngIf="blocker.blockerId"
                                                ></app-gantt-line>
                                            </ng-container>
                                        </ng-container>
                                        <div
                                            class="task-bar popover parent-pop"
                                            mwlResizable
                                            [class.popover-bottom]="popBottom()"
                                            [class.popover-top]="!popBottom()"
                                            id="taskbar-{{ task.id }}"
                                            [enableGhostResize]="true"
                                            [resizeEdges]="{
                                                bottom: false,
                                                right:
                                                    task.beginDate !== null &&
                                                    task._metadata.endDate !== undefined &&
                                                    authService.isManagerOf(project.project.id),
                                                top: false,
                                                left:
                                                    task.beginDate !== null &&
                                                    task._metadata.endDate !== undefined &&
                                                    authService.isManagerOf(project.project.id)
                                            }"
                                            (resizeEnd)="onResizeEndTask(task, $event, project, ganttMs)"
                                            (resizing)="resizingTask(task, $event)"
                                            [ngStyle]="{
                                                width:
                                                    convertIntervalToWidth(
                                                        task._metadata.beginDate,
                                                        task._metadata.endDate
                                                    ) + 'px',
                                                left:
                                                    startProjectToLeftPosition(
                                                        task._metadata.beginDate,
                                                        task._metadata.endDate
                                                    ) + 'px'
                                            }"
                                        >
                                            <div class="child-pop">
                                                <app-gantt-popover [task]="task"></app-gantt-popover>
                                            </div>
                                            <div
                                                class="task"
                                                [ngClass]="{
                                                    'text-dark': isColorDark(
                                                        taskColorList[i % (taskColorList.length + 1)]
                                                    ),
                                                    'text-light': !isColorDark(
                                                        taskColorList[i % (taskColorList.length + 1)]
                                                    )
                                                }"
                                                [ngStyle]="{
                                                    'background-color': taskColorList[i % (taskColorList.length + 1)],
                                                    'border-color': project.project.color
                                                }"
                                            >
                                                <div
                                                    class="task-completion"
                                                    [ngStyle]="{
                                                        width:
                                                            convertIntervalToWidth(project) *
                                                                (task.progress > 100 ? 1 : task.progress / 100) +
                                                            'px'
                                                    }"
                                                ></div>
                                                <div class="task-content pl-2 pr-2">
                                                    <div
                                                        class="task-template text-ellipsis"
                                                        *ngIf="project"
                                                        [title]="task.name"
                                                    >
                                                        {{ task.name }}
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                class="task-dot task-start"
                                                *ngIf="
                                                    task.beginDate !== null &&
                                                    task._metadata.endDate !== undefined &&
                                                    authService.isManagerOf(project.project.id)
                                                "
                                            >
                                                <clr-icon
                                                    shape="drag-handle"
                                                    class="c-colresize"
                                                    mwlResizeHandle
                                                    [resizeEdges]="{
                                                        bottom: false,
                                                        right: false,
                                                        top: false,
                                                        left:
                                                            task.beginDate !== null &&
                                                            task._metadata.endDate !== undefined &&
                                                            authService.isManagerOf(project.project.id)
                                                    }"
                                                ></clr-icon>
                                            </div>
                                            <div
                                                class="task-dot task-end"
                                                *ngIf="
                                                    task.beginDate !== null &&
                                                    task._metadata.endDate !== undefined &&
                                                    authService.isManagerOf(project.project.id)
                                                "
                                            >
                                                <clr-icon
                                                    shape="drag-handle"
                                                    class="c-colresize"
                                                    mwlResizeHandle
                                                    [resizeEdges]="{
                                                        bottom: false,
                                                        left: false,
                                                        top: false,
                                                        right:
                                                            task.beginDate !== null &&
                                                            task._metadata.endDate !== undefined &&
                                                            authService.isManagerOf(project.project.id)
                                                    }"
                                                ></clr-icon>
                                            </div>
                                            <div class="task-emp" *ngIf="displayContent('taskbar-' + task.id)">
                                                <ng-container *ngFor="let assignation of task.assigned">
                                                    <app-gantt-task-employee
                                                        [code]="assignation?.employee.user?.code"
                                                        [color]="assignation?.employee.user?.color"
                                                    >
                                                    </app-gantt-task-employee>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>

<clr-alert class="date-indicator" [clrAlertAppLevel]="false" [clrAlertType]="'info'" *ngIf="goalDate">
    <clr-alert-item>
        <span class="alert-text">{{ 'date_indicator' | translate }}: {{ goalDate | momentFormat }}</span>
    </clr-alert-item>
</clr-alert>
