import {FormGroup} from 'ngx-strongly-typed-forms';
import {AbstractControl} from 'ngx-strongly-typed-forms/model';
import {TOAST_TYPE, ToastService} from '../../shared/toast/toast.service';

export const validate = <T>(f: FormGroup<T>, showToast = true) => {
    const toastService = showToast && new ToastService();
    const toast = (text: string) =>
        toastService &&
        toastService.show({
            text,
            type: TOAST_TYPE.WARNING,
        });

    if (f.valid || f.status === 'VALID') {
        return true;
    }

    let validDate = true;
    let validWeekend = true;
    let validVersion = true;
    (Object.values(f.controls) as AbstractControl<T>[]).map((control) => {
        if (!control.errors) {
            return;
        }

        if (control.errors.validDate) {
            validDate = false;
        } else if (control.errors.validWeekend) {
            validWeekend = false;
        } else if (control.errors.validSemVer) {
            validVersion = false;
        }
    });

    if (!validDate) {
        toast('Please fix date format (YYYY-MM-DD)');
    } else if (!validWeekend) {
        toast('Please select a working day (No weekend)');
    } else if (!validVersion) {
        toast('Please fix the version field');
    } else if (!f.valid) {
        // time has changed so form can now be valid
        const invalids = Object.keys(f.controls).filter((control) => f.controls[control].status === 'INVALID');

        toast(`Please fill in ${invalids.join(', ') || 'all required fields'}`);
    } else {
        return true;
    }

    return false;
};
