var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { RIGHTS } from '../../../../../defs/schema-static';
import { AuthService } from '../../auth/auth.service';
import { FormsAddMilestoneService } from '../../forms/add-milestone.service';
import { FormsAddTaskComponent } from '../../forms/add-task/add-task.component';
import { FormsAddTaskService } from '../../forms/add-task/add-task.service';
import { FormsAddTimeService } from '../../forms/add-time.service';
import { validate } from '../../forms/validators/form.validator';
import { GlobalSearchModalComponent } from '../../global-search-modal/global-search-modal.component';
import { SHORTCUT_COMBO, SHORTCUT_CREATE, SHORTCUT_MISC, SHORTCUT_QUICK_ACTION, ShortcutHandlerService, } from '../shortcut-handler/shortcut-handler.service';
var IActionName;
(function (IActionName) {
    IActionName["TASK"] = "task";
    IActionName["MILESTONE"] = "milestone";
    IActionName["TIME"] = "time";
    IActionName["SEARCH"] = "search";
    IActionName["COMMAND"] = "command";
})(IActionName || (IActionName = {}));
var QuickActionComponent = (function () {
    function QuickActionComponent(formsAddTaskService, formsAddMilestoneService, formsAddTimeService, shortcutHandlerService, authService) {
        var _a;
        var _this = this;
        this.formsAddTaskService = formsAddTaskService;
        this.formsAddMilestoneService = formsAddMilestoneService;
        this.formsAddTimeService = formsAddTimeService;
        this.shortcutHandlerService = shortcutHandlerService;
        this.authService = authService;
        this.isCollapsed = true;
        this.actions = (_a = {},
            _a[IActionName.TASK] = {
                label: 'add_task',
                shortcut: SHORTCUT_CREATE.TASK,
                quickShortcut: SHORTCUT_QUICK_ACTION.CREATE_TASK,
                icon: 'tasks',
                requiredRight: RIGHTS.TASK_CREATE,
                showModal: false,
                class: 'quick-action-task',
                component: this.addTaskComponent,
            },
            _a[IActionName.MILESTONE] = {
                label: 'add_milestone',
                shortcut: SHORTCUT_CREATE.MILESTONE,
                quickShortcut: SHORTCUT_QUICK_ACTION.CREATE_MILESTONE,
                icon: 'bookmark',
                showModal: false,
                class: 'quick-action-milestone',
            },
            _a[IActionName.TIME] = {
                label: 'add_time',
                shortcut: SHORTCUT_CREATE.TIME,
                quickShortcut: SHORTCUT_QUICK_ACTION.CREATE_TIME,
                icon: 'clock',
                showModal: false,
                class: 'quick-action-time',
            },
            _a[IActionName.SEARCH] = {
                label: 'global_search',
                shortcut: SHORTCUT_MISC.GLOBAL_SEARCH,
                globalShortcut: true,
                icon: 'search',
                showModal: false,
                class: 'quick-action-search',
                component: this.globalSearchComponent,
            },
            _a[IActionName.COMMAND] = {
                label: 'Run a command',
                icon: 'terminal',
                showModal: false,
                hide: function (action) { return !_this.process || !_this.process.spawn; },
            },
            _a);
        this.showMultiple = false;
        this.prompt = false;
        this.command = '';
        this.showMenuModal = false;
        this.IActionName = IActionName;
        this.objectValues = Object.values;
        this.SHORTCUT_COMOBO = SHORTCUT_COMBO;
        this.validate = validate;
        this.RIGHTS = RIGHTS;
        if (!window.require) {
            try {
                this.process = window.require('child_process');
            }
            catch (e) { }
        }
    }
    QuickActionComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.shortcutHandlerService.register({
            name: SHORTCUT_MISC.QUICK_ACTION,
            shortcut: SHORTCUT_MISC.QUICK_ACTION,
            callback: function () {
                _this.showMenuModal = true;
            },
            context: this,
        });
        Object.values(this.actions)
            .filter(function (action) { return action.shortcut; })
            .map(function (action) {
            _this.shortcutHandlerService.register({
                name: action.shortcut,
                shortcut: action.shortcut,
                callback: function (params) {
                    action.params = params;
                    _this.showAction(action);
                },
            });
        });
        this.actions[IActionName.TASK].component = this.addTaskComponent;
    };
    QuickActionComponent.prototype.registerShortcuts = function () {
        var _this = this;
        Object.values(this.actions)
            .filter(function (action) { return action.quickShortcut; })
            .map(function (action) {
            _this.shortcutHandlerService.register({
                shortcut: action.quickShortcut,
                callback: function (params) {
                    _this.showAction(action);
                },
                context: _this,
            });
        });
    };
    QuickActionComponent.prototype.unregisterShortcuts = function () {
        var _a;
        (_a = this.shortcutHandlerService).unregister.apply(_a, [this].concat(Object.values(this.actions)
            .filter(function (action) { return action.quickShortcut; })
            .map(function (action) { return action.quickShortcut; })));
    };
    QuickActionComponent.prototype.openModal = function (name) {
        this.unregisterShortcuts();
    };
    QuickActionComponent.prototype.resetParams = function (name) {
        delete this.actions[name].params;
    };
    QuickActionComponent.prototype.closeModal = function (name) {
        this.actions[name].showModal = false;
        this.showMenuModal = false;
    };
    QuickActionComponent.prototype.showAction = function (action) {
        action.showModal = true;
        if (this.showMenuModal) {
            this.showMenuModal = false;
        }
    };
    QuickActionComponent.prototype.addTask = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.formsAddTaskService.submit(this.addTaskComponent.form)];
                    case 1:
                        if (_a.sent()) {
                            this.addTaskComponent.alreadyInit = false;
                            this.resetParams(IActionName.TASK);
                            this.closeModal(IActionName.TASK);
                        }
                        return [2];
                }
            });
        });
    };
    QuickActionComponent.prototype.addNewMilestone = function (milestone) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.formsAddMilestoneService.submit(milestone)];
                    case 1:
                        if (_a.sent()) {
                            this.closeModal(IActionName.MILESTONE);
                        }
                        return [2];
                }
            });
        });
    };
    QuickActionComponent.prototype.cancelMilestoneCreation = function () {
        this.formsAddMilestoneService.abort();
        this.resetParams(IActionName.MILESTONE);
    };
    QuickActionComponent.prototype.addTime = function (item) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.formsAddTimeService.submit(item.time, item.task)];
                    case 1:
                        if (_a.sent()) {
                            this.closeModal(IActionName.TIME);
                            this.resetParams(IActionName.TIME);
                        }
                        return [2];
                }
            });
        });
    };
    QuickActionComponent.prototype.loadAddTask = function () {
        var _this = this;
        requestAnimationFrame(function () {
            _this.addTaskComponent.load(_this.actions[IActionName.TASK].params);
        });
    };
    QuickActionComponent.prototype.run = function (command) {
        var _this = this;
        var cmd = this.process.spawn(command);
        cmd.stdout.on('data', function (data) { return console.log(command, data.toString()); });
        cmd.stderr.on('data', function (data) { return console.error(command, data.toString()); });
        cmd.on('close', function () { return (_this.actions[IActionName.COMMAND].showModal = false); });
    };
    return QuickActionComponent;
}());
export { QuickActionComponent };
