import * as i0 from "@angular/core";
import * as i1 from "@clr/angular";
import * as i2 from "@angular/forms";
import * as i3 from "../autofocus/autofocus.directive";
import * as i4 from "@angular/common";
import * as i5 from "./fuzzy-datagrid-filter.component";
var styles_FuzzyDatagridFilterComponent = [];
var RenderType_FuzzyDatagridFilterComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FuzzyDatagridFilterComponent, data: {} });
export { RenderType_FuzzyDatagridFilterComponent as RenderType_FuzzyDatagridFilterComponent };
function View_FuzzyDatagridFilterComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "clr-icon", [["class", "c-hand"], ["shape", "eraser"], ["style", "position: absolute; right: 0;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.filter = "";
        var pd_0 = (_co.setFilter() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 16384, null, 0, i1.ClrIconCustomTag, [], null, null)], null, null); }
export function View_FuzzyDatagridFilterComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["style", "position: relative"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "input", [["name", "search"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 2)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 2)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.filter = $event) !== false);
        ad = (pd_4 && ad);
    } if (("input" === en)) {
        var pd_5 = (_co.changes.next(_co.filterKey) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 16384, null, 0, i2.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i0.ɵdid(4, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i0.ɵdid(6, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), i0.ɵdid(7, 4734976, null, 0, i3.AutofocusDirective, [i0.ElementRef], { sharedAutofocus: [0, "sharedAutofocus"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FuzzyDatagridFilterComponent_1)), i0.ɵdid(9, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = "search"; var currVal_8 = _co.filter; _ck(_v, 4, 0, currVal_7, currVal_8); var currVal_9 = _co.filterOpen; _ck(_v, 7, 0, currVal_9); var currVal_10 = _co.filter.length; _ck(_v, 9, 0, currVal_10); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 6).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 6).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 6).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 6).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 6).ngClassValid; var currVal_5 = i0.ɵnov(_v, 6).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 6).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_FuzzyDatagridFilterComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "shared-fuzzy-datagrid-filter", [], null, null, null, View_FuzzyDatagridFilterComponent_0, RenderType_FuzzyDatagridFilterComponent)), i0.ɵdid(1, 638976, null, 0, i5.FuzzyDatagridFilterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FuzzyDatagridFilterComponentNgFactory = i0.ɵccf("shared-fuzzy-datagrid-filter", i5.FuzzyDatagridFilterComponent, View_FuzzyDatagridFilterComponent_Host_0, { filterKey: "filterKey", propertyGetter: "propertyGetter", filter: "filter", filterOpen: "filterOpen" }, {}, []);
export { FuzzyDatagridFilterComponentNgFactory as FuzzyDatagridFilterComponentNgFactory };
