import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {duration} from 'moment';
import {Subscription} from 'rxjs';
import {FileStateType} from '../../../../../defs/schema-static';
import {getTimeString} from '../../../../../defs/schema/public/Times';
import {FileManagerService, IUploadingFile} from '../file-manager/file-manager.service';

@Component({
    selector: 'app-file-manager-progress',
    templateUrl: './file-manager-progress.component.html',
    styleUrls: ['./file-manager-progress.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileManagerProgressComponent implements OnInit, OnDestroy {
    @Input() public file: IUploadingFile;

    private uploadingFileProgressSubscription: Subscription;

    public constructor(
        private readonly fileManager: FileManagerService,
        private readonly changeDetectorRef: ChangeDetectorRef
    ) {}

    public ngOnInit() {
        this.uploadingFileProgressSubscription = this.fileManager.uploadingFileProgress.subscribe(
            (uploadingFile: IUploadingFile) => {
                if (uploadingFile === this.file) {
                    this.changeDetectorRef.detectChanges();
                }
            }
        );
    }

    public ngOnDestroy() {
        if (this.uploadingFileProgressSubscription) {
            this.uploadingFileProgressSubscription.unsubscribe();
            this.uploadingFileProgressSubscription = undefined;
        }
    }

    public static formatRemainingSeconds(seconds: number) {
        return getTimeString(duration(seconds || 0, 'seconds'), 1);
    }

    public readonly FileStateType = FileStateType;
    public readonly formatRemainingSeconds = FileManagerProgressComponent.formatRemainingSeconds;
}
