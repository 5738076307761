import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';

@Component({
    selector: 'app-wander',
    templateUrl: './wander.component.html',
    styleUrls: ['./wander.component.scss'],
})
export class WanderComponent implements OnInit {
    @ViewChild('bobby') public bobby: ElementRef;

    @Input() public isCollapsed: boolean;

    public ngOnInit() {
        const preselected = localStorage.getItem('bobbyColor');
        (this.bobby.nativeElement as HTMLElement).style.backgroundColor = 'teal';
        if (preselected && preselected === 'rainbow') {
            (this.bobby.nativeElement as HTMLElement).classList.add('rainbow');
        } else if (preselected) {
            (this.bobby.nativeElement as HTMLElement).style.backgroundColor = preselected;
        }
    }

    public setRandomColor() {
        if (localStorage.getItem('bobbyColor') === 'rainbow') {
            return;
        }
        (this.bobby.nativeElement as HTMLElement).style.backgroundColor = `rgb(${Math.random() * 255},${Math.random() *
            255},${Math.random() * 255})`;
        localStorage.setItem('bobbyColor', (this.bobby.nativeElement as HTMLElement).style.backgroundColor);
    }

    public rainbowMode() {
        if ((this.bobby.nativeElement as HTMLElement).classList.contains('rainbow')) {
            (this.bobby.nativeElement as HTMLElement).classList.remove('rainbow');
            localStorage.removeItem('bobbyColor');
        } else {
            (this.bobby.nativeElement as HTMLElement).classList.add('rainbow');
            localStorage.setItem('bobbyColor', 'rainbow');
        }
    }
}
