<ng-select
    *ngIf="
        type === SelectFilterType.EMPLOYEE ||
        type === SelectFilterType.CLIENT ||
        type === SelectFilterType.MANAGER ||
        type === SelectFilterType.CONTACT
    "
    [placeholder]="(type === SelectFilterType.CLIENT ? 'clients' : 'employees') | translate"
    [items]="emps"
    [(ngModel)]="selected"
    [multiple]="true"
    [hideSelected]="true"
    [closeOnSelect]="false"
    (change)="toggleEmp($event)"
    bindLabel="user.name"
>
    <ng-template ng-label-tmp let-item="item" let-clear="clear">
        <!-- <app-long-avatar class="text-bold float-left mr-02 smaller" [code]="item?.code" [bgColor]="item?.color">
    </app-long-avatar> -->
        {{ item?.user?.name }}
        <span class="ng-value-icon right ml-01" (click)="clear(item)" aria-hidden="true">×</span>
    </ng-template>
</ng-select>

<ng-select
    *ngIf="type === SelectFilterType.PROJECT"
    [placeholder]="'projects' | translate"
    [items]="projects"
    [(ngModel)]="selected"
    [multiple]="true"
    [hideSelected]="true"
    [closeOnSelect]="false"
    (change)="toggleProject($event)"
    bindLabel="obs"
>
    <ng-template ng-label-tmp let-item="item" let-clear="clear">
        <!-- <app-long-avatar class="text-bold float-left mr-02 smaller" [code]="item?.code" [bgColor]="item?.color">
        </app-long-avatar> -->
        {{ item?.obs }}
        <span class="ng-value-icon right ml-01" (click)="clear(item)" aria-hidden="true">×</span>
    </ng-template>
</ng-select>
