// @ts-ignore
import publicConfig from '../../../defs/config/config.json';
import {version} from '../../../package.json';

// noinspection JSUnusedGlobalSymbols
export const environment = {
    production: true,
    version,
    publicConfig,
};
