import {CommonModule} from '@angular/common';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {ClarityModule} from '@clr/angular';
import {SharedModule} from '../shared/shared.module';
import {AuthComponent} from './auth.component';
import {LogInComponent} from './log-in/log-in.component';
import {LogOutComponent} from './log-out/log-out.component';
import {SignUpComponent} from './sign-up/sign-up.component';
import {UnauthorizedComponent} from './unauthorized/unauthorized.component';

@NgModule({
    imports: [CommonModule, FormsModule, RouterModule, SharedModule, ClarityModule],
    declarations: [AuthComponent, LogInComponent, LogOutComponent, SignUpComponent, UnauthorizedComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AuthModule {}
