import * as i0 from "./gantt-task-employee.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./gantt-task-employee.component";
var styles_GanttTaskEmployeeComponent = [i0.styles];
var RenderType_GanttTaskEmployeeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GanttTaskEmployeeComponent, data: {} });
export { RenderType_GanttTaskEmployeeComponent as RenderType_GanttTaskEmployeeComponent };
export function View_GanttTaskEmployeeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [["class", "usertag pr-1 pl-1"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(2, { "background-color": 0 }), (_l()(), i1.ɵted(3, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, _co.color); _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.code; _ck(_v, 3, 0, currVal_1); }); }
export function View_GanttTaskEmployeeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-gantt-task-employee", [], null, null, null, View_GanttTaskEmployeeComponent_0, RenderType_GanttTaskEmployeeComponent)), i1.ɵdid(1, 49152, null, 0, i3.GanttTaskEmployeeComponent, [], null, null)], null, null); }
var GanttTaskEmployeeComponentNgFactory = i1.ɵccf("app-gantt-task-employee", i3.GanttTaskEmployeeComponent, View_GanttTaskEmployeeComponent_Host_0, { color: "color", code: "code" }, {}, []);
export { GanttTaskEmployeeComponentNgFactory as GanttTaskEmployeeComponentNgFactory };
