<div class="column-container py-02">
    <div class="clr-row flex-1-set">
        <div class="clr-col-12">
            <clr-datagrid appDatagridToggle class="h-100" [clrDgLoading]="!backups">
                <clr-dg-column
                    *ngFor="let column of BACKUP_COLUMNS"
                    [clrDgField]="column.field"
                    [clrDgSortOrder]="column.order"
                >
                    {{ column.translateKey | translate }}

                    <clr-dg-filter
                        *ngIf="!column.filterType || column.filterType === DATAGRID_FILTER_TYPE.FUZZY"
                        [clrDgFilter]="fuzzyFilter"
                        [(clrDgFilterOpen)]="filterOpen[column.field]"
                    >
                        <shared-fuzzy-datagrid-filter
                            [filterKey]="column.field"
                            [filterOpen]="filterOpen[column.field]"
                            #fuzzyFilter
                        ></shared-fuzzy-datagrid-filter>
                    </clr-dg-filter>

                    <clr-dg-filter
                        *ngIf="column.filterType === DATAGRID_FILTER_TYPE.DATE"
                        [clrDgFilter]="dateFilter"
                        [(clrDgFilterOpen)]="filterOpen[column.field]"
                    >
                        <shared-date-datagrid-filter
                            [filterKey]="column.field"
                            [filterOpen]="filterOpen[column.field]"
                            #dateFilter
                        ></shared-date-datagrid-filter>
                    </clr-dg-filter>
                </clr-dg-column>
                <clr-dg-row *clrDgItems="let backup of backups" [clrDgItem]="backup">
                    <clr-dg-cell class="monospace">
                        <a
                            style="color: #0079b8;"
                            class="c-hand "
                            *sharedAccessControl="RIGHTS.CLIENT_BK_DL; else keyWithoutLink"
                            (click)="downloadBackup(backup.key)"
                        >
                            {{ getKeyWithoutPrefix(backup) }}
                        </a>

                        <ng-template #keyWithoutLink> {{ getKeyWithoutPrefix(backup) }} </ng-template>
                    </clr-dg-cell>

                    <clr-dg-cell class="monospace-date" [title]="backup.date | momentDuration">
                        {{ backup.date | momentFormat: 'L LT' }}
                    </clr-dg-cell>

                    <clr-dg-cell class="monospace">{{ backup.size | fileSize }}</clr-dg-cell>
                </clr-dg-row>

                <clr-dg-footer>
                    {{ 'placeholder_backupprefix' | translate }}: <code>{{ prefix }}</code>

                    <span *ngIf="backups">| {{ 'count_backups' | translate: {count: backups.length} }}</span>
                </clr-dg-footer>
            </clr-datagrid>
        </div>
    </div>
</div>
