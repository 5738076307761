<div *ngIf="show" class="modal" [style.zIndex]="zIndex" #modal>
    <div
        class="modal-dialog"
        [class.modal-sm]="size === 'sm'"
        [class.modal-lg]="size === 'lg'"
        [class.modal-xl]="size === 'xl'"
        role="dialog"
        aria-hidden="true"
        appMovable
    >
        <div class="modal-content">
            <div class="modal-header movable-handle" *ngIf="!!modalHeader || !!title">
                <button
                    *ngIf="!preventDismiss"
                    aria-label="Close"
                    class="close"
                    type="button"
                    (click)="closeModal(true, true, $event)"
                >
                    <clr-icon aria-hidden="true" shape="close"></clr-icon>
                </button>
                <ng-template *ngIf="!!modalHeader" [ngTemplateOutlet]="modalHeader"></ng-template>
                <ng-container *ngIf="!modalHeader && !!title">
                    <h3 class="modal-title">{{ title }}</h3>
                </ng-container>
            </div>
            <div class="modal-body" *ngIf="!!modalBody"><ng-template [ngTemplateOutlet]="modalBody"></ng-template></div>
            <div class="modal-footer" *ngIf="!!modalFooter" #modalFooterRef>
                <ng-template [ngTemplateOutlet]="modalFooter"></ng-template>
            </div>
        </div>
    </div>
    <div *ngIf="show" class="modal-backdrop" aria-hidden="true" (click)="closeModal(true, true, $event)"></div>
</div>
