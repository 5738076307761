<div class="column-container py-02">
    <div class="clr-row">
        <div class="clr-col-12"><h1 class="mt-0" translate>title_employees</h1></div>
    </div>

    <div class="clr-row flex-1">
        <clr-datagrid
            appDatagridToggle
            class="clr-col-12"
            [(clrDgSelected)]="selectedEmployees"
            [clrDgLoading]="!employees"
        >
            <clr-dg-action-bar>
                <div class="btn-group">
                    <button
                        [title]="('shortcut_' | translate) + ' C'"
                        class="btn btn-primary"
                        (click)="showCreateModal = true"
                        *sharedAccessControl="RIGHTS.EMPLOYEE_CREATE"
                    >
                        {{ 'add_employee' | translate }}
                    </button>
                </div>

                <div class="btn-group">
                    <button
                        class="btn btn-danger"
                        (click)="showDeleteModal = true"
                        *sharedAccessControl="RIGHTS.EMPLOYEE_DELETE"
                        [disabled]="!selectedEmployees.length"
                    >
                        {{ (selectedEmployees.length > 1 ? 'delete_employees' : 'delete_employee') | translate }}
                    </button>
                </div>
            </clr-dg-action-bar>

            <ng-container *ngFor="let column of EMPLOYEES_COLUMNS">
                <clr-dg-column
                    *sharedAccessControl="column.right"
                    [clrDgField]="column.field"
                    [clrDgSortOrder]="column.order"
                >
                    {{ column.translateKey | translate }}
                    <clr-dg-filter [clrDgFilter]="fuzzyFilter" [(clrDgFilterOpen)]="filterOpen[column.field]">
                        <shared-fuzzy-datagrid-filter
                            [filterKey]="column.field"
                            [filterOpen]="filterOpen[column.field]"
                            #fuzzyFilter
                        ></shared-fuzzy-datagrid-filter>
                    </clr-dg-filter>
                </clr-dg-column>
            </ng-container>

            <clr-dg-row *clrDgItems="let employee of employees" [clrDgItem]="employee">
                <clr-dg-cell>
                    <a [routerLink]="['/employee', employee.id]"> {{ employee.user?.name }} </a>
                    <app-long-avatar
                        [code]="employee.user?.code"
                        [bgColor]="employee.user?.color"
                        [type]="LONG_AVATAR_TYPE.BADGE_100"
                    ></app-long-avatar>
                </clr-dg-cell>

                <clr-dg-cell>
                    <ng-container *sharedAccessControl="RIGHTS.MONEY"
                        >{{ employee.dayPrice | currency: 'EUR' }}
                    </ng-container>
                </clr-dg-cell>
            </clr-dg-row>
            <clr-dg-footer>
                <span *ngIf="employees">{{ 'count_employees' | translate: {count: employees.length} }}</span>
            </clr-dg-footer>
        </clr-datagrid>
    </div>
</div>

<app-modal [(show)]="showDeleteModal" [title]="'employee_delete_modal_title' | translate">
    <ng-template appModalBody>
        <p>
            {{
                'swal_delete_attch'
                    | translate
                        : {
                              what:
                                  selectedEmployees.length > 1
                                      ? ('count_employees' | translate: {count: selectedEmployees.length})
                                      : selectedEmployees[0].user?.name
                          }
            }}
        </p>
    </ng-template>

    <ng-template appModalFooter>
        <button
            class="btn btn-secondary"
            (click)="showDeleteModal = false"
            [sharedAutofocus]="showDeleteModal"
            translate
        >
            form_cancel
        </button>
        <button class="btn btn-danger" (click)="deleteSelectedEmployees()">
            {{ (selectedEmployees.length > 1 ? 'delete_employees' : 'delete_employee') | translate }}
        </button>
    </ng-template>
</app-modal>

<ng-container *ngIf="showCreateModal">
    <app-employee-wizard
        [edit]="false"
        [(clrWizardOpen)]="showCreateModal"
        (finish)="addNewEmployee($event)"
        *sharedAccessControl="RIGHTS.EMPLOYEE_CREATE"
    ></app-employee-wizard>
</ng-container>
