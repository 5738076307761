import {Component, Input} from '@angular/core';
import {MilestonesType} from '../../../../defs/schema-static';
import {IGanttMilestone} from '../gantt/gantt.component';

@Component({
    selector: 'app-gantt-milestone',
    templateUrl: './gantt-milestone.component.html',
    styleUrls: ['./gantt-milestone.component.scss'],
})
export class GanttMilestoneComponent {
    @Input()
    public ganttms: IGanttMilestone;
    @Input()
    public popBottom: boolean;

    public readonly MilestonesType = MilestonesType;
}
