import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
// @ts-ignore
import fuzzysort from 'fuzzysort';
// @ts-ignore
import {horsey} from 'horsey';
import {
    ApiRoutePlurality,
    HTTP_METHOD,
    NAVIGATE_RIGHTS,
    stripAccents,
    TASK_STATUS_CLASS,
    TASK_STATUS_SHORT_LABEL,
    TaskStatusType,
} from '../../../../defs/schema-static';
import {CLIENT_SCHEMA_ROUTE, IClient} from '../../../../defs/schema/public/Clients';
import {EMPLOYEE_SCHEMA_ROUTE, IEmployee} from '../../../../defs/schema/public/Employees';
import {IProject, PROJECT_SCHEMA_ROUTE} from '../../../../defs/schema/public/Projects';
import {ITask, TASK_SCHEMA_ROUTE} from '../../../../defs/schema/public/Tasks';
import {SETTING_LANGUAGE_VALUES} from '../../../../defs/schema/public/Users';
import {IWikiPage, WIKI_PAGE_SCHEMA_ROUTE} from '../../../../defs/schema/public/WikiPage';
import {
    CLIENT_TABS,
    CLIENT_TABS_LABELS,
    CLIENT_TABS_RIGHTS,
    ILoadableComponent,
    PROJECT_TABS,
    PROJECT_TABS_LABELS,
    PROJECT_TABS_RIGHTS,
    TASK_STATUS_FILTER,
} from '../app-static';
import {AuthService} from '../auth/auth.service';
import {ModalComponent} from '../modal/modal.component';
import {ControlFlowService} from '../shared/control-flow/control-flow.service';
import {HttpRestService} from '../shared/http-rest/http-rest.service';
import {
    SHORTCUT_COMBO,
    SHORTCUT_LABEL,
    SHORTCUT_NAME,
    SHORTCUT_RIGHTS,
    ShortcutHandlerService,
} from '../shared/shortcut-handler/shortcut-handler.service';

enum AutocompleteCategory {
    GLOBAL = 'Global',
    ACTION = 'Actions',
    PROJECT = 'Projects',
    PROJECT_SUBMENU = 'PROJECT_SUBMENU',
    CLIENT = 'Clients',
    CLIENT_SUBMENU = 'CLIENT_SUBMENU',
    TASKS = 'Tasks',
    EMPLOYEE = 'Employees',
    WIKI_PAGE = 'Wiki Pages',
}

const AUTOCOMPLETE_CATEGORY_TRANSLATE_KEY: {[key in AutocompleteCategory]?: string} = {
    [AutocompleteCategory.ACTION]: 'actions',
    [AutocompleteCategory.PROJECT]: 'projects',
    [AutocompleteCategory.CLIENT]: 'clients',
    [AutocompleteCategory.TASKS]: 'tasks',
    [AutocompleteCategory.EMPLOYEE]: 'employees',
    [AutocompleteCategory.WIKI_PAGE]: 'wiki-pages',
};

type AutocompleteEntity = IProject | IClient | IEmployee | ITask | IWikiPage;

interface ISourceData {
    input: string;
    limit: number;
    previousSelection?: any;
    previousSuggestions: any[];
    renderCategory?: AutocompleteCategory;
    renderItem?: any;
}

export interface IAutocompleteSuggestion<T extends AutocompleteEntity = any, U extends AutocompleteEntity = any> {
    id?: number;
    value: string | SHORTCUT_NAME;
    text: string;
    category: AutocompleteCategory;
    shortcut?: SHORTCUT_NAME;
    entity?: Partial<T>;
    parent?: IAutocompleteSuggestion<U>;
}

interface IAutocompleteSource<T extends AutocompleteEntity = any> {
    id: AutocompleteCategory;
    list: IAutocompleteSuggestion<T>[];
    limit: number;
    submenu?: IAutocompleteSuggestion[];
}

interface IAutocompleteCategoryMethod<T extends AutocompleteEntity> {
    get(): Promise<T[]>;
    map(entities: T[]): Promise<IAutocompleteSource<T>>;
}

@Component({
    selector: 'app-global-search',
    templateUrl: './global-search-modal.component.html',
    styleUrls: ['./global-search-modal.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class GlobalSearchModalComponent extends ModalComponent
    implements OnInit, OnDestroy, ILoadableComponent, AfterViewInit {
    public title = 'global_search';
    @Input() public query = '';

    @Input() public displayInline = false;

    @Input() public isCollapsed = false;

    @ViewChild('autocompleteInput') public autocompleteInput: ElementRef;

    public autocompleteCategoryMethods: {[category in AutocompleteCategory]?: IAutocompleteCategoryMethod<any>} = {
        [AutocompleteCategory.PROJECT]: {get: this.getProjects, map: this.mapProjects},
        [AutocompleteCategory.CLIENT]: {get: this.getClients, map: this.mapClients},
        [AutocompleteCategory.TASKS]: {get: this.getTasks, map: GlobalSearchModalComponent.mapTasks},
        [AutocompleteCategory.EMPLOYEE]: {get: this.getEmployees, map: GlobalSearchModalComponent.mapEmployees},
        [AutocompleteCategory.WIKI_PAGE]: {get: this.getWikiPages, map: GlobalSearchModalComponent.mapWikiPages},
    };

    private _searchScope: AutocompleteCategory = AutocompleteCategory.GLOBAL;
    private horsey: typeof horsey;
    private readonly horseySources: IAutocompleteSource[] = [];

    public constructor(
        protected shortcutHandlerService: ShortcutHandlerService,
        private readonly httpRest: HttpRestService,
        private readonly router: Router,
        protected changeDetectorRef: ChangeDetectorRef,
        private readonly translate: TranslateService,
        controlFlowService: ControlFlowService,
        private readonly authService: AuthService
    ) {
        super(shortcutHandlerService, controlFlowService, changeDetectorRef);
    }

    public ngOnInit() {
        super.ngOnInit();

        if (this.displayInline) {
            this.controlFlowService.isCollapsed.subscribe((collapsed) => {
                if (collapsed) {
                    this.query = '';
                }
            });
        }
    }

    // tslint:disable-next-line:prefer-function-over-method no-empty
    public load() {}

    public modalShown() {
        (async () => this.initHorsey())();
        this.query = '';
        this._searchScope = AutocompleteCategory.GLOBAL;
    }

    public modalHidden() {
        if (this.horsey) {
            this.horsey.destroy();
            this.horsey = null;
        }
    }

    public ngAfterViewInit() {
        if (this.displayInline) {
            (async () => this.initHorsey())();
            this.query = '';
            this._searchScope = AutocompleteCategory.GLOBAL;
        }
    }

    protected focusInput() {
        if (this.autocompleteInput) {
            this.autocompleteInput.nativeElement.focus();
        }
    }

    private async initHorsey() {
        if (this.horsey) {
            this.horsey.destroy();
            this.autocompleteInput.nativeElement.focus();
        }

        if (!this.translate.currentLang) {
            const browserLang = this.translate.getBrowserLang().toUpperCase() as SETTING_LANGUAGE_VALUES;
            const lang = Object.values(SETTING_LANGUAGE_VALUES).includes(browserLang)
                ? browserLang
                : SETTING_LANGUAGE_VALUES.EN;

            this.translate.use(lang);

            await new Promise((resolve, reject) => this.translate.onLangChange.subscribe(resolve, reject));
        }

        const {no_matches_ellipsis, loading_ellipsis, count_more_ellipsis} = await this.translate
            .get(['no_matches_ellipsis', 'loading_ellipsis', 'count_more_ellipsis'])
            .toPromise();

        this.horsey = horsey(this.autocompleteInput.nativeElement, {
            source: this.getHorseySources.bind(this),
            getText: (suggestion: IAutocompleteSuggestion) => suggestion.text,
            fuzzyKeys: GlobalSearchModalComponent.HORSEY_FUZZY_KEYS,
            fuzzyThreshold: GlobalSearchModalComponent.HORSEY_FUZZYSORT_THRESHOLD,
            limit: GlobalSearchModalComponent.HORSEY_CATEGORY_LIMIT,
            overflowIncrement: GlobalSearchModalComponent.HORSEY_CATEGORY_OVERFLOW_LIMIT_INCREMENT,
            i18n: {
                noMatches: no_matches_ellipsis,
                loadingSources: loading_ellipsis,
                more: count_more_ellipsis,
            },
            filterCategories: (query: string, category: IAutocompleteSource) => {
                const categoryIndex = Object.values(GlobalSearchModalComponent.CATEGORY_KEY).findIndex(
                    (_key) => query.indexOf(_key) === 0
                );
                const categoryKey =
                    categoryIndex !== -1 && Object.keys(GlobalSearchModalComponent.CATEGORY_KEY)[categoryIndex];

                return (
                    this.categoriesScope.indexOf(category.id) !== -1 && (!categoryKey || categoryKey === category.id)
                );
            },
            filterSuggestions: (query: string, category: IAutocompleteSource) => {
                const categoryKey =
                    GlobalSearchModalComponent.CATEGORY_KEY[GlobalSearchModalComponent.getCategory(this.query)];

                let needle = this.query.toLowerCase();
                if (categoryKey && needle.indexOf(categoryKey) === 0) {
                    needle = needle.substr(categoryKey.length);
                }

                needle = needle.trim();

                const limit = category.limit || GlobalSearchModalComponent.HORSEY_CATEGORY_LIMIT;
                // FIXME remove when https://github.com/farzher/fuzzysort/issues/41 fixed
                if (!needle) {
                    return [
                        category.list.slice(0, limit).map((suggestion) => ({obj: suggestion})),
                        category.list.length,
                    ];
                }

                type NormalizedMap<T> = {[key: string]: string} & {__original: T};

                const normalizedList: NormalizedMap<IAutocompleteSuggestion<any>>[] = (category.list || []).map(
                    (suggestion: IAutocompleteSuggestion<any>) =>
                        GlobalSearchModalComponent.HORSEY_FUZZY_KEYS.reduce(
                            (_normalized, key: keyof (typeof suggestion)) => {
                                _normalized[key] = stripAccents(`${suggestion[key]}`);

                                return _normalized;
                            },
                            {__original: suggestion} as NormalizedMap<typeof suggestion>
                        )
                );

                const result = fuzzysort
                    .go(stripAccents(needle), normalizedList, {
                        keys: GlobalSearchModalComponent.HORSEY_FUZZY_KEYS,
                        threshold: GlobalSearchModalComponent.HORSEY_FUZZYSORT_THRESHOLD,
                    })
                    .map((line) => line.obj && line.obj.__original)
                    .filter((res) => !!res);

                return [result.slice(0, limit), result.length];
            },
            set: this.getHorseySet.bind(this),
            highlightCompleteWords: false,
            renderItem: GlobalSearchModalComponent.renderItem,
            renderCategory: this.renderCategory.bind(this),
            fixPosition: true,
        });

        if (this.query) {
            window.setTimeout(() => this.horsey.show(), 0);
        }
    }

    private async getHorseySources(
        data: ISourceData,
        done: (err: Error | null, res: IAutocompleteSource[]) => void,
        partial: (err: Error | null, res: IAutocompleteSource[]) => void
    ) {
        await Promise.all(
            this.categoriesScope.map(async (category) => {
                if (this.horseySources.find((_source) => _source.id === category)) {
                    return;
                }

                switch (category) {
                    case AutocompleteCategory.ACTION:
                        this.horseySources.push(await this.GET_ACTION_CATEGORY_SOURCE());
                        partial(null, this.filteredHorseySources);
                        break;
                    default:
                        if (!this.autocompleteCategoryMethods[category]) {
                            return;
                        }

                        const entities = await this.autocompleteCategoryMethods[category].get.call(this);
                        this.horseySources.push(
                            await this.autocompleteCategoryMethods[category].map.call(this, entities)
                        );
                        this.mapEntities();
                        partial(null, this.filteredHorseySources);
                        break;
                }
            })
        );

        this.mapEntities();
        done(null, this.filteredHorseySources);
    }

    private get filteredHorseySources(): IAutocompleteSource[] {
        return this.horseySources.filter((source) => this.categoriesScope.indexOf(source.id) !== -1);
    }

    private async getHorseySet(text: string, suggestion: IAutocompleteSuggestion) {
        this.query = '';

        switch (suggestion.category) {
            case AutocompleteCategory.TASKS:
                (async () => this.router.navigate(['/firon'], {queryParams: {taskId: suggestion.id}}))();
                break;
            case AutocompleteCategory.PROJECT:
                (async () => this.router.navigate([`/project/${suggestion.id}`]))();
                break;
            case AutocompleteCategory.PROJECT_SUBMENU:
                (async () =>
                    this.router.navigate([`/project/${suggestion.parent.id}/`], {
                        queryParams: {tab: suggestion.value},
                    }))();
                break;
            case AutocompleteCategory.CLIENT:
                (async () => this.router.navigate([`/client/${suggestion.id}`]))();
                break;
            case AutocompleteCategory.CLIENT_SUBMENU:
                (async () =>
                    this.router.navigate([`/client/${suggestion.parent.id}/`], {
                        queryParams: {tab: suggestion.value},
                    }))();
                break;
            case AutocompleteCategory.EMPLOYEE:
                (async () => this.router.navigate([`/employee/${suggestion.id}`]))();
                break;
            case AutocompleteCategory.WIKI_PAGE:
                (async () => this.router.navigate([`/wiki/page/${suggestion.value}`]))();
                break;
            case AutocompleteCategory.ACTION:
                await this.shortcutHandlerService.execute(suggestion.value as SHORTCUT_NAME);
                break;
            default:
                throw new Error(`Unknown AutocompleteCategory: ${suggestion.category}`);
        }

        this.showChange.emit((this.show = false));
    }

    private static renderItem($li: HTMLLIElement, suggestion: IAutocompleteSuggestion) {
        $li.innerHTML = `<span class="sey-item-text">${suggestion.text}</span>`;

        let avatar: string | number;
        let icon: string;
        let classes = '';
        let styles = '';
        let kbd: string;
        switch (suggestion.category) {
            case AutocompleteCategory.PROJECT:
                avatar = ((suggestion.entity as IProject).tasks || []).filter(GlobalSearchModalComponent.taskFilter)
                    .length;
                break;
            case AutocompleteCategory.EMPLOYEE:
                const employee = suggestion.entity as IEmployee;
                avatar = employee.user.code;
                if (employee.user.color) {
                    styles += `background-color: ${employee.user.color}`;
                }
                break;
            case AutocompleteCategory.TASKS:
                const status = (suggestion.entity as ITask).status;
                const statusFilter = TASK_STATUS_FILTER[status];
                if (statusFilter) {
                    icon = statusFilter.icon;
                    classes = Array.isArray(statusFilter.classList)
                        ? statusFilter.classList.join(' ')
                        : statusFilter.classList;
                }
                break;
            case AutocompleteCategory.CLIENT:
                avatar = ((suggestion.entity as IClient).projects || []).reduce(
                    (sum, project) => sum + (project.tasks || []).filter(GlobalSearchModalComponent.taskFilter).length,
                    0
                );
                break;
            case AutocompleteCategory.ACTION:
                if (suggestion.shortcut && SHORTCUT_COMBO[suggestion.shortcut]) {
                    kbd = Array.isArray(SHORTCUT_COMBO[suggestion.shortcut])
                        ? (SHORTCUT_COMBO[suggestion.shortcut] as string[]).join('+')
                        : (SHORTCUT_COMBO[suggestion.shortcut] as string);
                }
                break;
            default:
                break;
        }

        if (avatar) {
            const classList = `avatar float-right ${classes}`;
            $li.innerHTML += `<figure class="${classList}" style="${styles}" data-initial="${avatar}"></figure>`;
        } else if (kbd) {
            $li.innerHTML += `<kbd class="float-right ${classes}" style="white-space: pre; ${styles}">${kbd}</kbd>`;
        } else if (icon) {
            $li.innerHTML += `<clr-icon shape="${icon}" class="float-right ${classes}" style="${styles}"></clr-icon>`;
        }
    }

    private renderCategory($div: HTMLDivElement, category: IAutocompleteSource<any>) {
        if (category.id === ('default' as AutocompleteCategory)) {
            return;
        }

        const $categoryId = document.createElement('div');
        $categoryId.classList.add('sey-category-id');
        $div.appendChild($categoryId);

        if (!AUTOCOMPLETE_CATEGORY_TRANSLATE_KEY[category.id]) {
            $categoryId.innerText = category.id;

            return;
        }

        (async () => {
            $categoryId.innerText = await this.translate
                .get(AUTOCOMPLETE_CATEGORY_TRANSLATE_KEY[category.id])
                .toPromise();
        })();
    }

    private static taskFilter(task: Partial<ITask>) {
        return task.status !== TaskStatusType.DONE;
    }

    private async getClients(): Promise<IClient[]> {
        this.authService.rights = await this.authService.getRightsPromise;
        if (
            !this.authService.rights.find(
                (right) => right.code === GlobalSearchModalComponent.CATEGORY_RIGHTS[AutocompleteCategory.CLIENT]
            )
        ) {
            return [];
        }

        return this.httpRest
            ._request<IClient[]>(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, CLIENT_SCHEMA_ROUTE, 'list')
            .toPromise();
    }

    private async mapClients(clients: IClient[]): Promise<IAutocompleteSource> {
        this.authService.rights = await this.authService.getRightsPromise;

        return {
            ...(await GlobalSearchModalComponent.generateSource(AutocompleteCategory.CLIENT, clients, (client) => ({
                category: AutocompleteCategory.CLIENT,
                id: client.id,
                value: client.obs,
                text: client.user.name,
                entity: client,
            }))),
            submenu: await Promise.all(
                Object.keys(CLIENT_TABS_LABELS)
                    .filter((tab: CLIENT_TABS) =>
                        this.authService.rights.find((right) => right.code === CLIENT_TABS_RIGHTS[tab])
                    )
                    .map(async (tab: CLIENT_TABS) => ({
                        category: AutocompleteCategory.CLIENT_SUBMENU,
                        value: tab,
                        text: await this.translate.get(tab).toPromise(),
                    }))
            ),
        } as IAutocompleteSource;
    }

    private async getProjects(): Promise<IProject[]> {
        this.authService.rights = await this.authService.getRightsPromise;
        if (
            !this.authService.rights.find(
                (right) => right.code === GlobalSearchModalComponent.CATEGORY_RIGHTS[AutocompleteCategory.PROJECT]
            )
        ) {
            return [];
        }

        return this.httpRest
            ._request<IProject[]>(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, PROJECT_SCHEMA_ROUTE, 'globalSearchList')
            .toPromise();
    }

    private async mapProjects(projects: IProject[]): Promise<IAutocompleteSource> {
        return {
            ...(await GlobalSearchModalComponent.generateSource(AutocompleteCategory.PROJECT, projects, (project) => ({
                category: AutocompleteCategory.PROJECT,
                id: project.id,
                value: project.code,
                text: project.obs,
                entity: project,
            }))),
            submenu: await Promise.all(
                Object.keys(PROJECT_TABS_LABELS)
                    .filter((tab: PROJECT_TABS) =>
                        this.authService.rights.find((right) => right.code === PROJECT_TABS_RIGHTS[tab])
                    )
                    .map(async (tab: PROJECT_TABS) => ({
                        category: AutocompleteCategory.PROJECT_SUBMENU,
                        value: tab,
                        text: await this.translate.get(tab).toPromise(),
                    }))
            ),
        } as IAutocompleteSource;
    }

    private async getEmployees(): Promise<IEmployee[]> {
        this.authService.rights = await this.authService.getRightsPromise;
        if (
            !this.authService.rights.find(
                (right) => right.code === GlobalSearchModalComponent.CATEGORY_RIGHTS[AutocompleteCategory.EMPLOYEE]
            )
        ) {
            return [];
        }

        return this.httpRest
            ._request<IEmployee[]>(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, EMPLOYEE_SCHEMA_ROUTE, 'globalSearch')
            .toPromise();
    }

    private static async mapEmployees(employees: IEmployee[]): Promise<IAutocompleteSource> {
        return GlobalSearchModalComponent.generateSource(AutocompleteCategory.EMPLOYEE, employees, (employee) => ({
            category: AutocompleteCategory.EMPLOYEE,
            id: employee.id,
            value: employee.user.code,
            text: employee.user.name,
            entity: employee,
        }));
    }

    private async getTasks(): Promise<ITask[]> {
        this.authService.rights = await this.authService.getRightsPromise;
        if (
            !this.authService.rights.find(
                (right) => right.code === GlobalSearchModalComponent.CATEGORY_RIGHTS[AutocompleteCategory.TASKS]
            )
        ) {
            return [];
        }

        return this.httpRest
            ._request<ITask[]>(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, TASK_SCHEMA_ROUTE, 'global')
            .toPromise();
    }

    private static async mapTasks(tasks: ITask[]): Promise<IAutocompleteSource> {
        return GlobalSearchModalComponent.generateSource(AutocompleteCategory.TASKS, tasks, (task) => ({
            category: AutocompleteCategory.TASKS,
            id: task.id,
            value: `${task.project && task.project.code}-${task.id}`,
            text: task.name,
            entity: task,
        }));
    }

    private async getWikiPages(): Promise<IWikiPage[]> {
        this.authService.rights = await this.authService.getRightsPromise;
        if (
            !this.authService.rights.find(
                (right) => right.code === GlobalSearchModalComponent.CATEGORY_RIGHTS[AutocompleteCategory.WIKI_PAGE]
            )
        ) {
            return [];
        }

        // return this.httpRest
        //     ._request<IWikiPage[]>(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, WIKI_PAGE_SCHEMA_ROUTE, 'light')
        //     .toPromise();
        return this.httpRest
            ._request<IWikiPage[]>(HTTP_METHOD.POST, ApiRoutePlurality.PLURAL, WIKI_PAGE_SCHEMA_ROUTE, 'search')
            .toPromise();
    }

    private static async mapWikiPages(wikiPages: IWikiPage[]): Promise<IAutocompleteSource> {
        return GlobalSearchModalComponent.generateSource(AutocompleteCategory.WIKI_PAGE, wikiPages, (wikiPage) => ({
            category: AutocompleteCategory.WIKI_PAGE,
            id: wikiPage.id,
            value: wikiPage.hash,
            text: wikiPage.title,
            entity: wikiPage,
        }));
    }

    private static async generateSource<T>(
        category: AutocompleteCategory,
        entities: T[],
        mapFn: (
            value: T,
            index: number,
            array: T[]
        ) => (IAutocompleteSuggestion | boolean) | Promise<IAutocompleteSuggestion | boolean>
    ): Promise<IAutocompleteSource> {
        return {
            id: category,
            limit: GlobalSearchModalComponent.HORSEY_CATEGORY_LIMIT,
            list: (await Promise.all(entities.map(mapFn))).filter((suggestion) => !!suggestion),
        } as IAutocompleteSource;
    }

    public get searchScope(): AutocompleteCategory {
        return this._searchScope;
    }

    public set searchScope(searchScope: AutocompleteCategory) {
        this._searchScope = searchScope;
        this.title = `${searchScope}_search`;

        (async () => this.initHorsey())();
        /*if (this.query && this.horsey) {
      window.setTimeout(() => {
        this.autocompleteInput.nativeElement.focus();
        this.horsey.retarget(this.autocompleteInput.nativeElement);
        this.horsey.show();
      }, 0);
    }*/
    }

    private mapEntities() {
        const taskSource = this.horseySources.find(
            (_source) => _source.id === AutocompleteCategory.TASKS
        ) as IAutocompleteSource<ITask>;
        const projectSource = this.horseySources.find(
            (_source) => _source.id === AutocompleteCategory.PROJECT
        ) as IAutocompleteSource<IProject>;
        const clientSource = this.horseySources.find(
            (_source) => _source.id === AutocompleteCategory.CLIENT
        ) as IAutocompleteSource<IClient>;

        if (taskSource && projectSource) {
            const tasks = taskSource.list.map((task) => task.entity);
            const projects = projectSource.list.map((project) => project.entity);

            tasks.map((task) => (task.project = projects.find((project) => project.id === task.projectId)));

            projects.map((project) => (project.tasks = tasks.filter((task) => task.projectId === project.id)));
        }

        if (projectSource && clientSource) {
            const projects = projectSource.list.map((project) => project.entity);
            const clients = clientSource.list.map((client) => client.entity);

            projects.map((project) => (project.client = clients.find((client) => client.id === project.clientId)));

            clients.map((client) => (client.projects = projects.filter((project) => project.clientId === client.id)));
        }
    }

    private static getCategory(query: string): AutocompleteCategory {
        const categoryIndex = Object.values(GlobalSearchModalComponent.CATEGORY_KEY).findIndex(
            (_key) => query.indexOf(_key) === 0
        );

        return (
            categoryIndex !== -1 &&
            (Object.keys(GlobalSearchModalComponent.CATEGORY_KEY)[categoryIndex] as AutocompleteCategory)
        );
    }

    public get categoriesScope(): AutocompleteCategory[] {
        const category = GlobalSearchModalComponent.getCategory(this.query);
        if (category) {
            return [category];
        }

        return this.searchScope !== AutocompleteCategory.GLOBAL
            ? [this.searchScope]
            : (Object.values(AutocompleteCategory) as AutocompleteCategory[]).filter(
                  (_category) => _category !== AutocompleteCategory.GLOBAL
              );
    }

    public static readonly HORSEY_CATEGORY_LIMIT = 5;
    public static readonly HORSEY_CATEGORY_OVERFLOW_LIMIT_INCREMENT = 10;
    public static readonly HORSEY_FUZZYSORT_THRESHOLD = -Infinity;

    // FIXME cast ids to string (fuzzysort)?
    private static readonly HORSEY_FUZZY_KEYS = [/*'id',*/ 'value', 'text'];

    public static readonly CATEGORY_KEY: {[category in AutocompleteCategory]?: string} = {
        [AutocompleteCategory.ACTION]: '!',
        [AutocompleteCategory.PROJECT]: '#',
        [AutocompleteCategory.CLIENT]: '~',
        [AutocompleteCategory.EMPLOYEE]: '@',
        [AutocompleteCategory.WIKI_PAGE]: '?',
        [AutocompleteCategory.TASKS]: "'",
    };

    public static readonly CATEGORY_RIGHTS: {[category in AutocompleteCategory]?: NAVIGATE_RIGHTS} = {
        [AutocompleteCategory.PROJECT]: NAVIGATE_RIGHTS.NAVIGATE_PROJECT,
        [AutocompleteCategory.CLIENT]: NAVIGATE_RIGHTS.NAVIGATE_CLIENT,
        [AutocompleteCategory.EMPLOYEE]: NAVIGATE_RIGHTS.NAVIGATE_EMPLOYEE,
        [AutocompleteCategory.WIKI_PAGE]: NAVIGATE_RIGHTS.NAVIGATE_WIKI,
        [AutocompleteCategory.TASKS]: NAVIGATE_RIGHTS.NAVIGATE_FIRON,
    };

    private readonly GET_ACTION_CATEGORY_SOURCE: () => Promise<IAutocompleteSource> = async () => {
        this.authService.rights = await this.authService.getRightsPromise;

        return GlobalSearchModalComponent.generateSource(
            AutocompleteCategory.ACTION,
            Object.keys(SHORTCUT_LABEL),
            async (shortcutKey: SHORTCUT_NAME) =>
                (!SHORTCUT_RIGHTS[shortcutKey] ||
                    this.authService.rights.find((right) => right.code === SHORTCUT_RIGHTS[shortcutKey])) && {
                    category: AutocompleteCategory.ACTION,
                    value: shortcutKey,
                    text: await this.translate.get(SHORTCUT_LABEL[shortcutKey]).toPromise(),
                    shortcut: shortcutKey || undefined,
                }
        );
    };

    public readonly AutocompleteCategory = AutocompleteCategory;
    public readonly CATEGORY_KEY = GlobalSearchModalComponent.CATEGORY_KEY;
    public readonly CATEGORY_KEY_KEYS = Object.keys(GlobalSearchModalComponent.CATEGORY_KEY) as AutocompleteCategory[];

    public readonly CATEGORY_RIGHTS = GlobalSearchModalComponent.CATEGORY_RIGHTS;
}
