import {
    ApplicationRef,
    ComponentFactoryResolver,
    ComponentRef,
    EmbeddedViewRef,
    Injectable,
    Injector,
    Type,
} from '@angular/core';
import {Observable, Subject} from 'rxjs';

export interface IModalButtonOptions {
    i18n: string;
    class: string;
}

export interface IModalSimpleOptions {
    title: string;
    size?: 'sm' | 'md' | 'lg' | 'xl';
    ok?: IModalButtonOptions;
    ko?: IModalButtonOptions;
    contentString?: string;
    contentI18n?: string;
    contentObj?: any;
}

/****** Add a modal

private readonly modalSimpleService: ModalSimpleService

this.modalSimpleService
    .open(ModalSimpleComponent, {
        title: 'Title of my new modal',
        contentString: 'My dynamic message with <br> HTML !!!',
        size: '',
    })
    .subscribe(
        (closed) => console.log('modalClosed', closed),
        (dismissed) => console.log('modalDismiss', dismissed),
        () => console.log('completed')
    );
*/

@Injectable({
    providedIn: 'root',
})
export class ModalSimpleService {
    public componentRef: ComponentRef<any>;
    private result$: Subject<any>;

    public constructor(
        private readonly componentFactoryResolver: ComponentFactoryResolver,
        private readonly appRef: ApplicationRef,
        private readonly injector: Injector
    ) {}

    public open<T>(component: Type<T>, modalOptions: IModalSimpleOptions): Observable<any> {
        this.removeModal();

        const factory = this.componentFactoryResolver.resolveComponentFactory(component);
        this.componentRef = factory.create(this.injector);

        this.appRef.attachView(this.componentRef.hostView);

        this.componentRef.instance.onInjectInputs(modalOptions);

        const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

        document.body.appendChild(domElem);

        return this.onResult();
    }

    public removeModal() {
        if (this.componentRef) {
            this.appRef.detachView(this.componentRef.hostView);
            this.componentRef.destroy();
        }
    }

    public onResult(): Observable<any> {
        this.result$ = new Subject<any>();

        return this.result$.asObservable();
    }

    public close(): void {
        this.result$.next({result: false});
        this.result$.complete();
        this.removeModal();
    }
    public dismiss(): void {
        this.result$.error('dismissed');
        this.removeModal();
    }
    public validate(): void {
        this.result$.next({result: true});
        this.result$.complete();
        this.removeModal();
    }
}
