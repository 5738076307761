import {Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {Validators} from '@angular/forms';
import {ClrForm} from '@clr/angular';
import {FormControl, FormGroup} from 'ngx-strongly-typed-forms';
import {
    ApiRoutePlurality,
    HTTP_METHOD,
    MAX_LENGTH_DEFAULT,
    PATTERN_CAPITAL_NUMERIC_SPACE,
} from '../../../../../defs/schema-static';
import {CLIENT_SCHEMA_ROUTE, IClient} from '../../../../../defs/schema/public/Clients';
import {IUser, USER_SCHEMA_ROUTE} from '../../../../../defs/schema/public/Users';
import {getRandomColorHex, IResettable, MAX_LENGTH_CLIENT_CODE} from '../../app-static';
import {requiredTrimValidator} from '../../forms/validators/required-trim.validator';
import {HttpRestService} from '../../shared/http-rest/http-rest.service';

enum CLIENT_FORM_KEYS {
    name = 'name',
    code = 'code',
    color = 'color',
    obs = 'obs',
}

interface IClientFormValues {
    name: string;
    code: string;
    color: string;
    obs: string;
}

@Component({
    selector: 'app-client-wizard-client',
    templateUrl: './client-wizard-client.component.html',
    styleUrls: ['./client-wizard-client.component.scss'],
})
export class ClientWizardClientComponent implements OnInit, OnChanges, IResettable {
    @ViewChild(ClrForm) private readonly clrForm: ClrForm;

    @ViewChild('nameElement') public nameElement: ElementRef;

    @Input() public client: IClient;
    @Input() public formLayout = 'horizontal';

    @Input() public clrWizardOpen = false;

    public readonly form = new FormGroup<IClientFormValues>({
        [CLIENT_FORM_KEYS.name]: new FormControl<string>(null, [
            requiredTrimValidator(),
            Validators.maxLength(MAX_LENGTH_DEFAULT),
        ]),
        [CLIENT_FORM_KEYS.code]: new FormControl<string>(null, [
            requiredTrimValidator(),
            Validators.maxLength(MAX_LENGTH_CLIENT_CODE),
            Validators.pattern(PATTERN_CAPITAL_NUMERIC_SPACE),
        ]),
        [CLIENT_FORM_KEYS.color]: new FormControl<string>(getRandomColorHex(), requiredTrimValidator()),
        [CLIENT_FORM_KEYS.obs]: new FormControl<string>(null, Validators.maxLength(MAX_LENGTH_DEFAULT)),
    });
    public constructor(private readonly httpRest: HttpRestService) {}

    public ngOnInit() {
        this.setClient();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.client) {
            this.setClient();
        }
    }

    public onLoad() {
        requestAnimationFrame(() => {
            if (this.nameElement) {
                this.nameElement.nativeElement.focus();
            }
        });
    }

    public setClient(): void {
        if (!this.client) {
            return this.reset();
        }

        this.form.reset({
            [CLIENT_FORM_KEYS.obs]: this.client.obs,
            ...(((this.client && this.client.user) || {}) as IClientFormValues),
        });
    }

    public async submit(): Promise<IClient> {
        if (!this.form.valid) {
            this.clrForm.markAsDirty();

            return undefined;
        }

        const {name, code, color, obs} = this.form.value;

        const userValues: Partial<IUser> = {
            name,
            code,
            color,
        };

        const clientValues: Partial<IClient> = {obs};

        const {client, user} = await this.httpRest
            ._request<{client: IClient; user: IUser}>(
                HTTP_METHOD.PUT,
                ApiRoutePlurality.SINGULAR,
                CLIENT_SCHEMA_ROUTE,
                'withUser',
                {
                    userValues,
                    clientValues,
                }
            )
            .toPromise();
        client.user = user;

        return client;
    }

    public reset(): void {
        this.form.reset({
            [CLIENT_FORM_KEYS.color]: getRandomColorHex(),
        });
    }

    public readonly CLIENT_FORM_KEYS = CLIENT_FORM_KEYS;
    public readonly MAX_LENGTH_CLIENT_CODE = MAX_LENGTH_CLIENT_CODE;
    public readonly MAX_LENGTH_DEFAULT = MAX_LENGTH_DEFAULT;
}
