import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {ClrLoadingState} from '@clr/angular';
import {TranslateService} from '@ngx-translate/core';
import {noop} from 'rxjs';
import {ApiRoutePlurality, HTTP_METHOD, RIGHTS} from '../../../../defs/schema-static';
import {EMPLOYEE_SCHEMA_ROUTE, IEmployee} from '../../../../defs/schema/public/Employees';
import {IUser} from '../../../../defs/schema/public/Users';
import {EmployeeWizardEmployeeComponent} from '../employee-wizard/employee/employee-wizard-employee.component';
import {validate} from '../forms/validators/form.validator';
import {ModalSimpleComponent} from '../modal-simple/modal-simple.component';
import {ModalSimpleService} from '../modal-simple/modal-simple.service';
import {ICanComponentDeactivate} from '../shared/can-deactivate-guard/can-deactivate-guard';
import {HttpRestService} from '../shared/http-rest/http-rest.service';
import {LONG_AVATAR_TYPE} from '../shared/long-avatar/long-avatar.component';
import {TOAST_TYPE, ToastService} from '../shared/toast/toast.service';

@Component({
    selector: 'app-employee',
    templateUrl: './employee.component.html',
    styleUrls: ['./employee.component.scss'],
})
export class EmployeeComponent implements OnInit, ICanComponentDeactivate {
    public employee: IEmployee;

    public validateBtnState: ClrLoadingState = ClrLoadingState.DEFAULT;

    @ViewChild(EmployeeWizardEmployeeComponent) public editComponent: EmployeeWizardEmployeeComponent;

    public constructor(
        private readonly httpRest: HttpRestService,
        private readonly route: ActivatedRoute,
        private readonly toastService: ToastService,
        private readonly translate: TranslateService,
        private readonly router: Router,
        private readonly modalSimpleService: ModalSimpleService
    ) {}

    public ngOnInit() {
        this.route.params.subscribe(async ({id}) => {
            await this.getEmployee(Number(id));
        });
    }

    public canDeactivate(
        component: EmployeeComponent,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState?: RouterStateSnapshot
    ): boolean {
        return !this.editComponent.form.dirty;
    }

    @HostListener('window:beforeunload', ['$event'])
    public confirmUnloadChanges($event: BeforeUnloadEvent) {
        if (this.editComponent.form.dirty) {
            $event.preventDefault();

            return ($event.returnValue = true);
        }
    }

    public async getEmployee(employeeId?: number) {
        if (!employeeId) {
            this.employee = undefined;

            return;
        }

        if (this.employee && employeeId === this.employee.id) {
            return;
        }

        this.employee = await this.httpRest
            ._request<IEmployee>(
                HTTP_METHOD.GET,
                ApiRoutePlurality.SINGULAR,
                EMPLOYEE_SCHEMA_ROUTE,
                `employee/${employeeId}`
            )
            .toPromise();
    }

    public saveEmployee() {
        const {form} = this.editComponent;
        if (!form.valid) {
            return;
        }

        this.validateBtnState = ClrLoadingState.LOADING;

        const {name, code, color, dayPrice, email, discordSnowflake, phoneNumber, mobileNumber} = form.getRawValue();

        const employee: Partial<IEmployee> = {
            dayPrice,
            phoneNumber,
            mobileNumber,
            discordSnowflake,
        };

        const user: Partial<IUser> = {
            color,
            name,
            code,
            email,
        };

        const postEmployee = () =>
            this.httpRest
                ._request(HTTP_METHOD.POST, ApiRoutePlurality.SINGULAR, EMPLOYEE_SCHEMA_ROUTE, '/employee/withUser', {
                    userValues: {...user, id: this.employee.user.id},
                    employeeValues: {...employee, id: this.employee.id},
                })
                .subscribe(
                    async () => {
                        this.validateBtnState = ClrLoadingState.SUCCESS;
                        Object.assign(this.employee, employee);
                        Object.assign(this.employee.user, user);

                        this.toastService.show({
                            type: TOAST_TYPE.SUCCESS,
                            text: 'success_update_employee',
                        });

                        form.markAsPristine();
                    },
                    () => {
                        this.validateBtnState = ClrLoadingState.DEFAULT;
                    }
                );

        postEmployee();
    }

    public deleteEmployeeModal() {
        this.modalSimpleService
            .open(ModalSimpleComponent, {
                title: 'employee_delete_modal_title',
                contentI18n: 'swal_delete_attch',
                contentObj: {what: this.employee.user.name},
                ok: {
                    i18n: 'delete_employee',
                    class: 'btn-danger',
                },
            })
            .subscribe((closed) => {
                if (closed.result) {
                    this.deleteEmployee();
                }
            }, noop);
    }

    public async deleteEmployee() {
        try {
            await this.httpRest
                ._request(
                    HTTP_METHOD.DELETE,
                    ApiRoutePlurality.SINGULAR,
                    EMPLOYEE_SCHEMA_ROUTE,
                    `/employee/withUser/${this.employee.id}`
                )
                .toPromise();
        } catch (err) {
            return undefined;
        }

        this.toastService.show({
            type: TOAST_TYPE.SUCCESS,
            text: 'success_delete_employee',
        });

        return this.router.navigate(['/employees']);
    }

    public readonly validate = validate;
    public readonly RIGHTS = RIGHTS;
    public readonly LONG_AVATAR_TYPE = LONG_AVATAR_TYPE;
}
